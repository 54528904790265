import { Button, Checkbox, Descriptions, TreeSelect } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class ConfigCOAExpensesModalAddLinkPI extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			PIs: [this.getEmptyPIObj()],
		}

		this.onCOA = this.onCOA.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onDate = this.onDate.bind(this)
		this.onAddPILink = this.onAddPILink.bind(this)
	}

	componentDidMount() {
		if (this.props.COA.expense.selectedAccount.account) {
			if (!_.isEmpty(this.props.COA.chartOfAccounts)) {
				this.initialSelected()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.COA.chartOfAccounts, this.props.COA.chartOfAccounts)) {
			if (this.props.COA.expense.selectedAccount.account && !_.isEmpty(this.props.COA.chartOfAccounts)) {
				this.initialSelected()
			}
		}

		if (!_.isEqual(prevState.PIs, this.state.PIs)) {
			this.props.onRenderParentCallback()
		}
	}

	componentWillUnmount() {}

	initialSelected() {
		let selectedAccount = this.props.COA.expense.selectedAccount.account
		this.setState({
			PIs: selectedAccount.linkedAccountingCharts.map((link) => {
				let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.COA.chartOfAccounts, link.accountingChart)
				return {
					selectedPI: {
						index: _.isEmpty(selectedAry) ? -1 : selectedAry[0],
						PI: _.isEmpty(selectedAry) ? null : selectedAry[1],
					},
					purchaseInvoiceTransDescr: Utils.trim(
						link.transDescr.replace(ResourceAssistance.PROGRAM_DEFINED.supplier, "").replace(ResourceAssistance.PROGRAM_DEFINED.date, "")
					),
					isPurchaseInvoiceTransDescrIncludeSupplier: link.transDescr.includes(ResourceAssistance.PROGRAM_DEFINED.supplier),
					isPurchaseInvoiceTransDescrIncludeDate: link.transDescr.includes(ResourceAssistance.PROGRAM_DEFINED.date),
				}
			}),
		})
	}

	getEmptyPIObj() {
		return {
			selectedPI: {
				index: -1,
				PI: null,
			},
			purchaseInvoiceTransDescr: "",
			isPurchaseInvoiceTransDescrIncludeSupplier: false,
			isPurchaseInvoiceTransDescrIncludeDate: false,
		}
	}

	onCOA(value, idx) {
		if (value === undefined) {
			this.setState((prevState) => {
				const updatedPIs = [...prevState.PIs]
				updatedPIs[idx] = {
					...updatedPIs[idx],
					selectedPI: {
						index: -1,
						PI: null,
					},
				}
				return { PIs: updatedPIs }
			})
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.setState((prevState) => {
				const updatedPIs = [...prevState.PIs]
				updatedPIs[idx] = {
					...updatedPIs[idx],
					selectedPI: {
						index: value,
						PI: (function getSelectedAccount(accounts, idx) {
							if (idx === keys.length - 1) {
								return accounts[keys[idx]]
							}
							return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
						})(this.props.COA.chartOfAccounts[keys[0]].values, 1),
					},
				}
				return { PIs: updatedPIs }
			})
		} else {
			this.setState((prevState) => {
				const updatedPIs = [...prevState.PIs]
				updatedPIs[idx] = {
					...updatedPIs[idx],
					selectedPI: {
						index: value,
						PI: this.props.COA.chartOfAccounts[value],
					},
				}
				return { PIs: updatedPIs }
			})
		}
	}

	onDescription(e) {
		let idx = JSON.parse(e.target.getAttribute("extra-value")).index
		this.setState((prevState) => {
			const updatedPIs = [...prevState.PIs]
			updatedPIs[idx] = {
				...updatedPIs[idx],
				purchaseInvoiceTransDescr: e.target.value,
			}
			return { PIs: updatedPIs }
		})
	}

	onSupplier(e) {
		let idx = e.target["extra-data"].index
		this.setState((prevState) => {
			const updatedPIs = [...prevState.PIs]
			updatedPIs[idx] = {
				...updatedPIs[idx],
				isPurchaseInvoiceTransDescrIncludeSupplier: e.target.checked,
			}
			return { PIs: updatedPIs }
		})
	}

	onDate(e) {
		let idx = e.target["extra-data"].index
		this.setState((prevState) => {
			const updatedPIs = [...prevState.PIs]
			updatedPIs[idx] = {
				...updatedPIs[idx],
				isPurchaseInvoiceTransDescrIncludeDate: e.target.checked,
			}
			return { PIs: updatedPIs }
		})
	}

	onAddPILink(e) {
		this.setState((prevState) => {
			const updatedPIs = [...prevState.PIs]
			updatedPIs.push(this.getEmptyPIObj())
			return {
				PIs: updatedPIs,
			}
		})
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.purchaseInvoice)}</legend>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={2}
							extra={
								<Row>
									<Col md="auto">
										<Button
											type="primary"
											size="middle"
											shape="round"
											icon={<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />}
											onClick={this.onAddPILink}
										/>
									</Col>
								</Row>
							}
						>
							{this.getLinks()}
						</Descriptions>
					</Col>
				</Row>
			</fieldset>
		)
	}

	getLinks() {
		return this.state.PIs.map((each, i) => {
			return (
				<Fragment key={i}>
					<Descriptions.Item span={2}>
						<TreeSelect
							style={{
								textAlign: "center",
								flex: "1",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							loading={this.props.COA.isChartOfAccountsLoading}
							value={each.selectedPI.PI ? each.selectedPI.index : undefined}
							onChange={(v) => {
								this.onCOA(v, i)
							}}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(
								this.props.COA.chartOfAccounts,
								true,
								this.state.PIs.reduce((ary, cur) => {
									if (cur.selectedPI.PI) {
										ary.push(cur.selectedPI.PI.id)
									}
									return ary
								}, []),
								undefined,
								["displayName", "fullCode"],
								false
							)}
						/>
					</Descriptions.Item>
					<Descriptions.Item span={2}>
						<Row className={"full-size"}>
							<Col>
								<GInput
									required
									placeholder={translate(ResourceAssistance.Message.description)}
									disabled={!each.selectedPI.PI}
									value={each.purchaseInvoiceTransDescr}
									extraValue={JSON.stringify({ index: i })}
									onChange={this.onDescription}
								/>
							</Col>
						</Row>
					</Descriptions.Item>
					<Descriptions.Item contentStyle={{ justifyContent: "center" }} span={1}>
						<Checkbox
							style={{ flexDirection: "row" }}
							disabled={!each.selectedPI.PI}
							checked={each.isPurchaseInvoiceTransDescrIncludeSupplier}
							extra-data={{
								index: i,
							}}
							onChange={this.onSupplier}
						>
							{translate(ResourceAssistance.Message.supplier)}
						</Checkbox>
					</Descriptions.Item>
					<Descriptions.Item contentStyle={{ justifyContent: "center" }} span={1}>
						<Checkbox
							style={{ flexDirection: "row" }}
							disabled={!each.selectedPI.PI}
							checked={each.isPurchaseInvoiceTransDescrIncludeDate}
							extra-data={{
								index: i,
							}}
							onChange={this.onDate}
						>
							{translate(ResourceAssistance.Message.date)}
						</Checkbox>
					</Descriptions.Item>
				</Fragment>
			)
		})
	}
}

const mapStateToProps = (state) => ({
	COA: state.admin.configCOA,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ConfigCOAExpensesModalAddLinkPI)
