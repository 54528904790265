import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { SET_HOSPITEL_LAB_SELECTED_PAITENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import { setSelected } from "~/redux/action"

class CLLeftPatientRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLLeftPatientRow",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.lab.patientTable.filtered, this.props.lab.patientTable.filtered)) {
			let index = this.props.lab.selectedPatient.patient
				? this.props.lab.patientTable.filtered.findIndex((patient) => patient.id === this.props.lab.selectedPatient.patient.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_HOSPITEL_LAB_SELECTED_PAITENT, index, this.props.lab.patientTable.filtered[index])
			} else {
				this.props.setSelected(SET_HOSPITEL_LAB_SELECTED_PAITENT, -1, null)
			}
		}

		if (!_.isEqual(prevProps.lab.selectedPatient, this.props.lab.selectedPatient)) {
			if (
				(prevProps.lab.selectedPatient.patient &&
					this.props.lab.selectedPatient.patient &&
					prevProps.lab.selectedPatient.patient.id !== this.props.lab.selectedPatient.patient.id) ||
				(!prevProps.lab.selectedPatient.patient && this.props.lab.selectedPatient.patient)
			) {
				Utils.notification(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.notification }),
					this.props.lab.selectedPatient.patient.notifications,
					"warning"
				)
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_HOSPITEL_LAB_SELECTED_PAITENT, index, this.props.lab.patientTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.lab.patientTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.lab.selectedPatient.index === -1}
						highlightedRow={this.props.lab.selectedPatient.index}
					/>
					{this.props.lab.patientTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CLLeftPatientRow))
