import { Card, Descriptions } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { ImAttachment, ImPrinter } from "react-icons/im"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setPSCompletePaymentPlans,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
	setPSRequests,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_PS_GL_DATE_TIME, SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PaymentSlipPrintDataFactory from "~/utils/factory/print/fiannce/PaymentSlipPrintDataFactory"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentSlipRightPaymentPlanTabComplete extends React.Component {
	constructor(props) {
		super(props)

		this.isActionClicked = false

		this.selectRow = this.selectRow.bind(this)
		this.onViewAttachment = this.onViewAttachment.bind(this)
		this.onInspector = this.onInspector.bind(this)
		this.onReject = this.onReject.bind(this)
		this.onApprover = this.onApprover.bind(this)
		this.onGeneralLedgerDateTime = this.onGeneralLedgerDateTime.bind(this)
		this.onPrintJV = this.onPrintJV.bind(this)
	}

	componentDidMount() {
		if (this.props.PS.selectedSupplier.supplier) {
			this.props.setPSCompletePaymentPlans(this.props.PS.selectedSupplier.supplier.paymentPlans)
		} else {
			this.props.setPSCompletePaymentPlans([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.inspectPaymentPlan)) {
					this.inspectPaymentPlan()
				} else if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.approvePaymentPlan)) {
					this.approvePaymentPlan()
				} else if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.rejectPaymentPlan)) {
					this.rejectPaymentPlan()
				}
			}
		}
		if (!_.isEqual(prevProps.warning.isClose, this.props.warning.isClose)) {
			if (this.props.warning.isClose) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.approvePaymentPlan)) {
					this.props.setValue(SET_PS_GL_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
				}
			}
		}

		if (!_.isEqual(prevProps.PS.selectedSupplier, this.props.PS.selectedSupplier)) {
			if (this.props.PS.selectedSupplier.supplier) {
				this.props.setPSCompletePaymentPlans(this.props.PS.selectedSupplier.supplier.paymentPlans)
			} else {
				this.props.setPSCompletePaymentPlans([])
			}
		}
		if (!_.isEqual(prevProps.PS.completePaymentPlanTable.original, this.props.PS.completePaymentPlanTable.original)) {
			let index = this.props.PS.selectedCompletePaymentPlan.paymentPlan
				? this.props.PS.completePaymentPlanTable.filtered.findIndex((each) => each.id === this.props.PS.selectedCompletePaymentPlan.paymentPlan.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN, index, this.props.PS.completePaymentPlanTable.filtered[index])
			} else {
				this.props.setSelected(SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN, -1, null)
	}

	isDisplayInspector() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.FinancialOperationInspector, this.props.login.user.roles)
	}

	isDisplayReject() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.FinancialOperationApprover, this.props.login.user.roles)
	}

	isDisplayApprover() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.FinancialOperationApprover, this.props.login.user.roles)
	}

	approvePaymentPlan() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.approvePaymentPlan,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedCompletePaymentPlan.paymentPlan.id,
					},
				],
				generalLedgers: this.props.PS.generalLedgerGroupTable.body.map((each, idx) => {
					return {
						dateTime: this.props.PS.generalLedgerDateTime,
						documentCode: ResourceAssistance.Enum.financePaymentDocumentCode,
						documentId: this.props.PS.selectedCompletePaymentPlan.paymentPlan.id,
						code: each[0],
						name: each[1],
						category: each[2],
						transactionDescription: each[3],
						debit: Utils.formatNumberFromStr(each[4]).toFixed(2),
						credit: Utils.formatNumberFromStr(each[5]).toFixed(2),
						workflow: ResourceAssistance.Enum.financePayment,
						systemGenerated: this.props.PS.generalLedgerGroupTable.rowColor[idx][0],
					}
				}),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	inspectPaymentPlan() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.inspectPaymentPlan,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedCompletePaymentPlan.paymentPlan.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	rejectPaymentPlan() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.rejectPaymentPlan,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedCompletePaymentPlan.paymentPlan.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	displayWarning(selectedPaymentPlan, warningId, msg, isDisplayDate) {
		this.isActionClicked = true
		this.props.setWarningId(warningId)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={msg}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.id)} span={1}>
						{selectedPaymentPlan.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedPaymentPlan.paymentTermDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
						{selectedPaymentPlan.paymentMethod}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedPaymentPlan.amount).toFixed(2))}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPaymentPlan.remark}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.financialTransaction)} span={1}>
						{selectedPaymentPlan.financialRequest.id}
					</Descriptions.Item>
					{isDisplayDate && (
						<Descriptions.Item labelStyle={{ alignItems: "center" }} label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
							<AntDatePicker
								allowClear={false}
								size={"middle"}
								format={"DD-MM-YYYY"}
								defaultValues={[moment(this.props.PS.generalLedgerDateTime)]}
								onChange={this.onGeneralLedgerDateTime}
							/>
						</Descriptions.Item>
					)}
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	selectRow(plan, index) {
		if (this.props.PS.selectedCompletePaymentPlan.paymentPlan && this.props.PS.selectedCompletePaymentPlan.index === index && !this.isActionClicked) {
			this.props.setSelected(SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN, -1, null)
		} else {
			this.props.setSelected(SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN, index, this.props.PS.completePaymentPlanTable.filtered[index])
			this.isActionClicked = false
		}
	}

	onViewAttachment(e, row, rIdx, cIdx) {
		this.isActionClicked = true
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.downloadFile,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				path: this.props.PS.completePaymentPlanTable.filtered[rIdx].financialRequest.filePath,
				name: this.props.PS.completePaymentPlanTable.filtered[rIdx].financialRequest.fileName,
			},
		}
		let callback = (res) => {
			if (res.data.encodedFile) {
				let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
				myWindow.data = PrintableDataFactory.generatePdfFileData(res.data.encodedFile)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onInspector(e, row, rIdx, cIdx) {
		this.displayWarning(
			this.props.PS.completePaymentPlanTable.filtered[rIdx],
			ResourceAssistance.ID.ACCOUNTING.paymentSlip.inspectPaymentPlan,
			translate(ResourceAssistance.Message.inspector)
		)
	}

	onReject(e, row, rIdx, cIdx) {
		this.displayWarning(
			this.props.PS.completePaymentPlanTable.filtered[rIdx],
			ResourceAssistance.ID.ACCOUNTING.paymentSlip.rejectPaymentPlan,
			translate(ResourceAssistance.Message.reject)
		)
	}

	onApprover(e, row, rIdx, cIdx) {
		this.displayWarning(
			this.props.PS.completePaymentPlanTable.filtered[rIdx],
			ResourceAssistance.ID.ACCOUNTING.paymentSlip.approvePaymentPlan,
			translate(ResourceAssistance.Message.approver),
			true
		)
	}

	onGeneralLedgerDateTime(value) {
		this.props.setValue(SET_PS_GL_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onPrintJV(e, row, rIdx, cIdx) {
		let selectedCompletePaymentPlan = this.props.PS.completePaymentPlanTable.filtered[rIdx]
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.journalVoucher, "_blank")
		myWindow.data = PaymentSlipPrintDataFactory.generateFinancePaymentJournalVoucherData(
			this.props.PS.location,
			this.props.PS.selectedSupplier.supplier,
			selectedCompletePaymentPlan,
			selectedCompletePaymentPlan.paymentSlipGLs.filter((GL) => Number(GL.workflow) === ResourceAssistance.Enum.financePayment)
		)
	}
	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlineInfo,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineRed,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineInfo,
						]}
						btnMessages={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.financialTransaction }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.inspector }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.approver }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.journalVoucher }),
						]}
						btnIcons={[
							<ImAttachment size={ResourceAssistance.ReactIcon.size} />,
							this.isDisplayInspector() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayReject() ? <TiArrowBack size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayApprover() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onViewAttachment, this.onInspector, this.onReject, this.onApprover, this.onPrintJV]}
						data={this.props.PS.completePaymentPlanTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.PS.selectedCompletePaymentPlan.index === -1}
						highlightedRow={this.props.PS.selectedCompletePaymentPlan.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPSCompletePaymentPlans,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
			onModalDisplayAction,
			setPSRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipRightPaymentPlanTabComplete))
