import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PharmacyRightOrderTab from "./PharmacyRightOrderTab"
import PharmacyRightPharmacyTab from "./PharmacyRightPharmacyTab"

class PharmacyRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRight",
		}

		this.orderTabRef = React.createRef()
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<PharmacyRightOrderTab ref={this.orderTabRef} onRenderParentCallback={this.onRenderParentCallback} />
				<PharmacyRightPharmacyTab
					isDoctorOrder={this.orderTabRef.current ? this.orderTabRef.current.state.isDoctorOrder : false}
				/>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PharmacyRight)
