import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue } from "~/redux/action"
import { SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OperatingRoomModalEditPreDetails from "./OperatingRoomModalEditPreDetails"
import _ from "lodash"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"

class OperatingRoomModalEditPre extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.operatingRoom.selectedRequest.request) {
			return true
		}
		let anesthesiaPreOP = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
			? undefined
			: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
		let target = Object.assign({}, anesthesiaPreOP, {
			lastMenstrualPeriod: Utils.trim(this.props.operatingRoom.modalEditAnPre.LMP),
			pregnant: Utils.trim(this.props.operatingRoom.modalEditAnPre.pregnantDetail),
			smoking: Utils.trim(this.props.operatingRoom.modalEditAnPre.smokingDetail),
			medicationUsage: Utils.trim(this.props.operatingRoom.modalEditAnPre.medicationUsageDetail),
			generalAppearance: this.props.operatingRoom.modalEditAnPre.generalAppearanceDetail.join(","),
			generalAppearanceOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.generalAppearanceOther),
			asaClass: `${this.props.operatingRoom.modalEditAnPre.ASAClass},${this.props.operatingRoom.modalEditAnPre.isASAClassEmergency}`,
			airwayDentures: this.props.operatingRoom.modalEditAnPre.isAirwayDentures,
			airwayDentalDamage: this.props.operatingRoom.modalEditAnPre.isAirwayDentalDamage,
			airwayThyromental: this.props.operatingRoom.modalEditAnPre.isAirwayThyromental,
			airwayMouthOpen: this.props.operatingRoom.modalEditAnPre.isAirwayMouthOpen,
			airwayHyomentalDistance: this.props.operatingRoom.modalEditAnPre.isAirwayHyomentalDistance,
			airwayNeck: Utils.trim(this.props.operatingRoom.modalEditAnPre.airwayNeckDetail),
			airwayMallampatiScore: this.props.operatingRoom.modalEditAnPre.airwayMallampatiScore,
			airwayExpectedDifficult: this.props.operatingRoom.modalEditAnPre.airwayExpectedDifficultDetail.join(","),
			neurologicalAssessment: this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail.join(","),
			neurologicalAssessmentOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentOther),
			backExamination: this.props.operatingRoom.modalEditAnPre.backExaminationDetail.join(","),
			backExaminationOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.backExaminationOther),
			cardiovascular: this.props.operatingRoom.modalEditAnPre.cardiovascularDetail.join(","),
			cardiovascularHypertension: this.props.operatingRoom.modalEditAnPre.isCardiovascularHypertension,
			cardiovascularFunctionalCapacity: Utils.trim(this.props.operatingRoom.modalEditAnPre.cardiovascularFunctionalCapacity),
			cardiovascularOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.cardiovascularOther),
			respiratory: this.props.operatingRoom.modalEditAnPre.respiratoryDetail.join(","),
			respiratoryOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.respiratoryOther),
			gastrointestinal: this.props.operatingRoom.modalEditAnPre.gastrointestinalDetail.join(","),
			gastrointestinalOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.gastrointestinalOther),
			endocrine: this.props.operatingRoom.modalEditAnPre.endocrineDetail.join(","),
			endocrineDM: Utils.trim(this.props.operatingRoom.modalEditAnPre.endocrineDMType),
			endocrineOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.endocrineOther),
			hematoOncology: this.props.operatingRoom.modalEditAnPre.hematoOncologyDetail.join(","),
			hematoOncologyOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.hematoOncologyOther),
			renal: this.props.operatingRoom.modalEditAnPre.renalDetail.join(","),
			renalInsufficiency: Utils.trim(this.props.operatingRoom.modalEditAnPre.renalInsufficiency),
			renalOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.renalOther),
			completeBloodCount: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.cbcWBC)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.cbcHgb
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.cbcHct)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.cbcPlatelets)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.cbcDate
			)}`,
			electrolytes: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesNa)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.electrolytesCl
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesK)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.electrolytesHCO3
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesBun)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.electrolytesCr
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesGlucose)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesDate)}`,
			liverFunctionTest: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionAlbumin)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.liverFunctionGlobulin
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionAST)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.liverFunctionALT
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionTotalBilirubin)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.liverFunctionDirectBilirubin
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionAlkalinePhosphatase)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.liverFunctionOther
			)}`,
			coagulation: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.coagulationPT)},${Utils.trim(
				this.props.operatingRoom.modalEditAnPre.coagulationPTT
			)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.coagulationINR)}`,
			ekg: Utils.trim(this.props.operatingRoom.modalEditAnPre.EKGDetail),
			cxr: Utils.trim(this.props.operatingRoom.modalEditAnPre.CXRDetail),
			otherInvestigation: Utils.trim(this.props.operatingRoom.modalEditAnPre.otherInvestigation),
			antiHIV: this.props.operatingRoom.modalEditAnPre.isAntiHIV,
			crossMatching: this.props.operatingRoom.modalEditAnPre.crossMatchingDetail.join(","),
			crossMatchingPackedRedCell: Utils.trim(this.props.operatingRoom.modalEditAnPre.crossMatchingRedCell),
			crossMatchingFreshFrozenPlasma: Utils.trim(this.props.operatingRoom.modalEditAnPre.crossMatchingFreshFrozenPlasma),
			crossMatchingPlateletConcentrate: Utils.trim(this.props.operatingRoom.modalEditAnPre.crossMatchingPlateletConcentrate),
			anesthesiaPlan: this.props.operatingRoom.modalEditAnPre.anesthesiaPlanDetail.join(","),
			anesthesiaPlanRegional: Utils.trim(this.props.operatingRoom.modalEditAnPre.anesthesiaPlanRegional),
			anesthesiaPlanPreMedication: Utils.trim(this.props.operatingRoom.modalEditAnPre.anesthesiaPlanPreMedication),
		})
		return _.isEqual(anesthesiaPreOP, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveAnesthesiaPreOperatives,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				clinicalRecordId: this.props.operatingRoom.selectedRequest.request.clinicalRecord.id,
				userId: this.props.login.user.id,
				anesthesiaPreOperatives: [
					{
						id: _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
							? 0
							: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0].id,
						lastMenstrualPeriod: Utils.trim(this.props.operatingRoom.modalEditAnPre.LMP),
						pregnant: Utils.trim(this.props.operatingRoom.modalEditAnPre.pregnantDetail),
						smoking: Utils.trim(this.props.operatingRoom.modalEditAnPre.smokingDetail),
						medicationUsage: Utils.trim(this.props.operatingRoom.modalEditAnPre.medicationUsageDetail),
						generalAppearance: this.props.operatingRoom.modalEditAnPre.generalAppearanceDetail.join(","),
						generalAppearanceOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.generalAppearanceOther),
						asaClass: `${this.props.operatingRoom.modalEditAnPre.ASAClass},${this.props.operatingRoom.modalEditAnPre.isASAClassEmergency}`,
						airwayDentures: this.props.operatingRoom.modalEditAnPre.isAirwayDentures,
						airwayDentalDamage: this.props.operatingRoom.modalEditAnPre.isAirwayDentalDamage,
						airwayThyromental: this.props.operatingRoom.modalEditAnPre.isAirwayThyromental,
						airwayMouthOpen: this.props.operatingRoom.modalEditAnPre.isAirwayMouthOpen,
						airwayHyomentalDistance: this.props.operatingRoom.modalEditAnPre.isAirwayHyomentalDistance,
						airwayNeck: Utils.trim(this.props.operatingRoom.modalEditAnPre.airwayNeckDetail),
						airwayMallampatiScore: this.props.operatingRoom.modalEditAnPre.airwayMallampatiScore,
						airwayExpectedDifficult: this.props.operatingRoom.modalEditAnPre.airwayExpectedDifficultDetail.join(","),
						neurologicalAssessment: this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail.join(","),
						neurologicalAssessmentOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentOther),
						backExamination: this.props.operatingRoom.modalEditAnPre.backExaminationDetail.join(","),
						backExaminationOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.backExaminationOther),
						cardiovascular: this.props.operatingRoom.modalEditAnPre.cardiovascularDetail.join(","),
						cardiovascularHypertension: this.props.operatingRoom.modalEditAnPre.isCardiovascularHypertension,
						cardiovascularFunctionalCapacity: Utils.trim(this.props.operatingRoom.modalEditAnPre.cardiovascularFunctionalCapacity),
						cardiovascularOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.cardiovascularOther),
						respiratory: this.props.operatingRoom.modalEditAnPre.respiratoryDetail.join(","),
						respiratoryOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.respiratoryOther),
						gastrointestinal: this.props.operatingRoom.modalEditAnPre.gastrointestinalDetail.join(","),
						gastrointestinalOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.gastrointestinalOther),
						endocrine: this.props.operatingRoom.modalEditAnPre.endocrineDetail.join(","),
						endocrineDMType: Utils.trim(this.props.operatingRoom.modalEditAnPre.endocrineDMType),
						endocrineOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.endocrineOther),
						hematoOncology: this.props.operatingRoom.modalEditAnPre.hematoOncologyDetail.join(","),
						hematoOncologyOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.hematoOncologyOther),
						renal: this.props.operatingRoom.modalEditAnPre.renalDetail.join(","),
						renalInsufficiency: Utils.trim(this.props.operatingRoom.modalEditAnPre.renalInsufficiency),
						renalOther: Utils.trim(this.props.operatingRoom.modalEditAnPre.renalOther),
						completeBloodCount: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.cbcWBC)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.cbcHgb
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.cbcHct)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.cbcPlatelets)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.cbcDate
						)}`,
						electrolytes: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesNa)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.electrolytesCl
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesK)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.electrolytesHCO3
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesBun)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.electrolytesCr
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.electrolytesGlucose)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.electrolytesDate
						)}`,
						liverFunctionTest: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionAlbumin)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.liverFunctionGlobulin
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionAST)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.liverFunctionALT
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionTotalBilirubin)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.liverFunctionDirectBilirubin
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.liverFunctionAlkalinePhosphatase)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.liverFunctionOther
						)}`,
						coagulation: `${Utils.trim(this.props.operatingRoom.modalEditAnPre.coagulationPT)},${Utils.trim(
							this.props.operatingRoom.modalEditAnPre.coagulationPTT
						)},${Utils.trim(this.props.operatingRoom.modalEditAnPre.coagulationINR)}`,
						ekg: Utils.trim(this.props.operatingRoom.modalEditAnPre.EKGDetail),
						cxr: Utils.trim(this.props.operatingRoom.modalEditAnPre.CXRDetail),
						otherInvestigation: Utils.trim(this.props.operatingRoom.modalEditAnPre.otherInvestigation),
						antiHIV: this.props.operatingRoom.modalEditAnPre.isAntiHIV,
						crossMatching: this.props.operatingRoom.modalEditAnPre.crossMatchingDetail.join(","),
						crossMatchingPackedRedCell: Utils.trim(this.props.operatingRoom.modalEditAnPre.crossMatchingRedCell),
						crossMatchingFreshFrozenPlasma: Utils.trim(this.props.operatingRoom.modalEditAnPre.crossMatchingFreshFrozenPlasma),
						crossMatchingPlateletConcentrate: Utils.trim(this.props.operatingRoom.modalEditAnPre.crossMatchingPlateletConcentrate),
						anesthesiaPlan: this.props.operatingRoom.modalEditAnPre.anesthesiaPlanDetail.join(","),
						anesthesiaPlanRegional: Utils.trim(this.props.operatingRoom.modalEditAnPre.anesthesiaPlanRegional),
						anesthesiaPlanPreMedication: Utils.trim(this.props.operatingRoom.modalEditAnPre.anesthesiaPlanPreMedication),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullWidth}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.preAnesthesiaRecord)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalEditPreDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomEditAnesthesiologistPreDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalEditPre)
