import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaUserInjured } from "react-icons/fa"
import { RiUserAddFill, RiUserSearchFill } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setValue } from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT,
	SET_HOSPITEL_HOSPITEL_NEW_DISPLAY,
	SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY,
	SET_HOSPITEL_SELECTED_PAITENT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelLeftActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onPatientClinicalRecord = this.onPatientClinicalRecord.bind(this)
		this.onSearchPatient = this.onSearchPatient.bind(this)
		this.onNew = this.onNew.bind(this)
	}

	onPatientClinicalRecord(event) {
		let patient = {
			...this.props.hospitel.selectedPatient.patient,
			clinicalRecord: {
				...this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord,
				...this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.medicalRecords.find(
					(medicalRecord) => !_.isEmpty(medicalRecord.admissionPlan)
				),
			},
		}
		this.props.setValue(SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT, patient)
		this.props.onModalDisplayAction(SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY, true)
	}

	onSearchPatient(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY, true)
	}

	onNew(event) {
		this.props.setSelected(SET_HOSPITEL_SELECTED_PAITENT, -1, null)
		this.props.onModalDisplayAction(SET_HOSPITEL_HOSPITEL_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.info}
						disabled={
							this.props.hospitel.selectedPatient.index === -1 || this.props.hospitel.selectedHospitel.index === -1
						}
						onClick={this.onPatientClinicalRecord}
					>
						<FaUserInjured size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.clinicalRecord)}
					</Button>
				</Col>
				<Col />
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.info}
						onClick={this.onSearchPatient}
					>
						<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Register, this.props.login.user.roles) && (
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							onClick={this.onNew}
						>
							<RiUserAddFill size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.new)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelLeftActionRow))
