import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onModalDisplayAction, setSelectedType, setValue } from "~/redux/action"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import AbstractTypeView from "../AbstractTypeView"
import AdmCategoryServiceCOA from "./AdmCategoryServiceCOA"
import { SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM } from "~/redux/type"

class ServiceType extends React.Component {
	constructor(props) {
		super(props)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
	}

	onEditBtnClick(event) {
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM, true)
	}

	onAddBtnClick(event) {
		this.props.setSelectedType(-1, null)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM, true)
	}

	render() {
		return (
			<>
				<AbstractTypeView
					scrollableTableData={this.props.type.types}
					onAddBtnClick={this.onAddBtnClick}
					onEditBtnClick={this.onEditBtnClick}
					onRefreshClick={this.props.onRefreshClick}
				/>
				<AdmCategoryServiceCOA />
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setSelectedType, setValue }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ServiceType)
