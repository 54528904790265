import { Badge } from "antd"
import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OutpatientRightDoctorOrder from "./OutpatientRightDoctorOrder"
import OutpatientRightMedicalCertificate from "./OutpatientRightMedicalCertificate"
import OutpatientRightNurseOrder from "./OutpatientRightNurseOrder"
import OutpatientRightOrderTabOR from "./OutpatientRightOrderTabOR"

class OutpatientRightOrderTab extends React.Component {
	EOrder = {
		DOCTOR: "OPD_DOCTOR_ORDER",
		NURSE: "OPD_NURSE_ORDER",
		CERTIFICATE: "OPD_MEDICAL_CERTIFICATE",
		OR: "OPD_OR",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EOrder.DOCTOR,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Row className="full-size">
				<Col>
					<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
						<Nav variant="tabs" justify>
							<Nav.Item>
								<Nav.Link eventKey={this.EOrder.DOCTOR}>{translate(ResourceAssistance.Message.doctor)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EOrder.NURSE}>{translate(ResourceAssistance.Message.nurse)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Badge
									dot={
										this.props.opd.selectedMedicalRecord.medicalRecord &&
										this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.operatingRoomRequests.some((request) =>
											[ResourceAssistance.Enum.pending].includes(request.status)
										)
									}
								>
									<Nav.Link eventKey={this.EOrder.OR}>{translate(ResourceAssistance.Message.operatingRoom)}</Nav.Link>
								</Badge>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EOrder.CERTIFICATE}>{translate(ResourceAssistance.Message.medicalCertificate)}</Nav.Link>
							</Nav.Item>
						</Nav>
						<Tab.Content>
							<Tab.Pane eventKey={this.EOrder.DOCTOR}>{this.state.tab === this.EOrder.DOCTOR && <OutpatientRightDoctorOrder {...this.state} />}</Tab.Pane>
							<Tab.Pane eventKey={this.EOrder.NURSE}>{this.state.tab === this.EOrder.NURSE && <OutpatientRightNurseOrder {...this.state} />}</Tab.Pane>
							<Tab.Pane eventKey={this.EOrder.OR}>{this.state.tab === this.EOrder.OR && <OutpatientRightOrderTabOR {...this.state} />}</Tab.Pane>
							<Tab.Pane eventKey={this.EOrder.CERTIFICATE}>
								{this.state.tab === this.EOrder.CERTIFICATE && <OutpatientRightMedicalCertificate {...this.state} />}
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientRightOrderTab)
