import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_ADMIN_TYPE_TITLE,
	SET_LOCATIONS,
	SET_LOCATION_DISPLAY_INACTIVE,
	SET_LOC_SEARCH_TEXT,
	SET_SELECTED_LOC,
	SET_SELECTED_TYPE,
	SET_TYPE_URL,
} from "~/redux/type"
export * from "./chartOfAccountsActions"
export * from "./customDefaultActions"
export * from "./formActions"
export * from "./generalLedgerActions"
export * from "./icdActions"
export * from "./itemActions"
export * from "./itemSourceActions"
export * from "./laboratoryActions"
export * from "./locationActions"
export * from "./permissionActions"
export * from "./roleActions"
export * from "./serviceActions"
export * from "./supplierActions"
export * from "./taxActions"
export * from "./typeActions"
export * from "./unitActions"
export * from "./userActions"
export * from "./eclaimActions/eclaimActions"

const ORG = "ORG"
const BRANCH = "BRANCH"
const INVENTORY = "INVENTORY"
const GENERAL_LOCATION = "GENERAL_LOCATION"
const IPD = "IPD"
const OPD = "OPD"
const PHARMACY = "PHARMACY"
const OPERATING_ROOM = "OPERATING_ROOM"

export const setSelectedType = (index, type) => {
	return {
		type: SET_SELECTED_TYPE,
		payload: {
			type: type,
			index: index,
		},
	}
}

export const setTypeUrl = (url) => {
	return {
		type: SET_TYPE_URL,
		payload: {
			url: url,
		},
	}
}

export const setLocation = (locations) => {
	return (dispatch, getState) => {
		let filtered = locations.filter((location) => location.displayName.toLowerCase().includes(getState().admin.location.searchText.toLowerCase()))

		let filteredAry = []
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.org)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === ORG))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.branch)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === BRANCH))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.inventory)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === INVENTORY))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.location)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === GENERAL_LOCATION))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.opd)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === OPD))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.ipd)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === IPD))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.pharmacy)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === PHARMACY))
		}
		if (_.includes(getState().admin.location.filters, ResourceAssistance.Message.operatingRoom)) {
			filteredAry = filteredAry.concat(filtered.filter((location) => location.code.displayName === OPERATING_ROOM))
		}

		filteredAry = _.isEmpty(filteredAry) ? filtered : filteredAry

		if (!getState().admin.location.displayInactive) {
			filteredAry = filteredAry.filter((location) => location.active)
		}

		let header = [
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.nameEng,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.hospitalCode,
			ResourceAssistance.Message.address,
			ResourceAssistance.Message.addressEng,
			ResourceAssistance.Message.billingAddress,
			ResourceAssistance.Message.telNo,
			ResourceAssistance.Message.faxNo,
			ResourceAssistance.Message.type,
			ResourceAssistance.Message.parentLoc,
			ResourceAssistance.Message.by,
		]

		let colors = []
		if (getState().admin.location.displayInactive) {
			colors = filteredAry.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filteredAry.map((location) => {
			return [
				location.displayName,
				location.displayNameEng,
				location.description,
				location.taxNo,
				location.hospitalCode,
				location.address ? location.address.displayName : "",
				location.address ? location.address.displayNameEng : "",
				location.address ? location.address.billingAddress : "",
				location.address ? location.address.tel : "",
				location.address ? location.address.fax : "",
				location.code.displayName,
				location.parent ? location.parent.displayName : "",
				location.createdBy.nickname,
			]
		})

		dispatch({
			type: SET_LOCATIONS,
			payload: {
				locations: locations,
				filtered: filteredAry,
				header: header,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setLocationDisplayInactive = (isDisplay) => {
	return {
		type: SET_LOCATION_DISPLAY_INACTIVE,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setSelectedLoc = (index, loc) => {
	return {
		type: SET_SELECTED_LOC,
		payload: {
			loc: loc,
			index: index,
		},
	}
}

export const setLocSearchText = (text) => {
	return {
		type: SET_LOC_SEARCH_TEXT,
		payload: {
			searchText: text,
		},
	}
}

export const setLocationType = (type, value) => {
	return {
		type: type,
		payload: {
			value: value,
		},
	}
}

export const setAdminTypeTitile = (title) => {
	return {
		type: SET_ADMIN_TYPE_TITLE,
		payload: {
			title: title,
		},
	}
}

export const setDisplay = (type, isDisplay) => {
	return {
		type: type,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setDisplayInactive = (type, isDisplay) => {
	return {
		type: type,
		payload: {
			isDisplay: isDisplay,
		},
	}
}
