import { Button, Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSurgicalAuthOperatingRoomRequests, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_SA_BUDGET_DISPLAY,
	SET_SA_COST_DISPLAY,
	SET_SA_INSPECT_DISPLAY,
	SET_SA_REJECT_DISPLAY,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class SurgicalAuthRightInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onBudget = this.onBudget.bind(this)
		this.onCost = this.onCost.bind(this)
		this.onInspect = this.onInspect.bind(this)
		this.onApprove = this.onApprove.bind(this)
		this.onReject = this.onReject.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.surgicalAuthorization.inspectOperatingRoomRequest)) {
					if (
						this.getBudgetCompareToCostPercent().gt(this.props.surgicalAuth.workflowProperty.minCost) &&
						Utils.BigNumber(this.props.surgicalAuth.workflowProperty.minCost).isGreaterThan(0)
					) {
						this.updateOperatingRoomRequest(ResourceAssistance.Url.surgicalAuth.approveOperatingRoomRequest)
					} else {
						this.props.onModalDisplayAction(SET_SA_INSPECT_DISPLAY, true)
					}
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.surgicalAuthorization.approveOperatingRoomRequest)) {
					this.updateOperatingRoomRequest(ResourceAssistance.Url.surgicalAuth.approveOperatingRoomRequest)
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.surgicalAuthorization.rejectOperatingRoomRequest)) {
					this.props.onModalDisplayAction(SET_SA_REJECT_DISPLAY, true)
				}
			}
		}
	}

	getTotalBudget() {
		let ary = this.props.surgicalAuth.selectedORRequest.request.otherBudgets.split(ResourceAssistance.PROGRAM_DEFINED.split).filter((each) => Boolean(each))
		let totalBudget = Utils.BigNumber(0)
		for (let i = 0; i < ary.length; i += 3) {
			totalBudget = totalBudget.plus(ary[i + 2])
		}
		return Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.diagnosisRelatedGroup)
			.times(this.props.surgicalAuth.selectedORRequest.request.diagnosisRelatedGroupPrice)
			.plus(totalBudget)
	}

	getTotalCost() {
		let ary = this.props.surgicalAuth.selectedORRequest.request.otherCosts.split(ResourceAssistance.PROGRAM_DEFINED.split).filter((each) => Boolean(each))
		let totalCost = Utils.BigNumber(0)
		for (let i = 0; i < ary.length; i += 6) {
			totalCost = totalCost.plus(ary[i + 5])
		}
		return totalCost
	}

	getBudgetCompareToCostPercent() {
		if (!this.props.surgicalAuth.selectedORRequest.request) {
			return Utils.BigNumber(0)
		}
		let budget = this.getTotalBudget()
		let cost = this.getTotalCost()
		let total = budget.minus(cost).dividedBy(budget).times(100)
		return total.isNaN() ? Utils.BigNumber(0) : total
	}

	updateOperatingRoomRequest(url) {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.surgicalAuth.selectedORRequest.request.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.surgicalAuth.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setSurgicalAuthOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	displayWarning(id, msg) {
		let selectedORRequest = this.props.surgicalAuth.selectedORRequest.request
		this.props.setWarningId(id)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={msg}
				bordered={false}
				hoverable
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
						{`${Utils.formatDateTime(selectedORRequest.operatingRoomSchedules[0].startDateTime)} - ${Utils.formatDateTime(
							selectedORRequest.operatingRoomSchedules[0].endDateTime
						)}`}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.orCase)} span={1}>
						{selectedORRequest.operatingRoomCase}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onBudget() {
		this.props.onModalDisplayAction(SET_SA_BUDGET_DISPLAY, true)
	}

	onCost() {
		this.props.onModalDisplayAction(SET_SA_COST_DISPLAY, true)
	}

	onInspect() {
		this.displayWarning(ResourceAssistance.ID.HOSPITAL.surgicalAuthorization.inspectOperatingRoomRequest, translate(ResourceAssistance.Message.inspector))
	}

	onApprove() {
		this.displayWarning(ResourceAssistance.ID.HOSPITAL.surgicalAuthorization.approveOperatingRoomRequest, translate(ResourceAssistance.Message.approver))
	}

	onReject() {
		this.displayWarning(ResourceAssistance.ID.HOSPITAL.surgicalAuthorization.rejectOperatingRoomRequest, translate(ResourceAssistance.Message.reject))
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"default"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={4}
							extra={
								this.props.surgicalAuth.selectedORRequest.request &&
								!this.props.surgicalAuth.selectedORRequest.request.approver && (
									<Row>
										{!this.props.surgicalAuth.selectedORRequest.request.inspector && (
											<>
												<Col md="auto">
													<Button
														type="primary"
														size="middle"
														shape="round"
														icon={<FaArrowDown size={ResourceAssistance.ReactIcon.size} />}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.totalEstimatedBudget })}
														disabled={!this.props.surgicalAuth.workflowProperty}
														onClick={this.onBudget}
													/>
												</Col>
												<Col md="auto">
													<Button
														type="primary"
														size="middle"
														shape="round"
														icon={<FaArrowUp size={ResourceAssistance.ReactIcon.size} />}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.totalEstimationCost })}
														disabled={!this.props.surgicalAuth.workflowProperty}
														onClick={this.onCost}
													/>
												</Col>
												<Col md="auto">
													<Button
														style={{
															background: ResourceAssistance.CSS.Color.dark_green,
														}}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.inspector })}
														type="primary"
														size="middle"
														shape="round"
														icon={<AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} />}
														disabled={!this.props.surgicalAuth.workflowProperty}
														onClick={this.onInspect}
													/>
												</Col>
											</>
										)}
										{this.props.surgicalAuth.selectedORRequest.request.inspector && !this.props.surgicalAuth.selectedORRequest.request.approver && (
											<Col md="auto">
												<Button
													style={{
														background: ResourceAssistance.CSS.Color.dark_green,
													}}
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.approver })}
													type="primary"
													size="middle"
													shape="round"
													icon={<AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} />}
													disabled={this.props.surgicalAuth.selectedORRequest.request.approver || !this.props.surgicalAuth.workflowProperty}
													onClick={this.onApprove}
												/>
											</Col>
										)}
										<Col md="auto">
											<Button
												title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject })}
												type="primary"
												size="middle"
												shape="round"
												danger
												icon={<TiArrowBack size={ResourceAssistance.ReactIcon.size} />}
												disabled={!this.props.surgicalAuth.workflowProperty}
												onClick={this.onReject}
											/>
										</Col>
									</Row>
								)
							}
						/>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<Descriptions style={{ flex: "1", display: "unset" }} size={"default"} contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }} column={4}>
							<Descriptions.Item contentStyle={{ fontSize: 20, fontStyle: "bold" }} span={4}>
								{this.props.surgicalAuth.selectedORRequest.request
									? this.props.surgicalAuth.selectedORRequest.request.clinicalRecord.admission.patient.displayName
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.startDateTime)}>
								{this.props.surgicalAuth.selectedORRequest.request && this.props.surgicalAuth.selectedORRequest.request.operatingRoomSchedules[0]
									? Utils.formatDateTime(this.props.surgicalAuth.selectedORRequest.request.operatingRoomSchedules[0].startDateTime)
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.endDateTime)}>
								{this.props.surgicalAuth.selectedORRequest.request && this.props.surgicalAuth.selectedORRequest.request.operatingRoomSchedules[0]
									? Utils.formatDateTime(this.props.surgicalAuth.selectedORRequest.request.operatingRoomSchedules[0].endDateTime)
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={4} label={translate(ResourceAssistance.Message.operativeDoctor)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.operativeDoctor : ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.preOperativeDiagICD)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.preDiagICD : ""}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.preOperativeDiag)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.preOperativeDiag : ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.operationProcedureICD)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.operativeICD : ""}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.operationProcedure)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.operationProcedure : ""}
							</Descriptions.Item>
							<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.anesthesiologist)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.anesthesiologist : ""}
							</Descriptions.Item>
							<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.anesthesia)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.anesthesiaType : ""}
							</Descriptions.Item>
							<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.estimatedLengthOfStay)}>
								{this.props.surgicalAuth.selectedORRequest.request ? this.props.surgicalAuth.selectedORRequest.request.lengthOfStay : ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.doctorFee)}>
								{this.props.surgicalAuth.selectedORRequest.request
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.doctorFee).toFixed(2))
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.anesthesiologistFee)}>
								{this.props.surgicalAuth.selectedORRequest.request
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.anesthesiologistFee).toFixed(2))
									: ""}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset", maxHeight: "100px", overflowY: "auto" }}
							size={"default"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={4}
						>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.DRG)}>
								{this.props.surgicalAuth.selectedORRequest.request
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.diagnosisRelatedGroup).toFixed(2))
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.pricePerDRG)}>
								{this.props.surgicalAuth.selectedORRequest.request
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.diagnosisRelatedGroupPrice).toFixed(2))
									: ""}
							</Descriptions.Item>
							{this.getOtherBudgets()}
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset", maxHeight: "100px", overflowY: "auto" }}
							size={"default"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={2}
						>
							{this.getOtherCosts()}
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"default"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={2}
						>
							{this.props.surgicalAuth.workflowProperty && (
								<Descriptions.Item
									span={2}
									label={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.min })}(${this.props.surgicalAuth.workflowProperty.minCost}%)`}
									contentStyle={{
										color: this.getBudgetCompareToCostPercent().gt(this.props.surgicalAuth.workflowProperty.minCost)
											? ResourceAssistance.CSS.Color.dark_green
											: ResourceAssistance.CSS.Color.red,
									}}
								>
									{this.props.surgicalAuth.selectedORRequest.request ? `${this.getBudgetCompareToCostPercent().toFixed(2)}%` : ""}
								</Descriptions.Item>
							)}
							<Descriptions.Item
								span={1}
								label={translate(ResourceAssistance.Message.totalEstimatedBudget)}
								labelStyle={{ fontSize: 20 }}
								contentStyle={{ color: ResourceAssistance.CSS.Color.dark_green, fontSize: 20 }}
							>
								{this.props.surgicalAuth.selectedORRequest.request ? Utils.formatNumWithComma(this.getTotalBudget().toFixed(2)) : ""}
							</Descriptions.Item>
							<Descriptions.Item
								span={1}
								label={translate(ResourceAssistance.Message.totalEstimationCost)}
								labelStyle={{ fontSize: 20 }}
								contentStyle={{ color: ResourceAssistance.CSS.Color.red, fontSize: 20 }}
							>
								{this.props.surgicalAuth.selectedORRequest.request ? Utils.formatNumWithComma(this.getTotalCost().toFixed(2)) : ""}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"default"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={3}
						>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.inspectedBy)}>
								{this.props.surgicalAuth.selectedORRequest.request && this.props.surgicalAuth.selectedORRequest.request.inspector
									? this.props.surgicalAuth.selectedORRequest.request.inspector.displayName
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.remark)}>
								{this.props.surgicalAuth.selectedORRequest.request && this.props.surgicalAuth.selectedORRequest.request.inspector
									? this.props.surgicalAuth.selectedORRequest.request.inspectorRemark
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.approvedBy)}>
								{this.props.surgicalAuth.selectedORRequest.request && this.props.surgicalAuth.selectedORRequest.request.approver
									? this.props.surgicalAuth.selectedORRequest.request.approver.displayName
									: ""}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Fragment>
		)
	}

	getOtherBudgets() {
		if (!this.props.surgicalAuth.selectedORRequest.request) {
			return
		}
		let ary = this.props.surgicalAuth.selectedORRequest.request.otherBudgets.split(ResourceAssistance.PROGRAM_DEFINED.split).filter((each) => Boolean(each))
		let budgets = []
		for (let i = 0; i < ary.length; i += 3) {
			budgets.push(
				<Descriptions.Item key={i} span={1} label={`${ary[i]}(${ary[i + 1]})`}>
					{Utils.formatNumWithComma(Utils.BigNumber(ary[i + 2]).toFixed(2))}
				</Descriptions.Item>
			)
		}
		return budgets
	}

	getOtherCosts() {
		if (!this.props.surgicalAuth.selectedORRequest.request) {
			return
		}
		let ary = this.props.surgicalAuth.selectedORRequest.request.otherCosts.split(ResourceAssistance.PROGRAM_DEFINED.split).filter((each) => Boolean(each))
		let costs = []
		for (let i = 0; i < ary.length; i += 6) {
			costs.push(
				ary[i + 1] === "true" ? (
					<Descriptions.Item key={i} span={1} label={`${ary[i + 2]}(${ary[i + 4]} ${ary[i + 3]})`}>
						{Utils.formatNumWithComma(Utils.BigNumber(ary[i + 5]).toFixed(2))}
					</Descriptions.Item>
				) : (
					<Descriptions.Item key={i} span={1} label={`${ary[i + 2]}`}>
						{Utils.formatNumWithComma(Utils.BigNumber(ary[i + 5]).toFixed(2))}
					</Descriptions.Item>
				)
			)
		}
		return costs
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSurgicalAuthOperatingRoomRequests,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SurgicalAuthRightInfo))
