import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class RegModalExpenseEstFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onType = this.onType.bind(this)
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.types)) {
			this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE, 0, this.props.types[0])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.types, this.props.types)) {
			this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE, 0, this.props.types[0])
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES, "")
	}

	onName(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_SEARCH_DES, Utils.trim(event.target.value))
	}

	onType(event) {
		this.props.setSelected(
			SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_TYPE,
			event.target.value,
			this.props.types[event.target.value]
		)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<Form.Select required size={ResourceAssistance.FormControl.size.sm} onChange={this.onType}>
							{Utils.renderOptions(this.props.types, false)}
						</Form.Select>
					</Col>
					<Col>
						<SearchBox
							num={1}
							lableName=""
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.description]}
							callbacks={[this.onName]}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstFilter))
