import { DiffOutlined } from "@ant-design/icons"
import React, { Fragment } from "react"
import { Item, Menu } from "react-contexify"
import "react-contexify/dist/ReactContexify.css"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_CMENU_SELECTABLE_ACCOUNTS_CODES, SET_SELECTABLE_ACCOUNTS_CODE_DISPLAY } from "~/redux/type"

class ContextMenuAccountsPayable extends React.Component {
	constructor(props) {
		super(props)

		this.onAccountsCode = this.onAccountsCode.bind(this)
	}

	onAccountsCode({ event, props, triggerEvent, data }) {
		this.props.setValue(SET_CMENU_SELECTABLE_ACCOUNTS_CODES, props.accountingCodes)
		this.props.onModalDisplayAction(SET_SELECTABLE_ACCOUNTS_CODE_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Menu id={ResourceAssistance.ContextMenu.accountsCode} theme={"dark"} animation={false}>
					<Item onClick={this.onAccountsCode}>
						<span>
							<DiffOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
						</span>
						<span style={{ marginLeft: "10px" }}>{translate(ResourceAssistance.Message.accountsCode)}</span>
					</Item>
				</Menu>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	contextMenuAP: state.contextMenu.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContextMenuAccountsPayable))
