import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID,
	SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME,
	SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME,
	SET_DEBT_MODAL_SEARCH_MORE_PID,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class DebtModalSearchMoreDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onContractBillingId = this.onContractBillingId.bind(this)
		this.onPID = this.onPID.bind(this)
		this.onFirstName = this.onFirstName.bind(this)
		this.onLastName = this.onLastName.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID, "")
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME, "")
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME, "")
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_PID, "")
	}

	onContractBillingId(e) {
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID, e.target.value)
	}

	onPID(e) {
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_PID, e.target.value)
	}

	onFirstName(e) {
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME, e.target.value)
	}

	onLastName(e) {
		this.props.setValue(SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME, e.target.value)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col />
					<Col md="auto">
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.contractBillingId })}
							pattern="^\d+$"
							disabled={
								!_.isEmpty(this.props.debt.modalSearchMore.firstName) ||
								!_.isEmpty(this.props.debt.modalSearchMore.lastName) ||
								!_.isEmpty(this.props.debt.modalSearchMore.pid)
							}
							onChange={this.onContractBillingId}
							value={this.props.debt.modalSearchMore.contractBillingId}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.patientId })}
							pattern="^\d+$"
							disabled={
								!_.isEmpty(this.props.debt.modalSearchMore.contractBillingId) ||
								!_.isEmpty(this.props.debt.modalSearchMore.firstName) ||
								!_.isEmpty(this.props.debt.modalSearchMore.lastName)
							}
							onChange={this.onPID}
							value={this.props.debt.modalSearchMore.pid}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={5}>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.firstName })}
							disabled={
								!_.isEmpty(this.props.debt.modalSearchMore.contractBillingId) ||
								!_.isEmpty(this.props.debt.modalSearchMore.pid)
							}
							onChange={this.onFirstName}
							value={this.props.debt.modalSearchMore.firstName}
						/>
					</Col>
					<Col md={5}>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastName })}
							disabled={
								!_.isEmpty(this.props.debt.modalSearchMore.contractBillingId) ||
								!_.isEmpty(this.props.debt.modalSearchMore.pid)
							}
							onChange={this.onLastName}
							value={this.props.debt.modalSearchMore.lastName}
						/>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalSearchMoreDetails))
