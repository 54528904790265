import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_FO_MODAL_EDIT_REQUEST_FEE } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class FOModalEditRequestDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onFee = this.onFee.bind(this)
	}

	componentDidMount() {
		if (this.props.FO.selectedFinancialPlan.financialPlan) {
			this.props.setValue(SET_FO_MODAL_EDIT_REQUEST_FEE, this.props.FO.selectedFinancialPlan.financialPlan.fee)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_FO_MODAL_EDIT_REQUEST_FEE, "")
	}

	onFee(e) {
		this.props.setValue(SET_FO_MODAL_EDIT_REQUEST_FEE, e.target.value)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col />
					<Col md={9}>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.fee })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onFee}
							value={this.props.FO.modalEditRequest.fee}
						/>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FOModalEditRequestDetails))
