import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_SA_OPERATING_ROOM_REQUESTS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setSurgicalAuthOperatingRoomRequests = (requests) => {
	return (dispatch, getState) => {
		let tempFiltered = Array.from(requests).sort((a, b) => Utils.sort(a.operatingRoomSchedules[0].startDateTime, b.operatingRoomSchedules[0].startDateTime))
		let filtered = []
		if (_.includes(getState().hospital.surgicalAuthorization.filters, ResourceAssistance.Message.approved)) {
			filtered = filtered.concat(tempFiltered.filter((request) => request.approver))
		}
		if (_.includes(getState().hospital.surgicalAuthorization.filters, ResourceAssistance.Message.unapproved)) {
			filtered = filtered.concat(tempFiltered.filter((request) => !request.approver))
		}
		filtered = _.isEmpty(getState().hospital.surgicalAuthorization.filters) ? tempFiltered : filtered
		let body = filtered.map((request) => {
			return [
				request.operatingRoomSchedules[0] ? Utils.formatDateTime(request.operatingRoomSchedules[0].startDateTime) : "",
				request.operatingRoomSchedules[0] ? Utils.formatDateTime(request.operatingRoomSchedules[0].endDateTime) : "",
				request.operatingRoomCase,
			]
		})

		let rowColor = filtered.map((request) => {
			let color = ""

			if (request.approver) {
				color = ResourceAssistance.CSS.Color.green
			} else if (request.inspector) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		dispatch({
			type: SET_SA_OPERATING_ROOM_REQUESTS,
			payload: {
				original: requests,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
