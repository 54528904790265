import { Divider, TreeSelect } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { CgRowLast } from "react-icons/cg"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdOutlineSubdirectoryArrowRight } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setConfigChartOfAccountsAssetsAccounts,
	setLoadingAction,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_COA_ASSETS,
	SET_ADM_COA_ASSETS_ACCOUNTS_LOADING,
	SET_ADM_COA_ASSETS_ADD_DISPLAY,
	SET_ADM_COA_ASSETS_FILTER_CODE,
	SET_ADM_COA_ASSETS_FILTER_NAME,
	SET_ADM_COA_ASSETS_ORIGINAL,
	SET_ADM_COA_ASSETS_SELECTED_ACCOUNT,
	SET_ADM_COA_ASSETS_SELECTED_ASSETS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigAssets extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigAssets",
		}

		this.onAssets = this.onAssets.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadAccounts(
			ResourceAssistance.Url.admin.chartOfAccounts.getAssets,
			(res) => {
				this.props.setValue(SET_ADM_COA_ASSETS_ORIGINAL, res.data.accounts)
				this.props.setValue(SET_ADM_COA_ASSETS, Utils.getChartOfAccounts(res.data.accounts))
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_ASSETS_ACCOUNTS_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_ASSETS_ACCOUNTS_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_ASSETS_ACCOUNTS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_COA_ASSETS_ORIGINAL, [])
		this.props.setValue(SET_ADM_COA_ASSETS, [])
		this.props.setValue(SET_ADM_COA_ASSETS_FILTER_CODE, "")
		this.props.setValue(SET_ADM_COA_ASSETS_FILTER_NAME, "")
		this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ASSETS, -1, null)
		this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ACCOUNT, -1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.assets.originalAssets, this.props.assets.originalAssets)) {
			this.props.setValue(SET_ADM_COA_ASSETS, Utils.getChartOfAccounts(this.props.assets.originalAssets))
		}
		if (!_.isEqual(prevProps.assets.assets, this.props.assets.assets)) {
			if (this.props.assets.selectedAssets.assets) {
				let selectedAssets = Utils.findChartOfAccounts(
					this.props.assets.assets,
					this.props.assets.selectedAssets.assets
				)
				this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ASSETS, selectedAssets[0], selectedAssets[1])
			} else {
				this.props.setConfigChartOfAccountsAssetsAccounts(this.props.assets.assets)
			}
		}
		if (
			!_.isEqual(prevProps.assets.selectedAssets, this.props.assets.selectedAssets) ||
			prevProps.assets.filterCode !== this.props.assets.filterCode ||
			prevProps.assets.filterName !== this.props.assets.filterName
		) {
			if (this.props.assets.selectedAssets.assets) {
				this.props.setConfigChartOfAccountsAssetsAccounts(this.props.assets.selectedAssets.assets.accounts)
			} else {
				this.props.setConfigChartOfAccountsAssetsAccounts(this.props.assets.assets)
			}
		}
		if (!_.isEqual(prevProps.assets.accountTable.filtered, this.props.assets.accountTable.filtered)) {
			this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ACCOUNT, -1, null)
		}
	}

	onAssets(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ASSETS, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_COA_ASSETS_SELECTED_ASSETS,
				value,
				(function getSelectedAsset(assets, idx) {
					if (idx === keys.length - 1) {
						return assets[keys[idx]]
					}
					return getSelectedAsset(assets[keys[idx]].accounts, idx + 1)
				})(this.props.assets.assets, 0)
			)
		} else {
			this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ASSETS, value, this.props.assets.assets[value])
		}
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADM_COA_ASSETS_FILTER_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_COA_ASSETS_FILTER_NAME, event.target.value)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ACCOUNT, index, this.props.assets.accountTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADM_COA_ASSETS_SELECTED_ACCOUNT, -1, null)
		this.props.onModalDisplayAction(SET_ADM_COA_ASSETS_ADD_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_COA_ASSETS_ADD_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							allowClear
							treeLine={{ showLeafIcon: false }}
							placeholder={translate(ResourceAssistance.Message.assets)}
							loading={this.props.assets.accountTable.isLoading}
							value={this.props.assets.selectedAssets.assets ? this.props.assets.selectedAssets.index : undefined}
							onChange={this.onAssets}
							treeData={Utils.getTreeNodesFromChartOfAccounts(this.props.assets.assets, false)}
						/>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.assets.selectedAccount.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							hover
							striped
							enableHighlight
							displayBtn
							buttonColors={[
								ResourceAssistance.Button.variant.outlineInfo,
								ResourceAssistance.Button.variant.outlineInfo,
							]}
							btnMessages={["", ""]}
							btnTypes={[EBtnType.POPOVER, EBtnType.POPOVER]}
							btnIcons={[
								<MdOutlineSubdirectoryArrowRight size={ResourceAssistance.ReactIcon.size} />,
								<CgRowLast size={ResourceAssistance.ReactIcon.size} />,
							]}
							data={this.props.assets.accountTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.assets.selectedAccount.index === -1}
							highlightedRow={this.props.assets.selectedAccount.index}
						/>
						{this.props.assets.accountTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	assets: state.admin.configCOA.assets,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setConfigChartOfAccountsAssetsAccounts,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigAssets)
