import propTypes from "prop-types"
import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

class LevelType extends React.Component {
	render() {
		return (
			<Row>
				<Col />
				<Col md={8}>
					<FormControl
						required
						size={ResourceAssistance.FormControl.size.sm}
						as={ResourceAssistance.FormControl.as.select}
						onChange={this.props.onType}
					>
						{Utils.renderOptions(this.props.levelTypes, true)}
					</FormControl>
				</Col>
				<Col />
			</Row>
		)
	}
}

LevelType.propTypes = {
	onType: propTypes.func,
}

LevelType.defaultProps = {}

export default LevelType
