import { message } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setUploadDocuments, setValue } from "~/redux/action"
import { SET_COMMON_DOCUMENT_DATA, SET_DOCUMENT_UPLOAD_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DocumentModalUploadDetails from "./DocumentModalUploadDetails"
import { injectIntl } from "react-intl"

class DocumentModalUpload extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_DOCUMENT_UPLOAD_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			_.isEmpty(this.props.document.modalUpload.description) ||
			(_.isEmpty(this.props.document.modalUpload.fileList) && !this.props.document.selectedDocument.document)
		) {
			return true
		}
		let target = Object.assign({}, this.props.document.selectedDocument.document, {
			...this.props.document.selectedDocument.document,
			description: Utils.trim(this.props.document.modalUpload.description),
			displayPatientLabel: this.props.document.modalUpload.isDisplayPatient,
		})
		return _.isEqual(this.props.document.selectedDocument.document, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let file = this.props.document.modalUpload.fileList[0]
		const formData = new FormData()
		formData.append("file", file)
		formData.append(
			"req",
			new Blob(
				[
					JSON.stringify({
						userId: this.props.login.user.id,
						uploadDocuments: [
							{
								id: this.props.document.selectedDocument.document ? this.props.document.selectedDocument.document.id : 0,
								name: this.props.document.selectedDocument.document ? this.props.document.selectedDocument.document.fileName : file.name,
								path: this.props.document.selectedDocument.document ? this.props.document.selectedDocument.document.filePath : "",
								description: Utils.trim(this.props.document.modalUpload.description),
								displayPatientLabel: this.props.document.modalUpload.isDisplayPatient,
							},
						],
					}),
				],
				{ type: "application/json" }
			)
		)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.document.uploadDocument,
			withCredentials: true,
			headers: {
				"content-type": "multipart/form-data",
			},
			data: formData,
		}
		let callback = (res) => {
			if (file) {
				message.success(
					`${file.name} ${this.props.intl.formatMessage({
						id: ResourceAssistance.Warning.fileUploadSuccess,
					})}`
				)
			}
			let rst = Object.assign({}, this.props.common.documents)
			if (this.props.document.selectedDocument.document && rst[this.props.document.selectedDocument.document.id]) {
				rst[this.props.document.selectedDocument.document.id].isDisplayPatientLabel = this.props.document.modalUpload.isDisplayPatient
			}
			this.props.setValue(SET_COMMON_DOCUMENT_DATA, rst)
			this.props.setUploadDocuments(res.data.uploadDocuments)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
			if (file) {
				message.error(
					`${file.name} ${this.props.intl.formatMessage({
						id: ResourceAssistance.Warning.fileUploadFailed,
					})}`
				)
			}
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.upload)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<DocumentModalUploadDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isMenuDocumentUploadDisplay,
	common: state.common,
	login: state.login,
	document: state.menu.document,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setUploadDocuments,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DocumentModalUpload))
