import Duration from "./Duration"
import EMomentTime from "./EMomentTime"
import ServerCoreCode from "./ServerCoreCode"

const EnumAssistance = {
	...ServerCoreCode,
	Moment: {
		...EMomentTime,
	},
	Duration: Duration,
}

export default EnumAssistance
