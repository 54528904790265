import { ResourceAssistance } from "~/i18n"
import { SET_COMMON_DOCUMENT_DATA, SET_HEADER, SET_ORG_ID, SET_ORGS, SET_PAGE_CONTINUE_LOADING, SET_PAGE_LOADING } from "../type"

const init = {
	isPageLoading: false,
	isContinueLoading: false,
	header: ResourceAssistance.CONSTANT.PMGCARE,
	orgId: "0",
	orgs: [],
	documents: {},
}

const modalReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PAGE_LOADING:
			return Object.assign({}, state, {
				isPageLoading: action.payload.isLoading,
			})
		case SET_HEADER:
			return Object.assign({}, state, {
				header: action.payload.value,
			})
		case SET_PAGE_CONTINUE_LOADING:
			return Object.assign({}, state, {
				isContinueLoading: action.payload.value,
			})
		case SET_ORG_ID:
			return Object.assign({}, state, {
				orgId: action.payload.value,
			})
		case SET_ORGS:
			return Object.assign({}, state, {
				orgs: action.payload.value,
			})
		case SET_COMMON_DOCUMENT_DATA:
			return Object.assign({}, state, {
				documents: action.payload.value,
			})
		default:
			return state
	}
}

export default modalReducer
