import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import CashierModalCertificateAction from "./CashierModalCertificateAction"
import CashierModalCertificatePending from "./CashierModalCertificatePending"
import CashierModalCertificateUncharged from "./CashierModalCertificateUncharged"

class CashierModalCertificateDetails extends React.Component {
	constructor(props) {
		super(props)

		this.calculateCertificateBalance = this.calculateCertificateBalance.bind(this)
	}

	calculateCertificateBalance(certificate) {
		return Utils.BigNumber(certificate.service.pricePerUnit)
			.minus(
				this.props.cashier.selectedAdmission.admission.billingStatements
					.filter(
						(billingStatement) => billingStatement.certificate && billingStatement.certificate.id === certificate.id
					)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.minus(
				this.props.cashier.modalFinancialPlan.serviceTable.original
					.filter(
						(billingStatement) =>
							!billingStatement.id &&
							billingStatement.isCertificate &&
							billingStatement.order &&
							billingStatement.order.id === certificate.id
					)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.minus(
				this.props.cashier.modalCertificate.pendingTable.original
					.filter((order) => order.id === certificate.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.toFixed(2)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<CashierModalCertificateUncharged />
				<CashierModalCertificateAction {...this.props} calculateCertificateBalance={this.calculateCertificateBalance} />
				<CashierModalCertificatePending />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalCertificateDetails)
