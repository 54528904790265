import _ from "lodash"
import { LOCALES, ResourceAssistance } from "~/i18n"
import { store } from "~/redux/Store"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"
import moment from "moment"

class DepositPrintableDataFactory extends PrintableDataFactory {
	static generateDepositSlipData = (org, branch, logo, patient, transactions, cashier) => {
		let intl = super.getIntl()

		return {
			identificationIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			patientNameIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			ageIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			dobIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			addressIntl: intl.formatMessage({ id: ResourceAssistance.Message.address }),
			telIntl: intl.formatMessage({ id: ResourceAssistance.Message.tel }),
			pidIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientId }),
			depositIntl: intl.formatMessage({ id: ResourceAssistance.Message.deposit }),
			paymentIntl: intl.formatMessage({ id: ResourceAssistance.Message.payment }),
			amountIntl: intl.formatMessage({ id: ResourceAssistance.Message.amount }),
			remarkIntl: intl.formatMessage({ id: ResourceAssistance.Message.remark }),
			remark1Intl: intl.formatMessage({ id: ResourceAssistance.Message.pleaseBringDepositSlipWithYouEveryTimeWhenContactWithTheCasier }),
			remark2Intl: intl.formatMessage({ id: ResourceAssistance.Message.theDepositCannotBeRefunded7DaysAfterDischarged }),

			documentNo: `DP${moment().milliseconds(0).format("YYMMDD")}-${moment().milliseconds(0).format("HHmm")}`,
			titleIntl: intl.formatMessage({ id: ResourceAssistance.Message.depositSlip }),
			logo: logo,
			name: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? org.displayNameEng : org.displayName,
			branchName: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? branch.displayNameEng : branch.displayName,
			address: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? branch.address.displayNameEng : branch.address.displayName,
			tel: branch.address.tel,

			pid: patient.id,
			identification: patient.identificationNumber,
			patientName: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH)
				? `${patient.firstNameEng ? patient.firstNameEng : patient.firstName} ${patient.lastNameEng ? patient.lastNameEng : patient.lastName}`
				: `${patient.firstName} ${patient.lastName}`,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			patientAddress: Utils.trim(patient.address),
			patientTel: patient.tel,

			transactions: transactions.map((transaction) => {
				return {
					words: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? transaction.wordsInEng : transaction.wordsInThai,
					payment: transaction.payment,
					amount: transaction.amount,
				}
			}),

			signatureDate: `${Utils.formatDateTime(moment().milliseconds(0).valueOf())}`,
			signatureTitles: [intl.formatMessage({ id: ResourceAssistance.Message.cashier })],
			signatureNames: [
				_.isEqual(store.getState().language.locale, LOCALES.ENGLISH)
					? !_.includes(cashier.displayNameEng, "null")
						? cashier.displayNameEng
						: ""
					: cashier.displayName,
			],
		}
	}
}

export default DepositPrintableDataFactory
