const init = {}

const accountsPayableReducer = (state = init, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export default accountsPayableReducer
