import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOPDNurseOrders,
	setOPDPatients,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
	SET_MODAL_WARNING_SIZE,
	SET_OPD_ADJUSTMENT_DISPLAY,
	SET_OPD_NURSE_ORDER_DISPLAY,
	SET_OPD_NURSE_SERVICE_DISPLAY,
	SET_OPD_SELECTED_NURSE_ORDER,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightNurseOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightNurseOrder",
		}

		this.onSelectOrder = this.onSelectOrder.bind(this)
		this.onNewMedicalSupply = this.onNewMedicalSupply.bind(this)
		this.onNewService = this.onNewService.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDNurseOrders(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseOrders)
		} else {
			this.props.setOPDNurseOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteNurseService)) {
				this.adjustmentServiceDeleteCallback()
			}
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteNurseMedicalSupply)) {
				this.deleteNurseOrder()
			}
		}

		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			this.props.setSelected(SET_OPD_SELECTED_NURSE_ORDER, -1, null)
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDNurseOrders(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseOrders)
			} else {
				this.props.setOPDNurseOrders([])
			}
		}
	}

	isDisplayEdit() {
		return (
			Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) &&
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	isDisplayDelete() {
		return (
			Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) &&
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	saveNurseOrders(nurseOrders) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveNurseOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				nurseOrders: nurseOrders,
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	adjustmentItemCallback() {
		let selectedOrder = this.props.opd.selectedNurseOrder.order
		this.saveNurseOrders([
			{
				...selectedOrder,
				id: selectedOrder.id,
				code: selectedOrder.code,
				fsCode: selectedOrder.fsCode,
				description: selectedOrder.description,
				startDateTime: this.props.opd.modalAdjustment.startDateTime,
				endDateTime: this.props.opd.modalAdjustment.isDisableEndDate ? null : this.props.opd.modalAdjustment.endDateTime,
				qtyPerDay: this.props.opd.modalAdjustment.qtyPerDay,
				unit: this.props.opd.modalAdjustment.selectedUnit.unit.oneTime
					? selectedOrder.itemSupplierUnit
					: this.props.opd.modalAdjustment.selectedUnit.unit.displayName,
				pricePerUnit: this.props.opd.modalAdjustment.selectedUnit.unit.oneTime
					? selectedOrder.itemSupplierPricePerUnit
					: this.props.opd.modalAdjustment.selectedUnit.unit.sellingPricePerUnit,
				notes: Utils.trim(this.props.opd.modalAdjustment.notes),
				categoryName: selectedOrder.categoryName,
				locationAccountsReceivable: selectedOrder.locationAR,
				stat: this.props.opd.modalAdjustment.isSTAT,
			},
		])
	}

	adjustmentServiceCallback() {
		let selectedOrder = this.props.opd.selectedNurseOrder.order
		this.saveNurseOrders([
			{
				...selectedOrder,
				id: selectedOrder.id,
				serviceCode: selectedOrder.serviceCode,
				fsCode: selectedOrder.fsCode,
				description: selectedOrder.description,
				startDateTime: this.props.hospitel.modalAdjustmentService.startDateTime,
				endDateTime: this.props.hospitel.modalAdjustmentService.isDisableEndDate ? null : this.props.hospitel.modalAdjustmentService.endDateTime,
				pricePerUnit: selectedOrder.pricePerUnit,
				qtyPerDay: 1,
				categoryName: selectedOrder.categoryName,
				notes: Utils.trim(this.props.hospitel.modalAdjustmentService.notes),
				locationAccountsReceivable: selectedOrder.locationAR,
			},
		])
	}

	adjustmentServiceDeleteCallback() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteNurseOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				nurseOrders: [
					{
						id: this.props.opd.selectedNurseOrder.order.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deleteNurseOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteNurseOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				nurseOrders: [
					{
						id: this.props.opd.selectedNurseOrder.order.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectOrder(order, index) {
		this.props.setSelected(SET_OPD_SELECTED_NURSE_ORDER, index, this.props.opd.nurseOrderTable.filtered[index])
	}

	onNewMedicalSupply() {
		this.props.onModalDisplayAction(SET_OPD_NURSE_ORDER_DISPLAY, true)
		this.props.setSelected(SET_OPD_SELECTED_NURSE_ORDER, -1, null)
	}

	onNewService() {
		this.props.onModalDisplayAction(SET_OPD_NURSE_SERVICE_DISPLAY, true)
		this.props.setSelected(SET_OPD_SELECTED_NURSE_ORDER, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		let selectedNurseOrder = this.props.opd.nurseOrderTable.filtered[rIdx]
		if (selectedNurseOrder.code) {
			window.adjustmentItemCaller = this
			this.props.onModalDisplayAction(SET_OPD_ADJUSTMENT_DISPLAY, true)
		} else {
			window.adjustmentServiceCaller = this
			this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE, selectedNurseOrder)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY, true)
		}
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedNurseOrder = this.props.opd.nurseOrderTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, undefined)
		if (selectedNurseOrder.code) {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteNurseMedicalSupply)
			this.props.setWarningMsgAction(`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
        ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.medicalSupply })}: ${selectedNurseOrder.description}
        ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate })}: ${Utils.formatDateTime(selectedNurseOrder.startDateTime)}
        ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate })}: ${Utils.formatDateTime(selectedNurseOrder.endDateTime)}`)
		} else {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteNurseService)
			this.props.setWarningMsgAction(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }) +
					"\n" +
					this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.service }) +
					": " +
					selectedNurseOrder.description +
					"\n" +
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate }) +
					": " +
					Utils.formatDate(selectedNurseOrder.startDateTime) +
					"\n" +
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate }) +
					": " +
					Utils.formatDate(selectedNurseOrder.endDateTime)
			)
		}
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[
							this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.opd.nurseOrderTable}
						onClick={this.onSelectOrder}
						isClearHighlight={this.props.opd.selectedNurseOrder.index === -1}
						highlightedRow={this.props.opd.selectedNurseOrder.index}
						extra={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) &&
							this.props.opd.selectedMedicalRecord.medicalRecord &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 && (
								<Row className={"g-0"}>
									<Col />
									<Col md="auto">
										<Button
											style={{ fontSize: "medium" }}
											variant={ResourceAssistance.Button.variant.link}
											size={ResourceAssistance.Button.size.sm}
											onClick={this.onNewMedicalSupply}
										>
											<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
											{translate(ResourceAssistance.Message.medicalSupply)}
										</Button>
									</Col>
									<Col />
									<Col md="auto">
										<Button
											style={{ fontSize: "medium" }}
											variant={ResourceAssistance.Button.variant.link}
											size={ResourceAssistance.Button.size.sm}
											onClick={this.onNewService}
										>
											<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
											{translate(ResourceAssistance.Hospitel.service)}
										</Button>
									</Col>
									<Col />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	hospitel: state.hospitel.hospitel,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDNurseOrders,
			setOPDPatients,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightNurseOrder))
