import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setFOPaymentPlans,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
	setFORequests,
} from "~/redux/action"
import {
	SET_FO_SELECTED_PAYMENT_PLAN,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FinancialOperationsRightPaymentPlan extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.financialOperations.deletePaymentPlan)
			) {
				this.deletePaymentPlans()
			}
		}

		if (!_.isEqual(prevProps.FO.selectedFinancialPlan, this.props.FO.selectedFinancialPlan)) {
			if (this.props.FO.selectedFinancialPlan.financialPlan) {
				this.props.setFOPaymentPlans(this.props.FO.selectedFinancialPlan.financialPlan.paymentPlans)
			} else {
				this.props.setFOPaymentPlans([])
			}
		}
		if (!_.isEqual(prevProps.FO.paymentPlanTable.filtered, this.props.FO.paymentPlanTable.filtered)) {
			let index = this.props.FO.selectedPaymentPlan.paymentPlan
				? this.props.FO.paymentPlanTable.filtered.findIndex(
						(each) => each.id === this.props.FO.selectedPaymentPlan.paymentPlan.id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_FO_SELECTED_PAYMENT_PLAN, index, this.props.FO.paymentPlanTable.filtered[index])
			} else {
				this.props.setSelected(SET_FO_SELECTED_PAYMENT_PLAN, -1, null)
			}
		}
	}

	deletePaymentPlans() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.financialOperations.deletePaymentPlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.FO.location.id,
				financialRequestId: this.props.FO.selectedFinancialPlan.financialPlan.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.FO.selectedPaymentPlan.paymentPlan.id,
					},
				],
			},
		}
		let callback = (res) => {
			let requests = this.props.FO.financialPlanTable.original.filter(
				(each) => each.id !== this.props.FO.selectedFinancialPlan.financialPlan.id
			)
			if (res.data.requests.length > 0) {
				requests.push(res.data.requests[0])
			}
			this.props.setFORequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isDisplayDelete() {
		return (
			this.props.FO.selectedFinancialPlan.financialPlan &&
			!this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete
		)
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_FO_SELECTED_PAYMENT_PLAN, index, this.props.FO.paymentPlanTable.filtered[index])
	}

	onDelete(e, row, rIdx, cIdx) {
		let selectedPaymentPlan = this.props.FO.paymentPlanTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.financialOperations.deletePaymentPlan)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions
					style={{ flex: "unset", display: "unset" }}
					size={"small"}
					contentStyle={{ fontStyle: "italic", fontWeight: "normal" }}
					column={1}
				>
					<Descriptions.Item label={translate(ResourceAssistance.Message.psId)} span={1}>
						{selectedPaymentPlan.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierCode)} span={1}>
						{selectedPaymentPlan.paymentSlip.supplierCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierName)} span={1}>
						{selectedPaymentPlan.paymentSlip.supplierName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedPaymentPlan.amount).toFixed(2))}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		let totalWithholdingTax = this.props.FO.paymentPlanTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[7]))
		}, Utils.BigNumber(0))
		let total = this.props.FO.paymentPlanTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[8]))
		}, Utils.BigNumber(0))
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
						btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })]}
						btnIcons={
							this.isDisplayDelete() ? [<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />] : undefined
						}
						onCheckedArray={[this.onDelete]}
						data={this.props.FO.paymentPlanTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.FO.selectedPaymentPlan.index === -1}
						highlightedRow={this.props.FO.selectedPaymentPlan.index}
						extra={
							!_.isEmpty(this.props.FO.paymentPlanTable.filtered) && (
								<>
									<Row className={"g-0"}>
										<Col />
										<Col md={3}>
											<Descriptions
												size="small"
												contentStyle={{
													fontStyle: "italic",
													fontWeight: "normal",
													textAlign: "right",
													paddingRight: "1rem",
													display: "unset",
												}}
												column={1}
											>
												<Descriptions.Item label={translate(ResourceAssistance.Message.withholdingTax)}>
													{Utils.formatNumWithComma(totalWithholdingTax.toFixed(2))}
												</Descriptions.Item>
											</Descriptions>
										</Col>
										<Col md={1} />
									</Row>
									<Row className={"g-0"}>
										<Col />
										<Col md={3}>
											<Descriptions
												size="small"
												contentStyle={{
													fontStyle: "italic",
													fontWeight: "normal",
													textAlign: "right",
													paddingRight: "1rem",
													display: "unset",
												}}
												column={1}
											>
												<Descriptions.Item label={translate(ResourceAssistance.Message.amount)}>
													{Utils.formatNumWithComma(total.toFixed(2))}
												</Descriptions.Item>
											</Descriptions>
										</Col>
										<Col md={1} />
									</Row>
								</>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setFOPaymentPlans,
			onModalDisplayAction,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setFORequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FinancialOperationsRightPaymentPlan))
