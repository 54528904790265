import { Checkbox, Space } from "antd"
import React from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setDateAction,
	setLoadingAction,
	setReportTFByLocationApproved,
	setReportTFByLocationBranches,
	setReportTFByLocationCertified,
	setReportTFByLocationOrgs,
	setReportTFByLocationSelectedBranch,
	setReportTFByLocationSelectedOrg,
} from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_TF_BY_LOCATION_END_DATETIME, SET_REPORT_TF_BY_LOCATION_START_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class ReportTFByLocationFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onCertified = this.onCertified.bind(this)
		this.onApproved = this.onApproved.bind(this)
	}

	componentDidMount() {
		this.props.setDateAction(SET_REPORT_TF_BY_LOCATION_START_DATETIME, Utils.generateDate().getTime())
		this.props.setDateAction(SET_REPORT_TF_BY_LOCATION_END_DATETIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.tf.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setReportTFByLocationOrgs(res.data.locations)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	componentWillUnmount() {
		this.props.setReportTFByLocationOrgs([])
		this.props.setReportTFByLocationBranches([])
		this.props.setReportTFByLocationSelectedOrg(-1, null)
		this.props.setReportTFByLocationSelectedBranch(-1, null)
		this.props.setReportTFByLocationCertified(true)
		this.props.setReportTFByLocationApproved(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.filter.selectedOrg.index !== this.props.filter.selectedOrg.index) {
			let branchHtml = document.getElementById(ResourceAssistance.ID.REPORT.tf.byLocation.branch)
			branchHtml.selectedIndex = 0
			this.props.setReportTFByLocationSelectedBranch(-1, null)

			if (this.props.filter.selectedOrg.org) {
				this.props.setReportTFByLocationBranches(this.props.filter.selectedOrg.org.locations)
			} else {
				this.props.setReportTFByLocationBranches([])
			}
		}
	}

	onStartDate(date) {
		this.props.setDateAction(SET_REPORT_TF_BY_LOCATION_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setDateAction(SET_REPORT_TF_BY_LOCATION_END_DATETIME, date.getTime())
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setReportTFByLocationSelectedOrg(event.target.value, this.props.filter.orgs[event.target.value])
		} else {
			this.props.setReportTFByLocationSelectedOrg(-1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setReportTFByLocationSelectedBranch(event.target.value, this.props.filter.branches[event.target.value])
		} else {
			this.props.setReportTFByLocationSelectedBranch(-1, null)
		}
	}

	onCertified(event) {
		this.props.setReportTFByLocationCertified(event.target.checked)
	}

	onApproved(event) {
		this.props.setReportTFByLocationApproved(event.target.checked)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.filter)}</legend>
					<Row>
						<Col />
						<Col md="auto">
							<DatePicker startDate={Utils.generateDate()} onChange={this.onStartDate} />
						</Col>
						<Col md="auto">
							<label style={{ alignSelf: ResourceAssistance.CSS.center }}>{translate(ResourceAssistance.Message.to)}</label>
						</Col>
						<Col md="auto">
							<DatePicker startDate={Utils.generateDate(0, 0, 0, 23, 59, 59)} onChange={this.onEndDate} />
						</Col>
						<Col />
					</Row>
					<Row>
						<Col md="auto">
							<label>{translate(ResourceAssistance.Message.org)}</label>
						</Col>
						<Col>
							<FormControl required size={ResourceAssistance.FormControl.size.sm} as={ResourceAssistance.FormControl.as.select} onChange={this.onOrg}>
								{Utils.renderOptions(this.props.filter.orgs)}
							</FormControl>
						</Col>
					</Row>
					<Row>
						<Col md="auto">
							<label>{translate(ResourceAssistance.Message.branch)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.REPORT.tf.byLocation.branch}
								size={ResourceAssistance.FormControl.size.sm}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onBranch}
							>
								{Utils.renderOptions(this.props.filter.branches)}
							</FormControl>
						</Col>
					</Row>
					<Row>
						<Col>
							<Space align="center" size={"large"} style={{ alignSelf: "center" }}>
								<Checkbox style={{ flexDirection: "row" }} checked={this.props.filter.isCertified} onChange={this.onCertified}>
									{translate(ResourceAssistance.Message.certified)}
								</Checkbox>
								<Checkbox style={{ flexDirection: "row" }} checked={this.props.filter.isApproved} onChange={this.onApproved}>
									{translate(ResourceAssistance.Message.approved)}
								</Checkbox>
							</Space>
						</Col>
					</Row>
				</fieldset>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.tf.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setReportTFByLocationOrgs,
			setReportTFByLocationBranches,
			setReportTFByLocationSelectedOrg,
			setReportTFByLocationSelectedBranch,
			setReportTFByLocationCertified,
			setReportTFByLocationApproved,
			setDateAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportTFByLocationFilterDetails))
