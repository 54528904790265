import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import DepositModalSearchPatientResult from "./DepositModalSearchPatientResult"
import DepositModalSearchPatientSearch from "./DepositModalSearchPatientSearch"

class DepositModalSearchPatientDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{_.isEmpty(this.props.deposit.modalSearch.patientTable.original) ? (
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patient)}</legend>
						<DepositModalSearchPatientSearch />
					</fieldset>
				) : (
					<DepositModalSearchPatientResult />
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositModalSearchPatientDetails)
