import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { FaRegEdit, FaTimes } from "react-icons/fa"
import { FaCheck } from "react-icons/fa6"
import { GrRotateLeft } from "react-icons/gr"
import { IoCutSharp } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_CANCEL_DISPLAY,
	SET_OR_EDIT_REQUEST_DISPLAY,
	SET_OR_SCHEDULES,
	SET_OR_SCHEDULE_DISPLAY,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OperatingRoomModalScheduleDetails from "./OperatingRoomModalScheduleDetails"

class OperatingRoomModalSchedule extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onReschedule = this.onReschedule.bind(this)
		this.onConfirm = this.onConfirm.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.confirmSchedule)) {
					this.updateSchedule(ResourceAssistance.Url.operatingRoom.confirmOperatingRoomSchedules)
				}
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_SCHEDULE_DISPLAY, false)
	}

	displayWarning(id, msg) {
		let event = this.props.operatingRoom.selectedEvent.event
		this.props.setWarningId(id)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={msg}
				bordered={false}
				hoverable
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.scheduledDate)} span={1}>
						{Utils.formatDateTime(event.extendedProps.schedule.startDateTime)} - {Utils.formatDateTime(event.extendedProps.schedule.endDateTime)}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	updateSchedule(url) {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.id,
				departmentId: this.props.operatingRoom.selectedOperatingRoom.operatingRoom.id,
				startDateTime: this.props.operatingRoom.calendarStartDateTime,
				endDateTime: this.props.operatingRoom.calendarEndDateTime,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_SCHEDULES, res.data.schedules)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xxl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.appointment)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<OperatingRoomModalScheduleDetails />
				</ModalBody>
				{this.props.operatingRoom.selectedEvent.event &&
					this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.status !== ResourceAssistance.Enum.confirmed && (
						<ModalFooter>
							<Row>
								<Col>
									<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
										<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.close)}
									</Button>
								</Col>
								<Col>
									<Button variant={ResourceAssistance.Button.variant.primary} onClick={this.onEdit}>
										<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.edit)}
									</Button>
								</Col>
								<Col>
									<Button variant={ResourceAssistance.Button.variant.red} onClick={this.onCancel}>
										<FaTimes size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.cancel)}
									</Button>
								</Col>
								<Col>
									<Button variant={ResourceAssistance.Button.variant.warning} onClick={this.onReschedule}>
										<IoCutSharp size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.reschedule)}
									</Button>
								</Col>
								<Col>
									<Button variant={ResourceAssistance.Button.variant.green} onClick={this.onConfirm}>
										<FaCheck size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.confirm)}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					)}
			</Modal>
		)
	}

	onClose() {
		this.reset()
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_OR_EDIT_REQUEST_DISPLAY, true)
		this.reset()
	}

	onCancel() {
		this.props.onModalDisplayAction(SET_OR_CANCEL_DISPLAY, true)
		this.reset()
	}

	onReschedule() {
		this.props.operatingRoom.selectedEvent.event.setProp("editable", true)
		this.props.operatingRoom.selectedEvent.event.setProp("color", ResourceAssistance.CSS.Color.warning)
		this.props.operatingRoom.selectedEvent.event.setExtendedProp("isRescheduled", true)
		this.reset()
	}

	onConfirm() {
		this.displayWarning(ResourceAssistance.ID.HOSPITAL.operatingRoom.confirmSchedule, translate(ResourceAssistance.Message.confirm))
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomScheduleDisplay,
	login: state.login,
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalSchedule))
