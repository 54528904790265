import { Button, Card, Descriptions, Progress, Upload, message } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Button as RButton, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { FaFileExport, FaRegEdit } from "react-icons/fa"
import { GrFormUpload } from "react-icons/gr"
import { ImAttachment } from "react-icons/im"
import { RiFileExcel2Line } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFORequests, setLoadingAction, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_FO_EDIT_REQUEST_DISPLAY, SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import { FinancialOperationsFactory } from "~/utils/factory/print/fiannce/FinancialOperationsFactory"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class FinancialOperationsRightInfo extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			progress: 0,
			exportFileName: "",
			exportFile: "",
		}
		this.ref = React.createRef()
		this.uploadFile = this.uploadFile.bind(this)
		this.beforeUpload = this.beforeUpload.bind(this)
		this.uploadChange = this.uploadChange.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onComplete = this.onComplete.bind(this)
		this.onExport = this.onExport.bind(this)
		this.onViewAttachment = this.onViewAttachment.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.financialOperations.complete_financial_transaction)
			) {
				this.completeFinancialTransaction()
			}
		}

		if (!_.isEqual(prevProps.FO.selectedFinancialPlan.financialPlan, this.props.FO.selectedFinancialPlan.financialPlan)) {
			if (this.props.FO.selectedFinancialPlan.financialPlan && this.ref.current) {
				let excel = FinancialOperationsFactory.generateExcelData(this.props.FO.selectedFinancialPlan.financialPlan)
				this.ref.current.updateExcel(excel)
			}
		}
	}

	completeFinancialTransaction() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.financialOperations.completeFinancialTransation,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.FO.location.id,
				financialRequestId: this.props.FO.selectedFinancialPlan.financialPlan.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.FO.financialPlanTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setFORequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	uploadFile(options) {
		const { file, onProgress, onSuccess, onError } = options
		let selectedFinancialPlan = this.props.FO.selectedFinancialPlan.financialPlan
		let selectedOrg = this.props.FO.location
		const formData = new FormData()
		formData.append("file", file)
		formData.append("name", selectedOrg.id + "_" + selectedFinancialPlan.id)
		formData.append("financialRequestId", selectedFinancialPlan.id)
		formData.append("orgId", selectedOrg.id)
		formData.append("userId", this.props.login.user.id)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.financialOperations.uploadFile,
			withCredentials: true,
			headers: {
				"content-type": "multipart/form-data",
			},
			onUploadProgress: (event) => {
				const percent = Math.floor((event.loaded / event.total) * 100)
				this.setState({
					progress: percent,
				})
				if (percent === 100) {
					setTimeout(
						() =>
							this.setState({
								progress: 0,
							}),
						1000
					)
				}
				onProgress({ percent: (event.loaded / event.total) * 100 })
			},
			data: formData,
		}

		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.FO.financialPlanTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setFORequests(requests)
			}
		}
		let errorHandler = (error) => {
			onError()
			this.setState({
				progress: 0,
			})
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			onSuccess()
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	downloadFile(path, name, func) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.financialOperations.downloadFile,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				financialRequestId: this.props.FO.selectedFinancialPlan.financialPlan.id,
				orgId: this.props.FO.location.id,
				path: path,
				name: name,
			},
		}
		let callback = (res) => {
			func(res)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	beforeUpload(file) {
		const isPDF = file.type === "application/pdf"
		if (!isPDF) {
			message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.uploadPDFOnly }), 10)
		}
		const isLt10M = file.size / 1024 / 1024 < 10
		if (!isLt10M) {
			message.error(`${this.props.intl.formatMessage({ id: ResourceAssistance.Warning.fileMustSmallerThan })} 10 MB!`, 5)
		}
		return isPDF && isLt10M
	}

	uploadChange(info) {
		if (info.file.status === "done") {
			message.success(
				`${info.file.name} ${this.props.intl.formatMessage({
					id: ResourceAssistance.Warning.fileUploadSuccess,
				})}`
			)
		} else if (info.file.status === "error") {
			message.error(
				`${info.file.name} ${this.props.intl.formatMessage({
					id: ResourceAssistance.Warning.fileUploadFailed,
				})}`
			)
		}
	}

	onEdit(e) {
		this.props.onModalDisplayAction(SET_FO_EDIT_REQUEST_DISPLAY, true)
	}

	onComplete(e, row, rIdx, cIdx) {
		let selectedFinancialPlan = this.props.FO.selectedFinancialPlan.financialPlan
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.financialOperations.complete_financial_transaction)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.id)} span={1}>
						{selectedFinancialPlan.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedFinancialPlan.supplierReceivedDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.paymentType)} span={1}>
						{selectedFinancialPlan.paymentMethod}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.bankAccNum)} span={1}>
						{selectedFinancialPlan.bankAccount}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.bank)} span={1}>
						{selectedFinancialPlan.bankCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.branch)} span={1}>
						{selectedFinancialPlan.bankBranchCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.fee)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedFinancialPlan.fee).toFixed(2))}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedFinancialPlan.amount).toFixed(2))}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onExport() {
		let data = ""
		let batchFormat = this.props.FO.selectedFinancialPlan.financialPlan.batchRecordFormat
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
		let batchIdx = 0
		while (batchIdx < batchFormat.length) {
			if (ResourceAssistance.PROGRAM_DEFINED.num_trans.includes(batchFormat[batchIdx])) {
				let numTrans = String(this.props.FO.paymentPlanTable.original.length).padStart(batchFormat[batchIdx + 1], "0")
				data = data.concat(numTrans)
				batchIdx += 1
			} else if (ResourceAssistance.PROGRAM_DEFINED.total_amount.includes(batchFormat[batchIdx])) {
				let totalAmount = String(
					this.props.FO.paymentPlanTable.body
						.reduce((total, cur) => {
							return total.plus(Utils.formatNumberFromStr(cur[6]))
						}, Utils.BigNumber(0))
						.toFixed(2)
				)
					.replace(".", "")
					.padStart(batchFormat[batchIdx + 1], "0")
				data = data.concat(totalAmount)
				batchIdx += 1
			} else if (ResourceAssistance.PROGRAM_DEFINED.effective_date.includes(batchFormat[batchIdx])) {
				let effectiveDate = Utils.formatDate(this.props.FO.selectedFinancialPlan.financialPlan.supplierReceivedDateTime)
					.replaceAll("/", "")
					.padStart(batchFormat[batchIdx + 1], "0")
				data = data.concat(effectiveDate)
				batchIdx += 1
			} else {
				data = data.concat(batchFormat[batchIdx])
			}
			batchIdx += 1
		}

		data = data.concat("\n")
		let detailFormat = this.props.FO.selectedFinancialPlan.financialPlan.detailRecordFormat
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
		this.props.FO.paymentPlanTable.original.forEach((paymentPlan) => {
			let detailIdx = 0
			while (detailIdx < detailFormat.length) {
				if (ResourceAssistance.PROGRAM_DEFINED.receiving_bank_code.includes(detailFormat[detailIdx])) {
					let bankCode = paymentPlan.paymentSlip.supplierBankCode ? paymentPlan.paymentSlip.supplierBankCode : ""
					let receivingBankCode = bankCode.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(receivingBankCode)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.receiving_branch_code.includes(detailFormat[detailIdx])) {
					let branchCode = paymentPlan.paymentSlip.supplierBranchCode ? paymentPlan.paymentSlip.supplierBranchCode : ""
					let receivingBranchCode = branchCode.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(receivingBranchCode)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.receiving_account.includes(detailFormat[detailIdx])) {
					let receivingAccount = paymentPlan.paymentSlip.supplierBankAccount.replace(/[^a-zA-Z0-9 ]/g, "").padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(receivingAccount)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.sending_branch_code.includes(detailFormat[detailIdx])) {
					let sendingBranchCode = this.props.FO.selectedFinancialPlan.financialPlan.bankBranchCode.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(sendingBranchCode)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.sending_account.includes(detailFormat[detailIdx])) {
					let sendingAccount = this.props.FO.selectedFinancialPlan.financialPlan.bankAccount
						.replace(/[^a-zA-Z0-9 ]/g, "")
						.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(sendingAccount)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.effective_date.includes(detailFormat[detailIdx])) {
					let effectiveDate = Utils.formatDate(this.props.FO.selectedFinancialPlan.financialPlan.supplierReceivedDateTime)
						.replace(/[^a-zA-Z0-9 ]/g, "")
						.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(effectiveDate)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.service_type.includes(detailFormat[detailIdx])) {
					let serviceType = this.props.FO.selectedFinancialPlan.financialPlan.serviceTypeCode.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(serviceType)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.total_amount.includes(detailFormat[detailIdx])) {
					let totalAmount = String(Utils.BigNumber(paymentPlan.amount).minus(paymentPlan.withholdingTaxAmount).toFixed(2))
						.replace(/[^a-zA-Z0-9 ]/g, "")
						.padStart(detailFormat[detailIdx + 1], "0")
					data = data.concat(totalAmount)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.receiver_name.includes(detailFormat[detailIdx])) {
					let receiverName = paymentPlan.paymentSlip.supplierName.padEnd(detailFormat[detailIdx + 1], " ")
					data = data.concat(receiverName)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.sender_name.includes(detailFormat[detailIdx])) {
					let senderName = this.props.FO.location.displayName.padEnd(detailFormat[detailIdx + 1], " ")
					data = data.concat(senderName)
					detailIdx += 1
				} else if (ResourceAssistance.PROGRAM_DEFINED.email.includes(detailFormat[detailIdx])) {
					let email = paymentPlan.paymentSlip.supplierEmail.padEnd(detailFormat[detailIdx + 1], " ")
					data = data.concat(email)
					detailIdx += 1
				} else {
					data = data.concat(detailFormat[detailIdx])
				}
				detailIdx += 1
			}
		})
		let blob = new Blob([data], { type: "text/plain" })
		this.setState({
			exportFileName: this.props.FO.selectedFinancialPlan.financialPlan.id,
			exportFile: URL.createObjectURL(blob),
		})
	}

	onViewAttachment(e) {
		this.downloadFile(this.props.FO.selectedFinancialPlan.financialPlan.filePath, this.props.FO.selectedFinancialPlan.financialPlan.fileName, (res) => {
			if (res.data.encodedFile) {
				let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
				myWindow.data = PrintableDataFactory.generatePdfFileData(res.data.encodedFile)
			} else {
				let requests = this.props.FO.financialPlanTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setFORequests(requests)
			}
		})
	}

	render() {
		return (
			this.props.FO.selectedFinancialPlan.financialPlan && (
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.financialTransaction)}</legend>
							<Descriptions
								style={{ flex: "unset", display: "unset" }}
								size={"small"}
								contentStyle={{ fontStyle: "italic" }}
								column={4}
								extra={
									this.props.FO.selectedFinancialPlan.financialPlan.complete ? (
										<ExcelDocument
											ref={this.ref}
											element={
												<Button
													style={{
														background: ResourceAssistance.CSS.Color.info,
													}}
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.download })}
													type="primary"
													size="middle"
													shape="round"
													icon={<RiFileExcel2Line size={ResourceAssistance.ReactIcon.size} />}
												/>
											}
										/>
									) : (
										<Row>
											<Col md="auto">
												<Button
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.export })}
													type="primary"
													size="middle"
													shape="round"
													icon={<FaFileExport size={ResourceAssistance.ReactIcon.size} />}
													disabled={
														this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete ||
														!this.props.FO.selectedFinancialPlan.financialPlan.exportAvailable
													}
													download={this.state.exportFileName}
													href={this.state.exportFile}
													onClick={this.onExport}
												/>
											</Col>
											<Col md="auto">
												<Button
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit })}
													type="primary"
													size="middle"
													shape="round"
													icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />}
													disabled={this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete}
													onClick={this.onEdit}
												/>
											</Col>
											{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.FinancialOperations.Inspector, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														style={{
															background:
																_.isEmpty(this.props.FO.selectedFinancialPlan.financialPlan.fee) ||
																this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete
																	? undefined
																	: ResourceAssistance.CSS.Color.dark_green,
														}}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.complete })}
														type="primary"
														size="middle"
														shape="round"
														icon={<AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} />}
														disabled={
															_.isEmpty(this.props.FO.selectedFinancialPlan.financialPlan.fee) ||
															this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete
														}
														onClick={this.onComplete}
													/>
												</Col>
											)}
											<Col md="auto">
												<Upload
													customRequest={this.uploadFile}
													accept={"application/pdf"}
													showUploadList={false}
													beforeUpload={this.beforeUpload}
													onChange={this.uploadChange}
												>
													<Button
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.upload })}
														type="primary"
														size="middle"
														shape="round"
														icon={<GrFormUpload size={ResourceAssistance.ReactIcon.size} />}
														disabled={!this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete}
													/>
												</Upload>
											</Col>
										</Row>
									)
								}
							>
								<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
									{Utils.formatDate(this.props.FO.selectedFinancialPlan.financialPlan.supplierReceivedDateTime)}
								</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.paymentType)} span={1}>
									{this.props.FO.selectedFinancialPlan.financialPlan.paymentMethod}
								</Descriptions.Item>
								<Descriptions.Item
									label={translate(ResourceAssistance.Message.fee)}
									contentStyle={this.props.FO.selectedFinancialPlan.financialPlan.fee ? undefined : { color: ResourceAssistance.CSS.Color.red }}
									span={1}
								>
									{this.props.FO.selectedFinancialPlan.financialPlan.fee
										? Utils.formatNumWithComma(Utils.BigNumber(this.props.FO.selectedFinancialPlan.financialPlan.fee).toFixed(2))
										: "*"}
								</Descriptions.Item>
								<Descriptions.Item span={1}></Descriptions.Item>
								{!_.isEmpty(this.props.FO.selectedFinancialPlan.financialPlan.bankAccount) && (
									<>
										<Descriptions.Item label={translate(ResourceAssistance.Message.bank)} span={1}>
											{this.props.FO.selectedFinancialPlan.financialPlan.bankName}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
											{this.props.FO.selectedFinancialPlan.financialPlan.bankCode}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.branch)} span={1}>
											{this.props.FO.selectedFinancialPlan.financialPlan.bankBranchName}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.branchCode)} span={1}>
											{this.props.FO.selectedFinancialPlan.financialPlan.bankBranchCode}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.bankAccNum)} span={2}>
											{this.props.FO.selectedFinancialPlan.financialPlan.bankAccount}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.serviceType)} span={2}>
											{this.props.FO.selectedFinancialPlan.financialPlan.serviceType}
										</Descriptions.Item>
									</>
								)}
								<Descriptions.Item label={translate(ResourceAssistance.Message.uploaded)} span={1}>
									{this.props.FO.selectedFinancialPlan.financialPlan.filePath && this.state.progress === 0 ? (
										<RButton
											title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.view })}
											variant={ResourceAssistance.Button.variant.outlineInfo}
											style={{ borderColor: "transparent" }}
											onClick={this.onViewAttachment}
										>
											<ImAttachment />
										</RButton>
									) : this.state.progress > 0 ? (
										<Progress size={"small"} percent={this.state.progress} />
									) : (
										""
									)}
								</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
									{this.props.FO.selectedFinancialPlan.financialPlan.filePath
										? Utils.formatDateTime(this.props.FO.selectedFinancialPlan.financialPlan.fileLastModifiedDateTime)
										: ""}
								</Descriptions.Item>
								<Descriptions.Item span={2}></Descriptions.Item>
							</Descriptions>
						</fieldset>
					</Col>
				</Row>
			)
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setLoadingAction,
			setFORequests,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FinancialOperationsRightInfo))
