import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ConfigEvaluationModalNewInfo from "./ConfigEvaluationModalNewInfo"

class ConfigEvaluationModalNewDetails extends React.Component {
	render() {
		return (
			<Container fluid className={"full-flex space-top-each-row-parent"}>
				<ConfigEvaluationModalNewInfo />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigEvaluationModalNewDetails))
