import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_REPORT_CASHIER_ADMISSION_ID } from "~/redux/type"

class ReportCashierAdmissionFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onId = this.onId.bind(this)
	}

	onId(event) {
		this.props.setValue(SET_REPORT_CASHIER_ADMISSION_ID, event.target.value)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Message.id)}</InputGroup.Text>
							<FormControl required type={ResourceAssistance.FormControl.type.number} onChange={this.onId} />
						</InputGroup>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportCashierAdmissionFilterDetails)
