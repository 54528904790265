import {
	SET_REPORT_PO_BY_LOCATION_APPROVAL_IND,
	SET_REPORT_PO_BY_LOCATION_BRANCHES,
	SET_REPORT_PO_BY_LOCATION_INSPECTOR_IND,
	SET_REPORT_PO_BY_LOCATION_ORGS,
	SET_REPORT_PO_BY_LOCATION_REQUESTER_IND,
	SET_REPORT_PO_BY_LOCATION_SELECTED_BRANCH,
	SET_REPORT_PO_BY_LOCATION_SELECTED_ORG,
	SET_REPORT_PO_BY_ID_SEARCH_ID,
} from "~/redux/type"

export const setReportPOByLocationOrgs = (orgs) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_REPORT_PO_BY_LOCATION_ORGS,
			payload: {
				orgs: orgs,
			},
		})
		return Promise.resolve()
	}
}

export const setReportPOByLocationSelectedOrg = (index, org) => {
	return {
		type: SET_REPORT_PO_BY_LOCATION_SELECTED_ORG,
		payload: {
			index: index,
			org: org,
		},
	}
}

export const setReportPOByLocationBranches = (branches) => {
	return {
		type: SET_REPORT_PO_BY_LOCATION_BRANCHES,
		payload: {
			branches: branches,
		},
	}
}

export const setReportPOByLocationSelectedBranch = (index, branch) => {
	return {
		type: SET_REPORT_PO_BY_LOCATION_SELECTED_BRANCH,
		payload: {
			index: index,
			branch: branch,
		},
	}
}

export const setReportPOByLocationRequesterInd = (isRequester) => {
	return {
		type: SET_REPORT_PO_BY_LOCATION_REQUESTER_IND,
		payload: {
			isRequester: isRequester,
		},
	}
}

export const setReportPOByLocationInspectorInd = (isInspector) => {
	return {
		type: SET_REPORT_PO_BY_LOCATION_INSPECTOR_IND,
		payload: {
			isInspector: isInspector,
		},
	}
}

export const setReportPOByLocationApprovalInd = (isApproval) => {
	return {
		type: SET_REPORT_PO_BY_LOCATION_APPROVAL_IND,
		payload: {
			isApproval: isApproval,
		},
	}
}

export const setReportPOByIdSearchId = (id) => {
	return {
		type: SET_REPORT_PO_BY_ID_SEARCH_ID,
		payload: {
			searchId: id,
		},
	}
}
