import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS,
	SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE,
	SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA,
	SET_CONTRACT_BILLING_MODAL_INFO_TEL,
} from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class ContractBillingModalEditDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDueDate = this.onDueDate.bind(this)
		this.onFactoryArea = this.onFactoryArea.bind(this)
		this.onAddress = this.onAddress.bind(this)
		this.onTel = this.onTel.bind(this)
	}

	componentDidMount() {
		if (this.props.contract.selectedBilling.billing) {
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE, this.props.contract.selectedBilling.billing.dueDateTime)
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA, this.props.contract.selectedBilling.billing.factoryArea)
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS, this.props.contract.selectedBilling.billing.insuranceCompanyAddress)
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_TEL, this.props.contract.selectedBilling.billing.insuranceCompanyTel)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_TEL, "")
	}

	onDueDate(v) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE, moment(v).endOf("day").milliseconds(0).valueOf())
	}

	onFactoryArea(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA, e.target.value)
	}

	onAddress(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS, e.target.value)
	}

	onTel(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INFO_TEL, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col />
					<Col md={8}>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							allowClear={false}
							placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.dueDate })]}
							status={this.props.contract.modalInfo.dueDateTime ? undefined : "error"}
							values={[this.props.contract.modalInfo.dueDateTime ? moment(this.props.contract.modalInfo.dueDateTime) : undefined]}
							onChange={this.onDueDate}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={2}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.factoryArea })}
							onChange={this.onFactoryArea}
							value={this.props.contract.modalInfo.factoryArea}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={3}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.address })}
							onChange={this.onAddress}
							value={this.props.contract.modalInfo.address}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.tel })}
							onChange={this.onTel}
							value={this.props.contract.modalInfo.tel}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalEditDetails))
