import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcDebt, FcInspection, FcSalesPerformance } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont, SvgDeposit } from "~/icons"
import { Utils } from "~/utils/Utils"
import { EType } from "../Home"

class Accounting extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0"} style={{ justifyContent: "center" }}>
					<Col md="auto">
						<h2 style={{ marginBottom: "0.5rem" }}>{translate(ResourceAssistance.Message.finance)}</h2>
					</Col>
				</Row>
				<Row style={{ justifyContent: "center" }}>
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsReceive.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.ARC} onClick={this.props.onBtnClick}>
								<SvgDeposit width={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.accountsReceive)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsReceivable.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.AR} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-caigoufapiao"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.accountsReceivable)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.ContractBilling.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.RC} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-contract"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.contractBilling)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.Debt.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.DEBT} onClick={this.props.onBtnClick}>
								<FcDebt size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.debt)}
							</Button>
						</Col>
					)}
				</Row>
				<Row style={{ justifyContent: "center" }}>
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PostingGL.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.PGL} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-caigoufapiao"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.postingGL)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsPayable.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.AP} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-caigoufapiao"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.accountsPayableRC)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PurchaseInvoice.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.PI} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-caigoufapiao"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.accountsPayablePI)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentVoucher.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.PV} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-caigoufapiao"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.accountsPayablePV)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.PS} onClick={this.props.onBtnClick}>
								<FcInspection size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.paymentSlip)}
							</Button>
						</Col>
					)}
				</Row>
				<Row style={{ justifyContent: "center" }}>
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.FinancialOperations.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.ACCOUNTING.FINANCIAL_OPERATIONS} onClick={this.props.onBtnClick}>
								<FcSalesPerformance size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.financialOperations)}
							</Button>
						</Col>
					)}
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default injectIntl(connect(mapStateToProps, {})(Accounting))
