import { Checkbox, Divider } from "antd"
import React from "react"
import { Button, Col, FormControl, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setInventoryDisplayExpireChecked,
	setInventoryDisplayMinStockChecked,
	setInventoryInventoryDetails,
	setInventoryLotExpiredDate,
	setInventorySearchCodeText,
	setInventorySearchNameText,
	setInventorySelectedInventoryDetail,
	setInventorySelectedType,
	setLoadingAction,
} from "~/redux/action"
import { SET_INVENTORY_INVENTORY_DETAILS_LOADING } from "~/redux/type"
import { SET_ADD_INVENTORY_DISPLAY, SET_INVENTORY_EDIT_INVENTORY_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import SearchBox from "~/view/component/search_box/SearchBox"

class InventorySecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InventorySecondRow",
		}

		this.onAddInventoryBtn = this.onAddInventoryBtn.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onCodeChange = this.onCodeChange.bind(this)
		this.onNameChange = this.onNameChange.bind(this)
		this.onChangeType = this.onChangeType.bind(this)
		this.onDisplayExpire = this.onDisplayExpire.bind(this)
		this.onExpiredChange = this.onExpiredChange.bind(this)
		this.onDisplayMinStock = this.onDisplayMinStock.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.inventories.filtered) !== JSON.stringify(this.props.inventories.filtered)) {
			this.props.setInventorySelectedInventoryDetail(-1, null)
		}
	}

	onCodeChange(event) {
		this.props.setInventorySearchCodeText(event.target.value)
	}

	onNameChange(event) {
		this.props.setInventorySearchNameText(event.target.value)
	}

	onAddInventoryBtn(event) {
		this.props.setInventorySelectedInventoryDetail(-1, null)
		this.props.onModalDisplayAction(SET_ADD_INVENTORY_DISPLAY, true)
	}

	onEdit(event) {
		this.props.onModalDisplayAction(SET_INVENTORY_EDIT_INVENTORY_DISPLAY, true)
	}

	onDelete(event) {
		let raw = {
			locationId: this.props.selectedInventory.loc.id,
			userId: this.props.login.user.id,
			inventoryDetails: [{ itemSupplierRelpId: this.props.selectedInventoryDetail.inventoryDetail.itemSupplierRelpId }],
		}

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.inventory.deleteInventory,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: raw,
		}

		let callback = (res) => {
			let stock = this.props.selectedInventory.loc
			Object.assign(stock, {
				inventoryDetails: stock.inventoryDetails.filter(
					(detail) => detail.id !== this.props.selectedInventoryDetail.inventoryDetail.id
				),
			})
			this.props.setInventoryInventoryDetails(this.props.selectedInventory.loc.inventoryDetails)
			this.props.setInventorySelectedInventoryDetail(-1, null)
		}

		let errorHandler = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onChangeType(event) {
		this.props.setInventorySelectedType(event.target.value, this.props.type.types[event.target.value])
	}

	onDisplayExpire(event) {
		this.props.setInventoryDisplayExpireChecked(event.target.checked)
	}

	onDisplayMinStock(event) {
		this.props.setInventoryDisplayMinStockChecked(event.target.checked)
	}

	onExpiredChange(date) {
		this.props.setInventoryLotExpiredDate(date)
	}

	isAddEnabled() {
		return this.props.selectedInventory.index === -1 || this.props.selectedType.index === -1
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"g-0 label-info-row"}>
				<Col md="auto">
					<FormattedMessage id={ResourceAssistance.Message.loading}>
						{(placeholder) => (
							<FormControl
								id={ResourceAssistance.ID.INV.type}
								size={ResourceAssistance.FormControl.size.sm}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.selectedBranch.index === -1}
								onChange={this.onChangeType}
							>
								{this.props.type.isLoading ? (
									<option>{placeholder}</option>
								) : (
									Utils.renderOptions(this.props.type.types)
								)}
							</FormControl>
						)}
					</FormattedMessage>
				</Col>
				<Col>
					<SearchBox
						size={ResourceAssistance.Button.size.sm}
						num={2}
						controlType={ResourceAssistance.FormControl.type.text}
						callbacks={[this.onCodeChange, this.onNameChange]}
						values={[this.props.searchCodeText, this.props.searchNameText]}
						placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
					/>
				</Col>
				<Col md="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isAddEnabled()}
						onClick={this.onAddInventoryBtn}
					>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.add)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						disabled={this.props.selectedInventoryDetail.index === -1 || this.props.selectedInventory.index === -1}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.red}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.props.selectedInventoryDetail.index === -1 || this.props.selectedInventory.index === -1}
						onClick={this.onDelete}
					>
						<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.delete)}
					</Button>
				</Col>
				<Col md="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<DatePicker
						className={ResourceAssistance.CSS.flexSelfCenter}
						isShowTime={false}
						startDate={this.props.lotExpiredDate}
						onChange={this.onExpiredChange}
					/>
				</Col>
				<Col md="auto">
					<Checkbox
						style={{ flexDirection: "row" }}
						disabled={this.props.selectedInventory.index === -1 && this.props.selectedBranch.index === -1}
						onChange={this.onDisplayExpire}
					>
						{translate(ResourceAssistance.Message.displayExpire)}
					</Checkbox>
				</Col>
				<Col md="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Checkbox
						style={{ flexDirection: "row" }}
						disabled={this.props.selectedInventory.index === -1}
						onChange={this.onDisplayMinStock}
					>
						{translate(ResourceAssistance.Message.displayMinStock)}
					</Checkbox>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	searchCodeText: state.inventory.searchCodeText,
	searchNameText: state.inventory.searchNameText,
	isDisplayExpire: state.inventory.isDisplayExpireChecked,
	lotExpiredDate: state.inventory.lotExpiredDate,
	selectedBranch: state.inventory.selectedBranch,
	selectedInventory: state.inventory.selectedInventory,
	selectedInventoryDetail: state.inventory.selectedInventoryDetail,
	type: state.inventory.type,
	selectedType: state.inventory.selectedType,
	inventories: state.inventory.inventories,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setInventorySearchCodeText,
			setInventorySearchNameText,
			setLoadingAction,
			setInventorySelectedType,
			setInventorySelectedInventoryDetail,
			setInventoryDisplayExpireChecked,
			setInventoryDisplayMinStockChecked,
			setInventoryLotExpiredDate,
			setInventoryInventoryDetails,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(InventorySecondRow)
