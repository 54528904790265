import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_TF_DISPLAY_ALL_IND,
	SET_TF_MODAL_CANCEL_TRI_REASON,
	SET_TF_MODAL_REQUEST_ITEMS,
	SET_TF_MODAL_REQUEST_PENDING_ITEMS,
	SET_TF_MODAL_REQUEST_PENDING_SELECTED_ITEM,
	SET_TF_MODAL_REQUEST_SEARCH,
	SET_TF_MODAL_REQUEST_SELECTED_DUE_DATE,
	SET_TF_MODAL_REQUEST_SELECTED_ITEM,
	SET_TF_MODAL_REQUEST_SELECTED_ITEM_AMOUNT,
	SET_TF_MODAL_REQUEST_SELECTED_TO_BRANCH,
	SET_TF_MODAL_REQUEST_SELECTED_TO_LOCATION,
	SET_TF_MODAL_REQUEST_SELECTED_TO_ORG,
	SET_TF_MODAL_REQUEST_SELECTED_TYPE,
	SET_TF_MODAL_REQUEST_TO_BRANCHES,
	SET_TF_MODAL_REQUEST_TO_LOCATIONS,
	SET_TF_MODAL_REQUEST_TO_ORGS,
	SET_TF_MODAL_TRANSFER_BRANCHES,
	SET_TF_MODAL_TRANSFER_CURRENT,
	SET_TF_MODAL_TRANSFER_EXPIRE,
	SET_TF_MODAL_TRANSFER_ITEMS,
	SET_TF_MODAL_TRANSFER_LOTS,
	SET_TF_MODAL_TRANSFER_ORGS,
	SET_TF_MODAL_TRANSFER_PENDING_ITEMS,
	SET_TF_MODAL_TRANSFER_SELECTED_BRANCH,
	SET_TF_MODAL_TRANSFER_SELECTED_ITEM,
	SET_TF_MODAL_TRANSFER_SELECTED_LOT,
	SET_TF_MODAL_TRANSFER_SELECTED_ORG,
	SET_TF_MODAL_TRANSFER_SELECTED_PENDING_ITEM,
	SET_TF_MODAL_TRANSFER_TRANSFER,
	SET_TF_REQUESTS,
	SET_TF_REQUEST_ITEMS,
	SET_TF_REQUEST_LOADING,
	SET_TF_SEARCH_END_DATE,
	SET_TF_SEARCH_LOCATION,
	SET_TF_SEARCH_NUMBER,
	SET_TF_SEARCH_START_DATE,
	SET_TF_SELECTED_REQUEST,
	SET_TF_SELECTED_REQUEST_ITEM,
	SET_TF_SELECTED_TRANSFER,
	SET_TF_SELECTED_TRANSFER_ITEM,
	SET_TF_TRANSFERS,
	SET_TF_TRANSFER_ITEMS,
	SET_TF_TYPES,
} from "../type"

const init = {
	searchDate: {
		startDate: moment().startOf("month").milliseconds(0).toDate(),
		endDate: moment().endOf("month").milliseconds(0).toDate(),
	},
	isDisplayAll: false,
	searchNum: "",
	searchLocation: "",
	types: [],
	selectedRequest: {
		index: -1,
		request: null,
	},
	selectedRequestItem: {
		index: -1,
		item: null,
	},
	selectedTransfer: {
		index: -1,
		transfer: null,
	},
	selectedTransferItem: {
		index: -1,
		item: null,
	},
	requestTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.hashSymbol,
			ResourceAssistance.Message.location,
			ResourceAssistance.Message.requesterCheck,
			ResourceAssistance.Message.approverCheck,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	requestItemTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.dueDate,
			ResourceAssistance.Message.transferred,
			"",
		],
		body: [],
		rowColor: [],
		overlayMsg: [],
	},
	transferTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.id, ResourceAssistance.Message.date, ResourceAssistance.Message.from, ResourceAssistance.Message.by, ""],
		body: [],
		rowColor: [],
	},
	transferItemTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.lotNum,
			ResourceAssistance.Message.expiredDate,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.status,
			"",
		],
		body: [],
		rowColor: [],
	},
	newRequestModal: {
		searchText: "",
		amount: 0,
		selectedDueDate: Utils.generateDate(0, 0, 0, 0, 0, 0).getTime(),
		toOrgs: [],
		toBranches: [],
		toLocations: [],
		itemTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.name, ResourceAssistance.Message.brandName, ResourceAssistance.Message.unit],
			body: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.amount,
				ResourceAssistance.Message.dueDate,
			],
			body: [],
		},
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		selectedToOrg: {
			index: -1,
			location: null,
		},
		selectedToBranch: {
			index: -1,
			location: null,
		},
		selectedToLocation: {
			index: -1,
			location: null,
		},
	},
	newTransferModal: {
		current: "",
		expired: "",
		transfer: "",
		orgs: [],
		branches: [],
		lots: [],
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.amount,
				ResourceAssistance.Message.dueDate,
			],
			body: [],
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.location,
				ResourceAssistance.Message.lotNum,
				ResourceAssistance.Message.expiredDate,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.transfer,
			],
			body: [],
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		selectedOrg: {
			index: -1,
			location: null,
		},
		selectedBranch: {
			index: -1,
			location: null,
		},
		selectedLot: {
			index: -1,
			lot: null,
		},
	},
	cancelRequestItemModal: {
		reason: "",
	},
}

const transferReducer = (state = init, action) => {
	switch (action.type) {
		case SET_TF_SEARCH_START_DATE:
			return Object.assign({}, state, {
				searchDate: {
					...state.searchDate,
					startDate: action.payload.date,
				},
			})

		case SET_TF_SEARCH_END_DATE:
			return Object.assign({}, state, {
				searchDate: {
					...state.searchDate,
					endDate: action.payload.date,
				},
			})

		case SET_TF_SEARCH_NUMBER:
			return Object.assign({}, state, {
				searchNum: action.payload.text,
			})

		case SET_TF_SEARCH_LOCATION:
			return Object.assign({}, state, {
				searchLocation: action.payload.text,
			})

		case SET_TF_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				isDisplayAll: action.payload.isDisplayAll,
			})

		case SET_TF_REQUEST_LOADING:
			return Object.assign({}, state, {
				requestTable: {
					...state.requestTable,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_TF_TYPES:
			return Object.assign({}, state, {
				types: action.payload.types,
			})

		case SET_TF_REQUESTS:
			return Object.assign({}, state, {
				requestTable: {
					...state.requestTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_TF_SELECTED_REQUEST:
			return Object.assign({}, state, {
				selectedRequest: {
					...state.selectedRequest,
					index: action.payload.index,
					request: action.payload.request,
				},
			})

		case SET_TF_MODAL_REQUEST_TO_ORGS:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					toOrgs: action.payload.locations,
				},
			})

		case SET_TF_MODAL_REQUEST_TO_BRANCHES:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					toBranches: action.payload.locations,
				},
			})

		case SET_TF_MODAL_REQUEST_TO_LOCATIONS:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					toLocations: action.payload.locations,
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_TO_ORG:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedToOrg: {
						index: action.payload.index,
						location: action.payload.location,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_TO_BRANCH:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedToBranch: {
						index: action.payload.index,
						location: action.payload.location,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_TO_LOCATION:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedToLocation: {
						index: action.payload.index,
						location: action.payload.location,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_TYPE:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedType: {
						index: action.payload.index,
						type: action.payload.type,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_SEARCH:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					searchText: action.payload.text,
				},
			})

		case SET_TF_MODAL_REQUEST_ITEMS:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					itemTable: {
						...state.newRequestModal.itemTable,
						original: action.payload.items,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_ITEM:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.item,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_ITEM_AMOUNT:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					amount: action.payload.amount,
				},
			})

		case SET_TF_MODAL_REQUEST_SELECTED_DUE_DATE:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedDueDate: action.payload.date,
				},
			})

		case SET_TF_MODAL_REQUEST_PENDING_ITEMS:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					pendingItemTable: {
						...state.newRequestModal.pendingItemTable,
						original: action.payload.items,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_TF_MODAL_REQUEST_PENDING_SELECTED_ITEM:
			return Object.assign({}, state, {
				newRequestModal: {
					...state.newRequestModal,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.item,
					},
				},
			})

		case SET_TF_REQUEST_ITEMS:
			return Object.assign({}, state, {
				requestItemTable: {
					...state.requestItemTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					overlayMsg: action.payload.overlayMsg,
				},
			})

		case SET_TF_SELECTED_REQUEST_ITEM:
			return Object.assign({}, state, {
				selectedRequestItem: {
					...state.selectedRequestItem,
					index: action.payload.index,
					item: action.payload.item,
				},
			})

		case SET_TF_MODAL_TRANSFER_ORGS: {
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					orgs: action.payload.locations,
				},
			})
		}

		case SET_TF_MODAL_TRANSFER_BRANCHES: {
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					branches: action.payload.locations,
				},
			})
		}

		case SET_TF_MODAL_TRANSFER_LOTS:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					lots: action.payload.lots,
				},
			})

		case SET_TF_MODAL_TRANSFER_ITEMS:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					itemTable: {
						...state.newTransferModal.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_TF_MODAL_TRANSFER_SELECTED_ITEM:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					selectedItem: {
						...state.newTransferModal.selectedItem,
						index: action.payload.index,
						item: action.payload.item,
					},
				},
			})

		case SET_TF_MODAL_TRANSFER_SELECTED_ORG:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					selectedOrg: {
						...state.newTransferModal.selectedOrg,
						index: action.payload.index,
						location: action.payload.location,
					},
				},
			})

		case SET_TF_MODAL_TRANSFER_SELECTED_BRANCH:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					selectedBranch: {
						...state.newTransferModal.selectedBranch,
						index: action.payload.index,
						location: action.payload.location,
					},
				},
			})

		case SET_TF_MODAL_TRANSFER_SELECTED_LOT:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					selectedLot: {
						...state.newTransferModal.selectedLot,
						index: action.payload.index,
						lot: action.payload.lot,
					},
				},
			})

		case SET_TF_MODAL_TRANSFER_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					selectedPendingItem: {
						...state.newTransferModal.selectedPendingItem,
						index: action.payload.index,
						item: action.payload.item,
					},
				},
			})

		case SET_TF_MODAL_TRANSFER_CURRENT:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					current: action.payload.current,
				},
			})

		case SET_TF_MODAL_TRANSFER_EXPIRE:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					expired: action.payload.time,
				},
			})

		case SET_TF_MODAL_TRANSFER_TRANSFER:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					transfer: action.payload.amount,
				},
			})

		case SET_TF_MODAL_TRANSFER_PENDING_ITEMS:
			return Object.assign({}, state, {
				newTransferModal: {
					...state.newTransferModal,
					pendingItemTable: {
						...state.newTransferModal.pendingItemTable,
						original: action.payload.items,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_TF_TRANSFERS:
			return Object.assign({}, state, {
				transferTable: {
					...state.transferTable,
					original: action.payload.transfers,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_TF_SELECTED_TRANSFER:
			return Object.assign({}, state, {
				selectedTransfer: {
					...state.selectedTransfer,
					index: action.payload.index,
					transfer: action.payload.transfer,
				},
			})

		case SET_TF_TRANSFER_ITEMS:
			return Object.assign({}, state, {
				transferItemTable: {
					...state.transferItemTable,
					original: action.payload.items,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_TF_SELECTED_TRANSFER_ITEM:
			return Object.assign({}, state, {
				selectedTransferItem: {
					...state.selectedTransferItem,
					index: action.payload.index,
					item: action.payload.item,
				},
			})

		case SET_TF_MODAL_CANCEL_TRI_REASON:
			return Object.assign({}, state, {
				cancelRequestItemModal: {
					...state.cancelRequestItemModal,
					reason: action.payload.reason,
				},
			})

		default:
			return state
	}
}

export default transferReducer
