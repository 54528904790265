import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import momentPlugin from "@fullcalendar/moment"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import timeGridPlugin from "@fullcalendar/timegrid"
import { Select } from "antd"
import _ from "lodash"
import moment from "moment"
import "moment/locale/th"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { RiRefreshLine } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_OR_CALENDAR_END_DATETIME,
	SET_OR_CALENDAR_START_DATETIME,
	SET_OR_REMARK_DISPLAY,
	SET_OR_SCHEDULES,
	SET_OR_SCHEDULE_DISPLAY,
	SET_OR_SELECTED_EVENT,
	SET_OR_SELECTED_OPERATING_ROOM,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import FCalendar from "~/view/component/calendar/FCalendar"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class OperatingRoomRightTabCalendar extends React.Component {
	constructor(props) {
		super(props)

		this.calendarRef = React.createRef()

		this.onOperatingRoom = this.onOperatingRoom.bind(this)
		this.onCalendarDateTime = this.onCalendarDateTime.bind(this)
		this.onRefresh = this.onRefresh.bind(this)
		this.isOverlapSelectionAllowed = this.isOverlapSelectionAllowed.bind(this)
		this.isSelectionAllowed = this.isSelectionAllowed.bind(this)
		this.isEventClickAllowed = this.isEventClickAllowed.bind(this)
		this.onPopConfirmOk = this.onPopConfirmOk.bind(this)
		this.onPopConfrimNo = this.onPopConfrimNo.bind(this)
		this.onEventDrop = this.onEventDrop.bind(this)
		this.onEventResize = this.onEventResize.bind(this)
		this.onEventRemove = this.onEventRemove.bind(this)
		this.onEvents = this.onEvents.bind(this)
		this.onDatesSet = this.onDatesSet.bind(this)
		this.onEventClick = this.onEventClick.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_OR_CALENDAR_START_DATETIME, moment().startOf("month").milliseconds(0).valueOf())
		this.props.setValue(SET_OR_CALENDAR_END_DATETIME, moment().endOf("month").milliseconds(0).valueOf())
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.schedules, this.props.operatingRoom.schedules)) {
			this.props.setSelected(SET_OR_SELECTED_EVENT, -1, null)
		}
	}

	componentWillUnmount() {}

	shouldComponentUpdate(nextProps, nextState) {
		return true
	}

	loadOperatingRoomSchedules() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getOperatingRoomSchedules,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.operatingRoom.selectedOperatingRoom.operatingRoom.id,
				startDateTime: this.props.operatingRoom.calendarStartDateTime,
				endDateTime: this.props.operatingRoom.calendarEndDateTime,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_SCHEDULES, res.data.schedules)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isClickAllowed(eventInfo) {
		// this.props.opd.selectedORRequest.request &&
		// 					this.props.opd.selectedORRequest.request.id === each.request.id &&
		// 					this.props.opd.selectedORRequest.request.status !== ResourceAssistance.Enum.approved,
		return eventInfo.request.status !== ResourceAssistance.Enum.cancelled
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className="g-1">
					<Col md={2}>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.room)}
							loading={this.props.operatingRoom.isOperatingRoomsLoading}
							value={this.props.operatingRoom.selectedOperatingRoom.operatingRoom ? this.props.operatingRoom.selectedOperatingRoom.index : undefined}
							onChange={this.onOperatingRoom}
						>
							{Utils.renderSelects(this.props.operatingRoom.operatingRooms, false)}
						</Select>
					</Col>
					<Col md={3}>
						<AntDatePicker
							displayRange
							size={"middle"}
							allowClear={false}
							picker={"month"}
							format={"MM-YYYY"}
							values={[moment(this.props.operatingRoom.calendarStartDateTime), moment(this.props.operatingRoom.calendarEndDateTime)]}
							onChange={this.onCalendarDateTime}
						/>
					</Col>
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							disabled={!this.props.operatingRoom.selectedOperatingRoom.operatingRoom}
							onClick={this.onRefresh}
						>
							<RiRefreshLine size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.refresh)}
						</Button>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<FCalendar
							calendarRef={this.calendarRef}
							plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, momentPlugin, momentTimezonePlugin]}
							initialView="dayGridMonth"
							headerToolbar={{
								left: "prev today next",
								center: "title",
								right: "dayGridMonth,timeGridWeek,timeGridDay listMonth",
							}}
							buttonText={{
								today: this.props.intl.formatMessage({ id: ResourceAssistance.Message.today }),
								month: this.props.intl.formatMessage({ id: ResourceAssistance.Message.month }),
								week: this.props.intl.formatMessage({ id: ResourceAssistance.Message.week }),
								day: this.props.intl.formatMessage({ id: ResourceAssistance.Message.day }),
								list: this.props.intl.formatMessage({ id: ResourceAssistance.Message.list }),
							}}
							validRange={{ start: this.props.operatingRoom.calendarStartDateTime, end: this.props.operatingRoom.calendarEndDateTime }}
							displayPopConfirm={false}
							navLinks={true}
							dayMaxEvents={true}
							editable={true}
							selectable={true}
							selectMirror={true}
							selectAllow={this.isSelectionAllowed}
							selectOverlap={this.isOverlapSelectionAllowed}
							eventOverlap={this.isEventOverlap}
							eventClickAllow={this.isEventClickAllowed}
							eventDragable={true}
							eventResizeable={true}
							eventMaxStack={1}
							events={this.getEvents()}
							onEventContent={this.onRenderEventContent} // custom render function
							onEventsSet={this.onEvents} // called after events are initialized/added/changed/removed
							onPopConfirmOk={this.onPopConfirmOk}
							onPopConfirmNo={this.onPopConfrimNo}
							onSelect={this.onSelectDate}
							onEventClick={this.onEventClick}
							onEventDrop={this.onEventDrop}
							onEventResize={this.onEventResize}
							onEventRemove={this.onEventRemove}
							onDatesSet={this.onDatesSet}
						/>
					</Col>
				</Row>
			</Container>
		)
	}

	isEventOverlap(stillEvent, moveEvent) {
		return false
	}

	isOverlapSelectionAllowed(event) {
		if (this.calendarRef.current.getApi().view.type === "dayGridMonth") {
			return true
		}
		return false
	}

	isSelectionAllowed(selectInfo) {
		if (
			this.calendarRef.current.getApi().view.type === "dayGridMonth" ||
			(this.props.operatingRoom.selectedEvent.event && this.props.operatingRoom.selectedEvent.event.extendedProps.isRescheduled)
		) {
			return true
		}
		return false
	}

	isEventClickAllowed(selectInfo) {
		// return (
		// 	selectInfo.event.extendedProps.schedule.request.status === ResourceAssistance.Enum.approved ||
		// 	selectInfo.event.extendedProps.schedule.request.status === ResourceAssistance.Enum.reschedule
		// )
		return true
	}

	//called everytime component re-render(state, props changes)
	getEvents() {
		let events = this.props.operatingRoom.schedules
			// .filter((each) => !this.props.opd.modalOR.newEvents.some((event) => event.id === each.id))
			.map((each, idx) => {
				if (this.props.operatingRoom.selectedEvent.event && this.props.operatingRoom.selectedEvent.event.id === each.id) {
					// let event = this.props.operatingRoom.selectedEvent.event.toPlainObject()
					// return {
					// 	...event,
					// 	start: moment(each.startDateTime).toDate(),
					// 	end: moment(each.endDateTime).toDate(),
					// }
					return this.props.operatingRoom.selectedEvent.event
				} else {
					return {
						id: each.id,
						title: `${each.description} ${each.request.operatingRoomCase} ${each.request.operationProcedure}`,
						start: moment(each.startDateTime).toDate(),
						end: moment(each.endDateTime).toDate(),
						editable: false,
						color:
							each.request.status === ResourceAssistance.Enum.confirmed
								? ResourceAssistance.CSS.Color.dark_green
								: this.props.operatingRoom.selectedRequest.request && this.props.operatingRoom.selectedRequest.request.id === each.request.id
								? ResourceAssistance.CSS.Color.primary
								: each.request.status === ResourceAssistance.Enum.reschedule
								? ResourceAssistance.CSS.Color.warning
								: ResourceAssistance.CSS.Color.info,
						extendedProps: {
							// isEventClickAllowed: this.isClickAllowed(each),
							idx: idx,
							schedule: each,
						},
						display: "block",
					}
				}
				// return event
			})
		// .concat(this.props.opd.modalOR.newEvents)
		return events
	}

	onOperatingRoom(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_SELECTED_OPERATING_ROOM, -1, null)
			return
		}
		this.props.setSelected(SET_OR_SELECTED_OPERATING_ROOM, v, this.props.operatingRoom.operatingRooms[v])
	}

	onCalendarDateTime(v) {
		this.props.setValue(
			SET_OR_CALENDAR_START_DATETIME,
			v ? v[0].startOf("month").milliseconds(0).valueOf() : moment().startOf("month").milliseconds(0).valueOf()
		)
		this.props.setValue(SET_OR_CALENDAR_END_DATETIME, v ? v[1].endOf("month").milliseconds(0).valueOf() : moment().endOf("month").milliseconds(0).valueOf())
	}

	onRefresh() {
		if (this.calendarRef.current) {
			this.calendarRef.current.getApi().gotoDate(this.props.operatingRoom.calendarStartDateTime)
		}
		this.props.setSelected(SET_OR_SELECTED_EVENT, -1, null)
		this.loadOperatingRoomSchedules()
	}

	onPopConfirmOk(selectInfo, isEventClicked) {
		// let calendarApi = this.calendarRef.current.getApi()
		// if (isEventClicked) {
		// 	selectInfo.event.remove()
		// } else if (this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.operatingRoomSchedules.length > 0) {
		// 	this.props.opd.selectedORRequest.request.operatingRoomSchedules.forEach((each) => {
		// 		let event = calendarApi.getEventById(each.id)
		// 		if (event) {
		// 			event.setStart(selectInfo.start)
		// 			event.setEnd(selectInfo.end)
		// 			event.setExtendedProp("isModified", true)
		// 		} else {
		// 			calendarApi.addEvent({
		// 				id: each.id,
		// 				title: this.props.opd.selectedPatient.patient.displayName,
		// 				start: selectInfo.start,
		// 				end: selectInfo.end,
		// 				editable: true,
		// 				color: ResourceAssistance.CSS.Color.dark_green,
		// 				display: "block",
		// 				extendedProps: {
		// 					isModified: true,
		// 					isEventClickAllowed: true,
		// 				},
		// 			})
		// 		}
		// 	})
		// } else if (calendarApi.getEventById("")) {
		// 	let event = calendarApi.getEventById("")
		// 	event.setStart(selectInfo.start)
		// 	event.setEnd(selectInfo.end)
		// } else {
		// 	calendarApi.addEvent({
		// 		title: this.props.opd.selectedPatient.patient.displayName,
		// 		start: selectInfo.start,
		// 		end: selectInfo.end,
		// 		editable: true,
		// 		color: ResourceAssistance.CSS.Color.dark_green,
		// 		display: "block",
		// 		extendedProps: {
		// 			isModified: true,
		// 			isEventClickAllowed: true,
		// 		},
		// 	})
		// }
		// calendarApi.unselect()
	}

	onPopConfrimNo(selectInfo) {
		// this.calendarRef.current.getApi().unselect()
	}

	onSelectDate = (selectInfo) => {
		let calendarApi = selectInfo.view.calendar
		if (selectInfo.view.type === "dayGridMonth") {
			calendarApi.changeView("timeGridDay", selectInfo.start)
		} else {
			// check overlap with other events
			let duration = this.props.operatingRoom.selectedEvent.event.end - this.props.operatingRoom.selectedEvent.event.start
			this.props.operatingRoom.selectedEvent.event.setStart(selectInfo.start)
			this.props.operatingRoom.selectedEvent.event.setEnd(moment(selectInfo.start).add(duration).toDate())
			this.props.onModalDisplayAction(SET_OR_REMARK_DISPLAY, true)
		}
		calendarApi.unselect()
	}

	onEventClick = (selectInfo) => {
		this.props.setSelected(SET_OR_SELECTED_EVENT, selectInfo.event.id, selectInfo.event)
		this.props.onModalDisplayAction(SET_OR_SCHEDULE_DISPLAY, true)
	}

	onEventDrop(selectInfo) {
		this.props.setSelected(SET_OR_SELECTED_EVENT, selectInfo.event.id, selectInfo.event)
		this.props.onModalDisplayAction(SET_OR_REMARK_DISPLAY, true)
	}

	onEventResize(selectInfo) {
		this.props.setSelected(SET_OR_SELECTED_EVENT, selectInfo.event.id, selectInfo.event)
		this.props.onModalDisplayAction(SET_OR_REMARK_DISPLAY, true)
	}

	onEventRemove(selectInfo) {
		// if (!_.isEmpty(selectInfo.event.id)) {
		// 	let params = {
		// 		method: "POST",
		// 		url: ResourceAssistance.Url.opd.deleteOperatingRoomSchedules,
		// 		withCredentials: true,
		// 		headers: {
		// 			"content-type": "application/json",
		// 		},
		// 		data: {
		// 			pid: this.props.opd.selectedPatient.patient.id,
		// 			operatingRoomSchedules: [
		// 				{
		// 					id: selectInfo.event.id,
		// 				},
		// 			],
		// 		},
		// 	}
		// 	let callback = (res) => {
		// 		if (res.data.patients.length > 0) {
		// 			let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
		// 			patients.push(res.data.patients[0])
		// 			this.props.setOPDPatients(patients)
		// 			this.props.setValue(
		// 				SET_OPD_MODAL_OR_SCHEDULES,
		// 				this.props.opd.modalOR.schedules.filter((each) => each.id !== selectInfo.event.id)
		// 			)
		// 		}
		// 	}
		// 	let errorHandler = (error) => {
		// 		this.props.setLoadingAction(SET_PAGE_LOADING, false)
		// 	}
		// 	let reqInterceptor = (config) => {
		// 		this.props.setLoadingAction(SET_PAGE_LOADING, true)
		// 	}
		// 	let resInterceptor = (response) => {
		// 		this.props.setLoadingAction(SET_PAGE_LOADING, false)
		// 	}
		// 	axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		// }
	}

	onEvents(events) {
		events.forEach((event) => {
			if (event.extendedProps.isRescheduled) {
				// console.log(event)
				// console.log(this.props.operatingRoom.schedules[event.extendedProps.idx])
				// this.updateEvent(event)
			}
		})
	}

	onRenderEventContent(eventInfo) {
		return (
			<span style={{ textWrap: "pretty" }}>
				<b>{`${Utils.formatTime(eventInfo.event.start)} - ${Utils.formatTime(eventInfo.event.end)}`}</b>
				<i>&nbsp;{eventInfo.event.title}</i>
			</span>
		)
	}

	onDatesSet(info) {}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OperatingRoomRightTabCalendar))
