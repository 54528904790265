import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setPoAddress, setPoShippingContact, setPoBillingContact } from "~/redux/action"

class PORightContactInfo extends React.Component {
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.po.selectedPo.index !== this.props.po.selectedPo.index) {
			if (this.props.po.selectedPo.po) {
				this.props.setPoAddress(this.props.po.selectedPo.po.pr.location.address.displayName)
			} else {
				this.props.setPoAddress("")
			}
		}
		if (prevProps.po.selectedSupplier.index !== this.props.po.selectedSupplier.index) {
			if (this.props.po.selectedSupplier.supplier) {
				this.props.setPoShippingContact(
					this.props.po.selectedSupplier.supplier.orderDetail.shippingContact ? this.props.po.selectedSupplier.supplier.orderDetail.shippingContact : ""
				)
				this.props.setPoBillingContact(
					this.props.po.selectedSupplier.supplier.orderDetail.billingContact ? this.props.po.selectedSupplier.supplier.orderDetail.billingContact : ""
				)
			} else {
				this.props.setPoShippingContact("")
				this.props.setPoBillingContact("")
			}
		}
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.contactInfo)}</legend>
						<Descriptions size={"default"} contentStyle={{ fontStyle: "italic" }} column={2}>
							<Descriptions.Item label={translate(ResourceAssistance.Message.shipAddress)} span={1}>
								{this.props.po.address}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.contact)} span={1}>
								{this.props.po.shippingContact}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.billingAddress)} span={1}>
								{this.props.po.selectedPo.po ? this.props.po.selectedPo.po.pr.location.address.billingAddress : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.contact)} span={1}>
								{this.props.po.billingContact}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	po: state.purchaseOrder,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPoAddress,
			setPoShippingContact,
			setPoBillingContact,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightContactInfo)
