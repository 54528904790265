import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmIItems, setAdmISelectedItem, setLoadingAction } from "~/redux/action"
import { SET_ADM_ITEM_CONFIG_UNIT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AdmItemSourceModalUnitDetails from "./AdmItemModalUnitDetails"

class AdmItemModalUnit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmItemModalUnit",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_ITEM_CONFIG_UNIT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.configItem.selectedItem.item || !this.props.configItem.modalUnit.selectedUnit.unit) {
			return false
		}
		let target = Object.assign({}, this.props.configItem.selectedUnit.unit, {
			...this.props.configItem.selectedUnit.unit,
			amount: this.props.configItem.modalUnit.amount,
			description: Utils.trim(this.props.configItem.modalUnit.description),
			displayName: this.props.configItem.modalUnit.selectedUnit.unit.displayName,
			oneTime: this.props.configItem.modalUnit.isOneTime,
			sellingPricePerUnit: Utils.BigNumber(this.props.configItem.modalUnit.sellingPricePerUnit).toNumber(),
		})
		return (
			_.isEqual(this.props.configItem.selectedItem.item.unit.displayName, this.props.configItem.modalUnit.selectedUnit.unit.displayName) ||
			_.isEqual(target, this.props.configItem.selectedUnit.unit)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.item.saveUnitConversion}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				unitConversions: [
					{
						id: this.props.configItem.selectedUnit.unit ? this.props.configItem.selectedUnit.unit.id : 0,
						itemSupplierId: this.props.configItem.selectedItem.item.id,
						amount: this.props.configItem.modalUnit.amount,
						unit: this.props.configItem.modalUnit.selectedUnit.unit.displayName,
						description: Utils.trim(this.props.configItem.modalUnit.description),
						oneTime: this.props.configItem.modalUnit.isOneTime,
						sellingPricePerUnit: this.props.configItem.modalUnit.sellingPricePerUnit,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setAdmIItems(res.data.itemSuppliers)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.md}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.unit)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<AdmItemSourceModalUnitDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmItemConfigUnitDisplay,
	login: state.login,
	configItem: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmIItems,
			setAdmISelectedItem,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmItemModalUnit)
