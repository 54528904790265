import { Select } from "antd"
import React, { Component } from "react"
import propTypes from "prop-types"
import { Utils } from "~/utils/Utils"
import _ from "lodash"
import { ResourceAssistance } from "~/i18n"

class ASelect extends Component {
	constructor(props) {
		super(props)
		this.separator = "/"

		this.state = {
			options: [],
		}

		this.onSelectChange = this.onSelectChange.bind(this)
		this.getSelectValue = this.getSelectValue.bind(this)
	}

	componentDidMount() {
		this.setState({
			options: this.props.isSorted
				? this.props.options.sort((a, b) => Utils.sort(a[this.props.displayProperty], b[this.props.displayProperty]))
				: this.props.options,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.options !== this.props.options) {
			this.setState({
				options: this.props.isSorted
					? this.props.options.sort((a, b) => Utils.sort(a[this.props.displayProperty], b[this.props.displayProperty]))
					: this.props.options,
			})
		}
	}

	getSelectionList() {
		return this.props.isSorted ? this.props.options.sort((a, b) => Utils.sort(a[this.props.displayProperty], b[this.props.displayProperty])) : this.props.options
	}

	onSelectChange(value) {
		if (this.props.mode === ResourceAssistance.Select.mode.multiple || this.props.mode === ResourceAssistance.Select.mode.tags) {
			this.props.onSelectChange(
				value
					.sort((a, b) => a - b)
					.map((idx) => this.state.options[idx][this.props.displayProperty])
					.join(this.separator)
			)
		} else {
			this.props.onSelectChange(value > -1 ? this.state.options[value][this.props.displayProperty] || "" : "")
		}
	}

	getSelectValue() {
		let value = []
		this.state.options.forEach((item, idx) => {
			if (_.includes(this.props.value, item[this.props.displayProperty])) {
				value.push(idx)
			}
		})
		return value
	}

	render() {
		return (
			<Select
				disabled={this.props.disabled}
				required={this.props.required}
				showSearch={this.props.showSearch}
				allowClear={this.props.allowClear}
				size={this.props.size}
				maxTagCount={this.props.maxTagCount}
				mode={this.props.mode}
				style={this.props.style}
				placeholder={this.props.placeholder}
				status={this.props.required && (!this.props.value ? "error" : undefined)}
				optionFilterProp="children"
				filterOption={this.props.filterOption}
				filterSort={this.props.isSorted ? this.props.filterSort : () => {}}
				value={this.getSelectValue()}
				onChange={this.onSelectChange}
			>
				{Utils.renderSelects(this.state.options, this.props.includeDefaultOption, this.props.defaultOptionValue, this.props.displayProperty, this.props.isSorted)}
			</Select>
		)
	}
}

ASelect.propTypes = {
	disabled: propTypes.bool,
	required: propTypes.bool,
	showSearch: propTypes.bool,
	allowClear: propTypes.bool,
	size: propTypes.string,
	maxTagCount: propTypes.string,
	mode: propTypes.string,
	style: propTypes.object,
	placeholder: propTypes.any,
	filterOption: propTypes.func,
	filterSort: propTypes.func,
	value: propTypes.string,
	onSelectChange: propTypes.func,
	options: propTypes.array,
	includeDefaultOption: propTypes.bool,
	defaultOptionValue: propTypes.number,
	displayProperty: propTypes.string,
	isSorted: propTypes.bool,
}

ASelect.defaultProps = {
	disabled: false,
	required: false,
	showSearch: false,
	allowClear: true,
	size: "middle",
	maxTagCount: "",
	mode: "",
	style: { textAlign: "center" },
	placeholder: "",
	filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
	filterSort: (optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase()),
	value: "",
	onSelectChange: () => {},
	options: [],
	includeDefaultOption: false,
	defaultOptionValue: -1,
	displayProperty: "displayName",
	isSorted: true,
}

export default ASelect
