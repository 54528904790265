import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"

class SearchableCriteria extends React.Component {
	render() {
		return (
			<Container>
				<Row>
					<Col />
					<Col md={"auto"}>
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Hospitel.patientId)}</InputGroup.Text>
							<FormControl
								name={"pid"}
								autoFocus
								required
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.identificationNumber || this.props.firstName || this.props.lastName}
								value={this.props.pid}
								onChange={this.props.onChange}
							/>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Hospitel.identification)}</InputGroup.Text>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.text}
								name={"identificationNumber"}
								disabled={this.props.pid || this.props.firstName || this.props.lastName}
								value={this.props.identificationNumber}
								onChange={this.props.onChange}
							/>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.firstName}>
							{(placeholder) => (
								<FormControl
									required={_.isEmpty(this.props.lastName)}
									type={ResourceAssistance.FormControl.type.text}
									name={"firstName"}
									placeholder={placeholder}
									disabled={this.props.identificationNumber || this.props.pid}
									value={this.props.firstName}
									onChange={this.props.onChange}
								/>
							)}
						</FormattedMessage>
					</Col>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.lastName}>
							{(placeholder) => (
								<FormControl
									required={_.isEmpty(this.props.firstName)}
									type={ResourceAssistance.FormControl.type.text}
									name={"lastName"}
									placeholder={placeholder}
									disabled={this.props.identificationNumber || this.props.pid}
									value={this.props.lastName}
									onChange={this.props.onChange}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Container>
		)
	}
}

SearchableCriteria.propTypes = {
	onChange: PropTypes.func,
}

SearchableCriteria.defaultProps = {
	onChange: () => {},
}

export default SearchableCriteria
