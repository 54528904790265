import React from "react"
import { Button, Col, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { FaUserInjured } from "react-icons/fa"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalDoctorOrderDetails from "./HospitelModalDoctorOrderDetails"

class HospitelModalDoctorOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalDoctorOrder",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onPatientClinicalRecord = this.onPatientClinicalRecord.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.hospitel.modalDoctorOrder.pendingItemTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.hospitel.selectedHospitel.hospitel.admission.id,
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				orders: this.props.hospitel.modalDoctorOrder.pendingItemTable.original.map((order) => ({
					categoryId: order.categoryId,
					code: Utils.trim(order.code),
					fsCode: order.fsCode,
					description: order.brand ? Utils.trim(order.displayName) + ` (${order.brand})` : Utils.trim(order.displayName),
					unit: order.unit ? order.unit.displayName : "",
					startDateTime: order.startDateTime,
					endDateTime: order.endDateTime,
					pricePerUnit: order.sellPricePerUnit,
					notes: Utils.trim(order.notes),
					categoryName: order.categoryName,
					categoryNum: order.categoryNum,
					durationQty: order.durationQty,
					duration: order.duration,
					prn: order.isPRN,
					oneTimeOnly: order.isOneTimeOnly,
					stat: order.isSTAT,
					takeHome: order.isTakeHome,
					nonDisposable: order.isNonDisposable,
					healthcarePlan: order.healthcarePlan,
					exclusiveDays: order.exclusiveDays,
					warnings: order.item ? order.item.warnings : "",
					locationAccountsReceivable: this.props.hospitel.department.accountsReceivable,
				})),
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	onPatientClinicalRecord(event) {
		let patient = {
			...this.props.hospitel.selectedPatient.patient,
			clinicalRecord: this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord,
		}

		this.props.setValue(SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT, patient)
		this.props.onModalDisplayAction(SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullWidth}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Hospitel.doctor)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<HospitelModalDoctorOrderDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.info} onClick={this.onPatientClinicalRecord}>
						<FaUserInjured size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.clinicalRecord)}
					</Button>
					<Col />
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isDoctorOrderDisplay,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrder))
