import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import PharmacyModalReturnDoctorOrder from "./PharmacyModalReturnDoctorOrder"
import PharmacyModalReturnDispensedOrder from "./PharmacyModalReturnDispensedOrder"
import PharmacyModalReturnItem from "./PharmacyModalReturnItem"

class PharmacyModalReturnDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<PharmacyModalReturnDoctorOrder />
				<PharmacyModalReturnDispensedOrder />
				<PharmacyModalReturnItem ref={this.props.lotRef} {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalReturnDetails)
