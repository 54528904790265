import { Card, Descriptions } from "antd"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_SELECTABLE_ACCOUNTS_CODE_DISPLAY, SET_WARNING_DISPLAY } from "~/redux/type"
import CMenuSelectableAccountsCodeDetails from "./CMenuSelectableAccountsCodeDetails"
import _ from "lodash"

class CMenuSelectableAccountsCode extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.MENU.selectableAccountingCode.save)) {
					this.reset()
				}
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_SELECTABLE_ACCOUNTS_CODE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return !this.props.SAC.selectedAccountingCode.accountingCode
	}

	onClose() {
		this.reset()
	}

	onDone(e) {
		e.preventDefault()
		e.stopPropagation()
		let selectedAccountingCode = this.props.SAC.selectedAccountingCode.accountingCode
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningId(ResourceAssistance.ID.MENU.selectableAccountingCode.save)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.save)}
				bordered={false}
				hoverable
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					body: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{selectedAccountingCode.chartOfAccounts.fullCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
						{selectedAccountingCode.chartOfAccounts.displayName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
						{selectedAccountingCode.chartOfAccounts.description}
					</Descriptions.Item>
					{selectedAccountingCode.debit ? (
						<Descriptions.Item label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
							{translate(ResourceAssistance.Message.debit)}
						</Descriptions.Item>
					) : (
						<Descriptions.Item label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
							{translate(ResourceAssistance.Message.credit)}
						</Descriptions.Item>
					)}
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				size={ResourceAssistance.Modal.size.md}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsCode)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<CMenuSelectableAccountsCodeDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isMenuSelectableAccountsCodeDisplay,
	warning: state.modal.warning,
	modal: state.modal,
	SAC: state.menu.selectableAccountsCode,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CMenuSelectableAccountsCode)
