import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../print/PrintableDataFactory"
import moment from "moment"

class ReportFinanceDataFactory extends PrintableDataFactory {
	static generateGeneralLedgerExcelData = (GLs, org, startDateTime, endDateTime) => {
		let glHeaders = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.accountsCode }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.id }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.transactionDescription }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.totalBroughtForward }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.debit }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.credit }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
		]
		let TBHeaders = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.chartOfAccounts }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.totalBroughtForward }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.january }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.february }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.march }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.april }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.may }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.june }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.july }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.august }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.september }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.october }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.november }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.december }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
		]
		let TBGeneralLedgers = GLs.filter((gl) => !_.isEmpty(gl.trans))
		let excel = {
			filename: org.displayName + "_" + Utils.formatDate(startDateTime) + "_" + Utils.formatDate(endDateTime),
			sheets: [
				{
					name: this.getIntl().formatMessage({ id: ResourceAssistance.Message.generalLedger }),
					headers: glHeaders,
					data: GLs.reduce((data, gl) => {
						let cumulativeTotal = Utils.BigNumber(gl.totalDebit).minus(gl.totalCredit)
						let total = cumulativeTotal.plus(gl.debit).minus(gl.credit)
						let glBody = [
							gl.code,
							gl.displayName,
							"",
							"",
							"",
							Utils.formatNumWithComma(cumulativeTotal.toFixed(2)),
							Utils.formatNumWithComma(gl.debit.toFixed(2)),
							Utils.formatNumWithComma(gl.credit.toFixed(2)),
							Utils.formatNumWithComma(total.toFixed(2)),
						]
						data.push(_.zipObject(Object.keys(Object.assign({}, glHeaders)), glBody))
						if (gl.trans) {
							gl.trans
								.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
								.forEach((tran) => {
									cumulativeTotal = cumulativeTotal.plus(tran.debit).minus(tran.credit)
									let tranBody = [
										"",
										"",
										Utils.formatDate(tran.dateTime),
										tran.documentCode + "-" + tran.documentId,
										tran.transactionDescription,
										"",
										Utils.formatNumWithComma(Utils.BigNumber(tran.debit).toFixed(2)),
										Utils.formatNumWithComma(Utils.BigNumber(tran.credit).toFixed(2)),
										Utils.formatNumWithComma(cumulativeTotal.toFixed(2)),
									]
									data.push(_.zipObject(Object.keys(Object.assign({}, glHeaders)), tranBody))
								})
						}
						return data
					}, []),
				},
				{
					name: "TB",
					headers: TBHeaders,
					data: Object.entries(
						Utils.groupBy(
							TBGeneralLedgers.reduce((trans, gl) => {
								return trans.concat(gl.trans)
							}, []),
							"code"
						)
					).map(([key, values]) => {
						let monthMap = new Map()
						let foundGL = TBGeneralLedgers.find((each) => each.code === key)
						for (let i = 1; i <= 12; i++) {
							monthMap.set(i, [])
						}
						values.forEach((each) => {
							let month = moment(each.dateTime).month()
							monthMap.get(month + 1).push(each)
						})
						let jan = monthMap.get(1).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let feb = monthMap.get(2).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let mar = monthMap.get(3).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let apr = monthMap.get(4).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let may = monthMap.get(5).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let jun = monthMap.get(6).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let jul = monthMap.get(7).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let aug = monthMap.get(8).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let sep = monthMap.get(9).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let oct = monthMap.get(10).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let nov = monthMap.get(11).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let dec = monthMap.get(12).reduce((total, cur) => {
							return total.plus(cur.debit).minus(cur.credit)
						}, Utils.BigNumber(0))
						let body = [
							key,
							foundGL.displayName,
							Utils.formatNumWithComma(Utils.BigNumber(foundGL.totalDebit).minus(foundGL.totalCredit).toFixed(2)),
							!jan.isEqualTo(0) ? Utils.formatNumWithComma(jan.toFixed(2)) : "",
							!feb.isEqualTo(0) ? Utils.formatNumWithComma(feb.toFixed(2)) : "",
							!mar.isEqualTo(0) ? Utils.formatNumWithComma(mar.toFixed(2)) : "",
							!apr.isEqualTo(0) ? Utils.formatNumWithComma(apr.toFixed(2)) : "",
							!may.isEqualTo(0) ? Utils.formatNumWithComma(may.toFixed(2)) : "",
							!jun.isEqualTo(0) ? Utils.formatNumWithComma(jun.toFixed(2)) : "",
							!jul.isEqualTo(0) ? Utils.formatNumWithComma(jul.toFixed(2)) : "",
							!aug.isEqualTo(0) ? Utils.formatNumWithComma(aug.toFixed(2)) : "",
							!sep.isEqualTo(0) ? Utils.formatNumWithComma(sep.toFixed(2)) : "",
							!oct.isEqualTo(0) ? Utils.formatNumWithComma(oct.toFixed(2)) : "",
							!nov.isEqualTo(0) ? Utils.formatNumWithComma(nov.toFixed(2)) : "",
							!dec.isEqualTo(0) ? Utils.formatNumWithComma(dec.toFixed(2)) : "",
							Utils.formatNumWithComma(
								Utils.BigNumber(foundGL.totalDebit)
									.minus(foundGL.totalCredit)
									.plus(jan)
									.plus(feb)
									.plus(mar)
									.plus(apr)
									.plus(may)
									.plus(jun)
									.plus(jul)
									.plus(aug)
									.plus(sep)
									.plus(oct)
									.plus(nov)
									.plus(dec)
									.toFixed(2)
							),
						]
						return _.zipObject(Object.keys(Object.assign({}, TBHeaders)), body)
					}),
				},
			],
		}
		return excel
	}

	static generateAccountsPayableAgingExcelData = (accountsPayableAgings, org, endDateTime) => {
		let agingHeaders = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.code }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.supplierName }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.numberOfInvoices }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.notDueYet }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue1To30 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue31To90 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue91To180 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue181To360 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDueOver360 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
		]
		let supplierHeaders = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.dueDate }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.paymentPlan }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.documentNum }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceId }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.accountsCode }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.paymentType }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
		]
		let groupedAccountsPayables = Object.entries(
			accountsPayableAgings.reduce((grouped, cur) => {
				let key = cur.accountsPayableRC
					? cur.accountsPayableRC.supplierCode + ResourceAssistance.PROGRAM_DEFINED.split + cur.accountsPayableRC.supplierName
					: cur.accountsPayablePI.supplierCode + ResourceAssistance.PROGRAM_DEFINED.split + cur.accountsPayablePI.supplierName
				if (!grouped[key]) {
					grouped[key] = []
				}
				let found = grouped[key].find((each) => each.documentCode === cur.documentCode && each.documentId === cur.documentId)
				if (found) {
					found["total"] = Utils.BigNumber(found.total).plus(cur.credit).toFixed(2)
					if (!found["accountCodes"].find((each) => each.code === cur.code && each.name === cur.name)) {
						found["accountCodes"].push({
							code: cur.code,
							name: cur.name,
						})
					}
				} else {
					grouped[key].push(
						Object.assign(
							{},
							{
								accountsPayable: cur.accountsPayableRC ? cur.accountsPayableRC : cur.accountsPayablePI,
								paymentTermDateTime: cur.accountsPayableRC ? cur.accountsPayableRC.paymentTermDateTime : cur.accountsPayablePI.paymentTermDateTime,
								documentCode: cur.documentCode,
								documentId: cur.documentId,
								total: cur.credit,
								accountCodes: [
									{
										code: cur.code,
										name: cur.name,
									},
								],
							}
						)
					)
				}
				return grouped
			}, {})
		).sort((a, b) => Utils.sort(a[0], b[0]))
		let excel = {
			filename: ResourceAssistance.CONSTANT.ACCOUNTS_PAYABLE_AGING + "_" + org.displayName + "_" + Utils.formatDate(endDateTime),
			sheets: [
				{
					name: this.getIntl().formatMessage({ id: ResourceAssistance.Message.supplier }),
					dataSet: [
						{
							xSteps: 6,
							columns: [this.getIntl().formatMessage({ id: ResourceAssistance.Message.overDueDays })],
							data: [],
						},
						{
							columns: agingHeaders,
							data: groupedAccountsPayables.map(([key, values]) => {
								let keys = key.split(ResourceAssistance.PROGRAM_DEFINED.split)
								let current = Utils.getAgingTotal(values, endDateTime, Infinity, 0)
								let _1To30 = Utils.getAgingTotal(values, endDateTime, 1, 30)
								let _31To90 = Utils.getAgingTotal(values, endDateTime, 31, 90)
								let _91To180 = Utils.getAgingTotal(values, endDateTime, 91, 180)
								let _181To360 = Utils.getAgingTotal(values, endDateTime, 181, 360)
								let over360 = Utils.getAgingTotal(values, endDateTime, 361, Infinity)
								let total = current.plus(_1To30).plus(_31To90).plus(_91To180).plus(_181To360).plus(over360)
								return [
									keys[0],
									keys[1],
									Utils.formatNumWithComma(values.length),
									Utils.formatNumWithComma(current.toFixed(2)),
									Utils.formatNumWithComma(_1To30.toFixed(2)),
									Utils.formatNumWithComma(_31To90.toFixed(2)),
									Utils.formatNumWithComma(_91To180.toFixed(2)),
									Utils.formatNumWithComma(_181To360.toFixed(2)),
									Utils.formatNumWithComma(over360.toFixed(2)),
									Utils.formatNumWithComma(total.toFixed(2)),
								]
							}),
						},
					],
				},
			],
		}
		groupedAccountsPayables.forEach(([key, values]) => {
			excel.sheets.push({
				name: key.replace(/\\/, "_"),
				headers: supplierHeaders,
				data: values.map((eachAP) => {
					let body = [
						Utils.formatDate(eachAP.paymentTermDateTime),
						eachAP.accountsPayable.paymentPlan ? eachAP.accountsPayable.paymentPlan.id : "",
						eachAP.documentCode + "-" + eachAP.documentId,
						Utils.formatDate(eachAP.accountsPayable.invoiceDateTime),
						eachAP.accountsPayable.invoice,
						eachAP.accountCodes.reduce((str, cur) => {
							return str.concat(cur.code, "-", cur.name, "\n")
						}, ""),
						eachAP.accountsPayable.payment,
						Utils.formatNumWithComma(Utils.BigNumber(eachAP.total).toFixed(2)),
					]
					return _.zipObject(Object.keys(Object.assign({}, supplierHeaders)), body)
				}),
			})
		})
		return excel
	}

	static generateAccountsReceivableExcelData = (accountsReceivableAgings, org, endDateTime) => {
		let agingHeaders = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.code }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.numberOfInvoices }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.notDueYet }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue1To30 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue31To90 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue91To180 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDue181To360 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.pastDueOver360 }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
		]
		let insuranceCompanyHeaders = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.dueDate }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.receiptId }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.documentNum }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceId }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.visitId }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientId }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.accountsCode }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
		]

		let groupedAccountsReceivables = Object.entries(
			accountsReceivableAgings.reduce((grouped, cur) => {
				let key =
					cur.accountsReceivable.contractBillingChild.contractBilling.insuranceCompanyCode +
					ResourceAssistance.PROGRAM_DEFINED.split +
					cur.accountsReceivable.contractBillingChild.contractBilling.insuranceCompany
				if (!grouped[key]) {
					grouped[key] = []
				}
				let found = grouped[key].find((each) => each.documentCode === cur.documentCode && each.documentId === cur.documentId)
				if (found) {
					found["total"] = Utils.BigNumber(found.total).plus(cur.debit).toFixed(2)
					if (!found["accountCodes"].find((each) => each.code === cur.code && each.name === cur.name)) {
						found["accountCodes"].push({
							code: cur.code,
							name: cur.name,
						})
					}
				} else {
					grouped[key].push(
						Object.assign(
							{},
							{
								accountsReceivable: cur.accountsReceivable,
								paymentTermDateTime: cur.accountsReceivable.contractBillingChild.contractBilling.dueDateTime,
								documentCode: cur.documentCode,
								documentId: cur.documentId,
								total: cur.debit,
								accountCodes: [
									{
										code: cur.code,
										name: cur.name,
									},
								],
							}
						)
					)
				}
				return grouped
			}, {})
		).sort((a, b) => Utils.sort(a[0], b[0]))
		let excel = {
			filename: ResourceAssistance.CONSTANT.ACCOUNTS_RECEIVABLE_AGING + "_" + org.displayName + "_" + Utils.formatDate(endDateTime),
			sheets: [
				{
					name: this.getIntl().formatMessage({ id: ResourceAssistance.Message.insuranceCompany }),
					dataSet: [
						{
							xSteps: 6,
							columns: [this.getIntl().formatMessage({ id: ResourceAssistance.Message.overDueDaysDays })],
							data: [],
						},
						{
							columns: agingHeaders,
							data: groupedAccountsReceivables.map(([key, values]) => {
								let keys = key.split(ResourceAssistance.PROGRAM_DEFINED.split)
								let current = Utils.getAgingTotal(values, endDateTime, Infinity, 0)
								let _1To30 = Utils.getAgingTotal(values, endDateTime, 1, 30)
								let _31To90 = Utils.getAgingTotal(values, endDateTime, 31, 90)
								let _91To180 = Utils.getAgingTotal(values, endDateTime, 91, 180)
								let _181To360 = Utils.getAgingTotal(values, endDateTime, 181, 360)
								let over360 = Utils.getAgingTotal(values, endDateTime, 361, Infinity)
								let total = current.plus(_1To30).plus(_31To90).plus(_91To180).plus(_181To360).plus(over360)
								return [
									keys[0],
									keys[1],
									Utils.formatNumWithComma(values.length),
									Utils.formatNumWithComma(current.toFixed(2)),
									Utils.formatNumWithComma(_1To30.toFixed(2)),
									Utils.formatNumWithComma(_31To90.toFixed(2)),
									Utils.formatNumWithComma(_91To180.toFixed(2)),
									Utils.formatNumWithComma(_181To360.toFixed(2)),
									Utils.formatNumWithComma(over360.toFixed(2)),
									Utils.formatNumWithComma(total.toFixed(2)),
								]
							}),
						},
					],
				},
			],
		}
		groupedAccountsReceivables.forEach(([key, values]) => {
			excel.sheets.push({
				name: key.replace(/\\/, "_"),
				headers: insuranceCompanyHeaders,
				data: values.map((eachAR) => {
					let body = [
						Utils.formatDate(eachAR.paymentTermDateTime),
						eachAR.accountsReceivable.debtPlan ? eachAR.accountsReceivable.debtPlan.id : "",
						eachAR.documentCode + "-" + eachAR.documentId,
						Utils.formatDate(eachAR.accountsReceivable.creationDateTime),
						eachAR.accountsReceivable.id,
						eachAR.accountsReceivable.billing.billingPlan.admission.id,
						eachAR.accountsReceivable.billing.billingPlan.admission.patient.id,
						eachAR.accountsReceivable.billing.billingPlan.admission.patient.displayName,
						eachAR.accountCodes.reduce((str, cur) => {
							return str.concat(cur.code, "-", cur.name, "\n")
						}, ""),
						Utils.formatNumWithComma(Utils.BigNumber(eachAR.total).toFixed(2)),
					]
					return _.zipObject(Object.keys(Object.assign({}, insuranceCompanyHeaders)), body)
				}),
			})
		})
		return excel
	}
}

export { ReportFinanceDataFactory }
