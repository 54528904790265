import React, { Fragment } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onModalDisplayAction, onModalElementDisplayAction } from "~/redux/action"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import AbstractTypeView from "../AbstractTypeView"
import AdmCategoryCOA from "./AdmCategoryInventoryCOA"
import { SET_ADM_NEW_TYPE_DISPLAY_INVENTORY } from "~/redux/type"
import { ResourceAssistance } from "~/i18n"
import { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import { SvgDoctor, SvgNurse } from "~/icons"

class AdmCategoryInventory extends React.Component {
	constructor(props) {
		super(props)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
	}

	onEditBtnClick(event) {
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_INVENTORY, true)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	onAddBtnClick(event) {
		this.props.setSelectedType(-1, null)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_INVENTORY, true)
		this.props.dispatch(onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)).then(() => {
			// let newType = document.getElementById("displayParent")
			// console.log((newType.hidden = false))
		})
	}

	render() {
		return (
			<Fragment>
				<AbstractTypeView
					scrollableTableData={this.props.type.types}
					onAddBtnClick={this.onAddBtnClick}
					onEditBtnClick={this.onEditBtnClick}
					onRefreshClick={this.props.onRefreshClick}
					displayBtn
					buttonColors={[ResourceAssistance.Button.variant.outlineInfo, ResourceAssistance.Button.variant.outlineInfo]}
					btnMessages={["", ""]}
					btnTypes={[EBtnType.POPOVER, EBtnType.POPOVER]}
					btnIcons={[<SvgDoctor width={ResourceAssistance.ReactIcon.size} />, <SvgNurse width={ResourceAssistance.ReactIcon.size} />]}
				/>
				<AdmCategoryCOA />
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mpaDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, onModalElementDisplayAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mpaDispatchToProps)(AdmCategoryInventory)
