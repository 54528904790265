import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setHospitelPatients,
	setHospitelVitalSigns,
	setLoadingAction,
	setSelected,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY, SET_HOSPITEL_SELECTED_VITAL_SIGN, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import IPDRightVitalSignGraph from "./IPDRightVitalSignGraph"

class HospitelRightVitalSignRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelRightVitalSignRow",
		}

		this.onSelectVitalSign = this.onSelectVitalSign.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedHospitel.hospitel) {
			this.props.setHospitelVitalSigns(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.vitalSigns)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.modalVitalSign.delete)) {
				this.deleteVitalSign()
			}
		}
		if (
			!_.isEqual(prevProps.hospitel.selectedHospitel.hospitel, this.props.hospitel.selectedHospitel.hospitel) ||
			!_.isEqual(prevProps.hospitel.orderEndDateTime, this.props.hospitel.orderEndDateTime)
		) {
			this.props.setSelected(SET_HOSPITEL_SELECTED_VITAL_SIGN, -1, null)
			if (this.props.hospitel.selectedHospitel.hospitel) {
				this.props.setHospitelVitalSigns(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.vitalSigns)
			} else {
				this.props.setHospitelVitalSigns([])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_HOSPITEL_SELECTED_VITAL_SIGN, -1, null)
	}

	deleteVitalSign() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.deleteVitalSign,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.hospitel.selectedPatient.patient.id,
				vitalSigns: [
					{
						id: this.props.hospitel.selectedVitalSign.vitalSign.id,
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectVitalSign(vitalSign, index) {
		this.props.setSelected(SET_HOSPITEL_SELECTED_VITAL_SIGN, index, this.props.hospitel.vitalSignTable.filtered[index])
	}

	onNew() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY, true)
		this.props.setSelected(SET_HOSPITEL_SELECTED_VITAL_SIGN, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedVS = this.props.hospitel.vitalSignTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.modalVitalSign.delete)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
            ${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.vitalSign })}
            ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(selectedVS.dateTime)}
            `
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return this.props.graphic ? (
			<IPDRightVitalSignGraph />
		) : (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.hospitel.vitalSignTable}
						onClick={this.onSelectVitalSign}
						isClearHighlight={this.props.hospitel.selectedVitalSign.index === -1}
						highlightedRow={this.props.hospitel.selectedVitalSign.index}
						extra={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, this.props.login.user.roles) &&
							this.props.hospitel.selectedHospitel.hospitel &&
							!this.props.hospitel.selectedHospitel.hospitel.dischargeSummary && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelPatients,
			setHospitelVitalSigns,
			setLoadingAction,
			setSelected,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelRightVitalSignRow))
