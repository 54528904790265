export const SET_FRONT_INVENTORY_DISPLAY_EXPIRED = "SET_FRONT_INVENTORY_DISPLAY_EXPIRED"
export const SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK = "SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK"
export const SET_FRONT_INVENTORY_DISTRIBUTIONS = "SET_FRONT_INVENTORY_DISTRIBUTIONS"
export const SET_FRONT_INVENTORY_EDIT_DISPLAY = "SET_FRONT_INVENTORY_EDIT_DISPLAY"
export const SET_FRONT_INVENTORY_EXPIRE_DATETIME = "SET_FRONT_INVENTORY_EXPIRE_DATETIME"
export const SET_FRONT_INVENTORY_INVENTORIES = "SET_FRONT_INVENTORY_INVENTORIES"
export const SET_FRONT_INVENTORY_INVENTORIES_LOADING = "SET_FRONT_INVENTORY_INVENTORIES_LOADING"
export const SET_FRONT_INVENTORY_LOTS = "SET_FRONT_INVENTORY_LOTS"
export const SET_FRONT_INVENTORY_MODAL_SPLIT_LOTS = "SET_FRONT_INVENTORY_MODAL_SPLIT_LOTS"
export const SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT = "SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT"
export const SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION = "SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION"
export const SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS = "SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS"
export const SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING = "SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING"
export const SET_FRONT_INVENTORY_MODAL_WASTE_LOTS = "SET_FRONT_INVENTORY_MODAL_WASTE_LOTS"
export const SET_FRONT_INVENTORY_MODAL_WASTE_REASON = "SET_FRONT_INVENTORY_MODAL_WASTE_REASON"
export const SET_FRONT_INVENTORY_MODAL_WASTE_SELECTED_LOT = "SET_FRONT_INVENTORY_MODAL_WASTE_SELECTED_LOT"
export const SET_FRONT_INVENTORY_ORGS = "SET_FRONT_INVENTORY_ORGS"
export const SET_FRONT_INVENTORY_ORGS_LOADING = "SET_FRONT_INVENTORY_ORGS_LOADING"
export const SET_FRONT_INVENTORY_SEARCH_CODE = "SET_FRONT_INVENTORY_SEARCH_CODE"
export const SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME = "SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME"
export const SET_FRONT_INVENTORY_SELECTED_DEPARTMENT = "SET_FRONT_INVENTORY_SELECTED_DEPARTMENT"
export const SET_FRONT_INVENTORY_SELECTED_INVENTORY = "SET_FRONT_INVENTORY_SELECTED_INVENTORY"
export const SET_FRONT_INVENTORY_SELECTED_TYPE = "SET_FRONT_INVENTORY_SELECTED_TYPE"
export const SET_FRONT_INVENTORY_SPLIT_DISPLAY = "SET_FRONT_INVENTORY_SPLIT_DISPLAY"
export const SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY = "SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY"
export const SET_FRONT_INVENTORY_TYPES = "SET_FRONT_INVENTORY_TYPES"
export const SET_FRONT_INVENTORY_TYPES_LOADING = "SET_FRONT_INVENTORY_TYPES_LOADING"
export const SET_FRONT_INVENTORY_WASTE_DISPLAY = "SET_FRONT_INVENTORY_WASTE_DISPLAY"
export const SET_FRONT_INVENTORY_WASTE_EDIT_DISPLAY = "SET_FRONT_INVENTORY_WASTE_EDIT_DISPLAY"
export const SET_FRONT_INVENTORY_WASTES = "SET_FRONT_INVENTORY_WASTES"
export const SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS = "SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS"
export const SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION = "SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION"
