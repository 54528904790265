import { Select } from "antd"
import React from "react"
import { Col, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSearchText, setSelected } from "~/redux/action"
import {
	SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT,
	SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import { ANTIGEN_METHOD, EAntigenMethod } from "./CLModalNegativeDetails"

class CLModalNegativeAntigen extends React.Component {
	constructor(props) {
		super(props)

		this.onCategory = this.onCategory.bind(this)
		this.onAntigenComment = this.onAntigenComment.bind(this)
	}

	onCategory(value) {
		this.props.setSelected(
			SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.lab.modalNegative.antigenTypes[value] : null
		)
	}

	onAntigenComment(value) {
		this.props.setSelected(
			SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.lab.modalNegative.selectedType.type.labComments[value] : null
		)
	}
	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.antigen)}</legend>
				<Row>
					<Col>
						<Row>
							<Col>
								<InputGroup>
									<InputGroup.Radio
										value={EAntigenMethod.Chromatography}
										name={ANTIGEN_METHOD}
										checked={
											this.props.lab.modalNegative.method === ResourceAssistance.CONSTANT.IMMUNO_CHROMATOGRAPHY_ASSAY
										}
										onChange={this.props.onMethod}
									/>
									<InputGroup.Text>{translate(ResourceAssistance.Hospitel.chromatography)}</InputGroup.Text>
								</InputGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<InputGroup>
									<InputGroup.Radio
										value={EAntigenMethod.Fluorescence}
										name={ANTIGEN_METHOD}
										checked={
											this.props.lab.modalNegative.method === ResourceAssistance.CONSTANT.IMMUNO_FLUORESCENCE_ASSAY
										}
										onChange={this.props.onMethod}
									/>
									<InputGroup.Text>{translate(ResourceAssistance.Hospitel.fluorescence)}</InputGroup.Text>
								</InputGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<Select
									showSearch
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder={translate(ResourceAssistance.Hospitel.antigen)}
									disabled={this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.COVID_19_ANTIGEN}
									status={this.props.lab.modalNegative.selectedType.type ? undefined : "error"}
									onChange={this.onCategory}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.COVID_19_ANTIGEN &&
										this.props.lab.modalNegative.selectedType.type
											? this.props.lab.modalNegative.selectedType.index
											: undefined
									}
								>
									{Utils.renderSelects(this.props.lab.modalNegative.antigenTypes, false)}
								</Select>
							</Col>
						</Row>
						<Row>
							<Col>
								<Select
									showSearch
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder={translate(ResourceAssistance.Message.antigenComment)}
									disabled={
										this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.COVID_19_ANTIGEN ||
										!this.props.lab.modalNegative.selectedType.type
									}
									status={this.props.lab.modalNegative.selectedComment.comment ? undefined : "error"}
									loading={this.props.lab.modalNegative.isAntigenLoading}
									onChange={this.onAntigenComment}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.COVID_19_ANTIGEN &&
										this.props.lab.modalNegative.selectedComment.comment
											? this.props.lab.modalNegative.selectedComment.index
											: undefined
									}
								>
									{Utils.renderSelects(
										this.props.lab.modalNegative.selectedType.type
											? this.props.lab.modalNegative.selectedType.type.labComments
											: [],
										false,
										-1,
										"name"
									)}
								</Select>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<GInput
									autoSize
									minRows={6}
									placeholder={translate(ResourceAssistance.Message.antigenComment)}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.COVID_19_ANTIGEN &&
										this.props.lab.modalNegative.selectedComment.comment
											? this.props.lab.modalNegative.selectedComment.comment.comments
											: ""
									}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSearchText,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalNegativeAntigen)
