import { ResourceAssistance } from "~/i18n"
import {
	SET_ADMIN_PERMISSION_PERMISSIONS,
	SET_ADMIN_PERMISSION_SEARCH_NAME,
	SET_ADMIN_ROLE_DISPLAY_ALL_IND,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_ACTIVE_IND,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_DESCRIPTION,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_NAME,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_PENDING_PERMISSIONS,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PENDING_PERMISSION,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PERMISSION,
	SET_ADMIN_ROLE_PERMISSIONS,
	SET_ADMIN_ROLE_ROLES,
	SET_ADMIN_ROLE_SEARCH_NAME,
	SET_ADMIN_ROLE_SELECTED_ROLE,
	SET_ADMIN_USER_DISPLAY_ALL_IND,
	SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_CONFIRM_PASSWORD,
	SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_PASSWORD,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING,
	SET_ADMIN_USER_MODAL_NEW_USER_ACTIVE,
	SET_ADMIN_USER_MODAL_NEW_USER_CONFIRM_PASSWORD,
	SET_ADMIN_USER_MODAL_NEW_USER_EMAIL,
	SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME,
	SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME,
	SET_ADMIN_USER_MODAL_NEW_USER_LOGIN_NAME,
	SET_ADMIN_USER_MODAL_NEW_USER_NICKNAME,
	SET_ADMIN_USER_MODAL_NEW_USER_PASSWORD,
	SET_ADMIN_USER_MODAL_NEW_USER_PENDING_ROLES,
	SET_ADMIN_USER_MODAL_NEW_USER_ROLES,
	SET_ADMIN_USER_MODAL_NEW_USER_ROLES_LOADING,
	SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_PENDING_ROLE,
	SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE,
	SET_ADMIN_USER_ROLES,
	SET_ADMIN_ROLE_ROLES_LOADING,
	SET_ADMIN_USER_SEARCH_FIRST_NAME,
	SET_ADMIN_USER_SEARCH_LAST_NAME,
	SET_ADMIN_USER_SELECTED_USER,
	SET_ADMIN_USER_USERS,
	SET_ADMIN_USER_USERS_LOADING,
	SET_ADMIN_PERMISSION_PERMISSIONS_LOADING,
	SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG,
	SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG,
	SET_ADM_USER_ROLE_TYPES,
	SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE,
	SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM,
	SET_ADMIN_USER_MODAL_NEW_USER_PHONE,
} from "~/redux/type"

const init = {
	user: {
		searchFirstName: "",
		searchLastName: "",
		displayInactive: true,
		roleTypes: [],
		userTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.signature,
				ResourceAssistance.Message.firstName,
				ResourceAssistance.Message.lastName,
				ResourceAssistance.Message.firstNameEng,
				ResourceAssistance.Message.lastNameEng,
				ResourceAssistance.Message.email,
				ResourceAssistance.Message.phone,
				ResourceAssistance.Message.nickName,
				ResourceAssistance.Message.loginName,
				ResourceAssistance.Message.role,
				ResourceAssistance.Message.licenseNum,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		roleTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.role, ResourceAssistance.Message.description],
			body: [],
		},
		selectedUser: {
			index: -1,
			user: null,
		},
		modalNewUser: {
			licenseNum: "",
			lastName: "",
			firstName: "",
			lastNameEng: "",
			firstNameEng: "",
			email: "",
			phone: "",
			nickname: "",
			loginName: "",
			password: "",
			confirmPassword: "",
			isActive: true,
			roleTable: {
				original: [],
				filtered: [],
				header: [ResourceAssistance.Message.role, ResourceAssistance.Message.description],
				body: [],
				isLoading: false,
			},
			pendingRoleTable: {
				original: [],
				filtered: [],
				header: [ResourceAssistance.Message.role, ResourceAssistance.Message.description],
				body: [],
			},
			selectedRole: {
				index: -1,
				role: null,
			},
			selectedPendingRole: {
				index: -1,
				role: null,
			},
			selectedRoleType: {
				index: -1,
				type: null,
			},
		},
		modalChangePassword: {
			password: "",
			confirmPassword: "",
		},
	},
	role: {
		searchName: "",
		displayInactive: true,
		selectedRole: {
			index: -1,
			role: null,
		},
		roleTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		permissionTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.permission, ResourceAssistance.Message.description],
			body: [],
		},
		modalNewRole: {
			name: "",
			description: "",
			isActive: true,
			permissionTable: {
				original: [],
				filtered: [],
				header: [ResourceAssistance.Message.permission, ResourceAssistance.Message.description],
				body: [],
				isLoading: false,
			},
			pendingPermissionTable: {
				original: [],
				filtered: [],
				header: [ResourceAssistance.Message.permission, ResourceAssistance.Message.description],
				body: [],
			},
			selectedPermission: {
				index: -1,
				permission: null,
			},
			selectedPendingPermission: {
				index: -1,
				permission: null,
			},
		},
	},
	permission: {
		searchName: "",
		permissionTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			isLoading: false,
		},
	},
}

const userConfigReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADMIN_USER_USERS:
			return Object.assign({}, state, {
				user: {
					...state.user,
					userTable: {
						...state.user.userTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_ADMIN_USER_USERS_LOADING:
			return Object.assign({}, state, {
				user: {
					...state.user,
					userTable: {
						...state.user.userTable,
						isLoading: action.payload.value,
					},
				},
			})

		case SET_ADMIN_USER_SEARCH_FIRST_NAME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					searchFirstName: action.payload.text,
				},
			})

		case SET_ADMIN_USER_SEARCH_LAST_NAME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					searchLastName: action.payload.text,
				},
			})

		case SET_ADMIN_USER_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				user: {
					...state.user,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_USER_SELECTED_USER:
			return Object.assign({}, state, {
				user: {
					...state.user,
					selectedUser: {
						index: action.payload.index,
						user: action.payload.user,
					},
				},
			})

		case SET_ADMIN_USER_ROLES:
			return Object.assign({}, state, {
				user: {
					...state.user,
					roleTable: {
						...state.user.roleTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_ADMIN_ROLE_ROLES_LOADING:
			return Object.assign({}, state, {
				user: {
					...state.user,
					roleTable: {
						...state.user.roleTable,
						isLoading: action.payload.value,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						lastName: action.payload.lastName,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						firstName: action.payload.firstName,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						lastNameEng: action.payload.value,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						firstNameEng: action.payload.value,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_EMAIL:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						email: action.payload.email,
					},
				},
			})
		case SET_ADMIN_USER_MODAL_NEW_USER_PHONE:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						phone: action.payload.value,
					},
				},
			})
		case SET_ADMIN_USER_MODAL_NEW_USER_NICKNAME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						nickname: action.payload.nickname,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_LOGIN_NAME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						loginName: action.payload.loginName,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_PASSWORD:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						password: action.payload.password,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_CONFIRM_PASSWORD:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						confirmPassword: action.payload.confirmPassword,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_ACTIVE:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						isActive: action.payload.isActive,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_ROLES:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						roleTable: {
							...state.user.modalNewUser.roleTable,
							original: action.payload.original,
							filtered: action.payload.filtered,
							body: action.payload.body,
						},
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_ROLES_LOADING:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						roleTable: {
							...state.user.modalNewUser.roleTable,
							isLoading: action.payload.value,
						},
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						selectedRole: {
							index: action.payload.index,
							role: action.payload.role,
						},
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_PENDING_ROLES:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						pendingRoleTable: {
							...state.user.modalNewUser.pendingRoleTable,
							original: action.payload.original,
							filtered: action.payload.filtered,
							body: action.payload.body,
						},
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_PENDING_ROLE:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						selectedPendingRole: {
							index: action.payload.index,
							role: action.payload.role,
						},
					},
				},
			})

		case SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_PASSWORD:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalChangePassword: {
						...state.user.modalChangePassword,
						password: action.payload.password,
					},
				},
			})

		case SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_CONFIRM_PASSWORD:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalChangePassword: {
						...state.user.modalChangePassword,
						confirmPassword: action.payload.confirmPassword,
					},
				},
			})

		case SET_ADMIN_ROLE_ROLES:
			return Object.assign({}, state, {
				role: {
					...state.role,
					roleTable: {
						...state.role.roleTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})

		case SET_ADMIN_ROLE_SEARCH_NAME:
			return Object.assign({}, state, {
				role: {
					...state.role,
					searchName: action.payload.text,
				},
			})

		case SET_ADMIN_ROLE_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				role: {
					...state.role,
					displayInactive: action.payload.isDisplay,
				},
			})

		case SET_ADMIN_ROLE_SELECTED_ROLE:
			return Object.assign({}, state, {
				role: {
					...state.role,
					selectedRole: {
						index: action.payload.index,
						role: action.payload.role,
					},
				},
			})

		case SET_ADMIN_ROLE_PERMISSIONS:
			return Object.assign({}, state, {
				role: {
					...state.role,
					permissionTable: {
						...state.role.permissionTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						permissionTable: {
							...state.role.modalNewRole.permissionTable,
							original: action.payload.original,
							filtered: action.payload.filtered,
							body: action.payload.body,
						},
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						permissionTable: {
							...state.role.modalNewRole.permissionTable,
							isLoading: action.payload.value,
						},
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_NAME:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						name: action.payload.name,
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_DESCRIPTION:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						description: action.payload.description,
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_ACTIVE_IND:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						isActive: action.payload.isActive,
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PERMISSION:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						selectedPermission: {
							index: action.payload.index,
							permission: action.payload.permission,
						},
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_PENDING_PERMISSIONS:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						pendingPermissionTable: {
							...state.role.modalNewRole.pendingPermissionTable,
							original: action.payload.original,
							filtered: action.payload.filtered,
							body: action.payload.body,
						},
					},
				},
			})

		case SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PENDING_PERMISSION:
			return Object.assign({}, state, {
				role: {
					...state.role,
					modalNewRole: {
						...state.role.modalNewRole,
						selectedPendingPermission: {
							index: action.payload.index,
							permission: action.payload.permission,
						},
					},
				},
			})

		case SET_ADMIN_PERMISSION_PERMISSIONS:
			return Object.assign({}, state, {
				permission: {
					...state.permission,
					permissionTable: {
						...state.permission.permissionTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})

		case SET_ADMIN_PERMISSION_PERMISSIONS_LOADING:
			return Object.assign({}, state, {
				permission: {
					...state.permission,
					permissionTable: {
						...state.permission.permissionTable,
						isLoading: action.payload.value,
					},
				},
			})

		case SET_ADMIN_PERMISSION_SEARCH_NAME:
			return Object.assign({}, state, {
				permission: {
					...state.permission,
					searchName: action.payload.text,
				},
			})

		case SET_ADM_USER_ROLE_TYPES:
			return Object.assign({}, state, {
				user: {
					...state.user,
					roleTypes: action.payload.objs,
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						selectedRoleType: {
							index: action.payload.index,
							type: action.payload.selected,
						},
					},
				},
			})

		case SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM:
			return Object.assign({}, state, {
				user: {
					...state.user,
					modalNewUser: {
						...state.user.modalNewUser,
						licenseNum: action.payload.value,
					},
				},
			})

		default:
			return state
	}
}
export default userConfigReducer
