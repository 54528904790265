const IntraAnesthesiaRecord = {
	patientSafety: "Patient Safety",
	anestheticMachineEquipmentsChecked: "Anesthetic machine & equipments checked",
	pressurePointsChecked: "Pressure points checked",
	armsRestrained: "Arms restrained",
	eyesCare: "Eyes Care",
	ointment: "Ointment",
	gel: "Gel",
	taped: "Taped",
	warming: "Warming",
	blanket: "Blanket",
	forceAirWarmer: "Force air warmer",
	HMEFilter: "HME/Filter",
	fluidWarmer: "Fluid warmer",
	preinductionAssessment: "Preinduction Assessment",
	changesFromThePreOpAssessment: "Changes from the Pre-Op assessment",
	significantChange: "Significant change",
	PtIdentified: "Pt. identified",
	consentSigned: "Consent signed",
	riskForAspiration: "Risk for aspiration",
	HxPELabReviewed: "Hx & PE & Lab reviewed",
	predictedDifficultAirway: "Predicted difficult airway",
	consciousness: "Consciousness",
	alert: "Alert",
	drowsiness: "Drowsiness",
	stupor: "Stupor",
	coma: "Coma",
	patientCondition: "Patient's Condition",
	position: "Position",
	respiration: "Respiration",
	supine: "Supine",
	LUD: "LUD",
	lateralRight: "Lateral Right",
	lateralLeft: "Lateral Left",
	prone: "Prone",
	semilateral: "Semilateral",
	lithotomy: "Lithotomy",
	sitting: "Sitting",
	jackknife: "Jackknife",
	trendelenburg: "Trendelenburg",
	reverseTrend: "Reverse Trend",
	spontaneous: "Spontaneous",
	assisted: "Assisted",
	continuous: "Continuous",
	preoxygention: "Preoxygention",
	rapidSequence: "Rapid Sequence",
	cricoidPressure: "Cricoid Pressure",
	induction: "Induction",
	intravenous: "Intravenous",
	inhalation: "Inhalation",
	maintenance: "Maintenance",
	balanced: "Balanced",
	TIVA: "TIVA",
	spinal: "Spinal",
	epidural: "Epidural",
	CSE: "CSE",
	caudal: "Caudal",
	brachialPlexus: "Brachial Plexus",
	asepticTechniqueDone: "Aseptic Technique Done",
	techniqueApproach: "Technique/Approach",
	equipment: "Equipment",
	ultrasound: "Ultrasound",
	nerveSimulator: "Nerve Simulator",
	noMuscleContractionAt: "No muscle contraction At 0.2mA",
	site: "Site",
	needleSize: "Needle Size",
	cut: "Cut",
	pencil: "Pencil",
	touhy: "Touhy",
	catheter: "Catheter",
	skinMarking: "Skin Marking",
	punctureAttemptingCounts: "Puncture Attempting Counts",
	anesthesiaLevel: "Anesthesia Level",
	agentUsed: "Agent Used",
	airwayManagement: "Airway Management",
	underMask: "Under Mask",
	oralAirwayNo: "Oral Airway No.",
	LMANo: "LMA No.",
	tracheostomyNo: "Tracheostomy No.",
	nasalAirwayNo: "Nasal Airway No.",
	ETTubeNo: "ET tube No.",
	cuff: "Cuff",
	uncuff: "Uncuff",
	oral: "Oral",
	nasal: "Nasal",
	depth: "Depth",
	PVC: "PVC",
	flexible: "Flexible",
	RAE: "RAE",
	MLT: "MLT",
	DLTRtNo: "DLT:Rt No.",
	DLTLtNo: "DLT:Lt No.",
	laryngealView: "Laryngeal View",
	difficulty: "Difficulty",
	attempt: "Attempt",
	intubationTechnique: "Intubation Technique",
	awake: "Awake",
	alreadyIntubated: "Already Intubated",
	blind: "Blind",
	flexibleFiberopticEndoscope: "Flexible Fiberoptic/Endoscope",
	videoLaryngoscope: "Video Laryngoscope",
	directLaryngoscope: "Direct Laryngoscope",
	bladeType: "Blade Type",
	macintosh: "Macintosh",
	miller: "Miller",
	bladeSize: "Blade Size",
	styletUsed: "Stylet Used",
	assistedDevices: "Assisted Devices",
	breathSoundChecked: "Breath Sound Checked",
	ETCO2Present: "ETCO2 Present",
	afterIntubation: "After Intubation",
	ETTubeSecuredWithTape: "ET Tube Secured With Tape",
	cuffedInflation: "Cuffed Inflation",
	minimalOcclusiveVolume: "Minimal Occlusive Volume",
	manometer2030cmH2O: "Manometer(20-30cmH2O)",
	breathingSystem: "Breathing System",
	semiClosedCircle: "Semi-Closed/Circle",
	semiOpenJacksonReesBain: "Semi-Open:Jackson Rees/Brain",
	beforeExtubation: "Before Extubation",
	afterExtubation: "After Extubation",
	verbalCommand: "Verbal Command",
	dyspnea: "Dyspnea",
	coughOnSuction: "Cough On Suction",
	UAO: "UAO",
	adeqSpontVentilator: "Adeq Spont Ventilator",
	wheezing: "Wheezing",
	swallowingGag: "Swallowing Gag",
	cyanosis: "Cyanosis",
	handGripHeadLift: "Hand Grip/Head Lift",
	babyDelivery: "Baby Delivery",
	timeOfBirth: "Time Of Birth",
	patientTransfer: "Patient Transfer",
	conditionBeforeTransfer: "Condition Before Transfer",
	oxygenCanula: "Oxygen Canula",
	oxygenMaskWithBag: "Oxygen Mask With Bag",
	tPiece: "T-Piece",
	ambulatoryBag: "Ambulatory Bag",
	airwaySupport: "Airway Support",
	retainedETTube: "Retained ET Tube",
	tracheostomy: "Tracheostomy",
	oralNasalAirway: "Oral/Nasal Airway",
	arousableOnSimulation: "Arousable On Simulation",
	nonResponding: "Non-Responding",
	transferTo: "Transfer To",
	none: "None",
	PACU: "PACU",
	ICU: "ICU",
	ward: "Ward",
}

export default IntraAnesthesiaRecord
