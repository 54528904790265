import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_NOTIFICATION_PATIENT } from "~/redux/type"
import NotificationPatientInfo from "./NotificationPatientInfo"
import NotificationTable from "./NotificationTable"

class NotificationDetails extends React.Component {
	componentWillUnmount() {
		this.props.setValue(SET_NOTIFICATION_PATIENT, null)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<NotificationPatientInfo />
				<NotificationTable />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDetails)
