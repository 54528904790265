import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setFORequests, setSelected } from "~/redux/action"
import { SET_FO_SELECTED_FINANCIAL_REQUEST } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FinancialOperationsLeftRequest extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.FO.financialPlanTable.filtered, this.props.FO.financialPlanTable.filtered)) {
			let index = this.props.FO.selectedFinancialPlan.financialPlan
				? this.props.FO.financialPlanTable.filtered.findIndex(
						(financialPlan) => financialPlan.id === this.props.FO.selectedFinancialPlan.financialPlan.id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(
					SET_FO_SELECTED_FINANCIAL_REQUEST,
					index,
					this.props.FO.financialPlanTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_FO_SELECTED_FINANCIAL_REQUEST, -1, null)
			}
		}
		if (
			!_.isEqual(prevProps.FO.filterFinancialPlanId, this.props.FO.filterFinancialPlanId) ||
			!_.isEqual(prevProps.FO.filters, this.props.FO.filters)
		) {
			this.props.setFORequests(this.props.FO.financialPlanTable.original)
		}
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_FO_SELECTED_FINANCIAL_REQUEST, index, this.props.FO.financialPlanTable.filtered[index])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.FO.financialPlanTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.FO.selectedFinancialPlan.index === -1}
						highlightedRow={this.props.FO.selectedFinancialPlan.index}
					/>
					{this.props.FO.financialPlanTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setFORequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FinancialOperationsLeftRequest))
