import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setRegModalExpenseEstServices, setSelected } from "~/redux/action"
import { SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RegModalExpenseEstService extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.reg.modalExpenseEst.searchDescription !== this.props.reg.modalExpenseEst.searchDescription ||
			!_.isEqual(prevProps.reg.modalExpenseEst.selectedType, this.props.reg.modalExpenseEst.selectedType)
		) {
			this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER, -1, null)
			this.props.setRegModalExpenseEstServices(this.props.reg.modalExpenseEst.selectedType.type.services)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_ORDER, index, this.props.reg.modalExpenseEst.serviceTable.filtered[index])
	}

	render() {
		return (
			<Row>
				<Col>
					<ScrollableTable
						style={{ minHeight: "300px", maxHeight: "300px" }}
						striped
						hover
						enableHighlight
						onClick={this.onSelectItem}
						data={this.props.reg.modalExpenseEst.serviceTable}
						isClearHighlight={this.props.reg.modalExpenseEst.selectedOrder.index === -1}
						highlightedRow={this.props.reg.modalExpenseEst.selectedOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setRegModalExpenseEstServices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstService))
