import EN from "@fullcalendar/core/locales/en-gb"
import TH from "@fullcalendar/core/locales/th"
import FullCalendar from "@fullcalendar/react"
import _ from "lodash"
import "moment/locale/th"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { LOCALES, ResourceAssistance, translate } from "~/i18n"
import "./f_calendar.css"
import { Popconfirm } from "antd"
import { Utils } from "~/utils/Utils"

class FCalendar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isEventClicked: false,
			visible: false,
			title: "",
			selectInfo: undefined,
			position: { x: 0, y: 0 },
		}
		switch (this.props.language.locale) {
			case LOCALES.THAI:
				this.state = {
					locale: TH,
				}
				break

			default:
				this.state = {
					locale: EN,
				}
				break
		}

		this.onConfirm = this.onConfirm.bind(this)
		this.onCancel = this.onCancel.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.language, this.props.language)) {
			switch (this.props.language.locale) {
				case LOCALES.THAI:
					this.setState({
						locale: TH,
					})
					break

				default:
					this.setState({
						locale: EN,
					})
					break
			}
		}
	}

	setPopConfirm(selectInfo, isEventClicked) {
		const { start, end } = selectInfo.event ? selectInfo.event : selectInfo
		this.setState({
			isEventClicked: isEventClicked,
			visible: true,
			title: `${Utils.formatDateTime(start)} - ${Utils.formatDateTime(end)}`,
			position: {
				x: selectInfo.jsEvent.clientX,
				y: selectInfo.jsEvent.clientY,
			},
			selectInfo: selectInfo,
		})
	}

	onConfirm() {
		if (this.props.onPopConfirmOk) {
			this.props.onPopConfirmOk(this.state.selectInfo, this.state.isEventClicked)
		}
		this.setState({
			visible: false,
		})
	}

	onCancel() {
		if (this.props.onPopConfirmNo) {
			this.props.onPopConfirmNo(this.state.selectInfo)
		}
		this.setState({
			visible: false,
		})
	}

	render() {
		return (
			<>
				{this.state.visible && (
					<div
						style={{
							position: "fixed",
							left: `${this.state.position.x}px`,
							top: `${this.state.position.y}px`, // Adjust this value to align with the cell
						}}
					>
						<Popconfirm
							okType={this.state.isEventClicked ? "danger" : "primary"}
							title={this.state.title}
							open={this.state.visible}
							onConfirm={this.onConfirm}
							onCancel={this.onCancel}
							okText={this.state.isEventClicked ? translate(ResourceAssistance.Message.delete) : translate(ResourceAssistance.Message.confirm)}
							cancelText={translate(ResourceAssistance.Message.close)}
						/>
					</div>
				)}
				<FullCalendar
					eventDisplay="list-item"
					ref={this.props.calendarRef}
					height={"100%"}
					plugins={this.props.plugins}
					initialView={this.props.initialView}
					initialDate={this.props.initialDate}
					validRange={this.props.validRange}
					now={this.props.now}
					// timeZone="Asia/Bangkok"
					locale={this.state.locale}
					headerToolbar={this.props.headerToolbar}
					buttonText={this.props.buttonText}
					navLinks={this.props.navLinks}
					dayMaxEvents={this.props.dayMaxEvents}
					editable={this.props.editable}
					selectable={this.props.selectable}
					selectMirror={this.props.selectMirror}
					selectOverlap={this.props.selectOverlap}
					initialEvents={this.props.initialEvents} // alternatively, use the `events` setting to fetch from a feed
					events={this.props.events}
					eventContent={this.props.onEventContent} // custom render function
					selectAllow={this.props.selectAllow}
					eventMaxStack={this.props.eventMaxStack}
					select={(selectInfo) => {
						if (this.props.displayPopConfirm && selectInfo.view.type !== "dayGridMonth") {
							this.setPopConfirm(selectInfo, false)
						}
						this.props.onSelect(selectInfo)
					}}
					datesSet={(info) => {
						if (this.props.displayPopConfirm) {
							this.setState({
								visible: false,
							})
						}
						if (this.props.onDatesSet) {
							this.props.onDatesSet(info)
						}
					}}
					unselectAuto={false}
					unselect={() => {
						if (this.props.displayPopConfirm) {
							this.setState({
								visible: false,
							})
						}
					}}
					eventClick={(selectInfo) => {
						if (this.props.eventClickAllow && !this.props.eventClickAllow(selectInfo)) {
							return
						}
						if (this.props.displayPopConfirm && selectInfo.view.type !== "dayGridMonth") {
							this.setPopConfirm(selectInfo, true)
						}
						if (this.props.onEventClick) {
							this.props.onEventClick(selectInfo)
						}
					}}
					eventOverlap={this.props.eventOverlap}
					eventStartEditable={this.props.eventDragable}
					eventDrop={(selectInfo) => {
						if (this.props.onEventDrop) {
							this.props.onEventDrop(selectInfo)
						}
					}}
					eventDurationEditable={this.props.eventResizeable}
					eventResize={(selectInfo) => {
						if (this.props.onEventDrop) {
							this.props.onEventResize(selectInfo)
						}
					}}
					eventsSet={this.props.onEventsSet} // called after events are initialized/added/changed/removed
					// you can update a remote database when these fire:
					//         eventAdd={function(){}}
					//         eventChange={function(){}}
					eventRemove={this.props.onEventRemove}
				/>
			</>
		)
	}
}

FCalendar.propTypes = {}

FCalendar.defaultProps = {}

const mapStateToProps = (state) => ({
	language: state.language,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FCalendar))
