import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setDebtModalEditInvoiceBillingStatementGroups, setSelected } from "~/redux/action"
import { SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class DebtModalEditInvoiceBSGroup extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		this.props.setDebtModalEditInvoiceBillingStatementGroups(
			this.props.debt.selectedInvoice.invoice.billing.billingStatements
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.debt.modalEditInvoice.billingStatementGroupTable.filtered,
				this.props.debt.modalEditInvoice.billingStatementGroupTable.filtered
			)
		) {
			let index = this.props.debt.modalEditInvoice.selectedGroup.group
				? this.props.debt.modalEditInvoice.billingStatementGroupTable.filtered.findIndex(
						(group) => group[0] === this.props.debt.modalEditInvoice.selectedGroup.group[0]
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(
					SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP,
					index,
					this.props.debt.modalEditInvoice.billingStatementGroupTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP, -1, null)
		this.props.setDebtModalEditInvoiceBillingStatementGroups([])
	}

	onSelectRow(group, index) {
		this.props.setSelected(
			SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP,
			index,
			this.props.debt.modalEditInvoice.billingStatementGroupTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						data={this.props.debt.modalEditInvoice.billingStatementGroupTable}
						isClearHighlight={this.props.debt.modalEditInvoice.selectedGroup.index === -1}
						highlightedRow={this.props.debt.modalEditInvoice.selectedGroup.index}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtModalEditInvoiceBillingStatementGroups,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalEditInvoiceBSGroup))
