import React from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { loadPRsAction, selectPRAction, setPrSearchLocation, setSearchNumAction, setTextAreaAction } from "~/redux/action"
import { SET_COMMENT, SET_REASON } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"
import PRLeftFirstRow from "./PRLeftFirstRow"
import PRLeftFourthRow from "./PRLeftFourthRow"
import PRLeftThirdRow from "./PRLeftThirdRow"

class PRLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRLeft",
		}

		this.onSearchChange = this.onSearchChange.bind(this)
		this.onTextAreaChange = this.onTextAreaChange.bind(this)
		this.onSearchLocation = this.onSearchLocation.bind(this)
	}

	onTextAreaChange(event, type) {
		this.props.setTextAreaAction(type, event.target.value)
	}

	onSearchChange(event) {
		event.preventDefault()
		let filteredOrders = this.props.pr.prs.filter((order) => {
			return order.id.includes(event.target.value)
		})
		this.props.setSearchNumAction(event.target.value)
		this.props.loadPRsAction(this.props.pr.prs, filteredOrders)
		this.props.selectPRAction(-1, null)
	}

	onSearchLocation(event) {
		this.props.setPrSearchLocation(event.target.value)
		this.props.loadPRsAction(this.props.pr.prs, this.props.pr.prs)
		this.props.selectPRAction(-1, null)
	}

	textAreaField(label, isDisabled, type, value, variant) {
		return (
			<Row>
				<Col md="auto">
					<label variant={variant}>{label}</label>
				</Col>
				<Col>
					<FormControl
						as={ResourceAssistance.FormControl.as.textArea}
						disabled={isDisabled}
						rows="5"
						onChange={(event) => {
							this.onTextAreaChange(event, type)
						}}
						value={value}
					/>
				</Col>
			</Row>
		)
	}

	renderApproverLabel(label, approver, date) {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md={3}>
					<label>{translate(label)}</label>
				</Col>
				{/* <Col className={ResourceAssistance.CSS.SpacingUtility.mr(2)}> */}
				<Col>
					<FormControl
						size={ResourceAssistance.FormControl.size.sm}
						type={ResourceAssistance.FormControl.type.text}
						disabled
						value={approver && approver ? approver : ""}
					/>
				</Col>
				<Col md={5}>
					<FormControl
						size={ResourceAssistance.FormControl.size.sm}
						type={ResourceAssistance.FormControl.type.text}
						disabled
						value={date ? Utils.formatDateTime(date) : ""}
					/>
				</Col>
			</Row>
		)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<PRLeftFirstRow {...this.props} />

				<Row>
					<Col>
						<SearchBox
							num={2}
							placeholders={[ResourceAssistance.Message.number, ResourceAssistance.Message.location]}
							callbacks={[this.onSearchChange, this.onSearchLocation]}
						/>
					</Col>
				</Row>

				<PRLeftThirdRow {...this.props} />
				<PRLeftFourthRow {...this.props} />

				<Row>
					<Col>
						{this.textAreaField(
							translate(ResourceAssistance.Message.note),
							!(
								Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) &&
								this.props.pr.selectedPR.index !== -1 &&
								!this.props.pr.approver.requesterApproved
							),
							SET_COMMENT,
							this.props.pr.selectedPR.index !== -1 ? this.props.pr.comment : ""
						)}
					</Col>
				</Row>

				<Row>
					<Col>
						{this.textAreaField(
							translate(ResourceAssistance.Message.unapproveReason),
							!(
								Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Inspector, this.props.login.user.roles) ||
								Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Approval, this.props.login.user.roles)
							) ||
								this.props.pr.selectedPR.index === -1 ||
								this.props.isAllApproved() ||
								this.props.pr.prItemsTableData.pendingItems.length === 0 ||
								!this.props.pr.approver.requesterApproved,
							SET_REASON,
							this.props.pr.selectedPR.index !== -1 ? this.props.pr.reason : "",
							ResourceAssistance.Label.variant.red
						)}
					</Col>
				</Row>

				{this.renderApproverLabel(
					ResourceAssistance.Message.requester,
					this.props.selectedPR.pr !== null && this.props.selectedPR.pr !== undefined && this.props.selectedPR.pr.requester
						? this.props.selectedPR.pr.requester.firstName + " " + this.props.selectedPR.pr.requester.lastName
						: "",
					this.props.selectedPR.pr ? this.props.selectedPR.pr.requesterApprovedDateTime : null
				)}

				{this.renderApproverLabel(
					ResourceAssistance.Message.inspector,
					this.props.selectedPR.pr && this.props.selectedPR.pr.inspector
						? this.props.selectedPR.pr.inspector.firstName + " " + this.props.selectedPR.pr.inspector.lastName
						: "",
					this.props.selectedPR.pr ? this.props.selectedPR.pr.inspectorApprovedDateTime : null
				)}

				{this.renderApproverLabel(
					ResourceAssistance.Message.approver,
					this.props.selectedPR.pr && this.props.selectedPR.pr.approver
						? this.props.selectedPR.pr.approver.firstName + " " + this.props.selectedPR.pr.approver.lastName
						: "",
					this.props.selectedPR.pr ? this.props.selectedPR.pr.approverApprovedDateTime : null
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	approver: state.purchaseRequest.approver,
	selectedPR: state.purchaseRequest.selectedPR,
	selectedType: state.purchaseRequest.selectedType,
	pr: state.purchaseRequest,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			loadPRsAction,
			selectPRAction,
			setPrSearchLocation,
			setSearchNumAction,
			setTextAreaAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PRLeft)
