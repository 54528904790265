import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalNurseOrdeItems, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalMedicalSupplyItem extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.hospitel.modalNurseOrder.searchName !== this.props.hospitel.modalNurseOrder.searchName ||
			!_.isEqual(prevProps.hospitel.modalNurseOrder.selectedType, this.props.hospitel.modalNurseOrder.selectedType)
		) {
			this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM, -1, null)
			this.props.setHospitelModalNurseOrdeItems(this.props.hospitel.modalNurseOrder.itemTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM,
			index,
			this.props.hospitel.modalNurseOrder.itemTable.filtered[index]
		)
	}

	render() {
		return (
			<Fragment>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col id={ResourceAssistance.ID.HOSPITEL.hospitel.modalNurseOrder.itemTable}>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectItem}
							data={this.props.hospitel.modalNurseOrder.itemTable}
							isClearHighlight={this.props.hospitel.modalNurseOrder.selectedItem.index === -1}
						/>
						{this.props.hospitel.modalNurseOrder.itemTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setHospitelModalNurseOrdeItems,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalMedicalSupplyItem))
