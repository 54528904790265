import { ResourceAssistance } from "~/i18n"
import {
	SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DEFAULTS,
	SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DISPLAY_ALL_IND,
	SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_ASSESSMENT,
	SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_DEFAULTS,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_DISPLAY_ALL_IND,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_DISPLAY_ALL_IND,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS,
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DISPLAY_ALL_IND,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_INTERVENTION,
	SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME,
	SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_NAME,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_NAME,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME,
} from "~/redux/type"

const init = {
	assessment: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedAssessment: {
			index: -1,
			assessment: null,
		},
		assessmentTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ""],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewAssessment: {
			name: "",
			description: "",
			isActive: true,
		},
	},
	focus: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedFocus: {
			index: -1,
			focus: null,
		},
		focusTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ""],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewFocus: {
			name: "",
			description: "",
			isActive: true,
		},
	},
	intervention: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedIntervention: {
			index: -1,
			intervention: null,
		},
		interventionTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ""],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewIntervention: {
			name: "",
			description: "",
			isActive: true,
		},
	},
	evaluation: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedEvaluation: {
			index: -1,
			evaluation: null,
		},
		evaluationTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ""],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewEvaluation: {
			name: "",
			description: "",
			isActive: true,
		},
	},
}

const customDefaultConfigReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_SEARCH_NAME:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_CATEGORIES:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					types: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_TYPE:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					focusTable: {
						...state.focus.focusTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					selectedFocus: {
						index: action.payload.index,
						focus: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_NAME:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					modalNewFocus: {
						...state.focus.modalNewFocus,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DESCRIPTION:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					modalNewFocus: {
						...state.focus.modalNewFocus,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_ACTIVE:
			return Object.assign({}, state, {
				focus: {
					...state.focus,
					modalNewFocus: {
						...state.focus.modalNewFocus,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SEARCH_NAME:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_CATEGORIES:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					types: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_TYPE:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					interventionTable: {
						...state.intervention.interventionTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_INTERVENTION:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					selectedIntervention: {
						index: action.payload.index,
						intervention: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					modalNewIntervention: {
						...state.intervention.modalNewIntervention,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					modalNewIntervention: {
						...state.intervention.modalNewIntervention,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE:
			return Object.assign({}, state, {
				intervention: {
					...state.intervention,
					modalNewIntervention: {
						...state.intervention.modalNewIntervention,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_EVALUATION_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					types: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_EVALUATION_DEFAULTS:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					evaluationTable: {
						...state.evaluation.evaluationTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					selectedEvaluation: {
						index: action.payload.index,
						evaluation: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_NAME:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					modalNewEvaluation: {
						...state.evaluation.modalNewEvaluation,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DESCRIPTION:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					modalNewEvaluation: {
						...state.evaluation.modalNewEvaluation,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_ACTIVE:
			return Object.assign({}, state, {
				evaluation: {
					...state.evaluation,
					modalNewEvaluation: {
						...state.evaluation.modalNewEvaluation,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SEARCH_NAME:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_CATEGORIES:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					types: action.payload.value,
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_TYPE:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DEFAULTS:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					assessmentTable: {
						...state.assessment.assessmentTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_ASSESSMENT:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					selectedAssessment: {
						index: action.payload.index,
						assessment: action.payload.selected,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					modalNewAssessment: {
						...state.assessment.modalNewAssessment,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					modalNewAssessment: {
						...state.assessment.modalNewAssessment,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE:
			return Object.assign({}, state, {
				assessment: {
					...state.assessment,
					modalNewAssessment: {
						...state.assessment.modalNewAssessment,
						isActive: action.payload.value,
					},
				},
			})
		default:
			return state
	}
}

export default customDefaultConfigReducer
