import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setPSModalPaymentPlanInvoices, setSelected } from "~/redux/action"
import { SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PSModalPaymentPlanInvoice extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
	}

	componentDidMount() {
		this.props.setPSModalPaymentPlanInvoices(this.props.PS.selectedSupplier.supplier.invoices)
	}

	componentWillUnmount() {
		this.props.setPSModalPaymentPlanInvoices([])
		this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE, -1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.PS.modalPaymentPlan.pendingInvoiceTable.original,
				this.props.PS.modalPaymentPlan.pendingInvoiceTable.original
			)
		) {
			this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE, -1, null)
			let invoices = Array.from(this.props.PS.modalPaymentPlan.invoiceTable.original, (each) => {
				if (this.props.PS.modalPaymentPlan.selectedPendingInvoice.invoice) {
					if (each.id === this.props.PS.modalPaymentPlan.selectedPendingInvoice.invoice.id) {
						return {
							...each,
							paymentPlan: undefined,
						}
					}
				}
				return each
			})
			this.props.setPSModalPaymentPlanInvoices(invoices)
		}
	}

	selectRow(plan, index) {
		this.props.setSelected(
			SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE,
			index,
			this.props.PS.modalPaymentPlan.invoiceTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.PS.modalPaymentPlan.invoiceTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.PS.modalPaymentPlan.selectedInvoice.index === -1}
						highlightedRow={this.props.PS.modalPaymentPlan.selectedInvoice.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPSModalPaymentPlanInvoices,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalPaymentPlanInvoice))
