import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOperatingRoomRequests,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
	setORModalIntraAneIOIntakes,
	setORModalIntraAneIOPendingIntakes,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE,
	SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE,
	SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ORModalIntraAneIOIntake extends React.Component {
	constructor(props) {
		super(props)

		this.onFilterIntake = this.onFilterIntake.bind(this)
		this.onSelect = this.onSelect.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectPending = this.onSelectPending.bind(this)
	}

	componentDidMount() {
		this.props.setORModalIntraAneIOIntakes(this.props.operatingRoom.intakes)
		this.props.setORModalIntraAneIOPendingIntakes(
			this.getPendingIntakes(this.props.operatingRoom.selectedIntraAneRecord.record.intakeOutputs.filter((each) => each.intake))
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteIntraAneIntakeOutput)) {
				this.deleteIntakeOutput()
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.selectedIntraAneRecord.record, this.props.operatingRoom.selectedIntraAneRecord.record)) {
			if (this.props.operatingRoom.selectedIntraAneRecord.record) {
				this.props.setORModalIntraAneIOPendingIntakes(
					this.getPendingIntakes(this.props.operatingRoom.selectedIntraAneRecord.record.intakeOutputs.filter((each) => each.intake))
				)
			}
		}
		if (
			prevProps.operatingRoom.modalIntraAneIO.filterIntake !== this.props.operatingRoom.modalIntraAneIO.filterIntake ||
			!_.isEqual(prevProps.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered, this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered)
		) {
			this.props.setSelected(SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE, -1, null)
			this.props.setORModalIntraAneIOIntakes(this.props.operatingRoom.intakes)
		}
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered, this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered)) {
			this.props.setSelected(SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE, "")
		this.props.setORModalIntraAneIOIntakes([])
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE, -1, null)
		this.props.setORModalIntraAneIOPendingIntakes([])
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE, -1, null)
	}

	getPendingIntakes(intakes) {
		const distinctByIntake = intakes.reduce((acc, current) => {
			if (!acc.some((item) => item.intake === current.intake)) {
				acc.push(current)
			}
			return acc
		}, [])
		return this.props.operatingRoom.intakes
			.filter((intake) => distinctByIntake.some((each) => each.intake === intake.displayName))
			.map((each) => {
				return {
					...each,
					isExistIntake: true,
				}
			})
	}

	isDownBtnDisabled() {
		return !this.props.operatingRoom.modalIntraAneIO.selectedIntake.intake
	}

	isUpBtnDisabled() {
		return !this.props.operatingRoom.modalIntraAneIO.selectedPendingIntake.intake
	}

	deleteIntakeOutput() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteAneIntakeOutputs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				ios: this.props.operatingRoom.selectedIntraAneRecord.record.intakeOutputs.filter(
					(each) => each.intake === this.props.operatingRoom.modalIntraAneIO.selectedPendingIntake.intake.displayName
				),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<SearchBox
							num={1}
							lableName=""
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.intake]}
							callbacks={[this.onFilterIntake]}
						/>
					</Col>
				</Row>
				<Row className={"full-size"}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelect}
							data={this.props.operatingRoom.modalIntraAneIO.intakeTable}
							isClearHighlight={this.props.operatingRoom.modalIntraAneIO.selectedIntake.index === -1}
							highlightedRow={this.props.operatingRoom.modalIntraAneIO.selectedIntake.index}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.isDownBtnDisabled()}
							onClick={this.onDownBtn}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button variant={ResourceAssistance.Button.variant.link} disabled={this.isUpBtnDisabled()} onClick={this.onUpBtn}>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectPending}
							data={this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable}
							isClearHighlight={this.props.operatingRoom.modalIntraAneIO.selectedPendingIntake.index === -1}
							highlightedRow={this.props.operatingRoom.modalIntraAneIO.selectedPendingIntake.index}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onFilterIntake(event) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE, Utils.trim(event.target.value))
	}
	onSelect(service, index) {
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE, index, this.props.operatingRoom.modalIntraAneIO.intakeTable.filtered[index])
	}
	onSelectPending(item, index) {
		this.props.setSelected(
			SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE,
			index,
			this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered[index]
		)
	}
	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()
		let newIntake = Object.assign({}, this.props.operatingRoom.modalIntraAneIO.selectedIntake.intake)
		let intakes = this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.original
		intakes.push(newIntake)
		this.props.setORModalIntraAneIOPendingIntakes(intakes)
	}
	onUpBtn() {
		let selectedPendingIntake = this.props.operatingRoom.modalIntraAneIO.selectedPendingIntake.intake
		if (selectedPendingIntake.isExistIntake) {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteIntraAneIntakeOutput)
			this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
			this.props.setWarningMsgAction(
				<Card
					title={translate(ResourceAssistance.Message.delete)}
					bordered={false}
					style={{
						backgroundColor: "inherit",
					}}
					styles={{
						header: {
							fontSize: "28px",
						},
					}}
				>
					<Descriptions
						style={{ flex: "unset", display: "unset" }}
						size={"small"}
						contentStyle={{
							fontStyle: "italic",
							fontWeight: "normal",
							textAlign: "left",
						}}
						column={1}
					>
						<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
							{selectedPendingIntake.displayName}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
							{selectedPendingIntake.description}
						</Descriptions.Item>
					</Descriptions>
				</Card>
			)
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
		} else {
			let pendingIntakes = Array.from(this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered)
			pendingIntakes.splice(this.props.operatingRoom.modalIntraAneIO.selectedPendingIntake.index, 1)
			this.props.setORModalIntraAneIOPendingIntakes(pendingIntakes)
		}
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setORModalIntraAneIOIntakes,
			setORModalIntraAneIOPendingIntakes,
			setOperatingRoomRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalIntraAneIOIntake))
