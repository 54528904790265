import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_DEPOSIT_MODAL_SEARCH_PATIENTS, SET_DEPOSIT_TRANSACTIONS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setDepositModalSearchPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients.sort((a, b) => Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber()))

		let body = filtered.map((patient) => {
			return [patient.id, patient.identificationNumber, patient.firstName, patient.lastName, Utils.formatDate(patient.dobtime)]
		})

		dispatch({
			type: SET_DEPOSIT_MODAL_SEARCH_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setDepositTransactions = (transactions) => {
	return (dispatch, getState) => {
		let tempFiltered = Array.from(transactions).sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))
		let filtered = []
		if (_.includes(getState().hospital.deposit.filters, ResourceAssistance.Message.deposit)) {
			filtered = filtered.concat(tempFiltered.filter((trans) => _.isEqual(trans.type, ResourceAssistance.Enum.deposit)))
		}
		if (_.includes(getState().hospital.deposit.filters, ResourceAssistance.Message.withdraw)) {
			filtered = filtered.concat(tempFiltered.filter((trans) => _.isEqual(trans.type, ResourceAssistance.Enum.withdraw)))
		}
		filtered = _.isEmpty(getState().hospital.deposit.filters) ? tempFiltered : filtered
		let body = filtered.map((transaction) => {
			return [
				transaction.id,
				Utils.formatDate(transaction.creationDateTime),
				Utils.formatTime(transaction.creationDateTime),
				transaction.type.charAt(0),
				transaction.payment,
				transaction.remark.replace("\n", " - "),
				Utils.formatNumWithComma(Utils.BigNumber(transaction.amount).toFixed(2)),
				transaction.createdBy.displayName,
				[_.isEqual(transaction.type, ResourceAssistance.Enum.deposit)],
			]
		})

		let rowColor = filtered.map((trans) => {
			let color = ""
			if (_.isEqual(trans.type, ResourceAssistance.Enum.deposit)) {
				color = ResourceAssistance.CSS.Color.green
			} else {
				color = ResourceAssistance.CSS.Color.red
			}
			return [true, color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_DEPOSIT_TRANSACTIONS,
			payload: {
				original: transactions,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
