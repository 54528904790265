const ProgramDefined = {
	PROGRAM_DEFINED: {
		date: "\\date\\",
		effective_date: "\\effective_date",
		email: "\\email",
		num_trans: "\\num_trans",
		receiver_name: "\\receiver_name",
		receiving_account: "\\receiving_account",
		receiving_bank_code: "\\receiving_bank_code",
		receiving_branch_code: "\\receiving_branch_code",
		sender_name: "\\sender_name",
		sending_account: "\\sending_account",
		sending_bank_code: "\\sending_bank_code",
		sending_branch_code: "\\sending_branch_code",
		service_type: "\\service_type",
		split: "\\",
		supplier: "\\supplier\\",
		total_amount: "\\total_amount",
		lineBreak: "\n",
	},
}
export default ProgramDefined
