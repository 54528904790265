import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_PS_AP_ACTIVITIES,
	SET_PS_PAYMENT_TYPES_LOADING,
	SET_PS_PAYMENT_TYPES,
	SET_PS_BANKS,
	SET_PS_BANKS_LOADING,
	SET_PS_WITHHOLDING_TAXES,
} from "~/redux/type"
import PaymentSlipRightPaymentPlanTab from "./PaymentSlipRightPaymentPlanTab"
import PaymentSlipRightSupplier from "./PaymentSlipRightSupplier"
import PaymentSlipRightTab from "./PaymentSlipRightTab"

class PaymentSlipRight extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.PS.accountsPayableActivities)) {
			this.loadAccountsPayableActivities()
		}
		if (_.isEmpty(this.props.PS.paymentTypes)) {
			this.loadPaymentTypes()
		}
		if (_.isEmpty(this.props.PS.banks)) {
			this.loadBanks()
		}
		if (_.isEmpty(this.props.PS.withholdingTaxes)) {
			this.loadWithholdingTaxes()
		}
	}

	loadAccountsPayableActivities() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.generalLedger.getAccountsPayableActivities,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PS_AP_ACTIVITIES, res.data.activities)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPaymentTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.getPayments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			let types = res.data.uts.filter((type) => !type.savingAccount)
			this.props.setValue(SET_PS_PAYMENT_TYPES, types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PS_PAYMENT_TYPES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PS_PAYMENT_TYPES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PS_PAYMENT_TYPES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadBanks() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.getBanks,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PS_BANKS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PS_BANKS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PS_BANKS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PS_BANKS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadWithholdingTaxes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.getWithholdingTaxes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PS_WITHHOLDING_TAXES, res.data.uts)
		}
		let errorHandler = (error) => {}
		let reqInterceptor = (config) => {}
		let resInterceptor = (response) => {}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<PaymentSlipRightSupplier />
				<PaymentSlipRightPaymentPlanTab />
				<PaymentSlipRightTab />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentSlipRight)
