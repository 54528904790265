import { Card, Descriptions } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOperatingRoomRequests,
	setORModalPostAneServicePendingServices,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"

class ORModalPostAneServiceBtn extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.deletePostAneService)) {
				this.deleteDoctorOrder()
			}
		}
	}

	isDownBtnDisabled() {
		return !this.props.operatingRoom.modalPostAneService.selectedService.service
	}

	isUpBtnDisabled() {
		return !this.props.operatingRoom.modalPostAneService.selectedPendingService.service
	}

	deleteDoctorOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteAneDoctorOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				doctorOrders: [
					{
						id: this.props.operatingRoom.modalPostAneService.selectedPendingService.service.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} disabled={this.isDownBtnDisabled()} onClick={this.onDownBtn}>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button variant={ResourceAssistance.Button.variant.link} disabled={this.isUpBtnDisabled()} onClick={this.onUpBtn}>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()
		let newService = Object.assign({}, this.props.operatingRoom.modalPostAneService.selectedService.service, {
			categoryId: this.props.operatingRoom.modalPostAneService.selectedType.type.id,
			startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
			endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		})
		let services = this.props.operatingRoom.modalPostAneService.pendingServiceTable.original
		services.push(newService)
		this.props.setORModalPostAneServicePendingServices(services)
	}
	onUpBtn() {
		let selectedPendingService = this.props.operatingRoom.modalPostAneService.selectedPendingService.service
		if (selectedPendingService.isExistService) {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.deletePostAneService)
			this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
			this.props.setWarningMsgAction(
				<Card
					title={translate(ResourceAssistance.Message.delete)}
					bordered={false}
					style={{
						backgroundColor: "inherit",
					}}
					styles={{
						header: {
							fontSize: "28px",
						},
					}}
				>
					<Descriptions
						style={{ flex: "unset", display: "unset" }}
						size={"small"}
						contentStyle={{
							fontStyle: "italic",
							fontWeight: "normal",
							textAlign: "left",
						}}
						column={1}
					>
						<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
							{selectedPendingService.code}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
							{selectedPendingService.name}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.receiptCategory)} span={1}>
							{selectedPendingService.description}
						</Descriptions.Item>
					</Descriptions>
				</Card>
			)
			this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
		} else {
			let pendingServices = Array.from(this.props.operatingRoom.modalPostAneService.pendingServiceTable.filtered)
			pendingServices.splice(this.props.operatingRoom.modalPostAneService.selectedPendingService.index, 1)
			this.props.setORModalPostAneServicePendingServices(pendingServices)
		}
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setORModalPostAneServicePendingServices,
			setOperatingRoomRequests,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneServiceBtn))
