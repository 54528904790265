import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_SA_WORKFLOW_PROPERTY } from "~/redux/type"
import SurgicalAuthLeftAction from "./SurgicalAuthLeftAction"
import SurgicalAuthLeftOperatingRequest from "./SurgicalAuthLeftOperatingRequest"
import _ from "lodash"
import SurgicalAuthLeftDate from "./SurgicalAuthLeftDate"
import SurgicalAuthLeftFilter from "./SurgicalAuthLeftFilter"

class SurgicalAuthLeft extends React.Component {
	componentDidMount() {
		this.loadWorkflowProperties()
	}

	loadWorkflowProperties() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.workflowProperty.getSurgicalAuthProperties,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.workflowProperties)) {
				this.props.setValue(SET_SA_WORKFLOW_PROPERTY, res.data.workflowProperties[0])
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<SurgicalAuthLeftDate />
				<SurgicalAuthLeftFilter />
				<SurgicalAuthLeftAction />
				<SurgicalAuthLeftOperatingRequest />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SurgicalAuthLeft)
