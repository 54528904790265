import _ from "lodash"

class CheckBoxGroupUtils {
	static getKeyStr = (enums, values, excludes = [], extra = {}) => {
		let res = []
		for (const key in enums) {
			if (_.includes(values, enums[key]) && !_.includes(excludes, enums[key])) {
				let resV = key.replaceAll("_", " ")
				if (_.includes(Object.keys(extra), enums[key])) {
					resV = `${resV}(${extra[enums[key]]})`
				}
				res.push(resV)
			}
		}
		return res
			.map((str, index) => {
				if (str.endsWith(".")) {
					return str + "\n"
				} else if (index !== res.length - 1) {
					return str + ","
				}
				return str
			})
			.join("")
	}
}

export { CheckBoxGroupUtils }
