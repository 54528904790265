import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_REGISTRATION_MODAL_BUDGET_BUDGET,
	SET_REGISTRATION_MODAL_BUDGET_CONTACT,
	SET_REGISTRATION_MODAL_BUDGET_NOTE,
} from "~/redux/type"

class RegModalBudgetInput extends React.Component {
	constructor(props) {
		super(props)

		this.onBudget = this.onBudget.bind(this)
		this.onContact = this.onContact.bind(this)
		this.onNote = this.onNote.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_BUDGET, "")
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_CONTACT, "")
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_NOTE, "")
	}

	onBudget(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_BUDGET, event.target.value)
	}

	onContact(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_CONTACT, event.target.value)
	}

	onNote(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_BUDGET_NOTE, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.budget)}</legend>
					<Row>
						<Col />
						<Col md={6}>
							<FormControl
								required
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.number}
								step={0.01}
								value={this.props.reg.modalBudget.budget}
								onChange={this.onBudget}
							/>
						</Col>
						<Col />
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.companyOrResponsiblePersonContact)}</legend>
					<Row>
						<Col>
							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows={3}
								value={this.props.reg.modalBudget.contact}
								onChange={this.onContact}
							/>
						</Col>
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.note)}</legend>
					<Row>
						<Col>
							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows={3}
								value={this.props.reg.modalBudget.note}
								onChange={this.onNote}
							/>
						</Col>
					</Row>
				</fieldset>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalBudgetInput))
