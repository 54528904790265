import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_RC_ORDERS_LOADING, SET_RC_SEARCH_DISPLAY } from "~/redux/type/type/RC"
import { Utils } from "~/utils/Utils"

class ReceiveLeftActions extends React.Component {
	constructor(props) {
		super(props)
		this.onComplete = this.onComplete.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	onComplete(event) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.rc.updateReceive,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.selectedRc.rc.id,
				approvalApproved: true,
				userId: this.props.user.id,
			},
		}

		let callback = (res) => {
			this.props.updateReceive(res.data.receives)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, false)
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, true)
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_RC_ORDERS_LOADING, false)
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_RC_SEARCH_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Rc.Complete, this.props.user.roles) && (
					<Col sm="auto">
						<Button
							variant={ResourceAssistance.Button.variant.green}
							size={ResourceAssistance.Button.size.sm}
							disabled={
								this.props.selectedRc.index === -1 ||
								this.props.selectedRc.rc.suppliers.length > 0 ||
								this.props.selectedRc.rc.approvalApproved
							}
							onClick={this.onComplete}
						>
							<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.complete)}
						</Button>
					</Col>
				)}
				<Col></Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.info}
						onClick={this.onSearch}
					>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.login.user,
	selectedRc: state.receive.selectedRc,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReceiveLeftActions))
