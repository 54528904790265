import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportIPDPatientDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.patientInfo,
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayOrg={false}
						displayFax={false}
						displayTaxNum={false}
						displayBranch={true}
					/>
					{this.state.data.patient && (
						<Fragment>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.dateIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.patient.registrationDate} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.pid} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.genderIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 20 }]}>
									<Text>{this.state.data.patient.gender} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.name}</Text>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.identificationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.identification}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.dob} </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>
										{this.state.data.patient.age} {this.state.data.yearIntl} {this.state.data.patient.ageMonth}{" "}
										{this.state.data.monthIntl}
									</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.addressIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.address} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.telIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.patient.tel} </Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />

							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.healthCareIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 20 }]}>
									<Text>{this.state.data.patient.healthCare} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row, { minHeight: 100 }]}>
								<View
									style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { alignSelf: "flex-start" }]}
								>
									<Text>{this.state.data.healthCareNoteIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { alignSelf: "flex-start" }]}>
									<Text>{this.state.data.patient.healthCareNote} </Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />

							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.heightIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.height} cm</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.weightIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.weight} kg</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View
									style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { alignSelf: "flex-start" }]}
								>
									<Text>{this.state.data.underlyingDiseaseIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.underlyingDisease}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View
									style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { alignSelf: "flex-start" }]}
								>
									<Text>{this.state.data.allergiesIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.allergies}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View
									style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { alignSelf: "flex-start" }]}
								>
									<Text>{this.state.data.pastIllnessesIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient.pastIllnesses}</Text>
								</View>
							</View>
						</Fragment>
					)}
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
		marginBottom: 15,
	},
	infoBody: {
		marginBottom: 15,
	},
})

export default ReportIPDPatientDocument
