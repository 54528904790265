import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setLabPatientLabs, setSelected } from "~/redux/action"
import { SET_HOSPITEL_LAB_SELECTED_LAB } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CLRightLabRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLRightLabRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.lab.selectedPatient, this.props.lab.selectedPatient)) {
			if (this.props.lab.selectedPatient.index === -1) {
				this.props.setLabPatientLabs([])
			} else {
				this.props.setLabPatientLabs(this.props.lab.selectedPatient.patient.labs)
			}
			this.props.setSelected(SET_HOSPITEL_LAB_SELECTED_LAB, -1, null)
		}
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_HOSPITEL_LAB_SELECTED_LAB, index, this.props.lab.labTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"g-0"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.lab.labTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.lab.selectedLab.index === -1}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLabPatientLabs,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLRightLabRow)
