import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX, SET_AR_MODAL_ADJUSTMENT_DISCOUNT_PERCENT } from "~/redux/type"
import ARModalAdjustmentDiscount from "./ARModalAdjustmentDiscount"

class ARModalAdjustmentDetails extends React.Component {
	componentDidMount() {
		this.props.setValue(SET_AR_MODAL_ADJUSTMENT_DISCOUNT_PERCENT, 0)
	}

	componentWillUnmount() {
		this.props.setValue(SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX, 0)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<ARModalAdjustmentDiscount />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ARModalAdjustmentDetails)
