import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setAdmPermissionPermissions, setSearchText, setValue } from "~/redux/action"
import { SET_ADMIN_PERMISSION_PERMISSIONS_LOADING, SET_ADMIN_PERMISSION_SEARCH_NAME } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class AdmPermission extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmPermission",
		}

		this.onSearchName = this.onSearchName.bind(this)
	}

	componentDidMount() {
		this.props.loadPermissions(
			(permissions) => {
				this.props.setAdmPermissionPermissions(permissions)
			},
			() => {
				this.props.setValue(SET_ADMIN_PERMISSION_PERMISSIONS_LOADING, true)
			},
			() => {
				this.props.setValue(SET_ADMIN_PERMISSION_PERMISSIONS_LOADING, false)
			},
			() => {
				this.props.setValue(SET_ADMIN_PERMISSION_PERMISSIONS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setSearchText(SET_ADMIN_PERMISSION_SEARCH_NAME, "")
		this.props.setAdmPermissionPermissions([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.permission.searchName !== this.props.permission.searchName) {
			this.props.setAdmPermissionPermissions(this.props.permission.permissionTable.original)
		}
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_PERMISSION_SEARCH_NAME, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							// isClearHighlight={this.props.role.selectedRole.index === -1}
							data={this.props.permission.permissionTable}
							onClick={this.onSelectRow}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	permission: state.admin.userConfig.permission,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSearchText,
			setAdmPermissionPermissions,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmPermission)
