import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmISItems, setAdmISModalNISTypes, setAdmISSelectedItem, setLoadingAction } from "~/redux/action"
import { SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AdmISModalNISDetails from "./AdmISModalNISDetails"

class AdmISModalNIS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmISModalNIS",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.loadTypes = this.loadTypes.bind(this)
	}

	loadTypes(func = () => {}) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.type.item.loadTypes}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setAdmISModalNISTypes(res.data.uts)
			func()
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.itemSource.selectedItem.item, {
			...this.props.itemSource.selectedItem.item,
			displayName: this.props.itemSource.modalNewItem.name.trim(),
			keyword: Utils.trim(this.props.itemSource.modalNewItem.keyword),
			warnings: Utils.trim(this.props.itemSource.modalNewItem.warnings),
			active: this.props.itemSource.modalNewItem.isActive,
		})

		let targetTypeId = this.props.itemSource.modalNewItem.selectedType.type ? this.props.itemSource.modalNewItem.selectedType.type.id : 0

		return _.isEqual(this.props.itemSource.selectedItem.item, target) && this.props.itemSource.selectedItem.item.type.id === targetTypeId
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.itemSource.saveItem}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.itemSource.selectedItem.index === -1 ? 0 : this.props.itemSource.selectedItem.item.id,
				typeId: this.props.itemSource.modalNewItem.selectedType.type.id,
				name: Utils.trim(this.props.itemSource.modalNewItem.name),
				keyword: Utils.trim(this.props.itemSource.modalNewItem.keyword),
				warnings: Utils.trim(this.props.itemSource.modalNewItem.warnings),
				active: this.props.itemSource.modalNewItem.isActive,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			this.props.setAdmISSelectedItem(-1, null)
			let items = this.props.itemSource.itemTable.original.filter((each) => each.id !== res.data.items[0].id)
			items.push(res.data.items[0])
			this.props.setAdmISItems(items)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.newItem)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<AdmISModalNISDetails loadTypes={this.loadTypes} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmISNewItemDisplay,
	login: state.login,
	itemSource: state.admin.itemConfig.itemSource,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmISItems,
			setAdmISModalNISTypes,
			setAdmISSelectedItem,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmISModalNIS)
