import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setARBillingStatementGLGroups, setLoadingAction, setSelected, setValue, setARGLGroups } from "~/redux/action"
import { SET_AR_ACTIVITIES, SET_AR_MODAL_GENERAL_LEDGER_DISPLAY, SET_AR_SELECTED_GROUP, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceivableRightGL extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onCOA = this.onCOA.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.AR.accountsReceivableActivities)) {
			this.loadAccountsReceivableActivities()
		}
		if (this.props.AR.selectedInvoice.invoice) {
			if (_.isEmpty(this.props.AR.selectedInvoice.invoice.accountsReceivableGLs)) {
				this.props.setARBillingStatementGLGroups(this.props.AR.selectedInvoice.invoice.billing.billingStatements)
			} else {
				this.props.setARGLGroups(this.props.AR.selectedInvoice.invoice.accountsReceivableGLs)
			}
		} else {
			this.props.setARBillingStatementGLGroups([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.AR.selectedInvoice, this.props.AR.selectedInvoice) ||
			!_.isEqual(prevProps.AR.accountsReceivableActivities, this.props.AR.accountsReceivableActivities)
		) {
			if (this.props.AR.selectedInvoice.invoice) {
				if (_.isEmpty(this.props.AR.selectedInvoice.invoice.accountsReceivableGLs)) {
					this.props.setARBillingStatementGLGroups(this.props.AR.selectedInvoice.invoice.billing.billingStatements)
				} else {
					this.props.setARGLGroups(this.props.AR.selectedInvoice.invoice.accountsReceivableGLs)
				}
			} else {
				this.props.setARBillingStatementGLGroups([])
			}
		}
		if (!_.isEqual(prevProps.AR.billingStatementGLGroupTable.filtered, this.props.AR.billingStatementGLGroupTable.filtered)) {
			let index = this.props.AR.selectedGroup.group
				? this.props.AR.billingStatementGLGroupTable.filtered.findIndex((group) => group[0].includes(this.props.AR.selectedGroup.group[0]))
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AR_SELECTED_GROUP, index, this.props.AR.billingStatementGLGroupTable.filtered[index])
			} else {
				this.props.setSelected(SET_AR_SELECTED_GROUP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_AR_SELECTED_GROUP, -1, null)
	}

	isDisplayEdit() {
		return this.props.AR.selectedInvoice.invoice && !this.props.AR.selectedInvoice.invoice.accountsReceivableInspected
	}

	loadAccountsReceivableActivities() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.generalLedger.getAccountsReceivableActivities,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_AR_ACTIVITIES, res.data.activities)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_AR_SELECTED_GROUP, index, this.props.AR.billingStatementGLGroupTable.filtered[index])
	}

	onCOA() {
		this.props.onModalDisplayAction(SET_AR_MODAL_GENERAL_LEDGER_DISPLAY, true)
	}

	render() {
		let totalDebit = this.props.AR.billingStatementGLGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[4]))
		}, Utils.BigNumber(0))
		let totalCredit = this.props.AR.billingStatementGLGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[5]))
		}, Utils.BigNumber(0))
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined]}
						onCheckedArray={[this.onCOA]}
						data={this.props.AR.billingStatementGLGroupTable}
						isClearHighlight={this.props.AR.selectedGroup.index === -1}
						highlightedRow={this.props.AR.selectedGroup.index}
						onClick={this.onSelectRow}
						extra={
							!_.isEmpty(this.props.AR.billingStatementGLGroupTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
												color: totalCredit.eq(totalDebit) ? undefined : ResourceAssistance.CSS.Color.red,
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.debit)}>
												{Utils.formatNumWithComma(totalDebit.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.credit)}>
												{Utils.formatNumWithComma(totalCredit.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setARBillingStatementGLGroups,
			setARGLGroups,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceivableRightGL))
