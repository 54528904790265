import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdminHealthCareTypes, setLoadingAction } from "~/redux/action"
import { SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import AdmHealthCarePlanModalARDetails from "./AdmHealthCarePlanModalARDetails"
import _ from "lodash"
import { axios } from "~/axios"

class AdmHealthCarePlanModalAR extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmHealthCarePlanModalAR",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.type.healthcarePlan.modalAccountsReceivable.selectedAR.AR) {
			return true
		}
		if (this.props.type.healthcarePlan.selectedAR.AR) {
			let target = Object.assign({}, this.props.type.healthcarePlan.selectedAR.AR, {
				...this.props.type.healthcarePlan.selectedAR.AR,
				chartOfAccounts: {
					...this.props.type.healthcarePlan.selectedAR.AR.chartOfAccounts,
					id: this.props.type.healthcarePlan.modalAccountsReceivable.selectedAR.AR
						? this.props.type.healthcarePlan.modalAccountsReceivable.selectedAR.AR.id
						: 0,
				},
				linkGL: this.props.type.healthcarePlan.modalAccountsReceivable.isLinkGL,
			})
			return _.isEqual(target, this.props.type.healthcarePlan.selectedAR.AR)
		}
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.type.healthCarePlan.saveHealthCarePlanAccountsReceivable,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				activities: [
					{
						id: this.props.type.healthcarePlan.selectedAR.AR ? this.props.type.healthcarePlan.selectedAR.AR.id : 0,
						categoryId: this.props.type.healthcarePlan.selectedCompany.company ? 0 : this.props.type.selectedType.type.id,
						healthcarePlanCompanyId: this.props.type.healthcarePlan.selectedCompany.company ? this.props.type.healthcarePlan.selectedCompany.company.id : 0,
						chartOfAccountsId: this.props.type.healthcarePlan.modalAccountsReceivable.selectedAR.AR.id,
						linkGL: this.props.type.healthcarePlan.modalAccountsReceivable.isLinkGL,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setAdminHealthCareTypes(res.data.uts)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				size={ResourceAssistance.Modal.size.md}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsReceivable)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<AdmHealthCarePlanModalARDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmHealthCarePlanAccountsReceivableDisplay,
	login: state.login,
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdminHealthCareTypes,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmHealthCarePlanModalAR))
