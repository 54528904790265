export const SET_CASHIER_ADMISSIONS = "SET_CASHIER_ADMISSIONS"
export const SET_CASHIER_ADMISSION_BALANCE = "SET_CASHIER_ADMISSION_BALANCE"
export const SET_CASHIER_ADMISSION_LOADING = "SET_CASHIER_ADMISSION_LOADING"
export const SET_CASHIER_BANKS = "SET_CASHIER_BANKS"
export const SET_CASHIER_BANKS_LOADING = "SET_CASHIER_BANKS_LOADING"
export const SET_CASHIER_CERTIFICATE_DISPLAY = "SET_CASHIER_CERTIFICATE_DISPLAY"
export const SET_CASHIER_DOCTOR_DISPLAY = "SET_CASHIER_DOCTOR_DISPLAY"
export const SET_CASHIER_HEALTH_CARE_PLANS = "SET_CASHIER_HEALTH_CARE_PLANS"
export const SET_CASHIER_LAB_DISPLAY = "SET_CASHIER_LAB_DISPLAY"
export const SET_CASHIER_LOCATION = "SET_CASHIER_LOCATION"
export const SET_CASHIER_LOCATION_DISPLAY = "SET_CASHIER_LOCATION_DISPLAY"
export const SET_CASHIER_MODAL_ADJUSTMENT_AMOUNT = "SET_CASHIER_MODAL_ADJUSTMENT_AMOUNT"
export const SET_CASHIER_MODAL_ADJUSTMENT_BALANCE = "SET_CASHIER_MODAL_ADJUSTMENT_BALANCE"
export const SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_MAX = "SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_MAX"
export const SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY = "SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY"
export const SET_CASHIER_MODAL_ADJUSTMENT_CLOSE = "SET_CASHIER_MODAL_ADJUSTMENT_CLOSE"
export const SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE = "SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE"
export const SET_CASHIER_MODAL_ADJUSTMENT_DESCRIPTION = "SET_CASHIER_MODAL_ADJUSTMENT_DESCRIPTION"
export const SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY = "SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY"
export const SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_MAX = "SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_MAX"
export const SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_PERCENT = "SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_PERCENT"
export const SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY = "SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY"
export const SET_CASHIER_MODAL_ADJUSTMENT_ID = "SET_CASHIER_MODAL_ADJUSTMENT_ID"
export const SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY = "SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY"
export const SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY = "SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY"
export const SET_CASHIER_MODAL_CERTIFICATE_PENDING = "SET_CASHIER_MODAL_CERTIFICATE_PENDING"
export const SET_CASHIER_MODAL_CERTIFICATE_SELECTED_PENDING = "SET_CASHIER_MODAL_CERTIFICATE_SELECTED_PENDING"
export const SET_CASHIER_MODAL_CERTIFICATE_SELECTED_UNCHARGED = "SET_CASHIER_MODAL_CERTIFICATE_SELECTED_UNCHARGED"
export const SET_CASHIER_MODAL_CERTIFICATE_UNCHARGED = "SET_CASHIER_MODAL_CERTIFICATE_UNCHARGED"
export const SET_CASHIER_MODAL_DOCTOR_ORDERS = "SET_CASHIER_MODAL_DOCTOR_ORDERS"
export const SET_CASHIER_MODAL_DOCTOR_PENDING_ORDERS = "SET_CASHIER_MODAL_DOCTOR_PENDING_ORDERS"
export const SET_CASHIER_MODAL_DOCTOR_SELECTED_ORDER = "SET_CASHIER_MODAL_DOCTOR_SELECTED_ORDER"
export const SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER = "SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER"
export const SET_CASHIER_MODAL_FINANCIAL_PLAN_BILLING_STATEMENTS = "SET_CASHIER_MODAL_FINANCIAL_PLAN_BILLING_STATEMENTS"
export const SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES = "SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES"
export const SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE = "SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE"
export const SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE = "SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE"
export const SET_CASHIER_MODAL_LAB_PENDING_LABS = "SET_CASHIER_MODAL_LAB_PENDING_LABS"
export const SET_CASHIER_MODAL_LAB_SELECTED_PENDING = "SET_CASHIER_MODAL_LAB_SELECTED_PENDING"
export const SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED = "SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED"
export const SET_CASHIER_MODAL_LAB_UNCHARGED_LABS = "SET_CASHIER_MODAL_LAB_UNCHARGED_LABS"
export const SET_CASHIER_MODAL_LOCATION_ORGS = "SET_CASHIER_MODAL_LOCATION_ORGS"
export const SET_CASHIER_MODAL_LOCATION_ORGS_LOADING = "SET_CASHIER_MODAL_LOCATION_ORGS_LOADING"
export const SET_CASHIER_MODAL_LOCATION_SELECTED_BRANCH = "SET_CASHIER_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_CASHIER_MODAL_LOCATION_SELECTED_ORG = "SET_CASHIER_MODAL_LOCATION_SELECTED_ORG"
export const SET_CASHIER_MODAL_NURSE_ORDERS = "SET_CASHIER_MODAL_NURSE_ORDERS"
export const SET_CASHIER_MODAL_NURSE_PENDING_ORDERS = "SET_CASHIER_MODAL_NURSE_PENDING_ORDERS"
export const SET_CASHIER_MODAL_NURSE_SELECTED_ORDER = "SET_CASHIER_MODAL_NURSE_SELECTED_ORDER"
export const SET_CASHIER_MODAL_NURSE_SELECTED_PENDING_ORDER = "SET_CASHIER_MODAL_NURSE_SELECTED_PENDING_ORDER"
export const SET_CASHIER_MODAL_PAYMENT_SELECTED_BANK = "SET_CASHIER_MODAL_PAYMENT_SELECTED_BANK"
export const SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT = "SET_CASHIER_MODAL_PAYMENT_SELECTED_PAYMENT"
export const SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT = "SET_CASHIER_MODAL_PAYMENT_SKIP_PAYMENT"
export const SET_CASHIER_MODAL_PAYMENT_TOTAL = "SET_CASHIER_MODAL_PAYMENT_TOTAL"
export const SET_CASHIER_MODAL_PAYMENT_TYPES = "SET_CASHIER_MODAL_PAYMENT_TYPES"
export const SET_CASHIER_MODAL_SEARCH_END_DATETIME = "SET_CASHIER_MODAL_SEARCH_END_DATETIME"
export const SET_CASHIER_MODAL_SEARCH_FIRST_NAME = "SET_CASHIER_MODAL_SEARCH_FIRST_NAME"
export const SET_CASHIER_MODAL_SEARCH_ID = "SET_CASHIER_MODAL_SEARCH_ID"
export const SET_CASHIER_MODAL_SEARCH_LAST_NAME = "SET_CASHIER_MODAL_SEARCH_LAST_NAME"
export const SET_CASHIER_MODAL_SEARCH_PATIENTS = "SET_CASHIER_MODAL_SEARCH_PATIENTS"
export const SET_CASHIER_MODAL_SEARCH_PID = "SET_CASHIER_MODAL_SEARCH_PID"
export const SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT = "SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT"
export const SET_CASHIER_MODAL_SEARCH_START_DATETIME = "SET_CASHIER_MODAL_SEARCH_START_DATETIME"
export const SET_CASHIER_NURSE_DISPLAY = "SET_CASHIER_NURSE_DISPLAY"
export const SET_CASHIER_PATIENT = "SET_CASHIER_PATIENT"
export const SET_CASHIER_SEARCH_PATIENT_DISPLAY = "SET_CASHIER_SEARCH_PATIENT_DISPLAY"
export const SET_CASHIER_SELECTED_ADMISSION = "SET_CASHIER_SELECTED_ADMISSION"
export const SET_CASHIER_SELECTED_HEALTH_CARE_PLAN = "SET_CASHIER_SELECTED_HEALTH_CARE_PLAN"
export const SET_CASHIER_SELECTED_SERVICE = "SET_CASHIER_SELECTED_SERVICE"
export const SET_CASHIER_SERVICES = "SET_CASHIER_SERVICES"
