import { ResourceAssistance } from "~/i18n"
import {
	SET_DOCUMENT_DOCUMENTS,
	SET_DOCUMENT_DOCUMENTS_LOADING,
	SET_DOCUMENT_FILTER_NAME,
	SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION,
	SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL,
	SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST,
	SET_DOCUMENT_PATIENT,
	SET_DOCUMENT_SEARCH_PID_LOADING,
	SET_DOCUMENT_SELECTED_DOCUMENT,
} from "~/redux/type"

const init = {
	filterName: "",
	patient: null,
	isSearchPIDLoading: false,
	selectedDocument: {
		index: -1,
		document: null,
	},
	documentTable: {
		original: [],
		filtered: [],
		header: [
			"",
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		colStyle: [],
		popoverMsg: [],
		isLoading: false,
	},
	modalUpload: {
		fileList: [],
		description: "",
		isDisplayPatient: false,
	},
}

const documentReducer = (state = init, action) => {
	switch (action.type) {
		case SET_DOCUMENT_DOCUMENTS:
			return Object.assign({}, state, {
				documentTable: {
					...state.documentTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
					popoverMsg: action.payload.popoverMsg,
				},
			})
		case SET_DOCUMENT_DOCUMENTS_LOADING:
			return Object.assign({}, state, {
				documentTable: {
					...state.documentTable,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_DOCUMENT_SELECTED_DOCUMENT:
			return Object.assign({}, state, {
				selectedDocument: {
					index: action.payload.index,
					document: action.payload.selected,
				},
			})
		case SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST:
			return Object.assign({}, state, {
				modalUpload: {
					...state.modalUpload,
					fileList: action.payload.value,
				},
			})
		case SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION:
			return Object.assign({}, state, {
				modalUpload: {
					...state.modalUpload,
					description: action.payload.value,
				},
			})
		case SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL:
			return Object.assign({}, state, {
				modalUpload: {
					...state.modalUpload,
					isDisplayPatient: action.payload.value,
				},
			})
		case SET_DOCUMENT_FILTER_NAME:
			return Object.assign({}, state, {
				filterName: action.payload.value,
			})
		case SET_DOCUMENT_PATIENT:
			return Object.assign({}, state, {
				patient: action.payload.value,
			})
		case SET_DOCUMENT_SEARCH_PID_LOADING:
			return Object.assign({}, state, {
				isSearchPIDLoading: action.payload.isLoading,
			})
		default:
			return state
	}
}

export default documentReducer
