import { Badge } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { BsFillPersonXFill, BsPersonCheckFill, BsPersonLinesFill } from "react-icons/bs"
import { FaUserInjured, FaUserClock, FaUserEdit } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY,
	SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY,
	SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT,
	SET_OPD_APPOINTMENT_EDIT_DISPLAY,
	SET_OPD_MEDICAL_RECORD_DISPLAY,
	SET_OPD_PATIENTS_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OutpatientLeftMedicalRecordAction extends React.Component {
	constructor(props) {
		super(props)

		this.onMedicalRecord = this.onMedicalRecord.bind(this)
		this.onCheckIn = this.onCheckIn.bind(this)
		this.onCheckOut = this.onCheckOut.bind(this)
		this.onEditMedicalRecord = this.onEditMedicalRecord.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.checkOut)) {
				this.updateMedicalRecord({
					pid: this.props.opd.selectedPatient.patient.id,
					medicalRecordId: this.props.opd.selectedMedicalRecord.medicalRecord.id,
					userId: this.props.login.user.id,
					checkOutDateTime: moment().valueOf(),
				})
			} else if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.appointmentDelete)) {
				this.deleteMedicalRecord({
					pid: this.props.opd.selectedPatient.patient.id,
					medicalRecordId: this.props.opd.selectedMedicalRecord.medicalRecord.id,
				})
			}
		}

		if (
			!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord) &&
			this.props.isClinicalRecordDisplay
		) {
			this.onMedicalRecord()
		}
	}

	isCheckOutDisabled() {
		return (
			this.props.opd.selectedMedicalRecord.index === -1 ||
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime ||
			// _.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.treatment) ||
			this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseOrders.some((nurseOrder) => !nurseOrder.endDateTime) ||
			this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.operatingRoomRequests.some((request) =>
				[ResourceAssistance.Enum.pending].includes(request.status)
			)
		)
	}

	updateMedicalRecord(data) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.updateMedicalRecord,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: data,
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deleteMedicalRecord(data) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteMedicalRecord,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: data,
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			} else {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== this.props.opd.selectedPatient.patient.id)
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onEditMedicalRecord() {
		this.props.onModalDisplayAction(SET_OPD_MEDICAL_RECORD_DISPLAY, true)
	}

	onMedicalRecord() {
		let patient = {
			...this.props.opd.selectedPatient.patient,
			clinicalRecord: {
				...this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord,
				...this.props.opd.selectedMedicalRecord.medicalRecord,
			},
		}
		this.props.setValue(SET_HOSPITAL_MODAL_PATIENT_CLINICAL_RECORD_PATIENT, patient)
		if (this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 && this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0) {
			this.props.setValue(SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY, true)
			this.props.setValue(SET_HOSPITAL_MODAL_CLINICAL_RECORD_CALLBACK, this.onEditMedicalRecord)
		}
		this.props.onModalDisplayAction(SET_HOSPITAL_MODAL_CLINICAL_RECORD_DISPLAY, true)
	}

	onCheckIn(event) {
		this.updateMedicalRecord({
			pid: this.props.opd.selectedPatient.patient.id,
			medicalRecordId: this.props.opd.selectedMedicalRecord.medicalRecord.id,
			userId: this.props.login.user.id,
			appointmentDateTime: moment().valueOf(),
			checkInDateTime: moment().valueOf(),
		})
	}

	onCheckOut(event) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.checkOut)
		this.props.setWarningMsgAction(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.opdCheckOut }))
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onEdit(event) {
		this.props.onModalDisplayAction(SET_OPD_APPOINTMENT_EDIT_DISPLAY, true)
	}

	onDelete(event) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.appointmentDelete)
		this.props.setWarningMsgAction(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.appointmentDelete }))
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<Badge dot={this.props.opd.selectedMedicalRecord.medicalRecord && _.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.treatment)}>
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.info}
							disabled={this.props.opd.selectedMedicalRecord.index === -1 || this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0}
							onClick={this.onMedicalRecord}
						>
							<FaUserInjured size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.medicalRecord)}
						</Button>
					</Badge>
				</Col>
				<Col />
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) && (
					<Col md="auto">
						<Dropdown as={ButtonGroup}>
							<Dropdown.Toggle
								variant={ResourceAssistance.Button.variant.primary}
								size={ResourceAssistance.FormControl.size.sm}
								disabled={this.props.opd.selectedMedicalRecord.index === -1 || this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime > 0}
							>
								<FaUserClock size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.appointment)}
							</Dropdown.Toggle>
							<DropdownMenu variant={"info"}>
								<DropdownItem
									disabled={
										this.props.opd.selectedMedicalRecord.index === -1 ||
										this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime ||
										this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime > 0 ||
										moment(this.props.opd.selectedMedicalRecord.medicalRecord.appointmentDateTime).date() > moment().date()
									}
									onClick={this.onCheckIn}
								>
									<BsPersonCheckFill size={ResourceAssistance.ReactIcon.size} /> {translate(ResourceAssistance.Message.checkIn)}
								</DropdownItem>
								<DropdownItem disabled={this.isCheckOutDisabled()} onClick={this.onCheckOut}>
									<BsPersonLinesFill size={ResourceAssistance.ReactIcon.size} /> {translate(ResourceAssistance.Message.checkOut)}
								</DropdownItem>
								<DropdownItem
									disabled={this.props.opd.selectedMedicalRecord.index === -1 || this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0}
									onClick={this.onEdit}
								>
									<FaUserEdit size={ResourceAssistance.ReactIcon.size} /> {translate(ResourceAssistance.Message.edit)}
								</DropdownItem>
								<DropdownItem
									disabled={this.props.opd.selectedMedicalRecord.index === -1 || this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0}
									onClick={this.onDelete}
								>
									<BsFillPersonXFill size={ResourceAssistance.ReactIcon.size} /> {translate(ResourceAssistance.Message.delete)}
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	isClinicalRecordDisplay: state.modal.hospital.clinicalRecord.isClinicalRecordDisplay,
	warning: state.modal.warning,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
			setWarningId,
			setWarningMsgAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientLeftMedicalRecordAction))
