import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_PI_MODAL_LINK_LINKS, SET_PI_MODAL_LINK_SELECTED_LINK } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class PIModalLinkDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		if (this.props.PI.selectedPurchase.purchase) {
			if (!_.isEmpty(this.props.PI.chartOfAccounts)) {
				this.initialCOA()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PI.chartOfAccounts, this.props.PI.chartOfAccounts)) {
			if (this.props.PI.selectedPurchase.purchase && !_.isEmpty(this.props.PI.chartOfAccounts)) {
				this.initialCOA()
			}
		}
		if (!_.isEqual(prevProps.PI.modalLink.links, this.props.PI.modalLink.links)) {
			let idx = this.props.PI.modalLink.links.findIndex(
				(each) =>
					_.isEmpty(this.props.PI.selectedPurchase.purchase.linkedAccountingCode) ||
					each.fullCode === this.props.PI.selectedPurchase.purchase.linkedAccountingCode
			)
			if (idx > -1) {
				this.props.setSelected(SET_PI_MODAL_LINK_SELECTED_LINK, idx, this.props.PI.modalLink.links[idx])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PI_MODAL_LINK_SELECTED_LINK, -1, null)
		this.props.setValue(SET_PI_MODAL_LINK_LINKS, [])
	}

	initialCOA() {
		let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.PI.chartOfAccounts, this.props.PI.selectedPurchase.purchase.accountingCode)
		if (!_.isEmpty(selectedAry)) {
			this.props.setValue(
				SET_PI_MODAL_LINK_LINKS,
				selectedAry[1].linkedAccountingCharts.map((each) => {
					return {
						...each.accountingChart,
						transDescr: each.transDescr,
					}
				})
			)
		}
	}

	onSelect(v) {
		if (v === undefined) {
			this.props.setSelected(SET_PI_MODAL_LINK_SELECTED_LINK, -1, null)
			return
		}
		this.props.setSelected(SET_PI_MODAL_LINK_SELECTED_LINK, v, this.props.PI.modalLink.links[v])
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col>
						<Select
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.accountsCode)}
							value={this.props.PI.modalLink.selectedLink.link ? this.props.PI.modalLink.selectedLink.index : undefined}
							onChange={this.onSelect}
						>
							{Utils.renderSelects(this.props.PI.modalLink.links, false, -1, "displayFullCodeName")}
						</Select>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PIModalLinkDetails))
