import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setTfModalRequestPendingSelectedItem } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFModalNRPendingItemRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentWillUnmount() {
		this.props.setTfModalRequestPendingSelectedItem(-1, null)
	}

	onSelectRow(row, index) {
		this.props.setTfModalRequestPendingSelectedItem(
			index,
			this.props.transfer.newRequestModal.pendingItemTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col id={ResourceAssistance.ID.TF.modalRequest.tableSelectedItem}>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						responsive
						hover
						enableHighlight
						data={this.props.transfer.newRequestModal.pendingItemTable}
						isClearHighlight={this.props.transfer.newRequestModal.selectedPendingItem.index === -1}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfModalRequestPendingSelectedItem }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNRPendingItemRow)
