import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"

class FrontInventoryModalWasteInventoryInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions contentStyle={{ fontStyle: "italic" }} size={"small"}>
						<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={3}>
							{this.props.frontInventory.selectedInventory.inventory.code}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.genericName)} span={3}>
							{this.props.frontInventory.selectedInventory.inventory.genericName} (
							{this.props.frontInventory.selectedInventory.inventory.brand})
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontInventoryModalWasteInventoryInfo)
