import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { AiOutlineWarning } from "react-icons/ai"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction, setObjArray } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY,
	SET_IPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_ROLES,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import IPDModalMedicalCertificateDetails from "./IPDModalMedicalCertificateDetails"

class IPDModalMedicalCertificate extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDModalMedicalCertificate",
		}

		this.onClose = this.onClose.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		this.loadUserTypes()
	}

	loadUserTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getUserTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_IPD_MODAL_MEDICAL_CERTIFICATE_ROLES, res.data.types)
		}
		axios(params, callback)
	}

	reset() {
		this.props.onModalDisplayAction(SET_IPD_MEDICAL_CERTIFICATE_DISPLAY, false)
	}

	validate() {
		if (!this.props.ipd.modalCertificate.selectedCategory.category) {
			let categoryDOM = document.getElementById(ResourceAssistance.ID.HOSPITAL.ipd.modalCertificate.category)
			categoryDOM.reportValidity()
			return false
		} else if (!this.props.ipd.modalCertificate.selectedService.service) {
			let serviceDOM = document.getElementById(ResourceAssistance.ID.HOSPITAL.ipd.modalCertificate.service)
			serviceDOM.required = true
			serviceDOM.reportValidity()
			return false
		}
		return true
	}

	isDoneBtnDisabled() {
		if (!this.props.ipd.modalCertificate.selectedUser.user) {
			return true
		}
		if (!this.props.ipd.selectedCertificate.certificate) {
			return false
		}

		let target = Object.assign({}, this.props.ipd.selectedCertificate.certificate, {
			...this.props.ipd.selectedCertificate.certificate,
			radiologyDetails: Utils.trim(this.props.ipd.modalCertificate.radiologyDetails),
			examinationStartDateTime: this.props.ipd.modalCertificate.examinationStartDateTime,
			bloodTestDetails: Utils.trim(this.props.ipd.modalCertificate.bloodTestDetails),
			examinationEndDateTime: this.props.ipd.modalCertificate.examinationEndDateTime,
			doctorOpinion: Utils.trim(this.props.ipd.modalCertificate.doctorOpinion),
			diagnosis: Utils.trim(this.props.ipd.modalCertificate.diagnosis),
			symptoms: Utils.trim(this.props.ipd.modalCertificate.symptom),
			treatment: Utils.trim(this.props.ipd.modalCertificate.treatment),
			bloodTestChecked: this.props.ipd.modalCertificate.bloodTestChecked,
			radiologyChecked: this.props.ipd.modalCertificate.radiologyChecked,
			investigation: Utils.trim(this.props.ipd.modalCertificate.investigation),
			restEndDateTime: this.props.ipd.modalCertificate.endDateTime,
			restStartDateTime: this.props.ipd.modalCertificate.startDateTime,
		})

		return (
			_.isEqual(this.props.ipd.selectedCertificate.certificate, target) &&
			this.props.ipd.modalLocation.selectedOrg.org &&
			this.props.ipd.selectedCertificate.certificate.location.parent.parent &&
			this.props.ipd.modalLocation.selectedOrg.org.id ===
				this.props.ipd.selectedCertificate.certificate.location.parent.parent.id &&
			this.props.ipd.modalLocation.selectedBranch.branch &&
			this.props.ipd.modalLocation.selectedBranch.branch.id ===
				this.props.ipd.selectedCertificate.certificate.location.parent.id &&
			this.props.ipd.modalLocation.selectedDepartment.department &&
			this.props.ipd.modalLocation.selectedDepartment.department.id ===
				this.props.ipd.selectedCertificate.certificate.location.id &&
			this.props.ipd.modalCertificate.selectedService.service &&
			this.props.ipd.modalCertificate.selectedService.service.id ===
				this.props.ipd.selectedCertificate.certificate.service.id &&
			this.props.ipd.modalCertificate.selectedUser.user &&
			this.props.ipd.modalCertificate.selectedUser.user.displayName ===
				this.props.ipd.selectedCertificate.certificate.doctorName &&
			this.props.ipd.modalCertificate.selectedUser.user &&
			this.props.ipd.modalCertificate.selectedUser.user.licenseNum ===
				this.props.ipd.selectedCertificate.certificate.doctorLicense
		)
	}

	onClose() {
		this.reset()
	}

	onNew(event) {
		if (!this.validate()) {
			return
		}
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.ipd.selectedPatient.patient.id,
				admissionId: this.props.ipd.selectedHospitel.hospitel.admission.id,
				userId: this.props.login.user.id,
				medicalCertificates: [
					{
						locationId: this.props.ipd.modalLocation.selectedDepartment.department.id,
						categoryId: this.props.ipd.modalCertificate.selectedCategory.category.id,
						serviceId: this.props.ipd.modalCertificate.selectedService.service.id,
						examinationStartDateTime: this.props.ipd.modalCertificate.examinationStartDateTime,
						examinationEndDateTime: this.props.ipd.modalCertificate.examinationEndDateTime,
						startDateTime: this.props.ipd.modalCertificate.startDateTime,
						endDateTime: this.props.ipd.modalCertificate.endDateTime,
						locationAccountsReceivable: this.props.ipd.department.accountsReceivable,
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY, false)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.ipd.selectedPatient.patient.id,
				admissionId: this.props.ipd.selectedHospitel.hospitel.admission.id,
				userId: this.props.login.user.id,
				medicalCertificates: [
					{
						id: this.props.ipd.selectedCertificate.certificate ? this.props.ipd.selectedCertificate.certificate.id : 0,
						locationId: this.props.ipd.modalLocation.selectedDepartment.department.id,
						categoryId: this.props.ipd.modalCertificate.selectedCategory.category.id,
						serviceId: this.props.ipd.modalCertificate.selectedService.service.id,
						examinationStartDateTime: this.props.ipd.modalCertificate.examinationStartDateTime,
						examinationEndDateTime: this.props.ipd.modalCertificate.examinationEndDateTime,
						doctorName: Utils.trim(this.props.ipd.modalCertificate.selectedUser.user.displayName),
						doctorNameEng: Utils.trim(this.props.ipd.modalCertificate.selectedUser.user.displayNameEng),
						doctorLicense: Utils.trim(this.props.ipd.modalCertificate.selectedUser.user.licenseNum),
						symptoms: Utils.trim(this.props.ipd.modalCertificate.symptom),
						diagnosis: Utils.trim(this.props.ipd.modalCertificate.diagnosis),
						bloodTestChecked: this.props.ipd.modalCertificate.bloodTestChecked,
						bloodTestDetails: Utils.trim(this.props.ipd.modalCertificate.bloodTestDetails),
						radiologyChecked: this.props.ipd.modalCertificate.radiologyChecked,
						radiologyDetails: Utils.trim(this.props.ipd.modalCertificate.radiologyDetails),
						investigation: Utils.trim(this.props.ipd.modalCertificate.investigation),
						treatment: Utils.trim(this.props.ipd.modalCertificate.treatment),
						doctorOpinion: Utils.trim(this.props.ipd.modalCertificate.doctorOpinion),
						startDateTime: this.props.ipd.modalCertificate.startDateTime,
						endDateTime: this.props.ipd.modalCertificate.endDateTime,
						locationAccountsReceivable: this.props.ipd.department.accountsReceivable,
					},
				],
			},
		}

		let callback = (res) => {
			let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY, false)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)

			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.medicalCertificate)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<IPDModalMedicalCertificateDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>

						{!this.props.ipd.selectedCertificate.certificate && (
							<Button variant={ResourceAssistance.Button.variant.warning} onClick={this.onNew}>
								<AiOutlineWarning size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						)}

						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isInpatientMedicalCertificateDisplay,
	login: state.login,
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setObjArray,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalMedicalCertificate))
