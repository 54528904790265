import { Checkbox } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_PGL_MODAL_SEARCH_INCOMPLETE_IND, SET_PGL_MODAL_SEARCH_POSTING_GL_ID } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class PGLModalSearchDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onPostingGLId = this.onPostingGLId.bind(this)
		this.onSearchIncomplete = this.onSearchIncomplete.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_PGL_MODAL_SEARCH_POSTING_GL_ID, "")
		this.props.setValue(SET_PGL_MODAL_SEARCH_INCOMPLETE_IND, false)
	}

	onPostingGLId(e) {
		this.props.setValue(SET_PGL_MODAL_SEARCH_POSTING_GL_ID, Utils.trim(e.target.value))
	}

	onSearchIncomplete(e) {
		this.props.setValue(SET_PGL_MODAL_SEARCH_INCOMPLETE_IND, e.target.checked)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.id })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							disabled={this.props.PGL.modalSearch.isSearchIncomplete}
							onChange={this.onPostingGLId}
							value={this.props.PGL.modalSearch.searchPostingGLId}
						/>
					</Col>
					<Col md={2} />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<Checkbox
							style={{ flexDirection: "row" }}
							disabled={this.props.PGL.modalSearch.searchPostingGLId}
							checked={this.props.PGL.modalSearch.isSearchIncomplete}
							onChange={this.onSearchIncomplete}
						>
							{translate(ResourceAssistance.Message.searchIncomplete)}
						</Checkbox>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PGLModalSearchDetails))
