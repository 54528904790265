import { Checkbox, Radio, Space, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import ConfigGLModalReceivableAccountsReceive from "./ConfigGLModalReceivableAccountsReceive"

class ConfigGLModalReceivableDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onBalance = this.onBalance.bind(this)
		this.onDiscount = this.onDiscount.bind(this)
		this.onTransactionFee = this.onTransactionFee.bind(this)
		this.onWithholdingTax = this.onWithholdingTax.bind(this)
		this.onBalanceAdjustment = this.onBalanceAdjustment.bind(this)
		this.onIPD = this.onIPD.bind(this)
		this.onOPD = this.onOPD.bind(this)
		this.onCOA = this.onCOA.bind(this)
		this.onPayment = this.onPayment.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onDate = this.onDate.bind(this)
	}

	componentDidMount() {
		if (this.props.configGL.accountsReceivable.selectedAR.AR) {
			let selectedAR = this.props.configGL.accountsReceivable.selectedAR.AR
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK, selectedAR.includeBalance)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK, selectedAR.includeDiscount)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK, selectedAR.includeTransactionFee)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK, selectedAR.includeWithholdingTax)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT, selectedAR.includeBalanceAdjustment)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK, selectedAR.includeIPD)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK, selectedAR.includeOPD)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT, selectedAR.debit)
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION, Utils.trim(selectedAR.description.replace(ResourceAssistance.PROGRAM_DEFINED.date, "")))
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK, selectedAR.description.includes(ResourceAssistance.PROGRAM_DEFINED.date))
			let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.configGL.chartOfAccounts, selectedAR.chartOfAccounts)
			if (!_.isEmpty(selectedAry)) {
				this.props.setSelected(SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR, selectedAry[0], selectedAry[1])
			} else {
				this.props.setSelected(SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR, -1, null)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT, false)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION, "")
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK, false)
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.configGL.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR, value, this.props.configGL.chartOfAccounts[value])
		}
	}

	onBalance(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK, e.target.checked)
	}

	onDiscount(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK, e.target.checked)
	}

	onTransactionFee(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK, e.target.checked)
	}

	onWithholdingTax(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK, e.target.checked)
	}

	onBalanceAdjustment(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT, e.target.checked)
	}

	onIPD(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK, e.target.checked)
	}

	onOPD(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK, e.target.checked)
	}

	onPayment(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT, e.target.value)
	}

	onDescription(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION, e.target.value)
	}

	onDate(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK, e.target.checked)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeExpandAction="click"
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							value={this.props.configGL.accountsReceivable.modalAR.selectedAR.AR ? this.props.configGL.accountsReceivable.modalAR.selectedAR.index : undefined}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(
								this.props.configGL.chartOfAccounts,
								true,
								[],
								undefined,
								["displayName", "fullCode"],
								false
							)}
						/>
					</Col>
				</Row>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.calculation)}</legend>
					<Row className="g-2">
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsReceivable.modalAR.isIncludeBalance} onChange={this.onBalance}>
								{translate(ResourceAssistance.Hospitel.balance)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsReceivable.modalAR.isIncludeDiscount} onChange={this.onDiscount}>
								{translate(ResourceAssistance.Message.discount)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsReceivable.modalAR.isIncludeWithholdingTax}
								onChange={this.onWithholdingTax}
							>
								{translate(ResourceAssistance.Message.withholdingTax)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsReceivable.modalAR.isIncludeTransactionFee}
								onChange={this.onTransactionFee}
							>
								{translate(ResourceAssistance.Message.transactionFee)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsReceivable.modalAR.isIncludeBalanceAdjustment}
								onChange={this.onBalanceAdjustment}
							>
								{translate(ResourceAssistance.Message.balanceAdjustment)}
							</Checkbox>
						</Col>
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.department)}</legend>
					<Row className="g-2">
						<Col>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsReceivable.modalAR.isIncludeIPD} onChange={this.onIPD}>
								{translate(ResourceAssistance.Hospitel.ipd)}
							</Checkbox>
						</Col>
						<Col>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsReceivable.modalAR.isIncludeOPD} onChange={this.onOPD}>
								{translate(ResourceAssistance.Message.opd)}
							</Checkbox>
						</Col>
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.payment)}</legend>
					<Row>
						<Col />
						<Col>
							<Radio.Group value={this.props.configGL.accountsReceivable.modalAR.isDebit} onChange={this.onPayment}>
								<Space direction="horizontal" size={"large"}>
									<Radio value={false}>{translate(ResourceAssistance.Message.credit)}</Radio>
									<Radio value={true}>{translate(ResourceAssistance.Message.debit)}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col />
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.transactionDescription)}</legend>
					<Row>
						<Col>
							<GInput
								required
								placeholder={translate(ResourceAssistance.Message.description)}
								value={this.props.configGL.accountsReceivable.modalAR.description}
								onChange={this.onDescription}
							/>
						</Col>
					</Row>
					<Row>
						<Col />
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsReceivable.modalAR.isDescriptionIncludesDate}
								onChange={this.onDate}
							>
								{translate(ResourceAssistance.Message.date)}
							</Checkbox>
						</Col>
						<Col />
					</Row>
				</fieldset>
				<ConfigGLModalReceivableAccountsReceive />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	configGL: state.admin.configGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGLModalReceivableDetails))
