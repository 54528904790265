import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, SET_IPD_DTX_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import Glucose from "~/view/component/DTX/Glucose"

class IPDModalDTX extends React.Component {
	constructor(props) {
		super(props)
		this.DTXRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	isDoneBtnDisabled() {
		if (!this.DTXRef.current || !this.DTXRef.current.state.datetime) {
			return true
		}
		let target = Object.assign({}, this.props.ipd.selectedDTX.DTX, {
			dateTime: this.DTXRef.current.state.datetime,
			level: this.DTXRef.current.state.level,
		})
		return _.isEqual(target, this.props.ipd.selectedDTX.DTX)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.props.onModalDisplayAction(SET_IPD_DTX_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveGlucose,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.ipd.selectedPatient.patient.id,
				admissionId: this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.id,
				userId: this.props.login.user.id,
				glucoses: [
					{
						id: this.props.ipd.selectedDTX.DTX ? this.props.ipd.selectedDTX.DTX.id : 0,
						dateTime: this.DTXRef.current.state.datetime,
						level: this.DTXRef.current.state.level,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setHospitelPatients(patients)
			}
			this.props.onModalDisplayAction(SET_IPD_DTX_DISPLAY, false)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.DTX)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Glucose
							ref={this.DTXRef}
							onRenderParentCallback={this.onRenderParentCallback}
							glucose={
								this.props.ipd.selectedDTX.DTX
									? {
											datetime: this.props.ipd.selectedDTX.DTX.dateTime,
											level: this.props.ipd.selectedDTX.DTX.level,
									  }
									: null
							}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button type={ResourceAssistance.Button.type.submit} variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isInpatientGlucoseDisplay,
	login: state.login,
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalDTX))
