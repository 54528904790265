import { Select } from "antd"
import React from "react"
import { Col, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE,
	SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT,
	SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import { ANTIGEN_METHOD, EAntigenMethod } from "./CLModalNegativeDetails"

class CLModalNegativeSARS extends React.Component {
	constructor(props) {
		super(props)

		this.onNGene = this.onNGene.bind(this)
		this.onSGene = this.onSGene.bind(this)
		this.onOGene = this.onOGene.bind(this)
		this.onEGene = this.onEGene.bind(this)
		this.onCategory = this.onCategory.bind(this)
		this.onPCRComment = this.onPCRComment.bind(this)
	}

	componentDidUpdate(prevState, prevProp) {
		if (prevState.lab.modalNegative.analyte !== this.props.lab.modalNegative.analyte) {
			if (this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.SARS_COV_2_RNA_PCR) {
				this.reset()
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE, "")
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE, "")
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE, "")
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE, "")
		this.props.setSelected(SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE, -1, null)
		this.props.setSelected(SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT, -1, null)
	}

	onNGene(event) {
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_NGENE, event.target.value)
	}

	onSGene(event) {
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_SGENE, event.target.value)
	}

	onOGene(event) {
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_OGENE, event.target.value)
	}

	onEGene(event) {
		this.props.setValue(SET_HOSPITEL_LAB_MODAL_NEGATIVE_EGENE, event.target.value)
	}

	onCategory(value) {
		this.props.setSelected(
			SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_TYPE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.lab.modalNegative.types[value] : null
		)
	}

	onPCRComment(value) {
		this.props.setSelected(
			SET_LAB_MODAL_NEGATIVE_SELECTED_PCR_COMMENT_COMMENT,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.lab.modalNegative.selectedType.type.labComments[value] : null
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.sarsCov2RnaPcr)}</legend>
				<Row>
					<Col md="auto">
						<Row>
							<Col>
								<InputGroup style={{ height: "38px" }}>
									<InputGroup.Radio
										value={EAntigenMethod.SARS_COV_2_RNA_PCR}
										name={ANTIGEN_METHOD}
										checked={
											this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR
										}
										onChange={this.props.onMethod}
									/>
								</InputGroup>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<GInput
									placeholder={translate(ResourceAssistance.Hospitel.eGene)}
									type={ResourceAssistance.FormControl.type.number}
									step="0.001"
									disabled={this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR}
									onChange={this.onEGene}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR
											? this.props.lab.modalNegative.eGene
											: ""
									}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									placeholder={translate(ResourceAssistance.Hospitel.nGene)}
									type={ResourceAssistance.FormControl.type.number}
									step="0.001"
									disabled={this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR}
									onChange={this.onNGene}
									value={this.props.lab.modalNegative.nGene}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									placeholder={translate(ResourceAssistance.Hospitel.oGene)}
									type={ResourceAssistance.FormControl.type.number}
									step="0.001"
									disabled={this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR}
									onChange={this.onOGene}
									value={this.props.lab.modalNegative.oGene}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									placeholder={translate(ResourceAssistance.Hospitel.sGene)}
									type={ResourceAssistance.FormControl.type.number}
									step="0.001"
									disabled={this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR}
									onChange={this.onSGene}
									value={this.props.lab.modalNegative.sGene}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<Select
									showSearch
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder={translate(ResourceAssistance.Hospitel.pcr)}
									disabled={this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR}
									status={this.props.lab.modalNegative.selectedType.type ? undefined : "error"}
									onChange={this.onCategory}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR &&
										this.props.lab.modalNegative.selectedType.type
											? this.props.lab.modalNegative.selectedType.index
											: undefined
									}
								>
									{Utils.renderSelects(this.props.lab.modalNegative.types, false)}
								</Select>
							</Col>
						</Row>
						<Row>
							<Col>
								<Select
									showSearch
									style={{ flexGrow: 1, textAlign: "center" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}
									placeholder={translate(ResourceAssistance.Message.pcrComment)}
									disabled={
										this.props.lab.modalNegative.analyte !== ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR ||
										!this.props.lab.modalNegative.selectedType.type
									}
									status={this.props.lab.modalNegative.selectedComment.comment ? undefined : "error"}
									loading={this.props.lab.modalNegative.isPCRTypeLoading}
									onChange={this.onPCRComment}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR &&
										this.props.lab.modalNegative.selectedComment.comment
											? this.props.lab.modalNegative.selectedComment.index
											: undefined
									}
								>
									{Utils.renderSelects(
										this.props.lab.modalNegative.selectedType.type
											? this.props.lab.modalNegative.selectedType.type.labComments
											: [],
										false,
										-1,
										"name"
									)}
								</Select>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<GInput
									autoSize
									minRows={7}
									maxRows={11}
									placeholder={translate(ResourceAssistance.Message.pcrComment)}
									value={
										this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR &&
										this.props.lab.modalNegative.selectedComment.comment
											? this.props.lab.modalNegative.selectedComment.comment.comments
											: ""
									}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalNegativeSARS)
