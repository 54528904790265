import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME,
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalDefaultOrderOrder extends React.Component {
	ADMIT_HOSPITEL = "Admit Hospitel"
	ON_OXYGEN_CANNULAR_LPM = "On oxygen cannular 3 LPM"
	ON_OXYGEN_MASK_WITH_BAG_10_LPM = "On oxygen mask with bag 10 LPM"
	REGULAR_DIET = "Regular diet"
	RECORD_VITAL_SIGN_OD = "Record vital sign OD"
	MONITOR_SPO2 = "Monitor SpO2 if < 95%, please notify"

	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onAdmitHospitel = this.onAdmitHospitel.bind(this)
		this.onOxygenCannular = this.onOxygenCannular.bind(this)
		this.onOxygenMask = this.onOxygenMask.bind(this)
		this.onDiet = this.onDiet.bind(this)
		this.onVitalSign = this.onVitalSign.bind(this)
		this.onMonitor = this.onMonitor.bind(this)
		this.onOneTimeCheckAll = this.onOneTimeCheckAll.bind(this)
		this.onContinuesCheckAll = this.onContinuesCheckAll.bind(this)
		this.onDescription = this.onDescription.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.hospitel.modalDefaultOrder.startDateTime !== this.props.hospitel.modalDefaultOrder.startDateTime) {
			if (this.props.hospitel.modalDefaultOrder.startDateTime > this.props.hospitel.modalDefaultOrder.endDateTime) {
				this.props.setValue(
					SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME,
					Utils.generateDateFromLong(this.props.hospitel.modalDefaultOrder.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
				)
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME, Utils.generateDate().getTime())
		this.props.setValue(
			SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME,
			Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
		)
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR, "")
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION, "")
	}

	onStartDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME, date.getTime())
	}

	onOneTimeCheckAll(event) {
		this.props.setValue(
			SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL,
			event.target.checked ? this.ADMIT_HOSPITEL : ""
		)
		this.props.setValue(
			SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR,
			event.target.checked ? this.ON_OXYGEN_CANNULAR_LPM : ""
		)
		this.props.setValue(
			SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK,
			event.target.checked ? this.ON_OXYGEN_MASK_WITH_BAG_10_LPM : ""
		)
	}

	onAdmitHospitel(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL, event.target.checked ? event.target.value : "")
	}

	onOxygenCannular(event) {
		this.props.setValue(
			SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR,
			event.target.checked ? event.target.value : ""
		)
	}

	onOxygenMask(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK, event.target.checked ? event.target.value : "")
	}

	onContinuesCheckAll(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET, event.target.checked ? this.REGULAR_DIET : "")
		this.props.setValue(
			SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN,
			event.target.checked ? this.RECORD_VITAL_SIGN_OD : ""
		)
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR, event.target.checked ? this.MONITOR_SPO2 : "")
	}

	onDiet(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET, event.target.checked ? event.target.value : "")
	}

	onVitalSign(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN, event.target.checked ? event.target.value : "")
	}

	onMonitor(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR, event.target.checked ? event.target.value : "")
	}

	onDescription(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row style={{ alignSelf: "center" }}>
					<Col md="auto">
						<DatePicker
							isShowTime={false}
							minDate={Utils.generateDate()}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.hospitel.modalDefaultOrder.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md={"auto"}>
						<DatePicker
							isShowTime={false}
							minDate={Utils.generateDateFromLong(this.props.hospitel.modalDefaultOrder.startDateTime)}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.hospitel.modalDefaultOrder.endDateTime)}
							onChange={this.onEndDate}
							isShowDateRange
							shouldCloseOnSelect={false}
							rangeStartDate={Utils.generateDateFromLong(this.props.hospitel.modalDefaultOrder.startDateTime)}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "0.3rem" }}>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.description}>
							{(placeholder) => (
								<FormControl
									autoFocus
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									placeholder={placeholder}
									value={this.props.hospitel.modalDefaultOrder.description}
									onChange={this.onDescription}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
				<fieldset>
					<legend>{"One Time"}</legend>
					<Row>
						<Col>
							<FormGroup controlId={"first_day_one_time_order"}>
								<FormCheck label={"All"} onChange={this.onOneTimeCheckAll} />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"admitHospitel"}>
								<FormCheck
									label={this.ADMIT_HOSPITEL}
									checked={!_.isEmpty(this.props.hospitel.modalDefaultOrder.admitHospitel)}
									onChange={this.onAdmitHospitel}
									value={this.ADMIT_HOSPITEL}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"oxygenCannular"}>
								<FormCheck
									label={this.ON_OXYGEN_CANNULAR_LPM}
									checked={!_.isEmpty(this.props.hospitel.modalDefaultOrder.oxygenCannular)}
									onChange={this.onOxygenCannular}
									value={this.ON_OXYGEN_CANNULAR_LPM}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"oxygenMask"}>
								<FormCheck
									label={this.ON_OXYGEN_MASK_WITH_BAG_10_LPM}
									checked={!_.isEmpty(this.props.hospitel.modalDefaultOrder.oxygenMask)}
									onChange={this.onOxygenMask}
									value={this.ON_OXYGEN_MASK_WITH_BAG_10_LPM}
								/>
							</FormGroup>
						</Col>
					</Row>
				</fieldset>
				<fieldset>
					<legend>{"Continues"}</legend>
					<Row>
						<Col>
							<FormGroup controlId={"first_day_continues_order"}>
								<FormCheck label={"All"} onChange={this.onContinuesCheckAll} />
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"diet"}>
								<FormCheck
									label={this.REGULAR_DIET}
									checked={!_.isEmpty(this.props.hospitel.modalDefaultOrder.diet)}
									onChange={this.onDiet}
									value={this.REGULAR_DIET}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"vitalSign"}>
								<FormCheck
									label={this.RECORD_VITAL_SIGN_OD}
									checked={!_.isEmpty(this.props.hospitel.modalDefaultOrder.vitalSign)}
									onChange={this.onVitalSign}
									value={this.RECORD_VITAL_SIGN_OD}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"monitor"}>
								<FormCheck
									label={this.MONITOR_SPO2}
									checked={!_.isEmpty(this.props.hospitel.modalDefaultOrder.monitor)}
									onChange={this.onMonitor}
									value={this.MONITOR_SPO2}
								/>
							</FormGroup>
						</Col>
					</Row>
				</fieldset>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDefaultOrderOrder))
