import { Radio, Space, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_PGL_MODAL_ENTRY_AMOUNT, SET_PGL_MODAL_ENTRY_DEBIT_IND, SET_PGL_MODAL_ENTRY_DESCRIPTION, SET_PGL_MODAL_ENTRY_SELECTED_GL } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class PGLModalEntryFields extends React.Component {
	constructor(props) {
		super(props)

		this.onCOA = this.onCOA.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onDebit = this.onDebit.bind(this)
		this.onAmount = this.onAmount.bind(this)
	}

	componentDidMount() {
		if (this.props.PGL.selectedEntry.entry) {
			let selectedEntry = this.props.PGL.selectedEntry.entry
			this.props.setValue(SET_PGL_MODAL_ENTRY_DESCRIPTION, selectedEntry.accountingTransDescr)
			this.props.setValue(SET_PGL_MODAL_ENTRY_DEBIT_IND, selectedEntry.debit)
			this.props.setValue(SET_PGL_MODAL_ENTRY_AMOUNT, selectedEntry.amount)
			if (!_.isEmpty(this.props.PGL.chartOfAccounts)) {
				this.initialCOA()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PGL.chartOfAccounts, this.props.PGL.chartOfAccounts)) {
			if (this.props.PGL.selectedEntry.entry && !_.isEmpty(this.props.PGL.chartOfAccounts)) {
				this.initialCOA()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PGL_MODAL_ENTRY_SELECTED_GL, -1, null)
		this.props.setValue(SET_PGL_MODAL_ENTRY_DESCRIPTION, "")
		this.props.setValue(SET_PGL_MODAL_ENTRY_DEBIT_IND, true)
		this.props.setValue(SET_PGL_MODAL_ENTRY_AMOUNT, "")
	}

	initialCOA() {
		let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.PGL.chartOfAccounts, this.props.PGL.selectedEntry.entry.accountingCode)
		if (!_.isEmpty(selectedAry)) {
			this.props.setSelected(SET_PGL_MODAL_ENTRY_SELECTED_GL, selectedAry[0], selectedAry[1])
		} else {
			this.props.setSelected(SET_PGL_MODAL_ENTRY_SELECTED_GL, -1, null)
		}
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PGL_MODAL_ENTRY_SELECTED_GL, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_PGL_MODAL_ENTRY_SELECTED_GL,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.PGL.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_PGL_MODAL_ENTRY_SELECTED_GL, value, this.props.PGL.chartOfAccounts[value])
		}
	}

	onDescription(e) {
		this.props.setValue(SET_PGL_MODAL_ENTRY_DESCRIPTION, e.target.value)
	}

	onDebit(e) {
		this.props.setValue(SET_PGL_MODAL_ENTRY_DEBIT_IND, e.target.value)
	}

	onAmount(e) {
		this.props.setValue(SET_PGL_MODAL_ENTRY_AMOUNT, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							loading={this.props.PGL.isChartOfAccountsLoading}
							value={this.props.PGL.modalEntry.selectedGL.GL ? this.props.PGL.modalEntry.selectedGL.index : undefined}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.PGL.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"], false)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={3}
							type={ResourceAssistance.FormControl.type.text}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.PGL.modalEntry.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<Radio.Group value={this.props.PGL.modalEntry.isDebit} onChange={this.onDebit}>
							<Space size={"large"}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.debit)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.credit)}
								</Radio>
							</Space>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onAmount}
							value={this.props.PGL.modalEntry.amount}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PGLModalEntryFields))
