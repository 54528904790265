import { Radio, Space } from "antd"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_ADM_MODAL_NEW_ROLE } from "~/redux/type"
import { setValue } from "~/redux/action"

class TypeModalNewInventory extends React.Component {
	ETypeRadio = {
		Doctor: {
			description: translate(ResourceAssistance.Message.doctor),
			value: ResourceAssistance.Enum.doctor,
		},
		Nurse: {
			description: translate(ResourceAssistance.Message.nurse),
			value: ResourceAssistance.Enum.nurse,
		},
		Other: {
			description: translate(ResourceAssistance.Message.other),
			value: ResourceAssistance.Enum.other,
		},
	}
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
	}

	componentDidMount() {
		if (this.props.type.selectedType.type) {
			this.props.setValue(SET_ADM_MODAL_NEW_ROLE, this.props.type.selectedType.type.role)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_MODAL_NEW_ROLE, ResourceAssistance.CONSTANT.OTHER)
	}

	onType(e) {
		this.props.setValue(SET_ADM_MODAL_NEW_ROLE, e.target.value)
	}

	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayInventory && (
					<Row>
						<Col>
							<Space align="center" size={"large"} style={{ alignSelf: "center" }}>
								<Radio.Group value={this.props.type.modalNew.role} onChange={this.onType}>
									<Radio style={{ flexDirection: "row" }} value={this.ETypeRadio.Doctor.value}>
										{this.ETypeRadio.Doctor.description}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={this.ETypeRadio.Nurse.value}>
										{this.ETypeRadio.Nurse.description}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={this.ETypeRadio.Other.value}>
										{this.ETypeRadio.Other.description}
									</Radio>
								</Radio.Group>
							</Space>
						</Col>
					</Row>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	inventory: state.admin.type.inventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewInventory)
