import { ResourceAssistance } from "~/i18n"
import {
	SET_ADMIN_TYPE_TITLE,
	SET_ADM_BANK_BRANCHES,
	SET_ADM_BANK_MODAL_BRANCH_ACCOUNT,
	SET_ADM_BANK_MODAL_BRANCH_ACTIVE,
	SET_ADM_BANK_MODAL_BRANCH_ADDRESS,
	SET_ADM_BANK_MODAL_BRANCH_CODE,
	SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION,
	SET_ADM_BANK_MODAL_BRANCH_NAME,
	SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER,
	SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT,
	SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER,
	SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT,
	SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT,
	SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE,
	SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE,
	SET_ADM_BANK_SELECTED_BRANCH,
	SET_ADM_CATEGORY_CHART_OF_ACCOUNTS,
	SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING,
	SET_ADM_CATEGORY_COA_SELECTED_AP,
	SET_ADM_CATEGORY_COA_SELECTED_AR,
	SET_ADM_CATEGORY_INVENTORY_PAYABLE_ACTIVITIES,
	SET_ADM_CATEGORY_INVENTORY_RECEIVABLE_ACTIVITIES,
	SET_ADM_CATEGORY_MODAL_COA_DATE_IND,
	SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION,
	SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND,
	SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP,
	SET_ADM_CATEGORY_MODAL_COA_SELECTED_AR,
	SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND,
	SET_ADM_CATEGORY_MODAL_COA_WORKFLOW,
	SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLES,
	SET_ADM_HEALTH_CARE_PLAN_COMPANIES,
	SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY,
	SET_ADM_HEALTH_CARE_PLAN_SELECTED_ACCOUNTS_RECEIVABLE,
	SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY,
	SET_ADM_CATEGORY_SERVICE_IS_CHRGITEMS_LOADING,
	SET_ADM_CATEGORY_SERVICE_CHRGITEMS,
	SET_ADM_MODAL_NEW_ROLE,
	SET_ADM_MODAL_NEW_SKIP_IND,
	SET_ADM_MODAL_NEW_WHT_TYPE,
	SET_ADM_NEW_TYPE_DISPLAY_ADDRESS,
	SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_CODE,
	SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL,
	SET_ADM_NEW_TYPE_DISPLAY_INVENTORY,
	SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT,
	SET_ADM_NEW_TYPE_DISPLAY_PERCENT,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN,
	SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_TEL,
	SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM,
	SET_ADM_TYPES,
	SET_ADM_TYPE_MODAL_NEW_ADDRESS,
	SET_ADM_TYPE_MODAL_NEW_TEL,
	SET_GENERAL_TYPES,
	SET_GENERAL_TYPE_LOADING,
	SET_SEARCH_TEXT,
	SET_SELECTED_TYPE,
	SET_TYPE_DISPLAY_INACTIVE,
	SET_TYPE_URL,
} from "~/redux/type"
const init = {
	searchText: "",
	displayInactive: true,
	url: "",
	chartOfAccounts: [],
	isChartOfAccountsLoading: false,
	types: {
		header: [],
		types: [],
		filtered: [],
		body: [],
		rowColor: [],
		isLoading: false,
		popoverMsg: [],
	},
	selectedType: {
		type: null,
		index: -1,
	},
	modal: {
		title: "",
		displayCode: false,
		displaySavingAccount: false,
		displayPrimaryPlan: false,
		displayPrimaryHospital: false,
		displayBankAccount: false,
		displayCheckAccount: false,
		displayCreditCardAccount: false,
		displayIPayFormat: false,
		displayPercent: false,
		displayInventory: false,
		displayAddress: false,
		displayTel: false,
		displayChrgItem: false,
	},
	modalNew: {
		withholdingTaxType: ResourceAssistance.CONSTANT.NONE,
		isSkipPercent: false,
		role: ResourceAssistance.Enum.other,
		address: "",
		tel: "",
	},
	healthcarePlan: {
		selectedCompany: {
			index: -1,
			company: null,
		},
		selectedAR: {
			index: -1,
			AR: null,
		},
		companyTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.insuranceCompany,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
			rowColor: [],
			popoverMsg: [],
		},
		accountsReceivableTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
			rowColor: [],
			popoverMsg: [],
		},
		modalCompany: {
			name: "",
			description: "",
			isActive: true,
			insuranceCompanies: [],
			selectedInsuranceCompany: {
				index: -1,
				company: null,
			},
		},
		modalAccountsReceivable: {
			isLinkGL: false,
			selectedAR: {
				index: -1,
				AR: null,
			},
		},
	},
	bank: {
		selectedBranch: {
			index: -1,
			branch: null,
		},
		branchTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.bankAccNum,
				ResourceAssistance.Message.address,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
		},
		modalBranch: {
			code: "",
			name: "",
			account: "",
			address: "",
			description: "",
			isActive: true,
		},
		modalBank: {
			isDisplayFormat: false,
			batchRecordFormat: "",
			batchMaxCharacters: "",
			batchEmptyCharacter: "",
			batchMaxEmptyCharacters: "",
			detailRecordFormat: "",
			detailMaxCharacters: "",
			detailEmptyCharacter: "",
			detailMaxEmptyCharacters: "",
			selectedBatchVariable: {
				index: -1,
				variable: null,
			},
			selectedDetailVariable: {
				index: -1,
				variable: null,
			},
		},
	},
	COA: {
		selectedAR: {
			index: -1,
			activity: null,
		},
		selectedAP: {
			index: -1,
			activity: null,
		},
		accountingReceivableTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.transactionDescription,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
			rowColor: [],
			popoverMsg: [],
		},
		accountingPayableTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.transactionDescription,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
			rowColor: [],
			popoverMsg: [],
		},
		modalAccount: {
			description: "",
			isLinkGL: false,
			workflow: 1,
			isDescriptionIncludesSupplier: false,
			isDescriptionIncludesDate: false,
			selectedAR: {
				index: -1,
				account: null,
			},
			selectedAP: {
				index: -1,
				account: null,
			},
		},
	},
	service: {
		isChrgItemsLoading: false,
		chrgItems: [],
	},
}

const typeReducer = (state = init, action) => {
	switch (action.type) {
		case SET_GENERAL_TYPE_LOADING:
			return Object.assign({}, state, {
				types: {
					...state.types,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_ADM_TYPES:
		case SET_GENERAL_TYPES:
			return Object.assign({}, state, {
				types: {
					...state.types,
					types: action.payload.types,
					filtered: action.payload.filtered,
					header: action.payload.header,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
					rowColor: action.payload.rowColor,
					popoverMsg: action.payload.popoverMsg,
				},
			})

		case SET_SEARCH_TEXT:
			return Object.assign({}, state, {
				searchText: action.payload.text,
			})

		case SET_TYPE_DISPLAY_INACTIVE:
			return Object.assign({}, state, {
				displayInactive: action.payload.value,
			})

		case SET_SELECTED_TYPE:
			return Object.assign({}, state, {
				selectedType: {
					...state.selectedType,
					type: action.payload.type,
					index: action.payload.index,
				},
			})

		case SET_TYPE_URL:
			return Object.assign({}, state, {
				url: action.payload.url,
			})

		case SET_ADMIN_TYPE_TITLE:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					title: action.payload.title,
				},
			})

		case SET_ADM_NEW_TYPE_DISPLAY_CODE:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayCode: action.payload.value,
				},
			})

		case SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displaySavingAccount: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayBankAccount: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayCheckAccount: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayCreditCardAccount: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayPrimaryPlan: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayPrimaryHospital: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayIPayFormat: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_PERCENT:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayPercent: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_INVENTORY:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayInventory: action.payload.value,
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					selectedCompany: {
						index: action.payload.index,
						company: action.payload.selected,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_SELECTED_ACCOUNTS_RECEIVABLE:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					selectedAR: {
						index: action.payload.index,
						AR: action.payload.selected,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalCompany: {
						...state.healthcarePlan.modalCompany,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalCompany: {
						...state.healthcarePlan.modalCompany,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalCompany: {
						...state.healthcarePlan.modalCompany,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_COMPANIES: {
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					companyTable: {
						...state.healthcarePlan.companyTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		}
		case SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalAccountsReceivable: {
						...state.healthcarePlan.modalAccountsReceivable,
						selectedAR: {
							index: action.payload.index,
							AR: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalAccountsReceivable: {
						...state.healthcarePlan.modalAccountsReceivable,
						isLinkGL: action.payload.value,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLES:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					accountsReceivableTable: {
						...state.healthcarePlan.accountsReceivableTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalCompany: {
						...state.healthcarePlan.modalCompany,
						insuranceCompanies: action.payload.objs,
					},
				},
			})
		case SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				healthcarePlan: {
					...state.healthcarePlan,
					modalCompany: {
						...state.healthcarePlan.modalCompany,
						selectedInsuranceCompany: {
							index: action.payload.index,
							company: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_BANK_MODAL_BRANCH_CODE:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBranch: {
						...state.bank.modalBranch,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_BRANCH_NAME:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBranch: {
						...state.bank.modalBranch,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_BRANCH_ACCOUNT:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBranch: {
						...state.bank.modalBranch,
						account: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_BRANCH_ADDRESS:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBranch: {
						...state.bank.modalBranch,
						address: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBranch: {
						...state.bank.modalBranch,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_BRANCH_ACTIVE:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBranch: {
						...state.bank.modalBranch,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						selectedBatchVariable: {
							index: action.payload.index,
							variable: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						selectedDetailVariable: {
							index: action.payload.index,
							variable: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						isDisplayFormat: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						batchRecordFormat: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						detailRecordFormat: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						batchMaxCharacters: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						detailMaxCharacters: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						batchEmptyCharacter: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						batchMaxEmptyCharacters: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						detailEmptyCharacter: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					modalBank: {
						...state.bank.modalBank,
						detailMaxEmptyCharacters: action.payload.value,
					},
				},
			})
		case SET_ADM_BANK_BRANCHES:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					branchTable: {
						...state.bank.branchTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_BANK_SELECTED_BRANCH:
			return Object.assign({}, state, {
				bank: {
					...state.bank,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_SELECTED_AR:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						selectedAR: {
							index: action.payload.index,
							account: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						selectedAP: {
							index: action.payload.index,
							account: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						isLinkGL: action.payload.value,
					},
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						isDescriptionIncludesSupplier: action.payload.value,
					},
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_DATE_IND:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						isDescriptionIncludesDate: action.payload.value,
					},
				},
			})
		case SET_ADM_CATEGORY_COA_SELECTED_AR:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					selectedAR: {
						index: action.payload.index,
						activity: action.payload.selected,
					},
				},
			})
		case SET_ADM_CATEGORY_COA_SELECTED_AP:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					selectedAP: {
						index: action.payload.index,
						activity: action.payload.selected,
					},
				},
			})
		case SET_ADM_CATEGORY_INVENTORY_RECEIVABLE_ACTIVITIES:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					accountingReceivableTable: {
						...state.COA.accountingReceivableTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_CATEGORY_INVENTORY_PAYABLE_ACTIVITIES:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					accountingPayableTable: {
						...state.COA.accountingPayableTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_CATEGORY_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				isChartOfAccountsLoading: action.payload.isLoading,
			})
		case SET_ADM_MODAL_NEW_SKIP_IND:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isSkipPercent: action.payload.value,
				},
			})
		case SET_ADM_MODAL_NEW_ROLE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					role: action.payload.value,
				},
			})
		case SET_ADM_MODAL_NEW_WHT_TYPE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					withholdingTaxType: action.payload.value,
				},
			})
		case SET_ADM_CATEGORY_MODAL_COA_WORKFLOW:
			return Object.assign({}, state, {
				COA: {
					...state.COA,
					modalAccount: {
						...state.COA.modalAccount,
						workflow: action.payload.value,
					},
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_ADDRESS:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayAddress: action.payload.value,
				},
			})
		case SET_ADM_TYPE_MODAL_NEW_ADDRESS:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					address: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_TEL:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayTel: action.payload.value,
				},
			})
		case SET_ADM_TYPE_MODAL_NEW_TEL:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					tel: action.payload.value,
				},
			})
		case SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM:
			return Object.assign({}, state, {
				modal: {
					...state.modal,
					displayChrgItem: action.payload.value,
				},
			})
		case SET_ADM_CATEGORY_SERVICE_IS_CHRGITEMS_LOADING:
			return Object.assign({}, state, {
				service: {
					...state.service,
					isChrgItemsLoading: action.payload.isLoading,
				},
			})
		case SET_ADM_CATEGORY_SERVICE_CHRGITEMS:
			return Object.assign({}, state, {
				service: {
					...state.service,
					chrgItems: action.payload.objs,
				},
			})
		default:
			return state
	}
}

export default typeReducer
