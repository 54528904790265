import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPoSelectedSupplier, setPoSuppliers } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PORightPODetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORightPODetails",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedPo.index !== this.props.selectedPo.index || !_.isEqual(prevProps.selectedPo.po, this.props.selectedPo.po)) {
			if (this.props.selectedPo.index === -1) {
				this.props.setPoSuppliers([])
			} else {
				this.props.setPoSuppliers(this.props.selectedPo.po.suppliers)
			}
		}
		if (!_.isEqual(prevProps.po.supplier.filtered, this.props.po.supplier.filtered)) {
			let index = this.props.po.selectedSupplier.supplier
				? this.props.po.supplier.filtered.findIndex((each) => each.code === this.props.po.selectedSupplier.supplier.code)
				: -1
			if (index !== -1) {
				this.props.setPoSelectedSupplier(index, this.props.po.supplier.filtered[index])
			} else {
				this.props.setPoSelectedSupplier(-1, null)
			}
		}
	}

	onSelectRow(row, index) {
		this.props.setPoSelectedSupplier(index, this.props.supplier.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"g-0"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.supplier}
						displayAttachment
						onClick={this.onSelectRow}
						isClearHighlight={this.props.selectedSupplier.index === -1}
						highlightedRow={this.props.selectedSupplier.index}
					/>
					{this.props.supplier.isLoading && <Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	supplier: state.purchaseOrder.supplier,
	selectedPo: state.purchaseOrder.selectedPo,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
	po: state.purchaseOrder,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setPoSuppliers, setPoSelectedSupplier }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightPODetails)
