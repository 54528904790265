const PreOperativeCheckList = {
	identifyThePatientNameSurnameAndDateOfBirth: "identifyThePatientNameSurnameAndDateOfBirth",
	markingSiteForSurgeryProcedure: "markingSiteForSurgeryProcedure",
	letterOfConsentToUndergoSurgeryDiagnosis: "letterOfConsentToUndergoSurgeryDiagnosis",
	letterOfConsentToReceiveAnesthesia: "letterOfConsentToReceiveAnesthesia",
	labResult: "labResult",
	imagingResult: "imagingResult",
	EKGResult: "EKGResult",
	cleanPatientBodyForSurgery: "cleanPatientBodyForSurgery",
	unisonEnema: "unisonEnema",
	NPOTime: "NPOTime",
	removeDentures: "removeDentures",
	eyeGlassesContactLensEyelashesRemoved: "eyeGlassesContactLensEyelashesRemoved",
	jewelryAndHairpiecesRemoved: "jewelryAndHairpiecesRemoved",
	bodyPiercingRemoved: "bodyPiercingRemoved",
	changePatientClothes: "changePatientClothes",
	haveThePatientUrinateBeforeBeingReferralOR: "haveThePatientUrinateBeforeBeingReferralOR",
	bloodRequested: "bloodRequested",
	preEducation: "preEducation",
	otherMedicalDevice: "otherMedicalDevice",
	toolSpecialEquipment: "toolSpecialEquipment",
	implant: "implant",
}

export default PreOperativeCheckList
