import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { MdDeleteForever, MdOutlineCancel } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setTfRequestItems,
	setTfRequests,
	setTfSelectedRequestItem,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_TF_CANCEL_REQUEST_ITEM_DISPLAY, SET_TF_REQUEST_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFRightSecondRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onTooltipEntering = this.onTooltipEntering.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onCancel = this.onCancel.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.TF.delete_request_item)) {
					this.deleteRequestItem()
				}
			}
		}
		if (JSON.stringify(prevProps.transfer.selectedRequest) !== JSON.stringify(this.props.transfer.selectedRequest)) {
			this.props.setTfSelectedRequestItem(-1, null)
			if (this.props.transfer.selectedRequest.request) {
				this.props.setTfRequestItems(this.props.transfer.selectedRequest.request.items)
			} else {
				this.props.setTfRequestItems([])
			}
		}
	}

	isDisplayDelete() {
		return (
			Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Request, this.props.login.user.roles) &&
			this.props.transfer.selectedRequest.request &&
			!this.props.transfer.selectedRequest.request.approvalApprove &&
			!this.props.transfer.selectedRequest.request.requesterApproved
		)
	}

	isDisplayCancel() {
		return (
			(Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Request, this.props.login.user.roles) ||
				Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.Transfer, this.props.login.user.roles)) &&
			this.props.transfer.selectedRequest.request &&
			!this.props.transfer.selectedRequest.request.approvalApproved &&
			this.props.transfer.selectedRequest.request.requesterApproved
		)
	}

	deleteRequestItem() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.deleteRequestItem,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				userId: this.props.login.user.id,
				items: [
					{
						transferRequestItemId: this.props.transfer.selectedRequestItem.item.id,
					},
				],
			},
		}
		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== this.props.transfer.selectedRequest.request.id)
			if (res.data.requests.length > 0) {
				requests.push(res.data.requests[0])
			}
			this.props.setTfRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(row, index) {
		let item = this.props.transfer.requestItemTable.filtered[index]
		this.props.setTfSelectedRequestItem(index, item)
	}

	onTooltipEntering(event, rKey, cKey) {
		let transferredIdx = 6
		if (cKey === transferredIdx) {
			event.children[1].style.backgroundColor = ResourceAssistance.CSS.Color.dark_red
		}
	}

	onDelete(e, row, rIdx, cIdx) {
		let selectedItem = this.props.transfer.requestItemTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.TF.delete_request_item)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{selectedItem.code}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.genericName)} span={1}>
						{selectedItem.name}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.brandName)} span={1}>
						{selectedItem.brand}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.unit)} span={1}>
						{selectedItem.unit}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
						{Utils.formatNumWithComma(selectedItem.amount)}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onCancel(e, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_TF_CANCEL_REQUEST_ITEM_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pendingItems)}</legend>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							enableOverlay
							onTooltipEntering={this.onTooltipEntering}
							enableHighlight
							striped
							hover
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlineRed, ResourceAssistance.Button.variant.outlineRed]}
							btnMessages={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.cancelled }),
							]}
							btnIcons={[
								this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayCancel() ? <MdOutlineCancel size={ResourceAssistance.ReactIcon.size} /> : undefined,
							]}
							onCheckedArray={[this.onDelete, this.onCancel]}
							data={this.props.transfer.requestItemTable}
							isClearHighlight={this.props.transfer.selectedRequestItem.index === -1}
							highlightedRow={this.props.transfer.selectedRequestItem.index}
							onClick={this.onSelectRow}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setTfRequestItems,
			setTfSelectedRequestItem,
			onModalDisplayAction,
			setWarningId,
			setWarningMsgAction,
			setValue,
			setTfRequests,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TFRightSecondRow))
