import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES,
	SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING,
	SET_REPORT_FINANCE_ARA_ORGS,
	SET_REPORT_FINANCE_ARA_ORGS_LOADING,
} from "~/redux/type"
import ReportFinanceARAFilters from "./ReportFinanceARAFilters"
import ReportFinanceARATab from "./ReportFinanceARATab"

class ReportFinanceARADetails extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.filter.orgs)) {
			this.loadOrgs()
		}
		if (_.isEmpty(this.props.filter.insuranceCompanies)) {
			this.loadInsuranceCompanies()
		}
	}
	loadOrgs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.finance.getOrgs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_REPORT_FINANCE_ARA_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_ARA_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_ARA_ORGS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_ARA_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadInsuranceCompanies() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.finance.getInsuranceCompanies,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<ReportFinanceARAFilters />
				<ReportFinanceARATab />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.finance.accountsReceivableAging,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportFinanceARADetails))
