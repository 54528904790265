import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class DepositSlipDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				transactions: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} displayBranch displayFax={false} displayTaxNum={false} />

						{/*Patient Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.pidIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.identificationIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.identification}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.addressIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientAddress}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>{this.state.data.telIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientTel}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Table Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.deposit]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.depositIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.payment]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.paymentIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Items */}
						{this.state.data.transactions.map((transation) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.deposit]}>
										<Text>{transation.words}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.payment]}>
										<Text>{transation.payment}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{transation.amount}</Text>
									</View>
								</View>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.remarkIntl}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={ResourceAssistance.Report.styles.textItalic}>1. {this.state.data.remark1Intl}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={ResourceAssistance.Report.styles.textItalic}>2. {this.state.data.remark2Intl}</Text>
							</View>
						</View>

						{/* Signature */}
						<Signature
							numOfSignature={1}
							signatureTitles={this.state.data.signatureTitles}
							signatureNames={this.state.data.signatureNames}
							signatureDate={this.state.data.signatureDate}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		fontStyle: "bold",
		paddingLeft: "10px",
	},
	infoBody: {
		fontStyle: "italic",
		paddingRight: "10px",
	},
	deposit: {
		minWidth: "60%",
		maxWidth: "60%",
	},
	payment: {
		minWidth: "25%",
		maxWidth: "25%",
	},
	amount: {
		minWidth: "15%",
		maxWidth: "15%",
	},
})

export default DepositSlipDocument
