import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import RCBottomFirstRow from "./RCBottomFirstRow"
import RCBottomSecondRow from "./RCBottomSecondRow"

class RCBottom extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCBottom",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<RCBottomFirstRow />
				<RCBottomSecondRow />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCBottom)
