import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME,
	SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class RegistrationModalRegisterRelative extends React.Component {
	constructor(props) {
		super(props)

		this.onFirstName = this.onFirstName.bind(this)
		this.onLastName = this.onLastName.bind(this)
		this.onRelationship = this.onRelationship.bind(this)
		this.onTel = this.onTel.bind(this)
		this.onAddress = this.onAddress.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedPatient.index !== -1) {
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME, this.props.ipd.selectedPatient.patient.relativeFirstName)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME, this.props.ipd.selectedPatient.patient.relativeLastName)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP, this.props.ipd.selectedPatient.patient.relationship)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL, this.props.ipd.selectedPatient.patient.relativeTel)
			this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS, this.props.ipd.selectedPatient.patient.relativeAddress)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL, "")
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS, "")
	}

	onFirstName(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_FIRST_NAME, event.target.value)
	}

	onLastName(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_LAST_NAME, event.target.value)
	}

	onRelationship(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIONSHIP, event.target.value)
	}

	onTel(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_TEL, event.target.value)
	}

	onAddress(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_REGISTER_RELATIVE_ADDRESS, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.relativeInfo)}</legend>
				<Row>
					<Col>
						<GInput
							placeholder={translate(ResourceAssistance.Message.firstName)}
							onChange={this.onFirstName}
							value={this.props.ipd.modalRegister.relativeFirstName}
						/>
					</Col>
					<Col>
						<GInput
							placeholder={translate(ResourceAssistance.Message.lastName)}
							onChange={this.onLastName}
							value={this.props.ipd.modalRegister.relativeLastName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							placeholder={translate(ResourceAssistance.Message.relationship)}
							onChange={this.onRelationship}
							value={this.props.ipd.modalRegister.relationship}
						/>
					</Col>
					<Col>
						<GInput
							type={ResourceAssistance.FormControl.type.tel}
							placeholder={translate(ResourceAssistance.Message.tel)}
							onChange={this.onTel}
							value={this.props.ipd.modalRegister.relativeTel}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.address)}
							onChange={this.onAddress}
							value={this.props.ipd.modalRegister.relativeAddress}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegistrationModalRegisterRelative))
