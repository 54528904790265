import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import {
	loadPRsAction,
	selectPRAction,
	selectPrItemAction,
	setLoadingAction,
	setPrLocationBranches,
	setPrSelectedBranch,
	setPrSelectedOrg,
} from "~/redux/action"
import { SET_PR_LOADING } from "~/redux/type"
import MinStock from "./MinStock"
import NewPR from "./NewPR"

class AbstractPrModal extends React.Component {
	constructor(props) {
		super(props)

		this.fetch = this.fetch.bind(this)
		this.callback = this.callback.bind(this)
		this.errorHandler = this.errorHandler.bind(this)
		this.onOrgChange = this.onOrgChange.bind(this)
		this.onBranchChange = this.onBranchChange.bind(this)
		this.reset = this.reset.bind(this)
		this.validateItem = this.validateItem.bind(this)
	}

	reset() {
		this.props.setPrSelectedOrg(-1, null)
		this.props.setPrSelectedBranch(-1, null)
		this.props.setPrLocationBranches([])
	}

	validateItem(curItems) {
		//check item already added by another pr either pending for approve or in-progress.
		let foundItems = curItems.reduce((curFoundItems, curItem) => {
			let prIdx = this.props.pr.location.selectedBranch.branch.prs.findIndex((pr) => {
				let idx = pr.items.findIndex(
					(item) =>
						item.code.trim().toLowerCase() === curItem.code.trim().toLowerCase() &&
						item.displayName.trim().toLowerCase() === curItem.displayName.trim().toLowerCase()
				)
				return idx > -1
			})

			if (prIdx > -1) {
				curFoundItems = curFoundItems
					.concat(
						curItem.code
							.concat(" ", curItem.displayName)
							.concat(" : #", this.props.pr.location.selectedBranch.branch.prs[prIdx].id)
					)
					.concat("\n")
			}

			return curFoundItems
		}, "")

		if (foundItems) {
			alert(
				`${this.props.intl.formatMessage({
					id: ResourceAssistance.Message.approvedByAnotherOrderWarning,
				})}\n${foundItems}`
			)
			return false
		}

		return true
	}

	setParam(param) {
		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.savePR}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: param,
		}

		return params
	}

	callback(res) {
		let orders = this.props.pr.prs
		res.data.orders.forEach((newOrder) => (orders = orders.filter((oldOrder) => oldOrder.id !== newOrder.id)))
		res.data.orders.forEach((order) => orders.push(order))

		let filteredOrders = orders.filter(
			(order) =>
				order.id.includes(this.props.pr.searchNum) &&
				order.creationDateTime >= this.props.pr.searchDate.startDate.getTime() &&
				order.creationDateTime <= this.props.pr.searchDate.endDate
		)

		this.props.loadPRsAction(orders, filteredOrders)
		let orderIndex = this.props.pr.selectedPR.index
		this.props.selectPRAction(orderIndex, this.props.pr.filtered[orderIndex])

		let index = this.props.pr.selectedPrItem.index
		if (index > -1) {
			this.props.selectPrItemAction(index, this.props.pr.selectedPR.pr.items[index])
		}
	}

	errorHandler(error) {
		this.props.setLoadingAction(SET_PR_LOADING, false)
	}

	fetch(param) {
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PR_LOADING, true)
		}

		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PR_LOADING, false)
		}
		axios(this.setParam(param), this.callback, this.errorHandler, reqInterceptor, resInterceptor)
	}

	onOrgChange(event) {
		if (!event.target.value) {
			this.props.setPrSelectedOrg(-1, null)
			this.props.setPrSelectedBranch(-1, null)

			this.props.setPrLocationBranches([])
		} else {
			this.props.setPrSelectedOrg(event.target.value, this.props.pr.location.orgs[event.target.value])
			this.props.setPrLocationBranches(this.props.pr.location.orgs[event.target.value].locations)
			this.props.setPrSelectedBranch(-1, null)

			let branch = document.getElementById(ResourceAssistance.CONSTANT.BRANCH)
			branch.selectedIndex = 0
		}
	}

	onBranchChange(event) {
		if (!event.target.value) {
			this.props.setPrSelectedBranch(-1, null)
		} else {
			this.props.setPrSelectedBranch(event.target.value, this.props.pr.location.branches[event.target.value])
		}
	}

	render() {
		return (
			<>
				<MinStock
					fetch={this.fetch}
					onOrgChange={this.onOrgChange}
					onBranchChange={this.onBranchChange}
					validateItem={this.validateItem}
					reset={this.reset}
					{...this.props}
				/>
				<NewPR
					fetch={this.fetch}
					onOrgChange={this.onOrgChange}
					onBranchChange={this.onBranchChange}
					validateItem={this.validateItem}
					reset={this.reset}
					{...this.props}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	pr: state.purchaseRequest,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			loadPRsAction,
			selectPRAction,
			selectPrItemAction,
			setLoadingAction,
			setPrSelectedOrg,
			setPrLocationBranches,
			setPrSelectedBranch,
		},
		dispatch
	),
	dispatch,
})
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AbstractPrModal))
