import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AccountsReceivableLeftAction from "./AccountsReceivableLeftAction"
import AccountsReceivableLeftDate from "./AccountsReceivableLeftDate"
import AccountsReceivableLeftFilter from "./AccountsReceivableLeftFilter"
import AccountsReceivableLeftInvoice from "./AccountsReceivableLeftInvoice"

class AccountsReceivableLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AccountsReceivableLeft",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<AccountsReceivableLeftDate />
				<AccountsReceivableLeftFilter />
				<AccountsReceivableLeftAction />
				<AccountsReceivableLeftInvoice />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccountsReceivableLeft)
