import React from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setTfModalCancelTRIReason } from "~/redux/action"

class TFModalCancelTRIReasonRow extends React.Component {
	constructor(props) {
		super(props)

		this.onTextAreaChange = this.onTextAreaChange.bind(this)
	}

	componentWillUnmount() {
		this.props.setTfModalCRIReason("")
	}

	onTextAreaChange(event) {
		this.props.setTfModalCRIReason(event.target.value)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							rows="5"
							onChange={this.onTextAreaChange}
						></FormControl>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfModalCRIReason: setTfModalCancelTRIReason }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalCancelTRIReasonRow)
