import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_FO_LOCATION_DISPLAY } from "~/redux/type"
import FinancialOperationsLeft from "./left/FinancialOperationsLeft"
import FinancialOperationsRight from "./right/FinancialOperationsRight"

class FinancialOperations extends React.Component {
	componentDidMount() {
		this.props.onModalDisplayAction(SET_FO_LOCATION_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				{this.props.FO.location && (
					<Container fluid className={ResourceAssistance.CSS.fullFlex}>
						<Row className={"g-0 full-size"}>
							<Col style={{ maxWidth: "437px" }}>
								<FinancialOperationsLeft />
							</Col>
							<Col>
								<FinancialOperationsRight />
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FinancialOperations))
