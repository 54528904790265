import { ResourceAssistance } from "~/i18n"
import { PrintableDataFactory } from "../PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class PostingGLPrintDataFactory extends PrintableDataFactory {
	static generateJournalVoucherData = (org, postingGL) => {
		let totalDebit = Utils.BigNumber(0)
		let totalCredit = Utils.BigNumber(0)
		return {
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.journalEntry }),
			idIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.id }),
			invoiceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceNum }),
			supplierIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.supplier }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			codeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.code }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			debitIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.debit }),
			creditIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.credit }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),

			name: org.displayName,
			address: org.address.displayName,

			id: postingGL.code + "-" + postingGL.id,
			date: Utils.formatDate(postingGL.creationDateTime),

			GLs: postingGL.postingGLs
				.sort((a, b) => Utils.sort(a.credit, b.credit))
				.map((each) => {
					totalDebit = totalDebit.plus(each.debit)
					totalCredit = totalCredit.plus(each.credit)
					return {
						code: each.code,
						name: each.name,
						debit: Utils.formatNumWithComma(Utils.BigNumber(each.debit).toFixed(2)),
						credit: Utils.formatNumWithComma(Utils.BigNumber(each.credit).toFixed(2)),
					}
				}),

			totalDebit: Utils.formatNumWithComma(totalDebit.toFixed(2)),
			totalCredit: Utils.formatNumWithComma(totalCredit.toFixed(2)),

			signatureNames: [postingGL.inspector.displayName, postingGL.lastModifiedBy.displayName],
		}
	}
}

export default PostingGLPrintDataFactory
