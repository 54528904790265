import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import {
	setLabModalPositiveAnalyte,
	setLabModalPositiveTestMethod,
	setLoadingAction,
	setObjArray,
} from "~/redux/action"
import {
	SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPES,
	SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING,
	SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPES,
	SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING,
} from "~/redux/type"
import CLModalPositiveAntigen from "./CLModalPositiveAntigen"
import CLModalPositiveSARS from "./CLModalPositiveSARS"
import CLModalPositiveXper from "./CLModalPositiveXper"

export const ANTIGEN_METHOD = "ANTIGEN_METHOD"
export const EAntigenMethod = {
	XPERT_XPRESS_SARS_COV_2: "HOSPITAL_LAB_MODAL_POSITIVE_METHOD_XPERT_XPRESS_SARS_COV_2",
	SARS_COV_2_RNA_PCR: "HOSPITAL_LAB_MODAL_POSITIVE_METHOD_SARS_COV_2_RNA_PCR",
	Chromatography: "HOSPITAL_LAB_MODAL_POSITIVE_ANTIGEN_METHOD_CHROMATOGRAPHY",
	Fluorescence: "HOSPITAL_LAB_MODAL_POSITIVE_ANTIGEN_METHOD_FLUORESCENCE",
}

class CLModalPositiveDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onMethod = this.onMethod.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.lab.modalPositive.types)) {
			this.loadPCRCommentCategories()
		}
		if (_.isEmpty(this.props.lab.modalPositive.antigenTypes)) {
			this.loadAntigenCommentCategories()
		}
	}

	componentWillUnmount() {
		this.props.setLabModalPositiveTestMethod(ResourceAssistance.CONSTANT.IMMUNO_CHROMATOGRAPHY_ASSAY)
		this.props.setLabModalPositiveAnalyte(ResourceAssistance.CONSTANT.COVID_19_ANTIGEN)
	}

	loadPCRCommentCategories() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.lab.getPCRCommentCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_LAB_MODAL_POSITIVE_PCR_COMMENT_TYPE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadAntigenCommentCategories() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.lab.getAntigenCommentCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_LAB_MODAL_POSITIVE_ANTIGEN_COMMENT_TYPE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onMethod(event) {
		switch (event.target.value) {
			case EAntigenMethod.XPERT_XPRESS_SARS_COV_2:
				this.props.setLabModalPositiveAnalyte(ResourceAssistance.CONSTANT.XPERT_XPRESS_SARS_COV_2)
				this.props.setLabModalPositiveTestMethod(ResourceAssistance.CONSTANT.PCR)
				break

			case EAntigenMethod.SARS_COV_2_RNA_PCR:
				this.props.setLabModalPositiveAnalyte(ResourceAssistance.CONSTANT.SARS_COV_2_RNA_PCR)
				this.props.setLabModalPositiveTestMethod(ResourceAssistance.CONSTANT.PCR)
				break

			case EAntigenMethod.Chromatography:
				this.props.setLabModalPositiveAnalyte(ResourceAssistance.CONSTANT.COVID_19_ANTIGEN)
				this.props.setLabModalPositiveTestMethod(ResourceAssistance.CONSTANT.IMMUNO_CHROMATOGRAPHY_ASSAY)
				break

			case EAntigenMethod.Fluorescence:
				this.props.setLabModalPositiveAnalyte(ResourceAssistance.CONSTANT.COVID_19_ANTIGEN)
				this.props.setLabModalPositiveTestMethod(ResourceAssistance.CONSTANT.IMMUNO_FLUORESCENCE_ASSAY)
				break
			default:
				break
		}
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<CLModalPositiveXper onMethod={this.onMethod} />
				<CLModalPositiveSARS onMethod={this.onMethod} />
				<CLModalPositiveAntigen onMethod={this.onMethod} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLabModalPositiveAnalyte,
			setLabModalPositiveTestMethod,
			setLoadingAction,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalPositiveDetails)
