import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import IPDLeftActionRow from "./IPDLeftActionRow"
import IPDLeftClinicalRecord from "./IPDLeftClinicalRecord"
import IPDLeftClinicalRecordAction from "./IPDLeftClinicalRecordAction"
import IPDLeftDateRow from "./IPDLeftDateRow"
import IPDLeftPatientRow from "./IPDLeftPatientRow"
import IPDLeftSearchRow from "./IPDLeftSearchRow"

class IPDLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDLeft",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<IPDLeftDateRow {...this.props} />
				<IPDLeftSearchRow />
				<IPDLeftActionRow />
				<IPDLeftPatientRow />
				<IPDLeftClinicalRecordAction />
				<IPDLeftClinicalRecord />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IPDLeft)
