const PostAnesthesiaRecord = {
	"20%OfPreoperation": "20%OfPreoperation",
	"20%To40%OfPreoperation": "20%To40%OfPreoperation",
	"BP&Pulse": "BP&Pulse",
	"greaterThan40%OfPreoperation": "greaterThan40%OfPreoperation",
	aldreteScoreAndOrModifiedPADSS: "aldreteScoreAndOrModifiedPADSS",
	ambulateWithAssistance: "ambulateWithAssistance",
	complicationInPACU: "complicationInPACU",
	continuteAfterRepeatedTreatment: "continuteAfterRepeatedTreatment",
	controlledWithOralAnalgesicsAndAcceptableToPatient: "controlledWithOralAnalgesicsAndAcceptableToPatient",
	minimalBleeding: "minimalBleeding",
	minimalTreatedWithOralMedication: "minimalTreatedWithOralMedication",
	moderateBleeding: "moderateBleeding",
	moderateTreatedWithIVMedication: "moderateTreatedWithIVMedication",
	modifiedPADSSBeforeDischargeToHome: "modifiedPADSSBeforeDischargeToHome",
	painNotAcceptableToPatient: "painNotAcceptableToPatient",
	sensationLevelIsLowerThanT10Level: "sensationLevelIsLowerThanT10Level",
	severeBleeding: "severeBleeding",
	steadyGaitNoDizziness: "steadyGaitNoDizziness",
	unableToAmbulate: "unableToAmbulate",
}

export default PostAnesthesiaRecord
