import { combineReducers } from "redux"
import { LOGOUT, RESET } from "../type"
import accountingReducer from "./accounting"
import adminReducer from "./admin"
import commonReducer from "./commonReducer"
import contextMenuReducer from "./context-menu"
import hospitalReducers from "./hospital"
import hospitelReducers from "./hospitel"
import inventoryReducer from "./inventoryReducer"
import { languageReducer } from "./languageReducer"
import LoginReducer from "./loginReducer"
import menuReducer from "./menu"
import componentReducers from "./modal"
import modalReducer from "./modalReducer"
import purchaseOrderReducer from "./purchaseOrderReducer"
import purchaseRequestReducer from "./purchaseRequester"
import receiveReducer from "./receiveReducer"
import reportReducer from "./report"
import transferReducer from "./transferReducer"
import { ResourceAssistance } from "~/i18n"

const appReducer = combineReducers({
	accounting: accountingReducer,
	admin: adminReducer,
	common: commonReducer,
	component: componentReducers,
	contextMenu: contextMenuReducer,
	hospital: hospitalReducers,
	hospitel: hospitelReducers,
	inventory: inventoryReducer,
	language: languageReducer,
	login: LoginReducer,
	menu: menuReducer,
	modal: modalReducer,
	purchaseOrder: purchaseOrderReducer,
	purchaseRequest: purchaseRequestReducer,
	receive: receiveReducer,
	report: reportReducer,
	transfer: transferReducer,
})

const rootReducer = (state, action) => {
	switch (action.type) {
		case RESET:
			return appReducer(
				{
					login: {
						...state.login,
					},
					language: {
						...state.language,
					},
					common: {
						...state.common,
						isPageLoading: false,
						isContinueLoading: false,
						header: ResourceAssistance.CONSTANT.PMGCARE,
					},
				},
				action
			)
		case LOGOUT:
			return appReducer(undefined, action)

		default:
			return appReducer(state, action)
	}
}

export default rootReducer
