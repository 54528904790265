import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setLoadingAction,
	setReportInvByLocationBranches,
	setReportInvByLocationCode,
	setReportInvByLocationDescription,
	setReportInvByLocationOrgs,
	setReportInvByLocationSelectedBranch,
	setReportInvByLocationSelectedOrg,
} from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class ReportInvByLocationFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onCode = this.onCode.bind(this)
		this.onDescription = this.onDescription.bind(this)
	}

	componentDidMount() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.inv.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setReportInvByLocationOrgs(res.data.locations)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	componentWillUnmount() {
		this.props.setReportInvByLocationOrgs([])
		this.props.setReportInvByLocationBranches([])
		this.props.setReportInvByLocationSelectedOrg(-1, null)
		this.props.setReportInvByLocationSelectedBranch(-1, null)
		this.props.setReportInvByLocationCode("")
		this.props.setReportInvByLocationDescription("")
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.filter.selectedOrg.index !== this.props.filter.selectedOrg.index) {
			let branchHtml = document.getElementById(ResourceAssistance.ID.REPORT.inv.byLocation.branch)
			branchHtml.selectedIndex = 0
			this.props.setReportInvByLocationSelectedBranch(-1, null)

			if (this.props.filter.selectedOrg.org) {
				this.props.setReportInvByLocationBranches(this.props.filter.selectedOrg.org.locations)
			} else {
				this.props.setReportInvByLocationBranches([])
			}
		}
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setReportInvByLocationSelectedOrg(event.target.value, this.props.filter.orgs[event.target.value])
		} else {
			this.props.setReportInvByLocationSelectedOrg(-1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setReportInvByLocationSelectedBranch(event.target.value, this.props.filter.branches[event.target.value])
		} else {
			this.props.setReportInvByLocationSelectedBranch(-1, null)
		}
	}

	onCode(event) {
		this.props.setReportInvByLocationCode(event.target.value.trim())
	}

	onDescription(event) {
		this.props.setReportInvByLocationDescription(event.target.value.trim())
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.filter)}</legend>
				<Row>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.org)}</label>
					</Col>
					<Col>
						<FormControl required size={ResourceAssistance.FormControl.size.sm} as={ResourceAssistance.FormControl.as.select} onChange={this.onOrg}>
							{Utils.renderOptions(this.props.filter.orgs)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.branch)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.REPORT.inv.byLocation.branch}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onBranch}
						>
							{Utils.renderOptions(this.props.filter.branches)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.code)}</label>
					</Col>
					<Col>
						<FormControl
							size={ResourceAssistance.FormControl.size.sm}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.filter.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.description)}</label>
					</Col>
					<Col>
						<FormControl
							size={ResourceAssistance.FormControl.size.sm}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.filter.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.inv.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setReportInvByLocationBranches,
			setReportInvByLocationOrgs,
			setReportInvByLocationSelectedBranch,
			setReportInvByLocationSelectedOrg,
			setReportInvByLocationCode,
			setReportInvByLocationDescription,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportInvByLocationFilterDetails))
