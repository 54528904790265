import _ from "lodash"
import React from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { CgMoreO } from "react-icons/cg"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setOPDMedicalRecords, setOPDPatients, setSelected } from "~/redux/action"
import { SET_OPD_MEDICAL_RECORD_LOADING, SET_OPD_SELECTED_MEDICAL_RECORD } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientLeftMedicalRecord extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientLeftMedicalRecord",
		}
		this.selectRow = this.selectRow.bind(this)
		this.onLoadPreviousMedicalRecords = this.onLoadPreviousMedicalRecords.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedPatient, this.props.opd.selectedPatient)) {
			if (this.props.opd.selectedPatient.patient) {
				this.props.setOPDMedicalRecords(this.props.opd.selectedPatient.patient.admissions)
			} else {
				this.props.setOPDMedicalRecords([])
			}
		}

		if (!_.isEqual(prevProps.opd.medicalRecordTable.filtered, this.props.opd.medicalRecordTable.filtered)) {
			let idx = this.props.opd.selectedMedicalRecord.medicalRecord
				? this.props.opd.medicalRecordTable.filtered.findIndex(
						(medicalRecord) => medicalRecord.id === this.props.opd.selectedMedicalRecord.medicalRecord.id
				  )
				: -1
			if (idx !== -1) {
				this.props.setSelected(SET_OPD_SELECTED_MEDICAL_RECORD, idx, this.props.opd.medicalRecordTable.filtered[idx])
			} else {
				if (this.props.opd.medicalRecordTable.filtered.length > 0) {
					this.props.setSelected(SET_OPD_SELECTED_MEDICAL_RECORD, 0, this.props.opd.medicalRecordTable.filtered[0])
				} else {
					this.props.setSelected(SET_OPD_SELECTED_MEDICAL_RECORD, -1, null)
				}
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_OPD_SELECTED_MEDICAL_RECORD, index, this.props.opd.medicalRecordTable.filtered[index])
	}

	onLoadPreviousMedicalRecords() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.opd.location.id,
				pid: this.props.opd.selectedPatient.patient.id,
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				res.data.patients.forEach((patient) =>
					Object.assign(patient, {
						loadPreviousMedicalRecords: false,
					})
				)
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OPD_MEDICAL_RECORD_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OPD_MEDICAL_RECORD_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OPD_MEDICAL_RECORD_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.opd.medicalRecordTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.opd.selectedMedicalRecord.index === -1}
						highlightedRow={this.props.opd.selectedMedicalRecord.index}
						extra={
							this.props.opd.selectedPatient.patient &&
							this.props.opd.selectedPatient.patient.loadPreviousMedicalRecords && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									onClick={this.onLoadPreviousMedicalRecords}
								>
									<CgMoreO size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.loadPreviousMedicalRecords)}
								</Button>
							)
						}
					/>
					{this.props.opd.medicalRecordTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setOPDMedicalRecords,
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientLeftMedicalRecord)
