const Enum = {
	approved: "APPROVED",
	cancelled: "CANCELLED",
	completed: "COMPLETED",
	confirmed: "CONFIRMED",
	deposit: "DEPOSIT",
	dispensed: "DISPENSED",
	doctor: "DOCTOR",
	modified: "MODIFIED",
	nurse: "NURSE",
	other: "OTHER",
	pending: "PENDING",
	received: "RECEIVED",
	reschedule: "RESCHEDULE",
	skipped: "SKIPPED",
	verified: "VERIFIED",
	withdraw: "WITHDRAW",
	accountsPayable: 1,
	paymentPlan: 2,
	financePayment: 3,
	purchaseInvoice: 4,
	accountsReceive: 5,
	accountsReceivable: 6,
	postingGL: 7,
	accountsPayableDocumentCode: "AP",
	paymentPlanDocumentCode: "PS",
	financePaymentDocumentCode: "FO",
	purchaseInvoiceDocumentCode: "PI",
	accountsReceiveDocumentCode: "R",
	accountsReceivableDocumentCode: "AR",
	EWithholdingTaxSubLevels: [
		{ displayName: "401" },
		{ displayName: "402" },
		{ displayName: "403" },
		{ displayName: "404-a" },
		{ displayName: "404-b" },
		{ displayName: "404-b-1" },
		{ displayName: "404-b-1-1" },
		{ displayName: "404-b-1-2" },
		{ displayName: "404-b-1-3" },
		{
			displayName: "404-b-1-4",
			isOther: true,
		},
		{ displayName: "404-b-2" },
		{ displayName: "404-b-2-1" },
		{ displayName: "404-b-2-2" },
		{ displayName: "404-b-2-3" },
		{ displayName: "404-b-2-4" },
		{
			displayName: "404-b-2-5",
			isOther: true,
		},
		{
			displayName: "5",
			isOther: true,
		},
		{
			displayName: "6",
			isOther: true,
		},
	],
}

export default Enum
