export const SET_REPORT_PO_BY_LOCATION_START_DATETIME = "SET_REPORT_PO_BY_LOCATION_START_DATETIME"
export const SET_REPORT_PO_BY_LOCATION_END_DATETIME = "SET_REPORT_PO_BY_LOCATION_END_DATETIME"
export const SET_REPORT_PO_BY_LOCATION_ORGS = "SET_REPORT_PO_BY_LOCATION_ORGS"
export const SET_REPORT_PO_BY_LOCATION_BRANCHES = "SET_REPORT_PO_BY_LOCATION_BRANCHES"
export const SET_REPORT_PO_BY_LOCATION_SELECTED_ORG = "SET_REPORT_PO_BY_LOCATION_SELECTED_ORG"
export const SET_REPORT_PO_BY_LOCATION_SELECTED_BRANCH = "SET_REPORT_PO_BY_LOCATION_SELECTED_BRANCH"
export const SET_REPORT_PO_BY_LOCATION_REQUESTER_IND = "SET_REPORT_PO_BY_LOCATION_REQUESTER_IND"
export const SET_REPORT_PO_BY_LOCATION_INSPECTOR_IND = "SET_REPORT_PO_BY_LOCATION_INSPECTOR_IND"
export const SET_REPORT_PO_BY_LOCATION_APPROVAL_IND = "SET_REPORT_PO_BY_LOCATION_APPROVAL_IND"

export const SET_REPORT_PO_BY_ID_SEARCH_ID = "SET_REPORT_PO_BY_ID_SEARCH_ID"
