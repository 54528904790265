import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportCashierBillDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				services: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayTitle={false}
						displayBranch={true}
						displayFax={false}
						styles={{
							org: {
								fontSize: 24,
							},
						}}
					/>

					{/* Patient Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>PID: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.pid}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.patientName}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Address/{this.state.data.addressIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.patientAddress}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.separator]} />

					{/* Receipt Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Admission ID/{this.state.data.admissionIdIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.admissionId}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Admission Date/{this.state.data.admissionDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.admissionDate}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Discharge Date/{this.state.data.dischargeDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.dischargeDate}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.separator]} />

					{/* Service Header */}
					<View wrap={false}>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceDescription]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustments]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.adjustmentsIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceDescription]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>List</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustments]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Adjustments</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
					</View>

					{/* Services */}
					{this.state.data.services.map((service) => {
						return (
							<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.serviceDescription]}>
									<Text>{service.description}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.adjustments, { textAlign: "right" }]}>
									<Text>{service.adjustments}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.amount, { textAlign: "right" }]}>
									<Text>{service.amount}</Text>
								</View>
							</View>
						)
					})}

					{/* Grand Total */}
					<View style={[{ flex: 1 }]} wrap={false}>
						<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>
							<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Sub Total/{this.state.data.subTotalIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.subTotal}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Discount/{this.state.data.adjustmentIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.grandAdjustment}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Grand Total/{this.state.data.grandTotalIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.grandTotal}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />
					</View>

					{/* Signature */}
					<Signature numOfSignature={2} signatureTitles={this.state.data.signatureTitles} signatureNames={this.state.data.signatureNames} />
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 115,
	},
	infoHeader: {
		fontStyle: "bold",
		textAlign: "right",
		marginRight: 10,
	},
	itemRow: {
		fontSize: 16,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	serviceDescription: {
		minWidth: "74%",
		maxWidth: "74%",
	},
	adjustments: {
		minWidth: "13%",
		maxWidth: "13%",
	},
	amount: {
		minWidth: "13%",
		maxWidth: "13%",
	},
})

export default ReportCashierBillDocument
