import { ResourceAssistance } from "~/i18n"
import {
	SET_NOTIFCATION_PATIENTS,
	SET_NOTIFICATION_MODAL_NEW_MESSAGE,
	SET_NOTIFICATION_PATIENT,
	SET_NOTIFICATION_SELECTED_NOTIFICATION,
	SET_NOTIFICATION_NOTIFICATIONS,
	SET_NOTIFICATION_SELECTED_PATIENT,
} from "~/redux/type"

const init = {
	patient: null,
	selectedNotification: {
		index: -1,
		notification: null,
	},
	notificationTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.message,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
	},
	modalSearch: {
		selectedPatient: {
			index: -1,
			patient: null,
		},
		patientTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Hospitel.identification,
				ResourceAssistance.Message.firstName,
				ResourceAssistance.Message.lastName,
				ResourceAssistance.Hospitel.dateOfBirth,
			],
			body: [],
		},
	},
	modalNew: {
		message: "",
	},
}

const notificationReducer = (state = init, action) => {
	switch (action.type) {
		case SET_NOTIFCATION_PATIENTS:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					patientTable: {
						...state.modalSearch.patientTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_NOTIFICATION_SELECTED_PATIENT:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					selectedPatient: {
						index: action.payload.index,
						patient: action.payload.selected,
					},
				},
			})
		case SET_NOTIFICATION_PATIENT:
			return Object.assign({}, state, {
				patient: action.payload.value,
			})
		case SET_NOTIFICATION_MODAL_NEW_MESSAGE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					message: action.payload.value,
				},
			})
		case SET_NOTIFICATION_SELECTED_NOTIFICATION:
			return Object.assign({}, state, {
				selectedNotification: {
					index: action.payload.index,
					notification: action.payload.selected,
				},
			})
		case SET_NOTIFICATION_NOTIFICATIONS:
			return Object.assign({}, state, {
				notificationTable: {
					...state.notificationTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		default:
			return state
	}
}

export default notificationReducer
