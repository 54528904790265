import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_PHARMACY_ADMISSIONS,
	SET_PHARMACY_DISPENSED_ORDERS,
	SET_PHARMACY_DOCTOR_ORDERS,
	SET_PHARMACY_FILTER,
	SET_PHARMACY_LOCATION,
	SET_PHARMACY_MODAL_LOCATION_ORGS,
	SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING,
	SET_PHARMACY_MODAL_LOCATION_SELECTED_BRANCH,
	SET_PHARMACY_MODAL_LOCATION_SELECTED_DEPARTMENT,
	SET_PHARMACY_MODAL_LOCATION_SELECTED_ORG,
	SET_PHARMACY_MODAL_RETURN_DOCTOR_ORDER_IND,
	SET_PHARMACY_MODAL_RETURN_LOTS,
	SET_PHARMACY_MODAL_RETURN_SELECTED_LOT,
	SET_PHARMACY_MODAL_VERIFY_DAILY_IND,
	SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND,
	SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME,
	SET_PHARMACY_MODAL_VERIFY_LOTS,
	SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY,
	SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME,
	SET_PHARMACY_NURSE_ORDERS,
	SET_PHARMACY_PATIENTS,
	SET_PHARMACY_PATIENTS_LOADING,
	SET_PHARMACY_RETURN_ORDERS,
	SET_PHARMACY_SEARCH_END_DATETIME,
	SET_PHARMACY_SEARCH_IDENTIFICATION,
	SET_PHARMACY_SEARCH_PID,
	SET_PHARMACY_SEARCH_START_DATETIME,
	SET_PHARMACY_SELECTED_ADMISSION,
	SET_PHARMACY_SELECTED_DISPENSED_ORDER,
	SET_PHARMACY_SELECTED_DOCTOR_ORDER,
	SET_PHARMACY_SELECTED_PAITENT,
	SET_PHARMACY_SELECTED_SKIPPED_ORDER,
	SET_PHARMACY_SELECTED_VERIFIED_ORDER,
	SET_PHARMACY_SKIPPED_ORDERS,
	SET_PHARMACY_VERIFIED_ORDERS,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	searchPID: "",
	searchIdentification: "",
	filters: [],
	selectedPatient: {
		index: -1,
		patient: null,
	},
	selectedAdmission: {
		index: -1,
		admission: null,
	},
	selectedDoctorOrder: {
		index: -1,
		doctorOrder: null,
	},
	selectedVerifiedOrder: {
		index: -1,
		verifiedOrder: null,
	},
	selectedDispensedOrder: {
		index: -1,
		dispensedOrder: null,
	},
	selectedSkippedOrder: {
		index: -1,
		skippedOrder: null,
	},
	patientTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.seq,
			ResourceAssistance.Hospitel.patientId,
			ResourceAssistance.Message.firstName,
			ResourceAssistance.Message.lastName,
			ResourceAssistance.Hospitel.dateOfBirth,
			ResourceAssistance.Hospitel.identification,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	admissionTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.time, ResourceAssistance.Message.lastLocation],
		body: [],
		rowColor: [],
	},
	doctorOrderTable: {
		original: [],
		filtered: [],
		header: [
			"",
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Hospitel.doctorName,
			ResourceAssistance.Hospitel.healthCarePlan,
			ResourceAssistance.Message.status,
			"",
		],
		body: [],
		rowColor: [],
		popoverMsg: [],
	},
	nurseOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.note,
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.nurseName,
			ResourceAssistance.Hospitel.healthCarePlan,
			ResourceAssistance.Message.status,
			"",
		],
		body: [],
		rowColor: [],
	},
	verifiedOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.location,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	skippedOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.location,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	dispensedOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.location,
			ResourceAssistance.Message.by,
			"",
		],
		body: [],
		rowColor: [],
	},
	returnOrderTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.location,
			ResourceAssistance.Message.by,
		],
		body: [],
		rowColor: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
		selectedDepartment: {
			index: -1,
			department: null,
		},
	},
	modalVerify: {
		pharmacyInventory: null,
		startDateTime: "",
		endDateTime: "",
		isDaily: false,
		isDoctorOrder: false,
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.lotNum,
				ResourceAssistance.Message.currentLevel,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.expiredDate,
				"",
			],
			body: [],
			rowColor: [],
		},
	},
	modalReturn: {
		isDoctorOrder: false,
		selectedLot: {
			index: -1,
			lot: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.lotNum,
				ResourceAssistance.Message.dispensed,
				ResourceAssistance.Message.returned,
				ResourceAssistance.Message.returning,
				"",
			],
			body: [],
			rowColor: [],
		},
	},
}

const pharmacyReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PHARMACY_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_PHARMACY_MODAL_LOCATION_SELECTED_ORG: {
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		}
		case SET_PHARMACY_MODAL_LOCATION_SELECTED_BRANCH: {
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		}
		case SET_PHARMACY_MODAL_LOCATION_SELECTED_DEPARTMENT: {
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedDepartment: {
						index: action.payload.index,
						department: action.payload.selected,
					},
				},
			})
		}
		case SET_PHARMACY_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_PHARMACY_SEARCH_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_PHARMACY_SEARCH_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_PHARMACY_SEARCH_PID:
			return Object.assign({}, state, {
				searchPID: action.payload.value,
			})

		case SET_PHARMACY_SEARCH_IDENTIFICATION:
			return Object.assign({}, state, {
				searchIdentification: action.payload.value,
			})
		case SET_PHARMACY_PATIENTS:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_PATIENTS_LOADING:
			return Object.assign({}, state, {
				patientTable: {
					...state.patientTable,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_PHARMACY_SELECTED_PAITENT:
			return Object.assign({}, state, {
				selectedPatient: {
					index: action.payload.index,
					patient: action.payload.selected,
				},
			})
		case SET_PHARMACY_ADMISSIONS:
			return Object.assign({}, state, {
				admissionTable: {
					...state.admissionTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_SELECTED_ADMISSION:
			return Object.assign({}, state, {
				selectedAdmission: {
					index: action.payload.index,
					admission: action.payload.selected,
				},
			})
		case SET_PHARMACY_DOCTOR_ORDERS:
			return Object.assign({}, state, {
				doctorOrderTable: {
					...state.doctorOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					popoverMsg: action.payload.popoverMsg,
				},
			})
		case SET_PHARMACY_NURSE_ORDERS:
			return Object.assign({}, state, {
				nurseOrderTable: {
					...state.nurseOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_SELECTED_DOCTOR_ORDER:
			return Object.assign({}, state, {
				selectedDoctorOrder: {
					index: action.payload.index,
					doctorOrder: action.payload.selected,
				},
			})
		case SET_PHARMACY_VERIFIED_ORDERS:
			return Object.assign({}, state, {
				verifiedOrderTable: {
					...state.verifiedOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_SELECTED_VERIFIED_ORDER:
			return Object.assign({}, state, {
				selectedVerifiedOrder: {
					index: action.payload.index,
					verifiedOrder: action.payload.selected,
				},
			})
		case SET_PHARMACY_SKIPPED_ORDERS:
			return Object.assign({}, state, {
				skippedOrderTable: {
					...state.skippedOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_SELECTED_SKIPPED_ORDER:
			return Object.assign({}, state, {
				selectedSkippedOrder: {
					index: action.payload.index,
					skippedOrder: action.payload.selected,
				},
			})
		case SET_PHARMACY_DISPENSED_ORDERS:
			return Object.assign({}, state, {
				dispensedOrderTable: {
					...state.dispensedOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_SELECTED_DISPENSED_ORDER:
			return Object.assign({}, state, {
				selectedDispensedOrder: {
					index: action.payload.index,
					dispensedOrder: action.payload.selected,
				},
			})
		case SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY:
			return Object.assign({}, state, {
				modalVerify: {
					...state.modalVerify,
					pharmacyInventory: action.payload.value,
				},
			})
		case SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME:
			return Object.assign({}, state, {
				modalVerify: {
					...state.modalVerify,
					startDateTime: action.payload.value,
				},
			})
		case SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME:
			return Object.assign({}, state, {
				modalVerify: {
					...state.modalVerify,
					endDateTime: action.payload.value,
				},
			})
		case SET_PHARMACY_MODAL_VERIFY_LOTS:
			return Object.assign({}, state, {
				modalVerify: {
					...state.modalVerify,
					itemTable: {
						...state.modalVerify.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_PHARMACY_MODAL_VERIFY_DAILY_IND:
			return Object.assign({}, state, {
				modalVerify: {
					...state.modalVerify,
					isDaily: action.payload.value,
				},
			})
		case SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND:
			return Object.assign({}, state, {
				modalVerify: {
					...state.modalVerify,
					isDoctorOrder: action.payload.value,
				},
			})
		case SET_PHARMACY_RETURN_ORDERS:
			return Object.assign({}, state, {
				returnOrderTable: {
					...state.returnOrderTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PHARMACY_MODAL_RETURN_LOTS:
			return Object.assign({}, state, {
				modalReturn: {
					...state.modalReturn,
					itemTable: {
						...state.modalReturn.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_PHARMACY_MODAL_RETURN_SELECTED_LOT:
			return Object.assign({}, state, {
				modalReturn: {
					...state.modalReturn,
					selectedLot: {
						index: action.payload.index,
						lot: action.payload.selected,
					},
				},
			})
		case SET_PHARMACY_MODAL_RETURN_DOCTOR_ORDER_IND:
			return Object.assign({}, state, {
				modalReturn: {
					...state.modalReturn,
					isDoctorOrder: action.payload.value,
				},
			})
		case SET_PHARMACY_FILTER:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		default:
			return state
	}
}

export default pharmacyReducer
