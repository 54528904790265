import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { TbDiscount2 } from "react-icons/tb"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import {
	onModalDisplayAction,
	setDebtModalEditInvoiceBillingStatementGroups,
	setDebtModalEditInvoiceBillingStatements,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_DEBT_ADJUSTMENT_DISPLAY,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_DEBT_MODAL_ADJUSTMENT_ID,
	SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class DebtModalEditInvoiceBillingStatement extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onDicount = this.onDicount.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.debt.modalAdjustment.isContinue, this.props.debt.modalAdjustment.isContinue)) {
			if (
				this.props.debt.modalAdjustment.isContinue &&
				_.isEqual(
					this.props.debt.modalAdjustment.id,
					ResourceAssistance.ID.ACCOUNTING.debt.modalAdjustment.billingStatementAdjustment
				)
			) {
				this.saveBillingStatementAdjustments()
			}
		}

		if (!_.isEqual(prevProps.debt.modalEditInvoice.selectedGroup, this.props.debt.modalEditInvoice.selectedGroup)) {
			if (this.props.debt.modalEditInvoice.selectedGroup.group) {
				this.props.setDebtModalEditInvoiceBillingStatements(this.props.debt.modalEditInvoice.selectedGroup.group[1])
			} else {
				this.props.setDebtModalEditInvoiceBillingStatements([])
			}
			this.props.setSelected(SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS, -1, null)
		}

		if (
			!_.isEqual(
				prevProps.debt.modalEditInvoice.billingStatementTable.filtered,
				this.props.debt.modalEditInvoice.billingStatementTable.filtered
			)
		) {
			let index = this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement
				? this.props.debt.modalEditInvoice.billingStatementTable.filtered.findIndex(
						(bs) => bs.id === this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(
					SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS,
					index,
					this.props.debt.modalEditInvoice.billingStatementTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS, -1, null)
		this.props.setDebtModalEditInvoiceBillingStatements([])
	}

	saveBillingStatementAdjustments() {
		let discount = Utils.BigNumber(this.props.debt.modalAdjustment.discountPercent).isGreaterThan(0)
			? Utils.BigNumber(this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.charge)
					.times(this.props.debt.modalAdjustment.discountPercent)
					.dividedBy(100)
					.toFixed(2)
			: this.props.debt.modalAdjustment.discount
		if (
			!Utils.BigNumber(discount).isEqualTo(
				this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.adjustment
			) ||
			_.isEmpty(this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.accountantAdjustment) ||
			!Utils.BigNumber(discount).isEqualTo(
				this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.accountantAdjustment
			)
		) {
			let modifiedBS = Object.assign({}, this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement, {
				accountantAdjustment: discount,
				accountantAdjustmentDescription: Utils.trim(this.props.debt.modalAdjustment.remark),
				isModified:
					this.props.debt.selectedInvoice.invoice.billing.billingStatements.filter(
						(each) =>
							each.id === this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.id &&
							!Utils.BigNumber(each.accountantAdjustment).isEqualTo(discount)
					).length > 0,
			})
			let billingStatements = Array.from(this.props.debt.selectedInvoice.invoice.billing.billingStatements).filter(
				(each) => each.id !== this.props.debt.modalEditInvoice.selectedBillingStatement.billingStatement.id
			)
			billingStatements.push(modifiedBS)
			this.props.setDebtModalEditInvoiceBillingStatementGroups(billingStatements)
		}
	}

	onSelectRow(bs, index) {
		this.props.setSelected(
			SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS,
			index,
			this.props.debt.modalEditInvoice.billingStatementTable.filtered[index]
		)
	}

	onDicount(event, row, rIdx, cIdx) {
		let selectedBS = this.props.debt.modalEditInvoice.billingStatementTable.filtered[rIdx]
		this.props.setValue(
			SET_DEBT_MODAL_ADJUSTMENT_ID,
			ResourceAssistance.ID.ACCOUNTING.debt.modalAdjustment.billingStatementAdjustment
		)
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX, selectedBS.charge)
		this.props.setValue(
			SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT,
			_.isEmpty(selectedBS.accountantAdjustment)
				? Utils.BigNumber(selectedBS.adjustment).toFixed(2)
				: Utils.BigNumber(selectedBS.accountantAdjustment).toFixed(2)
		)
		this.props.onModalDisplayAction(SET_DEBT_ADJUSTMENT_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[<TbDiscount2 size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onDicount]}
						data={this.props.debt.modalEditInvoice.billingStatementTable}
						isClearHighlight={this.props.debt.modalEditInvoice.selectedBillingStatement.index === -1}
						highlightedRow={this.props.debt.modalEditInvoice.selectedBillingStatement.index}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtModalEditInvoiceBillingStatementGroups,
			setDebtModalEditInvoiceBillingStatements,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalEditInvoiceBillingStatement))
