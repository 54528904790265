import { Checkbox, Select, Space } from "antd"
import _ from "lodash"
import moment from "moment"
import React, { Fragment } from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND,
	SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME,
	SET_OPD_MODAL_ADJUSTMENT_NOTES,
	SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY,
	SET_OPD_MODAL_ADJUSTMENT_SELECTED_UNIT,
	SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME,
	SET_OPD_MODAL_ADJUSTMENT_STAT_CHECKED,
	SET_OPD_MODAL_ADJUSTMENT_UNITS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import GInput from "~/view/component/input/GInput"

class OPDModalAdjustmentDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCheckEndDate = this.onCheckEndDate.bind(this)
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onQty = this.onQty.bind(this)
		this.onNote = this.onNote.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onSTAT = this.onSTAT.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedNurseOrder.order) {
			let foundItem = this.props.opd.items.find((each) => each.displayName === this.props.opd.selectedNurseOrder.order.description)
			if (foundItem) {
				this.props.setValue(
					SET_OPD_MODAL_ADJUSTMENT_UNITS,
					foundItem.unitConversions.concat([
						{
							amount: 1,
							displayName: foundItem.unit.displayName,
							sellingPricePerUnit: foundItem.sellPricePerUnit,
							oneTime: true,
						},
					])
				)
			}
			this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME, this.props.opd.selectedNurseOrder.order.startDateTime)
			this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_STAT_CHECKED, this.props.opd.selectedNurseOrder.order.stat)
			this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY, this.props.opd.selectedNurseOrder.order.qtyPerDay)
			this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_NOTES, this.props.opd.selectedNurseOrder.order.notes)
			if (this.props.opd.selectedNurseOrder.order.endDateTime) {
				this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, false)
				this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME, this.props.opd.selectedNurseOrder.order.endDateTime)
			}
		} else {
			this.props.setValue(
				SET_OPD_MODAL_ADJUSTMENT_UNITS,
				this.props.opd.modalNurseOrder.selectedItem.item.unitConversions.concat([
					{
						amount: 1,
						displayName: this.props.opd.modalNurseOrder.selectedItem.item.unit.displayName,
						sellingPricePerUnit: this.props.opd.modalNurseOrder.selectedItem.item.sellPricePerUnit,
						oneTime: true,
					},
				])
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalAdjustment.units, this.props.opd.modalAdjustment.units)) {
			if (this.props.opd.selectedNurseOrder.order) {
				let foundIdx = this.props.opd.modalAdjustment.units.findIndex((each) => each.displayName === this.props.opd.selectedNurseOrder.order.unit)
				if (foundIdx > -1) {
					this.props.setSelected(SET_OPD_MODAL_ADJUSTMENT_SELECTED_UNIT, foundIdx, this.props.opd.modalAdjustment.units[foundIdx])
				}
			}
		}
		if (prevProps.opd.modalAdjustment.startDateTime !== this.props.opd.modalAdjustment.startDateTime) {
			this.props.setValue(
				SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME,
				Utils.generateDateFromLong(this.props.opd.modalAdjustment.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
			)
		}
		if (!_.isEqual(prevProps.opd.modalAdjustment.isSTAT, this.props.opd.modalAdjustment.isSTAT)) {
			if (this.props.opd.modalAdjustment.isSTAT) {
				this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME, moment().endOf("day").seconds(0).milliseconds(0).valueOf())
				this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, false)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, true)
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME, Utils.generateDate().getTime())
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY, "")
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_NOTES, "")
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_UNITS, [])
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_STAT_CHECKED, false)
		this.props.setSelected(SET_OPD_MODAL_ADJUSTMENT_SELECTED_UNIT, -1, null)
	}

	onCheckEndDate(event) {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND, !event.target.checked)
	}

	onStartDate(date) {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_START_DATE_TIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_END_DATE_TIME, date.getTime())
	}

	onSTAT(e) {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_STAT_CHECKED, e.target.checked)
	}

	onQty(event) {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_QTY_PER_DAY, Number(event.target.value))
	}

	onUnit(value) {
		value > -1
			? this.props.setSelected(SET_OPD_MODAL_ADJUSTMENT_SELECTED_UNIT, value, this.props.opd.modalAdjustment.units[value])
			: this.props.setSelected(SET_OPD_MODAL_ADJUSTMENT_SELECTED_UNIT, -1, null)
	}

	onNote(event) {
		this.props.setValue(SET_OPD_MODAL_ADJUSTMENT_NOTES, event.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0"} style={{ justifyContent: "center" }}>
					<Col md="auto">
						<DatePicker isShowTime={false} startDate={Utils.generateDateFromLong(this.props.opd.modalAdjustment.startDateTime)} onChange={this.onStartDate} />
					</Col>
					<Col>
						<label style={{ textAlign: "center" }}>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md="auto">
						<Space size={"small"}>
							<Checkbox style={{ flexDirection: "row" }} checked={!this.props.opd.modalAdjustment.isDisableEndDate} onChange={this.onCheckEndDate}></Checkbox>
							<DatePicker
								isShowTime={false}
								minDate={Utils.generateDateFromLong(this.props.opd.modalAdjustment.startDateTime)}
								showDisabledMonthNavigation
								disabled={this.props.opd.modalAdjustment.isDisableEndDate || this.props.opd.modalAdjustment.isSTAT}
								startDate={Utils.generateDateFromLong(this.props.opd.modalAdjustment.endDateTime)}
								onChange={this.onEndDate}
							/>
						</Space>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col>
						<Space size={"large"}>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.opd.modalAdjustment.isSTAT} onChange={this.onSTAT}>
								{ResourceAssistance.CONSTANT.STAT}
							</Checkbox>
						</Space>
					</Col>
				</Row>
				<Row className={"g-0"}>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.number}
							placeholder={translate(ResourceAssistance.Message.amount)}
							step={1}
							min={1}
							value={this.props.opd.modalAdjustment.qtyPerDay}
							onChange={this.onQty}
							addonAfter={
								<Fragment>
									<Select
										showSearch
										style={{ textAlign: "center", width: "100px", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
										value={this.props.opd.modalAdjustment.selectedUnit.unit ? this.props.opd.modalAdjustment.selectedUnit.unit.displayName : undefined}
										onChange={this.onUnit}
									>
										{Utils.renderSelects(this.props.opd.modalAdjustment.units, false)}
									</Select>
									<span style={{ verticalAlign: "middle" }}>
										<IconFont type={"icon-ali-pmgcare-chuhao"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
									</span>
									<span>{translate(ResourceAssistance.Message.day)}</span>
								</Fragment>
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.note}>
							{(placeholder) => (
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									placeholder={placeholder}
									value={this.props.opd.modalAdjustment.notes}
									onChange={this.onNote}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalAdjustmentDetails)
