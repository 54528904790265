import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_USER_DISPLAY_ALL_IND,
	SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_CONFIRM_PASSWORD,
	SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_PASSWORD,
	SET_ADMIN_USER_MODAL_NEW_USER_ACTIVE,
	SET_ADMIN_USER_MODAL_NEW_USER_CONFIRM_PASSWORD,
	SET_ADMIN_USER_MODAL_NEW_USER_EMAIL,
	SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME,
	SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME,
	SET_ADMIN_USER_MODAL_NEW_USER_LOGIN_NAME,
	SET_ADMIN_USER_MODAL_NEW_USER_NICKNAME,
	SET_ADMIN_USER_MODAL_NEW_USER_PASSWORD,
	SET_ADMIN_USER_MODAL_NEW_USER_PENDING_ROLES,
	SET_ADMIN_USER_MODAL_NEW_USER_ROLES,
	SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_PENDING_ROLE,
	SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE,
	SET_ADMIN_USER_ROLES,
	SET_ADMIN_USER_SELECTED_USER,
	SET_ADMIN_USER_USERS,
} from "../../type/type/ADMIN"

export const setAdmUserUsers = (users) => {
	return (dispatch, getState) => {
		let filtered = users
			.filter((each) => {
				if (getState().admin.userConfig.user.displayInactive) {
					return (
						each.firstName.toLowerCase().includes(getState().admin.userConfig.user.searchFirstName.toLowerCase()) &&
						each.lastName.toLowerCase().includes(getState().admin.userConfig.user.searchLastName.toLowerCase())
					)
				} else {
					return (
						each.active &&
						each.firstName.toLowerCase().includes(getState().admin.userConfig.user.searchFirstName.toLowerCase()) &&
						each.lastName.toLowerCase().includes(getState().admin.userConfig.user.searchLastName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.username, b.username))

		let colors = []
		if (getState().admin.userConfig.user.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [
				each.signature ? true : false,
				each.firstName,
				each.lastName,
				each.firstNameEng,
				each.lastNameEng,
				each.email,
				each.phone,
				each.nickname,
				each.username,
				each.role ? each.role.displayName : "",
				each.licenseNum,
				each.createdBy.displayName,
				Utils.formatDate(each.creationDateTime),
			]
		})
		dispatch({
			type: SET_ADMIN_USER_USERS,
			payload: {
				original: users,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmUserDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_USER_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmUserSelectedUser = (index, user) => {
	return {
		type: SET_ADMIN_USER_SELECTED_USER,
		payload: {
			index: index,
			user: user,
		},
	}
}

export const setAdmUserModalNULastName = (lastName) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME,
		payload: {
			lastName: lastName,
		},
	}
}

export const setAdmUserModalNUFirstName = (firstName) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME,
		payload: {
			firstName: firstName,
		},
	}
}

export const setAdmUserModalNUEmail = (email) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_EMAIL,
		payload: {
			email: email,
		},
	}
}

export const setAdmUserModalNUNickname = (nickname) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_NICKNAME,
		payload: {
			nickname: nickname,
		},
	}
}

export const setAdmUserModalNULoginName = (loginName) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_LOGIN_NAME,
		payload: {
			loginName: loginName,
		},
	}
}

export const setAdmUserModalNUPassword = (password) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_PASSWORD,
		payload: {
			password: password,
		},
	}
}

export const setAdmUserModalNUConfirmPassword = (confirmPassword) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_CONFIRM_PASSWORD,
		payload: {
			confirmPassword: confirmPassword,
		},
	}
}

export const setAdmUserModalNUActive = (isActive) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}

export const setAdmUserModalNURoles = (roles) => {
	return (dispatch, getState) => {
		let filtered = roles.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})

		dispatch({
			type: SET_ADMIN_USER_MODAL_NEW_USER_ROLES,
			payload: {
				original: roles,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmUserModalNUSelectedRole = (index, role) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE,
		payload: {
			index: index,
			role: role,
		},
	}
}

export const setAdmUserModalNUPendingRoles = (roles) => {
	return (dispatch, getState) => {
		let filtered = roles.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((each) => [each.name, each.description])

		dispatch({
			type: SET_ADMIN_USER_MODAL_NEW_USER_PENDING_ROLES,
			payload: {
				original: roles,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmUserModalNUSelectedPendingRole = (index, role) => {
	return {
		type: SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_PENDING_ROLE,
		payload: {
			index: index,
			role: role,
		},
	}
}

export const setAdmUserModalCPWPassword = (password) => {
	return {
		type: SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_PASSWORD,
		payload: {
			password: password,
		},
	}
}

export const setAdmUserModalCPWConfirmPassword = (password) => {
	return {
		type: SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_CONFIRM_PASSWORD,
		payload: {
			confirmPassword: password,
		},
	}
}

export const setAdmUserRoles = (roles) => {
	return (dispatch, getState) => {
		let filtered = roles.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((each) => [each.name, each.description])

		dispatch({
			type: SET_ADMIN_USER_ROLES,
			payload: {
				original: roles,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
