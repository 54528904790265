import React from "react"
import { Col, Container, Row } from "react-bootstrap"
// import "./File.css"

class PdfFile extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
			// numPages: null,
		}

		// this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	// onDocumentLoadSuccess({ numPages }) {
	// 	this.setState({
	// 		numPages: numPages,
	// 	})
	// }

	render() {
		return (
			// <div className={"full-flex all-page-container"}>
			// 	<Document
			// 		className={"full-size"}
			// 		file={this.state.data.file}
			// 		options={{ workerSrc: "/pdf.worker.js" }}
			// 		onLoadSuccess={this.onDocumentLoadSuccess}
			// 	>
			<Container fluid className={"full-size full-flex"} style={{ padding: "0 0" }}>
				<Row className={"g-0 full-size"}>
					<Col>
						<iframe id="iframe" title="iframe" className={"full-size"} src={this.state.data.file} />
					</Col>
				</Row>
			</Container>

			// 	{Array.from(new Array(this.state.numPages), (el, index) => (
			// 	<Page key={`page_${index + 1}`} pageNumber={index + 1} />
			// 	))}
			// 	</Document>
			// </div>
		)
	}
}

export default PdfFile
