import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setAdmUserModalNUPendingRoles, setAdmUserModalNUSelectedPendingRole, setAdmUserModalNUSelectedRole } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AdmUserModalNURole extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onSelectPendingRow = this.onSelectPendingRow.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentDidMount() {
		if (this.props.user.selectedUser.index !== -1) {
			this.props.setAdmUserModalNUPendingRoles(this.props.user.selectedUser.user.roles)
		}
	}

	componentWillUnmount() {
		this.props.setAdmUserModalNUSelectedRole(-1, null)
		this.props.setAdmUserModalNUSelectedPendingRole(-1, null)
		this.props.setAdmUserModalNUPendingRoles([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.user.modalNewUser.pendingRoleTable.original, this.props.user.modalNewUser.pendingRoleTable.original)) {
			this.props.setAdmUserModalNUSelectedPendingRole(-1, null)
		}
	}

	onSelectRow(row, index) {
		this.props.setAdmUserModalNUSelectedRole(index, this.props.user.modalNewUser.roleTable.filtered[index])
	}

	onSelectPendingRow(row, index) {
		this.props.setAdmUserModalNUSelectedPendingRole(index, this.props.user.modalNewUser.pendingRoleTable.filtered[index])
	}

	onDownBtn() {
		if (this.props.user.modalNewUser.selectedRole.index === -1) {
			return
		}

		let selectedRole = this.props.user.modalNewUser.selectedRole.role

		let pendingRoles = this.props.user.modalNewUser.pendingRoleTable.filtered.filter((each) => each.id !== this.props.user.modalNewUser.selectedRole.role.id)

		pendingRoles.push(selectedRole)

		this.props.setAdmUserModalNUPendingRoles(pendingRoles)
	}

	onUpBtn() {
		if (this.props.user.modalNewUser.selectedPendingRole.index === -1) {
			return
		}

		let pendingRoles = this.props.user.modalNewUser.pendingRoleTable.filtered.filter(
			(each) => each.id !== this.props.user.modalNewUser.selectedPendingRole.role.id
		)
		this.props.setAdmUserModalNUPendingRoles(pendingRoles)
	}

	render() {
		return (
			<Container fluid="small" className="full-flex ">
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							data={this.props.user.modalNewUser.roleTable}
							isClearHighlight={this.props.user.modalNewUser.selectedRole.index === -1}
							highlightedRow={this.props.user.modalNewUser.selectedRole.index}
							onClick={this.onSelectRow}
						/>
					</Col>
				</Row>
				<Row>
					<Col className={this.props.user.modalNewUser.selectedRole.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
						<FcDownload size={"3rem"} onClick={this.onDownBtn} style={{ alignSelf: "center" }} />
					</Col>
					<Col className={this.props.user.modalNewUser.selectedPendingRole.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
						<FcUpload size={"3rem"} onClick={this.onUpBtn} style={{ alignSelf: "center" }} />
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							data={this.props.user.modalNewUser.pendingRoleTable}
							isClearHighlight={this.props.user.modalNewUser.selectedPendingRole.index === -1}
							highlightedRow={this.props.user.modalNewUser.selectedPendingRole.index}
							onClick={this.onSelectPendingRow}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.admin.userConfig.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setAdmUserModalNUSelectedRole, setAdmUserModalNUSelectedPendingRole, setAdmUserModalNUPendingRoles }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUserModalNURole)
