import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setRegisterAdmissions, setSelected } from "~/redux/action"
import { SET_REGISTER_SELECTED_ADMISSION } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class IPDLeftClinicalRecord extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDLeftClinicalRecord",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ipd.selectedPatient.patient, this.props.ipd.selectedPatient.patient)) {
			if (this.props.ipd.selectedPatient.patient) {
				this.props.setRegisterAdmissions(this.props.ipd.selectedPatient.patient.admissions)
			} else {
				this.props.setRegisterAdmissions([])
			}
		}

		if (!_.isEqual(prevProps.ipd.admissionTable.filtered, this.props.ipd.admissionTable.filtered)) {
			if (this.props.ipd.selectedAdmission.admission) {
				let idx = this.props.ipd.admissionTable.filtered.findIndex(
					(admission) => admission.id === this.props.ipd.selectedAdmission.admission.id
				)
				if (idx > -1) {
					this.props.setSelected(SET_REGISTER_SELECTED_ADMISSION, idx, this.props.ipd.admissionTable.filtered[idx])
				} else {
					this.props.setSelected(SET_REGISTER_SELECTED_ADMISSION, -1, null)
				}
			}
		}
	}

	selectRow(admission, index) {
		this.props.setSelected(SET_REGISTER_SELECTED_ADMISSION, index, this.props.ipd.admissionTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.ipd.admissionTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.ipd.selectedAdmission.index === -1}
						highlightedRow={this.props.ipd.selectedAdmission.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setRegisterAdmissions,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDLeftClinicalRecord)
