import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_PGL_ENTRIES, SET_PGL_MODAL_NEW_ENTRIES, SET_PGL_REQUESTS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setPostingGLs = (postingGLs) => {
	return (dispatch, getState) => {
		let filtered = postingGLs
			.filter(
				(pgl) =>
					(_.isEmpty(getState().accounting.postingGL.filters) ||
						_.includes(getState().accounting.postingGL.filters, pgl.code) ||
						(_.includes(getState().accounting.postingGL.filters, ResourceAssistance.Message.incomplete) && !pgl.complete)) &&
					pgl.id.includes(getState().accounting.postingGL.filterPostingGLId)
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((pgl) => {
			return [Utils.formatDate(pgl.creationDateTime), pgl.code + "-" + pgl.id]
		})

		let rowColor = filtered.map((pgl) => {
			let color = ""
			if (pgl.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		dispatch({
			type: SET_PGL_REQUESTS,
			payload: {
				original: postingGLs,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPGLEntries = (entries) => {
	return (dispatch, getState) => {
		let filtered = Array.from(entries).sort((a, b) => Utils.sort(b.debit, a.debit))

		let body = filtered.map((entry) => {
			return [
				entry.accountingCode,
				entry.accountingChartName,
				entry.accountingTransDescr,
				entry.debit ? Utils.formatNumWithComma(Utils.BigNumber(entry.amount).toFixed(2)) : Utils.BigNumber(0).toFixed(2),
				!entry.debit ? Utils.formatNumWithComma(Utils.BigNumber(entry.amount).toFixed(2)) : Utils.BigNumber(0).toFixed(2),
				[true, true],
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PGL_ENTRIES,
			payload: {
				original: entries,
				filtered: filtered,
				body: body,
				rowColor: [],
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPGLModalNewEntries = (entries) => {
	return (dispatch, getState) => {
		let filtered = Array.from(entries).sort((a, b) => Utils.sort(b.debit, a.debit))

		let body = filtered.map((entry) => {
			return [
				entry.accountingCode,
				entry.accountingChartName,
				entry.accountingTransDescr,
				entry.debit ? Utils.formatNumWithComma(Utils.BigNumber(entry.amount).toFixed(2)) : Utils.BigNumber(0).toFixed(2),
				!entry.debit ? Utils.formatNumWithComma(Utils.BigNumber(entry.amount).toFixed(2)) : Utils.BigNumber(0).toFixed(2),
				[true],
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PGL_MODAL_NEW_ENTRIES,
			payload: {
				original: entries,
				filtered: filtered,
				body: body,
				rowColor: [],
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
