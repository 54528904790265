import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_PI_INVOICES, SET_PI_MODAL_NEW_PURCHASES, SET_PI_PURCHASES } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setPurchaseInvoices = (invoices) => {
	return (dispatch, getState) => {
		// if (_.includes(getState().accounting.paymentSlip.filters, ResourceAssistance.Message.incomplete)) {
		// 	filtered = filtered.filter((request) => !request.complete)
		// }
		let filtered = invoices
			.filter((request) => request.id.includes(getState().accounting.purchaseInvoice.filterPurchaseInvoiceId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((invoice) => {
			return [Utils.formatDate(invoice.creationDateTime), invoice.id]
		})

		let rowColor = filtered.map((invoice) => {
			let color = ""
			if (invoice.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		dispatch({
			type: SET_PI_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPIPurchases = (purchases) => {
	return (dispatch, getState) => {
		let filtered = Array.from(purchases).sort((a, b) => Utils.sort(a.accountingCode, b.accountingCode))
		let linkedObj = {}
		let body = filtered.map((purchase) => {
			let color = ""
			let foundAry = Utils.findChartOfAccountsFromGroup(getState().accounting.purchaseInvoice.chartOfAccounts, purchase.accountingCode)
			if (_.isEmpty(getState().accounting.purchaseInvoice.selectedPI.PI.purchaseInvoiceGLs)) {
				if (!_.isEmpty(foundAry) && !_.isEmpty(foundAry[1].linkedAccountingCharts)) {
					if (!_.isEmpty(purchase.linkedAccountingCode)) {
						if (linkedObj.hasOwnProperty(purchase.linkedAccountingCode)) {
							Object.assign(linkedObj, {
								[purchase.linkedAccountingCode]: {
									...linkedObj[purchase.linkedAccountingCode],
									taxedTotalPrice: Utils.BigNumber(linkedObj[purchase.linkedAccountingCode].taxedTotalPrice).plus(purchase.taxedTotalPrice).toFixed(2),
								},
							})
						} else {
							Object.assign(linkedObj, {
								[purchase.linkedAccountingCode]: {
									accountingCode: purchase.linkedAccountingCode,
									accountingChartName: purchase.linkedAccountingChartName,
									accountingTransDescr: purchase.linkedAccountingTransDescr,
									taxedTotalPrice: purchase.taxedTotalPrice,
									hasMultipleLinks: foundAry[1].linkedAccountingCharts.length > 1,
									links: foundAry[1].linkedAccountingCharts.map((each) => each.accountingChart),
								},
							})
						}
					} else {
						let linkedAry = Utils.findChartOfAccountsFromGroup(
							getState().accounting.purchaseInvoice.chartOfAccounts,
							foundAry[1].linkedAccountingCharts[0].accountingChart.fullCode
						)
						if (!_.isEmpty(linkedAry)) {
							if (linkedObj.hasOwnProperty(linkedAry[1].fullCode)) {
								Object.assign(linkedObj, {
									[linkedAry[1].fullCode]: {
										...linkedObj[linkedAry[1].fullCode],
										taxedTotalPrice: Utils.BigNumber(linkedObj[linkedAry[1].fullCode].taxedTotalPrice).plus(purchase.taxedTotalPrice).toFixed(2),
									},
								})
							} else {
								Object.assign(linkedObj, {
									[linkedAry[1].fullCode]: {
										accountingCode: linkedAry[1].fullCode,
										accountingChartName: linkedAry[1].displayName,
										accountingTransDescr: foundAry[1].linkedAccountingCharts[0].transDescr,
										taxedTotalPrice: purchase.taxedTotalPrice,
										hasMultipleLinks: foundAry[1].linkedAccountingCharts.length > 1,
										links: foundAry[1].linkedAccountingCharts.map((each) => each.accountingChart),
									},
								})
							}
						} else {
							color = ResourceAssistance.CSS.Color.warning
						}
					}
				} else {
					color = ResourceAssistance.CSS.Color.warning
				}
			} else {
				getState()
					.accounting.purchaseInvoice.selectedPI.PI.purchaseInvoiceGLs.filter((each) => each.systemGenerated)
					.forEach((GL) => {
						Object.assign(linkedObj, {
							[GL.code]: {
								accountingCode: GL.code,
								accountingChartName: GL.name,
								accountingTransDescr: GL.transactionDescription,
								taxedTotalPrice: GL.credit,
							},
						})
					})
			}

			return {
				color: color,
				values: [
					purchase.accountingCode,
					purchase.accountingChartName,
					purchase.accountingTransDescr,
					purchase.withholdingTaxCode + "-" + purchase.withholdingTaxName,
					purchase.withholdingTax,
					purchase.withholdingTaxSubLevel,
					purchase.withholdingTaxSubLevelOther,
					purchase.withholdingTaxAccountingCode,
					purchase.withholdingTaxAccountingChartName,
					purchase.tax,
					Utils.formatNumWithComma(Utils.BigNumber(purchase.taxedTotalPrice).toFixed(2)),
					"",
					[true, !_.isEmpty(foundAry) && foundAry[1].linkedAccountingCharts.length > 1, true],
				],
			}
		})
		Object.values(linkedObj).forEach((linkedPI) => {
			filtered.push({ ...linkedPI })
			body.push({
				color: ResourceAssistance.CSS.Color.green,
				values: [
					linkedPI.accountingCode,
					linkedPI.accountingChartName,
					Utils.decodeAccountingTransDescr(
						linkedPI.accountingTransDescr,
						getState().accounting.purchaseInvoice.selectedPI.PI.supplierName,
						Utils.formatDate(getState().accounting.purchaseInvoice.selectedPI.PI.creationDateTime)
					),
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					"",
					Utils.formatNumWithComma(Utils.BigNumber(linkedPI.taxedTotalPrice).toFixed(2)),
					[false, false, false],
				],
			})
		})

		let rowColor = body.map((each) => {
			return [!_.isEmpty(each.color), each.color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PI_PURCHASES,
			payload: {
				original: purchases,
				filtered: filtered,
				body: body.map((each) => each.values),
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPIModalNewPurchases = (purchases) => {
	return (dispatch, getState) => {
		let filtered = Array.from(purchases)

		let body = filtered.map((purchase) => {
			return [
				purchase.accountingCode,
				purchase.accountingChartName,
				purchase.accountingTransDescr,
				purchase.withholdingTaxCode + "-" + purchase.withholdingTaxName,
				purchase.withholdingTax,
				purchase.withholdingTaxSubLevel,
				purchase.withholdingTaxSubLevelOther,
				purchase.withholdingTaxAccountingCode,
				purchase.withholdingTaxAccountingChartName,
				purchase.tax,
				Utils.formatNumWithComma(Utils.BigNumber(purchase.taxedTotalPrice).toFixed(2)),
				[true],
			]
		})

		let rowColor = filtered.map((request) => {
			let color = ""
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PI_MODAL_NEW_PURCHASES,
			payload: {
				original: purchases,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
