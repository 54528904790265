import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportDoctorStandingOrderDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "Doctor Order",
				orders: [],
				soaps: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Date: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.date}</Text>
										</View>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>ID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.id}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Name: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Age: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>DOB: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* SOAP Header*/}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.soapTime]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.timeIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Subjective</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Objective</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Assessment</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Plan</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* SOAP */}
						{this.state.data.soaps.map((soap) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.soapTime]}>
											<Text>{soap.time}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
											<Text>{soap.subjective}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
											<Text>{soap.objective}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
											<Text>{soap.assessment}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
											<Text>{soap.plan}</Text>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.horizontalLine} />
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Item Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.order]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Order</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.scheduled]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Scheduled</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Items */}
						{this.state.data.orders.map((order) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
										<View style={[ResourceAssistance.Report.styles.col, styles.order]}>
											<Text>{order.order}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.scheduled]}>
											<Text>{order.scheduled}</Text>
										</View>
									</View>

									{!_.isEmpty(order.cancelledBy) && (
										// Cancelled Header
										<Fragment>
											<View wrap={false}>
												<View style={ResourceAssistance.Report.styles.horizontalSubline} />
												<View style={[ResourceAssistance.Report.styles.row, { fontSize: 14 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter]} />
													<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
														<Text style={[ResourceAssistance.Report.styles.textBold]}>Cancelled Date</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
														<Text style={ResourceAssistance.Report.styles.textBold}>Cancelled By</Text>
													</View>
												</View>
											</View>
											<View style={[ResourceAssistance.Report.styles.row, { fontSize: 14 }]}>
												<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
													<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.arrowIcon}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
													<Text>{order.cancelledDate}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
													<Text>{order.cancelledBy}</Text>
												</View>
											</View>
										</Fragment>
									)}
									<View style={[ResourceAssistance.Report.styles.horizontalLine, { marginTop: 5 }]} />
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Signature */}
						<Signature
							signatureDate={this.state.data.signatureDate}
							numOfSignature={2}
							signatureTitles={this.state.data.signatureTitles}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	soapTime: {
		minWidth: "11%",
		maxWidth: "11%",
	},
	order: {
		minWidth: "85%",
		maxWidth: "85%",
	},
	scheduled: {
		minWidth: "15%",
		maxWidth: "15%",
	},
})

export default ReportDoctorStandingOrderDocument
