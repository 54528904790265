import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_OR_EDIT_REQUEST_DISPLAY, SET_OR_SCHEDULES, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OperatingRoomModalEditRequestDetails from "./OperatingRoomModalEditRequestDetails"
import { axios } from "~/axios"

class OperatingRoomModalEditRequest extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_EDIT_REQUEST_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.operatingRoom.selectedEvent.event ||
			!this.props.operatingRoom.modalEditRequest.selectedORCase.case ||
			!this.props.operatingRoom.modalEditRequest.selectedOperativeDoctor.doctor ||
			!this.props.operatingRoom.modalEditRequest.selectedOperativeICD.icd ||
			!this.props.operatingRoom.modalEditRequest.selectedAnesthesiologist.anesthesiologist ||
			!this.props.operatingRoom.modalEditRequest.selectedAnesthesiaType.type
		) {
			return true
		}
		let target = Object.assign({}, this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request, {
			operatingRoomCase: this.props.operatingRoom.modalEditRequest.selectedORCase.case.displayName,
			operativeDoctor: this.props.operatingRoom.modalEditRequest.selectedOperativeDoctor.doctor.displayName,
			preDiagICD: this.props.operatingRoom.modalEditRequest.selectedPreDiagICD.icd.description,
			preOperativeDiag: Utils.trim(this.props.operatingRoom.modalEditRequest.preOperativeDiag),
			operativeICD: this.props.operatingRoom.modalEditRequest.selectedOperativeICD.icd.description,
			operationProcedure: Utils.trim(this.props.operatingRoom.modalEditRequest.operationProcedure),
			anesthesiologist: this.props.operatingRoom.modalEditRequest.selectedAnesthesiologist.anesthesiologist.displayName,
			anesthesiaType: this.props.operatingRoom.modalEditRequest.selectedAnesthesiaType.type.displayName,
		})
		return _.isEqual(this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.updateOperatingRoomRequests,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.id,
				departmentId: this.props.operatingRoom.selectedOperatingRoom.operatingRoom.id,
				startDateTime: this.props.operatingRoom.calendarStartDateTime,
				endDateTime: this.props.operatingRoom.calendarEndDateTime,
				userId: this.props.login.user.id,
				operatingRoomRequests: [
					{
						id: this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.id,
						operatingRoomCase: this.props.operatingRoom.modalEditRequest.selectedORCase.case.displayName,
						operativeDoctor: this.props.operatingRoom.modalEditRequest.selectedOperativeDoctor.doctor.displayName,
						preDiagICD: this.props.operatingRoom.modalEditRequest.selectedPreDiagICD.icd.description,
						preOperativeDiag: Utils.trim(this.props.operatingRoom.modalEditRequest.preOperativeDiag),
						operativeICD: this.props.operatingRoom.modalEditRequest.selectedOperativeICD.icd.description,
						operationProcedure: Utils.trim(this.props.operatingRoom.modalEditRequest.operationProcedure),
						anesthesiologist: this.props.operatingRoom.modalEditRequest.selectedAnesthesiologist.anesthesiologist.displayName,
						anesthesiaType: this.props.operatingRoom.modalEditRequest.selectedAnesthesiaType.type.displayName,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_SCHEDULES, res.data.schedules)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullWidth}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.edit)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<OperatingRoomModalEditRequestDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomEditRequestDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditRequest))
