import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setDebtPlans, setSelected } from "~/redux/action"
import { SET_DEBT_SELECTED_PLAN } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class DebtLeftPlan extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "DebtLeftPlan",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.debt.planTable.filtered, this.props.debt.planTable.filtered)) {
			let index = this.props.debt.selectedPlan.plan
				? this.props.debt.planTable.filtered.findIndex((plan) => plan.id === this.props.debt.selectedPlan.plan.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_DEBT_SELECTED_PLAN, index, this.props.debt.planTable.filtered[index])
			} else {
				this.props.setSelected(SET_DEBT_SELECTED_PLAN, -1, null)
			}
		}
		if (
			!_.isEqual(prevProps.debt.filterDebtPlanId, this.props.debt.filterDebtPlanId) ||
			!_.isEqual(prevProps.debt.filterInsuranceCompany, this.props.debt.filterInsuranceCompany)
		) {
			this.props.setDebtPlans(this.props.debt.planTable.original)
		}
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_DEBT_SELECTED_PLAN, index, this.props.debt.planTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.debt.planTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.debt.selectedPlan.index === -1}
						highlightedRow={this.props.debt.selectedPlan.index}
					/>
					{this.props.debt.planTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setDebtPlans,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtLeftPlan))
