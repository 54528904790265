import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setTfModalTransferOrgs, setTfSelectedTransfer, setTfSelectedTransferItem } from "~/redux/action"
import { SET_PAGE_LOADING, SET_TF_NEW_TRANSFER_DISPLAY } from "~/redux/type"

class TFRightTransferActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
	}

	isNewBtnDisabled() {
		let itemSize = this.props.transfer.selectedRequest.request
			? this.props.transfer.selectedRequest.request.items.filter((each) => !each.cancelled && each.amount !== each.transferredAmount).length
			: 0
		return (
			this.props.transfer.selectedRequest.index === -1 ||
			!this.props.transfer.selectedRequest.request.requesterApproved ||
			this.props.transfer.selectedRequest.request.approvalApproved ||
			itemSize === 0
		)
	}

	onNew() {
		this.props.setTfSelectedTransfer(-1, null)
		this.props.setTfSelectedTransferItem(-1, null)
		this.props.loadOrgs(
			ResourceAssistance.Url.transfer.getOrgs,
			(res) => {
				this.props.setTfModalTransferOrgs(res.data.locations)
				this.props.onModalDisplayAction(SET_TF_NEW_TRANSFER_DISPLAY, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			{ orgId: this.props.transfer.selectedRequest.request.org.id }
		)
	}

	render() {
		return (
			<Row className="flex-self-center">
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isNewBtnDisabled()}
						onClick={this.onNew}
					>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.newTransfer)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setTfModalTransferOrgs,
			setTfSelectedTransfer,
			setTfSelectedTransferItem,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFRightTransferActionRow)
