import { TreeSelect } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormCheck, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_REGISTER_MODAL_CLINICAL_RECORD_AUTHEN_NUMBER,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_CODE,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_DES,
	SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_NAME,
	SET_REGISTER_MODAL_CLINICAL_RECORD_IS_PRIMARY_HOSPITAL,
	SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_CODE,
	SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_NAME,
	SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ASelect from "~/view/component/ant_select/ASelect"
import GInput from "~/view/component/input/GInput"

class RegisterModalClinicalRecordHealthCare extends React.Component {
	HEALTH_CARE = "HEALTH_CARE"
	EHealthCare = {
		HEALTH_CARE: "HEALTH_CARE",
		OTHER: "OTHER",
	}
	PRIMARY_HOSPITAL_CODE = "PRIMARY_HOSPITAL_CODE"
	PRIMARY_HOSPITAL_NAME = "PRIMARY_HOSPITAL_NAME"
	AUTHEN_NUMBER = "AUTHEN_NUMBER"
	UCAE = "UCAE"

	constructor(props) {
		super(props)

		this.state = {
			healthcareRadio: this.EHealthCare.HEALTH_CARE,
		}

		this.onHealthCare = this.onHealthCare.bind(this)
		this.onHealthCareRadio = this.onHealthCareRadio.bind(this)
		this.onHealthCareOther = this.onHealthCareOther.bind(this)
		this.onClearHealthCare = this.onClearHealthCare.bind(this)
		this.onPrimaryHospitalInputChange = this.onPrimaryHospitalInputChange.bind(this)
		this.onSelectChange = this.onSelectChange.bind(this)
	}

	componentDidMount() {
		this.props.loadHealthCares()
		if (this.props.ipd.selectedAdmission.admission) {
			if (_.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareOther)) {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_CODE, this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareCode)
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_NAME, this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareName)
				this.props.setValue(
					SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_DES,
					this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareDescription
				)
			} else {
				this.setState({
					healthcareRadio: this.EHealthCare.OTHER,
				})
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareOther)
			}
			this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE, this.props.ipd.selectedAdmission.admission.clinicalRecord.ucae)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.healthcareRadio, this.state.healthcareRadio)) {
			if (_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)) {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_CODE, "")
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_NAME, "")
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_DES, "")
			} else {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, "")
			}
		}
		if (
			!_.isEqual(prevProps.ipd.modalClinicalRecord.healthcareCode, this.props.ipd.modalClinicalRecord.healthcareCode) ||
			!_.isEqual(prevProps.ipd.modalClinicalRecord.healthcares, this.props.ipd.modalClinicalRecord.healthcares)
		) {
			if (this.props.ipd.modalClinicalRecord.healthcares.length !== 0) {
				const healthcareCode = this.props.ipd.modalClinicalRecord.healthcareCode.split("?") || []
				const healthcares = this.props.ipd.modalClinicalRecord.healthcares || []
				this.props.setValue(
					SET_REGISTER_MODAL_CLINICAL_RECORD_IS_PRIMARY_HOSPITAL,
					healthcares.some((healthcare) => {
						return healthcare.primaryHospital && healthcareCode.includes(healthcare.code)
					})
				)
			}
		}
		if (!_.isEqual(prevProps.ipd.modalClinicalRecord.isPrimaryHospital, this.props.ipd.modalClinicalRecord.isPrimaryHospital)) {
			if (this.props.ipd.selectedAdmission.admission) {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_CODE, this.props.ipd.selectedAdmission.admission.clinicalRecord.primaryHospitalCode)
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_NAME, this.props.ipd.selectedAdmission.admission.clinicalRecord.primaryHospitalName)
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_AUTHEN_NUMBER, this.props.ipd.selectedAdmission.admission.clinicalRecord.authenNumber)
			} else {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_CODE, "")
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_NAME, "")
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_AUTHEN_NUMBER, "")
			}
		}
		if (!_.isEqual(prevProps.ipd.modalClinicalRecord.ucaeOptions, this.props.ipd.modalClinicalRecord.ucaeOptions)) {
			if (this.props.ipd.selectedAdmission.index !== -1 && this.props.ipd.modalClinicalRecord.ucaeOptions !== 0) {
				const ucaeOptions = this.props.ipd.modalClinicalRecord.ucaeOptions
				const ucae = this.props.ipd.selectedAdmission.admission.clinicalRecord.ucae
				const selectIdx = ucaeOptions.findIndex((item) => _.isEqual(item.name, ucae))
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE, selectIdx > -1 ? ucaeOptions[selectIdx].name : "")
			} else {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARES, [])
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_IS_PRIMARY_HOSPITAL, false)
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_CODE, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_NAME, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_DES, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_CODE, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_NAME, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_AUTHEN_NUMBER, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE, "")
	}

	getSelectedHealthCarePlan() {
		let healthcareName = this.props.ipd.modalClinicalRecord.healthcareName
		let selected = []
		healthcareName
			.split("?")
			.filter((each) => Boolean(each))
			.forEach((healthcareName) => {
				let idx = this.props.ipd.modalClinicalRecord.healthcares.findIndex((healthcare) => _.isEqual(healthcare.displayName, healthcareName))
				if (idx > -1) {
					selected.push(idx)
				} else {
					this.props.ipd.modalClinicalRecord.healthcares.forEach((healthcare, idx) => {
						let childIdx = healthcare.companies.findIndex((company) => _.includes(healthcareName, healthcare.displayName) && _.includes(healthcareName, company.name))
						if (childIdx > -1) {
							selected.push(idx + "-" + childIdx)
						}
					})
				}
			})
		return selected
	}

	onHealthCareRadio(event) {
		this.setState({
			healthcareRadio: event.target.value,
		})
	}

	onHealthCare(value, node, extra) {
		let healthcareCode = ""
		let healthcareName = ""
		let healthcareDescription = ""
		value.forEach((each, idx) => {
			if (typeof each === "string") {
				let idxAry = each.split("-")
				healthcareCode = [healthcareCode, this.props.ipd.modalClinicalRecord.healthcares[idxAry[0]].code].filter((code) => Boolean(code)).join("?")
				healthcareName = [healthcareName, this.props.ipd.modalClinicalRecord.healthcares[idxAry[0]].displayName + "/" + node[idx]]
					.filter((name) => Boolean(name))
					.join("?")
				healthcareDescription = [healthcareDescription, this.props.ipd.modalClinicalRecord.healthcares[idxAry[0]].companies[idxAry[1]].description]
					.filter((des) => Boolean(des))
					.join("?")
			} else {
				healthcareCode = [healthcareCode, this.props.ipd.modalClinicalRecord.healthcares[each].code].filter((code) => Boolean(code)).join("?")
				healthcareName = [healthcareName, node[idx]].filter((name) => Boolean(name)).join("?")
				healthcareDescription = [healthcareDescription, this.props.ipd.modalClinicalRecord.healthcares[each].description].filter((des) => Boolean(des)).join("?")
			}
		})

		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_CODE, healthcareCode)
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_NAME, healthcareName)
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_DES, healthcareDescription)
	}

	onClearHealthCare() {
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_CODE, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_NAME, "")
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_PLAN_DES, "")
	}

	onHealthCareOther(event) {
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_HEALTH_CARE_OTHER, event.target.value)
	}

	onPrimaryHospitalInputChange(event) {
		switch (event.target.name) {
			case this.PRIMARY_HOSPITAL_CODE:
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_CODE, event.target.value)
				break
			case this.PRIMARY_HOSPITAL_NAME:
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_PRIMARY_HOSPITAL_NAME, event.target.value)
				break
			case this.AUTHEN_NUMBER:
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_AUTHEN_NUMBER, event.target.value)
				break
			default:
		}
	}

	onSelectChange(name, value) {
		switch (name) {
			case this.UCAE:
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE, value)
				break
			default:
				break
		}
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
				<Row className={"g-1"}>
					<Col md="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.radio}
							name={this.HEALTH_CARE}
							value={this.EHealthCare.HEALTH_CARE}
							checked={_.isEqual(this.state.healthcareRadio, this.EHealthCare.HEALTH_CARE)}
							onChange={this.onHealthCareRadio}
						/>
					</Col>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							multiple
							allowClear
							treeLine={{ showLeafIcon: false }}
							placeholder={translate(ResourceAssistance.Hospitel.healthCare)}
							status={_.isEmpty(this.props.ipd.modalClinicalRecord.healthcareName) ? "error" : undefined}
							disabled={_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							value={this.getSelectedHealthCarePlan()}
							onChange={this.onHealthCare}
							onClear={this.onClearHealthCare}
							treeData={Utils.getTreeNodesFromHealthCarePlan(this.props.ipd.modalClinicalRecord.healthcares)}
						/>
					</Col>
				</Row>
				<Row className={"g-1"}>
					<Col md="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.radio}
							name={this.HEALTH_CARE}
							value={this.EHealthCare.OTHER}
							checked={_.isEqual(this.state.healthcareRadio, this.EHealthCare.OTHER)}
							onChange={this.onHealthCareRadio}
						/>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.other })}...`}
							rows={6}
							disabled={_.isEqual(this.state.healthcareRadio, this.EHealthCare.HEALTH_CARE)}
							value={this.props.ipd.modalClinicalRecord.healthcareOther}
							onChange={this.onHealthCareOther}
						/>
					</Col>
				</Row>
				{this.props.ipd.modalClinicalRecord.isPrimaryHospital && (
					<Fragment>
						<Row className={"g-1"}>
							<Col md={6} className={"px-2"}>
								<GInput
									required
									style={{ textAlign: "center" }}
									placeholder={translate(ResourceAssistance.Message.primaryHospitalCode)}
									name={this.PRIMARY_HOSPITAL_CODE}
									value={this.props.ipd.modalClinicalRecord.primaryHospitalCode}
									onChange={this.onPrimaryHospitalInputChange}
								/>
							</Col>
							<Col md={6} className={"px-2"}>
								<GInput
									required
									style={{ textAlign: "center" }}
									placeholder={translate(ResourceAssistance.Message.primaryHospitalName)}
									name={this.PRIMARY_HOSPITAL_NAME}
									value={this.props.ipd.modalClinicalRecord.primaryHospitalName}
									onChange={this.onPrimaryHospitalInputChange}
								/>
							</Col>
						</Row>
						<Row className={"g-1"}>
							<Col className={"px-2"}>
								<GInput
									required
									style={{ textAlign: "center" }}
									name={this.AUTHEN_NUMBER}
									placeholder={translate(ResourceAssistance.Message.authenNumber)}
									value={this.props.ipd.modalClinicalRecord.authenNumber}
									onChange={this.onPrimaryHospitalInputChange}
								/>
							</Col>
						</Row>
					</Fragment>
				)}
				<Row className={"g-1"}>
					<Col className={"px-2"}>
						<ASelect
							required
							showSearch
							style={{ flexGrow: 1, textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.ucae)}
							onSelectChange={(value) => this.onSelectChange(this.UCAE, value)}
							value={this.props.ipd.modalClinicalRecord.ucae}
							options={this.props.ipd.modalClinicalRecord.ucaeOptions}
							displayProperty="name"
							isSorted={false}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setObjArray,
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegisterModalClinicalRecordHealthCare))
