import React from "react"
import { Button, Col, Container, FormControl, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setInventoryInventoryDetails, setLoadingAction } from "~/redux/action"
import { SET_INVENTORY_INVENTORY_DETAILS_LOADING } from "~/redux/type"
import { SET_INVENTORY_EDIT_INVENTORY_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"

class EditInventory extends React.Component {
	constructor(props) {
		super(props)
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onMinChange = this.onMinChange.bind(this)
		this.onMaxChange = this.onMaxChange.bind(this)

		this.state = this.initState()
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.selectedInventoryDetail.inventoryDetail) !==
				JSON.stringify(this.props.selectedInventoryDetail.inventoryDetail) &&
			this.props.selectedInventoryDetail.inventoryDetail
		) {
			this.setState({
				min: this.props.selectedInventoryDetail.inventoryDetail.min,
				max: this.props.selectedInventoryDetail.inventoryDetail.max,
			})
		}
	}

	initState() {
		return {
			componentName: "EditInventory",
			min: 0,
			max: 0,
		}
	}

	reset() {
		this.setState(this.initState())
		this.props.onModalDisplayAction(SET_INVENTORY_EDIT_INVENTORY_DISPLAY, false)
	}

	onDone() {
		let raw = {
			locationId: this.props.selectedInventory.loc.id,
			userId: this.props.login.user.id,
			inventoryDetails: [
				{
					itemSupplierRelpId: this.props.selectedInventoryDetail.inventoryDetail.itemSupplierRelpId,
					min: this.state.min,
					max: this.state.max,
				},
			],
		}

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.inventory.saveInventory}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: raw,
		}

		let errorHandler = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, false)
			this.reset()
		}

		let callback = (res) => {
			let updateInventory = Utils.convertArrayToObject(res.data.inventoryDetails, "id")
			let stock = this.props.selectedInventory.loc
			Object.assign(stock, {
				inventoryDetails: Object.values(
					Object.assign(Utils.convertArrayToObject(stock.inventoryDetails, "id"), updateInventory)
				),
			})
			this.props.setInventoryInventoryDetails(stock.inventoryDetails)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onClose() {
		this.reset()
	}

	onMinChange(event) {
		this.setState({ min: Number(event.target.value) })
	}
	onMaxChange(event) {
		this.setState({ max: Number(event.target.value) })
	}

	isDoneDisabled() {
		if (this.props.selectedInventoryDetail.inventoryDetail) {
			return (
				this.state.min === this.props.selectedInventoryDetail.inventoryDetail.min &&
				this.state.max === this.props.selectedInventoryDetail.inventoryDetail.max
			)
		}
		return true
	}

	render() {
		return (
			<div>
				<Modal
					id={this.state.componentName}
					centered
					animation={false}
					size={ResourceAssistance.Modal.size.sm}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					show={this.props.isDisplay}
					onHide={this.onClose}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.inventory)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Container fluid>
							<Row>
								<Col>
									<label>{translate(ResourceAssistance.Message.min)}</label>
								</Col>
								<Col>
									<FormControl
										type={ResourceAssistance.FormControl.type.number}
										size={ResourceAssistance.FormControl.size.sm}
										onChange={this.onMinChange}
										defaultValue={
											this.props.selectedInventoryDetail.inventoryDetail
												? this.props.selectedInventoryDetail.inventoryDetail.min
												: ""
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<label>{translate(ResourceAssistance.Message.max)}</label>
								</Col>
								<Col>
									<FormControl
										type={ResourceAssistance.FormControl.type.number}
										size={ResourceAssistance.FormControl.size.sm}
										onChange={this.onMaxChange}
										defaultValue={
											this.props.selectedInventoryDetail.inventoryDetail
												? this.props.selectedInventoryDetail.inventoryDetail.max
												: ""
										}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							onClick={this.onDone}
							disabled={this.isDoneDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isEditInventoryDisplay,
	selectedInventoryDetail: state.inventory.selectedInventoryDetail,
	selectedInventory: state.inventory.selectedInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setInventoryInventoryDetails }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(EditInventory)
