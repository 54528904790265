import { DatePicker } from "antd"
import EN from "antd/lib/date-picker/locale/en_US"
import TH from "antd/lib/date-picker/locale/th_TH"
import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { LOCALES } from "~/i18n"
import "./ant_date_picker.css"

class AntDatePicker extends React.Component {
	constructor(props) {
		super(props)

		switch (this.props.language.locale) {
			case LOCALES.THAI:
				this.state = {
					locale: TH,
				}
				break

			default:
				this.state = {
					locale: EN,
				}
				break
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.language, this.props.language)) {
			switch (this.props.language.locale) {
				case LOCALES.THAI:
					this.setState({
						locale: TH,
					})
					break

				default:
					this.setState({
						locale: EN,
					})
					break
			}
		}
	}

	render() {
		return this.props.displayRange ? (
			<DatePicker.RangePicker
				picker={this.props.picker}
				style={this.props.style}
				locale={this.state.locale}
				size={this.props.size}
				format={this.props.format}
				showTime={this.props.showTime}
				placeholder={this.props.placeholder}
				status={this.props.status}
				disabled={this.props.disabled}
				disabledDate={this.props.disabledDate}
				disabledTime={this.props.disabledTime}
				defaultValue={this.props.defaultValues}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				presets={this.props.presets}
				needConfirm={this.props.needConfirm}
				value={this.props.values}
				onChange={this.props.onChange}
			/>
		) : (
			<DatePicker
				picker={this.props.picker}
				allowClear={this.props.allowClear}
				size={this.props.size}
				format={this.props.format}
				showTime={this.props.showTime}
				placeholder={this.props.placeholder}
				disabled={this.props.disabled}
				status={this.props.status}
				minDate={this.props.minDate}
				maxDate={this.props.maxDate}
				disabledTime={this.props.disabledTime}
				defaultValue={this.props.defaultValues[0]}
				needConfirm={this.props.needConfirm}
				value={this.props.values[0]}
				onChange={this.props.onChange}
				locale={this.state.locale}
			/>
		)
	}
}

AntDatePicker.propTypes = {
	allowClear: propTypes.bool,
	defaultValues: propTypes.array,
	disabledDate: propTypes.func,
	disabledTime: propTypes.func,
	displayRange: propTypes.bool,
	format: propTypes.string,
	isDisabled: propTypes.bool,
	onChange: propTypes.func,
	picker: propTypes.string,
	placeholder: propTypes.array,
	presets: propTypes.array,
	showTime: propTypes.object,
	size: propTypes.string,
	values: propTypes.array,
}

AntDatePicker.defaultProps = {
	allowClear: true,
	defaultValues: [undefined, undefined],
	picker: "date",
	values: [undefined, undefined],
	disabledDate: () => {
		return false
	},
	disabledTime: () => {
		return false
	},
}

const mapStateToProps = (state) => ({
	language: state.language,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AntDatePicker))
