import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"

class PharmacyModalReturnDoctorOrder extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>
							{this.props.pharmacy.modalReturn.isDoctorOrder
								? translate(ResourceAssistance.Hospitel.doctorStandingOrder)
								: translate(ResourceAssistance.Message.nurseOrder)}
						</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={2}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
								{this.props.pharmacy.selectedDoctorOrder.doctorOrder.code}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCarePlan)} span={1}>
								{this.props.pharmacy.selectedDoctorOrder.doctorOrder.healthcarePlan}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.genericName)} span={2}>
								{this.props.pharmacy.selectedDoctorOrder.doctorOrder.description}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									this.props.pharmacy.modalReturn.isDoctorOrder
										? translate(ResourceAssistance.Message.medicationUsage)
										: translate(ResourceAssistance.Message.note)
								}
								span={2}
							>
								{this.props.pharmacy.selectedDoctorOrder.doctorOrder.notes}
							</Descriptions.Item>
							<Descriptions.Item
								label={
									this.props.pharmacy.modalReturn.isDoctorOrder
										? translate(ResourceAssistance.Hospitel.doctorName)
										: translate(ResourceAssistance.Message.nurseName)
								}
								span={2}
							>
								{this.props.pharmacy.selectedDoctorOrder.doctorOrder.lastModifiedBy.displayName}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalReturnDoctorOrder)
