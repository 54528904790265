import { SET_CONTEXT_MENU_PATIENT_DISPLAY_PATIENT_INFO } from "~/redux/type"

const init = {
	isDisplayPatientInfo: true,
}

const patientReducer = (state = init, action) => {
	switch (action.type) {
		case SET_CONTEXT_MENU_PATIENT_DISPLAY_PATIENT_INFO:
			return Object.assign({}, state, {
				isDisplayPatientInfo: action.payload.value,
			})
		default:
			return state
	}
}

export default patientReducer
