import { Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_DEBT_MODAL_PAYMENT_BANKS,
	SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES,
	SET_DEBT_MODAL_PAYMENT_SELECTED_BANK,
	SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH,
	SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH,
	SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class DebtModalPaymentBank extends React.Component {
	constructor(props) {
		super(props)

		this.onBank = this.onBank.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onTransferBankName = this.onTransferBankName.bind(this)
		this.onTransferBankBranch = this.onTransferBankBranch.bind(this)
	}

	componentDidMount() {
		this.loadBanks()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.debt.modalPayment.selectedBank, this.props.debt.modalPayment.selectedBank)) {
			if (this.props.debt.modalPayment.selectedBank.bank) {
				this.props.setValue(
					SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES,
					this.props.debt.modalPayment.selectedBank.bank.branches
				)
			} else {
				this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH, -1, null)
				this.props.setValue(SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BANK, -1, null)
		this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH, -1, null)
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_BANKS, [])
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES, [])
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME, "")
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH, "")
	}

	loadBanks() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.debt.getBanks,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DEBT_MODAL_PAYMENT_BANKS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onBank(value) {
		if (value === undefined) {
			this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BANK, -1, null)
			return
		}
		this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BANK, value, this.props.debt.modalPayment.banks[value])
	}

	onBranch(value) {
		if (value === undefined) {
			this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH, -1, null)
			return
		}
		this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH, value, this.props.debt.modalPayment.branches[value])
	}

	onTransferBankName(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME, e.target.value)
	}

	onTransferBankBranch(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH, e.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<Select
							showSearch
							allowClear
							size={"large"}
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							placeholder={translate(ResourceAssistance.Message.bank)}
							status={this.props.debt.modalPayment.selectedBank.bank ? undefined : "error"}
							value={
								this.props.debt.modalPayment.selectedBank.bank
									? this.props.debt.modalPayment.selectedBank.index
									: undefined
							}
							onChange={this.onBank}
						>
							{Utils.renderSelects(this.props.debt.modalPayment.banks, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							showSearch
							allowClear
							size={"large"}
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							placeholder={translate(ResourceAssistance.Message.bankBranch)}
							status={this.props.debt.modalPayment.selectedBranch.branch ? undefined : "error"}
							value={
								this.props.debt.modalPayment.selectedBranch.branch
									? this.props.debt.modalPayment.selectedBranch.index
									: undefined
							}
							onChange={this.onBranch}
						>
							{Utils.renderSelects(this.props.debt.modalPayment.branches, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.transferBankName })}
							onChange={this.onTransferBankName}
							value={this.props.debt.modalPayment.transferBankName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.transferBankBranch })}
							onChange={this.onTransferBankBranch}
							value={this.props.debt.modalPayment.transferBankBranch}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalPaymentBank))
