import { Select, Switch } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME,
	SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class AdmHealthCarePlanModalCompanyInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
		this.onInsuranceCompany = this.onInsuranceCompany.bind(this)
		this.onInsuranceCompanyClear = this.onInsuranceCompanyClear.bind(this)
	}

	componentDidMount() {
		this.props.loadInsuranceCompanies()
		let company = this.props.type.healthcarePlan.selectedCompany.company
		if (company) {
			this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME, company.name)
			this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION, company.description)
			this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE, company.active)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.type.healthcarePlan.modalCompany.insuranceCompanies, this.props.type.healthcarePlan.modalCompany.insuranceCompanies)) {
			if (this.props.type.healthcarePlan.selectedCompany.company && !_.isEmpty(this.props.type.healthcarePlan.modalCompany.insuranceCompanies)) {
				let idx = this.props.type.healthcarePlan.modalCompany.insuranceCompanies.findIndex(
					(insuranceCompany) =>
						insuranceCompany.displayName.toLowerCase() === this.props.type.healthcarePlan.selectedCompany.company.insuranceCompany.toLowerCase()
				)
				if (idx > -1) {
					this.props.setSelected(
						SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY,
						idx,
						this.props.type.healthcarePlan.modalCompany.insuranceCompanies[idx]
					)
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME, "")
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION, "")
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE, true)
		this.props.setObjArray(SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES, [])
		this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY, -1, null)
	}

	onName(event) {
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME, event.target.value)
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION, event.target.value)
	}

	onActive(value) {
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE, value)
	}

	onInsuranceCompany(value) {
		this.props.setSelected(
			SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.type.healthcarePlan.modalCompany.insuranceCompanies[value] : null
		)
	}

	onInsuranceCompanyClear() {
		this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY, -1, null)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							size={ResourceAssistance.Select.size.large}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.insuranceCompany)}
							status={this.props.type.healthcarePlan.modalCompany.selectedInsuranceCompany.company ? undefined : "error"}
							value={
								this.props.type.healthcarePlan.modalCompany.selectedInsuranceCompany.company
									? this.props.type.healthcarePlan.modalCompany.selectedInsuranceCompany.index
									: undefined
							}
							onChange={this.onInsuranceCompany}
							onClear={this.onInsuranceCompanyClear}
						>
							{Utils.renderSelects(this.props.type.healthcarePlan.modalCompany.insuranceCompanies, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.name)}
							value={this.props.type.healthcarePlan.modalCompany.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.type.healthcarePlan.modalCompany.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Switch
							style={this.props.type.healthcarePlan.modalCompany.isActive ? { backgroundColor: ResourceAssistance.CSS.Color.dark_green } : undefined}
							checkedChildren={translate(ResourceAssistance.Message.active)}
							unCheckedChildren={translate(ResourceAssistance.Message.inactive)}
							checked={this.props.type.healthcarePlan.modalCompany.isActive}
							onChange={this.onActive}
						/>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmHealthCarePlanModalCompanyInfo))
