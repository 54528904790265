import { Divider } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { GiReceiveMoney } from "react-icons/gi"
import { ImPrinter } from "react-icons/im"
import { MdCreateNewFolder } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setCashierAdmissions, setLoadingAction, setSelected, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_CASHIER_ADMISSION_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import CashierPrintableDataFactory from "~/utils/factory/print/HIS/CashierPrintableDataFactory"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import ServerUtils from "~/utils/ServerUtils"
import { Utils } from "~/utils/Utils"

class CashierLeftActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.billingStatements = []

		this.onNew = this.onNew.bind(this)
		this.onDischarge = this.onDischarge.bind(this)
		this.onPrintBillByCategory = this.onPrintBillByCategory.bind(this)
		this.onPrintBillSummary = this.onPrintBillSummary.bind(this)
		this.onPrintBillDetails = this.onPrintBillDetails.bind(this)
		this.onPrintBillByHealthCarePlan = this.onPrintBillByHealthCarePlan.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.cashier.dischargePatient)) {
				let data = {
					admissionId: this.props.cashier.selectedAdmission.admission.id,
					userId: this.props.login.user.id,
					billings: this.props.cashier.healthCarePlanTable.filtered
						.filter((hcp) => !hcp.primaryHealthCarePlan)
						.map((hcp) => {
							return {
								branchId: this.props.cashier.location.id,
								billingPlanId: hcp.id,
								unpaid: Utils.calculateBillingStatementBalance(hcp.billingStatements)
									.minus(ServerUtils.calculateBillingPlanAdjustments(hcp.billingPlanAdjustments.filter((bpAdj) => !bpAdj.billing)))
									.toFixed(2),
							}
						}),
					billingPlans: this.props.cashier.healthCarePlanTable.filtered
						.filter((hcp) => !hcp.primaryHealthCarePlan)
						.map((hcp) => {
							let allocatedBillingStatements = Utils.allocateBillDiscount(
								hcp.billingStatements,
								_.isEmpty(hcp.billingPlanAdjustments) ? Utils.BigNumber(0) : hcp.billingPlanAdjustments[0].adjustments
							)
							return {
								...hcp,
								billingStatements: hcp.billingStatements
									.filter((bs) => !bs.billing && (!bs.doctorOrder || !bs.doctorOrder.prescriptionOnly))
									.map((billingStatement) => {
										return {
											...billingStatement,
											accountingTransDescr: billingStatement.accountingTransactionDescription,
											billAdjustment: allocatedBillingStatements[billingStatement.id],
										}
									}),
							}
						}),
					billingStatements: this.props.cashier.selectedAdmission.admission.billingStatements
						.filter((bs) => bs.doctorOrder && bs.doctorOrder.prescriptionOnly)
						.map((bs) => {
							return Object.assign(bs, {
								//paid/debt/deletable
								refund: bs.billing !== undefined,
								billingId: bs.billing ? bs.billing.id : -1,
							})
						}),
				}
				this.updateAdmission(ResourceAssistance.Url.cashier.discharge, data)
			}
		}

		if (!_.isEqual(prevProps.cashier.selectedAdmission, this.props.cashier.selectedAdmission)) {
			this.billingStatements = this.props.cashier.admissionTable.original.reduce((obj, cur) => {
				return obj.concat(cur.billingStatements.filter((bs) => bs.billingPlan))
			}, [])
		}
	}

	isCheckOutDisabled() {
		let patient = this.props.cashier.patient
		let selectedAdmission = this.props.cashier.selectedAdmission.admission
		return (
			(selectedAdmission && selectedAdmission.clinicalRecord && selectedAdmission.clinicalRecord.medicalRecords.some((md) => md.checkOutDateTime === 0)) ||
			patient.labs.some((lab) => {
				if (lab.status === ResourceAssistance.CONSTANT.CANCELLED) {
					return false
				}
				let billingStatementTotal = this.billingStatements
					.filter((bs) => bs.lab && bs.lab.id === lab.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
				return !billingStatementTotal.isEqualTo(lab.service.pricePerUnit)
			}) ||
			patient.certificates.some((certificate) => {
				let billingStatementTotal = this.billingStatements
					.filter((bs) => bs.certificate && bs.certificate.id === certificate.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))

				return !billingStatementTotal.isEqualTo(certificate.service.pricePerUnit)
			}) ||
			patient.hospitels.some((hospitel) => !hospitel.dischargeSummary) ||
			(selectedAdmission &&
				selectedAdmission.doctorOrders
					.filter((order) => !order.prescriptionOnly)
					.some((order) => {
						let billingStatementTotal = this.billingStatements
							.filter((bs) => bs.doctorOrder && bs.doctorOrder.id === order.id)
							.reduce((total, cur) => {
								return total.plus(cur.charge)
							}, Utils.BigNumber(0))
						return !billingStatementTotal.isEqualTo(
							Utils.BigNumber(Utils.calculateDoctorOrderBalance(order)).minus(
								Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit)
							)
						)
					})) ||
			(selectedAdmission &&
				selectedAdmission.nurseOrders.some((order) => {
					let billingStatementTotal = this.billingStatements
						.filter((bs) => bs.nurseOrder && bs.nurseOrder.id === order.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))
					return !billingStatementTotal.isEqualTo(
						Utils.BigNumber(Utils.calculateNurseOrderBalance(order)).minus(
							Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit)
						)
					)
				})) ||
			// disable if primary healthcare plan is not paid.
			this.props.cashier.healthCarePlanTable.filtered.filter((hcp) => hcp.primaryHealthCarePlan).some((hcp) => hcp.billingStatements.some((bs) => !bs.billing))
		)
	}

	updateAdmission(url, data) {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: data,
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter((each) => each.id !== res.data.admissions[0].id)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_CASHIER_ADMISSION_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_CASHIER_ADMISSION_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_CASHIER_ADMISSION_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPrintBillDetails(event) {
		let grandTotal = Utils.BigNumber(0)
		let admission = this.props.cashier.selectedAdmission.admission

		let data = {
			taxIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			descriptionIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount }),
			grandTotalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.address }),
			qtyIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.qty }),
			balanceIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			admissionDateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.dischargeDate }),
			admissionIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.admission }),

			logo: Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs),
			name: Utils.isDevMode() ? "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด" : process.env.REACT_APP_ORG_NAME,
			branchName: Utils.isDevMode() ? "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี" : process.env.REACT_APP_BRANCH_NAME,
			address: Utils.isDevMode() ? "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150" : process.env.REACT_APP_ADDRESS,
			tel: Utils.isDevMode() ? "02-451-4920-4" : process.env.REACT_APP_TEL,
			taxId: Utils.isDevMode() ? "0105535059152" : process.env.REACT_APP_TAX_ID,

			pid: this.props.cashier.patient.id,
			patientName: this.props.cashier.patient.title + " " + this.props.cashier.patient.firstName + " " + this.props.cashier.patient.lastName,
			patientAddress: this.props.cashier.patient.address,
			admissionId: admission.id,
			date: Utils.formatDateTime(new Date()),
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			services: Utils.groupBy(
				this.props.cashier.selectedAdmission.admission.billingStatements.reduce((services, service) => {
					grandTotal = grandTotal.plus(service.charge)
					if (service.doctorOrder) {
						for (let i = 0; i <= Utils.calculateDaysBetween(service.doctorOrder.startDateTime, service.doctorOrder.endDateTime); i++) {
							services.push({
								description: service.code + "    " + service.name,
								categoryName: service.categoryName,
								amount: Utils.formatNumWithComma(Utils.BigNumber(service.doctorOrder.pricePerUnit).toFixed(2)),
								qty: Utils.formatNumWithComma(service.qtyPerDay),
								balance: Utils.formatNumWithComma(Utils.calculateDoctorOrderBalance(service.doctorOrder)),
							})
						}
					} else if (service.nurseOrder) {
						for (let i = 0; i <= Utils.calculateDaysBetween(service.nurseOrder.startDateTime, service.nurseOrder.endDateTime); i++) {
							services.push({
								description: service.code + "    " + service.name,
								categoryName: service.categoryName,
								amount: Utils.formatNumWithComma(Utils.BigNumber(service.nurseOrder.pricePerUnit).toFixed(2)),
								qty: Utils.formatNumWithComma(service.qtyPerDay),
								balance: Utils.formatNumWithComma(Utils.BigNumber(service.nurseOrder.pricePerUnit).times(service.qtyPerDay).toFixed(2)),
							})
						}
					} else if (service.lab) {
						services.push({
							description: service.code + "    " + service.name,
							categoryName: service.categoryName,
							amount: Utils.formatNumWithComma(Utils.BigNumber(service.lab.service.pricePerUnit).toFixed(2)),
							qty: Utils.formatNumWithComma(service.qtyPerDay),
							balance: Utils.formatNumWithComma(Utils.BigNumber(service.lab.service.pricePerUnit).times(service.qtyPerDay).toFixed(2)),
						})
					} else if (service.certificate) {
						services.push({
							description: service.code + "    " + service.name,
							categoryName: service.categoryName,
							amount: Utils.formatNumWithComma(Utils.BigNumber(service.certificate.service.pricePerUnit).toFixed(2)),
							qty: Utils.formatNumWithComma(service.qtyPerDay),
							balance: Utils.formatNumWithComma(Utils.BigNumber(service.certificate.service.pricePerUnit).times(service.qtyPerDay).toFixed(2)),
						})
					}
					return services
				}, []),
				"categoryName"
			),
			grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),

			signatureTitles: [this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier"],
			signatureNames: [this.props.login.user.firstName + " " + this.props.login.user.lastName],
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.cashier.billDetails, "_blank")
		myWindow.data = data
	}

	onPrintBillSummary(event) {
		let admission = this.props.cashier.selectedAdmission.admission
		let subTotal = Utils.BigNumber(0)
		let serverAdjustments = Utils.BigNumber(0)
		let billingPlanAdjustments = admission.billingPlans.reduce((totalAdjustments, billingPlan) => {
			return totalAdjustments.plus(ServerUtils.calculateBillingPlanAdjustments(billingPlan.billingPlanAdjustments))
		}, Utils.BigNumber(0))
		let grandTotal = Utils.BigNumber(0)

		let data = {
			taxIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			descriptionIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount }),
			subTotalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.subTotal }),
			grandTotalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.address }),
			adjustmentIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.discount }),
			balanceIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			admissionDateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.dischargeDate }),

			logo: Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs),
			name: Utils.isDevMode() ? "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด" : process.env.REACT_APP_ORG_NAME,
			branchName: Utils.isDevMode() ? "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี" : process.env.REACT_APP_BRANCH_NAME,
			address: Utils.isDevMode() ? "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150" : process.env.REACT_APP_ADDRESS,
			tel: Utils.isDevMode() ? "02-451-4920-4" : process.env.REACT_APP_TEL,
			taxId: Utils.isDevMode() ? "0105535059152" : process.env.REACT_APP_TAX_ID,

			pid: this.props.cashier.patient.id,
			patientName: this.props.cashier.patient.title + " " + this.props.cashier.patient.firstName + " " + this.props.cashier.patient.lastName,
			patientAddress: this.props.cashier.patient.address,
			admissionId: admission.id,
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			services: Utils.groupBy(
				this.props.cashier.selectedAdmission.admission.billingStatements.map((service) => {
					subTotal = subTotal.plus(service.charge)
					serverAdjustments = serverAdjustments.plus(service.adjustment)
					grandTotal = grandTotal.plus(service.charge).minus(service.adjustment)
					return {
						name: service.name,
						categoryName: service.categoryName,
						amount: Utils.formatNumWithComma(Utils.BigNumber(service.charge).toFixed(2)),
						adjustment: Utils.formatNumWithComma(Utils.BigNumber(service.adjustment).negated().toFixed(2)),
						balance: Utils.formatNumWithComma(Utils.BigNumber(service.charge).minus(service.adjustment).toFixed(2)),
					}
				}),
				"categoryName"
			),

			subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
			grandAdjustment: Utils.formatNumWithComma(serverAdjustments.plus(billingPlanAdjustments).negated().toFixed(2)),
			grandTotal: Utils.formatNumWithComma(grandTotal.minus(billingPlanAdjustments).toFixed(2)),

			signatureTitles: [
				this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.patient }) + "/Patient",
				this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier",
			],
			signatureNames: [
				this.props.cashier.patient.firstName + " " + this.props.cashier.patient.lastName,
				this.props.login.user.firstName + " " + this.props.login.user.lastName,
			],
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.cashier.billSummary, "_blank")
		myWindow.data = data
	}

	onPrintBillByCategory(event) {
		let billStatements = []

		this.props.cashier.selectedAdmission.admission.billingStatements.forEach((billStatement) => {
			if (billStatement.lab) {
				let lab = billStatement.lab
				if (!billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(lab.service.type.description).toLowerCase())) {
					billStatements.push({
						description: lab.service.type.description,
						details: {
							adjustment: 0,
							amount: 0,
						},
					})
				}
				let service = billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(lab.service.type.description).toLowerCase())
				service.details.adjustment = Utils.BigNumber(service.details.adjustment).plus(billStatement.adjustment).toFixed(2)
				service.details.amount = Utils.BigNumber(service.details.amount).plus(billStatement.charge).toFixed(2)
			} else if (billStatement.certificate) {
				let certificate = billStatement.certificate
				if (!billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(certificate.service.type.description).toLowerCase())) {
					billStatements.push({
						description: certificate.service.type.description,
						details: {
							adjustment: 0,
							amount: 0,
						},
					})
				}
				let service = billStatements.find(
					(each) => Utils.trim(each.description).toLowerCase() === Utils.trim(certificate.service.type.description).toLowerCase()
				)
				service.details.adjustment = Utils.BigNumber(service.details.adjustment).plus(billStatement.adjustment).toFixed(2)
				service.details.amount = Utils.BigNumber(service.details.amount).plus(billStatement.charge).toFixed(2)
			} else if (billStatement.doctorOrder) {
				let doctorOrder = billStatement.doctorOrder
				if (!billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(doctorOrder.categoryName).toLowerCase())) {
					billStatements.push({
						description: doctorOrder.categoryName,
						details: {
							adjustment: 0,
							amount: 0,
						},
					})
				}
				let service = billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(doctorOrder.categoryName).toLowerCase())
				service.details.adjustment = Utils.BigNumber(service.details.adjustment).plus(billStatement.adjustment).toFixed(2)
				service.details.amount = Utils.BigNumber(service.details.amount).plus(billStatement.charge).toFixed(2)
			} else if (billStatement.nurseOrder) {
				let nurseOrder = billStatement.nurseOrder
				if (!billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(nurseOrder.categoryName).toLowerCase())) {
					billStatements.push({
						description: nurseOrder.categoryName,
						details: {
							adjustment: 0,
							amount: 0,
						},
					})
				}
				let service = billStatements.find((each) => Utils.trim(each.description).toLowerCase() === Utils.trim(nurseOrder.categoryName).toLowerCase())
				service.details.adjustment = Utils.BigNumber(service.details.adjustment).plus(billStatement.adjustment).toFixed(2)
				service.details.amount = Utils.BigNumber(service.details.amount).plus(billStatement.charge).toFixed(2)
			}
		})

		let data = PrintableDataFactory.generateBillReportDate(
			this.props.cashier.patient,
			this.props.cashier.selectedAdmission.admission,
			this.props.login.user,
			billStatements.sort((a, b) => Utils.sort(a.description, b.description)),
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.cashier.bill, "_blank")
		myWindow.data = data
	}

	onPrintBillByHealthCarePlan() {
		let data = CashierPrintableDataFactory.generateBillByHealthCarePlanData(
			this.props.cashier.patient,
			this.props.cashier.selectedAdmission.admission,
			this.props.login.user,
			this.props.cashier.selectedAdmission.admission.billingPlans,
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.cashier.billByHealthCarePlan, "_blank")
		myWindow.data = data
	}

	onDischarge(event) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.cashier.dischargePatient)
		this.props.setWarningMsgAction(translate(ResourceAssistance.Warning.dischargePatient))
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onNew(event) {
		this.updateAdmission(ResourceAssistance.Url.cashier.createAdmission, {
			patientId: this.props.cashier.patient.id,
			userId: this.props.login.user.id,
		})
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Cashier.Discharge, this.props.login.user.roles) && (
					<Fragment>
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={
									this.props.cashier.selectedAdmission.index === -1 || this.props.cashier.selectedAdmission.admission.dischargedBy || this.isCheckOutDisabled()
								}
								onClick={this.onDischarge}
							>
								<GiReceiveMoney size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Hospitel.checkout)}
							</Button>
						</Col>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
					</Fragment>
				)}
				<Col md={"auto"}>
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<Dropdown.Toggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={this.props.cashier.selectedAdmission.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Dropdown.Toggle>
						<DropdownMenu>
							<DropdownItem onClick={this.onPrintBillSummary}>{translate(ResourceAssistance.Message.billSummary)}</DropdownItem>
							<DropdownItem onClick={this.onPrintBillByCategory}>{translate(ResourceAssistance.Message.billByCategory)}</DropdownItem>
							<DropdownItem onClick={this.onPrintBillByHealthCarePlan}>{translate(ResourceAssistance.Message.billByHealthCarePlan)}</DropdownItem>
							{/* <DropdownItem onClick={this.onPrintBillDetails}>
								{translate(ResourceAssistance.Message.billDetails)}
							</DropdownItem> */}
						</DropdownMenu>
					</Dropdown>
				</Col>

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Cashier.Discharge, this.props.login.user.roles) && (
					<Fragment>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={this.props.cashier.admissionTable.original.some((each) => !each.dischargedBy)}
								onClick={this.onNew}
							>
								<MdCreateNewFolder size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						</Col>
					</Fragment>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	cashier: state.hospitel.cashier,
	common: state.common
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setCashierAdmissions,
			setLoadingAction,
			setSelected,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierLeftActionRow))
