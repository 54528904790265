import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setIPDPatients, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER_OPTIONS,
	SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE_OPTIONS,
	SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE_OPTIONS,
	SET_HOSPITEL_IPD_MODAL_REGISTER_NATION_OPTIONS,
	SET_HOSPITEL_IPD_PATIENTS_LOADING,
	SET_PAGE_LOADING,
	SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE_OPTIONS,
	SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE_OPTIONS,
} from "~/redux/type"
import "./ipd.css"
import IPDLeft from "./left-pannel/IPDLeft"
import IPDRight from "./right-pannel/IPDRight"

class InPatientDepartment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InPatientDepartment",
		}

		this.loadPatients = this.loadPatients.bind(this)
	}

	componentDidMount() {
		this.loadElcaimOptions()
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE_OPTIONS, [])
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER_OPTIONS, [])
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE_OPTIONS, [])
		this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_NATION_OPTIONS, [])
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE_OPTIONS, [])
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE_OPTIONS, [])
	}

	loadElcaimOptions() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getRegistrationSelectOption,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_IDTYPE_OPTIONS, res.data.wfep_idt)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_CAREER_OPTIONS, res.data.wfep_crr)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_MARRIAGE_OPTIONS, res.data.wfep_mre)
			this.props.setValue(SET_HOSPITEL_IPD_MODAL_REGISTER_NATION_OPTIONS, res.data.wfep_ntn)
			this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_UCAE_OPTIONS, res.data.wfep_uae)
			this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE_OPTIONS, res.data.wfep_opt)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPatients() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.ipd.startDateTime,
				endDateTime: this.props.ipd.endDateTime,
			},
		}

		let callback = (res) => {
			this.props.setIPDPatients(res.data.patients)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_IPD_PATIENTS_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0 full-size"}>
					<Col md="auto">
						<IPDLeft loadPatients={this.loadPatients} />
					</Col>
					<Col>
						<IPDRight />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setIPDPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InPatientDepartment))
