import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setModalDoneIndAction, setPoModalEditPayments, setPoOrders } from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_PO_EDIT_DONE_IND, SET_PO_LOADING } from "~/redux/type/type/PO"
import { Utils } from "~/utils/Utils"
import POLeft from "./left_pannel/POLeft"
import "./purchaseOrder.css"
import PORight from "./right_pannel/PORight"

class PurchaseOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PurchaseOrder",
		}

		this.loadOrders = this.loadOrders.bind(this)
		this.updateOriginalOrders = this.updateOriginalOrders.bind(this)
		this.print = this.print.bind(this)
	}

	componentDidMount() {
		this.loadOrders()
		this.loadPayments()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.po.edit.isDone !== this.props.po.edit.isDone && this.props.po.edit.isDone) {
			this.updateOriginalOrders(this.props.po.edit.callback)
			this.props.setModalDoneIndAction(SET_PO_EDIT_DONE_IND, false, null)
		}
	}

	loadOrders() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.po.getPurchaseOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: this.props.po.searchDate,
		}

		let callback = (res) => {
			this.props.setPoOrders(res.data.orders)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PO_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PO_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PO_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPayments() {
		let paymentParams = {
			method: "GET",
			url: ResourceAssistance.Url.po.getPaymentTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}

		let paymentCallback = (res) => {
			this.props.setPoModalEditPayments(res.data.uts)
		}
		let paymentReqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let paymentResInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(paymentParams, paymentCallback, errorHandler, paymentReqInterceptor, paymentResInterceptor)
	}

	updateOriginalOrders(orders) {
		let original = this.props.po.order.original

		orders.forEach((order) => {
			let index = original.findIndex((originalOrder) => originalOrder.id === order.id)
			original[index] = order
		})
		this.props.setPoOrders(original)
	}

	print(order, suppliers) {
		suppliers.forEach((each) => {
			const target = {
				taxIdIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.taxId,
					defaultMessage: "",
				}),
				titleIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.purchaseOrder,
					defaultMessage: "",
				}),
				purchaseFromIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.purchaseFrom,
					defaultMessage: "",
				}),
				vendorIdIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.supplierCode,
					defaultMessage: "",
				}),
				shipToIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.shipAddress,
					defaultMessage: "",
				}),
				memoIdIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.memoId,
					defaultMessage: "",
				}),
				poIdIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.poId,
					defaultMessage: "",
				}),
				poDateIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.poDate,
					defaultMessage: "",
				}),
				paymentTermsIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.paymentTerms,
					defaultMessage: "",
				}),
				requiredByDateIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.requiredByDate,
					defaultMessage: "",
				}),
				paymentTypeIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.paymentType,
					defaultMessage: "",
				}),
				idIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.id,
					defaultMessage: "",
				}),
				codeIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.code }),
				descriptionIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.list,
					defaultMessage: "",
				}),
				qtyIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.qty,
					defaultMessage: "",
				}),
				bonusIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.bonus,
					defaultMessage: "",
				}),
				unitPriceIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.unitPrice,
					defaultMessage: "",
				}),
				discountIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.discount,
					defaultMessage: "",
				}),
				amountIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.amount,
					defaultMessage: "",
				}),
				uomIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.uom,
					defaultMessage: "",
				}),
				remarkIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.remark,
					defaultMessage: "",
				}),
				subTotalIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.subTotal,
					defaultMessage: "",
				}),
				totalIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.totalPriceDiscounted,
					defaultMessage: "",
				}),
				grandTotalIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.grandTotal,
					defaultMessage: "`",
				}),
				issuedByIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.issuedBy,
					defaultMessage: "",
				}),
				certifiedByIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.certifiedBy,
					defaultMessage: "",
				}),
				approvedByIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.approvedBy,
					defaultMessage: "",
				}),
				vatIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.vat,
					defaultMessage: "",
				}),
				billToIntl: this.props.intl.formatMessage({
					id: ResourceAssistance.Message.billTo,
				}),

				name: order.pr.location.parent.displayName,
				address: order.pr.location.address.displayName,
				tel: order.pr.location.address.tel,
				fax: order.pr.location.address.fax,
				taxId: order.pr.location.parent.taxNo,

				vendorName: each.name,
				vendorAddress: each.address,
				vendorTel: each.phone,

				shipToName: order.pr.location.displayName,
				shipToAddress: order.pr.location.address.displayName,
				shippingContact: each.orderDetail.shippingContact,

				billToAddress: order.pr.location.address.billingAddress,
				billingContact: each.orderDetail.billingContact,

				poId: order.id,
				poDate: Utils.formatDate(order.approvalApprovedDateTime),
				memoId: each.orderDetail.routingId,

				vendorId: each.code,
				paymentType: each.orderDetail.payment.displayName,
				paymentTerms: each.orderDetail.paymentTerms,
				requiredByDate: Utils.formatDate(each.orderDetail.requiredDateTime),

				items: each.items,

				subTotal: Utils.formatNumWithComma(Utils.BigNumber(each.totalPriceBeforeTax).toFixed(2)),
				discount: each.orderDetail.discount,
				discountTotal: Utils.formatNumWithComma(Utils.BigNumber(each.discountPrice).toFixed(2)),
				total: Utils.formatNumWithComma(Utils.BigNumber(each.totalPriceAfterDiscount).toFixed(2)),
				vat: each.items.some((item) => !Utils.BigNumber(item.taxRate).eq(each.items[0].taxRate)) ? 0 : each.items[0].taxRate,
				vatTotal: Utils.formatNumWithComma(Utils.BigNumber(each.taxPrice).toFixed(2)),
				grandTotal: Utils.formatNumWithComma(Utils.BigNumber(each.totalPriceAfterDiscountAndTax).toFixed(2)),

				issuedByLastName: order.pr.approval.lastName,
				issuedByFirstName: order.pr.approval.firstName,
				issuedDate: Utils.formatDate(order.pr.approverApprovedDateTime),

				certifiedByLastName: order.inspector.lastName,
				certifiedByFirstName: order.inspector.firstName,
				certifiedBySignature: order.inspector.signature,
				certifiedDate: Utils.formatDate(order.inspectorApprovedDateTime),

				approvedByLastName: order.approval.lastName,
				approvedByFirstName: order.approval.firstName,
				approvedBySignature: order.approval.signature,
				approvedDate: Utils.formatDate(order.approvalApprovedDateTime),
			}

			let myWindow = window.open(ResourceAssistance.Path.Report.Po.po, "_blank")
			myWindow.data = target
			// myWindow.onload = (e) => {
			// localStorage.setItem("reportPo", JSON.stringify(target))
			// localStorage.removeItem("reportPo")
			// }
		})
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0"}>
					<Col md="auto">
						<POLeft {...this.props} print={this.print} loadOrders={this.loadOrders} />
					</Col>
					<Col>
						<PORight {...this.props} updateOriginalOrders={this.updateOriginalOrders} print={this.print} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	po: state.purchaseOrder,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction, setPoOrders, setModalDoneIndAction, setPoModalEditPayments }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder))
