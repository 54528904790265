import React from "react"
import { BsBank2, BsCreditCard2Back } from "react-icons/bs"
import { FaHospitalUser } from "react-icons/fa"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { IconFont } from "~/icons"
import { onModalDisplayAction, setSelectedType, setValue } from "~/redux/action"
import {
	SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT,
} from "~/redux/type"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import AbstractTypeView from "../AbstractTypeView"
import AdmPaymentAccountsPayable from "./AdmPaymentAccountsPayable"
import { Col, Row } from "react-bootstrap"
import AdmPaymentAccountsReceivable from "./AdmPaymentAccountsReceivable"

class AdmPayment extends React.Component {
	constructor(props) {
		super(props)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
	}

	onEditBtnClick(event) {
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT, true)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	onAddBtnClick(event) {
		this.props.setSelectedType(-1, null)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT, true)
		this.props.dispatch(onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)).then(() => {
			// let newType = document.getElementById("displayParent")
			// console.log((newType.hidden = false))
		})
	}

	render() {
		return (
			<>
				<AbstractTypeView
					scrollableTableData={this.props.type.types}
					onAddBtnClick={this.onAddBtnClick}
					onEditBtnClick={this.onEditBtnClick}
					onRefreshClick={this.props.onRefreshClick}
					displayBtn
					buttonColors={[
						ResourceAssistance.Button.variant.outlineInfo,
						ResourceAssistance.Button.variant.outlineInfo,
						"",
						ResourceAssistance.Button.variant.outlineInfo,
					]}
					btnMessages={["", "", "", ""]}
					btnTypes={[EBtnType.POPOVER, EBtnType.POPOVER, EBtnType.POPOVER, EBtnType.POPOVER]}
					btnIcons={[
						<BsBank2 size={ResourceAssistance.ReactIcon.size} />,
						<FaHospitalUser size={ResourceAssistance.ReactIcon.size} />,
						<IconFont type={"icon-ali-pmgcare-check"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
						<BsCreditCard2Back size={ResourceAssistance.ReactIcon.size} />,
					]}
				/>
				<Row className={"full-size g-2"}>
					<Col>
						<AdmPaymentAccountsReceivable />
					</Col>
					<Col>
						<AdmPaymentAccountsPayable />
					</Col>
				</Row>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelectedType,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmPayment)
