import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OperatingRoomRightTabGeneral from "./OperatingRoomRightTabGeneral"
import OperatingRoomRightTabCalendar from "./OperatingRoomRightTabCalendar"
import OperatingRoomRightTabAnesthesiologist from "./anesethesia-tab/OperatingRoomRightTabAnesthesiologist"
import _ from "lodash"
import OperatingRoomRightTabNurse from "./nurse-tab/OperatingRoomRightTabNurse"

class OperatingRoomRightTab extends React.Component {
	EOperatingRoom = {
		GENERAL: "OPERATING_ROOM_GENERAL",
		CALENDAR: "OPERATING_ROOM_CALENDAR",
		NURSE: "OPERATING_ROOM_NURSE",
		SURGEON: "OPERATING_ROOM_SURGEON",
		ANESTHESIA: "OPERATING_ROOM_ANESTHESIA",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EOperatingRoom.CALENDAR,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.selectedRequest.request, this.props.operatingRoom.selectedRequest.request)) {
			if (!this.props.operatingRoom.selectedRequest.request) {
				this.setState({
					tab: this.EOperatingRoom.CALENDAR,
				})
			}
		}
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
						<Nav variant="tabs" justify>
							<Nav.Item>
								<Nav.Link eventKey={this.EOperatingRoom.CALENDAR}>{translate(ResourceAssistance.Message.calendar)}</Nav.Link>
							</Nav.Item>
							{this.props.operatingRoom.selectedRequest.request && (
								<>
									<Nav.Item>
										<Nav.Link eventKey={this.EOperatingRoom.GENERAL}>{translate(ResourceAssistance.Message.general)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EOperatingRoom.NURSE}>{translate(ResourceAssistance.Message.nurse)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EOperatingRoom.SURGEON}>{translate(ResourceAssistance.Message.surgeon)}</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey={this.EOperatingRoom.ANESTHESIA}>{translate(ResourceAssistance.Message.anesthesiologist)}</Nav.Link>
									</Nav.Item>
								</>
							)}
						</Nav>
						<Tab.Content>
							<Tab.Pane eventKey={this.EOperatingRoom.CALENDAR}>
								{this.state.tab === this.EOperatingRoom.CALENDAR && <OperatingRoomRightTabCalendar />}
							</Tab.Pane>
							<Tab.Pane eventKey={this.EOperatingRoom.GENERAL}>{this.state.tab === this.EOperatingRoom.GENERAL && <OperatingRoomRightTabGeneral />}</Tab.Pane>
							<Tab.Pane eventKey={this.EOperatingRoom.NURSE}>{this.state.tab === this.EOperatingRoom.NURSE && <OperatingRoomRightTabNurse />}</Tab.Pane>
							<Tab.Pane eventKey={this.EOperatingRoom.ANESTHESIA}>
								{this.state.tab === this.EOperatingRoom.ANESTHESIA && <OperatingRoomRightTabAnesthesiologist />}
							</Tab.Pane>
						</Tab.Content>
					</Tab.Container>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OperatingRoomRightTab)
