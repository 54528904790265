import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import { ResourceAssistance } from "~/i18n"
import ItemConfig from "~/view/container/admin/itemConfig/ItemConfig"
import Location from "~/view/container/admin/location/Location"
import UserConfig from "~/view/container/admin/userConfig/UserConfig"
import ConfigServiceHome from "~/view/container/admin/configService/ConfigServiceHome"
import ConfigICDHome from "~/view/container/admin/configICD/ConfigICDHome"
import Type from "~/view/container/admin/type/Type"
import Home from "~/view/container/homePage/Home"
import Inventory from "~/view/container/inventory/Inventory"
import PurchaseOrder from "~/view/container/purchase_order/PurchaseOrder"
import PurchaseRequest from "~/view/container/purchase_request/PurchaseRequest"
import Receive from "~/view/container/receive/Receive"
import transfer from "~/view/container/transfer/transfer"
import InPatientDepartment from "~/view/hospitel/ipd/InPatientDepartment"
import Covid19Lab from "~/view/hospitel/lab/Covid19Lab"
import ReportLab from "~/report/hospitel/lab/ReportLab"
import ReportInventory from "~/report/inv/ReportInventory"
import ReportPurchaseOrder from "~/report/po/ReportPurchaseOrder"
import ReportPurchaseRequest from "~/report/pr/ReportPurchaseRequest"
import ReportReceive from "~/report/rc/ReportReceive"
import ReportTransfer from "~/report/tf/ReportTransfer"
import Hospitel from "~/view/hospitel/hospitel/Hospitel"
import Cashier from "~/view/hospitel/cashier/Cashier"
import ReportHospitel from "~/report/hospitel/hospitel/ReportHospitel"
import ReportCashier from "~/report/hospitel/cashier/ReportCashier"
import Radiology from "~/view/HIS/radiology/Radiology"
import CustomDefaultHome from "../admin/customDefault/CustomDefaultHome"
import Deposit from "~/view/HIS/deposit/Deposit"
import Debt from "~/view/accounting/debt/Debt"
import OutPatientDepartment from "~/view/HIS/opd/OutPatientDepartment"
import Pharmacy from "~/view/HIS/pharmacy/Pharmacy"
import FrontInventory from "~/view/HIS/front-inventory/FrontInventory"
import ConfigLaboratoryHome from "../admin/configLaboratory/ConfigLaboratoryHome"
import ContractBilling from "~/view/accounting/contract-billing/ContractBilling"
import AccountsReceivable from "~/view/accounting/accounts-receivable/AccountsReceivable"
import ConfigChartOfAccounts from "../admin/configChartOfAccounts/ConfigChartOfAccounts"
import ConfigGeneralLedger from "../admin/configGeneralLedger/ConfigGeneralLedger"
import AccountsPayable from "~/view/accounting/accounts-payable/AccountsPayable"
import PaymentSlip from "~/view/accounting/payment-slip/PaymentSlip"
import FinancialOperations from "~/view/accounting/financial-operations/FinancialOperations"
import PurchaseInvoice from "~/view/accounting/purchase-invoice/PurchaseInvoice"
import PaymentVoucher from "~/view/accounting/payment-voucher/PaymentVoucher"
import AccountsReceive from "~/view/accounting/accounts-receive/AccountsReceive"
import PostingGL from "~/view/accounting/postingGL/PostingGL"
import OperatingRoom from "~/view/HIS/operating-room/OperatingRoom"
import SurgicalAuthorization from "~/view/HIS/surgical-authorization/SurgicalAuthorization"
import ConfigWorkflowProperty from "../admin/configWorkflowProperty/ConfigWorkflowProperty"

export default class HomeRedirect extends Component {
	render() {
		return (
			<Switch>
				{/* Hospital */}
				<Route exact path={ResourceAssistance.Path.HIS.deposit} component={Deposit} />
				<Route exact path={ResourceAssistance.Path.Hospitel.cashier} component={Cashier} />
				<Route exact path={ResourceAssistance.Path.Hospitel.hospitel} component={Hospitel} />
				<Route exact path={ResourceAssistance.Path.Hospitel.registration} component={InPatientDepartment} />
				<Route exact path={ResourceAssistance.Path.Hospitel.covid19Lab} component={Covid19Lab} />
				<Route exact path={ResourceAssistance.Path.HIS.radiology} component={Radiology} />
				<Route exact path={ResourceAssistance.Path.HIS.opd} component={OutPatientDepartment} />
				<Route exact path={ResourceAssistance.Path.HIS.pharmacy} component={Pharmacy} />
				<Route exact path={ResourceAssistance.Path.HIS.frontInventory} component={FrontInventory} />
				<Route exact path={ResourceAssistance.Path.HIS.operatingRoom} component={OperatingRoom} />
				<Route exact path={ResourceAssistance.Path.HIS.surgicalAuthorization} component={SurgicalAuthorization} />

				{/* Stock & Purchasing */}
				<Route exact path={ResourceAssistance.Path.home} component={Home} />
				<Route exact path={ResourceAssistance.Path.inventory} component={Inventory} />
				<Route exact path={ResourceAssistance.Path.transfer} component={transfer} />
				<Route exact path={ResourceAssistance.Path.pr} component={PurchaseRequest} />
				<Route exact path={ResourceAssistance.Path.po} render={(props) => <PurchaseOrder {...props} />} />
				<Route exact path={ResourceAssistance.Path.rc} component={Receive} />

				{/* Accounting */}
				<Route exact path={ResourceAssistance.Path.ACC.accountsPayable} component={AccountsPayable} />
				<Route exact path={ResourceAssistance.Path.ACC.accountsReceive} component={AccountsReceive} />
				<Route exact path={ResourceAssistance.Path.ACC.accountsReceivable} component={AccountsReceivable} />
				<Route exact path={ResourceAssistance.Path.ACC.contractBilling} component={ContractBilling} />
				<Route exact path={ResourceAssistance.Path.ACC.debt} component={Debt} />
				<Route exact path={ResourceAssistance.Path.ACC.financialOperations} component={FinancialOperations} />
				<Route exact path={ResourceAssistance.Path.ACC.paymentSlip} component={PaymentSlip} />
				<Route exact path={ResourceAssistance.Path.ACC.paymentVoucher} component={PaymentVoucher} />
				<Route exact path={ResourceAssistance.Path.ACC.purchaseInvoice} component={PurchaseInvoice} />
				<Route exact path={ResourceAssistance.Path.ACC.postingGL} component={PostingGL} />

				{/* Admin */}
				<Route exact path={ResourceAssistance.Path.configType} component={Type} />
				<Route exact path={ResourceAssistance.Path.configLoc} component={Location} />
				<Route exact path={ResourceAssistance.Path.configItem} component={ItemConfig} />
				<Route exact path={ResourceAssistance.Path.configUser} component={UserConfig} />
				<Route exact path={ResourceAssistance.Path.configService} component={ConfigServiceHome} />
				<Route exact path={ResourceAssistance.Path.configCustomDefault} component={CustomDefaultHome} />
				<Route exact path={ResourceAssistance.Path.configICD} component={ConfigICDHome} />
				<Route exact path={ResourceAssistance.Path.configLaboratory} component={ConfigLaboratoryHome} />
				<Route exact path={ResourceAssistance.Path.configCOA} component={ConfigChartOfAccounts} />
				<Route exact path={ResourceAssistance.Path.configGL} component={ConfigGeneralLedger} />
				<Route exact path={ResourceAssistance.Path.configWP} component={ConfigWorkflowProperty} />

				{/* Report */}
				<Route exact path={ResourceAssistance.Path.reportInv} component={ReportInventory} />
				<Route exact path={ResourceAssistance.Path.reportTf} component={ReportTransfer} />
				<Route exact path={ResourceAssistance.Path.reportPr} component={ReportPurchaseRequest} />
				<Route exact path={ResourceAssistance.Path.reportPo} component={ReportPurchaseOrder} />
				<Route exact path={ResourceAssistance.Path.reportRc} component={ReportReceive} />
				<Route exact path={ResourceAssistance.Path.Hospitel.reportCashier} component={ReportCashier} />
				<Route exact path={ResourceAssistance.Path.Hospitel.reportHospitel} component={ReportHospitel} />
				<Route exact path={ResourceAssistance.Path.Hospitel.reportLab} component={ReportLab} />
			</Switch>
		)
	}
}
