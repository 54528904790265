import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_HOSPITEL_IPD_CERTIFICATES,
	SET_HOSPITEL_IPD_PATIENTS,
	SET_HOSPITEL_IPD_PATIENT_LABS,
	SET_REGISTER_ADMISSIONS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_BUDGETS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_ITEMS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_PENDINGS,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setIPDPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients
			.filter(
				(patient) =>
					String(patient.id).includes(getState().hospitel.ipd.searchPatientId.trim().toLowerCase()) &&
					String(patient.identificationNumber).includes(getState().hospitel.ipd.searchId.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let color = []

		let body = filtered.map((patient, index) => {
			return [
				index + 1,
				Utils.formatDate(patient.creationDateTime),
				Utils.formatTime(patient.creationDateTime),
				patient.id,
				patient.identificationNumber,
			]
		})
		dispatch({
			type: SET_HOSPITEL_IPD_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
				rowColor: color,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDPatientLabs = (labs) => {
	return (dispatch, getState) => {
		let filtered = labs.filter((lab) => lab.approvedBy).sort((a, b) => Utils.sort(b.issuedDateTime, a.issuedDateTime))

		let rowColor = []
		rowColor = filtered.map((lab) => {
			let negative = ResourceAssistance.CSS.Color.green
			let positive = ResourceAssistance.CSS.Color.warning
			let color = ""
			if (
				lab.detail.result === ResourceAssistance.CONSTANT.POSITIVE ||
				lab.detail.result === ResourceAssistance.CONSTANT.DETECTED
			) {
				color = positive
			} else if (
				lab.detail.result === ResourceAssistance.CONSTANT.NEGATIVE ||
				lab.detail.result === ResourceAssistance.CONSTANT.NOT_DETECTED
			) {
				color = negative
			}

			return [true, color]
		})

		let body = filtered.map((lab) => {
			return [
				Utils.formatDate(lab.issuedDateTime),
				Utils.formatTime(lab.issuedDateTime),
				lab.id,
				lab.detail.analyte,
				lab.detail.result,
				lab.status,
			]
		})
		dispatch({
			type: SET_HOSPITEL_IPD_PATIENT_LABS,
			payload: {
				original: labs,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setIPDCertificates = (certificates) => {
	return (dispatch, getState) => {
		let filtered = certificates.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = []

		rowColor = filtered.map((certificate) => {
			let color = ""
			if (certificate.admission && !_.isEmpty(certificate.doctorName)) {
				color = ResourceAssistance.CSS.Color.green
			} else if (certificate.admission) {
				color = ResourceAssistance.CSS.Color.warning
			}

			return [true, color]
		})

		let body = filtered.map((certificate) => {
			return [
				Utils.formatDate(certificate.creationDateTime),
				Utils.formatTime(certificate.creationDateTime),
				certificate.symptoms,
				certificate.diagnosis,
				certificate.doctorOpinion,
				certificate.doctorName,
				certificate.doctorLicense,
				certificate.createdBy.displayName,
			]
		})
		dispatch({
			type: SET_HOSPITEL_IPD_CERTIFICATES,
			payload: {
				original: certificates,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setRegisterAdmissions = (admissions) => {
	return (dispatch, getState) => {
		let filtered = admissions
			.filter((admission) => admission.clinicalRecord)
			.sort((a, b) => Utils.sort(b.clinicalRecord.creationDateTime, a.clinicalRecord.creationDateTime))

		let color = filtered.map((admission) => {
			return [true, admission.dischargedDateTime ? ResourceAssistance.CSS.Color.green : ""]
		})

		let body = filtered.map((admission) => {
			return [
				Utils.formatDate(admission.clinicalRecord.creationDateTime),
				Utils.formatTime(admission.clinicalRecord.creationDateTime),
			]
		})
		dispatch({
			type: SET_REGISTER_ADMISSIONS,
			payload: {
				original: admissions,
				filtered: filtered,
				body: body,
				rowColor: color,
			},
		})
		return Promise.resolve()
	}
}

export const setRegModalExpenseEstPendings = (pendings) => {
	return (dispatch, getState) => {
		if (
			!getState().hospitel.ipd.modalExpenseEst.filterDoctor &&
			!getState().hospitel.ipd.modalExpenseEst.filterNurse &&
			!getState().hospitel.ipd.modalExpenseEst.filterCoordinator &&
			!getState().hospitel.ipd.modalExpenseEst.filterService &&
			!getState().hospitel.ipd.modalExpenseEst.filterMedicine &&
			!getState().hospitel.ipd.modalExpenseEst.filterMedicalSupply
		) {
			var filtered = pendings.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
		} else {
			filtered = []
			if (getState().hospitel.ipd.modalExpenseEst.filterDoctor) {
				filtered = filtered.concat(pendings.filter((each) => each.duration !== undefined && each.id))
			}
			if (getState().hospitel.ipd.modalExpenseEst.filterNurse) {
				filtered = filtered.concat(pendings.filter((each) => each.duration === undefined && each.id))
			}
			if (getState().hospitel.ipd.modalExpenseEst.filterCoordinator) {
				filtered = filtered.concat(pendings.filter((each) => each.id === undefined))
			}

			let tempFiltered = []
			if (getState().hospitel.ipd.modalExpenseEst.filterService) {
				tempFiltered =
					_.isEmpty(filtered) &&
					!getState().hospitel.ipd.modalExpenseEst.filterDoctor &&
					!getState().hospitel.ipd.modalExpenseEst.filterNurse &&
					!getState().hospitel.ipd.modalExpenseEst.filterCoordinator
						? tempFiltered.concat(pendings.filter((each) => each.serviceCode))
						: tempFiltered.concat(filtered.filter((each) => each.serviceCode))
			}
			if (getState().hospitel.ipd.modalExpenseEst.filterMedicine) {
				tempFiltered =
					_.isEmpty(filtered) &&
					!getState().hospitel.ipd.modalExpenseEst.filterDoctor &&
					!getState().hospitel.ipd.modalExpenseEst.filterNurse &&
					!getState().hospitel.ipd.modalExpenseEst.filterCoordinator
						? tempFiltered.concat(pendings.filter((each) => each.code && each.duration !== undefined))
						: tempFiltered.concat(filtered.filter((each) => each.code && each.duration !== undefined))
			}
			if (getState().hospitel.ipd.modalExpenseEst.filterMedicalSupply) {
				tempFiltered =
					_.isEmpty(filtered) &&
					!getState().hospitel.ipd.modalExpenseEst.filterDoctor &&
					!getState().hospitel.ipd.modalExpenseEst.filterNurse &&
					!getState().hospitel.ipd.modalExpenseEst.filterCoordinator
						? tempFiltered.concat(pendings.filter((each) => each.code && each.duration === undefined))
						: tempFiltered.concat(filtered.filter((each) => each.code && each.duration === undefined))
			}

			if (
				getState().hospitel.ipd.modalExpenseEst.filterService ||
				getState().hospitel.ipd.modalExpenseEst.filterMedicine ||
				getState().hospitel.ipd.modalExpenseEst.filterMedicalSupply
			) {
				filtered = tempFiltered
			}
			filtered = filtered.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
		}

		let color = filtered.map((order) => {
			return [true, order.id ? ResourceAssistance.CSS.Color.green : ""]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		let body = filtered.map((order) => {
			return [
				Utils.formatDate(order.creationDateTime),
				Utils.formatTime(order.creationDateTime),
				order.code || order.serviceCode,
				order.fsCode,
				order.description,
				order.notes,
				order.code && order.duration !== undefined
					? Utils.formatDateTime(order.startDateTime)
					: Utils.formatDate(order.startDateTime),
				order.code && order.duration !== undefined
					? Utils.formatDateTime(order.endDateTime)
					: Utils.formatDate(order.endDateTime),
				Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).toFixed(2)),
				order.duration !== undefined
					? Utils.formatNumWithComma(Utils.calculateDoctorOrderBalance(order))
					: Utils.formatNumWithComma(Utils.calculateNurseOrderBalance(order)),
			]
		})

		dispatch({
			type: SET_REGISTRATION_MODAL_EXPENSE_EST_PENDINGS,
			payload: {
				original: pendings,
				filtered: filtered,
				body: body,
				rowColor: color,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setRegModalExpenseEstItems = (items) => {
	return (dispatch, getState) => {
		let filtered = Utils.getItemRelpsFrom(items)
			.filter(
				(itemRelp) =>
					getState().hospitel.ipd.modalExpenseEst.selectedType.type &&
					getState().hospitel.ipd.modalExpenseEst.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospitel.ipd.modalExpenseEst.searchDescription.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospitel.ipd.modalExpenseEst.searchDescription).toLowerCase()) ||
						Utils.trim(itemRelp.brand)
							.toLowerCase()
							.includes(Utils.trim(getState().hospitel.ipd.modalExpenseEst.searchDescription).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_REGISTRATION_MODAL_EXPENSE_EST_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRegModalExpenseEstServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter((service) =>
				service.name
					.trim()
					.toLowerCase()
					.includes(getState().hospitel.ipd.modalExpenseEst.searchDescription.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((each) => {
			return [each.name, each.description]
		})

		dispatch({
			type: SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setRegModalExpenseEstBudgets = (budgets) => {
	return (dispatch, getState) => {
		let filtered = budgets

		let body = filtered.map((each) => {
			return [
				each.code,
				each.name,
				each.description,
				Utils.formatNumWithComma(Utils.BigNumber(each.budget).toFixed(2)),
				each.contact,
				each.note,
				true,
			]
		})

		let colStyle = filtered.map((each) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_REGISTRATION_MODAL_EXPENSE_EST_BUDGETS,
			payload: {
				original: budgets,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
