import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import ORModalPostAneServiceBtn from "./ORModalPostAneServiceBtn"
import ORModalPostAneServiceFilter from "./ORModalPostAneServiceFilter"
import ORModalPostAneServiceService from "./ORModalPostAneServiceService"
import ORModalPostAneServicePending from "./ORModalPostAneServicePending"

class ORModalPostAneServiceDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<ORModalPostAneServiceFilter />
				<ORModalPostAneServiceService />
				<ORModalPostAneServiceBtn />
				<ORModalPostAneServicePending />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneServiceDetails)
