import React from "react"
import { Button, Col, Container, FormControl, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPoOrders, setRejectedReason } from "~/redux/action"
import { SET_PO_REJECT_REASON_DISPLAY } from "~/redux/type/type/MODAL"
import { SET_PO_LOADING } from "~/redux/type/type/PO"

class PORejectReason extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORejectReason",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onTextAreaChange = this.onTextAreaChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay && !this.props.isDisplay) {
			this.props.setRejectedReason("")
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_PO_REJECT_REASON_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	onDone() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.po.rejectPr,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.selectedPo.po.id,
				reason: this.props.reason,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let original = this.props.order.original
			this.props.setPoOrders(original.filter((order) => order.id !== this.props.selectedPo.po.id))
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PO_LOADING, false)
		}
		let reqInterceptor = (request) => {
			this.props.setLoadingAction(SET_PO_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PO_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onTextAreaChange(event) {
		this.props.setRejectedReason(event.target.value)
	}

	render() {
		return (
			<div id={this.state.componentName}>
				<Modal
					centered
					animation={false}
					show={this.props.isDisplay}
					size={ResourceAssistance.Modal.size.lg}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					onHide={this.onClose}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>{translate(ResourceAssistance.Message.rejectReason)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container>
							<Row>
								<Col>
									<FormControl
										as={ResourceAssistance.FormControl.as.textArea}
										rows="10"
										onChange={this.onTextAreaChange}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.red} onClick={this.onDone}>
							{translate(ResourceAssistance.Message.continue)}
						</Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPORejectDisplay,
	login: state.login,
	order: state.purchaseOrder.order,
	selectedPo: state.purchaseOrder.selectedPo,
	reason: state.purchaseOrder.rejectedReason,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setRejectedReason, setLoadingAction, setPoOrders }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORejectReason)
