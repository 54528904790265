import { TreeSelect } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_LOCATION_ORGS,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT,
	SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG,
	SET_IPD_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalLocationIPD extends React.Component {
	constructor(props) {
		super(props)

		this.onLocation = this.onLocation.bind(this)
	}

	componentDidMount() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.ipd.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_HOSPITEL_MODAL_LOCATION_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_IPD_MODAL_LOCATION_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_IPD_MODAL_LOCATION_ORGS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_IPD_MODAL_LOCATION_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_MODAL_LOCATION_ORGS, [])
	}

	render() {
		return (
			<Row>
				<Col>
					<TreeSelect
						loading={this.props.ipd.modalLocation.isOrgsLoading}
						treeLine={{ showLeafIcon: false }}
						style={{
							textAlign: "center",
						}}
						treeExpandAction="click"
						placeholder={translate(ResourceAssistance.Message.department)}
						treeData={Utils.getTreeNodesFromOrg(this.props.ipd.modalLocation.orgs, false)}
						onSelect={this.onLocation}
					/>
				</Col>
			</Row>
		)
	}
	onLocation(value, node) {
		this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG, node.extra.org.index, node.extra.org.org)
		this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH, node.extra.branch.index, node.extra.branch.branch)
		this.props.setSelected(SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT, node.extra.department.index, node.extra.department.department)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalLocationIPD)
