import { Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAccountsReceivableInvoices, setLoadingAction, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_AR_GL_DATE_TIME, SET_AR_SEARCH_DISPLAY, SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class AccountsReceivableLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearchDebt = this.onSearchDebt.bind(this)
		this.onGeneralLedgerDateTime = this.onGeneralLedgerDateTime.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsReceivable.approveInvoice)) {
				this.approveInvoice()
			}
		}
		if (!_.isEqual(prevProps.warning.isClose, this.props.warning.isClose)) {
			if (this.props.warning.isClose) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsReceivable.approveInvoice)) {
					this.props.setValue(SET_AR_GL_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
				}
			}
		}
	}

	isCompleteDisabled() {
		return (
			!this.props.AR.selectedInvoice.invoice ||
			!this.props.AR.selectedInvoice.invoice.accountsReceivableInspected ||
			this.props.AR.selectedInvoice.invoice.accountsReceivableComplete ||
			this.props.AR.selectedInvoice.invoice.billing.billingStatements
				.reduce((total, cur) => {
					return total.plus(cur.charge).minus(cur.adjustment)
				}, Utils.BigNumber(0))
				.minus(
					this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.reduce((total, cur) => {
						return total.plus(cur.adjustments)
					}, Utils.BigNumber(0))
				)
				.isLessThan(0) ||
			!this.props.AR.billingStatementGLGroupTable.body
				.reduce((total, cur) => {
					return total.plus(Utils.formatNumberFromStr(cur[4]))
				}, Utils.BigNumber(0))
				.eq(
					this.props.AR.billingStatementGLGroupTable.body.reduce((total, cur) => {
						return total.plus(Utils.formatNumberFromStr(cur[5]))
					}, Utils.BigNumber(0))
				) ||
			this.props.AR.selectedInvoice.invoice.billing.billingStatements.some((each) => _.isEmpty(each.accountingCode))
		)
	}

	approveInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsReceivable.completeAccountsReceivable,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.AR.location.id,
				invoiceId: this.props.AR.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
				invoices: [
					{
						id: this.props.AR.selectedInvoice.invoice.id,
						accountsReceivableComplete: true,
					},
				],
				generalLedgers: this.props.AR.billingStatementGLGroupTable.body.map((each, idx) => {
					return {
						dateTime: this.props.AR.generalLedgerDateTime,
						documentCode: ResourceAssistance.Enum.accountsReceivableDocumentCode,
						documentId: this.props.AR.selectedInvoice.invoice.id,
						code: each[0],
						name: each[1],
						category: each[2],
						transactionDescription: each[3],
						debit: Utils.formatNumberFromStr(each[4]).toFixed(2),
						credit: Utils.formatNumberFromStr(each[5]).toFixed(2),
						workflow: ResourceAssistance.Enum.accountsReceivable,
						systemGenerated: this.props.AR.billingStatementGLGroupTable.rowColor[idx][0],
					}
				}),
			},
		}
		let callback = (res) => {
			let invoices = this.props.AR.invoiceTable.original.filter((each) => each.id !== res.data.invoices[0].id)
			invoices.push(res.data.invoices[0])
			this.props.setAccountsReceivableInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onComplete() {
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.accountsReceivable.approveInvoice)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.visitId)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.id : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.id : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.displayName : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? Utils.formatDate(this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.dobtime) : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.identificationNumber : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.code : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCarePlan)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.name : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.insuranceCompany)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.insuranceCompany : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.discountBill)} span={1}>
						{this.props.AR.selectedInvoice.invoice
							? Utils.formatNumWithComma(
									this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments
										.reduce((total, cur) => {
											return total.plus(cur.adjustments)
										}, Utils.BigNumber(0))
										.toFixed(2)
							  )
							: ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{this.props.AR.selectedInvoice.invoice
							? this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.reduce((remark, cur) => {
									return remark.concat(cur.discountDescription, "\n")
							  }, "")
							: ""}
					</Descriptions.Item>
					<Descriptions.Item labelStyle={{ alignItems: "center" }} label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
						<AntDatePicker
							allowClear={false}
							size={"middle"}
							format={"DD-MM-YYYY"}
							defaultValues={[moment(this.props.AR.generalLedgerDateTime)]}
							onChange={this.onGeneralLedgerDateTime}
						/>
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSearchDebt(event) {
		this.props.onModalDisplayAction(SET_AR_SEARCH_DISPLAY, true)
	}

	onGeneralLedgerDateTime(value) {
		this.props.setValue(SET_AR_GL_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.green}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isCompleteDisabled()}
						onClick={this.onComplete}
					>
						<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.complete)}
					</Button>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearchDebt}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAccountsReceivableInvoices,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceivableLeftAction))
