import { Checkbox } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ADM_SERVICE_NEW_SERVICE_DURATION, SET_ADM_SERVICE_NEW_SERVICE_FIX_AMOUNT } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class ConfigServiceModalNSTreatment extends React.Component {
	constructor(props) {
		super(props)

		this.onFixAmount = this.onFixAmount.bind(this)
		this.onDuration = this.onDuration.bind(this)
	}

	componentDidMount() {
		let service = this.props.service.selectedService.service
		if (service) {
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_FIX_AMOUNT, service.fixAmount)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_DURATION, service.treatmentTime)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_FIX_AMOUNT, true)
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_DURATION, "")
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.treatment)}</legend>
				<Container fluid="small">
					<Row>
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.service.modalNewService.isFixAmount} onChange={this.onFixAmount}>
								{translate(ResourceAssistance.Message.fixAmount)}
							</Checkbox>
						</Col>
						<Col>
							<GInput
								size={"small"}
								styles={{ input: { textAlign: "center" } }}
								placeholder={translate(ResourceAssistance.Message.duration)}
								suffix={ResourceAssistance.CONSTANT.MINUTE}
								disabled={this.props.service.modalNewService.isFixAmount}
								pattern="^\d+$"
								value={this.props.service.modalNewService.duration}
								onChange={this.onDuration}
							/>
						</Col>
					</Row>
				</Container>
			</fieldset>
		)
	}

	onDuration(e) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_DURATION, e.target.value)
	}

	onFixAmount(e) {
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_FIX_AMOUNT, e.target.checked)
	}
}

const mapStateToProps = (state) => ({
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigServiceModalNSTreatment))
