import _ from "lodash"
import React, { Fragment } from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import SearchableCriteria from "~/view/component/search/SearchableCriteria"
import OPDModalAppointmentDate from "./OPDModalAppointmentDate"
import OPDModalAppointmentResult from "./OPDModalAppointmentResult"

class OPDModalAppointmentDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onChange = this.onChange.bind(this)
	}

	onChange(event) {
		this.props.setSearchableState(event.target.name, event.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{_.isEmpty(this.props.opd.modalAppointment.patientTable.original) ? (
					<SearchableCriteria {...this.props} onChange={this.onChange} />
				) : (
					<Fragment>
						<OPDModalAppointmentDate />
						<OPDModalAppointmentResult />
					</Fragment>
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalAppointmentDetails)
