import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFORequests, setLoadingAction } from "~/redux/action"
import { SET_FO_EDIT_REQUEST_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import FOModalEditRequestDetails from "./FOModalEditRequestDetails"

class FOModalEditRequest extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_FO_EDIT_REQUEST_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (_.isEmpty(this.props.FO.modalEditRequest.fee)) {
			return true
		}
		let target = Object.assign({}, this.props.FO.selectedFinancialPlan.financialPlan, {
			fee: Utils.trim(this.props.FO.modalEditRequest.fee),
		})
		return _.isEqual(this.props.FO.selectedFinancialPlan.financialPlan, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let fee = Utils.BigNumber(this.props.FO.modalEditRequest.fee)
		let averageFee = fee.dividedBy(this.props.FO.selectedFinancialPlan.financialPlan.paymentPlans.length)
		let total = Utils.BigNumber(0)
		let paymentPlans = this.props.FO.selectedFinancialPlan.financialPlan.paymentPlans.map((each, idx) => {
			let ppFee = averageFee.toFixed(2)
			if (total.gte(fee)) {
				ppFee = Utils.BigNumber(0)
			} else {
				if (averageFee.gt(each.amount)) {
					ppFee = Utils.BigNumber(each.amount).toFixed(2)
				}
				if (idx === this.props.FO.selectedFinancialPlan.financialPlan.paymentPlans.length - 1) {
					ppFee = fee.minus(total).toFixed(2)
				}
				total = total.plus(ppFee)
			}
			return {
				id: each.id,
				fee: ppFee,
			}
		})

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.financialOperations.updateFinancialRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				financialRequestId: this.props.FO.selectedFinancialPlan.financialPlan.id,
				orgId: this.props.FO.location.id,
				userId: this.props.login.user.id,
				requests: [
					{
						id: this.props.FO.selectedFinancialPlan.financialPlan.id,
						fee: Utils.BigNumber(this.props.FO.modalEditRequest.fee).isNaN()
							? Utils.BigNumber(0)
							: Utils.BigNumber(this.props.FO.modalEditRequest.fee),
						paymentPlans: paymentPlans,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.FO.financialPlanTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setFORequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.edit)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FOModalEditRequestDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isFinancialOperationsEditRequestDisplay,
	login: state.login,
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setFORequests,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FOModalEditRequest)
