import Chart from "chart.js/auto"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

class IPDRightPupilSizeChart extends React.Component {
	chart = null

	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightPupilSizeChart",
		}

		this.chartRef = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel)) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.buildChart()
			} else {
				if (this.chart) {
					this.chart.destroy()
				}
			}
		}
	}

	componentDidMount() {
		this.buildChart()
	}

	buildChart() {
		const ctx = this.chartRef.current.getContext("2d")
		this.chartRef.current.style.maxHeight = "168px"
		this.chartRef.current.style.maxWidth = this.chartRef.current.clientWidth + "px"

		if (this.chart) {
			this.chart.destroy()
		}

		let pupilSizes = this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.pupilSizes.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))

		this.chart = new Chart(ctx, {
			type: "line",
			data: {
				labels: pupilSizes.map((pupilSize) => {
					return Utils.formatDateTime(Utils.generateDateFromLong(pupilSize.dateTime))
				}),
				datasets: [
					{
						data: pupilSizes.map((pupilSize) => {
							return pupilSize.left
						}),
						label: "L",
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.blue,
						backgroundColor: "white",
						fill: false,
					},
					{
						data: pupilSizes.map((pupilSize) => {
							return pupilSize.right
						}),
						label: "R",
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.red,
						backgroundColor: "white",
						fill: false,
					},
				],
			},
			options: {
				stacked: false,
				responsive: true,
				maintainAspectRatio: false,
				interaction: {
					mode: "index",
					intersect: false,
				},
				scales: {
					y: {
						type: "linear",
						display: true,
						position: "left",
						suggestedMin: 1,
						suggestedMax: 9,
						beginAtZero: true,
						ticks: {
							precision: 1,
							stepSize: 1,
						},
						title: {
							display: true,
							align: "end",
							text: ResourceAssistance.CONSTANT.MM,
							padding: { top: 0, left: 0, right: 0, bottom: 0 },
						},
					},
				},
			},
		})
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<canvas id={this.state.componentName} ref={this.chartRef} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightPupilSizeChart))
