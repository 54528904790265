import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setFrontInventoryLots } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FrontInventoryLot extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "FrontInventoryLot",
		}
	}

	componentDidMount() {
		if (this.props.frontInventory.selectedInventory.inventory) {
			this.props.setFrontInventoryLots(this.props.frontInventory.selectedInventory.inventory.transferOrderItems)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.frontInventory.selectedInventory, this.props.frontInventory.selectedInventory) ||
			!_.isEqual(prevProps.frontInventory.lotExpiredDateTime, this.props.frontInventory.lotExpiredDateTime) ||
			!_.isEqual(prevProps.frontInventory.isDisplayExpired, this.props.frontInventory.isDisplayExpired)
		) {
			if (this.props.frontInventory.selectedInventory.inventory) {
				this.props.setFrontInventoryLots(this.props.frontInventory.selectedInventory.inventory.transferOrderItems)
			} else {
				this.props.setFrontInventoryLots([])
			}
		}
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable striped data={this.props.frontInventory.lotTable} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setFrontInventoryLots,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontInventoryLot)
