import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_PGL_FILTERS, SET_PGL_FILTER_POSTING_GL_ID } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class PostingGLLeftFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onChangeFilter = this.onChangeFilter.bind(this)
		this.onPostingGLId = this.onPostingGLId.bind(this)
		this.state = {
			CPostingGLFilters: [
				{
					id: ResourceAssistance.Message.incomplete,
					displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.incomplete }),
				},
			],
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PGL.glDocuments, this.props.PGL.glDocuments)) {
			this.setState({
				CPostingGLFilters: this.state.CPostingGLFilters.concat(
					this.props.PGL.glDocuments.map((doc) => {
						return {
							id: doc.code,
							displayName: doc.code,
						}
					})
				).sort((a, b) => Utils.sort(a.displayName, b.displayName)),
			})
		}
		if (!_.isEqual(prevProps.language.locale, this.props.language.locale)) {
			this.setState({
				CPostingGLFilters: [
					{
						id: ResourceAssistance.Message.incomplete,
						displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.incomplete }),
					},
				]
					.concat(
						this.props.PGL.glDocuments.map((doc) => {
							return {
								id: doc.code,
								displayName: doc.code,
							}
						})
					)
					.sort((a, b) => Utils.sort(a.displayName, b.displayName)),
			})
		}
	}

	getFilters() {
		let filters = []
		this.props.PGL.filters.forEach((each) => {
			this.state.CPostingGLFilters.forEach((filter, idx) => {
				if (filter.id === each) {
					filters.push(idx)
				}
			})
		})
		return filters
	}

	onChangeFilter(values) {
		let filters = values.map((each) => this.state.CPostingGLFilters[each].id)
		this.props.setValue(SET_PGL_FILTERS, filters)
	}

	onPostingGLId(event) {
		this.props.setValue(SET_PGL_FILTER_POSTING_GL_ID, event.target.value)
	}

	render() {
		return (
			<Row className="g-2">
				<Col md="auto">
					<Select
						mode={ResourceAssistance.Select.mode.multiple}
						allowClear
						style={{ flexGrow: 1, textAlign: "center", width: "150px" }}
						maxTagCount="responsive"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.filter)}
						onChange={this.onChangeFilter}
						value={this.getFilters()}
					>
						{Utils.renderSelects(this.state.CPostingGLFilters, false)}
					</Select>
				</Col>
				<Col>
					<SearchBox
						num={1}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number]}
						placeholders={[ResourceAssistance.Message.id]}
						callbacks={[this.onPostingGLId]}
						values={[this.props.PGL.filterPostingGLId]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	language: state.language,
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PostingGLLeftFilter))
