export const SET_CONTRACT_BILLING_BILLINGS = "SET_CONTRACT_BILLING_BILLINGS"
export const SET_CONTRACT_BILLING_END_DATE_TIME = "SET_CONTRACT_BILLING_END_DATE_TIME"
export const SET_CONTRACT_BILLING_FILTER_BILLING_ID = "SET_CONTRACT_BILLING_FILTER_BILLING_ID"
export const SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN = "SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN"
export const SET_CONTRACT_BILLING_INFO_DISPLAY = "SET_CONTRACT_BILLING_INFO_DISPLAY"
export const SET_CONTRACT_BILLING_INVOICES = "SET_CONTRACT_BILLING_INVOICES"
export const SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY = "SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY"
export const SET_CONTRACT_BILLING_LOCATION = "SET_CONTRACT_BILLING_LOCATION"
export const SET_CONTRACT_BILLING_LOCATION_DISPLAY = "SET_CONTRACT_BILLING_LOCATION_DISPLAY"
export const SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE = "SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE"
export const SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA = "SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA"
export const SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS = "SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS"
export const SET_CONTRACT_BILLING_MODAL_INFO_TEL = "SET_CONTRACT_BILLING_MODAL_INFO_TEL"
export const SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM = "SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM"
export const SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS = "SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS"
export const SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF = "SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF"
export const SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS = "SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS"
export const SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS_LOADING = "SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS_LOADING"
export const SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG = "SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG"
export const SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID = "SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID"
export const SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME = "SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME"
export const SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS = "SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS"
export const SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING = "SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING"
export const SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES = "SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES"
export const SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING = "SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING"
export const SET_CONTRACT_BILLING_MODAL_NEW_INVOICES = "SET_CONTRACT_BILLING_MODAL_NEW_INVOICES"
export const SET_CONTRACT_BILLING_MODAL_NEW_PENDING_INVOICES = "SET_CONTRACT_BILLING_MODAL_NEW_PENDING_INVOICES"
export const SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN = "SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN"
export const SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY = "SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY"
export const SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE = "SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE"
export const SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_PENDING_INVOICE = "SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_PENDING_INVOICE"
export const SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID = "SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID"
export const SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY = "SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY"
export const SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME = "SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME"
export const SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME = "SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME"
export const SET_CONTRACT_BILLING_NEW_DISPLAY = "SET_CONTRACT_BILLING_NEW_DISPLAY"
export const SET_CONTRACT_BILLING_SEARCH_DISPLAY = "SET_CONTRACT_BILLING_SEARCH_DISPLAY"
export const SET_CONTRACT_BILLING_SELECTED_BILLING = "SET_CONTRACT_BILLING_SELECTED_BILLING"
export const SET_CONTRACT_BILLING_SELECTED_INVOICE = "SET_CONTRACT_BILLING_SELECTED_INVOICE"
export const SET_CONTRACT_BILLING_START_DATE_TIME = "SET_CONTRACT_BILLING_START_DATE_TIME"
export const SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES = "SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES"
export const SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING = "SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING"
