import { Checkbox, message, Upload } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap"
import { GrFormUpload, GrRotateLeft } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION, SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL, SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class DocumentModalUploadDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDisplayPatient = this.onDisplayPatient.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onBeforeUpload = this.onBeforeUpload.bind(this)
		this.onPreview = this.onPreview.bind(this)
		this.onClose = this.onClose.bind(this)
		this.state = {
			file: undefined,
			isVisible: false,
		}
	}

	componentDidMount() {
		if (this.props.document.selectedDocument.document) {
			this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION, this.props.document.selectedDocument.document.description)
			this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL, this.props.document.selectedDocument.document.displayPatientLabel)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL, false)
		this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION, "")
		this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST, [])
	}

	render() {
		return (
			<>
				<Container fluid className={"full-flex space-top-each-row-parent"}>
					{!this.props.document.selectedDocument.document && (
						<Row>
							<Col>
								<Upload
									accept={"application/pdf"}
									fileList={this.props.document.modalUpload.fileList}
									listType="text"
									showUploadList={{
										showRemoveIcon: false,
										showPreviewIcon: true,
									}}
									beforeUpload={this.onBeforeUpload}
									onPreview={this.onPreview}
									onChange={this.onUploadChange}
								>
									<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary}>
										<GrFormUpload size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.upload)}
									</Button>
								</Upload>
							</Col>
						</Row>
					)}
					<Row>
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.document.modalUpload.isDisplayPatient} onChange={this.onDisplayPatient}>
								{translate(ResourceAssistance.Message.label)}
							</Checkbox>
						</Col>
					</Row>
					<Row>
						<Col>
							<GInput
								autoSize
								required
								minRows={3}
								placeholder={translate(ResourceAssistance.Message.description)}
								value={this.props.document.modalUpload.description}
								onChange={this.onDescription}
							/>
						</Col>
					</Row>
				</Container>
				<Modal
					centered
					animation={false}
					show={this.state.isVisible}
					dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
					backdrop={ResourceAssistance.Modal.backdrop.static}
					keyboard={ResourceAssistance.Modal.keyboard.false}
					onHide={this.onClose}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}></ModalHeader>
					<ModalBody>
						<Container fluid className={"full-size full-flex"}>
							<Row className={"g-0 full-size"}>
								<Col>
									<iframe id="iframe" title="iframe" className={"full-size"} src={this.state.file} />
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
					</ModalFooter>
				</Modal>
			</>
		)
	}

	onBeforeUpload(file) {
		if (this.props.document.documentTable.filtered.some((each) => _.isEqual(each.fileName.toLocaleString(), file.name.toLocaleString()))) {
			message.error(`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning })}`, 5)
		} else if (!(file.size / 1024 / 1024 < 10)) {
			message.error(`${this.props.intl.formatMessage({ id: ResourceAssistance.Warning.fileMustSmallerThan })} 10 MB!`, 5)
		} else {
			const fileObject = file.originFileObj || file
			if (fileObject instanceof Blob || fileObject instanceof File) {
				const fileURL = URL.createObjectURL(fileObject)
				file.url = fileURL
			}
			this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST, [file])
		}
		return false
	}
	onPreview(file) {
		if (file.url) {
			this.setState({
				file: file.url,
				isVisible: true,
			})
		}
	}

	onClose() {
		this.setState({
			file: undefined,
			isVisible: false,
		})
	}
	onDisplayPatient(e) {
		this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL, e.target.checked)
	}

	onDescription(e) {
		this.props.setValue(SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	document: state.menu.document,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DocumentModalUploadDetails))
