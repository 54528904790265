import { TreeSelect } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_COA_ASSETS_MODAL_ADD_CODE,
	SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION,
	SET_ADM_COA_ASSETS_MODAL_ADD_NAME,
	SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS,
	SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class ConfigCOAModalAddDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onAssets = this.onAssets.bind(this)
		this.onCode = this.onCode.bind(this)
		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onSubLevel = this.onSubLevel.bind(this)
	}

	componentDidMount() {
		if (this.props.assets.selectedAccount.account) {
			let selectedAccount = this.props.assets.selectedAccount.account
			this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_CODE, selectedAccount.code)
			this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_NAME, selectedAccount.displayName)
			this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION, selectedAccount.description)
			this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL, selectedAccount.subLevel)

			if (selectedAccount.parent) {
				let selectedParent = Utils.findChartOfAccounts(this.props.assets.assets, selectedAccount.parent)
				this.props.setSelected(SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS, selectedParent[0], selectedParent[1])
			} else {
				this.props.setSelected(SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_CODE, "")
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_NAME, "")
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION, "")
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL, false)
		this.props.setSelected(SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS, -1, null)
	}

	onAssets(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS,
				value,
				(function getSelectedAsset(assets, idx) {
					if (idx === keys.length - 1) {
						return assets[keys[idx]]
					}
					return getSelectedAsset(assets[keys[idx]].accounts, idx + 1)
				})(this.props.assets.assets, 0)
			)
		} else {
			this.props.setSelected(SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS, value, this.props.assets.assets[value])
		}
	}

	onCode(e) {
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_CODE, e.target.value)
	}

	onName(e) {
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_NAME, e.target.value)
	}

	onDescription(e) {
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION, e.target.value)
	}

	onSubLevel(e) {
		this.props.setValue(SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL, e.target.checked)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							placeholder={translate(ResourceAssistance.Message.assets)}
							value={this.props.assets.modalAdd.selectedAssets.assets ? this.props.assets.modalAdd.selectedAssets.index : undefined}
							onChange={this.onAssets}
							treeData={Utils.getTreeNodesFromChartOfAccounts(
								this.props.assets.assets,
								false,
								this.props.assets.selectedAccount.account ? [this.props.assets.selectedAccount.account.id] : []
							)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.code)}
							value={this.props.assets.modalAdd.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.name)}
							value={this.props.assets.modalAdd.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.assets.modalAdd.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row className="g-0">
					<Col style={{ textAlign: "center" }}>
						<label>{translate(ResourceAssistance.Message.subLevel)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onSubLevel} checked={this.props.assets.modalAdd.isSubLevel} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	assets: state.admin.configCOA.assets,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigCOAModalAddDetails))
