import _ from "lodash"
import React from "react"
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmRoleRoles, setAdmRoleSelectedRole, setLoadingAction } from "~/redux/action"
import { SET_ADM_ROLE_NEW_ROLE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import AdmRoleModalNRInfo from "./AdmRoleModalNRInfo"
import AdmRoleModalNRPermission from "./AdmRoleModalNRPermission"

class AdmRoleModalNR extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmRoleModalNR",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.role.selectedRole.role, {
			...this.props.role.selectedRole.role,
			name: this.props.role.modalNewRole.name.trim(),
			description: this.props.role.modalNewRole.description.trim(),
			active: this.props.role.modalNewRole.isActive,
			permissions: this.props.role.modalNewRole.pendingPermissionTable.original,
		})
		return _.isEmpty(this.props.role.modalNewRole.pendingPermissionTable.original) || _.isEqual(this.props.role.selectedRole.role, target)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_ROLE_NEW_ROLE_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.role.saveRole}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.role.selectedRole.index === -1 ? 0 : this.props.role.selectedRole.role.id,
				name: this.props.role.modalNewRole.name.trim(),
				description: this.props.role.modalNewRole.description.trim(),
				active: this.props.role.modalNewRole.isActive,
				userId: this.props.login.user.id,
				permissions: this.props.role.modalNewRole.pendingPermissionTable.original.map((permission) => {
					return {
						id: permission.id,
					}
				}),
			},
		}

		let callback = (res) => {
			this.props.setAdmRoleSelectedRole(-1, null)
			let roles = this.props.role.roleTable.original.filter((each) => each.id !== res.data.roles[0].id)
			roles.push(res.data.roles[0])
			this.props.setAdmRoleRoles(roles)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_ROLE_NEW_ROLE_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.newRole)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<Row className={`${ResourceAssistance.CSS.fullSize}`}>
								<Col md={3}>
									<AdmRoleModalNRInfo />
								</Col>
								<Col>
									<AdmRoleModalNRPermission />
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmRoleNewRoleDisplay,
	login: state.login,
	role: state.admin.userConfig.role,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setAdmRoleSelectedRole, setAdmRoleRoles }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmRoleModalNR)
