import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaPrescription } from "react-icons/fa"
import { TbDiscount2 } from "react-icons/tb"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setCashierAdmissions,
	setCashierServices,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
	SET_CASHIER_SELECTED_SERVICE,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import ServerUtils from "~/utils/ServerUtils"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import { SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_MAX } from "./../../../../redux/type/type/hospital/CASHIER"

class CashierRightService extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierRightService",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdjustment = this.onAdjustment.bind(this)
		this.onPrescriptionOnly = this.onPrescriptionOnly.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalAdjustment.isContinue, this.props.cashier.modalAdjustment.isContinue)) {
			if (
				this.props.cashier.modalAdjustment.isContinue &&
				_.isEqual(this.props.cashier.modalAdjustment.id, ResourceAssistance.ID.HOSPITEL.cashier.id)
			) {
				this.saveAdjustment()
			}
		}
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.cashier.doctorPrescriptionOnly)) {
					this.saveDoctorPrescriptionOnly(true)
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.cashier.cancelDoctorPrescriptionOnly)) {
					this.saveDoctorPrescriptionOnly(false)
				}
			}
		}

		if (!_.isEqual(prevProps.cashier.selectedHealthCarePlan, this.props.cashier.selectedHealthCarePlan)) {
			if (this.props.cashier.selectedHealthCarePlan.healthCarePlan) {
				this.props.setCashierServices(this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements)
			} else {
				this.props.setCashierServices([])
			}
		}

		if (!_.isEqual(prevProps.cashier.serviceTable.original, this.props.cashier.serviceTable.original)) {
			if (this.props.cashier.selectedService.service) {
				let idx = this.props.cashier.serviceTable.filtered.findIndex(
					(billingStatement) => billingStatement.id === this.props.cashier.selectedService.service.id
				)
				if (idx > -1) {
					this.props.setSelected(SET_CASHIER_SELECTED_SERVICE, idx, this.props.cashier.serviceTable.filtered[idx])
				} else {
					this.props.setSelected(SET_CASHIER_SELECTED_SERVICE, -1, null)
				}
			}
		}
	}

	isDisplayBtnIcon() {
		return (
			this.props.cashier.selectedAdmission.admission && !this.props.cashier.selectedAdmission.admission.dischargedBy
		)
	}

	saveAdjustment() {
		let selectedBillingStatement = this.props.cashier.selectedService.service
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.saveBillingStatementAdjustment,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.cashier.selectedAdmission.admission.id,
				userId: this.props.login.user.id,
				billingStatements: [
					{
						id: selectedBillingStatement.id,
						adjustments: Utils.BigNumber(this.props.cashier.modalAdjustment.discountPercent).isGreaterThan(0)
							? Utils.BigNumber(selectedBillingStatement.charge)
									.times(this.props.cashier.modalAdjustment.discountPercent)
									.dividedBy(100)
									.toFixed(2)
							: this.props.cashier.modalAdjustment.amount,
						adjustmentsDescription: Utils.trim(this.props.cashier.modalAdjustment.description),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter(
					(each) => each.id !== res.data.admissions[0].id
				)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	saveDoctorPrescriptionOnly(isDoctorPrescriptionOnly) {
		let billingStatements = this.props.cashier.selectedAdmission.admission.billingStatements.filter(
			(bs) =>
				bs.doctorOrder && bs.doctorOrder.id === this.props.cashier.selectedService.service.doctorOrder.id && !bs.billing
		)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.saveDoctorOrderPrescriptionOnly,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.cashier.selectedAdmission.admission.id,
				userId: this.props.login.user.id,
				doctorPrescriptionOnly: isDoctorPrescriptionOnly,
				billingStatements: billingStatements,
				doctorOrders: [
					{
						id: this.props.cashier.selectedService.service.doctorOrder.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter(
					(each) => each.id !== res.data.admissions[0].id
				)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(service, index) {
		this.props.setSelected(SET_CASHIER_SELECTED_SERVICE, index, this.props.cashier.serviceTable.filtered[index])
	}

	onAdjustment(event, row, rIdx, cIdx) {
		let balance = Utils.calculateBillingStatementBalance(
			this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
		).minus(
			ServerUtils.calculateBillingPlanAdjustments(
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingPlanAdjustments.filter(
					(bpAdj) => !bpAdj.billing
				)
			)
		)
		let selectedService = this.props.cashier.serviceTable.filtered[rIdx]
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.HOSPITEL.cashier.id)
		this.props.setValue(
			SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_MAX,
			balance.lt(selectedService.charge) ? balance.toFixed(2) : selectedService.charge
		)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY, false)
		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onPrescriptionOnly(event, row, rIdx, cIdx) {
		let selectedService = this.props.cashier.serviceTable.filtered[rIdx]
		if (selectedService.doctorOrder.prescriptionOnly) {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.cashier.cancelDoctorPrescriptionOnly)
			this.props.setWarningMsgAction(
				translate(ResourceAssistance.Warning.theDoctorOrderIsNotPrescriptionOnlyIncludeInBilling)
			)
		} else {
			this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.cashier.doctorPrescriptionOnly)
			this.props.setWarningMsgAction(
				translate(ResourceAssistance.Warning.theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling)
			)
		}
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<fieldset style={{ display: "flex" }}>
				<legend>{translate(ResourceAssistance.Hospitel.service)}</legend>
				<Row className={"full-size"}>
					<Col>
						<ScrollableTable
							enableOverlay
							enableHighlight
							striped
							hover
							displayBtn
							buttonColors={[
								ResourceAssistance.Button.variant.outlinePrimary,
								ResourceAssistance.Button.variant.outlinePrimary,
							]}
							btnIcons={[
								this.isDisplayBtnIcon() ? <TbDiscount2 size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayBtnIcon() ? <FaPrescription size={ResourceAssistance.ReactIcon.size} /> : undefined,
							]}
							btnMessages={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.discount }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.doctorPrescriptionOnly }),
							]}
							onCheckedArray={[this.onAdjustment, this.onPrescriptionOnly]}
							data={this.props.cashier.serviceTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.cashier.selectedService.index === -1}
							highlightedRow={this.props.cashier.selectedService.index}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setCashierAdmissions,
			setCashierServices,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierRightService))
