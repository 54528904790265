import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import HospitelModalDoctorNoteA from "./HospitelModalDoctorNoteA"
import HospitelModalDoctorNoteDate from "./HospitelModalDoctorNoteDate"
import HospitelModalDoctorNoteO from "./HospitelModalDoctorNoteO"
import HospitelModalDoctorNoteP from "./HospitelModalDoctorNoteP"
import HospitelModalDoctorNoteS from "./HospitelModalDoctorNoteS"

class HospitelModalDoctorNoteDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitelModalDoctorNoteDate />
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.subjective)}</legend>
							<HospitelModalDoctorNoteS />
						</fieldset>
					</Col>
				</Row>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.objective)}</legend>
							<HospitelModalDoctorNoteO />
						</fieldset>
					</Col>
				</Row>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.assessment)}</legend>
							<HospitelModalDoctorNoteA />
						</fieldset>
					</Col>
				</Row>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.plan)}</legend>
							<HospitelModalDoctorNoteP />
						</fieldset>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNoteDetails)
