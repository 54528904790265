import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalNurseOrderPendingItems, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalMedicalSupplyPendingItem extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.hospitel.modalNurseOrder.pendingItemTable.original,
				this.props.hospitel.modalNurseOrder.pendingItemTable.original
			)
		) {
			this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setHospitelModalNurseOrderPendingItems([])
		this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM,
			index,
			this.props.hospitel.modalNurseOrder.pendingItemTable.filtered[index]
		)
	}

	render() {
		return (
			<Fragment>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col id={ResourceAssistance.ID.HOSPITEL.hospitel.modalNurseOrder.pendingItemTable}>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectItem}
							data={this.props.hospitel.modalNurseOrder.pendingItemTable}
							isClearHighlight={this.props.hospitel.modalNurseOrder.selectedPendingItem.index === -1}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setHospitelModalNurseOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalMedicalSupplyPendingItem)
