import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setConfigServiceServices, setLoadingAction, setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_ADMIN_SERVICE_DISPLAY_ALL_IND,
	SET_ADMIN_SERVICE_ROLES,
	SET_ADMIN_SERVICE_SEARCH_CODE,
	SET_ADMIN_SERVICE_SEARCH_NAME,
	SET_ADMIN_SERVICE_SELECTED_SERVICE,
	SET_ADMIN_SERVICE_SELECTED_TYPE,
	SET_ADMIN_SERVICE_TYPES,
	SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPES,
	SET_ADM_SERVICE_NEW_SERVICE_DISPLAY,
	SET_ADM_SERVICE_NEW_SERVICE_LAB_TESTS,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigService extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigService",
		}

		this.onType = this.onType.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	async componentDidMount() {
		this.props.loadTypes(
			(types) => {
				this.props.setObjArray(SET_ADMIN_SERVICE_TYPES, types)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
		)

		this.props.loadRoles(
			(roles) => {
				this.props.setObjArray(SET_ADMIN_SERVICE_ROLES, roles)
			},
			() => {},
			() => {},
			() => {}
		)

		await this.props.loadAdpType(
			(adpType) => {
				this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPES, adpType)
			},
			() => {},
			() => {},
			() => {}
		)

		await this.props.loadLabTest(
			(labTest) => {
				this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_LAB_TESTS, labTest)
			},
			() => {},
			() => {},
			() => {}
		)
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_TYPE, -1, null)
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_CODE, "")
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_NAME, "")
		this.props.setValue(SET_ADMIN_SERVICE_DISPLAY_ALL_IND, true)
		this.props.setConfigServiceServices([])
		this.props.setObjArray(SET_ADMIN_SERVICE_TYPES, [])
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPES, [])
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_LAB_TESTS, [])
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.service.types, this.props.service.types)) {
			this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_TYPE, 0, this.props.service.types[0])
			this.props.setConfigServiceServices(this.props.service.types[0].services)
		}

		if (
			prevProps.service.selectedType.index !== this.props.service.selectedType.index ||
			prevProps.service.searchCode !== this.props.service.searchCode ||
			prevProps.service.searchName !== this.props.service.searchName ||
			prevProps.service.displayInactive !== this.props.service.displayInactive
		) {
			this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
			if (this.props.service.selectedType.type) {
				this.props.setConfigServiceServices(this.props.service.selectedType.type.services)
			}
		}
	}

	onType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_TYPE, v, this.props.service.types[v])
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADMIN_SERVICE_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setValue(SET_ADMIN_SERVICE_DISPLAY_ALL_IND, event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, index, this.props.service.serviceTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
		this.props.onModalDisplayAction(SET_ADM_SERVICE_NEW_SERVICE_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_SERVICE_NEW_SERVICE_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.category)}
							value={this.props.service.selectedType.type ? this.props.service.selectedType.index : undefined}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.service.types, false)}
						</Select>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.service.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.service.selectedService.index === -1} onClick={this.onEdit}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.service.selectedService.index === -1}
							data={this.props.service.serviceTable}
							onClick={this.onSelectRow}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setValue,
			setSelected,
			setLoadingAction,
			onModalDisplayAction,
			setConfigServiceServices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigService)
