import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFrontInventoryInventories, setLoadingAction, setValue } from "~/redux/action"
import { SET_FRONT_INVENTORY_EDIT_DISPLAY, SET_FRONT_INVENTORY_INVENTORIES_LOADING } from "~/redux/type"
import FrontInventoryModalEditDetails from "./FrontInventoryModalEditDetails"

class FrontInventoryModalEdit extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "FrontInventoryModalEdit",
		}
		this.editRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_EDIT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.editRef.current) {
			return true
		}
		let target = Object.assign({}, this.props.frontInventory.selectedInventory.inventory, {
			min: Number(this.editRef.current.state.min),
			max: Number(this.editRef.current.state.max),
		})
		return _.isEqual(target, this.props.frontInventory.selectedInventory.inventory)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.frontInventory.updateInventory,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				locationId: this.props.frontInventory.selectedDepartment.department.id,
				userId: this.props.login.user.id,
				pharmacyInventories: [
					{
						id: this.props.frontInventory.selectedInventory.inventory.id,
						min: Number(this.editRef.current.state.min),
						max: Number(this.editRef.current.state.max),
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setFrontInventoryInventories(res.data.pharmacyInventories)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_INVENTORIES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_INVENTORIES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_INVENTORIES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.edit)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FrontInventoryModalEditDetails
							editRef={this.editRef}
							min={
								this.props.frontInventory.selectedInventory.inventory
									? this.props.frontInventory.selectedInventory.inventory.min
									: 0
							}
							max={
								this.props.frontInventory.selectedInventory.inventory
									? this.props.frontInventory.selectedInventory.inventory.max
									: 0
							}
							onRenderParentCallback={this.onRenderParentCallback}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isFrontInventoryEditDisplay,
	login: state.login,
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setFrontInventoryInventories,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontInventoryModalEdit)
