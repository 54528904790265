import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { contextMenu } from "react-contexify"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPharmacyPatients, setSelected } from "~/redux/action"
import { SET_PHARMACY_SELECTED_PAITENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyLeftPatient extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyLeftPatient",
		}
		this.selectRow = this.selectRow.bind(this)
		this.onContextMenu = this.onContextMenu.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.selectedPatient, this.props.pharmacy.selectedPatient)) {
			if (
				(prevProps.pharmacy.selectedPatient.patient &&
					this.props.pharmacy.selectedPatient.patient &&
					prevProps.pharmacy.selectedPatient.patient.id !== this.props.pharmacy.selectedPatient.patient.id) ||
				(!prevProps.pharmacy.selectedPatient.patient && this.props.pharmacy.selectedPatient.patient)
			) {
				Utils.notification(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.notification }),
					this.props.pharmacy.selectedPatient.patient.notifications,
					"warning"
				)
			}
		}
		if (!_.isEqual(prevProps.pharmacy.patientTable.filtered, this.props.pharmacy.patientTable.filtered)) {
			let index = this.props.pharmacy.selectedPatient.patient
				? this.props.pharmacy.patientTable.filtered.findIndex(
						(patient) => patient.id === this.props.pharmacy.selectedPatient.patient.id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PHARMACY_SELECTED_PAITENT, index, this.props.pharmacy.patientTable.filtered[index])
			} else {
				this.props.setSelected(SET_PHARMACY_SELECTED_PAITENT, -1, null)
			}
		}
		if (!_.isEqual(prevProps.pharmacy.filters, this.props.pharmacy.filters)) {
			this.props.setPharmacyPatients(this.props.pharmacy.patientTable.original)
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_PHARMACY_SELECTED_PAITENT, index, this.props.pharmacy.patientTable.filtered[index])
	}

	onContextMenu(row, rIdx, e) {
		contextMenu.show({
			id: ResourceAssistance.ContextMenu.patient,
			event: e,
			props: {
				patient: {
					...this.props.pharmacy.selectedPatient.patient,
					clinicalRecord: {
						...this.props.pharmacy.selectedAdmission.admission.clinicalRecord,
						medicalRecords: this.props.pharmacy.selectedAdmission.admission.clinicalRecord.medicalRecords,
					},
				},
			},
		})
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.pharmacy.patientTable}
						onClick={this.selectRow}
						onContextMenu={this.onContextMenu}
						isClearHighlight={this.props.pharmacy.selectedPatient.index === -1}
						highlightedRow={this.props.pharmacy.selectedPatient.index}
					/>
					{this.props.pharmacy.patientTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPharmacyPatients,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyLeftPatient))
