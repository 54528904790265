import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_LEVEL_MODAL_NEW_ACTIVE,
	SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION,
	SET_ADM_LEVEL_MODAL_NEW_NAME,
} from "~/redux/type"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class ConfigLevelModalNInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		let level = this.props.level.selectedLevel.level
		if (level) {
			this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_NAME, level.displayName)
			this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION, level.description)
			this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_ACTIVE, level.active)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_NAME, "")
		this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION, "")
		this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_ACTIVE, true)
	}

	onName(event) {
		this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_NAME, event.target.value)
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION, event.target.value)
	}

	onActive(event) {
		this.props.setValue(SET_ADM_LEVEL_MODAL_NEW_ACTIVE, event.target.checked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.name)}</label>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.level.modalNewLevel.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.description)}</label>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							rows={5}
							value={this.props.level.modalNewLevel.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.level.modalNewLevel.isActive} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	level: state.admin.configICD.level,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigLevelModalNInfo))
