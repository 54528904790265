import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY } from "~/redux/type"

class CashierModalAdjustmentQty extends React.Component {
	constructor(props) {
		super(props)

		this.onQtyPerDay = this.onQtyPerDay.bind(this)
	}

	onQtyPerDay(event) {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.qty)}</legend>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col md={2} />
					<Col>
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Message.qtyPerDay)}</InputGroup.Text>
							<FormControl
								required
								autoFocus
								type={ResourceAssistance.FormControl.type.number}
								min={0}
								step={1}
								onChange={this.onQtyPerDay}
							/>
						</InputGroup>
					</Col>
					<Col md={2} />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalAdjustmentQty)
