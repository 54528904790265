import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class DebtModalAdjustmentDiscount extends React.Component {
	constructor(props) {
		super(props)

		this.onDiscount = this.onDiscount.bind(this)
		this.onRemark = this.onRemark.bind(this)
		this.onDiscountPercent = this.onDiscountPercent.bind(this)
	}

	onDiscount(event) {
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT, event.target.value)
	}

	onRemark(event) {
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK, event.target.value)
	}

	onDiscountPercent(event) {
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.discount)}</legend>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							placeholder={translate(ResourceAssistance.Message.currencySign)}
							step={0.01}
							min={0}
							max={this.props.debt.modalAdjustment.discountMax}
							disabled={Utils.BigNumber(this.props.debt.modalAdjustment.discountPercent).isGreaterThan(0)}
							value={this.props.debt.modalAdjustment.discount}
							onChange={this.onDiscount}
						/>
					</Col>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							placeholder={"%"}
							step={1}
							min={0}
							max={100}
							disabled={Utils.BigNumber(this.props.debt.modalAdjustment.discount).isGreaterThan(0)}
							value={this.props.debt.modalAdjustment.discountPercent}
							onChange={this.onDiscountPercent}
						/>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.debt.modalAdjustment.remark}
							onChange={this.onRemark}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtModalAdjustmentDiscount)
