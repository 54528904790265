import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setPharmacyAdmissions, setSelected } from "~/redux/action"
import { SET_PHARMACY_SELECTED_ADMISSION } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyLeftAdmission extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyLeftAdmission",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.selectedPatient, this.props.pharmacy.selectedPatient)) {
			if (this.props.pharmacy.selectedPatient.patient) {
				this.props.setPharmacyAdmissions(this.props.pharmacy.selectedPatient.patient.admissions)
			} else {
				this.props.setPharmacyAdmissions([])
			}
		}
		if (!_.isEqual(prevProps.pharmacy.admissionTable.filtered, this.props.pharmacy.admissionTable.filtered)) {
			let idx = this.props.pharmacy.selectedAdmission.admission
				? this.props.pharmacy.admissionTable.filtered.findIndex((admission) => admission.id === this.props.pharmacy.selectedAdmission.admission.id)
				: -1
			if (idx !== -1) {
				this.props.setSelected(SET_PHARMACY_SELECTED_ADMISSION, idx, this.props.pharmacy.admissionTable.filtered[idx])
			} else {
				if (this.props.pharmacy.admissionTable.filtered.length > 0) {
					this.props.setSelected(SET_PHARMACY_SELECTED_ADMISSION, 0, this.props.pharmacy.admissionTable.filtered[0])
				} else {
					this.props.setSelected(SET_PHARMACY_SELECTED_ADMISSION, -1, null)
				}
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_PHARMACY_SELECTED_ADMISSION, index, this.props.pharmacy.admissionTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"} style={{ maxHeight: "250px" }}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.pharmacy.admissionTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.pharmacy.selectedAdmission.index === -1}
						highlightedRow={this.props.pharmacy.selectedAdmission.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPharmacyAdmissions,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyLeftAdmission)
