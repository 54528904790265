import { ResourceAssistance } from "~/i18n"
import { SET_ADM_GL_PAYABLE_ACTIVITIES_TABLE, SET_ADM_GL_RECEIVABLE_ACTIVITIES_TABLE } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setConfigGLReceivableActivities = (activities) => {
	return (dispatch, getState) => {
		let filtered = activities
			.filter((each) => {
				return (
					each.chartOfAccounts.fullCode.toLowerCase().includes(getState().admin.configGL.accountsReceivable.filterCode.toLowerCase()) &&
					each.chartOfAccounts.displayName.toLowerCase().includes(getState().admin.configGL.accountsReceivable.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.chartOfAccounts.fullCode, b.chartOfAccounts.fullCode))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[
					each.includeBalance,
					each.includeDiscount,
					each.includeWithholdingTax,
					each.includeTransactionFee,
					each.includeBalanceAdjustment,
					each.includeIPD,
					each.includeOPD,
					each.debit,
					each.credit,
				],
				each.chartOfAccounts.fullCode,
				each.chartOfAccounts.displayName,
				each.chartOfAccounts.description,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
				[true],
			]
		})
		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [
				[
					[[true, ResourceAssistance.Message.totalPrice]],
					[[true, ResourceAssistance.Message.discount]],
					[[true, ResourceAssistance.Message.withholdingTax]],
					[[true, ResourceAssistance.Message.transactionFee]],
					[[true, ResourceAssistance.Hospitel.ipd]],
					[[true, ResourceAssistance.Message.opd]],
					[[true, ResourceAssistance.Message.debit]],
					[[true, ResourceAssistance.Message.credit]],
				],
			]
		})

		dispatch({
			type: SET_ADM_GL_RECEIVABLE_ACTIVITIES_TABLE,
			payload: {
				original: activities,
				filtered: filtered,
				body: body,
				rowColor: colors,
				popoverMsg: popoverMsg,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setConfigGLPayableActivities = (activities) => {
	return (dispatch, getState) => {
		let filtered = activities
			.filter((each) => {
				return (
					each.chartOfAccounts.fullCode.toLowerCase().includes(getState().admin.configGL.accountsPayable.filterCode.toLowerCase()) &&
					each.chartOfAccounts.displayName.toLowerCase().includes(getState().admin.configGL.accountsPayable.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.chartOfAccounts.fullCode, b.chartOfAccounts.fullCode))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[
					each.includeBalance,
					each.includeDiscount,
					each.includeTax,
					each.includeWithholdingTax,
					each.includeTransactionFee,
					each.includeBalanceAdjustment,
					each.debit,
					each.credit,
				],
				each.chartOfAccounts.fullCode,
				each.chartOfAccounts.displayName,
				each.chartOfAccounts.description,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
				[true],
			]
		})
		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[true, { textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [
				[
					[[true, ResourceAssistance.Message.totalPrice]],
					[[true, ResourceAssistance.Message.discount]],
					[[true, ResourceAssistance.Message.taxRate]],
					[[true, ResourceAssistance.Message.withholdingTax]],
					[[true, ResourceAssistance.Message.transactionFee]],
					[[true, ResourceAssistance.Message.balanceAdjustment]],
					[[true, ResourceAssistance.Message.debit]],
					[[true, ResourceAssistance.Message.credit]],
				],
			]
		})

		dispatch({
			type: SET_ADM_GL_PAYABLE_ACTIVITIES_TABLE,
			payload: {
				original: activities,
				filtered: filtered,
				body: body,
				rowColor: colors,
				popoverMsg: popoverMsg,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
