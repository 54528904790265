import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_POST_ANE_VS_ALINE,
	SET_OR_MODAL_POST_ANE_VS_DATE_TIME,
	SET_OR_MODAL_POST_ANE_VS_NIBP,
	SET_OR_MODAL_POST_ANE_VS_PULSE,
	SET_OR_MODAL_POST_ANE_VS_RR,
	SET_OR_MODAL_POST_ANE_VS_SPO2,
} from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class ORModalPostAneVSNewDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDateTime = this.onDateTime.bind(this)
		this.onPulse = this.onPulse.bind(this)
		this.onOnNIBP = this.onOnNIBP.bind(this)
		this.onALine = this.onALine.bind(this)
		this.onRR = this.onRR.bind(this)
		this.onSPO2 = this.onSPO2.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.modalPostAneVS.selectedVS.vs) {
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_PULSE, this.props.operatingRoom.modalPostAneVS.selectedVS.vs.pr)
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_NIBP, this.props.operatingRoom.modalPostAneVS.selectedVS.vs.bp)
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_ALINE, this.props.operatingRoom.modalPostAneVS.selectedVS.vs.aline)
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_DATE_TIME, this.props.operatingRoom.modalPostAneVS.selectedVS.vs.dateTime)
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_RR, this.props.operatingRoom.modalPostAneVS.selectedVS.vs.rr)
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_SPO2, this.props.operatingRoom.modalPostAneVS.selectedVS.vs.oxygenSat)
		} else {
			this.props.setValue(SET_OR_MODAL_POST_ANE_VS_DATE_TIME, this.props.operatingRoom.selectedPostAneRecord.record.startDateTime)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_PULSE, "")
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_NIBP, "")
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_ALINE, "")
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_RR, "")
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_SPO2, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col />
					<Col md="auto">
						<AntDatePicker
							allowClear={false}
							size={"large"}
							format={"DD-MM-YYYY  HH:mm"}
							showTime={{ format: "HH:mm" }}
							minDate={moment(this.props.operatingRoom.selectedPostAneRecord.record.startDateTime)}
							maxDate={moment(this.props.operatingRoom.selectedPostAneRecord.record.endDateTime)}
							disabledTime={(current, type) => {
								let start = moment(this.props.operatingRoom.selectedPostAneRecord.record.startDateTime)
								let end = moment(this.props.operatingRoom.selectedPostAneRecord.record.endDateTime)
								return {
									disabledHours: () => {
										const allHours = []
										for (let i = 0; i < 24; i++) {
											allHours.push(i)
										}
										return allHours.filter((hour) => hour < start.hour() || hour > end.hour())
									},
									disabledMinutes: (selectedHour) => {
										const startMinutes = start.hours() * 60 + start.minutes()
										const endMinutes = end.hours() * 60 + end.minutes()
										const currentHourStart = selectedHour * 60
										const currentHourMinutes = Array.from({ length: 60 }, (_, i) => currentHourStart + i)
										const excludedMinutes = currentHourMinutes.filter((minute) => minute < startMinutes || minute >= endMinutes)
										return excludedMinutes.map((minute) => minute % 60)
									},
								}
							}}
							values={[moment(this.props.operatingRoom.modalPostAneVS.dateTime)]}
							onChange={this.onDateTime}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.prPerMin })}
							pattern="^\d+$"
							onChange={this.onPulse}
							value={this.props.operatingRoom.modalPostAneVS.pulse}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.NIBP })}(${ResourceAssistance.CONSTANT.MMHG})`}
							pattern="^\d+\/\d+$"
							onChange={this.onOnNIBP}
							value={this.props.operatingRoom.modalPostAneVS.NIBP}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.ALine })}(${ResourceAssistance.CONSTANT.MMHG})`}
							pattern="^\d+\/\d+$"
							onChange={this.onALine}
							value={this.props.operatingRoom.modalPostAneVS.ALine}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.rrPerMin })}
							pattern="^\d+$"
							onChange={this.onRR}
							value={this.props.operatingRoom.modalPostAneVS.RR}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.SpO2 })}
							pattern="^\d+$"
							onChange={this.onSPO2}
							value={this.props.operatingRoom.modalPostAneVS.SpO2}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onDateTime(v) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_DATE_TIME, v ? v.milliseconds(0).valueOf() : "")
	}
	onPulse(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_PULSE, e.target.value)
	}
	onOnNIBP(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_NIBP, e.target.value)
	}
	onALine(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_ALINE, e.target.value)
	}
	onRR(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_RR, e.target.value)
	}
	onSPO2(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_VS_SPO2, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneVSNewDetails))
