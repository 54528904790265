import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_INVENTORY_BRANCHES,
	SET_INVENTORY_INVENTORIES,
	SET_INVENTORY_INVENTORY_DETAILS,
	SET_INVENTORY_INVENTORY_DETAILS_LOADING,
	SET_INVENTORY_LOCS,
	SET_INVENTORY_LOC_LOADING,
	SET_INVENTORY_ORGS,
	SET_INVENTORY_PENDING_SUPPLIERS,
	SET_INVENTORY_SEARCH_CODE_TEXT,
	SET_INVENTORY_SEARCH_NAME_TEXT,
	SET_INVENTORY_SELECTED_BRANCH,
	SET_INVENTORY_SELECTED_INVENTORY,
	SET_INVENTORY_SELECTED_ORG,
	SET_INVENTORY_SELECTED_PENDING_SUPPLIER,
	SET_INVENTORY_SELECTED_SUPPLIER,
	SET_INVENTORY_SUPPLIERS,
	SET_INVENTORY_SUPPLIERS_LOADING,
	SET_INVENTORY_SUPPLIER_SEARCH_TEXT,
	SET_INVENTORY_TYPES,
	SET_INVENTORY_TYPE_LOADING,
	SET_INVENTORY_SELECTED_TYPE,
	SET_INVENTORY_SELECTED_INVENTORY_DETAIL,
	SET_INVENTORY_DISPLAY_MIN_STOCK_CHECKED,
	SET_INVENTORY_LOTS,
	SET_INVENTORY_PENDING_ORDER,
	SET_INVENTORY_LOT_EXPIRED_DATE,
	SET_INVENTORY_DISPLAY_EXPIRE_CHECKED,
	SET_INVENTORY_NEW_INVENTORY_SELECTED_TYPE,
} from "../type"
const init = {
	searchCodeText: "",
	searchNameText: "",
	isDisplayExpireChecked: false,
	isDisplayMinStockChecked: false,
	lotExpiredDate: Utils.generateDate(0, 6),
	locs: {
		locs: [],
		orgs: [],
		branches: [],
		inventories: [],
		isLoading: false,
	},
	type: {
		types: [],
		isLoading: false,
	},
	supplier: {
		searchText: "",
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.form,
			ResourceAssistance.Message.dose,
			ResourceAssistance.Message.unit,
		],
		body: [],
		items: [],
		filtered: [],
		isLoading: false,
	},
	pendingSupplier: {
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.form,
			ResourceAssistance.Message.dose,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.minLevel,
			ResourceAssistance.Message.maxLevel,
		],
		body: [],
		suppliers: [],
	},
	inventories: {
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.currentLevel,
			ResourceAssistance.Message.minLevel,
			ResourceAssistance.Message.maxLevel,
		],
		body: [],
		inventories: [],
		filtered: [],
		isLoading: false,
	},
	lots: {
		header: [
			ResourceAssistance.Message.lotNum,
			ResourceAssistance.Message.current,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.expired,
			ResourceAssistance.Message.lastReceivedDate,
		],
		body: [],
		colColor: [],
		lots: [],
	},
	pendingOrder: {
		prId: "",
		dateOfPo: "",
		package: "",
		amount: "",
	},
	newInventory: {
		selectedType: {
			type: null,
			index: -1,
		},
	},
	selectedType: {
		type: null,
		index: -1,
	},
	selectedOrg: {
		loc: null,
		index: -1,
	},
	selectedBranch: {
		loc: null,
		index: -1,
	},
	selectedInventory: {
		loc: null,
		index: -1,
	},
	selectedInventoryDetail: {
		inventoryDetail: null,
		index: -1,
	},
	selectedSupplier: {
		supplier: null,
		index: -1,
	},
	selectedPendingSupplier: {
		supplier: null,
		index: -1,
	},
}

const inventoryReducer = (state = init, action) => {
	switch (action.type) {
		case SET_INVENTORY_LOCS:
			return Object.assign({}, state, {
				locs: {
					...state.locs,
					locs: action.payload.locs,
				},
			})

		case SET_INVENTORY_LOC_LOADING:
			return Object.assign({}, state, {
				locs: {
					...state.locs,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_INVENTORY_ORGS:
			return Object.assign({}, state, {
				locs: {
					...state.locs,
					orgs: action.payload.orgs,
				},
			})

		case SET_INVENTORY_BRANCHES:
			return Object.assign({}, state, {
				locs: {
					...state.locs,
					branches: action.payload.branches,
				},
			})

		case SET_INVENTORY_INVENTORIES:
			return Object.assign({}, state, {
				locs: {
					...state.locs,
					inventories: action.payload.inventories,
				},
			})

		case SET_INVENTORY_SELECTED_ORG:
			return Object.assign({}, state, {
				selectedOrg: {
					...state.selectedOrg,
					loc: action.payload.loc,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_SELECTED_BRANCH:
			return Object.assign({}, state, {
				selectedBranch: {
					...state.selectedBranch,
					loc: action.payload.loc,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_SELECTED_INVENTORY:
			return Object.assign({}, state, {
				selectedInventory: {
					...state.selectedInventory,
					loc: action.payload.loc,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_SUPPLIERS:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					items: action.payload.items,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_INVENTORY_SUPPLIERS_LOADING:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_INVENTORY_SUPPLIER_SEARCH_TEXT:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					searchText: action.payload.text,
				},
			})

		case SET_INVENTORY_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				selectedSupplier: {
					...state.selectedSupplier,
					supplier: action.payload.supplier,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_SELECTED_PENDING_SUPPLIER:
			return Object.assign({}, state, {
				selectedPendingSupplier: {
					...state.selectedPendingSupplier,
					supplier: action.payload.supplier,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_PENDING_SUPPLIERS:
			return Object.assign({}, state, {
				pendingSupplier: {
					...state.pendingSupplier,
					suppliers: action.payload.suppliers,
					body: action.payload.body,
				},
			})

		case SET_INVENTORY_SEARCH_NAME_TEXT:
			return Object.assign({}, state, {
				searchNameText: action.payload.text,
			})

		case SET_INVENTORY_SEARCH_CODE_TEXT:
			return Object.assign({}, state, {
				searchCodeText: action.payload.text,
			})

		case SET_INVENTORY_INVENTORY_DETAILS:
			return Object.assign({}, state, {
				inventories: {
					...state.inventories,
					inventories: action.payload.inventoryDetails,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_INVENTORY_INVENTORY_DETAILS_LOADING:
			return Object.assign({}, state, {
				inventories: {
					...state.inventories,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_INVENTORY_TYPES:
			return Object.assign({}, state, {
				type: {
					...state.type,
					types: action.payload.types,
				},
			})

		case SET_INVENTORY_TYPE_LOADING:
			return Object.assign({}, state, {
				type: {
					...state.type,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_INVENTORY_SELECTED_TYPE:
			return Object.assign({}, state, {
				selectedType: {
					...state.selectedType,
					type: action.payload.type,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_SELECTED_INVENTORY_DETAIL:
			return Object.assign({}, state, {
				selectedInventoryDetail: {
					...state.selectedInventoryDetail,
					inventoryDetail: action.payload.detail,
					index: action.payload.index,
				},
			})

		case SET_INVENTORY_DISPLAY_EXPIRE_CHECKED:
			return Object.assign({}, state, {
				isDisplayExpireChecked: action.payload.isChecked,
			})

		case SET_INVENTORY_DISPLAY_MIN_STOCK_CHECKED:
			return Object.assign({}, state, {
				isDisplayMinStockChecked: action.payload.isChecked,
			})

		case SET_INVENTORY_LOT_EXPIRED_DATE:
			return Object.assign({}, state, {
				lotExpiredDate: action.payload.date,
			})

		case SET_INVENTORY_LOTS:
			return Object.assign({}, state, {
				lots: {
					...state.lots,
					body: action.payload.body,
					colColor: action.payload.colColor,
					lots: action.payload.lots,
				},
			})

		case SET_INVENTORY_PENDING_ORDER:
			return Object.assign({}, state, {
				pendingOrder: {
					...state.pendingOrder,
					prId: action.payload.prId,
					dateOfPo: action.payload.dateOfPo,
					package: action.payload.package,
					amount: action.payload.amount,
				},
			})

		case SET_INVENTORY_NEW_INVENTORY_SELECTED_TYPE:
			return Object.assign({}, state, {
				newInventory: {
					...state.newInventory,
					selectedType: {
						...state.newInventory.selectedType,
						index: action.payload.index,
						type: action.payload.type,
					},
				},
			})

		default:
			return state
	}
}

export default inventoryReducer
