import { Button as AButton, Card, ConfigProvider, Descriptions, Form, Input, Popconfirm, Tooltip, Typography } from "antd"
import _ from "lodash"
import moment from "moment"
import React, { createRef } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgVitalSign } from "~/icons"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOperatingRoomRequests,
	setORModalIntraAneServicePendingServices,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_INTRA_ANE_END_DATE_TIME,
	SET_OR_INTRA_ANE_START_DATE_TIME,
	SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_MEDICATION_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_VITAL_SIGN_DISPLAY,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalIntraAneRecordTable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			column: 17,
			editableKey: "",
			deleteService: undefined,
			deleteIOs: [],
			notes: {},
		}
		this.formRef = createRef()
		this.onRecordTime = this.onRecordTime.bind(this)
		this.onIO = this.onIO.bind(this)
		this.onMedication = this.onMedication.bind(this)
		this.onVitalSign = this.onVitalSign.bind(this)
		this.onService = this.onService.bind(this)
		this.onSaveServiceDetail = this.onSaveServiceDetail.bind(this)
		this.onDeleteService = this.onDeleteService.bind(this)
		this.onSaveIO = this.onSaveIO.bind(this)
		this.onDeleteIO = this.onDeleteIO.bind(this)
	}

	componentDidMount() {
		this.initState()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteIntraAneModalService)) {
				this.deleteDoctorOrder()
			}
		}
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteIntraAneIntakeOutput)) {
				this.deleteIO()
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.selectedIntraAneRecord.record, this.props.operatingRoom.selectedIntraAneRecord.record)) {
			this.initState()
		}
	}

	initState() {
		let timeList = [
			this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime,
			this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime,
			this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime,
			this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime,
		]
			.filter((each) => each)
			.sort((a, b) => Utils.sort(a, b))
		let startDateTime = timeList[0]
		let endDateTime = timeList[timeList.length - 1]
		const startTimeInMinutes = startDateTime / 60000
		const endTimeInMinutes = endDateTime / 60000
		const differenceInMinutes = endTimeInMinutes - startTimeInMinutes
		const numberOfIntervals = differenceInMinutes / 15
		this.setState({
			editableKey: "",
			deleteService: undefined,
			deleteIOs: [],
			notes: {},
			//the view can totally have 17 columns
			column: numberOfIntervals < 16 ? 17 : Math.ceil(numberOfIntervals) + 2, //1 column for the description, 1 column for the Total
		})
		this.props.setValue(SET_OR_INTRA_ANE_START_DATE_TIME, startDateTime)
		this.props.setValue(SET_OR_INTRA_ANE_END_DATE_TIME, endDateTime)
	}

	isSaveServiceDisabled() {
		let selected = this.props.operatingRoom.selectedIntraAneRecord.record.doctorOrders
			.filter((order) => order.serviceCode)
			.find((each) => each.serviceCode === this.state.editableKey)
		return _.isEqual(
			selected.details.sort((a, b) => Utils.sort(a.id, b.id)),
			Object.values(this.formRef.current.getFieldsValue(true))
				.filter((each) => typeof each === "object" && (each.note || each.id))
				.map((each) => {
					return {
						id: each.id,
						startDateTime: each.start,
						endDateTime: each.end,
						note: Utils.trim(each.note),
					}
				})
				.sort((a, b) => Utils.sort(a.id, b.id))
		)
	}

	isSaveIODisabled() {
		let selected = this.props.operatingRoom.selectedIntraAneRecord.record.intakeOutputs
			.filter((each) => (each.intake === this.state.editableKey || each.output === this.state.editableKey) && each.dateTime > 0)
			.map((each) => {
				return {
					id: each.id,
					dateTime: each.dateTime,
					method: each.intake ? each.intake : each.output,
					amount: each.intake ? each.intakeAmount : each.outputAmount,
				}
			})
		return _.isEqual(
			selected.sort((a, b) => Utils.sort(a.id, b.id)),
			Object.values(this.formRef.current.getFieldsValue(true))
				.filter((each) => typeof each === "object" && (each.amount || each.id))
				.map((each) => {
					return {
						id: each.id,
						dateTime: each.dateTime,
						method: each.method,
						amount: each.amount,
					}
				})
				.sort((a, b) => Utils.sort(a.id, b.id))
		)
	}

	deleteDoctorOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteAneDoctorOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				doctorOrders: [
					{
						id: this.state.deleteService.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deleteIO() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteAneIntakeOutputs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				ios: this.state.deleteIOs,
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<ConfigProvider
							theme={{
								components: {
									Descriptions: {},
								},
							}}
						>
							<Descriptions
								bordered
								style={{
									flex: "1",
									flexDirection: "column",
									minWidth: "1741.39px",
									maxWidth: "1741.39px",
									overflowY: "auto",
									overflowX: "hidden",
								}}
								size={"small"}
								contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
								column={5}
								extra={
									!this.props.operatingRoom.selectedIntraAneRecord.record.approver && (
										<Container fluid className={"full-size full-flex"}>
											<Row className="g-2">
												<Col md="auto">
													<AButton
														type="primary"
														size="middle"
														shape="round"
														icon={
															<span className={"anticon"}>
																<MdLibraryAdd size={ResourceAssistance.ReactIcon.size_antd_button} />
															</span>
														}
														disabled={!this.props.operatingRoom.selectedIntraAneRecord.record}
														onClick={this.onMedication}
													>
														{translate(ResourceAssistance.Message.medication)}
													</AButton>
												</Col>
												<Col md="auto">
													<AButton
														type="primary"
														size="middle"
														shape="round"
														icon={
															<span className={"anticon"}>
																<MdLibraryAdd size={ResourceAssistance.ReactIcon.size_antd_button} />
															</span>
														}
														onClick={this.onService}
													>
														{translate(ResourceAssistance.Message.service)}
													</AButton>
												</Col>
												<Col md="auto">
													<AButton
														type="primary"
														size="middle"
														shape="round"
														icon={
															<span className={"anticon"}>
																<MdLibraryAdd size={ResourceAssistance.ReactIcon.size_antd_button} />
															</span>
														}
														onClick={this.onIO}
													>
														{translate(ResourceAssistance.Message.intakeOutput)}
													</AButton>
												</Col>
												<Col md="auto">
													<AButton
														type="primary"
														size="middle"
														shape="round"
														icon={
															<span className={"anticon"}>
																<SvgVitalSign width={ResourceAssistance.ReactIcon.size} height={ResourceAssistance.ReactIcon.size} />
															</span>
														}
														disabled={!this.props.operatingRoom.selectedIntraAneRecord.record}
														onClick={this.onVitalSign}
													>
														{translate(ResourceAssistance.Message.vitalSign)}
													</AButton>
												</Col>
											</Row>
										</Container>
									)
								}
							>
								<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											<Col md="auto">
												{Utils.formatDate(this.props.operatingRoom.intraAneStartDateTime)} - {Utils.formatDate(this.props.operatingRoom.intraAneEndDateTime)}
											</Col>
											{!this.props.operatingRoom.selectedIntraAneRecord.record.approver && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onRecordTime}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.anestheticTime)} span={1}>
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											<Col md="auto">
												{this.props.operatingRoom.selectedIntraAneRecord.record
													? `${Utils.formatTime(this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime)} - ${Utils.formatTime(
															this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime
													  )}`
													: ""}
											</Col>
											{!this.props.operatingRoom.selectedIntraAneRecord.record.approver && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onRecordTime}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								</Descriptions.Item>
								<Descriptions.Item label={"Total Time"} span={1}>
									{this.props.operatingRoom.selectedIntraAneRecord.record
										? Utils.calculateHourAndMinute(
												this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime,
												this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime
										  )
										: ""}
								</Descriptions.Item>
								<Descriptions.Item label={"Operative Time"} span={1}>
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											<Col md="auto">
												{this.props.operatingRoom.selectedIntraAneRecord.record
													? `${Utils.formatTime(this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime)} - ${Utils.formatTime(
															this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime
													  )}`
													: ""}
											</Col>
											{!this.props.operatingRoom.selectedIntraAneRecord.record.approver && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onRecordTime}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								</Descriptions.Item>
								<Descriptions.Item label={"Total Time"} span={1}>
									{this.props.operatingRoom.selectedIntraAneRecord.record
										? Utils.calculateHourAndMinute(
												this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime,
												this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime
										  )
										: ""}
								</Descriptions.Item>
							</Descriptions>
						</ConfigProvider>
					</Col>
				</Row>
				<Row>
					<Col>
						<Form ref={this.formRef} component={false}>
							<ConfigProvider
								theme={{
									components: {
										Descriptions: {},
									},
								}}
							>
								<Descriptions
									className={"has-overflow-x"}
									bordered
									style={{
										flex: "1",
										flexDirection: "column",
										overflowY: "auto",
										overflowX: "auto",
										paddingBottom: "10px",
									}}
									size={"small"}
									contentStyle={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
									column={this.state.column}
								>
									{this.renderTimeRange()}
									{this.renderDrugs()}
									{this.renderService()}
									{this.renderIO()}
								</Descriptions>
							</ConfigProvider>
						</Form>
					</Col>
				</Row>
			</>
		)
	}
	renderTimeRange() {
		let rst = [<Descriptions.Item key={0} labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px" }} contentStyle={{ display: "none" }} span={1} />]
		for (let i = 1, start = moment(this.props.operatingRoom.intraAneStartDateTime); i < this.state.column - 1; i++, start.add(15, "minutes")) {
			rst.push(
				<Descriptions.Item
					key={i}
					labelStyle={{ display: "none" }}
					contentStyle={{ minWidth: "90px", maxWidth: "90px", textAlign: "center", fontWeight: "bold" }}
					span={1}
				>
					{Utils.formatTime(start.valueOf())}
				</Descriptions.Item>
			)
		}
		rst.push(
			<Descriptions.Item
				key={this.state.column}
				labelStyle={{ display: "none" }}
				contentStyle={{ minWidth: "90px", maxWidth: "90px", textAlign: "center", fontWeight: "bold" }}
				span={1}
			>
				{translate(ResourceAssistance.Message.total)}
			</Descriptions.Item>
		)
		return rst
	}
	renderDrugs() {
		//1741.39
		if (!this.props.operatingRoom.selectedIntraAneRecord.record) {
			return []
		}
		let rst = []
		let descObj = this.props.operatingRoom.selectedIntraAneRecord.record.doctorOrders
			.filter((order) => !order.serviceCode)
			.sort((a, b) => Utils.sort(a.description, b.description))
			.reduce((total, cur) => {
				if (!total[`${cur.description}${ResourceAssistance.PROGRAM_DEFINED.split}${cur.unit}`]) {
					total[`${cur.description}${ResourceAssistance.PROGRAM_DEFINED.split}${cur.unit}`] = []
				}
				total[`${cur.description}${ResourceAssistance.PROGRAM_DEFINED.split}${cur.unit}`].push({
					startDateTime: cur.startDateTime,
					description: cur.description,
					durationQty: cur.durationQty,
					unit: cur.unit,
				})
				return total
			}, {})
		for (let descKey in descObj) {
			let rowTotal = Utils.BigNumber(0)
			let row = [
				<Descriptions.Item
					key={0}
					labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px", padding: "8px 5px" }}
					contentStyle={{ display: "none" }}
					label={
						<Container fluid="small">
							<Row className="g-1">
								<Col>
									<Typography.Text>{descKey.split(ResourceAssistance.PROGRAM_DEFINED.split)[0]}</Typography.Text>
								</Col>
							</Row>
						</Container>
					}
					span={1}
				/>,
			]
			for (
				let i = 1,
					start = moment(this.props.operatingRoom.intraAneStartDateTime),
					end = moment(this.props.operatingRoom.intraAneStartDateTime).add(15, "minutes");
				i < this.state.column - 1;
				i++, start.add(15, "minutes"), end.add(15, "minutes")
			) {
				let durationQty = descObj[descKey].reduce((total, cur) => {
					if (moment(cur.startDateTime).isBetween(start, end, "minutes", "[)")) {
						total = total.plus(cur.durationQty)
					}
					return total
				}, Utils.BigNumber(0))
				rowTotal = rowTotal.plus(durationQty)
				row.push(
					<Descriptions.Item
						key={i}
						labelStyle={{ display: "none" }}
						contentStyle={{ minWidth: "90px", maxWidth: "90px", padding: "0 5px", textAlign: "center" }}
						span={1}
					>
						{durationQty.gt(0) && (
							<Typography.Text
								ellipsis={{
									tooltip: true,
								}}
							>{`${durationQty.toNumber()} ${descObj[descKey][0].unit}`}</Typography.Text>
						)}
					</Descriptions.Item>
				)
			}
			row.push(
				<Descriptions.Item
					key={this.state.column}
					labelStyle={{ display: "none" }}
					contentStyle={{ minWidth: "90px", maxWidth: "90px", padding: "0 5px", textAlign: "center" }}
					span={1}
				>
					{`${rowTotal.toNumber()} ${descObj[descKey][0].unit}`}
				</Descriptions.Item>
			)
			rst.push(row)
		}
		return rst
	}
	renderService() {
		//1756.39
		if (!this.props.operatingRoom.selectedIntraAneRecord.record) {
			return []
		}
		let rst = []
		let services = this.props.operatingRoom.selectedIntraAneRecord.record.doctorOrders.filter((order) => order.serviceCode)
		services.forEach((service) => {
			let row = [
				<Descriptions.Item
					key={0}
					labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px", padding: "8px 5px" }}
					contentStyle={{ display: "none" }}
					label={
						<Container fluid="small">
							<Row className="g-2">
								<Col>
									<Typography.Text>{service.description}</Typography.Text>
								</Col>
								{this.state.editableKey === service.serviceCode ? (
									<>
										<Col md="auto">
											<Typography.Link disabled={this.isSaveServiceDisabled()} onClick={this.onSaveServiceDetail}>
												{translate(ResourceAssistance.Message.save)}
											</Typography.Link>
										</Col>
										<Col md="auto">
											<Popconfirm
												title={translate(ResourceAssistance.Message.cancel)}
												onConfirm={() => {
													this.setState({
														editableKey: "",
														notes: {},
													})
													this.formRef.current.resetFields()
												}}
												okText={translate(ResourceAssistance.Message.confirm)}
												cancelText={translate(ResourceAssistance.Message.close)}
											>
												<Typography.Link>{translate(ResourceAssistance.Message.cancel)}</Typography.Link>
											</Popconfirm>
										</Col>
									</>
								) : (
									!this.props.operatingRoom.selectedIntraAneRecord.record.approver && (
										<>
											<Col md="auto">
												<Typography.Link
													disabled={this.state.editableKey}
													onClick={() => {
														let notes = {}
														let initialValues = {}
														for (
															let i = 1,
																start = moment(this.props.operatingRoom.intraAneStartDateTime),
																end = moment(this.props.operatingRoom.intraAneStartDateTime).add(15, "minutes");
															i < this.state.column - 1;
															i++, start.add(15, "minutes"), end.add(15, "minutes")
														) {
															let serviceDetail = service.details.find((each) => each.startDateTime === start.valueOf() && each.endDateTime === end.valueOf())
															if (serviceDetail) {
																notes[i] = serviceDetail.note
																initialValues[start.valueOf()] = serviceDetail.note
																this.formRef.current.setFieldValue(i, {
																	doctorOrderId: service.id,
																	id: serviceDetail.id,
																	start: serviceDetail.startDateTime,
																	end: serviceDetail.endDateTime,
																	note: serviceDetail.note,
																})
															} else {
																initialValues[start.valueOf()] = ""
															}
														}
														this.formRef.current.setFieldsValue(initialValues)
														this.setState({
															editableKey: service.serviceCode,
															notes: notes,
														})
													}}
												>
													{translate(ResourceAssistance.Message.edit)}
												</Typography.Link>
											</Col>
											<Col md="auto">
												<Typography.Link
													type={"danger"}
													disabled={this.state.editableKey}
													onClick={() => {
														this.setState({
															deleteService: service,
														})
														this.onDeleteService(service)
													}}
												>
													{translate(ResourceAssistance.Message.delete)}
												</Typography.Link>
											</Col>
										</>
									)
								)}
							</Row>
						</Container>
					}
					span={1}
				/>,
			]
			for (
				let i = 1,
					start = moment(this.props.operatingRoom.intraAneStartDateTime),
					end = moment(this.props.operatingRoom.intraAneStartDateTime).add(15, "minutes");
				i < this.state.column - 1;
				i++, start.add(15, "minutes"), end.add(15, "minutes")
			) {
				let serviceDetail = service.details.find((each) => each.startDateTime === start.valueOf() && each.endDateTime === end.valueOf())
				row.push(
					<Descriptions.Item
						key={i}
						labelStyle={{ display: "none" }}
						contentStyle={{ minWidth: "90px", maxWidth: "90px", padding: "0 5px", textAlign: "center" }}
						span={1}
					>
						{service.serviceCode === this.state.editableKey &&
						start.isBetween(this.props.operatingRoom.intraAneStartDateTime, this.props.operatingRoom.intraAneEndDateTime, "minutes", "[]") ? (
							this.renderServiceEditableCell(i, service, start.clone(), end.clone())
						) : (
							<Typography.Text
								ellipsis={{
									tooltip: true,
								}}
							>
								{serviceDetail ? serviceDetail.note : ""}
							</Typography.Text>
						)}
					</Descriptions.Item>
				)
			}
			row.push(
				<Descriptions.Item
					key={this.state.column}
					labelStyle={{ display: "none" }}
					contentStyle={{ minWidth: "90px", maxWidth: "90px", padding: "0 5px", textAlign: "center" }}
					span={1}
				></Descriptions.Item>
			)
			rst.push(row)
		})
		if (!_.isEmpty(rst)) {
			rst.unshift(
				<Descriptions.Item
					key={0}
					labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px", padding: "8px 5px", fontWeight: "bold", textAlign: "center" }}
					contentStyle={{ display: "none" }}
					label={<Typography.Text>{translate(ResourceAssistance.Message.service)}</Typography.Text>}
					span={this.state.column}
				></Descriptions.Item>
			)
		}
		return rst
	}
	renderServiceEditableCell(idx, service, start, end) {
		return (
			<Tooltip open={this.state.notes[idx] && this.state.notes[idx].length > 7} title={this.state.notes[idx]}>
				<Form.Item name={start.valueOf()} noStyle>
					<Input
						style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
						onChange={(e) => {
							this.formRef.current.setFieldValue(idx, {
								...this.formRef.current.getFieldValue(idx),
								doctorOrderId: service.id,
								start: start.valueOf(),
								end: end.valueOf(),
								note: e.target.value,
							})
							this.setState((prevState) => ({
								notes: {
									...prevState.notes,
									[idx]: e.target.value,
								},
							}))
						}}
					/>
				</Form.Item>
			</Tooltip>
		)
	}
	renderIO() {
		if (!this.props.operatingRoom.selectedIntraAneRecord.record) {
			return []
		}
		let rst = []
		let descObj = this.props.operatingRoom.selectedIntraAneRecord.record.intakeOutputs.reduce((total, cur) => {
			if (cur.intake) {
				if (!total[cur.intake]) {
					total[cur.intake] = []
				}
				total[cur.intake].push({
					id: cur.id,
					dateTime: cur.dateTime,
					amount: cur.intakeAmount,
					isIntake: true,
					io: cur,
				})
			} else {
				if (!total[cur.output]) {
					total[cur.output] = []
				}
				total[cur.output].push({
					id: cur.id,
					dateTime: cur.dateTime,
					amount: cur.outputAmount,
					isIntake: false,
					io: cur,
				})
			}
			return total
		}, {})
		let colIOBalance = new Array(this.state.column).fill(Utils.BigNumber(0))
		for (let method in descObj) {
			let isIntake = false
			let rowTotal = Utils.BigNumber(0)
			let row = [
				<Descriptions.Item
					key={0}
					labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px", padding: "8px 5px" }}
					contentStyle={{ display: "none" }}
					label={
						<Container fluid="small">
							<Row className="g-2">
								<Col>
									<Typography.Text>{method}</Typography.Text>
								</Col>
								{this.state.editableKey === method ? (
									<>
										<Col md="auto">
											<Typography.Link disabled={this.isSaveIODisabled()} onClick={this.onSaveIO}>
												{translate(ResourceAssistance.Message.save)}
											</Typography.Link>
										</Col>
										<Col md="auto">
											<Popconfirm
												title={translate(ResourceAssistance.Message.cancel)}
												onConfirm={() => {
													this.setState({
														editableKey: "",
														notes: {},
													})
													this.formRef.current.resetFields()
												}}
												okText={translate(ResourceAssistance.Message.confirm)}
												cancelText={translate(ResourceAssistance.Message.close)}
											>
												<Typography.Link>{translate(ResourceAssistance.Message.cancel)}</Typography.Link>
											</Popconfirm>
										</Col>
									</>
								) : (
									!this.props.operatingRoom.selectedIntraAneRecord.record.approver && (
										<>
											<Col md="auto">
												<Typography.Link
													disabled={this.state.editableKey}
													onClick={() => {
														let notes = {}
														let initialValues = {}
														for (
															let i = 1,
																start = moment(this.props.operatingRoom.intraAneStartDateTime),
																end = moment(this.props.operatingRoom.intraAneStartDateTime).add(15, "minutes");
															i < this.state.column - 1;
															i++, start.add(15, "minutes"), end.add(15, "minutes")
														) {
															let found = descObj[method].find((each) => moment(each.dateTime).isBetween(start, end, "minutes", "[)"))
															if (found) {
																notes[i] = found.amount
																initialValues[start.valueOf()] = found.amount
																this.formRef.current.setFieldValue(i, {
																	id: found.id,
																	dateTime: found.dateTime,
																	method: method,
																	amount: found.amount,
																	isIntake: found.isIntake,
																})
															} else {
																initialValues[start.valueOf()] = ""
															}
														}
														this.formRef.current.setFieldsValue(initialValues)
														this.setState({
															editableKey: method,
															notes: notes,
														})
													}}
												>
													{translate(ResourceAssistance.Message.edit)}
												</Typography.Link>
											</Col>
											<Col md="auto">
												<Typography.Link
													type={"danger"}
													disabled={this.state.editableKey}
													onClick={() => {
														this.setState({
															deleteIOs: descObj[method],
														})
														this.onDeleteIO(method)
													}}
												>
													{translate(ResourceAssistance.Message.delete)}
												</Typography.Link>
											</Col>
										</>
									)
								)}
							</Row>
						</Container>
					}
					span={1}
				/>,
			]
			for (
				let i = 1,
					start = moment(this.props.operatingRoom.intraAneStartDateTime),
					end = moment(this.props.operatingRoom.intraAneStartDateTime).add(15, "minutes");
				i < this.state.column - 1;
				i++, start.add(15, "minutes"), end.add(15, "minutes")
			) {
				let found = descObj[method].find((each) => moment(each.dateTime).isBetween(start, end, "minutes", "[)"))
				if (found) {
					isIntake = found.isIntake
					rowTotal = rowTotal.plus(found.amount)
					colIOBalance[i] = found.isIntake ? colIOBalance[i].plus(found.amount) : colIOBalance[i].minus(found.amount)
				}
				let startClone = start.clone()
				row.push(
					<Descriptions.Item
						key={i}
						labelStyle={{ display: "none" }}
						contentStyle={{
							minWidth: "90px",
							maxWidth: "90px",
							padding: "0 5px",
							textAlign: "center",
						}}
						span={1}
					>
						{method === this.state.editableKey &&
						startClone.isBetween(this.props.operatingRoom.intraAneStartDateTime, this.props.operatingRoom.intraAneEndDateTime, "minutes", "[]") ? (
							<Form.Item name={startClone.valueOf()} noStyle>
								<Input
									style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
									onChange={(e) => {
										this.formRef.current.setFieldValue(i, {
											...this.formRef.current.getFieldValue(i),
											dateTime: startClone.valueOf(),
											method: method,
											amount: e.target.value,
											isIntake: descObj[method][0].isIntake,
										})
										this.setState((prevState) => ({
											notes: {
												...prevState.notes,
												[i]: e.target.value,
											},
										}))
									}}
								/>
							</Form.Item>
						) : (
							<Typography.Text
								ellipsis={{
									tooltip: true,
								}}
							>
								{found ? `${found.amount} ${ResourceAssistance.CONSTANT.ML}` : ""}
							</Typography.Text>
						)}
					</Descriptions.Item>
				)
			}
			row.push(
				<Descriptions.Item
					key={this.state.column}
					labelStyle={{ display: "none" }}
					contentStyle={{
						minWidth: "90px",
						maxWidth: "90px",
						padding: "0 5px",
						textAlign: "center",
						color: isIntake
							? rowTotal.eq(0)
								? undefined
								: ResourceAssistance.CSS.Color.dark_green
							: rowTotal.eq(0)
							? undefined
							: ResourceAssistance.CSS.Color.red,
					}}
					span={1}
				>
					{`${rowTotal.toNumber()} ${ResourceAssistance.CONSTANT.ML}`}
				</Descriptions.Item>
			)
			rst.push(row)
		}
		if (!_.isEmpty(rst)) {
			rst.unshift(
				<Descriptions.Item
					key={0}
					labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px", padding: "8px 5px", fontWeight: "bold", textAlign: "center" }}
					contentStyle={{ display: "none" }}
					label={<Typography.Text>{translate(ResourceAssistance.Message.intakeOutput)}</Typography.Text>}
					span={this.state.column}
				></Descriptions.Item>
			)
			let ioBalanceRow = [
				<Descriptions.Item
					key={0}
					labelStyle={{ minWidth: "301.39px", maxWidth: "301.39px", padding: "8px 5px" }}
					contentStyle={{ display: "none" }}
					label={<Typography.Text>{translate(ResourceAssistance.Message.ioBalance)}</Typography.Text>}
					span={1}
				/>,
			]
			for (let i = 1; i < this.state.column - 1; i++) {
				ioBalanceRow.push(
					<Descriptions.Item
						key={i}
						labelStyle={{ display: "none" }}
						contentStyle={{
							minWidth: "90px",
							maxWidth: "90px",
							padding: "0 5px",
							textAlign: "center",
							color: colIOBalance[i].isPositive() ? ResourceAssistance.CSS.Color.dark_green : ResourceAssistance.CSS.Color.red,
						}}
						span={1}
					>
						{colIOBalance[i].eq(0) ? "" : `${colIOBalance[i].abs().toNumber()} ${ResourceAssistance.CONSTANT.ML}`}
					</Descriptions.Item>
				)
			}
			let totalIOBalance = colIOBalance.reduce((total, cur) => {
				return total.plus(cur)
			}, Utils.BigNumber(0))
			ioBalanceRow.push(
				<Descriptions.Item
					key={this.state.column - 1}
					labelStyle={{ display: "none" }}
					contentStyle={{
						minWidth: "90px",
						maxWidth: "90px",
						padding: "0 5px",
						textAlign: "center",
						color: totalIOBalance.isPositive() ? ResourceAssistance.CSS.Color.green : ResourceAssistance.CSS.Color.red,
					}}
					span={1}
				>
					{`${totalIOBalance.abs().toNumber()} ${ResourceAssistance.CONSTANT.ML}`}
				</Descriptions.Item>
			)
			rst.push(ioBalanceRow)
		}
		return rst
	}
	onMedication() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_MEDICATION_DISPLAY, true)
	}
	onService() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY, true)
	}
	onIO() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY, true)
	}
	onVitalSign() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_VITAL_SIGN_DISPLAY, true)
	}
	onRecordTime() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY, true)
	}
	onSaveServiceDetail() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveAnesthesiaDoctorOrderDetails,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				doctorOrderDetails: Object.values(this.formRef.current.getFieldsValue(true))
					.filter((each) => typeof each === "object" && (each.note || each.id))
					.map((detail) => ({
						id: detail.id,
						doctorOrderId: detail.doctorOrderId,
						startDateTime: detail.start,
						endDateTime: detail.end,
						note: Utils.trim(detail.note),
					})),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.formRef.current.resetFields()
				this.setState({
					editableKey: "",
					notes: {},
				})
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	onDeleteService(service) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteIntraAneModalService)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions
					style={{ flex: "unset", display: "unset" }}
					size={"small"}
					contentStyle={{
						fontStyle: "italic",
						fontWeight: "normal",
						textAlign: "left",
					}}
					column={1}
				>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{service.serviceCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
						{service.description}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.receiptCategory)} span={1}>
						{service.categoryName}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
	onSaveIO() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveAnesthesiaIOs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				clinicalRecordId: this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.id,
				userId: this.props.login.user.id,
				ios: Object.values(this.formRef.current.getFieldsValue(true))
					.filter((each) => typeof each === "object" && (each.amount || each.id))
					.map((io) => ({
						id: io.id,
						intraAnesthesiaRecordId: this.props.operatingRoom.selectedIntraAneRecord.record.id,
						dateTime: io.dateTime,
						intake: io.isIntake ? io.method : "",
						intakeAmount: io.isIntake ? io.amount : "",
						output: !io.isIntake ? io.method : "",
						outputAmount: !io.isIntake ? io.amount : "",
					})),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.formRef.current.resetFields()
				this.setState({
					editableKey: "",
					notes: {},
				})
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	onDeleteIO(method) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteIntraAneIntakeOutput)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions
					style={{ flex: "unset", display: "unset" }}
					size={"small"}
					contentStyle={{
						fontStyle: "italic",
						fontWeight: "normal",
						textAlign: "left",
					}}
					column={1}
				>
					<Descriptions.Item label={translate(ResourceAssistance.Message.method)} span={1}>
						{method}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setORModalIntraAneServicePendingServices,
			setOperatingRoomRequests,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneRecordTable)
