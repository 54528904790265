import { Document, Page, PDFViewer, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import React from "react"
import { ResourceAssistance } from "~/i18n"

class ReportLabelDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				displayLabNumber: false,
				displayRoom: false,
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.LL} style={[ResourceAssistance.Report.styles.pageLabel]}>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>{this.state.data.name}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>PID: {this.state.data.patientNum}</Text>
							</View>
							{this.state.data.displayLabNumber && (
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text>LNo.: {this.state.data.labNum}</Text>
								</View>
							)}
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text>DOB: {this.state.data.dob}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text>
									อายุ: {this.state.data.ageYear} ปี {this.state.data.ageMonth} เดือน
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							{!_.isEmpty(this.state.data.hn) && (
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text>Ref HN: {this.state.data.hn}</Text>
								</View>
							)}
							{this.state.data.displayRoom && (
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text>Room: {this.state.data.room}</Text>
								</View>
							)}
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

export default ReportLabelDocument
