import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"
import { Utils } from "~/utils/Utils"

class ReportOPDNurseOrderDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				orders: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID/{this.state.data.identificationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.identification}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age/{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB/{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Item Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.dateIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.timeIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.order]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.orderIntl}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Date</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.order]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Order</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Items */}
						{this.state.data.orders.map((order) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.date]}>
										<Text>{Utils.formatDate(order.creationDateTime)}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.time]}>
										<Text>{Utils.formatTime(order.creationDateTime)}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.order]}>
										<Text>
											{order.description} {order.notes}({Utils.formatDate(order.startDateTime)} -{" "}
											{Utils.formatDate(order.endDateTime)})
										</Text>
									</View>
								</View>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Signature */}
						<Signature
							signatureDate={this.state.data.signatureDate}
							numOfSignature={1}
							signatureTitles={this.state.data.signatureTitles}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	soapTime: {
		minWidth: "11%",
		maxWidth: "11%",
	},
	date: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	time: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	order: {
		minWidth: "78%",
		maxWidth: "78%",
	},
})

export default ReportOPDNurseOrderDocument
