import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SearchableCriteria from "~/view/component/search/SearchableCriteria"

class OPDModalSearchSearchable extends React.Component {
	constructor(props) {
		super(props)

		this.onChange = this.onChange.bind(this)
	}

	onChange(event) {
		this.props.setSearchableState(event.target.name, event.target.value)
	}

	render() {
		return <SearchableCriteria {...this.props} onChange={this.onChange} />
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalSearchSearchable)
