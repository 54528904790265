import { combineReducers } from "redux"
import hospitelReducer from "./hospitelReducer"
import ipdReducer from "./ipdReducer"
import labReducer from "./labReducer"
import cashierReducer from "./cashierReducer"

export default combineReducers({
	lab: labReducer,
	ipd: ipdReducer,
	hospitel: hospitelReducer,
	cashier: cashierReducer,
})
