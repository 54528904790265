import React from "react"
import { Col, Container, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OperatingRoomNurseTabPreOperativeCheckList from "./pre-operative-check-lists/OperatingRoomNurseTabPreOperativeCheckList"
import OperatingRoomNurseTabSurgicalSafetyCheckList from "./surgical-safety-check-lists/OperatingRoomNurseTabSurgicalSafetyCheckList"

class OperatingRoomRightTabNurse extends React.Component {
	ENurse = {
		Pre_Operative_Check_List: "Pre_Operative_Check_List",
		Surgical_Safety_Check_List: "Surgical_Safety_Ckeck_List",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.ENurse.Pre_Operative_Check_List,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"full-size"}>
					<Col>
						<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link eventKey={this.ENurse.Pre_Operative_Check_List}>{translate(ResourceAssistance.Message.preOperativeCheckList)}</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey={this.ENurse.Surgical_Safety_Check_List}>{translate(ResourceAssistance.Message.surgicalSafetyChecklist)}</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey={this.ENurse.Pre_Operative_Check_List}>
									{this.state.tab === this.ENurse.Pre_Operative_Check_List && <OperatingRoomNurseTabPreOperativeCheckList />}
								</Tab.Pane>
								<Tab.Pane eventKey={this.ENurse.Surgical_Safety_Check_List}>
									{this.state.tab === this.ENurse.Surgical_Safety_Check_List && <OperatingRoomNurseTabSurgicalSafetyCheckList />}
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OperatingRoomRightTabNurse)
