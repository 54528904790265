export const SET_ARC_MODAL_LOCATION_DISPLAY = "SET_ARC_MODAL_LOCATION_DISPLAY"
export const SET_ARC_LOCATION = "SET_ARC_LOCATION"
export const SET_ARC_MODAL_LOCATION_ORGS = "SET_ARC_MODAL_LOCATION_ORGS"
export const SET_ARC_MODAL_LOCATION_ORGS_LOADING = "SET_ARC_MODAL_LOCATION_ORGS_LOADING"
export const SET_ARC_MODAL_LOCATION_SELECTED_ORG = "SET_ARC_MODAL_LOCATION_SELECTED_ORG"
export const SET_ARC_START_DATE_TIME = "SET_ARC_START_DATE_TIME"
export const SET_ARC_END_DATE_TIME = "SET_ARC_END_DATE_TIME"
export const SET_ARC_RECEIPTS = "SET_ARC_RECEIPTS"
export const SET_ARC_FILTERS = "SET_ARC_FILTERS"
export const SET_ARC_FILTER_RECEIPT_ID = "SET_ARC_FILTER_RECEIPT_ID"
export const SET_ARC_SEARCH_DISPLAY = "SET_ARC_SEARCH_DISPLAY"
export const SET_ARC_SELECTED_RECEIPT = "SET_ARC_SELECTED_RECEIPT"
export const SET_ARC_SELECTED_GROUP = "SET_ARC_SELECTED_GROUP"
export const SET_ARC_TAB_RECEIPT_GROUP = "SET_ARC_TAB_RECEIPT_GROUP"
export const SET_ARC_BILLING_STATEMENT = "SET_ARC_BILLING_STATEMENT"
export const SET_ARC_SELECTED_BILLING_STATEMENT = "SET_ARC_SELECTED_BILLING_STATEMENT"
export const SET_ARC_AR_ACTIVITIES = "SET_ARC_AR_ACTIVITIES"
export const SET_ARC_AR_ACTIVITIES_LOADING = "SET_ARC_AR_ACTIVITIES_LOADING"
export const SET_ARC_SELECTABLE_ACCOUNTING_CODES = "SET_ARC_SELECTABLE_ACCOUNTING_CODES"
export const SET_ARC_TAB_GL_GROUP = "SET_ARC_TAB_GL_GROUP"
export const SET_ARC_INSURANCE_COMPANIES = "SET_ARC_INSURANCE_COMPANIES"
export const SET_ARC_INSURANCE_COMPANIES_LOADING = "SET_ARC_INSURANCE_COMPANIES_LOADING"
export const SET_ARC_PAYMENTS = "SET_ARC_PAYMENTS"
export const SET_ARC_PAYMENTS_LOADING = "SET_ARC_PAYMENTS_LOADING"
export const SET_ARC_BANKS = "SET_ARC_BANKS"
export const SET_ARC_BANKS_LOADING = "SET_ARC_BANKS_LOADING"
export const SET_ARC_MODAL_GENERAL_LEDGER_DISPLAY = "SET_ARC_MODAL_GENERAL_LEDGER_DISPLAY"
export const SET_ARC_MODAL_GL_SELECTED_GL = "SET_ARC_MODAL_GL_SELECTED_GL"
export const SET_ARC_MODAL_GL_DESCRIPTION = "SET_ARC_MODAL_GL_DESCRIPTION"
export const SET_ARC_CHART_OF_ACCOUNTS = "SET_ARC_CHART_OF_ACCOUNTS"
export const SET_ARC_GL_DATE_TIME = "SET_ARC_GL_DATE_TIME"
export const SET_ARC_MODAL_SEARCH_RECEIPT_ID = "SET_ARC_MODAL_SEARCH_RECEIPT_ID"
