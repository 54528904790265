import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_CASHIER_ADMISSIONS,
	SET_CASHIER_ADMISSION_BALANCE,
	SET_CASHIER_HEALTH_CARE_PLANS,
	SET_CASHIER_MODAL_CERTIFICATE_PENDING,
	SET_CASHIER_MODAL_CERTIFICATE_UNCHARGED,
	SET_CASHIER_MODAL_DOCTOR_ORDERS,
	SET_CASHIER_MODAL_DOCTOR_PENDING_ORDERS,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_BILLING_STATEMENTS,
	SET_CASHIER_MODAL_LAB_PENDING_LABS,
	SET_CASHIER_MODAL_LAB_UNCHARGED_LABS,
	SET_CASHIER_MODAL_NURSE_ORDERS,
	SET_CASHIER_MODAL_NURSE_PENDING_ORDERS,
	SET_CASHIER_MODAL_SEARCH_PATIENTS,
	SET_CASHIER_SERVICES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setCashierAdmissions = (admissions) => {
	return (dispatch, getState) => {
		let filtered = admissions.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = filtered.map((admission) => {
			let color = ""
			if (admission.dischargedBy) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		let body = filtered.map((admission) => {
			return [admission.id, Utils.formatDateTime(admission.creationDateTime), Utils.formatDateTime(admission.dischargedDateTime)]
		})

		dispatch({
			type: SET_CASHIER_ADMISSIONS,
			payload: {
				original: admissions,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierHealthCarePlans = (billingPlans) => {
	return (dispatch, getState) => {
		let filtered = billingPlans.filter((bp) => !_.isEmpty(bp.billingStatements)).sort((a, b) => Utils.sort(a.code, b.code))

		let rowColor = filtered.map((healthCarePlan) => {
			let color = ""
			if (!healthCarePlan.primaryHealthCarePlan || healthCarePlan.billingStatements.every((bs) => bs.billing)) {
				color = ResourceAssistance.CSS.Color.green
			} else if (healthCarePlan.billingStatements.some((bs) => bs.billing)) {
				color = color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((healthCarePlan) => {
			let balance = healthCarePlan.billingStatements.reduce((total, cur) => {
				return total.plus(cur.charge).minus(cur.adjustment)
			}, Utils.BigNumber(0))
			let adjustments = Utils.BigNumber(0).toFixed(2)
			let paid = Utils.BigNumber(0).toFixed(2)
			let unpaid = Utils.BigNumber(0).toFixed(2)

			adjustments = healthCarePlan.billingPlanAdjustments
				.reduce((total, cur) => {
					return total.plus(cur.discount)
				}, Utils.BigNumber(0))
				.toFixed(2)
			paid = healthCarePlan.billings
				.reduce((total, cur) => {
					return total.plus(cur.total).minus(cur.change)
				}, Utils.BigNumber(0))
				.toFixed(2)
			unpaid = balance.minus(paid).minus(adjustments).toFixed(2)

			return [
				[
					healthCarePlan.billingPlanAdjustments.some((bpAdj) => {
						return !bpAdj.billing && Utils.BigNumber(bpAdj.adjustments).gt(0)
					}),
				],
				healthCarePlan.code,
				healthCarePlan.name,
				Utils.formatNumWithComma(balance.toFixed(2)),
				Utils.formatNumWithComma(adjustments),
				Utils.formatNumWithComma(balance.minus(adjustments).toFixed(2)),
				Utils.formatNumWithComma(paid),
				Utils.formatNumWithComma(unpaid),
				[
					healthCarePlan.billingStatements.some((bs) => !bs.billing) &&
						!healthCarePlan.billingPlanAdjustments.some((bpAdj) => !bpAdj.billing && Utils.BigNumber(bpAdj.adjustments).gt(0)),
					healthCarePlan.billingStatements.some((bs) => !bs.billing),
					healthCarePlan.primaryHealthCarePlan && healthCarePlan.billingStatements.some((bs) => !bs.billing),
					getState().hospitel.cashier.selectedAdmission && getState().hospitel.cashier.selectedAdmission.admission.dischargedBy,
				],
			]
		})

		let isDisplayDropDownItems = filtered.map(() => {
			return [true, true]
		})
		let popoverMsg = filtered.map(() => {
			return [[[[true, ResourceAssistance.Warning.adjustmentBillingPlanCannotModify]]]]
		})

		dispatch({
			type: SET_CASHIER_HEALTH_CARE_PLANS,
			payload: {
				original: billingPlans,
				filtered: filtered,
				body: body,
				popoverMsg: popoverMsg,
				rowColor: rowColor,
				isDisplayDropDownItems: isDisplayDropDownItems,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalLabUnchargedLabs = (labs) => {
	return (dispatch, getState) => {
		let filtered = labs
			.filter((each) => {
				return (
					each.status !== ResourceAssistance.CONSTANT.CANCELLED &&
					!getState().hospitel.cashier.admissionTable.original.some((admission) => {
						//total by created billing statmement
						let billingStatementTotal = admission.billingStatements
							.filter((billingStatement) => billingStatement.lab && billingStatement.lab.id === each.id)
							.reduce((total, cur) => {
								return total.plus(cur.charge)
							}, Utils.BigNumber(0))

						//total by newly created billing statement
						let newBillingStatmentTotal = getState()
							.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
								(billingStatement) => !billingStatement.id && billingStatement.isLab && billingStatement.order && billingStatement.order.id === each.id
							)
							.reduce((total, cur) => {
								return total.plus(cur.charge)
							}, Utils.BigNumber(0))

						//total by pending billing statement
						let pendingBillingStatementTotal = getState()
							.hospitel.cashier.modalLab.pendingTable.original.filter((order) => order.id === each.id)
							.reduce((total, cur) => {
								return total.plus(cur.charge)
							}, Utils.BigNumber(0))

						return Utils.BigNumber(billingStatementTotal.plus(newBillingStatmentTotal).plus(pendingBillingStatementTotal).toFixed(2)).isGreaterThanOrEqualTo(
							each.service.pricePerUnit
						)
					})
				)
			})
			.sort((a, b) => Utils.sort(b.issuedDateTime, a.issuedDateTime))

		let body = filtered.map((lab) => {
			return [
				Utils.formatDate(lab.issuedDateTime),
				Utils.formatTime(lab.issuedDateTime),
				lab.id,
				lab.status,
				lab.service.name,
				Utils.formatNumWithComma(
					Utils.BigNumber(lab.service.pricePerUnit)
						.minus(
							getState()
								.hospitel.cashier.selectedAdmission.admission.billingStatements.filter(
									(billingStatement) => billingStatement.lab && billingStatement.lab.id === lab.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
									(billingStatement) => !billingStatement.id && billingStatement.isLab && billingStatement.order.id === lab.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalLab.pendingTable.original.filter((each) => each.id === lab.id)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.toFixed(2)
				),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_LAB_UNCHARGED_LABS,
			payload: {
				original: labs,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalLabPendingLabs = (labs) => {
	return (dispatch, getState) => {
		let filtered = labs.sort((a, b) => Utils.sort(b.issuedDateTime, a.issuedDateTime))

		let body = filtered.map((lab) => {
			return [
				Utils.formatDate(lab.issuedDateTime),
				Utils.formatTime(lab.issuedDateTime),
				lab.id,
				lab.status,
				lab.service.name,
				Utils.formatNumWithComma(Utils.BigNumber(lab.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(lab.adjustment).negated().toFixed(2)),
				lab.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(lab.charge).minus(lab.adjustment).toFixed(2)),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_LAB_PENDING_LABS,
			payload: {
				original: labs,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = filtered.map((service) => {
			let color = ""
			if (service.billing) {
				color = ResourceAssistance.CSS.Color.green
			} else if (service.doctorOrder && service.doctorOrder.prescriptionOnly) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((service) => {
			return [
				service.name,
				service.description,
				Utils.formatNumWithComma(Utils.BigNumber(service.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(service.adjustment).toFixed(2)),
				service.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(service.charge).minus(service.adjustment).toFixed(2)),
				[!service.billing, !service.billing && service.doctorOrder && service.doctorOrder.code],
			]
		})

		dispatch({
			type: SET_CASHIER_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalCertificateUncharged = (certificates) => {
	return (dispatch, getState) => {
		let filtered = certificates
			.filter((each) => {
				return !getState().hospitel.cashier.admissionTable.original.some((admission) => {
					//total by created billing statmement
					let billingStatementTotal = admission.billingStatements
						.filter((billingStatement) => billingStatement.certificate && billingStatement.certificate.id === each.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					//total by newly created billing statement
					let newBillingStatmentTotal = getState()
						.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
							(billingStatement) => !billingStatement.id && billingStatement.isCertificate && billingStatement.order && billingStatement.order.id === each.id
						)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					//total by pending billing statement
					let pendingBillingStatementTotal = getState()
						.hospitel.cashier.modalCertificate.pendingTable.original.filter((order) => order.id === each.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					return Utils.BigNumber(billingStatementTotal.plus(newBillingStatmentTotal).plus(pendingBillingStatementTotal).toFixed(2)).isGreaterThanOrEqualTo(
						each.service.pricePerUnit
					)
				})
			})
			.sort((a, b) => Utils.sort(b.examinationStartDateTime, a.examinationStartDateTime))

		let body = filtered.map((certificate) => {
			return [
				certificate.id,
				Utils.formatDate(certificate.examinationStartDateTime),
				Utils.formatDate(certificate.examinationEndDateTime),
				certificate.symptoms,
				certificate.diagnosis,
				certificate.doctorOpinion,
				certificate.doctorName,
				Utils.formatNumWithComma(
					Utils.BigNumber(certificate.service.pricePerUnit)
						.minus(
							getState()
								.hospitel.cashier.selectedAdmission.admission.billingStatements.filter(
									(billingStatement) => billingStatement.certificate && billingStatement.certificate.id === certificate.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
									(billingStatement) => !billingStatement.id && billingStatement.isCertificate && billingStatement.order.id === certificate.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalCertificate.pendingTable.original.filter((each) => each.id === certificate.id)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.toFixed(2)
				),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_CERTIFICATE_UNCHARGED,
			payload: {
				original: certificates,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalCertificatePending = (certificates) => {
	return (dispatch, getState) => {
		let filtered = certificates.sort((a, b) => Utils.sort(b.examinationStartDateTime, a.examinationStartDateTime))

		let body = filtered.map((certificate) => {
			return [
				certificate.id,
				Utils.formatDate(certificate.examinationStartDateTime),
				Utils.formatDate(certificate.examinationEndDateTime),
				certificate.symptoms,
				certificate.diagnosis,
				certificate.doctorOpinion,
				certificate.doctorName,
				Utils.formatNumWithComma(Utils.BigNumber(certificate.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(certificate.adjustment).negated().toFixed(2)),
				certificate.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(certificate.charge).minus(certificate.adjustment).toFixed(2)),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_CERTIFICATE_PENDING,
			payload: {
				original: certificates,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalSearchPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients.sort((a, b) => Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber()))

		let body = filtered.map((patient) => {
			return [patient.id, patient.identificationNumber, patient.firstName, patient.lastName, Utils.formatDate(patient.dobtime)]
		})

		dispatch({
			type: SET_CASHIER_MODAL_SEARCH_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalDoctorOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders
			.filter((each) => {
				return !getState().hospitel.cashier.admissionTable.original.some((admission) => {
					//total by created billing statmement
					let billingStatementTotal = admission.billingStatements
						.filter((billingStatement) => billingStatement.doctorOrder && billingStatement.doctorOrder.id === each.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					//total by newly created billing statement
					let newBillingStatmentTotal = getState()
						.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
							(billingStatement) => !billingStatement.id && billingStatement.isDoctorOrder && billingStatement.order && billingStatement.order.id === each.id
						)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					//total by pending billing statement
					let pendingBillingStatementTotal = getState()
						.hospitel.cashier.modalDoctor.pendingOrderTable.original.filter((order) => order.id === each.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					return Utils.BigNumber(billingStatementTotal.plus(newBillingStatmentTotal).plus(pendingBillingStatementTotal).toFixed(2)).isGreaterThanOrEqualTo(
						Utils.BigNumber(Utils.calculateDoctorOrderBalance(each)).minus(
							Utils.calculatePharmacyReturnOrderBalance(each.pharmacyReturnOrders, each.pricePerUnit)
						)
					)
				})
			})
			.sort((a, b) => Utils.sort(a.startDateTime, b.startDateTime))

		let rowColor = []
		rowColor = filtered.map((order) => {
			let color = ""
			if (order.endDateTime > Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((order) => {
			return [
				order.code ? order.code : order.serviceCode,
				order.fsCode,
				order.description,
				order.notes,
				order.code ? Utils.formatDateTime(order.startDateTime) : Utils.formatDate(order.startDateTime),
				order.code ? Utils.formatDateTime(order.endDateTime) : Utils.formatDate(order.endDateTime),
				Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(
					Utils.BigNumber(Utils.calculateDoctorOrderBalance(order))
						.minus(
							getState()
								.hospitel.cashier.selectedAdmission.admission.billingStatements.filter(
									(billingStatement) => billingStatement.doctorOrder && billingStatement.doctorOrder.id === order.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
									(billingStatement) => !billingStatement.id && billingStatement.isDoctorOrder && billingStatement.order.id === order.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalDoctor.pendingOrderTable.original.filter((each) => each.id === order.id)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit))
						.toFixed(2)
				),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_DOCTOR_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalDoctorPendingOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders.sort((a, b) => Utils.sort(a.startDateTime, b.startDateTime))

		let body = filtered.map((order) => {
			return [
				order.code ? order.code : order.serviceCode,
				order.fsCode,
				order.description,
				Utils.formatNumWithComma(Utils.BigNumber(order.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(order.adjustment).negated().toFixed(2)),
				order.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(order.charge).minus(order.adjustment).toFixed(2)),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_DOCTOR_PENDING_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalNurseOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders
			.filter((each) => {
				return !getState().hospitel.cashier.admissionTable.original.some((admission) => {
					//total by created billing statmement
					let billingStatementTotal = admission.billingStatements
						.filter((billingStatement) => billingStatement.nurseOrder && billingStatement.nurseOrder.id === each.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					//total by newly created billing statement
					let newBillingStatmentTotal = getState()
						.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
							(billingStatement) => !billingStatement.id && billingStatement.isNurseOrder && billingStatement.order && billingStatement.order.id === each.id
						)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					//total by pending billing statement
					let pendingBillingStatementTotal = getState()
						.hospitel.cashier.modalNurse.pendingOrderTable.original.filter((order) => order.id === each.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					return billingStatementTotal
						.plus(newBillingStatmentTotal)
						.plus(pendingBillingStatementTotal)
						.isGreaterThanOrEqualTo(
							Utils.BigNumber(Utils.calculateNurseOrderBalance(each)).minus(
								Utils.calculatePharmacyReturnOrderBalance(each.pharmacyReturnOrders, each.pricePerUnit)
							)
						)
				})
			})
			.sort((a, b) => Utils.sort(a.startDateTime, b.startDateTime))

		let rowColor = []
		rowColor = filtered.map((order) => {
			let color = ""
			if (!order.endDateTime || order.endDateTime > Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((order) => {
			return [
				order.code ? order.code : order.serviceCode,
				order.fsCode,
				order.description,
				order.notes,
				Utils.formatDate(order.startDateTime),
				Utils.formatDate(order.endDateTime),
				Utils.formatNumWithComma(order.qtyPerDay),
				Utils.formatNumWithComma(Utils.BigNumber(order.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(
					Utils.BigNumber(Utils.calculateNurseOrderBalance(order))
						.minus(
							getState()
								.hospitel.cashier.selectedAdmission.admission.billingStatements.filter(
									(billingStatement) => billingStatement.nurseOrder && billingStatement.nurseOrder.id === order.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalFinancialPlan.serviceTable.original.filter(
									(billingStatement) => !billingStatement.id && billingStatement.isNurseOrder && billingStatement.order.id === order.id
								)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(
							getState()
								.hospitel.cashier.modalNurse.pendingOrderTable.original.filter((each) => each.id === order.id)
								.reduce((total, cur) => {
									return total.plus(cur.charge)
								}, Utils.BigNumber(0))
						)
						.minus(Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit))
						.toFixed(2)
				),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_NURSE_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalNursePendingOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders.sort((a, b) => Utils.sort(a.startDateTime, b.startDateTime))

		let body = filtered.map((order) => {
			return [
				order.code ? order.code : order.serviceCode,
				order.fsCode,
				order.description,
				Utils.formatNumWithComma(Utils.BigNumber(order.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(order.adjustment).negated().toFixed(2)),
				order.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(order.charge).minus(order.adjustment).toFixed(2)),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_NURSE_PENDING_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierModalFinancialPlanBillingStatements = (billingStatements) => {
	return (dispatch, getState) => {
		let filtered = billingStatements.filter((bs) => !bs.billing)

		let body = filtered.map((billingStatement) => {
			return [
				billingStatement.name,
				billingStatement.description,
				Utils.formatNumWithComma(Utils.BigNumber(billingStatement.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(billingStatement.adjustment).toFixed(2)),
				billingStatement.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(billingStatement.charge).minus(billingStatement.adjustment).toFixed(2)),
			]
		})

		dispatch({
			type: SET_CASHIER_MODAL_FINANCIAL_PLAN_BILLING_STATEMENTS,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setCashierAdmissionBalance = (admission) => {
	return (dispatch, getState) => {
		if (!admission) {
			dispatch({
				type: SET_CASHIER_ADMISSION_BALANCE,
				payload: {
					body: [],
				},
			})
			return Promise.resolve()
		}

		let filtered = admission.billingPlans

		let total = admission.billingStatements
			.reduce((total, cur) => {
				return total.plus(cur.charge).minus(cur.adjustment)
			}, Utils.BigNumber(0))
			.toFixed(2)
		let adjustments = filtered
			.reduce((adjustmentTotal, curBillingPlan) => {
				return adjustmentTotal.plus(
					curBillingPlan.billingPlanAdjustments.reduce((total, cur) => {
						return total.plus(cur.discount)
					}, Utils.BigNumber(0))
				)
			}, Utils.BigNumber(0))
			.toFixed(2)
		let balance = Utils.BigNumber(total).minus(adjustments).toFixed(2)
		let totalPaid = filtered
			.reduce((total, cur) => {
				return total.plus(
					cur.billings.reduce((billingTotal, billingCur) => {
						return billingTotal.plus(billingCur.total).minus(billingCur.change)
					}, Utils.BigNumber(0))
				)
			}, Utils.BigNumber(0))
			.toFixed(2)
		let totalUnpaid = Utils.BigNumber(total).minus(adjustments).minus(totalPaid).toFixed(2)

		let rowColor = [[true, ""]]

		let body = [
			[
				Utils.formatNumWithComma(total),
				Utils.formatNumWithComma(adjustments),
				Utils.formatNumWithComma(balance),
				Utils.formatNumWithComma(totalPaid),
				Utils.formatNumWithComma(totalUnpaid),
			],
		]

		dispatch({
			type: SET_CASHIER_ADMISSION_BALANCE,
			payload: {
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
