import { Button as AButton, Card, Descriptions, Tooltip } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { FaCheck } from "react-icons/fa6"
import { LiaFileMedicalAltSolid } from "react-icons/lia"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PATIENT_CONDITION_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PATIENT_SAFETY_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PREINDUCTION_ASSESSMENT_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PREMEDICATION_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_PROBLEM_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY,
	SET_OR_INTRA_ANESTHESIA_TRANSFER_DISPLAY,
	SET_OR_SELECTED_INTRA_ANE_RECORD,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { CheckBoxGroupUtils } from "~/utils/component/CheckBoxGroupUtils"
import { Utils } from "~/utils/Utils"
import {
	CBAfterIntubation,
	CBAssistedDevices,
	CBBladeType,
	CBCuffedInflation,
	CBMethod,
	RBladeType,
	RETTubeNoCuff,
	RETTubeNoOral,
	RIntubationTechnique,
} from "../../../_modal/anesthesia-intra/airway-management/OperatingRoomModalIntraAneAirwayDetails"
import {
	CBEquipment,
	CBGeneralAnesthesia,
	CBMonitoredAnesthesiaCare,
	RInduction,
	RMaintenance,
	RRegionalAnesthesia,
	RType,
} from "../../../_modal/anesthesia-intra/anesthetic-technique/OperatingRoomModalIntraAneTechniqueDetails"
import { RGender } from "../../../_modal/anesthesia-intra/baby-delivery/OperatingRoomModalIntraAneBabyDeliveryDetails"
import { CBPosition, RRespiration } from "../../../_modal/anesthesia-intra/patient-condition/OperatingRoomModalIntraAnePatientConditionDetails"
import { CBEyesCare, CBWarming } from "../../../_modal/anesthesia-intra/patient-safety/OperatingRoomModalIntraAnesthePatientSafetyDetails"
import { CBConsciousness } from "../../../_modal/anesthesia-intra/preinduction-assessment/OperatingRoomModalIntraAnePreinductionAssessmentDetails"
import {
	RAirwaySupport,
	RConsciousness,
	ROxygen,
	RRespiration as RTransferRespiration,
	RTransferTo,
} from "../../../_modal/anesthesia-intra/transfer/OperatingRoomModalIntraAneTransferDetails"
import { axios } from "~/axios"

class OperatingRoomAnesthesiaTabIntra extends React.Component {
	constructor(props) {
		super(props)

		this.onAnesthesiaRecord = this.onAnesthesiaRecord.bind(this)
		this.onApprove = this.onApprove.bind(this)
		this.onPatientSafety = this.onPatientSafety.bind(this)
		this.onPreinductionAssessment = this.onPreinductionAssessment.bind(this)
		this.onProblem = this.onProblem.bind(this)
		this.onPremedication = this.onPremedication.bind(this)
		this.onPatientCondition = this.onPatientCondition.bind(this)
		this.onTechnique = this.onTechnique.bind(this)
		this.onAirwayManagement = this.onAirwayManagement.bind(this)
		this.onBabyDelivery = this.onBabyDelivery.bind(this)
		this.onTransfer = this.onTransfer.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)) {
			this.props.setSelected(SET_OR_SELECTED_INTRA_ANE_RECORD, 0, this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.approveIntraAnesthesiaRecord)) {
				this.approve()
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.selectedRequest.request, this.props.operatingRoom.selectedRequest.request)) {
			if (this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)) {
				this.props.setSelected(SET_OR_SELECTED_INTRA_ANE_RECORD, 0, this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0])
			} else {
				this.props.setSelected(SET_OR_SELECTED_INTRA_ANE_RECORD, -1, null)
			}
		}
	}

	isApproveDisabled() {
		return !this.props.operatingRoom.selectedIntraAneRecord.record || this.props.operatingRoom.selectedIntraAneRecord.record.approver
	}

	approve() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.approveAnesthesiaRecord,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				intraAnesthesiaRecordId: this.props.operatingRoom.selectedIntraAneRecord.record.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		let op =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
				: {}
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className="full-size">
					<Col>
						<Descriptions
							style={{ flex: "1", flexDirection: "column", overflowY: "auto", overflowX: "hidden" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={5}
							extra={
								<Container fluid className={"full-size full-flex"}>
									<Row className="g-2" style={{ marginBottom: "0" }}>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.anesthesiaRecord)}>
												<AButton
													type="primary"
													size="middle"
													shape="round"
													icon={<LiaFileMedicalAltSolid size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onAnesthesiaRecord}
												/>
											</Tooltip>
										</Col>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.approve)}>
												<AButton
													style={{
														background: this.isApproveDisabled() ? undefined : ResourceAssistance.CSS.Color.dark_green,
													}}
													type="primary"
													size="middle"
													shape="round"
													icon={<FaCheck size={ResourceAssistance.ReactIcon.size} />}
													disabled={this.isApproveDisabled()}
													onClick={this.onApprove}
												/>
											</Tooltip>
										</Col>
									</Row>
								</Container>
							}
						>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.patientSafety)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onPatientSafety}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.pressurePointsChecked)} span={1}>
								{Utils.getYesNoMsg(op.pressurePointsChecked)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.armsRestrained)} span={1}>
								{Utils.getYesNoMsg(op.armsRestrained)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.anestheticMachineEquipmentsChecked)} span={3}>
								{Utils.getYesNoMsg(op.anestheticMachineChecked)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.eyesCare)} span={2}>
								{op.eyesCare === undefined ? "" : `${CheckBoxGroupUtils.getKeyStr(CBEyesCare, op.eyesCare, [CBEyesCare.Other])}, ${op.eyesCareOther}`}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.warming)} span={3}>
								{op.warming === undefined ? "" : `${CheckBoxGroupUtils.getKeyStr(CBWarming, op.warming, [CBWarming.Other])}, ${op.warmingOther}`}
							</Descriptions.Item>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.preinductionAssessment)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button
														size={ResourceAssistance.Button.size.sm}
														variant={ResourceAssistance.Button.variant.link}
														onClick={this.onPreinductionAssessment}
													>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.NPOHour)} span={1}>
								{op.npoHours}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.ASAClass)} span={1}>
								{op.asaClass
									? `${op.asaClass.split(",")[0]} ${
											op.asaClass.split(",")[1] ? this.props.intl.formatMessage({ id: ResourceAssistance.Message.emergency }) : ""
									  }`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.changesFromThePreOpAssessment)} span={3}>
								{op.changesFromPreAssessment ? `${Utils.getYesNoMsg(op.changesFromPreAssessment)}, ${op.changesFromPreAssessmentOther}` : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.PtIdentified)} span={1}>
								{op.patientIdentified ? Utils.getYesNoMsg(op.patientIdentified) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.consentSigned)} span={1}>
								{op.consentSigned ? Utils.getYesNoMsg(op.consentSigned) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.riskForAspiration)} span={1}>
								{op.riskForAspiration ? Utils.getYesNoMsg(op.riskForAspiration) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.HxPELabReviewed)} span={1}>
								{op.labReviewed ? Utils.getYesNoMsg(op.labReviewed) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.predictedDifficultAirway)} span={1}>
								{op.predictedDifficultAirway ? Utils.getYesNoMsg(op.predictedDifficultAirway) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bpmmHg)} span={1}>
								{op.bp ? op.bp : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.HRbpm)} span={1}>
								{op.hr ? op.hr : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.SpO2)} span={3}>
								{op.spo2 ? op.spo2 : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.consciousness)} span={5}>
								{op.consciousness ? `${CheckBoxGroupUtils.getKeyStr(CBConsciousness, op.consciousness)}` : ""}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.problem)} span={5}>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											<Col md="auto">{op.problem ? op.problem : ""}</Col>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onProblem}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.premedication)} span={5}>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											<Col md="auto">{op.premedication ? op.premedication : ""}</Col>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onPremedication}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.patientCondition)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onPatientCondition}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.position)} span={3}>
								{op.position ? CheckBoxGroupUtils.getKeyStr(CBPosition, op.position) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.respiration)} span={2}>
								{op.respiration ? CheckBoxGroupUtils.getKeyStr(RRespiration, op.respiration) : ""}
							</Descriptions.Item>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.anesthesiaTechnique)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onTechnique}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.generalAnesthesia)} span={5}>
								{op.generalAnesthesia ? CheckBoxGroupUtils.getKeyStr(CBGeneralAnesthesia, op.generalAnesthesia) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.induction)} span={2}>
								{op.induction ? CheckBoxGroupUtils.getKeyStr(RInduction, op.induction) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.maintenance)} span={3}>
								{op.maintenance ? `${CheckBoxGroupUtils.getKeyStr(RMaintenance, op.maintenance, [RMaintenance.Other])} ${op.maintenanceOther}` : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.regionalAnesthesia)} span={5}>
								{op.regionalAnesthesia
									? `${CheckBoxGroupUtils.getKeyStr(RRegionalAnesthesia, op.regionalAnesthesia, [RRegionalAnesthesia.Other])} ${op.regionalAnesthesiaOther}`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.asepticTechniqueDone)} span={1}>
								{op.asepticTechniqueDone ? Utils.getYesNoMsg(op.asepticTechniqueDone) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.techniqueApproach)} span={1}>
								{op.techniqueApproach ? `${Utils.getYesNoMsg(op.techniqueApproach)}, ${op.techniqueApproachOther}` : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.equipment)} span={3}>
								{op.equipment ? CheckBoxGroupUtils.getKeyStr(CBEquipment, op.equipment) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.site)} span={1}>
								{op.site ? op.site : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.position)} span={1}>
								{op.regionalAnesthesiaPosition ? op.regionalAnesthesiaPosition : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.needleSize)} span={3}>
								{op.needleSize ? op.needleSize : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.type)} span={1}>
								{op.type ? CheckBoxGroupUtils.getKeyStr(RType, op.type) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.catheter)} span={1}>
								{op.catheter
									? `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.skinMarking })} ${op.catheter}${ResourceAssistance.CONSTANT.CM}`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.punctureAttemptingCounts)} span={1}>
								{op.punctureAttemptingCounts ? op.punctureAttemptingCounts : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.anesthesiaLevel)} span={1}>
								{op.anesthesiaLevel ? op.anesthesiaLevel : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.agentUsed)} span={1}>
								{op.agentUsed ? op.agentUsed : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.monitorAnesthesiaCare)} span={5}>
								{op.monitorAnesthesiaCare
									? `${Utils.getYesNoMsg(op.monitorAnesthesiaCare.split(",")[0])}, ${CheckBoxGroupUtils.getKeyStr(
											CBMonitoredAnesthesiaCare,
											op.monitorAnesthesiaCare.split(",")[1]
									  )}`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.airwayManagement)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onAirwayManagement}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.method)} span={3}>
								{op.method
									? CheckBoxGroupUtils.getKeyStr(CBMethod, op.method, [], {
											[CBMethod.Oral_Airway_No]: op.methodOralAirwayNo,
											[CBMethod.LMA_No]: op.methodLMANo,
											[CBMethod.Tracheostomy_No]: op.methodTracheostomyNo,
											[CBMethod.Nasal_Airway_No]: op.methodNasalAirwayNo,
											[CBMethod.ET_Tube_No]: _.isEqual(op.methodETTubeNoOral, RETTubeNoOral.Nasal)
												? `${op.methodETTubeNo} ${CheckBoxGroupUtils.getKeyStr(RETTubeNoCuff, op.methodETTubeNoCuff)} ${CheckBoxGroupUtils.getKeyStr(
														RETTubeNoOral,
														op.methodETTubeNoOral
												  )} ${op.methodETTubeNoDepth}${ResourceAssistance.CONSTANT.CM}`
												: `${op.methodETTubeNo} ${op.methodETTubeNoCuff} ${op.methodETTubeNoOral}`,
									  })
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.type)} span={2}>
								{op.airwayType ? op.airwayType : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.laryngealView)} span={1}>
								{op.laryngealView ? op.laryngealView : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.difficulty)} span={1}>
								{op.laryngealViewDifficulty ? Utils.getYesNoMsg(op.laryngealViewDifficulty) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.attempt)} span={3}>
								{op.laryngealViewAttempt ? op.laryngealViewAttempt : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.intubationTechnique)} span={1}>
								{op.intubationTechnique ? CheckBoxGroupUtils.getKeyStr(RIntubationTechnique, op.intubationTechnique) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bladeType)} span={1}>
								{op.bladeType ? CheckBoxGroupUtils.getKeyStr(RBladeType, op.bladeType) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bladeSize)} span={1}>
								{op.bladeSize ? `${op.bladeSize} ${CheckBoxGroupUtils.getKeyStr(CBBladeType, op.bladeStylet)}` : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.assistedDevices)} span={2}>
								{op.assistedDevice ? CheckBoxGroupUtils.getKeyStr(CBAssistedDevices, op.assistedDevice) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.afterIntubation)} span={3}>
								{op.afterIntubation
									? CheckBoxGroupUtils.getKeyStr(CBAfterIntubation, op.afterIntubation, [], {
											[CBAfterIntubation.Cuffed_Inflation]: CheckBoxGroupUtils.getKeyStr(CBCuffedInflation, op.cuffedInflation),
									  })
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.breathingSystem)} span={2}>
								{op.breathingSystem ? op.breathingSystem : ""}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} label={translate(ResourceAssistance.Message.beforeExtubation)} span={5} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.verbalCommand)} span={1}>
								{op.verbalCommand ? Utils.getYesNoMsg(op.verbalCommand) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.coughOnSuction)} span={1}>
								{op.coughOnSuction ? Utils.getYesNoMsg(op.coughOnSuction) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.adeqSpontVentilator)} span={1}>
								{op.adeqSpontVentilator ? Utils.getYesNoMsg(op.adeqSpontVentilator) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.swallowingGag)} span={1}>
								{op.swallowingGag ? Utils.getYesNoMsg(op.swallowingGag) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.handGripHeadLift)} span={1}>
								{op.handGrip ? Utils.getYesNoMsg(op.handGrip) : ""}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} label={translate(ResourceAssistance.Message.afterExtubation)} span={5} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.dyspnea)} span={1}>
								{op.dyspnea ? Utils.getYesNoMsg(op.dyspnea) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.UAO)} span={1}>
								{op.uao ? Utils.getYesNoMsg(op.uao) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.wheezing)} span={1}>
								{op.wheezing ? Utils.getYesNoMsg(op.wheezing) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.cyanosis)} span={2}>
								{op.cyanosis ? Utils.getYesNoMsg(op.cyanosis) : ""}
							</Descriptions.Item>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.babyDelivery)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onBabyDelivery}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.timeOfBirth)} span={1}>
								{op.timeOfBirth ? op.timeOfBirth : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.gender)} span={4}>
								{op.gender ? CheckBoxGroupUtils.getKeyStr(RGender, op.gender) : ""}
							</Descriptions.Item>
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.patientTransfer)}
								span={5}
							>
								{(!this.props.operatingRoom.selectedIntraAneRecord.record || !this.props.operatingRoom.selectedIntraAneRecord.record.approver) && (
									<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
										<Row className="g-1" style={{ marginBottom: 0 }}>
											{this.props.operatingRoom.selectedRequest.request && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onTransfer}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
										</Row>
									</Container>
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bpmmHg)} span={1}>
								{op.transferBP ? op.transferBP : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.HRbpm)} span={1}>
								{op.transferHR ? op.transferHR : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.SpO2)} span={3}>
								{op.transferSpo2 ? op.transferSpo2 : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.oxygen)} span={1}>
								{op.oxygen ? CheckBoxGroupUtils.getKeyStr(ROxygen, op.oxygen) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.airwaySupport)} span={1}>
								{op.airwaySupport ? CheckBoxGroupUtils.getKeyStr(RAirwaySupport, op.airwaySupport) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.respiration)} span={1}>
								{op.transferRespiration ? CheckBoxGroupUtils.getKeyStr(RTransferRespiration, op.transferRespiration) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.consciousness)} span={2}>
								{op.transferConsciousness ? CheckBoxGroupUtils.getKeyStr(RConsciousness, op.transferConsciousness) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.transferTo)} span={5}>
								{op.transferTo ? `${CheckBoxGroupUtils.getKeyStr(RTransferTo, op.transferTo, [RTransferTo.Other])} ${op.transferToOther}` : ""}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold", marginRight: "5px" }} label={translate(ResourceAssistance.Message.approvedBy)} span={5}>
								{op.approver ? op.approver.displayName : ""}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Container>
		)
	}
	onAnesthesiaRecord() {
		let op =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
				: {}
		if ((op.anesthesiaStartDateTime && op.anesthesiaEndDateTime) || (op.operativeStartDateTime && op.operativeEndDateTime)) {
			this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY, true)
		} else {
			this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY, true)
		}
	}
	onApprove() {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.approveIntraAnesthesiaRecord)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.approve)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions
					style={{ flex: "unset", display: "unset" }}
					size={"small"}
					contentStyle={{
						fontStyle: "italic",
						fontWeight: "normal",
						textAlign: "left",
					}}
					column={1}
				>
					<Descriptions.Item label={translate(ResourceAssistance.Message.approvedBy)} span={1}>
						{this.props.login.user.displayName}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
	onPatientSafety() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_PATIENT_SAFETY_DISPLAY, true)
	}
	onPreinductionAssessment() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_PREINDUCTION_ASSESSMENT_DISPLAY, true)
	}
	onProblem() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_PROBLEM_DISPLAY, true)
	}
	onPremedication() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_PREMEDICATION_DISPLAY, true)
	}
	onPatientCondition() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_PATIENT_CONDITION_DISPLAY, true)
	}
	onTechnique() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY, true)
	}
	onAirwayManagement() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY, true)
	}
	onBabyDelivery() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY, true)
	}
	onTransfer() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_TRANSFER_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomAnesthesiaTabIntra))
