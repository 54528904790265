import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmSDisplayAll,
	setAdmSModalNSActive,
	setAdmSModalNSAddress,
	setAdmSModalNSCode,
	setAdmSModalNSName,
	setAdmSModalNSPhone,
	setAdmSSelectedSupplier,
	setAdmSSuppliers,
	setSearchText,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_SUPPLIER_SEARCH_CODE,
	SET_ADMIN_SUPPLIER_SEARCH_NAME,
	SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT,
	SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE,
	SET_ADM_SUPPLIER_MODAL_NEW_EMAIL,
	SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID,
	SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaRegEdit } from "react-icons/fa"
import { Divider } from "antd"

class AdmSupplier extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmSSelectedSupplier(-1, null)
		this.props.setSearchText(SET_ADMIN_SUPPLIER_SEARCH_CODE, "")
		this.props.setSearchText(SET_ADMIN_SUPPLIER_SEARCH_NAME, "")
		this.props.setAdmSDisplayAll(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.supplier.searchCode !== this.props.supplier.searchCode ||
			prevProps.supplier.searchName !== this.props.supplier.searchName ||
			prevProps.supplier.displayInactive !== this.props.supplier.displayInactive
		) {
			this.props.setAdmSSelectedSupplier(-1, null)
			this.props.setAdmSSuppliers(this.props.supplier.supplierTable.original)
		}
	}

	onSearchCode(event) {
		this.props.setSearchText(SET_ADMIN_SUPPLIER_SEARCH_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_SUPPLIER_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmSDisplayAll(event.target.checked)
	}

	onAdd() {
		this.props.setAdmSSelectedSupplier(-1, null)
		this.props.onModalDisplayAction(SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY, true)
	}

	onEdit() {
		this.props.setAdmSModalNSCode(this.props.supplier.selectedSupplier.supplier.code)
		this.props.setAdmSModalNSName(this.props.supplier.selectedSupplier.supplier.displayName)
		this.props.setAdmSModalNSPhone(this.props.supplier.selectedSupplier.supplier.phone)
		this.props.setAdmSModalNSAddress(this.props.supplier.selectedSupplier.supplier.address)
		this.props.setAdmSModalNSActive(this.props.supplier.selectedSupplier.supplier.active)
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID, this.props.supplier.selectedSupplier.supplier.taxId)
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT, this.props.supplier.selectedSupplier.supplier.bankAccount)
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE, this.props.supplier.selectedSupplier.supplier.bankCode)
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_EMAIL, this.props.supplier.selectedSupplier.supplier.email)
		this.props.onModalDisplayAction(SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY, true)
	}

	onSelectRow(row, index) {
		this.props.setAdmSSelectedSupplier(index, this.props.supplier.supplierTable.filtered[index])
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.supplier.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.supplier.selectedSupplier.index === -1} onClick={this.onEdit}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.supplier.selectedSupplier.index === -1}
							data={this.props.supplier.supplierTable}
							onClick={this.onSelectRow}
						/>
						{this.props.supplier.supplierTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	supplier: state.admin.itemConfig.supplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmSSelectedSupplier,
			setSearchText,
			setAdmSSuppliers,
			onModalDisplayAction,
			setAdmSDisplayAll,
			setAdmSModalNSCode,
			setAdmSModalNSName,
			setAdmSModalNSPhone,
			setAdmSModalNSAddress,
			setAdmSModalNSActive,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmSupplier)
