import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME, SET_DEBT_MODAL_PAYMENT_CHECK_NUM } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"
import { setValue } from "~/redux/action"

class DebtModalPaymentCheck extends React.Component {
	constructor(props) {
		super(props)

		this.onCheckNum = this.onCheckNum.bind(this)
		this.onCheckDate = this.onCheckDate.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME, moment().startOf("day").milliseconds(0).valueOf())
	}

	componentWillUnmount() {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_CHECK_NUM, "")
	}

	onCheckNum(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_CHECK_NUM, e.target.value)
	}

	onCheckDate(value, dateString) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME, moment(value).startOf("day").milliseconds(0).valueOf())
	}

	render() {
		return (
			<Row>
				<Col>
					<GInput
						style={{ textAlign: "center" }}
						placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.checkNum })}
						onChange={this.onCheckNum}
						value={this.props.debt.modalPayment.checkNum}
					/>
				</Col>
				<Col md="auto">
					<AntDatePicker
						allowClear={false}
						size={"large"}
						format={"DD-MM-YYYY"}
						values={[moment(this.props.debt.modalPayment.checkDateTime)]}
						onChange={this.onCheckDate}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalPaymentCheck))
