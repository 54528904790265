import { LOCALES } from "../locale"

const zh = {
	[LOCALES.CHINESE]: {
		login: "登录",
		username: "用户名",
		password: "密码",
		logout: "登出",
		loginError: "用户名/密码错误！",
	},
}

export default zh
