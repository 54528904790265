import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME, SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { setValue } from "~/redux/action"

class PharmacyModalVerifyPhamacistOrder extends React.Component {
	componentWillUnmount() {
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME, "")
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME, "")
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pharmacistVerified)}</legend>
						<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={4}>
							<Descriptions.Item label={translate(ResourceAssistance.Message.startDate)} span={1}>
								{Utils.formatDate(this.props.pharmacy.modalVerify.startDateTime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.endDate)} span={1}>
								{Utils.formatDate(this.props.pharmacy.modalVerify.endDateTime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
								{this.props.pharmacy.modalVerify.isDoctorOrder
									? Utils.formatNumWithComma(
											Utils.calculateDoctorOrderQty(
												this.props.pharmacy.selectedDoctorOrder.doctorOrder,
												this.props.pharmacy.modalVerify.startDateTime,
												this.props.pharmacy.modalVerify.endDateTime
											)
									  )
									: Utils.formatNumWithComma(
											Utils.calculateNurseOrderQty(
												this.props.pharmacy.selectedDoctorOrder.doctorOrder,
												this.props.pharmacy.modalVerify.startDateTime,
												this.props.pharmacy.modalVerify.endDateTime
											)
									  )}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.unit)} span={1}>
								{this.props.pharmacy.selectedDoctorOrder.doctorOrder.unit}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalVerifyPhamacistOrder)
