import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class RegModalExpenseEstPatientInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={3}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={2}>
								{this.props.reg.selectedPatient.patient
									? this.props.reg.selectedPatient.patient.identificationNumber
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.nameTitle)} span={1}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.title : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.firstName)} span={1}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.firstName : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.lastName)} span={1}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.lastName : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.gender)} span={1}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.gender : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{this.props.reg.selectedPatient.patient
									? Utils.formatDate(this.props.reg.selectedPatient.patient.dobtime)
									: " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.age)} span={1}>
								{this.props.reg.selectedPatient.patient
									? `${Utils.calculateAge(
											this.props.reg.selectedPatient.patient.dobtime
									  )} ${this.props.intl.formatMessage({
											id: ResourceAssistance.Hospitel.year,
									  })} ${Utils.calculateAgeMonth(
											this.props.reg.selectedPatient.patient.dobtime
									  )} ${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.month })}`
									: " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={3}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.tel : " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.address)} span={3}>
								{this.props.reg.selectedPatient.patient ? this.props.reg.selectedPatient.patient.address : " "}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstPatientInfo))
