import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue } from "~/redux/action"
import { SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import OperatingRoomModalSurgicalSafetyCheckListSignInDetails from "./OperatingRoomModalSurgicalSafetyCheckListSignInDetails"
import _ from "lodash"
import { axios } from "~/axios"

class OperatingRoomModalSurgicalSafetyCheckListSignIn extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.operatingRoom.selectedRequest.request ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientIdentity === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientSignConsent === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isSiteMarked === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isAnesthesiaMachineCheckComplete === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPulseOximeterFunctioning === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isKnowAllergy === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isDifficultAirway === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isRiskOfBloodLoss === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isOREquipment === "" ||
			this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isMedicalDeviceImplant === ""
		) {
			return true
		}
		let checklist = this.props.operatingRoom.selectedSurgicalSafetyCheckList.checklist
		let target = Object.assign({}, checklist, {
			...checklist,
			signInPatientIdentity: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientIdentity,
			signInPatientSignConsent: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientSignConsent,
			signInSiteMarked: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isSiteMarked,
			signInAnesthesiaMachineCheckComplete: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isAnesthesiaMachineCheckComplete,
			signInPulseOximeterFunctioning: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPulseOximeterFunctioning,
			signInKnowAllergy: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isKnowAllergy,
			signInDifficultAirway: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isDifficultAirway,
			signInRiskOfBloodLoss: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isRiskOfBloodLoss,
			signInOREquipment: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isOREquipment,
			signInMedicalDeviceImplant: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isMedicalDeviceImplant,
		})
		return _.isEqual(checklist, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveSurgicalSafetyCheckListSignIn,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				surgicalSafetyCheckLists: [
					{
						id: _.isEmpty(this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists)
							? 0
							: this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists[0].id,
						isPatientIdentity: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientIdentity,
						isPatientSignConsent: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientSignConsent,
						isSiteMarked: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isSiteMarked,
						isAnesthesiaMachineCheckComplete: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isAnesthesiaMachineCheckComplete,
						isPulseOximeterFunctioning: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPulseOximeterFunctioning,
						isKnowAllergy: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isKnowAllergy,
						isDifficultAirway: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isDifficultAirway,
						isRiskOfBloodLoss: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isRiskOfBloodLoss,
						isOREquipment: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isOREquipment,
						isMedicalDeviceImplant: this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isMedicalDeviceImplant,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.signIn)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalSurgicalSafetyCheckListSignInDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomSurgicalSafetyCheckListSignInDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalSurgicalSafetyCheckListSignIn)
