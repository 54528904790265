import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setPharmacyReturnOrders, setSelected } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyRightReturnOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRightReturnOrder",
		}
	}

	componentDidMount() {
		if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
			this.props.setPharmacyReturnOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyReturnOrders)
		} else {
			this.props.setPharmacyReturnOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.selectedDoctorOrder, this.props.pharmacy.selectedDoctorOrder)) {
			if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
				this.props.setPharmacyReturnOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyReturnOrders)
			} else {
				this.props.setPharmacyReturnOrders([])
			}
		}
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable striped data={this.props.pharmacy.returnOrderTable} onClick={this.onSelectDispensed} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPharmacyReturnOrders,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyRightReturnOrder))
