import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportPupilSizeDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				pupilSizes: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row, { justifyContent: "center" }]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>ID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.id}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Name: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Age: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>DOB: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Date Based Pupil Size */}
						{this.state.data.pupilSizes.map((pupilSize) => {
							return (
								<Fragment>
									<View wrap={false}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>Date: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { marginRight: 10 }]}>
												<Text>{pupilSize.date}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>Day: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{pupilSize.day} </Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />

										{/* Item Header */}
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.timeIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.leftEyePupilSize]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>
													{this.state.data.leftEyePupilSizeIntl}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.leftEyeReactionToLight]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>
													{this.state.data.leftEyeReactionToLightIntl}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rightEyePupilSize]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>
													{this.state.data.rightEyePupilSizeIntl}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rightEyeReactionToLight]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>
													{this.state.data.rightEyeReactionToLightIntl}
												</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.leftEyePupilSize]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Pupil Size(mm)(L)</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.leftEyeReactionToLight]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Reaction To Light(L)</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rightEyePupilSize]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Pupil Size(mm)(R)</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rightEyeReactionToLight]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Reaction To Light(R)</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
										{pupilSize.pupilSizes.map((ps) => {
											return (
												<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
														<Text>{Utils.formatTime(ps.dateTime)}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.leftEyePupilSize]}>
														<Text>{ps.left}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.leftEyeReactionToLight]}>
														<Text>{ps.leftReactionToLight}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rightEyePupilSize]}>
														<Text>{ps.right}</Text>
													</View>
													<View
														style={[ResourceAssistance.Report.styles.colTextCenter, styles.rightEyeReactionToLight]}
													>
														<Text>{ps.rightReactionToLight}</Text>
													</View>
												</View>
											)
										})}
									</View>
									<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />
								</Fragment>
							)
						})}
						<View fixed style={{ flexGrow: 1, justifyContent: "flex-end" }}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text>{this.state.data.remark}</Text>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	time: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	leftEyePupilSize: {
		minWidth: "22%",
		maxWidth: "22%",
	},
	leftEyeReactionToLight: {
		minWidth: "22%",
		maxWidth: "22%",
	},
	rightEyePupilSize: {
		minWidth: "22%",
		maxWidth: "22%",
	},
	rightEyeReactionToLight: {
		minWidth: "22%",
		maxWidth: "22%",
	},
})

export default ReportPupilSizeDoc
