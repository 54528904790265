import { Select, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_PV_MODAL_PURCHASE_AMOUNT,
	SET_PV_MODAL_PURCHASE_DESCRIPTION,
	SET_PV_MODAL_PURCHASE_SELECTED_GL,
	SET_PV_MODAL_PURCHASE_SELECTED_TAX,
	SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE,
	SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX,
	SET_PV_MODAL_PURCHASE_WHT_PERCENT,
	SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { TreeSelectUtils } from "~/utils/component/TreeSelectUtils"
import GInput from "~/view/component/input/GInput"

class PVModalPurchaseFields extends React.Component {
	constructor(props) {
		super(props)

		this.onCOA = this.onCOA.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onWithholdingTax = this.onWithholdingTax.bind(this)
		this.onWithholdingTaxPercent = this.onWithholdingTaxPercent.bind(this)
		this.onWithholdingTaxSubType = this.onWithholdingTaxSubType.bind(this)
		this.onTaxRate = this.onTaxRate.bind(this)
		this.onOther = this.onOther.bind(this)
		this.onAmount = this.onAmount.bind(this)
	}

	componentDidMount() {
		if (this.props.PV.selectedPurchase.purchase) {
			let selectedPurchase = this.props.PV.selectedPurchase.purchase
			this.props.setValue(SET_PV_MODAL_PURCHASE_DESCRIPTION, selectedPurchase.accountingTransDescr)
			this.props.setValue(
				SET_PV_MODAL_PURCHASE_WHT_PERCENT,
				Utils.BigNumber(selectedPurchase.withholdingTax).isGreaterThan(0) ? selectedPurchase.withholdingTax : ""
			)
			this.props.setValue(SET_PV_MODAL_PURCHASE_AMOUNT, selectedPurchase.taxedTotalPrice)
			this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER, selectedPurchase.withholdingTaxSubLevelOther)
			if (!_.isEmpty(this.props.PV.chartOfAccounts)) {
				this.initialCOA()
			}
			if (!_.isEmpty(this.props.PV.withholdingTaxes)) {
				this.initalWithholdingTax()
			}
			if (!_.isEmpty(this.props.PV.taxes)) {
				this.initialTaxRate()
			}
			this.initialWithholdingTaxSubLevel()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PV.chartOfAccounts, this.props.PV.chartOfAccounts)) {
			if (this.props.PV.selectedPurchase.purchase && !_.isEmpty(this.props.PV.chartOfAccounts)) {
				this.initialCOA()
			}
		}
		if (!_.isEqual(prevProps.PV.withholdingTaxes, this.props.PV.withholdingTaxes)) {
			if (this.props.PV.selectedPurchase.purchase && !_.isEmpty(this.props.PV.withholdingTaxes)) {
				this.initalWithholdingTax()
			}
		}
		if (!_.isEqual(prevProps.PV.taxes, this.props.PV.taxes)) {
			if (this.props.PV.selectedPurchase.purchase && !_.isEmpty(this.props.PV.taxes)) {
				this.initialTaxRate()
			}
		}

		if (!_.isEqual(prevProps.PV.modalPurchase.selectedWithholdingTax, this.props.PV.modalPurchase.selectedWithholdingTax)) {
			if (this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax && this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent) {
				this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE, -1, null)
				this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.PV.modalPurchase.selectedWithholdingTaxSubLevel, this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel)) {
			if (this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.subLevel && !this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.subLevel.isOther) {
				this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_GL, -1, null)
		this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX, -1, null)
		this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE, -1, null)
		this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_TAX, -1, null)
		this.props.setValue(SET_PV_MODAL_PURCHASE_DESCRIPTION, "")
		this.props.setValue(SET_PV_MODAL_PURCHASE_AMOUNT, "")
		this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER, "")
		this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_PERCENT, "")
	}

	initialCOA() {
		let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.PV.chartOfAccounts, this.props.PV.selectedPurchase.purchase.accountingCode)
		if (!_.isEmpty(selectedAry)) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_GL, selectedAry[0], selectedAry[1])
		} else {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_DESCRIPTION, -1, null)
		}
	}

	initalWithholdingTax() {
		let idx = this.props.PV.withholdingTaxes.findIndex(
			(wht) =>
				wht.code === this.props.PV.selectedPurchase.purchase.withholdingTaxCode &&
				(_.isEmpty(this.props.PV.selectedPurchase.purchase.withholdingTaxAccountingCode) ||
					wht.activities.some((activity) => activity.chartOfAccounts.fullCode === this.props.PV.selectedPurchase.purchase.withholdingTaxAccountingCode))
		)
		if (idx > -1) {
			let key = idx
			if (!_.isEmpty(this.props.PV.selectedPurchase.purchase.withholdingTaxAccountingCode)) {
				let activityIdx = this.props.PV.withholdingTaxes[idx].activities.findIndex(
					(activity) => activity.chartOfAccounts.fullCode === this.props.PV.selectedPurchase.purchase.withholdingTaxAccountingCode
				)
				key = idx + "-" + activityIdx
			}
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX, key, this.props.PV.withholdingTaxes[idx])
		}
	}

	initialWithholdingTaxSubLevel() {
		let idx = ResourceAssistance.Enum.EWithholdingTaxSubLevels.findIndex(
			(subLevel) => subLevel.displayName === this.props.PV.selectedPurchase.purchase.withholdingTaxSubLevel
		)
		if (idx > -1) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE, idx, ResourceAssistance.Enum.EWithholdingTaxSubLevels[idx])
		}
	}

	initialTaxRate() {
		let idx = this.props.PV.taxes.findIndex((tax) => tax.displayName === this.props.PV.selectedPurchase.purchase.taxName)
		if (idx > -1) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_TAX, idx, this.props.PV.taxes[idx])
		}
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_GL, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_PV_MODAL_PURCHASE_SELECTED_GL,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.PV.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_GL, value, this.props.PV.chartOfAccounts[value])
		}
	}

	onDescription(e) {
		this.props.setValue(SET_PV_MODAL_PURCHASE_DESCRIPTION, e.target.value)
	}

	onWithholdingTax(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX, -1, null)
			return
		}
		if (typeof value === "string") {
			let idxs = value.split("-")
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX, value, this.props.PV.withholdingTaxes[idxs[0]])
		} else {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX, value, this.props.PV.withholdingTaxes[value])
		}
	}

	onWithholdingTaxPercent(e) {
		this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_PERCENT, e.target.value)
	}

	onWithholdingTaxSubType(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE, value, ResourceAssistance.Enum.EWithholdingTaxSubLevels[value])
	}

	onTaxRate(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_TAX, -1, null)
			return
		}
		this.props.setSelected(SET_PV_MODAL_PURCHASE_SELECTED_TAX, value, this.props.PV.taxes[value])
	}

	onOther(e) {
		this.props.setValue(SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER, e.target.value)
	}

	onAmount(e) {
		this.props.setValue(SET_PV_MODAL_PURCHASE_AMOUNT, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							loading={this.props.PV.isChartOfAccountsLoading}
							value={this.props.PV.modalPurchase.selectedGL.GL ? this.props.PV.modalPurchase.selectedGL.index : undefined}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.PV.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"], false)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={3}
							type={ResourceAssistance.FormControl.type.text}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.PV.modalPurchase.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row className={"g-1"}>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							disabled
							value={
								this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax
									? this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax.code
									: undefined
							}
							addonAfter={
								<TreeSelect
									style={{
										textAlign: "center",
										width: "350px",
									}}
									showSearch
									allowClear
									treeLine={{ showLeafIcon: false }}
									treeExpandAction="click"
									filterTreeNode={(input, treeNode) => {
										return treeNode.title.toLowerCase().includes(input)
									}}
									placeholder={translate(ResourceAssistance.Message.withholdingTax)}
									loading={this.props.PV.isWithholdingTaxesLoading}
									value={
										this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax ? this.props.PV.modalPurchase.selectedWithholdingTax.index : undefined
									}
									onChange={this.onWithholdingTax}
									treeData={TreeSelectUtils.displayWithholdingTax(this.props.PV.withholdingTaxes)}
								/>
							}
						/>
					</Col>
					<Col md={2}>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.percentSymbol)}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							min={1}
							max={100}
							disabled={
								!this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax ||
								this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
							}
							value={this.props.PV.modalPurchase.percent}
							onChange={this.onWithholdingTaxPercent}
						/>
					</Col>
				</Row>
				{this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax &&
					!this.props.PV.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent && (
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.withholdingTax)}</legend>
							<Row>
								<Col>
									<Select
										allowClear
										showSearch
										style={{ textAlign: "center", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
										placeholder={translate(ResourceAssistance.Message.subLevel)}
										status={this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.subLevel ? undefined : "error"}
										value={
											this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.subLevel
												? this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.index
												: undefined
										}
										onChange={this.onWithholdingTaxSubType}
									>
										{Utils.renderSelects(ResourceAssistance.Enum.EWithholdingTaxSubLevels, false)}
									</Select>
								</Col>
								{this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.subLevel &&
									this.props.PV.modalPurchase.selectedWithholdingTaxSubLevel.subLevel.isOther && (
										<Col>
											<GInput
												required
												autoSize
												minRows={1}
												placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.other })}
												onChange={this.onOther}
												value={this.props.PV.modalPurchase.whtSubLevelOther}
											/>
										</Col>
									)}
							</Row>
						</fieldset>
					)}
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.taxRate)}
							loading={this.props.PV.isTaxesLoading}
							status={this.props.PV.modalPurchase.selectedTax.tax ? undefined : "error"}
							value={this.props.PV.modalPurchase.selectedTax.tax ? this.props.PV.modalPurchase.selectedTax.index : undefined}
							onChange={this.onTaxRate}
						>
							{Utils.renderSelects(this.props.PV.taxes, false)}
						</Select>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.totalPriceDiscountedTaxed })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onAmount}
							value={this.props.PV.modalPurchase.amount}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PVModalPurchaseFields))
