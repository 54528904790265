import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setOPDPupilSizes } from "~/redux/action"
import OutpatientRightPupilSizeChart from "./OutpatientRightPupilSizeChart"
import OutpatientRightPupilSizeTable from "./OutpatientRightPupilSizeTable"

class OutpatientRightPupilSize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightPupilSize",
		}
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDPupilSizes(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.pupilSizes)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDPupilSizes(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.pupilSizes)
			} else {
				this.props.setOPDPupilSizes([])
			}
		}
	}

	render() {
		return this.props.graphic ? <OutpatientRightPupilSizeChart /> : <OutpatientRightPupilSizeTable />
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setOPDPupilSizes,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightPupilSize))
