import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { approveAction, loadPRsAction } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import PRRightFifthRow from "./PRRightFifthRow"
import PRRightFirstRow from "./PRRightFirstRow"
import PRRightSecondRow from "./PRRightSecondRow"
import PRRightThirdRow from "./PRRightThirdRow"

class PRRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRRight",
		}

		this.disapprove = this.disapprove.bind(this)
	}

	disapprove(approverK, isApprovedK, approveDateK) {
		this.props.dispatch(approveAction(approverK, null, isApprovedK, false, approveDateK, null)).then(() => {
			let filteredOrders = this.props.pr.prs.filter((order) => order.id.includes(this.props.pr.searchNum))
			this.props.loadPRsAction(this.props.pr.prs, filteredOrders)
		})
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Request, this.props.login.user.roles) && (
					<PRRightFirstRow isAllApproved={this.props.isAllApproved} {...this.props} />
				)}
				<PRRightSecondRow updateOriginalOrders={this.props.updateOriginalOrders} disapprove={this.disapprove} {...this.props} />
				<PRRightThirdRow
					updateOriginalOrders={this.props.updateOriginalOrders}
					isAllApproved={this.props.isAllApproved}
					disapprove={this.disapprove}
					{...this.props}
				/>
				<PRRightFifthRow updateOriginalOrders={this.props.updateOriginalOrders} isAllApproved={this.props.isAllApproved} {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ approveAction, loadPRsAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PRRight)
