import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray, setValue } from "~/redux/action"
import { SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE } from "~/redux/type"
import ASelect from "~/view/component/ant_select/ASelect"
import GInput from "~/view/component/input/GInput"

class RegModalClinicalRecordOther extends React.Component {
	ERadio = {
		YES: "YES",
		NO: "NO",
	}
	Selection = {
		OPERATION_TYPE: "OPERATION_TYPE",
	}
	constructor(props) {
		super(props)

		this.state = {
			translator: this.ERadio.NO,
		}

		this.onSelectChange = this.onSelectChange.bind(this)
		this.onTranslator = this.onTranslator.bind(this)
		this.onLanguage = this.onLanguage.bind(this)
	}

	componentDidMount() {
		if (this.props.reg.selectedAdmission.admission) {
			if (this.props.reg.selectedAdmission.index !== -1 && this.props.reg.modalClinicalRecord.operationTypeOptions !== 0) {
				const operationTypeOptions = this.props.reg.modalClinicalRecord.operationTypeOptions
				const operationType = this.props.reg.selectedAdmission.admission.clinicalRecord.operationType
				const selectIdx = operationTypeOptions.findIndex((item) => _.isEqual(item.name, operationType))
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, selectIdx > -1 ? operationTypeOptions[selectIdx].name : "")
			} else {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, "")
			}
			this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE, this.props.reg.selectedAdmission.admission.clinicalRecord.language)
			if (this.props.reg.selectedAdmission.admission.clinicalRecord.language) {
				this.setState({
					translator: this.ERadio.YES,
				})
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.reg.modalClinicalRecord.operationTypeOptions, this.props.reg.modalClinicalRecord.operationTypeOptions)) {
			if (this.props.reg.selectedAdmission.index !== -1 && this.props.reg.modalClinicalRecord.operationTypeOptions !== 0) {
				const operationTypeOptions = this.props.reg.modalClinicalRecord.operationTypeOptions
				const operationType = this.props.reg.selectedAdmission.admission.clinicalRecord.operationType
				const selectIdx = operationTypeOptions.findIndex((item) => _.isEqual(item.name, operationType))
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, selectIdx > -1 ? operationTypeOptions[selectIdx].name : "")
			} else {
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, "")
			}
		}
		if (!_.isEqual(prevState.translator, this.state.translator)) {
			this.props.setValue(
				SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE,
				this.state.translator === this.ERadio.YES && this.props.reg.selectedAdmission.admission
					? this.props.reg.selectedAdmission.admission.clinicalRecord.language
					: ""
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, "")
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE, "")
	}

	onSelectChange(name, value) {
		switch (name) {
			case this.Selection.OPERATION_TYPE:
				this.props.setValue(SET_REGISTER_MODAL_CLINICAL_RECORD_OPERATIONTYPE, value)
				break
			default:
				break
		}
	}

	onTranslator(e) {
		this.setState({
			translator: e.target.value,
		})
	}

	onLanguage(e) {
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_LANGUAGE, e.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.other)}</legend>
				<Row className={"g-1"}>
					<ASelect
						required
						showSearch
						placeholder={translate(ResourceAssistance.Message.operationType)}
						value={this.props.reg.modalClinicalRecord.operationType}
						onSelectChange={(value) => this.onSelectChange(this.Selection.OPERATION_TYPE, value)}
						options={this.props.reg.modalClinicalRecord.operationTypeOptions}
						displayProperty="name"
					/>
				</Row>
				<Row className={"g-0"}>
					<Col md="auto">
						<label style={{ paddingLeft: "0.3rem" }}>{translate(ResourceAssistance.Message.translator)}</label>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<Radio.Group
							options={[
								{ label: translate(ResourceAssistance.Message.no), value: this.ERadio.NO },
								{ label: translate(ResourceAssistance.Message.yes), value: this.ERadio.YES },
							]}
							value={this.state.translator}
							onChange={this.onTranslator}
						/>
					</Col>
					<Col>
						<GInput
							style={{ marginTop: 0 }}
							required
							placeholder={translate(ResourceAssistance.Message.language)}
							disabled={this.state.translator === this.ERadio.NO}
							value={this.props.reg.modalClinicalRecord.language}
							onChange={this.onLanguage}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegModalClinicalRecordOther)
