export const SET_ADM_GL_CHART_OF_ACCOUNTS = "SET_ADM_GL_CHART_OF_ACCOUNTS"
export const SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING = "SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING"
export const SET_ADM_GL_PAYABLE_ACTIVITIES_LOADING = "SET_ADM_GL_PAYABLE_ACTIVITIES_LOADING"
export const SET_ADM_GL_PAYABLE_ACTIVITIES_TABLE = "SET_ADM_GL_PAYABLE_ACTIVITIES_TABLE"
export const SET_ADM_GL_PAYABLE_DISPLAY = "SET_ADM_GL_PAYABLE_DISPLAY"
export const SET_ADM_GL_PAYABLE_FILTER_CODE = "SET_ADM_GL_PAYABLE_FILTER_CODE"
export const SET_ADM_GL_PAYABLE_FILTER_NAME = "SET_ADM_GL_PAYABLE_FILTER_NAME"
export const SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK = "SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK"
export const SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT = "SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT"
export const SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION = "SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION"
export const SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK = "SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK"
export const SET_ADM_GL_PAYABLE_MODAL_AP_RC_SELECTABLE_IND = "SET_ADM_GL_PAYABLE_MODAL_AP_RC_SELECTABLE_IND"
export const SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK = "SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK"
export const SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK = "SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK"
export const SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP = "SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP"
export const SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK = "SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK"
export const SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK = "SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK"
export const SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE = "SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE"
export const SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT = "SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT"
export const SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP = "SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP"
export const SET_ADM_GL_PAYABLE_SELECTED_AP = "SET_ADM_GL_PAYABLE_SELECTED_AP"
export const SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING = "SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING"
export const SET_ADM_GL_RECEIVABLE_ACTIVITIES_TABLE = "SET_ADM_GL_RECEIVABLE_ACTIVITIES_TABLE"
export const SET_ADM_GL_RECEIVABLE_DISPLAY = "SET_ADM_GL_RECEIVABLE_DISPLAY"
export const SET_ADM_GL_RECEIVABLE_FILTER_CODE = "SET_ADM_GL_RECEIVABLE_FILTER_CODE"
export const SET_ADM_GL_RECEIVABLE_FILTER_NAME = "SET_ADM_GL_RECEIVABLE_FILTER_NAME"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT = "SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION = "SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR = "SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR"
export const SET_ADM_GL_RECEIVABLE_SELECTED_AR = "SET_ADM_GL_RECEIVABLE_SELECTED_AR"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK = "SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT = "SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT"
export const SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND = "SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND"
