import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { LuEqual } from "react-icons/lu"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected } from "~/redux/action"
import { SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class FIModalSplitEditProperties extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			amount: 0,
		}

		this.onAmount = this.onAmount.bind(this)
		this.onUnitConversion = this.onUnitConversion.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	componentDidMount() {
		this.setState({
			amount: this.props.amount,
		})
		this.initialUnitConversion()
	}

	componentWillUnmount() {
		this.setState({
			amount: 0,
		})
		this.props.setSelected(SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION, -1, null)
	}

	initialUnitConversion() {
		let idx = this.props.frontInventory.modalSplit.unitConversions.findIndex(
			(each) => each.displayName === this.props.frontInventory.modalSplit.selectedLot.lot.splitUnit
		)
		if (idx > -1) {
			this.props.setSelected(SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION, idx, this.props.frontInventory.modalSplit.unitConversions[idx])
		}
	}

	onAmount(event) {
		this.setState({
			amount: event.target.value,
		})
	}

	onUnitConversion(value) {
		if (value === undefined) {
			this.props.setSelected(SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION, -1, null)
			return
		}
		this.props.setSelected(SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION, value, this.props.frontInventory.modalSplit.unitConversions[value])
	}

	render() {
		return (
			<Row className={"g-2"}>
				<Col>
					<GInput
						required
						style={{ textAlign: "center" }}
						type={ResourceAssistance.FormControl.type.number}
						pattern="^\d+$"
						placeholder={translate(ResourceAssistance.Message.amount)}
						max={this.props.frontInventory.modalSplit.selectedLot.lot.total}
						onChange={this.onAmount}
						value={this.state.amount}
						addonAfter={this.props.frontInventory.selectedInventory.inventory.unit}
					/>
				</Col>
				<Col md="auto">{<LuEqual size={ResourceAssistance.ReactIcon.size} />}</Col>
				<Col>
					<GInput
						style={{ textAlign: "center" }}
						disabled
						addonAfter={
							<Select
								allowClear
								showSearch
								style={{ textAlign: "center" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Message.unitConversion)}
								loading={this.props.frontInventory.modalSplit.isUnitConversionsLoading}
								value={
									this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion
										? this.props.frontInventory.modalSplit.selectedUnitConversion.index
										: undefined
								}
								onChange={this.onUnitConversion}
							>
								{Utils.renderSelects(this.props.frontInventory.modalSplit.unitConversions, false)}
							</Select>
						}
						value={
							this.state.amount && this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion
								? Utils.formatNumWithComma(
										Utils.BigNumber(this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion.amount).times(this.state.amount)
								  )
								: ""
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FIModalSplitEditProperties)
