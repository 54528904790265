import { Slider } from "antd"
import propTypes from "prop-types"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance } from "~/i18n"

class PainScaleSlider extends React.Component {
	marks = {
		0: {
			style: {},
			label: "0",
		},
		1: "1",
		2: "2",
		3: "3",
		4: "4",
		5: "5",
		6: "6",
		7: "7",
		8: "8",
		9: "9",
		10: "10",
	}
	constructor(props) {
		super(props)

		this.state = {
			score: 0,
		}

		this.onPainScaleChange = this.onPainScaleChange.bind(this)
	}

	componentDidMount() {
		if (this.props.painScale) {
			this.setState({
				score: this.props.painScale.score,
			})
		}
	}

	onPainScaleChange(value) {
		this.setState({
			score: value,
		})
		this.props.onPainScaleChange(value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<Slider
							tooltipVisible={false}
							marks={this.marks}
							min={0}
							max={10}
							step={null}
							value={this.state.score}
							onChange={this.onPainScaleChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col style={{ alignItems: "center" }}>
						<svg
							className="SvgFace_svg__faces"
							viewBox="0 0 105 105"
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={1.5}
							width={ResourceAssistance.ReactIcon.size_md}
						>
							<circle cx={52.5} cy={52.5} r={50} fill="#21a662" />
							<path
								d="M36.391 64.422S37.32 82.444 52.5 82.19c14.813-.248 16.671-17.811 16.585-17.922"
								fill="none"
								stroke="#fff"
								strokeWidth={5}
							/>
							<g fill="#fff">
								<ellipse cx={29.31} cy={42.488} rx={3.922} ry={3.923} />
								<ellipse cx={75.706} cy={42.488} rx={3.922} ry={3.923} />
							</g>
						</svg>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<svg
							className="SvgFace_svg__faces"
							viewBox="0 0 105 105"
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={1.5}
							width={ResourceAssistance.ReactIcon.size_md}
						>
							<circle cx={52.5} cy={52.5} r={50} fill="#8dd995" />
							<path
								d="M36.391 64.422s.929 9.363 16.109 9.11c14.813-.248 16.671-9.153 16.585-9.264"
								fill="none"
								stroke="#fff"
								strokeWidth={5}
							/>
							<g fill="#fff">
								<ellipse cx={29.31} cy={42.488} rx={3.922} ry={3.923} />
								<ellipse cx={75.706} cy={42.488} rx={3.922} ry={3.923} />
							</g>
						</svg>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<svg
							className="SvgFace_svg__faces"
							viewBox="0 0 105 105"
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={1.5}
							width={ResourceAssistance.ReactIcon.size_md}
						>
							<circle cx={52.5} cy={52.5} r={50} fill="#e7e47a" />
							<path
								d="M36.391 64.422s1.03.001 16.21-.252c14.813-.248 16.57.209 16.484.098"
								fill="none"
								stroke="#fff"
								strokeWidth={5}
							/>
							<g fill="#fff">
								<ellipse cx={29.31} cy={42.488} rx={3.922} ry={3.923} />
								<ellipse cx={75.706} cy={42.488} rx={3.922} ry={3.923} />
							</g>
						</svg>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<svg
							className="SvgFace_svg__faces"
							viewBox="0 0 105 105"
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={1.5}
							width={ResourceAssistance.ReactIcon.size_md}
						>
							<circle cx={52.5} cy={52.5} r={50} fill="#df9533" />
							<path
								d="M34.293 69.676s3.128-5.253 18.308-5.506c14.813-.248 20.011 5.04 19.925 4.929"
								fill="none"
								stroke="#fff"
								strokeWidth={5}
							/>
							<g fill="#fff">
								<ellipse cx={29.31} cy={42.488} rx={3.922} ry={3.923} />
								<ellipse cx={75.706} cy={42.488} rx={3.922} ry={3.923} />
							</g>
						</svg>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<svg
							className="SvgFace_svg__faces"
							viewBox="0 0 105 105"
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={1.5}
							width={ResourceAssistance.ReactIcon.size_md}
						>
							<circle cx={52.5} cy={52.5} r={50} fill="#e8670d" />
							<path
								d="M35.202 72.615s2.219-8.192 17.399-8.445c14.813-.248 20.948 6.599 21.348 7.425"
								fill="none"
								stroke="#fff"
								strokeWidth={5}
							/>
							<g fill="#fff">
								<ellipse cx={29.31} cy={42.488} rx={3.922} ry={3.923} />
								<ellipse cx={75.706} cy={42.488} rx={3.922} ry={3.923} />
							</g>
						</svg>
					</Col>
					<Col style={{ alignItems: "center" }}>
						<svg
							className="SvgFace_svg__faces"
							viewBox="0 0 105 105"
							xmlns="http://www.w3.org/2000/svg"
							fillRule="evenodd"
							clipRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeMiterlimit={1.5}
							width={ResourceAssistance.ReactIcon.size_md}
						>
							<circle cx={52.5} cy={52.5} r={50} fill="#c14825" />
							<path
								d="M35.202 75.377s2.219-10.954 17.399-11.207c14.813-.248 20.644 6.501 21.348 11.177"
								fill="none"
								stroke="#fff"
								strokeWidth={5}
							/>
							<g fill="#fff">
								<ellipse cx={29.31} cy={42.488} rx={3.922} ry={3.923} />
								<ellipse cx={75.706} cy={42.488} rx={3.922} ry={3.923} />
							</g>
						</svg>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

PainScaleSlider.propTypes = {
	onPainScaleChange: propTypes.func.isRequired,
}

PainScaleSlider.defaultProps = {
	onPainScaleChange: () => {},
}

export default injectIntl(PainScaleSlider, { forwardRef: true })
