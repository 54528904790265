import React from "react"
import { Col, Row } from "react-bootstrap"
import { ImPrinter } from "react-icons/im"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setRcInvoices, setRcSelectedInvoice } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RCRightFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCRightFirstRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}

	onSelectRow(row, index) {
		this.props.setRcSelectedInvoice(index, this.props.invoice.filtered[index])
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.selectedRc.rc) !== JSON.stringify(this.props.selectedRc.rc)) {
			if (this.props.selectedRc.rc) {
				this.props.setRcInvoices(this.props.selectedRc.rc.invoices)
			} else {
				this.props.setRcInvoices([])
			}
			this.props.setRcSelectedInvoice(-1, null)
		}
	}

	onPrint(event, row, rKey, cKey) {
		let selectedInvoice = this.props.invoice.filtered[rKey]
		let subTotal = selectedInvoice.receivedItems.reduce((total, item) => {
			return total.plus(item.totalPrice)
		}, Utils.BigNumber(0))
		let discountTotal = subTotal.times(selectedInvoice.discount / 100)

		let target = {
			taxIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxId }),
			titleIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.receive }),
			poIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.poId }),
			invoiceIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.invoiceNum }),
			dateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.date }),
			vendorIdIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.supplierCode }),
			vendorIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.supplierName }),
			paymentIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.payment }),
			termsIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.terms }),
			idIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.id }),
			descriptionIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.list }),
			qtyIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.qty }),
			bonusIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.bonus }),
			unitPriceIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.unitPrice }),
			discountIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.discount }),
			discountReceiptIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.discountReceipt }),
			amountIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount }),
			uomIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.uom }),
			remarkIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark }),
			subTotalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.subTotal }),
			totalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.totalPriceDiscounted }),
			grandTotalIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			vatIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.vat }),

			name: this.props.selectedRc.rc.pr.location.parent.displayName,
			address: this.props.selectedRc.rc.pr.location.address.displayName,
			tel: this.props.selectedRc.rc.pr.location.address.tel,
			fax: this.props.selectedRc.rc.pr.location.address.fax,
			taxId: this.props.selectedRc.rc.pr.location.parent.taxNo,

			poId: this.props.selectedRc.rc.id,
			invoiceId: selectedInvoice.invoice,
			invoiceDate: Utils.formatDate(selectedInvoice.receivedDateTime),
			vendorId: selectedInvoice.supplierCode,
			vendorName: selectedInvoice.supplierName,
			payment: selectedInvoice.payment,
			terms: selectedInvoice.paymentTerms,

			items: selectedInvoice.receivedItems.map((item) => {
				return Object.assign(
					{},
					{
						id: item.code,
						description: item.name,
						qty: Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()),
						minQtyPerOrder: Utils.formatNumWithComma(item.minQtyPerOrder),
						unit: item.unit,
						bonus: Utils.formatNumWithComma(item.bonus),
						pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
						discount: item.discount,
						totalPrice: Utils.formatNumWithComma(Utils.BigNumber(item.totalPrice).toFixed(2)),
					}
				)
			}),

			subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
			discount: selectedInvoice.discount,
			discountReceipt: Utils.formatNumWithComma(Utils.BigNumber(selectedInvoice.discountReceipt).negated().toFixed(2)),
			discountTotal: Utils.formatNumWithComma(discountTotal.negated().toFixed(2)),
			total: Utils.formatNumWithComma(
				subTotal
					.times(1 - selectedInvoice.discount / 100)
					.minus(selectedInvoice.discountReceipt)
					.toFixed(2)
			),
			vat: selectedInvoice.receivedItems.some((item) => item.taxRate !== selectedInvoice.receivedItems[0].taxRate)
				? 0
				: selectedInvoice.receivedItems[0].taxRate,
			vatTotal: Utils.formatNumWithComma(
				selectedInvoice.receivedItems
					.reduce((total, item) => {
						total = total.plus(Utils.BigNumber(item.totalPrice).times(item.taxRate / 100))
						return total
					}, Utils.BigNumber(0))
					.toFixed(2)
			),
			grandTotal: Utils.formatNumWithComma(
				selectedInvoice.receivedItems
					.reduce((total, item) => {
						total = total.plus(Utils.BigNumber(item.totalPrice).times(1 + item.taxRate / 100))
						return total
					}, Utils.BigNumber(0))
					.minus(discountTotal)
					.minus(selectedInvoice.discountReceipt)
					.toFixed(2)
			),

			signatureTitles: [
				this.props.intl
					.formatMessage({ id: ResourceAssistance.Message.receivedBy })
					.concat(`: ${selectedInvoice.createdBy.firstName} ${selectedInvoice.createdBy.lastName}`),
			],
			signatureDate: Utils.formatDateTime(new Date().getTime()),
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.Rc.rc, "_blank")
		myWindow.data = target
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						enableOverlay
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineInfo]}
						btnIcons={[<ImPrinter size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onPrint]}
						data={this.props.invoice}
						isClearHighlight={this.props.selectedInvoice.index === -1}
						highlightedRow={this.props.selectedInvoice.index}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	invoice: state.receive.invoice,
	selectedRc: state.receive.selectedRc,
	selectedInvoice: state.receive.selectedInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcInvoices, setRcSelectedInvoice }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RCRightFirstRow))
