import { ResourceAssistance } from "~/i18n"
import {
	SET_OR_MODAL_INTRA_ANE_IO_INTAKES,
	SET_OR_MODAL_INTRA_ANE_IO_OUTPUTS,
	SET_OR_MODAL_INTRA_ANE_IO_PENDING_INTAKES,
	SET_OR_MODAL_INTRA_ANE_IO_PENDING_OUTPUTS,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_ITEMS,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_PENDING_ITEMS,
	SET_OR_MODAL_INTRA_ANE_SERVICE_PENDING_SERVICES,
	SET_OR_MODAL_INTRA_ANE_SERVICE_SERVICES,
	SET_OR_MODAL_INTRA_ANE_VITAL_SIGNS,
	SET_OR_MODAL_POST_ANE_IO_INTAKES,
	SET_OR_MODAL_POST_ANE_IO_OUTPUTS,
	SET_OR_MODAL_POST_ANE_IO_PENDING_INTAKES,
	SET_OR_MODAL_POST_ANE_IO_PENDING_OUTPUTS,
	SET_OR_MODAL_POST_ANE_MEDICATION_ITEMS,
	SET_OR_MODAL_POST_ANE_MEDICATION_PENDING_ITEMS,
	SET_OR_MODAL_POST_ANE_SERVICE_PENDING_SERVICES,
	SET_OR_MODAL_POST_ANE_SERVICE_SERVICES,
	SET_OR_MODAL_POST_ANE_VITAL_SIGNS,
	SET_OR_OPERATING_ROOM_REQUESTS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setOperatingRoomRequests = (requests) => {
	return (dispatch, getState) => {
		let filtered = Array.from(requests).sort((a, b) => Utils.sort(a.operatingRoomSchedules[0].startDateTime, b.operatingRoomSchedules[0].startDateTime))
		let body = filtered.map((request) => {
			return [
				request.operatingRoomSchedules[0] ? Utils.formatDateTime(request.operatingRoomSchedules[0].startDateTime) : "",
				request.operatingRoomSchedules[0] ? Utils.formatDateTime(request.operatingRoomSchedules[0].endDateTime) : "",
				request.clinicalRecord.admission.patient.id,
				request.clinicalRecord.admission.patient.displayName,
			]
		})

		let rowColor = filtered.map((request) => {
			let color = ""

			if (request.approver) {
				color = ResourceAssistance.CSS.Color.green
			} else if (request.inspector) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		dispatch({
			type: SET_OR_OPERATING_ROOM_REQUESTS,
			payload: {
				original: requests,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneMedicationItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items
			.filter(
				(itemRelp) =>
					getState().hospital.operatingRoom.modalIntraAneMedication.selectedType.type &&
					getState().hospital.operatingRoom.modalIntraAneMedication.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospital.operatingRoom.modalIntraAneMedication.filterGenericName.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.operatingRoom.modalIntraAneMedication.filterGenericName).toLowerCase()) ||
						Utils.trim(itemRelp.brand)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.operatingRoom.modalIntraAneMedication.filterGenericName).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.code, each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_MEDICATION_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneMedicationPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = Array.from(items)

		let body = filtered.map((item) => {
			return [item.displayName, item.brand, Utils.trim(item.notes), Utils.formatDateTime(item.startDateTime), Utils.formatDateTime(item.endDateTime)]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_MEDICATION_PENDING_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter(
				(service) =>
					service.name.trim().toLowerCase().includes(getState().hospital.operatingRoom.modalIntraAneService.filterDescription.trim().toLowerCase()) &&
					!getState().hospital.operatingRoom.modalIntraAneService.pendingServiceTable.filtered.some((each) => each.code === service.code)
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((each) => {
			return [each.code, each.name, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneServicePendingServices = (services) => {
	return (dispatch, getState) => {
		let filtered = Array.from(services)

		let body = filtered.map((each) => {
			return [each.code, each.name, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_SERVICE_PENDING_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneIOIntakes = (intakes) => {
	return (dispatch, getState) => {
		let filtered = intakes
			.filter(
				(intake) =>
					intake.displayName.trim().toLowerCase().includes(getState().hospital.operatingRoom.modalIntraAneIO.filterIntake.trim().toLowerCase()) &&
					!getState().hospital.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered.some((each) => each.displayName === intake.displayName)
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_IO_INTAKES,
			payload: {
				original: intakes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneIOPendingIntakes = (intakes) => {
	return (dispatch, getState) => {
		let filtered = Array.from(intakes)

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_IO_PENDING_INTAKES,
			payload: {
				original: intakes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneIOOutputs = (outputs) => {
	return (dispatch, getState) => {
		let filtered = outputs
			.filter(
				(intake) =>
					intake.displayName.trim().toLowerCase().includes(getState().hospital.operatingRoom.modalIntraAneIO.filterOutput.trim().toLowerCase()) &&
					!getState().hospital.operatingRoom.modalIntraAneIO.pendingOutputTable.filtered.some((each) => each.displayName === intake.displayName)
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_IO_OUTPUTS,
			payload: {
				original: outputs,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneIOPendingOutputs = (outputs) => {
	return (dispatch, getState) => {
		let filtered = Array.from(outputs)

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_IO_PENDING_OUTPUTS,
			payload: {
				original: outputs,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalIntraAneVitalSigns = (vitalSigns) => {
	return (dispatch, getState) => {
		let filtered = Array.from(vitalSigns).sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
		let body = filtered.map((each) => {
			return [Utils.formatDateTime(each.dateTime), each.pr, each.bp, each.aline, true]
		})

		dispatch({
			type: SET_OR_MODAL_INTRA_ANE_VITAL_SIGNS,
			payload: {
				original: vitalSigns,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneMedicationItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items
			.filter(
				(itemRelp) =>
					getState().hospital.operatingRoom.modalPostAneMedication.selectedType.type &&
					getState().hospital.operatingRoom.modalPostAneMedication.selectedType.type.id === itemRelp.type.id &&
					(itemRelp.displayName
						.trim()
						.toLowerCase()
						.includes(getState().hospital.operatingRoom.modalPostAneMedication.filterGenericName.trim().toLowerCase()) ||
						Utils.trim(itemRelp.item.keyword)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.operatingRoom.modalPostAneMedication.filterGenericName).toLowerCase()) ||
						Utils.trim(itemRelp.brand)
							.toLowerCase()
							.includes(Utils.trim(getState().hospital.operatingRoom.modalPostAneMedication.filterGenericName).toLowerCase()))
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.code, each.displayName, each.brand, each.unit.displayName]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_MEDICATION_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneMedicationPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = Array.from(items)

		let body = filtered.map((item) => {
			return [item.displayName, item.brand, Utils.trim(item.notes), Utils.formatDateTime(item.startDateTime), Utils.formatDateTime(item.endDateTime)]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_MEDICATION_PENDING_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter(
				(service) =>
					service.name.trim().toLowerCase().includes(getState().hospital.operatingRoom.modalPostAneService.filterDescription.trim().toLowerCase()) &&
					!getState().hospital.operatingRoom.modalPostAneService.pendingServiceTable.filtered.some((each) => each.code === service.code)
			)
			.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((each) => {
			return [each.code, each.name, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneServicePendingServices = (services) => {
	return (dispatch, getState) => {
		let filtered = Array.from(services)

		let body = filtered.map((each) => {
			return [each.code, each.name, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_SERVICE_PENDING_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneIOIntakes = (intakes) => {
	return (dispatch, getState) => {
		let filtered = intakes
			.filter(
				(intake) =>
					intake.displayName.trim().toLowerCase().includes(getState().hospital.operatingRoom.modalPostAneIO.filterIntake.trim().toLowerCase()) &&
					!getState().hospital.operatingRoom.modalPostAneIO.pendingIntakeTable.filtered.some((each) => each.displayName === intake.displayName)
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_IO_INTAKES,
			payload: {
				original: intakes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneIOPendingIntakes = (intakes) => {
	return (dispatch, getState) => {
		let filtered = Array.from(intakes)

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_IO_PENDING_INTAKES,
			payload: {
				original: intakes,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneIOOutputs = (outputs) => {
	return (dispatch, getState) => {
		let filtered = outputs
			.filter(
				(intake) =>
					intake.displayName.trim().toLowerCase().includes(getState().hospital.operatingRoom.modalPostAneIO.filterOutput.trim().toLowerCase()) &&
					!getState().hospital.operatingRoom.modalPostAneIO.pendingOutputTable.filtered.some((each) => each.displayName === intake.displayName)
			)
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_IO_OUTPUTS,
			payload: {
				original: outputs,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneIOPendingOutputs = (outputs) => {
	return (dispatch, getState) => {
		let filtered = Array.from(outputs)

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_IO_PENDING_OUTPUTS,
			payload: {
				original: outputs,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setORModalPostAneVitalSigns = (vitalSigns) => {
	return (dispatch, getState) => {
		let filtered = Array.from(vitalSigns).sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
		let body = filtered.map((each) => {
			return [Utils.formatDateTime(each.dateTime), each.pr, each.bp, each.aline, each.rr, each.oxygenSat, true]
		})

		dispatch({
			type: SET_OR_MODAL_POST_ANE_VITAL_SIGNS,
			payload: {
				original: vitalSigns,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
