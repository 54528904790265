import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setInventoryBranches,
	setInventoryInventories,
	setInventoryInventoryDetails,
	setSelectedBranch,
	setSelectedInventory,
	setSelectedOrg,
} from "~/redux/action"

const ORG = "ORG"
const BRANCH = "BRANCH"
const INVENTORY = "INVENTORY"

class InventoryFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InventoryFirstRow",
		}

		this.onOrgChange = this.onOrgChange.bind(this)
		this.onBranchChange = this.onBranchChange.bind(this)
		this.onInventoryChange = this.onInventoryChange.bind(this)
	}

	onOrgChange(event) {
		if (Number(event.target.value) === -1) {
			this.props.setSelectedOrg(-1, null)
			this.props.setSelectedBranch(-1, null)
			this.props.setSelectedInventory(-1, null)

			this.props.setInventoryBranches([])
			this.props.setInventoryInventories([])
			return
		} else {
			this.props.setSelectedOrg(event.target.value, this.props.inventory.locs.orgs[event.target.value])
			this.props.setInventoryBranches(this.props.inventory.locs.orgs[event.target.value].locations)
			this.props.setSelectedBranch(-1, null)
			this.props.setSelectedInventory(-1, null)

			let branch = document.getElementById(BRANCH)
			branch.selectedIndex = 0
		}
	}

	onBranchChange(event) {
		if (Number(event.target.value) === -1) {
			this.props.setSelectedBranch(-1, null)
			this.props.setSelectedInventory(-1, null)

			this.props.setInventoryInventories([])
			return
		} else {
			this.props.setSelectedBranch(event.target.value, this.props.inventory.locs.branches[event.target.value])
			this.props.setInventoryInventories(this.props.inventory.locs.branches[event.target.value].locations)
			this.props.setSelectedInventory(-1, null)
			let inventory = document.getElementById(INVENTORY)
			inventory.selectedIndex = 0
		}
	}

	onInventoryChange(event) {
		if (Number(event.target.value) === -1) {
			this.props.setSelectedInventory(-1, null)
			this.props.setInventoryInventoryDetails([])
			return
		} else {
			this.props.setSelectedInventory(event.target.value, this.props.inventory.locs.inventories[event.target.value])
			this.props.setInventoryInventoryDetails(
				this.props.inventory.locs.inventories[event.target.value].inventoryDetails
			)
		}
	}

	renderLocs(locs) {
		let options = locs.map((loc, key) => {
			return (
				<option key={key} value={key}>
					{loc.displayName}
				</option>
			)
		})

		return Object.assign([], options, [
			<option key={-1} value={-1}>
				{ResourceAssistance.Symbol.space}
			</option>,
			...options,
		])
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<Row>
						<Col sm="auto">
							<label>{translate(ResourceAssistance.Message.org)}</label>
						</Col>
						<Col>
							<FormattedMessage id={ResourceAssistance.Message.loading}>
								{(placeholder) => (
									<FormControl
										id={ORG}
										size={ResourceAssistance.FormControl.size.sm}
										as={ResourceAssistance.FormControl.as.select}
										disabled={this.props.inventory.locs.isLoading}
										onChange={this.onOrgChange}
									>
										{this.props.inventory.locs.isLoading ? (
											<option>{placeholder}</option>
										) : (
											this.renderLocs(this.props.inventory.locs.orgs)
										)}
									</FormControl>
								)}
							</FormattedMessage>
						</Col>
						<Col sm="auto">
							<label>{translate(ResourceAssistance.Message.location)}</label>
						</Col>
						<Col>
							<FormattedMessage id={ResourceAssistance.Message.loading}>
								{(placeholder) => (
									<FormControl
										id={BRANCH}
										size={ResourceAssistance.FormControl.size.sm}
										as={ResourceAssistance.FormControl.as.select}
										disabled={this.props.inventory.locs.isLoading}
										onChange={this.onBranchChange}
									>
										{this.props.inventory.locs.isLoading ? (
											<option>{placeholder}</option>
										) : (
											this.renderLocs(this.props.inventory.locs.branches)
										)}
									</FormControl>
								)}
							</FormattedMessage>
						</Col>
						<Col sm="auto">
							<label>{translate(ResourceAssistance.Message.inventory)}</label>
						</Col>
						<Col>
							<FormattedMessage id={ResourceAssistance.Message.loading}>
								{(placeholder) => (
									<FormControl
										id={INVENTORY}
										size={ResourceAssistance.FormControl.size.sm}
										as={ResourceAssistance.FormControl.as.select}
										disabled={this.props.inventory.locs.isLoading}
										onChange={this.onInventoryChange}
									>
										{this.props.inventory.locs.isLoading ? (
											<option>{placeholder}</option>
										) : (
											this.renderLocs(this.props.inventory.locs.inventories)
										)}
									</FormControl>
								)}
							</FormattedMessage>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	inventory: state.inventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setInventoryBranches,
			setInventoryInventories,
			setInventoryInventoryDetails,
			setSelectedBranch,
			setSelectedInventory,
			setSelectedOrg,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFirstRow)
