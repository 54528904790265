export const SET_ADMIN_FORM_DISPLAY_ALL_IND = "SET_ADMIN_FORM_DISPLAY_ALL_IND"
export const SET_ADMIN_FORM_FORMS = "SET_ADMIN_FORM_FORMS"
export const SET_ADMIN_FORM_FORMS_LOADING = "SET_ADMIN_FORM_FORMS_LOADING"
export const SET_ADMIN_FORM_MODAL_NEW_FORM_ACTIVE = "SET_ADMIN_FORM_MODAL_NEW_FORM_ACTIVE"
export const SET_ADMIN_FORM_MODAL_NEW_FORM_DESC = "SET_ADMIN_FORM_MODAL_NEW_FORM_DESC"
export const SET_ADMIN_FORM_MODAL_NEW_FORM_NAME = "SET_ADMIN_FORM_MODAL_NEW_FORM_NAME"
export const SET_ADMIN_FORM_SEARCH_NAME = "SET_ADMIN_FORM_SEARCH_NAME"
export const SET_ADMIN_FORM_SELECTED_FORM = "SET_ADMIN_FORM_SELECTED_FORM"
export const SET_ADMIN_ITEM_DISPLAY_ALL_IND = "SET_ADMIN_ITEM_DISPLAY_ALL_IND"
export const SET_ADMIN_ITEM_ITEMS = "SET_ADMIN_ITEM_ITEMS"
export const SET_ADMIN_ITEM_ITEMS_LOADING = "SET_ADMIN_ITEM_ITEMS_LOADING"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_ACTIVE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_ACTIVE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPES = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPES"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_BONUS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_BONUS"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_BRAND = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_BRAND"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEMS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEMS"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_CODE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_CODE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_DISCOUNT = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_DISCOUNT"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_DOSE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_DOSE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_FORMS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_FORMS"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_ITEMS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_ITEMS"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_PRICE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_PRICE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_QTY_PER_ORDER = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_QTY_PER_ORDER"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_FORM = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_FORM"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_ITEM = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_ITEM"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_SUPPLIER = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_SUPPLIER"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TAX = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TAX"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TYPE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TYPE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_UNIT = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_UNIT"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_SUPPLIERS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_SUPPLIERS"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_TAXES = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_TAXES"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_TRADE_NAME = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_TRADE_NAME"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_TYPES = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_TYPES"
export const SET_ADMIN_ITEM_MODAL_NEW_ITEM_UNITS = "SET_ADMIN_ITEM_MODAL_NEW_ITEM_UNITS"
export const SET_ADMIN_ITEM_SEARCH_CODE = "SET_ADMIN_ITEM_SEARCH_CODE"
export const SET_ADMIN_ITEM_SEARCH_NAME = "SET_ADMIN_ITEM_SEARCH_NAME"
export const SET_ADMIN_ITEM_SELECTED_ITEM = "SET_ADMIN_ITEM_SELECTED_ITEM"
export const SET_ADMIN_ITEM_SELECTED_TYPE = "SET_ADMIN_ITEM_SELECTED_TYPE"
export const SET_ADMIN_ITEM_SOURCE_DISPLAY_ALL_IND = "SET_ADMIN_ITEM_SOURCE_DISPLAY_ALL_IND"
export const SET_ADMIN_ITEM_SOURCE_ITEMS = "SET_ADMIN_ITEM_SOURCE_ITEMS"
export const SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING = "SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING"
export const SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_ACTIVE = "SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_ACTIVE"
export const SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD = "SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_KEYWORD"
export const SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_NAME = "SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_NAME"
export const SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_SELECTED_TYPE = "SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_SELECTED_TYPE"
export const SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_TYPES = "SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_TYPES"
export const SET_ADMIN_ITEM_SOURCE_SEARCH_NAME = "SET_ADMIN_ITEM_SOURCE_SEARCH_NAME"
export const SET_ADMIN_ITEM_SOURCE_SELECTED_ITEM = "SET_ADMIN_ITEM_SOURCE_SELECTED_ITEM"
export const SET_ADMIN_ITEM_SOURCE_SELECTED_TYPE = "SET_ADMIN_ITEM_SOURCE_SELECTED_TYPE"
export const SET_ADMIN_ITEM_SOURCE_TYPES = "SET_ADMIN_ITEM_SOURCE_TYPES"
export const SET_ADMIN_ITEM_TYPES = "SET_ADMIN_ITEM_TYPES"
export const SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE = "SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE"
export const SET_ADMIN_PERMISSION_PERMISSIONS = "SET_ADMIN_PERMISSION_PERMISSIONS"
export const SET_ADMIN_PERMISSION_PERMISSIONS_LOADING = "SET_ADMIN_PERMISSION_PERMISSIONS_LOADING"
export const SET_ADMIN_PERMISSION_SEARCH_NAME = "SET_ADMIN_PERMISSION_SEARCH_NAME"
export const SET_ADMIN_ROLE_DISPLAY_ALL_IND = "SET_ADMIN_ROLE_DISPLAY_ALL_IND"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_ACTIVE_IND = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_ACTIVE_IND"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_DESCRIPTION = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_DESCRIPTION"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_NAME = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_NAME"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_PENDING_PERMISSIONS = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_PENDING_PERMISSIONS"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PENDING_PERMISSION = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PENDING_PERMISSION"
export const SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PERMISSION = "SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PERMISSION"
export const SET_ADMIN_ROLE_PERMISSIONS = "SET_ADMIN_ROLE_PERMISSIONS"
export const SET_ADMIN_ROLE_ROLES = "SET_ADMIN_ROLE_ROLES"
export const SET_ADMIN_ROLE_ROLES_LOADING = "SET_ADMIN_ROLE_ROLES_LOADING"
export const SET_ADMIN_ROLE_SEARCH_NAME = "SET_ADMIN_ROLE_SEARCH_NAME"
export const SET_ADMIN_ROLE_SELECTED_ROLE = "SET_ADMIN_ROLE_SELECTED_ROLE"
export const SET_ADMIN_SERVICE_DISPLAY_ALL_IND = "SET_ADMIN_SERVICE_DISPLAY_ALL_IND"
export const SET_ADMIN_SERVICE_ROLES = "SET_ADMIN_SERVICE_ROLES"
export const SET_ADMIN_SERVICE_SEARCH_CODE = "SET_ADMIN_SERVICE_SEARCH_CODE"
export const SET_ADMIN_SERVICE_SEARCH_NAME = "SET_ADMIN_SERVICE_SEARCH_NAME"
export const SET_ADMIN_SERVICE_SELECTED_SERVICE = "SET_ADMIN_SERVICE_SELECTED_SERVICE"
export const SET_ADMIN_SERVICE_SELECTED_TYPE = "SET_ADMIN_SERVICE_SELECTED_TYPE"
export const SET_ADMIN_SERVICE_SERVICES = "SET_ADMIN_SERVICE_SERVICES"
export const SET_ADMIN_SERVICE_SERVICES_LOADING = "SET_ADMIN_SERVICE_SERVICES_LOADING"
export const SET_ADMIN_SERVICE_TYPES = "SET_ADMIN_SERVICE_TYPES"
export const SET_ADMIN_SUPPLIER_DISPLAY_ALL_IND = "SET_ADMIN_SUPPLIER_DISPLAY_ALL_IND"
export const SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ACTIVE = "SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ACTIVE"
export const SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ADDRESS = "SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_ADDRESS"
export const SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_CODE = "SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_CODE"
export const SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_NAME = "SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_NAME"
export const SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_PHONE = "SET_ADMIN_SUPPLIER_MODAL_NEW_SUPPLIER_PHONE"
export const SET_ADMIN_SUPPLIER_SEARCH_CODE = "SET_ADMIN_SUPPLIER_SEARCH_CODE"
export const SET_ADMIN_SUPPLIER_SEARCH_NAME = "SET_ADMIN_SUPPLIER_SEARCH_NAME"
export const SET_ADMIN_SUPPLIER_SELECTED_SUPPLIER = "SET_ADMIN_SUPPLIER_SELECTED_SUPPLIER"
export const SET_ADMIN_SUPPLIER_SUPPLIERS = "SET_ADMIN_SUPPLIER_SUPPLIERS"
export const SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING = "SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING"
export const SET_ADMIN_TAX_DISPLAY_ALL_IND = "SET_ADMIN_TAX_DISPLAY_ALL_IND"
export const SET_ADMIN_TAX_MODAL_NEW_TAX_ACTIVE = "SET_ADMIN_TAX_MODAL_NEW_TAX_ACTIVE"
export const SET_ADMIN_TAX_MODAL_NEW_TAX_DESC = "SET_ADMIN_TAX_MODAL_NEW_TAX_DESC"
export const SET_ADMIN_TAX_MODAL_NEW_TAX_NAME = "SET_ADMIN_TAX_MODAL_NEW_TAX_NAME"
export const SET_ADMIN_TAX_MODAL_NEW_TAX_TAX_RATE = "SET_ADMIN_TAX_MODAL_NEW_TAX_TAX_RATE"
export const SET_ADMIN_TAX_SEARCH_NAME = "SET_ADMIN_TAX_SEARCH_NAME"
export const SET_ADMIN_TAX_SELECTED_TAX = "SET_ADMIN_TAX_SELECTED_TAX"
export const SET_ADMIN_TAX_TAXS = "SET_ADMIN_TAX_TAXS"
export const SET_ADMIN_TAX_TAXS_LOADING = "SET_ADMIN_TAX_TAXS_LOADING"
export const SET_ADMIN_TYPE_TITLE = "SET_ADMIN_TYPE_TITLE"
export const SET_ADMIN_UNIT_DISPLAY_ALL_IND = "SET_ADMIN_UNIT_DISPLAY_ALL_IND"
export const SET_ADMIN_UNIT_MODAL_NEW_UNIT_ACTIVE = "SET_ADMIN_UNIT_MODAL_NEW_UNIT_ACTIVE"
export const SET_ADMIN_UNIT_MODAL_NEW_UNIT_DESC = "SET_ADMIN_UNIT_MODAL_NEW_UNIT_DESC"
export const SET_ADMIN_UNIT_MODAL_NEW_UNIT_NAME = "SET_ADMIN_UNIT_MODAL_NEW_UNIT_NAME"
export const SET_ADMIN_UNIT_SEARCH_NAME = "SET_ADMIN_UNIT_SEARCH_NAME"
export const SET_ADMIN_UNIT_SELECTED_UNIT = "SET_ADMIN_UNIT_SELECTED_UNIT"
export const SET_ADMIN_UNIT_UNITS = "SET_ADMIN_UNIT_UNITS"
export const SET_ADMIN_UNIT_UNITS_LOADING = "SET_ADMIN_UNIT_UNITS_LOADING"
export const SET_ADMIN_USER_DISPLAY_ALL_IND = "SET_ADMIN_USER_DISPLAY_ALL_IND"
export const SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_CONFIRM_PASSWORD = "SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_CONFIRM_PASSWORD"
export const SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_PASSWORD = "SET_ADMIN_USER_MODAL_CHANGE_PASSWORD_PASSWORD"
export const SET_ADMIN_USER_MODAL_NEW_USER_ACTIVE = "SET_ADMIN_USER_MODAL_NEW_USER_ACTIVE"
export const SET_ADMIN_USER_MODAL_NEW_USER_CONFIRM_PASSWORD = "SET_ADMIN_USER_MODAL_NEW_USER_CONFIRM_PASSWORD"
export const SET_ADMIN_USER_MODAL_NEW_USER_EMAIL = "SET_ADMIN_USER_MODAL_NEW_USER_EMAIL"
export const SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME = "SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME"
export const SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG = "SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG"
export const SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME = "SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME"
export const SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG = "SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG"
export const SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM = "SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM"
export const SET_ADMIN_USER_MODAL_NEW_USER_LOGIN_NAME = "SET_ADMIN_USER_MODAL_NEW_USER_LOGIN_NAME"
export const SET_ADMIN_USER_MODAL_NEW_USER_NICKNAME = "SET_ADMIN_USER_MODAL_NEW_USER_NICKNAME"
export const SET_ADMIN_USER_MODAL_NEW_USER_PASSWORD = "SET_ADMIN_USER_MODAL_NEW_USER_PASSWORD"
export const SET_ADMIN_USER_MODAL_NEW_USER_PENDING_ROLES = "SET_ADMIN_USER_MODAL_NEW_USER_PENDING_ROLES"
export const SET_ADMIN_USER_MODAL_NEW_USER_PHONE = "SET_ADMIN_USER_MODAL_NEW_USER_PHONE"
export const SET_ADMIN_USER_MODAL_NEW_USER_ROLES = "SET_ADMIN_USER_MODAL_NEW_USER_ROLES"
export const SET_ADMIN_USER_MODAL_NEW_USER_ROLES_LOADING = "SET_ADMIN_USER_MODAL_NEW_USER_ROLES_LOADING"
export const SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_PENDING_ROLE = "SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_PENDING_ROLE"
export const SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE = "SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE"
export const SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE = "SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE"
export const SET_ADMIN_USER_ROLES = "SET_ADMIN_USER_ROLES"
export const SET_ADMIN_USER_SEARCH_FIRST_NAME = "SET_ADMIN_USER_SEARCH_FIRST_NAME"
export const SET_ADMIN_USER_SEARCH_LAST_NAME = "SET_ADMIN_USER_SEARCH_LAST_NAME"
export const SET_ADMIN_USER_SELECTED_USER = "SET_ADMIN_USER_SELECTED_USER"
export const SET_ADMIN_USER_USERS = "SET_ADMIN_USER_USERS"
export const SET_ADMIN_USER_USERS_LOADING = "SET_ADMIN_USER_USERS_LOADING"
export const SET_ADM_BANK_BRANCHES = "SET_ADM_BANK_BRANCHES"
export const SET_ADM_BANK_MODAL_BRANCH_ACCOUNT = "SET_ADM_BANK_MODAL_BRANCH_ACCOUNT"
export const SET_ADM_BANK_MODAL_BRANCH_ACTIVE = "SET_ADM_BANK_MODAL_BRANCH_ACTIVE"
export const SET_ADM_BANK_MODAL_BRANCH_ADDRESS = "SET_ADM_BANK_MODAL_BRANCH_ADDRESS"
export const SET_ADM_BANK_MODAL_BRANCH_CODE = "SET_ADM_BANK_MODAL_BRANCH_CODE"
export const SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION = "SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION"
export const SET_ADM_BANK_MODAL_BRANCH_NAME = "SET_ADM_BANK_MODAL_BRANCH_NAME"
export const SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER = "SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER"
export const SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS = "SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS"
export const SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS = "SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS"
export const SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT = "SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT"
export const SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER = "SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER"
export const SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS = "SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS"
export const SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS = "SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS"
export const SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT = "SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT"
export const SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT = "SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT"
export const SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE = "SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE"
export const SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE = "SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE"
export const SET_ADM_BANK_NEW_BRANCH_DISPLAY = "SET_ADM_BANK_NEW_BRANCH_DISPLAY"
export const SET_ADM_BANK_SELECTED_BRANCH = "SET_ADM_BANK_SELECTED_BRANCH"
export const SET_ADM_CATEGORY_CHART_OF_ACCOUNTS = "SET_ADM_CATEGORY_CHART_OF_ACCOUNTS"
export const SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING = "SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING"
export const SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY = "SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY"
export const SET_ADM_CATEGORY_COA_RECEIABLE_DISPLAY = "SET_ADM_CATEGORY_COA_RECEIABLE_DISPLAY"
export const SET_ADM_CATEGORY_COA_SELECTED_AP = "SET_ADM_CATEGORY_COA_SELECTED_AP"
export const SET_ADM_CATEGORY_COA_SELECTED_AR = "SET_ADM_CATEGORY_COA_SELECTED_AR"
export const SET_ADM_CATEGORY_INVENTORY_PAYABLE_ACTIVITIES = "SET_ADM_CATEGORY_INVENTORY_PAYABLE_ACTIVITIES"
export const SET_ADM_CATEGORY_INVENTORY_RECEIVABLE_ACTIVITIES = "SET_ADM_CATEGORY_INVENTORY_RECEIVABLE_ACTIVITIES"
export const SET_ADM_CATEGORY_INVENTORY_SELECTED_AP = "SET_ADM_CATEGORY_INVENTORY_SELECTED_AP"
export const SET_ADM_CATEGORY_MODAL_COA_DATE_IND = "SET_ADM_CATEGORY_MODAL_COA_DATE_IND"
export const SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION = "SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION"
export const SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND = "SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND"
export const SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP = "SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP"
export const SET_ADM_CATEGORY_MODAL_COA_SELECTED_AR = "SET_ADM_CATEGORY_MODAL_COA_SELECTED_AR"
export const SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND = "SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND"
export const SET_ADM_CATEGORY_MODAL_COA_WORKFLOW = "SET_ADM_CATEGORY_MODAL_COA_WORKFLOW"
export const SET_ADM_CATEGORY_PAYMENT_PAYABLE_DISPLAY = "SET_ADM_CATEGORY_PAYMENT_PAYABLE_DISPLAY"
export const SET_ADM_CATEGORY_SERVICE_CHRGITEMS = "SET_ADM_CATEGORY_SERVICE_CHRGITEMS"
export const SET_ADM_CATEGORY_SERVICE_IS_CHRGITEMS_LOADING = "SET_ADM_CATEGORY_SERVICE_IS_CHRGITEMS_LOADING"
export const SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_CATEGORIES = "SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_CATEGORIES"
export const SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DEFAULTS = "SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DEFAULTS"
export const SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DISPLAY_ALL_IND = "SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_DISPLAY_ALL_IND"
export const SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SEARCH_NAME = "SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SEARCH_NAME"
export const SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_ASSESSMENT = "SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_ASSESSMENT"
export const SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_TYPE = "SET_ADM_CUSTOM_DEFAULT_ASSESSMENT_SELECTED_TYPE"
export const SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES = "SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES"
export const SET_ADM_CUSTOM_DEFAULT_EVALUATION_DEFAULTS = "SET_ADM_CUSTOM_DEFAULT_EVALUATION_DEFAULTS"
export const SET_ADM_CUSTOM_DEFAULT_EVALUATION_DISPLAY_ALL_IND = "SET_ADM_CUSTOM_DEFAULT_EVALUATION_DISPLAY_ALL_IND"
export const SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME = "SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME"
export const SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION = "SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION"
export const SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE = "SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE"
export const SET_ADM_CUSTOM_DEFAULT_FOCUS_CATEGORIES = "SET_ADM_CUSTOM_DEFAULT_FOCUS_CATEGORIES"
export const SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS = "SET_ADM_CUSTOM_DEFAULT_FOCUS_DEFAULTS"
export const SET_ADM_CUSTOM_DEFAULT_FOCUS_DISPLAY_ALL_IND = "SET_ADM_CUSTOM_DEFAULT_FOCUS_DISPLAY_ALL_IND"
export const SET_ADM_CUSTOM_DEFAULT_FOCUS_SEARCH_NAME = "SET_ADM_CUSTOM_DEFAULT_FOCUS_SEARCH_NAME"
export const SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS = "SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS"
export const SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_TYPE = "SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_TYPE"
export const SET_ADM_CUSTOM_DEFAULT_INTERVENTION_CATEGORIES = "SET_ADM_CUSTOM_DEFAULT_INTERVENTION_CATEGORIES"
export const SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS = "SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DEFAULTS"
export const SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DISPLAY_ALL_IND = "SET_ADM_CUSTOM_DEFAULT_INTERVENTION_DISPLAY_ALL_IND"
export const SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SEARCH_NAME = "SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SEARCH_NAME"
export const SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_INTERVENTION = "SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_INTERVENTION"
export const SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_TYPE = "SET_ADM_CUSTOM_DEFAULT_INTERVENTION_SELECTED_TYPE"
export const SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE = "SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE"
export const SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION = "SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION"
export const SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DISPLAY = "SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DISPLAY"
export const SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME = "SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME"
export const SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_ACTIVE = "SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_ACTIVE"
export const SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DESCRIPTION = "SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DESCRIPTION"
export const SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DISPLAY = "SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DISPLAY"
export const SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_NAME = "SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_NAME"
export const SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_ACTIVE = "SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_ACTIVE"
export const SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DESCRIPTION = "SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DESCRIPTION"
export const SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_NAME = "SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_NAME"
export const SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE = "SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_ACTIVE"
export const SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION = "SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_DESCRIPTION"
export const SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME = "SET_ADM_CUSTOM_DEFAULT_NEW_INTERVENTION_NAME"
export const SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLES = "SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLES"
export const SET_ADM_HEALTH_CARE_PLAN_COMPANIES = "SET_ADM_HEALTH_CARE_PLAN_COMPANIES"
export const SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES = "SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES"
export const SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND = "SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND"
export const SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR = "SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR"
export const SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE = "SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_ACTIVE"
export const SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION = "SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_DESCRIPTION"
export const SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME = "SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_NAME"
export const SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY = "SET_ADM_HEALTH_CARE_PLAN_MODAL_COMPANY_SELECTED_INSURANCE_COMPANY"
export const SET_ADM_HEALTH_CARE_PLAN_SELECTED_ACCOUNTS_RECEIVABLE = "SET_ADM_HEALTH_CARE_PLAN_SELECTED_ACCOUNTS_RECEIVABLE"
export const SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY = "SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY"
export const SET_ADM_ICD_DISPLAY_ALL_IND = "SET_ADM_ICD_DISPLAY_ALL_IND"
export const SET_ADM_ICD_ICDS = "SET_ADM_ICD_ICDS"
export const SET_ADM_ICD_ICDS_LOADING = "SET_ADM_ICD_ICDS_LOADING"
export const SET_ADM_ICD_NEW_ICD_ACTIVE = "SET_ADM_ICD_NEW_ICD_ACTIVE"
export const SET_ADM_ICD_NEW_ICD_CODE = "SET_ADM_ICD_NEW_ICD_CODE"
export const SET_ADM_ICD_NEW_ICD_DESCRIPTION = "SET_ADM_ICD_NEW_ICD_DESCRIPTION"
export const SET_ADM_ICD_NEW_ICD_SELECTED_TYPE = "SET_ADM_ICD_NEW_ICD_SELECTED_TYPE"
export const SET_ADM_ICD_SEARCH_CODE = "SET_ADM_ICD_SEARCH_CODE"
export const SET_ADM_ICD_SEARCH_NAME = "SET_ADM_ICD_SEARCH_NAME"
export const SET_ADM_ICD_SELECTED_ICD = "SET_ADM_ICD_SELECTED_ICD"
export const SET_ADM_ICD_SELECTED_TYPE = "SET_ADM_ICD_SELECTED_TYPE"
export const SET_ADM_ICD_TYPES = "SET_ADM_ICD_TYPES"
export const SET_ADM_ITEM_INFO_DISPLAY = "SET_ADM_ITEM_INFO_DISPLAY"
export const SET_ADM_ITEM_MODAL_UNIT_AMOUNT = "SET_ADM_ITEM_MODAL_UNIT_AMOUNT"
export const SET_ADM_ITEM_MODAL_UNIT_DESCRIPTION = "SET_ADM_ITEM_MODAL_UNIT_DESCRIPTION"
export const SET_ADM_ITEM_MODAL_UNIT_ONE_TIME = "SET_ADM_ITEM_MODAL_UNIT_ONE_TIME"
export const SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT = "SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT"
export const SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT = "SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT"
export const SET_ADM_ITEM_MODAL_UNIT_UNITS = "SET_ADM_ITEM_MODAL_UNIT_UNITS"
export const SET_ADM_ITEM_SELECTED_UNIT = "SET_ADM_ITEM_SELECTED_UNIT"
export const SET_ADM_ITEM_SOURCE_MODAL_NEW_ITEM_CONTRAINDICATIONS_WARNINGS = "SET_ADM_ITEM_SOURCE_MODAL_NEW_ITEM_CONTRAINDICATIONS_WARNINGS"
export const SET_ADM_ITEM_UNIT_CONVERSIONS = "SET_ADM_ITEM_UNIT_CONVERSIONS"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS_LOADING = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS_LOADING"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_DISPLAY_ALL_IND = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_DISPLAY_ALL_IND"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_SEARCH_NAME = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_SEARCH_NAME"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_COMMENT = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_COMMENT"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_TYPE = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_TYPE"
export const SET_ADM_LABORATORY_ANTIGEN_COMMENT_TYPES = "SET_ADM_LABORATORY_ANTIGEN_COMMENT_TYPES"
export const SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS = "SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS"
export const SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING = "SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING"
export const SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND = "SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND"
export const SET_ADM_LABORATORY_PCR_COMMENT_NEW_ACTIVE = "SET_ADM_LABORATORY_PCR_COMMENT_NEW_ACTIVE"
export const SET_ADM_LABORATORY_PCR_COMMENT_NEW_DESCRIPTION = "SET_ADM_LABORATORY_PCR_COMMENT_NEW_DESCRIPTION"
export const SET_ADM_LABORATORY_PCR_COMMENT_NEW_NAME = "SET_ADM_LABORATORY_PCR_COMMENT_NEW_NAME"
export const SET_ADM_LABORATORY_PCR_COMMENT_NEW_SELECTED_TYPE = "SET_ADM_LABORATORY_PCR_COMMENT_NEW_SELECTED_TYPE"
export const SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME = "SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME"
export const SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT = "SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT"
export const SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE = "SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE"
export const SET_ADM_LABORATORY_PCR_COMMENT_TYPES = "SET_ADM_LABORATORY_PCR_COMMENT_TYPES"
export const SET_ADM_LEVEL_DISPLAY_ALL_IND = "SET_ADM_LEVEL_DISPLAY_ALL_IND"
export const SET_ADM_LEVEL_LEVELS = "SET_ADM_LEVEL_LEVELS"
export const SET_ADM_LEVEL_LEVELS_LOADING = "SET_ADM_LEVEL_LEVELS_LOADING"
export const SET_ADM_LEVEL_MODAL_NEW_ACTIVE = "SET_ADM_LEVEL_MODAL_NEW_ACTIVE"
export const SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION = "SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION"
export const SET_ADM_LEVEL_MODAL_NEW_NAME = "SET_ADM_LEVEL_MODAL_NEW_NAME"
export const SET_ADM_LEVEL_SEARCH_NAME = "SET_ADM_LEVEL_SEARCH_NAME"
export const SET_ADM_LEVEL_SELECTED_LEVEL = "SET_ADM_LEVEL_SELECTED_LEVEL"
export const SET_ADM_LOCATION_FILTER = "SET_ADM_LOCATION_FILTER"
export const SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY = "SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY"
export const SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY = "SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY"
export const SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY = "SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY"
export const SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY = "SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY"
export const SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY = "SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY"
export const SET_ADM_LOC_MODAL_NEW_SELECTED_LOC = "SET_ADM_LOC_MODAL_NEW_SELECTED_LOC"
export const SET_ADM_LOC_ORGS = "SET_ADM_LOC_ORGS"
export const SET_ADM_MODAL_NEW_ROLE = "SET_ADM_MODAL_NEW_ROLE"
export const SET_ADM_MODAL_NEW_SKIP_IND = "SET_ADM_MODAL_NEW_SKIP_IND"
export const SET_ADM_MODAL_NEW_WHT_TYPE = "SET_ADM_MODAL_NEW_WHT_TYPE"
export const SET_ADM_NEW_TYPE_DISPLAY_ADDRESS = "SET_ADM_NEW_TYPE_DISPLAY_ADDRESS"
export const SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT = "SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT"
export const SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT = "SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT"
export const SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM = "SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM"
export const SET_ADM_NEW_TYPE_DISPLAY_CODE = "SET_ADM_NEW_TYPE_DISPLAY_CODE"
export const SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT = "SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT"
export const SET_ADM_NEW_TYPE_DISPLAY_INVENTORY = "SET_ADM_NEW_TYPE_DISPLAY_INVENTORY"
export const SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT = "SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT"
export const SET_ADM_NEW_TYPE_DISPLAY_PERCENT = "SET_ADM_NEW_TYPE_DISPLAY_PERCENT"
export const SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL = "SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL"
export const SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN = "SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN"
export const SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT = "SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT"
export const SET_ADM_NEW_TYPE_DISPLAY_TEL = "SET_ADM_NEW_TYPE_DISPLAY_TEL"
export const SET_ADM_SERVICE_NEW_SERVICE_ACTIVE = "SET_ADM_SERVICE_NEW_SERVICE_ACTIVE"
export const SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPE = "SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPE"
export const SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPES = "SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPES"
export const SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM = "SET_ADM_SERVICE_NEW_SERVICE_CATEGORY_NUM"
export const SET_ADM_SERVICE_NEW_SERVICE_CODE = "SET_ADM_SERVICE_NEW_SERVICE_CODE"
export const SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION = "SET_ADM_SERVICE_NEW_SERVICE_DESCRIPTION"
export const SET_ADM_SERVICE_NEW_SERVICE_DISPLAY = "SET_ADM_SERVICE_NEW_SERVICE_DISPLAY"
export const SET_ADM_SERVICE_NEW_SERVICE_DURATION = "SET_ADM_SERVICE_NEW_SERVICE_DURATION"
export const SET_ADM_SERVICE_NEW_SERVICE_FIX_AMOUNT = "SET_ADM_SERVICE_NEW_SERVICE_FIX_AMOUNT"
export const SET_ADM_SERVICE_NEW_SERVICE_FS_CODE = "SET_ADM_SERVICE_NEW_SERVICE_FS_CODE"
export const SET_ADM_SERVICE_NEW_SERVICE_GOVERNMENT_CODE = "SET_ADM_SERVICE_NEW_SERVICE_GOVERNMENT_CODE"
export const SET_ADM_SERVICE_NEW_SERVICE_LAB_TEST = "SET_ADM_SERVICE_NEW_SERVICE_LAB_TEST"
export const SET_ADM_SERVICE_NEW_SERVICE_LAB_TESTS = "SET_ADM_SERVICE_NEW_SERVICE_LAB_TESTS"
export const SET_ADM_SERVICE_NEW_SERVICE_NAME = "SET_ADM_SERVICE_NEW_SERVICE_NAME"
export const SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT = "SET_ADM_SERVICE_NEW_SERVICE_PRICE_PER_UNIT"
export const SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE = "SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE"
export const SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE = "SET_ADM_SERVICE_NEW_SERVICE_SELECTED_TYPE"
export const SET_ADM_SERVICE_NEW_SERVICE_TMLT_TMT_CODE = "SET_ADM_SERVICE_NEW_SERVICE_TMLT_TMT_CODE"
export const SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT = "SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT"
export const SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE = "SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE"
export const SET_ADM_SUPPLIER_MODAL_NEW_EMAIL = "SET_ADM_SUPPLIER_MODAL_NEW_EMAIL"
export const SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID = "SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID"
export const SET_ADM_TYPES = "SET_ADM_TYPES"
export const SET_ADM_TYPE_MODAL_NEW_ADDRESS = "SET_ADM_TYPE_MODAL_NEW_ADDRESS"
export const SET_ADM_TYPE_MODAL_NEW_TEL = "SET_ADM_TYPE_MODAL_NEW_TEL"
export const SET_ADM_USER_ROLE_TYPES = "SET_ADM_USER_ROLE_TYPES"
export const SET_CONFIG_LABORATORY_ANTIGEN_COMMENT_NEW_DISPLAY = "SET_CONFIG_LABORATORY_ANTIGEN_COMMENT_NEW_DISPLAY"
export const SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY = "SET_CONFIG_LABORATORY_PCR_COMMENT_NEW_DISPLAY"
export const SET_GENERAL_TYPES = "SET_GENERAL_TYPES"
export const SET_GENERAL_TYPE_LOADING = "SET_GENERAL_TYPE_LOADING"
export const SET_LOCATIONS = "SET_LOCATIONS"
export const SET_LOCATION_DISPLAY_INACTIVE = "SET_LOCATION_DISPLAY_INACTIVE"
export const SET_LOCATION_LOADING = "SET_LOCATION_LOADING"
export const SET_LOC_BRANCH = "SET_LOC_BRANCH"
export const SET_LOC_CHART_OF_ACCOUNTS = "SET_LOC_CHART_OF_ACCOUNTS"
export const SET_LOC_GENERAL = "SET_LOC_GENERAL"
export const SET_LOC_INVENTORY = "SET_LOC_INVENTORY"
export const SET_LOC_IPD = "SET_LOC_IPD"
export const SET_LOC_MODAL_NEW_AP_CODES = "SET_LOC_MODAL_NEW_AP_CODES"
export const SET_LOC_MODAL_NEW_AR_CODES = "SET_LOC_MODAL_NEW_AR_CODES"
export const SET_LOC_OPD = "SET_LOC_OPD"
export const SET_LOC_OPERATING_ROOM = "SET_LOC_OPERATING_ROOM"
export const SET_LOC_ORG = "SET_LOC_ORG"
export const SET_LOC_SEARCH_TEXT = "SET_LOC_SEARCH_TEXT"
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT"
export const SET_SELECTED_LOC = "SET_SELECTED_LOC"
export const SET_SELECTED_TYPE = "SET_SELECTED_TYPE"
export const SET_TYPE_DISPLAY_INACTIVE = "SET_TYPE_DISPLAY_INACTIVE"
export const SET_TYPE_URL = "SET_TYPE_URL"