export const SET_PR_LOCATIONS = "SET_PR_LOCATIONS"
export const SET_PR_LOCATION_LOADING = "SET_PR_LOCATION_LOADING"
export const SET_PR_LOCATION_ORG = "SET_PR_LOCATION_ORG"
export const SET_PR_LOCATION_BRANCH = "SET_PR_LOCATION_BRANCH"
export const SET_PR_SELECTED_ORG = "SET_PR_SELECTED_ORG"
export const SET_PR_SELECTED_BRANCH = "SET_PR_SELECTED_BRANCH"
export const SET_PR_SEARCH_LOCATION = "SET_PR_SEARCH_LOCATION"
export const SET_PR_APPROVER_APPROVED = "SET_PR_APPROVER_APPROVED"
export const SET_PR_APPROVE_EVENT = "SET_PR_APPROVE_EVENT"
export const SET_PR_APPROVE_APPROVE_IND = "SET_PR_APPROVE_APPROVE_IND"
export const SET_PR_MIN_STOCK_SEARCH_CODE = "SET_PR_MIN_STOCK_SEARCH_CODE"
export const SET_PR_MIN_STOCK_SEARCH_NAME = "SET_PR_MIN_STOCK_SEARCH_NAME"
export const SET_PR_MIN_STOCK_LAST_PURCHASED_INFO = "SET_PR_MIN_STOCK_LAST_PURCHASED_INFO"
export const SET_PR_NEW_PR_LAST_PURCHASED_INFO = "SET_PR_NEW_PR_LAST_PURCHASED_INFO"
export const SET_PR_NEW_PR_SEARCH_NAME = "SET_PR_NEW_PR_SEARCH_NAME"
