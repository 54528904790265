import React from "react"
import { Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue } from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_CLOSE,
	SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import CashierModalAdjustmentDetails from "./CashierModalAdjustmentDetails"

class CashierModalAdjustment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierModalAdjustment",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (!this.props.isDisplay) {
				this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, "")
				this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_CLOSE, false)
				this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE, false)
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, true)
	}

	isDoneBtnDisabled() {
		return Utils.BigNumber(this.props.cashier.modalAdjustment.balance).isLessThanOrEqualTo(0) && this.props.cashier.modalAdjustment.isBalanceDisplay
	}

	onClose() {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_CLOSE, true)
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_CONTINUE, true)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.adjustments)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<CashierModalAdjustmentDetails {...this.props} />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.cashier.isAdjustmentDisplay,
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalAdjustment)
