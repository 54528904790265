import {
	SET_REPORT_TF_BY_LOCATION_APPROVED_IND,
	SET_REPORT_TF_BY_LOCATION_BRANCHES,
	SET_REPORT_TF_BY_LOCATION_CERTIFIED_IND,
	SET_REPORT_TF_BY_LOCATION_ORGS,
	SET_REPORT_TF_BY_LOCATION_SELECTED_BRANCH,
	SET_REPORT_TF_BY_LOCATION_SELECTED_ORG,
} from "~/redux/type"

export const setReportTFByLocationOrgs = (orgs) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_REPORT_TF_BY_LOCATION_ORGS,
			payload: {
				orgs: orgs,
			},
		})
		return Promise.resolve()
	}
}

export const setReportTFByLocationSelectedOrg = (index, org) => {
	return {
		type: SET_REPORT_TF_BY_LOCATION_SELECTED_ORG,
		payload: {
			index: index,
			org: org,
		},
	}
}

export const setReportTFByLocationBranches = (branches) => {
	return {
		type: SET_REPORT_TF_BY_LOCATION_BRANCHES,
		payload: {
			branches: branches,
		},
	}
}

export const setReportTFByLocationSelectedBranch = (index, branch) => {
	return {
		type: SET_REPORT_TF_BY_LOCATION_SELECTED_BRANCH,
		payload: {
			index: index,
			branch: branch,
		},
	}
}

export const setReportTFByLocationCertified = (isCertified) => {
	return {
		type: SET_REPORT_TF_BY_LOCATION_CERTIFIED_IND,
		payload: {
			isCertified: isCertified,
		},
	}
}

export const setReportTFByLocationApproved = (isApproved) => {
	return {
		type: SET_REPORT_TF_BY_LOCATION_APPROVED_IND,
		payload: {
			isApproved: isApproved,
		},
	}
}
