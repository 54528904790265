import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportFirstAssessmentDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "First Assessment",
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
							displayTitleEng={false}
							styles={{
								title: {
									fontSize: 36,
								},
							}}
						/>

						{/* Patient Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.id}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Name: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.dob}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Ref HN: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.hn}</Text>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18 }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 10 }]}>
								<Text>สภาพผู้ป่วยเมื่อมาที่แผนก</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.checkBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
								<Text>เดินเข้ามา</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
								<Text>รถนั่ง</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
								<Text>เปลนอน</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text>อื่น: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
								<Text></Text>
							</View>
						</View>

						{/* PI */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18 }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>อาการสำคัญ: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
								<Text>{this.state.data.pi}</Text>
							</View>
						</View>
						{/* <View style={ResourceAssistance.Report.styles.separator} /> */}

						{/* Patient health */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>ประวัติส่วนตัวผู้ป่วย</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18, paddingLeft: 10 }]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>ประวัติการเจ็บป่วยปัจจุบัน: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
										<Text>{this.state.data.pastIllnesses}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
										<Text>ประวัติการเจ็บป่วยในอดีต</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row, { paddingLeft: 10 }]}>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 10 }]}>
												<Text>ประวัติการผ่าตัด:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
												<Text>ไม่เคย</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>เคย/เมื่อ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>โรค </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 10 }]}>
												<Text>ประวัติการรับประทานยาเดิม:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
												<Text>ไม่มี</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>มี/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 10 }]}>
												<Text>ประวัติการแพ้ยา:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
												<Text>ไม่มี</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>มี/ระบุชนิดและอาการที่แพ้ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 10 }]}>
												<Text>ประวัติการแพ้อาหาร:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
												<Text>ไม่มี</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>มี/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>อาหารที่ไม่รับประทาน/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>โรคประจำตัวระบุ: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
												<Text>{this.state.data.underlyingDisease}</Text>
											</View>
										</View>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>ประวัติการเจ็บป่วยในครอบครัว/โรคทางพันธุกรรม: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
										<Text></Text>
									</View>
								</View>
							</View>
						</View>
						{/* <View style={ResourceAssistance.Report.styles.separator} /> */}

						{/* Physical Examination, Vital Sign */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>การประเมินสภาพร่างกายสัญญาณชีพ</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { paddingLeft: 10 }]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.temperature]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>T.(C)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pr]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>PR./min</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rr]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>RR./min</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bp]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>BP.(mmHg)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bw]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>BW.(kg)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.ht]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>HT.(cm)</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bmi]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>BMI</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.oxygen]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>O{ResourceAssistance.Symbol[2]}Sat</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.temperature]}>
										<Text>{this.state.data.temperature}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pr]}>
										<Text>{this.state.data.pr}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.rr]}>
										<Text>{this.state.data.rr}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bp]}>
										<Text>{this.state.data.bp}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bw]}>
										<Text>{this.state.data.bw}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.ht]}>
										<Text>{this.state.data.ht}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.bmi]}>
										<Text>{this.state.data.bmi}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.oxygen]}>
										<Text>{this.state.data.oxygenSat}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />

								{/*  */}
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18 }]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบประสาท</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบห่อหุ้มร่างกาย/ผิวหนัง</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบหัวใจการไหลเวียนของโลหิตและต่อมน้ำเหลือง</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบทางเดินหายใจ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบทางเดินอาหารและขับถ่าย</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบกล้ามเนื้อและกระดูก</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบต่อมไร้ท่อ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบทางเดินปัสสาวะ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบอวัยวะสืบพันธุ์</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ระบบหูคอจมูกและตา</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>การดูแลช่วยเหลือตนเอง</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ความรู้เกี่ยวกับการเจ็บป่วยครั้งนี้</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ภาวะเสี่ยงจากอุบัติเหตุ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { minHeight: 15 }]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>การประเมินสภาพทางจิตใจอารมณ์และสังคม</Text>
											</View>
										</View>
									</View>

									{/*  */}
									<View style={[ResourceAssistance.Report.styles.colAuto, { fontSize: 18 }]}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ปกติ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ได้</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>เข้าใจดี</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ไม่เสี่ยง</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.checkBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ไม่มี</Text>
											</View>
										</View>
									</View>

									{/*  */}
									<View style={[ResourceAssistance.Report.styles.col, { fontSize: 18 }]}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={{ fontSize: 15 }}>ประจำเดือนครั้งสุดท้าย</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ผิดปกติ/ระบุ </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ไม่ได้</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ยังไม่เข้าใจ</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>เสี่ยง </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>ให้คำแนะนำแนวทางการป้องกัน</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
												<Text></Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={{ fontSize: 15 }}>มี (ภาพลักษณ์ตนเอง สภาพอารมณ์/จิตใจ ครอบครัว)</Text>
											</View>
										</View>
									</View>
								</View>

								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18 }]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>สิ่งที่กังวลใจไม่สบายใจในการเจ็บป่วยครั้งนี้คือ: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
										<Text></Text>
									</View>
								</View>
							</View>
						</View>
						{/* <View style={ResourceAssistance.Report.styles.separator} /> */}

						{/* Values and Beliefs */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>คุณค่าและความเชื่อ</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18, paddingLeft: 10 }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>ความต้องการปฏิบัติกิจกรรมทางศาสนา </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.checkBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 10 }]}>
								<Text>ไม่ต้องการ</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text>ต้องการ/ระบุ </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
								<Text></Text>
							</View>
						</View>
						{/* <View style={ResourceAssistance.Report.styles.separator} /> */}

						{/* Pain Level */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16, marginTop: 5 }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>การประเมิน Pain: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.checkBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>ไม่ปวด</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>ปวดเล็กน้อย</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>ปวดปานกลาง</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>ปวดมาก</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, { marginRight: 5 }]}>
								<Text>ปวดมากที่สุด</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text>ปวดไม่สามารถทนได้</Text>
							</View>
						</View>
						{/* <View style={ResourceAssistance.Report.styles.separator} /> */}

						{/* Paitent Problem Summary */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18 }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>สรุปปัญหาของผู้ป่วย: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, styles.dashBottom]}>
								<Text>{this.state.data.diagnosis}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Signature */}
						<Signature
							signatureDate={this.state.data.signatureDate}
							numOfSignature={2}
							signatureTitles={this.state.data.signatureTitles}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	dashBottom: {
		borderBottomStyle: "dashed",
		borderBottomWidth: 1,
	},
})

export default ReportFirstAssessmentDocument
