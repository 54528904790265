import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setCashierModalNurseOrders, setSelected } from "~/redux/action"
import { SET_CASHIER_MODAL_NURSE_SELECTED_ORDER } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierModalNurseOrder extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		this.props.setCashierModalNurseOrders(this.props.cashier.selectedAdmission.admission.nurseOrders)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.cashier.modalNurse.pendingOrderTable.original,
				this.props.cashier.modalNurse.pendingOrderTable.original
			)
		) {
			this.props.setCashierModalNurseOrders(this.props.cashier.modalNurse.orderTable.original)
			this.props.setSelected(SET_CASHIER_MODAL_NURSE_SELECTED_ORDER, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_CASHIER_MODAL_NURSE_SELECTED_ORDER, -1, null)
	}

	onSelect(lab, index) {
		this.props.setSelected(
			SET_CASHIER_MODAL_NURSE_SELECTED_ORDER,
			index,
			this.props.cashier.modalNurse.orderTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						bordered
						hover
						isClearHighlight={this.props.cashier.modalNurse.selectedOrder.index === -1}
						data={this.props.cashier.modalNurse.orderTable}
						onClick={this.onSelect}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierModalNurseOrders,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalNurseOrder)
