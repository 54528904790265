import { Button, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FiRefreshCw } from "react-icons/fi"
import { RiUserSearchFill } from "react-icons/ri"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_CASHIER_SEARCH_PATIENT_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CashierLeftPatientRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSearch = this.onSearch.bind(this)
		this.onRefresh = this.onRefresh.bind(this)
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_CASHIER_SEARCH_PATIENT_DISPLAY, true)
	}

	onRefresh() {
		this.props.getPatientById(this.props.cashier.patient.id)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={1}
							extra={
								<Row className="g-3">
									<Col md="auto">
										<Button
											type="primary"
											size="middle"
											shape="circle"
											icon={<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />}
											onClick={this.onSearch}
										/>
									</Col>
									<Col md="auto">
										<Button
											type="primary"
											size="middle"
											shape="circle"
											icon={<FiRefreshCw size={ResourceAssistance.ReactIcon.size} />}
											onClick={this.onRefresh}
										/>
									</Col>
								</Row>
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
								{this.props.cashier.patient.id}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
								{this.props.cashier.patient.title} {this.props.cashier.patient.firstName} {this.props.cashier.patient.lastName}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{Utils.formatDate(this.props.cashier.patient.dobtime)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
								{this.props.cashier.patient.identificationNumber}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCarePlan)} span={1}>
								{this.props.cashier.selectedAdmission.admission && this.props.cashier.selectedAdmission.admission.clinicalRecord
									? _.isEmpty(this.props.cashier.selectedAdmission.admission.clinicalRecord.healthcareOther)
										? this.props.cashier.selectedAdmission.admission.clinicalRecord.healthcareName
										: this.props.cashier.selectedAdmission.admission.clinicalRecord.healthcareOther
									: ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierLeftPatientRow)
