import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineSchedule } from "react-icons/ai"
import { RiUserSearchFill } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected } from "~/redux/action"
import { SET_OPD_APPOINTMENT_DISPLAY, SET_OPD_SEARCH_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OutpatientLeftPatientAction extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchPatient = this.onSearchPatient.bind(this)
		this.onAppointment = this.onAppointment.bind(this)
	}

	onSearchPatient(event) {
		this.props.onModalDisplayAction(SET_OPD_SEARCH_DISPLAY, true)
	}

	onAppointment(event) {
		this.props.onModalDisplayAction(SET_OPD_APPOINTMENT_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col />
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.info}
						onClick={this.onSearchPatient}
					>
						<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) && (
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							onClick={this.onAppointment}
						>
							<AiOutlineSchedule size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.appointment)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientLeftPatientAction))
