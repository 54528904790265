import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import TFLeftFirstRow from "./TFLeftFirstRow"
import TFLeftFourthRow from "./TFLeftFourthRow"
import TFLeftSecondRow from "./TFLeftSecondRow"
import TFLeftThirdRow from "./TFLeftThirdRow"
import TFLeftZthRow from "./TFLeftZthRow"

class TFLeft extends React.Component {
	render() {
		return (
			<Container fluid className={`border ${ResourceAssistance.CSS.fullFlex}`}>
				<TFLeftFirstRow />
				<TFLeftSecondRow />
				<TFLeftThirdRow {...this.props} />
				<TFLeftFourthRow />
				<TFLeftZthRow />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFLeft)
