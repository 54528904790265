export const configEclaimVariable = [
	{ id: "HN", displayName: "Patient number (HN)" },
	{ id: "INSCL", displayName: "Treatment rights (INSCL)" },
	{ id: "SUBTYPE", displayName: "Coverage level (SUBTYPE)" },
	{ id: "CID", displayName: "Card ID (CID)" },
	{ id: "HCODE", displayName: "Hospital number (HCODE)" },
	{ id: "DATEEXP", displayName: "Rights Expired date (DATEEXP)" },
	{ id: "HOSPMAIN", displayName: "Main hospital (HOSPMAIN)" },
	{ id: "HOSPSUB", displayName: "Primary hospital (HOSPSUB)" },
	{ id: "GOVCODE", displayName: "Goverment code GOVCODE)" },
	{ id: "GOVNAME", displayName: "Goverment name (GOVNAME)" },
	{ id: "PERMITNO", displayName: "Authen Number (PERMITNO)" },
	{ id: "DOCNO", displayName: "Document Number (DOCNO)" },
	{ id: "OWNRPID", displayName: "Civil ID (OWNRPID)" },
	{ id: "OWNNAME", displayName: "Civil Name (OWNNAME)" },
	{ id: "AN", displayName: "IPD patient number (AN)" },
	{ id: "SEQ", displayName: "Sequence Number (SEQ)" },
	{ id: "SUBINSCL", displayName: "Owner Rights Type (SUBINSCL)" },
	{ id: "RELINSCL", displayName: "Relationship (RELINSCL)" },
	{ id: "HTYPE", displayName: "Hospital type (HTYPE)" },
	{ id: "CHANGWAT", displayName: "Code as Interior (CHANGWAT)" },
	{ id: "AMPHUR", displayName: "Code as Interior (AMPHUR)" },
	{ id: "DOB", displayName: "Date of birth (DOB)" },
	{ id: "SEX", displayName: "Sex (SEX)" },
	{ id: "MARRIAGE", displayName: "Marriage (MARRIAGE)" },
	{ id: "OCCUPA", displayName: "Career (OCCUPA)" },
	{ id: "NATION", displayName: "Nation (NATION)" },
	{ id: "PERSON_ID", displayName: "Person Id (PERSON_ID)" },
	{ id: "NAMEPAT", displayName: "Full name (NAMEPAT)" },
	{ id: "TITLE", displayName: "Title (TITLE)" },
	{ id: "FNAME", displayName: "First Name (FNAME)" },
	{ id: "LNAME", displayName: "Last (LNAME)" },
	{ id: "IDTYPE", displayName: "ID Type (IDTYPE)" },
	{ id: "CLINIC", displayName: "Clinic (CLINIC)" },
	{ id: "DATEOPD", displayName: "OPD Date (DATEOPD)" },
	{ id: "TIMEOPD", displayName: "OPD Time (TIMEOPD)" },
	{ id: "UUC", displayName: "Rights usage (UUC)" },
	{ id: "DETAIL", displayName: "Symptoms (DETAIL)" },
	{ id: "BTEMP", displayName: "Body Temperature (BTEMP)" },
	{ id: "SBP", displayName: "Systolic Blood Pressure (SBP)" },
	{ id: "DBP", displayName: "Diastolic Blood Pressure (DBP)" },
	{ id: "PR", displayName: "Pluse Rate (PR)" },
	{ id: "RR", displayName: "Respiratory Rate (RR)" },
	{ id: "OPD_OPTYPE", displayName: "OPD Operation Type (OPTYPE)" },
	{ id: "TYPEIN", displayName: "Input Type (TYPEIN)" },
	{ id: "TYPEOUT", displayName: "Output Type (TYPEOUT)" },
	{ id: "REFER", displayName: "Transferred Hospital Code (REFER)" },
	{ id: "REFERTYPE", displayName: "Transfer Type (REFERTYPE)" },
	{ id: "REFERDATE", displayName: "Transfer Date (REFERDATE)" },
	{ id: "DATEDX", displayName: "Disgnose Date (DATEDX)" },
	{ id: "DIAG", displayName: "ICD 10 Code (DIAG)" },
	{ id: "DXTYPE", displayName: "Disgnose Type (DXTYPE)" },
	{ id: "DRDX", displayName: "Medical license number (DRDX)" },
	{ id: "OPER", displayName: "ICD 9 code (OPER)" },
	{ id: "DROPID", displayName: "Medical license number (DROPID)" },
	{ id: "SERVPRICE", displayName: "Service Price (SERVPRICE)" },
	{ id: "DATEADM", displayName: "Admit Date (DATEADM)" },
	{ id: "TYPEADM", displayName: "Admit Time (TIMEADM)" },
	{ id: "DATEDSC", displayName: "Discharge Date (DATEDSC)" },
	{ id: "TIMEDSC", displayName: "Discharge Time (TIMEDSC)" },
	{ id: "DISCHS", displayName: "Discharge Status (DISCHS)" },
	{ id: "DISCHT", displayName: "Discharge Method (DISCHT)" },
	{ id: "WARDDSC", displayName: "Discharge Ward Name (WARDDSC)" },
	{ id: "DEPT", displayName: "Discharge Department (DEPT)" },
	{ id: "ADM_W", displayName: "Admit Wieght (ADM_W)" },
	{ id: "SVCTYPE", displayName: "Service Type (SVCTYPE)" },
	{ id: "IOP_OPTYPE", displayName: "IOP Operation Type (OPTYPE)" },
	{ id: "IOP_DATEIN", displayName: "IOP Start Date (DATEIN)" },
	{ id: "IOP_TIMEIN", displayName: "IOP Start Time (TIMEIN)" },
	{ id: "IOP_DATEOUT", displayName: "IOP End Date (DATEOUT)" },
	{ id: "IOP_TIMEOUT", displayName: "IOP End Time (TIMEOUT)" },
	{ id: "CHA_DATE", displayName: "Medical Billing Date (DATE)" },
	{ id: "TOTAL", displayName: "Total Medical Amount (TOTAL)" },
	{ id: "PAID", displayName: "Patient's Self-Payment Amount (PAID)" },
	{ id: "PTTYPE", displayName: "Treatment Code (PTTYPE)" },
	{ id: "OPD_MEMO", displayName: "Extra Service Details (OPD_MEMO)" },
	{ id: "INVOICE_NO", displayName: "Invoice Number (INVOICE_NO)" },
	{ id: "INVOICE_LT", displayName: "Invoice Information Number (INVOICE_LT)" },
	{ id: "CHRGITEM", displayName: "CHARGE ITEM (CHRGITEM)" },
	{ id: "AMOUNT", displayName: "SERVICE_PRICE (AMOUNT)" },
	{ id: "AUTHAE", displayName: "Claim Code (AUTHAE)" },
	{ id: "AEDATE", displayName: "Accident Date (AEDATE)" },
	{ id: "AETIME", displayName: "Accident Time (AETIME)" },
	{ id: "AETYPE", displayName: "Rights in Case of Accidents (AETYPE)" },
	{ id: "REFER_NO", displayName: "Refer Document no. (REFER_NO)" },
	{ id: "REFMAINI", displayName: "Refer Hospital Code (REFMAINI)" },
	{ id: "IREFTYPE", displayName: "Received Refer Purpose Code (IREFTYPE)" },
	{ id: "REFMAINO", displayName: "Transfer Refer Hospital Code (REFMAINO)" },
	{ id: "OREFTYPE", displayName: "Transfer Refer Purpose Type (OREFTYPE)" },
	{ id: "UCAE", displayName: "Accident / Emergency Type (UCAE)" },
	{ id: "EMTYPE", displayName: "Emergenfy indentify code (EMTYPE)" },
	{ Id: "DALERT", displayName: "Notification Date (DALERT)" },
	{ id: "ADP_TYPE", displayName: "ADP Type (TYPE)" },
	{ id: "GOV_CODE", displayName: "Government Code (CODE)" },
	{ id: "QTY", displayName: "Usage Quantity (QTY)" },
	{ id: "RATE", displayName: "Price per Unit (RATE)" },
	{ id: "CAGCODE", displayName: "Cancer Type (CAGCODE)" },
	{ id: "DOSE", displayName: "Dose (DOSE)" },
	{ id: "CA_TYPE", displayName: "Cancer Treatment Type (CA_TYPE)" },
	{ id: "SERIALNO", displayName: "Intrument Serial Number (SERIALNO)" },
	{ id: "USER_STATUS", displayName: "User Status (USER_STATUS)" },
	{ id: "ADP_TOTAL", displayName: "Total Claimed Amount (ADP_TOTAL)" },
	{ id: "QTYDAY", displayName: "Claimed Days (QTYDAY)" },
	{ id: "TMLTCODE", displayName: "TMLT Code (TMLTCODE)" },
	{ id: "STATUS1", displayName: "COVID LAB Result (STATUS1)" },
	{ id: "BI", displayName: "Barthel ADL Index (BI)" },
	{ id: "ITEMSRC", displayName: "Code Type (ITEMSRC)" },
	{ id: "PROVIDER", displayName: "Medical license (PROVIDER)" },
	{ id: "GRAVIDA", displayName: "Pregnant Sequence (GRAVIDA)" },
	{ id: "GA_WEEK", displayName: "Pregnant Weeks (GA_WEEK)" },
	{ id: "DCIP_E_SCREEN", displayName: "DCIP/E SCREEN Code (DCIP/E_SCREEN)" },
	{ id: "LMP", displayName: "Last Menstrual Period Date (LMP)" },
	{ id: "SEQLVD", displayName: "Leave Sequence (SEQLVD)" },
	{ id: "LVD_DATEOUT", displayName: "Out Leaving Date (DATEOUT)" },
	{ id: "LVD_TIMEOUT", displayName: "Out Leaving Time (TIMEOUT)" },
	{ id: "LVD_DATEIN", displayName: "In Leaving Date (DATEIN)" },
	{ id: "LVD_TIMEIN", displayName: "In Leaving Time (TIMEIN)" },
	{ id: "LVD_QTYDAY", displayName: "Leave Days Quantity (QTYDAY)" },
	{ id: "DATE_SERV", displayName: "Service Date (DATE_SERV)" },
	{ id: "DID", displayName: "Drug Code (DID)" },
	{ id: "DIDNAME", displayName: "Drug Name (DIDNAME)" },
	{ id: "DRU_AMOUNT", displayName: "Drug Usage Amount (AMOUNT)" },
	{ id: "DRUGPRICE", displayName: "Price per Unit for Sale (DRUGPRICE)" },
	{ id: "DRUGCOST", displayName: "Cost Price (DRUGCOST)" },
	{ id: "DIDSTD", displayName: "Drug Code (DIDSTD)" },
	{ id: "UNIT", displayName: "Drug Unit (UNIT)" },
	{ id: "UNIT_PACK", displayName: "Packing Amount per Unit (UNIT_PACK)" },
	{ id: "DRUGREMARK", displayName: "Drug Usage Reason Code (DRUGREMARK)" },
	{ id: "PA_NO", displayName: "Approval Number (PA_NO)" },
	{ id: "TOTCOPAY", displayName: "Total unclaimed amount (TOTCOPAY)" },
	{ id: "DRUG_USE_STATUS", displayName: "Drug Usage Category (USE_STATUS)" },
	{ id: "DRUG_TOTAL", displayName: "Total Claimed Amount (TOTAL)" },
	{ id: "SIGCODE", displayName: "Drug usage Method Code (SIGCODE)" },
	{ id: "SIGTEXT", displayName: "Grug usage Method (SIGTEXT)" },
	{ id: "DRUG_PROVIDER", displayName: "Pharmacist Medical License Number (PROVIDER)" },
	{ id: "LABTEST", displayName: "Lab Test Code (LABTEST)" },
	{ id: "LABRESULT", displayName: "Lab Result (LABRESULT)" },
]
