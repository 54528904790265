import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLabPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_LAB_NEGATIVE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import CLModalNegativeDetails from "./CLModalNegativeDetails"

class CLModalNegative extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLModalNegative",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_NEGATIVE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			!this.props.lab.modalNegative.selectedComment.comment ||
			(this.props.lab.modalNegative.method === ResourceAssistance.CONSTANT.PCR &&
				_.isEmpty(this.props.lab.modalNegative.nGene) &&
				_.isEmpty(this.props.lab.modalNegative.n2Gene) &&
				_.isEmpty(this.props.lab.modalNegative.sGene) &&
				_.isEmpty(this.props.lab.modalNegative.oGene) &&
				_.isEmpty(this.props.lab.modalNegative.eGene))
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.lab.updateLabResult,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.lab.selectedLab.lab.id,
				patientId: this.props.lab.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				labResult: {
					analyte: this.props.lab.modalNegative.analyte,
					assay: this.props.lab.modalNegative.method,
					pcr:
						this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_SARS_COV_2_RNA_PCR ||
						this.props.lab.modalNegative.analyte === ResourceAssistance.CONSTANT.PCR_XPERT_XPRESS_SARS_COV_2,
					result: false,
					geneN: this.props.lab.modalNegative.nGene,
					geneN2: this.props.lab.modalNegative.n2Gene,
					geneS: this.props.lab.modalNegative.sGene,
					geneO: this.props.lab.modalNegative.oGene,
					geneE: this.props.lab.modalNegative.eGene,
					comment: this.props.lab.modalNegative.selectedComment.comment
						? this.props.lab.modalNegative.selectedComment.comment.comments
						: "",
				},
			},
		}

		let callback = (res) => {
			let patients = this.props.lab.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setLabPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.negative)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<CLModalNegativeDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.lab.isNegativeDisplay,
	login: state.login,
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setLabPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalNegative)
