import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"
import { LOCALES, ResourceAssistance } from "~/i18n"
import _ from "lodash"

class DebtPrintDataFactory extends PrintableDataFactory {
	static generateReceiptData = (org, debtPlan, logo) => {
		let grandDiscount = Utils.BigNumber(0)
		let grandTotal = Utils.BigNumber(0)

		return {
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.receipt }),
			idIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.id }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			invoiceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceNum }),
			patientIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			discountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.discount }),
			totalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.total }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			paymentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.payment }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			chequeNumberIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.checkNum }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			creditCardNumberIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.creditCard }),
			remarkIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Remark.theReceiptWillBeValidOnceThePaymentHasBeenReceived }),

			logo: logo,
			name: org.displayName,
			address: org.address.displayName,
			tel: org.address.tel,
			fax: org.address.fax,
			taxId: org.taxNo,

			id: debtPlan.id,
			customer: debtPlan.insuranceCompany,
			payment: debtPlan.payment,
			chequeNumber: debtPlan.checkNum,
			chequeDate: Utils.formatDate(debtPlan.checkDateTime),
			creditCardNumber: debtPlan.creditCard,
			// customerAddress:debtPlan.
			// customerTaxId:
			invoices: debtPlan.invoices
				.sort((a, b) => Utils.sort(a.billing.billingPlan.admission.patient.displayName, b.billing.billingPlan.admission.patient.displayName))
				.map((invoice) => {
					let totalBalance = invoice.billing.billingStatements.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
					let billingPlanAdjustments = invoice.billing.billingPlanAdjustments.reduce((total, cur) => {
						return total.plus(_.isEmpty(cur.accountantAdjustments) ? cur.adjustments : cur.accountantAdjustments)
					}, Utils.BigNumber(0))
					let billingStatementAdjustments = invoice.billing.billingStatements.reduce((total, cur) => {
						return _.isEmpty(cur.accountantAdjustment) ? total.plus(cur.adjustment) : total.plus(cur.accountantAdjustment)
					}, Utils.BigNumber(0))
					grandDiscount = grandDiscount.plus(billingPlanAdjustments).plus(billingStatementAdjustments)
					grandTotal = grandTotal.plus(invoice.total)
					return {
						id: invoice.id,
						patient: invoice.billing.billingPlan.admission.patient.displayName,
						amount: Utils.formatNumWithComma(totalBalance.minus(invoice.billing.total).toFixed(2)),
						discount: Utils.formatNumWithComma(billingPlanAdjustments.plus(billingStatementAdjustments).toFixed(2)),
						total: Utils.formatNumWithComma(Utils.BigNumber(invoice.total).toFixed(2)),
					}
				}),
			grandDiscount: Utils.formatNumWithComma(grandDiscount.toFixed(2)),
			grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),
			grandTotalText:
				this.getIntl().locale === LOCALES.ENGLISH
					? Utils.convertNumberToUSLetters(grandTotal.toFixed(2))
					: Utils.convertNumberToThaiLetters(grandTotal.toFixed(2)),

			signatureNames: [debtPlan.lastModifiedBy.displayName],
			signatureDate: Utils.formatDate(debtPlan.paymentDateTime),
		}
	}
}

export default DebtPrintDataFactory
