import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_ADM_COA_ASSETS_ACCOUNTS,
	SET_ADM_COA_EQUITY_ACCOUNTS,
	SET_ADM_COA_EXPENSES_ACCOUNTS,
	SET_ADM_COA_LIABILITIES_ACCOUNTS,
	SET_ADM_COA_REVENUES_ACCOUNTS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setConfigChartOfAccountsAssetsAccounts = (accounts) => {
	return (dispatch, getState) => {
		let filtered = accounts
			.filter((each) => {
				return (
					each.code.toLowerCase().includes(getState().admin.configCOA.assets.filterCode.toLowerCase()) &&
					each.description.toLowerCase().includes(getState().admin.configCOA.assets.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[each.subLevel, !each.subLevel],
				each.level,
				each.code,
				each.fullCode,
				each.displayName,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
			]
		})
		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.subLevel]], [[true, ResourceAssistance.Message.last]]]]
		})
		dispatch({
			type: SET_ADM_COA_ASSETS_ACCOUNTS,
			payload: {
				original: accounts,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setConfigChartOfAccountsLiabilitiesAccounts = (accounts) => {
	return (dispatch, getState) => {
		let filtered = accounts
			.filter((each) => {
				return (
					each.code.toLowerCase().includes(getState().admin.configCOA.liabilities.filterCode.toLowerCase()) &&
					each.description.toLowerCase().includes(getState().admin.configCOA.liabilities.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[each.subLevel, !each.subLevel],
				each.level,
				each.code,
				each.fullCode,
				each.displayName,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
			]
		})
		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.subLevel]], [[true, ResourceAssistance.Message.last]]]]
		})
		dispatch({
			type: SET_ADM_COA_LIABILITIES_ACCOUNTS,
			payload: {
				original: accounts,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setConfigChartOfAccountsEquityAccounts = (accounts) => {
	return (dispatch, getState) => {
		let filtered = accounts
			.filter((each) => {
				return (
					each.code.toLowerCase().includes(getState().admin.configCOA.equity.filterCode.toLowerCase()) &&
					each.description.toLowerCase().includes(getState().admin.configCOA.equity.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[each.subLevel, !each.subLevel],
				each.level,
				each.code,
				each.fullCode,
				each.displayName,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
			]
		})
		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.subLevel]], [[true, ResourceAssistance.Message.last]]]]
		})
		dispatch({
			type: SET_ADM_COA_EQUITY_ACCOUNTS,
			payload: {
				original: accounts,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setConfigChartOfAccountsExpenseAccounts = (accounts) => {
	return (dispatch, getState) => {
		let filtered = accounts
			.filter((each) => {
				return (
					each.code.toLowerCase().includes(getState().admin.configCOA.expense.filterCode.toLowerCase()) &&
					each.description.toLowerCase().includes(getState().admin.configCOA.expense.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[each.subLevel, !each.subLevel, !_.isEmpty(each.linkedAccountingCharts)],
				each.level,
				each.code,
				each.fullCode,
				each.displayName,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
			]
		})
		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((each) => {
			let msgAry = each.linkedAccountingCharts.reduce(
				(ary, cur) => {
					ary.push([false, cur.accountingChart.displayFullCodeName.concat(", ", cur.transDescr)])
					return ary
				},
				[[true, ResourceAssistance.Message.purchaseInvoice]]
			)
			return [[[[true, ResourceAssistance.Message.subLevel]], [[true, ResourceAssistance.Message.last]], msgAry]]
		})
		dispatch({
			type: SET_ADM_COA_EXPENSES_ACCOUNTS,
			payload: {
				original: accounts,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setConfigChartOfAccountsRevenueAccounts = (accounts) => {
	return (dispatch, getState) => {
		let filtered = accounts
			.filter((each) => {
				return (
					each.code.toLowerCase().includes(getState().admin.configCOA.revenue.filterCode.toLowerCase()) &&
					each.description.toLowerCase().includes(getState().admin.configCOA.revenue.filterName.toLowerCase())
				)
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []

		let body = filtered.map((each) => {
			return [
				[each.subLevel, !each.subLevel],
				each.level,
				each.code,
				each.fullCode,
				each.displayName,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
			]
		})
		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.subLevel]], [[true, ResourceAssistance.Message.last]]]]
		})
		dispatch({
			type: SET_ADM_COA_REVENUES_ACCOUNTS,
			payload: {
				original: accounts,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}
