import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalDoctorOrderPendingItems, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalDoctorOrderPending extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.hospitel.modalDoctorOrder.pendingItemTable.original, this.props.hospitel.modalDoctorOrder.pendingItemTable.original)) {
			this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setHospitelModalDoctorOrderPendingItems([])
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM, index, this.props.hospitel.modalDoctorOrder.pendingItemTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col id={ResourceAssistance.ID.HOSPITEL.hospitel.modalDoctorOrder.pendingItemTable}>
					<ScrollableTable
						style={{ minHeight: "200px", maxHeight: "200px" }}
						striped
						hover
						enableHighlight
						onClick={this.onSelectItem}
						data={this.props.hospitel.modalDoctorOrder.pendingItemTable}
						isClearHighlight={this.props.hospitel.modalDoctorOrder.selectedPendingItem.index === -1}
						highlightedRow={this.props.hospitel.modalDoctorOrder.selectedPendingItem.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setHospitelModalDoctorOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderPending))
