import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcPortraitMode } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import { EType } from "../Home"

class Administration extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0"} style={{ justifyContent: "center" }}>
					<Col md="auto">
						<h2 style={{ marginBottom: "0.5rem" }}>{translate(ResourceAssistance.Message.admin)}</h2>
					</Col>
				</Row>
				<Row style={{ justifyContent: "center" }}>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.ADMIN.COA} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.chartOfAccounts)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.TYPE} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.category)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.CUSTOM_DEFAULT} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.default)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.ADMIN.GENERAL_LEDGER} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.generalLedger)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.ICD} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.ICD)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.ITEM} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.item)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.LABORATORY} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Hospitel.lab)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.LOCATION} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.location)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.SERVICE} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Hospitel.service)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.ADMIN.WORKFLOW_PROPERTY} onClick={this.props.onBtnClick}>
							<IconFont type={"icon-ali-pmgcare-setting-01"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
							{translate(ResourceAssistance.Message.workflowProperty)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.link} value={EType.USER} onClick={this.props.onBtnClick}>
							<FcPortraitMode size={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.user)}
						</Button>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default injectIntl(connect(mapStateToProps, {})(Administration))
