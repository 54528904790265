import { ResourceAssistance } from "~/i18n"
import { SET_ADMIN_SERVICE_SERVICES } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setConfigServiceServices = (services) => {
	return (dispatch, getState) => {
		let filtered = services
			.filter((each) => {
				if (getState().admin.serviceConfig.service.displayInactive) {
					return (
						each.code.toLowerCase().includes(getState().admin.serviceConfig.service.searchCode.toLowerCase()) &&
						each.name.toLowerCase().includes(getState().admin.serviceConfig.service.searchName.toLowerCase())
					)
				} else {
					return (
						each.active &&
						each.code.toLowerCase().includes(getState().admin.serviceConfig.service.searchCode.toLowerCase()) &&
						each.name.toLowerCase().includes(getState().admin.serviceConfig.service.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []
		if (getState().admin.serviceConfig.service.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [
				each.code,
				each.fsCode,
				each.categoryNum,
				each.name,
				each.description,
				each.treatmentTime,
				Utils.formatNumWithComma(Utils.BigNumber(each.pricePerUnit).toFixed(2)),
				each.role ? each.role.displayName : "",
				each.adpType,
				each.labTest,
				each.governmentCode,
				each.tmltTmtCode,
			]
		})
		let colStyle = body.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		dispatch({
			type: SET_ADMIN_SERVICE_SERVICES,
			payload: {
				original: services,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
