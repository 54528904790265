import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import {
	loadItemsAction,
	loadPRsAction,
	loadPrTypesAction,
	selectPRAction,
	selectPrItemAction,
	setLoadingAction,
	setPrLocationOrgs,
	setPrLocations,
} from "~/redux/action"
import { SET_ITEM_LOADING, SET_PR_LOADING, SET_PR_LOCATION_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PRLeft from "./left_pannel/PRLeft"
import "./purchaseRequest.css"
import PRRight from "./right_pannel/PRRight"

class PurchaseRequest extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PurchaseRequest",
		}

		this.loadPrs = this.loadPrs.bind(this)
		this.loadPrTypes = this.loadPrTypes.bind(this)
		this.updateOriginalOrders = this.updateOriginalOrders.bind(this)
		this.isAllApproved = this.isAllApproved.bind(this)
		this.loadLocations = this.loadLocations.bind(this)
		this.loadItems = this.loadItems.bind(this)
	}

	async componentDidMount() {
		this.loadPrs()
		this.loadPrTypes()
		this.loadItems()
		this.loadLocations()
	}

	loadItems() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.inventory.getActiveItems}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = ({ data }) => {
			this.props.loadItemsAction(data.items)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ITEM_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_ITEM_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ITEM_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.pr.getLocations,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setPrLocations(res.data.locations)
			this.props.setPrLocationOrgs(res.data.locations)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PR_LOCATION_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PR_LOCATION_LOADING, false)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PR_LOCATION_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPrs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.pr.getPrs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: this.props.searchDate,
		}

		let callback = (res) => {
			this.props.loadPRsAction(res.data.orders, res.data.orders)
		}

		let errorHandler = (error) => {}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PR_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PR_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPrTypes() {
		let params = {
			method: "GET",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.getTypes}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}

		let callback = (res) => {
			this.props.loadPrTypesAction(res.data.uts)
		}

		let errorHandler = (error) => {}
		let reqInterceptor = (config) => {}

		let resInterceptor = (response) => {}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isAllApproved() {
		return (
			this.props.pr.selectedPR.pr &&
			this.props.pr.selectedPR.pr.requesterApproved &&
			this.props.pr.selectedPR.pr.inspectorApproved &&
			this.props.pr.selectedPR.pr.approverApproved
		)
	}

	updateOriginalOrders(res) {
		let orders = this.props.pr.prs
		res.data.orders.forEach((newOrder) => (orders = orders.filter((oldOrder) => oldOrder.id !== newOrder.id)))
		res.data.orders.forEach((order) => orders.push(order))

		let filteredOrders = orders.filter((order) => order.id.includes(this.props.pr.searchNum))
		orders.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		filteredOrders.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		this.props.loadPRsAction(orders, filteredOrders)

		let index = this.props.pr.selectedPrItem.index
		if (index > -1) {
			this.props.selectPrItemAction(index, this.props.pr.selectedPR.pr.items[index])
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0 full-size"}>
					{/* <div className="col-sm-4-half"> */}
					<Col style={{ maxWidth: "437px" }}>
						<PRLeft loadPrs={this.loadPrs} loadLocations={this.loadLocations} isAllApproved={this.isAllApproved} {...this.props} />
					</Col>
					{/* </div> */}
					{/* <div className="col-sm-7-half"> */}
					<Col>
						<PRRight updateOriginalOrders={this.updateOriginalOrders} isAllApproved={this.isAllApproved} {...this.props} />
					</Col>
					{/* </div> */}
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	searchDate: state.purchaseRequest.searchDate,
	pr: state.purchaseRequest,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			loadPRsAction,
			selectPRAction,
			selectPrItemAction,
			loadPrTypesAction,
			setPrLocations,
			setPrLocationOrgs,
			setLoadingAction,
			loadItemsAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseRequest)
