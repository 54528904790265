import { Card } from "antd"
import { Descriptions } from "antd/lib"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setORModalPostAneVitalSigns,
	setOperatingRoomRequests,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY,
	SET_OR_MODAL_POST_ANE_VS_SELECTED_VS,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ORModalPostAneVSTable extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		this.props.setORModalPostAneVitalSigns(this.props.operatingRoom.selectedPostAneRecord.record.vitalSigns)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.modalPostAneVS.deleteVS)) {
				this.deleteVS()
			}
		}

		if (!_.isEqual(prevProps.operatingRoom.selectedPostAneRecord.record, this.props.operatingRoom.selectedPostAneRecord.record)) {
			if (this.props.operatingRoom.selectedPostAneRecord.record) {
				this.props.setORModalPostAneVitalSigns(this.props.operatingRoom.selectedPostAneRecord.record.vitalSigns)
			}
		}
	}

	componentWillUnmount() {
		this.props.setORModalPostAneVitalSigns([])
		this.props.setSelected(SET_OR_MODAL_POST_ANE_VS_SELECTED_VS, -1, null)
	}

	deleteVS() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteVitalSigns,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				vitalSigns: [
					{
						id: this.props.operatingRoom.modalPostAneVS.selectedVS.vs.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onEdit, this.onDelete]}
						data={this.props.operatingRoom.modalPostAneVS.vitalSignTable}
						isClearHighlight={this.props.operatingRoom.modalPostAneVS.selectedVS.index === -1}
						highlightedRow={this.props.operatingRoom.modalPostAneVS.selectedVS.index}
						onClick={this.onSelect}
						extra={
							<Button
								style={{ fontSize: "medium" }}
								variant={ResourceAssistance.Button.variant.link}
								size={ResourceAssistance.Button.size.sm}
								onClick={this.onNew}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						}
					/>
				</Col>
			</Row>
		)
	}
	onSelect(service, index) {
		this.props.setSelected(SET_OR_MODAL_POST_ANE_VS_SELECTED_VS, index, this.props.operatingRoom.modalPostAneVS.vitalSignTable.filtered[index])
	}
	onNew() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY, true)
		this.props.setSelected(SET_OR_MODAL_POST_ANE_VS_SELECTED_VS, -1, null)
	}
	onEdit() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY, true)
	}
	onDelete(event, row, rIdx, cIdx) {
		let selectedVS = this.props.operatingRoom.modalPostAneVS.vitalSignTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.modalPostAneVS.deleteVS)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions
					style={{ flex: "unset", display: "unset" }}
					size={"small"}
					contentStyle={{
						fontStyle: "italic",
						fontWeight: "normal",
						textAlign: "left",
					}}
					column={3}
				>
					<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={3}>
						{Utils.formatDateTime(selectedVS.dateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.prPerMin)} span={1}>
						{selectedVS.pr}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.NIBP)} span={1}>
						{selectedVS.bp}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.ALine)} span={1}>
						{selectedVS.aline}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setORModalPostAneVitalSigns,
			setOperatingRoomRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneVSTable))
