import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_IPD_CREATE_PRN_RECORD_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class IPDModalPRNRecordAction extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
	}

	onNew() {
		this.props.onModalDisplayAction(SET_IPD_CREATE_PRN_RECORD_DISPLAY, true)
	}

	render() {
		return (
			<Row>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, this.props.login.user.roles) && (
					<Col md={"auto"}>
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							disabled={
								this.props.hospitel.selectedOrder.order.cancelledDateTime ||
								this.props.hospitel.selectedHospitel.hospitel.dischargeSummary ||
								this.props.hospitel.selectedOrder.order.endDateTime < Utils.generateDate().getTime()
							}
							onClick={this.onNew}
						>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.newRecord)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalPRNRecordAction)
