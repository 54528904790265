import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID,
	SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID,
	SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER,
	SET_PS_SUPPLIERS,
	SET_PS_SUPPLIER_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class PSModalSearchDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onPaymentSlipRequestId = this.onPaymentSlipRequestId.bind(this)
		this.onPaymentSlipId = this.onPaymentSlipId.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.PS.suppliers)) {
			this.loadSuppliers()
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID, "")
		this.props.setValue(SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID, "")
		this.props.setSelected(SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER, -1, null)
	}

	loadSuppliers() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.getSuppliers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PS_SUPPLIERS, res.data.suppliers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PS_SUPPLIER_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PS_SUPPLIER_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PS_SUPPLIER_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPaymentSlipRequestId(e) {
		this.props.setValue(SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID, Utils.trim(e.target.value))
	}

	onPaymentSlipId(e) {
		this.props.setValue(SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID, Utils.trim(e.target.value))
	}

	onSupplier(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER, -1, null)
			return
		}
		this.props.setSelected(SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER, value, this.props.PS.suppliers[value])
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.id })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							disabled={!_.isEmpty(this.props.PS.modalSearch.searchPaymentSlipId) || this.props.PS.modalSearch.searchSelectedSupplier.supplier !== null}
							onChange={this.onPaymentSlipRequestId}
							value={this.props.PS.modalSearch.searchPaymentSlipRequestId}
						/>
					</Col>
					<Col md={2} />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.psId })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							disabled={!_.isEmpty(this.props.PS.modalSearch.searchPaymentSlipRequestId) || this.props.PS.modalSearch.searchSelectedSupplier.supplier !== null}
							onChange={this.onPaymentSlipId}
							value={this.props.PS.modalSearch.searchPaymentSlipId}
						/>
					</Col>
					<Col md={2} />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.supplier)}
							loading={this.props.PS.isSupplierLoading}
							disabled={!_.isEmpty(this.props.PS.modalSearch.searchPaymentSlipRequestId) || !_.isEmpty(this.props.PS.modalSearch.searchPaymentSlipId)}
							onChange={this.onSupplier}
							value={this.props.PS.modalSearch.searchSelectedSupplier.supplier ? this.props.PS.modalSearch.searchSelectedSupplier.index : undefined}
						>
							{Utils.renderSelects(this.props.PS.suppliers, false)}
						</Select>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalSearchDetails))
