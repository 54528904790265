import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class HospitelModalPatientClinicalRecordPatient extends React.Component {
	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
				<Row className={"g-0 label-info-row"}>
					<Col md="auto">
						<label>
							{translate(ResourceAssistance.Message.name)}
							{ResourceAssistance.Symbol.colon}
						</label>
					</Col>
					<Col md="auto">
						<label className={"italic-text"}>
							{this.props.patient.title + " " + this.props.patient.firstName + " " + this.props.patient.lastName}
						</label>
					</Col>
					<Col />
					<Col md="auto">
						<label>
							{translate(ResourceAssistance.Hospitel.dateOfBirth)}
							{ResourceAssistance.Symbol.colon}
						</label>
					</Col>
					<Col md="auto">
						<label className={"italic-text"}>{Utils.formatDate(this.props.patient.dobtime)}</label>
					</Col>
					<Col />
					<Col md="auto">
						<label>
							{translate(ResourceAssistance.Hospitel.age)}
							{ResourceAssistance.Symbol.colon}
						</label>
					</Col>
					<Col md="auto">
						<label className={"italic-text"}>{Utils.calculateAge(this.props.patient.dobtime)}</label>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

HospitelModalPatientClinicalRecordPatient.propTypes = {
	patient: propTypes.object,
}

HospitelModalPatientClinicalRecordPatient.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitelModalPatientClinicalRecordPatient
