import { Button, Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_DEBT_MODAL_PAYMENT_DISPLAY } from "~/redux/type"
import { onModalDisplayAction } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import { ImPrinter } from "react-icons/im"
import DebtPrintDataFactory from "~/utils/factory/print/fiannce/DebtPrintDataFactory"

class DebtRightInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onEdit = this.onEdit.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}
	
	onEdit(e) {
		this.props.onModalDisplayAction(SET_DEBT_MODAL_PAYMENT_DISPLAY, true)
	}

	onPrint() {
		let data = DebtPrintDataFactory.generateReceiptData(
			this.props.debt.location,
			this.props.debt.selectedPlan.plan,
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs),
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.debt.receipt, "_blank")
		myWindow.data = data
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.payment)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={4}
							extra={
								<>
									{this.props.debt.selectedPlan.plan && !this.props.debt.selectedPlan.plan.complete && (
										<Col md="auto">
											<Button type="primary" size="middle" shape="round" icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />} onClick={this.onEdit} />
										</Col>
									)}
									{this.props.debt.selectedPlan.plan && this.props.debt.selectedPlan.plan.complete && (
										<Col>
											<Button
												style={{ background: ResourceAssistance.CSS.Color.info }}
												type="primary"
												size="middle"
												shape="round"
												icon={<ImPrinter size={ResourceAssistance.ReactIcon.size} />}
												onClick={this.onPrint}
											/>
										</Col>
									)}
								</>
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.insuranceCompany)} span={4}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.insuranceCompany : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.paymentType)} span={2}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.payment : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={2}>
								{this.props.debt.selectedPlan.plan ? Utils.formatDate(this.props.debt.selectedPlan.plan.paymentDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bankName)} span={2}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.bank : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bankBranch)} span={2}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.branch : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.lastFourDigitOfCreditCard)} span={2}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.creditCard : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.checkNum)} span={1}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.checkNum : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
								{this.props.debt.selectedPlan.plan ? Utils.formatDate(this.props.debt.selectedPlan.plan.checkDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
								{this.props.debt.selectedPlan.plan ? Utils.formatNumWithComma(Utils.BigNumber(this.props.debt.selectedPlan.plan.amount).toFixed(2)) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.transactionFee)} span={1}>
								{this.props.debt.selectedPlan.plan
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.debt.selectedPlan.plan.transactionFee).toFixed(2))
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.withholdingTax)} span={1}>
								{this.props.debt.selectedPlan.plan
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.debt.selectedPlan.plan.withholdingTax).toFixed(2))
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.balanceAdjustment)} span={1}>
								{this.props.debt.selectedPlan.plan
									? Utils.formatNumWithComma(Utils.BigNumber(this.props.debt.selectedPlan.plan.balanceAdjustment).toFixed(2))
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.transferBankName)} span={2}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.transferBank : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.transferBankBranch)} span={2}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.transferBranch : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={4}>
								{this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.remark : ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
	common: state.common
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtRightInfo)
