import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS,
	SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF,
	SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class ContractBillingModalInvoiceInfoDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDiagnosis = this.onDiagnosis.bind(this)
		this.onClaimNum = this.onClaimNum.bind(this)
		this.onInsuranceRef = this.onInsuranceRef.bind(this)
	}

	componentDidMount() {
		if (this.props.contract.selectedInvoice.invoice) {
			let invoice = this.props.contract.selectedInvoice.invoice
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS, invoice.patientDiagnosis)
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM, invoice.claimNum)
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF, invoice.insuranceRef)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF, "")
	}

	onDiagnosis(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS, e.target.value)
	}

	onClaimNum(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM, e.target.value)
	}

	onInsuranceRef(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF, e.target.value)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.chiefComplaint })}
							onChange={this.onDiagnosis}
							value={this.props.contract.modalInvoiceInfo.diagnosis}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.fundOrClaimNum })}
							onChange={this.onClaimNum}
							value={this.props.contract.modalInvoiceInfo.claimNum}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.insuranceReference })}
							onChange={this.onInsuranceRef}
							value={this.props.contract.modalInvoiceInfo.insuranceRef}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalInvoiceInfoDetails))
