import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES, SET_IPD_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES_LOADING } from "~/redux/type"
import HospitelModalNurseRecordAction from "./HospitelModalNurseRecordAction"
import HospitelModalNurseRecordDate from "./HospitelModalNurseRecordDate"
import HospitelModalNurseRecordFocus from "./HospitelModalNurseRecordFocus"
import IPDModalNurseRecordAssessment from "./IPDModalNurseRecordAssessment"
import IPDModalNurseRecordEvaluation from "./IPDModalNurseRecordEvaluation"
import IPDModalNurseRecordIntervention from "./IPDModalNurseRecordIntervention"

class HospitelModalNurseRecordDetails extends React.Component {
	componentDidMount() {
		this.loadCustomDefaultCategories()
	}

	loadCustomDefaultCategories() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getCustomDefaultCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_IPD_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_IPD_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_IPD_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-flex space-top-each-row-parent"}>
				{!this.props.ipd.selectedNurseNote.nurseNote && <HospitelModalNurseRecordAction />}
				<HospitelModalNurseRecordDate />
				<Row className="full-size">
					<Col md={4}>
						<HospitelModalNurseRecordFocus />
					</Col>
					<Col>
						<fieldset className={ResourceAssistance.CSS.fullFlex}>
							<legend>{translate(ResourceAssistance.Hospitel.aie)}</legend>
							<IPDModalNurseRecordAssessment />
							<IPDModalNurseRecordIntervention />
							<IPDModalNurseRecordEvaluation />
						</fieldset>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseRecordDetails)
