const Privilege = {
	Privilege: {
		Finance: {
			View: "Finance",
			AccountsReceivable: {
				View: "Accounts_Receivable",
				Inspector: "Accounts_Receivable_Inspector",
				Approver: "Accounts_Receivable_Approver",
			},
			AccountsReceive: {
				View: "Accounts_Receive",
				Inspector: "Accounts_Receive_Inspector",
				Approver: "Accounts_Receive_Approver",
			},
			ContractBilling: {
				View: "Contract_Billing",
			},
			Debt: {
				View: "Debt",
			},
			PurchaseInvoice: {
				View: "Purchase_Invoice",
				Inspector: "Purchase_Invoice_Inspector",
				Approver: "Purchase_Invoice_Approver",
			},
			AccountsPayable: {
				View: "Accounts_Payable",
				Inspector: "Accounts_Payable_Inspector",
				Approver: "Accounts_Payable_Approver",
			},
			PostingGL: {
				View: "Posting_GL",
				Inspector: "Posting_GL_Inspector",
				Approver: "Posting_GL_Approver",
			},
			PaymentSlip: {
				View: "Payment_Slip",
				Creator: "Payment_Slip_Creator",
				Inspector: "Payment_Slip_Inspector",
				Approver: "Payment_Slip_Approver",
				FinancialOperationInspector: "Finance_Payment_Slip_Financial_Operation_Inspector",
				FinancialOperationApprover: "Finance_Payment_Slip_Financial_Operation_Approver",
			},
			PaymentVoucher: {
				View: "Payment_Voucher",
				Approver: "Payment_Voucher_Approver",
			},
			FinancialOperations: {
				View: "Financial_Operations",
				Inspector: "Financial_Operations_Inspector",
				Approver: "Financial_Operations_Approver",
			},
			Report: {
				View: "Finance_Report",
			},
		},
		Management: {
			View: "Management",
			Inventory: {
				View: "Inventory",
			},
			Transfer: {
				View: "Transfer",
				Request: "Transfer_Request",
				Transfer: "Transfer_Transfer",
				Complete: "Transfer_Complete_Request",
			},
			Pr: {
				View: "Purchase_Request",
				Request: "Purchase_Request_Request",
				Inspector: "Purchase_Request_Inspector",
				Approval: "Purchase_Request_Approval",
			},
			Po: {
				View: "Purchase_Order",
				Reject: "Purchase_Order_Reject",
				Inspector: "Purchase_Order_Inspector",
				Approval: "Purchase_Order_Approval",
			},
			Rc: {
				View: "Receive",
				Receive: "Receive_Receive",
				Complete: "Receive_Complete_Order",
			},
		},
		Report: {
			View: "Report",
		},
		Admin: {
			View: "Admin",
			Item: {
				Config_Unit: "Admin_Config_Item_Unit",
			},
		},
		Hospital: {
			View: "Hospital",
			Lab: {
				View: "Laboratory",
				CreateLab: "Laboratory_Create_Lab",
				Nurse: "Laboratory_Nurse",
				Technician: "Laboratory_Technician",
			},
			Deposit: {
				View: "Deposit",
			},
			Cashier: {
				View: "Cashier",
				HealthCarePlanCreateEdit: "Cashier_Health_Care_Plan_Create_Edit",
				BillingPlanCreate: "Cashier_Billing_Plan_Create",
				Discharge: "Cashier_Discharge",
			},
			Hospitel: {
				View: "Hospitel",
				Doctor: "Hospitel_CRUD_Doctor_Order",
				Nurse: "Hospitel_CRUD_Nurse_Order",
				Register: "Hospitel_Assign_Patient",
				Discharge: "Hospitel_Discharge_Patient",
			},
			Registration: {
				View: "Registration",
				CRUD: "Registration_CRUD",
				EXPENSE_EST: "Registration_Expense_Estimation",
			},
			Report: {
				View: "Hospital_Report",
				Cashier: "Cashier_Report",
				Hospitel: "Hospitel_Report",
				Lab: "Laboratory_Report",
				Finance: "Finance_Report",
			},
			OPD: {
				View: "OPD",
				Doctor: "OPD_CRUD_Doctor_Order",
				Nurse: "OPD_CRUD_Nurse_Order",
			},
			Pharmacy: {
				View: "Pharmacy",
			},
			Front_Inventory: {
				View: "Front_Inventory",
			},
			Operating_Room: {
				View: "HIS_Operating_Room",
			},
		},
		addAdmin: "add_admin",
		addUser: "add_user",
		createPr: "create_pr",
		approvePrInspector: "approve_pr_inspector",
		approvePrApprover: "approve_pr_approver",
		Witness: {
			WASTE: "Witness_Waste",
		},
	},
}

export default Privilege
