import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelmodalDoctorServicePendingServices, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalDoctorServicePendingService extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectService = this.onSelectService.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.hospitel.modalDoctorService.pendingServiceTable.original,
				this.props.hospitel.modalDoctorService.pendingServiceTable.original
			)
		) {
			this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setHospitelmodalDoctorServicePendingServices([])
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE, -1, null)
	}

	onSelectService(item, index) {
		this.props.setSelected(
			SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE,
			index,
			this.props.hospitel.modalDoctorService.pendingServiceTable.filtered[index]
		)
	}

	render() {
		return (
			<Fragment>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectService}
							data={this.props.hospitel.modalDoctorService.pendingServiceTable}
							isClearHighlight={this.props.hospitel.modalDoctorService.selectedPendingService.index === -1}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setHospitelmodalDoctorServicePendingServices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorServicePendingService)
