import { ResourceAssistance } from "~/i18n"
import {
	SET_CONTRACT_BILLING_BILLINGS,
	SET_CONTRACT_BILLING_INVOICES,
	SET_CONTRACT_BILLING_MODAL_NEW_INVOICES,
	SET_CONTRACT_BILLING_MODAL_NEW_PENDING_INVOICES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setContractBillingBillings = (billings) => {
	return (dispatch, getState) => {
		let filtered = billings
			.filter(
				(billing) =>
					billing.id.includes(getState().accounting.contract.filterContractBillingId) &&
					billing.insuranceCompany.trim().toLowerCase().includes(getState().accounting.contract.filterHealthCarePlan.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))
		let rowColor = filtered.map((billing) => {
			let color = ""
			if (billing.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})
		let body = filtered.map((billing) => {
			return [Utils.formatDate(billing.creationDateTime), Utils.formatTime(billing.creationDateTime), billing.id, billing.insuranceCompany]
		})

		dispatch({
			type: SET_CONTRACT_BILLING_BILLINGS,
			payload: {
				original: billings,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setContractBillingInvoices = (contractBillingInvoices) => {
	return (dispatch, getState) => {
		let filtered = contractBillingInvoices.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let body = filtered.map((contractBillingInvoice) => {
			return [
				Utils.formatDate(contractBillingInvoice.debt.creationDateTime),
				contractBillingInvoice.id,
				contractBillingInvoice.debt.billing.billingPlan.admission.patient.id,
				contractBillingInvoice.debt.billing.billingPlan.admission.patient.displayName,
				Utils.formatNumWithComma(Utils.BigNumber(contractBillingInvoice.debt.total).minus(contractBillingInvoice.debt.billing.total).toFixed(2)),
				contractBillingInvoice.patientDiagnosis,
				contractBillingInvoice.claimNum,
				contractBillingInvoice.insuranceRef,
				contractBillingInvoice.debt.debtPlan && contractBillingInvoice.debt.debtPlan.complete
					? Utils.formatDate(contractBillingInvoice.debt.debtPlan.lastModifiedDateTime)
					: "",
				contractBillingInvoice.debt.debtPlan && contractBillingInvoice.debt.debtPlan.complete ? contractBillingInvoice.debt.debtPlan.id : "",
				[true, true],
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_CONTRACT_BILLING_INVOICES,
			payload: {
				original: contractBillingInvoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setContractBillingModalNewInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
			.filter(
				(invoice) =>
					invoice.id.includes(getState().accounting.contract.modalNew.filterInvoiceId) &&
					invoice.billing.billingPlan.admission.patient.displayName
						.trim()
						.toLowerCase()
						.includes(getState().accounting.contract.modalNew.filterPatientName.trim().toLowerCase()) &&
					!getState().accounting.contract.modalNew.pendingInvoiceTable.original.some((pendingInvoice) => pendingInvoice.debt.id === invoice.id)
			)
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let body = filtered.map((invoice) => {
			return [
				Utils.formatDate(invoice.creationDateTime),
				invoice.id,
				invoice.billing.billingPlan.admission.patient.displayName,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.total).minus(invoice.billing.total).toFixed(2)),
				invoice.billing.billingPlan.name,
				invoice.billing.billingPlan.insuranceCompany,
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_CONTRACT_BILLING_MODAL_NEW_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setContractBillingModalNewPendingInvoices = (contractBillingInvoices) => {
	return (dispatch, getState) => {
		let filtered = contractBillingInvoices

		let body = filtered.map((contractBillingInvoice, index) => {
			return [
				Utils.formatDate(contractBillingInvoice.debt.creationDateTime),
				contractBillingInvoice.debt.id,
				contractBillingInvoice.debt.billing.billingPlan.admission.patient.displayName,
				Utils.formatNumWithComma(Utils.BigNumber(contractBillingInvoice.debt.total).minus(contractBillingInvoice.debt.billing.total).toFixed(2)),
				contractBillingInvoice.debt.billing.billingPlan.name,
				contractBillingInvoice.debt.billing.billingPlan.insuranceCompany,
				contractBillingInvoice.patientDiagnosis,
				contractBillingInvoice.claimNum,
				contractBillingInvoice.insuranceRef,
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_CONTRACT_BILLING_MODAL_NEW_PENDING_INVOICES,
			payload: {
				original: contractBillingInvoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
