import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PV_PAYMENT_TYPES, SET_PV_PAYMENT_TYPES_LOADING, SET_PV_SUPPLIERS, SET_PV_SUPPLIERS_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PVModalNewFields from "./PVModalNewFields"
import PVModalNewWithholdingTax from "./PVModalNewWithholdingTax"
import PVModalNewPurchase from "./PVModalNewPurchase"

class PVModalNewDetails extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.PV.modalNew.suppliers)) {
			this.loadSuppliers()
		}
		if (_.isEmpty(this.props.PV.modalNew.paymentTypes)) {
			this.loadPaymentTypes()
		}
	}

	loadSuppliers() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentVoucher.getSuppliers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PV_SUPPLIERS, res.data.suppliers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PV_SUPPLIERS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PV_SUPPLIERS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PV_SUPPLIERS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPaymentTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentVoucher.getPayments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			let types = res.data.uts.filter((type) => !type.savingAccount)
			this.props.setValue(SET_PV_PAYMENT_TYPES, types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PV_PAYMENT_TYPES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PV_PAYMENT_TYPES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PV_PAYMENT_TYPES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<PVModalNewFields />
					</Col>
					{this.props.PV.modalNew.purchaseTable.original.some((purchase) => Utils.BigNumber(purchase.withholdingTax).isGreaterThan(0)) && (
						<Col md={2}>
							<PVModalNewWithholdingTax />
						</Col>
					)}
				</Row>
				<PVModalNewPurchase />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PVModalNewDetails)
