import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ImPrinter } from "react-icons/im"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setDepositTransactions } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import DepositPrintableDataFactory from "./../../../utils/factory/print/HIS/DepositPrintableDataFactory"
import { Utils } from "~/utils/Utils"

class DepositTransaction extends React.Component {
	constructor(props) {
		super(props)

		this.onPrint = this.onPrint.bind(this)
	}
	componentDidMount() {
		this.props.setDepositTransactions(this.props.deposit.patient.transactions)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.deposit.patient, this.props.deposit.patient) || !_.isEqual(prevProps.deposit.filters, this.props.deposit.filters)) {
			if (this.props.deposit.patient) {
				this.props.setDepositTransactions(this.props.deposit.patient.transactions)
			} else {
				this.props.setDepositTransactions([])
			}
		}
	}

	componentWillUnmount() {
		this.props.setDepositTransactions([])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineInfo]}
						btnIcons={[<ImPrinter size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onPrint]}
						data={this.props.deposit.transactionTable}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}

	onPrint(event, row, rIdx, cIdx) {
		let transaction = this.props.deposit.transactionTable.filtered[rIdx]
		let data = DepositPrintableDataFactory.generateDepositSlipData(
			this.props.deposit.modalLocation.selectedOrg.org,
			this.props.deposit.modalLocation.selectedBranch.branch,
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs),
			this.props.deposit.patient,
			[
				{
					wordsInEng: Utils.convertNumberToUSLetters(transaction.amount),
					wordsInThai: Utils.convertNumberToThaiLetters(transaction.amount),
					payment: transaction.payment,
					amount: Utils.formatNumWithComma(Utils.BigNumber(transaction.amount).toFixed(2)),
				},
			],
			this.props.login.user
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.deposit.depositSlip, "_blank")
		myWindow.data = data
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	common: state.common,
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setDepositTransactions,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositTransaction)
