import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, resetAction, setValue } from "~/redux/action"
import { SET_ARC_LOCATION, SET_ARC_MODAL_LOCATION_DISPLAY, SET_HEADER } from "~/redux/type"
import ARCModalLocationDetails from "./ARCModalLocationDetails"

class ARCModalLocation extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ARC_MODAL_LOCATION_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return !this.props.ARC.modalLocation.selectedOrg.org
	}

	onClose() {
		this.reset()
		this.props.resetAction()
		this.props.history.push(ResourceAssistance.Path.home)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		this.props.setValue(SET_HEADER, this.props.common.header.concat("/", this.props.ARC.modalLocation.selectedOrg.org.displayName))
		this.props.setValue(SET_ARC_LOCATION, this.props.ARC.modalLocation.selectedOrg.org)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.location)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ARCModalLocationDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAccountsReceiveLocationDisplay,
	common: state.common,
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			onModalDisplayAction,
			resetAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ARCModalLocation)
