import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected } from "~/redux/action"
import { SET_PV_SELECTED_PV } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentVoucherLeftInvoice extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectPV = this.onSelectPV.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PV.paymentVoucherTable.filtered, this.props.PV.paymentVoucherTable.filtered)) {
			let index = this.props.PV.selectedPV.PV ? this.props.PV.paymentVoucherTable.filtered.findIndex((PV) => PV.id === this.props.PV.selectedPV.PV.id) : -1
			if (index !== -1) {
				this.props.setSelected(SET_PV_SELECTED_PV, index, this.props.PV.paymentVoucherTable.filtered[index])
			} else {
				this.props.setSelected(SET_PV_SELECTED_PV, -1, null)
			}
		}
		// if (
		// 	!_.isEqual(prevProps.FO.filterFinancialPlanId, this.props.FO.filterFinancialPlanId) ||
		// 	!_.isEqual(prevProps.FO.filters, this.props.FO.filters)
		// ) {
		// 	this.props.setFORequests(this.props.FO.financialPlanTable.original)
		// }
	}

	onSelectPV(plan, index) {
		this.props.setSelected(SET_PV_SELECTED_PV, index, this.props.PV.paymentVoucherTable.filtered[index])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.PV.paymentVoucherTable}
						onClick={this.onSelectPV}
						isClearHighlight={this.props.PV.selectedPV.index === -1}
						highlightedRow={this.props.PV.selectedPV.index}
					/>
					{this.props.PV.paymentVoucherTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentVoucherLeftInvoice))
