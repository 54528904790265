import {
	SET_REPORT_CASHIER_ADMISSION_ID,
	SET_REPORT_CASHIER_USER_END_DATE_TIME,
	SET_REPORT_CASHIER_USER_START_DATE_TIME,
	SET_REPORT_CASHIER_USER_ROLES,
	SET_REPORT_CASHIER_USER_USERS,
	SET_REPORT_CASHIER_USER_SELECTED_ROLE,
	SET_REPORT_CASHIER_USER_SELECTED_USER,
	SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID,
} from "~/redux/type"
import { SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED } from "../../type/type/report/CASHIER"

let DATE = new Date()

const init = {
	admission: {
		id: "",
	},
	user: {
		startDateTime: new Date(DATE.getFullYear(), DATE.getMonth(), 1).getTime(),
		endDateTime: new Date(DATE.getFullYear(), DATE.getMonth() + 1, 0, 23, 59, 59).getTime(),
		dischargedChecked: false,
		roles: [],
		users: [],
		selectedRole: {
			index: -1,
			role: null,
		},
		selectedUser: {
			index: -1,
			user: null,
		},
	},
	billingToToday: {
		admissionId: "",
	},
}

const reportCashierReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_CASHIER_ADMISSION_ID:
			return Object.assign({}, state, {
				admission: {
					...state.admission,
					id: action.payload.value,
				},
			})

		case SET_REPORT_CASHIER_USER_START_DATE_TIME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					startDateTime: action.payload.value,
				},
			})

		case SET_REPORT_CASHIER_USER_END_DATE_TIME:
			return Object.assign({}, state, {
				user: {
					...state.user,
					endDateTime: action.payload.value,
				},
			})

		case SET_REPORT_CASHIER_USER_ROLES:
			return Object.assign({}, state, {
				user: {
					...state.user,
					roles: action.payload.objs,
				},
			})

		case SET_REPORT_CASHIER_USER_USERS:
			return Object.assign({}, state, {
				user: {
					...state.user,
					users: action.payload.objs,
				},
			})

		case SET_REPORT_CASHIER_USER_SELECTED_ROLE:
			return Object.assign({}, state, {
				user: {
					...state.user,
					selectedRole: {
						index: action.payload.index,
						role: action.payload.selected,
					},
				},
			})

		case SET_REPORT_CASHIER_USER_SELECTED_USER:
			return Object.assign({}, state, {
				user: {
					...state.user,
					selectedUser: {
						index: action.payload.index,
						user: action.payload.selected,
					},
				},
			})

		case SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED:
			return Object.assign({}, state, {
				user: {
					...state.user,
					dischargedChecked: action.payload.value,
				},
			})

		case SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID:
			return Object.assign({}, state, {
				billingToToday: {
					...state.billingToToday,
					admissionId: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default reportCashierReducer
