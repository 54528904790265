import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import ORModalPostAneMedicationDuration from "./ORModalPostAneMedicationDuration"
import ORModalPostAneMedicationItem from "./ORModalPostAneMedicationItem"
import ORModalPostAneMedicationPending from "./ORModalPostAneMedicationPending"

class ORModalPostAneMedicationDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className="full-size" style={{ marginTop: 0, alignItems: "flex-start" }}>
					<Col style={{ marginTop: 0 }}>
						<ORModalPostAneMedicationItem />
					</Col>
					{this.props.operatingRoom.modalPostAneMedication.selectedItem.item && (
						<Col md={4} style={{ marginTop: 0 }}>
							<ORModalPostAneMedicationDuration />
						</Col>
					)}
				</Row>
				<Row>
					<Col>
						<ORModalPostAneMedicationPending />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneMedicationDetails)
