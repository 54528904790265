import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportNurseProgressNoteDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "Nurse Progress Note",
				nurseNotes: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Date: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.date}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Day: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.day}</Text>
										</View>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>ID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.id}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Name: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Age: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>DOB: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Note Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.focus]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Focus</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.progressNote]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Progress Note</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{this.state.data.nurseNotes.map((nurseNote) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]} wrap={false}>
										<View style={[ResourceAssistance.Report.styles.col, styles.time]}>
											<Text>{nurseNote.time}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.focus]}>
											<Text>{nurseNote.focus}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.progressNote]}>
											{!_.isEmpty(nurseNote.assessment) && (
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View
														style={[
															ResourceAssistance.Report.styles.colAuto,
															{ justifyContent: "flex-start", marginRight: 5 },
														]}
													>
														<Text>{"A: "}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}>
														<Text>{nurseNote.assessment}</Text>
													</View>
												</View>
											)}
											{!_.isEmpty(nurseNote.intervention) && (
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View
														style={[
															ResourceAssistance.Report.styles.colAuto,
															{ justifyContent: "flex-start", marginRight: 5 },
														]}
													>
														<Text>{"I: "}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}>
														<Text>{nurseNote.intervention}</Text>
													</View>
												</View>
											)}
											{!_.isEmpty(nurseNote.evaluation) && (
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View
														style={[
															ResourceAssistance.Report.styles.colAuto,
															{ justifyContent: "flex-start", marginRight: 5 },
														]}
													>
														<Text>{"E: "}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}>
														<Text>{nurseNote.evaluation}</Text>
													</View>
												</View>
											)}

											<View style={[ResourceAssistance.Report.styles.row]}>
												<View style={ResourceAssistance.Report.styles.col} />
												<View style={ResourceAssistance.Report.styles.col} />
												<View style={[ResourceAssistance.Report.styles.col]}>
													<Text>{"\n\n"}</Text>
													<View style={styles.line}></View>
													<Text style={{ textAlign: "center" }}>{nurseNote.signatureDate}</Text>
												</View>
											</View>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.horizontalLine} />
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		marginBottom: 10,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	time: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	focus: {
		minWidth: "30%",
		maxWidth: "30%",
	},
	progressNote: {
		minWidth: "58%",
		maxWidth: "58%",
	},
})

export default ReportNurseProgressNoteDocument
