import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setRegModalExpenseEstPendings, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
	SET_REGISTRATION_ADJUSTMENT_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import moment from "moment"

class RegModalExpenseEstAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	onDownBtn() {
		if (this.props.state.tab === this.props.EOrder.SERVICE) {
			window.adjustmentServiceCaller = this
			this.props.setValue(
				SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
				this.props.reg.modalExpenseEst.selectedOrder.order
			)
			this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY, true)
		} else {
			this.props.onModalDisplayAction(SET_REGISTRATION_ADJUSTMENT_DISPLAY, true)
		}
	}

	adjustmentServiceCallback() {
		let selected = Object.assign({}, this.props.reg.modalExpenseEst.selectedOrder.order, {
			id: undefined,
			creationDateTime: moment().valueOf(),
			code: "",
			serviceCode: this.props.reg.modalExpenseEst.selectedOrder.order.code,
			qtyPerDay: 1,
			startDateTime: this.props.ipd.modalAdjustmentService.startDateTime,
			endDateTime: this.props.ipd.modalAdjustmentService.isDisableEndDate
				? Utils.generateDateFromLong(this.props.ipd.modalAdjustmentService.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
				: this.props.ipd.modalAdjustmentService.endDateTime,
			notes: this.props.ipd.modalAdjustmentService.notes,
		})
		let pendings = this.props.reg.modalExpenseEst.pendingTable.original
		pendings.push(selected)
		this.props.setRegModalExpenseEstPendings(pendings)
	}

	onUpBtn() {
		let pendings = this.props.reg.modalExpenseEst.pendingTable.original.filter(
			(each) =>
				each.id !== undefined ||
				each.code !== this.props.reg.modalExpenseEst.selectedPending.pending.code ||
				each.name !== this.props.reg.modalExpenseEst.selectedPending.pending.name ||
				each.description !== this.props.reg.modalExpenseEst.selectedPending.pending.description ||
				each.fsCode !== this.props.reg.modalExpenseEst.selectedPending.pending.fsCode
		)
		this.props.setRegModalExpenseEstPendings(pendings)
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.reg.modalExpenseEst.selectedOrder.index === -1}
						onClick={this.onDownBtn}
					>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={
							this.props.reg.modalExpenseEst.selectedPending.index === -1 ||
							this.props.reg.modalExpenseEst.selectedPending.pending.id
						}
						onClick={this.onUpBtn}
					>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			onModalDisplayAction,
			setRegModalExpenseEstPendings,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstAction)
