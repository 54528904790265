import { TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_CASHIER_MODAL_LOCATION_ORGS,
	SET_CASHIER_MODAL_LOCATION_ORGS_LOADING,
	SET_CASHIER_MODAL_LOCATION_SELECTED_BRANCH,
	SET_CASHIER_MODAL_LOCATION_SELECTED_ORG,
} from "~/redux/type"
import { TreeSelectUtils } from "~/utils/component/TreeSelectUtils"

class CashierModalLocationDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onLocation = this.onLocation.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.cashier.modalLocation.orgs)) {
			this.loadLocations()
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_CASHIER_MODAL_LOCATION_ORGS, [])
		this.props.setSelected(SET_CASHIER_MODAL_LOCATION_SELECTED_ORG, -1, null)
		this.props.setSelected(SET_CASHIER_MODAL_LOCATION_SELECTED_BRANCH, -1, null)
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.cashier.getOrgs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_CASHIER_MODAL_LOCATION_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_CASHIER_MODAL_LOCATION_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_CASHIER_MODAL_LOCATION_ORGS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_CASHIER_MODAL_LOCATION_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							treeLine={{ showLeafIcon: false }}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.branch)}
							loading={this.props.cashier.modalLocation.isOrgsLoading}
							treeData={TreeSelectUtils.displayOrgBranch(this.props.cashier.modalLocation.orgs)}
							onChange={this.onLocation}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onLocation(v) {
		if (v === undefined) {
			this.props.setSelected(SET_CASHIER_MODAL_LOCATION_SELECTED_ORG, -1, null)
			this.props.setSelected(SET_CASHIER_MODAL_LOCATION_SELECTED_BRANCH, -1, null)
			return
		}
		let keys = v.split("-")
		this.props.setSelected(SET_CASHIER_MODAL_LOCATION_SELECTED_ORG, keys[0], this.props.cashier.modalLocation.orgs[keys[0]])
		this.props.setSelected(SET_CASHIER_MODAL_LOCATION_SELECTED_BRANCH, keys[1], this.props.cashier.modalLocation.orgs[keys[0]].locations[keys[1]])
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalLocationDetails)
