import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPIModalNewPurchases, setPurchaseInvoices } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PI_PURCHASE_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PIModalPurchaseDetails from "./PIModalPurchaseDetails"

class PIModalPurchase extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PI_PURCHASE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.PI.modalPurchase.selectedGL.GL ||
			!this.props.PI.modalPurchase.selectedTax.tax ||
			!this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax ||
			(Utils.BigNumber(this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.percent).isGreaterThan(0) &&
				!this.props.PI.modalPurchase.selectedWithholdingTaxSubLevel.subLevel)
		) {
			return true
		}
		let target = Object.assign({}, this.props.PI.selectedPurchase.purchase, {
			accountingCode: this.props.PI.modalPurchase.selectedGL.GL.fullCode,
			accountingChartName: this.props.PI.modalPurchase.selectedGL.GL.displayName,
			accountingChartDescription: this.props.PI.modalPurchase.selectedGL.GL.description,
			accountingTransDescr: Utils.trim(this.props.PI.modalPurchase.description),
			taxedTotalPrice: Number(Utils.BigNumber(this.props.PI.modalPurchase.amount).toFixed(2)),
			tax: this.props.PI.modalPurchase.selectedTax.tax.taxRate,
			taxName: this.props.PI.modalPurchase.selectedTax.tax.displayName,
			withholdingTaxCode: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.code,
			withholdingTaxName: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.displayName,
			withholdingTax: this.props.PI.modalPurchase.percent,
			withholdingTaxSubLevel: this.props.PI.modalPurchase.selectedWithholdingTaxSubLevel.subLevel
				? this.props.PI.modalPurchase.selectedWithholdingTaxSubLevel.subLevel.displayName
				: "",
			withholdingTaxSubLevelOther: Utils.trim(this.props.PI.modalPurchase.whtSubLevelOther),
			withholdingTaxAccountingCode: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.chartOfAccounts.fullCode,
			withholdingTaxAccountingChartName: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.chartOfAccounts.displayName,
			withholdingTaxAccountingChartDescription: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.chartOfAccounts.description,
			withholdingTaxAccountingTransDescr: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.description,
		})
		return _.isEqual(this.props.PI.selectedPurchase.purchase, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let purchase = {
			id: this.props.PI.selectedPurchase.purchase ? this.props.PI.selectedPurchase.purchase.id : 0,
			accountingCode: this.props.PI.modalPurchase.selectedGL.GL.fullCode,
			accountingChartName: this.props.PI.modalPurchase.selectedGL.GL.displayName,
			accountingChartDescription: this.props.PI.modalPurchase.selectedGL.GL.description,
			accountingTransDescr: Utils.trim(this.props.PI.modalPurchase.description),
			taxedTotalPrice: Utils.BigNumber(this.props.PI.modalPurchase.amount).toFixed(2),
			tax: this.props.PI.modalPurchase.selectedTax.tax.taxRate,
			taxName: this.props.PI.modalPurchase.selectedTax.tax.displayName,
			withholdingTaxCode: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.code,
			withholdingTaxType: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.withholdingTaxType,
			withholdingTaxName: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.displayName,
			withholdingTax: this.props.PI.modalPurchase.percent,
			withholdingTaxSubLevel: this.props.PI.modalPurchase.selectedWithholdingTaxSubLevel.subLevel
				? this.props.PI.modalPurchase.selectedWithholdingTaxSubLevel.subLevel.displayName
				: "",
			withholdingTaxSubLevelOther: Utils.trim(this.props.PI.modalPurchase.whtSubLevelOther),
			withholdingTaxAccountingCode: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.chartOfAccounts.fullCode,
			withholdingTaxAccountingChartName: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.chartOfAccounts.displayName,
			withholdingTaxAccountingChartDescription: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.chartOfAccounts.description,
			withholdingTaxAccountingTransDescr: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.skipPercent
				? ""
				: this.props.PI.modalPurchase.selectedWithholdingTax.withholdingTax.activities[this.props.PI.modalPurchase.selectedWithholdingTax.index.split("-")[1]]
						.description,
		}
		if (this.props.modalDisplay.isPurchaseInvoiceNewDisplay) {
			let purchases = Array.from(this.props.PI.modalNew.purchaseTable.original)
			purchases.push(purchase)
			this.props.setPIModalNewPurchases(purchases)
		} else {
			let params = {
				method: "POST",
				url: ResourceAssistance.Url.purchaseInvoice.updatePurchases,
				withCredentials: true,
				headers: {
					"content-type": "application/json",
				},
				data: {
					orgId: this.props.PI.location.id,
					purchaseInvoiceId: this.props.PI.selectedPI.PI.id,
					userId: this.props.login.user.id,
					invoices: [
						{
							id: this.props.PI.selectedPI.PI.id,
							purchases: [purchase],
						},
					],
				},
			}
			let callback = (res) => {
				if (res.data.invoices.length > 0) {
					let invoices = this.props.PI.purchaseInvoiceTable.original.filter((PI) => PI.id !== res.data.invoices[0].id)
					invoices.push(res.data.invoices[0])
					this.props.setPurchaseInvoices(invoices)
				}
			}
			let errorHandler = (error) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			let reqInterceptor = (config) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			}
			let resInterceptor = (response) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		}
		this.reset()
	}

	render() {
		return (
			<Modal
				size={ResourceAssistance.Modal.size.md}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.purchase)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PIModalPurchaseDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	modalDisplay: state.modal,
	isDisplay: state.modal.isPurchaseInvoicePurchaseDisplay,
	login: state.login,
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setPIModalNewPurchases,
			setLoadingAction,
			setPurchaseInvoices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PIModalPurchase))
