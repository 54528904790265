import _ from "lodash"
import { ResourceAssistance, translate } from "~/i18n"

class TreeSelectUtils {
	static displayWithholdingTax = (categories, locationAP = "") => {
		return categories.map((category, categoryIdx) => {
			return {
				title: category.displayCodeName,
				key: categoryIdx,
				value: categoryIdx,
				selectable: category.skipPercent,
				extra: {
					isParent: true,
					idx: categoryIdx,
				},
				children: category.activities
					.filter((each) => each.chartOfAccounts.fullCode.startsWith(locationAP) && !each.linkGL)
					.map((activity, activityIdx) => {
						return {
							title: activity.chartOfAccounts.displayFullCodeName,
							key: categoryIdx + "-" + activityIdx,
							value: categoryIdx + "-" + activityIdx,
							extra: {
								idx: activityIdx,
							},
						}
					}),
			}
		})
	}

	static displayICD = (icdTypes) => {
		return icdTypes.map((type, typeIdx) => {
			return {
				title: type.displayName,
				key: typeIdx,
				value: typeIdx,
				selectable: false,
				extra: {
					isParent: true,
					idx: typeIdx,
				},
				children: type.icds.map((icd, icdIdx) => {
					return {
						title: icd.code + "-" + icd.description,
						key: typeIdx + "-" + icdIdx,
						value: typeIdx + "-" + icdIdx,
						extra: {
							idx: icdIdx,
						},
					}
				}),
			}
		})
	}

	static displayOrgBranch = (orgs, displayEmptyChildren = true) => {
		return orgs
			.filter((org) => displayEmptyChildren || !_.isEmpty(org.locations))
			.map((org, orgIdx) => {
				return {
					title: org.displayName,
					value: orgIdx,
					selectable: false,
					children: org.locations.map((branch, branchIdx) => {
						return {
							title: branch.displayName,
							value: orgIdx + "-" + branchIdx,
							selectable: true,
							extra: {
								org: {
									index: orgIdx,
									org: org,
								},
								branch: {
									index: branchIdx,
									branch: branch,
								},
							},
						}
					}),
				}
			})
	}

	static displayInventoryAndService = (inventories, services) => {
		return [
			{
				title: translate(ResourceAssistance.Message.inventory),
				value: 0,
				selectable: false,
				children: inventories.map((inventory, inventoryIdx) => {
					return {
						title: inventory.displayName,
						value: 0 + "-" + inventoryIdx,
						extra: {
							isInventory: true,
						},
					}
				}),
			},
			{
				title: translate(ResourceAssistance.Message.service),
				value: 1,
				selectable: false,
				children: services.map((service, serviceIdx) => {
					return {
						title: service.displayName,
						value: 1 + "-" + serviceIdx,
						extra: {
							isService: true,
						},
					}
				}),
			},
		]
	}
}

export { TreeSelectUtils }
