import React, { Fragment } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import RegModalExpenseEstFilter from "./RegModalExpenseEstFilter"
import RegModalExpenseEstMedicalSupply from "./RegModalExpenseEstMedicalSupply"
import RegModalExpenseEstService from "./RegModalExpenseEstService"

class RegModalExpenseEstOrderTab extends React.Component {
	EOrder = {
		MEDICAL_SUPPLY: "REGISTRATION_EXPENSE_ESTIMATION_MEDICAL_SUPPLY",
		SERVICE: "REGISTRATION_EXPENSE_ESTIMATION_SERVICE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EOrder.MEDICAL_SUPPLY,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Tabs
				variant="tabs"
				transition={false}
				activeKey={this.state.tab}
				onSelect={this.onTabClick}
				mountOnEnter={false}
				justify
			>
				<Tab
					eventKey={this.EOrder.MEDICAL_SUPPLY}
					title={translate(ResourceAssistance.Message.medicalSupply)}
					name={this.EOrder.MEDICAL_SUPPLY}
				>
					{this.state.tab === this.EOrder.MEDICAL_SUPPLY && (
						<Fragment>
							<RegModalExpenseEstFilter types={this.props.reg.modalExpenseEst.itemTypes} />
							<RegModalExpenseEstMedicalSupply />
						</Fragment>
					)}
				</Tab>
				<Tab
					eventKey={this.EOrder.SERVICE}
					title={translate(ResourceAssistance.Hospitel.service)}
					name={this.EOrder.SERVICE}
				>
					{this.state.tab === this.EOrder.SERVICE && (
						<Fragment>
							<RegModalExpenseEstFilter types={this.props.reg.modalExpenseEst.serviceTypes} />
							<RegModalExpenseEstService />
						</Fragment>
					)}
				</Tab>
			</Tabs>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(RegModalExpenseEstOrderTab)
