import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalNurseRecordDetails from "./HospitelModalNurseRecordDetails"

class HospitelModalNurseRecord extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalNurseRecord",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.hospitel.selectedNurseNote.nurseNote, {
			...this.props.hospitel.selectedNurseNote.nurseNote,
			dateTime: this.props.hospitel.modalNurseRecord.recordDateTime,
			focus: Utils.trim(this.props.hospitel.modalNurseRecord.focus),
			assessment: Utils.trim(this.props.hospitel.modalNurseRecord.assessment),
			intervention: Utils.trim(this.props.hospitel.modalNurseRecord.intervention),
			evaluation: Utils.trim(this.props.hospitel.modalNurseRecord.evaluation),
		})
		return _.isEqual(target, this.props.hospitel.selectedNurseNote.nurseNote)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveNurseNote,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.hospitel.selectedHospitel.hospitel.admission.id,
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				nurseNotes: [
					{
						id: this.props.hospitel.selectedNurseNote.nurseNote ? this.props.hospitel.selectedNurseNote.nurseNote.id : 0,
						dateTime: this.props.hospitel.modalNurseRecord.recordDateTime,
						focus: Utils.trim(this.props.hospitel.modalNurseRecord.focus),
						assessment: Utils.trim(this.props.hospitel.modalNurseRecord.assessment),
						assessmentNote: Utils.trim(this.props.hospitel.modalNurseRecord.assessmentOther),
						intervention: Utils.trim(this.props.hospitel.modalNurseRecord.intervention),
						evaluation: Utils.trim(this.props.hospitel.modalNurseRecord.evaluation),
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.nurse)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalNurseRecordDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button type={ResourceAssistance.Button.type.submit} variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isNurseRecordDisplay,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseRecord))
