import { Divider, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GiDiploma } from "react-icons/gi"
import { ImLab } from "react-icons/im"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgDoctor, SvgNurse } from "~/icons"
import {
	onModalDisplayAction,
	setCashierAdmissions,
	setCashierModalFinancialPlanBillingStatements,
	setLoadingAction,
	setObjArray,
	setSelected,
} from "~/redux/action"
import {
	SET_CASHIER_CERTIFICATE_DISPLAY,
	SET_CASHIER_DOCTOR_DISPLAY,
	SET_CASHIER_LAB_DISPLAY,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES,
	SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE,
	SET_CASHIER_NURSE_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CashierModalFinancialPlanAction extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierModalFinancialPlanAction",
		}

		this.billingStatements = this.props.cashier.admissionTable.original.reduce((obj, cur) => {
			return obj.concat(cur.billingStatements)
		}, [])

		this.onHealthCarePlan = this.onHealthCarePlan.bind(this)
		this.onLab = this.onLab.bind(this)
		this.onMedicalCertificate = this.onMedicalCertificate.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onDoctor = this.onDoctor.bind(this)
		this.onNurse = this.onNurse.bind(this)
	}

	componentDidMount() {
		this.props.loadHealthCares()
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.cashier.modalFinancialPlan.healthCares, this.props.cashier.modalFinancialPlan.healthCares) &&
			this.props.cashier.selectedHealthCarePlan.healthCarePlan
		) {
			let healthcareIdx = this.props.cashier.modalFinancialPlan.healthCares.findIndex(
				(healthCare) => healthCare.code === this.props.cashier.selectedHealthCarePlan.healthCarePlan.code
			)
			if (healthcareIdx > -1) {
				let healthcarePlan = this.props.cashier.modalFinancialPlan.healthCares[healthcareIdx]
				if (
					healthcarePlan.displayName === this.props.cashier.selectedHealthCarePlan.healthCarePlan.name &&
					healthcarePlan.description === this.props.cashier.selectedHealthCarePlan.healthCarePlan.description
				) {
					this.props.setSelected(SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE, healthcareIdx, healthcarePlan)
				} else {
					let healthcareCompanyIdx = healthcarePlan.companies.findIndex(
						(company) =>
							company.displayName === this.props.cashier.selectedHealthCarePlan.healthCarePlan.name &&
							company.description === this.props.cashier.selectedHealthCarePlan.healthCarePlan.description
					)
					if (healthcareCompanyIdx > -1) {
						this.props.setSelected(
							SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE,
							healthcareIdx + "-" + healthcareCompanyIdx,
							healthcarePlan.companies[healthcareCompanyIdx]
						)
					}
				}
			}
		}

		if (!_.isEqual(prevProps.cashier.selectedAdmission, this.props.cashier.selectedAdmission)) {
			this.billingStatements = this.props.cashier.admissionTable.original.reduce((obj, cur) => {
				return obj.concat(cur.billingStatements)
			}, [])
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES, [])
		this.props.setSelected(SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE, -1, null)
	}

	deleteBillingStatement() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.deleteBillingStatement,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.cashier.selectedAdmission.admission.id,
				billingStatements: [
					{
						id: this.props.cashier.modalFinancialPlan.selectedService.service.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter((each) => each.id !== res.data.admissions[0].id)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isLabBtnDisabled() {
		return (
			!this.props.cashier.selectedAdmission.admission ||
			this.props.cashier.patient.labs.filter((lab) => {
				if (lab.status === ResourceAssistance.CONSTANT.CANCELLED) {
					return false
				}

				let billingStatamentTotal = this.billingStatements
					.filter((bs) => bs.lab && bs.lab.id === lab.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))

				let pendingTotal = this.props.cashier.modalFinancialPlan.serviceTable.original
					.filter((bs) => !bs.id && bs.isLab && bs.order.id === lab.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
				return !billingStatamentTotal.plus(pendingTotal).isEqualTo(lab.service.pricePerUnit)
			}).length === 0
		)
	}

	isCertificateBtnDisabled() {
		return (
			!this.props.cashier.selectedAdmission.admission ||
			this.props.cashier.patient.certificates.filter((certificate) => {
				let billingStatamentTotal = this.billingStatements
					.filter((bs) => bs.certificate && bs.certificate.id === certificate.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))

				let pendingTotal = this.props.cashier.modalFinancialPlan.serviceTable.original
					.filter((bs) => !bs.id && bs.isCertificate && bs.order.id === certificate.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
				return !billingStatamentTotal.plus(pendingTotal).isEqualTo(certificate.service.pricePerUnit)
			}).length === 0
		)
	}

	isHospitelBtnDisabled() {
		return (
			this.props.cashier.patient.hospitels.filter((hospitel) => !this.billingStatements.some((each) => each.hospitel && each.hospitel.id === hospitel.id))
				.length === 0
		)
	}

	isDoctorBtnDisabled() {
		return (
			!this.props.cashier.selectedAdmission.admission ||
			this.props.cashier.selectedAdmission.admission.doctorOrders
				.filter((order) => !order.prescriptionOnly)
				.filter((order) => {
					let billingStatamentTotal = this.billingStatements
						.filter((bs) => bs.doctorOrder && bs.doctorOrder.id === order.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))

					let pendingTotal = this.props.cashier.modalFinancialPlan.serviceTable.original
						.filter((bs) => !bs.id && bs.isDoctorOrder && bs.order.id === order.id)
						.reduce((total, cur) => {
							return total.plus(cur.charge)
						}, Utils.BigNumber(0))
					return !billingStatamentTotal
						.plus(pendingTotal)
						.isEqualTo(
							Utils.BigNumber(Utils.calculateDoctorOrderBalance(order)).minus(
								Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit)
							)
						)
				}).length === 0
		)
	}

	isNurseBtnDisabled() {
		return (
			!this.props.cashier.selectedAdmission.admission ||
			this.props.cashier.selectedAdmission.admission.nurseOrders.filter((order) => {
				let billingStatamentTotal = this.billingStatements
					.filter((bs) => bs.nurseOrder && bs.nurseOrder.id === order.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))

				let pendingTotal = this.props.cashier.modalFinancialPlan.serviceTable.original
					.filter((bs) => !bs.id && bs.isNurseOrder && bs.order.id === order.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))

				return !billingStatamentTotal
					.plus(pendingTotal)
					.isEqualTo(
						Utils.BigNumber(Utils.calculateNurseOrderBalance(order)).minus(
							Utils.calculatePharmacyReturnOrderBalance(order.pharmacyReturnOrders, order.pricePerUnit)
						)
					)
			}).length === 0
		)
	}

	onHealthCarePlan(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE, -1, null)
			return
		}
		if (typeof value === "string") {
			let entry = value.split("-")
			this.props.setSelected(
				SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE,
				value,
				this.props.cashier.modalFinancialPlan.healthCares[entry[0]].companies[entry[1]]
			)
		} else {
			this.props.setSelected(SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_HEALTH_CARE, value, this.props.cashier.modalFinancialPlan.healthCares[value])
		}
	}

	onLab(event) {
		this.props.onModalDisplayAction(SET_CASHIER_LAB_DISPLAY, true)
	}

	onMedicalCertificate(event) {
		this.props.onModalDisplayAction(SET_CASHIER_CERTIFICATE_DISPLAY, true)
	}

	onDoctor() {
		this.props.onModalDisplayAction(SET_CASHIER_DOCTOR_DISPLAY, true)
	}

	onNurse() {
		this.props.onModalDisplayAction(SET_CASHIER_NURSE_DISPLAY, true)
	}

	onDelete(event) {
		if (this.props.cashier.modalFinancialPlan.selectedService.service.id) {
			this.deleteBillingStatement()
		} else {
			let billingStatements = Array.from(this.props.cashier.modalFinancialPlan.serviceTable.filtered)
			billingStatements.splice(this.props.cashier.modalFinancialPlan.selectedService.index, 1)
			this.props.setCashierModalFinancialPlanBillingStatements(billingStatements)
		}
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col md={3}>
					<TreeSelect
						style={{
							textAlign: "center",
						}}
						showSearch
						filterTreeNode={(input, treeNode) => {
							return treeNode.title.toLowerCase().includes(input)
						}}
						allowClear
						treeLine={{ showLeafIcon: false }}
						placeholder={translate(ResourceAssistance.Hospitel.healthCare)}
						status={this.props.cashier.modalFinancialPlan.selectedHealthCare.healthCare ? undefined : "error"}
						value={
							this.props.cashier.modalFinancialPlan.selectedHealthCare.healthCare ? this.props.cashier.modalFinancialPlan.selectedHealthCare.index : undefined
						}
						onChange={this.onHealthCarePlan}
						treeData={Utils.getTreeNodesFromHealthCarePlan(this.props.cashier.modalFinancialPlan.healthCares, true, false)}
					/>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							this.props.cashier.selectedAdmission.index === -1 || this.props.cashier.selectedAdmission.admission.dischargedBy || this.isLabBtnDisabled()
						}
						onClick={this.onLab}
					>
						<ImLab size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.lab)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							this.props.cashier.selectedAdmission.index === -1 ||
							this.props.cashier.selectedAdmission.admission.dischargedBy ||
							this.isCertificateBtnDisabled()
						}
						onClick={this.onMedicalCertificate}
					>
						<GiDiploma size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.medicalCertificate)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							this.props.cashier.selectedAdmission.index === -1 || this.props.cashier.selectedAdmission.admission.dischargedBy || this.isDoctorBtnDisabled()
						}
						onClick={this.onDoctor}
					>
						<SvgDoctor width={ResourceAssistance.ReactIcon.size} height={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.doctor)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							this.props.cashier.selectedAdmission.index === -1 || this.props.cashier.selectedAdmission.admission.dischargedBy || this.isNurseBtnDisabled()
						}
						onClick={this.onNurse}
					>
						<SvgNurse width={ResourceAssistance.ReactIcon.size} height={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.nurse)}
					</Button>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.red}
						disabled={this.props.cashier.modalFinancialPlan.selectedService.index === -1}
						onClick={this.onDelete}
					>
						<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.delete)}
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setCashierAdmissions,
			setObjArray,
			setCashierModalFinancialPlanBillingStatements,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierModalFinancialPlanAction))
