import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import AccountsReceiveRightTabReceipt from "./AccountsReceiveRightTabReceipt"
import AccountsReceiveRightTabGL from "./AccountsReceiveRightTabGL"

class AccountsReceiveRightTab extends React.Component {
	EAccountsReceive = {
		GENERAL: "ACCOUNTS_RECEIVE_GENERAL",
		GL: "ACCOUNTS_RECEIVE_GL",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EAccountsReceive.GL,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Tab.Container id={this.state.componentName} activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Row>
					<Col>
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey={this.EAccountsReceive.GL}>{translate(ResourceAssistance.Message.generalLedger)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EAccountsReceive.GENERAL}>{translate(ResourceAssistance.Message.receipt)}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.EAccountsReceive.GL}>{this.state.tab === this.EAccountsReceive.GL && <AccountsReceiveRightTabGL />}</Tab.Pane>
							<Tab.Pane eventKey={this.EAccountsReceive.GENERAL}>
								{this.state.tab === this.EAccountsReceive.GENERAL && <AccountsReceiveRightTabReceipt />}
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AccountsReceiveRightTab)
