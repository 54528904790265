import _ from "lodash"
import React from "react"
import { Button, Col, Container, Form, Row } from "react-bootstrap"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ADM_WP_DRG, SET_ADM_WP_MIN_COST, SET_ADM_WP_PROPERTY } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class ConfigSurgicalAuth extends React.Component {
	constructor(props) {
		super(props)

		this.onDRG = this.onDRG.bind(this)
		this.onMinCost = this.onMinCost.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		this.props.loadProperties(ResourceAssistance.Url.admin.workflowProperty.getSurgicalAuthProperties, (res) => {
			if (!_.isEmpty(res.data.workflowProperties)) {
				let property = res.data.workflowProperties[0]
				this.props.setValue(SET_ADM_WP_PROPERTY, property)
				this.props.setValue(SET_ADM_WP_DRG, property.drg)
				this.props.setValue(SET_ADM_WP_MIN_COST, property.minCost)
			}
		})
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_WP_DRG, undefined)
		this.props.setValue(SET_ADM_WP_MIN_COST, undefined)
	}

	componentDidUpdate(prevProps, prevState) {}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.wp.property, {
			drg: Number(this.props.surgicalAuth.drg),
			minCost: Number(this.props.surgicalAuth.minCost),
		})
		return _.isEqual(target, this.props.wp.property)
	}

	onDRG(e) {
		this.props.setValue(SET_ADM_WP_DRG, e.target.value)
	}

	onMinCost(e) {
		this.props.setValue(SET_ADM_WP_MIN_COST, e.target.value)
	}

	onDone(e) {
		e.preventDefault()
		e.stopPropagation()
		this.props.onDone(ResourceAssistance.Url.admin.workflowProperty.saveSurgicalAuthProperty, {
			id: this.props.wp.property ? this.props.wp.property.id : 0,
			userId: this.props.login.user.id,
			drg: this.props.surgicalAuth.drg,
			minCost: this.props.surgicalAuth.minCost,
		})
	}

	render() {
		return (
			<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
				<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
					<Row>
						<Col md={"auto"}>
							<GInput
								required
								type={ResourceAssistance.FormControl.type.number}
								style={{ textAlign: "center", width: "200px" }}
								placeholder={translate(ResourceAssistance.Message.DRG)}
								value={this.props.surgicalAuth.drg}
								onChange={this.onDRG}
							/>
						</Col>
						<Col md={"auto"}>
							<GInput
								required
								type={ResourceAssistance.FormControl.type.number}
								style={{ textAlign: "center", width: "200px" }}
								placeholder={translate(ResourceAssistance.Message.minimumCost)}
								min={0}
								max={100}
								step={1}
								value={this.props.surgicalAuth.minCost}
								addonAfter={"%"}
								onChange={this.onMinCost}
							/>
						</Col>
						<Col md={"auto"}>
							<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
								<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.save)}
							</Button>
						</Col>
					</Row>
				</Container>
			</Form>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	wp: state.admin.configWP,
	surgicalAuth: state.admin.configWP.surgicalAuth,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSurgicalAuth)
