import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_PGL_MODAL_NEW_REMARK, SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class PGLModalNewFields extends React.Component {
	constructor(props) {
		super(props)

		this.onGLDocument = this.onGLDocument.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.PGL.selectedPGL.PGL) {
			let selectedPGL = this.props.PGL.selectedPGL.PGL
			this.props.setValue(SET_PGL_MODAL_NEW_REMARK, selectedPGL.remark)
			if (!_.isEmpty(this.props.PGL.glDocuments)) {
				this.initialGLDocument()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PGL.glDocuments, this.props.PGL.glDocuments)) {
			if (this.props.PGL.selectedPGL.PGL && !_.isEmpty(this.props.PGL.glDocuments)) {
				this.initialGLDocument()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT, -1, null)
		this.props.setValue(SET_PGL_MODAL_NEW_REMARK, "")
	}

	initialGLDocument() {
		let idx = this.props.PGL.glDocuments.findIndex((each) => each.code === this.props.PGL.selectedPGL.PGL.code)
		if (idx > -1) {
			this.props.setSelected(SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT, idx, this.props.PGL.glDocuments[idx])
		}
	}

	onGLDocument(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT, -1, null)
			return
		}
		this.props.setSelected(SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT, value, this.props.PGL.glDocuments[value])
	}

	onRemark(e) {
		this.props.setValue(SET_PGL_MODAL_NEW_REMARK, e.target.value)
	}

	render() {
		return (
			<Row>
				<Col md={3} style={{ justifyContent: "flex-start" }}>
					<Select
						allowClear
						showSearch
						size={"large"}
						style={{ textAlign: "center" }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.glDocument)}
						loading={this.props.PGL.isGLDocumentsLoading}
						status={this.props.PGL.modalNew.selectedGLDocument.document ? undefined : "error"}
						value={this.props.PGL.modalNew.selectedGLDocument.document ? this.props.PGL.modalNew.selectedGLDocument.index : undefined}
						onChange={this.onGLDocument}
					>
						{Utils.renderSelects(this.props.PGL.glDocuments, false, -1, "displayCodeName")}
					</Select>
				</Col>
				<Col>
					<GInput
						autoSize
						style={{ textAlign: "center" }}
						placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark })}
						minRows={5}
						onChange={this.onRemark}
						value={this.props.PGL.modalNew.remark}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PGLModalNewFields))
