import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_IPD_NURSE_INFO_DISPLAY } from "~/redux/type"
import InpatientModalNurseRecordInfoDetails from "./InpatientModalNurseRecordInfoDetails"

class InpatientModalNurseRecordInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InpatientModalNurseRecordInfo",
		}

		this.onClose = this.onClose.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_IPD_NURSE_INFO_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundInfo}>
					<ModalTitle>{translate(ResourceAssistance.Hospitel.nurseProgressNote)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<InpatientModalNurseRecordInfoDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isInpatientNurseInfoDisplay,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(InpatientModalNurseRecordInfo))
