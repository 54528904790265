import { SET_AUTHORIZATION_AUTHORIZE_IND, SET_AUTHORIZATION_ID, SET_AUTHORIZATION_PRIVILEGE } from "~/redux/type"

const init = {
	id: "",
	privilege: "",
	isAuthorized: false,
}

const authorizationReducer = (state = init, action) => {
	switch (action.type) {
		case SET_AUTHORIZATION_ID:
			return Object.assign({}, state, {
				id: action.payload.value,
			})
		case SET_AUTHORIZATION_PRIVILEGE:
			return Object.assign({}, state, {
				privilege: action.payload.value,
			})
		case SET_AUTHORIZATION_AUTHORIZE_IND:
			return Object.assign({}, state, {
				isAuthorized: action.payload.value,
			})
		default:
			return state
	}
}

export default authorizationReducer
