import React from "react"
import { Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { IoHourglassOutline } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_PO_FILTER_BY_LOCATION } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ReportPOByLocationFilterDetails from "./ReportPOByLocationFilterDetails"

class ReportPOByLocationFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportPOByLocationFilter",
		}

		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	runReport(orders) {
		let finalTotal = Utils.BigNumber(0)
		let data = {
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.purchaseOrderByLocation,
			}),
			startDateTimeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.startDateTime,
			}),
			endDateTimeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.endDateTime,
			}),
			branchIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.branch,
			}),
			poIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.poId,
			}),
			poDateTimeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.date,
			}),
			issuedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.issuedBy,
			}),
			certifiedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.certifiedBy,
			}),
			approvedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.approvedBy,
			}),
			memoIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.memoId,
			}),
			idIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.id,
			}),
			supplierIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.supplier,
			}),
			subTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.subTotal,
			}),
			discountIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.discount,
			}),
			totalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.totalPriceDiscounted,
			}),
			vatIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.vat,
			}),
			grandTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.grandTotal,
			}),
			paymentIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.payment,
			}),
			issuedIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.issued,
			}),
			certifiedIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.certified,
			}),
			approvedIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.approved,
			}),

			name: this.props.filter.selectedOrg.org.displayName,
			taxId: this.props.filter.selectedOrg.org.taxNo,
			address: this.props.filter.selectedBranch.branch.address.displayName,
			tel: this.props.filter.selectedBranch.branch.address.tel,
			fax: this.props.filter.selectedBranch.branch.address.fax,

			startDateTime: Utils.formatDateTime(this.props.filter.startDateTime),
			endDateTime: Utils.formatDateTime(this.props.filter.endDateTime),
			branch: this.props.filter.selectedBranch.branch.displayName,
			issuedIcon: this.props.filter.isRequester ? ResourceAssistance.Symbol.Report.check : ResourceAssistance.Symbol.Report.cancel,
			certifiedIcon: this.props.filter.isInspector ? ResourceAssistance.Symbol.Report.check : ResourceAssistance.Symbol.Report.cancel,
			approvedIcon: this.props.filter.isApproval ? ResourceAssistance.Symbol.Report.check : ResourceAssistance.Symbol.Report.cancel,

			orders: orders.map((po) => {
				let grandTotal = Utils.BigNumber(0)
				return Object.assign(
					{},
					{
						poId: po.id,
						poDateTime: Utils.formatDateTime(po.pr.approverApprovedDateTime),
						issuedBy: po.pr.approval.lastName + " " + po.pr.approval.firstName,
						certifiedBy: po.inspectorApproved ? po.inspector.lastName + " " + po.inspector.firstName : "",
						approvedBy: po.approvalApproved ? po.approval.lastName + " " + po.approval.firstName : "",
						suppliers: po.suppliers.map((supplier) => {
							let vat = supplier.items[0].tax
							return Object.assign(
								{},
								{
									memoId: supplier.orderDetail.routingId,
									id: supplier.code,
									supplier: supplier.name,
									subTotal: Utils.formatNumWithComma(
										supplier.items
											.reduce((total, item) => {
												total = total.plus(
													Utils.BigNumber(item.pricePerUnit)
														.times(1 - item.discount / 100)
														.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
												)
												return total
											}, Utils.BigNumber(0))
											.toFixed(2)
									),
									discount: supplier.orderDetail.discount,
									total: Utils.formatNumWithComma(
										supplier.items
											.reduce((total, item) => {
												total = total.plus(
													Utils.BigNumber(item.pricePerUnit)
														.times(1 - item.discount / 100)
														.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
														.times(1 - supplier.orderDetail.discount / 100)
												)
												return total
											}, Utils.BigNumber(0))
											.toFixed(2)
									),
									vat: supplier.items.some((item) => item.tax !== vat) ? 0 : vat,
									grandTotal: Utils.formatNumWithComma(
										supplier.items
											.reduce((total, item) => {
												total = total
													.plus(
														Utils.BigNumber(item.pricePerUnit)
															.times(1 - item.discount / 100)
															.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
															.times(1 - supplier.orderDetail.discount / 100)
													)
													.times(1 + vat / 100)
												grandTotal = grandTotal.plus(total)
												finalTotal = finalTotal.plus(total)
												return total
											}, Utils.BigNumber(0))
											.toFixed(2)
									),
									payment: supplier.orderDetail.payment ? supplier.orderDetail.payment.displayName : "",
								}
							)
						}),
						grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),
					}
				)
			}),
			grandTotal: Utils.formatNumWithComma(finalTotal.toFixed(2)),
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.Po.byLocation, "_blank")
		myWindow.data = data
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_PO_FILTER_BY_LOCATION, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.po.getPOs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				orgId: this.props.filter.selectedOrg.org.id,
				branchId: this.props.filter.selectedBranch.branch.id,
				requester: this.props.filter.isRequester,
				inspector: this.props.filter.isInspector,
				approval: this.props.filter.isApproval,
			},
		}
		let callback = (res) => {
			this.runReport(res.data.orders)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.reportByLocation)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<ReportPOByLocationFilterDetails />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.po.isReportByLocationDisplay,
	filter: state.report.po.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportPOByLocationFilter))
