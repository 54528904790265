import React from "react"
import { Col, Container, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OperatingRoomAnesthesiaTabIntra from "./intra/OperatingRoomAnesthesiaTabIntra"
import OperatingRoomAnesthesiaTabPost from "./post/OperatingRoomAnesthesiaTabPost"
import OperatingRoomAnesthesiologistTabPre from "./pre/OperatingRoomAnesthesiologistTabPre"

class OperatingRoomRightTabAnesthesiologist extends React.Component {
	EAnesthesiologist = {
		PRE: "OPERATING_ROOM_ANESTHESIOLOGIST_PRE",
		INTRA: "OPERATING_ROOM_ANESTHESIOLOGIST_INTRA",
		POST: "OPERATING_ROOM_ANESTHESIOLOGIST_POST",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EAnesthesiologist.PRE,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"full-size"}>
					<Col>
						<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
							<Nav variant="tabs">
								<Nav.Item>
									<Nav.Link eventKey={this.EAnesthesiologist.PRE}>{translate(ResourceAssistance.Message.preAnesthesiaRecord)}</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey={this.EAnesthesiologist.INTRA}>{translate(ResourceAssistance.Message.intraAnesthesiaRecord)}</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey={this.EAnesthesiologist.POST}>{translate(ResourceAssistance.Message.postAnesthesiaRecord)}</Nav.Link>
								</Nav.Item>
							</Nav>
							<Tab.Content>
								<Tab.Pane eventKey={this.EAnesthesiologist.PRE}>
									{this.state.tab === this.EAnesthesiologist.PRE && <OperatingRoomAnesthesiologistTabPre />}
								</Tab.Pane>
								<Tab.Pane eventKey={this.EAnesthesiologist.INTRA}>
									{this.state.tab === this.EAnesthesiologist.INTRA && <OperatingRoomAnesthesiaTabIntra />}
								</Tab.Pane>
								<Tab.Pane eventKey={this.EAnesthesiologist.POST}>
									{this.state.tab === this.EAnesthesiologist.POST && <OperatingRoomAnesthesiaTabPost />}
								</Tab.Pane>
							</Tab.Content>
						</Tab.Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OperatingRoomRightTabAnesthesiologist)
