import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setDebtPlans, setLoadingAction } from "~/redux/action"
import { SET_DEBT_MODAL_PAYMENT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DebtModalPaymentDetails from "./DebtModalPaymentDetails"

class DebtModalPayment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "DebtModalPayment",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_DEBT_MODAL_PAYMENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.debt.modalPayment.selectedPayment.payment ||
			(this.props.debt.modalPayment.selectedPayment.payment.bankAccount && !this.props.debt.modalPayment.selectedBranch.branch)
		) {
			return true
		}
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let GL = Utils.getGeneralLedgerCode(
			this.props.debt.location.accountsReceivable,
			[],
			this.props.debt.modalPayment.selectedPayment.payment.bankAccount
				? this.props.debt.modalPayment.selectedBranch.branch.activities
				: this.props.debt.modalPayment.selectedPayment.payment.activities
		)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.debt.updateDebtPlanPayment,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.debt.location.id,
				userId: this.props.login.user.id,
				debtPlans: [
					{
						id: this.props.debt.selectedPlan.plan.id,
						payment: this.props.debt.modalPayment.selectedPayment.payment.displayName,
						paymentDateTime: this.props.debt.modalPayment.paymentDateTime,
						bank: this.props.debt.modalPayment.selectedBank.bank ? this.props.debt.modalPayment.selectedBank.bank.displayName : "",
						branch: this.props.debt.modalPayment.selectedBranch.branch ? this.props.debt.modalPayment.selectedBranch.branch.displayName : "",
						transferBank: Utils.trim(this.props.debt.modalPayment.transferBankName),
						transferBranch: Utils.trim(this.props.debt.modalPayment.transferBankBranch),
						creditCard: this.props.debt.modalPayment.creditCardNum,
						checkNum: Utils.trim(this.props.debt.modalPayment.checkNum),
						checkDateTime: this.props.debt.modalPayment.checkNum ? this.props.debt.modalPayment.checkDateTime : "",
						amount: this.props.debt.modalPayment.amount,
						transactionFee: this.props.debt.modalPayment.transactionFee,
						withholdingTax: this.props.debt.modalPayment.withholdingTax,
						balanceAdjustment: this.props.debt.modalPayment.balanceAdjustment,
						remark: Utils.trim(this.props.debt.modalPayment.remark),
						invoices: this.props.debt.selectedPlan.plan.invoices.map((invoice) => {
							return {
								id: invoice.id,
								accountingCode: GL.fullCode,
								accountingChartName: GL.displayName,
								accountingChartDescription: GL.description,
								accountingTransDescr: GL.transDescr,
							}
						}),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.debtPlans.length > 0) {
				let plans = this.props.debt.planTable.original.filter((each) => each.id !== res.data.debtPlans[0].id)
				plans.push(res.data.debtPlans[0])
				this.props.setDebtPlans(plans)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.md}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.payment)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<DebtModalPaymentDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isDebtPaymentDisplay,
	login: state.login,
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtPlans,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtModalPayment)
