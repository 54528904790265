import _ from "lodash"
import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION, SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class OutpatientModalDoctorOrderFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onDescription = this.onDescription.bind(this)
		this.onType = this.onType.bind(this)
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.opd.modalDoctorOrder.types)) {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE, 0, this.props.opd.modalDoctorOrder.types[0])
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION, "")
	}

	onDescription(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION, Utils.trim(event.target.value))
	}

	onType(event) {
		this.props.setSelected(
			SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_TYPE,
			event.target.value,
			this.props.opd.modalDoctorOrder.types[event.target.value]
		)
	}

	render() {
		return (
			<Row>
				<Col md={3}>
					<FormControl
						size={ResourceAssistance.FormControl.size.sm}
						as={ResourceAssistance.FormControl.as.select}
						onChange={this.onType}
					>
						{Utils.renderOptions(this.props.opd.modalDoctorOrder.types, false)}
					</FormControl>
				</Col>
				<Col>
					<SearchBox
						num={1}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.genericName]}
						callbacks={[this.onDescription]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctorOrderFilter))
