import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportJournalVoucherDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				GLs: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
						{/* Title */}
						<ReportTitle displayOrg={true} displayAddress={true} displayFax={false} displayTaxNum={false} displayTel={false} data={this.state.data} />

						{/* General Info */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.idIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.id}</Text>
							</View>
						</View>
						{this.state.data.invoiceNum && (
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
									<Text>{this.state.data.invoiceIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.invoiceNum}</Text>
								</View>
							</View>
						)}
						{this.state.data.supplier && (
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
									<Text>{this.state.data.supplierIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.supplier}</Text>
								</View>
							</View>
						)}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.dateIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.date}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* GL Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.codeIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.name]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.nameIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.debit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.debitIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.credit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.creditIntl}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{this.state.data.GLs.map((GL) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
											<Text>{GL.code}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.name]}>
											<Text>{GL.name}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.debit]}>
											<Text>{GL.debit}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.credit]}>
											<Text>{GL.credit}</Text>
										</View>
									</View>
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />
						{/* Total */}
						<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}></View>
							<View
								style={[
									ResourceAssistance.Report.styles.col,
									styles.name,
									{
										textAlign: "right",
										fontStyle: "bold",
									},
								]}
							>
								<Text>{this.state.data.grandTotalIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.debit]}>
								<Text>{this.state.data.totalDebit}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.credit]}>
								<Text>{this.state.data.totalCredit}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />
						<Signature numOfSignature={2} displaySignatureDate={false} signatureTitles={this.state.data.signatureNames} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	code: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	name: {
		minWidth: "55%",
		maxWidth: "55%",
	},
	debit: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
	credit: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
})

export default ReportJournalVoucherDoc
