import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

const Duration = [
	{
		displayName: Utils.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.days }),
		shortName: ResourceAssistance.CONSTANT.DAY,
		value: 1440,
	},
	{
		displayName: Utils.getIntl().formatMessage({ id: ResourceAssistance.Message.hours }),
		shortName: ResourceAssistance.CONSTANT.HOUR,
		value: 60,
	},
	{
		displayName: Utils.getIntl().formatMessage({ id: ResourceAssistance.Message.minutes }),
		shortName: ResourceAssistance.CONSTANT.MINUTE,
		value: 1,
	},
]

export default Duration
