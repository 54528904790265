import { ResourceAssistance } from "~/i18n"
import {
	SET_ADM_COA_ASSETS,
	SET_ADM_COA_ASSETS_ACCOUNTS,
	SET_ADM_COA_ASSETS_ACCOUNTS_LOADING,
	SET_ADM_COA_ASSETS_FILTER_CODE,
	SET_ADM_COA_ASSETS_FILTER_NAME,
	SET_ADM_COA_ASSETS_MODAL_ADD_CODE,
	SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION,
	SET_ADM_COA_ASSETS_MODAL_ADD_NAME,
	SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS,
	SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL,
	SET_ADM_COA_ASSETS_ORIGINAL,
	SET_ADM_COA_ASSETS_SELECTED_ACCOUNT,
	SET_ADM_COA_ASSETS_SELECTED_ASSETS,
	SET_ADM_COA_CHART_OF_ACCOUNTS,
	SET_ADM_COA_CHART_OF_ACCOUNTS_LOADING,
	SET_ADM_COA_EQUITIES,
	SET_ADM_COA_EQUITY_ACCOUNTS,
	SET_ADM_COA_EQUITY_ACCOUNTS_LOADING,
	SET_ADM_COA_EQUITY_FILTER_CODE,
	SET_ADM_COA_EQUITY_FILTER_NAME,
	SET_ADM_COA_EQUITY_MODAL_ADD_CODE,
	SET_ADM_COA_EQUITY_MODAL_ADD_DESCRIPTION,
	SET_ADM_COA_EQUITY_MODAL_ADD_NAME,
	SET_ADM_COA_EQUITY_MODAL_ADD_SELECTED_EQUITY,
	SET_ADM_COA_EQUITY_MODAL_ADD_SUB_LEVEL,
	SET_ADM_COA_EQUITY_ORIGINAL,
	SET_ADM_COA_EQUITY_SELECTED_ACCOUNT,
	SET_ADM_COA_EQUITY_SELECTED_EQUITY,
	SET_ADM_COA_EXPENSES,
	SET_ADM_COA_EXPENSES_ACCOUNTS,
	SET_ADM_COA_EXPENSES_ACCOUNTS_LOADING,
	SET_ADM_COA_EXPENSES_FILTER_CODE,
	SET_ADM_COA_EXPENSES_FILTER_NAME,
	SET_ADM_COA_EXPENSES_MODAL_ADD_CODE,
	SET_ADM_COA_EXPENSES_MODAL_ADD_DESCRIPTION,
	SET_ADM_COA_EXPENSES_MODAL_ADD_NAME,
	SET_ADM_COA_EXPENSES_MODAL_ADD_SELECTED_EXPENSE,
	SET_ADM_COA_EXPENSES_MODAL_ADD_SUB_LEVEL,
	SET_ADM_COA_EXPENSES_ORIGINAL,
	SET_ADM_COA_EXPENSES_SELECTED_ACCOUNT,
	SET_ADM_COA_EXPENSES_SELECTED_EXPENSE,
	SET_ADM_COA_LIABILITIES,
	SET_ADM_COA_LIABILITIES_ACCOUNTS,
	SET_ADM_COA_LIABILITIES_ACCOUNTS_LOADING,
	SET_ADM_COA_LIABILITIES_FILTER_CODE,
	SET_ADM_COA_LIABILITIES_FILTER_NAME,
	SET_ADM_COA_LIABILITIES_MODAL_ADD_CODE,
	SET_ADM_COA_LIABILITIES_MODAL_ADD_DESCRIPTION,
	SET_ADM_COA_LIABILITIES_MODAL_ADD_NAME,
	SET_ADM_COA_LIABILITIES_MODAL_ADD_SELECTED_LIABILITIES,
	SET_ADM_COA_LIABILITIES_MODAL_ADD_SUB_LEVEL,
	SET_ADM_COA_LIABILITIES_ORIGINAL,
	SET_ADM_COA_LIABILITIES_SELECTED_ACCOUNT,
	SET_ADM_COA_LIABILITIES_SELECTED_LIABILITIES,
	SET_ADM_COA_REVENUES,
	SET_ADM_COA_REVENUES_ACCOUNTS,
	SET_ADM_COA_REVENUES_ACCOUNTS_LOADING,
	SET_ADM_COA_REVENUES_FILTER_CODE,
	SET_ADM_COA_REVENUES_FILTER_NAME,
	SET_ADM_COA_REVENUES_MODAL_ADD_CODE,
	SET_ADM_COA_REVENUES_MODAL_ADD_DESCRIPTION,
	SET_ADM_COA_REVENUES_MODAL_ADD_NAME,
	SET_ADM_COA_REVENUES_MODAL_ADD_SELECTED_REVENUE,
	SET_ADM_COA_REVENUES_MODAL_ADD_SUB_LEVEL,
	SET_ADM_COA_REVENUES_ORIGINAL,
	SET_ADM_COA_REVENUES_SELECTED_ACCOUNT,
	SET_ADM_COA_REVENUES_SELECTED_REVENUE,
} from "~/redux/type"

const init = {
	isChartOfAccountsLoading: false,
	chartOfAccounts: [],
	assets: {
		filterCode: "",
		filterName: "",
		assets: [],
		originalAssets: [],
		selectedAssets: {
			index: -1,
			assets: null,
		},
		selectedAccount: {
			index: -1,
			account: null,
		},
		accountTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.level,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.accountsCode,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAdd: {
			code: "",
			name: "",
			description: "",
			isSubLevel: false,
			selectedAssets: {
				index: -1,
				assets: null,
			},
		},
	},
	liabilities: {
		filterCode: "",
		filterName: "",
		liabilities: [],
		originalLiabilities: [],
		selectedLiabilities: {
			index: -1,
			liabilities: null,
		},
		selectedAccount: {
			index: -1,
			account: null,
		},
		accountTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.level,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.accountsCode,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAdd: {
			code: "",
			name: "",
			description: "",
			isSubLevel: false,
			selectedLiabilities: {
				index: -1,
				liabilities: null,
			},
		},
	},
	equity: {
		filterCode: "",
		filterName: "",
		equities: [],
		originalEquities: [],
		selectedEquity: {
			index: -1,
			equity: null,
		},
		selectedAccount: {
			index: -1,
			account: null,
		},
		accountTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.level,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.accountsCode,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAdd: {
			code: "",
			name: "",
			description: "",
			isSubLevel: false,
			selectedEquity: {
				index: -1,
				equity: null,
			},
		},
	},
	expense: {
		filterCode: "",
		filterName: "",
		expenses: [],
		originalExpenses: [],
		selectedExpense: {
			index: -1,
			expense: null,
		},
		selectedAccount: {
			index: -1,
			account: null,
		},
		accountTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.level,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.accountsCode,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAdd: {
			code: "",
			name: "",
			description: "",
			isSubLevel: false,
			selectedExpense: {
				index: -1,
				expense: null,
			},
		},
	},
	revenue: {
		filterCode: "",
		filterName: "",
		revenues: [],
		originalRevenus: [],
		selectedRevenue: {
			index: -1,
			revenue: null,
		},
		selectedAccount: {
			index: -1,
			account: null,
		},
		accountTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.level,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.accountsCode,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAdd: {
			code: "",
			name: "",
			description: "",
			isSubLevel: false,
			selectedRevenue: {
				index: -1,
				revenue: null,
			},
		},
	},
}

const ConfigCOAReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_COA_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_ADM_COA_CHART_OF_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				isChartOfAccountsLoading: action.payload.isLoading,
			})
		case SET_ADM_COA_ASSETS:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					assets: action.payload.value,
				},
			})
		case SET_ADM_COA_ASSETS_ORIGINAL:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					originalAssets: action.payload.value,
				},
			})
		case SET_ADM_COA_ASSETS_SELECTED_ASSETS:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					selectedAssets: {
						index: action.payload.index,
						assets: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_ASSETS_FILTER_CODE:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_COA_ASSETS_FILTER_NAME:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_COA_ASSETS_ACCOUNTS:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					accountTable: {
						...state.assets.accountTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_COA_ASSETS_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					accountTable: {
						...state.assets.accountTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_COA_ASSETS_SELECTED_ACCOUNT:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					selectedAccount: {
						index: action.payload.index,
						account: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					modalAdd: {
						...state.assets.modalAdd,
						selectedAssets: {
							index: action.payload.index,
							assets: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_COA_ASSETS_MODAL_ADD_CODE:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					modalAdd: {
						...state.assets.modalAdd,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_ASSETS_MODAL_ADD_NAME:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					modalAdd: {
						...state.assets.modalAdd,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					modalAdd: {
						...state.assets.modalAdd,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL:
			return Object.assign({}, state, {
				assets: {
					...state.assets,
					modalAdd: {
						...state.assets.modalAdd,
						isSubLevel: action.payload.value,
					},
				},
			})

		// Liabilities
		case SET_ADM_COA_LIABILITIES:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					liabilities: action.payload.value,
				},
			})
		case SET_ADM_COA_LIABILITIES_ORIGINAL:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					originalLiabilities: action.payload.value,
				},
			})
		case SET_ADM_COA_LIABILITIES_SELECTED_LIABILITIES:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					selectedLiabilities: {
						index: action.payload.index,
						liabilities: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_FILTER_CODE:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_COA_LIABILITIES_FILTER_NAME:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_COA_LIABILITIES_ACCOUNTS:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					accountTable: {
						...state.liabilities.accountTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					accountTable: {
						...state.liabilities.accountTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_SELECTED_ACCOUNT:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					selectedAccount: {
						index: action.payload.index,
						account: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_MODAL_ADD_SELECTED_LIABILITIES:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					modalAdd: {
						...state.liabilities.modalAdd,
						selectedLiabilities: {
							index: action.payload.index,
							liabilities: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_MODAL_ADD_CODE:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					modalAdd: {
						...state.liabilities.modalAdd,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_MODAL_ADD_NAME:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					modalAdd: {
						...state.liabilities.modalAdd,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_MODAL_ADD_DESCRIPTION:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					modalAdd: {
						...state.liabilities.modalAdd,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_LIABILITIES_MODAL_ADD_SUB_LEVEL:
			return Object.assign({}, state, {
				liabilities: {
					...state.liabilities,
					modalAdd: {
						...state.liabilities.modalAdd,
						isSubLevel: action.payload.value,
					},
				},
			})

		// Equity
		case SET_ADM_COA_EQUITIES:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					equities: action.payload.value,
				},
			})
		case SET_ADM_COA_EQUITY_ORIGINAL:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					originalEquities: action.payload.value,
				},
			})
		case SET_ADM_COA_EQUITY_SELECTED_EQUITY:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					selectedEquity: {
						index: action.payload.index,
						equity: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_EQUITY_FILTER_CODE:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_COA_EQUITY_FILTER_NAME:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_COA_EQUITY_ACCOUNTS:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					accountTable: {
						...state.equity.accountTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_COA_EQUITY_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					accountTable: {
						...state.equity.accountTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_COA_EQUITY_SELECTED_ACCOUNT:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					selectedAccount: {
						index: action.payload.index,
						account: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_EQUITY_MODAL_ADD_SELECTED_EQUITY:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					modalAdd: {
						...state.equity.modalAdd,
						selectedEquity: {
							index: action.payload.index,
							equity: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_COA_EQUITY_MODAL_ADD_CODE:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					modalAdd: {
						...state.equity.modalAdd,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_EQUITY_MODAL_ADD_NAME:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					modalAdd: {
						...state.equity.modalAdd,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_EQUITY_MODAL_ADD_DESCRIPTION:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					modalAdd: {
						...state.equity.modalAdd,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_EQUITY_MODAL_ADD_SUB_LEVEL:
			return Object.assign({}, state, {
				equity: {
					...state.equity,
					modalAdd: {
						...state.equity.modalAdd,
						isSubLevel: action.payload.value,
					},
				},
			})
		// Expense
		case SET_ADM_COA_EXPENSES:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					expenses: action.payload.value,
				},
			})
		case SET_ADM_COA_EXPENSES_ORIGINAL:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					originalExpenses: action.payload.value,
				},
			})
		case SET_ADM_COA_EXPENSES_SELECTED_EXPENSE:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					selectedExpense: {
						index: action.payload.index,
						expense: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_FILTER_CODE:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_COA_EXPENSES_FILTER_NAME:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_COA_EXPENSES_ACCOUNTS:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					accountTable: {
						...state.expense.accountTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					accountTable: {
						...state.expense.accountTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_SELECTED_ACCOUNT:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					selectedAccount: {
						index: action.payload.index,
						account: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_MODAL_ADD_SELECTED_EXPENSE:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					modalAdd: {
						...state.expense.modalAdd,
						selectedExpense: {
							index: action.payload.index,
							expense: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_COA_EXPENSES_MODAL_ADD_CODE:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					modalAdd: {
						...state.expense.modalAdd,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_MODAL_ADD_NAME:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					modalAdd: {
						...state.expense.modalAdd,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_MODAL_ADD_DESCRIPTION:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					modalAdd: {
						...state.expense.modalAdd,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_EXPENSES_MODAL_ADD_SUB_LEVEL:
			return Object.assign({}, state, {
				expense: {
					...state.expense,
					modalAdd: {
						...state.expense.modalAdd,
						isSubLevel: action.payload.value,
					},
				},
			})
		// Revenue
		case SET_ADM_COA_REVENUES:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					revenues: action.payload.value,
				},
			})
		case SET_ADM_COA_REVENUES_ORIGINAL:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					originalRevenues: action.payload.value,
				},
			})
		case SET_ADM_COA_REVENUES_SELECTED_REVENUE:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					selectedRevenue: {
						index: action.payload.index,
						revenue: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_REVENUES_FILTER_CODE:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_COA_REVENUES_FILTER_NAME:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_COA_REVENUES_ACCOUNTS:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					accountTable: {
						...state.revenue.accountTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
						popoverMsg: action.payload.popoverMsg,
					},
				},
			})
		case SET_ADM_COA_REVENUES_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					accountTable: {
						...state.revenue.accountTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_COA_REVENUES_SELECTED_ACCOUNT:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					selectedAccount: {
						index: action.payload.index,
						account: action.payload.selected,
					},
				},
			})
		case SET_ADM_COA_REVENUES_MODAL_ADD_SELECTED_REVENUE:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					modalAdd: {
						...state.revenue.modalAdd,
						selectedRevenue: {
							index: action.payload.index,
							revenue: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_COA_REVENUES_MODAL_ADD_CODE:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					modalAdd: {
						...state.revenue.modalAdd,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_REVENUES_MODAL_ADD_NAME:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					modalAdd: {
						...state.revenue.modalAdd,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_REVENUES_MODAL_ADD_DESCRIPTION:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					modalAdd: {
						...state.revenue.modalAdd,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_COA_REVENUES_MODAL_ADD_SUB_LEVEL:
			return Object.assign({}, state, {
				revenue: {
					...state.revenue,
					modalAdd: {
						...state.revenue.modalAdd,
						isSubLevel: action.payload.value,
					},
				},
			})
		default:
			return state
	}
}

export default ConfigCOAReducer
