import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_UNIT_DISPLAY_ALL_IND,
	SET_ADMIN_UNIT_MODAL_NEW_UNIT_ACTIVE,
	SET_ADMIN_UNIT_MODAL_NEW_UNIT_NAME,
	SET_ADMIN_UNIT_SELECTED_UNIT,
	SET_ADMIN_UNIT_UNITS,
	SET_ADMIN_UNIT_MODAL_NEW_UNIT_DESC,
} from "../../type/type/ADMIN"

export const setAdmUUnits = (units) => {
	return (dispatch, getState) => {
		let filtered = units
			.filter((each) => {
				if (getState().admin.itemConfig.unit.displayInactive) {
					return each.displayName.toLowerCase().includes(getState().admin.itemConfig.unit.searchName.toLowerCase())
				} else {
					return (
						each.active &&
						each.displayName.toLowerCase().includes(getState().admin.itemConfig.unit.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.itemConfig.unit.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.displayName, each.description, each.createdBy.displayName, Utils.formatDate(each.creationDateTime)]
		})

		dispatch({
			type: SET_ADMIN_UNIT_UNITS,
			payload: {
				original: units,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmUDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_UNIT_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmUSelectedUnit = (index, unit) => {
	return {
		type: SET_ADMIN_UNIT_SELECTED_UNIT,
		payload: {
			index: index,
			unit: unit,
		},
	}
}

export const setAdmUModalNUName = (name) => {
	return {
		type: SET_ADMIN_UNIT_MODAL_NEW_UNIT_NAME,
		payload: {
			name: name,
		},
	}
}

export const setAdmUModalNUDescription = (desc) => {
	return {
		type: SET_ADMIN_UNIT_MODAL_NEW_UNIT_DESC,
		payload: {
			desc: desc,
		},
	}
}

export const setAdmUModalNUActive = (isActive) => {
	return {
		type: SET_ADMIN_UNIT_MODAL_NEW_UNIT_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}
