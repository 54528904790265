import { ResourceAssistance } from "~/i18n"
import {
	ADD_PR_NEW_ITEM,
	APPROVE,
	CHANGE_DATE,
	LOAD_ITEMS,
	LOAD_OUT_OF_STOCK_INVENTORY,
	LOAD_PRS,
	LOAD_PR_TYPES,
	RESET,
	SELECT_INVENTORY,
	SELECT_NEW_ITEM,
	SELECT_PR,
	SELECT_PR_DETAIL,
	SELECT_PR_ITEM,
	SELECT_TYPE,
	SET_COMMENT,
	SET_DISPLAY_ALL,
	SET_INVENTORY_PROPERTY,
	SET_ITEM_LOADING,
	SET_PROPERTY,
	SET_PROPERTY_AMOUNT,
	SET_PROPERTY_DOSE,
	SET_PROPERTY_FORM,
	SET_PROPERTY_LABEL,
	SET_PROPERTY_SUPPLIER,
	SET_PROPERTY_UNIT,
	SET_PROPERTY_UNIT_PRICE,
	SET_PR_APPROVER_APPROVED,
	SET_PR_APPROVE_APPROVE_IND,
	SET_PR_APPROVE_EVENT,
	SET_PR_LOADING,
	SET_PR_LOCATIONS,
	SET_PR_LOCATION_BRANCH,
	SET_PR_LOCATION_LOADING,
	SET_PR_LOCATION_ORG,
	SET_PR_MIN_STOCK_SEARCH_CODE,
	SET_PR_MIN_STOCK_SEARCH_NAME,
	SET_PR_SEARCH_LOCATION,
	SET_PR_SELECTED_BRANCH,
	SET_PR_SELECTED_ORG,
	SET_REASON,
	SET_SEARCH_NUM,
	SET_SELECTED_INVENTORIES,
	SET_TYPE_LOADING,
	SET_PR_MIN_STOCK_LAST_PURCHASED_INFO,
	SET_PR_NEW_PR_LAST_PURCHASED_INFO,
	SET_PR_NEW_PR_SEARCH_NAME,
} from "../type"

let DATE = new Date()
const init = {
	searchDate: {
		startDate: new Date(DATE.getFullYear(), DATE.getMonth(), 1),
		endDate: new Date(DATE.getFullYear(), DATE.getMonth() + 1, 0, 23, 59, 59),
	},
	searchNum: "",
	searchLocation: "",
	comment: "",
	reason: "",
	isLoading: false,
	isDisplayAll: false,
	prs: [],
	filtered: [],
	type: [],
	location: {
		locations: [],
		orgs: [],
		branches: [],
		isLoading: false,
		selectedOrg: {
			org: null,
			index: -1,
		},
		selectedBranch: {
			branch: null,
			index: -1,
		},
	},
	prTableData: {
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.hashSymbol,
			ResourceAssistance.Message.label,
			ResourceAssistance.Message.location,
			ResourceAssistance.Message.requesterCheck,
			ResourceAssistance.Message.inspectorCheck,
			ResourceAssistance.Message.approverCheck,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	prItemsTableData: {
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.current,
			ResourceAssistance.Message.min,
			ResourceAssistance.Message.max,
			ResourceAssistance.Message.amount,
			"",
		],
		body: [],
		pendingItems: [],
	},
	prSelectedItemTableData: {
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.current,
			ResourceAssistance.Message.min,
			ResourceAssistance.Message.max,
			ResourceAssistance.Message.amount,
			"",
		],
		body: [],
		selectedItems: [],
	},
	approve: {
		isApprove: false,
		event: [],
	},
	approver: {
		[ResourceAssistance.PurchaseRequest.RightPannel.Value.isRequesterApproved]: false,
		[ResourceAssistance.PurchaseRequest.RightPannel.Value.isInspectorApproved]: false,
		[ResourceAssistance.PurchaseRequest.RightPannel.Value.isApproverApproved]: false,
	},
	newPr: {
		searchName: "",
		lastPurchasedFrom: "",
		lastPurchasedDate: "",
		original: [],
		filtered: [],
		items: {
			header: [ResourceAssistance.Message.name],
			body: [],
			isLoading: false,
		},
		selectedItems: {
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.amount,
			],
			body: [],
			newItems: {},
		},
		suppliers: [],
		forms: [],
		doses: [],
		units: [],
		unitPrices: [],
		selectedItem: {
			index: -1,
		},
		selectedForm: {
			id: 0,
		},
		selectedDose: {
			id: "",
		},
		selectedUnit: {
			id: 0,
		},
		selectedSupplier: {
			id: 0,
		},
		selectedUnitPrice: {
			id: "",
		},
		selectedAmount: "",
		selectedLabel: "",
	},
	minStock: {
		original: [],
		filtered: [],
		searchCode: "",
		searchName: "",
		lastPurchasedFrom: "",
		lastPurchasedDate: "",
		selectedLabel: "",
		selectedAmount: "",
		inventories: {
			header: [
				ResourceAssistance.Message.org,
				ResourceAssistance.Message.location,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.current,
				ResourceAssistance.Message.min,
				ResourceAssistance.Message.max,
			],
			body: [],
		},
		selectedInventories: {
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.amount,
			],
			body: [],
			inventories: [],
		},
		selectedInventory: {
			index: -1,
			inventory: null,
		},
	},
	selectedPR: {
		index: -1,
		pr: null,
	},
	selectedType: {
		index: 0,
		type: null,
	},
	selectedPrItem: {
		index: -1,
		item: null,
	},
}

const purchaseRequestReducer = (state = init, action) => {
	switch (action.type) {
		case ADD_PR_NEW_ITEM:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					selectedItems: {
						...state.newPr.selectedItems,
						body: action.payload.body,
						newItems: action.payload.items,
					},
				},
			})

		case APPROVE:
			return Object.assign({}, state, {
				selectedPR: {
					...state.selectedPR,
					pr: {
						...state.selectedPR.pr,
						[action.payload.approverK]: action.payload.approver,
						[action.payload.isApproved]: action.payload.approve,
						[action.payload.approvedDate]: action.payload.date,
					},
				},
				prs: state.prs.map((order, index) => {
					if (order.id === state.selectedPR.pr.id) {
						return {
							...order,
							[action.payload.approverK]: action.payload.approver,
							[action.payload.isApproved]: action.payload.approve,
							[action.payload.approvedDate]: action.payload.date,
						}
					}
					return order
				}),
				//update approver
				approver: {
					...state.approver,
					[action.payload.isApproved]: action.payload.approve,
				},
			})

		case SET_PR_APPROVER_APPROVED:
			return Object.assign({}, state, {
				approver: {
					...state.approver,
					[action.payload.approver]: action.payload.isApproved,
				},
			})

		case CHANGE_DATE:
			return Object.assign({}, state, {
				searchDate: {
					startDate: action.payload.startDate,
					endDate: action.payload.endDate,
				},
			})

		case LOAD_PR_TYPES:
			return Object.assign({}, state, {
				type: action.payload.types,
			})

		case LOAD_PRS:
			return Object.assign({}, state, {
				prs: action.payload.original,
				filtered: action.payload.filtered,
				prTableData: {
					...state.prTableData,
					body: action.payload.prs,
					rowColor: action.payload.rowColor,
				},
			})

		case LOAD_ITEMS:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					original: action.payload.original,
					filtered: action.payload.filtered,
					items: {
						...state.newPr.items,
						body: action.payload.tableBody,
					},
				},
			})

		case LOAD_OUT_OF_STOCK_INVENTORY:
			return Object.assign({}, state, {
				minStock: {
					...state.minStock,
					original: action.payload.original,
					filtered: action.payload.filtered,
					inventories: {
						...state.minStock.inventories,
						body: action.payload.body,
					},
				},
			})

		case RESET:
			return Object.assign({}, init, {})

		case SELECT_TYPE:
			return Object.assign({}, state, {
				selectedType: {
					index: action.payload.index,
					type: action.payload.type,
				},
			})

		case SELECT_PR:
			let approverObj = {}
			Object.keys(state.approver).map((key) => (approverObj[key] = action.payload.pr ? action.payload.pr[key] : false))

			return Object.assign({}, state, {
				comment: action.payload.note,
				reason: action.payload.reason,
				selectedPR: {
					...state.selectedPR,
					index: action.payload.index,
					pr: action.payload.pr ? action.payload.pr : null,
				},
				prItemsTableData: {
					...state.prItemsTableData,
					body: action.payload.pendingItems,
					pendingItems: action.payload.pending,
				},
				prSelectedItemTableData: {
					...state.prSelectedItemTableData,
					body: action.payload.selectedItems,
					selectedItems: action.payload.selected,
				},
				approver: approverObj,
			})

		case SELECT_PR_DETAIL:
			return Object.assign({}, state, {
				selectedPR: {
					...state.selectedPR,
					pr: {
						...state.selectedPR.pr,
						items: {
							...state.selectedPR.pr.items,
							[action.payload.index]: {
								...state.selectedPR.pr.items[action.payload.index],
								[action.payload.cIndex]: action.payload.cValue,
							},
						},
					},
				},
				prItemsTableData: {
					...state.prItemsTableData,
					body: state.prItemsTableData.body.map((row, key) => {
						if (key === action.payload.rIndex) {
							row[action.payload.cIndex] = action.payload.cValue
						}
						return row
					}),
				},
			})

		case SELECT_NEW_ITEM:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					selectedItem: {
						...state.newPr.selectedItem,
						index: action.payload.index,
					},

					//reset
					suppliers: [],
					selectedSupplier: {
						...state.newPr.selectedSupplier,
						id: 0,
					},
					forms: [],
					selectedForm: {
						...state.newPr.selectedForm,
						id: 0,
					},
					doses: [],
					selectedDose: {
						...state.newPr.selectedDose,
						id: "",
					},
					units: [],
					selectedUnit: {
						...state.newPr.selectedUnit,
						id: 0,
					},
					unitPrices: [],
					selectedUnitPrice: {
						...state.newPr.selectedUnitPrice,
						id: "",
					},
				},
			})

		case SELECT_PR_ITEM:
			return Object.assign({}, state, {
				selectedPrItem: {
					...state.selectedPrItem,
					index: action.payload.index,
					item: action.payload.item,
				},
			})

		case SELECT_INVENTORY:
			return Object.assign({}, state, {
				minStock: {
					...state.minStock,
					selectedInventory: {
						...state.selectedInventory,
						index: action.payload.index,
						inventory: action.payload.inventory,
					},
				},
			})

		case SET_PROPERTY:
			switch (action.payload.subType) {
				case SET_PROPERTY_LABEL:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedLabel: action.payload.data,
						},
					})

				case SET_PROPERTY_FORM:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedForm: {
								...state.newPr.selectedForm,
								id: action.payload.id,
							},
							forms: action.payload.data,
						},
					})

				case SET_PROPERTY_DOSE:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedDose: {
								...state.newPr.selectedDose,
								id: action.payload.id,
							},
							doses: action.payload.data,
						},
					})

				case SET_PROPERTY_UNIT:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedUnit: {
								...state.newPr.selectedUnit,
								id: action.payload.id,
							},
							units: action.payload.data,
						},
					})

				case SET_PROPERTY_SUPPLIER:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedSupplier: {
								...state.newPr.selectedSupplier,
								id: action.payload.id,
							},
							suppliers: action.payload.data,
						},
					})

				case SET_PROPERTY_UNIT_PRICE:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedUnitPrice: {
								...state.newPr.selectedUnitPrice,
								id: action.payload.id,
							},
							unitPrices: action.payload.data,
						},
					})

				case SET_PROPERTY_AMOUNT:
					return Object.assign({}, state, {
						newPr: {
							...state.newPr,
							selectedAmount: action.payload.data,
						},
					})

				default:
					return state
			}

		case SET_INVENTORY_PROPERTY:
			switch (action.payload.subType) {
				case SET_PROPERTY_LABEL:
					return Object.assign({}, state, {
						minStock: {
							...state.minStock,
							selectedLabel: action.payload.data,
						},
					})

				case SET_PROPERTY_AMOUNT:
					return Object.assign({}, state, {
						minStock: {
							...state.minStock,
							selectedAmount: action.payload.data,
						},
					})

				default:
					return state
			}

		case SET_SELECTED_INVENTORIES:
			return Object.assign({}, state, {
				minStock: {
					...state.minStock,
					selectedInventories: {
						...state.minStock.selectedInventories,
						body: action.payload.body,
						inventories: action.payload.inventories,
					},
				},
			})

		case SET_COMMENT:
			return Object.assign({}, state, {
				comment: action.payload.text,
			})

		case SET_REASON:
			return Object.assign({}, state, {
				reason: action.payload.text,
			})

		case SET_SEARCH_NUM:
			return Object.assign({}, state, {
				searchNum: action.payload.searchNum,
			})

		case SET_PR_SEARCH_LOCATION:
			return Object.assign({}, state, {
				searchLocation: action.payload.searchLocation,
			})

		case SET_PR_LOADING:
			return Object.assign({}, state, {
				prTableData: {
					...state.prTableData,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_ITEM_LOADING:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					items: {
						...state.newPr.items,
						isLoading: action.payload.isLoading,
					},
				},
			})

		case SET_TYPE_LOADING:
			return Object.assign({}, state, {
				isLoading: action.payload.isLoading,
			})

		case SET_DISPLAY_ALL:
			return Object.assign({}, state, {
				isDisplayAll: action.payload.isDisplayAll,
			})

		case SET_PR_LOCATIONS:
			return Object.assign({}, state, {
				location: {
					...state.location,
					locations: action.payload.locations,
				},
			})

		case SET_PR_LOCATION_ORG:
			return Object.assign({}, state, {
				location: {
					...state.location,
					orgs: action.payload.orgs,
				},
			})

		case SET_PR_LOCATION_BRANCH:
			return Object.assign({}, state, {
				location: {
					...state.location,
					branches: action.payload.branches,
				},
			})

		case SET_PR_LOCATION_LOADING: {
			return Object.assign({}, state, {
				location: {
					...state.location,
					isLoading: action.payload.isLoading,
				},
			})
		}

		case SET_PR_SELECTED_ORG: {
			return Object.assign({}, state, {
				location: {
					...state.location,
					selectedOrg: {
						org: action.payload.org,
						index: action.payload.index,
					},
				},
			})
		}

		case SET_PR_SELECTED_BRANCH: {
			return Object.assign({}, state, {
				location: {
					...state.location,
					selectedBranch: {
						branch: action.payload.branch,
						index: action.payload.index,
					},
				},
			})
		}

		case SET_PR_APPROVE_EVENT: {
			return Object.assign({}, state, {
				approve: {
					...state.approve,
					event: action.payload.event,
				},
			})
		}

		case SET_PR_APPROVE_APPROVE_IND: {
			return Object.assign({}, state, {
				approve: {
					...state.approve,
					isApprove: action.payload.isApprove,
				},
			})
		}

		case SET_PR_MIN_STOCK_SEARCH_NAME:
			return Object.assign({}, state, {
				minStock: {
					...state.minStock,
					searchName: action.payload.text,
				},
			})

		case SET_PR_MIN_STOCK_SEARCH_CODE:
			return Object.assign({}, state, {
				minStock: {
					...state.minStock,
					searchCode: action.payload.text,
				},
			})

		case SET_PR_MIN_STOCK_LAST_PURCHASED_INFO:
			return Object.assign({}, state, {
				minStock: {
					...state.minStock,
					lastPurchasedFrom: action.payload.lastPurchasedFrom,
					lastPurchasedDate: action.payload.lastPurchasedDate,
				},
			})

		case SET_PR_NEW_PR_LAST_PURCHASED_INFO:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					lastPurchasedFrom: action.payload.lastPurchasedFrom,
					lastPurchasedDate: action.payload.lastPurchasedDate,
				},
			})

		case SET_PR_NEW_PR_SEARCH_NAME:
			return Object.assign({}, state, {
				newPr: {
					...state.newPr,
					searchName: action.payload.text,
				},
			})

		default:
			return state
	}
}

export default purchaseRequestReducer
