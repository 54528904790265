import React, { Component } from "react"
import { Alert, Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { LOGIN, LOGIN_ERROR } from "~/redux/type"
import { loginAction } from "~/redux/action"
import LoginForm from "~/view/component/LoginForm"
import "./login.css"

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Login",
		}

		this.handleLogin = this.handleLogin.bind(this)
	}

	handleChange = (event) => {
		event.preventDefault()
		const { name, value } = event.target
		// this.setState({ [name]: value })
		this.props.loginAction(name, value)
	}

	handleLogin(event) {
		event.preventDefault()
		// const {
		//     [ResourceAssistance.LoginForm.username]: name,
		//     [ResourceAssistance.LoginForm.password]: password
		// } = this.state

		//form format
		// const formData = new FormData()
		// formData.append(ResourceAssistance.LoginForm.username, name)
		// formData.append(ResourceAssistance.LoginForm.password,
		//     this.state[ResourceAssistance.LoginForm.password])

		//json format
		// var raw = JSON.stringify({
		// 	[ResourceAssistance.LoginForm.username]: this.props.login.username,
		// 	[ResourceAssistance.LoginForm.password]: this.props.login.password,
		// })

		let params = {
			method: "GET",
			url: ResourceAssistance.Url.auth.login,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				username: this.props.login.username,
				password: this.props.login.password,
			},
		}

		let callback = (res) => {
			this.props.loginAction(LOGIN, true, res.data.user)
			this.props.history.push(ResourceAssistance.Path.home)
		}

		let errorHandler = (error) => {
			this.props.loginAction(LOGIN_ERROR, translate(ResourceAssistance.Message.loginError))
		}

		axios(params, callback, errorHandler)
	}

	render() {
		const props = {
			handleSubmit: this.handleLogin,
			handleChange: this.handleChange,
		}

		return (
			<Container id={this.state.componentName}>
				<Row className={ResourceAssistance.CSS.JustifyContentMdCenter} md={6}>
					<Col>
						<LoginForm {...props} />
					</Col>
				</Row>
				<Row className={ResourceAssistance.mulCSS(ResourceAssistance.CSS.JustifyContentMdCenter, ResourceAssistance.CSS.marginTop)}>
					<Col />
					<Col md="auto">{this.props.login.displayError && <Alert variant="danger">{this.props.login.errorMsg}</Alert>}</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default connect(mapStateToProps, { loginAction })(Login)
