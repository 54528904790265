import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { SET_INVENTORY_LOCS } from "../../type"
import {
	SET_INVENTORY_BRANCHES,
	SET_INVENTORY_DISPLAY_MIN_STOCK_CHECKED,
	SET_INVENTORY_INVENTORIES,
	SET_INVENTORY_INVENTORY_DETAILS,
	SET_INVENTORY_LOTS,
	SET_INVENTORY_LOT_EXPIRED_DATE,
	SET_INVENTORY_ORGS,
	SET_INVENTORY_PENDING_ORDER,
	SET_INVENTORY_PENDING_SUPPLIERS,
	SET_INVENTORY_SEARCH_CODE_TEXT,
	SET_INVENTORY_SEARCH_NAME_TEXT,
	SET_INVENTORY_SELECTED_BRANCH,
	SET_INVENTORY_SELECTED_INVENTORY,
	SET_INVENTORY_SELECTED_INVENTORY_DETAIL,
	SET_INVENTORY_SELECTED_ORG,
	SET_INVENTORY_SELECTED_PENDING_SUPPLIER,
	SET_INVENTORY_SELECTED_SUPPLIER,
	SET_INVENTORY_SELECTED_TYPE,
	SET_INVENTORY_SUPPLIERS,
	SET_INVENTORY_SUPPLIER_SEARCH_TEXT,
	SET_INVENTORY_TYPES,
	SET_INVENTORY_DISPLAY_EXPIRE_CHECKED,
	SET_INVENTORY_NEW_INVENTORY_SELECTED_TYPE,
} from "../../type/type/INVENTORY"

export const setInventoryLocs = (locs) => {
	return (dispatch) => {
		dispatch({
			type: SET_INVENTORY_LOCS,
			payload: {
				locs: locs,
			},
		})
		return Promise.resolve()
	}
}

export const setInventoryOrgs = (locs) => {
	let orgs = locs.filter((loc) => loc.code.displayName === "ORG" && loc.active)
	return (dispatch) => {
		dispatch({
			type: SET_INVENTORY_ORGS,
			payload: {
				orgs: orgs,
			},
		})
		return Promise.resolve()
	}
}

export const setInventoryBranches = (locs) => {
	let branches = locs.filter((loc) => loc.code.displayName === "BRANCH" && loc.active)
	return {
		type: SET_INVENTORY_BRANCHES,
		payload: {
			branches: branches,
		},
	}
}

export const setInventoryTypes = (types) => {
	types.sort((a, b) => Utils.sort(a.displayName, b.displayName))
	return {
		type: SET_INVENTORY_TYPES,
		payload: {
			types: types,
		},
	}
}

export const setInventorySelectedType = (index, type) => {
	return {
		type: SET_INVENTORY_SELECTED_TYPE,
		payload: {
			type: type,
			index: index,
		},
	}
}

export const setInventoryInventories = (locs) => {
	let inventories = locs.filter((loc) => loc.code.displayName === "INVENTORY" && loc.active)
	return {
		type: SET_INVENTORY_INVENTORIES,
		payload: {
			inventories: inventories,
		},
	}
}

export const setInventoryInventoryDetails = (inventoryDetails) => {
	return (dispatch, getState) => {
		let filtered = inventoryDetails
			.filter((inventoryDetail) => {
				return (
					inventoryDetail.code
						.trim()
						.toLowerCase()
						.includes(getState().inventory.searchCodeText.trim().toLowerCase()) &&
					inventoryDetail.displayName
						.trim()
						.toLowerCase()
						.includes(getState().inventory.searchNameText.trim().toLowerCase()) &&
					getState().inventory.selectedType.type &&
					inventoryDetail.typeId === getState().inventory.selectedType.type.id
				)
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		//set pending pr info
		filtered = filtered.map((inventoryDetail) => {
			let newPr = {}
			getState().inventory.selectedBranch.loc.prs.every((pr) => {
				let itemIdx = pr.items.findIndex(
					(item) =>
						item.displayName.trim().toLowerCase() === inventoryDetail.displayName.trim().toLowerCase() &&
						item.brand.trim().toLowerCase() === inventoryDetail.brand.trim().toLowerCase() &&
						item.supplierName.trim().toLowerCase() ===
							(inventoryDetail.supplierName
								? inventoryDetail.supplierName.trim().toLowerCase()
								: inventoryDetail.supplier.displayName.trim().toLowerCase())
				)
				if (itemIdx > -1) {
					let foundItem = pr.items[itemIdx]
					Object.assign(newPr, {
						id: pr.id,
						po: pr.po ? pr.po : {},
						minQtyPerOrder: foundItem.minQtyPerOrder,
						amount: foundItem.amount,
					})
					return false
				}
				return true
			})

			let inStockItem = getState().inventory.selectedBranch.loc.inStockItems.find(
				(inStockItem) =>
					inStockItem.code.trim().toLowerCase() === inventoryDetail.code.trim().toLowerCase() &&
					inStockItem.displayName.trim().toLowerCase() === inventoryDetail.displayName.trim().toLowerCase() &&
					inStockItem.brand.trim().toLowerCase() === inventoryDetail.brand.trim().toLowerCase() &&
					inStockItem.supplierName.trim().toLowerCase() ===
						(inventoryDetail.supplierName
							? inventoryDetail.supplierName.trim().toLowerCase()
							: inventoryDetail.supplier.displayName.trim().toLowerCase())
			)

			return {
				...inventoryDetail,
				lots: inStockItem ? inStockItem.lots : [],
				pr: newPr,
			}
		})

		if (getState().inventory.isDisplayMinStockChecked) {
			filtered = filtered.filter((inventoryDetail) =>
				inventoryDetail.lots
					.reduce((subTotal, lot) => {
						return subTotal.plus(lot.current)
					}, Utils.BigNumber(0))
					.lte(inventoryDetail.min)
			)
		}

		if (getState().inventory.isDisplayExpireChecked) {
			filtered = filtered.filter((inventoryDetail) =>
				inventoryDetail.lots.some((lot) =>
					Utils.BigNumber(lot.expiredDateTime).lte(getState().inventory.lotExpiredDate.getTime())
				)
			)
		}

		let body = filtered.map((inventoryDetail) => {
			return [
				inventoryDetail.code,
				inventoryDetail.displayName,
				inventoryDetail.brand,
				inventoryDetail.unit,
				Utils.formatNumWithComma(
					inventoryDetail.lots.reduce((total, lot) => total.plus(lot.current), Utils.BigNumber(0)).toNumber()
				),
				Utils.formatNumWithComma(inventoryDetail.min),
				Utils.formatNumWithComma(inventoryDetail.max),
			]
		})

		dispatch({
			type: SET_INVENTORY_INVENTORY_DETAILS,
			payload: {
				inventoryDetails: inventoryDetails,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setSelectedOrg = (index, loc) => {
	return {
		type: SET_INVENTORY_SELECTED_ORG,
		payload: {
			index: index,
			loc: loc,
		},
	}
}

export const setSelectedBranch = (index, loc) => {
	return {
		type: SET_INVENTORY_SELECTED_BRANCH,
		payload: {
			index: index,
			loc: loc,
		},
	}
}

export const setSelectedInventory = (index, loc) => {
	return {
		type: SET_INVENTORY_SELECTED_INVENTORY,
		payload: {
			index: index,
			loc: loc,
		},
	}
}

export const setInventorySuppliers = (items) => {
	return (dispatch, getState) => {
		let itemSupplierRelps = []
		items.forEach((item) => {
			item.itemSupplierRelps.forEach((itemSupplierRelp) => {
				itemSupplierRelps.push({
					...itemSupplierRelp,
					typeId: item.type.id,
				})
			})
		})

		let selectedInventoryDetails = getState().inventory.inventories.inventories.reduce((object, item) => {
			return [...object, item.itemSupplierRelpId]
		}, [])

		itemSupplierRelps.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		let filtered = itemSupplierRelps.filter(
			(itemSupplierRelp) =>
				getState().inventory.newInventory.selectedType.type &&
				itemSupplierRelp.typeId === getState().inventory.newInventory.selectedType.type.id &&
				itemSupplierRelp.displayName.toLowerCase().includes(getState().inventory.supplier.searchText.toLowerCase()) &&
				!selectedInventoryDetails.includes(itemSupplierRelp.id)
		)

		let body = filtered.map((itemSupplierRelp) => {
			return [
				itemSupplierRelp.code,
				itemSupplierRelp.displayName,
				itemSupplierRelp.brand,
				itemSupplierRelp.form,
				itemSupplierRelp.dose,
				itemSupplierRelp.unit,
			]
		})

		dispatch({
			type: SET_INVENTORY_SUPPLIERS,
			payload: {
				items: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setInventorySupplierSearchText = (text) => {
	return {
		type: SET_INVENTORY_SUPPLIER_SEARCH_TEXT,
		payload: {
			text: text,
		},
	}
}

export const setInventorySearchCodeText = (text) => {
	return {
		type: SET_INVENTORY_SEARCH_CODE_TEXT,
		payload: {
			text: text,
		},
	}
}

export const setInventorySearchNameText = (text) => {
	return {
		type: SET_INVENTORY_SEARCH_NAME_TEXT,
		payload: {
			text: text,
		},
	}
}

export const setInventorySelectedSupplier = (index, supplier) => {
	return {
		type: SET_INVENTORY_SELECTED_SUPPLIER,
		payload: {
			supplier: supplier,
			index: index,
		},
	}
}

export const setInventorySelectedPendingSupplier = (index, supplier) => {
	return {
		type: SET_INVENTORY_SELECTED_PENDING_SUPPLIER,
		payload: {
			supplier: supplier,
			index: index,
		},
	}
}

export const setInventoryPendingSuppliers = (itemSupplierRelps) => {
	let body = itemSupplierRelps
		.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		.map((itemSupplierRelp) => {
			return [
				itemSupplierRelp.code,
				itemSupplierRelp.displayName,
				itemSupplierRelp.brand,
				itemSupplierRelp.form,
				itemSupplierRelp.dose,
				itemSupplierRelp.unit,
				itemSupplierRelp.min,
				itemSupplierRelp.max,
			]
		})
	return {
		type: SET_INVENTORY_PENDING_SUPPLIERS,
		payload: {
			suppliers: itemSupplierRelps,
			body: body,
		},
	}
}

export const setInventorySelectedInventoryDetail = (index, inventoryDetail) => {
	return {
		type: SET_INVENTORY_SELECTED_INVENTORY_DETAIL,
		payload: {
			detail: inventoryDetail,
			index,
		},
	}
}

export const setInventoryLotExpiredDate = (date) => {
	return {
		type: SET_INVENTORY_LOT_EXPIRED_DATE,
		payload: {
			date: date,
		},
	}
}

export const setInventoryDisplayExpireChecked = (isChecked) => {
	return {
		type: SET_INVENTORY_DISPLAY_EXPIRE_CHECKED,
		payload: {
			isChecked: isChecked,
		},
	}
}

export const setInventoryDisplayMinStockChecked = (isChecked) => {
	return {
		type: SET_INVENTORY_DISPLAY_MIN_STOCK_CHECKED,
		payload: {
			isChecked: isChecked,
		},
	}
}

export const setInventoryLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))
		let body = filtered.map((lot) => {
			return [
				lot.number,
				lot.current,
				lot.unit,
				new Date(lot.expiredDateTime).toLocaleDateString(),
				new Date(lot.lastReceivedDateTime).toLocaleString(),
			]
		})

		let color = filtered.map((lot) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[
					Utils.BigNumber(lot.expiredDateTime).lte(getState().inventory.lotExpiredDate.getTime()),
					ResourceAssistance.CSS.Color.red,
				],
				[false, ""],
			]
		})

		dispatch({
			type: SET_INVENTORY_LOTS,
			payload: {
				body: body,
				colColor: color,
				lots: lots,
			},
		})
		return Promise.resolve()
	}
}

export const setInventoryPendingOrder = (pr) => {
	return {
		type: SET_INVENTORY_PENDING_ORDER,
		payload: {
			prId: pr.id ? pr.id : "",
			dateOfPo: pr.po ? Utils.formatDate(pr.po.approvalApprovedDateTime) : "",
			package: pr.minQtyPerOrder ? Utils.formatNumWithComma(pr.minQtyPerOrder) : "",
			amount: pr.amount ? pr.amount : "",
		},
	}
}

export const setInventoryNewInventorySelectedType = (index, type) => {
	return {
		type: SET_INVENTORY_NEW_INVENTORY_SELECTED_TYPE,
		payload: {
			index: index,
			type: type,
		},
	}
}
