import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect, Route, Switch } from "react-router-dom"
import { ResourceAssistance } from "~/i18n"
import { loginAction } from "~/redux/action"
import { ProtectedRoute } from "../../router/ProtectedRoute"
import ContextMenu from "../context-menu/ContextMenu"
import HomeRedirect from "./homePage/HomeRedirect"
import Login from "./login/Login"
import "./main.css"
import Modal from "./modals/Modal"

class Main extends Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Main",
		}
	}

	transit() {
		return this.props.login.isLogin ? <Redirect to={`${this.props.match.path}`} /> : <Route path="/" exact component={Login} />
	}

	render() {
		return (
			<div id={this.state.componentName}>
				<Switch>
					<Route exact path={ResourceAssistance.Path.login} component={Login} />
					<Route>
						<ProtectedRoute component={HomeRedirect} />
						<ProtectedRoute component={Modal} />
						<ProtectedRoute component={ContextMenu} />
					</Route>
					<Redirect to={ResourceAssistance.Path.notFoundPage} />
				</Switch>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default connect(mapStateToProps, { loginAction })(Main)
