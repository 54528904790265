import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue } from "~/redux/action"
import { SET_HEADER, SET_PHARMACY_LOCATION, SET_PHARMACY_LOCATION_DISPLAY } from "~/redux/type"
import PharmacyModalLocationDetails from "./PharmacyModalLocationDetails"

class PharmacyModalLocation extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyModalLocation",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PHARMACY_LOCATION_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return !this.props.pharmacy.modalLocation.selectedDepartment.department
	}

	onClose() {
		this.reset()
		this.props.history.push(ResourceAssistance.Path.home)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		this.props.setValue(
			SET_HEADER,
			this.props.common.header
				.concat("/", this.props.pharmacy.modalLocation.selectedOrg.org.displayName)
				.concat("/", this.props.pharmacy.modalLocation.selectedBranch.branch.displayName)
				.concat("/", this.props.pharmacy.modalLocation.selectedDepartment.department.displayName)
		)
		this.props.setValue(SET_PHARMACY_LOCATION, this.props.pharmacy.modalLocation.selectedDepartment.department)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.location)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PharmacyModalLocationDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPharmacyLocationDisplay,
	common: state.common,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalLocation)
