import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class AdmIModalInfoDetails extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }} column={5}>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.code)}>
							{this.props.configItem.selectedItem.item.code}
						</Descriptions.Item>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.genericName)}>
							{this.props.configItem.selectedItem.item.displayName}
						</Descriptions.Item>
						<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.brandName)}>
							{this.props.configItem.selectedItem.item.brand}
						</Descriptions.Item>
						<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.subunit)}>
							{this.props.configItem.selectedItem.item.unit.displayName}
						</Descriptions.Item>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.medicationUsage)}>
							{this.props.configItem.selectedItem.item.medicationUsage}
						</Descriptions.Item>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.healthCarePlan)}>
							{this.props.configItem.selectedItem.item.healthcarePlan}
						</Descriptions.Item>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.chrgItem)}>
							{this.props.configItem.selectedItem.item.chrgItem}
						</Descriptions.Item>
						<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.adpType)}>
							{this.props.configItem.selectedItem.item.adpType}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.governmentCode)}>
							{Utils.formatNumWithComma(this.props.configItem.selectedItem.item.governmentCode)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.tmltTmtCode)}>
							{Utils.formatNumWithComma(this.props.configItem.selectedItem.item.tmltTmtCode)}
						</Descriptions.Item>
						<Descriptions.Item span={1}></Descriptions.Item>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.supplier)}>
							{this.props.configItem.selectedItem.item.supplier.displayName}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.minQtyPerOrder)}>
							{this.props.configItem.selectedItem.item.minQtyPerOrder}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.bonus)}>
							{Utils.formatNumWithComma(this.props.configItem.selectedItem.item.bonus)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.pricePerOrder)}>
							{Utils.formatNumWithComma(Utils.BigNumber(this.props.configItem.selectedItem.item.pricePerUnit).toFixed(4))}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.discountWithSymbol)}>
							{this.props.configItem.selectedItem.item.discount}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.taxRateWithSymbol)}>
							{this.props.configItem.selectedItem.item.tax.taxRate}
						</Descriptions.Item>
						<Descriptions.Item span={2}></Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.pricePerUnit)}>
							{Utils.formatNumWithComma(Utils.BigNumber(this.props.configItem.selectedItem.item.sellPricePerUnit).toFixed(4))}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.fsCode)}>
							{this.props.configItem.selectedItem.item.fsCode}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.categoryNum)}>
							{this.props.configItem.selectedItem.item.categoryNum}
						</Descriptions.Item>
						<Descriptions.Item span={5} label={translate(ResourceAssistance.Message.by)}>
							{this.props.configItem.selectedItem.item.createdBy.displayName}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	configItem: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmIModalInfoDetails)
