import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { IoMdDoneAll } from "react-icons/io"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFOModalNewPendingPaymentPlans } from "~/redux/action"

class FOModalNewPaymentPlanAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	isDownBtnDisabled() {
		return (
			!this.props.FO.modalNew.selectedPaymentPlan.paymentPlan ||
			!this.props.FO.modalNew.selectedPaymentType.type ||
			!_.isEqual(
				this.props.FO.modalNew.selectedPaymentPlan.paymentPlan.paymentMethod,
				this.props.FO.modalNew.selectedPaymentType.type.displayName
			) ||
			(this.props.FO.modalNew.selectedPaymentType.type.bankAccount &&
				!(
					this.props.FO.modalNew.selectedServiceType.type &&
					this.props.FO.modalNew.selectedBank.bank &&
					_.isEqual(
						this.props.FO.modalNew.selectedPaymentPlan.paymentPlan.bankCode,
						this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].code
					) &&
					_.isEqual(
						this.props.FO.modalNew.selectedPaymentPlan.paymentPlan.bankBranchCode,
						this.props.FO.modalNew.selectedBank.bank.code
					) &&
					_.isEqual(
						this.props.FO.modalNew.selectedPaymentPlan.paymentPlan.bankAccount,
						this.props.FO.modalNew.selectedBank.bank.account
					)
				))
		)
	}

	isSelectAllDisabled() {
		return (
			this.props.FO.modalNew.paymentPlanTable.filtered.length === 0 ||
			!this.props.FO.modalNew.selectedPaymentType.type ||
			this.props.FO.modalNew.paymentPlanTable.filtered.some(
				(each) =>
					!_.isEqual(each.paymentMethod, this.props.FO.modalNew.selectedPaymentType.type.displayName) ||
					(this.props.FO.modalNew.selectedPaymentType.type.bankAccount &&
						!(
							this.props.FO.modalNew.selectedServiceType.type &&
							this.props.FO.modalNew.selectedBank.bank &&
							_.isEqual(
								each.bankCode,
								this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].code
							) &&
							_.isEqual(each.bankBranchCode, this.props.FO.modalNew.selectedBank.bank.code) &&
							_.isEqual(each.bankAccount, this.props.FO.modalNew.selectedBank.bank.account)
						))
			)
		)
	}

	onDownBtn() {
		let selectedPaymentPlan = Object.assign({}, this.props.FO.modalNew.selectedPaymentPlan.paymentPlan)
		let pendingPaymentPlans = Array.from(this.props.FO.modalNew.pendingPaymentPlanTable.original)
		pendingPaymentPlans.push(selectedPaymentPlan)
		this.props.setFOModalNewPendingPaymentPlans(pendingPaymentPlans)
	}

	onUpBtn() {
		let pendingPaymentPlans = this.props.FO.modalNew.pendingPaymentPlanTable.original.filter(
			(each, index) => index !== this.props.FO.modalNew.selectedPendingPaymentPlan.index
		)
		this.props.setFOModalNewPendingPaymentPlans(pendingPaymentPlans)
	}

	onSelectAll() {
		let pendingPaymentPlans = Array.from(this.props.FO.modalNew.pendingPaymentPlanTable.original)
		let selectedPaymentPlans = Array.from(this.props.FO.modalNew.paymentPlanTable.filtered)
		this.props.setFOModalNewPendingPaymentPlans(pendingPaymentPlans.concat(selectedPaymentPlans))
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.isDownBtnDisabled()}
						onClick={this.onDownBtn}
					>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.isSelectAllDisabled()}
						onClick={this.onSelectAll}
					>
						<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.selectAll)}
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={!this.props.FO.modalNew.selectedPendingPaymentPlan.paymentPlan}
						onClick={this.onUpBtn}
					>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setFOModalNewPendingPaymentPlans,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FOModalNewPaymentPlanAction)
