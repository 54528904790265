export const SET_DEPOSIT_LOCATION_DISPLAY = "SET_DEPOSIT_LOCATION_DISPLAY"
export const SET_DEPOSIT_MODAL_LOCATION_SELECTED_BRANCH = "SET_DEPOSIT_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_DEPOSIT_MODAL_LOCATION_SELECTED_ORG = "SET_DEPOSIT_MODAL_LOCATION_SELECTED_ORG"
export const SET_DEPOSIT_MODAL_SAVE_AMOUNT = "SET_DEPOSIT_MODAL_SAVE_AMOUNT"
export const SET_DEPOSIT_MODAL_SAVE_PAYMENT_TYPES = "SET_DEPOSIT_MODAL_SAVE_PAYMENT_TYPES"
export const SET_DEPOSIT_MODAL_SAVE_REMARK = "SET_DEPOSIT_MODAL_SAVE_REMARK"
export const SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT = "SET_DEPOSIT_MODAL_SAVE_SELECTED_PAYMENT"
export const SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME = "SET_DEPOSIT_MODAL_SEARCH_FIRST_NAME"
export const SET_DEPOSIT_MODAL_SEARCH_ID = "SET_DEPOSIT_MODAL_SEARCH_ID"
export const SET_DEPOSIT_MODAL_SEARCH_LAST_NAME = "SET_DEPOSIT_MODAL_SEARCH_LAST_NAME"
export const SET_DEPOSIT_MODAL_SEARCH_PATIENTS = "SET_DEPOSIT_MODAL_SEARCH_PATIENTS"
export const SET_DEPOSIT_MODAL_SEARCH_PID = "SET_DEPOSIT_MODAL_SEARCH_PID"
export const SET_DEPOSIT_MODAL_SEARCH_SELECTED_PATIENT = "SET_DEPOSIT_MODAL_SEARCH_SELECTED_PATIENT"
export const SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT = "SET_DEPOSIT_MODAL_WITHDRAW_AMOUNT"
export const SET_DEPOSIT_MODAL_WITHDRAW_PAYMENT_TYPES = "SET_DEPOSIT_MODAL_WITHDRAW_PAYMENT_TYPES"
export const SET_DEPOSIT_MODAL_WITHDRAW_REMARK = "SET_DEPOSIT_MODAL_WITHDRAW_REMARK"
export const SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT = "SET_DEPOSIT_MODAL_WITHDRAW_SELECTED_PAYMENT"
export const SET_DEPOSIT_PATIENT = "SET_DEPOSIT_PATIENT"
export const SET_DEPOSIT_TRANSACTIONS = "SET_DEPOSIT_TRANSACTIONS"