import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setIPDIntakeOutputs } from "~/redux/action"
import IPDRightIntakeOutputChart from "./IPDRightIntakeOutputChart"
import IPDRightIntakeOutputTable from "./IPDRightIntakeOutputTable"

class IPDRightIntakeOutput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightIntakeOutput",
		}
	}

	componentDidMount() {
		if (this.props.ipd.selectedHospitel.hospitel) {
			this.props.setIPDIntakeOutputs(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.intakeOutputs)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel) ||
			!_.isEqual(prevProps.ipd.orderEndDateTime, this.props.ipd.orderEndDateTime)
		) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.props.setIPDIntakeOutputs(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.intakeOutputs)
			} else {
				this.props.setIPDIntakeOutputs([])
			}
		}
	}

	render() {
		return this.props.graphic ? <IPDRightIntakeOutputChart /> : <IPDRightIntakeOutputTable />
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDIntakeOutputs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightIntakeOutput))
