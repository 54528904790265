import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { TbDiscount2 } from "react-icons/tb"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setDebtInvoices, setDebtPlans, setLoadingAction, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_DEBT_ADJUSTMENT_DISPLAY,
	SET_DEBT_EDIT_INVOICE_DISPLAY,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_DEBT_MODAL_ADJUSTMENT_ID,
	SET_DEBT_SELECTED_INVOICE,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class DebtRightInvoice extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "DebtRightInvoice",
		}
		this.selectRow = this.selectRow.bind(this)
		this.onHealthCarePlanDiscount = this.onHealthCarePlanDiscount.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.debt.modalAdjustment.isContinue, this.props.debt.modalAdjustment.isContinue)) {
			if (
				this.props.debt.modalAdjustment.isContinue &&
				_.isEqual(this.props.debt.modalAdjustment.id, ResourceAssistance.ID.ACCOUNTING.debt.modalAdjustment.billingPlanAdjustment)
			) {
				this.saveBillingPlanAdjustments()
			}
		}
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.debt.deleteInvoice)) {
				this.deleteDebtPlanInvoices()
			}
		}
		if (!_.isEqual(prevProps.debt.selectedPlan, this.props.debt.selectedPlan)) {
			if (this.props.debt.selectedPlan.plan) {
				this.props.setDebtInvoices(this.props.debt.selectedPlan.plan.invoices)
			} else {
				this.props.setDebtInvoices([])
			}
		}
		if (!_.isEqual(prevProps.debt.invoiceTable.filtered, this.props.debt.invoiceTable.filtered)) {
			let index = this.props.debt.selectedInvoice.invoice
				? this.props.debt.invoiceTable.filtered.findIndex((invoice) => invoice.id === this.props.debt.selectedInvoice.invoice.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_DEBT_SELECTED_INVOICE, index, this.props.debt.invoiceTable.filtered[index])
			} else {
				this.props.setSelected(SET_DEBT_SELECTED_INVOICE, -1, null)
			}
		}
	}

	isDisplayEdit() {
		// return this.props.debt.selectedPlan.plan && !this.props.debt.selectedPlan.plan.complete
		return false
	}

	isDisplayAdjustment() {
		// this.props.debt.selectedPlan.plan && !this.props.debt.selectedPlan.plan.complete
		return false
	}

	isDisplayDelete() {
		return this.props.debt.selectedPlan.plan && !this.props.debt.selectedPlan.plan.complete
	}

	deleteDebtPlanInvoices() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.debt.deleteDebtPlanInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.debt.location.id,
				debtPlans: [
					{
						id: this.props.debt.selectedPlan.plan.id,
						invoices: [
							{
								id: this.props.debt.selectedInvoice.invoice.id,
							},
						],
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.debtPlans.length > 0) {
				let plans = this.props.debt.planTable.original.filter((each) => each.id !== res.data.debtPlans[0].id)
				plans.push(res.data.debtPlans[0])
				this.props.setDebtPlans(plans)
			} else {
				let plans = this.props.debt.planTable.original.filter((each) => each.id !== this.props.debt.selectedPlan.plan.id)
				this.props.setDebtPlans(plans)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	saveBillingPlanAdjustments() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.debt.saveAccountantAdjustment,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.debt.location.id,
				userId: this.props.login.user.id,
				debtPlans: [
					{
						id: this.props.debt.selectedPlan.plan.id,
						billingPlanAdjustments: [
							{
								id: this.props.debt.selectedInvoice.invoice.billing.billingPlanAdjustments[0]
									? this.props.debt.selectedInvoice.invoice.billing.billingPlanAdjustments[0].id
									: 0,
								billingId: this.props.debt.selectedInvoice.invoice.billing.id,
								billingPlanId: this.props.debt.selectedInvoice.invoice.billing.billingPlan.id,
								accountantDiscount: Utils.BigNumber(this.props.debt.modalAdjustment.discountPercent).isGreaterThan(0)
									? this.props.debt.selectedInvoice.invoice.billing.billingStatements
											.reduce((total, cur) => {
												return total.plus(cur.charge).minus(_.isEmpty(cur.accountantAdjustment) ? cur.adjustment : cur.accountantAdjustment)
											}, Utils.BigNumber(0))
											.times(this.props.debt.modalAdjustment.discountPercent)
											.dividedBy(100)
											.toFixed(2)
									: this.props.debt.modalAdjustment.discount,
								accountantDiscountDescription: Utils.trim(this.props.debt.modalAdjustment.remark),
							},
						],
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.debtPlans.length > 0) {
				let plans = this.props.debt.planTable.original.filter((each) => each.id !== res.data.debtPlans[0].id)
				plans.push(res.data.debtPlans[0])
				this.props.setDebtPlans(plans)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_DEBT_SELECTED_INVOICE, index, this.props.debt.invoiceTable.filtered[index])
	}

	onHealthCarePlanDiscount(e, r, rIdx, cIdx) {
		let selectedInvoice = this.props.debt.invoiceTable.filtered[rIdx]
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.ACCOUNTING.debt.modalAdjustment.billingPlanAdjustment)
		let totalDiscountedBalance = selectedInvoice.billing.billingStatements
			.reduce((total, cur) => {
				return total.plus(cur.charge).minus(_.isEmpty(cur.accountantAdjustment) ? cur.adjustment : cur.accountantAdjustment)
			}, Utils.BigNumber(0))
			.toFixed(2)
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX, totalDiscountedBalance)
		this.props.setValue(
			SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT,
			_.isEmpty(selectedInvoice.billing.billingPlanAdjustments)
				? Utils.BigNumber(0).toFixed(2)
				: _.isEmpty(selectedInvoice.billing.billingPlanAdjustments[0].accountantDiscount)
				? Utils.BigNumber(selectedInvoice.billing.billingPlanAdjustments[0].discount).toFixed(2)
				: Utils.BigNumber(selectedInvoice.billing.billingPlanAdjustments[0].accountantDiscount).toFixed(2)
		)
		this.props.onModalDisplayAction(SET_DEBT_ADJUSTMENT_DISPLAY, true)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_DEBT_EDIT_INVOICE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedInvoice = this.props.debt.invoiceTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.debt.deleteInvoice)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={2}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
						{Utils.formatDate(selectedInvoice.creationDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceId)} span={1}>
						{selectedInvoice.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
						{selectedInvoice.billing.billingPlan.admission.patient.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
						{selectedInvoice.billing.billingPlan.admission.patient.displayName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.totalBalance)} span={2}>
						{Utils.formatNumWithComma(selectedInvoice.total)}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		let totalBalance = this.props.debt.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[5]))
		}, Utils.BigNumber(0))
		let totalDiscount = this.props.debt.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[6])).plus(Utils.formatNumberFromStr(cur[7]))
		}, Utils.BigNumber(0))
		let totalPaid = this.props.debt.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[9]))
		}, Utils.BigNumber(0))
		let totalDiscountedBalance = totalBalance.minus(totalDiscount)
		let currentBalance = totalDiscountedBalance.minus(totalPaid)
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							this.isDisplayAdjustment() ? <TbDiscount2 size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onHealthCarePlanDiscount, this.onModify, this.onDelete]}
						data={this.props.debt.invoiceTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.debt.selectedInvoice.index === -1}
						highlightedRow={this.props.debt.selectedInvoice.index}
						extra={
							!_.isEmpty(this.props.debt.invoiceTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col>
										<Descriptions size="small" contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalBalance)}>
												{Utils.formatNumWithComma(totalBalance.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.discount)}>
												{Utils.formatNumWithComma(totalDiscount.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscounted)}>
												{Utils.formatNumWithComma(totalDiscountedBalance.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.paid)}>{Utils.formatNumWithComma(totalPaid.toFixed(2))}</Descriptions.Item>
											<Descriptions.Item
												contentStyle={{
													color:
														!this.props.debt.selectedPlan.plan ||
														Utils.BigNumber(this.props.debt.selectedPlan.plan.amount)
															.plus(this.props.debt.selectedPlan.plan.transactionFee)
															.plus(this.props.debt.selectedPlan.plan.withholdingTax)
															.plus(this.props.debt.selectedPlan.plan.balanceAdjustment)
															.eq(currentBalance)
															? undefined
															: "red",
												}}
												label={translate(ResourceAssistance.Hospitel.balance)}
											>
												{Utils.formatNumWithComma(currentBalance.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtInvoices,
			setDebtPlans,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtRightInvoice))
