import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PurchaseInvoiceLeftDate from "./PurchaseInvoiceLeftDate"
import PurchaseInvoiceLeftFilter from "./PurchaseInvoiceLeftFilter"
import PurchaseInvoiceLeftAction from "./PurchaseInvoiceLeftAction"
import PurchaseInvoiceLeftInvoice from "./PurchaseInvoiceLeftInvoice"

class PurchaseInvoiceLeft extends React.Component {
	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<PurchaseInvoiceLeftDate />
				<PurchaseInvoiceLeftFilter />
				<PurchaseInvoiceLeftAction />
				<PurchaseInvoiceLeftInvoice />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PurchaseInvoiceLeft)
