import type from "./attribute/InputType"
import elementType from "./attribute/ElementType"
import { size } from "./attribute"

//Properties for each UI component.
const variant = {
	primary: "primary",
	info: "info",
	secondary: "secondary",
	green: "success",
	light: "light",
	warning: "warning",
	red: "danger",
	link: "link",
	dark: "dark",
	outlinePrimary: "outline-primary",
	outlineGreen: "outline-success",
	outlineRed: "outline-danger",
	outlineInfo: "outline-info",
	outlineLight: "outline-light",
	outlineSecondary: "outline-secondary",
	outlineWarning: "outline-warning",
}
const Component = {
	FormControl: {
		ref: {},
		type: { ...type },
		as: { ...elementType },
		custom: false,
		dissabled: false,
		htmlSize: 0,
		id: "",
		isInvalid: false,
		isValid: false,
		size: size,
		onChange: () => {},
	},

	Select: {
		size: {
			large: "large",
			middle: "middle",
			small: "small",
		},
		mode: {
			multiple: "multiple",
			tags: "tags",
		},
	},

	Button: {
		type: { ...type },
		variant: variant,
		size: size,
	},

	Grid: {
		breakpoint: {
			sm: "sm",
			md: "md",
			lg: "lg",
			xl: "xl",
		},
	},

	Modal: {
		size: { ...size },
		backdrop: {
			static: "static",
			true: true,
			false: false,
		},
		//close the modal when escape key is pressed.
		keyboard: {
			true: true,
			false: false,
		},
	},

	Spinner: {
		border: "border",
		grow: "grow",
	},

	ReactIcon: {
		size_antd_button: "1em",
		size: "1.25rem",
		size_smd: "1.75rem",
		size_md: "2.5rem",
		size_lg: "3.75rem",
	},

	Label: {
		variant: {
			warning: "warning",
			red: "red",
		},
	},
}

export default Component
