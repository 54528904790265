import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setObjArray } from "~/redux/action"

class AdmItemModalUnitDescription extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.item)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={2}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={2}>
								{this.props.configItem.selectedItem.item.code}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
								{this.props.configItem.selectedItem.item.displayName}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	configItem: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmItemModalUnitDescription)
