import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import ConfigAntigenComment from "./antigen_comment/ConfigAntigenComment"
import ConfigPCRComment from "./pcr_comment/ConfigPCRComment"

class ConfigLaboratoryHome extends React.Component {
	EConfigLaboratory = {
		PCR_COMMENT: "CONFIG_PCR_COMMENT",
		ANTIGEN_COMMENT: "CONFIG_ANTIGEN_COMMENT",
	}

	constructor(props) {
		super(props)

		this.state = {
			componentName: "ConfigLaboratoryHome",
			tab: this.EConfigLaboratory.PCR_COMMENT,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadData = this.loadData.bind(this)
	}

	loadData(url, func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false} transition={false}>
							<Tab
								eventKey={this.EConfigLaboratory.ANTIGEN_COMMENT}
								title={translate(ResourceAssistance.Message.antigenComment)}
								name={this.EConfigLaboratory.ANTIGEN_COMMENT}
							>
								{this.state.tab === this.EConfigLaboratory.ANTIGEN_COMMENT && (
									<ConfigAntigenComment loadAntigens={this.loadData} />
								)}
							</Tab>
							<Tab
								eventKey={this.EConfigLaboratory.PCR_COMMENT}
								title={translate(ResourceAssistance.Message.pcrComment)}
								name={this.EConfigLaboratory.PCR_COMMENT}
							>
								{this.state.tab === this.EConfigLaboratory.PCR_COMMENT && <ConfigPCRComment loadPCRs={this.loadData} />}
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigLaboratoryHome)
