import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_ADM_COA_REVENUES_ADD_DISPLAY, SET_ADM_COA_REVENUES_ORIGINAL, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ConfigCOARevenuesModalAddDetails from "./ConfigCOARevenuesModalAddDetails"

class ConfigCOARevenuesModalAdd extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigCOARevenuesModalAdd",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_COA_REVENUES_ADD_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (this.props.revenue.selectedAccount.account) {
			let selectedAccount = this.props.revenue.selectedAccount.account
			let target = Object.assign({}, selectedAccount, {
				code: Utils.trim(this.props.revenue.modalAdd.code),
				displayName: Utils.trim(this.props.revenue.modalAdd.name),
				description: Utils.trim(this.props.revenue.modalAdd.description),
				level: this.props.revenue.modalAdd.selectedRevenue.revenue
					? String(String(this.props.revenue.modalAdd.selectedRevenue.index).split("-").length + 1)
					: "1",
				subLevel: this.props.revenue.modalAdd.isSubLevel,
			})
			return (
				(_.isEqual(selectedAccount, target) &&
					selectedAccount.parent &&
					this.props.revenue.modalAdd.selectedRevenue.revenue &&
					selectedAccount.parent.id === this.props.revenue.modalAdd.selectedRevenue.revenue.id) ||
				(_.isEqual(selectedAccount, target) && !selectedAccount.parent && !this.props.revenue.modalAdd.selectedRevenue.revenue)
			)
		}
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.chartOfAccounts.saveRevenues,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				accounts: [
					{
						id: this.props.revenue.selectedAccount.account ? this.props.revenue.selectedAccount.account.id : 0,
						parentId: this.props.revenue.modalAdd.selectedRevenue.revenue ? this.props.revenue.modalAdd.selectedRevenue.revenue.id : 0,
						code: Utils.trim(this.props.revenue.modalAdd.code),
						fullCode: this.props.revenue.modalAdd.selectedRevenue.revenue
							? this.props.revenue.modalAdd.selectedRevenue.revenue.fullCode + "" + Utils.trim(this.props.revenue.modalAdd.code)
							: ResourceAssistance.CONSTANT.GL_REVENUES_CODE + Utils.trim(this.props.revenue.modalAdd.code),
						name: Utils.trim(this.props.revenue.modalAdd.name),
						description: Utils.trim(this.props.revenue.modalAdd.description),
						level: this.props.revenue.modalAdd.selectedRevenue.revenue ? String(this.props.revenue.modalAdd.selectedRevenue.index).split("-").length + 1 : 1,
						subLevel: this.props.revenue.modalAdd.isSubLevel,
					},
				],
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.accounts)) {
				let revenues = this.props.revenue.originalRevenues.filter((each) => each.id !== res.data.accounts[0].id)
				revenues.push(res.data.accounts[0])
				this.props.setValue(SET_ADM_COA_REVENUES_ORIGINAL, revenues)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.revenues)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ConfigCOARevenuesModalAddDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isConfigRevenueAddDisplay,
	login: state.login,
	revenue: state.admin.configCOA.revenue,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigCOARevenuesModalAdd))
