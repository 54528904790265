import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ErrorCode } from "~/axios/ErrorCode"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelectedType, setValue, setWarningMsgAction } from "~/redux/action"
import {
	SET_ADM_NEW_TYPE_DISPLAY_ADDRESS,
	SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_CODE,
	SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_INVENTORY,
	SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT,
	SET_ADM_NEW_TYPE_DISPLAY_PERCENT,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL,
	SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT,
	SET_ADM_NEW_TYPE_DISPLAY_TEL,
	SET_WARNING_DISPLAY,
	SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM,
} from "~/redux/type"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"
import TypeModalNewDetails from "./TypeModalNewDetails"

export const Property = {
	ACTIVE: "TYPE_ACTIVE",
	BANK_ACCOUNT: "BANK_ACCOUNT",
	CHECK_ACCOUNT: "CHECK_ACCOUNT",
	CODE: "TYPE_CODE",
	CREDIT_CARD_ACCOUNT: "CREDIT_CARD_ACCOUNT",
	DESCRIPTION: "TYPE_DESCRIPTION",
	NAME: "TYPE_NAME",
	PRIMARY_PLAN: "TYPE_HEALTH_CARE_PLAN_PRIMARY_PLAN",
	PRIMARY_HOSPITAL: "PRIMARY_HOSPITAL",
	CHRG_ITEM: "CHRG_ITEM",
	SAVING_ACCOUNT: "TYPE_PAYMENT_PATIENT_SAVING",
}

class TypeModalNew extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(Property)

		this.state = this.initState()
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onSelectChange = this.onSelectChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.type.selectedType.index !== this.props.type.selectedType.index) {
			if (this.props.type.selectedType.type) {
				this.setState({
					code: this.props.type.selectedType.type.code,
					name: this.props.type.selectedType.type.displayName,
					description: this.props.type.selectedType.type.description,
					chrgItem: this.props.type.selectedType.type.chrgItem,
					active: this.props.type.selectedType.type.active,
					isSavingAccount: this.props.type.selectedType.type.savingAccount,
					isPrimaryHealthCarePlan: this.props.type.selectedType.type.primaryHealthCarePlan,
					isPrimaryHospital: this.props.type.selectedType.type.primaryHospital || false,
					isBankAccount: this.props.type.selectedType.type.bankAccount,
					isCheckAccount: this.props.type.selectedType.type.checkAccount,
					isCreditCardAccount: this.props.type.selectedType.type.creditCardAccount,
				})
			} else {
				this.setState(this.initState())
			}
		}
	}

	initState() {
		return {
			componentName: "NewType",
			code: "",
			name: "",
			description: "",
			bankBatchRecordFormat: "",
			bankDetailRecordFormat: "",
			batchMaxCharacters: "",
			detailMaxCharacters: "",
			chrgItem: "",
			active: true,
			isSavingAccount: false,
			isPrimaryHealthCarePlan: false,
			isPrimaryHospital: false,
			isBankAccount: false,
			isCheckAccount: false,
			isCreditCardAccount: false,
		}
	}

	reset() {
		this.setState(this.initState())
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CODE, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_SAVING_ACCOUNT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_BANK_ACCOUNT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CHECK_ACCOUNT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CREDIT_CARD_ACCOUNT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PERCENT, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_INVENTORY, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_ADDRESS, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_TEL, false)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CHRGITEM, false)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, false)
		this.props.setSelectedType(-1, null)
	}

	onClose(event) {
		this.reset()
	}

	saveType() {
		let raw = {
			id: this.props.type.selectedType.type ? this.props.type.selectedType.type.id : 0,
			userId: this.props.login.user.id,
			code: Utils.trim(this.state.code),
			name: Utils.trim(this.state.name),
			description: Utils.trim(this.state.description),
			address: Utils.trim(this.props.type.modalNew.address),
			tel: Utils.trim(this.props.type.modalNew.tel),
			chrgItem: Utils.trim(this.state.chrgItem),
			active: this.state.active,
			internetPayFormatAvailable: this.props.type.bank.modalBank.isDisplayFormat,
			savingAccount: this.state.isSavingAccount,
			primaryHealthCarePlan: this.state.isPrimaryHealthCarePlan,
			primaryHospital: this.state.isPrimaryHospital,
			bankAccount: this.state.isBankAccount,
			checkAccount: this.state.isCheckAccount,
			creditCardAccount: this.state.isCreditCardAccount,
			internetPayBatchFormat: this.props.type.bank.modalBank.batchRecordFormat,
			internetPayDetailFormat: this.props.type.bank.modalBank.detailRecordFormat,
			withholdingTaxType: this.props.type.modalNew.withholdingTaxType,
			skipPercent: this.props.type.modalNew.isSkipPercent,
			role: this.props.type.modalNew.role,
		}
		let errorHandler = (error) => {
			this.setState(this.initState())
			if (_.isEqual(error.response.status, ErrorCode.CONFLICT)) {
				this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
				this.props.setWarningMsgAction(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			}
		}
		axios(this.props.setParam(raw, this.props.type.url), this.props.callback, errorHandler)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		this.saveType()
		this.reset()
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.CODE:
				this.setState({ code: event.target.value.toUpperCase() })
				break
			case Property.NAME:
				this.setState({ name: event.target.value })
				break
			case Property.DESCRIPTION:
				this.setState({ description: event.target.value })
				break
			case Property.ACTIVE:
				this.setState({ active: event.target.checked })
				break
			case Property.SAVING_ACCOUNT:
				this.setState({ isSavingAccount: event.target.checked })
				break
			case Property.PRIMARY_PLAN:
				this.setState({ isPrimaryHealthCarePlan: event.target.checked })
				break
			case Property.PRIMARY_HOSPITAL:
				this.setState({ isPrimaryHospital: event.target.checked })
				break
			case Property.BANK_ACCOUNT:
				this.setState({ isBankAccount: event.target.checked })
				break
			case Property.CHECK_ACCOUNT:
				this.setState({ isCheckAccount: event.target.checked })
				break
			case Property.CREDIT_CARD_ACCOUNT:
				this.setState({ isCreditCardAccount: event.target.checked })
				break
			default:
				break
		}
	}

	onSelectChange(name, selected) {
		switch (name) {
			case Property.CHRG_ITEM:
				this.setState({ chrgItem: selected })
				break
			default:
				break
		}
	}

	isDoneBtnDisabled() {
		let disabled = false
		disabled = disabled || _.isEmpty(Utils.trim(this.state.name)) || _.isEmpty(Utils.trim(this.state.description))
		if (this.props.type.modal.displayCode) {
			disabled = disabled || _.isEmpty(Utils.trim(this.state.code))
		}
		if (this.props.type.modal.displayChrgItem) {
			disabled = disabled || _.isEmpty(this.state.chrgItem)
		}
		return disabled
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.md}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(this.props.type.modal.title)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<TypeModalNewDetails {...this.props} {...this.state} onPropertyChange={this.onPropertyChange} onSelectChange={this.onSelectChange} />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isNewTypeDisplay,
	modal: state.modal.newType,
	login: state.login,
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setSelectedType,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TypeModalNew))
