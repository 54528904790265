import { Switch } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_BANK_MODAL_BRANCH_ACCOUNT,
	SET_ADM_BANK_MODAL_BRANCH_ACTIVE,
	SET_ADM_BANK_MODAL_BRANCH_ADDRESS,
	SET_ADM_BANK_MODAL_BRANCH_CODE,
	SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION,
	SET_ADM_BANK_MODAL_BRANCH_NAME,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class AdmBankModalBranchDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCode = this.onCode.bind(this)
		this.onName = this.onName.bind(this)
		this.onAccount = this.onAccount.bind(this)
		this.onAddress = this.onAddress.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		let branch = this.props.type.bank.selectedBranch.branch
		if (branch) {
			this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_CODE, branch.code)
			this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_NAME, branch.name)
			this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ACCOUNT, branch.account)
			this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ADDRESS, branch.address)
			this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION, branch.description)
			this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ACTIVE, branch.active)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_CODE, "")
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_NAME, "")
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ACCOUNT, "")
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ADDRESS, "")
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION, "")
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ACTIVE, true)
	}

	onCode(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_CODE, e.target.value)
	}

	onName(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_NAME, e.target.value)
	}

	onAccount(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ACCOUNT, e.target.value)
	}

	onAddress(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ADDRESS, e.target.value)
	}

	onDescription(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_DESCRIPTION, e.target.value)
	}

	onActive(value) {
		this.props.setValue(SET_ADM_BANK_MODAL_BRANCH_ACTIVE, value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.code)}
							value={this.props.type.bank.modalBranch.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.name)}
							value={this.props.type.bank.modalBranch.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.bankAccNum)}
							value={this.props.type.bank.modalBranch.account}
							onChange={this.onAccount}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.address)}
							value={this.props.type.bank.modalBranch.address}
							onChange={this.onAddress}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.type.bank.modalBranch.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Switch
							style={this.props.type.bank.modalBranch.isActive ? { backgroundColor: ResourceAssistance.CSS.Color.dark_green } : undefined}
							checkedChildren={translate(ResourceAssistance.Message.active)}
							unCheckedChildren={translate(ResourceAssistance.Message.inactive)}
							checked={this.props.type.bank.modalBranch.isActive}
							onChange={this.onActive}
						/>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setObjArray,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmBankModalBranchDetails))
