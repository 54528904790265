import { Divider, Select, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { RxReset } from "react-icons/rx"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setContractBillingModalNewInvoices, setSelected, setValue } from "~/redux/action"
import {
	SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID,
	SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME,
	SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS,
	SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES,
	SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN,
	SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class ContractBillingModalNewFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onSearch = this.onSearch.bind(this)
		this.onHealthCarePlan = this.onHealthCarePlan.bind(this)
		this.onInsuranceCompany = this.onInsuranceCompany.bind(this)
		this.onFilterInvoiceId = this.onFilterInvoiceId.bind(this)
		this.onFilterPatientName = this.onFilterPatientName.bind(this)
		this.onReset = this.onReset.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.contract.modalNew.insuranceCompanies, this.props.contract.modalNew.insuranceCompanies)) {
			if (this.props.contract.selectedBilling.billing && !_.isEmpty(this.props.contract.modalNew.insuranceCompanies)) {
				let idx = this.props.contract.modalNew.insuranceCompanies.findIndex(
					(insuranceCompany) =>
						insuranceCompany.displayName === this.props.contract.selectedBilling.billing.insuranceCompany
				)
				if (idx > -1) {
					this.props.setSelected(
						SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY,
						idx,
						this.props.contract.modalNew.insuranceCompanies[idx]
					)
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS, [])
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES, [])
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN, -1, null)
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY, -1, null)
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME, "")
	}

	onSearch() {
		this.props.getInvoices()
	}

	onHealthCarePlan(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN, -1, null)
			return
		}
		if (typeof value === "string") {
			let entry = value.split("-")
			this.props.setSelected(
				SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN,
				value,
				this.props.contract.modalNew.healthcarePlans[entry[0]].companies[entry[1]]
			)
		} else {
			this.props.setSelected(
				SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN,
				value,
				this.props.contract.modalNew.healthcarePlans[value]
			)
		}
	}

	onInsuranceCompany(value) {
		if (value === undefined) {
			this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY, -1, null)
			return
		}
		this.props.setSelected(
			SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY,
			value,
			this.props.contract.modalNew.insuranceCompanies[value]
		)
	}

	onFilterInvoiceId(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID, e.target.value)
	}

	onFilterPatientName(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME, e.target.value)
	}

	onReset() {
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN, -1, null)
		if (!this.props.contract.selectedBilling.billing) {
			this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY, -1, null)
		}
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME, "")
		this.props.setContractBillingModalNewInvoices([])
	}

	render() {
		return (
			<Row>
				<Col md={3}>
					<TreeSelect
						style={{
							textAlign: "center",
						}}
						showSearch
						filterTreeNode={(input, treeNode) => {
							return treeNode.title.toLowerCase().includes(input)
						}}
						allowClear
						treeLine={{ showLeafIcon: false }}
						placeholder={translate(ResourceAssistance.Hospitel.healthCarePlan)}
						loading={this.props.contract.modalNew.isHealthCarePlanLoading}
						value={
							this.props.contract.modalNew.searchHealthCarePlan.healthcarePlan
								? this.props.contract.modalNew.searchHealthCarePlan.index
								: undefined
						}
						onChange={this.onHealthCarePlan}
						treeData={Utils.getTreeNodesFromHealthCarePlan(this.props.contract.modalNew.healthcarePlans)}
					/>
				</Col>
				<Col md={3}>
					<Select
						allowClear
						showSearch
						style={{ textAlign: "center", margin: 0 }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
						}
						placeholder={translate(ResourceAssistance.Message.insuranceCompany)}
						disabled={this.props.contract.selectedBilling.billing}
						loading={this.props.contract.modalNew.isInsuranceCompanyLoading}
						value={
							this.props.contract.modalNew.searchInsuranceCompany.insuranceCompany
								? this.props.contract.modalNew.searchInsuranceCompany.index
								: undefined
						}
						onChange={this.onInsuranceCompany}
					>
						{Utils.renderSelects(this.props.contract.modalNew.insuranceCompanies, false)}
					</Select>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={
							!this.props.contract.modalNew.searchHealthCarePlan.healthcarePlan &&
							!this.props.contract.modalNew.searchInsuranceCompany.insuranceCompany
						}
						onClick={this.onSearch}
					>
						<GoSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col sm="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.invoiceId, ResourceAssistance.Message.patientName]}
						callbacks={[this.onFilterInvoiceId, this.onFilterPatientName]}
						values={[this.props.contract.modalNew.filterInvoiceId, this.props.contract.modalNew.filterPatientName]}
					/>
				</Col>
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.warning} onClick={this.onReset}>
						<RxReset size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.reset)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setContractBillingModalNewInvoices,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalNewFilter)
