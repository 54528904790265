import { Drawer } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Container } from "react-bootstrap"
import ContextMenuPatientHealthCarePlan from "./ContextMenuPatientHealthCarePlan"
import ContextMenuPatientHealthHistory from "./ContextMenuPatientHealthHistory"
import ContextMenuPatientInfo from "./ContextMenuPatientInfo"
import ContextMenuPatientMedicalRecord from "./ContextMenuPatientMedicalRecord"

class ContextMenuPatientDetails extends React.Component {
	render() {
		return (
			<Drawer
				styles={{
					body: {
						paddingLeft: 0,
						paddingRight: 0,
					},
				}}
				width={450}
				mask={false}
				maskClosable={false}
				placement={"left"}
				onClose={this.props.onClose}
				open={this.props.isVisible}
			>
				<Container fluid>
					<ContextMenuPatientInfo {...this.props} />
					<ContextMenuPatientHealthCarePlan {...this.props} />
					<ContextMenuPatientHealthHistory {...this.props} />
					<ContextMenuPatientMedicalRecord {...this.props} />
				</Container>
			</Drawer>
		)
	}
}

ContextMenuPatientDetails.propTypes = {
	isVisible: propTypes.bool,
	onClose: propTypes.func,
	patient: propTypes.object.isRequired,
}

ContextMenuPatientDetails.defaultProps = {
	isVisible: false,
	onClose: () => {},
}

export default ContextMenuPatientDetails
