import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_REPORT_FINANCE_APA_ACCOUNTS_CODES,
	SET_REPORT_FINANCE_APA_AGINGS,
	SET_REPORT_FINANCE_APA_FILTER_DATE_TIME,
	SET_REPORT_FINANCE_APA_FILTER_SUPPLIERS,
	SET_REPORT_FINANCE_APA_ORGS,
	SET_REPORT_FINANCE_APA_ORGS_LOADING,
	SET_REPORT_FINANCE_APA_SELECTED_ORG,
	SET_REPORT_FINANCE_APA_SUPPLIERS,
	SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING,
	SET_REPORT_FINANCE_APA_SUPPLIER_AGINGS,
	SET_REPORT_FINANCE_ARA_ACCOUNTS_CODES,
	SET_REPORT_FINANCE_ARA_AGINGS,
	SET_REPORT_FINANCE_ARA_CUSTOMERS,
	SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME,
	SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY,
	SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES,
	SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING,
	SET_REPORT_FINANCE_ARA_ORGS,
	SET_REPORT_FINANCE_ARA_ORGS_LOADING,
	SET_REPORT_FINANCE_ARA_SELECTED_ORG,
	SET_REPORT_FINANCE_GLS,
	SET_REPORT_FINANCE_GL_ACCOUNT_CODES,
	SET_REPORT_FINANCE_GL_COA,
	SET_REPORT_FINANCE_GL_END_DATE_TIME,
	SET_REPORT_FINANCE_GL_ORGS,
	SET_REPORT_FINANCE_GL_ORGS_LOADING,
	SET_REPORT_FINANCE_GL_SELECTED_ORG,
	SET_REPORT_FINANCE_GL_START_DATE_TIME,
	SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING,
} from "~/redux/type"

const init = {
	generalLedger: {
		startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
		endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		accountCodes: "",
		isGeneralLedgerTransactionsLoading: false,
		isOrgsLoading: false,
		orgs: [],
		chartOfAccounts: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		generalLedgerTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.totalBroughtForward,
				ResourceAssistance.Message.debit,
				ResourceAssistance.Message.credit,
				ResourceAssistance.Message.total,
			],
			body: [],
			align: [],
			colStyle: [],
			width: [],
			isLoading: false,
		},
	},
	accountsPayableAging: {
		filterDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		filterSuppliers: [],
		isOrgsLoading: false,
		isSuppliersLoading: false,
		orgs: [],
		suppliers: [],
		accountsPayableAgings: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		accountingCodeTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.numberOfInvoices,
				ResourceAssistance.Message.notDueYet,
				ResourceAssistance.Message.pastDue1To30,
				ResourceAssistance.Message.pastDue31To90,
				ResourceAssistance.Message.pastDue91To180,
				ResourceAssistance.Message.pastDue181To360,
				ResourceAssistance.Message.pastDueOver360,
				ResourceAssistance.Message.total,
			],
			body: [],
			align: [],
			colStyle: [],
			width: [],
			isLoading: false,
		},
		supplierTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.supplierName,
				ResourceAssistance.Message.numberOfInvoices,
				ResourceAssistance.Message.notDueYet,
				ResourceAssistance.Message.pastDue1To30,
				ResourceAssistance.Message.pastDue31To90,
				ResourceAssistance.Message.pastDue91To180,
				ResourceAssistance.Message.pastDue181To360,
				ResourceAssistance.Message.pastDueOver360,
				ResourceAssistance.Message.total,
			],
			body: [],
			align: [],
			colStyle: [],
			width: [],
			isLoading: false,
		},
	},
	accountsReceivableAging: {
		filterDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		filterInsuranceCompanies: [],
		isOrgsLoading: false,
		isInsuranceCompaniesLoading: false,
		orgs: [],
		insuranceCompanies: [],
		accountsReceivableAgings: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		accountingCodeTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.numberOfInvoices,
				ResourceAssistance.Message.notDueYet,
				ResourceAssistance.Message.pastDue1To30,
				ResourceAssistance.Message.pastDue31To90,
				ResourceAssistance.Message.pastDue91To180,
				ResourceAssistance.Message.pastDue181To360,
				ResourceAssistance.Message.pastDueOver360,
				ResourceAssistance.Message.total,
			],
			body: [],
			align: [],
			colStyle: [],
			width: [],
			isLoading: false,
		},
		customerTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.numberOfInvoices,
				ResourceAssistance.Message.notDueYet,
				ResourceAssistance.Message.pastDue1To30,
				ResourceAssistance.Message.pastDue31To90,
				ResourceAssistance.Message.pastDue91To180,
				ResourceAssistance.Message.pastDue181To360,
				ResourceAssistance.Message.pastDueOver360,
				ResourceAssistance.Message.total,
			],
			body: [],
			align: [],
			colStyle: [],
			width: [],
			isLoading: false,
		},
	},
}

const reportFinanceReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_FINANCE_GL_START_DATE_TIME:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					startDateTime: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_GL_END_DATE_TIME:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					endDateTime: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_GL_COA:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					chartOfAccounts: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_GL_ORGS:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					orgs: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_GL_ORGS_LOADING:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_REPORT_FINANCE_GL_SELECTED_ORG:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_REPORT_FINANCE_GL_ACCOUNT_CODES:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					accountCodes: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_GLS:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					generalLedgerTable: {
						...state.generalLedger.generalLedgerTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						align: action.payload.align,
						colStyle: action.payload.colStyle,
						width: action.payload.width,
					},
				},
			})
		case SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING:
			return Object.assign({}, state, {
				generalLedger: {
					...state.generalLedger,
					isGeneralLedgerTransactionsLoading: action.payload.isLoading,
				},
			})
		case SET_REPORT_FINANCE_APA_ORGS:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					orgs: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_APA_ORGS_LOADING:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_REPORT_FINANCE_APA_SUPPLIERS:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					suppliers: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					isSuppliersLoading: action.payload.isLoading,
				},
			})
		case SET_REPORT_FINANCE_APA_SELECTED_ORG:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_REPORT_FINANCE_APA_FILTER_DATE_TIME:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					filterDateTime: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_APA_FILTER_SUPPLIERS:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					filterSuppliers: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_APA_AGINGS:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					accountsPayableAgings: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_APA_ACCOUNTS_CODES:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					accountingCodeTable: {
						...state.accountsPayableAging.accountingCodeTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						align: action.payload.align,
						colStyle: action.payload.colStyle,
						width: action.payload.width,
					},
				},
			})
		case SET_REPORT_FINANCE_APA_SUPPLIER_AGINGS:
			return Object.assign({}, state, {
				accountsPayableAging: {
					...state.accountsPayableAging,
					supplierTable: {
						...state.accountsPayableAging.supplierTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						align: action.payload.align,
						colStyle: action.payload.colStyle,
						width: action.payload.width,
					},
				},
			})
		case SET_REPORT_FINANCE_ARA_ORGS:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					orgs: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_ARA_ORGS_LOADING:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_REPORT_FINANCE_ARA_SELECTED_ORG:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					filterDateTime: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					filterInsuranceCompanies: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					insuranceCompanies: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					isInsuranceCompaniesLoading: action.payload.isLoading,
				},
			})
		case SET_REPORT_FINANCE_ARA_AGINGS:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					accountsReceivableAgings: action.payload.value,
				},
			})
		case SET_REPORT_FINANCE_ARA_CUSTOMERS:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					customerTable: {
						...state.accountsReceivableAging.customerTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						align: action.payload.align,
						colStyle: action.payload.colStyle,
						width: action.payload.width,
					},
				},
			})
		case SET_REPORT_FINANCE_ARA_ACCOUNTS_CODES:
			return Object.assign({}, state, {
				accountsReceivableAging: {
					...state.accountsReceivableAging,
					accountingCodeTable: {
						...state.accountsReceivableAging.accountingCodeTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						align: action.payload.align,
						colStyle: action.payload.colStyle,
						width: action.payload.width,
					},
				},
			})
		default:
			return state
	}
}

export default reportFinanceReducer
