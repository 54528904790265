import { Select } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID,
	SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY,
	SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME,
	SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME,
	SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES,
	SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class ContractBillingModalSearchDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onContractBillingId = this.onContractBillingId.bind(this)
		this.onDateRange = this.onDateRange.bind(this)
		this.onInsuranceCompany = this.onInsuranceCompany.bind(this)
	}

	componentDidMount() {
		this.getInsuranceCompanies()
	}

	componentWillUnmount() {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME, "")
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME, "")
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY, -1, null)
		this.props.setValue(SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES, [])
	}

	getInsuranceCompanies() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.contractBilling.getInsuranceCompanies,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onContractBillingId(e) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID, e.target.value)
	}

	onDateRange(v) {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME, v ? v[0].startOf("day").milliseconds(0).valueOf() : undefined)
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME, v ? v[1].endOf("day").milliseconds(0).valueOf() : undefined)
	}

	onInsuranceCompany(value) {
		if (value === undefined) {
			this.props.setSelected(SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY, -1, null)
			return
		}
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY, value, this.props.contract.modalSearch.insuranceCompanies[value])
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col />
					<Col md="auto">
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.id })}
							pattern="^\d+$"
							disabled={
								this.props.contract.modalSearch.searchInsuranceCompany.insuranceCompany !== null ||
								this.props.contract.modalSearch.searchRangeStartDateTime > 0 ||
								this.props.contract.modalSearch.searchRangeEndDateTime > 0
							}
							onChange={this.onContractBillingId}
							value={this.props.contract.modalSearch.searchContractBillingId}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<AntDatePicker
							displayRange
							size={"large"}
							format={"DD-MM-YYYY"}
							placeholder={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate }),
							]}
							disabled={!_.isEmpty(this.props.contract.modalSearch.searchContractBillingId)}
							status={
								this.props.contract.modalSearch.searchRangeStartDateTime > 0 && this.props.contract.modalSearch.searchRangeEndDateTime > 0 ? undefined : "error"
							}
							values={[
								this.props.contract.modalSearch.searchRangeStartDateTime ? moment(this.props.contract.modalSearch.searchRangeStartDateTime) : undefined,
								this.props.contract.modalSearch.searchRangeEndDateTime ? moment(this.props.contract.modalSearch.searchRangeEndDateTime) : undefined,
							]}
							onChange={this.onDateRange}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							size={"large"}
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.insuranceCompany)}
							disabled={!_.isEmpty(this.props.contract.modalSearch.searchDebtPlanId)}
							loading={this.props.contract.modalSearch.isInsuranceCompanyLoading}
							value={
								this.props.contract.modalSearch.searchInsuranceCompany.insuranceCompany
									? this.props.contract.modalSearch.searchInsuranceCompany.index
									: undefined
							}
							onChange={this.onInsuranceCompany}
						>
							{Utils.renderSelects(this.props.contract.modalSearch.insuranceCompanies, false)}
						</Select>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalSearchDetails))
