import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalDoctorNoteDate extends React.Component {
	constructor(props) {
		super(props)

		this.onNoteDateTime = this.onNoteDateTime.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedSOAP.soap) {
			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME, this.props.hospitel.selectedSOAP.soap.startDateTime)
		} else {
			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME, new Date().getTime())
		}
	}

	onNoteDateTime(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME, date.getTime())
	}

	render() {
		return (
			<Row style={{ alignSelf: "center" }}>
				<Col md="auto">
					<DatePicker
						minDate={Utils.generateDate()}
						showDisabledMonthNavigation
						startDate={Utils.generateDateFromLong(this.props.hospitel.modalDoctorNote.noteDateTime)}
						onChange={this.onNoteDateTime}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNoteDate))
