import { Radio } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING,
} from "~/redux/type"

class OperatingRoomModalSurgicalSafetyCheckListSignOutDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onProcedureName = this.onProcedureName.bind(this)
		this.onCompletionCounts = this.onCompletionCounts.bind(this)
		this.onSpecimenLabling = this.onSpecimenLabling.bind(this)
		this.onEquipmentProblem = this.onEquipmentProblem.bind(this)
		this.onRecoveryConcern = this.onRecoveryConcern.bind(this)
	}

	componentDidMount() {
		let checklist = this.props.operatingRoom.selectedSurgicalSafetyCheckList.checklist
		if (this.props.operatingRoom.selectedRequest.request && checklist) {
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME, checklist.signOutProcedureName)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS, checklist.signOutCompletionOfCounts)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING, checklist.signOutSpecimenLabeling)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM, checklist.signOutEquipmentProblem)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN, checklist.signOutRecoveryConcern)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.nurseVerballyConfirms)}</legend>
					<Row>
						<Col md={9}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.theNameOfTheProcedure)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignOut.isProcedureName} onChange={this.onProcedureName}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col md={9}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.completionOfInstrumentSpongeAndNeedleCounts)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignOut.isCompletionOfCounts} onChange={this.onCompletionCounts}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col md={9}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.specimenLabeling)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignOut.isSpecimenLabeling} onChange={this.onSpecimenLabling}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col md={9}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.whetherThereAreAnyEquipmentProblemsToBeAddressed)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignOut.isEquipmentProblem} onChange={this.onEquipmentProblem}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col md={9}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.whatAreTheKeyConcernsForRecoveryAndManagementOfThisPatient)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignOut.isRecoveryConcern} onChange={this.onRecoveryConcern}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
				</fieldset>
			</Container>
		)
	}

	onProcedureName(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME, e.target.value)
	}
	onCompletionCounts(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS, e.target.value)
	}
	onSpecimenLabling(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING, e.target.value)
	}
	onEquipmentProblem(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM, e.target.value)
	}
	onRecoveryConcern(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalSurgicalSafetyCheckListSignOutDetails)
