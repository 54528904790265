import propTypes from "prop-types"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { ResourceAssistance } from "~/i18n"

class ICDAction extends React.Component {
	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.selectedICD.index === -1}
						onClick={this.props.onDownBtn}
					>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.selectedPendingICD.index === -1}
						onClick={this.props.onUpBtn}
					>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

ICDAction.propTypes = {
	onDownBtn: propTypes.func,
	onUpBtn: propTypes.func,
}

ICDAction.defaultProps = {}

export default ICDAction
