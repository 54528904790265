import { Select } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_PI_MODAL_NEW_DUE_DATE_TIME,
	SET_PI_MODAL_NEW_INVOICE,
	SET_PI_MODAL_NEW_INVOICE_DATE_TIME,
	SET_PI_MODAL_NEW_REMARK,
	SET_PI_MODAL_NEW_SELECTED_PAYMENT,
	SET_PI_MODAL_NEW_SELECTED_SUPPLIER,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class PIModalNewFields extends React.Component {
	constructor(props) {
		super(props)

		this.onDueDate = this.onDueDate.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onPayment = this.onPayment.bind(this)
		this.onInvoiceDate = this.onInvoiceDate.bind(this)
		this.onInvoice = this.onInvoice.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.PI.selectedPI.PI) {
			let selectedPI = this.props.PI.selectedPI.PI
			this.props.setValue(SET_PI_MODAL_NEW_DUE_DATE_TIME, selectedPI.paymentTermDateTime)
			this.props.setValue(SET_PI_MODAL_NEW_INVOICE_DATE_TIME, selectedPI.invoiceDateTime)
			this.props.setValue(SET_PI_MODAL_NEW_INVOICE, selectedPI.invoice)
			this.props.setValue(SET_PI_MODAL_NEW_REMARK, selectedPI.remark)
			if (!_.isEmpty(this.props.PI.suppliers)) {
				this.initialSupplier()
			}
			if (!_.isEmpty(this.props.PI.paymentTypes)) {
				this.initialPayment()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PI.suppliers, this.props.PI.suppliers)) {
			if (this.props.PI.selectedPI.PI && !_.isEmpty(this.props.PI.suppliers)) {
				this.initialSupplier()
			}
		}
		if (!_.isEqual(prevProps.PI.paymentTypes, this.props.PI.paymentTypes)) {
			if (this.props.PI.selectedPI.PI && !_.isEmpty(this.props.PI.paymentTypes)) {
				this.initialPayment()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_SUPPLIER, -1, null)
		this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PAYMENT, -1, null)
		this.props.setValue(SET_PI_MODAL_NEW_DUE_DATE_TIME, "")
		this.props.setValue(SET_PI_MODAL_NEW_INVOICE_DATE_TIME, "")
		this.props.setValue(SET_PI_MODAL_NEW_INVOICE, "")
		this.props.setValue(SET_PI_MODAL_NEW_REMARK, "")
	}

	initialSupplier() {
		let idx = this.props.PI.suppliers.findIndex((each) => each.code === this.props.PI.selectedPI.PI.supplierCode)
		if (idx > -1) {
			this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_SUPPLIER, idx, this.props.PI.suppliers[idx])
		}
	}

	initialPayment() {
		let idx = this.props.PI.paymentTypes.findIndex((each) => each.displayName === this.props.PI.selectedPI.PI.payment)
		if (idx > -1) {
			this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PAYMENT, idx, this.props.PI.paymentTypes[idx])
		}
	}

	onDueDate(v) {
		this.props.setValue(SET_PI_MODAL_NEW_DUE_DATE_TIME, moment(v).endOf("day").milliseconds(0).valueOf())
	}

	onSupplier(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_SUPPLIER, -1, null)
			return
		}
		this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_SUPPLIER, value, this.props.PI.suppliers[value])
	}

	onPayment(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PAYMENT, -1, null)
			return
		}
		this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PAYMENT, value, this.props.PI.paymentTypes[value])
	}

	onInvoiceDate(v) {
		this.props.setValue(SET_PI_MODAL_NEW_INVOICE_DATE_TIME, moment(v).endOf("day").milliseconds(0).valueOf())
	}

	onInvoice(e) {
		this.props.setValue(SET_PI_MODAL_NEW_INVOICE, Utils.trim(e.target.value))
	}

	onRemark(e) {
		this.props.setValue(SET_PI_MODAL_NEW_REMARK, e.target.value)
	}

	render() {
		return (
			<Container fluid="small">
				<Row>
					<Col md={2}>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.dueDate })]}
							status={this.props.PI.modalNew.dueDateTime ? undefined : "error"}
							values={this.props.PI.modalNew.dueDateTime ? [moment(this.props.PI.modalNew.dueDateTime)] : [undefined]}
							onChange={this.onDueDate}
						/>
					</Col>
					<Col>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.supplier)}
							loading={this.props.PI.isSuppliersLoading}
							status={this.props.PI.modalNew.selectedSupplier.supplier ? undefined : "error"}
							value={this.props.PI.modalNew.selectedSupplier.supplier ? this.props.PI.modalNew.selectedSupplier.index : undefined}
							onChange={this.onSupplier}
						>
							{Utils.renderSelects(this.props.PI.suppliers, false, -1, "displayCodeName")}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col md={2} style={{ marginTop: 0 }}>
						<Container fluid="small">
							<Row>
								<Col>
									<AntDatePicker
										size={"middle"}
										format={"DD-MM-YYYY"}
										placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.invoiceDate })]}
										status={this.props.PI.modalNew.invoiceDateTime ? undefined : "error"}
										values={this.props.PI.modalNew.invoiceDateTime ? [moment(this.props.PI.modalNew.invoiceDateTime)] : [undefined]}
										onChange={this.onInvoiceDate}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<GInput
										required
										style={{ textAlign: "center" }}
										placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.invoiceNum })}
										onChange={this.onInvoice}
										value={this.props.PI.modalNew.invoice}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Select
										allowClear
										showSearch
										size={"large"}
										style={{ textAlign: "center", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
										placeholder={translate(ResourceAssistance.Message.payment)}
										loading={this.props.PI.isPaymentTypesLoading}
										status={this.props.PI.modalNew.selectedPayment.payment ? undefined : "error"}
										value={this.props.PI.modalNew.selectedPayment.payment ? this.props.PI.modalNew.selectedPayment.index : undefined}
										onChange={this.onPayment}
									>
										{Utils.renderSelects(this.props.PI.paymentTypes, false)}
									</Select>
								</Col>
							</Row>
						</Container>
					</Col>
					<Col>
						<GInput
							autoSize
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark })}
							minRows={5}
							onChange={this.onRemark}
							value={this.props.PI.modalNew.remark}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PIModalNewFields))
