import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setSelected, setFrontInventoryModalSplitLots } from "~/redux/action"
import { SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT, SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FIModalSplitLot extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			lots: [],
		}

		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		let lots = this.props.frontInventory.selectedInventory.inventory.transferOrderItems.map((lot) =>
			Object.assign({}, lot, {
				splitAmount: 0,
				isSplit: false,
			})
		)
		this.props.setFrontInventoryModalSplitLots(lots)
	}

	componentWillUnmount() {
		this.props.setFrontInventoryModalSplitLots([])
		this.props.setSelected(SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT, -1, null)
	}

	onEdit(event, row, rKey, cKey) {
		this.props.setSelected(SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT, rKey, this.props.frontInventory.modalSplit.lotTable.filtered[rKey])
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col style={{ minHeight: "200px", maxHeight: "200px" }}>
					<ScrollableTable
						enableOverlay
						striped
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onEdit]}
						data={this.props.frontInventory.modalSplit.lotTable}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setFrontInventoryModalSplitLots,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FIModalSplitLot)
