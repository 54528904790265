import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"
import GInput from "../input/GInput"

class PupilSizeInput extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.leftEye)}</legend>
						<Row>
							<Col>
								<GInput
									required
									type={ResourceAssistance.FormControl.type.number}
									placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.size })}(${
										ResourceAssistance.CONSTANT.MM
									})`}
									step={1}
									min={1}
									max={9}
									onChange={this.props.onLeftChange}
									value={this.props.left}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									required
									placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.reactionToLight })}
									pattern={"[sc+-]"}
									onChange={this.props.onLeftReaction}
									value={this.props.leftReactionToLight}
								/>
							</Col>
						</Row>
					</fieldset>
				</Col>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.rightEye)}</legend>
						<Row>
							<Col>
								<GInput
									required
									type={ResourceAssistance.FormControl.type.number}
									placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.size })}(${
										ResourceAssistance.CONSTANT.MM
									})`}
									step={1}
									min={1}
									max={9}
									onChange={this.props.onRightChange}
									value={this.props.right}
								/>
							</Col>
						</Row>
						<Row>
							<Col>
								<GInput
									required
									placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.reactionToLight })}
									pattern={"[sc+-]"}
									onChange={this.props.onRightReaction}
									value={this.props.rightReactionToLight}
								/>
							</Col>
						</Row>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

PupilSizeInput.propTypes = {
	onLeftChange: propTypes.func.isRequired,
	onLeftReaction: propTypes.func.isRequired,
	onRightChange: propTypes.func.isRequired,
	onRightReaction: propTypes.func.isRequired,
}

PupilSizeInput.defaultProps = {
	onLeftChange: () => {},
	onRightChange: () => {},
	onLeftReaction: () => {},
	onRightReaction: () => {},
}

export default injectIntl(PupilSizeInput, { forwardRef: true })
