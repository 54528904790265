import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPSRequests } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PS_NEW_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PSModalNewDetails from "./PSModalNewDetails"

class PSModalNew extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PS_NEW_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.PS.modalNew.pendingInvoiceTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.savePaymentSlipRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				requests: [
					{
						id: this.props.PS.selectedPaymentSlip.ps ? this.props.PS.selectedPaymentSlip.ps.id : "",
						paymentSlips: Object.entries(
							Utils.groupBy(
								this.props.PS.modalNew.pendingInvoiceTable.filtered,
								"supplierCode",
								"supplierName",
								"supplierPhone",
								"supplierAddress",
								"supplierBankCode",
								"supplierBankAccount",
								"supplierTaxId",
								"supplierEmail"
							)
						).map(([keys, values]) => {
							let keyAry = keys.split("#")
							let id = 0
							if (this.props.PS.selectedPaymentSlip.ps) {
								let paymentSlip = this.props.PS.selectedPaymentSlip.ps.paymentSlips.find((each) => each.supplierCode === keyAry[0])
								if (paymentSlip) {
									id = paymentSlip.id
								}
							}
							return {
								id: id,
								supplierCode: keyAry[0],
								supplierName: keyAry[1],
								supplierPhone: keyAry[2],
								supplierAddress: keyAry[3],
								supplierBankCode: keyAry[4],
								supplierBankAccount: keyAry[5],
								supplierTaxId: keyAry[6],
								supplierEmail: keyAry[7],
								invoices: values,
							}
						}),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.paymentSlip)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<PSModalNewDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPaymentSlipNewDisplay,
	login: state.login,
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPSRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalNew))
