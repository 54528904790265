import React from "react"
import { IntlProvider } from "react-intl"
import messages from "./message"

export const provider = ({ children, locale }) => {
	return (
		<IntlProvider locale={locale} messages={messages[locale]}>
			{children}
		</IntlProvider>
	)
}
