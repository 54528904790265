import propTypes from "prop-types"
import React, { Fragment } from "react"
import { Container } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import IntakeOutputDate from "./IntakeOutputDate"
import IntakeOutputIntake from "./IntakeOutputIntake"
import IntakeOutputOutput from "./IntakeOutputOutput"

class IntakeOutput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dateRef: React.createRef(),
			intakeRef: React.createRef(),
			outputRef: React.createRef(),
		}
	}

	render() {
		return (
			<Fragment>
				<Container fluid className={ResourceAssistance.CSS.fullFlex}>
					<IntakeOutputDate ref={this.state.dateRef} {...this.props} />
					<IntakeOutputIntake ref={this.state.intakeRef} {...this.props} />
					<IntakeOutputOutput ref={this.state.outputRef} {...this.props} />
				</Container>
			</Fragment>
		)
	}
}

IntakeOutput.propTypes = {
	intakes: propTypes.array,
	outputs: propTypes.array,
}

IntakeOutput.defaultProps = {
	intakes: [],
	outputs: [],
}

export default IntakeOutput
// export default React.forwardRef((props, ref) => <IntakeOutput innerRef={ref} {...props} />)
