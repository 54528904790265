import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_SA_MODAL_BUDGET_DRG } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import { TreeSelect } from "antd"

class SurgicalAuthModalBudgetOther extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			budgets: [],
		}

		this.onDRG = this.onDRG.bind(this)
		this.onAddBudget = this.onAddBudget.bind(this)
		this.onHealthCarePlan = this.onHealthCarePlan.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onAmount = this.onAmount.bind(this)
		this.onDeleteOther = this.onDeleteOther.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_SA_MODAL_BUDGET_DRG, Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.diagnosisRelatedGroup).toFixed(2))
		let ary = this.props.surgicalAuth.selectedORRequest.request.otherBudgets.split(ResourceAssistance.PROGRAM_DEFINED.split).filter((each) => Boolean(each))
		let budgets = []
		for (let i = 0; i < ary.length; i += 3) {
			let idx = -1
			let healthcarePlan = null
			let healthcarePlanIdx = this.props.surgicalAuth.healthcarePlans.findIndex(
				(each) => each.displayName === ary[i] || each.companies.some((eachC) => eachC.displayName === ary[i])
			)
			if (healthcarePlanIdx > -1) {
				if (this.props.surgicalAuth.healthcarePlans[healthcarePlanIdx].displayName === ary[i]) {
					idx = healthcarePlanIdx
					healthcarePlan = this.props.surgicalAuth.healthcarePlans[idx]
				} else {
					let companyIdx = this.props.surgicalAuth.healthcarePlans[healthcarePlanIdx].companies.findIndex((each) => each.displayName === ary[i])
					idx = healthcarePlanIdx + "-" + companyIdx
					healthcarePlan = this.props.surgicalAuth.healthcarePlans[healthcarePlanIdx].companies[companyIdx]
				}
			}
			budgets.push({
				selectedHealthCarePlan: {
					index: idx,
					healthcarePlan: healthcarePlan,
				},
				description: ary[i + 1],
				amount: Utils.BigNumber(ary[i + 2]).toFixed(2),
			})
		}
		this.setState({
			budgets: budgets,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.budgets, this.state.budgets)) {
			this.props.onRenderParentCallback()
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_SA_MODAL_BUDGET_DRG, undefined)
		this.setState({
			budgets: [],
		})
	}

	getEmptyBudgetObj() {
		return {
			selectedHealthCarePlan: {
				index: -1,
				healthcarePlan: null,
			},
			description: "",
			amount: undefined,
		}
	}

	onDRG(e) {
		this.props.setValue(SET_SA_MODAL_BUDGET_DRG, e.target.value)
	}

	onAddBudget(e) {
		this.setState((prevState) => {
			const updateBudgets = [...prevState.budgets]
			updateBudgets.push(this.getEmptyBudgetObj())
			return {
				budgets: updateBudgets,
			}
		})
	}

	onHealthCarePlan(value, idx) {
		if (value === undefined) {
			this.setState((prevState) => {
				const updateBudgets = [...prevState.budgets]
				updateBudgets[idx] = {
					...updateBudgets[idx],
					selectedHealthCarePlan: {
						index: -1,
						healthcarePlan: null,
					},
				}
				return { budgets: updateBudgets }
			})
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.setState((prevState) => {
				const updateBudgets = [...prevState.budgets]
				updateBudgets[idx] = {
					...updateBudgets[idx],
					selectedHealthCarePlan: {
						index: value,
						healthcarePlan: this.props.surgicalAuth.healthcarePlans[keys[0]].companies[keys[1]],
					},
				}
				return { budgets: updateBudgets }
			})
		} else {
			this.setState((prevState) => {
				const updateBudgets = [...prevState.budgets]
				updateBudgets[idx] = {
					...updateBudgets[idx],
					selectedHealthCarePlan: {
						index: value,
						healthcarePlan: this.props.surgicalAuth.healthcarePlans[value],
					},
				}
				return { budgets: updateBudgets }
			})
		}
	}

	onDescription(e) {
		let idx = JSON.parse(e.target.getAttribute("extra-value")).index
		this.setState((prevState) => {
			const updatedBudgets = [...prevState.budgets]
			updatedBudgets[idx] = {
				...updatedBudgets[idx],
				description: e.target.value,
			}
			return { budgets: updatedBudgets }
		})
	}

	onAmount(e) {
		let idx = JSON.parse(e.target.getAttribute("extra-value")).index
		this.setState((prevState) => {
			const updatedBudgets = [...prevState.budgets]
			updatedBudgets[idx] = {
				...updatedBudgets[idx],
				amount: e.target.value,
			}
			return { budgets: updatedBudgets }
		})
	}

	onDeleteOther(idx) {
		this.setState((prevState) => {
			const updatedBudgets = [...prevState.budgets]
			updatedBudgets.splice(idx, 1)
			return { budgets: updatedBudgets }
		})
	}

	render() {
		return (
			<>
				<Row>
					<Col md={6}>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.DRG)}
							pattern="^[0-9]*\.[0-9]{2}$"
							addonAfter={`x ${Utils.formatNumWithComma(this.props.surgicalAuth.workflowProperty.drg)}`}
							onChange={this.onDRG}
							value={this.props.surgicalAuth.modalBudget.DRG}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.primary} size={ResourceAssistance.Button.size.sm} onClick={this.onAddBudget}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.other)}
						</Button>
					</Col>
				</Row>
				{this.getBudgets()}
			</>
		)
	}

	getBudgets() {
		return this.state.budgets.map((each, i) => {
			return (
				<Row key={i} className="g-1">
					<Col>
						<TreeSelect
							size={"large"}
							style={{
								textAlign: "center",
							}}
							showSearch
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							allowClear
							treeLine={{ showLeafIcon: false }}
							placeholder={translate(ResourceAssistance.Hospitel.healthCarePlan)}
							status={each.selectedHealthCarePlan.healthcarePlan ? undefined : "error"}
							value={each.selectedHealthCarePlan.healthcarePlan ? each.selectedHealthCarePlan.index : undefined}
							onChange={(v) => {
								this.onHealthCarePlan(v, i)
							}}
							treeData={Utils.getTreeNodesFromHealthCarePlan(this.props.surgicalAuth.healthcarePlans, true)}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.description)}
							disabled={!each.selectedHealthCarePlan.healthcarePlan}
							onChange={this.onDescription}
							value={each.description}
							extraValue={JSON.stringify({
								index: i,
							})}
						/>
					</Col>
					<Col md={2}>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.amount)}
							pattern="^[0-9]*\.[0-9]{2}$"
							disabled={!each.selectedHealthCarePlan.healthcarePlan}
							onChange={this.onAmount}
							value={each.amount}
							extraValue={JSON.stringify({
								index: i,
							})}
						/>
					</Col>
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.outlineRed}
							style={{ borderColor: "transparent", backgroundColor: "unset" }}
							size={ResourceAssistance.Button.size.sm}
							onClick={() => this.onDeleteOther(i)}
						>
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
						</Button>
					</Col>
				</Row>
			)
		})
	}
}

const mapStateToProps = (state) => ({
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SurgicalAuthModalBudgetOther)
