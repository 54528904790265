import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_DEBT_INVOICES,
	SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT,
	SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT_GROUP,
	SET_DEBT_MODAL_NEW_INVOICES,
	SET_DEBT_MODAL_NEW_PENDING_INVOICES,
	SET_DEBT_PLANS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setDebtPlans = (plans) => {
	return (dispatch, getState) => {
		let filtered = plans
			.filter(
				(plan) =>
					plan.id.includes(getState().accounting.debt.filterDebtPlanId) &&
					plan.insuranceCompany.trim().toLowerCase().includes(getState().accounting.debt.filterInsuranceCompany.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let rowColor = filtered.map((plan) => {
			let color = ""
			if (plan.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		let body = filtered.map((plan) => {
			return [Utils.formatDate(plan.creationDateTime), Utils.formatTime(plan.creationDateTime), plan.id, plan.insuranceCompany]
		})

		dispatch({
			type: SET_DEBT_PLANS,
			payload: {
				original: plans,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setDebtInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let body = filtered.map((invoice, index) => {
			let totalBalance = invoice.billing.billingStatements.reduce((total, cur) => {
				return total.plus(cur.charge)
			}, Utils.BigNumber(0))
			let billingPlanAdjustments = invoice.billing.billingPlanAdjustments.reduce((total, cur) => {
				return total.plus(_.isEmpty(cur.accountantAdjustments) ? cur.adjustments : cur.accountantAdjustments)
			}, Utils.BigNumber(0))
			let billingStatementAdjustments = invoice.billing.billingStatements.reduce((total, cur) => {
				return _.isEmpty(cur.accountantAdjustment) ? total.plus(cur.adjustment) : total.plus(cur.accountantAdjustment)
			}, Utils.BigNumber(0))

			return [
				Utils.formatDate(invoice.creationDateTime),
				invoice.billing.billingPlan.admission.id,
				invoice.id,
				invoice.billing.billingPlan.admission.patient.id,
				invoice.billing.billingPlan.admission.patient.displayName,
				Utils.formatNumWithComma(totalBalance.toFixed(2)),
				Utils.formatNumWithComma(billingStatementAdjustments.toFixed(2)),
				Utils.formatNumWithComma(billingPlanAdjustments.toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(invoice.total).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(invoice.billing.total).toFixed(2)),
				invoice.contractBillingInvoice ? invoice.contractBillingInvoice.contractBilling.id : "",
				invoice.contractBillingInvoice ? Utils.formatDate(invoice.contractBillingInvoice.contractBilling.creationDateTime) : "",
				[true, true, true],
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_DEBT_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setDebtModalNewInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
			.filter(
				(invoice) =>
					invoice.id.includes(getState().accounting.debt.modalNew.filterInvoiceId) &&
					!getState().accounting.debt.modalNew.pendingInvoiceTable.original.some((pendingInvoice) => pendingInvoice.id === invoice.id)
			)
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let body = filtered.map((invoice, index) => {
			return [
				Utils.formatDate(invoice.creationDateTime),
				invoice.billing.billingPlan.admission.id,
				invoice.id,
				invoice.billing.billingPlan.admission.patient.id,
				invoice.billing.billingPlan.admission.patient.displayName,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.total).minus(invoice.billing.total).toFixed(2)),
				invoice.billing.billingPlan.name,
				invoice.billing.billingPlan.insuranceCompany,
				invoice.contractBillingInvoice ? invoice.contractBillingInvoice.contractBilling.id : "",
				invoice.contractBillingInvoice ? Utils.formatDate(invoice.contractBillingInvoice.contractBilling.creationDateTime) : "",
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_DEBT_MODAL_NEW_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setDebtModalNewPendingInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices

		let body = filtered.map((invoice) => {
			return [
				Utils.formatDate(invoice.creationDateTime),
				invoice.billing.billingPlan.admission.id,
				invoice.id,
				invoice.billing.billingPlan.admission.patient.id,
				invoice.billing.billingPlan.admission.patient.displayName,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.total).minus(invoice.billing.total).toFixed(2)),
				invoice.billing.billingPlan.name,
				invoice.billing.billingPlan.insuranceCompany,
				invoice.contractBillingInvoice ? invoice.contractBillingInvoice.contractBilling.id : "",
				invoice.contractBillingInvoice ? Utils.formatDate(invoice.contractBillingInvoice.contractBilling.creationDateTime) : "",
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_DEBT_MODAL_NEW_PENDING_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setDebtModalEditInvoiceBillingStatementGroups = (billingStatements) => {
	return (dispatch, getState) => {
		let filtered = Object.entries(Utils.groupBy(billingStatements, "categoryName"))

		let body = filtered.map(([key, values]) => {
			let totals = values.reduce(
				(totalArray, cur) => {
					totalArray[0] = totalArray[0].plus(cur.charge)
					totalArray[1] = _.isEmpty(cur.accountantAdjustment) ? totalArray[1].plus(cur.adjustment) : totalArray[1].plus(cur.accountantAdjustment)
					return totalArray
				},
				[Utils.BigNumber(0), Utils.BigNumber(0)]
			)
			return [
				key,
				Utils.formatNumWithComma(totals[0].toFixed(2)),
				Utils.formatNumWithComma(totals[1].toFixed(2)),
				Utils.formatNumWithComma(totals[0].minus(totals[1]).toFixed(2)),
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT_GROUP,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setDebtModalEditInvoiceBillingStatements = (billingStatements) => {
	return (dispatch, getState) => {
		let filtered = billingStatements

		let body = filtered.map((bs) => {
			return [
				bs.name,
				Utils.formatNumWithComma(Utils.BigNumber(bs.charge).toFixed(2)),
				_.isEmpty(bs.accountantAdjustment)
					? Utils.formatNumWithComma(Utils.BigNumber(bs.adjustment).toFixed(2))
					: Utils.formatNumWithComma(Utils.BigNumber(bs.accountantAdjustment).toFixed(2)),
				_.isEmpty(bs.accountantAdjustment) ? bs.adjustmentDescription : bs.accountantAdjustmentDescription,
				_.isEmpty(bs.accountantAdjustment)
					? Utils.formatNumWithComma(Utils.BigNumber(bs.charge).minus(bs.adjustment).toFixed(2))
					: Utils.formatNumWithComma(Utils.BigNumber(bs.charge).minus(bs.accountantAdjustment).toFixed(2)),
				[true],
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
