const SurgicalSafetyCheckList = {
	confirmPatientIdentity: "Confirm patient's identity(Name-surname, date of birth, etc.)?",
	hasThePatientSignConsent: "Has the patient sign consent(s)?",
	isTheSiteMarked: "Is the site marked?",
	isTheAnesthesiaMachineAndMedicationCheckComplete: "Is the anesthesia machine and medication check complete?",
	isThePulseOximeterOnthePatient: "Is the pulse oximeter on the patient and functioning?",
	doesThePatientHave: "Does the patient have a",
	knowAllergy: "Known allergy?",
	difficultAirwayOrAspirationRisk: "Difficult airway or Aspiration risk?",
	riskOfGreaterThan500mlBloodLoss: "Risk of > 500 ml blood loss(7 ml/kg in children)?",
	orEquipmentAndInstrument: "OR equipment and instrument?",
	medicalDeviceImplant: "Medical device implant?",

	allTeamMembersHaveIntroducedThemselves: "All members have introduced themselves by name and role?",
	patientNameDateOfBirth: "Patient's name-surname, date of birth?",
	procedureSiteAndSide: "Procedure site and side?",
	hasAntibioticProphylaxisBeenGivenWithinTheLast60Min: "Has antibiotic prophylaxis been given within the last 60 minutes?",
	anticipatedCriticalEvents: "Anticipated Critical Events",
	toSurgeon: "To Surgeon",
	whatAreTheCriticalOrNonroutineSteps: "What are the critical or non-routine steps?",
	howLongWillTheCaseTake: "How long will the case take?",
	whatIsTheAnticipatedBloodLoss: "What is the anticipated blood loss?",
	toAnesthesiologist: "To Anesthesiologist",
	areThereAnyPatientSpecificConcerns: "Are there any patient-specific concerns?",
	toNursingTeam: "To Nursing Team",
	hasSterilizationBeenConfirmed: "Has Sterilization(including indicators results) been confirmed?",
	areThereEquipmentIssuesOrAnyConcerns: "Are there equipment issues or any concerns?",
	essentialImagingDisplayed: "Essential imaging displayed?",

	nurseVerballyConfirms: "Nurse verbally confirms",
	theNameOfTheProcedure: "The name of the procedure?",
	completionOfInstrumentSpongeAndNeedleCounts: "Completion of instrument, sponge and needle counts?",
	specimenLabeling: "Specimen labeling(read specimen labels including patient name)?",
	whetherThereAreAnyEquipmentProblemsToBeAddressed: "Whether there are any equipment problems to be addressed?",
	toSurgeonAnesthesiologistAndNurse: "To Surgeon, Anesthesiologist and Nurse",
	whatAreTheKeyConcernsForRecoveryAndManagementOfThisPatient: "What are the key concerns for recovery and management of this patient?",

	beforeInductionOfAnesthesia: "Before induction of anesthesia with nurse and anesthetist",
	beforeSkinIncision: "Before skin incision with nurse, anesthetist and surgeon",
	beforePatientLeavesOperatingRoom: "Before patient leaves OR with nurse, anesthetist and surgeon",
}

export default SurgicalSafetyCheckList
