import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { contextMenu } from "react-contexify"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setARCTabGLGroups, setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_ARC_AR_ACTIVITIES,
	SET_ARC_AR_ACTIVITIES_LOADING,
	SET_ARC_MODAL_GENERAL_LEDGER_DISPLAY,
	SET_ARC_SELECTABLE_ACCOUNTING_CODES,
	SET_ARC_SELECTED_GROUP,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceiveRightTabGL extends React.Component {
	constructor(props) {
		super(props)

		this.onContextMenu = this.onContextMenu.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onCOA = this.onCOA.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.ARC.accountsReceivableActivities)) {
			this.loadAccountsReceivableActivities()
		}
		if (this.props.ARC.selectedReceipt.receipt) {
			this.props.setARCTabGLGroups([this.props.ARC.selectedReceipt.receipt])
		} else {
			this.props.setARCTabGLGroups([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.MENU.selectableAccountingCode.save)) {
					let newSelectableAccountingCodes = this.props.ARC.selectableAccountingCodes.concat([this.props.SAC.selectedAccountingCode.accountingCode])
					this.props.setValue(SET_ARC_SELECTABLE_ACCOUNTING_CODES, newSelectableAccountingCodes)
				}
			}
		}
		if (
			!_.isEqual(prevProps.ARC.selectedReceipt, this.props.ARC.selectedReceipt) ||
			!_.isEqual(prevProps.ARC.accountsReceivableActivities, this.props.ARC.accountsReceivableActivities) ||
			!_.isEqual(prevProps.ARC.selectableAccountingCodes, this.props.ARC.selectableAccountingCodes)
		) {
			if (this.props.ARC.selectedReceipt.receipt) {
				this.props.setARCTabGLGroups([this.props.ARC.selectedReceipt.receipt])
			} else {
				this.props.setARCTabGLGroups([])
			}
		}
		if (!_.isEqual(prevProps.ARC.tabGLTable.filtered, this.props.ARC.tabGLTable.filtered)) {
			let index = this.props.ARC.selectedGroup.group
				? this.props.ARC.tabGLTable.filtered.findIndex(
						(group) => group[0] && this.props.ARC.selectedGroup.group[0] && group[0].id === this.props.ARC.selectedGroup.group[0].id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_ARC_SELECTED_GROUP, index, this.props.ARC.tabGLTable.filtered[index])
			} else {
				this.props.setSelected(SET_ARC_SELECTED_GROUP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ARC_SELECTED_GROUP, -1, null)
	}

	isDisplayEdit() {
		return this.props.ARC.selectedReceipt.receipt && !this.props.ARC.selectedReceipt.receipt.accountsReceiveInspected
	}

	isDisplayDelete() {
		return this.props.ARC.selectedReceipt.receipt && !this.props.ARC.selectedReceipt.receipt.accountsReceiveInspected
	}

	loadAccountsReceivableActivities() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.generalLedger.getAccountsReceivableActivities,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_ARC_AR_ACTIVITIES, res.data.activities)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_ARC_AR_ACTIVITIES_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ARC_AR_ACTIVITIES_LOADING, false)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ARC_AR_ACTIVITIES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onContextMenu(e) {
		if (!this.props.ARC.selectedReceipt.receipt || this.props.ARC.selectedReceipt.receipt.accountsReceiveInspected) {
			return
		}

		let accountingCodes = []
		if (this.props.ARC.selectedReceipt.receipt.debt) {
			let selectedInsuranceCompany = this.props.ARC.insuranceCompanies.find((each) =>
				_.isEqual(each.displayName, this.props.ARC.selectedReceipt.receipt.debt.debtPlan.insuranceCompany)
			)
			if (selectedInsuranceCompany) {
				accountingCodes = this.props.ARC.accountsReceivableActivities.filter(
					(each) =>
						each.accountsReceiveSelectable &&
						selectedInsuranceCompany.activities.some(
							(activity) => activity.receivable && activity.linkGL && activity.chartOfAccounts.id === each.chartOfAccounts.id
						) &&
						!this.props.ARC.tabGLTable.filtered.some((eachR) => eachR[3].isSelectable && eachR[3].accountingCode.chartOfAccounts.id === each.chartOfAccounts.id)
				)
			}
		}
		contextMenu.show({
			id: ResourceAssistance.ContextMenu.accountsCode,
			event: e,
			props: {
				accountingCodes: accountingCodes,
			},
		})
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_ARC_SELECTED_GROUP, index, this.props.ARC.tabGLTable.filtered[index])
	}

	onCOA() {
		this.props.onModalDisplayAction(SET_ARC_MODAL_GENERAL_LEDGER_DISPLAY, true)
	}

	onDelete(e, row, rIdx, cIdx) {
		let newSelectableAccountingCodes = Array.from(
			this.props.ARC.selectableAccountingCodes.filter(
				(each) => each.chartOfAccounts.id !== this.props.ARC.tabGLTable.filtered[rIdx][3].accountingCode.chartOfAccounts.id
			)
		)
		this.props.setValue(SET_ARC_SELECTABLE_ACCOUNTING_CODES, newSelectableAccountingCodes)
	}

	render() {
		let totalDebit = this.props.ARC.tabGLTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[3]))
		}, Utils.BigNumber(0))
		let totalCredit = this.props.ARC.tabGLTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[4]))
		}, Utils.BigNumber(0))
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col onContextMenu={this.onContextMenu}>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[
							this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onCOA, this.onDelete]}
						data={this.props.ARC.tabGLTable}
						isClearHighlight={this.props.ARC.selectedGroup.index === -1}
						highlightedRow={this.props.ARC.selectedGroup.index}
						onClick={this.onSelectRow}
						extra={
							!_.isEmpty(this.props.ARC.tabGLTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
												color: totalCredit.eq(totalDebit) ? undefined : ResourceAssistance.CSS.Color.red,
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.debit)}>
												{Utils.formatNumWithComma(totalDebit.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.credit)}>
												{Utils.formatNumWithComma(totalCredit.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	SAC: state.menu.selectableAccountsCode,
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setARCTabGLGroups,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceiveRightTabGL))
