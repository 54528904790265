import { Divider, TreeSelect } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { CgRowLast } from "react-icons/cg"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdOutlineSubdirectoryArrowRight } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setConfigChartOfAccountsRevenueAccounts,
	setLoadingAction,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_COA_REVENUES,
	SET_ADM_COA_REVENUES_ACCOUNTS_LOADING,
	SET_ADM_COA_REVENUES_ADD_DISPLAY,
	SET_ADM_COA_REVENUES_FILTER_CODE,
	SET_ADM_COA_REVENUES_FILTER_NAME,
	SET_ADM_COA_REVENUES_SELECTED_ACCOUNT,
	SET_ADM_COA_REVENUES_SELECTED_REVENUE,
	SET_ADM_COA_REVENUES_ORIGINAL,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigRevenues extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigRevenues",
		}

		this.onRevenue = this.onRevenue.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadAccounts(
			ResourceAssistance.Url.admin.chartOfAccounts.getRevenues,
			(res) => {
				this.props.setValue(SET_ADM_COA_REVENUES_ORIGINAL, res.data.accounts)
				this.props.setValue(SET_ADM_COA_REVENUES, Utils.getChartOfAccounts(res.data.accounts))
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_REVENUES_ACCOUNTS_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_REVENUES_ACCOUNTS_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_REVENUES_ACCOUNTS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_COA_REVENUES_ORIGINAL, [])
		this.props.setValue(SET_ADM_COA_REVENUES, [])
		this.props.setValue(SET_ADM_COA_REVENUES_FILTER_CODE, "")
		this.props.setValue(SET_ADM_COA_REVENUES_FILTER_NAME, "")
		this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_REVENUE, -1, null)
		this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_ACCOUNT, -1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.revenue.originalRevenues, this.props.revenue.originalRevenues)) {
			this.props.setValue(SET_ADM_COA_REVENUES, Utils.getChartOfAccounts(this.props.revenue.originalRevenues))
		}
		if (!_.isEqual(prevProps.revenue.revenues, this.props.revenue.revenues)) {
			if (this.props.revenue.selectedRevenue.revenue) {
				let selectedRevenue = Utils.findChartOfAccounts(
					this.props.revenue.revenues,
					this.props.revenue.selectedRevenue.revenue
				)
				this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_REVENUE, selectedRevenue[0], selectedRevenue[1])
			} else {
				this.props.setConfigChartOfAccountsRevenueAccounts(this.props.revenue.revenues)
			}
		}
		if (
			!_.isEqual(prevProps.revenue.selectedRevenue, this.props.revenue.selectedRevenue) ||
			prevProps.revenue.filterCode !== this.props.revenue.filterCode ||
			prevProps.revenue.filterName !== this.props.revenue.filterName
		) {
			if (this.props.revenue.selectedRevenue.revenue) {
				this.props.setConfigChartOfAccountsRevenueAccounts(this.props.revenue.selectedRevenue.revenue.accounts)
			} else {
				this.props.setConfigChartOfAccountsRevenueAccounts(this.props.revenue.revenues)
			}
		}
		if (!_.isEqual(prevProps.revenue.accountTable.filtered, this.props.revenue.accountTable.filtered)) {
			this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_ACCOUNT, -1, null)
		}
	}

	onRevenue(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_REVENUE, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_COA_REVENUES_SELECTED_REVENUE,
				value,
				(function getSelectedRevenue(revenues, idx) {
					if (idx === keys.length - 1) {
						return revenues[keys[idx]]
					}
					return getSelectedRevenue(revenues[keys[idx]].accounts, idx + 1)
				})(this.props.revenue.revenues, 0)
			)
		} else {
			this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_REVENUE, value, this.props.revenue.revenues[value])
		}
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADM_COA_REVENUES_FILTER_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_COA_REVENUES_FILTER_NAME, event.target.value)
	}

	onSelectRow(row, index) {
		this.props.setSelected(
			SET_ADM_COA_REVENUES_SELECTED_ACCOUNT,
			index,
			this.props.revenue.accountTable.filtered[index]
		)
	}

	onAdd() {
		this.props.setSelected(SET_ADM_COA_REVENUES_SELECTED_ACCOUNT, -1, null)
		this.props.onModalDisplayAction(SET_ADM_COA_REVENUES_ADD_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_COA_REVENUES_ADD_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							allowClear
							treeLine={{ showLeafIcon: false }}
							placeholder={translate(ResourceAssistance.Message.revenues)}
							loading={this.props.revenue.accountTable.isLoading}
							value={this.props.revenue.selectedRevenue.revenue ? this.props.revenue.selectedRevenue.index : undefined}
							onChange={this.onRevenue}
							treeData={Utils.getTreeNodesFromChartOfAccounts(this.props.revenue.revenues, false)}
						/>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.revenue.selectedAccount.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							hover
							striped
							enableHighlight
							displayBtn
							buttonColors={[
								ResourceAssistance.Button.variant.outlineInfo,
								ResourceAssistance.Button.variant.outlineInfo,
							]}
							btnMessages={["", ""]}
							btnTypes={[EBtnType.POPOVER, EBtnType.POPOVER]}
							btnIcons={[
								<MdOutlineSubdirectoryArrowRight size={ResourceAssistance.ReactIcon.size} />,
								<CgRowLast size={ResourceAssistance.ReactIcon.size} />,
							]}
							data={this.props.revenue.accountTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.revenue.selectedAccount.index === -1}
							highlightedRow={this.props.revenue.selectedAccount.index}
						/>
						{this.props.revenue.accountTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	revenue: state.admin.configCOA.revenue,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setConfigChartOfAccountsRevenueAccounts,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigRevenues)
