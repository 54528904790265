import { Card } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { BsLink } from "react-icons/bs"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmCategoryAccountingPayable,
	setAdmWithholdingTaxTypes,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY,
	SET_ADM_CATEGORY_COA_SELECTED_AP,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"

class WithholdingTaxAccountsPayable extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ADM.category.withholdingTax.deletePayableActivity)) {
				this.deleteActivity()
			}
		}
		if (!_.isEqual(prevProps.type.selectedType, this.props.type.selectedType)) {
			if (this.props.type.selectedType.type) {
				this.props.setAdmCategoryAccountingPayable(this.props.type.selectedType.type.activities)
			} else {
				this.props.setAdmCategoryAccountingPayable([])
			}
			this.props.setSelected(SET_ADM_CATEGORY_COA_SELECTED_AP, -1, null)
		}
	}

	deleteActivity() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.type.deleteAccountingActivities,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				withholdingTax: true,
				activities: [
					{
						id: this.props.type.COA.selectedAP.activity.id,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setAdmWithholdingTaxTypes(res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onNew(e) {
		this.props.setSelected(SET_ADM_CATEGORY_COA_SELECTED_AP, -1, null)
		this.props.onModalDisplayAction(SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY, true)
	}

	onSelectRow(company, index) {
		this.props.setSelected(SET_ADM_CATEGORY_COA_SELECTED_AP, index, this.props.type.COA.accountingPayableTable.filtered[index])
	}

	onModify() {
		this.props.onModalDisplayAction(SET_ADM_CATEGORY_COA_PAYABLE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedAP = this.props.type.COA.accountingPayableTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningId(ResourceAssistance.ID.ADM.category.withholdingTax.deletePayableActivity)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>{selectedAP.chartOfAccounts.fullCode}</p>
				<p>{selectedAP.chartOfAccounts.displayName}</p>
				<p>{selectedAP.chartOfAccounts.description}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.accountsPayable)}</legend>
						<ScrollableTable
							enableOverlay
							enableHighlight
							striped
							hover
							displayBtn
							buttonColors={[
								[ResourceAssistance.Button.variant.outlineInfo],
								[],
								[],
								[],
								[],
								[],
								[],
								[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed],
							]}
							btnMessages={[
								[],
								[],
								[],
								[],
								[],
								[],
								[],
								[
									this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
									this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
								],
							]}
							btnTypes={[[EBtnType.POPOVER], [], [], [], [], [], []]}
							btnIcons={[
								[<BsLink size={ResourceAssistance.ReactIcon.size} />],
								[],
								[],
								[],
								[],
								[],
								[],
								[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />],
							]}
							onCheckedArray={[this.onModify, this.onDelete]}
							data={this.props.type.COA.accountingPayableTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.type.COA.selectedAP.index === -1}
							highlightedRow={this.props.type.COA.selectedAP.index}
							extra={
								this.props.type.selectedType.type &&
								!this.props.type.selectedType.type.skipPercent && (
									<Button
										style={{ fontSize: "medium" }}
										variant={ResourceAssistance.Button.variant.link}
										size={ResourceAssistance.Button.size.sm}
										onClick={this.onNew}
									>
										<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.new)}
									</Button>
								)
							}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmCategoryAccountingPayable,
			setAdmWithholdingTaxTypes,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(WithholdingTaxAccountsPayable))
