import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOPDICDs,
	setOPDModalDoctorOrderItems,
	setOPDModalNurseOrderItems,
	setObjArray,
	setValue,
} from "~/redux/action"
import {
	SET_OPD_ICD_TYPES,
	SET_OPD_ITEMS,
	SET_OPD_LEVELS,
	SET_OPD_LOACTION_DISPLAY,
	SET_OPD_MODAL_DOCTOR_ORDER_TYPES,
	SET_OPD_MODAL_DOCTOR_SERVICE_CATEGORIES,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES,
	SET_OPD_DOCTORS,
	SET_OPD_MODAL_NURSE_ORDER_TYPES,
	SET_OPD_MODAL_NURSE_SERVICE_CATEGORIES,
} from "~/redux/type"
import OutpatientLeft from "./left/OutpatientLeft"
import OutpatientRight from "./right/OutpatientRight"

class OutPatientDepartment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutPatientDepartment",
		}
	}

	componentDidMount() {
		this.loadServices()
		this.loadTypes()
		this.loadItems()
		this.loadICDs()
		this.loadLevels()
		this.loadUserTypes()
		this.props.onModalDisplayAction(SET_OPD_LOACTION_DISPLAY, true)
	}

	loadServices() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.opd.getServices}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_OPD_MODAL_DOCTOR_SERVICE_CATEGORIES, res.data.types)
			this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES, res.data.types)
			this.props.setObjArray(SET_OPD_MODAL_NURSE_SERVICE_CATEGORIES, res.data.types)
		}
		axios(params, callback)
	}

	loadTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(
				SET_OPD_MODAL_DOCTOR_ORDER_TYPES,
				res.data.types.filter((type) => type.role === ResourceAssistance.Enum.doctor)
			)
			this.props.setObjArray(
				SET_OPD_MODAL_NURSE_ORDER_TYPES,
				res.data.types.filter((type) => type.role === ResourceAssistance.Enum.nurse)
			)
		}
		axios(params, callback)
	}

	loadItems() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			let items = res.data.items.reduce((obj, cur) => {
				return Array.prototype.concat.apply(
					obj,
					cur.itemSupplierRelps.map((each) => {
						return {
							...each,
							type: cur.type,
							item: {
								id: cur.id,
								displayName: cur.displayName,
								keyword: cur.keyword,
								warnings: cur.warnings,
							},
						}
					})
				)
			}, [])
			this.props.setValue(SET_OPD_ITEMS, items)
			this.props.setOPDModalDoctorOrderItems(items)
			this.props.setOPDModalNurseOrderItems(items)
		}
		axios(params, callback)
	}

	loadICDs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getICDs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_OPD_ICD_TYPES, res.data.types)
		}
		axios(params, callback)
	}

	loadLevels() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getLevels,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_OPD_LEVELS, res.data.types)
		}
		axios(params, callback)
	}

	loadUserTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getUserTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(
				SET_OPD_DOCTORS,
				res.data.types.filter((each) => each.role === ResourceAssistance.Enum.doctor).flatMap((role) => role.users)
			)
		}
		axios(params, callback)
	}

	render() {
		return (
			<Fragment>
				{this.props.opd.location && (
					<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
						<Row className={"g-0 full-size"}>
							<Col style={{ maxWidth: "437px" }}>
								<OutpatientLeft />
							</Col>
							<Col>
								<OutpatientRight />
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDICDs,
			setOPDModalDoctorOrderItems,
			setOPDModalNurseOrderItems,
			setObjArray,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutPatientDepartment))
