import * as React from "react";

function SvgSvgPatientOnHand(props) {
  return (
    <svg
      id="SvgPatientOnHand_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 372.56 424.01"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".SvgPatientOnHand_svg__st0{fill:#41b0e7}.SvgPatientOnHand_svg__st1{fill:#65c8c6}"
        }
      </style>
      <path
        className="SvgPatientOnHand_svg__st0"
        d="M249.63 163.04c-21.83 21.24-28.02 44.91-19.23 74.76-3.66.63-6.93 1.68-10.19 1.69-34.28.13-68.56-.11-102.83.19-14.46.13-25.9-5.23-32.01-20.98-5.58-14.4-1.63-31.3 10.03-43.96 4.06-4.4 8.52-8.73 13.46-11.59 45.92-26.6 91.98-24.62 140.77-.11zM178.23 127.92c-29.65.01-53.9-24.25-54.27-54.3-.38-30.52 24.2-55.81 54.28-55.88 29.25-.07 53.94 24.64 54.41 54.45.49 30.3-24.34 55.72-54.42 55.73zM284.31 168c-23.38.2-42.89 18.59-42.96 42.7-.07 24.21 18.79 43.06 42.71 43.08 24.67.02 43.06-19.06 43.07-42.95 0-24.12-19.33-42.51-42.82-42.83zm-1.62 75.88c-16.72-.69-31.79-15.01-31.41-33.69.36-17.57 14.85-32.26 32.89-32.27 19.21-.02 33.4 15.85 32.97 32.97.6 17.17-14.37 33.82-34.45 32.99z"
      />
      <path
        className="SvgPatientOnHand_svg__st0"
        d="M302.73 203.14H292v-10.72a4.39 4.39 0 00-4.39-4.39h-6.72a4.39 4.39 0 00-4.39 4.39v10.72h-10.73a4.39 4.39 0 00-4.39 4.39v6.71a4.39 4.39 0 004.39 4.39h10.74v10.72a4.39 4.39 0 004.39 4.39h6.72a4.39 4.39 0 004.39-4.39v-10.72h10.73a4.39 4.39 0 004.39-4.39v-6.71a4.398 4.398 0 00-4.4-4.39z"
      />
      <g>
        <path
          className="SvgPatientOnHand_svg__st1"
          d="M78.98 369.4c9.93.15 19.31 2.42 28.41 6.37 12.82 5.57 25.71 11.01 38.7 16.17 14.55 5.77 29.08 5.06 42.95-2.07 46.83-24.07 93.55-48.33 140.28-72.59 8.43-4.37 11.78-13.2 8.49-21.39-3.28-8.16-11.75-12.09-20.95-9.5-21.58 6.09-43.13 12.28-64.7 18.43-.63.18-1.25.42-1.9.56-5.3 1.19-9.52-1.14-10.84-5.95-1.24-4.5 1.24-8.78 6.28-10.29 8.21-2.46 16.48-4.72 24.73-7.08 13.83-3.95 27.64-7.95 41.48-11.86 17.73-5.01 34.85 2.95 41.5 19.22 6.71 16.42-.02 34.38-16.51 42.95-46.35 24.12-92.74 48.17-139.21 72.08-19.99 10.29-40.47 10.74-61.17 1.91-12.62-5.38-25.25-10.73-37.93-15.97-11.81-4.89-23.84-5.34-36-1.34-8.64 2.85-17.26 5.77-25.91 8.6-5.81 1.9-10.31.04-12.01-4.87-1.68-4.86.8-9.32 6.61-11.11 12.84-3.96 25.71-7.83 38.64-11.48 2.88-.79 6.04-.55 9.06-.79z"
        />
        <path
          className="SvgPatientOnHand_svg__st1"
          d="M135.57 261.53c19.11.74 37.32 4.62 54.9 11.69 7.1 2.85 14.22 5.66 21.3 8.56 16.32 6.68 24.33 23.3 19.02 39.37-5.26 15.9-21.42 24.53-38.35 20.34-19.34-4.79-38.65-9.69-57.97-14.55-5.96-1.5-8.81-5.46-7.55-10.47 1.27-5.07 5.67-7.38 11.52-5.92 19.2 4.79 38.38 9.66 57.58 14.44 11.2 2.79 20.26-4.43 19.26-15.28-.44-4.82-2.69-8.94-7.04-10.8-17.1-7.27-34.01-15.1-52.61-18.09-21.04-3.39-41.51-1.01-61.8 5.09-21.47 6.46-43.1 12.41-64.65 18.58-.89.25-1.77.54-2.67.74-4.74 1.03-9.15-1.41-10.36-5.7-1.32-4.65.99-9.12 5.85-10.59 7.95-2.42 15.97-4.62 23.97-6.86 19.81-5.55 39.53-11.51 59.5-16.41 9.77-2.4 20.05-2.82 30.1-4.14z"
        />
      </g>
    </svg>
  );
}

export default SvgSvgPatientOnHand;
