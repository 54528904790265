const ReportMessage = {
	withholdingTaxWithholder: "reportWithholdingTaxWithholder",
	withholdingTaxPayer: "reportWithholdingTaxPayer",
	formNumClarify: "reportFormNumClarify",
	typeOfIncomePaid: "reportTypeOfIncomePaid",
	salaryWagesEtcSection401: "reportSalaryWagesEtcSection401",
	feeCommissionsSection402: "reportFeeCommissionsSection402",
	copyrightSection403: "reportCopyrightSection403",
	interestSection404a: "reportInterestSection404a",
	dividendsSection404b: "reportDividendsSection404b",
	dividendEntitledToTaxCreditByRate: "reportDividendEntitledToTaxCreditByRate",
	_30PercentOfNetProfit: "report30PercentOfNetProfit",
	_25PercentOfNetProfit: "report25PercentOfNetProfit",
	_20PercentOfNetProfit: "report20PercentOfNetProfit",
	otherPercentOfNetProfit: "reportOtherPercentOfNetProfit",
	dividendEntitledToTaxCreditBy: "reportDividendEntitledToTaxCreditBy",
	netProfitOfBusinessExemptFromCorporateIncomeTax: "reportNetProfitOfBusinessExemptFromCorporateIncomeTax",
	dividendOrProfitSharesAreExcept: "reportDividendOrProfitSharesAreExcept",
	netProfitDeductedFromNetLosses: "reportNetProfitDeductedFromNetLosses",
	profitRecognizedByTheEquityMethod: "reportProfitRecognizedByTheEquityMethod",
	paymentOfIncomeSubjectToWithholdingTaxAccordingTo: "reportPaymentOfIncomeSubjectToWithholdingTaxAccordingTo",
	governmentPensionFundOrGovernmentPermanentExployee: "reportGovernmentPensionFundOrGovernmentPermanentExployee",
	withholdAtSource: "reportWithholdAtSource",
	payEveryTime: "reportPayEveryTime",
	payOneTime: "reportPayOneTime",
	iHereByCertify: "reportIHereByCertify",
	dayMonthYearThatTheCertificateWasIssued: "reportDayMonthYearThatTheCertificateWasIssued",
	stampedByLegalEntity: "reportStampedByLegalEntity",
	thePersonResponsibleForIssuingWithholdingTaxCertificate: "reportThePersonResponsibleForIssuingWithholdingTaxCertificate",
	totalAmountIncomeAndWithholdingTaxIntl: "reportTotalAmountIncomeAndWithholdingTaxIntl",
	totalWithholdingTaxInLetters: "reportTotalWithholdingTaxInLetters",
	theCashierReceivedADeposit: "",
}

export default ReportMessage
