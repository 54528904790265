import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_ARC_BILLING_STATEMENT, SET_ARC_RECEIPTS, SET_ARC_TAB_GL_GROUP, SET_ARC_TAB_RECEIPT_GROUP } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setAccountsReceiveReceipts = (receipts) => {
	return (dispatch, getState) => {
		let filtered = receipts
			.filter((receipt) => receipt.id.includes(getState().accounting.accountsReceive.filterReceiptId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))
		if (_.includes(getState().accounting.accountsReceive.filters, ResourceAssistance.Message.incomplete)) {
			filtered = filtered.filter((receipt) => !receipt.complete)
		}
		let rowColor = filtered.map((receipt) => {
			let color = ""
			if (receipt.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})
		let body = filtered.map((receipt) => {
			return [Utils.formatDate(receipt.creationDateTime), receipt.id]
		})

		dispatch({
			type: SET_ARC_RECEIPTS,
			payload: {
				original: receipts,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setARCTabReceiptGroups = (billings) => {
	return (dispatch, getState) => {
		let filtered = Object.entries(
			Utils.groupBy(
				billings.reduce((ary, billing) => {
					return ary.concat(billing.billingStatements)
				}, []),
				"categoryName"
			)
		).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = filtered.map(([key, values]) => {
			let totals = values.reduce(
				(totalArray, cur) => {
					totalArray[0] = totalArray[0].plus(cur.charge)
					totalArray[1] = totalArray[1].plus(cur.adjustment)
					return totalArray
				},
				[Utils.BigNumber(0), Utils.BigNumber(0)]
			)
			return [
				key,
				Utils.formatNumWithComma(totals[0].toFixed(2)),
				Utils.formatNumWithComma(totals[1].toFixed(2)),
				Utils.formatNumWithComma(totals[0].minus(totals[1]).toFixed(2)),
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_ARC_TAB_RECEIPT_GROUP,
			payload: {
				original: billings,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setARCTabGLGroups = (billings) => {
	return (dispatch, getState) => {
		let filtered = []
		let body = []
		billings.forEach((billing) => {
			if (_.isEmpty(billing.accountsReceiveGLs)) {
				if (Utils.BigNumber(billing.total).isGreaterThan(0) || (Utils.BigNumber(billing.total).isEqualTo(0) && !billing.debt)) {
					filtered.push([
						billing,
						billing.billingStatements,
						{ accountingCode: billing.accountingCode, accountingTransDesc: billing.accountingTransDescr },
						{ isBilling: true, id: billing.id },
					])
					body.push({
						color: "",
						values: [
							_.isEmpty(billing.accountingCode) ? [true] : billing.accountingCode,
							_.isEmpty(billing.accountingCode) ? "" : billing.accountingChartName,
							_.isEmpty(billing.accountingCode)
								? ""
								: Utils.decodeAccountingTransDescr(billing.accountingTransDescr, "", Utils.formatDate(billing.creationDateTime)),
							Utils.formatNumWithComma(Utils.BigNumber(billing.total).toFixed(2)),
							"",
							[!_.isEmpty(billing.accountingCode), false],
						],
					})
				}
				if (billing.debt) {
					filtered.push([
						billing,
						billing.billingStatements,
						{ accountingCode: billing.debt.accountingCode, accountingTransDesc: billing.debt.accountingTransDescr },
						{ isDebt: true, id: billing.debt.id },
					])
					body.push({
						color: "",
						values: [
							_.isEmpty(billing.debt.accountingCode) ? [true] : billing.debt.accountingCode,
							_.isEmpty(billing.debt.accountingCode) ? "" : billing.debt.accountingChartName,
							_.isEmpty(billing.debt.accountingCode)
								? ""
								: Utils.decodeAccountingTransDescr(billing.debt.accountingTransDescr, "", Utils.formatDate(billing.creationDateTime)),
							Utils.formatNumWithComma(Utils.BigNumber(billing.debt.amount).toFixed(2)),
							"",
							[!_.isEmpty(billing.debt.accountingCode), false],
						],
					})
				}
			} else {
				body = billing.accountsReceiveGLs
					.sort((a, b) => Utils.sort(a.credit, b.credit))
					.map((gl) => {
						filtered.push([billing, billing.billingStatements, {}, {}])
						return {
							color: gl.systemGenerated ? ResourceAssistance.CSS.Color.green : "",
							values: [
								gl.code,
								gl.name,
								gl.transactionDescription,
								Utils.formatNumWithComma(Utils.BigNumber(gl.debit).toFixed(2)),
								Utils.formatNumWithComma(Utils.BigNumber(gl.credit).toFixed(2)),
								[false, false],
							],
						}
					})
			}
		})

		//Configed GL Activities
		let ARs = getState()
			.accounting.accountsReceive.accountsReceivableActivities.filter((each) => !each.accountsReceiveSelectable)
			.concat(getState().accounting.accountsReceive.selectableAccountingCodes)
			.sort((a, b) => Utils.sort(b.chartOfAccounts.fullCode, a.chartOfAccounts.fullCode))
		let insuranceCompanies = getState().accounting.accountsReceive.insuranceCompanies
		let payments = getState().accounting.accountsReceive.payments
		let banks = getState().accounting.accountsReceive.banks
		billings.forEach((billing) => {
			if (billing && _.isEmpty(billing.accountsReceiveGLs)) {
				if (billing.debt) {
					let selectedInsuranceCompany = insuranceCompanies.find((each) => _.isEqual(each.displayName, billing.debt.debtPlan.insuranceCompany))
					if (selectedInsuranceCompany) {
						ARs.forEach((each) => {
							if (
								selectedInsuranceCompany.activities.some(
									(activity) => activity.receivable && activity.linkGL && activity.chartOfAccounts.id === each.chartOfAccounts.id
								)
							) {
								let filteredBillingStatements
								if (each.includeIPD && each.includeOPD) {
									filteredBillingStatements = billing.billingStatements
								} else if (each.includeOPD) {
									filteredBillingStatements = billing.billingStatements.filter((each) => each.order.outpatient)
								} else {
									filteredBillingStatements = billing.billingStatements.filter((each) => !each.order.outpatient)
								}
								let total = Utils.BigNumber(0)
								if (each.includeBalance) {
									total = total.plus(billing.debt.total).minus(billing.total)
								}
								if (each.includeDiscount) {
									total = total.minus(
										filteredBillingStatements.reduce((total, cur) => {
											return total.plus(cur.adjustment).plus(cur.billAdjustment)
										}, Utils.BigNumber(0))
									)
								}
								if (each.includeTransactionFee) {
									total = total.plus(billing.debt.transactionFee)
								}
								if (each.includeWithholdingTax) {
									total = total.plus(billing.debt.withholdingTaxAmount)
								}
								if (each.includeBalanceAdjustment) {
									total = total.plus(billing.debt.balanceAdjustment)
								}
								if (total.abs().isGreaterThan(0)) {
									if (each.debit) {
										filtered.unshift([
											billing,
											filteredBillingStatements,
											{},
											{
												isSelectable: each.accountsReceiveSelectable,
												accountingCode: each,
											},
										])
										body.unshift({
											color: ResourceAssistance.CSS.Color.green,
											values: [
												each.chartOfAccounts.fullCode,
												each.chartOfAccounts.displayName,
												Utils.decodeAccountingTransDescr(each.description, "", Utils.formatDate(billing.debt.debtPlan.paymentDateTime)),
												each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												[false, each.accountsReceiveSelectable],
											],
										})
									} else {
										filtered.push([
											billing,
											filteredBillingStatements,
											{},
											{
												isSelectable: each.accountsReceiveSelectable,
												accountingCode: each,
											},
										])
										body.push({
											color: ResourceAssistance.CSS.Color.green,
											values: [
												each.chartOfAccounts.fullCode,
												each.chartOfAccounts.displayName,
												Utils.decodeAccountingTransDescr(each.description, "", Utils.formatDate(billing.debt.debtPlan.paymentDateTime)),
												each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												[false, each.accountsReceiveSelectable],
											],
										})
									}
								}
							}
						})
					}
					filtered.push([
						billing,
						billing.billingStatements,
						{
							accountingCode: billing.debt.debtPlan.accountingCode,
							accountingTransDesc: billing.debt.debtPlan.accountingTransDescr,
						},
						{
							isDebtPlan: true,
							id: billing.debt.debtPlan.id,
						},
					])
					body.push({
						color: "",
						values: [
							_.isEmpty(billing.debt.debtPlan.accountingCode) ? [true] : billing.debt.debtPlan.accountingCode,
							_.isEmpty(billing.debt.debtPlan.accountingCode) ? "" : billing.debt.debtPlan.accountingChartName,
							_.isEmpty(billing.debt.debtPlan.accountingCode)
								? ""
								: Utils.decodeAccountingTransDescr(billing.debt.debtPlan.accountingTransDescr, "", Utils.formatDate(billing.debt.debtPlan.paymentDateTime)),
							"",
							Utils.formatNumWithComma(Utils.BigNumber(billing.debt.total).minus(billing.total).toFixed(2)),
							[!_.isEmpty(billing.debt.debtPlan.accountingCode), false],
						],
					})
				}
				if (Utils.BigNumber(billing.total).isGreaterThan(0) || (Utils.BigNumber(billing.total).isEqualTo(0) && !billing.debt)) {
					let selectedPayment = payments.find((each) => _.isEqual(each.displayName, billing.payment))
					if (selectedPayment.bankAccount) {
						let bank = banks.find((each) => _.isEqual(each.displayName, billing.bank))
						if (bank) {
							selectedPayment = bank.branches.find((each) => _.isEqual(each.displayName, billing.bankBranch))
						}
					}
					if (selectedPayment) {
						ARs.forEach((each) => {
							if (
								selectedPayment.activities.some((activity) => activity.receivable && activity.linkGL && activity.chartOfAccounts.id === each.chartOfAccounts.id)
							) {
								let filteredBillingStatements
								if (each.includeIPD && each.includeOPD) {
									filteredBillingStatements = billing.billingStatements
								} else if (each.includeOPD) {
									filteredBillingStatements = billing.billingStatements.filter((each) => each.order.outpatient)
								} else {
									filteredBillingStatements = billing.billingStatements.filter((each) => !each.order.outpatient)
								}
								let total = Utils.BigNumber(0)
								if (each.includeBalance) {
									total = total.plus(billing.total).plus(
										filteredBillingStatements.reduce((total, cur) => {
											return total.plus(cur.adjustment).plus(cur.billAdjustment)
										}, Utils.BigNumber(0))
									)
								}
								if (each.includeDiscount) {
									total = total.minus(
										filteredBillingStatements.reduce((total, cur) => {
											return total.plus(cur.adjustment).plus(cur.billAdjustment)
										}, Utils.BigNumber(0))
									)
								}
								if (total.abs().isGreaterThan(0)) {
									if (each.debit) {
										filtered.unshift([billing, filteredBillingStatements, {}, {}])
										body.unshift({
											color: ResourceAssistance.CSS.Color.green,
											values: [
												each.chartOfAccounts.fullCode,
												each.chartOfAccounts.displayName,
												Utils.decodeAccountingTransDescr(each.description, "", Utils.formatDate(billing.creationDateTime)),
												each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												[false, false],
											],
										})
									} else {
										filtered.push([billing, filteredBillingStatements, {}, {}])
										body.push({
											color: ResourceAssistance.CSS.Color.green,
											values: [
												each.chartOfAccounts.fullCode,
												each.chartOfAccounts.displayName,
												Utils.decodeAccountingTransDescr(each.description, "", Utils.formatDate(billing.creationDateTime)),
												each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
												[false, false],
											],
										})
									}
								}
							}
						})
					}
				}
			}
		})

		let rowColor = body.map((each) => {
			return [!_.isEmpty(each.color), each.color]
		})

		let colStyle = body.map(() => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_ARC_TAB_GL_GROUP,
			payload: {
				original: billings,
				filtered: filtered,
				body: body.map((each) => each.values),
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setARCBillingStatements = (billingStatements) => {
	return (dispatch, getState) => {
		let filtered = billingStatements.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((bs) => {
			return [
				bs.name,
				Utils.formatNumWithComma(Utils.BigNumber(bs.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(bs.adjustment).toFixed(2)) +
					" (" +
					Utils.BigNumber(bs.adjustment).dividedBy(bs.charge).times(100).toFixed(2) +
					"%)",
				bs.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(bs.billAdjustment).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(bs.charge).minus(bs.adjustment).toFixed(2)),
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_ARC_BILLING_STATEMENT,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
