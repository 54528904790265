import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportOPDVitalSignDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				vitalSigns: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID/{this.state.data.identificationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.identification}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age/{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB/{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Item Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Date</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>BT. (C)</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>PR./min</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>RR./min</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>BP.mmHg</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>O{ResourceAssistance.Symbol[2]}Sat %</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Vital Sign */}
						{this.state.data.vitalSigns.map((vitalSign) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{Utils.formatDate(vitalSign.dateTime)}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{Utils.formatTime(vitalSign.dateTime)}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{vitalSign.temperature}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{vitalSign.pr}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{vitalSign.rr}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{vitalSign.bp}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{vitalSign.oxygenSat}</Text>
									</View>
								</View>
							)
						})}
						<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
})

export default ReportOPDVitalSignDocument
