import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportCashierByUserDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.professionalServiceExpenseByUser,
				orders: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayOrg={false}
						displayTaxNum={false}
						displayFax={false}
						displayTel={false}
						displayLogo={false}
						displayAddress={false}
					/>

					{/* Filter */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Start Date/{this.state.data.startDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
							<Text>{this.state.data.startDate}</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>End Date/{this.state.data.endDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
							<Text>{this.state.data.endDate}</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Discharged Only/{this.state.data.dischargedOnlyIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text style={[ResourceAssistance.Report.styles.textIcon]}>
								{this.state.data.dischargedOnly ? ResourceAssistance.Symbol.Report.check : ResourceAssistance.Symbol.Report.cancel}
							</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Role/{this.state.data.roleIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
							<Text>{this.state.data.role}</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Name/{this.state.data.nameIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
							<Text>{this.state.data.name}</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>License #/{this.state.data.licenseNumIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
							<Text>{this.state.data.licenseNum}</Text>
						</View>
					</View>
					<View style={ResourceAssistance.Report.styles.separator} />

					{/* Service Header */}
					<View wrap={false}>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.dateIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pid]}></View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.heathCare]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.healthCareIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Date</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pid]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>PID</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.healthCare]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Health Care</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.description]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>List</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
					</View>

					{/* Services */}
					{this.state.data.orders
						.sort((a, b) =>
							Utils.sort(a.pid, b.pid, [
								[a.date, b.date],
								[a.description, b.description],
							])
						)
						.map((order) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{order.date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pid]}>
										<Text>{order.pid}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.healthCare]}>
										<Text>{order.healthCare}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.description]}>
										<Text>{order.description}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{order.amount}</Text>
									</View>
								</View>
							)
						})}

					<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />

					{/* Grand Total */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>

						<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
							<View style={ResourceAssistance.Report.styles.row}>
								<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Grand Total/{this.state.data.grandTotalIntl}</Text>
								</View>
								<View style={ResourceAssistance.Report.styles.col}>
									<Text>{this.state.data.grandTotal}</Text>
								</View>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
		textAlign: "right",
		marginRight: 10,
	},
	itemRow: {
		fontSize: 16,
	},
	line: {
		borderTopColor: "black",
		borderTopWidth: 1,
		flexGrow: 1,
	},
	date: {
		minWidth: "11%",
		maxWidth: "11%",
	},
	pid: {
		minWidth: "8%",
		maxWidth: "8%",
	},
	healthCare: {
		minWidth: "17%",
		maxWidth: "17%",
	},
	description: {
		minWidth: "52%",
		maxWidth: "52%",
	},
	amount: {
		minWidth: "12%",
		maxWidth: "12%",
	},
})

export default ReportCashierByUserDoc
