import React, { Component } from "react"
import { InboxOutlined } from "@ant-design/icons"
import { ResourceAssistance, translate } from "~/i18n"
import { message, Spin, Upload } from "antd"
import { FaRegTrashAlt } from "react-icons/fa"

import "./fileUpload.css"

const { Dragger } = Upload

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})

const handlePreview = async (file) => {
	if (!file.url && !file.preview) {
		file.preview = await getBase64(file)
	}
	return file.preview
}

export default class FileUpload extends Component {
	constructor(props) {
		super(props)

		this.state = {
			preview: "",
			logoType: "",
			logoLoading: true,
			isLogoHover: false,
		}

		this.cancelUploadHandler = this.cancelUploadHandler.bind(this)
	}

	getUploadProps() {
		return {
			name: "file",
			accept: "image/png, image/jpeg",
			multiple: false,
			showUploadList: false,
			beforeUpload: async (file) => {
				const isImg = file.type === "image/jpeg" || file.type === "image/png"

				if (!isImg) {
					message.error(`${file.name} is not PNG or JPEG file.`)
					return false
				}

				const isLightSize = file.size / 1024 <= 200

				if (!isLightSize) {
					message.error(`${file.name} size over than 200KB.`)
					return false
				}

				const preview = await handlePreview(file)
				this.setState({ preview: String(preview).split(";base64,")[1] })
				this.props.setLogoFile(file)
				return false
			},
		}
	}

	cancelUploadHandler() {
		this.setState({ preview: "" })
		this.props.setLogoFile("")
	}

	componentDidMount() {
		if (this.props.data) {
			const locationData = this.props.data

			this.setState({
				logoType: locationData.logoName?.split(".")[1] || "jpeg",
				preview: locationData.encodedFile,
				logoLoading: false,
			})
		} else {
			this.setState({ logoLoading: false })
		}
	}

	getPreviewElement() {
		if (this.state.preview !== "error") {
			return (
				<img className="logo" src={`${this.state.logoType === "png" ? "data:image/png;base64" : "data:image/jpeg;base64"}, ${this.state.preview}`} alt="Logo" />
			)
		} else {
			return (
				<div className="logo-loaderror">
					<p>{translate(ResourceAssistance.Message.somethingWrong)}</p>
					<p>{translate(ResourceAssistance.Message.cannotDownloadLogo)}</p>
				</div>
			)
		}
	}

	render() {
		return (
			<div className="upload-container">
				{this.state.logoLoading ? (
					<Spin size="large" />
				) : !this.state.preview ? (
					<Dragger className="file-upload" {...this.getUploadProps()}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">
							<span className="requireMark">*</span>
							{translate(ResourceAssistance.Message.logoDragDropTitle)}
						</p>
						<p className="ant-upload-hint">{translate(ResourceAssistance.Message.logoDragDropDescription)}</p>
					</Dragger>
				) : (
					this.getPreviewElement()
				)}

				{this.state.preview && (
					<div className="cancel-btn" onClick={this.cancelUploadHandler}>
						<FaRegTrashAlt size={ResourceAssistance.ReactIcon.size} color="#9ca3af" />
					</div>
				)}
			</div>
		)
	}
}
