import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setCashierModalDoctorPendingOrders, setSelected } from "~/redux/action"
import { SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierModalDoctorPendingOrder extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.cashier.modalDoctor.pendingOrderTable.original,
				this.props.cashier.modalDoctor.pendingOrderTable.original
			)
		) {
			this.props.setSelected(SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER, -1, null)
		this.props.setCashierModalDoctorPendingOrders([])
	}

	onSelect(lab, index) {
		this.props.setSelected(
			SET_CASHIER_MODAL_DOCTOR_SELECTED_PENDING_ORDER,
			index,
			this.props.cashier.modalDoctor.pendingOrderTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						bordered
						hover
						isClearHighlight={this.props.cashier.modalDoctor.selectedPendingOrder.index === -1}
						data={this.props.cashier.modalDoctor.pendingOrderTable}
						onClick={this.onSelect}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierModalDoctorPendingOrders,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalDoctorPendingOrder)
