import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSurgicalAuthOperatingRoomRequests, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_SA_BUDGET_DISPLAY, SET_SA_HEALTH_CARE_PLANS, SET_SA_HEALTH_CARE_PLANS_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SurgicalAuthModalBudgetDetails from "./SurgicalAuthModalBudgetDetails"

class SurgicalAuthModalBudget extends React.Component {
	constructor(props) {
		super(props)

		this.firstLevelRef = React.createRef()

		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.surgicalAuth.healthcarePlans)) {
			this.loadHealthCarePlans()
		}
	}

	loadHealthCarePlans() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.surgicalAuth.getHealthCarePlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_SA_HEALTH_CARE_PLANS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_SA_HEALTH_CARE_PLANS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_SA_HEALTH_CARE_PLANS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_SA_HEALTH_CARE_PLANS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	reset() {
		this.props.onModalDisplayAction(SET_SA_BUDGET_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.firstLevelRef.current || this.firstLevelRef.current.state.budgets.some((each) => !each.selectedHealthCarePlan.healthcarePlan)) {
			return true
		}
		let target = Object.assign({}, this.props.surgicalAuth.selectedORRequest.request, {
			doctorFee: Utils.BigNumber(this.props.surgicalAuth.modalBudget.doctorFee).toNumber(),
			anesthesiologistFee: Utils.BigNumber(this.props.surgicalAuth.modalBudget.anesthesiologistFee).toNumber(),
			diagnosisRelatedGroup: Utils.BigNumber(this.props.surgicalAuth.modalBudget.DRG).toNumber(),
			diagnosisRelatedGroupPrice: Utils.BigNumber(this.props.surgicalAuth.workflowProperty.drg).toNumber(),
			otherBudgets: this.firstLevelRef.current.state.budgets
				.filter((each) => each.selectedHealthCarePlan.healthcarePlan && !_.isEmpty(Utils.trim(each.description)) && Utils.BigNumber(each.amount).gt(0))
				.reduce((result, cur) => {
					return result.concat(
						ResourceAssistance.PROGRAM_DEFINED.split,
						cur.selectedHealthCarePlan.healthcarePlan.displayName,
						ResourceAssistance.PROGRAM_DEFINED.split,
						Utils.trim(cur.description),
						ResourceAssistance.PROGRAM_DEFINED.split,
						cur.amount
					)
				}, ""),
		})
		return _.isEqual(this.props.surgicalAuth.selectedORRequest.request, target)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.surgicalAuth.updateOperatingRoomRequestBudgets,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.surgicalAuth.selectedORRequest.request.id,
				userId: this.props.login.user.id,
				requests: [
					{
						id: this.props.surgicalAuth.selectedORRequest.request.id,
						doctorFee: this.props.surgicalAuth.modalBudget.doctorFee,
						anesthesiologistFee: this.props.surgicalAuth.modalBudget.anesthesiologistFee,
						diagnosisRelatedGroup: this.props.surgicalAuth.modalBudget.DRG,
						diagnosisRelatedGroupPrice: this.props.surgicalAuth.workflowProperty.drg,
						otherBudgets: this.firstLevelRef.current.state.budgets
							.filter((each) => each.selectedHealthCarePlan.healthcarePlan && !_.isEmpty(Utils.trim(each.description)) && Utils.BigNumber(each.amount).gt(0))
							.reduce((result, cur) => {
								return result.concat(
									ResourceAssistance.PROGRAM_DEFINED.split,
									cur.selectedHealthCarePlan.healthcarePlan.displayName,
									ResourceAssistance.PROGRAM_DEFINED.split,
									Utils.trim(cur.description),
									ResourceAssistance.PROGRAM_DEFINED.split,
									cur.amount
								)
							}, ""),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.surgicalAuth.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setSurgicalAuthOperatingRoomRequests(requests)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				size={ResourceAssistance.Modal.size.xl}
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.totalEstimatedBudget)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<SurgicalAuthModalBudgetDetails firstLevelRef={this.firstLevelRef} onRenderParentCallback={this.onRenderParentCallback} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isSurgicalAuthBudgetDisplay,
	login: state.login,
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSurgicalAuthOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SurgicalAuthModalBudget))
