import { Select } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS, SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class ContractBillingModalLocationOrg extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
	}

	componentDidMount() {
		this.props.loadLocations()
	}

	componentWillUnmount() {
		this.props.setValue(SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS, [])
	}

	onOrg(v) {
		if (v === undefined) {
			this.props.setSelected(SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG, -1, null)
			return
		}
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG, v, this.props.contract.modalLocation.orgs[v])
	}

	render() {
		return (
			<Row>
				<Col>
					<Select
						showSearch
						style={{ textAlign: "center", margin: 0 }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.org)}
						loading={this.props.contract.modalLocation.isOrgsLoading}
						value={this.props.contract.modalLocation.selectedOrg.org ? this.props.contract.modalLocation.selectedOrg.index : undefined}
						onChange={this.onOrg}
					>
						{Utils.renderSelects(this.props.contract.modalLocation.orgs, false)}
					</Select>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalLocationOrg)
