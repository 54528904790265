import { Radio } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED,
} from "~/redux/type"

class OperatingRoomModalSurgicalSafetyCheckListTimeOutDetail extends React.Component {
	constructor(props) {
		super(props)

		this.onConfirmTeamMembers = this.onConfirmTeamMembers.bind(this)
		this.onPatientName = this.onPatientName.bind(this)
		this.onProcedureSiteAndSide = this.onProcedureSiteAndSide.bind(this)
		this.onAntibiotic = this.onAntibiotic.bind(this)
		this.onCriticalSteps = this.onCriticalSteps.bind(this)
		this.onCaseDuration = this.onCaseDuration.bind(this)
		this.onAnticipatedBloodLoss = this.onAnticipatedBloodLoss.bind(this)
		this.onPatientConcerns = this.onPatientConcerns.bind(this)
		this.onSterilizationConfirmed = this.onSterilizationConfirmed.bind(this)
		this.onEquipmentIssues = this.onEquipmentIssues.bind(this)
		this.onEssentialImagingDisplayed = this.onEssentialImagingDisplayed.bind(this)
	}

	componentDidMount() {
		let checklist = this.props.operatingRoom.selectedSurgicalSafetyCheckList.checklist
		if (this.props.operatingRoom.selectedRequest.request && checklist) {
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS, checklist.timeOutConfirmTeamMembers)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME, checklist.timeOutPatientName)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE, checklist.timeOutProcedureSiteAndSide)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC, checklist.timeOutAntibiotic)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS, checklist.timeOutCriticalSteps)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION, checklist.timeOutCaseDuration)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS, checklist.timeOutAnticipatedBloodLoss)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS, checklist.timeOutPatientSpecificConcerns)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED, checklist.timeOutSterilizationConfirmed)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES, checklist.timeOutEquipmentIssues)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED, checklist.timeOutEssentialImagingDisplayed)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.allTeamMembersHaveIntroducedThemselves)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isConfirmTeamMembers} onChange={this.onConfirmTeamMembers}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.patientNameDateOfBirth)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isPatientName} onChange={this.onPatientName}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.procedureSiteAndSide)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isProcedureSiteAndSide} onChange={this.onProcedureSiteAndSide}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.hasAntibioticProphylaxisBeenGivenWithinTheLast60Min)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isAntibiotic} onChange={this.onAntibiotic}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.anticipatedCriticalEvents)}</legend>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.toSurgeon)}</legend>
						<Row>
							<Col md={8}>
								<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.whatAreTheCriticalOrNonroutineSteps)}:</label>
							</Col>
							<Col md="auto">
								<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isCriticalSteps} onChange={this.onCriticalSteps}>
									<Radio style={{ flexDirection: "row" }} value={true}>
										{translate(ResourceAssistance.Message.yes)}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={false}>
										{translate(ResourceAssistance.Message.no)}
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
						<Row>
							<Col md={8}>
								<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.howLongWillTheCaseTake)}:</label>
							</Col>
							<Col md="auto">
								<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isCaseDuration} onChange={this.onCaseDuration}>
									<Radio style={{ flexDirection: "row" }} value={true}>
										{translate(ResourceAssistance.Message.yes)}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={false}>
										{translate(ResourceAssistance.Message.no)}
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
						<Row>
							<Col md={8}>
								<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.whatIsTheAnticipatedBloodLoss)}:</label>
							</Col>
							<Col md="auto">
								<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isAnticipatedBloodLoss} onChange={this.onAnticipatedBloodLoss}>
									<Radio style={{ flexDirection: "row" }} value={true}>
										{translate(ResourceAssistance.Message.yes)}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={false}>
										{translate(ResourceAssistance.Message.no)}
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
					</fieldset>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.toAnesthesiologist)}</legend>
						<Row>
							<Col md={8}>
								<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.areThereAnyPatientSpecificConcerns)}:</label>
							</Col>
							<Col md="auto">
								<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isPatientSpecificConcerns} onChange={this.onPatientConcerns}>
									<Radio style={{ flexDirection: "row" }} value={true}>
										{translate(ResourceAssistance.Message.yes)}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={false}>
										{translate(ResourceAssistance.Message.no)}
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
					</fieldset>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.toNursingTeam)}</legend>
						<Row>
							<Col md={8}>
								<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.hasSterilizationBeenConfirmed)}:</label>
							</Col>
							<Col md="auto">
								<Radio.Group
									value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isSterilizationConfirmed}
									onChange={this.onSterilizationConfirmed}
								>
									<Radio style={{ flexDirection: "row" }} value={true}>
										{translate(ResourceAssistance.Message.yes)}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={false}>
										{translate(ResourceAssistance.Message.no)}
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
						<Row>
							<Col md={8}>
								<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.areThereEquipmentIssuesOrAnyConcerns)}:</label>
							</Col>
							<Col md="auto">
								<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isEquipmentIssues} onChange={this.onEquipmentIssues}>
									<Radio style={{ flexDirection: "row" }} value={true}>
										{translate(ResourceAssistance.Message.yes)}
									</Radio>
									<Radio style={{ flexDirection: "row" }} value={false}>
										{translate(ResourceAssistance.Message.no)}
									</Radio>
								</Radio.Group>
							</Col>
						</Row>
					</fieldset>
				</fieldset>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.essentialImagingDisplayed)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							value={this.props.operatingRoom.modalSurgicalSafetyCheckListTimeOut.isEssentialImagingDisplayed}
							onChange={this.onEssentialImagingDisplayed}
						>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
			</Container>
		)
	}

	onConfirmTeamMembers(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS, e.target.value)
	}
	onPatientName(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME, e.target.value)
	}
	onProcedureSiteAndSide(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE, e.target.value)
	}
	onAntibiotic(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC, e.target.value)
	}
	onCriticalSteps(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS, e.target.value)
	}
	onCaseDuration(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION, e.target.value)
	}
	onAnticipatedBloodLoss(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS, e.target.value)
	}
	onPatientConcerns(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS, e.target.value)
	}
	onSterilizationConfirmed(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED, e.target.value)
	}
	onEquipmentIssues(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES, e.target.value)
	}
	onEssentialImagingDisplayed(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalSurgicalSafetyCheckListTimeOutDetail)
