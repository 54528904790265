import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import IPDModalPRNRecordAction from "./IPDModalPRNRecordAction"
import IPDModalPRNRecordDescription from "./IPDModalPRNRecordDescription"
import IPDModalPRNRecordDispensing from "./IPDModalPRNRecordDispensing"

class IPDModalPRNRecordDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<IPDModalPRNRecordAction />
				<IPDModalPRNRecordDescription />
				<IPDModalPRNRecordDispensing />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalPRNRecordDetails)
