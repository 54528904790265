import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPostingGLs, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PGL_SEARCH_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PGLModalSearchDetails from "./PGLModalSearchDetails"

class PGLModalSearch extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PGL_SEARCH_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return _.isEmpty(this.props.PGL.modalSearch.searchPostingGLId) && !this.props.PGL.modalSearch.isSearchIncomplete
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.postingGL.getPostingGLs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				orgId: this.props.PGL.location.id,
				postingGLId: Utils.trim(this.props.PGL.modalSearch.searchPostingGLId),
				searchIncomplete: this.props.PGL.modalSearch.isSearchIncomplete,
			},
		}
		let callback = (res) => {
			this.props.setPostingGLs(res.data.postingGLs)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.search)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PGLModalSearchDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPostingGLSearchDisplay,
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPostingGLs,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PGLModalSearch)
