import { QuestionCircleOutlined } from "@ant-design/icons"
import { Popover } from "antd"
import React, { Fragment } from "react"
import { Col, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { Property } from "./TypeModalNew"

class TypeModalNewHealthCarePlanFields extends React.Component {
	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayPrimaryPlan && (
					<Row>
						<Col />
						<Col>
							<InputGroup style={{ width: "auto" }}>
								<label>{translate(ResourceAssistance.Message.primaryPlan)}</label>
								<Popover content={translate(ResourceAssistance.Remark.primaryHealthCarePlan)} trigger="hover">
									<QuestionCircleOutlined style={{ color: "red" }} />
								</Popover>
							</InputGroup>
						</Col>
						<Col>
							<ToggleCheckBox name={Property.PRIMARY_PLAN} onClick={this.props.onPropertyChange} checked={this.props.isPrimaryHealthCarePlan} />
						</Col>
						<Col />
					</Row>
				)}
				{this.props.type.modal.displayPrimaryHospital && (
					<Row>
						<Col />
						<Col>
							<InputGroup style={{ width: "auto" }}>
								<label>{translate(ResourceAssistance.Message.primaryHospital)}</label>
							</InputGroup>
						</Col>
						<Col>
							<ToggleCheckBox name={Property.PRIMARY_HOSPITAL} onClick={this.props.onPropertyChange} checked={this.props.isPrimaryHospital} />
						</Col>
						<Col />
					</Row>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewHealthCarePlanFields)
