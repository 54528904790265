import moment from "moment"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance } from "~/i18n"
import AntDatePicker from "../date_picker/AntDatePicker"

class GCSDate extends React.Component {
	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<AntDatePicker
						size={"large"}
						format={"DD-MM-YYYY  HH:mm"}
						showTime={{ format: "HH:mm" }}
						placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })]}
						values={this.props.datetime ? [moment(this.props.datetime)] : undefined}
						onChange={this.props.onDateChange}
					/>
				</Col>
				<Col />
			</Row>
		)
	}
}

GCSDate.propTypes = {
	onDateChange: propTypes.func.isRequired,
}

GCSDate.defaultProps = {
	onDateChange: () => {},
}

export default injectIntl(GCSDate, { forwardRef: true })
