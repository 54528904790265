import { Document, Page, PDFViewer, StyleSheet, Text, View, Image } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportClaimDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/*Patient Info */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>Date: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.date}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>PID: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.pid}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.identificationIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.identification}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>Ref HN: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.hn}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.nameIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.patientName}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.dobIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.dob}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.ageIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.age}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.healthCarePlanIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.healthCarePlan}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.telIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
								<Text>{this.state.data.tel}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.addressIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.address}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1, justifyContent: "center" }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Image style={styles.satCodeImg} src={this.state.data.file} />
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
		justifyContent: "flex-start",
		marginVertical: 10,
	},
	infoBody: {
		fontStyle: "italic",
		marginRight: 10,
		marginVertical: 10,
	},
	satCodeImg: {
		objectFit: "contain",
	},
})

export default ReportClaimDocument
