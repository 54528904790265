import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setContractBillingBillings, setContractBillingModalNewPendingInvoices, setLoadingAction } from "~/redux/action"
import { SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ContractBillingModalInvoiceInfoDetails from "./ContractBillingModalInvoiceInfoDetails"

class ContractBillingModalInvoiceInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ContractBillingModalInvoiceInfo",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		window.isContractBillingModalInvoiceInfoSelectAll = false
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (this.props.contract.selectedInvoice.invoice) {
			let target = Object.assign({}, this.props.contract.selectedInvoice.invoice, {
				patientDiagnosis: Utils.trim(this.props.contract.modalInvoiceInfo.diagnosis),
				claimNum: Utils.trim(this.props.contract.modalInvoiceInfo.claimNum),
				insuranceRef: Utils.trim(this.props.contract.modalInvoiceInfo.insuranceRef),
			})
			return _.isEqual(this.props.contract.selectedInvoice.invoice, target)
		}
		return (
			!this.props.contract.modalInvoiceInfo.diagnosis && !this.props.contract.modalInvoiceInfo.claimNum && !this.props.contract.modalInvoiceInfo.insuranceRef
		)
	}

	updateContractBillingInvoices(invoices) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.contractBilling.updateContractBillingInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.contract.selectedBilling.billing.id,
				userId: this.props.login.user.id,
				orgId: this.props.contract.location.id,
				invoices: invoices,
			},
		}
		let callback = (res) => {
			if (res.data.contractBillings.length > 0) {
				let billings = this.props.contract.billingTable.original.filter((each) => each.id !== res.data.contractBillings[0].id)
				billings.push(res.data.contractBillings[0])
				this.props.setContractBillingBillings(billings)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		if (this.props.contract.selectedInvoice.invoice) {
			let invoice = this.props.contract.selectedInvoice.invoice
			this.updateContractBillingInvoices([
				Object.assign({}, invoice, {
					patientDiagnosis: Utils.trim(this.props.contract.modalInvoiceInfo.diagnosis),
					claimNum: Utils.trim(this.props.contract.modalInvoiceInfo.claimNum),
					insuranceRef: Utils.trim(this.props.contract.modalInvoiceInfo.insuranceRef),
				}),
			])
		} else {
			let pendingInvoices = Array.from(this.props.contract.modalNew.pendingInvoiceTable.original)
			if (window.isContractBillingModalInvoiceInfoSelectAll) {
				let invoices = this.props.contract.modalNew.invoiceTable.filtered
				invoices.forEach((each) => {
					let invoice = Object.assign(
						{},
						{
							patientDiagnosis: Utils.trim(this.props.contract.modalInvoiceInfo.diagnosis),
							claimNum: Utils.trim(this.props.contract.modalInvoiceInfo.claimNum),
							insuranceRef: Utils.trim(this.props.contract.modalInvoiceInfo.insuranceRef),
							debt: Object.assign({}, each),
						}
					)
					pendingInvoices.push(invoice)
				})
			} else {
				let invoice = Object.assign(
					{},
					{
						patientDiagnosis: Utils.trim(this.props.contract.modalInvoiceInfo.diagnosis),
						claimNum: Utils.trim(this.props.contract.modalInvoiceInfo.claimNum),
						insuranceRef: Utils.trim(this.props.contract.modalInvoiceInfo.insuranceRef),
						debt: Object.assign({}, this.props.contract.modalNew.selectedInvoice.invoice),
					}
				)
				pendingInvoices.push(invoice)
			}
			this.props.setContractBillingModalNewPendingInvoices(pendingInvoices)
		}
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.invoiceInfo)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ContractBillingModalInvoiceInfoDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isContractBillingInvoiceInfoDisplay,
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setContractBillingBillings,
			setContractBillingModalNewPendingInvoices,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalInvoiceInfo)
