import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPSRequests, setSelected } from "~/redux/action"
import { SET_PS_SELECTED_PAYMENT_SLIP } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentSlipLeftRequest extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PS.paymentSlipTable.filtered, this.props.PS.paymentSlipTable.filtered)) {
			let index = this.props.PS.selectedPaymentSlip.ps
				? this.props.PS.paymentSlipTable.filtered.findIndex((ps) => ps.id === this.props.PS.selectedPaymentSlip.ps.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PS_SELECTED_PAYMENT_SLIP, index, this.props.PS.paymentSlipTable.filtered[index])
			} else {
				this.props.setSelected(SET_PS_SELECTED_PAYMENT_SLIP, -1, null)
			}
		}
		if (
			!_.isEqual(prevProps.PS.filterPaymentSlipId, this.props.PS.filterPaymentSlipId) ||
			!_.isEqual(prevProps.PS.filters, this.props.PS.filters)
		) {
			this.props.setPSRequests(this.props.PS.paymentSlipTable.original)
		}
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_PS_SELECTED_PAYMENT_SLIP, index, this.props.PS.paymentSlipTable.filtered[index])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.PS.paymentSlipTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.PS.selectedPaymentSlip.index === -1}
						highlightedRow={this.props.PS.selectedPaymentSlip.index}
					/>
					{this.props.PS.paymentSlipTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPSRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipLeftRequest))
