import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setOPDModalNurseOrderItems, setSelected } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OPDModalMedicalSupplyItem extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.opd.modalNurseOrder.searchName !== this.props.opd.modalNurseOrder.searchName ||
			!_.isEqual(prevProps.opd.modalNurseOrder.selectedType, this.props.opd.modalNurseOrder.selectedType)
		) {
			this.props.setSelected(SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM, -1, null)
			this.props.setOPDModalNurseOrderItems(this.props.opd.modalNurseOrder.itemTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_ORDER_SELECTED_ITEM,
			index,
			this.props.opd.modalNurseOrder.itemTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectItem}
						data={this.props.opd.modalNurseOrder.itemTable}
						isClearHighlight={this.props.opd.modalNurseOrder.selectedItem.index === -1}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setOPDModalNurseOrderItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalSupplyItem))
