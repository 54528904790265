import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setORModalPostAneServicePendingServices } from "~/redux/action"
import { SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ORModalPostAneServicePending extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		this.props.setORModalPostAneServicePendingServices(
			this.getPendingItems(this.props.operatingRoom.selectedPostAneRecord.record.doctorOrders.filter((each) => each.serviceCode))
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.selectedPostAneRecord.record, this.props.operatingRoom.selectedPostAneRecord.record)) {
			if (this.props.operatingRoom.selectedPostAneRecord.record) {
				this.props.setORModalPostAneServicePendingServices(
					this.getPendingItems(this.props.operatingRoom.selectedPostAneRecord.record.doctorOrders.filter((each) => each.serviceCode))
				)
			}
		}

		if (
			!_.isEqual(
				prevProps.operatingRoom.modalPostAneService.pendingServiceTable.filtered,
				this.props.operatingRoom.modalPostAneService.pendingServiceTable.filtered
			)
		) {
			this.props.setSelected(SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setORModalPostAneServicePendingServices([])
		this.props.setSelected(SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE, -1, null)
	}

	getPendingItems(services) {
		return services.map((each) => {
			return {
				...each,
				code: each.serviceCode,
				name: each.description,
				description: each.categoryName,
				isExistService: true,
			}
		})
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelect}
						data={this.props.operatingRoom.modalPostAneService.pendingServiceTable}
						isClearHighlight={this.props.operatingRoom.modalPostAneService.selectedPendingService.index === -1}
						highlightedRow={this.props.operatingRoom.modalPostAneService.selectedPendingService.index}
					/>
				</Col>
			</Row>
		)
	}

	onSelect(item, index) {
		this.props.setSelected(
			SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE,
			index,
			this.props.operatingRoom.modalPostAneService.pendingServiceTable.filtered[index]
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setORModalPostAneServicePendingServices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneServicePending))
