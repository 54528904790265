import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setAdmTModalNTActive,
	setAdmTModalNTDescription,
	setAdmTModalNTName,
	setAdmTModalNTTaxRate,
} from "~/redux/action"
import { Utils } from "~/utils/Utils"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class AdmTModalNTDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onTaxRate = this.onTaxRate.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmTModalNTName("")
		this.props.setAdmTModalNTDescription("")
		this.props.setAdmTModalNTTaxRate("")
		this.props.setAdmTModalNTActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.tax.modalNewTax.name !== this.props.tax.modalNewTax.name ||
			prevProps.tax.modalNewTax.description !== this.props.tax.modalNewTax.description ||
			prevProps.tax.modalNewTax.taxRate !== this.props.tax.modalNewTax.taxRate
		) {
			this.validateInput()
		}
	}

	validateInput() {
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.tax.modalNT.name)
		let nameInput = this.props.tax.modalNewTax.name.trim().toLowerCase()
		let descriptionHtml = document.getElementById(ResourceAssistance.ID.ADM.tax.modalNT.description)
		let descriptionInput = this.props.tax.modalNewTax.description.trim().toLowerCase()
		let taxRateHtml = document.getElementById(ResourceAssistance.ID.ADM.tax.modalNT.taxRate)
		let taxRateInput = this.props.tax.modalNewTax.taxRate

		if (!nameInput) {
			nameHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.emptyWarning }))
			descriptionHtml.setCustomValidity("")
			taxRateHtml.setCustomValidity("")
		} else if (!descriptionInput) {
			nameHtml.setCustomValidity("")
			descriptionHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.emptyWarning }))
			taxRateHtml.setCustomValidity("")
		} else if (
			this.props.tax.taxTable.original
				.filter((each) => !this.props.tax.selectedTax.tax || this.props.tax.selectedTax.tax.id !== each.id)
				.some(
					(each) =>
						each.displayName.trim().toLowerCase() === nameInput && Utils.BigNumber(each.taxRate).eq(taxRateInput)
				)
		) {
			nameHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning })
			)
			descriptionHtml.setCustomValidity("")
			taxRateHtml.setCustomValidity(
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning })
			)
		} else {
			nameHtml.setCustomValidity("")
			descriptionHtml.setCustomValidity("")
			taxRateHtml.setCustomValidity("")
		}
	}

	onName(event) {
		this.props.setAdmTModalNTName(event.target.value)
	}

	onDescription(event) {
		this.props.setAdmTModalNTDescription(event.target.value)
	}

	onTaxRate(event) {
		this.props.setAdmTModalNTTaxRate(event.target.value)
	}

	onActive(event) {
		this.props.setAdmTModalNTActive(event.target.checked)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<label>{translate(ResourceAssistance.Message.name)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.tax.modalNT.name}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.tax.modalNewTax.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>{translate(ResourceAssistance.Message.description)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.tax.modalNT.description}
							as={ResourceAssistance.FormControl.as.textArea}
							value={this.props.tax.modalNewTax.description}
							rows="5"
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>{translate(ResourceAssistance.Message.taxRate)}</label>
					</Col>
					<Col md={2} />
					<Col md={2}>
						<InputGroup>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.tax.modalNT.taxRate}
								type={ResourceAssistance.FormControl.type.number}
								value={this.props.tax.modalNewTax.taxRate}
								min="0"
								max="100"
								onChange={this.onTaxRate}
							/>
							<InputGroup.Text>%</InputGroup.Text>
						</InputGroup>
					</Col>
					<Col md={2} />
				</Row>
				<Row>
					<Col>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.tax.modalNewTax.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	tax: state.admin.itemConfig.tax,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{ setAdmTModalNTName, setAdmTModalNTDescription, setAdmTModalNTActive, setAdmTModalNTTaxRate },
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmTModalNTDetails))
