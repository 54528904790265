import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction } from "~/redux/action"
import AdmPermission from "./permission/AdmPermission"
import AdmRole from "./role/AdmRole"
import AdmUser from "./user/AdmUser"
import "./userConfig.css"

export const EUserConfig = {
	USER: "USER_CONFIG_USER",
	ROLE: "USER_ROLE",
	PERMISSION: "USER_PERMISSION",
}

class UserConfig extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			componentName: "UserConfig",
			tab: EUserConfig.USER,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadUsers = this.loadUsers.bind(this)
		this.loadRoles = this.loadRoles.bind(this)
		this.loadPermissions = this.loadPermissions.bind(this)
	}

	loadUsers(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.user.loadUsers}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.users)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	loadRoles(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.role.loadRoles}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.roles)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	loadPermissions(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.permission.loadPermissions}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.permissions)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<div className={` ${ResourceAssistance.CSS.fullFlex}`}>
							<Tabs justify activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
								<Tab eventKey={EUserConfig.USER} title={translate(ResourceAssistance.Message.user)} name={EUserConfig.USER}>
									{this.state.tab === EUserConfig.USER && <AdmUser loadUsers={this.loadUsers} loadRoles={this.loadRoles} />}
								</Tab>
								<Tab eventKey={EUserConfig.ROLE} title={translate(ResourceAssistance.Message.role)} name={EUserConfig.ROLE}>
									{this.state.tab === EUserConfig.ROLE && <AdmRole loadRoles={this.loadRoles} loadPermissions={this.loadPermissions} />}
								</Tab>

								<Tab eventKey={EUserConfig.PERMISSION} title={translate(ResourceAssistance.Message.permission)} name={EUserConfig.PERMISSION}>
									{this.state.tab === EUserConfig.PERMISSION && <AdmPermission loadPermissions={this.loadPermissions} />}
								</Tab>
							</Tabs>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(UserConfig)
