import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setCashierModalLabUnchargedLabs, setSelected } from "~/redux/action"
import { SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierModalLabUnchargedLab extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentDidMount() {
		this.props.setCashierModalLabUnchargedLabs(this.props.cashier.patient.labs)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.cashier.modalLab.pendingTable.original, this.props.cashier.modalLab.pendingTable.original)
		) {
			this.props.setCashierModalLabUnchargedLabs(this.props.cashier.modalLab.unchargedTable.original)
			this.props.setSelected(SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED, -1, null)
	}

	onSelect(lab, index) {
		this.props.setSelected(
			SET_CASHIER_MODAL_LAB_SELECTED_UNCHARGED,
			index,
			this.props.cashier.modalLab.unchargedTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col id={ResourceAssistance.ID.HOSPITEL.cashier.modalLab.tableUncharged}>
					<ScrollableTable
						enableHighlight
						striped
						bordered
						hover
						isClearHighlight={this.props.cashier.modalLab.selectedUncharged.index === -1}
						data={this.props.cashier.modalLab.unchargedTable}
						onClick={this.onSelect}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierModalLabUnchargedLabs,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalLabUnchargedLab)
