import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue } from "~/redux/action"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import OperatingRoomModalIntraAneAirwayDetails from "./OperatingRoomModalIntraAneAirwayDetails"
import { SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"
import _ from "lodash"

class OperatingRoomModalIntraAneAirway extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.operatingRoom.selectedRequest.request ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.type === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewDifficulty === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.bladeType === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.intubationTechnique === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.breathingSystem === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isVerbalCommand === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isCough === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isAdeq === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isSwallowing === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isHandGrip === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isDyspnea === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isUAO === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isWheezing === "" ||
			this.props.operatingRoom.modalIntraAneAirwayManagement.isCyanosis === ""
		) {
			return true
		}
		let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
			? undefined
			: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
		let target = Object.assign({}, op, {
			method: this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail.join(","),
			methodOralAirwayNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodOralAirwayNo),
			methodLMANo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodLMANo),
			methodTracheostomyNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodTracheostomyNo),
			methodNasalAirwayNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodNasalAirwayNo),
			methodETTubeNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNo),
			methodETTubeNoCuff: this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoCuff,
			methodETTubeNoOral: this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral,
			methodETTubeNoDepth: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoDepth),
			airwayType: this.props.operatingRoom.modalIntraAneAirwayManagement.type,
			typeDLTRtNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.typeDLTRtNo),
			typeDLTLtNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.typeDLTLtNo),
			laryngealView: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealView),
			laryngealViewDifficulty: this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewDifficulty,
			laryngealViewAttempt: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewAttempt),
			intubationTechnique: this.props.operatingRoom.modalIntraAneAirwayManagement.intubationTechnique,
			bladeType: this.props.operatingRoom.modalIntraAneAirwayManagement.bladeType,
			bladeSize: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.bladeSize),
			bladeStylet: this.props.operatingRoom.modalIntraAneAirwayManagement.bladeTypeDetail.join(","),
			assistedDevice: this.props.operatingRoom.modalIntraAneAirwayManagement.assistedDeviceDetail.join(","),
			afterIntubation: this.props.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail.join(","),
			cuffedInflation: this.props.operatingRoom.modalIntraAneAirwayManagement.cuffedInflationDetail.join(","),
			breathingSystem: this.props.operatingRoom.modalIntraAneAirwayManagement.breathingSystem,
			verbalCommand: this.props.operatingRoom.modalIntraAneAirwayManagement.isVerbalCommand,
			coughOnSuction: this.props.operatingRoom.modalIntraAneAirwayManagement.isCough,
			adeqSpontVentilator: this.props.operatingRoom.modalIntraAneAirwayManagement.isAdeq,
			swallowingGag: this.props.operatingRoom.modalIntraAneAirwayManagement.isSwallowing,
			handGrip: this.props.operatingRoom.modalIntraAneAirwayManagement.isHandGrip,
			dyspnea: this.props.operatingRoom.modalIntraAneAirwayManagement.isDyspnea,
			uao: this.props.operatingRoom.modalIntraAneAirwayManagement.isUAO,
			wheezing: this.props.operatingRoom.modalIntraAneAirwayManagement.isWheezing,
			cyanosis: this.props.operatingRoom.modalIntraAneAirwayManagement.isCyanosis,
		})
		return _.isEqual(op, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.operatingRoom.saveAnesthesiaIntraOperatives}?type=AIRWAY_MANAGEMENT`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				anesthesiaIntraOperatives: [
					{
						id: _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
							? 0
							: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0].id,
						method: this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail.join(","),
						methodOralAirwayNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodOralAirwayNo),
						methodLMANo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodLMANo),
						methodTracheostomyNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodTracheostomyNo),
						methodNasalAirwayNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodNasalAirwayNo),
						methodETTubeNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNo),
						methodETTubeNoCuff: this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoCuff,
						methodETTubeNoOral: this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral,
						methodETTubeNoDepth: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoDepth),
						type: this.props.operatingRoom.modalIntraAneAirwayManagement.type,
						typeDLTRtNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.typeDLTRtNo),
						typeDLTLtNo: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.typeDLTLtNo),
						laryngealView: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealView),
						laryngealViewDifficulty: this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewDifficulty,
						laryngealViewAttempt: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewAttempt),
						intubationTechnique: this.props.operatingRoom.modalIntraAneAirwayManagement.intubationTechnique,
						bladeType: this.props.operatingRoom.modalIntraAneAirwayManagement.bladeType,
						bladeSize: Utils.trim(this.props.operatingRoom.modalIntraAneAirwayManagement.bladeSize),
						bladeStylet: this.props.operatingRoom.modalIntraAneAirwayManagement.bladeTypeDetail.join(","),
						assistedDevice: this.props.operatingRoom.modalIntraAneAirwayManagement.assistedDeviceDetail.join(","),
						afterIntubation: this.props.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail.join(","),
						cuffedInflation: this.props.operatingRoom.modalIntraAneAirwayManagement.cuffedInflationDetail.join(","),
						breathingSystem: this.props.operatingRoom.modalIntraAneAirwayManagement.breathingSystem,
						verbalCommand: this.props.operatingRoom.modalIntraAneAirwayManagement.isVerbalCommand,
						coughOnSuction: this.props.operatingRoom.modalIntraAneAirwayManagement.isCough,
						adeqSpontVentilator: this.props.operatingRoom.modalIntraAneAirwayManagement.isAdeq,
						swallowingGag: this.props.operatingRoom.modalIntraAneAirwayManagement.isSwallowing,
						handGrip: this.props.operatingRoom.modalIntraAneAirwayManagement.isHandGrip,
						dyspnea: this.props.operatingRoom.modalIntraAneAirwayManagement.isDyspnea,
						uao: this.props.operatingRoom.modalIntraAneAirwayManagement.isUAO,
						wheezing: this.props.operatingRoom.modalIntraAneAirwayManagement.isWheezing,
						cyanosis: this.props.operatingRoom.modalIntraAneAirwayManagement.isCyanosis,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.airwayManagement)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalIntraAneAirwayDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomIntraAnesthesiaAirwayManagementDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalIntraAneAirway)
