import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setDebtModalNewInvoices, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_DEBT_MODAL_NEW_HEALTH_CARE_PLANS,
	SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING,
	SET_DEBT_MODAL_NEW_INSURANCE_COMPANIES,
	SET_DEBT_MODAL_NEW_INSURANCE_COMPANY_LOADING,
	SET_PAGE_LOADING,
} from "~/redux/type"
import DebtModalNewFilter from "./DebtModalNewFilter"
import DebtModalNewInvoice from "./DebtModalNewInvoice"
import DebtModalNewInvoiceAction from "./DebtModalNewInvoiceAction"
import DebtModalNewPendingInvoice from "./DebtModalNewPendingInvoice"

class DebtModalNewDetails extends React.Component {
	constructor(props) {
		super(props)

		this.getInvoices = this.getInvoices.bind(this)
	}

	componentDidMount() {
		this.getHealthCarePlans()
		this.getInsuranceCompanies()
	}

	getHealthCarePlans() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.debt.getHealthCarePlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DEBT_MODAL_NEW_HEALTH_CARE_PLANS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getInsuranceCompanies() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.debt.getInsuranceCompanies,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DEBT_MODAL_NEW_INSURANCE_COMPANIES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_NEW_INSURANCE_COMPANY_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_NEW_INSURANCE_COMPANY_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_NEW_INSURANCE_COMPANY_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getInvoices() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.debt.getInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				orgId: this.props.debt.location.id,
				healthcarePlanCode: this.props.debt.modalNew.searchHealthCarePlan.healthcarePlan
					? typeof this.props.debt.modalNew.searchHealthCarePlan.index === "string"
						? this.props.debt.modalNew.healthcarePlans[
								this.props.debt.modalNew.searchHealthCarePlan.index.split("-")[0]
						  ].code
						: this.props.debt.modalNew.searchHealthCarePlan.healthcarePlan.code
					: "",
				healthcarePlanName:
					this.props.debt.modalNew.searchHealthCarePlan.healthcarePlan &&
					typeof this.props.debt.modalNew.searchHealthCarePlan.index === "string"
						? this.props.debt.modalNew.searchHealthCarePlan.healthcarePlan.displayName
						: "",
				insuranceCompany: this.props.debt.modalNew.searchInsuranceCompany.insuranceCompany
					? this.props.debt.modalNew.searchInsuranceCompany.insuranceCompany.displayName
					: "",
			},
		}
		let callback = (res) => {
			this.props.setDebtModalNewInvoices(res.data.invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<DebtModalNewFilter getInvoices={this.getInvoices} />
				<DebtModalNewInvoice />
				<DebtModalNewInvoiceAction />
				<DebtModalNewPendingInvoice />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setDebtModalNewInvoices,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtModalNewDetails)
