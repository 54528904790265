import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import momentPlugin from "@fullcalendar/moment"
import momentTimezonePlugin from "@fullcalendar/moment-timezone"
import timeGridPlugin from "@fullcalendar/timegrid"
import _ from "lodash"
import moment from "moment"
import "moment/locale/th"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients, setValue } from "~/redux/action"
import { SET_OPD_MODAL_OR_SCHEDULES, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import FCalendar from "~/view/component/calendar/FCalendar"

class OutpatientModalORCalendarDetails extends React.Component {
	constructor(props) {
		super(props)

		this.isAllowOverlap = this.isAllowOverlap.bind(this)
		this.isSelectionAllowed = this.isSelectionAllowed.bind(this)
		this.isEventClickAllowed = this.isEventClickAllowed.bind(this)
		this.onPopConfirmOk = this.onPopConfirmOk.bind(this)
		this.onPopConfrimNo = this.onPopConfrimNo.bind(this)
		this.onEventDrop = this.onEventDrop.bind(this)
		this.onEventResize = this.onEventResize.bind(this)
		this.onEventRemove = this.onEventRemove.bind(this)
		this.onEvents = this.onEvents.bind(this)
		this.onDatesSet = this.onDatesSet.bind(this)
	}

	componentDidMount() {
		this.loadOperatingRoomSchedules()
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_OR_SCHEDULES, [])
	}

	loadOperatingRoomSchedules() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getOperatingRoomSchedules,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.opd.modalOR.selectedRoom.room.id,
				startDateTime: moment().startOf("month").milliseconds(0).valueOf(),
				endDateTime: moment().endOf("month").milliseconds(0).valueOf(),
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OPD_MODAL_OR_SCHEDULES, res.data.schedules)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isEventOverlap(stillEvent, moveEvent) {
		if (stillEvent.extendedProps.request && stillEvent.extendedProps.request.status === ResourceAssistance.Enum.cancelled) {
			return true
		}
		return false
	}

	isAllowOverlap(event) {
		if (
			this.props.calendarRef.current.getApi().view.type === "dayGridMonth" ||
			(event.extendedProps.request && event.extendedProps.request.status === ResourceAssistance.Enum.cancelled)
		) {
			return true
		}
		return false
	}

	isSelectionAllowed(selectInfo) {
		if (
			this.props.calendarRef.current.getApi().view.type !== "dayGridMonth" &&
			(moment(selectInfo.start).isBefore(moment()) ||
				(this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.approved))
		) {
			return false
		}
		return true
	}

	isEventClickAllowed(selectInfo) {
		if (this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.approved) {
			return false
		}
		return selectInfo.event.extendedProps.isEventClickAllowed
	}

	getEvents() {
		let events = this.props.opd.modalOR.schedules
			.filter((each) => !this.props.opd.modalOR.newEvents.some((event) => event.id === each.id))
			.map((each) => {
				return {
					id: each.id,
					title: each.description,
					start: moment(each.startDateTime).toDate(),
					end: moment(each.endDateTime).toDate(),
					editable: this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.id === each.request.id,
					color:
						each.request.status === ResourceAssistance.Enum.cancelled
							? ResourceAssistance.CSS.Color.dark_red
							: this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.id === each.request.id
							? ResourceAssistance.CSS.Color.dark_green
							: undefined,
					extendedProps: {
						isEventClickAllowed:
							this.props.opd.selectedORRequest.request &&
							this.props.opd.selectedORRequest.request.id === each.request.id &&
							this.props.opd.selectedORRequest.request.status !== ResourceAssistance.Enum.approved,
						request: each.request,
					},
					display: "block",
				}
			})
			.concat(this.props.opd.modalOR.newEvents)
		return events
	}

	onPopConfirmOk(selectInfo, isEventClicked) {
		let calendarApi = this.props.calendarRef.current.getApi()
		if (isEventClicked) {
			selectInfo.event.remove()
		} else if (this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.operatingRoomSchedules.length > 0) {
			this.props.opd.selectedORRequest.request.operatingRoomSchedules.forEach((each) => {
				let event = calendarApi.getEventById(each.id)
				if (event) {
					event.setStart(selectInfo.start)
					event.setEnd(selectInfo.end)
					event.setExtendedProp("isModified", true)
				} else {
					calendarApi.addEvent({
						id: each.id,
						title: this.props.opd.selectedPatient.patient.displayName,
						start: selectInfo.start,
						end: selectInfo.end,
						editable: true,
						color: ResourceAssistance.CSS.Color.dark_green,
						display: "block",
						extendedProps: {
							isModified: true,
							isEventClickAllowed: true,
						},
					})
				}
			})
		} else if (calendarApi.getEventById("")) {
			let event = calendarApi.getEventById("")
			event.setStart(selectInfo.start)
			event.setEnd(selectInfo.end)
		} else {
			calendarApi.addEvent({
				title: this.props.opd.selectedPatient.patient.displayName,
				start: selectInfo.start,
				end: selectInfo.end,
				editable: true,
				color: ResourceAssistance.CSS.Color.dark_green,
				display: "block",
				extendedProps: {
					isModified: true,
					isEventClickAllowed: true,
				},
			})
		}
		calendarApi.unselect()
	}

	onPopConfrimNo(selectInfo) {
		this.props.calendarRef.current.getApi().unselect()
	}

	onSelectDate = (selectInfo) => {
		let calendarApi = selectInfo.view.calendar
		if (selectInfo.view.type === "dayGridMonth") {
			calendarApi.changeView("timeGridDay", selectInfo.start)
		}
	}

	onEventDrop(selectInfo) {
		selectInfo.event.setExtendedProp("isModified", true)
	}

	onEventResize(selectInfo) {
		selectInfo.event.setExtendedProp("isModified", true)
	}

	onEventRemove(selectInfo) {
		if (!_.isEmpty(selectInfo.event.id)) {
			let params = {
				method: "POST",
				url: ResourceAssistance.Url.opd.deleteOperatingRoomSchedules,
				withCredentials: true,
				headers: {
					"content-type": "application/json",
				},
				data: {
					pid: this.props.opd.selectedPatient.patient.id,
					operatingRoomSchedules: [
						{
							id: selectInfo.event.id,
						},
					],
				},
			}
			let callback = (res) => {
				if (res.data.patients.length > 0) {
					let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
					patients.push(res.data.patients[0])
					this.props.setOPDPatients(patients)
					this.props.setValue(
						SET_OPD_MODAL_OR_SCHEDULES,
						this.props.opd.modalOR.schedules.filter((each) => each.id !== selectInfo.event.id)
					)
				}
			}
			let errorHandler = (error) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			let reqInterceptor = (config) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			}
			let resInterceptor = (response) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		}
	}

	onEvents(events) {}

	onRenderEventContent(eventInfo) {
		return (
			<>
				<b>{`${Utils.formatTime(eventInfo.event.start)} - ${Utils.formatTime(eventInfo.event.end)}`}</b>
				<i>&nbsp;{eventInfo.event.title}</i>
			</>
		)
	}

	onDatesSet(info) {}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className="full-size">
					<Col>
						<FCalendar
							calendarRef={this.props.calendarRef}
							plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, momentPlugin, momentTimezonePlugin]}
							initialView="dayGridMonth"
							headerToolbar={{
								left: "today next",
								center: "title",
								right: "dayGridMonth,timeGridWeek,timeGridDay listMonth",
							}}
							buttonText={{
								today: this.props.intl.formatMessage({ id: ResourceAssistance.Message.today }),
								month: this.props.intl.formatMessage({ id: ResourceAssistance.Message.month }),
								week: this.props.intl.formatMessage({ id: ResourceAssistance.Message.week }),
								day: this.props.intl.formatMessage({ id: ResourceAssistance.Message.day }),
								list: this.props.intl.formatMessage({ id: ResourceAssistance.Message.list }),
							}}
							navLinks={true}
							dayMaxEvents={true}
							editable={true}
							selectable={true}
							selectMirror={true}
							displayPopConfirm={true}
							selectOverlap={this.isAllowOverlap}
							eventOverlap={this.isEventOverlap}
							selectAllow={this.isSelectionAllowed}
							eventClickAllow={this.isEventClickAllowed}
							eventDragable={true}
							eventResizeable={true}
							eventMaxStack={1}
							events={this.getEvents()}
							onEventContent={this.onRenderEventContent} // custom render function
							onEventsSet={this.onEvents} // called after events are initialized/added/changed/removed
							onPopConfirmOk={this.onPopConfirmOk}
							onPopConfirmNo={this.onPopConfrimNo}
							onSelect={this.onSelectDate}
							onEventDrop={this.onEventDrop}
							onEventResize={this.onEventResize}
							onEventRemove={this.onEventRemove}
							onDatesSet={this.onDatesSet}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OutpatientModalORCalendarDetails))
