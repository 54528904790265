import { Divider, Radio, Select } from "antd"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setPSModalNewInvoices, setSelected, setValue } from "~/redux/action"
import {
	SET_PS_MODAL_NEW_FILTER_INVOICE_NUM,
	SET_PS_MODAL_NEW_FILTER_INVOICE_ID,
	SET_PS_MODAL_NEW_INVOICES_LOADING,
	SET_PS_MODAL_NEW_SEARCH_SUPPLIER,
	SET_PS_MODAL_NEW_SEARCH_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class PSModalNewSearchFilter extends React.Component {
	EAccountPayableRadio = {
		RC: {
			description: translate(ResourceAssistance.Message.accountsPayableRC),
			value: 1,
		},
		PI: {
			description: translate(ResourceAssistance.Message.accountsPayablePI),
			value: 2,
		},
		PV: {
			description: translate(ResourceAssistance.Message.accountsPayablePV),
			value: 3,
		},
	}
	constructor(props) {
		super(props)

		this.onSearch = this.onSearch.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onSearchType = this.onSearchType.bind(this)
		this.onFilterInvoiceNum = this.onFilterInvoiceNum.bind(this)
		this.onFilterInvoiceId = this.onFilterInvoiceId.bind(this)
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PS_MODAL_NEW_SEARCH_SUPPLIER, -1, null)
		this.props.setValue(SET_PS_MODAL_NEW_SEARCH_TYPE, this.EAccountPayableRadio.RC.value)
		this.props.setValue(SET_PS_MODAL_NEW_FILTER_INVOICE_NUM, "")
		this.props.setValue(SET_PS_MODAL_NEW_FILTER_INVOICE_ID, "")
	}

	onSearch() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.paymentSlip.getInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				orgId: this.props.PS.location.id,
				accountsPayableRC: this.props.PS.modalNew.searchType === this.EAccountPayableRadio.RC.value,
				accountsPayablePI: this.props.PS.modalNew.searchType === this.EAccountPayableRadio.PI.value,
				accountsPayablePV: this.props.PS.modalNew.searchType === this.EAccountPayableRadio.PV.value,
				supplierCode: this.props.PS.modalNew.searchSupplier.supplier.code,
			},
		}
		let callback = (res) => {
			this.props.setPSModalNewInvoices(res.data.invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PS_MODAL_NEW_INVOICES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PS_MODAL_NEW_INVOICES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PS_MODAL_NEW_INVOICES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSupplier(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_PS_MODAL_NEW_SEARCH_SUPPLIER, -1, null)
			return
		}
		this.props.setSelected(SET_PS_MODAL_NEW_SEARCH_SUPPLIER, value, this.props.PS.suppliers[value])
	}

	onSearchType(e) {
		this.props.setValue(SET_PS_MODAL_NEW_SEARCH_TYPE, e.target.value)
	}

	onFilterInvoiceNum(e) {
		this.props.setValue(SET_PS_MODAL_NEW_FILTER_INVOICE_NUM, e.target.value)
	}

	onFilterInvoiceId(e) {
		this.props.setValue(SET_PS_MODAL_NEW_FILTER_INVOICE_ID, e.target.value)
	}

	render() {
		return (
			<Row className="g-2">
				<Col>
					<Select
						allowClear
						showSearch
						style={{ textAlign: "center", margin: 0 }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.supplier)}
						loading={this.props.PS.isSupplierLoading}
						value={this.props.PS.modalNew.searchSupplier.supplier ? this.props.PS.modalNew.searchSupplier.index : undefined}
						onChange={this.onSupplier}
					>
						{Utils.renderSelects(this.props.PS.suppliers, false)}
					</Select>
				</Col>
				<Col md="auto">
					<Radio.Group value={this.props.PS.modalNew.searchType} onChange={this.onSearchType}>
						<Radio style={{ flexDirection: "row" }} value={this.EAccountPayableRadio.RC.value}>
							{this.EAccountPayableRadio.RC.description}
						</Radio>
						<Radio style={{ flexDirection: "row" }} value={this.EAccountPayableRadio.PI.value}>
							{this.EAccountPayableRadio.PI.description}
						</Radio>
						<Radio style={{ flexDirection: "row" }} value={this.EAccountPayableRadio.PV.value}>
							{this.EAccountPayableRadio.PV.description}
						</Radio>
					</Radio.Group>
				</Col>
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.primary} disabled={!this.props.PS.modalNew.searchSupplier.supplier} onClick={this.onSearch}>
						<GoSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md={4}>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.number]}
						placeholders={[
							ResourceAssistance.Message.invoiceId,
							this.props.PS.modalNew.searchType ? ResourceAssistance.Message.purchaseInvoiceId : ResourceAssistance.Message.receiveId,
						]}
						callbacks={[this.onFilterInvoiceNum, this.onFilterInvoiceId]}
						values={[this.props.PS.modalNew.filterInvoiceNum, this.props.PS.modalNew.filterInvoiceId]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setPSModalNewInvoices,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PSModalNewSearchFilter)
