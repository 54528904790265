import React, { Fragment } from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_VITAL_SIGN_BP,
	SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME,
	SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN,
	SET_HOSPITEL_MODAL_VITAL_SIGN_PR,
	SET_HOSPITEL_MODAL_VITAL_SIGN_RR,
	SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalVitalSignData extends React.Component {
	constructor(props) {
		super(props)

		this.onDateTime = this.onDateTime.bind(this)
		this.onTemperature = this.onTemperature.bind(this)
		this.onPR = this.onPR.bind(this)
		this.onRR = this.onRR.bind(this)
		this.onBP = this.onBP.bind(this)
		this.onO2 = this.onO2.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedVitalSign.vitalSign) {
			let selectedVitalSign = this.props.hospitel.selectedVitalSign.vitalSign
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME, selectedVitalSign.dateTime)
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE, selectedVitalSign.temperature)
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_PR, selectedVitalSign.pr)
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_RR, selectedVitalSign.rr)
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_BP, selectedVitalSign.bp)
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN, selectedVitalSign.oxygenSat)
		} else {
			this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME, new Date().getTime())
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE, "")
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_PR, "")
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_RR, "")
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_BP, "")
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN, "")
	}

	onDateTime(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME, date.getTime())
	}

	onTemperature(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE, event.target.value)
	}

	onPR(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_PR, event.target.value)
	}

	onRR(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_RR, event.target.value)
	}

	onBP(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_BP, event.target.value)
	}

	onO2(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row style={{ alignSelf: "center" }}>
					<Col md="auto">
						<DatePicker
							// timeIntervals={120}
							// includeTimes={[
							// 	Utils.generateDate(0, 0, 0, 2, 0, 0),
							// 	Utils.generateDate(0, 0, 0, 6, 0, 0),
							// 	Utils.generateDate(0, 0, 0, 10, 0, 0),
							// 	Utils.generateDate(0, 0, 0, 14, 0, 0),
							// 	Utils.generateDate(0, 0, 0, 18, 0, 0),
							// 	Utils.generateDate(0, 0, 0, 22, 0, 0),
							// ]}
							minDate={Utils.generateDate()}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.hospitel.modalVitalSign.dateTime)}
							onChange={this.onDateTime}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Text>T.(C)</InputGroup.Text>
							<FormControl
								required
								autoFocus
								type={ResourceAssistance.FormControl.type.number}
								step={0.1}
								value={this.props.hospitel.modalVitalSign.temperature}
								onChange={this.onTemperature}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Text>PR./min</InputGroup.Text>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.number}
								step={1}
								value={this.props.hospitel.modalVitalSign.pr}
								onChange={this.onPR}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Text>RR./min</InputGroup.Text>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.number}
								step={1}
								value={this.props.hospitel.modalVitalSign.rr}
								onChange={this.onRR}
							/>
						</InputGroup>
					</Col>
					<Col md="auto">
						<InputGroup>
							<InputGroup.Text>BP.(mmHg)</InputGroup.Text>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.text}
								value={this.props.hospitel.modalVitalSign.bp}
								onChange={this.onBP}
							/>
						</InputGroup>
					</Col>
					<Col>
						<InputGroup>
							<InputGroup.Text>O{ResourceAssistance.Symbol[2]} Sat</InputGroup.Text>
							<FormControl
								required
								type={ResourceAssistance.FormControl.type.number}
								step={1}
								value={this.props.hospitel.modalVitalSign.oxygen}
								onChange={this.onO2}
							/>
							<InputGroup.Text>%</InputGroup.Text>
						</InputGroup>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalVitalSignData)
