import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import PSModalPaymentPlanField from "./PSModalPaymentPlanField"
import PSModalPaymentPlanInvoice from "./PSModalPaymentPlanInvoice"
import PSModalPaymentPlanInvoiceAction from "./PSModalPaymentPlanInvoiceAction"
import PSModalPaymentPlanPendingInvoice from "./PSModalPaymentPlanPendingInvoice"

class PSModalPaymentPlanDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"full-size g-0"}>
					<Col>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<PSModalPaymentPlanInvoice />
							<PSModalPaymentPlanInvoiceAction />
							<PSModalPaymentPlanPendingInvoice />
						</Container>
					</Col>
					<Col md={2}>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<PSModalPaymentPlanField />
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PSModalPaymentPlanDetails)
