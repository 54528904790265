import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OPDModalNurseAction from "./OPDModalNurseAction"
import OPDModalNurseDate from "./OPDModalNurseDate"
import OPDModalNurseFocus from "./OPDModalNurseFocus"
import OPDModalNurseAssessment from "./OPDModalNurseAssessment"
import OPDModalNurseIntervention from "./OPDModalNurseIntervention"
import OPDModalNurseEvaluation from "./OPDModalNurseEvaluation"

class OPDModalNurseDetails extends React.Component {
	render() {
		return (
			<Container fluid className={"full-flex space-top-each-row-parent"}>
				{!this.props.opd.selectedNurseNote.nurseNote && <OPDModalNurseAction />}
				<OPDModalNurseDate />
				<Row className="full-size">
					<Col md={4}>
						<OPDModalNurseFocus />
					</Col>
					<Col>
						<fieldset className={ResourceAssistance.CSS.fullFlex}>
							<legend>{translate(ResourceAssistance.Hospitel.aie)}</legend>
							<OPDModalNurseAssessment />
							<OPDModalNurseIntervention />
							<OPDModalNurseEvaluation />
						</fieldset>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseDetails)
