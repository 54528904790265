import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CLRightActionRow from "./CLRightActionRow"
import CLRightLabRow from "./CLRightLabRow"
import CLRightPatientInfoRow from "./CLRightPatientInfoRow"
import CLRightUserRow from "./CLRightUserRow"

class CLRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLRight",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<CLRightPatientInfoRow />
				<CLRightActionRow {...this.props} />
				<CLRightLabRow />
				<CLRightUserRow />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLRight)
