import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { IoMdDoneAll } from "react-icons/io"
import { MdRemoveDone } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT, SET_IPD_MODAL_NURSE_RECORD_ASSESSMENT_SELECTED_CATEGORY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalNurseAssessment extends React.Component {
	constructor(props) {
		super(props)

		this.onAssessment = this.onAssessment.bind(this)
		this.onCategoryAssessment = this.onCategoryAssessment.bind(this)
		this.onSelectAssessment = this.onSelectAssessment.bind(this)
		this.onDeselectAssessment = this.onDeselectAssessment.bind(this)
		this.onSelectAllAssessment = this.onSelectAllAssessment.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT, this.props.ipd.selectedNurseNote.nurseNote.assessment)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT, "")
		this.props.setSelected(SET_IPD_MODAL_NURSE_RECORD_ASSESSMENT_SELECTED_CATEGORY, -1, null)
	}

	getValues() {
		let values = []
		if (this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category) {
			this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments.forEach((assessment, idx) => {
				if (_.includes(this.props.ipd.modalNurseRecord.assessment, assessment.description)) {
					values.push(idx)
				}
			})
		}
		return values
	}

	modifyAssessment(assessment, isAdd) {
		let value = this.props.ipd.modalNurseRecord.assessment
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : "\n", assessment)
		} else {
			assessment.split("\n").forEach((each) => {
				value = value.replace(each, "")
			})
		}
		value = Utils.replaceDuplicateEmptyLine(value)
		this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT, Utils.trim(value))
	}

	onCategoryAssessment(value) {
		this.props.setSelected(
			SET_IPD_MODAL_NURSE_RECORD_ASSESSMENT_SELECTED_CATEGORY,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.ipd.modalNurseRecord.categories[value] : null
		)
	}

	onSelectAssessment(value) {
		this.modifyAssessment(this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments[value].description, true)
	}

	onDeselectAssessment(value) {
		this.modifyAssessment(this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments[value].description, false)
	}

	onSelectAllAssessment(event) {
		let assessments = ""
		this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments.forEach((each) => {
			if (!_.includes(this.props.ipd.modalNurseRecord.assessment, each.description)) {
				assessments = assessments.concat(each.description, "\n")
			}
		})
		this.modifyAssessment(
			!_.isEmpty(assessments)
				? assessments
				: this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments.reduce((total, cur) => {
						return (total = total.concat(cur.description, "\n"))
				  }, ""),
			!_.isEmpty(assessments)
		)
	}

	onAssessment(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.assessment)}</legend>
				<Container fluid="small" className="full-flex">
					<Row className="g-1">
						<Col md={3}>
							<Select
								showSearch
								value={
									this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category
										? this.props.ipd.modalNurseRecord.selectedAssessmentCategory.index
										: undefined
								}
								size={"small"}
								style={{ flexGrow: 1, textAlign: "center" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
								placeholder={translate(ResourceAssistance.Message.default)}
								onChange={this.onCategoryAssessment}
							>
								{Utils.renderSelects(this.props.ipd.modalNurseRecord.categories, false)}
							</Select>
						</Col>
						<Col>
							<Select
								mode={ResourceAssistance.Select.mode.multiple}
								size={"small"}
								style={{ flexGrow: 1, textAlign: "center" }}
								disabled={!this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category}
								value={this.getValues()}
								maxTagCount="responsive"
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
								loading={this.props.ipd.modalNurseRecord.isCategoriesLoading}
								onSelect={this.onSelectAssessment}
								onDeselect={this.onDeselectAssessment}
								dropdownRender={(menu) => (
									<Fragment>
										{menu}
										{this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category &&
											!_.isEmpty(this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments) && (
												<Fragment>
													<Divider style={{ margin: "4px 0" }} />
													<Row>
														<Col />
														<Col md="auto">
															<Button
																variant={ResourceAssistance.Button.variant.primary}
																disabled={
																	!this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category ||
																	_.isEmpty(this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments)
																}
																onMouseDown={(e) => e.preventDefault()}
																onClick={this.onSelectAllAssessment}
															>
																{this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category &&
																this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments.every((assessment) =>
																	this.props.ipd.modalNurseRecord.assessment.includes(assessment.description)
																) ? (
																	<Fragment>
																		<MdRemoveDone size={ResourceAssistance.ReactIcon.size} />
																		{translate(ResourceAssistance.Message.deselectAll)}
																	</Fragment>
																) : (
																	<Fragment>
																		<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
																		{translate(ResourceAssistance.Message.selectAll)}
																	</Fragment>
																)}
															</Button>
														</Col>
														<Col />
													</Row>
												</Fragment>
											)}
									</Fragment>
								)}
							>
								{Utils.renderSelects(
									this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category
										? this.props.ipd.modalNurseRecord.selectedAssessmentCategory.category.assessments
										: [],
									false,
									-1,
									"description"
								)}
							</Select>
						</Col>
					</Row>
					<Row className="full-size">
						<Col>
							<InputGroup>
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={this.props.ipd.selectedNurseNote.nurseNote ? 5 : 4}
									value={this.props.ipd.modalNurseRecord.assessment}
									onChange={this.onAssessment}
								/>
							</InputGroup>
						</Col>
					</Row>
				</Container>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseAssessment))
