import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_MEDICAL_RECORD_CC,
	SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS,
	SET_OPD_MODAL_MEDICAL_RECORD_PI,
	SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OPDModalMedicalRecordConsultation extends React.Component {
	constructor(props) {
		super(props)

		this.onCC = this.onCC.bind(this)
		this.onPI = this.onPI.bind(this)
		this.onDiagnosis = this.onDiagnosis.bind(this)
		this.onTreatment = this.onTreatment.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			let medicalRecord = this.props.opd.selectedMedicalRecord.medicalRecord
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_CC, medicalRecord.cc)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PI, medicalRecord.pi)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS, medicalRecord.diagnosis)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT, medicalRecord.treatment)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_CC, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PI, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT, "")
	}

	onCC(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_CC, event.target.value)
	}

	onPI(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PI, event.target.value)
	}

	onDiagnosis(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_DIAGNOSIS, event.target.value)
	}

	onTreatment(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_TREATMENT, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.doctorConsultation)}</legend>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={4}
							maxRows={4}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.chiefComplaint })}
							onChange={this.onCC}
							value={this.props.opd.modalMedicalRecord.CC}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={4}
							maxRows={4}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.presentIllness })}
							onChange={this.onPI}
							value={this.props.opd.modalMedicalRecord.PI}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={4}
							maxRows={4}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.diagnosis })}
							value={this.props.opd.modalMedicalRecord.diagnosis}
							onChange={this.onDiagnosis}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={4}
							maxRows={4}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.treatment })}
							value={this.props.opd.modalMedicalRecord.treatment}
							onChange={this.onTreatment}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecordConsultation))
