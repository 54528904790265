import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { FiCheckSquare } from "react-icons/fi"
import { MdChecklist, MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOPDOperatingRoomRequests,
	setOPDPatients,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY,
	SET_OPD_OPERATING_ROOM_DISPLAY,
	SET_OPD_OPERATING_ROOM_REQUEST_INFO_DISPLAY,
	SET_OPD_SELECTED_OR_REQUEST,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightOrderTabOR extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onDoubleRow = this.onDoubleRow.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onComplete = this.onComplete.bind(this)
		this.onPreOperativeCheckList = this.onPreOperativeCheckList.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDOperatingRoomRequests(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.operatingRoomRequests)
		} else {
			this.props.setOPDOperatingRoomRequests([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.completeOperatingRoomRequest)) {
				this.completeOperatingRoomRequest()
			}
		}
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteOperatingRoomRequest)) {
				this.deleteOperatingRoomRequest()
			}
		}
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDOperatingRoomRequests(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.operatingRoomRequests)
			} else {
				this.props.setOPDOperatingRoomRequests([])
			}
		}

		if (!_.isEqual(prevProps.opd.operatingRoomRequestTable.filtered, this.props.opd.operatingRoomRequestTable.filtered)) {
			let index = this.props.opd.selectedORRequest.request
				? this.props.opd.operatingRoomRequestTable.filtered.findIndex((request) => request.id === this.props.opd.selectedORRequest.request.id)
				: -1
			if (index > -1) {
				this.props.setSelected(SET_OPD_SELECTED_OR_REQUEST, index, this.props.opd.operatingRoomRequestTable.filtered[index])
			} else {
				this.props.setSelected(SET_OPD_SELECTED_OR_REQUEST, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_SELECTED_OR_REQUEST, -1, null)
	}

	isDisplayEdit() {
		return (
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	isDisplayDelete() {
		return (
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	isDisplayComplete() {
		return (
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	isDisplayPreOperativeCheckList() {
		return (
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	completeOperatingRoomRequest() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.updateOperatingRoomRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				operatingRoomRequests: [
					{
						id: this.props.opd.selectedORRequest.request.id,
						status: ResourceAssistance.Enum.completed,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deleteOperatingRoomRequest() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteMedicalCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				medicalCertificates: [
					{
						id: this.props.opd.selectedCertificate.certificate.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_OPD_SELECTED_OR_REQUEST, index, this.props.opd.operatingRoomRequestTable.filtered[index])
	}

	onDoubleRow() {
		this.props.onModalDisplayAction(SET_OPD_OPERATING_ROOM_REQUEST_INFO_DISPLAY, true)
	}

	onNew() {
		this.props.onModalDisplayAction(SET_OPD_OPERATING_ROOM_DISPLAY, true)
		this.props.setSelected(SET_OPD_SELECTED_OR_REQUEST, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_OPD_OPERATING_ROOM_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		// let selectedCertificate = this.props.opd.certificateTable.filtered[rIdx]
		// this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteCertificate)
		// this.props.setWarningMsgAction(
		// 	`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
		//     ${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.medicalCertificate })}
		//     ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(selectedCertificate.creationDateTime)}
		//     `
		// )
		// this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onComplete(e, row, rIdx, cIdx) {
		let selectedORRequest = this.props.opd.operatingRoomRequestTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.completeOperatingRoomRequest)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				hoverable
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
						{`${Utils.formatDateTime(selectedORRequest.operatingRoomSchedules[0].startDateTime)} - ${Utils.formatDateTime(
							selectedORRequest.operatingRoomSchedules[0].endDateTime
						)}`}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.orCase)} span={1}>
						{selectedORRequest.operatingRoomCase}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onPreOperativeCheckList() {
		this.props.onModalDisplayAction(SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlinePrimary,
						]}
						btnIcons={[
							this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayComplete() ? <FiCheckSquare size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayPreOperativeCheckList() ? <MdChecklist size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onModify, this.onDelete, this.onComplete, this.onPreOperativeCheckList]}
						data={this.props.opd.operatingRoomRequestTable}
						onClick={this.onSelectRow}
						onDoubleClick={this.onDoubleRow}
						isClearHighlight={this.props.opd.selectedORRequest.index === -1}
						highlightedRow={this.props.opd.selectedORRequest.index}
						extra={
							this.props.opd.selectedMedicalRecord.medicalRecord &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDOperatingRoomRequests,
			setOPDPatients,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightOrderTabOR))
