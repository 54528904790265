import { Card } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdminHealthCarePlanCompanies,
	setAdminHealthCareTypes,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY,
	SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AdmHealthCarePlanCompany extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onSelectCompany = this.onSelectCompany.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.ADM.category.healthcarePlan.deleteCompany)
			) {
				this.deleteCompany()
			}
		}

		if (!_.isEqual(prevProps.type.selectedType, this.props.type.selectedType)) {
			if (this.props.type.selectedType.type) {
				this.props.setAdminHealthCarePlanCompanies(this.props.type.selectedType.type.companies)
			} else {
				this.props.setAdminHealthCarePlanCompanies([])
			}
		}

		if (
			!_.isEqual(
				prevProps.type.healthcarePlan.companyTable.filtered,
				this.props.type.healthcarePlan.companyTable.filtered
			)
		) {
			let index = this.props.type.healthcarePlan.selectedCompany.company
				? this.props.type.healthcarePlan.companyTable.filtered.findIndex(
						(company) => company.id === this.props.type.healthcarePlan.selectedCompany.company.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(
					SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY,
					index,
					this.props.type.healthcarePlan.companyTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setAdminHealthCarePlanCompanies([])
		this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY, -1, null)
	}

	deleteCompany() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.type.healthCarePlan.deleteHealthCarePlanCompany,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				healthcarePlanCompanies: [
					{
						id: this.props.type.healthcarePlan.selectedCompany.company.id,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setAdminHealthCareTypes(res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onNew(e) {
		this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY, -1, null)
		this.props.onModalDisplayAction(SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY, true)
	}

	onSelectCompany(company, index) {
		this.props.setSelected(
			SET_ADM_HEALTH_CARE_PLAN_SELECTED_COMPANY,
			index,
			this.props.type.healthcarePlan.companyTable.filtered[index]
		)
	}

	onModify() {
		this.props.onModalDisplayAction(SET_ADM_HEALTH_CARE_PLAN_COMPANY_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedCompany = this.props.type.healthcarePlan.companyTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningId(ResourceAssistance.ID.ADM.category.healthcarePlan.deleteCompany)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>{selectedCompany.name}</p>
				<p>{selectedCompany.description}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnMessages={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.type.healthcarePlan.companyTable}
						onClick={this.onSelectCompany}
						isClearHighlight={this.props.type.healthcarePlan.selectedCompany.index === -1}
						highlightedRow={this.props.type.healthcarePlan.selectedCompany.index}
						extra={
							this.props.type.selectedType.type && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdminHealthCarePlanCompanies,
			setAdminHealthCareTypes,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmHealthCarePlanCompany))
