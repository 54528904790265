import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportOPDMedicalCertificateDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayTitle={false}
						displayBranch={true}
						displayFax={false}
						displayTaxNum={false}
						styles={{
							org: {
								fontSize: 24,
							},
						}}
					/>
					<ReportTitle
						data={this.state.data}
						displayOrg={false}
						displayFax={false}
						displayTaxNum={false}
						displayTel={false}
						displayLogo={false}
						displayAddress={false}
					/>

					<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
							<Text>Document Number/{this.state.data.documentNumIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.documentNum}</Text>
						</View>
						{this.state.data.displayDepartmentCheckBox && (
							<Fragment>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text style={[ResourceAssistance.Report.styles.textIcon]}>
										{ResourceAssistance.Symbol.Report.emptyCheckBox}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>IPD</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col]} />
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text style={[ResourceAssistance.Report.styles.textIcon]}>
										{ResourceAssistance.Symbol.Report.emptyCheckBox}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>OPD</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col]} />
							</Fragment>
						)}
					</View>

					<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
							<Text>Doctor's Name/{this.state.data.doctorNameIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
							<Text>{this.state.data.doctorName}</Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
							<Text>License No./{this.state.data.licenseNoIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.dashBottom]}>
							<Text>{this.state.data.licenseNo}</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
							<Text>Certifies that/{this.state.data.certifiesThatIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
							<Text>{this.state.data.patientName}</Text>
						</View>
					</View>

					<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
							<Text>Has examined on/{this.state.data.hasExaminedOnIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.dashBottom, { marginRight: 10 }]}>
							<Text>{this.state.data.examinationStartDate}</Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 10 }]}>
							<Text>To/{this.state.data.toIntl}</Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.dashBottom]}>
							<Text>{this.state.data.examinationEndDate}</Text>
						</View>
					</View>

					{/* Symptoms */}
					<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Symptoms/{this.state.data.symptomsIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.symptoms}</Text>
								</View>
							</View>
						</View>
					</View>

					{/* Diagnosis */}
					<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Diagnosis/{this.state.data.diagnosisIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.diagnosis}</Text>
								</View>
							</View>
						</View>
					</View>

					{/* Investigation */}
					<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Investigation/{this.state.data.investigationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.investigation}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row, { paddingLeft: 20 }]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text style={[ResourceAssistance.Report.styles.textIcon]}>
										{this.state.data.isBloodTestChecked
											? ResourceAssistance.Symbol.Report.checkBox
											: ResourceAssistance.Symbol.Report.emptyCheckBox}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Blood Test/{this.state.data.bloodTestIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.bloodTestDetails}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row, styles.row, { paddingLeft: 20 }]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text style={[ResourceAssistance.Report.styles.textIcon]}>
										{this.state.data.isRadiologyChecked
											? ResourceAssistance.Symbol.Report.checkBox
											: ResourceAssistance.Symbol.Report.emptyCheckBox}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Radiology/{this.state.data.radiologyIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.radiologyDetails}</Text>
								</View>
							</View>
						</View>
					</View>

					{/* Treatment */}
					<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Treatment/{this.state.data.treatmentIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.treatment}</Text>
								</View>
							</View>
						</View>
					</View>

					{/* Doctor Opinion */}
					<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Doctor's opinion/{this.state.data.doctorOpinionIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.dashBottom]}>
									<Text>{this.state.data.doctorOpinion}</Text>
								</View>
							</View>
						</View>
					</View>

					{this.state.data.day > 0 && (
						<View style={[ResourceAssistance.Report.styles.row, { flexGrow: 1 }]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>
											{this.state.data.theAboveNamedPersonRequiresIntl} {this.state.data.day}{" "}
											{this.state.data.daysOfRestIntl}
										</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>The above named person requires {this.state.data.day} Days of rest.</Text>
									</View>
								</View>

								<View style={[ResourceAssistance.Report.styles.row, styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
										<Text>From/{this.state.data.fromIntl}: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text style={{ textDecoration: "underline", textDecorationStyle: "dotted" }}>
											{this.state.data.fromDate}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
										<Text>To/{this.state.data.toIntl}: </Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text style={{ textDecoration: "underline", textDecorationStyle: "dotted" }}>
											{this.state.data.toDate}
										</Text>
									</View>
								</View>
							</View>
						</View>
					)}

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col]}></View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text style={{ alignSelf: "center" }}>{this.state.data.signatureIntl}</Text>
									<Text>Signature</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text style={{ borderBottom: 1 }}> </Text>
									<Text style={{ textAlign: "center" }}>{this.state.data.doctorName}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Text>{this.state.data.mdIntl}</Text>
									<Text style={{ alignSelf: "center" }}>MD.</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text style={{ textAlign: "center" }}>{this.state.data.printDate}</Text>
								</View>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	row: {
		marginBottom: 15,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {},
	dashBottom: {
		borderBottomStyle: "dashed",
		borderBottomWidth: 1,
	},
	textIcon: {},
})

export default ReportOPDMedicalCertificateDocument
