import React, { Fragment } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setHospitelModalNurseOrderPendingItems } from "~/redux/action"
import { SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY } from "~/redux/type"

class HospitelModalMedicalSupplyAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	onDownBtn() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onUpBtn() {
		let pendingItems = this.props.hospitel.modalNurseOrder.pendingItemTable.filtered.filter(
			(each, index) => index !== this.props.hospitel.modalNurseOrder.selectedPendingItem.index
		)
		this.props.setHospitelModalNurseOrderPendingItems(pendingItems)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.hospitel.modalNurseOrder.selectedItem.index === -1}
							onClick={this.onDownBtn}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.hospitel.modalNurseOrder.selectedPendingItem.index === -1}
							onClick={this.onUpBtn}
						>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setHospitelModalNurseOrderPendingItems,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalMedicalSupplyAction)
