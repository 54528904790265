import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setOPDPatients } from "~/redux/action"
import { SET_OPD_PATIENTS_LOADING } from "~/redux/type"
import OutpatientLeftDate from "./OutpatientLeftDate"
import OutpatientLeftMedicalRecord from "./OutpatientLeftMedicalRecord"
import OutpatientLeftMedicalRecordAction from "./OutpatientLeftMedicalRecordAction"
import OutpatientLeftPatient from "./OutpatientLeftPatient"
import OutpatientLeftPatientAction from "./OutpatientLeftPatientAction"
import OutpatientLeftSearch from "./OutpatientLeftSearch"

class OutpatientLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientLeft",
		}
	}

	componentDidMount() {
		this.loadPatients()
	}

	loadPatients() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.opd.location.id,
				startDateTime: this.props.opd.startDateTime,
				endDateTime: this.props.opd.endDateTime,
			},
		}
		let callback = (res) => {
			res.data.patients.forEach((patient) =>
				Object.assign(patient, {
					loadPreviousMedicalRecords: true,
				})
			)
			this.props.setOPDPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OPD_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<OutpatientLeftDate />
				<OutpatientLeftSearch />
				<OutpatientLeftPatientAction />
				<OutpatientLeftPatient />
				<OutpatientLeftMedicalRecordAction />
				<OutpatientLeftMedicalRecord />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OutpatientLeft)
