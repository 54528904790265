import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportDebtReceiptDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				invoices: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* General Info */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.idIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.id}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.nameIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.customer}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.paymentIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.payment}</Text>
							</View>
							{this.state.data.chequeNumber && (
								<>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>{this.state.data.chequeNumberIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.chequeNumber}</Text>
									</View>
								</>
							)}
							{this.state.data.chequeDate && (
								<>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>{this.state.data.dateIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.chequeDate}</Text>
									</View>
								</>
							)}
							{/* {this.state.data.creditCardNumber && (
								<>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
										<Text>{this.state.data.creditCardNumberIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
										<Text>{this.state.data.creditCardNumber}</Text>
									</View>
								</>
							)} */}
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.invoice]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.invoiceIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.patient]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.patientIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.discountIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.totalIntl}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{this.state.data.invoices.map((invoice) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.invoice]}>
											<Text>{invoice.id}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.patient]}>
											<Text>{invoice.patient}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
											<Text>{invoice.amount}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.discount]}>
											<Text>{invoice.discount}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.total]}>
											<Text>{invoice.total}</Text>
										</View>
									</View>
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Total */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.grandTotalText]}>
								<Text>{this.state.data.grandTotalText}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.amount, { fontStyle: "bold" }]}>
								<Text>{this.state.data.grandTotalIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.discount]}>
								<Text>{this.state.data.grandDiscount}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.total]}>
								<Text>{this.state.data.grandTotal}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Remark */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.remark]}>
								<Text>* {this.state.data.remarkIntl}</Text>
							</View>
						</View>

						<Signature numOfSignature={1} signatureTitles={this.state.data.signatureNames} signatureDate={this.state.data.signatureDate} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {},
	invoice: {
		textAlign: "left",
		minWidth: "15%",
		maxWidth: "15%",
	},
	patient: {
		minWidth: "40%",
		maxWidth: "40%",
	},
	amount: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
	discount: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
	total: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
	grandTotalText: {
		inWidth: "55%",
		maxWidth: "55%",
	},
	remark: {
		fontStyle: "bold-italic",
	},
})

export default ReportDebtReceiptDoc
