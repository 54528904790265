import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OR_MODAL_CANCEL_REASON } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OperatingRoomModalCancelDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onReason = this.onReason.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_CANCEL_REASON, "")
	}

	onReason(e) {
		this.props.setValue(SET_OR_MODAL_CANCEL_REASON, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							placeholder={translate(ResourceAssistance.Message.reason)}
							minRows={3}
							onChange={this.onReason}
							value={this.props.operatingRoom.modalCancel.reason}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalCancelDetails)
