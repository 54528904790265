import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setARCTabReceiptGroups, setSelected } from "~/redux/action"
import { SET_ARC_SELECTED_GROUP } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceiveRightTabReceipt extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		if (this.props.ARC.selectedReceipt.receipt) {
			this.props.setARCTabReceiptGroups([this.props.ARC.selectedReceipt.receipt])
		} else {
			this.props.setARCTabReceiptGroups([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ARC.selectedReceipt, this.props.ARC.selectedReceipt)) {
			if (this.props.ARC.selectedReceipt.receipt) {
				this.props.setARCTabReceiptGroups([this.props.ARC.selectedReceipt.receipt])
			} else {
				this.props.setARCTabReceiptGroups([])
			}
		}
		if (!_.isEqual(prevProps.ARC.tabReceiptTable.filtered, this.props.ARC.tabReceiptTable.filtered)) {
			let index = this.props.ARC.selectedGroup.group
				? this.props.ARC.tabReceiptTable.filtered.findIndex(
						(group) => group[0] && this.props.ARC.selectedGroup.group[0] && group[0].id === this.props.ARC.selectedGroup.group[0].id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_ARC_SELECTED_GROUP, index, this.props.ARC.tabReceiptTable.filtered[index])
			} else {
				this.props.setSelected(SET_ARC_SELECTED_GROUP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ARC_SELECTED_GROUP, -1, null)
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_ARC_SELECTED_GROUP, index, this.props.ARC.tabReceiptTable.filtered[index])
	}

	render() {
		let totalBalance = this.props.ARC.tabReceiptTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[1]))
		}, Utils.BigNumber(0))
		let totalDiscountService = this.props.ARC.tabReceiptTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[2]))
		}, Utils.BigNumber(0))
		let totalDiscountHealthCarePlan = this.props.ARC.selectedReceipt.receipt
			? this.props.ARC.selectedReceipt.receipt.billingPlanAdjustments.reduce((total, cur) => {
					return total.plus(cur.adjustments)
			  }, Utils.BigNumber(0))
			: Utils.BigNumber(0)
		let totalDiscountedBalance = totalBalance.minus(totalDiscountService).minus(totalDiscountHealthCarePlan)
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						data={this.props.ARC.tabReceiptTable}
						isClearHighlight={this.props.ARC.selectedGroup.index === -1}
						highlightedRow={this.props.ARC.selectedGroup.index}
						onClick={this.onSelectRow}
						extra={
							!_.isEmpty(this.props.ARC.tabReceiptTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalBalance)}>
												{Utils.formatNumWithComma(totalBalance.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.discountService)}>
												{Utils.formatNumWithComma(totalDiscountService.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.discountBill)}>
												{Utils.formatNumWithComma(totalDiscountHealthCarePlan.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item
												label={translate(ResourceAssistance.Message.totalPriceDiscounted)}
												contentStyle={{ color: totalDiscountedBalance.isLessThan(0) ? "red" : undefined }}
											>
												{Utils.formatNumWithComma(totalDiscountedBalance.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setARCTabReceiptGroups,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceiveRightTabReceipt))
