import { Utils } from "~/utils/Utils"
import { SET_ADMIN_PERMISSION_PERMISSIONS } from "../../type/type/ADMIN"

export const setAdmPermissionPermissions = (permissions) => {
	return (dispatch, getState) => {
		let filtered = permissions
			.filter((each) => {
				return each.displayName.toLowerCase().includes(getState().admin.userConfig.permission.searchName.toLowerCase())
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.description, each.createdBy.displayName, Utils.formatDate(each.creationDateTime)]
		})

		dispatch({
			type: SET_ADMIN_PERMISSION_PERMISSIONS,
			payload: {
				original: permissions,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
