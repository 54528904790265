import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setLocation, setValue } from "~/redux/action"
import { SET_HEADER, SET_ORGS, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import Accounting from "./accounting/Accounting"
import Administration from "./administration/Administration"
import "./home.css"
import Hospital from "./hospital/Hospital"
import Management from "./stock-manager/Management"

export const EType = {
	INV: "INV",
	TF: "TF",
	PR: "PR",
	PO: "PO",
	RC: "RC",
	TYPE: "TYPE",
	SERVICE: "SERVICE",
	CUSTOM_DEFAULT: "CUSTOM_DEFAULT",
	LOCATION: "LOCATION",
	ITEM: "ITEM",
	ICD: "ICD",
	USER: "USER",
	LABORATORY: "LABORATORY",
	ADMIN: {
		COA: "CHART_OF_ACCOUNTS",
		GENERAL_LEDGER: "GENERAL_LEDGER",
		WORKFLOW_PROPERTY: "WORKFLOW_PROPERTY",
	},
	REPORT: {
		TF: "REPORT_TF",
		PR: "REPORT_PR",
		PO: "REPORT_PO",
		RC: "REPORT_RC",
		INV: "REPORT_INV",
	},
	HOSPITAL: {
		DEPOSIT: "HS_DEPOSIT",
		CASHIER: "HS_CASHIER",
		IPD: "HS_IPD",
		COVID19: "HS_COVID19",
		REGISTRATION: "HS_REGISTRATION",
		RADIOLOGY: "HIS_RADIOLOGY",
		OPD: "HS_OPD",
		PHARMACY: "HS_PHARMACY",
		FRONT_INVENTORY: "HS_FRONT_INVENTORY",
		OPERATING_ROOM: "HIS_OPERATING_ROOM",
		SURGICAL_AUTHORIZATION: "SURGICAL_AUTHORIZATION",
		REPORT: {
			CASHIER: "HS_REPORT_CASHIER",
			IPD: "HS_REPORT_IPD",
			LAB: "HS_REPORT_LAB",
		},
	},
	ACCOUNTING: {
		ARC: "ACC_RECEIVE",
		AP: "ACC_PAYABLE",
		AR: "ACC_RECEIVABLE",
		DEBT: "ACC_DEBT",
		FINANCIAL_OPERATIONS: "ACC_FINANCIAL_OPERATIONS",
		PI: "ACC_PURCHASE_INVOICE",
		PS: "ACC_PAYMENT_SLIP",
		PV: "ACC_PAYMENT_VOUCHER",
		RC: "ACC_RC",
		PGL: "ACC_PGL",
	},
}

class Home extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(EType)
		this.state = {
			componentName: "Home",
		}

		this.onBtnClick = this.onBtnClick.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_HEADER, ResourceAssistance.CONSTANT.PMGCARE)
		if (this.props.common.orgs.length === 0) {
			this.loadLocs()
		}
	}

	loadLocs() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.location.loadLocs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			Utils.convertToObject(res.data.locations)
			this.props.setValue(
				SET_ORGS,
				res.data.locations.filter((loc) => {
					return loc.code.displayName === "ORG"
				})
			)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onBtnClick(event) {
		switch (event.target.value) {
			//Stock
			case EType.INV:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory })
				this.props.history.push(ResourceAssistance.Path.inventory)
				break
			case EType.TF:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.transfer })
				this.props.history.push(ResourceAssistance.Path.transfer)
				break
			case EType.PR:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseRequest })
				this.props.history.push(ResourceAssistance.Path.pr)
				break
			case EType.PO:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseOrder })
				this.props.history.push(ResourceAssistance.Path.po)
				break
			case EType.RC:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.receive })
				this.props.history.push(ResourceAssistance.Path.rc)
				break

			// Admin
			case EType.USER:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.user })
				this.props.history.push(ResourceAssistance.Path.configUser)
				break
			case EType.TYPE:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.type })
				this.props.history.push(ResourceAssistance.Path.configType)
				break
			case EType.LABORATORY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.lab })
				this.props.history.push(ResourceAssistance.Path.configLaboratory)
				break
			case EType.LOCATION:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.location })
				this.props.history.push(ResourceAssistance.Path.configLoc)
				break
			case EType.ITEM:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.item })
				this.props.history.push(ResourceAssistance.Path.configItem)
				break
			case EType.SERVICE:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.service })
				this.props.history.push(ResourceAssistance.Path.configService)
				break
			case EType.CUSTOM_DEFAULT:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.default })
				this.props.history.push(ResourceAssistance.Path.configCustomDefault)
				break
			case EType.ICD:
				document.title = this.props.intl.formatMessage({
					id: ResourceAssistance.Message.internationalClassificationDiseases,
				})
				this.props.history.push(ResourceAssistance.Path.configICD)
				break
			case EType.ADMIN.COA:
				document.title = this.props.intl.formatMessage({
					id: ResourceAssistance.Message.chartOfAccounts,
				})
				this.props.history.push(ResourceAssistance.Path.configCOA)
				break
			case EType.ADMIN.GENERAL_LEDGER:
				document.title = this.props.intl.formatMessage({
					id: ResourceAssistance.Message.generalLedger,
				})
				this.props.history.push(ResourceAssistance.Path.configGL)
				break
			case EType.ADMIN.WORKFLOW_PROPERTY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.workflowProperty })
				this.props.history.push(ResourceAssistance.Path.configWP)
				break

			//Report
			case EType.REPORT.INV:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory })
				this.props.history.push(ResourceAssistance.Path.reportInv)
				break
			case EType.REPORT.TF:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.transfer })
				this.props.history.push(ResourceAssistance.Path.reportTf)
				break
			case EType.REPORT.PR:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseRequest })
				this.props.history.push(ResourceAssistance.Path.reportPr)
				break
			case EType.REPORT.PO:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.purchaseOrder })
				this.props.history.push(ResourceAssistance.Path.reportPo)
				break
			case EType.REPORT.RC:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.receive })
				this.props.history.push(ResourceAssistance.Path.reportRc)
				break

			//Hospital
			case EType.HOSPITAL.DEPOSIT:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.deposit })
				this.props.history.push(ResourceAssistance.Path.HIS.deposit)
				break
			case EType.HOSPITAL.CASHIER:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier })
				this.props.history.push(ResourceAssistance.Path.Hospitel.cashier)
				break
			case EType.HOSPITAL.REGISTRATION:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.registration })
				this.props.history.push(ResourceAssistance.Path.Hospitel.registration)
				break
			case EType.HOSPITAL.IPD:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.ipd })
				this.props.history.push(ResourceAssistance.Path.Hospitel.hospitel)
				break
			case EType.HOSPITAL.COVID19:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.covid19Lab })
				this.props.history.push(ResourceAssistance.Path.Hospitel.covid19Lab)
				break
			case EType.HOSPITAL.RADIOLOGY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.radiology })
				this.props.history.push(ResourceAssistance.Path.HIS.radiology)
				break
			case EType.HOSPITAL.OPD:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.opd })
				this.props.history.push(ResourceAssistance.Path.HIS.opd)
				break
			case EType.HOSPITAL.PHARMACY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.pharmacy })
				this.props.history.push(ResourceAssistance.Path.HIS.pharmacy)
				break
			case EType.HOSPITAL.FRONT_INVENTORY:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory })
				this.props.history.push(ResourceAssistance.Path.HIS.frontInventory)
				break
			case EType.HOSPITAL.OPERATING_ROOM:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.operatingRoom })
				this.props.history.push(ResourceAssistance.Path.HIS.operatingRoom)
				break
			case EType.HOSPITAL.SURGICAL_AUTHORIZATION:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.surgicalAuthorization })
				this.props.history.push(ResourceAssistance.Path.HIS.surgicalAuthorization)
				break

			//HIS Report
			case EType.HOSPITAL.REPORT.CASHIER:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.cashier })
				this.props.history.push(ResourceAssistance.Path.Hospitel.reportCashier)
				break
			case EType.HOSPITAL.REPORT.IPD:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.ipd })
				this.props.history.push(ResourceAssistance.Path.Hospitel.reportHospitel)
				break
			case EType.HOSPITAL.REPORT.LAB:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.covid19Lab })
				this.props.history.push(ResourceAssistance.Path.Hospitel.reportLab)
				break

			//Accounting
			case EType.ACCOUNTING.ARC:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.accountsReceive })
				this.props.history.push(ResourceAssistance.Path.ACC.accountsReceive)
				break
			case EType.ACCOUNTING.AR:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.accountsReceivable })
				this.props.history.push(ResourceAssistance.Path.ACC.accountsReceivable)
				break
			case EType.ACCOUNTING.RC:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.contractBilling })
				this.props.history.push(ResourceAssistance.Path.ACC.contractBilling)
				break
			case EType.ACCOUNTING.DEBT:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.debt })
				this.props.history.push(ResourceAssistance.Path.ACC.debt)
				break
			case EType.ACCOUNTING.PI:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.accountsPayablePI })
				this.props.history.push(ResourceAssistance.Path.ACC.purchaseInvoice)
				break
			case EType.ACCOUNTING.PV:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.accountsPayablePV })
				this.props.history.push(ResourceAssistance.Path.ACC.paymentVoucher)
				break
			case EType.ACCOUNTING.AP:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.accountsPayableRC })
				this.props.history.push(ResourceAssistance.Path.ACC.accountsPayable)
				break
			case EType.ACCOUNTING.PS:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.paymentSlip })
				this.props.history.push(ResourceAssistance.Path.ACC.paymentSlip)
				break
			case EType.ACCOUNTING.FINANCIAL_OPERATIONS:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.financialOperations })
				this.props.history.push(ResourceAssistance.Path.ACC.financialOperations)
				break
			case EType.ACCOUNTING.PGL:
				document.title = this.props.intl.formatMessage({ id: ResourceAssistance.Message.postingGL })
				this.props.history.push(ResourceAssistance.Path.ACC.postingGL)
				break

			default:
				break
		}
	}

	render() {
		return (
			// <div id={this.state.componentName}>
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{/* Hospital */}
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.View, this.props.login.user.roles) && (
					<Row className={"full-size"}>
						<Col>
							<Hospital onBtnClick={this.onBtnClick} />
						</Col>
					</Row>
				)}
				{/* Hospital Report */}
				{/* <Row className={"full-size"}>
					<Col>
						<HospitalReport onBtnClick={this.onBtnClick} />
					</Col>
				</Row> */}

				{/* Management */}
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.View, this.props.login.user.roles) && (
					<Row className={"full-size"}>
						<Col>
							<Management onBtnClick={this.onBtnClick} />
						</Col>
					</Row>
				)}

				{/* Accounting */}
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.View, this.props.login.user.roles) && (
					<Row className={"full-size"}>
						<Col>
							<Accounting onBtnClick={this.onBtnClick} />
						</Col>
					</Row>
				)}

				{/* Management Report */}
				{/* <Row className={"full-size"}>
					<Col><ManagementReport onBtnClick={this.onBtnClick} /></Col>
				</Row> */}
				{/* Admin */}
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Admin.View, this.props.login.user.roles) && (
					<Row className={"full-size"}>
						<Col>
							<Administration onBtnClick={this.onBtnClick} />
						</Col>
					</Row>
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	common: state.common,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLocation,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home))
