import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class PaymentVoucherRightInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.generalInfo)}</legend>
						<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={3}>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
								{this.props.PV.selectedPV.PV ? Utils.formatDate(this.props.PV.selectedPV.PV.paymentTermDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
								{this.props.PV.selectedPV.PV ? this.props.PV.selectedPV.PV.payment : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.withholdingTax)} span={1}>
								{this.props.PV.selectedPV.PV ? Utils.formatDate(this.props.PV.selectedPV.PV.withholdingTaxIssuedDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.psId)} span={3}>
								{this.props.PV.selectedPV.PV && this.props.PV.selectedPV.PV.paymentPlan ? this.props.PV.selectedPV.PV.paymentPlan.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.supplierCode)} span={1}>
								{this.props.PV.selectedPV.PV ? this.props.PV.selectedPV.PV.supplierCode : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.supplierName)} span={1}>
								{this.props.PV.selectedPV.PV ? this.props.PV.selectedPV.PV.supplierName : ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={3}>
								{this.props.PV.selectedPV.PV ? this.props.PV.selectedPV.PV.remark : ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentVoucherRightInfo))
