import { LOGIN, LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_ERROR } from "../type"

export const loginAction = (type, arg, user) => {
	let payload = {}
	switch (type) {
		case LOGIN:
			payload = {
				isLogin: arg,
				user: user,
			}
			break

		case LOGIN_USERNAME:
			payload = {
				username: arg,
			}
			break

		case LOGIN_PASSWORD:
			payload = {
				password: arg,
			}
			break

		case LOGIN_ERROR:
			payload = {
				errorMsg: arg,
			}
			break

		default:
			break
	}

	return {
		type: type,
		payload: payload,
	}
}
