import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setIPDGCSs } from "~/redux/action"
import IPDRightGCSChart from "./IPDRightGCSChart"
import IPDRightGCSTable from "./IPDRightGCSTable"

class IPDRightGCS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightGCS",
		}
	}

	componentDidMount() {
		if (this.props.ipd.selectedHospitel.hospitel) {
			this.props.setIPDGCSs(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.glasgowComaScales)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel) ||
			!_.isEqual(prevProps.ipd.orderEndDateTime, this.props.ipd.orderEndDateTime)
		) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.props.setIPDGCSs(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.glasgowComaScales)
			} else {
				this.props.setIPDGCSs([])
			}
		}
	}

	render() {
		return this.props.graphic ? <IPDRightGCSChart /> : <IPDRightGCSTable />
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDGCSs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightGCS))
