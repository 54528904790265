export const SET_ADM_WP_DRG = "SET_ADM_WP_DRG"
export const SET_ADM_WP_MIN_COST = "SET_ADM_WP_MIN_COST"
export const SET_ADM_WP_PROPERTY = "SET_ADM_WP_PROPERTY"
export const SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY = "SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY"
export const SET_ADM_WP_ECLAIMUC_ISDISPLAYINACTIVE = "SET_ADM_WP_ECLAIMUC_ISDISPLAYINACTIVE"
export const SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE = "SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE"
export const SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME = "SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME"
export const SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE = "SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE"
export const SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHDESCRIPTION = "SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHDESCRIPTION"
export const SET_ADM_WP_ECLAIMUC_GETURL = "SET_ADM_WP_ECLAIMUC_GETURL"
export const SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT = "SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT"
export const SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT = "SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT"
export const SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT = "SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT"
export const SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT = "SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT"
export const SET_ADM_WP_ECLAIMUC_SELECTEDITEM = "SET_ADM_WP_ECLAIMUC_SELECTEDITEM"
export const SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING = "SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING"
export const SET_ADM_WP_ECLAIMUC_ITEMTABLE = "SET_ADM_WP_ECLAIMUC_ITEMTABLE"
export const SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE = "SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE"
export const SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME = "SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME"
export const SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION = "SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION"
export const SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT = "SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT"
export const SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE = "SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE"
export const SET_ADM_WP_ECLAIMUC_MODAL_TITLE = "SET_ADM_WP_ECLAIMUC_MODAL_TITLE"
export const SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL = "SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL"
export const SET_ADM_WP_ECLAIMUC_MODAL_CODE = "SET_ADM_WP_ECLAIMUC_MODAL_CODE"
export const SET_ADM_WP_ECLAIMUC_MODAL_NAME = "SET_ADM_WP_ECLAIMUC_MODAL_NAME"
export const SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION = "SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION"
export const SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT = "SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT"
export const SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT = "SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT"
export const SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE = "SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE"
