import React from "react"
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { AiOutlinePercentage } from "react-icons/ai"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setRcDetailAmount,
	setRcDetailBonus,
	setRcDetailCode,
	setRcDetailDiscontPricePerUnit,
	setRcDetailDiscount,
	setRcDetailDiscountedModified,
	setRcDetailDiscountedTotalPrice,
	setRcDetailDiscountTaxPricePerUnit,
	setRcDetailExpireDate,
	setRcDetailLot,
	setRcDetailName,
	setRcDetailPricePerUnit,
	setRcDetailSupplierDiscount,
	setRcDetailSupplierDiscountPricePerUnit,
	setRcDetailTax,
	setRcDetailTaxedTotalPrice,
	setRcDetailTaxedTotalPriceModified,
	setRcDetailTotalPrice,
	setRcDetailTotalPriceModified,
	setRcDetailUnit,
	setRcPendingReceives,
} from "~/redux/action"
import { SET_RC_RECEIVE_DETAIL_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

const EFormInput = {
	RC_DETAIL_FORM: "RC_DETAIL_FORM",
	RC_DETAIL_AMOUNT: "RC_DETAIL_AMOUNT",
	RC_DETAIL_TAXED_TOTAL_PRICE: "RC_DETAIL_TAXED_TOTAL_PRICE",
	RC_DETAIL_TOTAL_PRICE: "RC_DETAIL_TOTAL_PRICE",
	RC_DETAIL_DISCOUNTED_TOTAL_PRICE: "RC_DETAIL_DISCOUNTED_TOTAL_PRICE",
}

class RCModalReceiveDetail extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCModalReceiveDetail",
		}

		this.onClose = this.onClose.bind(this)
		this.onConfirm = this.onConfirm.bind(this)
		this.onAmountChange = this.onAmountChange.bind(this)
		this.onBonusChange = this.onBonusChange.bind(this)
		this.onExpireDateChange = this.onExpireDateChange.bind(this)
		this.onLotChange = this.onLotChange.bind(this)
		this.onTotalPriceChange = this.onTotalPriceChange.bind(this)
		this.onDiscountedTotalPrice = this.onDiscountedTotalPrice.bind(this)
		this.onTaxedTotalPriceChange = this.onTaxedTotalPriceChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isDisplay) {
			this.validatePrice()
		}

		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				this.props.setRcDetailCode(this.props.selectedWaiting.inventory.code)
				this.props.setRcDetailName(this.props.selectedWaiting.inventory.displayName)
				this.props.setRcDetailUnit(this.props.selectedWaiting.inventory.displayUnit)
				this.props.setRcDetailPricePerUnit(Utils.BigNumber(this.props.selectedWaiting.inventory.pricePerUnit).toFixed(2))
				this.props.setRcDetailDiscount(Utils.BigNumber(this.props.selectedWaiting.inventory.discount).toNumber())
				this.props.setRcDetailDiscontPricePerUnit(
					Utils.BigNumber(this.props.selectedWaiting.inventory.pricePerUnit)
						.times(1 - this.props.selectedWaiting.inventory.discount / 100)
						.toNumber()
				)
				this.props.setRcDetailSupplierDiscount(Utils.BigNumber(this.props.selectedWaiting.inventory.supplierDiscount).toNumber())
				this.props.setRcDetailSupplierDiscountPricePerUnit(
					Utils.BigNumber(this.props.selectedWaiting.inventory.pricePerUnit)
						.times(1 - this.props.selectedWaiting.inventory.discount / 100)
						.times(1 - this.props.selectedWaiting.inventory.supplierDiscount / 100)
						.toNumber()
				)
				this.props.setRcDetailTax(Utils.BigNumber(this.props.selectedWaiting.inventory.tax).toNumber())
				this.props.setRcDetailDiscountTaxPricePerUnit(
					Utils.BigNumber(this.props.selectedWaiting.inventory.pricePerUnit)
						.times(1 - this.props.selectedWaiting.inventory.discount / 100)
						.times(1 - this.props.selectedWaiting.inventory.supplierDiscount / 100)
						.times(1 + this.props.selectedWaiting.inventory.tax / 100)
						.toNumber()
				)
			}
		}

		let taxRate = this.props.modalReceiveDetail.tax
		let supplierDiscount = this.props.modalReceiveDetail.supplierDiscount
		let totalPrice = this.props.modalReceiveDetail.totalPrice
		let discountedTotalPrice = this.props.modalReceiveDetail.discountedTotalPrice
		let taxedTotalPrice = this.props.modalReceiveDetail.taxedTotalPrice

		if (prevProps.modalReceiveDetail.totalPrice !== this.props.modalReceiveDetail.totalPrice && this.props.modalReceiveDetail.isTotalPriceModified) {
			if (!totalPrice) {
				this.props.setRcDetailDiscountedTotalPrice("")
				this.props.setRcDetailTaxedTotalPrice("")
				this.props.setRcDetailTotalPriceModified(false)
				return
			}

			discountedTotalPrice = Utils.BigNumber(totalPrice).times(1 - supplierDiscount / 100)
			if (!discountedTotalPrice.isNaN()) {
				this.props.setRcDetailDiscountedTotalPrice(discountedTotalPrice.toFixed(2))
			}

			taxedTotalPrice = Utils.BigNumber(discountedTotalPrice).times(1 + taxRate / 100)
			if (!taxedTotalPrice.isNaN()) {
				this.props.setRcDetailTaxedTotalPrice(taxedTotalPrice.dp(2).toFixed(2))
			}
		}

		if (
			prevProps.modalReceiveDetail.discountedTotalPrice !== this.props.modalReceiveDetail.discountedTotalPrice &&
			this.props.modalReceiveDetail.isDiscountedTotalPriceModified
		) {
			if (!discountedTotalPrice) {
				this.props.setRcDetailTotalPrice("")
				this.props.setRcDetailTaxedTotalPrice("")
				this.props.setRcDetailDiscountedModified(false)
				return
			}

			totalPrice = Utils.BigNumber(discountedTotalPrice).dividedBy(1 - supplierDiscount / 100)
			if (!totalPrice.isNaN()) {
				this.props.setRcDetailTotalPrice(totalPrice.toFixed(2))
			}

			taxedTotalPrice = Utils.BigNumber(discountedTotalPrice).times(1 + taxRate / 100)
			if (!taxedTotalPrice.isNaN()) {
				this.props.setRcDetailTaxedTotalPrice(taxedTotalPrice.toFixed(2))
			}
		}

		if (
			prevProps.modalReceiveDetail.taxedTotalPrice !== this.props.modalReceiveDetail.taxedTotalPrice &&
			this.props.modalReceiveDetail.isTaxedTotalPriceModified
		) {
			if (!taxedTotalPrice) {
				this.props.setRcDetailTotalPrice("")
				this.props.setRcDetailDiscountedTotalPrice("")
				this.props.setRcDetailTaxedTotalPriceModified(false)
				return
			}

			discountedTotalPrice = Utils.BigNumber(taxedTotalPrice).dividedBy(1 + taxRate / 100)
			if (!discountedTotalPrice.isNaN()) {
				this.props.setRcDetailDiscountedTotalPrice(discountedTotalPrice.toFixed(2))
			}

			totalPrice = discountedTotalPrice.dividedBy(1 - supplierDiscount / 100)
			if (!totalPrice.isNaN()) {
				this.props.setRcDetailTotalPrice(totalPrice.dp(2).toNumber())
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_RC_RECEIVE_DETAIL_DISPLAY, false)

		this.props.setRcDetailCode("")
		this.props.setRcDetailName("")
		this.props.setRcDetailLot("")
		this.props.setRcDetailExpireDate(Utils.generateDate(1, 0, 0))
		this.props.setRcDetailAmount("")
		this.props.setRcDetailUnit("")
		this.props.setRcDetailBonus("0")
		this.props.setRcDetailPricePerUnit("")
		this.props.setRcDetailDiscount("0")
		this.props.setRcDetailDiscontPricePerUnit("")
		this.props.setRcDetailSupplierDiscount("0")
		this.props.setRcDetailSupplierDiscountPricePerUnit("")
		this.props.setRcDetailTax("")
		this.props.setRcDetailTotalPrice("")
		this.props.setRcDetailTaxedTotalPrice("")
		this.props.setRcDetailDiscountTaxPricePerUnit("")
		this.props.setRcDetailDiscountedTotalPrice("")
	}

	validatePrice() {
		let amount = this.props.modalReceiveDetail.amount
		let pricePerUnit = this.props.modalReceiveDetail.pricePerUnit
		let discount = this.props.modalReceiveDetail.discount
		let totalPrice = this.props.modalReceiveDetail.totalPrice

		let form = document.getElementById(EFormInput.RC_DETAIL_FORM)
		if (amount && totalPrice) {
			if (
				Utils.BigNumber(totalPrice)
					.dp(2)
					.eq(
						Utils.BigNumber(pricePerUnit)
							.times(1 - discount / 100)
							.times(amount)
							.dp(2)
					)
			) {
				form[EFormInput.RC_DETAIL_TOTAL_PRICE].setCustomValidity("")
				form[EFormInput.RC_DETAIL_DISCOUNTED_TOTAL_PRICE].setCustomValidity("")
				form[EFormInput.RC_DETAIL_TAXED_TOTAL_PRICE].setCustomValidity("")
			} else {
				form[EFormInput.RC_DETAIL_TOTAL_PRICE].setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongTotalPrice }))
				form[EFormInput.RC_DETAIL_DISCOUNTED_TOTAL_PRICE].setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongTotalPrice }))
				form[EFormInput.RC_DETAIL_TAXED_TOTAL_PRICE].setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongTotalPrice }))
			}
		}
	}

	onClose() {
		this.reset()
	}

	onLotChange(event) {
		this.props.setRcDetailLot(event.target.value.toUpperCase())
	}

	onExpireDateChange(date) {
		this.props.setRcDetailExpireDate(date)
	}

	onAmountChange(event) {
		this.props.setRcDetailAmount(event.target.value)
	}

	onBonusChange(event) {
		this.props.setRcDetailBonus(event.target.value)
	}

	onTotalPriceChange(event) {
		this.props.setRcDetailTotalPrice(event.target.value)
		this.props.setRcDetailTotalPriceModified(true)
	}

	onDiscountedTotalPrice(event) {
		this.props.setRcDetailDiscountedTotalPrice(event.target.value)
		this.props.setRcDetailDiscountedModified(true)
	}

	onTaxedTotalPriceChange(event) {
		this.props.setRcDetailTaxedTotalPrice(event.target.value)
		this.props.setRcDetailTaxedTotalPriceModified(true)
	}

	onConfirm(event) {
		event.preventDefault()
		event.stopPropagation()

		let receives = Object.assign([], this.props.pendingReceive.filtered, [
			...this.props.pendingReceive.filtered.filter((item) => item.prItemId !== this.props.selectedWaiting.inventory.id),
			{
				// inventory: this.props.selectedWaiting.inventory,
				prItemId: this.props.selectedWaiting.inventory.id,
				code: this.props.modalReceiveDetail.code,
				name: this.props.modalReceiveDetail.name,
				lot: this.props.modalReceiveDetail.lot,
				expireDate: this.props.modalReceiveDetail.expireDate,
				amount: this.props.modalReceiveDetail.amount,
				unit: this.props.modalReceiveDetail.unit,
				bonus: this.props.modalReceiveDetail.bonus,
				pricePerUnit: this.props.modalReceiveDetail.pricePerUnit,
				discount: this.props.modalReceiveDetail.discount,
				supplierDiscount: this.props.modalReceiveDetail.supplierDiscount,
				discountPricePerUnit: this.props.modalReceiveDetail.discountPricePerUnit,
				taxRate: this.props.modalReceiveDetail.tax,
				totalPrice: this.props.modalReceiveDetail.totalPrice,
				discountedTotalPrice: this.props.modalReceiveDetail.discountedTotalPrice,
				taxedTotalPrice: this.props.modalReceiveDetail.taxedTotalPrice,
				minQtyPerOrder: this.props.selectedWaiting.inventory.minQtyPerOrder,
			},
		])

		this.props.setRcPendingReceives(receives)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.lg}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form id={EFormInput.RC_DETAIL_FORM} onSubmit={this.onConfirm}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.receiveItemDetails)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid>
							<Row className={ResourceAssistance.CSS.flexSelfCenter}>
								<Col>
									<label className={ResourceAssistance.CSS.textCenter}>{`(${this.props.modalReceiveDetail.code}) ${this.props.modalReceiveDetail.name}`}</label>
								</Col>
							</Row>

							<Row>
								<Col />
								<Col md={3}>
									<label>
										{translate(ResourceAssistance.Message.lotNum)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={3}>
									<FormControl
										required
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.text}
										value={this.props.modalReceiveDetail.lot}
										onChange={this.onLotChange}
									></FormControl>
								</Col>
								<Col />
							</Row>

							<Row>
								<Col />
								<Col md={3}>
									<label>
										{translate(ResourceAssistance.Message.expire)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={3}>
									<DatePicker
										className={ResourceAssistance.CSS.flexSelfCenter}
										isShowTime={false}
										startDate={this.props.modalReceiveDetail.expireDate}
										onChange={this.onExpireDateChange}
									/>
								</Col>
								<Col />
							</Row>

							<Row>
								<Col />
								<Col md={3}>
									<label>
										{translate(ResourceAssistance.Message.amountOfPackage)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={3}>
									<InputGroup size={ResourceAssistance.FormControl.size.sm}>
										<FormControl
											required
											name={EFormInput.RC_DETAIL_AMOUNT}
											type={ResourceAssistance.FormControl.type.number}
											size={ResourceAssistance.FormControl.size.sm}
											value={this.props.modalReceiveDetail.amount}
											min="0"
											step="1"
											onChange={this.onAmountChange}
										/>
										{this.props.selectedWaiting.inventory && (
											<InputGroup.Text>
												{" x " + this.props.selectedWaiting.inventory.minQtyPerOrder + " " + this.props.selectedWaiting.inventory.unit}
											</InputGroup.Text>
										)}
									</InputGroup>
								</Col>
								<Col />
							</Row>
							<Row>
								<Col />
								<Col md={3}>
									<label>
										{translate(ResourceAssistance.Message.bonus)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={3}>
									<FormControl
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.number}
										value={this.props.modalReceiveDetail.bonus}
										min="0"
										step="1"
										onChange={this.onBonusChange}
									></FormControl>
								</Col>
								<Col />
							</Row>

							<Row>
								<Col />
								<Col md={3}>
									<label>
										{translate(ResourceAssistance.Message.pricePerUnit)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={3}>
									<FormControl disabled size={ResourceAssistance.FormControl.size.sm} value={this.props.modalReceiveDetail.pricePerUnit}></FormControl>
								</Col>
								<Col />
							</Row>

							<Row>
								<Col md={2}>
									<label>
										{translate(ResourceAssistance.Message.discount)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<InputGroup size={ResourceAssistance.FormControl.size.sm}>
										<FormControl disabled size={ResourceAssistance.FormControl.size.sm} value={this.props.modalReceiveDetail.discount} />
										<InputGroup.Text>{<AiOutlinePercentage />}</InputGroup.Text>
									</InputGroup>
								</Col>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.discountPricePerUnit)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<FormControl disabled size={ResourceAssistance.FormControl.size.sm} value={this.props.modalReceiveDetail.discountPricePerUnit}></FormControl>
								</Col>
								<Col>
									<label>
										{translate(ResourceAssistance.Message.totalPrice)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<FormControl
										required
										name={EFormInput.RC_DETAIL_TOTAL_PRICE}
										className={ResourceAssistance.CSS.flexSelfCenter}
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.number}
										disabled={this.props.modalReceiveDetail.isDiscountedTotalPriceModified || this.props.modalReceiveDetail.isTaxedTotalPriceModified}
										value={this.props.modalReceiveDetail.totalPrice}
										min="0"
										step="0.01"
										onChange={this.onTotalPriceChange}
									></FormControl>
								</Col>
							</Row>
							<Row>
								<Col>
									<label>
										{translate(ResourceAssistance.Message.supplierDiscount)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<InputGroup size={ResourceAssistance.FormControl.size.sm}>
										<FormControl disabled size={ResourceAssistance.FormControl.size.sm} value={this.props.modalReceiveDetail.supplierDiscount} />
										<InputGroup.Text>{<AiOutlinePercentage />}</InputGroup.Text>
									</InputGroup>
								</Col>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.discountPricePerUnit)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<FormControl
										disabled
										size={ResourceAssistance.FormControl.size.sm}
										value={this.props.modalReceiveDetail.supplierDiscountPricePerUnit}
									></FormControl>
								</Col>
								<Col>
									<label>
										{translate(ResourceAssistance.Message.totalPriceDiscounted)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<FormControl
										required
										name={EFormInput.RC_DETAIL_DISCOUNTED_TOTAL_PRICE}
										className={ResourceAssistance.CSS.flexSelfCenter}
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.number}
										disabled={this.props.modalReceiveDetail.isTotalPriceModified || this.props.modalReceiveDetail.isTaxedTotalPriceModified}
										value={this.props.modalReceiveDetail.discountedTotalPrice}
										min="0"
										step="0.01"
										onChange={this.onDiscountedTotalPrice}
									></FormControl>
								</Col>
							</Row>
							<Row>
								<Col>
									<label>
										{translate(ResourceAssistance.Message.taxRate)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<InputGroup size={ResourceAssistance.FormControl.size.sm}>
										<FormControl disabled size={ResourceAssistance.FormControl.size.sm} value={this.props.modalReceiveDetail.tax} />
										<InputGroup.Text>{<AiOutlinePercentage />}</InputGroup.Text>
									</InputGroup>
								</Col>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.discountTaxPricePerUnit)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<FormControl disabled size={ResourceAssistance.FormControl.size.sm} value={this.props.modalReceiveDetail.discountTaxPricePerUnit} />
								</Col>
								<Col>
									<label>
										{translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col md={2}>
									<FormControl
										required
										name={EFormInput.RC_DETAIL_TAXED_TOTAL_PRICE}
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.number}
										disabled={this.props.modalReceiveDetail.isTotalPriceModified || this.props.modalReceiveDetail.isDiscountedTotalPriceModified}
										value={this.props.modalReceiveDetail.taxedTotalPrice}
										min="0"
										step="0.01"
										onChange={this.onTaxedTotalPriceChange}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isRCReceiveDetailDisplay,
	pendingReceive: state.receive.pendingReceive,
	selectedWaiting: state.receive.selectedWaiting,
	modalReceiveDetail: state.receive.modalReceiveDetail,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setRcDetailAmount,
			setRcDetailBonus,
			setRcDetailCode,
			setRcDetailDiscontPricePerUnit,
			setRcDetailDiscount,
			setRcDetailDiscountTaxPricePerUnit,
			setRcDetailExpireDate,
			setRcDetailLot,
			setRcDetailName,
			setRcDetailPricePerUnit,
			setRcDetailSupplierDiscount,
			setRcDetailSupplierDiscountPricePerUnit,
			setRcDetailTax,
			setRcDetailTaxedTotalPrice,
			setRcDetailTaxedTotalPriceModified,
			setRcDetailTotalPrice,
			setRcDetailTotalPriceModified,
			setRcDetailUnit,
			setRcPendingReceives,
			setRcDetailDiscountedTotalPrice,
			setRcDetailDiscountedModified,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RCModalReceiveDetail))
