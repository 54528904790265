import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { IoHourglassOutline } from "react-icons/io5"
import { MdFileDownload } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import { ExcelDataFactory } from "~/utils/factory/ExcelDataFactory"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import ReportCashierUserFilterDetails from "./ReportCashierUserFilterDetails"

class ReportCashierUserFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportCashierUserFilter",
			downloadDisabled: true,
		}

		this.ref = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.filter.startDateTime, this.props.filter.startDateTime) ||
			!_.isEqual(prevProps.filter.endDateTime, this.props.filter.endDateTime) ||
			!_.isEqual(prevProps.filter.selectedRole, this.props.filter.selectedRole) ||
			!_.isEqual(prevProps.filter.selectedUser, this.props.filter.selectedUser) ||
			!_.isEqual(prevProps.filter.dischargedChecked, this.props.filter.dischargedChecked)
		) {
			this.setState({
				downloadDisabled: true,
			})
		}
	}

	componentWillUnmount() {
		this.setState({
			downloadDisabled: true,
		})
	}

	downloadExcel(payeeOrders) {
		let excel = ExcelDataFactory.generateCashierByUser(
			this.props.filter.selectedRole.role,
			this.props.filter.selectedUser.user,
			this.props.filter.startDateTime,
			this.props.filter.endDateTime,
			payeeOrders
		)
		this.ref.current.updateExcel(excel)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.cashier.getPayeeOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				firstName: this.props.filter.selectedUser.user.firstName,
				lastName: this.props.filter.selectedUser.user.lastName,
				dischargedOnly: this.props.filter.dischargedChecked,
			},
		}
		let callback = (res) => {
			let data = PrintableDataFactory.generatePayeeReportData(
				this.props.filter.startDateTime,
				this.props.filter.endDateTime,
				this.props.filter.dischargedChecked,
				this.props.filter.selectedRole.role,
				this.props.filter.selectedUser.user,
				res.data.payeeOrders
			)

			let myWindow = window.open(ResourceAssistance.Path.Report.cashier.byUser, "_blank")
			myWindow.data = data
			this.setState({
				downloadDisabled: _.isEmpty(res.data.payeeOrders),
			})
			if (!_.isEmpty(res.data.payeeOrders)) {
				this.downloadExcel(data.orders)
			}
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.byUser)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportCashierUserFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={!this.props.filter.selectedUser.user}
						>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
						<ExcelDocument
							ref={this.ref}
							element={
								<Button variant={ResourceAssistance.Button.variant.info} disabled={this.state.downloadDisabled}>
									<MdFileDownload size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Hospitel.download)}
								</Button>
							}
						/>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.hospital.cashier.isUserRoleDisplay,
	filter: state.report.cashier.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierUserFilter))
