import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setPostingGLs } from "~/redux/action"
import { SET_PGL_SELECTED_PGL } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PostingGLLeftRequest extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PGL.postingGLTable.filtered, this.props.PGL.postingGLTable.filtered)) {
			let index = this.props.PGL.selectedPGL.PGL ? this.props.PGL.postingGLTable.filtered.findIndex((PGL) => PGL.id === this.props.PGL.selectedPGL.PGL.id) : -1
			if (index !== -1) {
				this.props.setSelected(SET_PGL_SELECTED_PGL, index, this.props.PGL.postingGLTable.filtered[index])
			} else {
				this.props.setSelected(SET_PGL_SELECTED_PGL, -1, null)
			}
		}
		if (!_.isEqual(prevProps.PGL.filterPostingGLId, this.props.PGL.filterPostingGLId) || !_.isEqual(prevProps.PGL.filters, this.props.PGL.filters)) {
			this.props.setPostingGLs(this.props.PGL.postingGLTable.original)
		}
	}

	onSelectRow(plan, index) {
		this.props.setSelected(SET_PGL_SELECTED_PGL, index, this.props.PGL.postingGLTable.filtered[index])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.PGL.postingGLTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.PGL.selectedPGL.index === -1}
						highlightedRow={this.props.PGL.selectedPGL.index}
					/>
					{this.props.PGL.postingGLTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPostingGLs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PostingGLLeftRequest))
