import { ResourceAssistance } from "~/i18n"
import {
	SET_ADM_GL_CHART_OF_ACCOUNTS,
	SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING,
	SET_ADM_GL_PAYABLE_ACTIVITIES_LOADING,
	SET_ADM_GL_PAYABLE_ACTIVITIES_TABLE,
	SET_ADM_GL_PAYABLE_FILTER_CODE,
	SET_ADM_GL_PAYABLE_FILTER_NAME,
	SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT,
	SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT,
	SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION,
	SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP,
	SET_ADM_GL_PAYABLE_MODAL_AP_RC_SELECTABLE_IND,
	SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP,
	SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE,
	SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK,
	SET_ADM_GL_PAYABLE_SELECTED_AP,
	SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING,
	SET_ADM_GL_RECEIVABLE_ACTIVITIES_TABLE,
	SET_ADM_GL_RECEIVABLE_FILTER_CODE,
	SET_ADM_GL_RECEIVABLE_FILTER_NAME,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK,
	SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK,
	SET_ADM_GL_RECEIVABLE_SELECTED_AR,
} from "~/redux/type"

const init = {
	isChartOfAccountsLoading: false,
	chartOfAccounts: [],
	accountsReceivable: {
		filterCode: "",
		filterName: "",
		selectedAR: {
			index: -1,
			AR: null,
		},
		activityTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.transactionDescription,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
			rowColor: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAR: {
			description: "",
			isDescriptionIncludesDate: false,
			isIncludeBalance: false,
			isIncludeDiscount: false,
			isIncludeTransactionFee: false,
			isIncludeWithholdingTax: false,
			isIncludeBalanceAdjustment: false,
			isIncludeIPD: false,
			isIncludeOPD: false,
			isDebit: false,
			isAccountsReceiveSelectable: false,
			selectedAR: {
				index: -1,
				AR: null,
			},
		},
	},
	accountsPayable: {
		filterCode: "",
		filterName: "",
		selectedAP: {
			index: -1,
			AP: null,
		},
		activityTable: {
			original: [],
			filtered: [],
			header: [
				"",
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.transactionDescription,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
				"",
			],
			body: [],
			rowColor: [],
			popoverMsg: [],
			isLoading: false,
		},
		modalAP: {
			description: "",
			isDescriptionIncludesSupplier: false,
			isDescriptionIncludesDate: false,
			isIncludeBalance: false,
			isIncludeDiscount: false,
			isIncludeTax: false,
			isIncludeWithholdingTax: false,
			isIncludeTransactionFee: false,
			isIncludeBalanceAdjustment: false,
			isExcludeWorkflowPaymentSlip: false,
			isDebit: false,
			isAccountsPayableRCSelectable: false,
			selectedAP: {
				index: -1,
				AP: null,
			},
		},
	},
}

const configGeneralLedgerReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_GL_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				isChartOfAccountsLoading: action.payload.isLoading,
			})
		case SET_ADM_GL_RECEIVABLE_FILTER_CODE:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_GL_RECEIVABLE_FILTER_NAME:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					activityTable: {
						...state.accountsReceivable.activityTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_ACTIVITIES_TABLE:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					activityTable: {
						...state.accountsReceivable.activityTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						popoverMsg: action.payload.popoverMsg,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeBalance: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_DISCOUNT_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeDiscount: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_TRANS_FEE_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeTransactionFee: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_WHT_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeWithholdingTax: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_BALANCE_ADJUSTMENT:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeBalanceAdjustment: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_IPD_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeIPD: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_OPD_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isIncludeOPD: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_DEBIT:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isDebit: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCRIPTION:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_DESCR_DATE_CHECK:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isDescriptionIncludesDate: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						isAccountsReceiveSelectable: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_MODAL_AR_SELECTED_AR:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					modalAR: {
						...state.accountsReceivable.modalAR,
						selectedAR: {
							index: action.payload.index,
							AR: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_GL_RECEIVABLE_SELECTED_AR:
			return Object.assign({}, state, {
				accountsReceivable: {
					...state.accountsReceivable,
					selectedAR: {
						index: action.payload.index,
						AR: action.payload.selected,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_FILTER_CODE:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					filterCode: action.payload.value,
				},
			})
		case SET_ADM_GL_PAYABLE_FILTER_NAME:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					filterName: action.payload.value,
				},
			})
		case SET_ADM_GL_PAYABLE_ACTIVITIES_LOADING:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					activityTable: {
						...state.accountsPayable.activityTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_ACTIVITIES_TABLE:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					activityTable: {
						...state.accountsPayable.activityTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						popoverMsg: action.payload.popoverMsg,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isIncludeBalance: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isIncludeDiscount: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isIncludeTax: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isIncludeWithholdingTax: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isIncludeTransactionFee: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isIncludeBalanceAdjustment: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isExcludeWorkflowPaymentSlip: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isDebit: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isDescriptionIncludesSupplier: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isDescriptionIncludesDate: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_RC_SELECTABLE_IND:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						isAccountsPayableRCSelectable: action.payload.value,
					},
				},
			})
		case SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					modalAP: {
						...state.accountsPayable.modalAP,
						selectedAP: {
							index: action.payload.index,
							AP: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_GL_PAYABLE_SELECTED_AP:
			return Object.assign({}, state, {
				accountsPayable: {
					...state.accountsPayable,
					selectedAP: {
						index: action.payload.index,
						AP: action.payload.selected,
					},
				},
			})
		default:
			return state
	}
}

export default configGeneralLedgerReducer
