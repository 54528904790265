export const SET_PS_AP_ACTIVITIES = "SET_PS_AP_ACTIVITIES"
export const SET_PS_BANKS = "SET_PS_BANKS"
export const SET_PS_BANKS_LOADING = "SET_PS_BANKS_LOADING"
export const SET_PS_CHART_OF_ACCOUNTS = "SET_PS_CHART_OF_ACCOUNTS"
export const SET_PS_CHART_OF_ACCOUNTS_LOADING = "SET_PS_CHART_OF_ACCOUNTS_LOADING"
export const SET_PS_COMPLETE_PAYMENT_PLANS = "SET_PS_COMPLETE_PAYMENT_PLANS"
export const SET_PS_END_DATE_TIME = "SET_PS_END_DATE_TIME"
export const SET_PS_FILTER = "SET_PS_FILTER"
export const SET_PS_FILTER_PAYMENT_SLIP_ID = "SET_PS_FILTER_PAYMENT_SLIP_ID"
export const SET_PS_GENERAL_LEDGER_DISPLAY = "SET_PS_GENERAL_LEDGER_DISPLAY"
export const SET_PS_GL_GROUPS = "SET_PS_GL_GROUPS"
export const SET_PS_INVOICES = "SET_PS_INVOICES"
export const SET_PS_LOCATION = "SET_PS_LOCATION"
export const SET_PS_LOCATION_DISPLAY = "SET_PS_LOCATION_DISPLAY"
export const SET_PS_MODAL_GL_DESCRIPTION = "SET_PS_MODAL_GL_DESCRIPTION"
export const SET_PS_MODAL_GL_SELECTED_GL = "SET_PS_MODAL_GL_SELECTED_GL"
export const SET_PS_MODAL_LOCATION_ORGS = "SET_PS_MODAL_LOCATION_ORGS"
export const SET_PS_MODAL_LOCATION_ORGS_LOADING = "SET_PS_MODAL_LOCATION_ORGS_LOADING"
export const SET_PS_MODAL_LOCATION_SELECTED_ORG = "SET_PS_MODAL_LOCATION_SELECTED_ORG"
export const SET_PS_MODAL_NEW_FILTER_INVOICE_ID = "SET_PS_MODAL_NEW_FILTER_INVOICE_ID"
export const SET_PS_MODAL_NEW_FILTER_INVOICE_NUM = "SET_PS_MODAL_NEW_FILTER_INVOICE_NUM"
export const SET_PS_MODAL_NEW_INVOICES = "SET_PS_MODAL_NEW_INVOICES"
export const SET_PS_MODAL_NEW_INVOICES_LOADING = "SET_PS_MODAL_NEW_INVOICES_LOADING"
export const SET_PS_MODAL_NEW_PENDING_INVOICES = "SET_PS_MODAL_NEW_PENDING_INVOICES"
export const SET_PS_MODAL_NEW_SEARCH_SUPPLIER = "SET_PS_MODAL_NEW_SEARCH_SUPPLIER"
export const SET_PS_MODAL_NEW_SEARCH_TYPE = "SET_PS_MODAL_NEW_SEARCH_TYPE"
export const SET_PS_MODAL_NEW_SELECTED_INVOICE = "SET_PS_MODAL_NEW_SELECTED_INVOICE"
export const SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE = "SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE"
export const SET_PS_MODAL_PAYMENT_PLAN_BRANCHES = "SET_PS_MODAL_PAYMENT_PLAN_BRANCHES"
export const SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME = "SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME"
export const SET_PS_MODAL_PAYMENT_PLAN_INVOICES = "SET_PS_MODAL_PAYMENT_PLAN_INVOICES"
export const SET_PS_MODAL_PAYMENT_PLAN_PENDING_INVOICES = "SET_PS_MODAL_PAYMENT_PLAN_PENDING_INVOICES"
export const SET_PS_MODAL_PAYMENT_PLAN_REMARK = "SET_PS_MODAL_PAYMENT_PLAN_REMARK"
export const SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK = "SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK"
export const SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH = "SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH"
export const SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE = "SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE"
export const SET_PS_MODAL_PAYMENT_PLAN_SELECTED_PENDING_INVOICE = "SET_PS_MODAL_PAYMENT_PLAN_SELECTED_PENDING_INVOICE"
export const SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE = "SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE"
export const SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID = "SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID"
export const SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID = "SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID"
export const SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER = "SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER"
export const SET_PS_NEW_DISPLAY = "SET_PS_NEW_DISPLAY"
export const SET_PS_PAYMENT_PLANS = "SET_PS_PAYMENT_PLANS"
export const SET_PS_PAYMENT_PLAN_DISPLAY = "SET_PS_PAYMENT_PLAN_DISPLAY"
export const SET_PS_PAYMENT_PLAN_TAB = "SET_PS_PAYMENT_PLAN_TAB"
export const SET_PS_PAYMENT_SLIPS = "SET_PS_PAYMENT_SLIPS"
export const SET_PS_PAYMENT_TYPES = "SET_PS_PAYMENT_TYPES"
export const SET_PS_PAYMENT_TYPES_LOADING = "SET_PS_PAYMENT_TYPES_LOADING"
export const SET_PS_REQUESTS = "SET_PS_REQUESTS"
export const SET_PS_SEARCH_DISPLAY = "SET_PS_SEARCH_DISPLAY"
export const SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN = "SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN"
export const SET_PS_SELECTED_GROUP = "SET_PS_SELECTED_GROUP"
export const SET_PS_SELECTED_INVOICE = "SET_PS_SELECTED_INVOICE"
export const SET_PS_SELECTED_PAYMENT_PLAN = "SET_PS_SELECTED_PAYMENT_PLAN"
export const SET_PS_SELECTED_PAYMENT_SLIP = "SET_PS_SELECTED_PAYMENT_SLIP"
export const SET_PS_SELECTED_SUPPLIER = "SET_PS_SELECTED_SUPPLIER"
export const SET_PS_START_DATE_TIME = "SET_PS_START_DATE_TIME"
export const SET_PS_SUPPLIERS = "SET_PS_SUPPLIERS"
export const SET_PS_SUPPLIER_LOADING = "SET_PS_SUPPLIER_LOADING"
export const SET_PS_WITHHOLDING_TAXES = "SET_PS_WITHHOLDING_TAXES"
export const SET_PS_GL_DATE_TIME = "SET_PS_GL_DATE_TIME"
