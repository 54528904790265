import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_ADM_TYPE_MODAL_NEW_ADDRESS, SET_ADM_TYPE_MODAL_NEW_TEL } from "~/redux/type"
import GInput from "~/view/component/input/GInput"
import { setValue } from "~/redux/action"

class TypeModalNewAddressField extends React.Component {
	constructor(props) {
		super(props)

		this.onAddress = this.onAddress.bind(this)
		this.onTel = this.onTel.bind(this)
	}
	componentDidMount() {
		if (this.props.type.selectedType.type) {
			this.props.setValue(SET_ADM_TYPE_MODAL_NEW_ADDRESS, this.props.type.selectedType.type.address)
			this.props.setValue(SET_ADM_TYPE_MODAL_NEW_TEL, this.props.type.selectedType.type.tel)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_TYPE_MODAL_NEW_ADDRESS, "")
		this.props.setValue(SET_ADM_TYPE_MODAL_NEW_TEL, "")
	}

	onAddress(e) {
		this.props.setValue(SET_ADM_TYPE_MODAL_NEW_ADDRESS, e.target.value)
	}

	onTel(e) {
		this.props.setValue(SET_ADM_TYPE_MODAL_NEW_TEL, e.target.value)
	}

	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayAddress && (
					<Row>
						<Col>
							<GInput
								required
								autoSize
								minRows={3}
								placeholder={translate(ResourceAssistance.Message.address)}
								value={this.props.type.modalNew.address}
								onChange={this.onAddress}
							/>
						</Col>
					</Row>
				)}
				{this.props.type.modal.displayTel && (
					<Row>
						<Col>
							<GInput
								required
								style={{ textAlign: "center" }}
								type={ResourceAssistance.FormControl.type.tel}
								placeholder={translate(ResourceAssistance.Message.tel)}
								value={this.props.type.modalNew.tel}
								onChange={this.onTel}
							/>
						</Col>
					</Row>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewAddressField)
