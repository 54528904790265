import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportCashierHealthCarePlanReceiptDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				services: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayTitle={false}
						displayBranch={true}
						displayFax={false}
						styles={{
							org: {
								fontSize: 24,
							},
						}}
					/>
					{/* Patient Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>PID: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.pid}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.patientName}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Address/{this.state.data.addressIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.patientAddress}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.separator]} />
					{/* Receipt Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							{this.state.data.isReceipt ? (
								<Text>Receipt ID/{this.state.data.receiptIdIntl}: </Text>
							) : (
								<Text>Invoice ID/{this.state.data.invoiceIdIntl}: </Text>
							)}
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.receiptId}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Admission ID/{this.state.data.admissionIdIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.admissionId}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Admission Date/{this.state.data.admissionDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.admissionDate}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
							<Text>Discharge Date/{this.state.data.dischargeDateIntl}: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<Text>{this.state.data.dischargeDate}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.separator]} />
					{/* Service Header */}
					<View wrap={false}>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceDescriptionHeader]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustment]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.adjustmentIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.balanceIntl}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceDescriptionHeader]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>List</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustment]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Discount</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Balance</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
					</View>
					{/* Services */}
					{Object.entries(this.state.data.services).map(([key, value]) => {
						return [
							<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontStyle: "bold" }]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text>{key}</Text>
								</View>
							</View>,
						].concat(
							value.map((service) => {
								return (
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.col, styles.blankSpace]}></View>
										<View style={[ResourceAssistance.Report.styles.col, styles.serviceDescription]}>
											<Text>{service.description}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount, { alignItems: "flex-end" }]}>
											<Text>{service.amount}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.adjustment, { alignItems: "flex-end" }]}>
											<Text>{service.adjustment}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance, { alignItems: "flex-end" }]}>
											<Text>{service.balance}</Text>
										</View>
									</View>
								)
							})
						)
					})}

					{/* Summary */}
					<View style={[{ flex: 1 }]} wrap={false}>
						<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>
							<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Sub Total/{this.state.data.subTotalIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.subTotal}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Discount/{this.state.data.adjustmentIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.adjustments}</Text>
									</View>
								</View>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Grand Total/{this.state.data.grandTotalIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.grandTotal}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separatorDash} />

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>
							<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.payment}</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.paymentAmount}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>
							<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>Change/{this.state.data.changeIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.change}</Text>
									</View>
								</View>
							</View>
						</View>
						{this.state.data.hasRefund && (
							<Fragment>
								<View style={ResourceAssistance.Report.styles.separatorDash} />
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>
									<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Refund/{this.state.data.refundIntl}:</Text>
											</View>
											<View style={ResourceAssistance.Report.styles.col}>
												<Text>{this.state.data.refund}</Text>
											</View>
										</View>
									</View>
								</View>
							</Fragment>
						)}
						{!this.state.data.isReceipt && (
							<Fragment>
								<View style={ResourceAssistance.Report.styles.separatorDash} />
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.collectFromIntl}:</Text>
											</View>
											<View style={ResourceAssistance.Report.styles.colAuto}>
												<Text>{this.state.data.insuranceCompany}</Text>
											</View>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Unpaid/{this.state.data.unpaidIntl}:</Text>
											</View>
											<View style={ResourceAssistance.Report.styles.col}>
												<Text>{this.state.data.unpaid}</Text>
											</View>
										</View>
									</View>
								</View>
							</Fragment>
						)}
						<View style={ResourceAssistance.Report.styles.separator} />
					</View>

					{/* Signature */}
					<Signature numOfSignature={2} signatureTitles={this.state.data.signatureTitles} signatureNames={this.state.data.signatureNames} />
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 115,
	},
	infoHeader: {
		fontStyle: "bold",
		textAlign: "right",
		marginRight: 10,
	},
	itemRow: {
		fontSize: 16,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	blankSpace: {
		minWidth: "2%",
		maxWidth: "2%",
	},
	serviceDescriptionHeader: {
		minWidth: "62%",
		maxWidth: "62%",
	},
	serviceDescription: {
		minWidth: "60%",
		maxWidth: "60%",
	},
	amount: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	adjustment: {
		minWidth: "13%",
		maxWidth: "13%",
	},
	balance: {
		minWidth: "13%",
		maxWidth: "13%",
	},
})

export default ReportCashierHealthCarePlanReceiptDoc
