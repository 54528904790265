import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import HospitelModalDefaultOrderOrder from "./HospitelModalDefaultOrderOrder"

class HospitelModalDefaultOrderDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitelModalDefaultOrderOrder />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDefaultOrderDetails)
