import { message } from "antd"
import _ from "lodash"
import React, { Component } from "react"
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row } from "react-bootstrap"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setEclaimUcTable, setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_ADM_WP_ECLAIMUC_MODAL_CODE,
	SET_ADM_WP_ECLAIMUC_MODAL_NAME,
	SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION,
	SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE,
	SET_ADM_WP_ECLAIMUC_SELECTEDITEM,
	SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE,
	SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT,
	SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import ConfigEclaimUcNewFile from "./ConfigEclaimUcNewFile"

const Property = {
	CODE: "CODE",
	NAME: "NAME",
	DESCRIPTION: "DESCRIPTION",
	DEFAULT: "DEFAULT",
	ACTIVE: "ACTIVE",
}

export class ConfigEclaimUcNewItem extends Component {
	constructor(props) {
		super(props)

		this.state = {
			componentName: "ConfigEclaimUcNewItem",
		}

		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onSave = this.onSave.bind(this)
		this.onClose = this.onClose.bind(this)
		this.isSaveDisabled = this.isSaveDisabled.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.configEclaimUc.isModalDisplay !== this.props.configEclaimUc.isModalDisplay) {
			if (this.props.configEclaimUc.isModalDisplay) {
				if (this.props.configEclaimUc.selectedItem.index !== -1) {
					const data = this.props.configEclaimUc.selectedItem.item

					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_CODE, data.code)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_NAME, data.name)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION, data.description)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT, data.variablesFormat)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT, data.defaultSelected)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE, data.active)
				}
			}
		}
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.CODE:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_CODE, event.target.value)
				break
			case Property.NAME:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_NAME, event.target.value)
				break
			case Property.DESCRIPTION:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION, event.target.value)
				break
			case Property.DEFAULT:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT, event.target.checked)
				break
			case Property.ACTIVE:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE, event.target.checked)
				break
			default:
				break
		}
	}

	isSaveDisabled() {
		if (this.props.configEclaimUc.selectedItem.index !== -1) {
			const target = Object.assign({}, this.props.configEclaimUc.selectedItem.item, {
				...this.props.configEclaimUc.selectedItem.item,
				active: this.props.configEclaimUc.newModal.active,
			})
			if (this.props.configEclaimUc.newModal.displayCode) {
				target.code = this.props.configEclaimUc.newModal.code.trim()
			}
			if (this.props.configEclaimUc.newModal.displayName) {
				target.name = this.props.configEclaimUc.newModal.name.trim()
			}
			if (this.props.configEclaimUc.newModal.displayFiles) {
				target.variablesFormat = this.props.configEclaimUc.newModal.variableFormat
			}
			if (this.props.configEclaimUc.newModal.displayDescription) {
				target.description = this.props.configEclaimUc.newModal.description.trim()
			}
			return _.isEqual(this.props.configEclaimUc.selectedItem.item, target)
		}

		const code = this.props.configEclaimUc.newModal.displayCode ? _.isEmpty(this.props.configEclaimUc.newModal.code.trim()) : false
		const name = this.props.configEclaimUc.newModal.displayName ? _.isEmpty(this.props.configEclaimUc.newModal.name.trim()) : false
		const files = this.props.configEclaimUc.newModal.displayFiles
			? _.isEmpty(this.props.configEclaimUc.newModal.code.trim()) ||
			  _.isEmpty(this.props.configEclaimUc.newModal.description.trim()) ||
			  _.isEmpty(this.props.configEclaimUc.newModal.variablesFormat)
			: false
		const description = this.props.configEclaimUc.newModal.displayDescription ? _.isEmpty(this.props.configEclaimUc.newModal.description.trim()) : false

		return code || name || description || files
	}

	onSave(event) {
		event.preventDefault()
		event.stopPropagation()

		let raw = {
			id: this.props.configEclaimUc.selectedItem.item.id || "0",
			code: this.props.configEclaimUc.newModal.code.trim(),
			name: this.props.configEclaimUc.newModal.name.trim(),
			description: this.props.configEclaimUc.newModal.description.trim(),
			variablesFormat: this.props.configEclaimUc.newModal.variablesFormat,
			default: this.props.configEclaimUc.newModal.default,
			active: this.props.configEclaimUc.newModal.active,
			userId: this.props.login.user.id,
		}
		let params = {
			method: "POST",
			url: this.props.configEclaimUc.newModal.saveUrl,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: raw,
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.wfep)) {
				this.props.setEclaimUcTable(
					SET_ADM_WP_ECLAIMUC_ITEMTABLE,
					this.props.configEclaimUc.itemTable.dataKeys,
					this.props.configEclaimUc.itemTable.header,
					res.data.wfep
				)
				this.onClose()
				message.success(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveSuccess }), 2)
			} else {
				message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveFail }), 2)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
			message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveFail }), 2)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onClose() {
		this.props.setSelected(SET_ADM_WP_ECLAIMUC_SELECTEDITEM, -1, {})
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_CODE, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_NAME, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT, false)
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE, true)
		this.props.setValue(SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.smd}
				show={this.props.configEclaimUc.isModalDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onSave}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(this.props.configEclaimUc.newModal.title)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							{this.props.configEclaimUc.newModal.displayFiles && <ConfigEclaimUcNewFile />}
							{this.props.configEclaimUc.newModal.displayCode && (
								<Row>
									<Col>
										<GInput
											required
											style={{ textAlign: "center" }}
											name={Property.CODE}
											placeholder={translate(ResourceAssistance.Message.code)}
											value={this.props.configEclaimUc.newModal.code}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.configEclaimUc.newModal.displayName && (
								<Row>
									<Col>
										<GInput
											required
											style={{ textAlign: "center" }}
											name={Property.NAME}
											placeholder={translate(ResourceAssistance.Message.name)}
											value={this.props.configEclaimUc.newModal.name}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.configEclaimUc.newModal.displayDescription && (
								<Row>
									<Col>
										<GInput
											required
											style={{ textAlign: "center" }}
											autoSize
											showCount
											minRows={5}
											name={Property.DESCRIPTION}
											placeholder={translate(ResourceAssistance.Message.description)}
											value={this.props.configEclaimUc.newModal.description}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.configEclaimUc.newModal.displayDefault && (
								<Row>
									<Col />
									<Col md={"auto"}>
										<label>{translate(ResourceAssistance.Message.default)}</label>
									</Col>
									<Col>
										<ToggleCheckBox name={Property.DEFAULT} onClick={this.onPropertyChange} checked={this.props.configEclaimUc.newModal.default} />
									</Col>
									<Col />
								</Row>
							)}
							<Row>
								<Col />
								<Col md={"auto"}>
									<label>{translate(ResourceAssistance.Message.active)}</label>
								</Col>
								<Col>
									<ToggleCheckBox name={Property.ACTIVE} onClick={this.onPropertyChange} checked={this.props.configEclaimUc.newModal.active} />
								</Col>
								<Col />
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isSaveDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	configEclaimUc: state.admin.configEclaim.eclaimUC,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
			setSelected,
			setEclaimUcTable,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigEclaimUcNewItem))
