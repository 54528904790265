import React from "react"
import { Col, Container, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND,
	SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME,
	SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class ModalSaveAllDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCheckEndDate = this.onCheckEndDate.bind(this)
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.saveAll.startDateTime !== this.props.saveAll.startDateTime) {
			this.props.setValue(
				SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME,
				Utils.generateDateFromLong(this.props.saveAll.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND, true)
		this.props.setValue(SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME, Utils.generateDate().getTime())
		this.props.setValue(SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
	}

	onCheckEndDate(event) {
		this.props.setValue(SET_HOSPITAL_MODAL_SAVE_ALL_DISABLE_END_DATE_IND, !event.target.checked)
	}

	onStartDate(date) {
		this.props.setValue(SET_HOSPITAL_MODAL_SAVE_ALL_START_DATE_TIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITAL_MODAL_SAVE_ALL_END_DATE_TIME, date.getTime())
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0 label-info-row"} style={{ justifyContent: "center" }}>
					<Col md={"auto"}>
						<DatePicker
							isShowTime={false}
							startDate={Utils.generateDateFromLong(this.props.saveAll.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md={"auto"}>
						<InputGroup>
							<InputGroup.Checkbox onChange={this.onCheckEndDate} />
							<DatePicker
								isShowTime={false}
								minDate={Utils.generateDateFromLong(this.props.saveAll.startDateTime)}
								showDisabledMonthNavigation
								disabled={this.props.saveAll.isDisableEndDate}
								startDate={Utils.generateDateFromLong(this.props.saveAll.endDateTime)}
								onChange={this.onEndDate}
							/>
						</InputGroup>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	saveAll: state.modal.hospital.saveAll,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalSaveAllDetails)
