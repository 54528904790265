import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setTfRequests, setTfSelectedRequest } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFLeftFourthRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.transfer.requestTable.filtered) !==
			JSON.stringify(this.props.transfer.requestTable.filtered)
		) {
			let index = this.props.transfer.selectedRequest.request
				? this.props.transfer.requestTable.filtered.findIndex(
						(each) => each.id === this.props.transfer.selectedRequest.request.id
				  )
				: -1
			if (index !== -1) {
				this.props.setTfSelectedRequest(index, this.props.transfer.requestTable.filtered[index])
			} else {
				this.props.setTfSelectedRequest(-1, null)
			}
		}
	}

	onSelectRow(row, index) {
		let selectedRequest = this.props.transfer.requestTable.filtered[index]
		this.props.setTfSelectedRequest(index, selectedRequest)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						enableHighlight
						striped
						hover
						data={this.props.transfer.requestTable}
						isClearHighlight={this.props.transfer.selectedRequest.index === -1}
						onClick={this.onSelectRow}
					/>
					{this.props.transfer.requestTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfRequests, setTfSelectedRequest }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFLeftFourthRow)
