import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY } from "~/redux/type"
import PharmacyModalVerifyDoctorOrder from "./PharmacyModalVerifyDoctorOrder"
import PharmacyModalVerifyItem from "./PharmacyModalVerifyItem"
import PharmacyModalVerifyPhamacistOrder from "./PharmacyModalVerifyPhamacistOrder"

class PharmacyModalVerifyDetails extends React.Component {
	componentDidMount() {
		this.loadInventory()
	}

	componentWillUnmount() {
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY, null)
	}

	loadInventory() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.pharmacy.getInventory,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.pharmacy.location.id,
				code: this.props.pharmacy.selectedDoctorOrder.doctorOrder.code,
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.pharmacyInventories)) {
				this.props.setValue(SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY, res.data.pharmacyInventories[0])
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<PharmacyModalVerifyDoctorOrder />
				<PharmacyModalVerifyPhamacistOrder />
				<PharmacyModalVerifyItem ref={this.props.lotRef} {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalVerifyDetails)
