import { Divider, TreeSelect } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { CgRowLast } from "react-icons/cg"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdOutlineSubdirectoryArrowRight } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setConfigChartOfAccountsEquityAccounts,
	setLoadingAction,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_COA_EQUITIES,
	SET_ADM_COA_EQUITY_ACCOUNTS_LOADING,
	SET_ADM_COA_EQUITY_ADD_DISPLAY,
	SET_ADM_COA_EQUITY_FILTER_CODE,
	SET_ADM_COA_EQUITY_FILTER_NAME,
	SET_ADM_COA_EQUITY_ORIGINAL,
	SET_ADM_COA_EQUITY_SELECTED_ACCOUNT,
	SET_ADM_COA_EQUITY_SELECTED_EQUITY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigEquity extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigEquity",
		}

		this.onEquity = this.onEquity.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadAccounts(
			ResourceAssistance.Url.admin.chartOfAccounts.getEquities,
			(res) => {
				this.props.setValue(SET_ADM_COA_EQUITY_ORIGINAL, res.data.accounts)
				this.props.setValue(SET_ADM_COA_EQUITIES, Utils.getChartOfAccounts(res.data.accounts))
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_EQUITY_ACCOUNTS_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_EQUITY_ACCOUNTS_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_COA_EQUITY_ACCOUNTS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_COA_EQUITY_ORIGINAL, [])
		this.props.setValue(SET_ADM_COA_EQUITIES, [])
		this.props.setValue(SET_ADM_COA_EQUITY_FILTER_CODE, "")
		this.props.setValue(SET_ADM_COA_EQUITY_FILTER_NAME, "")
		this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_EQUITY, -1, null)
		this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_ACCOUNT, -1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.equity.originalEquities, this.props.equity.originalEquities)) {
			this.props.setValue(SET_ADM_COA_EQUITIES, Utils.getChartOfAccounts(this.props.equity.originalEquities))
		}
		if (!_.isEqual(prevProps.equity.equities, this.props.equity.equities)) {
			if (this.props.equity.selectedEquity.equity) {
				let selectedEquity = Utils.findChartOfAccounts(
					this.props.equity.equities,
					this.props.equity.selectedEquity.equity
				)
				this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_EQUITY, selectedEquity[0], selectedEquity[1])
			} else {
				this.props.setConfigChartOfAccountsEquityAccounts(this.props.equity.equities)
			}
		}
		if (
			!_.isEqual(prevProps.equity.selectedEquity, this.props.equity.selectedEquity) ||
			prevProps.equity.filterCode !== this.props.equity.filterCode ||
			prevProps.equity.filterName !== this.props.equity.filterName
		) {
			if (this.props.equity.selectedEquity.equity) {
				this.props.setConfigChartOfAccountsEquityAccounts(this.props.equity.selectedEquity.equity.accounts)
			} else {
				this.props.setConfigChartOfAccountsEquityAccounts(this.props.equity.equities)
			}
		}
		if (!_.isEqual(prevProps.equity.accountTable.filtered, this.props.equity.accountTable.filtered)) {
			this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_ACCOUNT, -1, null)
		}
	}

	onEquity(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_EQUITY, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_COA_EQUITY_SELECTED_EQUITY,
				value,
				(function getSelectedEquity(equities, idx) {
					if (idx === keys.length - 1) {
						return equities[keys[idx]]
					}
					return getSelectedEquity(equities[keys[idx]].accounts, idx + 1)
				})(this.props.equity.equities, 0)
			)
		} else {
			this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_EQUITY, value, this.props.equity.equities[value])
		}
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADM_COA_EQUITY_FILTER_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_COA_EQUITY_FILTER_NAME, event.target.value)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_ACCOUNT, index, this.props.equity.accountTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADM_COA_EQUITY_SELECTED_ACCOUNT, -1, null)
		this.props.onModalDisplayAction(SET_ADM_COA_EQUITY_ADD_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_COA_EQUITY_ADD_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							allowClear
							treeLine={{ showLeafIcon: false }}
							placeholder={translate(ResourceAssistance.Message.equity)}
							loading={this.props.equity.accountTable.isLoading}
							value={this.props.equity.selectedEquity.equity ? this.props.equity.selectedEquity.index : undefined}
							onChange={this.onEquity}
							treeData={Utils.getTreeNodesFromChartOfAccounts(this.props.equity.equities, false)}
						/>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.equity.selectedAccount.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							hover
							striped
							enableHighlight
							displayBtn
							buttonColors={[
								ResourceAssistance.Button.variant.outlineInfo,
								ResourceAssistance.Button.variant.outlineInfo,
							]}
							btnMessages={["", ""]}
							btnTypes={[EBtnType.POPOVER, EBtnType.POPOVER]}
							btnIcons={[
								<MdOutlineSubdirectoryArrowRight size={ResourceAssistance.ReactIcon.size} />,
								<CgRowLast size={ResourceAssistance.ReactIcon.size} />,
							]}
							data={this.props.equity.accountTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.equity.selectedAccount.index === -1}
							highlightedRow={this.props.equity.selectedAccount.index}
						/>
						{this.props.equity.accountTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	equity: state.admin.configCOA.equity,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setConfigChartOfAccountsEquityAccounts,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigEquity)
