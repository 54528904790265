import { ResourceAssistance } from "~/i18n"

const filterTableData = (data, keyList, searchList, displayInactive) => {
	let filtered = data
	if (!displayInactive) {
		filtered = filtered.filter((item) => item.active)
	}
	keyList.forEach((key, i) => {
		filtered = filtered.filter((item) => item[key].toString().toLowerCase().includes(searchList[i].toLowerCase()))
	})
	return filtered
}

const getKeyList = (displaySearchCode, displaySearchName, displaySearchDescription, displaySearchVariable) => {
	let keyList = []

	if (displaySearchCode) {
		keyList.push("code")
	}
	if (displaySearchName) {
		keyList.push("name")
	}
	if (displaySearchVariable) {
		keyList.push("variablesFormat")
	}
	if (displaySearchDescription) {
		keyList.push("description")
	}

	return keyList
}

const getSearchList = (keyList, searchCodeText, searchNameText, searchDescription, searchVariableText) => {
	let searchList = []

	keyList.forEach((key) => {
		switch (key) {
			case "code":
				searchList.push(searchCodeText)
				break
			case "name":
				searchList.push(searchNameText)
				break
			case "variablesFormat":
				searchList.push(searchVariableText)
				break
			case "description":
				searchList.push(searchDescription)
				break
			default:
				break
		}
	})

	return searchList
}

const getColorList = (filtered, displayInactive) => {
	let colors = []
	if (displayInactive) {
		colors = filtered.map((each) => {
			return [!each.active, ResourceAssistance.CSS.Color.red]
		})
	}
	return colors
}

export const setEclaimUcTable = (type, dataKeys, header, data) => {
	return (dispatch, getState) => {
		const keyList = getKeyList(
			getState().admin.configEclaim.eclaimUC.displaySearchCode,
			getState().admin.configEclaim.eclaimUC.displaySearchName,
			getState().admin.configEclaim.eclaimUC.displaySearchDescription,
			getState().admin.configEclaim.eclaimUC.displaySearchVariable
		)
		const searchList = getSearchList(
			keyList,
			getState().admin.configEclaim.eclaimUC.searchCodeText,
			getState().admin.configEclaim.eclaimUC.searchNameText,
			getState().admin.configEclaim.eclaimUC.searchDescriptionText,
			getState().admin.configEclaim.eclaimUC.searchVariableText
		)
		const filtered = filterTableData(data, keyList, searchList, getState().admin.configEclaim.eclaimUC.displayInactive)

		const body = filtered.map((each) => {
			let item = []
			dataKeys.forEach((key) => item.push(each[key]))
			return item
		})

		const colors = getColorList(filtered, getState().admin.configEclaim.eclaimUC.displayInactive)
		const colStyle = body.map(() => {
			return header.map(() => [true, { textAlign: "left", paddingLeft: "1rem" }])
		})

		dispatch({
			type: type,
			payload: {
				dataKeys: dataKeys,
				header: header,
				original: data,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}
