import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { IoMdDoneAll } from "react-icons/io"
import { MdRemoveDone } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_EVALUATION, SET_OPD_MODAL_NURSE_EVALUATION_SELECTED_CATEGORY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalNurseEvaluation extends React.Component {
	constructor(props) {
		super(props)

		this.onEvaluation = this.onEvaluation.bind(this)
		this.onCategoryEvaluation = this.onCategoryEvaluation.bind(this)
		this.onSelectEvaluation = this.onSelectEvaluation.bind(this)
		this.onDeselectEvaluation = this.onDeselectEvaluation.bind(this)
		this.onSelectAllEvaluation = this.onSelectAllEvaluation.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, this.props.opd.selectedNurseNote.nurseNote.evaluation)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, "")
		this.props.setSelected(SET_OPD_MODAL_NURSE_EVALUATION_SELECTED_CATEGORY, -1, null)
	}

	getValues() {
		let values = []
		if (this.props.opd.modalNurse.selectedEvaluationCategory.category) {
			this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations.forEach((evaluation, idx) => {
				if (_.includes(this.props.opd.modalNurse.evaluation, evaluation.description)) {
					values.push(idx)
				}
			})
		}
		return values
	}

	modifyEvaluation(evaluation, isAdd) {
		let value = this.props.opd.modalNurse.evaluation
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : "\n", evaluation)
		} else {
			evaluation.split("\n").forEach((each) => {
				value = value.replace(each, "")
			})
		}
		value = Utils.replaceDuplicateEmptyLine(value)
		this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, Utils.trim(value))
	}

	onCategoryEvaluation(value) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_EVALUATION_SELECTED_CATEGORY,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalNurse.categories[value] : null
		)
	}

	onSelectEvaluation(value) {
		this.modifyEvaluation(this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations[value].description, true)
	}

	onDeselectEvaluation(value) {
		this.modifyEvaluation(this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations[value].description, false)
	}

	onSelectAllEvaluation(event) {
		let evaluations = ""
		this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations.forEach((each) => {
			if (!_.includes(this.props.opd.modalNurse.evaluation, each.description)) {
				evaluations = evaluations.concat(each.description, "\n")
			}
		})
		this.modifyEvaluation(
			!_.isEmpty(evaluations)
				? evaluations
				: this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations.reduce((total, cur) => {
						return (total = total.concat(cur.description, "\n"))
				  }, ""),
			!_.isEmpty(evaluations)
		)
	}

	onEvaluation(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_EVALUATION, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.evaluation)}</legend>
				<Container fluid="small" className="full-flex">
					<Row className="g-1">
						<Col md={3}>
							<Select
								showSearch
								size={"small"}
								style={{ flexGrow: 1, textAlign: "center" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
								placeholder={translate(ResourceAssistance.Message.default)}
								value={this.props.opd.modalNurse.selectedEvaluationCategory.category ? this.props.opd.modalNurse.selectedEvaluationCategory.index : undefined}
								onChange={this.onCategoryEvaluation}
							>
								{Utils.renderSelects(this.props.opd.modalNurse.categories, false)}
							</Select>
						</Col>
						<Col>
							<Select
								mode={ResourceAssistance.Select.mode.multiple}
								size={"small"}
								style={{ flexGrow: 1, textAlign: "center" }}
								disabled={!this.props.opd.modalNurse.selectedEvaluationCategory.category}
								value={this.getValues()}
								maxTagCount="responsive"
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
								onSelect={this.onSelectEvaluation}
								onDeselect={this.onDeselectEvaluation}
								dropdownRender={(menu) => (
									<Fragment>
										{menu}
										{this.props.opd.modalNurse.selectedEvaluationCategory.category &&
											!_.isEmpty(this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations) && (
												<Fragment>
													<Divider style={{ margin: "4px 0" }} />
													<Row>
														<Col />
														<Col md="auto">
															<Button
																variant={ResourceAssistance.Button.variant.primary}
																disabled={
																	!this.props.opd.modalNurse.selectedEvaluationCategory.category ||
																	_.isEmpty(this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations)
																}
																onMouseDown={(e) => e.preventDefault()}
																onClick={this.onSelectAllEvaluation}
															>
																{this.props.opd.modalNurse.selectedEvaluationCategory.category &&
																this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations.every((evaluation) =>
																	this.props.opd.modalNurse.evaluation.includes(evaluation.description)
																) ? (
																	<Fragment>
																		<MdRemoveDone size={ResourceAssistance.ReactIcon.size} />
																		{translate(ResourceAssistance.Message.deselectAll)}
																	</Fragment>
																) : (
																	<Fragment>
																		<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
																		{translate(ResourceAssistance.Message.selectAll)}
																	</Fragment>
																)}
															</Button>
														</Col>
														<Col />
													</Row>
												</Fragment>
											)}
									</Fragment>
								)}
							>
								{Utils.renderSelects(
									this.props.opd.modalNurse.selectedEvaluationCategory.category
										? this.props.opd.modalNurse.selectedEvaluationCategory.category.evaluations
										: [],
									false,
									-1,
									"description"
								)}
							</Select>
						</Col>
					</Row>
					<Row className="full-size">
						<Col>
							<InputGroup>
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={this.props.opd.selectedNurseNote.nurseNote ? 5 : 4}
									value={this.props.opd.modalNurse.evaluation}
									onChange={this.onEvaluation}
								/>
							</InputGroup>
						</Col>
					</Row>
				</Container>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseEvaluation))
