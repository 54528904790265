import { combineReducers } from "redux"
import depositReducer from "./depositReducer"
import outpatientReducer from "./outpatientReducer"
import pharmacyReducer from "./pharmacyReducer"
import radiologyReducer from "./radiologyReducer"
import inventoryReducer from "./inventoryReducer"
import operatingRoomReducer from "./operatingRoomReducer"
import surgicalAuthorizationReducer from "./surgicalAuthorizationReducer"

export default combineReducers({
	radiology: radiologyReducer,
	deposit: depositReducer,
	opd: outpatientReducer,
	pharmacy: pharmacyReducer,
	frontInventory: inventoryReducer,
	operatingRoom: operatingRoomReducer,
	surgicalAuthorization: surgicalAuthorizationReducer,
})
