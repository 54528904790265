import { Select, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_OR_DOCTOR_FEE,
	SET_OPD_MODAL_OR_OPERATION_PROCEDURE,
	SET_OPD_MODAL_OR_PRE_OPERATIVE_DIAG,
	SET_OPD_MODAL_OR_SELECTED_OPERATIVE_DOCTOR,
	SET_OPD_MODAL_OR_SELECTED_OPERATIVE_ICD,
	SET_OPD_MODAL_OR_SELECTED_PRE_DIAG_ICD,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { TreeSelectUtils } from "~/utils/component/TreeSelectUtils"
import GInput from "~/view/component/input/GInput"

class OutpatientModalORDoctor extends React.Component {
	constructor(props) {
		super(props)

		this.onOperativeDoctor = this.onOperativeDoctor.bind(this)
		this.onPreDiagICD = this.onPreDiagICD.bind(this)
		this.onPreOperativeDiag = this.onPreOperativeDiag.bind(this)
		this.onOperativeICD = this.onOperativeICD.bind(this)
		this.onOperationProcedure = this.onOperationProcedure.bind(this)
		this.onDoctorFee = this.onDoctorFee.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedORRequest.request) {
			let doctorIdx = this.props.opd.doctors.findIndex((doctor) => doctor.displayName === this.props.opd.selectedORRequest.request.operativeDoctor)
			if (doctorIdx > -1) {
				this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_DOCTOR, doctorIdx, this.props.opd.doctors[doctorIdx])
			}
			// pre-diag ICD
			let preDiagICDIdx = this.findICD(this.props.opd.selectedORRequest.request.preDiagICD)
			if (!_.isEmpty(preDiagICDIdx)) {
				this.props.setSelected(
					SET_OPD_MODAL_OR_SELECTED_PRE_DIAG_ICD,
					preDiagICDIdx.join("-"),
					this.props.opd.icdTypes[preDiagICDIdx[0]].icds[preDiagICDIdx[1]]
				)
			}
			// operative ICD
			let operativeICDIdx = this.findICD(this.props.opd.selectedORRequest.request.operativeICD)
			if (!_.isEmpty(operativeICDIdx)) {
				this.props.setSelected(
					SET_OPD_MODAL_OR_SELECTED_OPERATIVE_ICD,
					operativeICDIdx.join("-"),
					this.props.opd.icdTypes[operativeICDIdx[0]].icds[operativeICDIdx[1]]
				)
			}
			this.props.setValue(SET_OPD_MODAL_OR_DOCTOR_FEE, Utils.BigNumber(this.props.opd.selectedORRequest.request.doctorFee).toFixed(2))
			this.props.setValue(SET_OPD_MODAL_OR_PRE_OPERATIVE_DIAG, this.props.opd.selectedORRequest.request.preOperativeDiag)
			this.props.setValue(SET_OPD_MODAL_OR_OPERATION_PROCEDURE, this.props.opd.selectedORRequest.request.operationProcedure)
		}
	}

	findICD(description) {
		let typeIdx = this.props.opd.icdTypes.findIndex((each) => each.icds.some((icd) => icd.description === description))
		if (typeIdx > -1) {
			let icdIdx = this.props.opd.icdTypes[typeIdx].icds.findIndex((each) => each.description === description)
			if (icdIdx > -1) {
				return [typeIdx, icdIdx]
			}
		}
		return []
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_OR_DOCTOR_FEE, undefined)
		this.props.setValue(SET_OPD_MODAL_OR_PRE_OPERATIVE_DIAG, "")
		this.props.setValue(SET_OPD_MODAL_OR_OPERATION_PROCEDURE, "")
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_DOCTOR, -1, null)
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_PRE_DIAG_ICD, -1, null)
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_ICD, -1, null)
	}

	isPreApprovedFieldDisabled() {
		return this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.approved
	}

	onOperativeDoctor(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_DOCTOR, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_DOCTOR, v, this.props.opd.doctors[v])
	}

	onDoctorFee(e) {
		this.props.setValue(SET_OPD_MODAL_OR_DOCTOR_FEE, e.target.value)
	}

	onPreDiagICD(v, node) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_PRE_DIAG_ICD, -1, null)
			return
		}
		let keys = v.split("-")
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_PRE_DIAG_ICD, v, this.props.opd.icdTypes[keys[0]].icds[keys[1]])
	}

	onPreOperativeDiag(v) {
		this.props.setValue(SET_OPD_MODAL_OR_PRE_OPERATIVE_DIAG, v.target.value)
	}

	onOperativeICD(v, node) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_ICD, -1, null)
			return
		}
		let keys = v.split("-")
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_OPERATIVE_ICD, v, this.props.opd.icdTypes[keys[0]].icds[keys[1]])
	}

	onOperationProcedure(v) {
		this.props.setValue(SET_OPD_MODAL_OR_OPERATION_PROCEDURE, v.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.doctor)}</legend>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.operativeDoctor)}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.selectedOperativeDoctor.doctor ? this.props.opd.modalOR.selectedOperativeDoctor.index : undefined}
							onChange={this.onOperativeDoctor}
						>
							{Utils.renderSelects(this.props.opd.doctors, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.doctorFee)}
							pattern="^[0-9]*\.[0-9]{2}$"
							disabled={this.isPreApprovedFieldDisabled()}
							onChange={this.onDoctorFee}
							value={this.props.opd.modalOR.doctorFee}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TreeSelect
							allowClear
							showSearch
							loading={this.props.opd.modalOR.isICDsLoading}
							treeLine={{ showLeafIcon: false }}
							style={{
								textAlign: "center",
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.preOperativeDiagICD)}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.selectedPreDiagICD.icd ? this.props.opd.modalOR.selectedPreDiagICD.index : undefined}
							onChange={this.onPreDiagICD}
							treeData={TreeSelectUtils.displayICD(this.props.opd.icdTypes)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.preOperativeDiag)}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.preOperativeDiag}
							onChange={this.onPreOperativeDiag}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TreeSelect
							allowClear
							showSearch
							loading={this.props.opd.modalOR.isICDsLoading}
							treeLine={{ showLeafIcon: false }}
							style={{
								textAlign: "center",
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.operationProcedureICD)}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.selectedOperativeICD.icd ? this.props.opd.modalOR.selectedOperativeICD.index : undefined}
							onChange={this.onOperativeICD}
							treeData={TreeSelectUtils.displayICD(this.props.opd.icdTypes)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.operationProcedure)}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.operationProcedure}
							onChange={this.onOperationProcedure}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientModalORDoctor)
