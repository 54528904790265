import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY } from "~/redux/type"
import RegistrationPrintableDataFactory from "~/utils/factory/print/HIS/RegistrationPrintableDataFactory"
import RegModalExpenseEstDetails from "./RegModalExpenseEstDetails"

class RegModalExpenseEst extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RegModalExpenseEst",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.reg.modalExpenseEst.pendingTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let data = RegistrationPrintableDataFactory.generateExpenseEstReportData(
			this.props.reg.selectedPatient.patient,
			this.props.reg.modalExpenseEst.pendingTable.original,
			this.props.reg.modalExpenseEst.budgetTable.original
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.expenseEst, "_blank")
		myWindow.data = data
		// this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.expenseEstimation)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<RegModalExpenseEstDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isRegistrationExpenseEstimationDisplay,
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEst))
