import { Checkbox } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND } from "~/redux/type"

class ConfigGLModalReceivableAccountsReceive extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectable = this.onSelectable.bind(this)
	}

	componentDidMount() {
		if (this.props.configGL.accountsReceivable.selectedAR.AR) {
			let selectedAR = this.props.configGL.accountsReceivable.selectedAR.AR
			this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND, selectedAR.accountsReceiveSelectable)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND, false)
	}

	onSelectable(e) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_MODAL_AR_ACCOUNTS_RECEIVE_SELECTABLE_IND, e.target.checked)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.accountsReceive)}</legend>
				<Row className="g-2">
					<Col md="auto">
						<Checkbox
							style={{ flexDirection: "row" }}
							checked={this.props.configGL.accountsReceivable.modalAR.isAccountsReceiveSelectable}
							onChange={this.onSelectable}
						>
							{translate(ResourceAssistance.Message.selectable)}
						</Checkbox>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	configGL: state.admin.configGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGLModalReceivableAccountsReceive))
