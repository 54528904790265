import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_AP_SEARCH_DISPLAY } from "~/redux/type"
import { onModalDisplayAction } from "~/redux/action"

class AccountsPayableLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onSearch = this.onSearch.bind(this)
	}

	onSearch(event) {
		this.props.onModalDisplayAction(SET_AP_SEARCH_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col />
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.info}
						onClick={this.onSearch}
					>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsPayableLeftAction))
