import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportDepressionAssessmentDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "Depression Assessment",
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>
						{/* Patient Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.id}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Name: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.dob}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>
						{/* 2Q */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader]}>
								<Text>แบบประเมินภาวะซึมเศร้า 2Q</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />
						{/* 2Q Table */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>ลำดับที่</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.question]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>คำถาม</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.yes]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>มี</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.no]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>ไม่มี</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						{/* 2Q Questions */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>1</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.question]}>
								<Text>ใน 2 สัปดาห์ที่ผ่านมารวมวันนี้ท่านรู้สึกหดหู่ เศร้า หรือท้อแท้สิ้นหวังหรือไม่</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.yes]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.no]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>2</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.question]}>
								<Text>ใน 2 สัปดาห์ที่ผ่านมารวมวันนี้ท่านรู้สึก เบื่อ ทำอะไรก็ไม่เพลิดเพลินหรือไม่</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.yes]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.no]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* 2Q Signature */}
						<Signature
							fixed={false}
							signatureDate={this.state.data.signatureDate}
							numOfSignature={2}
							signatureTitles={this.state.data.signatureTitles}
						/>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* PHQ-9 */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader]}>
								<Text> แบบประเมินภาวะซึมเศร้า 9Q</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* PHQ-9 Table */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>ลำดับที่</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqQuestion]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>
									ใน2สัปดาห์ที่ผ่านมีรวมทั้งวันนี้ท่านมีอาการเหล่านี้บ่อยแค่ไหน
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>ไม่มีเลย</Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}> </Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>(0)</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>เป็นบางวัน</Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>1-7 วัน</Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>(1)</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>เป็นบ่อย</Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>{">"}7 วัน</Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>(2)</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>เป็นทุกวัน</Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}> </Text>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>(3)</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* PHQ-9 Questions */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>1</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>เบื่อ ไม่สนใจอยากทำอะไร</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>2</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>ไม่สบายใจ ซึมเศร้า ท้อแท้</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>3</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>หลับยาก หรือหลับ ๆ ตื่น ๆ หรือหลับมากไป</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>4</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>เหนื่อยง่าย หรือ ไม่ค่อยมีแรง</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>5</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>เบื่ออาหาร หรือ กินมากเกินไป</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>6</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>รู้สึกไม่ดีกับตัวเอง คิดว่า ตัวเองล้มเหลว หรือ ทำให้ตนเอง หรือครอบครัวผิดหวัง</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>7</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>สมาธิไม่ดีเวลาทำอะไร เช่น ดูโทรทัศน์ฟังวิทยุ หรือทำงานที่ต้องใช้ความตั้งใจ</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>8</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>
									พูดช้า ทำอะไรช้าจนคนอื่นสังเกตเห็นได้หรือ {"\n"} กระสับกระส่ายไม่สามารถอยู่นิ่งได้เหมือนที่เคยเป็น
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
								<Text>9</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.phqQuestion]}>
								<Text>คิดทำร้ายตนเอง หรือ คิดว่าถ้าตายไปคงจะดี</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqNo]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>
									{ResourceAssistance.Symbol.Report.check}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqOnlyOne]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqSomeDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.phqEveryDay]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}></Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* PHQ-9 Scores */}
						<View style={[ResourceAssistance.Report.styles.row, { marginVertical: 20 }]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader, { marginRight: 20 }]}>
								<Text>หมายเหตุ : คะแนน 9 Q ≥7 คะแนนให้ประเมิน 8 Q และส่งแพทย์</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>คะแนนรวม............................................</Text>
							</View>
						</View>

						{/* 2Q Signature */}
						<Signature
							fixed={false}
							signatureDate={this.state.data.signatureDate}
							numOfSignature={2}
							signatureTitles={this.state.data.signatureTitles}
						/>
						<View style={ResourceAssistance.Report.styles.separator} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	seq: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	question: {
		minWidth: "70%",
		maxWidth: "70%",
	},
	yes: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	no: {
		minWidth: "10%",
		maxWidth: "10%",
	},

	// PHQ-9
	phqQuestion: {
		minWidth: "49%",
		maxWidth: "49%",
	},
	phqNo: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	phqOnlyOne: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	phqSomeDay: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	phqEveryDay: {
		minWidth: "11%",
		maxWidth: "11%",
	},
})

export default ReportDepressionAssessmentDocument
