import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFrontInventoryInventories, setLoadingAction, setValue } from "~/redux/action"
import { SET_FRONT_INVENTORY_SPLIT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import FIModalSplitDetails from "./FIModalSplitDetails"

class FIModalSplit extends React.Component {
	constructor(props) {
		super(props)

		this.lotRef = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_SPLIT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.lotRef.current) {
			return true
		}
		return !this.props.frontInventory.modalSplit.lotTable.filtered.some((lot) => lot.isSplit)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.frontInventory.saveSplit,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				locationId: this.props.frontInventory.selectedDepartment.department.id,
				userId: this.props.login.user.id,
				splitItems: this.props.frontInventory.modalSplit.lotTable.filtered
					.filter((lot) => lot.isSplit)
					.map((lot) => ({
						pharmacyInventoryId: this.props.frontInventory.selectedInventory.inventory.id,
						code: lot.code,
						name: lot.name,
						brand: lot.brand,
						lotNum: lot.lotNum,
						unit: lot.unit,
						amount: lot.splitAmount,
						splitUnit: lot.splitUnit,
						splitUnitAmount: lot.splitUnitAmount,
						expiredDateTime: lot.expiredDateTime,
					})),
			},
		}
		let callback = (res) => {
			this.props.setFrontInventoryInventories(res.data.pharmacyInventories)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.split)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FIModalSplitDetails lotRef={this.lotRef} onRenderParentCallback={this.onRenderParentCallback} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isFrontInventorySplitDisplay,
	login: state.login,
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setFrontInventoryInventories,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FIModalSplit)
