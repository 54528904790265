import {
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS_LOADING,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_DISPLAY_ALL_IND,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_SEARCH_NAME,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_COMMENT,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_TYPE,
	SET_ADM_LABORATORY_ANTIGEN_COMMENT_TYPES,
	SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS,
	SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING,
	SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND,
	SET_ADM_LABORATORY_PCR_COMMENT_NEW_ACTIVE,
	SET_ADM_LABORATORY_PCR_COMMENT_NEW_DESCRIPTION,
	SET_ADM_LABORATORY_PCR_COMMENT_NEW_NAME,
	SET_ADM_LABORATORY_PCR_COMMENT_NEW_SELECTED_TYPE,
	SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME,
	SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT,
	SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE,
	SET_ADM_LABORATORY_PCR_COMMENT_TYPES,
} from "~/redux/type"

const { ResourceAssistance } = require("~/i18n")

const init = {
	pcrComment: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedComment: {
			index: -1,
			comment: null,
		},
		selectedType: {
			index: -1,
			type: null,
		},
		commentTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewComment: {
			name: "",
			description: "",
			isActive: true,
			selectedType: {
				index: -1,
				type: null,
			},
		},
	},
	antigenComment: {
		searchName: "",
		displayInactive: true,
		types: [],
		selectedComment: {
			index: -1,
			comment: null,
		},
		selectedType: {
			index: -1,
			type: null,
		},
		commentTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewComment: {
			name: "",
			description: "",
			isActive: true,
			selectedType: {
				index: -1,
				type: null,
			},
		},
	},
}

const ConfigLaboratoryReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_LABORATORY_PCR_COMMENT_TYPES:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					types: action.payload.objs,
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_TYPE:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_SEARCH_NAME:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					commentTable: {
						...state.pcrComment.commentTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_COMMENTS_LOADING:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					commentTable: {
						...state.pcrComment.commentTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_SELECTED_COMMENT:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					selectedComment: {
						index: action.payload.index,
						comment: action.payload.selected,
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_NEW_SELECTED_TYPE:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					modalNewComment: {
						...state.pcrComment.modalNewComment,
						selectedType: {
							index: action.payload.index,
							type: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_NEW_NAME:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					modalNewComment: {
						...state.pcrComment.modalNewComment,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_NEW_DESCRIPTION:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					modalNewComment: {
						...state.pcrComment.modalNewComment,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_LABORATORY_PCR_COMMENT_NEW_ACTIVE:
			return Object.assign({}, state, {
				pcrComment: {
					...state.pcrComment,
					modalNewComment: {
						...state.pcrComment.modalNewComment,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_TYPES:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					types: action.payload.objs,
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_TYPE:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_SEARCH_NAME:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					commentTable: {
						...state.pcrComment.commentTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_COMMENTS_LOADING:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					commentTable: {
						...state.pcrComment.commentTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_SELECTED_COMMENT:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					selectedComment: {
						index: action.payload.index,
						comment: action.payload.selected,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_SELECTED_TYPE:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					modalNewComment: {
						...state.antigenComment.modalNewComment,
						selectedType: {
							index: action.payload.index,
							type: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_NAME:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					modalNewComment: {
						...state.antigenComment.modalNewComment,
						name: action.payload.value,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_DESCRIPTION:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					modalNewComment: {
						...state.antigenComment.modalNewComment,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_LABORATORY_ANTIGEN_COMMENT_NEW_ACTIVE:
			return Object.assign({}, state, {
				antigenComment: {
					...state.antigenComment,
					modalNewComment: {
						...state.antigenComment.modalNewComment,
						isActive: action.payload.value,
					},
				},
			})
		default:
			return state
	}
}

export default ConfigLaboratoryReducer
