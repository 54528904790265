import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import SearchableCriteria from "~/view/component/search/SearchableCriteria"
import TimelineModalSearchResult from "./TimelineModalSearchResult"

class TimelineModalSearchDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{_.isEmpty(this.props.timeline.modalSearch.patientTable.original) ? (
					<SearchableCriteria {...this.props} onChange={this.props.onSearchChange} />
				) : (
					<TimelineModalSearchResult />
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	timeline: state.menu.timeline,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TimelineModalSearchDetails)
