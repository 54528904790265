import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPharmacyPatients, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PHARMACY_RETURN_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PharmacyModalReturnDetails from "./PharmacyModalReturnDetails"

class PharmacyModalReturn extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyModalReturn",
		}

		this.lotRef = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PHARMACY_RETURN_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.lotRef.current) {
			return true
		}
		return !this.props.pharmacy.modalReturn.itemTable.filtered.some((lot) => lot.isReturn)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let pharmacyReturnOrderItems = this.props.pharmacy.modalReturn.itemTable.filtered
			.filter((lot) => lot.isReturn)
			.map((lot) => {
				return {
					pharmacyOrderItemId: lot.id,
					pharmacyInventoryId: lot.pharmacyInventory.id,
					code: lot.code,
					name: lot.name,
					lotNum: lot.lotNum,
					amount: lot.returnAmount,
					unit: lot.unit,
				}
			})
		let pharmacyReturnOrder = {
			pharmacyOrderId: this.props.pharmacy.selectedDispensedOrder.dispensedOrder.id,
			amount: pharmacyReturnOrderItems
				.reduce((total, cur) => {
					total = total.plus(cur.amount)
					return total
				}, Utils.BigNumber(0))
				.toNumber(),
			unit: this.props.pharmacy.selectedDispensedOrder.dispensedOrder.unit,
			startDateTime: this.props.pharmacy.selectedDispensedOrder.dispensedOrder.startDateTime,
			endDateTime: this.props.pharmacy.selectedDispensedOrder.dispensedOrder.endDateTime,
			pharmacyReturnOrderItems: pharmacyReturnOrderItems,
		}
		if (this.props.pharmacy.modalReturn.isDoctorOrder) {
			Object.assign(pharmacyReturnOrder, {
				doctorOrderId: this.props.pharmacy.selectedDoctorOrder.doctorOrder.id,
			})
		} else {
			Object.assign(pharmacyReturnOrder, {
				nurseOrderId: this.props.pharmacy.selectedDoctorOrder.doctorOrder.id,
			})
		}
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.pharmacy.savePharmacyReturnOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.pharmacy.selectedPatient.patient.isSearchPatient
					? this.props.pharmacy.selectedPatient.patient.id
					: 0,
				startDateTime: this.props.pharmacy.startDateTime,
				endDateTime: this.props.pharmacy.endDateTime,
				departmentId: this.props.pharmacy.location.id,
				branchId: this.props.pharmacy.modalLocation.selectedBranch.branch.id,
				userId: this.props.login.user.id,
				pharmacyReturnOrders: [pharmacyReturnOrder],
			},
		}
		let callback = (res) => {
			if (this.props.pharmacy.selectedPatient.patient.isSearchPatient) {
				res.data.patients.forEach((patient) =>
					Object.assign(patient, {
						isSearchPatient: true,
					})
				)
			}
			this.props.setPharmacyPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.return)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PharmacyModalReturnDetails lotRef={this.lotRef} onRenderParentCallback={this.onRenderParentCallback} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPharmacyReturnDisplay,
	login: state.login,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPharmacyPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalReturn)
