import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

class ContextMenuPatientHealthHistory extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.healthHistory)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={2}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.height)} span={1}>
								{this.props.patient.height} {ResourceAssistance.CONSTANT.CM}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.weight)} span={1}>
								{this.props.patient.weight} {ResourceAssistance.CONSTANT.KG}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.underlyingDisease)} span={2}>
								{this.props.patient.underlyingDisease}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.allergies)} span={2}>
								{this.props.patient.allergies}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.pastIllnesses)} span={2}>
								{this.props.patient.pastIllnesses}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.pastSurgicalHistory)} span={2}>
								{this.props.patient.pastSurgicalHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.familyHealthHistory)} span={2}>
								{this.props.patient.familyHealthHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.accidentHistory)} span={2}>
								{this.props.patient.accidentHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.supplementOrHerbsHistory)} span={2}>
								{this.props.patient.supplementOrHerbsHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.alcohol)} span={2}>
								{this.props.patient.alcoholHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tobacco)} span={2}>
								{this.props.patient.tobaccoHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.passiveSmokeExposure)} span={2}>
								{this.props.patient.passiveSmokeExposure}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.medicineHistory)} span={2}>
								{this.props.patient.medicineHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.doctorNote)} span={2}>
								{this.props.patient.doctorNote}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

export default ContextMenuPatientHealthHistory
