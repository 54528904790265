import { Button, Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAccountsReceivableInvoices, setLoadingAction, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_AR_EDIT_INVOICE_DISPLAY,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_MAX,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AccountsReceivablePrintDataFactory from "~/utils/factory/print/fiannce/AccountsReceivablePrintDataFactory"

class AccountsReceivableRightInfo extends React.Component {
	constructor(props) {
		super(props)
		this.onEdit = this.onEdit.bind(this)
		this.onInspect = this.onInspect.bind(this)
		this.onReject = this.onReject.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}
	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsReceivable.inspectInvoice)) {
					this.inspectInvoice()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsReceivable.rejectInvoice)) {
					this.rejectInvoice()
				}
			}
		}
	}

	isInspectorCompleteDisabled() {
		return (
			this.props.AR.selectedInvoice.invoice.inspectorComplete ||
			this.props.AR.billingStatementGLGroupTable.body.some((each) => _.isEmpty(each[1])) ||
			!this.props.AR.billingStatementGLGroupTable.body
				.reduce((total, cur) => {
					return total.plus(Utils.formatNumberFromStr(cur[4]))
				}, Utils.BigNumber(0))
				.eq(
					this.props.AR.billingStatementGLGroupTable.body.reduce((total, cur) => {
						return total.plus(Utils.formatNumberFromStr(cur[5]))
					}, Utils.BigNumber(0))
				)
		)
	}

	inspectInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsReceivable.inspectInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.AR.location.id,
				invoiceId: this.props.AR.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let invoices = this.props.AR.invoiceTable.original.filter((each) => each.id !== res.data.invoices[0].id)
			invoices.push(res.data.invoices[0])
			this.props.setAccountsReceivableInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	rejectInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsReceivable.rejectInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.AR.location.id,
				invoiceId: this.props.AR.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let invoices = this.props.AR.invoiceTable.original.filter((each) => each.id !== res.data.invoices[0].id)
			invoices.push(res.data.invoices[0])
			this.props.setAccountsReceivableInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	displayWarning(warningId, warningMsg) {
		this.props.setWarningId(warningId)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(warningMsg)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.visitId)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.id : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.id : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.displayName : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? Utils.formatDate(this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.dobtime) : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.identificationNumber : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.code : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCarePlan)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.name : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.insuranceCompany)} span={1}>
						{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.insuranceCompany : ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.discountBill)} span={1}>
						{this.props.AR.selectedInvoice.invoice
							? Utils.formatNumWithComma(
									this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments
										.reduce((total, cur) => {
											return total.plus(cur.adjustments)
										}, Utils.BigNumber(0))
										.toFixed(2)
							  )
							: ""}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{this.props.AR.selectedInvoice.invoice
							? this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.reduce((remark, cur) => {
									return remark.concat(cur.discountDescription, "\n")
							  }, "")
							: ""}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onEdit(e) {
		let totalBalance = this.props.AR.selectedInvoice.invoice.billing.billingStatements.reduce((total, cur) => {
			return total.plus(cur.charge).minus(cur.adjustment)
		}, Utils.BigNumber(0))
		let totalBillingPlanDiscount = this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.reduce((total, cur) => {
			return total.plus(cur.adjustments)
		}, Utils.BigNumber(0))
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_MAX, totalBalance.toFixed(2))
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT, totalBillingPlanDiscount.toFixed(2))
		if (!_.isEmpty(this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments)) {
			this.props.setValue(
				SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK,
				this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments[0].discountDescription
			)
		}
		this.props.onModalDisplayAction(SET_AR_EDIT_INVOICE_DISPLAY, true)
	}

	onInspect() {
		this.displayWarning(ResourceAssistance.ID.ACCOUNTING.accountsReceivable.inspectInvoice, ResourceAssistance.Message.inspector)
	}

	onReject() {
		this.displayWarning(ResourceAssistance.ID.ACCOUNTING.accountsReceivable.rejectInvoice, ResourceAssistance.Message.reject)
	}

	onPrint() {
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.accountsReceiveJournalVoucher, "_blank")
		myWindow.data = AccountsReceivablePrintDataFactory.generateJournalVoucherData(this.props.AR.location, this.props.AR.selectedInvoice.invoice)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.generalInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={4}
							extra={
								this.props.AR.selectedInvoice.invoice && (
									<Row>
										{!this.props.AR.selectedInvoice.invoice.accountsReceivableInspected && (
											<Col md="auto">
												<Button
													type="primary"
													size="middle"
													shape="round"
													icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onEdit}
												/>
											</Col>
										)}
										{!this.props.AR.selectedInvoice.invoice.accountsReceivableComplete &&
											!this.props.AR.selectedInvoice.invoice.accountsReceivableInspected &&
											Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsReceivable.Inspector, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														style={{
															background: this.isInspectorCompleteDisabled() ? undefined : ResourceAssistance.CSS.Color.dark_green,
														}}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.inspector })}
														type="primary"
														size="middle"
														shape="round"
														icon={<AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} />}
														disabled={this.isInspectorCompleteDisabled()}
														onClick={this.onInspect}
													/>
												</Col>
											)}
										{!this.props.AR.selectedInvoice.invoice.accountsReceivableComplete &&
											this.props.AR.selectedInvoice.invoice.accountsReceivableInspected &&
											Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsReceivable.Approver, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject })}
														type="primary"
														size="middle"
														shape="round"
														danger
														icon={<TiArrowBack size={ResourceAssistance.ReactIcon.size} />}
														onClick={this.onReject}
													/>
												</Col>
											)}
										{this.props.AR.selectedInvoice.invoice.accountsReceivableComplete && (
											<Col md="auto">
												<Button
													style={{ background: ResourceAssistance.CSS.Color.info }}
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.print })}
													type="primary"
													size="middle"
													shape="round"
													icon={<ImPrinter size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onPrint}
												/>
											</Col>
										)}
									</Row>
								)
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.visitId)} span={4}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.patientId)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.displayName : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.dateOfBirth)} span={1}>
								{this.props.AR.selectedInvoice.invoice
									? Utils.formatDate(this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.dobtime)
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.admission.patient.identificationNumber : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.code : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCarePlan)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.name : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.insuranceCompanyCode : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.insuranceCompany)} span={1}>
								{this.props.AR.selectedInvoice.invoice ? this.props.AR.selectedInvoice.invoice.billing.billingPlan.insuranceCompany : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.discountBill)} span={1}>
								{this.props.AR.selectedInvoice.invoice
									? Utils.formatNumWithComma(
											this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments
												.reduce((total, cur) => {
													return total.plus(cur.adjustments)
												}, Utils.BigNumber(0))
												.toFixed(2)
									  )
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={2}>
								{this.props.AR.selectedInvoice.invoice
									? this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.reduce((remark, cur) => {
											return remark.concat(cur.discountDescription, "\n")
									  }, "")
									: ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
			setLoadingAction,
			setWarningId,
			setWarningMsgAction,
			setAccountsReceivableInvoices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceivableRightInfo))
