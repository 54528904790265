import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_SA_MODAL_INSPECT_REASON } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class SurgicalAuthModalInspectDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onInspectReason = this.onInspectReason.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_SA_MODAL_INSPECT_REASON, "")
	}

	onInspectReason(e) {
		this.props.setValue(SET_SA_MODAL_INSPECT_REASON, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							placeholder={translate(ResourceAssistance.Message.reason)}
							minRows={3}
							onChange={this.onInspectReason}
							value={this.props.surgicalAuth.modalInspect.reason}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(SurgicalAuthModalInspectDetails)
