import { SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE } from "~/redux/type"

export const setAdminlocModalNLTitle = (title) => {
	return {
		type: SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE,
		payload: {
			title: title,
		},
	}
}
