import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPGLEntries, setPostingGLs, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_PGL_ENTRY_DISPLAY, SET_PGL_SELECTED_ENTRY, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PostingGLRightEntry extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.postingGL.deleteGL)) {
				this.deleteGL()
			}
		}
		if (!_.isEqual(prevProps.PGL.selectedPGL, this.props.PGL.selectedPGL)) {
			if (this.props.PGL.selectedPGL.PGL) {
				this.props.setPGLEntries(this.props.PGL.selectedPGL.PGL.entries)
			} else {
				this.props.setPGLEntries([])
			}
		}
		if (!_.isEqual(prevProps.PGL.entryTable.filtered, this.props.PGL.entryTable.filtered)) {
			let index = this.props.PGL.selectedEntry.entry
				? this.props.PGL.entryTable.filtered.findIndex((each) => each.id === this.props.PGL.selectedEntry.entry.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PGL_SELECTED_ENTRY, index, this.props.PGL.entryTable.filtered[index])
			} else {
				this.props.setSelected(SET_PGL_SELECTED_ENTRY, -1, null)
			}
		}
	}

	deleteGL() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.postingGL.deleteEntry,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PGL.location.id,
				postingGLId: this.props.PGL.selectedPGL.PGL.id,
				userId: this.props.login.user.id,
				postingGLs: [
					{
						id: this.props.PGL.selectedPGL.PGL.id,
						entries: [
							{
								id: this.props.PGL.selectedEntry.entry.id,
							},
						],
					},
				],
			},
		}
		let callback = (res) => {
			let postingGLs = this.props.PGL.postingGLTable.original.filter((PGL) => PGL.id !== this.props.PGL.selectedPGL.PGL.id)
			if (res.data.postingGLs.length > 0) {
				postingGLs.push(res.data.postingGLs[0])
			}
			this.props.setPostingGLs(postingGLs)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isDisplayBtn() {
		return this.props.PGL.selectedPGL.PGL && !this.props.PGL.selectedPGL.PGL.inspectorComplete
	}

	onSelectRow(plan, index) {
		this.props.setSelected(SET_PGL_SELECTED_ENTRY, index, this.props.PGL.entryTable.filtered[index])
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_PGL_ENTRY_DISPLAY, true)
	}

	onDelete(e, row, rIdx, cIdx) {
		let selectedEntry = this.props.PGL.entryTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.postingGL.deleteGL)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{selectedEntry.accountingCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
						{selectedEntry.accountingChartName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
						{selectedEntry.accountingTransDescr}
					</Descriptions.Item>
					{selectedEntry.debit ? (
						<Descriptions.Item label={translate(ResourceAssistance.Message.debit)} span={1}>
							{Utils.formatNumWithComma(Utils.BigNumber(selectedEntry.amount).toFixed(2))}
						</Descriptions.Item>
					) : (
						<Descriptions.Item label={translate(ResourceAssistance.Message.credit)} span={1}>
							{Utils.formatNumWithComma(Utils.BigNumber(selectedEntry.amount).toFixed(2))}
						</Descriptions.Item>
					)}
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		let debit = this.props.PGL.entryTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[3]))
		}, Utils.BigNumber(0))
		let credit = this.props.PGL.entryTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[4]))
		}, Utils.BigNumber(0))
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnMessages={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
						]}
						btnIcons={[
							this.isDisplayBtn() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayBtn() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onEdit, this.onDelete]}
						data={this.props.PGL.entryTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.PGL.selectedEntry.index === -1}
						highlightedRow={this.props.PGL.selectedEntry.index}
						extra={
							!_.isEmpty(this.props.PGL.entryTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
												color: debit.eq(credit) ? undefined : ResourceAssistance.CSS.Color.red,
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.debit)}>{Utils.formatNumWithComma(debit.toFixed(2))}</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.credit)}>{Utils.formatNumWithComma(credit.toFixed(2))}</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setPGLEntries,
			setPostingGLs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PostingGLRightEntry))
