import { WarningOutlined } from "@ant-design/icons"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import { FaRegEdit } from "react-icons/fa"
import { GiPayMoney } from "react-icons/gi"
import { ImPrinter } from "react-icons/im"
import { MdLibraryAdd } from "react-icons/md"
import { TbDiscount2 } from "react-icons/tb"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setCashierAdmissionBalance,
	setCashierAdmissions,
	setCashierHealthCarePlans,
	setLoadingAction,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_CASHIER_FINANCIAL_PLAN_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
	SET_CASHIER_PAYMENT_DISPLAY,
	SET_CASHIER_SELECTED_HEALTH_CARE_PLAN,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"

class CashierRightHealthCarePlan extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierRightHealthCarePlan",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onFinancialPlan = this.onFinancialPlan.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDiscount = this.onDiscount.bind(this)
		this.onPay = this.onPay.bind(this)
		this.onPrintReceipt = this.onPrintReceipt.bind(this)
		this.onPrintCategoryReceipt = this.onPrintCategoryReceipt.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalAdjustment.isContinue, this.props.cashier.modalAdjustment.isContinue)) {
			if (
				this.props.cashier.modalAdjustment.isContinue &&
				_.isEqual(this.props.cashier.modalAdjustment.id, ResourceAssistance.ID.HOSPITEL.cashier.modalAdjustment.healthCarePlanDiscount)
			) {
				this.saveHealthCarePlanDiscount()
			}
		}
		if (!_.isEqual(prevProps.cashier.selectedAdmission, this.props.cashier.selectedAdmission)) {
			if (this.props.cashier.selectedAdmission.admission) {
				this.props.setCashierHealthCarePlans(this.props.cashier.selectedAdmission.admission.billingPlans)
				this.props.setCashierAdmissionBalance(this.props.cashier.selectedAdmission.admission)
			} else {
				this.props.setCashierHealthCarePlans([])
				this.props.setCashierAdmissionBalance(null)
			}
		}
		if (!_.isEqual(prevProps.cashier.healthCarePlanTable.original, this.props.cashier.healthCarePlanTable.original)) {
			if (this.props.cashier.selectedHealthCarePlan.healthCarePlan) {
				let idx = this.props.cashier.healthCarePlanTable.filtered.findIndex(
					(healthCarePlan) => healthCarePlan.id === this.props.cashier.selectedHealthCarePlan.healthCarePlan.id
				)
				if (idx > -1) {
					this.props.setSelected(SET_CASHIER_SELECTED_HEALTH_CARE_PLAN, idx, this.props.cashier.healthCarePlanTable.filtered[idx])
				} else {
					this.props.setSelected(SET_CASHIER_SELECTED_HEALTH_CARE_PLAN, -1, null)
				}
			}
		}
	}

	isDisplayIcon() {
		return this.props.cashier.selectedAdmission.admission && !this.props.cashier.selectedAdmission.admission.dischargedBy
	}

	isDisplayHealthCarePlanIcon() {
		return this.isDisplayIcon() && Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Cashier.HealthCarePlanCreateEdit, this.props.login.user.roles)
	}

	isDisplayPayIcon() {
		return (
			this.isDisplayIcon() &&
			Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Cashier.BillingPlanCreate, this.props.login.user.roles) &&
			!this.props.cashier.patient.hospitels.some((hospitel) => !hospitel.dischargeSummary) &&
			(!this.props.cashier.selectedAdmission.admission.clinicalRecord ||
				!this.props.cashier.selectedAdmission.admission.clinicalRecord.medicalRecords.some((md) => md.checkOutDateTime === 0))
		)
	}

	isDisplayPrintIcon() {
		return this.props.cashier.selectedAdmission.admission && this.props.cashier.selectedAdmission.admission.dischargedBy
	}

	isDisplayWarning() {
		return this.isDisplayIcon()
	}

	saveHealthCarePlanDiscount() {
		let billingPlan = this.props.cashier.selectedAdmission.admission.billingPlans.find(
			(each) =>
				_.isEqual(each.code, this.props.cashier.selectedHealthCarePlan.healthCarePlan.code) &&
				_.isEqual(each.name, this.props.cashier.selectedHealthCarePlan.healthCarePlan.name) &&
				_.isEqual(each.description, this.props.cashier.selectedHealthCarePlan.healthCarePlan.description)
		)
		let pendingBillingPlanAdjustments = billingPlan.billingPlanAdjustments.filter((each) => !each.billing)
		let billBalance = this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
			.filter((bs) => !bs.billing)
			.reduce((total, cur) => {
				total = total.plus(cur.charge).minus(cur.adjustment)
				return total
			}, Utils.BigNumber(0))
		let discount = Utils.BigNumber(this.props.cashier.modalAdjustment.discountPercent).isGreaterThan(0)
			? billBalance.times(this.props.cashier.modalAdjustment.discountPercent).dividedBy(100)
			: Utils.BigNumber(this.props.cashier.modalAdjustment.amount)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.saveBillingPlanAdjustments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.cashier.selectedAdmission.admission.id,
				userId: this.props.login.user.id,
				billingPlanAdjustments: [
					{
						id: _.isEmpty(pendingBillingPlanAdjustments) ? 0 : pendingBillingPlanAdjustments[0].id,
						billingPlanId: this.props.cashier.selectedHealthCarePlan.healthCarePlan.id,
						discount: discount.toFixed(2),
						discountDescription: Utils.trim(this.props.cashier.modalAdjustment.description),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter((each) => each.id !== res.data.admissions[0].id)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(service, index) {
		this.props.setSelected(SET_CASHIER_SELECTED_HEALTH_CARE_PLAN, index, this.props.cashier.healthCarePlanTable.filtered[index])
	}

	onFinancialPlan(event) {
		this.props.setSelected(SET_CASHIER_SELECTED_HEALTH_CARE_PLAN, -1, null)
		this.props.onModalDisplayAction(SET_CASHIER_FINANCIAL_PLAN_DISPLAY, true)
	}

	onEdit(event) {
		this.props.onModalDisplayAction(SET_CASHIER_FINANCIAL_PLAN_DISPLAY, true)
	}

	onDiscount(event, row, rIdx, cIdx) {
		let selectedHealthCarePlan = this.props.cashier.healthCarePlanTable.filtered[rIdx]
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.HOSPITEL.cashier.modalAdjustment.healthCarePlanDiscount)
		this.props.setValue(
			SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_MAX,
			selectedHealthCarePlan.billingStatements
				.filter((bs) => !bs.billing)
				.reduce((total, cur) => {
					total = total.plus(cur.charge).minus(cur.adjustment)
					return total
				}, Utils.BigNumber(0))
				.toNumber()
		)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_DISPLAY, false)
		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onPay(event) {
		this.props.onModalDisplayAction(SET_CASHIER_PAYMENT_DISPLAY, true)
	}

	onPrintReceipt() {
		this.props.cashier.selectedHealthCarePlan.healthCarePlan.billings.forEach((billing) => {
			let data = PrintableDataFactory.generateHealthCarePlanReceiptData(
				this.props.cashier.patient,
				this.props.cashier.selectedAdmission.admission,
				this.props.cashier.selectedHealthCarePlan.healthCarePlan,
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements.filter((bs) => bs.billing && bs.billing.id === billing.id),
				billing,
				this.props.login.user,
				Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
			)

			let myWindow = window.open(ResourceAssistance.Path.Report.cashier.healthCarePlanReceipt, "_blank")
			myWindow.data = data
		})
	}

	onPrintCategoryReceipt() {
		this.props.cashier.selectedHealthCarePlan.healthCarePlan.billings.forEach((billing) => {
			let data = PrintableDataFactory.generateHealthCarePlanReceiptCategoryData(
				this.props.cashier.patient,
				this.props.cashier.selectedAdmission.admission,
				this.props.cashier.selectedHealthCarePlan.healthCarePlan,
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements.filter((bs) => bs.billing && bs.billing.id === billing.id),
				billing,
				this.props.login.user,
				Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
			)

			let myWindow = window.open(ResourceAssistance.Path.Report.cashier.healthCarePlanCategoryReceipt, "_blank")
			myWindow.data = data
		})
	}

	render() {
		return (
			<fieldset style={{ display: "flex", flexDirection: "column" }}>
				<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
				<Row className={"full-size"}>
					<Col>
						<ScrollableTable
							enableHighlight
							striped
							hover
							displayBtn
							buttonColors={[
								[ResourceAssistance.Button.variant.outlineRed],
								[],
								[],
								[],
								[],
								[],
								[],
								[],
								[
									ResourceAssistance.Button.variant.outlinePrimary,
									ResourceAssistance.Button.variant.outlinePrimary,
									ResourceAssistance.Button.variant.outlinePrimary,
									ResourceAssistance.Button.variant.outlineInfo,
								],
							]}
							btnIcons={[
								[this.isDisplayWarning() ? <WarningOutlined style={{ fontSize: ResourceAssistance.ReactIcon.size }} /> : undefined],
								[],
								[],
								[],
								[],
								[],
								[],
								[],
								[
									this.isDisplayHealthCarePlanIcon() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
									this.isDisplayIcon() ? <TbDiscount2 size={ResourceAssistance.ReactIcon.size} /> : undefined,
									this.isDisplayPayIcon() ? <GiPayMoney size={ResourceAssistance.ReactIcon.size} /> : undefined,
									this.isDisplayPrintIcon() ? <ImPrinter size={ResourceAssistance.ReactIcon.size} /> : undefined,
								],
							]}
							btnTypes={[[EBtnType.POPOVER], [], [], [], [], [], [], [], [EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DROP_DOWN]]}
							btnMessages={[
								[""],
								[],
								[],
								[],
								[],
								[],
								[],
								[],
								[
									this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
									this.props.intl.formatMessage({ id: ResourceAssistance.Message.discount }),
									this.props.intl.formatMessage({ id: ResourceAssistance.Message.pay }),
									this.props.intl.formatMessage({ id: ResourceAssistance.Message.print }),
								],
							]}
							dropdownItems={[
								<DropdownItem key={"cashier_health_care_plan_receipt"} onClick={this.onPrintReceipt}>
									{translate(ResourceAssistance.Message.receipt)}
								</DropdownItem>,
								<DropdownItem key={"cashier_health_care_plan_receipt_category"} onClick={this.onPrintCategoryReceipt}>
									{translate(ResourceAssistance.Message.receiptCategory)}
								</DropdownItem>,
							]}
							onCheckedArray={[[], [], [], [], [], [], [], [], [this.onEdit, this.onDiscount, this.onPay]]}
							data={this.props.cashier.healthCarePlanTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.cashier.selectedHealthCarePlan.index === -1}
							highlightedRow={this.props.cashier.selectedHealthCarePlan.index}
							extra={
								this.isDisplayHealthCarePlanIcon() && (
									<Button
										style={{ fontSize: "medium" }}
										variant={ResourceAssistance.Button.variant.link}
										size={ResourceAssistance.Button.size.sm}
										onClick={this.onFinancialPlan}
									>
										<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.financialPlan)}
									</Button>
								)
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<ScrollableTable style={{ minHeight: "50px" }} data={this.props.cashier.balanceTable} />
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	cashier: state.hospitel.cashier,
	common: state.common
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setCashierAdmissionBalance,
			setCashierAdmissions,
			setCashierHealthCarePlans,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierRightHealthCarePlan))
