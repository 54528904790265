import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setObjArray, setOPDPatients } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_CUSTOM_DEFAULT_CATEGORIES, SET_OPD_NURSE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OPDModalNurseDetails from "./OPDModalNurseDetails"

class OPDModalNurse extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalNurse",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		this.loadCustomDefaultCategories()
	}

	reset() {
		this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.opd.selectedNurseNote.nurseNote, {
			...this.props.opd.selectedNurseNote.nurseNote,
			dateTime: this.props.opd.modalNurse.recordDateTime,
			focus: Utils.trim(this.props.opd.modalNurse.focus),
			assessment: Utils.trim(this.props.opd.modalNurse.assessment),
			intervention: Utils.trim(this.props.opd.modalNurse.intervention),
			evaluation: Utils.trim(this.props.opd.modalNurse.evaluation),
		})
		return _.isEqual(target, this.props.opd.selectedNurseNote.nurseNote)
	}

	loadCustomDefaultCategories() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getCustomDefaultCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: true },
		}
		let callback = (res) => {
			this.props.setObjArray(SET_OPD_MODAL_NURSE_CUSTOM_DEFAULT_CATEGORIES, res.data.types)
		}
		axios(params, callback)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveNurseNote,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				nurseNotes: [
					{
						id: this.props.opd.selectedNurseNote.nurseNote ? this.props.opd.selectedNurseNote.nurseNote.id : 0,
						dateTime: this.props.opd.modalNurse.recordDateTime,
						focus: Utils.trim(this.props.opd.modalNurse.focus),
						assessment: Utils.trim(this.props.opd.modalNurse.assessment),
						assessmentNote: Utils.trim(this.props.opd.modalNurse.assessmentOther),
						intervention: Utils.trim(this.props.opd.modalNurse.intervention),
						evaluation: Utils.trim(this.props.opd.modalNurse.evaluation),
					},
				],
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.nurse)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<OPDModalNurseDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button type={ResourceAssistance.Button.type.submit} variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientNurseDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setObjArray,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurse))
