import { Button, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { GrAdd } from "react-icons/gr"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER,
	SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT,
	SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER,
	SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS,
	SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT,
	SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT,
	SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE,
	SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

const variables = [
	{
		displayName: "Number of Transactions",
		value: ResourceAssistance.PROGRAM_DEFINED.num_trans,
	},
	{
		displayName: "Total Amount",
		value: ResourceAssistance.PROGRAM_DEFINED.total_amount,
	},
	{
		displayName: "Effective Date",
		value: ResourceAssistance.PROGRAM_DEFINED.effective_date,
	},
	{
		displayName: "Receiving Bank Code",
		value: ResourceAssistance.PROGRAM_DEFINED.receiving_bank_code,
	},
	{
		displayName: "Receiving Branch Code",
		value: ResourceAssistance.PROGRAM_DEFINED.receiving_branch_code,
	},
	{
		displayName: "Receiving A/C",
		value: ResourceAssistance.PROGRAM_DEFINED.receiving_account,
	},
	{
		displayName: "Receiver Name",
		value: ResourceAssistance.PROGRAM_DEFINED.receiver_name,
	},
	{
		displayName: "Sending Bank Code",
		value: ResourceAssistance.PROGRAM_DEFINED.sending_bank_code,
	},
	{
		displayName: "Sending Branch Code",
		value: ResourceAssistance.PROGRAM_DEFINED.sending_branch_code,
	},
	{
		displayName: "Sending A/C",
		value: ResourceAssistance.PROGRAM_DEFINED.sending_account,
	},
	{
		displayName: "Sender Name",
		value: ResourceAssistance.PROGRAM_DEFINED.sender_name,
	},
	{
		displayName: "Service Type",
		value: ResourceAssistance.PROGRAM_DEFINED.service_type,
	},
	{
		displayName: "Email",
		value: ResourceAssistance.PROGRAM_DEFINED.email,
	},
]

class TypeModalNewBankField extends React.Component {
	constructor(props) {
		super(props)

		this.onDisplayFormat = this.onDisplayFormat.bind(this)
		this.onBatchVariable = this.onBatchVariable.bind(this)
		this.onDetailVariable = this.onDetailVariable.bind(this)
		this.onAddBatchVariable = this.onAddBatchVariable.bind(this)
		this.onAddDetailVariable = this.onAddDetailVariable.bind(this)
		this.onBatchMaxCharacters = this.onBatchMaxCharacters.bind(this)
		this.onDetailMaxCharacters = this.onDetailMaxCharacters.bind(this)
		this.onBatchRecordFormat = this.onBatchRecordFormat.bind(this)
		this.onDetailRecordFormat = this.onDetailRecordFormat.bind(this)
		this.onBatchEmptyCharacter = this.onBatchEmptyCharacter.bind(this)
		this.onBatchMaxEmptyCharacters = this.onBatchMaxEmptyCharacters.bind(this)
		this.onAddBatchEmptyCharacters = this.onAddBatchEmptyCharacters.bind(this)
		this.onDetailEmptyCharacter = this.onDetailEmptyCharacter.bind(this)
		this.onDetailMaxEmptyCharacters = this.onDetailMaxEmptyCharacters.bind(this)
		this.onAddDetailEmptyCharacters = this.onAddDetailEmptyCharacters.bind(this)
	}

	componentDidMount() {
		if (this.props.type.selectedType.type) {
			let selectedType = this.props.type.selectedType.type
			this.props.setValue(SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT, selectedType.internetPayFormatAvailable)
			this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT, selectedType.ipayBatchFormat)
			this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT, selectedType.ipayDetailFormat)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.type.bank.modalBank.isDisplayFormat !== this.props.type.bank.modalBank.isDisplayFormat) {
			if (!this.props.type.bank.modalBank.isDisplayFormat) {
				this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT, "")
				this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT, false)
		this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE, -1, null)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT, "")
		this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE, -1, null)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT, "")
	}

	onDisplayFormat(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DISPLAY_FORMAT, e.target.checked)
	}

	onBatchVariable(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE, -1, null)
			return
		}
		this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE, value, variables[value])
	}

	onBatchMaxCharacters(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS, e.target.value)
	}

	onAddBatchVariable() {
		let format = this.props.bank.modalBank.batchRecordFormat
		format = format.concat(
			this.props.bank.modalBank.selectedBatchVariable.variable.value,
			ResourceAssistance.PROGRAM_DEFINED.split,
			this.props.bank.modalBank.batchMaxCharacters,
			ResourceAssistance.PROGRAM_DEFINED.split
		)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT, format)
		this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_BATCH_VARIABLE, -1, null)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_MAX_CHARACTERS, "")
	}

	onBatchEmptyCharacter(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER, e.target.value)
	}

	onBatchMaxEmptyCharacters(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS, e.target.value)
	}

	onAddBatchEmptyCharacters() {
		let format = this.props.bank.modalBank.batchRecordFormat
		format = format.padEnd(
			Utils.BigNumber(this.props.bank.modalBank.batchMaxEmptyCharacters).plus(format.length).toNumber(),
			this.props.bank.modalBank.batchEmptyCharacter
		)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT, format)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_EMPTY_CHARACTER, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_MAX_EMPTY_CHARACTERS, "")
	}

	onBatchRecordFormat(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_BATCH_RECORD_FORMAT, e.target.value)
	}

	onDetailVariable(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE, -1, null)
			return
		}
		this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE, value, variables[value])
	}

	onDetailMaxCharacters(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS, e.target.value)
	}

	onAddDetailVariable() {
		let format = this.props.bank.modalBank.detailRecordFormat
		format = format.concat(
			this.props.bank.modalBank.selectedDetailVariable.variable.value,
			ResourceAssistance.PROGRAM_DEFINED.split,
			this.props.bank.modalBank.detailMaxCharacters,
			ResourceAssistance.PROGRAM_DEFINED.split
		)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT, format)
		this.props.setSelected(SET_ADM_BANK_MODAL_NEW_SELECTED_DETAIL_VARIABLE, -1, null)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_CHARACTERS, "")
	}

	onDetailEmptyCharacter(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER, e.target.value)
	}

	onDetailMaxEmptyCharacters(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS, e.target.value)
	}

	onAddDetailEmptyCharacters() {
		let format = this.props.bank.modalBank.detailRecordFormat
		format = format.padEnd(
			Utils.BigNumber(this.props.bank.modalBank.detailMaxEmptyCharacters).plus(format.length).toNumber(),
			this.props.bank.modalBank.detailEmptyCharacter
		)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT, format)
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_EMPTY_CHARACTER, "")
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_MAX_EMPTY_CHARACTERS, "")
	}

	onDetailRecordFormat(e) {
		this.props.setValue(SET_ADM_BANK_MODAL_NEW_DETAIL_RECORD_FORMAT, e.target.value)
	}

	render() {
		return (
			<>
				{this.props.type.modal.displayIPayFormat && (
					<Row>
						<Col />
						<Col>
							<label>{translate(ResourceAssistance.Message.iPayFormat)}</label>
						</Col>
						<Col>
							<ToggleCheckBox onClick={this.onDisplayFormat} checked={this.props.type.bank.modalBank.isDisplayFormat} />
						</Col>
						<Col />
					</Row>
				)}
				{this.props.type.bank.modalBank.isDisplayFormat && (
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.iPayFormat)}</legend>
						<Row className="g-2">
							<Col style={{ justifyContent: "end" }}>
								<Select
									showSearch
									allowClear
									size={"large"}
									style={{ textAlign: "center", margin: 0 }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
									placeholder={translate(ResourceAssistance.Message.variable)}
									value={this.props.bank.modalBank.selectedBatchVariable.variable ? this.props.bank.modalBank.selectedBatchVariable.index : undefined}
									onChange={this.onBatchVariable}
								>
									{Utils.renderSelects(variables, false)}
								</Select>
							</Col>
							<Col>
								<GInput
									required
									type={ResourceAssistance.FormControl.type.number}
									style={{ textAlign: "center" }}
									pattern="^\d+$"
									placeholder={translate(ResourceAssistance.Message.maxCharacters)}
									disabled={!this.props.bank.modalBank.selectedBatchVariable.variable}
									value={this.props.bank.modalBank.batchMaxCharacters}
									onChange={this.onBatchMaxCharacters}
								/>
							</Col>
							<Col md="auto" style={{ justifyContent: "end" }}>
								<Col md="auto">
									<Button
										title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.add })}
										type="text"
										size="middle"
										shape="circle"
										icon={<GrAdd size={ResourceAssistance.ReactIcon.size} />}
										disabled={!this.props.bank.modalBank.selectedBatchVariable.variable || _.isEmpty(this.props.bank.modalBank.batchMaxCharacters)}
										onClick={this.onAddBatchVariable}
									/>
								</Col>
							</Col>
						</Row>
						<Row className="g-2">
							<Col>
								<GInput
									style={{ textAlign: "center" }}
									placeholder={translate(ResourceAssistance.Message.fillWithCharacter)}
									value={this.props.bank.modalBank.batchEmptyCharacter}
									onChange={this.onBatchEmptyCharacter}
								/>
							</Col>
							<Col>
								<GInput
									style={{ textAlign: "center" }}
									type={ResourceAssistance.FormControl.type.number}
									placeholder={translate(ResourceAssistance.Message.number)}
									value={this.props.bank.modalBank.batchMaxEmptyCharacters}
									onChange={this.onBatchMaxEmptyCharacters}
								/>
							</Col>
							<Col md="auto" style={{ justifyContent: "end" }}>
								<Col md="auto">
									<Button
										title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.add })}
										type="text"
										size="middle"
										shape="circle"
										icon={<GrAdd size={ResourceAssistance.ReactIcon.size} />}
										disabled={_.isEmpty(this.props.bank.modalBank.batchEmptyCharacter) || _.isEmpty(this.props.bank.modalBank.batchMaxEmptyCharacters)}
										onClick={this.onAddBatchEmptyCharacters}
									/>
								</Col>
							</Col>
						</Row>
						<Row style={{ marginBottom: "15px" }}>
							<Col>
								<GInput
									required
									autoSize
									showCount
									placeholder={translate(ResourceAssistance.Message.batchRecordFormat)}
									minRows={10}
									value={this.props.bank.modalBank.batchRecordFormat}
									onChange={this.onBatchRecordFormat}
								/>
							</Col>
						</Row>

						{/* Detail Record */}
						<Row className="g-2">
							<Col style={{ justifyContent: "end" }}>
								<Select
									showSearch
									allowClear
									size={"large"}
									style={{ textAlign: "center", margin: 0 }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
									placeholder={translate(ResourceAssistance.Message.variable)}
									value={this.props.bank.modalBank.selectedDetailVariable.variable ? this.props.bank.modalBank.selectedDetailVariable.index : undefined}
									onChange={this.onDetailVariable}
								>
									{Utils.renderSelects(variables, false)}
								</Select>
							</Col>
							<Col>
								<GInput
									required
									type={ResourceAssistance.FormControl.type.number}
									pattern="^\d+$"
									placeholder={translate(ResourceAssistance.Message.maxCharacters)}
									disabled={!this.props.bank.modalBank.selectedDetailVariable.variable}
									value={this.props.bank.modalBank.detailMaxCharacters}
									onChange={this.onDetailMaxCharacters}
								/>
							</Col>
							<Col md="auto" style={{ justifyContent: "end" }}>
								<Col md="auto">
									<Button
										title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.add })}
										type="text"
										size="middle"
										shape="circle"
										icon={<GrAdd size={ResourceAssistance.ReactIcon.size} />}
										disabled={!this.props.bank.modalBank.selectedDetailVariable.variable || _.isEmpty(this.props.bank.modalBank.detailMaxCharacters)}
										onClick={this.onAddDetailVariable}
									/>
								</Col>
							</Col>
						</Row>
						<Row className="g-2">
							<Col>
								<GInput
									style={{ textAlign: "center" }}
									placeholder={translate(ResourceAssistance.Message.fillWithCharacter)}
									value={this.props.bank.modalBank.detailEmptyCharacter}
									onChange={this.onDetailEmptyCharacter}
								/>
							</Col>
							<Col>
								<GInput
									style={{ textAlign: "center" }}
									type={ResourceAssistance.FormControl.type.number}
									placeholder={translate(ResourceAssistance.Message.number)}
									value={this.props.bank.modalBank.detailMaxEmptyCharacters}
									onChange={this.onDetailMaxEmptyCharacters}
								/>
							</Col>
							<Col md="auto" style={{ justifyContent: "end" }}>
								<Col md="auto">
									<Button
										title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.add })}
										type="text"
										size="middle"
										shape="circle"
										icon={<GrAdd size={ResourceAssistance.ReactIcon.size} />}
										disabled={_.isEmpty(this.props.bank.modalBank.detailEmptyCharacter) || _.isEmpty(this.props.bank.modalBank.detailMaxEmptyCharacters)}
										onClick={this.onAddDetailEmptyCharacters}
									/>
								</Col>
							</Col>
						</Row>
						<Row style={{ paddingBottom: "20px" }}>
							<Col>
								<GInput
									required
									autoSize
									showCount
									minRows={10}
									placeholder={translate(ResourceAssistance.Message.detailRecordFormat)}
									value={this.props.bank.modalBank.detailRecordFormat}
									onChange={this.onDetailRecordFormat}
								/>
							</Col>
						</Row>
					</fieldset>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	bank: state.admin.type.bank,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewBankField)
