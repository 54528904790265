import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setConfigGLPayableActivities, setLoadingAction, setValue } from "~/redux/action"
import { SET_ADM_GL_PAYABLE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import ConfigGLModalPayableDetails from "./ConfigGLModalPayableDetails"
import { Utils } from "~/utils/Utils"

class ConfigGLModalPayable extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_GL_PAYABLE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.AP.modalAP.selectedAP.AP ||
			(!this.props.AP.modalAP.isIncludeBalance &&
				!this.props.AP.modalAP.isIncludeDiscount &&
				!this.props.AP.modalAP.isIncludeTax &&
				!this.props.AP.modalAP.isIncludeWithholdingTax &&
				!this.props.AP.modalAP.isIncludeTransactionFee &&
				!this.props.AP.modalAP.isIncludeBalanceAdjustment)
		) {
			return true
		}
		if (this.props.AP.selectedAP.AP) {
			let target = Object.assign({}, this.props.AP.selectedAP.AP, {
				...this.props.AP.selectedAP.AP,
				chartOfAccounts: {
					...this.props.AP.selectedAP.AP.chartOfAccounts,
					id: this.props.AP.modalAP.selectedAP.AP ? this.props.AP.modalAP.selectedAP.AP.id : 0,
				},
				includeBalance: this.props.AP.modalAP.isIncludeBalance,
				includeDiscount: this.props.AP.modalAP.isIncludeDiscount,
				includeTax: this.props.AP.modalAP.isIncludeTax,
				includeWithholdingTax: this.props.AP.modalAP.isIncludeWithholdingTax,
				includeTransactionFee: this.props.AP.modalAP.isIncludeTransactionFee,
				includeBalanceAdjustment: this.props.AP.modalAP.isIncludeBalanceAdjustment,
				excludeWorkflowPaymentSlip: this.props.AP.modalAP.isExcludeWorkflowPaymentSlip,
				debit: this.props.AP.modalAP.isDebit,
				description: Utils.trim(
					this.props.AP.modalAP.description.concat(
						" ",
						this.props.AP.modalAP.isDescriptionIncludesSupplier ? ResourceAssistance.PROGRAM_DEFINED.supplier : "",
						" ",
						this.props.AP.modalAP.isDescriptionIncludesDate ? ResourceAssistance.PROGRAM_DEFINED.date : ""
					)
				),
				accountsPayableRCSelectable: this.props.AP.modalAP.isAccountsPayableRCSelectable,
			})
			return _.isEqual(target, this.props.AP.selectedAP.AP)
		}
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.generalLedger.saveAccountsPayableActivity,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				activities: [
					{
						id: this.props.AP.selectedAP.AP ? this.props.AP.selectedAP.AP.id : 0,
						chartOfAccountsId: this.props.AP.modalAP.selectedAP.AP.id,
						includeBalance: this.props.AP.modalAP.isIncludeBalance,
						includeDiscount: this.props.AP.modalAP.isIncludeDiscount,
						includeTax: this.props.AP.modalAP.isIncludeTax,
						includeWithholdingTax: this.props.AP.modalAP.isIncludeWithholdingTax,
						includeTransactionFee: this.props.AP.modalAP.isIncludeTransactionFee,
						includeBalanceAdjustment: this.props.AP.modalAP.isIncludeBalanceAdjustment,
						excludeWorkflowPaymentSlip: this.props.AP.modalAP.isExcludeWorkflowPaymentSlip,
						debit: this.props.AP.modalAP.isDebit,
						description: Utils.trim(
							this.props.AP.modalAP.description.concat(
								" ",
								this.props.AP.modalAP.isDescriptionIncludesSupplier ? ResourceAssistance.PROGRAM_DEFINED.supplier : "",
								" ",
								this.props.AP.modalAP.isDescriptionIncludesDate ? ResourceAssistance.PROGRAM_DEFINED.date : ""
							)
						),
						accountsPayableReceiveSelectable: this.props.AP.modalAP.isAccountsPayableRCSelectable,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setConfigGLPayableActivities(res.data.activities)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.md}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsPayable)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ConfigGLModalPayableDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isConfigGLPayableDisplay,
	login: state.login,
	AP: state.admin.configGL.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
			setConfigGLPayableActivities,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGLModalPayable))
