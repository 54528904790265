import { Divider } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setConfigICDLevels, setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_LEVEL_DISPLAY_ALL_IND,
	SET_ADM_LEVEL_LEVELS_LOADING,
	SET_ADM_LEVEL_SEARCH_NAME,
	SET_ADM_LEVEL_SELECTED_LEVEL,
	SET_CONFIG_LEVEL_NEW_DISPLAY,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigLevel extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigLevel",
		}

		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadLevels(
			ResourceAssistance.Url.admin.icd.getLevels,
			(res) => {
				this.props.setConfigICDLevels(res.data.types)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_LEVEL_LEVELS_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_LEVEL_LEVELS_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_LEVEL_LEVELS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setConfigICDLevels([])
		this.props.setSelected(SET_ADM_LEVEL_SELECTED_LEVEL, -1, null)
		this.props.setValue(SET_ADM_LEVEL_SEARCH_NAME, "")
		this.props.setValue(SET_ADM_LEVEL_DISPLAY_ALL_IND, true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.level.searchName !== this.props.level.searchName ||
			prevProps.level.displayInactive !== this.props.level.displayInactive
		) {
			this.props.setConfigICDLevels(this.props.level.levelTable.original)
		}
		if (!_.isEqual(prevProps.level.levelTable.filtered, this.props.level.levelTable.filtered)) {
			if (this.props.level.selectedLevel.level) {
				let idx = this.props.level.levelTable.filtered.findIndex(
					(each) => each.id === this.props.level.selectedLevel.level.id
				)
				if (idx > -1) {
					this.props.setSelected(SET_ADM_LEVEL_SELECTED_LEVEL, idx, this.props.level.levelTable.filtered[idx])
				} else {
					this.props.setSelected(SET_ADM_LEVEL_SELECTED_LEVEL, -1, null)
				}
			} else {
				this.props.setSelected(SET_ADM_LEVEL_SELECTED_LEVEL, -1, null)
			}
		}
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_LEVEL_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setValue(SET_ADM_LEVEL_DISPLAY_ALL_IND, event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADM_LEVEL_SELECTED_LEVEL, index, this.props.level.levelTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADM_LEVEL_SELECTED_LEVEL, -1, null)
		this.props.onModalDisplayAction(SET_CONFIG_LEVEL_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_CONFIG_LEVEL_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.level.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.level.selectedLevel.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							data={this.props.level.levelTable}
							isClearHighlight={this.props.level.selectedLevel.index === -1}
							highlightedRow={this.props.level.selectedLevel.index}
							onClick={this.onSelectRow}
						/>
						{this.props.level.levelTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	level: state.admin.configICD.level,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
			setLoadingAction,
			onModalDisplayAction,
			setConfigICDLevels,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigLevel)
