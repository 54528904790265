import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setIPDPatients, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import IPDRightHealthCareRow from "./IPDRightHealthCareRow"
import IPDRightHealthRow from "./IPDRightHealthRow"
import IPDRightInfoRow from "./IPDRightInfoRow"
import RegisterRightReference from "./RegisterRightReference"
import RegistrationRightRelative from "./RegistrationRightRelative"

class IPDRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRight",
		}

		this.uploadPatientFile = this.uploadPatientFile.bind(this)
		this.uploadAdmissionFile = this.uploadAdmissionFile.bind(this)
	}

	uploadFile = (url, formData) => {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "multipart/form-data",
			},
			data: formData,
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setIPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	uploadPatientFile = (url, file) => {
		let patient = this.props.ipd.selectedPatient.patient
		const formData = new FormData()
		formData.append("file", file)
		formData.append(
			"name",
			this.props.ipd.selectedPatient.patient.id +
				"_" +
				this.props.ipd.selectedPatient.patient.firstName +
				"_" +
				this.props.ipd.selectedPatient.patient.lastName
		)
		formData.append("description", file.name)
		formData.append("patientId", patient.id)
		formData.append("userId", this.props.login.user.id)
		this.uploadFile(url, formData)
	}

	uploadAdmissionFile = (url, file) => {
		const formData = new FormData()
		formData.append("file", file)
		formData.append(
			"name",
			this.props.ipd.selectedPatient.patient.id +
				"_" +
				this.props.ipd.selectedPatient.patient.firstName +
				"_" +
				this.props.ipd.selectedPatient.patient.lastName +
				"_" +
				this.props.ipd.selectedAdmission.admission.id
		)
		formData.append("description", file.name)
		formData.append("patientId", this.props.ipd.selectedPatient.patient.id)
		formData.append("admissionId", this.props.ipd.selectedAdmission.admission.id)
		formData.append("userId", this.props.login.user.id)
		this.uploadFile(url, formData)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<IPDRightInfoRow uploadPatientFile={this.uploadPatientFile} />
				<RegistrationRightRelative />
				<IPDRightHealthRow />
				<IPDRightHealthCareRow uploadAdmissionFile={this.uploadAdmissionFile} />
				<RegisterRightReference uploadAdmissionFile={this.uploadAdmissionFile} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setIPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDRight)
