import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_SA_FILTERS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class SurgicalAuthLeftFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onChangeFilter = this.onChangeFilter.bind(this)
		this.state = {
			CSurgicalAuthFilters: [
				{
					id: ResourceAssistance.Message.approved,
					displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.approved }),
				},
				{
					id: ResourceAssistance.Message.unapproved,
					displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.unapproved }),
				},
			],
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.language.locale, this.props.language.locale)) {
			this.setState({
				CSurgicalAuthFilters: [
					{
						id: ResourceAssistance.Message.approved,
						displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.approved }),
					},
					{
						id: ResourceAssistance.Message.unapproved,
						displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.unapproved }),
					},
				],
			})
		}
	}

	getFilters() {
		let filters = []
		this.props.surgicalAuth.filters.forEach((each) => {
			this.state.CSurgicalAuthFilters.forEach((filter, idx) => {
				if (filter.id === each) {
					filters.push(idx)
				}
			})
		})
		return filters
	}

	onChangeFilter(values) {
		let filters = values.map((each) => this.state.CSurgicalAuthFilters[each].id)
		this.props.setValue(SET_SA_FILTERS, filters)
	}

	render() {
		return (
			<Row className="g-2">
				<Col>
					<Select
						mode={ResourceAssistance.Select.mode.multiple}
						allowClear
						style={{ flexGrow: 1, textAlign: "center" }}
						maxTagCount="responsive"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.filter)}
						onChange={this.onChangeFilter}
						value={this.getFilters()}
					>
						{Utils.renderSelects(this.state.CSurgicalAuthFilters, false)}
					</Select>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	language: state.language,
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SurgicalAuthLeftFilter))
