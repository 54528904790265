import * as React from "react"

function SvgSvgDoctor(props) {
	return (
		<svg
			stroke="currentColor"
			fill="currentColor"
			strokeWidth="0"
			viewBox="0, 0, 400,400"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M167.578.467c-1.719.161-7.519.659-12.89 1.106-42.144 3.509-71.416 14.944-76.94 30.058-.973 2.662-15.415 136.46-16.36 151.572-.536 8.563-2.688 15.012-6.842 20.5-17.393 22.979-7.347 59.119 19.349 69.605l3.695 1.452 3.812 9.299c35.006 85.383 82.579 125.583 135.415 114.428 39.404-8.32 75.175-48.552 101.743-114.432 3.587-8.895 3.841-9.281 6.84-10.438 27.392-10.566 38.182-50.516 19.293-71.424-4.123-4.563-4.763-8.932-13.438-91.646-4.326-41.25-8.205-76.264-8.62-77.809-4.016-14.937-29.735-25.853-72.712-30.86C240.078.731 176.413-.36 167.578.467m73.828 32.303c20.374 2.016 37.268 5.575 46.877 9.877l4.298 1.924 6.266 60.136c4.01 38.473 5.972 60.137 5.448 60.137-.45 0-5.732-.855-11.737-1.899-69.358-12.061-115.758-12.061-185.116 0-6.005 1.044-11.287 1.899-11.737 1.899-.524 0 1.438-21.664 5.448-60.137l6.266-60.136 4.298-1.904c12.929-5.727 28.455-8.432 62.892-10.957 7.552-.554 58.687.258 66.797 1.06m-57.031 28.558v15.235h-31.25v31.25h31.25v31.25h31.25v-31.25h31.25v-31.25h-31.25V46.094h-31.25v15.234m48.438 125.381c13.501 1.36 32.383 3.824 46.289 6.04l13.086 2.086v25.56l11.914-.201c14.034-.237 16.366.472 17.732 5.393 2.972 10.701-2.931 18.193-16.18 20.538-6.874 1.216-5.975.093-11.869 14.813-31.879 79.613-65.762 114.408-103.779 106.571-30.259-6.238-57.227-40.532-83.807-106.571-5.753-14.293-5.389-13.734-9.357-14.368-13.346-2.134-19.422-7.103-19.481-15.931-.056-8.401 3.611-10.479 19.319-10.949l11.133-.333v-12.264l-.001-12.265 13.477-2.134c17.301-2.74 34.45-4.901 47.161-5.943 5.536-.453 11.472-.964 13.191-1.134 5.568-.551 42.731.242 51.172 1.092"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default SvgSvgDoctor
