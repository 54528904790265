import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { MdDeleteForever } from "react-icons/md"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE,
	SET_MODAL_WARNING_SIZE,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalAdjustmentServiceDetails from "./HospitelModalAdjustmentServiceDetails"

class HospitelModalAdjustmentService extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalAdjustmentService",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.modalAdjustmentService.delete)
			) {
				this.deleteNurseService()
			}
		}
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE, null)
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return false
	}

	deleteNurseService() {
		window.adjustmentServiceCaller.adjustmentServiceDeleteCallback()
		window.adjustmentServiceCaller = null
		this.reset()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		window.adjustmentServiceCaller.adjustmentServiceCallback()
		window.adjustmentServiceCaller = null
		this.reset()
	}

	onDelete(event) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.modalAdjustmentService.delete)
		this.props.setValue(SET_MODAL_WARNING_SIZE, undefined)
		this.props.setWarningMsgAction(
			this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }) +
				"\n" +
				this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.service }) +
				": " +
				this.props.hospitel.modalAdjustmentService.service.description +
				"\n" +
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate }) +
				": " +
				Utils.formatDate(this.props.hospitel.modalAdjustmentService.service.startDateTime) +
				"\n" +
				this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate }) +
				": " +
				Utils.formatDate(this.props.hospitel.modalAdjustmentService.service.endDateTime)
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							{this.props.hospitel.modalAdjustmentService.service &&
							this.props.hospitel.modalAdjustmentService.service.name
								? this.props.hospitel.modalAdjustmentService.service.name
								: this.props.hospitel.modalAdjustmentService.service &&
								  this.props.hospitel.modalAdjustmentService.service.description
								? this.props.hospitel.modalAdjustmentService.service.description
								: ""}
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalAdjustmentServiceDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						{this.props.hospitel.modalAdjustmentService.service &&
							this.props.hospitel.modalAdjustmentService.service.startDateTime && (
								<Button variant={ResourceAssistance.Button.variant.red} onClick={this.onDelete}>
									<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.delete)}
								</Button>
							)}
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.FormControl.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isAdjustmentServiceDisplay,
	warning: state.modal.warning,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setWarningId,
			setWarningMsgAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalAdjustmentService))
