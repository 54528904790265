import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalMedicalCertificateSymptomRow extends React.Component {
	constructor(props) {
		super(props)

		this.onCovidTestSymptom = this.onCovidTestSymptom.bind(this)
		this.onSymptoms = this.onSymptoms.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedCertificate.certificate) {
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS,
				this.props.opd.selectedCertificate.certificate.symptoms
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(
			SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS,
			ResourceAssistance.CONSTANT.REQUEST_COVID19_MEDICAL_CERTIFICATE
		)
	}

	modifySymptoms(symptom, isAdd) {
		let value = this.props.opd.modalCertificate.symptom
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : " ", symptom)
		} else {
			value = value.replace(symptom, "")
		}
		value = Utils.replaceDuplicateSpaces(value)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS, Utils.trim(value))
	}

	onCovidTestSymptom(event) {
		this.modifySymptoms(event.target.value, event.target.checked)
	}

	onSymptoms(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.symptoms)}</legend>
				<Row>
					<Col />
					<Col md={"auto"}>
						<FormGroup controlId={"covidTest"}>
							<FormCheck
								label={translate(ResourceAssistance.Hospitel.requestCovid19MedicalCertificate)}
								checked={_.includes(
									this.props.opd.modalCertificate.symptom,
									ResourceAssistance.CONSTANT.REQUEST_COVID19_MEDICAL_CERTIFICATE
								)}
								onChange={this.onCovidTestSymptom}
								value={ResourceAssistance.CONSTANT.REQUEST_COVID19_MEDICAL_CERTIFICATE}
							/>
						</FormGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={2}
							value={this.props.opd.modalCertificate.symptom}
							onChange={this.onSymptoms}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateSymptomRow)
