import { Radio } from "antd"
import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class PreOperativeCheckList extends React.Component {
	constructor(props) {
		super(props)

		this.state = this.initialState()

		this.onIdentifyPatient = this.onIdentifyPatient.bind(this)
		this.onIdentifyPatientRemark = this.onIdentifyPatientRemark.bind(this)
		this.onMarkingSiteForSurgery = this.onMarkingSiteForSurgery.bind(this)
		this.onMarkingSiteForSurgeryRemark = this.onMarkingSiteForSurgeryRemark.bind(this)
		this.onLetterConsentSurgery = this.onLetterConsentSurgery.bind(this)
		this.onLetterConsentSurgeryRemark = this.onLetterConsentSurgeryRemark.bind(this)
		this.onLetterConsentAnesthesia = this.onLetterConsentAnesthesia.bind(this)
		this.onLetterConsentAnesthesiaRemark = this.onLetterConsentAnesthesiaRemark.bind(this)
		this.onLabResult = this.onLabResult.bind(this)
		this.onLabResultRemark = this.onLabResultRemark.bind(this)
		this.onImagingResult = this.onImagingResult.bind(this)
		this.onImagingResultRemark = this.onImagingResultRemark.bind(this)
		this.onEKGResult = this.onEKGResult.bind(this)
		this.onEKGResultRemark = this.onEKGResultRemark.bind(this)
		this.onCleanPatientBody = this.onCleanPatientBody.bind(this)
		this.onCleanPatientBodyRemark = this.onCleanPatientBodyRemark.bind(this)
		this.onUnisonEnema = this.onUnisonEnema.bind(this)
		this.onUnisonEnemaRemark = this.onUnisonEnemaRemark.bind(this)
		this.onNPOTime = this.onNPOTime.bind(this)
		this.onNPOTimeRemark = this.onNPOTimeRemark.bind(this)
		this.onRemoveDentures = this.onRemoveDentures.bind(this)
		this.onRemoveDenturesRemark = this.onRemoveDenturesRemark.bind(this)
		this.onEyeglassesRemoved = this.onEyeglassesRemoved.bind(this)
		this.onEyeglassesRemovedRemark = this.onEyeglassesRemovedRemark.bind(this)
		this.onJewelryRemoved = this.onJewelryRemoved.bind(this)
		this.onJewelryRemovedRemark = this.onJewelryRemovedRemark.bind(this)
		this.onBodyPiercingRemoved = this.onBodyPiercingRemoved.bind(this)
		this.onBodyPiercingRemovedRemark = this.onBodyPiercingRemovedRemark.bind(this)
		this.onChangePatientClothes = this.onChangePatientClothes.bind(this)
		this.onChangePatientClothesRemark = this.onChangePatientClothesRemark.bind(this)
		this.onPatientUrinate = this.onPatientUrinate.bind(this)
		this.onPatientUrinateRemark = this.onPatientUrinateRemark.bind(this)
		this.onBloodRequested = this.onBloodRequested.bind(this)
		this.onBloodRequestedRemark = this.onBloodRequestedRemark.bind(this)
		this.onPreEducation = this.onPreEducation.bind(this)
		this.onPreEducationRemark = this.onPreEducationRemark.bind(this)
		this.onOtherMedicalDevice = this.onOtherMedicalDevice.bind(this)
		this.onOtherMedicalDeviceRemark = this.onOtherMedicalDeviceRemark.bind(this)
		this.onToolSpecialEquipment = this.onToolSpecialEquipment.bind(this)
		this.onToolSpecialEquipmentRemark = this.onToolSpecialEquipmentRemark.bind(this)
		this.onImplant = this.onImplant.bind(this)
		this.onImplantRemark = this.onImplantRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.checkList) {
			let preOPCheckList = this.props.checkList
			let [isIdentifyPatient, identifyPatientRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.identifyPatient)
			this.setState({ isIdentifyPatient: isIdentifyPatient })
			this.setState({ identifyPatientRemark: identifyPatientRemark })

			let [isMarkingSurgerySite, markingSurgerySiteRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.markingSurgerySite)
			this.setState({ isMarkingSiteForSurgery: isMarkingSurgerySite })
			this.setState({ markingSiteForSurgeryRemark: markingSurgerySiteRemark })

			let [isConsentSurgery, consentSurgeryRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.consentSurgery)
			this.setState({ isLetterConsentSurgery: isConsentSurgery })
			this.setState({ letterConsentSurgeryRemark: consentSurgeryRemark })

			let [isConsentAnesthesia, consentAnesthesiaRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.consentAnesthesia)
			this.setState({ isLetterConsentAnesthesia: isConsentAnesthesia })
			this.setState({ letterConsentAnesthesiaRemark: consentAnesthesiaRemark })

			let [isLabResult, labResultRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.labResult)
			this.setState({ isLabResult: isLabResult })
			this.setState({ labResultRemark: labResultRemark })

			let [isImagingResult, imagingResultRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.imagingResult)
			this.setState({ isImagingResult: isImagingResult })
			this.setState({ imagingResultRemark: imagingResultRemark })

			let [isEkgResult, ekgResultRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.ekgResult)
			this.setState({ isEKGResult: isEkgResult })
			this.setState({ EKGResultRemark: ekgResultRemark })

			let [isCleanPatientBody, cleanPatientBodyRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.cleanPatientBody)
			this.setState({ isCleanPatientBody: isCleanPatientBody })
			this.setState({ cleanPatientBodyRemark: cleanPatientBodyRemark })

			let [isUnisonEnema, unisonEnemaRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.unisonEnema)
			this.setState({ isUnisonEnema: isUnisonEnema })
			this.setState({ unisonEnemaRemark: unisonEnemaRemark })

			let [isNpoTime, npoTimeRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.npoTime)
			this.setState({ isNPOTime: isNpoTime })
			this.setState({ NPOTimeRemark: npoTimeRemark })

			let [isRemoveDentures, removeDenturesRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeDentures)
			this.setState({ isRemoveDentures: isRemoveDentures })
			this.setState({ removeDenturesRemark: removeDenturesRemark })

			let [isRemoveEyeglasses, removeEyeglassesRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeEyeglasses)
			this.setState({ isEyeglassesRemoved: isRemoveEyeglasses })
			this.setState({ eyeglassesRemovedRemark: removeEyeglassesRemark })

			let [removeJewelry, removeJewelryRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeJewelry)
			this.setState({ isJewelryRemoved: removeJewelry })
			this.setState({ jewelryRemovedRemark: removeJewelryRemark })

			let [removeBodyPiercing, removeBodyPiercingRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeBodyPiercing)
			this.setState({ isBodyPiercingRemoved: removeBodyPiercing })
			this.setState({ bodyPiercingRemovedRemark: removeBodyPiercingRemark })

			let [changePatientClothes, changePatientClothesRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.changePatientClothes)
			this.setState({ isChangePaitentClothes: changePatientClothes })
			this.setState({ changePatientClothesRemark: changePatientClothesRemark })

			let [urinateBeforeOR, urinateBeforeORRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.urinateBeforeOR)
			this.setState({ isPatientUrinate: urinateBeforeOR })
			this.setState({ patientUrinateRemark: urinateBeforeORRemark })

			let [bloodRequested, bloodRequestedRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.bloodRequested)
			this.setState({ isBloodRequested: bloodRequested })
			this.setState({ bloodRequestedRemark: bloodRequestedRemark })

			let [preEducation, preEducationRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.preEducation)
			this.setState({ isPreEducation: preEducation })
			this.setState({ preEducationRemark: preEducationRemark })

			let [otherMedicalDevice, otherMedicalDeviceRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.otherMedicalDevice)
			this.setState({ isOtherMedicalDevice: otherMedicalDevice })
			this.setState({ otherMedicalDeviceRemark: otherMedicalDeviceRemark })

			let [toolSpecialEquipment, toolSpecialEquipmentRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.toolSpecialEquipment)
			this.setState({ isToolSpecialEquipment: toolSpecialEquipment })
			this.setState({ toolSpecialEquipmentRemark: toolSpecialEquipmentRemark })

			let [implant, implantRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.implant)
			this.setState({ isImplant: implant })
			this.setState({ implantRemark: implantRemark })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	componentWillUnmount() {
		this.setState(this.initialState())
	}

	initialState() {
		return {
			isIdentifyPatient: true,
			identifyPatientRemark: "",
			isMarkingSiteForSurgery: true,
			markingSiteForSurgeryRemark: "",
			isLetterConsentSurgery: true,
			letterConsentSurgeryRemark: "",
			isLetterConsentAnesthesia: true,
			letterConsentAnesthesiaRemark: "",
			isLabResult: true,
			labResultRemark: "",
			isImagingResult: true,
			imagingResultRemark: "",
			isEKGResult: true,
			EKGResultRemark: "",
			isCleanPatientBody: true,
			cleanPatientBodyRemark: "",
			isUnisonEnema: true,
			unisonEnemaRemark: "",
			isNPOTime: true,
			NPOTimeRemark: "",
			isRemoveDentures: true,
			removeDenturesRemark: "",
			isEyeglassesRemoved: true,
			eyeglassesRemovedRemark: "",
			isJewelryRemoved: true,
			jewelryRemovedRemark: "",
			isBodyPiercingRemoved: true,
			bodyPiercingRemovedRemark: "",
			isChangePaitentClothes: true,
			changePatientClothesRemark: "",
			isPatientUrinate: true,
			patientUrinateRemark: "",
			isBloodRequested: true,
			bloodRequestedRemark: "",
			isPreEducation: true,
			preEducationRemark: "",
			isOtherMedicalDevice: true,
			otherMedicalDeviceRemark: "",
			isToolSpecialEquipment: true,
			toolSpecialEquipmentRemark: "",
			isImplant: true,
			implantRemark: "",
		}
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.identifyThePatientNameSurnameAndDateOfBirth)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isIdentifyPatient} onChange={this.onIdentifyPatient}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.identifyPatientRemark}
							onChange={this.onIdentifyPatientRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.markingSiteForSurgeryProcedure)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isMarkingSiteForSurgery} onChange={this.onMarkingSiteForSurgery}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.markingSiteForSurgeryRemark}
							onChange={this.onMarkingSiteForSurgeryRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.letterOfConsentToUndergoSurgeryDiagnosis)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isLetterConsentSurgery} onChange={this.onLetterConsentSurgery}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.letterConsentSurgeryRemark}
							onChange={this.onLetterConsentSurgeryRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.letterOfConsentToReceiveAnesthesia)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isLetterConsentAnesthesia} onChange={this.onLetterConsentAnesthesia}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.letterConsentAnesthesiaRemark}
							onChange={this.onLetterConsentAnesthesiaRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.labResult)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isLabResult} onChange={this.onLabResult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.labResultRemark}
							onChange={this.onLabResultRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.imagingResult)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isImagingResult} onChange={this.onImagingResult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.imagingResultRemark}
							onChange={this.onImagingResultRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.EKGResult)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isEKGResult} onChange={this.onEKGResult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.EKGResultRemark}
							onChange={this.onEKGResultRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.cleanPatientBodyForSurgery)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isCleanPatientBody} onChange={this.onCleanPatientBody}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.cleanPatientBodyRemark}
							onChange={this.onCleanPatientBodyRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.unisonEnema)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isUnisonEnema} onChange={this.onUnisonEnema}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.unisonEnemaRemark}
							onChange={this.onUnisonEnemaRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.NPOTime)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isNPOTime} onChange={this.onNPOTime}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.NPOTimeRemark}
							onChange={this.onNPOTimeRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.removeDentures)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isRemoveDentures} onChange={this.onRemoveDentures}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.removeDenturesRemark}
							onChange={this.onRemoveDenturesRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.eyeGlassesContactLensEyelashesRemoved)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isEyeglassesRemoved} onChange={this.onEyeglassesRemoved}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.eyeglassesRemovedRemark}
							onChange={this.onEyeglassesRemovedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.jewelryAndHairpiecesRemoved)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isJewelryRemoved} onChange={this.onJewelryRemoved}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.jewelryRemovedRemark}
							onChange={this.onJewelryRemovedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.bodyPiercingRemoved)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isBodyPiercingRemoved} onChange={this.onBodyPiercingRemoved}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.bodyPiercingRemovedRemark}
							onChange={this.onBodyPiercingRemovedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.changePatientClothes)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isChangePaitentClothes} onChange={this.onChangePatientClothes}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.changePatientClothesRemark}
							onChange={this.onChangePatientClothesRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.haveThePatientUrinateBeforeBeingReferralOR)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isPatientUrinate} onChange={this.onPatientUrinate}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.patientUrinateRemark}
							onChange={this.onPatientUrinateRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.bloodRequested)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isBloodRequested} onChange={this.onBloodRequested}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.bloodRequestedRemark}
							onChange={this.onBloodRequestedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.preEducation)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isPreEducation} onChange={this.onPreEducation}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.preEducationRemark}
							onChange={this.onPreEducationRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.otherMedicalDevice)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isOtherMedicalDevice} onChange={this.onOtherMedicalDevice}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.otherMedicalDeviceRemark}
							onChange={this.onOtherMedicalDeviceRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.toolSpecialEquipment)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isToolSpecialEquipment} onChange={this.onToolSpecialEquipment}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.toolSpecialEquipmentRemark}
							onChange={this.onToolSpecialEquipmentRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.implant)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.state.isImplant} onChange={this.onImplant}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.state.implantRemark}
							onChange={this.onImplantRemark}
						/>
					</Col>
				</Row>
			</Container>
		)
	}

	onIdentifyPatient(e) {
		this.setState({ isIdentifyPatient: e.target.value })
	}
	onIdentifyPatientRemark(e) {
		this.setState({ identifyPatientRemark: e.target.value })
	}
	onMarkingSiteForSurgery(e) {
		this.setState({ isMarkingSiteForSurgery: e.target.value })
	}
	onMarkingSiteForSurgeryRemark(e) {
		this.setState({ markingSiteForSurgeryRemark: e.target.value })
	}
	onLetterConsentSurgery(e) {
		this.setState({ isLetterConsentSurgery: e.target.value })
	}
	onLetterConsentSurgeryRemark(e) {
		this.setState({ letterConsentSurgeryRemark: e.target.value })
	}
	onLetterConsentAnesthesia(e) {
		this.setState({ isLetterConsentAnesthesia: e.target.value })
	}
	onLetterConsentAnesthesiaRemark(e) {
		this.setState({ letterConsentAnesthesiaRemark: e.target.value })
	}
	onLabResult(e) {
		this.setState({ isLabResult: e.target.value })
	}
	onLabResultRemark(e) {
		this.setState({ labResultRemark: e.target.value })
	}
	onImagingResult(e) {
		this.setState({ isImagingResult: e.target.value })
	}
	onImagingResultRemark(e) {
		this.setState({ imagingResultRemark: e.target.value })
	}
	onEKGResult(e) {
		this.setState({ isEKGResult: e.target.value })
	}
	onEKGResultRemark(e) {
		this.setState({ EKGResultRemark: e.target.value })
	}
	onCleanPatientBody(e) {
		this.setState({ isCleanPatientBody: e.target.value })
	}
	onCleanPatientBodyRemark(e) {
		this.setState({ cleanPatientBodyRemark: e.target.value })
	}
	onUnisonEnema(e) {
		this.setState({ isUnisonEnema: e.target.value })
	}
	onUnisonEnemaRemark(e) {
		this.setState({ unisonEnemaRemark: e.target.value })
	}
	onNPOTime(e) {
		this.setState({ isNPOTime: e.target.value })
	}
	onNPOTimeRemark(e) {
		this.setState({ NPOTimeRemark: e.target.value })
	}
	onRemoveDentures(e) {
		this.setState({ isRemoveDentures: e.target.value })
	}
	onRemoveDenturesRemark(e) {
		this.setState({ removeDenturesRemark: e.target.value })
	}
	onEyeglassesRemoved(e) {
		this.setState({ isEyeglassesRemoved: e.target.value })
	}
	onEyeglassesRemovedRemark(e) {
		this.setState({ eyeglassesRemovedRemark: e.target.value })
	}
	onJewelryRemoved(e) {
		this.setState({ isJewelryRemoved: e.target.value })
	}
	onJewelryRemovedRemark(e) {
		this.setState({ jewelryRemovedRemark: e.target.value })
	}
	onBodyPiercingRemoved(e) {
		this.setState({ isBodyPiercingRemoved: e.target.value })
	}
	onBodyPiercingRemovedRemark(e) {
		this.setState({ bodyPiercingRemovedRemark: e.target.value })
	}
	onChangePatientClothes(e) {
		this.setState({ isChangePaitentClothes: e.target.value })
	}
	onChangePatientClothesRemark(e) {
		this.setState({ changePatientClothesRemark: e.target.value })
	}
	onPatientUrinate(e) {
		this.setState({ isPatientUrinate: e.target.value })
	}
	onPatientUrinateRemark(e) {
		this.setState({ patientUrinateRemark: e.target.value })
	}
	onBloodRequested(e) {
		this.setState({ isBloodRequested: e.target.value })
	}
	onBloodRequestedRemark(e) {
		this.setState({ bloodRequestedRemark: e.target.value })
	}
	onPreEducation(e) {
		this.setState({ isPreEducation: e.target.value })
	}
	onPreEducationRemark(e) {
		this.setState({ preEducationRemark: e.target.value })
	}
	onOtherMedicalDevice(e) {
		this.setState({ isOtherMedicalDevice: e.target.value })
	}
	onOtherMedicalDeviceRemark(e) {
		this.setState({ otherMedicalDeviceRemark: e.target.value })
	}
	onToolSpecialEquipment(e) {
		this.setState({ isToolSpecialEquipment: e.target.value })
	}
	onToolSpecialEquipmentRemark(e) {
		this.setState({ toolSpecialEquipmentRemark: e.target.value })
	}
	onImplant(e) {
		this.setState({ isImplant: e.target.value })
	}
	onImplantRemark(e) {
		this.setState({ implantRemark: e.target.value })
	}
}

PreOperativeCheckList.propTypes = {
	checkList: propTypes.object,
}

PreOperativeCheckList.defaultProps = {
	checkList: undefined,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PreOperativeCheckList)
