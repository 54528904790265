import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAccountsReceivableInvoices, setLoadingAction } from "~/redux/action"
import { SET_AR_EDIT_INVOICE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ARModalEditInvoiceDetails from "./ARModalEditInvoiceDetails"

class modalEditInvoice extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "modalEditInvoice",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_AR_EDIT_INVOICE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan || !this.props.AR.selectedInvoice.invoice) {
			return true
		}
		let target = Object.assign({}, this.props.AR.selectedInvoice.invoice, {
			billing: {
				...this.props.AR.selectedInvoice.invoice.billing,
				billingPlan: {
					...this.props.AR.selectedInvoice.invoice.billing.billingPlan,
					code: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.code,
					name: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.name,
					description: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.description,
					insuranceCompany: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompany,
					insuranceCompanyCode: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompanyCode,
					insuranceCompanyAddress: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompanyAddress,
					insuranceCompanyTel: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompanyTel,
					primaryHealthCarePlan: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.primaryHealthCarePlan,
				},
				billingPlanAdjustments: _.isEmpty(this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments)
					? [
							{
								discount: Utils.BigNumber(this.props.AR.modalEditInvoice.discountPercent).isGreaterThan(0)
									? this.props.AR.selectedInvoice.invoice.billing.billingStatements
											.reduce((total, cur) => {
												return total.plus(cur.charge).minus(cur.adjustment)
											}, Utils.BigNumber(0))
											.times(this.props.AR.modalEditInvoice.discountPercent)
											.dividedBy(100)
											.toFixed(2)
									: Utils.BigNumber(this.props.AR.modalEditInvoice.discount).toFixed(2),
								discountDescription: Utils.trim(this.props.AR.modalEditInvoice.remark),
							},
					  ]
					: this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.map((each) => {
							return {
								...each,
								discount: Utils.BigNumber(this.props.AR.modalEditInvoice.discountPercent).isGreaterThan(0)
									? this.props.AR.selectedInvoice.invoice.billing.billingStatements
											.reduce((total, cur) => {
												return total.plus(cur.charge).minus(cur.adjustment)
											}, Utils.BigNumber(0))
											.times(this.props.AR.modalEditInvoice.discountPercent)
											.dividedBy(100)
											.toFixed(2)
									: Utils.BigNumber(this.props.AR.modalEditInvoice.discount).toFixed(2),
								discountDescription: Utils.trim(this.props.AR.modalEditInvoice.remark),
							}
					  }),
			},
		})
		return _.isEqual(this.props.AR.selectedInvoice.invoice, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let discount = Utils.BigNumber(this.props.AR.modalEditInvoice.discountPercent).isGreaterThan(0)
			? this.props.AR.selectedInvoice.invoice.billing.billingStatements
					.reduce((total, cur) => {
						return total.plus(cur.charge).minus(cur.adjustment)
					}, Utils.BigNumber(0))
					.times(this.props.AR.modalEditInvoice.discountPercent)
					.dividedBy(100)
			: Utils.BigNumber(this.props.AR.modalEditInvoice.discount)
		let allocatedBillingStatements = Utils.allocateBillDiscount(this.props.AR.selectedInvoice.invoice.billing.billingStatements, discount)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsReceivable.updateBillingPlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				invoiceId: this.props.AR.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
				billingPlans: [
					{
						id: this.props.AR.selectedInvoice.invoice.billing.billingPlan.id,
						healthCareCode: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.code,
						healthCareName: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.displayName,
						healthCareDescription: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.description,
						insuranceCompany: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompany,
						insuranceCompanyCode: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompanyCode,
						insuranceCompanyAddress: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompanyAddress,
						insuranceCompanyTel: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.insuranceCompanyTel,
						primaryHealthCarePlan: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.primaryHealthCarePlan,
						billingPlanAdjustments: _.isEmpty(this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments)
							? [
									{
										discount: discount.toFixed(2),
										discountDescription: Utils.trim(this.props.AR.modalEditInvoice.remark),
									},
							  ]
							: this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.map((each) => {
									return Object.assign({}, each, {
										discount: discount.toFixed(2),
										discountDescription: Utils.trim(this.props.AR.modalEditInvoice.remark),
									})
							  }),
						billingStatements: this.props.AR.selectedInvoice.invoice.billing.billingStatements.map((each) => {
							let GL = Utils.getGeneralLedgerCode(
								each.order.locationAR,
								this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.activities,
								each.type.activities
							)
							return {
								...each,
								healthCareCode: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.code,
								healthCareName: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.displayName,
								healthCareDescription: this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan.description,
								accountingCode: GL.fullCode,
								accountingChartName: GL.displayName,
								accountingChartDescription: GL.description,
								accountingTransDescr: GL.transDescr,
								billAdjustment: allocatedBillingStatements[each.id],
							}
						}),
					},
				],
			},
		}
		let callback = (res) => {
			let invoices = this.props.AR.invoiceTable.original.filter((each) => each.id !== res.data.invoices[0].id)
			invoices.push(res.data.invoices[0])
			this.props.setAccountsReceivableInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.smd}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.edit)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ARModalEditInvoiceDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAREditInvoiceDisplay,
	login: state.login,
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAccountsReceivableInvoices,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(modalEditInvoice)
