import { Input, Radio, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_ITEM_MODAL_UNIT_AMOUNT,
	SET_ADM_ITEM_MODAL_UNIT_DESCRIPTION,
	SET_ADM_ITEM_MODAL_UNIT_ONE_TIME,
	SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT,
	SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class AdmItemModalUnitConversion extends React.Component {
	constructor(props) {
		super(props)

		this.onAmount = this.onAmount.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onOneTime = this.onOneTime.bind(this)
		this.onSellingPricePerUnit = this.onSellingPricePerUnit.bind(this)
	}

	componentDidMount() {
		if (this.props.configItem.selectedUnit.unit) {
			this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_AMOUNT, this.props.configItem.selectedUnit.unit.amount)
			this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_DESCRIPTION, this.props.configItem.selectedUnit.unit.description)
			this.setSelectedUnit(this.props.configItem.selectedUnit.unit.displayName)
			this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_ONE_TIME, this.props.configItem.selectedUnit.unit.oneTime)
			this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT, this.props.configItem.selectedUnit.unit.sellingPricePerUnit)
		} else {
			this.setSelectedUnit(this.props.configItem.selectedItem.item.unit.displayName)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.configItem.modalUnit.isOneTime, this.props.configItem.modalUnit.isOneTime)) {
			if (this.props.configItem.selectedUnit.unit) {
				this.props.setValue(
					SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT,
					this.props.configItem.modalUnit.isOneTime ? "" : this.props.configItem.selectedUnit.unit.sellingPricePerUnit
				)
			} else {
				this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_AMOUNT, 1)
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_DESCRIPTION, "")
		this.props.setSelected(SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT, -1, null)
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT, "")
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_ONE_TIME, false)
	}

	setSelectedUnit(unit) {
		let unitIdx = this.props.configItem.modalUnit.units.findIndex((each) => _.isEqual(Utils.trim(each.displayName), Utils.trim(unit)))
		unitIdx > -1
			? this.props.setSelected(SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT, unitIdx, this.props.configItem.modalUnit.units[unitIdx])
			: this.props.setSelected(SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT, -1, null)
	}

	onAmount(e) {
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_AMOUNT, e.target.value)
	}

	onUnit(value) {
		value > -1
			? this.props.setSelected(SET_ADM_ITEM_MODAL_UNIT_SELECTED_UNIT, value, this.props.configItem.modalUnit.units[value])
			: this.setSelectedUnit(this.props.configItem.selectedItem.item.unit.displayName)
	}

	onDescription(e) {
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_DESCRIPTION, e.target.value)
	}

	onOneTime(e) {
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_ONE_TIME, e.target.value)
	}

	onSellingPricePerUnit(e) {
		this.props.setValue(SET_ADM_ITEM_MODAL_UNIT_SELLING_PRICE_PER_UNIT, e.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.unitConversion)}</legend>
				<Row>
					<Col />
					<Col md="auto">
						<span>1 {this.props.configItem.selectedItem.item.unit.displayName}</span>
					</Col>
					<Col md="auto">
						<span>=</span>
					</Col>
					<Col md="auto">
						<Input
							style={{ textAlign: "center", width: "200px" }}
							type={ResourceAssistance.FormControl.type.number}
							addonAfter={
								<Select
									allowClear
									showSearch
									size={ResourceAssistance.Select.size.large}
									style={{ flexGrow: 1, textAlign: "center", width: "120px" }}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
									value={this.props.configItem.modalUnit.selectedUnit.unit ? this.props.configItem.modalUnit.selectedUnit.unit.displayName : undefined}
									onChange={this.onUnit}
								>
									{Utils.renderSelects(this.props.configItem.modalUnit.units, false)}
								</Select>
							}
							value={this.props.configItem.modalUnit.amount}
							onChange={this.onAmount}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col style={{ alignItems: "center" }}>
						<Radio.Group value={this.props.configItem.modalUnit.isOneTime} onChange={this.onOneTime}>
							<Radio value={true}>{translate(ResourceAssistance.Message.singleTime)}</Radio>
							<Radio value={false}>{translate(ResourceAssistance.Message.multiTime)}</Radio>
						</Radio.Group>
					</Col>
				</Row>
				{!this.props.configItem.modalUnit.isOneTime && (
					<Row style={{ justifyContent: "center" }}>
						<Col md="auto">
							<GInput
								required
								style={{ textAlign: "center", alignSelf: "center" }}
								placeholder={translate(ResourceAssistance.Message.sellingPricePerUnit)}
								pattern="^[0-9]*\.[0-9]{2}$"
								onChange={this.onSellingPricePerUnit}
								value={this.props.configItem.modalUnit.sellingPricePerUnit}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<GInput
							autoSize
							minRows={4}
							placeholder={translate(ResourceAssistance.Message.description)}
							onChange={this.onDescription}
							value={this.props.configItem.modalUnit.description}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	configItem: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmItemModalUnitConversion)
