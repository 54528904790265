import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setLoadingAction,
	setObjArray,
	setRegModalExpenseEstItems,
	setRegModalExpenseEstPendings,
} from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_REGISTRATION_MODAL_EXPENSE_EST_ITEM_TYPES,
	SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICE_TYPES,
} from "~/redux/type"
import RegModalExpenseEstAction from "./RegModalExpenseEstAction"
import RegModalExpenseEstBudgetDetails from "./RegModalExpenseEstBudgetDetails"
import RegModalExpenseEstHealthCareInfo from "./RegModalExpenseEstHealthCareInfo"
import RegModalExpenseEstOrderTab from "./RegModalExpenseEstOrderTab"
import RegModalExpenseEstPatientInfo from "./RegModalExpenseEstPatientInfo"
import RegModalExpenseEstPending from "./RegModalExpenseEstPending"
import RegModalExpenseEstPendingFilter from "./RegModalExpenseEstPendingFilter"

class RegModalExpenseEstDetails extends React.Component {
	constructor(props) {
		super(props)

		this.tabRef = React.createRef()
	}
	componentDidMount() {
		this.loadExpenseEstimation()
		if (_.isEmpty(this.props.reg.modalExpenseEst.itemTypes)) {
			this.loadTypes()
		}
		if (_.isEmpty(this.props.reg.modalExpenseEst.itemTable.original)) {
			this.loadItems()
		}
		if (_.isEmpty(this.props.reg.modalExpenseEst.serviceTypes)) {
			this.loadServices()
		}
	}

	loadExpenseEstimation() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getExpenseEstimation,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				admissionId: this.props.reg.selectedAdmission.admission.id,
			},
		}
		let callback = (res) => {
			if (res.data.clinicalRecords.length > 0) {
				let clinicalRecord = res.data.clinicalRecords[0]
				this.props.setRegModalExpenseEstPendings(clinicalRecord.doctorOrders.concat(clinicalRecord.nurseOrders))
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REGISTRATION_MODAL_EXPENSE_EST_ITEM_TYPES, res.data.types)
		}
		axios(params, callback)
	}

	loadItems() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setRegModalExpenseEstItems(res.data.items)
		}
		axios(params, callback)
	}

	loadServices() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.getServices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_REGISTRATION_MODAL_EXPENSE_EST_SERVICE_TYPES, res.data.types)
		}
		axios(params, callback)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<RegModalExpenseEstPatientInfo />
				<RegModalExpenseEstHealthCareInfo />
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.expenseEstimation)}</legend>
					<RegModalExpenseEstOrderTab ref={this.tabRef} />
					<RegModalExpenseEstAction {...this.tabRef.current} />
					<RegModalExpenseEstPendingFilter />
					<RegModalExpenseEstPending />
				</fieldset>
				<RegModalExpenseEstBudgetDetails />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setLoadingAction,
			setRegModalExpenseEstPendings,
			setRegModalExpenseEstItems,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstDetails)
