import { Checkbox } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { translate, ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE,
	SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE,
} from "~/redux/type"

class RegModalExpenseEstPendingFilter extends React.Component {
	EFilter = {
		DOCTOR: "DOCTOR",
		NURSE: "NURSE",
		SERVICE: "SERVICE",
		MEDICINE: "MEDICINE",
		MEDICAL_SUPPLY: "MEDICAL_SUPPLY",
		COORDINATOR: "COORDINATOR",
	}
	constructor(props) {
		super(props)

		this.onFilterOrder = this.onFilterOrder.bind(this)
		this.onFilterOrderType = this.onFilterOrderType.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR, false)
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE, false)
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR, false)
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE, false)
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE, false)
		this.props.setValue(SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY, false)
	}

	onFilterOrder(checkedValues) {
		this.props.setValue(
			SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_DOCTOR,
			checkedValues.some((each) => _.isEqual(each, this.EFilter.DOCTOR))
		)
		this.props.setValue(
			SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_NURSE,
			checkedValues.some((each) => _.isEqual(each, this.EFilter.NURSE))
		)
		this.props.setValue(
			SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_COORDINATOR,
			checkedValues.some((each) => _.isEqual(each, this.EFilter.COORDINATOR))
		)
	}

	onFilterOrderType(checkedValues) {
		this.props.setValue(
			SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_SERVICE,
			checkedValues.some((each) => _.isEqual(each, this.EFilter.SERVICE))
		)
		this.props.setValue(
			SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICINE,
			checkedValues.some((each) => _.isEqual(each, this.EFilter.MEDICINE))
		)
		this.props.setValue(
			SET_REGISTRATION_MODAL_EXPENSE_EST_FILTER_MEDICAL_SUPPLY,
			checkedValues.some((each) => _.isEqual(each, this.EFilter.MEDICAL_SUPPLY))
		)
	}

	render() {
		return (
			<Row>
				<Col style={{ alignItems: "center" }}>
					<Checkbox.Group
						options={[
							{ label: translate(ResourceAssistance.Hospitel.doctor), value: this.EFilter.DOCTOR },
							{ label: translate(ResourceAssistance.Hospitel.nurse), value: this.EFilter.NURSE },
							{ label: translate(ResourceAssistance.Message.coordinator), value: this.EFilter.COORDINATOR },
						]}
						value={[
							this.props.reg.modalExpenseEst.filterDoctor && this.EFilter.DOCTOR,
							this.props.reg.modalExpenseEst.filterNurse && this.EFilter.NURSE,
							this.props.reg.modalExpenseEst.filterCoordinator && this.EFilter.COORDINATOR,
						]}
						onChange={this.onFilterOrder}
					/>
				</Col>
				<Col style={{ alignItems: "center" }}>
					<Checkbox.Group
						options={[
							{ label: translate(ResourceAssistance.Hospitel.service), value: this.EFilter.SERVICE },
							{ label: translate(ResourceAssistance.Message.medicine), value: this.EFilter.MEDICINE },
							{ label: translate(ResourceAssistance.Message.medicalSupply), value: this.EFilter.MEDICAL_SUPPLY },
						]}
						value={[
							this.props.reg.modalExpenseEst.filterService && this.EFilter.SERVICE,
							this.props.reg.modalExpenseEst.filterMedicine && this.EFilter.MEDICINE,
							this.props.reg.modalExpenseEst.filterMedicalSupply && this.EFilter.MEDICAL_SUPPLY,
						]}
						onChange={this.onFilterOrderType}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstPendingFilter))
