import _ from "lodash"
import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import Enum from "~/i18n/resource/Enum"
import {
	SET_PHARMACY_ADMISSIONS,
	SET_PHARMACY_DISPENSED_ORDERS,
	SET_PHARMACY_DOCTOR_ORDERS,
	SET_PHARMACY_MODAL_RETURN_LOTS,
	SET_PHARMACY_MODAL_VERIFY_LOTS,
	SET_PHARMACY_NURSE_ORDERS,
	SET_PHARMACY_PATIENTS,
	SET_PHARMACY_RETURN_ORDERS,
	SET_PHARMACY_SKIPPED_ORDERS,
	SET_PHARMACY_VERIFIED_ORDERS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setPharmacyPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients
			.filter(
				(patient) =>
					String(patient.id).includes(getState().hospital.pharmacy.searchPID.trim().toLowerCase()) &&
					String(patient.identificationNumber).includes(getState().hospital.pharmacy.searchIdentification.trim().toLowerCase()) &&
					patient.admissions.some(
						(admission) =>
							// (admission.dischargedDateTime &&
							// 	admission.clinicalRecord.doctorOrders.some(
							// 		(doctorOrder) =>
							// 			doctorOrder.pharmacyOrders.find(
							// 				(po) =>
							// 					(po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
							// 					(po.startDateTime === doctorOrder.startDateTime && po.endDateTime === doctorOrder.endDateTime)
							// 			) === undefined
							// 	)) ||
							// (
							admission.dischargedDateTime ||
							// &&
							// getState().hospital.pharmacy.startDateTime <= admission.dischargedDateTime &&
							// admission.dischargedDateTime <= getState().hospital.pharmacy.endDateTime)
							(!admission.dischargedDateTime &&
								(admission.clinicalRecord.doctorOrders.some(
									(doctorOrder) => !doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(getState().hospital.pharmacy.startDateTime).weekday())
								) ||
									!_.isEmpty(admission.clinicalRecord.nurseOrders)))
					)
			)
			.sort((a, b) => Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber()))

		if (_.includes(getState().hospital.pharmacy.filters, ResourceAssistance.CONSTANT.STAT)) {
			filtered = filtered.filter((patient) =>
				patient.admissions.some((admission) => admission.clinicalRecord.doctorOrders.some((doctorOrder) => doctorOrder.stat))
			)
		}

		let rowColor = filtered
			.filter((patient) => !patient.isSearchPatient)
			.map((patient) => {
				let color = ""
				if (
					patient.admissions.every((admission) => {
						return (
							admission.clinicalRecord.doctorOrders
								.filter(
									(doctorOrder) =>
										admission.dischargedDateTime ||
										!doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(getState().hospital.pharmacy.startDateTime).weekday())
								)
								.every((doctorOrder) => {
									return doctorOrder.pharmacyOrders.find((po) => {
										return (
											(po.status === ResourceAssistance.Enum.dispensed || po.status === ResourceAssistance.Enum.skipped) &&
											((po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
												(po.startDateTime === doctorOrder.startDateTime && po.endDateTime === doctorOrder.endDateTime))
										)
									})
								}) &&
							admission.clinicalRecord.nurseOrders.every((nurseOrder) => {
								return nurseOrder.pharmacyOrders.find((po) => {
									return (
										(po.status === ResourceAssistance.Enum.dispensed || po.status === ResourceAssistance.Enum.skipped) &&
										((po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
											(po.startDateTime === nurseOrder.startDateTime && po.endDateTime === nurseOrder.endDateTime))
									)
								})
							})
						)
					})
				) {
					color = ResourceAssistance.CSS.Color.green
				} else if (
					patient.admissions.some((admission) => {
						return admission.clinicalRecord.doctorOrders
							.filter(
								(doctorOrder) =>
									admission.dischargedDateTime ||
									!doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(getState().hospital.pharmacy.startDateTime).weekday())
							)
							.some((doctorOrder) => {
								return doctorOrder.pharmacyOrders.find((po) => {
									return (
										(po.status === ResourceAssistance.Enum.dispensed || po.status === ResourceAssistance.Enum.skipped) &&
										((po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
											(po.startDateTime === doctorOrder.startDateTime && po.endDateTime === doctorOrder.endDateTime))
									)
								})
							})
					})
				) {
					color = ResourceAssistance.CSS.Color.warning
				}
				return [true, color]
			})

		let body = filtered.map((patient, index) => {
			return [index + 1, patient.id, patient.firstName, patient.lastName, Utils.formatDate(patient.dobtime), patient.identificationNumber]
		})

		dispatch({
			type: SET_PHARMACY_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyAdmissions = (admissions) => {
	return (dispatch, getState) => {
		let filtered = admissions
			.filter((admission) => {
				let location = Utils.getLastVisitedLocationFrom(admission)
				return (
					location &&
					(_.isEqual(location.department.branch.id, getState().hospital.pharmacy.modalLocation.selectedBranch.branch.id) ||
						admission.clinicalRecord.doctorOrders.some((doctorOrder) =>
							doctorOrder.pharmacyOrders.some((po) => _.isEqual(po.department.branch.id, getState().hospital.pharmacy.modalLocation.selectedBranch.branch.id))
						) ||
						admission.clinicalRecord.nurseOrders.some((nurseOrder) =>
							nurseOrder.pharmacyOrders.some((po) => _.isEqual(po.department.branch.id, getState().hospital.pharamcy.modalLocation.selectedBranch.branch.id))
						))
				)
			})
			.filter((admission) => {
				return (
					admission.dischargedDateTime ||
					admission.clinicalRecord.doctorOrders.some(
						(doctorOrder) => !doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(getState().hospital.pharmacy.startDateTime).weekday())
					) ||
					!_.isEmpty(admission.clinicalRecord.nurseOrders)
				)
			})
			.sort((a, b) => Utils.sort(b.clinicalRecord.creationDateTime, a.clinicalRecord.creationDateTime))

		let rowColor = filtered.map((admission) => {
			let color = ""

			if (admission.dischargedDateTime > 0) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		let body = filtered.map((admission) => {
			let lastVisitedDepartment = _.isEmpty(admission.ipds)
				? admission.clinicalRecord.medicalRecords
						.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
						.sort((a, b) => Utils.sort(b.checkInDateTime, a.checkInDateTime))
						.find((medicalRecord) => true)
				: admission.ipds.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime)).find((ipd) => true)
			return [
				Utils.formatDate(admission.clinicalRecord.creationDateTime),
				Utils.formatTime(admission.clinicalRecord.creationDateTime),
				lastVisitedDepartment ? lastVisitedDepartment.department.branch.displayName + "/" + lastVisitedDepartment.department.displayName : "",
			]
		})

		dispatch({
			type: SET_PHARMACY_ADMISSIONS,
			payload: {
				original: admissions,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyDoctorOrders = (doctorOrders) => {
	return (dispatch, getState) => {
		let selectedAdmission = getState().hospital.pharmacy.selectedAdmission.admission
		let selectedBranch = getState().hospital.pharmacy.modalLocation.selectedBranch.branch
		let lastVisitedDepartment = null
		if (selectedAdmission && selectedBranch) {
			lastVisitedDepartment = _.isEmpty(selectedAdmission.ipds)
				? selectedAdmission.clinicalRecord.medicalRecords
						.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
						.sort((a, b) => Utils.sort(b.checkInDateTime, a.checkInDateTime))
						.find((medicalRecord) => true)
				: selectedAdmission.ipds.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime)).find((ipd) => true)
		}
		let filtered = doctorOrders
			.filter((doctorOrder) => lastVisitedDepartment && _.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id))
			.filter((doctorOrder) => {
				return (
					selectedAdmission.dischargedDateTime ||
					!_.isEmpty(doctorOrder.pharmacyOrders) ||
					((doctorOrder.stat || (selectedAdmission.ipds.some((ipd) => !ipd.dischargeSummary) && !doctorOrder.takeHome)) &&
						!doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(getState().hospital.pharmacy.startDateTime).weekday()))
				)
			})
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = filtered.map((doctorOrder) => {
			let color = ""
			if (
				doctorOrder.stat &&
				!doctorOrder.pharmacyOrders.some(
					(po) =>
						po.status === ResourceAssistance.Enum.dispensed &&
						((po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
							(po.startDateTime === doctorOrder.startDateTime && po.endDateTime === doctorOrder.endDateTime))
				)
			) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((order) => {
			let pharmacyOrder = order.pharmacyOrders.find(
				(po) =>
					(po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
					(po.startDateTime === order.startDateTime && po.endDateTime === order.endDateTime)
			)
			let status = pharmacyOrder ? pharmacyOrder.status : ResourceAssistance.Enum.pending

			return [
				[order.nonDisposable],
				order.code,
				order.description,
				Utils.trim(order.notes),
				Utils.formatDateTime(order.startDateTime),
				Utils.formatDateTime(order.endDateTime),
				order.lastModifiedBy.displayName,
				Utils.trim(order.healthcarePlan),
				Utils.convertEnum(status),
				[
					!pharmacyOrder && lastVisitedDepartment && _.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id),
					!pharmacyOrder && lastVisitedDepartment && _.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id),
					!pharmacyOrder &&
						lastVisitedDepartment &&
						_.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id) &&
						(moment(order.endDateTime).seconds(0).milliseconds(0).valueOf() >= moment().seconds(0).milliseconds(0).valueOf() ||
							(selectedAdmission.dischargedDateTime &&
								moment().startOf("day").seconds(0).milliseconds(0).valueOf() <= selectedAdmission.dischargedDateTime &&
								selectedAdmission.dischargedDateTime <= moment().endOf("day").seconds(0).milliseconds(0).valueOf()) ||
							order.stat),
				],
			]
		})

		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Warning.medicationCanBeUsedMultipleTimes]]]]
		})

		dispatch({
			type: SET_PHARMACY_DOCTOR_ORDERS,
			payload: {
				original: doctorOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyNurseOrders = (nurseOrders) => {
	return (dispatch, getState) => {
		let selectedAdmission = getState().hospital.pharmacy.selectedAdmission.admission
		let selectedBranch = getState().hospital.pharmacy.modalLocation.selectedBranch.branch
		let lastVisitedDepartment = null
		if (selectedAdmission && selectedBranch) {
			lastVisitedDepartment = _.isEmpty(selectedAdmission.ipds)
				? selectedAdmission.clinicalRecord.medicalRecords
						.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
						.sort((a, b) => Utils.sort(b.checkInDateTime, a.checkInDateTime))
						.find((medicalRecord) => true)
				: selectedAdmission.ipds.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime)).find((ipd) => true)
		}
		let filtered = nurseOrders
			.filter(() => lastVisitedDepartment && _.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id))
			.filter(
				(nurseOrder) =>
					selectedAdmission.dischargedDateTime ||
					nurseOrder.stat ||
					!_.isEmpty(nurseOrder.pharmacyOrders) ||
					(selectedAdmission.ipds.some((ipd) => !ipd.dischargeSummary) && !nurseOrder.takeHome)
			)
			.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))

		let rowColor = filtered.map((order) => {
			let color = ""
			if (
				order.stat &&
				!order.pharmacyOrders.some(
					(po) =>
						po.status === ResourceAssistance.Enum.dispensed &&
						((po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
							(po.startDateTime === order.startDateTime && po.endDateTime === order.endDateTime))
				)
			) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((order) => {
			let pharmacyOrder = order.pharmacyOrders.find(
				(po) =>
					(po.startDateTime === getState().hospital.pharmacy.startDateTime && po.endDateTime === getState().hospital.pharmacy.endDateTime) ||
					(po.startDateTime === order.startDateTime && po.endDateTime === order.endDateTime)
			)
			let status = pharmacyOrder ? pharmacyOrder.status : ResourceAssistance.Enum.pending

			return [
				order.code,
				order.description,
				Utils.trim(order.notes),
				Utils.formatDateTime(order.startDateTime),
				order.endDateTime ? Utils.formatDateTime(order.endDateTime) : "",
				order.lastModifiedBy.displayName,
				Utils.trim(order.healthCarePlan),
				Utils.convertEnum(status),
				[
					!pharmacyOrder,
					!pharmacyOrder &&
						lastVisitedDepartment &&
						_.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id) &&
						order.endDateTime > moment().valueOf(),
				],
			]
		})

		dispatch({
			type: SET_PHARMACY_NURSE_ORDERS,
			payload: {
				original: nurseOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyVerifiedOrders = (pharmacyOrders) => {
	return (dispatch, getState) => {
		let selectedAdmission = getState().hospital.pharmacy.selectedAdmission.admission
		let selectedBranch = getState().hospital.pharmacy.modalLocation.selectedBranch.branch
		let lastVisitedDepartment = null
		if (selectedAdmission && selectedBranch) {
			lastVisitedDepartment = _.isEmpty(selectedAdmission.ipds)
				? selectedAdmission.clinicalRecord.medicalRecords
						.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
						.sort((a, b) => Utils.sort(b.checkInDateTime, a.checkInDateTime))
						.find((medicalRecord) => true)
				: selectedAdmission.ipds.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime)).find((ipd) => true)
		}

		let filtered = pharmacyOrders.filter((po) => !_.isEqual(po.status, Enum.skipped)).sort((a, b) => Utils.sort(b.startDateTime, a.startDateTime))

		let rowColor = pharmacyOrders.map((pharmacyOrder) => {
			let color = ""
			if (pharmacyOrder.dispensedBy) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		let body = filtered.map((pharmacyOrder) => {
			return [
				Utils.formatDate(pharmacyOrder.startDateTime),
				Utils.formatDate(pharmacyOrder.endDateTime),
				Utils.formatNumWithComma(pharmacyOrder.amount),
				pharmacyOrder.unit,
				pharmacyOrder.department.branch.displayName + "/" + pharmacyOrder.department.displayName,
				pharmacyOrder.lastModifiedBy.displayName,
				[
					true,
					!pharmacyOrder.dispensedBy &&
						lastVisitedDepartment &&
						_.isEqual(lastVisitedDepartment.department.branch.id, selectedBranch.id) &&
						((pharmacyOrder.startDateTime < moment().valueOf() && pharmacyOrder.endDateTime > moment().valueOf()) || !pharmacyOrder.daily),
					!pharmacyOrder.dispensedBy,
				],
			]
		})

		dispatch({
			type: SET_PHARMACY_VERIFIED_ORDERS,
			payload: {
				original: pharmacyOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacySkippedOrders = (pharmacyOrders) => {
	return (dispatch, getState) => {
		let filtered = pharmacyOrders.filter((po) => _.isEqual(po.status, Enum.skipped)).sort((a, b) => Utils.sort(b.startDateTime, a.startDateTime))

		let rowColor = []

		let body = filtered.map((pharmacyOrder) => {
			return [
				Utils.formatDate(pharmacyOrder.startDateTime),
				Utils.formatDate(pharmacyOrder.endDateTime),
				Utils.formatNumWithComma(pharmacyOrder.amount),
				pharmacyOrder.unit,
				pharmacyOrder.department.branch.displayName + "/" + pharmacyOrder.department.displayName,
				pharmacyOrder.lastModifiedBy.displayName,
				[true],
			]
		})

		dispatch({
			type: SET_PHARMACY_SKIPPED_ORDERS,
			payload: {
				original: pharmacyOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyDispensedOrders = (pharmacyOrders) => {
	return (dispatch, getState) => {
		let filtered = pharmacyOrders.filter((po) => po.dispensedBy).sort((a, b) => Utils.sort(b.startDateTime, a.startDateTime))

		let rowColor = []

		let body = filtered.map((pharmacyOrder) => {
			return [
				Utils.formatDate(pharmacyOrder.startDateTime),
				Utils.formatDate(pharmacyOrder.endDateTime),
				Utils.formatNumWithComma(pharmacyOrder.amount),
				pharmacyOrder.unit,
				pharmacyOrder.department.branch.displayName + "/" + pharmacyOrder.department.displayName,
				pharmacyOrder.lastModifiedBy.displayName,
				[
					pharmacyOrder.items.some((lot) =>
						Utils.BigNumber(lot.amount).gt(
							lot.returnItems.reduce((total, cur) => {
								total = total.plus(cur.amount)
								return total
							}, Utils.BigNumber(0))
						)
					),
				],
			]
		})

		dispatch({
			type: SET_PHARMACY_DISPENSED_ORDERS,
			payload: {
				original: pharmacyOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyReturnOrders = (pharmacyReturnOrders) => {
	return (dispatch, getState) => {
		let filtered = pharmacyReturnOrders.sort((a, b) => Utils.sort(b.startDateTime, a.startDateTime))

		let rowColor = []

		let body = filtered.map((pharmacyReturnOrder) => {
			return [
				Utils.formatDate(pharmacyReturnOrder.startDateTime),
				Utils.formatDate(pharmacyReturnOrder.endDateTime),
				Utils.formatNumWithComma(pharmacyReturnOrder.amount),
				pharmacyReturnOrder.unit,
				pharmacyReturnOrder.department.branch.displayName + "/" + pharmacyReturnOrder.department.displayName,
				pharmacyReturnOrder.lastModifiedBy.displayName,
			]
		})

		dispatch({
			type: SET_PHARMACY_RETURN_ORDERS,
			payload: {
				original: pharmacyReturnOrders,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyModalVerifyLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))

		let rowColor = []

		let body = filtered.map((lot) => {
			return [lot.lotNum, Utils.formatNumWithComma(lot.total), lot.unit, Utils.formatDate(lot.expiredDateTime), true]
		})

		dispatch({
			type: SET_PHARMACY_MODAL_VERIFY_LOTS,
			payload: {
				original: lots,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPharmacyModalReturnLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.sort((a, b) => Utils.sort(a.lotNum, b.lotNum))

		let rowColor = filtered.map((lot) => {
			let color = ""
			if (lot.returnAmount > 0) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((lot) => {
			return [
				lot.lotNum,
				Utils.formatNumWithComma(lot.amount),
				Utils.formatNumWithComma(lot.returned),
				Utils.formatNumWithComma(lot.returnAmount),
				[Utils.BigNumber(lot.amount).gt(lot.returned)],
			]
		})

		dispatch({
			type: SET_PHARMACY_MODAL_RETURN_LOTS,
			payload: {
				original: lots,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
