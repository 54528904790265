import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_ICD_NEW_ICD_ACTIVE,
	SET_ADM_ICD_NEW_ICD_CODE,
	SET_ADM_ICD_NEW_ICD_DESCRIPTION,
	SET_ADM_ICD_NEW_ICD_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class ConfigICDModalNInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onCode = this.onCode.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		let icd = this.props.icd.selectedICD.icd
		if (icd) {
			let type = document.getElementById(ResourceAssistance.ID.ADM.icd.modalNewICD.type)
			type.selectedIndex = Utils.BigNumber(this.props.icd.selectedType.index).plus(1).toNumber()
			this.props.setSelected(
				SET_ADM_ICD_NEW_ICD_SELECTED_TYPE,
				this.props.icd.selectedType.index,
				this.props.icd.types[this.props.icd.selectedType.index]
			)

			this.props.setValue(SET_ADM_ICD_NEW_ICD_CODE, icd.code)
			this.props.setValue(SET_ADM_ICD_NEW_ICD_DESCRIPTION, icd.description)
			this.props.setValue(SET_ADM_ICD_NEW_ICD_ACTIVE, icd.active)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_ICD_NEW_ICD_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_ADM_ICD_NEW_ICD_CODE, "")
		this.props.setValue(SET_ADM_ICD_NEW_ICD_DESCRIPTION, "")
		this.props.setValue(SET_ADM_ICD_NEW_ICD_ACTIVE, true)
	}

	onType(event) {
		this.props.setSelected(
			SET_ADM_ICD_NEW_ICD_SELECTED_TYPE,
			event.target.value,
			this.props.icd.types[event.target.value]
		)
	}

	onCode(event) {
		this.props.setValue(SET_ADM_ICD_NEW_ICD_CODE, event.target.value.toUpperCase())
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_ICD_NEW_ICD_DESCRIPTION, event.target.value)
	}

	onActive(event) {
		this.props.setValue(SET_ADM_ICD_NEW_ICD_ACTIVE, event.target.checked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.category)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.icd.modalNewICD.type}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.icd.types, true)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.code)}</label>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.icd.modalNewICD.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.description)}</label>
					</Col>
					<Col>
						<FormControl
							required
							as={ResourceAssistance.FormControl.as.textArea}
							rows={5}
							value={this.props.icd.modalNewICD.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.icd.modalNewICD.isActive} />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	icd: state.admin.configICD.icd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigICDModalNInfo))
