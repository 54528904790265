import * as React from "react";

function SvgSvgDeposit(props) {
  return (
    <svg
      id="SvgDeposit_svg__icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...props}
    >
      <defs>
        <style>
          {
            ".SvgDeposit_svg__cls-1{fill:#fcd8c0}.SvgDeposit_svg__cls-2{fill:#ef9e86}.SvgDeposit_svg__cls-4{fill:#c7dba2}.SvgDeposit_svg__cls-6{fill:#4d4d4d}"
          }
        </style>
      </defs>
      <path
        className="SvgDeposit_svg__cls-1"
        d="M143.64 103L253 32.8a35.59 35.59 0 0130.24-3.88L381 60.76h79.48v107.11h-48.11s-15.95 30.86-53.54 30.86H143.64z"
      />
      <path
        className="SvgDeposit_svg__cls-2"
        d="M405.28 137.77L243.77 91.86h76.77v-2A12.5 12.5 0 00308 77.32H183.62l-40 25.65v95.76h215.21a60.85 60.85 0 0025.81-5.54c20.36-22.76 20.64-55.42 20.64-55.42z"
      />
      <path
        d="M51.55 105.49v146.2a14.11 14.11 0 0014.11 14.11h294.09a14.11 14.11 0 0014.11-14.11V91.38H65.66a14.11 14.11 0 00-14.11 14.11z"
        fill="#aabc80"
      />
      <path
        className="SvgDeposit_svg__cls-4"
        d="M51.55 105.49v67.77a65.16 65.16 0 0086.13-81.88h-72a14.11 14.11 0 00-14.13 14.11z"
      />
      <path
        className="SvgDeposit_svg__cls-4"
        d="M346.61 213.79a24.76 24.76 0 00-24.76 24.76H103.56a24.76 24.76 0 00-24.75-24.76v-70.4a24.75 24.75 0 0024.75-24.75h218.29a24.75 24.75 0 0024.76 24.75z"
      />
      <path
        className="SvgDeposit_svg__cls-1"
        d="M399.62 138.39c-12.6 24.08-43 24.7-43 24.7-40.74 0-52.23-29.88-52.23-29.88h-53.5A20.91 20.91 0 01230 112.3a20.92 20.92 0 0120.91-20.92h63.89l60.61-9.21a22 22 0 0125.32 22.51zM52 484.85l63.89-94.43a87.34 87.34 0 0181.76-38 134.23 134.23 0 0130 6.64 53.6 53.6 0 0017.81 3.13h58.1a22.49 22.49 0 0122.49 22.49l56.18-60.73a21.17 21.17 0 0128.71-2.2 21.18 21.18 0 013.23 30l-62.76 76.74a34.51 34.51 0 01-21.78 12.32l-121.42 17.54a34.76 34.76 0 00-25.42 17.57l-4.94 8.93z"
      />
      <path
        className="SvgDeposit_svg__cls-2"
        d="M304.57 410.23h-66.39l-22 17.73v3.88l8.59-.11a23.64 23.64 0 0015.44-6l.71-.63a16.82 16.82 0 0111.26-4.31h58.36A25.12 25.12 0 00334 405.37c2.54-6.31 2.86-14.65-2.58-25.19l-.83-.41-4.52 4.89c-.24 18.88-21.5 25.57-21.5 25.57z"
      />
      <path
        d="M141.09 118.64h-37.53a24.75 24.75 0 01-24.75 24.75V178a65.13 65.13 0 0062.28-59.36z"
        fill="#e0edc3"
      />
      <path
        className="SvgDeposit_svg__cls-6"
        d="M460.45 55.06h-78.57L285 23.5a41.13 41.13 0 00-35.08 4.5l-58.37 37.46a5.7 5.7 0 106.16 9.59l58.36-37.45a29.75 29.75 0 0125.4-3.26l97.74 31.84a5.84 5.84 0 001.76.28h73.78v95.71h-42.38a5.7 5.7 0 00-5.06 3.08 57.27 57.27 0 01-27.75 23.93v-25.65c9.13-3.9 18.92-10.68 25.1-22.49a5.7 5.7 0 10-10.09-5.29c-10.84 20.71-37.78 21.63-37.92 21.64-36.08 0-46.49-25.18-46.91-26.23a5.69 5.69 0 00-5.32-3.65h-53.53a15.22 15.22 0 110-30.43h63.91a5.7 5.7 0 000-11.4h-63.91a26.62 26.62 0 000 53.23h50c4.76 8.73 20 29.88 55.91 29.88a62.35 62.35 0 0011.39-1.59v84.49a8.42 8.42 0 01-8.41 8.41H65.66a8.42 8.42 0 01-8.41-8.41v-146.2a8.42 8.42 0 018.41-8.41H211a5.7 5.7 0 000-11.4H65.66a19.84 19.84 0 00-19.81 19.81v146.2a19.83 19.83 0 0019.81 19.81h294.09a19.83 19.83 0 0019.81-19.81v-50.43c20.43-6.51 31.87-21.24 36-27.69h44.85a5.7 5.7 0 005.7-5.7V60.76a5.7 5.7 0 00-5.66-5.7z"
      />
      <path
        className="SvgDeposit_svg__cls-6"
        d="M84.51 208.63v-60.07a30.53 30.53 0 0024.21-24.22H206a5.7 5.7 0 100-11.4H103.56a5.7 5.7 0 00-5.7 5.7 19.08 19.08 0 01-19 19 5.7 5.7 0 00-5.7 5.7v70.4a5.7 5.7 0 005.7 5.7 19.08 19.08 0 0119 19.06 5.7 5.7 0 005.7 5.7h218.29a5.7 5.7 0 005.7-5.7 19.08 19.08 0 0119.06-19.06 5.7 5.7 0 005.69-5.7V188a5.7 5.7 0 10-11.39 0v20.6a30.53 30.53 0 00-24.22 24.22h-208a30.52 30.52 0 00-24.18-24.19z"
      />
      <path
        className="SvgDeposit_svg__cls-6"
        d="M205.22 159.84h13.71a5.7 5.7 0 100-11.4h-4.48v-3.67a5.7 5.7 0 00-11.4 0v3.81a17.92 17.92 0 002.17 35.71h7.07a6.53 6.53 0 010 13.06h-13.71a5.7 5.7 0 100 11.39h4.47v3.67a5.7 5.7 0 0011.4 0v-3.81a17.92 17.92 0 00-2.16-35.71h-7.07a6.53 6.53 0 110-13zM145.92 174.57a5.7 5.7 0 00-1.67 4 6 6 0 00.11 1.13 5.56 5.56 0 00.33 1 4.55 4.55 0 00.53 1 5.26 5.26 0 00.7.86 4.91 4.91 0 00.87.71 6.18 6.18 0 001 .53 6.3 6.3 0 001.07.32 5.21 5.21 0 001.11.1 5.71 5.71 0 004-1.66 5.9 5.9 0 00.71-.86 7.28 7.28 0 00.53-1 6.33 6.33 0 00.31-1 4.72 4.72 0 00.12-1.13 5.7 5.7 0 00-9.73-4zM270.72 181.76a6.49 6.49 0 00.71.86 5.67 5.67 0 008.76-.86 4.55 4.55 0 00.53-1 5.56 5.56 0 00.33-1 6 6 0 00.11-1.11 5.7 5.7 0 00-11.4 0 6 6 0 00.12 1.11 5.53 5.53 0 00.32 1 6.29 6.29 0 00.52 1zM414.52 317.26a27 27 0 00-36.43 2.8l-36 39a5.7 5.7 0 008.37 7.74l36-39a15.48 15.48 0 0126.74 8.85 15.34 15.34 0 01-3.41 11.46L347 424.85a28.8 28.8 0 01-18.19 10.28L207.4 452.71a40.66 40.66 0 00-29.59 20.45l-3.32 6H62.76l57.87-85.54a81.45 81.45 0 0176.42-35.55 129.29 129.29 0 0128.75 6.35 59.15 59.15 0 0019.7 3.46h58.1a16.82 16.82 0 0116.79 16.79 18.15 18.15 0 01-18.13 18.13H249a37.31 37.31 0 00-23.79 8.59l-12.5 10.37a5.7 5.7 0 003.65 10.09 5.62 5.62 0 003.63-1.32l12.5-10.37a25.93 25.93 0 0116.51-6h53.26a29.57 29.57 0 0029.53-29.53 28.23 28.23 0 00-28.19-28.19h-58.1a47.82 47.82 0 01-15.92-2.81 140.69 140.69 0 00-31.29-6.93 92.83 92.83 0 00-87.1 40.49L47.3 481.65a5.69 5.69 0 004.7 8.89h125.85a5.68 5.68 0 005-2.94l4.94-8.92A29.23 29.23 0 01209 464l121.46-17.58a40.12 40.12 0 0025.37-14.35l62.76-76.74a26.88 26.88 0 00-4.1-38.06z"
      />
    </svg>
  );
}

export default SvgSvgDeposit;
