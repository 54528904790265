import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { contextMenu } from "react-contexify"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_CASHIER_LOCATION_DISPLAY,
	SET_CASHIER_SEARCH_PATIENT_DISPLAY,
	SET_CONTEXT_MENU_PATIENT_DISPLAY_PATIENT_INFO,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import "./Cashier.css"
import CashierLeft from "./left-pannel/CashierLeft"
import CashierRight from "./right-pannel/CashierRight"

class Cashier extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Cashier",
		}
		this.onContextMenu = this.onContextMenu.bind(this)
	}

	componentDidMount() {
		this.props.onModalDisplayAction(SET_CASHIER_LOCATION_DISPLAY, true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.location, this.props.cashier.location)) {
			if (this.props.cashier.location) {
				this.props.onModalDisplayAction(SET_CASHIER_SEARCH_PATIENT_DISPLAY, true)
			}
		}

		if (!_.isEqual(prevProps.cashier.patient, this.props.cashier.patient)) {
			if (this.props.cashier.patient) {
				Utils.notification(
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.notification }),
					this.props.cashier.patient.notifications,
					"warning"
				)
			}
		}
	}

	onContextMenu(e) {
		this.props.setValue(SET_CONTEXT_MENU_PATIENT_DISPLAY_PATIENT_INFO, false)
		contextMenu.show({
			id: ResourceAssistance.ContextMenu.patient,
			event: e,
			props: {
				pid: this.props.cashier.patient.id,
				patient: {
					...this.props.cashier.patient,
				},
			},
		})
	}

	render() {
		return (
			<>
				{this.props.cashier.patient && (
					<Container
						fluid
						id={this.state.componentName}
						className={ResourceAssistance.CSS.fullFlex}
						onContextMenu={this.onContextMenu}
					>
						<Row className={"g-0 full-size"}>
							<Col style={{ maxWidth: "437px" }}>
								<CashierLeft />
							</Col>
							<Col>
								<CashierRight />
							</Col>
						</Row>
					</Container>
				)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Cashier))
