import { notification, Select } from "antd"
import BigNumber from "bignumber.js"
import _ from "lodash"
import moment from "moment"
import { createIntl, createIntlCache } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"
import Messages from "~/i18n/message"
import { store } from "~/redux/Store"
import ServerUtils from "./ServerUtils"
import "moment/locale/th" // without this line it didn't work
import THBText from "thai-baht-text"
import { PrintableDataFactory } from "./factory/print/PrintableDataFactory"

export const BigNumberRoundingMode = {
	ROUND_UP: 0,
	ROUND_DOWN: 1,
	ROUND_CEIL: 2,
	ROUND_FLOOR: 3,
	ROUND_HALF_UP: 4,
	ROUND_HALF_DOWN: 5,
	ROUND_HALF_EVEN: 6,
	ROUND_HALF_CEIL: 7,
	ROUND_HALF_FLOOR: 8,
}

class Utils extends ServerUtils {
	static BigNumber(num, decimalPlace = 20, roundingMode = BigNumberRoundingMode.ROUND_HALF_UP) {
		let BN = BigNumber.clone({ DECIMAL_PLACES: decimalPlace, ROUNDING_MODE: roundingMode })
		if (num === undefined || !num) {
			return new BN(0)
		}
		return new BN(num.toString().replace(/,/g, ""))
	}

	static allocateBillDiscount(billingStatements, discount) {
		let billBalance = this.calculateBillingStatementBalance(billingStatements)
		let curDiscount = Utils.BigNumber(0)
		return billingStatements.reduce((obj, cur, idx) => {
			if (curDiscount.isGreaterThanOrEqualTo(discount)) {
				return {
					...obj,
					[cur.id]: 0,
				}
			}
			let billAdjustment
			let bsDiscount = Utils.BigNumber(cur.charge).minus(cur.adjustment).dividedBy(billBalance).times(discount)
			curDiscount = curDiscount.plus(bsDiscount.toFixed(2))
			if (curDiscount.isGreaterThan(discount)) {
				billAdjustment = bsDiscount.minus(curDiscount.minus(discount)).toFixed(2)
			} else if (idx === billingStatements.length - 1 && curDiscount.isLessThan(discount)) {
				billAdjustment = bsDiscount.plus(discount.minus(curDiscount)).toFixed(2)
			} else {
				billAdjustment = bsDiscount.toFixed(2)
			}
			return {
				...obj,
				[cur.id]: billAdjustment,
			}
		}, {})
	}

	static calculateAge = (dob) => {
		let diff_ms = 0
		if (typeof dob === "number") {
			diff_ms = Date.now() - dob
		} else {
			diff_ms = Date.now() - dob.getTime()
		}

		if (diff_ms < 0) {
			return 0
		}
		let age_dt = new Date(diff_ms)

		return Math.abs(age_dt.getUTCFullYear() - 1970)
	}

	static calculateAgeMonth = (dob) => {
		let birthDate
		if (typeof dob === "number") {
			birthDate = new Date(dob)
		} else {
			birthDate = dob
		}
		let today = new Date()

		if (today.getMonth() >= birthDate.getMonth()) {
			var monthAge = today.getMonth() - birthDate.getMonth()
		} else {
			monthAge = 12 + today.getMonth() - birthDate.getMonth()
		}

		// if (dateNow >= dateDob) {
		// 	var dateAge = dateNow - dateDob
		// } else {
		// 	monthAge--
		// 	var dateAge = 31 + dateNow - dateDob

		// 	if (monthAge < 0) {
		// 		monthAge = 11
		// 		yearAge--
		// 	}
		// }

		return monthAge
	}

	static calculateDaysBetween = (dateTimeA, dateTimeB, dayOffSet = 0) => {
		if (!dateTimeA || !dateTimeB) {
			return 0
		}
		let a = new Date(dateTimeA)
		let b = new Date(dateTimeB)
		return Utils.BigNumber(new Date(b.getFullYear(), b.getMonth(), b.getDate()).getTime())
			.minus(new Date(a.getFullYear(), a.getMonth(), a.getDate()).getTime())
			.dividedBy(24 * 60 * 60 * 1000)
			.plus(dayOffSet)
			.toFixed(0)
	}

	static calculateHourAndMinute(start, end) {
		const startTimeInMinutes = start / 60000
		const endTimeInMinutes = end / 60000
		const differenceInMinutes = endTimeInMinutes - startTimeInMinutes
		const hours = Math.floor(differenceInMinutes / 60)
		const minutes = differenceInMinutes % 60
		if (hours === 0 && minutes === 0) {
			return ""
		}
		return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`
	}

	static calculateTextWidthWithW(text, font) {
		let canvas = document.createElement("canvas")
		let context = canvas.getContext("2d")
		context.font = font
		let strW = new Array(text.length + 1).join("w")
		let width = context.measureText(strW).width
		return Math.ceil(width)
	}

	static calculateStepSize = (values, tickCount) => {
		let min = Math.min(...values)
		let max = Math.max(...values)
		let range = max - min
		let unroundedTickSize = range / (tickCount - 1)
		let x = Math.ceil(Math.log10(unroundedTickSize) - 1)
		let pow10x = Math.pow(10, x)
		return Math.ceil(unroundedTickSize / pow10x) * pow10x
	}

	static calculateDoctorOrderQtyByRate = (durationQty, duration, conversionQty, isOneTimeUnit = false) => {
		if (_.isEmpty(duration)) {
			duration = 1
		}
		return isOneTimeUnit
			? Utils.BigNumber(durationQty, 0, BigNumberRoundingMode.ROUND_UP).times(duration).div(conversionQty)
			: BigNumber.clone()(durationQty).times(duration)
		// .div(conversionQty)
	}

	static calculateDoctorOrderQty = (doctorOrder, startDateTime = doctorOrder.startDateTime, endDateTime = doctorOrder.endDateTime) => {
		//Old doctor order made before scheduled time introduced.
		if (Utils.BigNumber(doctorOrder.duration).eq(0) && _.isEmpty(doctorOrder.serviceCode) && !doctorOrder.oneTimeOnly) {
			return this.BigNumber(0).toFixed(2)
		}

		//Newly doctor order made after schdueld time introduced.
		let qty = Utils.BigNumber(0)
		if (doctorOrder.oneTimeOnly) {
			qty = qty.plus(doctorOrder.durationQty)
		} else {
			for (let i = doctorOrder.startDateTime; i <= doctorOrder.endDateTime; i = i + Utils.BigNumber(doctorOrder.duration).times(60000).toNumber()) {
				if (i >= startDateTime && i <= endDateTime && !doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(i).weekday())) {
					qty = qty.plus(doctorOrder.durationQty)
				}
			}
		}
		return qty.dp(0, BigNumberRoundingMode.ROUND_UP)
	}

	static calculateDoctorOrderBalance = (doctorOrder) => {
		//Old doctor order made before scheduled time introduced.
		if (Utils.BigNumber(doctorOrder.duration).eq(0) && _.isEmpty(doctorOrder.serviceCode) && !doctorOrder.oneTimeOnly) {
			return this.BigNumber(0).toFixed(2)
		}

		//Newly doctor order made after schdueld time introduced.
		let qty = Utils.BigNumber(0)
		// if (doctorOrder.prn && doctorOrder.prnDispensingRecords) {
		// 	qty = doctorOrder.prnDispensingRecords
		// 		.reduce((total, record) => {
		// 			return total.plus(record.dispensingQty)
		// 		}, Utils.BigNumber(0))
		// 		.toNumber()
		// } else
		if (!_.isEmpty(doctorOrder.serviceCode)) {
			if (doctorOrder.duration && !_.isEmpty(doctorOrder.details)) {
				let rst = doctorOrder.details
					.reduce((total, cur) => {
						return total.plus((cur.endDateTime - cur.startDateTime) / 60000)
					}, Utils.BigNumber(0, 0, BigNumberRoundingMode.ROUND_CEIL))
					.dividedBy(doctorOrder.duration)
				return rst.times(doctorOrder.pricePerUnit).toFixed(2)
			} else {
				qty = this.calculateDaysBetween(doctorOrder.startDateTime, doctorOrder.endDateTime, 1)
				return this.BigNumber(doctorOrder.pricePerUnit).times(qty).toFixed(2)
			}
		} else {
			if (doctorOrder.oneTimeOnly) {
				qty = qty.plus(doctorOrder.durationQty)
			} else {
				for (let i = doctorOrder.startDateTime; i <= doctorOrder.endDateTime; i = i + Utils.BigNumber(doctorOrder.duration).times(60000).toNumber()) {
					if (!doctorOrder.exclusiveDays.map((each) => Number(each)).includes(moment(i).weekday())) {
						qty = qty.plus(doctorOrder.durationQty)
					}
				}
			}
			return this.BigNumber(doctorOrder.pricePerUnit).times(qty.dp(0, BigNumberRoundingMode.ROUND_UP)).toFixed(2)
		}
	}

	static calculateNurseOrderQty = (nurseOrder, startDateTime = nurseOrder.startDateTime, endDateTime = nurseOrder.endDateTime) => {
		let days = Utils.BigNumber(this.calculateDaysBetween(startDateTime, endDateTime)).plus(1).toNumber()
		return Utils.BigNumber(days).times(nurseOrder.qtyPerDay).toNumber()
	}

	static calculateNurseOrderBalance = (nurseOrder) => {
		let days = Utils.BigNumber(this.calculateDaysBetween(nurseOrder.startDateTime, nurseOrder.endDateTime)).plus(1).toNumber()
		return Utils.BigNumber(days).times(nurseOrder.qtyPerDay).times(nurseOrder.pricePerUnit).toFixed(2)
	}

	static calculatePatientDeposit = (transactions) => {
		return transactions
			.reduce((total, cur) => {
				return (total = total.plus(cur.amount))
			}, Utils.BigNumber(0))
			.toFixed(2)
	}

	static calculatePharmacyReturnOrderBalance = (pharmacyReturnOrders, pricePerUnit) => {
		if (pharmacyReturnOrders === null || pharmacyReturnOrders === undefined) {
			return 0
		}
		return pharmacyReturnOrders
			.reduce((total, cur) => {
				cur.items.forEach((item) => {
					total = total.plus(Utils.BigNumber(item.amount).times(pricePerUnit))
				})
				return total
			}, Utils.BigNumber(0))
			.toFixed(2)
	}

	static calculateBillingStatementBalance(billingStatements) {
		return billingStatements
			.filter((bs) => !bs.billing)
			.reduce((total, cur) => {
				return total.plus(cur.charge).minus(cur.adjustment)
			}, Utils.BigNumber(0))
	}

	static convertArrayToObject = (array, key) => {
		const initialValue = {}
		return array.reduce((obj, item) => {
			return {
				...obj,
				[item[key]]: item,
			}
		}, initialValue)
	}

	static converArrayToObjectByCustomKey = (array, keyArray) => {
		const initialValue = {}
		return array.reduce((obj, item) => {
			return {
				...obj,
				[keyArray.reduce((obj, key) => {
					return obj + (item[key] !== undefined ? item[key].toLowerCase().trim() : "")
				}, "")]: item,
			}
		}, initialValue)
	}

	static convertEnum = (e, isReturnObj = true) => {
		let em = {
			CANCELLED: ResourceAssistance.Message.cancelled,
			DISPENSED: ResourceAssistance.Message.dispensed,
			IN_PROGRESS: ResourceAssistance.Message.inProgress,
			MODIFIED: ResourceAssistance.Message.modified,
			PENDING: ResourceAssistance.Message.pending,
			RECEIVED: ResourceAssistance.Message.received,
			VERIFIED: ResourceAssistance.Message.verified,
			SKIPPED: ResourceAssistance.Message.skipped,
			ชาย: ResourceAssistance.Message.male,
			หญิง: ResourceAssistance.Message.female,
		}
		return isReturnObj ? translate(em[e]) : em[e]
	}

	static convertToObject(json) {
		// Check if an object is an array
		var isObject = function (value) {
			return typeof value === "object"
		}

		// Iterate object properties and store all reference keys and references
		var getKeys = function (obj, key) {
			var keys = []
			for (var i in obj) {
				// Skip methods
				if (!obj.hasOwnProperty(i)) {
					continue
				}

				if (isObject(obj[i])) {
					keys = keys.concat(getKeys(obj[i], key))
				} else if (i === key) {
					keys.push({ key: obj[key], obj: obj })
				}
			}

			return keys
		}

		var convertToObjectHelper = function (json, key, keys) {
			// Store all reference keys and references to object map
			if (!keys) {
				keys = getKeys(json, key)

				var convertedKeys = {}

				for (var i = 0; i < keys.length; i++) {
					convertedKeys[keys[i].key] = keys[i].obj
				}

				keys = convertedKeys
			}

			var obj = json

			// Iterate all object properties and object children
			// recursively and replace references with real objects
			for (var j in obj) {
				// Skip methods
				if (!obj.hasOwnProperty(j)) {
					continue
				}

				if (isObject(obj[j])) {
					// Property is an object, so process its children
					// (note well: recursive call)
					convertToObjectHelper(obj[j], key, keys)
				} else if (j === key) {
					// Remove reference id
					delete obj[j]
				} else if (keys[obj[j]]) {
					// Replace reference with real object
					obj[j] = keys[obj[j]]
				}
			}

			return obj
		}

		// As discussed above, the serializer needs to use some unique property name for
		// the IDs it generates. Here we use "@id" since presumably prepending the "@" to
		// the property name is adequate to ensure that it is unique. But any unique
		// property name can be used, as long as the same one is used by the serializer
		// and deserializer.
		//
		// Also note that we leave off the 3rd parameter in our call to
		// convertToObjectHelper since it will be initialized within that function if it
		// is not provided.
		return convertToObjectHelper(json, "@id")
	}

	static formatNumberFromStr(numStr) {
		if (!numStr) {
			return Utils.BigNumber(0)
		}
		let num = numStr.replace(/,/g, "")
		return BigNumber(num).isNaN() ? numStr.slice(0, numStr.length - 1) : Utils.BigNumber(num)
	}

	static formatNumWithComma(num) {
		if (num !== undefined) {
			return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
		}
	}

	static formatDate(dateTime) {
		if (dateTime) {
			// let date = new Date(dateTime)
			// return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()).toLocaleDateString(
			// 	undefined,
			// 	{ day: "2-digit", month: "2-digit", year: "numeric" }
			// )
			moment.locale(process.env.REACT_APP_DATE_LOCALE)
			return moment(dateTime).seconds(0).milliseconds(0).format(process.env.REACT_APP_DATE_FORMAT)
		}
		return ""
	}

	static formatTime(dateTime) {
		if (dateTime) {
			// let date = new Date(dateTime)
			// return date.toLocaleTimeString()
			moment.locale(process.env.REACT_APP_DATE_LOCALE)
			return moment(dateTime).milliseconds(0).format(process.env.REACT_APP_TIME_FORMAT)
		}
		return ""
	}

	static formatDateTime(dateTime) {
		if (dateTime) {
			// let date = new Date(dateTime)
			// return date.toLocaleString()
			moment.locale(process.env.REACT_APP_DATE_LOCALE)
			return moment(dateTime).milliseconds(0).format(process.env.REACT_APP_DATE_TIME_FORMAT)
		}
		return ""
	}

	static generateDate = (year = 0, month = 0, date = 0, hour = 0, minute = 0, second = 0) => {
		const d = new Date()
		return new Date(d.getFullYear() + year, d.getMonth() + month, d.getDate() + date, hour, minute, second)
	}

	static generateDateFromLong = (datetime = 0, year = 0, month = 0, date = 0, hour = 0, minute = 0, second = 0) => {
		const d = new Date(datetime)
		return new Date(d.getFullYear() + year, d.getMonth() + month, d.getDate() + date, d.getHours() + hour, d.getMinutes() + minute, d.getSeconds() + second)
	}

	static generateDateByFormat = (dateStr, format) => {
		format = format || "yyyy-mm-dd" // default format
		let parts = dateStr.match(/(\d+)/g)
		let i = 0,
			fmt = {}
		// extract date-part indexes from the format
		format.replace(/(yyyy|dd|mm)/g, function (part) {
			fmt[part] = i++
		})

		return new Date(parts[fmt["yyyy"]], parts[fmt["mm"]] - 1, parts[fmt["dd"]])
	}

	static getComputedStyle(html) {
		return window.getComputedStyle(html)
	}

	static getAgingTotal(values, filterDateTime, rangeStart, rangeEnd) {
		return values
			.filter((each) => {
				let paymentTermDateTime = each.paymentTermDateTime
				return (
					(Utils.BigNumber(rangeStart).isFinite() ? paymentTermDateTime <= filterDateTime - rangeStart * 24 * 60 * 60 * 1000 : true) &&
					(Utils.BigNumber(rangeEnd).isFinite() ? paymentTermDateTime >= filterDateTime - rangeEnd * 24 * 60 * 60 * 1000 : true)
				)
			})
			.reduce((total, cur) => {
				return total.plus(cur.total)
			}, Utils.BigNumber(0))
	}

	static getGeneralLedgerCode(locationCOA = "", sourceActivities, targetActivities, isAccountsReceivable = true) {
		let emptyGL = {
			fullCode: "",
			displayName: "",
			description: "",
			transDescr: "",
		}
		let sourceGL = undefined
		if (_.isEmpty(sourceActivities)) {
			let activities = []

			if (isAccountsReceivable) {
				activities = targetActivities.filter(
					(activity) =>
						!activity.linkGL &&
						activity.receivable &&
						locationCOA
							.split(ResourceAssistance.PROGRAM_DEFINED.split)
							.filter((each) => Boolean(each))
							.some((each) => activity.chartOfAccounts.fullCode.startsWith(each))
				)
			} else {
				activities = targetActivities.filter(
					(activity) =>
						!activity.linkGL &&
						!activity.receivable &&
						locationCOA
							.split(ResourceAssistance.PROGRAM_DEFINED.split)
							.filter((each) => Boolean(each))
							.some((each) => activity.chartOfAccounts.fullCode.startsWith(each))
				)
			}
			if (activities.length === 1) {
				sourceGL = activities[0]
			}
		} else {
			sourceGL = sourceActivities.find((each) => !each.linkGL && each.chartOfAccounts.fullCode.startsWith(locationCOA))
		}
		if (!sourceGL) {
			return emptyGL
		}
		if (isAccountsReceivable) {
			let filteredActivities = targetActivities.filter(
				(each) => each.receivable && !each.linkGL && each.chartOfAccounts.fullCode.startsWith(sourceGL.chartOfAccounts.fullCode)
			)
			return filteredActivities.length === 1
				? {
						...filteredActivities[0].chartOfAccounts,
						transDescr: filteredActivities[0].description,
				  }
				: emptyGL
		} else {
			let filteredActivities = targetActivities.filter(
				(each) => !each.receivable && !each.linkGL && each.chartOfAccounts.fullCode.startsWith(sourceGL.chartOfAccounts.fullCode)
			)
			return filteredActivities.length === 1
				? {
						...filteredActivities[0].chartOfAccounts,
						transDescr: filteredActivities[0].description,
				  }
				: emptyGL
		}
	}

	static getYesNoMsg(input) {
		if (input === "" || input === undefined) {
			return ""
		}

		return input === true || input === "true"
			? PrintableDataFactory.getIntl().formatMessage({ id: ResourceAssistance.Message.yes })
			: PrintableDataFactory.getIntl().formatMessage({ id: ResourceAssistance.Message.no })
	}

	static groupBy(array, ...keys) {
		if (keys.length === 1) {
			let key = keys[0]
			return array.reduce((rv, x) => {
				;(rv[x[key]] = rv[x[key]] || []).push(x)
				return rv
			}, {})
		} else {
			return array.reduce((rv, x) => {
				let key = keys.reduce((combineKey, cur) => {
					let target = x
					if (cur.includes("/")) {
						let levelKeys = cur.split("/")
						levelKeys.forEach((each) => {
							target = target[each]
						})
					} else {
						target = x[cur]
					}
					if (_.isEmpty(combineKey)) {
						return combineKey.concat(target)
					} else {
						return combineKey.concat("#", target)
					}
				}, "")

				;(rv[key] = rv[key] || []).push(x)
				return rv
			}, {})
		}
	}

	static hasPrivilege(privilege, roles = []) {
		let permissions = []
		roles.forEach((role) => {
			permissions = permissions.concat(role.permissions)
		})
		return permissions.some((prv) => prv.displayName === privilege)
	}

	static isListsEqual(list1, list2, equalProperties = []) {
		if (list1.length !== list2.length) return false

		return (
			list1.every((item1) => list2.some((item2) => this.isObjectEqual(item1, item2, equalProperties))) &&
			list2.every((item2) => list1.some((item1) => this.isObjectEqual(item2, item1, equalProperties)))
		)
	}

	static isObjectEqual(object1, object2, equalProperties = []) {
		if (_.isEmpty(equalProperties)) {
			return true
		}
		let properties = Array.from(equalProperties)
		for (let key of properties.shift()) {
			const val1 = object1[key]
			const val2 = object2[key]

			const areObjects = this.isObject(val1) && this.isObject(val2)

			// If both values are objects, recursively compare them
			// Otherwise, check if the values are strictly equal
			if ((areObjects && !this.isObjectEqual(val1, val2, properties)) || (!areObjects && val1 !== val2)) {
				return false
			}
		}
		return true
	}

	static isObject(object) {
		return object != null && typeof object === "object"
	}

	static isDevMode(env) {
		return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
	}

	static isTimeIntersect(startDateTimeA, endDateTimeA, startDateTimeB, endDateTimeB) {
		return (
			(startDateTimeB <= startDateTimeA && endDateTimeB >= endDateTimeA) ||
			(startDateTimeB <= startDateTimeA && endDateTimeB <= endDateTimeA) ||
			(startDateTimeB <= endDateTimeA && endDateTimeB >= endDateTimeA) ||
			(startDateTimeB >= startDateTimeA && endDateTimeB <= endDateTimeA)
		)
	}

	static parseFloat(num) {
		return Math.round(parseFloat((num * Math.pow(10, 8)).toFixed(8))) / Math.pow(10, 8)
	}

	static preventEnterKeyPress(event) {
		let keyCode = event.keyCode ? event.keyCode : event.which
		if (keyCode === ResourceAssistance.KeyCode.enter) {
			event.preventDefault()
		}
	}

	static renderOptions(options, includeDefaultOption = true, defaultOptionValue = "", displayProperty = "displayName") {
		let html = options
			.sort((a, b) => Utils.sort(a[displayProperty], b[displayProperty]))
			.map((loc, key) => {
				return (
					<option key={key} value={key} style={loc.active !== undefined && !loc.active ? { backgroundColor: ResourceAssistance.CSS.Color.red } : {}}>
						{loc[displayProperty]}
					</option>
				)
			})

		if (includeDefaultOption) {
			return Object.assign([], html, [
				<option key={-1} value={defaultOptionValue}>
					{ResourceAssistance.Symbol.space}
				</option>,
				...html,
			])
		}

		return html
	}

	static renderSelects = (options, includeDefaultOption = true, defaultOptionValue = -1, displayProperty = "displayName", isSorted = true) => {
		let html = isSorted
			? options
					.sort((a, b) => Utils.sort(a[displayProperty], b[displayProperty]))
					.map((loc, key) => {
						return (
							<Select.Option key={key} value={key} style={loc.active !== undefined && !loc.active ? { backgroundColor: ResourceAssistance.CSS.Color.red } : {}}>
								{loc[displayProperty]}
							</Select.Option>
						)
					})
			: options.map((loc, key) => {
					return (
						<Select.Option key={key} value={key} style={loc.active !== undefined && !loc.active ? { backgroundColor: ResourceAssistance.CSS.Color.red } : {}}>
							{loc[displayProperty]}
						</Select.Option>
					)
			  })

		if (includeDefaultOption) {
			return Object.assign([], html, [
				<Select.Option key={-1} value={defaultOptionValue}>
					{ResourceAssistance.Symbol.space}
				</Select.Option>,
				...html,
			])
		}
		return html
	}

	static replaceDuplicateEmptyLine(str) {
		return str.replace(/^\s*$(?:\r\n?|\n)/gm, "")
	}

	static replaceAllEmptyLines(str) {
		return str.replace(/(?:\r\n|\r|\n)/g, " ")
	}

	static replaceDuplicateSpaces(str) {
		str = str.replace(/  +/g, " ").trim()
		return str.replace(/^,|,$/g, "")
	}

	static sort(x, y, array) {
		if (typeof x === "string" && typeof y === "string") {
			return Utils.sortWithNumber(x.toLocaleLowerCase(), y.toLocaleLowerCase())
		} else {
			if (x < y) {
				return -1
			}
			if (x > y) {
				return 1
			}
		}

		if (array && array.length > 0) {
			for (const each of array) {
				if (typeof each[0] === "string" && typeof each[1] === "string") {
					return Utils.sortWithNumber(each[0].toLocaleLowerCase(), each[1].toLocaleLowerCase())
				} else {
					if (each[0] < each[1]) {
						return -1
					}
					if (each[0] > each[1]) {
						return 1
					}
				}
			}
		}
		return 0
	}

	static sortWithNumber(a, b) {
		const aStartsWithNumber = /^\d+/.test(a)
		const bStartsWithNumber = /^\d+/.test(b)
		if (aStartsWithNumber && bStartsWithNumber) {
			const numA = parseInt(a.match(/^\d+/)[0], 10)
			const numB = parseInt(b.match(/^\d+/)[0], 10)
			return numA - numB
		}
		if (aStartsWithNumber) return -1
		if (bStartsWithNumber) return 1
		return a.localeCompare(b)
	}

	static trim(str) {
		if (str && typeof str === "string") {
			return Utils.replaceDuplicateSpaces(str.trim())
		} else {
			return str
		}
	}

	static getItemRelpsFrom = (items) => {
		return items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
							keyword: cur.keyword,
						},
					}
				})
			)
		}, [])
	}

	static base64ToArrayBuffer = (base64) => {
		var binaryString = window.atob(base64)
		var binaryLen = binaryString.length
		var bytes = new Uint8Array(binaryLen)
		for (var i = 0; i < binaryLen; i++) {
			var ascii = binaryString.charCodeAt(i)
			bytes[i] = ascii
		}
		return bytes
	}

	// This function is used to convert base64 encoding to mime type (blob)
	static base64ToBlob = (base64, mime) => {
		mime = mime || ""
		var sliceSize = 1024
		var byteChars = window.atob(base64)
		var byteArrays = []

		for (var offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
			var slice = byteChars.slice(offset, offset + sliceSize)

			var byteNumbers = new Array(slice.length)
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i)
			}

			var byteArray = new Uint8Array(byteNumbers)

			byteArrays.push(byteArray)
		}

		return new Blob(byteArrays, { type: mime })
	}

	static convertBlob = (blob, type, callback) => {
		return new Promise((resolve, reject) => {
			let canvas = this.createTempCanvas()
			let ctx = canvas.getContext("2d")
			let image = new Image()
			image.src = URL.createObjectURL(blob)
			image.onload = () => {
				canvas.width = image.width
				canvas.height = image.height
				ctx.drawImage(image, 0, 0)
				let result = this.dataURItoBlob(canvas.toDataURL(type))

				result.lastModified = blob.lastModified
				result.lastModifiedDate = blob.lastModifiedDate
				result.name = blob.name
				result.webkitRelativePath = blob.webkitRelativePath

				if (callback) {
					callback(result)
				} else {
					resolve(result)
				}
			}
		})
	}

	static dataURItoBlob = (dataURI) => {
		var byteString = window.atob(dataURI.split(",")[1])
		var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
		var ab = new ArrayBuffer(byteString.length)
		var ia = new Uint8Array(ab)
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		var blob = new Blob([ab], { type: mimeString })
		return blob
	}

	static createTempCanvas = () => {
		let canvas = document.createElement("CANVAS")
		canvas.style.display = "none"
		return canvas
	}

	static notification = (title, notifications, type) => {
		notification.destroy()
		if (_.isEmpty(notifications)) {
			return
		}
		notifications.forEach((each) => {
			let args = {
				placement: "topRight",
				top: 72,
				message: title,
				description: each.message,
				duration: 0,
			}
			switch (type) {
				case "success":
					notification.success(args)
					break
				case "error":
					notification.error(args)
					break
				case "info":
					notification.info(args)
					break
				case "warning":
					notification.warning(args)
					break
				default:
					notification.open(args)
					break
			}
		})
	}

	static getIntl = () => {
		if (this.intl === undefined || !_.isEqual(this.locale, store.getState().language.locale)) {
			this.locale = store.getState().language.locale
			this.messages = Messages[this.locale]
			this.cache = createIntlCache()
			this.intl = createIntl({ locale: this.locale, messages: this.messages }, this.cache)
		}
		return this.intl
	}

	static convertNumberToThaiLetters = (number) => {
		return THBText(number)
	}

	static convertNumberToUSLetters = (number) => {
		let units = ["", "Thousand", "Million", "Billion", "Trillion"]
		let belowTwenty = [
			"",
			"One",
			"Two",
			"Three",
			"Four",
			"Five",
			"Six",
			"Seven",
			"Eight",
			"Nine",
			"Ten",
			"Eleven",
			"Twelve",
			"Thirteen",
			"Fourteen",
			"Fifteen",
			"Sixteen",
			"Seventeen",
			"Eighteen",
			"Nineteen",
		]
		let tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"]
		function convertLessThanThousand(num) {
			if (num === 0) {
				return ""
			} else if (num < 20) {
				return belowTwenty[num] + " "
			} else if (num < 100) {
				return tens[Math.floor(num / 10)] + " " + convertLessThanThousand(num % 10)
			} else {
				return belowTwenty[Math.floor(num / 100)] + " Hundred " + convertLessThanThousand(num % 100)
			}
		}
		function convert(num) {
			if (num === 0) {
				return "Zero"
			}
			let result = ""
			for (let i = 0; num > 0; i++, num = Math.floor(num / 1000)) {
				if (num % 1000 !== 0) {
					result = convertLessThanThousand(num % 1000) + units[i] + " " + result
				}
			}
			return result.trim()
		}
		if (isNaN(number)) {
			return "Invalid input"
		}
		const dollarsText = convert(Math.floor(number))
		const centsText = Math.round((number - Math.floor(number)) * 100)
		const centsPart = centsText === 0 ? "" : `and ${convertLessThanThousand(centsText)}`
		return dollarsText + " " + centsPart
	}

	static getLogoImg = (orgId, locations) => {
		const selectedOrg = locations.find((loc) => loc.id === orgId)
		let logo = "data:image/jpeg;base64"

		if (selectedOrg) {
			const fileType = selectedOrg.logoName.split(".")[1] || "jpeg"
			const logoType = fileType === "png" ? "data:image/png;base64" : "data:image/jpeg;base64"
			logo = `${logoType},${selectedOrg.encodedFile}`
		}

		return logo
	}
}

export { Utils }
