import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES,
	SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE,
	SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT,
	SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES,
	SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL,
	SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY,
	SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE,
	SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OPDModalMedicalRecordHealthHistory extends React.Component {
	constructor(props) {
		super(props)

		this.onHeight = this.onHeight.bind(this)
		this.onWeight = this.onWeight.bind(this)
		this.onUnderlyingDisease = this.onUnderlyingDisease.bind(this)
		this.onAllergies = this.onAllergies.bind(this)
		this.onPastIllnesses = this.onPastIllnesses.bind(this)
		this.onPastSurgicalHistory = this.onPastSurgicalHistory.bind(this)
		this.onFamilyHealthHistory = this.onFamilyHealthHistory.bind(this)
		this.onAccidentHistory = this.onAccidentHistory.bind(this)
		this.onMedicineHistory = this.onMedicineHistory.bind(this)
		this.onSupplementOrHerbsHistory = this.onSupplementOrHerbsHistory.bind(this)
		this.onAlcoholHistory = this.onAlcoholHistory.bind(this)
		this.onTobaccoHistory = this.onTobaccoHistory.bind(this)
		this.onPassiveSmokeExposure = this.onPassiveSmokeExposure.bind(this)
		this.onDoctorNote = this.onDoctorNote.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedPatient.patient) {
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT, this.props.opd.selectedPatient.patient.height)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT, this.props.opd.selectedPatient.patient.weight)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE,
				this.props.opd.selectedPatient.patient.underlyingDisease
			)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES, this.props.opd.selectedPatient.patient.allergies)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES,
				this.props.opd.selectedPatient.patient.pastIllnesses
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL,
				this.props.opd.selectedPatient.patient.pastSurgicalHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY,
				this.props.opd.selectedPatient.patient.familyHealthHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY,
				this.props.opd.selectedPatient.patient.accidentHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY,
				this.props.opd.selectedPatient.patient.medicineHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY,
				this.props.opd.selectedPatient.patient.supplementOrHerbsHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY,
				this.props.opd.selectedPatient.patient.alcoholHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY,
				this.props.opd.selectedPatient.patient.tobaccoHistory
			)
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY,
				this.props.opd.selectedPatient.patient.passiveSmokeExposure
			)
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE, this.props.opd.selectedPatient.patient.doctorNote)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT, "")
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE, ResourceAssistance.CONSTANT.NONE)
	}

	onHeight(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HEIGHT, event.target.value)
	}

	onWeight(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_WEIGHT, event.target.value)
	}

	onUnderlyingDisease(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_UNDERLYING_DISEASE, event.target.value)
	}

	onAllergies(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ALLERGIES, event.target.value)
	}

	onPastIllnesses(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PAST_ILLNESSES, event.target.value)
	}

	onPastSurgicalHistory(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PAST_SURGICAL, event.target.value)
	}

	onFamilyHealthHistory(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_FAMILY_HISTORY, event.target.value)
	}

	onAccidentHistory(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ACCIDENT_HISTORY, e.target.value)
	}

	onMedicineHistory(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_MEDICINE_HISTORY, e.target.value)
	}

	onSupplementOrHerbsHistory(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_HERBS_HISTORY, e.target.value)
	}

	onAlcoholHistory(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ALCOHOL_HISTORY, e.target.value)
	}

	onTobaccoHistory(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_TOBACCO_HISTORY, e.target.value)
	}

	onPassiveSmokeExposure(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_PASSIVE_SMOKE_EXPOSURE_HISTORY, e.target.value)
	}

	onDoctorNote(e) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_DOCTOR_NOTE, e.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.healthHistory)}</legend>
				<Row>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							step={1}
							min={0}
							max={280}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.height })} (${
								ResourceAssistance.CONSTANT.CM
							})`}
							value={this.props.opd.modalMedicalRecord.height}
							onChange={this.onHeight}
						/>
					</Col>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.weight })} (${
								ResourceAssistance.CONSTANT.KG
							})`}
							step={0.1}
							min={0}
							max={450}
							value={this.props.opd.modalMedicalRecord.weight}
							onChange={this.onWeight}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.alcohol)}
							value={this.props.opd.modalMedicalRecord.alcoholHistory}
							onChange={this.onAlcoholHistory}
						/>
					</Col>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.tobacco)}
							value={this.props.opd.modalMedicalRecord.tobaccoHistory}
							onChange={this.onTobaccoHistory}
						/>
					</Col>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.passiveSmokeExposure)}
							value={this.props.opd.modalMedicalRecord.passiveSmokeExposure}
							onChange={this.onPassiveSmokeExposure}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Hospitel.underlyingDisease)}
							value={this.props.opd.modalMedicalRecord.underlyingDisease}
							onChange={this.onUnderlyingDisease}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Hospitel.allergies)}
							value={this.props.opd.modalMedicalRecord.allergies}
							onChange={this.onAllergies}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Hospitel.pastIllnesses)}
							value={this.props.opd.modalMedicalRecord.pastIllnesses}
							onChange={this.onPastIllnesses}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.pastSurgicalHistory)}
							value={this.props.opd.modalMedicalRecord.pastSurgicalHistory}
							onChange={this.onPastSurgicalHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.familyHealthHistory)}
							value={this.props.opd.modalMedicalRecord.familyHealthHistory}
							onChange={this.onFamilyHealthHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.accidentHistory)}
							value={this.props.opd.modalMedicalRecord.accidentHistory}
							onChange={this.onAccidentHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.supplementOrHerbsHistory)}
							value={this.props.opd.modalMedicalRecord.supplementOrHerbsHistory}
							onChange={this.onSupplementOrHerbsHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							placeholder={translate(ResourceAssistance.Message.medicineHistory)}
							value={this.props.opd.modalMedicalRecord.medicineHistory}
							onChange={this.onMedicineHistory}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							placeholder={translate(ResourceAssistance.Message.doctorNote)}
							value={this.props.opd.modalMedicalRecord.doctorNote}
							onChange={this.onDoctorNote}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecordHealthHistory))
