import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_ITEM_SOURCE_DISPLAY_ALL_IND,
	SET_ADMIN_ITEM_SOURCE_ITEMS,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_ACTIVE,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_NAME,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_SELECTED_TYPE,
	SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_TYPES,
	SET_ADMIN_ITEM_SOURCE_SELECTED_ITEM,
	SET_ADMIN_ITEM_SOURCE_SELECTED_TYPE,
	SET_ADMIN_ITEM_SOURCE_TYPES,
} from "../../type/type/ADMIN"

export const setAdmISItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items.filter((each) => {
			if (getState().admin.itemConfig.itemSource.displayInactive) {
				return each.displayName.toLowerCase().includes(getState().admin.itemConfig.itemSource.searchName.toLowerCase())
			} else {
				return each.active && each.displayName.toLowerCase().includes(getState().admin.itemConfig.itemSource.searchName.toLowerCase())
			}
		})

		if (getState().admin.itemConfig.itemSource.selectedType.type) {
			filtered = filtered.filter((each) => {
				return each.type.id === getState().admin.itemConfig.itemSource.selectedType.type.id
			})
		}

		filtered.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.itemConfig.itemSource.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.displayName, each.warnings, each.keyword, each.createdBy.displayName, Utils.formatDate(each.creationDateTime)]
		})

		dispatch({
			type: SET_ADMIN_ITEM_SOURCE_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmISTypes = (types) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_TYPES,
		payload: {
			types: types,
		},
	}
}

export const setAdmISSelectedType = (index, type) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_SELECTED_TYPE,
		payload: {
			index: index,
			type: type,
		},
	}
}

export const setAdmISDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmISSelectedItem = (index, item) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_SELECTED_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setAdmISModalNISTypes = (types) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_TYPES,
		payload: {
			types: types,
		},
	}
}

export const setAdmISModalNISSelectedType = (index, type) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_SELECTED_TYPE,
		payload: {
			index: index,
			type: type,
		},
	}
}

export const setAdmISModalNISName = (name) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_NAME,
		payload: {
			name: name,
		},
	}
}

export const setAdmISModalNISActive = (isActive) => {
	return {
		type: SET_ADMIN_ITEM_SOURCE_MODAL_NEW_ITEM_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}
