import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK,
} from "~/redux/type"
import DebtModalBSAdjustmentDiscount from "./DebtModalAdjustmentDiscount"

class DebtModalAdjustmentDetails extends React.Component {
	componentDidMount() {
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT, 0)
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK, ResourceAssistance.CONSTANT.NONE)
	}

	componentWillUnmount() {
		this.props.setValue(SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX, 0)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<DebtModalBSAdjustmentDiscount />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtModalAdjustmentDetails)
