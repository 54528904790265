import { Descriptions, Divider } from "antd"
import React from "react"
import { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalScheduleDetails extends React.Component {
	getHistory() {
		let res = []
		let ary = this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.remark
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
		for (let i = 0; i < ary.length; i += 4) {
			res.push(
				<Fragment key={i}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.from)} span={1}>
						{ary[i]}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.to)} span={1}>
						{ary[i + 1]}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.by)} span={1}>
						{ary[i + 2]}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{ary[i + 3]}
					</Descriptions.Item>
				</Fragment>
			)
		}
		return res
	}

	render() {
		let event = this.props.operatingRoom.selectedEvent.event
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<Descriptions style={{ flex: "1", display: "unset" }} size={"default"} contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }} column={3}>
							<Descriptions.Item label={translate(ResourceAssistance.Message.patientId)} span={1}>
								{event ? event.extendedProps.schedule.request.clinicalRecord.admission.patient.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
								{event ? event.extendedProps.schedule.request.clinicalRecord.admission.patient.displayName : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dateOfBirth)} span={1}>
								{event
									? `${Utils.formatDate(event.extendedProps.schedule.request.clinicalRecord.admission.patient.dobtime)}(${Utils.calculateAge(
											event.extendedProps.schedule.request.clinicalRecord.admission.patient.dobtime
									  )} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.year })} ${Utils.calculateAgeMonth(
											event.extendedProps.schedule.request.clinicalRecord.admission.patient.dobtime
									  )} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.month })})`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.scheduledDate)} span={3}>
								{event
									? `${Utils.formatDateTime(event.extendedProps.schedule.startDateTime)} - ${Utils.formatDateTime(event.extendedProps.schedule.endDateTime)}`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.orCase)} span={3}>
								{event ? event.extendedProps.schedule.request.operatingRoomCase : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.anesthesia)} span={3}>
								{event ? event.extendedProps.schedule.request.anesthesiaType : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.surgeon)} span={3}>
								{event ? event.extendedProps.schedule.request.operativeDoctor : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.anesthesiologist)} span={3}>
								{event ? event.extendedProps.schedule.request.anesthesiologist : ""}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "1", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={4}
							title={translate(ResourceAssistance.Message.appointmentHistory)}
						>
							{this.getHistory()}
						</Descriptions>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalScheduleDetails))
