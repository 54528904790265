import { Table } from "antd"
import _ from "lodash"
import PropTypes from "prop-types"
import React from "react"
import "react-bootstrap"
import { injectIntl } from "react-intl"
import { Utils } from "~/utils/Utils"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

class ATable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			height: undefined,
		}
		this.observer = new IntersectionObserver(([entry]) => {
			if (entry.isIntersecting) {
				this.setState({
					height: this.props.yLength ? this.props.yLength : this.calculateHeight(),
				})
			}
		})
		this.ref = this.props.refs ? this.props.refs : React.createRef()
	}

	componentDidMount() {
		this.observer.observe(this.ref.current.nativeElement)
	}

	componentWillUnmount() {
		this.setState({
			height: undefined,
		})
		this.observer.disconnect()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.height, this.state.height)) {
			this.ref.current.nativeElement.getElementsByTagName("table")[1].style.minHeight = `${this.state.height}px`
		}
	}

	calculateHeight() {
		if (this.ref.current.nativeElement.parentNode.nodeName === "TD") {
			return undefined
		}
		let clientHeight = this.ref.current.nativeElement.parentNode.clientHeight
		let headerHeight = this.ref.current.nativeElement.getElementsByTagName("table")[0].children[1].offsetHeight
		let summary = this.ref.current.nativeElement.getElementsByClassName("ant-table-summary")[1]
		if (summary) {
			clientHeight -= summary.offsetHeight
		}
		let marginBottom = parseFloat(Utils.getComputedStyle(this.ref.current.nativeElement).marginBottom)
		return clientHeight - marginBottom - headerHeight
	}

	getHeader() {
		return this.props.data.header.map((header, cIdx) => {
			return {
				title: this.props.intl.formatMessage({ id: header }),
				dataIndex: header,
				key: header,
				align: _.isEmpty(this.props.data.align) ? "center" : this.props.data.align[cIdx],
				width: _.isEmpty(this.props.data.width) ? undefined : this.props.data.width[cIdx],
				render: (text, { colStyle }, rIdx) => {
					if (colStyle[cIdx] && colStyle[cIdx][0]) {
						return <div style={colStyle[cIdx][1]}> {text}</div>
					} else {
						return text
					}
				},
			}
		})
	}

	getBody() {
		return this.props.data.body.map((row, rIdx) => {
			return row.reduce(
				(obj, col, cIdx) => {
					return {
						...obj,
						[this.props.data.header[cIdx]]: col,
					}
				},
				{
					key: rIdx,
					colStyle: _.isEmpty(this.props.data.colStyle) ? [] : this.props.data.colStyle[rIdx],
				}
			)
		})
	}

	render() {
		return (
			<Table
				ref={this.ref}
				style={{ flex: "1 1" }}
				size="small"
				sticky={true}
				loading={this.props.loading}
				pagination={this.props.pagination}
				columns={this.getHeader()}
				dataSource={this.getBody()}
				expandable={this.props.expandable}
				scroll={{
					y: this.state.height,
				}}
				footer={this.props.footer}
				summary={this.props.summary}
			/>
		)
	}
}

ATable.propTypes = {
	data: PropTypes.object.isRequired,
	displayY: PropTypes.bool,
	yLength: PropTypes.number,
}

ATable.defaultProps = {
	data: { header: [], body: [], align: [] },
	displayY: false,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ATable))
