import { Switch } from "antd"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE,
	SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION,
	SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class ConfigAssessmentModalNewInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		let assessment = this.props.assessment.selectedAssessment.assessment
		if (assessment) {
			this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME, assessment.name)
			this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION, assessment.description)
			this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE, assessment.active)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME, "")
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION, "")
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE, true)
	}

	onName(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_NAME, event.target.value)
	}

	onDescription(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_DESCRIPTION, event.target.value)
	}

	onActive(isChecked) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_ASSESSMENT_ACTIVE, isChecked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.name)}
							value={this.props.assessment.modalNewAssessment.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							styles={{ input: { textAlign: "center" } }}
							minRows={5}
							autoFocus={false}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.assessment.modalNewAssessment.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Switch
							style={this.props.assessment.modalNewAssessment.isActive ? { backgroundColor: ResourceAssistance.CSS.Color.dark_green } : undefined}
							checkedChildren={translate(ResourceAssistance.Message.active)}
							unCheckedChildren={translate(ResourceAssistance.Message.inactive)}
							checked={this.props.assessment.modalNewAssessment.isActive}
							onChange={this.onActive}
						/>
					</Col>
					<Col />
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	assessment: state.admin.customDefaultConfig.assessment,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigAssessmentModalNewInfo))
