import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPSRequests } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PS_PAYMENT_PLAN_DISPLAY } from "~/redux/type"
import PSModalPaymentPlanDetails from "./PSModalPaymentPlanDetails"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"

class PSModalPaymentPlan extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PS_PAYMENT_PLAN_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			this.props.PS.modalPaymentPlan.pendingInvoiceTable.original.length === 0 ||
			!this.props.PS.modalPaymentPlan.selectedPaymentType.type ||
			(this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount && !this.props.PS.modalPaymentPlan.selectedBranch.branch)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.savePaymentPlan,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedPaymentPlan.paymentPlan ? this.props.PS.selectedPaymentPlan.paymentPlan.id : "",
						paymentSlipId: this.props.PS.selectedSupplier.supplier.id,
						paymentTermDateTime: this.props.PS.modalPaymentPlan.dateTime,
						paymentMethod: this.props.PS.modalPaymentPlan.selectedPaymentType.type.displayName,
						bankAccount: this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount
							? this.props.PS.modalPaymentPlan.selectedBranch.branch.account
							: "",
						bankCode: this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount ? this.props.PS.modalPaymentPlan.selectedBank.bank.code : "",
						bankName: this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount ? this.props.PS.modalPaymentPlan.selectedBank.bank.displayName : "",
						bankBranchCode: this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount
							? this.props.PS.modalPaymentPlan.selectedBranch.branch.code
							: "",
						bankBranchName: this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount
							? this.props.PS.modalPaymentPlan.selectedBranch.branch.displayName
							: "",
						amount: this.props.PS.modalPaymentPlan.pendingInvoiceTable.body
							.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[4]))
							}, Utils.BigNumber(0))
							.toFixed(2),
						fee: Utils.trim(this.props.PS.modalPaymentPlan.fee),
						remark: Utils.trim(this.props.PS.modalPaymentPlan.remark),
						invoices: this.props.PS.modalPaymentPlan.pendingInvoiceTable.filtered.map((each) => {
							let GL = Utils.getGeneralLedgerCode(
								each.receiveInvoice ? each.receive.purchaseRequest.locationAP : each.locationAP,
								[],
								this.props.PS.modalPaymentPlan.selectedPaymentType.type.activities.filter((activity) => activity.paymentSlip),
								false
							)
							return {
								...each,
								accountingCode: GL.fullCode,
								accountingChartName: GL.displayName,
								accountingChartDescription: GL.description,
								accountingTransDescr: GL.transDescr,
							}
						}),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							{this.props.PS.selectedSupplier.supplier
								? this.props.intl.formatMessage({ id: ResourceAssistance.Message.paymentPlan }) +
								  "-" +
								  this.props.PS.selectedSupplier.supplier.supplierCode +
								  "/" +
								  this.props.PS.selectedSupplier.supplier.supplierName
								: ""}
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PSModalPaymentPlanDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPaymentSlipPaymentPlanDisplay,
	login: state.login,
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPSRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalPaymentPlan))
