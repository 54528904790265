import React from "react"
import { Col, Row } from "react-bootstrap"
import { LuPackageCheck } from "react-icons/lu"
import { MdOutlineCancel } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setTfRequests, setTfSelectedTransferItem, setTfTransferItems, setTfTransfers } from "~/redux/action"
import { SET_TF_REQUEST_LOADING } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFRightTransferItemRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onTransferred = this.onTransferred.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.transfer.selectedTransfer) !== JSON.stringify(this.props.transfer.selectedTransfer)
			// ||JSON.stringify(prevProps.transfer.selectedRequest) !== JSON.stringify(this.props.transfer.selectedRequest)
		) {
			this.props.setTfSelectedTransferItem(-1, null)
			if (this.props.transfer.selectedTransfer.index === -1) {
				this.props.setTfTransferItems([])
			} else {
				this.props.setTfTransferItems(this.props.transfer.selectedTransfer.transfer.items)
			}
		}
	}

	isDisplayTransferred() {
		return (
			this.props.transfer.selectedRequest.request &&
			!this.props.transfer.selectedRequest.request.approvalApproved &&
			this.props.transfer.selectedTransfer.transfer &&
			this.props.transfer.selectedTransfer.transfer.approval
		)
	}

	isDisplayCancelled() {
		return (
			this.props.transfer.selectedRequest.request &&
			!this.props.transfer.selectedRequest.request.approvalApproved &&
			this.props.transfer.selectedTransfer.transfer &&
			this.props.transfer.selectedTransfer.transfer.approval
		)
	}

	onSelectRow(row, index) {
		this.props.setTfSelectedTransferItem(index, this.props.transfer.transferItemTable.filtered[index])
	}

	onTransferred(e, row, rIdx, cIdx, isTransferred) {
		let selectedTransferItem = this.props.transfer.transferItemTable.filtered[rIdx]
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.setTransferred,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				items: [
					{
						transferOrderItemId: selectedTransferItem.id,
						transferred: isTransferred,
					},
				],
			},
		}
		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.transferingItems)}</legend>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							enableHighlight
							striped
							hover
							enableOverlay
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlineGreen, ResourceAssistance.Button.variant.outlineRed]}
							btnMessages={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.transferred }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.cancelled }),
							]}
							btnIcons={[
								this.isDisplayTransferred() ? <LuPackageCheck size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayCancelled() ? <MdOutlineCancel size={ResourceAssistance.ReactIcon.size} /> : undefined,
							]}
							onCheckedArray={[
								(e, row, rIdx, cIdx) => {
									this.onTransferred(e, row, rIdx, cIdx, true)
								},
								(e, row, rIdx, cIdx) => {
									this.onTransferred(e, row, rIdx, cIdx, false)
								},
							]}
							data={this.props.transfer.transferItemTable}
							isClearHighlight={this.props.transfer.selectedTransferItem.index === -1}
							highlightedRow={this.props.transfer.selectedTransferItem.index}
							onClick={this.onSelectRow}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setTfTransferItems,
			setTfSelectedTransferItem,
			setTfTransfers,
			setTfRequests,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TFRightTransferItemRow))
