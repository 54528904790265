import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS, SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING } from "~/redux/type"
import FIModalSplitInventoryInfo from "./FIModalSplitInventoryInfo"
import FIModalSplitLot from "./FIModalSplitLot"

class FIModalSplitDetails extends React.Component {
	componentDidMount() {
		this.loadUnitConversions()
	}

	loadUnitConversions() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.frontInventory.getInventoryConversionUnits,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				code: this.props.frontInventory.selectedInventory.inventory.code,
			},
		}
		let callback = (res) => {
			this.props.setValue(
				SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS,
				res.data.units.filter((each) => !each.oneTime)
			)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<FIModalSplitInventoryInfo />
				<FIModalSplitLot ref={this.props.lotRef} {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FIModalSplitDetails)
