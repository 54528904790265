import { Radio, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue, setSelected } from "~/redux/action"
import { SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB, SET_OR_MODAL_POST_ANE_TRANSFER_TO } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const RTransferTo = {
	Home: "H",
	SBU: "S",
}

class OperatingRoomModalPostAneTransferDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onTransferTo = this.onTransferTo.bind(this)
		this.onSBU = this.onSBU.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedPostAneRecord.record) {
			this.props.setValue(SET_OR_MODAL_POST_ANE_TRANSFER_TO, this.props.operatingRoom.selectedPostAneRecord.record.transferTo)
			let sbuIdx = this.props.operatingRoom.SBUs.findIndex((each) => each.displayName === this.props.operatingRoom.selectedPostAneRecord.record.transferToSBU)
			if (sbuIdx > -1) {
				this.props.setSelected(SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB, sbuIdx, this.props.operatingRoom.SBUs[sbuIdx])
			}
		} else {
			this.props.setValue(SET_OR_MODAL_POST_ANE_TRANSFER_TO, RTransferTo.Home)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalPostAneTransfer.transferTo, this.props.operatingRoom.modalPostAneTransfer.transferTo)) {
			if (!_.includes(this.props.operatingRoom.modalPostAneTransfer.transferTo, RTransferTo.SBU)) {
				this.props.setSelected(SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_POST_ANE_TRANSFER_TO, RTransferTo.Home)
		this.props.setSelected(SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB, -1, null)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className="g-1">
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.transferTo)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalPostAneTransfer.transferTo} onChange={this.onTransferTo}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RTransferTo.Home}>
								{translate(ResourceAssistance.Message.home)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RTransferTo.SBU}>
								{translate(ResourceAssistance.Message.SBU)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.org)}
							disabled={_.includes(this.props.operatingRoom.modalPostAneTransfer.transferTo, RTransferTo.Home)}
							loading={this.props.operatingRoom.isSBUsLoading}
							value={
								this.props.operatingRoom.modalPostAneTransfer.selectedSBU.SBU ? this.props.operatingRoom.modalPostAneTransfer.selectedSBU.index : undefined
							}
							onChange={this.onSBU}
						>
							{Utils.renderSelects(this.props.operatingRoom.SBUs, false)}
						</Select>
					</Col>
				</Row>
			</Container>
		)
	}
	onTransferTo(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_TRANSFER_TO, e.target.value)
	}
	onSBU(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB, -1, null)
			return
		}
		this.props.setSelected(SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB, v, this.props.operatingRoom.SBUs[v])
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalPostAneTransferDetails)
