import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setARCBillingStatements, setSelected } from "~/redux/action"
import { SET_ARC_SELECTED_BILLING_STATEMENT } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceiveRightBillingStatement extends React.Component {
	constructor(props) {
		super(props)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ARC.selectedGroup, this.props.ARC.selectedGroup)) {
			if (this.props.ARC.selectedGroup.group) {
				this.props.setARCBillingStatements(this.props.ARC.selectedGroup.group[1])
			} else {
				this.props.setARCBillingStatements([])
			}
		}
		if (!_.isEqual(prevProps.ARC.billingStatementTable.filtered, this.props.ARC.billingStatementTable.filtered)) {
			let index = this.props.ARC.selectedBS.billingStatement
				? this.props.ARC.billingStatementTable.filtered.findIndex((bs) => bs.id === this.props.ARC.selectedBS.billingStatement.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_ARC_SELECTED_BILLING_STATEMENT, index, this.props.ARC.billingStatementTable.filtered[index])
			} else {
				this.props.setSelected(SET_ARC_SELECTED_BILLING_STATEMENT, -1, null)
			}
		}
	}

	onSelectRow(patient, index) {
		this.props.setSelected(SET_ARC_SELECTED_BILLING_STATEMENT, index, this.props.ARC.billingStatementTable.filtered[index])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.ARC.billingStatementTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.ARC.selectedBS.index === -1}
						highlightedRow={this.props.ARC.selectedBS.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setARCBillingStatements,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceiveRightBillingStatement))
