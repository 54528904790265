import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcDocument } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import {
	SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY,
	SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY,
} from "~/redux/type"

const EReport = {
	ADMISSION: "REPORT_ADMISSION",
	USER_ROLE: "REPORT_USER_ROLE",
	BILLING_TO_TODAY: "REPORT_BILLING_TO_TODAY",
}

class ReportCashier extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportCashier",
		}

		this.onBtnClick = this.onBtnClick.bind(this)
	}

	onBtnClick(event) {
		switch (event.target.value) {
			case EReport.ADMISSION:
				this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY, true)
				break

			case EReport.USER_ROLE:
				this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY, true)
				break

			case EReport.BILLING_TO_TODAY:
				this.props.onModalDisplayAction(SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY, true)
				break

			default:
				break
		}
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Container fluid id={this.state.componentName} className={`border ${ResourceAssistance.CSS.fullFlex}`}>
							<Row className={ResourceAssistance.CSS.fullSize} style={{ justifyContent: "center" }}>
								<Col sm="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.lg}
										value={EReport.ADMISSION}
										disabled={true}
										onClick={this.onBtnClick}
									>
										<FcDocument size={ResourceAssistance.ReactIcon.size_lg} />
										{translate(ResourceAssistance.Message.byAdmission)}
									</Button>
								</Col>
								<Col sm="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.lg}
										value={EReport.USER_ROLE}
										onClick={this.onBtnClick}
									>
										<FcDocument size={ResourceAssistance.ReactIcon.size_lg} />
										{translate(ResourceAssistance.Message.professionalServiceExpenseByUser)}
									</Button>
								</Col>
								<Col sm="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.lg}
										value={EReport.BILLING_TO_TODAY}
										onClick={this.onBtnClick}
									>
										<FcDocument size={ResourceAssistance.ReactIcon.size_lg} />
										{translate(ResourceAssistance.Message.billingToTodayByAdmission)}
									</Button>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportCashier)
