import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class OutpatientModalDoctorOrderInfoCriteria extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }} column={3}>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.startDate)}>
							{Utils.formatDateTime(this.props.opd.selectedDoctorOrder.order.startDateTime)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.endDate)}>
							{Utils.formatDateTime(this.props.opd.selectedDoctorOrder.order.endDateTime)}
						</Descriptions.Item>
						<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.duration)}>
							{Utils.calculateDaysBetween(this.props.opd.selectedDoctorOrder.order.startDateTime, this.props.opd.selectedDoctorOrder.order.endDateTime, 1)}{" "}
							{translate(ResourceAssistance.Message.days)}
						</Descriptions.Item>
						<Descriptions.Item span={3}>{this.props.opd.selectedDoctorOrder.order.code}</Descriptions.Item>
						<Descriptions.Item span={3}>{this.props.opd.selectedDoctorOrder.order.description}</Descriptions.Item>
						<Descriptions.Item span={3}>{this.props.opd.selectedDoctorOrder.order.notes}</Descriptions.Item>
						<Descriptions.Item span={1} contentStyle={{ justifyContent: "flex-start", color: ResourceAssistance.CSS.Color.dark_warning }}>
							{this.props.opd.selectedDoctorOrder.order.nonDispensingRx ? translate(ResourceAssistance.Message.nonDispensingRx) : ""}
						</Descriptions.Item>
						<Descriptions.Item span={2} contentStyle={{ justifyContent: "flex-end" }}>
							{this.props.opd.selectedDoctorOrder.order.lastModifiedBy.displayName}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctorOrderInfoCriteria)
