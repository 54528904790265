import * as React from "react"

function SvgSvgDoctorWork(props) {
	return (
		<svg
			stroke="currentColor"
			fill="currentColor"
			strokeWidth="10"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 400 400"
			{...props}
		>
			<path
				d="M127.502 28.038C84.18 37.463 31.25 59.869 31.25 68.782c0 3.284 7.367 25.011 16.372 48.282 9.004 23.271 18.86 48.798 21.901 56.726 3.141 8.186 8.508 12.574 12.422 10.156 4.878-3.015 5.35 1.903 1.615 16.835C46.783 347.801 235.644 432.5 311.186 302.865c12.465-21.392 14.092-31.295 12.024-73.177-1.316-26.641-.379-48.438 2.082-48.438C330.083 181.25 375 80.29 375 69.521c0-32.335-163.547-59.748-247.498-41.483M309.141 50.96c56.053 18.864 54.305 15.116 32.345 69.334-14.624 36.105-21.995 47.514-28.986 44.864-44.835-17-175.196-16.581-220.251.709-11.31 4.34-18.234-7.921-37.913-67.133l-9.768-29.392 34.57-12.934c73.722-27.583 158.275-29.586 230.003-5.448m-125.929 4.712c-1.715 6.557-8.483 13.325-15.04 15.04-16.824 4.4-16.824 41.676 0 46.076 6.557 1.715 13.325 8.483 15.04 15.04 4.298 16.437 35.528 16.437 39.826 0 1.715-6.557 8.483-13.325 15.04-15.04 16.824-4.4 16.824-41.676 0-46.076-6.557-1.715-13.325-8.483-15.04-15.04-2.092-7.998-8.645-11.922-19.913-11.922s-17.821 3.924-19.913 11.922m27.326 12.5c1.715 6.557 8.779 13.403 15.698 15.212 14.827 3.878 11.609 20.592-3.447 17.905-6.795-1.213-10.289 2.501-10.289 10.937 0 7.188-4.1 12.774-9.375 12.774s-9.375-5.586-9.375-12.774c0-8.436-3.494-12.15-10.289-10.937-15.459 2.759-18.132-14.065-2.869-18.056 7.502-1.962 13.158-8.501 13.158-15.212 0-15.357 12.762-15.242 16.788.151m74.988 104.617c27.133 6.678 36.603 81.178 15.39 121.059C247.424 394.41 93.75 356.853 93.75 243.218c0-45.773 10.107-67.295 33.361-71.04 10.87-1.75 26.795-4.422 35.389-5.937 17.579-3.1 102.107 1.399 123.026 6.548"
				fillRule="evenodd"
			/>
		</svg>
	)
}

export default SvgSvgDoctorWork
