import { message } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalDoctorOrderItems, setHospitelModalDoctorOrderPendingItems, setLoadingAction, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM } from "~/redux/type"
import { BigNumberRoundingMode, Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalDoctorOrderItem extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.hospitel.modalDoctorOrder.searchDescription !== this.props.hospitel.modalDoctorOrder.searchDescription ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedType, this.props.hospitel.modalDoctorOrder.selectedType)
		) {
			this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
			this.props.setHospitelModalDoctorOrderItems(this.props.hospitel.modalDoctorOrder.itemTable.original)
		}

		if (
			prevProps.hospitel.modalDoctorOrder.startDateTime !== this.props.hospitel.modalDoctorOrder.startDateTime ||
			prevProps.hospitel.modalDoctorOrder.endDateTime !== this.props.hospitel.modalDoctorOrder.endDateTime ||
			prevProps.hospitel.modalDoctorOrder.duration !== this.props.hospitel.modalDoctorOrder.duration ||
			prevProps.hospitel.modalDoctorOrder.durationQty !== this.props.hospitel.modalDoctorOrder.durationQty ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedDuration.duration, this.props.hospitel.modalDoctorOrder.selectedDuration.duration) ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedUnit, this.props.hospitel.modalDoctorOrder.selectedUnit) ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.selectedRate, this.props.hospitel.modalDoctorOrder.selectedRate) ||
			!_.isEqual(prevProps.hospitel.modalDoctorOrder.rateDuration, this.props.hospitel.modalDoctorOrder.rateDuration)
		) {
			if (
				this.props.hospitel.modalDoctorOrder.durationQty &&
				(this.props.hospitel.modalDoctorOrder.duration || this.props.hospitel.modalDoctorOrder.isOneTime) &&
				this.props.hospitel.modalDoctorOrder.selectedUnit.unit &&
				this.props.hospitel.modalDoctorOrder.selectedRate.rate &&
				Utils.BigNumber(this.props.hospitel.modalDoctorOrder.rateDuration).gte(1) &&
				Utils.BigNumber(this.props.hospitel.modalDoctorOrder.selectedRate.rate.value)
					.times(this.props.hospitel.modalDoctorOrder.rateDuration)
					.gte(Utils.BigNumber(this.props.hospitel.modalDoctorOrder.selectedDuration.duration.value).times(this.props.hospitel.modalDoctorOrder.duration))
			) {
				message.destroy()
				message.warning(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.wrongFrequency }), 10)
			} else {
				message.destroy()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	validateDuration() {
		if (
			_.isEmpty(this.props.hospitel.modalDoctorOrder.durationQty) ||
			(_.isEmpty(this.props.hospitel.modalDoctorOrder.duration) && !this.props.hospitel.modalDoctorOrder.isOneTime) ||
			!this.props.hospitel.modalDoctorOrder.selectedUnit.unit ||
			(this.props.hospitel.modalDoctorOrder.selectedRate.rate && _.isEmpty(this.props.hospitel.modalDoctorOrder.rateDuration)) ||
			(this.props.hospitel.modalDoctorOrder.selectedRate.rate &&
				!_.isEmpty(this.props.hospitel.modalDoctorOrder.rateDuration) &&
				Utils.BigNumber(this.props.hospitel.modalDoctorOrder.selectedRate.rate.value)
					.times(this.props.hospitel.modalDoctorOrder.rateDuration)
					.gte(Utils.BigNumber(this.props.hospitel.modalDoctorOrder.selectedDuration.duration.value).times(this.props.hospitel.modalDoctorOrder.duration)))
		) {
			return false
		}

		let qty = Utils.calculateDoctorOrderQtyByRate(
			this.props.hospitel.modalDoctorOrder.durationQty,
			this.props.hospitel.modalDoctorOrder.rateDuration,
			this.props.hospitel.modalDoctorOrder.selectedUnit.unit.amount,
			this.props.hospitel.modalDoctorOrder.selectedUnit.unit.oneTime
		)
		let doctorOrderQty = this.props.hospitel.modalDoctorOrder.isOneTime
			? qty.dp(0, BigNumberRoundingMode.ROUND_UP)
			: Utils.calculateDoctorOrderQty(
					{
						...this.props.hospitel.modalDoctorOrder.selectedItem.item,
						startDateTime: this.props.hospitel.modalDoctorOrder.startDateTime,
						endDateTime: this.props.hospitel.modalDoctorOrder.endDateTime,
						duration: Utils.BigNumber(this.props.hospitel.modalDoctorOrder.duration)
							.times(this.props.hospitel.modalDoctorOrder.selectedDuration.duration.value)
							.toNumber(),
						durationQty: qty.toNumber(),
						exclusiveDays: this.props.hospitel.modalDoctorOrder.exclusiveDays,
					},
					this.props.hospitel.modalDoctorOrder.startDateTime,
					this.props.hospitel.modalDoctorOrder.endDateTime
			  )

		return doctorOrderQty > 0
	}

	onSelectItem(item, index) {
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM, index, this.props.hospitel.modalDoctorOrder.itemTable.filtered[index])
	}

	onUpBtn() {
		let pendingItems = this.props.hospitel.modalDoctorOrder.pendingItemTable.filtered.filter(
			(each) => each.displayName !== this.props.hospitel.modalDoctorOrder.selectedPendingItem.item.displayName
		)
		this.props.setHospitelModalDoctorOrderPendingItems(pendingItems)
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectItem}
							data={this.props.hospitel.modalDoctorOrder.itemTable}
							isClearHighlight={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1}
							highlightedRow={this.props.hospitel.modalDoctorOrder.selectedItem.index}
						/>
					</Col>
					{this.props.hospitel.modalDoctorOrder.itemTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.hospitel.modalDoctorOrder.selectedItem.index === -1 || !this.validateDuration()}
						>
							<FcDownload size={"3rem"} />
						</Button>
					</Col>
					<Col />
					<Col md={"auto"}>
						<Button
							variant={ResourceAssistance.Button.variant.link}
							disabled={this.props.hospitel.modalDoctorOrder.selectedPendingItem.index === -1}
							onClick={this.onUpBtn}
						>
							<FcUpload size={"3rem"} />
						</Button>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setHospitelModalDoctorOrderItems,
			setHospitelModalDoctorOrderPendingItems,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderItem))
