import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class IPDModalCreatePRNRecordGeneral extends React.Component {
	constructor(props) {
		super(props)

		this.onQty = this.onQty.bind(this)
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY, "")
	}

	onQty(event) {
		this.props.setValue(SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY, event.target.value)
	}

	render() {
		return (
			<Row>
				<Col md={6} />
				<Col>
					<GInput
						required
						style={{ textAlign: "center" }}
						placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount })}
						suffix={this.props.ipd.selectedOrder.order.unit}
						pattern="^\d+$"
						onChange={this.onQty}
						value={this.props.ipd.modalCreatePRNRecord.dispensingQty}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalCreatePRNRecordGeneral))
