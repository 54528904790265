import { ResourceAssistance } from "~/i18n"
import { PrintableDataFactory } from "../PrintableDataFactory"
import { Utils } from "~/utils/Utils"
import ServerUtils from "../../../ServerUtils"

const ORG_NAME = process.env.REACT_APP_ORG_NAME
const BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME
const ADDRESS = process.env.REACT_APP_ADDRESS
const TEL = process.env.REACT_APP_TEL
const TAX_ID = process.env.REACT_APP_TAX_ID

class CashierPrintableDataFactory extends PrintableDataFactory {
	static generateBillByHealthCarePlanData = (patient, admission, cashier, healthCarePlans, logo) => {
		return {
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			patientNameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			descriptionIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.list }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			subTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.subTotal }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			adjustmentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.discount }),
			balanceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.balance }),
			admissionDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.admissionDate }),
			dischargeDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dischargeDate }),

			logo: logo,
			name: Utils.isDevMode() ? "บริษัท พระราม 2 เมดิคอล กรุ๊ป จำกัด" : ORG_NAME,
			branchName: Utils.isDevMode() ? "โรงพยาบาลทั่วไปขนาดใหญ่พีเอ็มจี" : BRANCH_NAME,
			address: Utils.isDevMode() ? "280 ถนนพระราม 2 แขวงแสมดำ, บางขุนเทียน, กรุงเทพมหานคร, 10150" : ADDRESS,
			tel: Utils.isDevMode() ? "02-451-4920-4" : TEL,
			taxId: Utils.isDevMode() ? "0105535059152" : TAX_ID,

			pid: patient.id,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			patientAddress: patient.address,
			admissionId: admission.id,
			admissionDate: admission.clinicalRecord
				? Utils.formatDateTime(admission.clinicalRecord.creationDateTime)
				: Utils.formatDateTime(admission.creationDateTime),
			dischargeDate: admission.dischargedDateTime ? Utils.formatDateTime(admission.dischargedDateTime) : "",

			healthCarePlans: healthCarePlans.map((hcp) => {
				let subTotal = Utils.BigNumber(0)
				let serviceAdjustments = Utils.BigNumber(0)
				let billingPlanAdjustments = ServerUtils.calculateBillingPlanAdjustments(hcp.billingPlanAdjustments)
				return {
					...hcp,
					billingStatements: hcp.billingStatements.map((bs) => {
						subTotal = subTotal.plus(bs.charge)
						serviceAdjustments = serviceAdjustments.plus(bs.adjustment)
						return {
							...bs,
							amount: Utils.formatNumWithComma(Utils.BigNumber(bs.charge).toFixed(2)),
							adjustment: Utils.formatNumWithComma(Utils.BigNumber(bs.adjustment).negated().toFixed(2)),
							balance: Utils.formatNumWithComma(Utils.BigNumber(bs.charge).minus(bs.adjustment).toFixed(2)),
						}
					}),
					subTotal: Utils.formatNumWithComma(subTotal.toFixed(2)),
					adjustments: Utils.formatNumWithComma(serviceAdjustments.plus(billingPlanAdjustments).negated().toFixed(2)),
					grandTotal: Utils.formatNumWithComma(subTotal.minus(serviceAdjustments).minus(billingPlanAdjustments).toFixed(2)),
				}
			}),

			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patient }) + "/Patient",
				this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.cashier }) + "/Cashier",
			],
			signatureNames: [patient.firstName + " " + patient.lastName, cashier.firstName + " " + cashier.lastName],
		}
	}
}

export default CashierPrintableDataFactory
