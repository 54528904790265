export const SET_AR_MODAL_LOCATION_DISPLAY = "SET_AR_MODAL_LOCATION_DISPLAY"
export const SET_AR_MODAL_LOCATION_ORGS = "SET_AR_MODAL_LOCATION_ORGS"
export const SET_AR_MODAL_LOCATION_ORGS_LOADING = "SET_AR_MODAL_LOCATION_ORGS_LOADING"
export const SET_AR_MODAL_LOCATION_SELECTED_ORG = "SET_AR_MODAL_LOCATION_SELECTED_ORG"
export const SET_AR_LOCATION = "SET_AR_LOCATION"
export const SET_AR_START_DATE_TIME = "SET_AR_START_DATE_TIME"
export const SET_AR_END_DATE_TIME = "SET_AR_END_DATE_TIME"
export const SET_AR_INVOICES = "SET_AR_INVOICES"
export const SET_AR_SELECTED_INVOICE = "SET_AR_SELECTED_INVOICE"
export const SET_AR_BILLING_STATEMENT_GROUP = "SET_AR_BILLING_STATEMENT_GROUP"
export const SET_AR_BILLING_STATEMENT_GL_GROUP = "SET_AR_BILLING_STATEMENT_GL_GROUP"
export const SET_AR_SELECTED_GROUP = "SET_AR_SELECTED_GROUP"
export const SET_AR_BILLING_STATEMENT = "SET_AR_BILLING_STATEMENT"
export const SET_AR_SELECTED_BILLING_STATEMENT = "SET_AR_SELECTED_BILLING_STATEMENT"
export const SET_AR_ADJUSTMENT_DISPLAY = "SET_AR_ADJUSTMENT_DISPLAY"
export const SET_AR_MODAL_ADJUSTMENT_DISCOUNT = "SET_AR_MODAL_ADJUSTMENT_DISCOUNT"
export const SET_AR_MODAL_ADJUSTMENT_DISCOUNT_REMARK = "SET_AR_MODAL_ADJUSTMENT_DISCOUNT_REMARK"
export const SET_AR_MODAL_ADJUSTMENT_DISCOUNT_PERCENT = "SET_AR_MODAL_ADJUSTMENT_DISCOUNT_PERCENT"
export const SET_AR_MODAL_ADJUSTMENT_ID = "SET_AR_MODAL_ADJUSTMENT_ID"
export const SET_AR_MODAL_ADJUSTMENT_CONTINUE = "SET_AR_MODAL_ADJUSTMENT_CONTINUE"
export const SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX = "SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX"
export const SET_AR_EDIT_INVOICE_DISPLAY = "SET_AR_EDIT_INVOICE_DISPLAY"
export const SET_AR_MODAL_EDIT_INVOICE_DISCOUNT = "SET_AR_MODAL_EDIT_INVOICE_DISCOUNT"
export const SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK = "SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK"
export const SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT = "SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT"
export const SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN = "SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN"
export const SET_AR_HEALTH_CARE_PLANS = "SET_AR_HEALTH_CARE_PLANS"
export const SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_MAX = "SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_MAX"
export const SET_AR_FILTER = "SET_AR_FILTER"
export const SET_AR_FILTER_INVOICE_ID = "SET_AR_FILTER_INVOICE_ID"
export const SET_AR_CHART_OF_ACCOUNTS = "SET_AR_CHART_OF_ACCOUNTS"
export const SET_AR_MODAL_GL_SELECTED_GL = "SET_AR_MODAL_GL_SELECTED_GL"
export const SET_AR_MODAL_GL_DESCRIPTION = "SET_AR_MODAL_GL_DESCRIPTION"
export const SET_AR_MODAL_GENERAL_LEDGER_DISPLAY = "SET_AR_MODAL_GENERAL_LEDGER_DISPLAY"
export const SET_AR_ACTIVITIES = "SET_AR_ACTIVITIES"
export const SET_AR_SEARCH_DISPLAY = "SET_AR_SEARCH_DISPLAY"
export const SET_AR_GL_DATE_TIME = "SET_AR_GL_DATE_TIME"
