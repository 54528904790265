import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportPurchaseOrderByLocationDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.purchaseOrderByLocation,
				orders: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* Filter */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Start Date/{this.state.data.startDateTimeIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.startDateTime}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>End Date/{this.state.data.endDateTimeIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.endDateTime}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Branch/{this.state.data.branchIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.branch}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Issued/{this.state.data.issuedIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.issuedIcon}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Certified/{this.state.data.certifiedIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.certifiedIcon}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>Approved/{this.state.data.approvedIntl}: </Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon]}>{this.state.data.approvedIcon}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{this.state.data.orders.map((po) => {
							return (
								<Fragment>
									{/* PO info */}
									<View wrap={false}>
										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Purchase Order ID/{this.state.data.poIdIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{po.poId}</Text>
											</View>
										</View>

										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Date/{this.state.data.poDateTimeIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{po.poDateTime}</Text>
											</View>
										</View>

										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Issued By/{this.state.data.issuedByIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{po.issuedBy}</Text>
											</View>
										</View>

										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Certified By/{this.state.data.certifiedByIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{po.certifiedBy}</Text>
											</View>
										</View>

										<View style={ResourceAssistance.Report.styles.row}>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
												<Text>Approved By/{this.state.data.approvedByIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{po.approvedBy}</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
									</View>

									{/* Item Header */}
									<View wrap={false}>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 12 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.memoId]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.memoIdIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.idIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.supplier]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.supplierIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.subTotal]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.subTotalIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.discountIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.totalIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.vat]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.vatIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.grandTotal]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.grandTotalIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.payment]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.paymentIntl}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 12 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.memoId]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>Memo ID</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
												<Text style={[ResourceAssistance.Report.styles.textBold]}>ID</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.supplier]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Vendor</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.subTotal]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Sub Total</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Discount</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Total</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.vat]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>VAT</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.grandTotal]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Grand Total</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.payment]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Payment</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
									</View>

									{/* Suppliers */}
									{po.suppliers.map((supplier) => {
										return (
											<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]}>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.memoId]}>
													<Text>{supplier.memoId}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
													<Text>{supplier.id}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.col, styles.supplier]}>
													<Text style={ResourceAssistance.Report.styles.textItalic}>{supplier.supplier}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.subTotal]}>
													<Text>{supplier.subTotal}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.discount]}>
													<Text>{supplier.discount}%</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.total]}>
													<Text>{supplier.total}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.vat]}>
													<Text>{supplier.vat}%</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.grandTotal]}>
													<Text>{supplier.grandTotal}</Text>
												</View>
												<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.payment]}>
													<Text>{supplier.payment}</Text>
												</View>
											</View>
										)
									})}
									<View style={[ResourceAssistance.Report.styles.separatorFlexGrow, { borderBottomWidth: 1 }]} />

									{/* Totals */}
									<View style={[ResourceAssistance.Report.styles.row]} wrap={false}>
										<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right", paddingRight: 50 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Grand Total/{this.state.data.grandTotalIntl}
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.colAuto}>
											<Text>{po.grandTotal}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.separator]} />
								</Fragment>
							)
						})}

						<View wrap={false}>
							<View style={ResourceAssistance.Report.styles.separator} />
							<View style={[ResourceAssistance.Report.styles.row]} wrap={false}>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right", paddingRight: 50 }]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>
										Grand Total/{this.state.data.grandTotalIntl}
									</Text>
								</View>
								<View style={ResourceAssistance.Report.styles.colAuto}>
									<Text>{this.state.data.grandTotal}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.separator, { marginBottom: 0 }]} />
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		justifyContent: "flex-start",
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	memoId: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	id: {
		minWidth: "11%",
		maxWidth: "11%",
	},
	supplier: {
		minWidth: "22%",
		maxWidth: "22%",
	},
	subTotal: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	discount: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	total: {
		minWidth: "13%",
		maxWidth: "13%",
	},
	vat: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	grandTotal: {
		minWidth: "11%",
		maxWidth: "11%",
	},
	payment: {
		minWidth: "8%",
		maxWidth: "8%",
	},
})

export default ReportPurchaseOrderByLocationDocument
