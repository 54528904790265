import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import OutpatientRightAction from "./OutpatientRightAction"
import OutpatientRightDataTab from "./OutpatientRightDataTab"
import OutpatientRightNoteTab from "./OutpatientRightNoteTab"
import OutpatientRightOrderTab from "./OutpatientRightOrderTab"

class OutpatientRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRight",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<OutpatientRightAction />
				<OutpatientRightOrderTab />
				<OutpatientRightNoteTab />
				<OutpatientRightDataTab />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OutpatientRight)
