import React from "react"
import "./required.css"

class RequiredLabel extends React.Component {
	render() {
		return <span className="required-label">{this.props.children}</span>
	}
}

RequiredLabel.propTypes = {}

RequiredLabel.defaultProps = {}

export default RequiredLabel
