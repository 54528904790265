import { ResourceAssistance } from "~/i18n"
import {
	SET_FRONT_INVENTORY_DISTRIBUTIONS,
	SET_FRONT_INVENTORY_INVENTORIES,
	SET_FRONT_INVENTORY_LOTS,
	SET_FRONT_INVENTORY_MODAL_SPLIT_LOTS,
	SET_FRONT_INVENTORY_MODAL_WASTE_LOTS,
	SET_FRONT_INVENTORY_WASTES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setFrontInventoryInventories = (inventories) => {
	return (dispatch, getState) => {
		let filtered = inventories
			.filter(
				(inventory) =>
					inventory.type.id === getState().hospital.frontInventory.selectedType.type.id &&
					inventory.code.trim().toLowerCase().includes(getState().hospital.frontInventory.searchCode.trim().toLowerCase()) &&
					inventory.genericName.trim().toLowerCase().includes(getState().hospital.frontInventory.searchGenericName.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.genericName, b.genericName))

		if (getState().hospital.frontInventory.isDisplayExpired) {
			filtered = filtered.filter((inventory) =>
				inventory.transferOrderItems.some((item) => item.expiredDateTime <= getState().hospital.frontInventory.lotExpiredDateTime)
			)
		}

		if (getState().hospital.frontInventory.isDisplayMinStock) {
			filtered = filtered.filter((inventory) => Utils.BigNumber(inventory.totalAmount).lt(inventory.max))
		}

		let rowColor = []

		let body = filtered.map((inventory, index) => {
			return [
				inventory.code,
				inventory.genericName,
				inventory.brand,
				inventory.unit,
				Utils.formatNumWithComma(inventory.totalAmount),
				Utils.formatNumWithComma(inventory.min),
				Utils.formatNumWithComma(inventory.max),
				[true, true, true],
			]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_INVENTORIES,
			payload: {
				original: inventories,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFrontInventoryLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.filter((lot) => lot.total > 0).sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))

		if (getState().hospital.frontInventory.isDisplayExpired) {
			filtered = filtered.filter((lot) => lot.expiredDateTime <= getState().hospital.frontInventory.lotExpiredDateTime)
		}

		let colColor = filtered.map((lot) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[Utils.BigNumber(lot.expiredDateTime).lte(getState().hospital.frontInventory.lotExpiredDateTime), ResourceAssistance.CSS.Color.red],
				[false, ""],
			]
		})

		let body = filtered.map((lot, index) => {
			return [lot.lotNum, Utils.formatNumWithComma(lot.total), lot.unit, Utils.formatDate(lot.expiredDateTime)]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_LOTS,
			payload: {
				original: lots,
				filtered: filtered,
				body: body,
				colColor: colColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFrontInventoryDistributions = (distributions) => {
	return (dispatch, getState) => {
		let filtered = distributions.filter((distribution) => distribution.totalAmount > 0).sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))

		if (getState().hospital.frontInventory.isDisplayExpired) {
			filtered = filtered.filter((distribution) => distribution.expiredDateTime <= getState().hospital.frontInventory.lotExpiredDateTime)
		}

		let body = filtered.map((distribution, index) => {
			return [distribution.lot, Utils.formatNumWithComma(distribution.totalAmount), distribution.unit, Utils.formatDate(distribution.expiredDateTime), [true]]
		})

		let colColor = filtered.map((distribution) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[Utils.BigNumber(distribution.expiredDateTime).lte(getState().hospital.frontInventory.lotExpiredDateTime), ResourceAssistance.CSS.Color.red],
				[false, ""],
			]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_DISTRIBUTIONS,
			payload: {
				original: distributions,
				filtered: filtered,
				body: body,
				colColor: colColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFrontInventoryWaste = (items) => {
	return (dispatch, getState) => {
		let filtered = items.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((item, index) => {
			return [item.lot, Utils.formatNumWithComma(Utils.BigNumber(item.amount).toFixed(2)), item.unit, item.reason]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_WASTES,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setFrontInventoryModalWasteLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.filter((lot) => lot.total > 0).sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))

		let rowColor = filtered.map((lot) => {
			let color = ""
			if (lot.wasteAmount > 0) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((lot, index) => {
			return [
				lot.lotNum,
				Utils.formatNumWithComma(lot.total),
				lot.unit,
				Utils.formatDate(lot.expiredDateTime),
				Utils.formatNumWithComma(lot.wasteAmount),
				[true],
			]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_MODAL_WASTE_LOTS,
			payload: {
				original: lots,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFrontInventoryModalSplitLots = (lots) => {
	return (dispatch, getState) => {
		let filtered = lots.filter((lot) => lot.total > 0).sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime))

		let rowColor = filtered.map((lot) => {
			let color = ""
			if (lot.splitAmount > 0) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [true, color]
		})

		let body = filtered.map((lot) => {
			return [
				lot.lotNum,
				Utils.formatNumWithComma(lot.total),
				lot.unit,
				Utils.formatDate(lot.expiredDateTime),
				Utils.formatNumWithComma(lot.splitAmount),
				Utils.formatNumWithComma(lot.splitUnitAmount),
				lot.splitUnit,
				[true],
			]
		})

		dispatch({
			type: SET_FRONT_INVENTORY_MODAL_SPLIT_LOTS,
			payload: {
				original: lots,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
