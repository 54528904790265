import { StyleSheet, Text, View } from "@react-pdf/renderer"
import PropTypes from "prop-types"
import React from "react"
import { ResourceAssistance } from "~/i18n"

class Fieldset extends React.Component {
	render() {
		return (
			<View style={[styles.field]}>
				<View style={[ResourceAssistance.Report.styles.row, styles.fieldH1]}>
					<View style={[ResourceAssistance.Report.styles.colAuto, styles.fieldH1Span]}>
						<Text>{this.props.legend}</Text>
					</View>
				</View>
				{this.props.content}
			</View>
		)
	}
}

const styles = StyleSheet.create({
	field: {
		marginTop: 7,
		border: "1 solid 0",
		paddingTop: 8,
		paddingHorizontal: 2,
	},
	fieldH1: {
		margin: "-16 -8 0 0",
	},
	fieldH1Span: {
		marginLeft: "8",
		backgroundColor: "white",
	},
})

Fieldset.propTypes = {
	legend: PropTypes.string,
	content: PropTypes.any,
}

Fieldset.defaultProps = {
	legend: "",
}

export default Fieldset
