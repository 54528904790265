import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import propTypes from "prop-types"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportCovid19Document extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				results: [],
			},
		}
	}

	componentDidMount() {
		if (this.props.data) {
			this.setState({
				data: Object.assign({}, this.state.data, {
					...this.state.data,
					...this.props.data,
				}),
			})
			return
		}

		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
							{/* Title */}
							<ReportTitle
								data={this.state.data}
								displayTitle={false}
								displayFax={false}
								displayTaxNum={false}
								displayBranch={true}
								styles={{ org: { fontSize: 24 } }}
							/>
						</View>
					</View>

					{/* Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
							<Text>Patient Name: </Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
							<Text>{this.state.data.patientName}</Text>
						</View>
					</View>
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient ID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientId}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.id}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Gender: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.gender} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Lab No.: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.labNum}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Ordered By: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.issuedBy} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Order Date: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.issuedDate} </Text>
								</View>
							</View>
						</View>
					</View>
					<View style={ResourceAssistance.Report.styles.separator} />

					{/* Item Header */}
					<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
						<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
							<Text style={ResourceAssistance.Report.styles.textBold}>Analyte</Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
							<Text style={[ResourceAssistance.Report.styles.textBold]}>Result</Text>
						</View>
						<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
							<Text style={[ResourceAssistance.Report.styles.textBold]}>Method</Text>
						</View>
					</View>
					<View style={ResourceAssistance.Report.styles.horizontalLine} />

					{/* Items */}
					{this.state.data.results.map((result) => {
						if (result.isDisplay) {
							return (
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{result.name}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{result.value}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
										<Text>{result.method}</Text>
									</View>
								</View>
							)
						} else {
							return <></>
						}
					})}
					<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />
					{this.state.data.comment ? (
						<Fragment>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Comment:</Text>
									<Text>{this.state.data.comment}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</Fragment>
					) : (
						<Fragment>
							{this.state.data.results.some((result) => result.name === "N gene:CT") &&
								this.state.data.results.some((result) => result.name === "ORF1ab Gene:CT") && (
									<Fragment>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Comment:</Text>
												<Text>SAT Code: </Text>
												<Text>Internal control Valid</Text>
												<Text>Specimen: NS+TS in VTM</Text>
												<Text>Detection Method: Real-Time RT PCR</Text>
												<Text>Novel Coronavirus (2019-nCov)</Text>
												<Text>Nucleic Acid Diagnostic Kit</Text>
												<Text>Reference: N gene, ORF1ab gene</Text>
												<Text>Limit of Detection = 200 copies/mL</Text>
												<Text style={{ fontSize: 16 }}>*Considered as positive if Ct falls within 40 cycles</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />
									</Fragment>
								)}

							{this.state.data.results.some((result) => result.name === "ORF1ab Gene:CT") &&
								this.state.data.results.some((result) => result.name === "E gene:CT") && (
									<Fragment>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Comment:</Text>
												<Text>SAT Code: </Text>
												<Text>Internal control Valid</Text>
												<Text>Specimen: Nasopharyngeal swab in VTM</Text>
												<Text>Detection Method: Real-Time RT PCR</Text>
												<Text>Target Gene: ORF1ab gene, E gene</Text>
												<Text>Limit of Detection:</Text>
												<Text> ORF1ab = 25 copies/mL,</Text>
												<Text> E = 32 copies/mL</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />
									</Fragment>
								)}

							{this.state.data.results.some((result) => result.name === "N gene:CT") &&
								this.state.data.results.some((result) => result.name === "S gene:CT") && (
									<Fragment>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Comment:</Text>
												<Text>SAT Code: </Text>
												<Text>Internal control Valid</Text>
												<Text>Specimen: NS+TS in VTM</Text>
												<Text>Detection Method: Real-Time RT PCR</Text>
												<Text>SAR-CoV-2 Nucleic Acid Detection Kit</Text>
												<Text>Based on real-time PCR platform</Text>
												<Text>Reference: N gene, S gene</Text>
												<Text>Limit of Detection = 500 copies/mL</Text>
												<Text style={{ fontSize: 16 }}>*Considered as positive if Ct falls within 36 cycles</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />
									</Fragment>
								)}
						</Fragment>
					)}

					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Reported By: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { textAlign: "center" }]}>
									<Text>{this.state.data.certifiedBy} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Reported Date: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { textAlign: "center" }]}>
									<Text>{this.state.data.certifiedDate}</Text>
								</View>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Approved By: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { textAlign: "center" }]}>
									<Text>{this.state.data.approvedBy} </Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Approved Date: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { textAlign: "center" }]}>
									<Text>{this.state.data.approvedDate}</Text>
								</View>
							</View>
						</View>
					</View>
				</Page>
			</Document>
		)
	}

	render() {
		return this.props.isPdfViewer ? (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
		) : (
			this.renderDoc()
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
		marginRight: 3,
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
		marginBottom: 10,
	},
})

ReportCovid19Document.propTypes = {
	isPdfViewer: propTypes.bool,
}

ReportCovid19Document.defaultProps = {
	isPdfViewer: true,
}

export default ReportCovid19Document
