import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFrontInventoryModalSplitLots } from "~/redux/action"
import { SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import FIModalSplitEditDetails from "./FIModalSplitEditDetails"

class FIModalSplitEdit extends React.Component {
	constructor(props) {
		super(props)
		this.editRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_SPLIT_EDIT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.editRef.current || !this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion) {
			return true
		}
		let target = Object.assign({}, this.props.frontInventory.modalSplit.selectedLot.lot, {
			splitAmount: Number(this.editRef.current.state.amount),
			splitUnit: this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion.displayName,
			splitUnitAmount: Utils.BigNumber(this.editRef.current.state.amount)
				.times(this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion.amount)
				.toNumber(),
		})
		return _.isEqual(target, this.props.frontInventory.modalSplit.selectedLot.lot)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		Object.assign(this.props.frontInventory.modalSplit.selectedLot.lot, {
			splitAmount: Number(this.editRef.current.state.amount),
			splitUnit: this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion.displayName,
			splitUnitAmount: Utils.BigNumber(this.editRef.current.state.amount)
				.times(this.props.frontInventory.modalSplit.selectedUnitConversion.unitConversion.amount)
				.toNumber(),
			isSplit: Utils.BigNumber(this.editRef.current.state.amount).gt(0),
		})
		this.props.setFrontInventoryModalSplitLots(this.props.frontInventory.modalSplit.lotTable.original)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.edit)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FIModalSplitEditDetails
							editRef={this.editRef}
							amount={this.props.frontInventory.modalSplit.selectedLot.lot ? this.props.frontInventory.modalSplit.selectedLot.lot.splitAmount : 0}
							onRenderParentCallback={this.onRenderParentCallback}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isFrontInventorySplitEditDisplay,
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setFrontInventoryModalSplitLots,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FIModalSplitEdit)
