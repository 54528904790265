import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import HospitelModalDoctorServiceAction from "./HospitelModalDoctorServiceAction"
import HospitelModalDoctorServiceFilter from "./HospitelModalDoctorServiceFilter"
import HospitelModalDoctorServicePendingService from "./HospitelModalDoctorServicePendingService"
import HospitelModalDoctorServiceService from "./HospitelModalDoctorServiceService"

class HospitelModalDoctorServiceDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitelModalDoctorServiceFilter />
				<HospitelModalDoctorServiceService />
				<HospitelModalDoctorServiceAction />
				<HospitelModalDoctorServicePendingService />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorServiceDetails)
