import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportMedicationSheetDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "Medication Sheet",
				medications: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderNurseSignatureRows() {
		let rows = []
		for (let i = 0; i < 5; i++) {
			rows.push(
				<Fragment>
					<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-end" }]}>
						<View style={[styles.line]}></View>
					</View>
					<View style={ResourceAssistance.Report.styles.colAuto}>
						<Text>/</Text>
					</View>
					<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-end", marginRight: 10 }]}>
						<View style={styles.line}></View>
					</View>
				</Fragment>
			)
		}

		return rows
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]} fixed>
								<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Date: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.date}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Room: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.room}</Text>
										</View>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>ID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.id}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Name: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Age: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>DOB: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Item Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Medication</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Medications */}
						{this.state.data.medications.map((medication) => {
							return (
								<View wrap={false}>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]}>
										<View style={[ResourceAssistance.Report.styles.col]}>
											<Text>{medication.description}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.horizontalSubline]} />
									<View style={[ResourceAssistance.Report.styles.row]}>{this.renderNurseSignatureRows()}</View>
									<View style={[ResourceAssistance.Report.styles.row, { marginBottom: 10 }]}>
										{this.renderNurseSignatureRows()}
									</View>
								</View>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
})

export default ReportMedicationSheetDocument
