import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_AP_ACTIVITIES,
	SET_AP_CHART_OF_ACCOUNTS,
	SET_AP_END_DATE_TIME,
	SET_AP_FILTER,
	SET_AP_FILTER_RECEIVE_ID,
	SET_AP_GENERAL_GROUPS,
	SET_AP_GL_DATE_TIME,
	SET_AP_GL_GROUPS,
	SET_AP_INVOICES,
	SET_AP_ITEMS,
	SET_AP_LOCATION,
	SET_AP_MODAL_GL_DESCRIPTION,
	SET_AP_MODAL_GL_SELECTED_GL,
	SET_AP_MODAL_LOCATION_ORGS,
	SET_AP_MODAL_LOCATION_ORGS_LOADING,
	SET_AP_MODAL_LOCATION_SELECTED_ORG,
	SET_AP_MODAL_SEARCH_ALL_INCOMPLETE,
	SET_AP_MODAL_SEARCH_RECEIVE_ID,
	SET_AP_RECEIVES,
	SET_AP_SELECTABLE_ACCOUNTING_CODES,
	SET_AP_SELECTED_GROUP,
	SET_AP_SELECTED_INVOICE,
	SET_AP_SELECTED_ITEM,
	SET_AP_SELECTED_RC,
	SET_AP_START_DATE_TIME,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	generalLedgerDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterReceiveId: "",
	filters: [],
	chartOfAccounts: [],
	accountsPayableActivities: [],
	selectableAccountingCodes: [],
	selectedRC: {
		index: -1,
		rc: null,
	},
	selectedInvoice: {
		index: -1,
		invoice: null,
	},
	selectedGroup: {
		index: -1,
		group: null,
	},
	selectedItem: {
		index: -1,
		item: null,
	},
	receiveTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	invoiceTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.invoiceId,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.supplier,
			ResourceAssistance.Message.totalBalance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
			"",
		],
		body: [],
		rowColor: [],
	},
	generalGroupTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.totalPriceDiscounted,
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	generalLedgerGroupTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.category,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.debit,
			ResourceAssistance.Message.credit,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	itemTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.pricePerOrder,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
		],
		body: [],
		rowColor: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalSearch: {
		searchReceiveId: "",
		isSearchAllIncomplete: false,
	},
	modalGeneralLedger: {
		description: "",
		selectedGL: {
			index: -1,
			GL: null,
		},
	},
}

const accountsPayableReducer = (state = init, action) => {
	switch (action.type) {
		case SET_AP_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_AP_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_AP_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_AP_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_AP_ACTIVITIES:
			return Object.assign({}, state, {
				accountsPayableActivities: action.payload.value,
			})
		case SET_AP_MODAL_SEARCH_RECEIVE_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchReceiveId: action.payload.value,
				},
			})
		case SET_AP_MODAL_SEARCH_ALL_INCOMPLETE:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					isSearchAllIncomplete: action.payload.value,
				},
			})
		case SET_AP_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_AP_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_AP_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_AP_GL_DATE_TIME:
			return Object.assign({}, state, {
				generalLedgerDateTime: action.payload.value,
			})
		case SET_AP_FILTER_RECEIVE_ID:
			return Object.assign({}, state, {
				filterReceiveId: action.payload.value,
			})
		case SET_AP_FILTER:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_AP_RECEIVES:
			return Object.assign({}, state, {
				receiveTable: {
					...state.receiveTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_AP_SELECTED_RC:
			return Object.assign({}, state, {
				selectedRC: {
					index: action.payload.index,
					rc: action.payload.selected,
				},
			})
		case SET_AP_INVOICES:
			return Object.assign({}, state, {
				invoiceTable: {
					...state.invoiceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AP_SELECTED_INVOICE:
			return Object.assign({}, state, {
				selectedInvoice: {
					index: action.payload.index,
					invoice: action.payload.selected,
				},
			})
		case SET_AP_GENERAL_GROUPS:
			return Object.assign({}, state, {
				generalGroupTable: {
					...state.generalGroupTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AP_SELECTED_GROUP:
			return Object.assign({}, state, {
				selectedGroup: {
					index: action.payload.index,
					group: action.payload.selected,
				},
			})
		case SET_AP_GL_GROUPS:
			return Object.assign({}, state, {
				generalLedgerGroupTable: {
					...state.generalLedgerGroupTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AP_ITEMS:
			return Object.assign({}, state, {
				itemTable: {
					...state.itemTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AP_SELECTED_ITEM:
			return Object.assign({}, state, {
				selectedItem: {
					index: action.payload.index,
					item: action.payload.selected,
				},
			})
		case SET_AP_SELECTABLE_ACCOUNTING_CODES:
			return Object.assign({}, state, {
				selectableAccountingCodes: action.payload.value,
			})
		case SET_AP_MODAL_GL_SELECTED_GL:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					selectedGL: {
						index: action.payload.index,
						GL: action.payload.selected,
					},
				},
			})
		case SET_AP_MODAL_GL_DESCRIPTION:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					description: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default accountsPayableReducer
