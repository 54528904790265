import * as axios from "axios"
import { ResourceAssistance } from "~/i18n"
import { store } from "~/redux/Store"
import { LOGOUT } from "~/redux/type"

const Logout = ({ history }) => {
	const unSubscribe = store.subscribe(() => {})
	try {
		document.cookie = "accessToken= ; path=/"
		document.cookie = "refreshToken= ; path=/"
		axios({
			method: "GET",
			url: ResourceAssistance.Url.auth.logout,
			withCredentials: true,
		}).then((res) => {
			// store.dispatch(loginAction(LOGIN, false, {}))
			store.dispatch({ type: LOGOUT })
			// history.push(ResourceAssistance.Path.root)
		})
	} catch (error) {
		console.log(error)
	}
	unSubscribe()
}

export default Logout
