import { Checkbox } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA } from "~/redux/type"

export const CBDischargeCriteria = {
	"Aldrete score or modified PADSS <=8, notify the doctor.": "A",
	"Sensation level is lower than T10 level, notify the doctor.": "S",
}

class OperatingRoomModalPostAneDischargeDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDischargeCriteriaDetail = this.onDischargeCriteriaDetail.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedPostAneRecord.record) {
			this.props.setValue(SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA, this.props.operatingRoom.selectedPostAneRecord.record.dischargeCriteria.split(","))
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA, [])
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className="g-1">
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.dischargeCriteria)}:</label>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
							options={[
								{
									label: translate(ResourceAssistance.Message.aldreteScoreAndOrModifiedPADSS),
									value: CBDischargeCriteria["Aldrete score or modified PADSS <=8, notify the doctor."],
									style: { flexDirection: "row" },
								},
								{
									label: translate(ResourceAssistance.Message.sensationLevelIsLowerThanT10Level),
									value: CBDischargeCriteria["Sensation level is lower than T10 level, notify the doctor."],
									style: { flexDirection: "row" },
								},
							]}
							value={this.props.operatingRoom.modalPostAneDischarge.dischargeCriteriaDetail}
							onChange={this.onDischargeCriteriaDetail}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onDischargeCriteriaDetail(values) {
		this.props.setValue(SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA, values)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalPostAneDischargeDetails)
