import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import CashierModalDoctorAction from "./CashierModalDoctorAction"
import CashierModalDoctorOrder from "./CashierModalDoctorOrder"
import CashierModalDoctorPendingOrder from "./CashierModalDoctorPendingOrder"

class CashierModalDoctorDetails extends React.Component {
	constructor(props) {
		super(props)

		this.calculateDoctorOrderBalance = this.calculateDoctorOrderBalance.bind(this)
	}

	calculateDoctorOrderBalance(order) {
		return Utils.BigNumber(Utils.calculateDoctorOrderBalance(order))
			.minus(
				this.props.cashier.selectedAdmission.admission.billingStatements
					.filter((billingStatement) => billingStatement.doctorOrder && billingStatement.doctorOrder.id === order.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.minus(
				this.props.cashier.modalFinancialPlan.serviceTable.original
					.filter(
						(billingStatement) =>
							!billingStatement.id &&
							billingStatement.isDoctorOrder &&
							billingStatement.order &&
							billingStatement.order.id === order.id
					)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.minus(
				this.props.cashier.modalDoctor.pendingOrderTable.original
					.filter((pendingOrder) => pendingOrder.id === order.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.toFixed(2)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<CashierModalDoctorOrder />
				<CashierModalDoctorAction {...this.props} calculateDoctorOrderBalance={this.calculateDoctorOrderBalance} />
				<CashierModalDoctorPendingOrder />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalDoctorDetails)
