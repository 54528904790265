import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import HospitelLeftActionRow from "./HospitelLeftActionRow"
import HospitelLeftDateRow from "./HospitelLeftDateRow"
import HospitelLeftFilterRow from "./HospitelLeftFilterRow"
import HospitelLeftHospitelActionRow from "./HospitelLeftHospitelActionRow"
import HospitelLeftHospitelRow from "./HospitelLeftHospitelRow"
import HospitelLeftPatientRow from "./HospitelLeftPatientRow"
import HospitelLeftSearchRow from "./HospitelLeftSearchRow"

class HospitelLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelLeft",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<HospitelLeftDateRow {...this.props} />
				<HospitelLeftSearchRow />
				<HospitelLeftFilterRow />
				<HospitelLeftActionRow />
				<HospitelLeftPatientRow />
				<HospitelLeftHospitelActionRow />
				<HospitelLeftHospitelRow />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(HospitelLeft)
