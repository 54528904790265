import { Checkbox, Radio, Space } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBCrossMatching = {
	Packed_Red_Cell: "PCE",
	Fresh_Frozen_Plasma: "FFP",
	Platelet_Concentrate: "PC",
}

class OperatingRoomModalEditPrePrepared extends React.Component {
	constructor(props) {
		super(props)

		this.onAntiHIV = this.onAntiHIV.bind(this)
		this.onCrossMatchingDetail = this.onCrossMatchingDetail.bind(this)
		this.onRedCell = this.onRedCell.bind(this)
		this.onFreshFrozenPlasma = this.onFreshFrozenPlasma.bind(this)
		this.onPlateletConcentrate = this.onPlateletConcentrate.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let anesthesiaPreOP = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
			if (anesthesiaPreOP) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV, anesthesiaPreOP.antiHIV)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING, anesthesiaPreOP.crossMatching.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL, anesthesiaPreOP.crossMatchingPackedRedCell)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA, anesthesiaPreOP.crossMatchingFreshFrozenPlasma)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE, anesthesiaPreOP.crossMatchingPlateletConcentrate)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE, "")
	}

	render() {
		return (
			<>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.antiHIV)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAntiHIV} onChange={this.onAntiHIV}>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.negative)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.positive)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ marginTop: "7px", fontWeight: "bold" }}>{translate(ResourceAssistance.Message.crossMatching)}:</label>
					</Col>
					<Col>
						<Checkbox.Group
							options={[
								{
									label: [
										<Space key={1}>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												prefix={translate(ResourceAssistance.Message.packedRedCell)}
												suffix={translate(ResourceAssistance.Message.unit)}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.crossMatchingDetail, CBCrossMatching.Packed_Red_Cell)}
												value={this.props.operatingRoom.modalEditAnPre.crossMatchingRedCell}
												onChange={this.onRedCell}
											/>
										</Space>,
									],
									value: CBCrossMatching.Packed_Red_Cell,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: [
										<Space key={1}>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												prefix={translate(ResourceAssistance.Message.freshFrozenPlasma)}
												suffix={translate(ResourceAssistance.Message.unit)}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.crossMatchingDetail, CBCrossMatching.Fresh_Frozen_Plasma)}
												value={this.props.operatingRoom.modalEditAnPre.crossMatchingFreshFrozenPlasma}
												onChange={this.onFreshFrozenPlasma}
											/>
										</Space>,
									],
									value: CBCrossMatching.Fresh_Frozen_Plasma,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: [
										<Space key={1}>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												prefix={translate(ResourceAssistance.Message.plateletConcentrate)}
												suffix={translate(ResourceAssistance.Message.unit)}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.crossMatchingDetail, CBCrossMatching.Platelet_Concentrate)}
												value={this.props.operatingRoom.modalEditAnPre.crossMatchingPlateletConcentrate}
												onChange={this.onPlateletConcentrate}
											/>
										</Space>,
									],
									value: CBCrossMatching.Platelet_Concentrate,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.crossMatchingDetail}
							onChange={this.onCrossMatchingDetail}
						/>
					</Col>
				</Row>
			</>
		)
	}
	onAntiHIV(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV, e.target.value)
	}
	onCrossMatchingDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING, values)
	}
	onRedCell(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL, e.target.value)
	}
	onFreshFrozenPlasma(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA, e.target.value)
	}
	onPlateletConcentrate(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPrePrepared))
