import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPSModalNewInvoices, setSelected } from "~/redux/action"
import { SET_PS_MODAL_NEW_SELECTED_INVOICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PSModalNewInvoices extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectInvoice = this.onSelectInvoice.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.PS.modalNew.filterInvoiceNum, this.props.PS.modalNew.filterInvoiceNum) ||
			!_.isEqual(prevProps.PS.modalNew.filterInvoiceId, this.props.PS.modalNew.filterInvoiceId) ||
			!_.isEqual(
				prevProps.PS.modalNew.pendingInvoiceTable.original,
				this.props.PS.modalNew.pendingInvoiceTable.original
			)
		) {
			this.props.setSelected(SET_PS_MODAL_NEW_SELECTED_INVOICE, -1, null)
			this.props.setPSModalNewInvoices(this.props.PS.modalNew.invoiceTable.original)
		}

		if (
			!_.isEqual(prevProps.PS.modalNew.searchSupplier, this.props.PS.modalNew.searchSupplier) ||
			!_.isEqual(prevProps.PS.modalNew.searchType, this.props.PS.modalNew.searchType)
		) {
			this.props.setSelected(SET_PS_MODAL_NEW_SELECTED_INVOICE, -1, null)
			this.props.setPSModalNewInvoices([])
		}
	}

	componentWillUnmount() {
		this.props.setPSModalNewInvoices([])
		this.props.setSelected(SET_PS_MODAL_NEW_SELECTED_INVOICE, -1, null)
	}

	onSelectInvoice(service, index) {
		this.props.setSelected(
			SET_PS_MODAL_NEW_SELECTED_INVOICE,
			index,
			this.props.PS.modalNew.invoiceTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectInvoice}
						data={this.props.PS.modalNew.invoiceTable}
						isClearHighlight={this.props.PS.modalNew.selectedInvoice.index === -1}
						highlightedRow={this.props.PS.modalNew.selectedInvoice.index}
					/>
					{this.props.PS.modalNew.invoiceTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPSModalNewInvoices,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalNewInvoices))
