import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setORModalPostAneMedicationPendingItems, setSelected } from "~/redux/action"
import { SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM } from "~/redux/type"
import ServerUtils from "~/utils/ServerUtils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ORModalPostAneMedicationPending extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidMount() {
		this.props.setORModalPostAneMedicationPendingItems(
			this.getPendingItems(this.props.operatingRoom.selectedPostAneRecord.record.doctorOrders.filter((each) => !each.serviceCode))
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.selectedRequest.request, this.props.operatingRoom.selectedRequest.request)) {
			if (this.props.operatingRoom.selectedRequest.request) {
				this.props.setORModalPostAneMedicationPendingItems(
					this.getPendingItems(this.props.operatingRoom.selectedPostAneRecord.record.doctorOrders.filter((each) => !each.serviceCode))
				)
			}
		}

		if (
			!_.isEqual(
				prevProps.operatingRoom.modalPostAneMedication.pendingItemTable.original,
				this.props.operatingRoom.modalPostAneMedication.pendingItemTable.original
			)
		) {
			this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setORModalPostAneMedicationPendingItems([])
		this.props.setSelected(SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM, -1, null)
	}

	getPendingItems(doctorOrders) {
		return doctorOrders.map((each) => {
			let ary = ServerUtils.getNameAndBrandFromDoctorOrder(each.description)
			return {
				...each,
				displayName: ary[0],
				brand: ary[1],
				isExistOrder: true,
			}
		})
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						style={{ minHeight: "200px", maxHeight: "200px" }}
						striped
						hover
						enableHighlight
						onClick={this.onSelectItem}
						data={this.props.operatingRoom.modalPostAneMedication.pendingItemTable}
						isClearHighlight={this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.index === -1}
						highlightedRow={this.props.operatingRoom.modalPostAneMedication.selectedPendingItem.index}
					/>
				</Col>
			</Row>
		)
	}

	onSelectItem(item, index) {
		this.props.setSelected(
			SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM,
			index,
			this.props.operatingRoom.modalPostAneMedication.pendingItemTable.filtered[index]
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setORModalPostAneMedicationPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalPostAneMedicationPending))
