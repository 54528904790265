import { Divider } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, FormControl, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setConfigICDICDs,
	setLoadingAction,
	setObjArray,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_ICD_DISPLAY_ALL_IND,
	SET_ADM_ICD_ICDS_LOADING,
	SET_ADM_ICD_SEARCH_CODE,
	SET_ADM_ICD_SEARCH_NAME,
	SET_ADM_ICD_SELECTED_ICD,
	SET_ADM_ICD_SELECTED_TYPE,
	SET_ADM_ICD_TYPES,
	SET_CONFIG_ICD_NEW_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigICD extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigICD",
		}

		this.onType = this.onType.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadTypes(
			ResourceAssistance.Url.admin.icd.getICDs,
			(res) => {
				this.props.setObjArray(SET_ADM_ICD_TYPES, res.data.types)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_ICD_ICDS_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_ICD_ICDS_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_ICD_ICDS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setConfigICDICDs([])
		this.props.setObjArray(SET_ADM_ICD_TYPES, [])
		this.props.setSelected(SET_ADM_ICD_SELECTED_TYPE, -1, null)
		this.props.setSelected(SET_ADM_ICD_SELECTED_ICD, -1, null)
		this.props.setValue(SET_ADM_ICD_SEARCH_CODE, "")
		this.props.setValue(SET_ADM_ICD_SEARCH_NAME, "")
		this.props.setValue(SET_ADM_ICD_DISPLAY_ALL_IND, true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.icd.types, this.props.icd.types)) {
			let type = document.getElementById(ResourceAssistance.ID.ADM.icd.type)
			type.selectedIndex = 0
			this.props.setSelected(SET_ADM_ICD_SELECTED_TYPE, type.selectedIndex, this.props.icd.types[type.selectedIndex])
			this.props.setConfigICDICDs(this.props.icd.types[type.selectedIndex].icds)
		}
		if (
			prevProps.icd.selectedType.index !== this.props.icd.selectedType.index ||
			prevProps.icd.searchCode !== this.props.icd.searchCode ||
			prevProps.icd.searchName !== this.props.icd.searchName ||
			prevProps.icd.displayInactive !== this.props.icd.displayInactive
		) {
			if (this.props.icd.selectedType.type) {
				this.props.setConfigICDICDs(this.props.icd.selectedType.type.icds)
			}
		}
		if (!_.isEqual(prevProps.icd.icdTable.filtered, this.props.icd.icdTable.filtered)) {
			if (this.props.icd.selectedICD.icd) {
				let idx = this.props.icd.icdTable.filtered.findIndex((each) => each.id === this.props.icd.selectedICD.icd.id)
				if (idx > -1) {
					this.props.setSelected(SET_ADM_ICD_SELECTED_ICD, idx, this.props.icd.icdTable.filtered[idx])
				} else {
					this.props.setSelected(SET_ADM_ICD_SELECTED_ICD, -1, null)
				}
			} else {
				this.props.setSelected(SET_ADM_ICD_SELECTED_ICD, -1, null)
			}
		}
	}

	onType(event) {
		this.props.setSelected(SET_ADM_ICD_SELECTED_TYPE, event.target.value, this.props.icd.types[event.target.value])
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADM_ICD_SEARCH_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_ICD_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setValue(SET_ADM_ICD_DISPLAY_ALL_IND, event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADM_ICD_SELECTED_ICD, index, this.props.icd.icdTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADM_ICD_SELECTED_ICD, -1, null)
		this.props.onModalDisplayAction(SET_CONFIG_ICD_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_CONFIG_ICD_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md="auto">
						<FormControl
							id={ResourceAssistance.ID.ADM.icd.type}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.icd.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.icd.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.icd.selectedICD.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							enableHighlight
							data={this.props.icd.icdTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.icd.selectedICD.index === -1}
						/>
						{this.props.icd.icdTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	icd: state.admin.configICD.icd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setValue,
			setSelected,
			setLoadingAction,
			onModalDisplayAction,
			setConfigICDICDs,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigICD)
