import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import GInput from "~/view/component/input/GInput"

class FrontInventoryModalEditMinMax extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			min: 0,
			max: 0,
		}

		this.onMin = this.onMin.bind(this)
		this.onMax = this.onMax.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	componentDidMount() {
		this.setState({
			min: this.props.min,
			max: this.props.max,
		})
	}

	componentWillUnmount() {
		this.setState({
			min: 0,
			max: 0,
		})
	}

	onMin(event) {
		this.setState({
			min: event.target.value,
		})
	}

	onMax(event) {
		this.setState({
			max: event.target.value,
		})
	}

	render() {
		return (
			<Row>
				<Col>
					<GInput
						style={{ textAlign: "center" }}
						type={ResourceAssistance.FormControl.type.number}
						placeholder={translate(ResourceAssistance.Message.min)}
						onChange={this.onMin}
						value={this.state.min}
					/>
				</Col>
				<Col>
					<GInput
						style={{ textAlign: "center" }}
						type={ResourceAssistance.FormControl.type.number}
						placeholder={translate(ResourceAssistance.Message.max)}
						onChange={this.onMax}
						value={this.state.max}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FrontInventoryModalEditMinMax)
