import { Checkbox, Radio, Typography } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBEyesCare = {
	Ointment: "O",
	Gel: "G",
	Taped: "T",
	Other: "Ot",
}
export const CBWarming = {
	Blanket: "B",
	Force_Air_Warmer: "F",
	HME_Filter: "H",
	Fluid_Warmer: "FW",
	Other: "Ot",
}

class OperatingRoomModalIntraAnesthePatientSafetyDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onAnestheticMachine = this.onAnestheticMachine.bind(this)
		this.onPressurePoints = this.onPressurePoints.bind(this)
		this.onArmsRestrained = this.onArmsRestrained.bind(this)
		this.onEyesCareDetail = this.onEyesCareDetail.bind(this)
		this.onEyesCareOther = this.onEyesCareOther.bind(this)
		this.onWarmingDetail = this.onWarmingDetail.bind(this)
		this.onWarmingOther = this.onWarmingOther.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED, op.anestheticMachineChecked)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED, op.pressurePointsChecked)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED, op.armsRestrained)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL, op.eyesCare.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER, op.eyesCareOther)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL, op.warming.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER, op.warmingOther)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAnePatientSafety.eyesCareDetail, this.props.operatingRoom.modalIntraAnePatientSafety.eyesCareDetail)) {
			if (!_.includes(this.props.operatingRoom.modalIntraAnePatientSafety.eyesCareDetail, CBEyesCare.Other)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAnePatientSafety.warmingDetail, this.props.operatingRoom.modalIntraAnePatientSafety.warmingDetail)) {
			if (!_.includes(this.props.operatingRoom.modalIntraAnePatientSafety.warmingDetail, CBWarming.Other)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={3}>
						<Typography.Text style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.anestheticMachineEquipmentsChecked)}:</Typography.Text>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePatientSafety.isAnestheticMachineChecked} onChange={this.onAnestheticMachine}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.pressurePointsChecked)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePatientSafety.isPressurePointsChecked} onChange={this.onPressurePoints}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.armsRestrained)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePatientSafety.isArmsRestrained} onChange={this.onArmsRestrained}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.eyesCare)}:</label>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.ointment),
									value: CBEyesCare.Ointment,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.gel),
									value: CBEyesCare.Gel,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.taped),
									value: CBEyesCare.Taped,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBEyesCare.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAnePatientSafety.eyesCareDetail}
							onChange={this.onEyesCareDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalIntraAnePatientSafety.eyesCareDetail, CBEyesCare.Other)}
							value={this.props.operatingRoom.modalIntraAnePatientSafety.eyesCareOther}
							onChange={this.onEyesCareOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.warming)}:</label>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.blanket),
									value: CBWarming.Blanket,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.forceAirWarmer),
									value: CBWarming.Force_Air_Warmer,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.HMEFilter),
									value: CBWarming.HME_Filter,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.fluidWarmer),
									value: CBWarming.Fluid_Warmer,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBWarming.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAnePatientSafety.warmingDetail}
							onChange={this.onWarmingDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalIntraAnePatientSafety.warmingDetail, CBEyesCare.Other)}
							value={this.props.operatingRoom.modalIntraAnePatientSafety.warmingOther}
							onChange={this.onWarmingOther}
						/>
					</Col>
				</Row>
			</Container>
		)
	}

	onAnestheticMachine(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED, e.target.value)
	}
	onPressurePoints(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED, e.target.value)
	}
	onArmsRestrained(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED, e.target.value)
	}
	onEyesCareDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL, values)
	}
	onEyesCareOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER, e.target.value)
	}
	onWarmingDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL, values)
	}
	onWarmingOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAnesthePatientSafetyDetails)
