import { Checkbox } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OR_MODAL_POST_ANE_COMPLICATION, SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBComplication = {
	Hypotension: "Hypo",
	Hypertension: "Hype",
	Arrhythmia: "Ar",
	Airway_Obstruction: "Ai",
	Hypoxia: "Hypox",
	"Nausea/Vomitting": "N",
	Urinary_Retention: "U",
	Shivering: "S",
	Bleeding: "B",
	Hypoventilation: "Hypov",
	Reversed_Trend: "R",
	Other: "O",
}

class OperatingRoomModalPostAneComplicationDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onComplicationDetail = this.onComplicationDetail.bind(this)
		this.onComplicationOther = this.onComplicationOther.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedPostAneRecord.record) {
			this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION, this.props.operatingRoom.selectedPostAneRecord.record.complication.split(","))
			this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER, this.props.operatingRoom.selectedPostAneRecord.record.complicationOther)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalPostAneComplication.complicationDetail, this.props.operatingRoom.modalPostAneComplication.complicationDetail)) {
			if (!_.includes(this.props.operatingRoom.modalPostAneComplication.complicationDetail, CBComplication.Other)) {
				this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION, [])
		this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className="g-1">
					<Col md="auto">
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.hypotension),
									value: CBComplication.Hypotension,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.hypertension),
									value: CBComplication.Hypertension,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.arrhythmia),
									value: CBComplication.Arrhythmia,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.airwayObstruction),
									value: CBComplication.Airway_Obstruction,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.hypoxia),
									value: CBComplication.Hypoxia,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.nauseaOrVomitting),
									value: CBComplication["Nausea/Vomitting"],
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.urinaryRetention),
									value: CBComplication.Urinary_Retention,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.shivering),
									value: CBComplication.Shivering,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.bleeding),
									value: CBComplication.Bleeding,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.hypoventilation),
									value: CBComplication.Hypoventilation,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBComplication.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalPostAneComplication.complicationDetail}
							onChange={this.onComplicationDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalPostAneComplication.complicationDetail, CBComplication.Other)}
							value={this.props.operatingRoom.modalPostAneComplication.complicationOther}
							onChange={this.onComplicationOther}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onComplicationDetail(values) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION, values)
	}
	onComplicationOther(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalPostAneComplicationDetails)
