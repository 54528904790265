import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { BiDownload, BiUpload } from "react-icons/bi"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue } from "~/redux/action"
import { SET_DEPOSIT_FILTERS, SET_DEPOSIT_SAVING_DISPLAY, SET_DEPOSIT_WITHDRAW_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class DepositAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDeposit = this.onDeposit.bind(this)
		this.onWithdraw = this.onWithdraw.bind(this)
		this.onChangeFilter = this.onChangeFilter.bind(this)
		this.state = {
			filters: [
				{
					id: ResourceAssistance.Message.deposit,
					displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.deposit }),
				},
				{
					id: ResourceAssistance.Message.withdraw,
					displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.withdraw }),
				},
			],
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.language.locale, this.props.language.locale)) {
			this.setState({
				filters: [
					{
						id: ResourceAssistance.Message.deposit,
						displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.deposit }),
					},
					{
						id: ResourceAssistance.Message.withdraw,
						displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.withdraw }),
					},
				],
			})
		}
	}

	isWithdrawDisabled() {
		return (
			Utils.BigNumber(Utils.calculatePatientDeposit(this.props.deposit.patient.transactions)).isLessThanOrEqualTo(0) ||
			this.props.deposit.patient.admissions.some((admission) => admission.dischargedDateTime === undefined)
		)
	}

	getFilters() {
		let filters = []
		this.props.deposit.filters.forEach((each) => {
			this.state.filters.forEach((filter, idx) => {
				if (filter.id === each) {
					filters.push(idx)
				}
			})
		})
		return filters
	}

	onDeposit() {
		this.props.onModalDisplayAction(SET_DEPOSIT_SAVING_DISPLAY, true)
	}

	onWithdraw() {
		this.props.onModalDisplayAction(SET_DEPOSIT_WITHDRAW_DISPLAY, true)
	}

	onChangeFilter(values) {
		let filters = values.map((each) => this.state.filters[each].id)
		this.props.setValue(SET_DEPOSIT_FILTERS, filters)
	}

	render() {
		return (
			<Row className="g-2">
				<Col md={2}>
					<Select
						mode={ResourceAssistance.Select.mode.multiple}
						allowClear
						showSearch
						style={{ flexGrow: 1, textAlign: "center" }}
						maxTagCount="responsive"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
						placeholder={translate(ResourceAssistance.Message.filter)}
						value={this.getFilters()}
						onChange={this.onChangeFilter}
					>
						{Utils.renderSelects(this.state.filters, false)}
					</Select>
				</Col>
				<Col md={"auto"}>
					<Button variant={ResourceAssistance.Button.variant.green} onClick={this.onDeposit}>
						<BiDownload size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.deposit)}
					</Button>
				</Col>
				<Col md={"auto"}>
					<Button variant={ResourceAssistance.Button.variant.red} disabled={this.isWithdrawDisabled()} onClick={this.onWithdraw}>
						<BiUpload size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.withdraw)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	language: state.language,
	deposit: state.hospital.deposit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DepositAction))
