import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import OPDModalAppointmentEditDate from "./OPDModalAppointmentEditDate"

class OPDModalAppointmentEditDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<OPDModalAppointmentEditDate />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalAppointmentEditDetails)
