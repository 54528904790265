import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_FRONT_INVENTORY_MODAL_WASTE_REASON } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class FrontInventoryModalWasteReason extends React.Component {
	constructor(props) {
		super(props)

		this.onReason = this.onReason.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_FRONT_INVENTORY_MODAL_WASTE_REASON, "")
	}

	onReason(e) {
		this.props.setValue(SET_FRONT_INVENTORY_MODAL_WASTE_REASON, e.target.value)
	}

	render() {
		return (
			<Row>
				<Col>
					<GInput
						autoSize
						required
						type={ResourceAssistance.FormControl.type.text}
						placeholder={translate(ResourceAssistance.Message.reason)}
						minRows={5}
						onChange={this.onReason}
						value={this.props.frontInventory.modalWaste.reason}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontInventoryModalWasteReason)
