import { Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPurchaseInvoices, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PI_GL_DATE_TIME,
	SET_PI_NEW_DISPLAY,
	SET_PI_SEARCH_DISPLAY,
	SET_PI_SELECTED_PI,
	SET_PI_SELECTED_PURCHASE,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class PurchaseInvoiceLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onGeneralLedgerDateTime = this.onGeneralLedgerDateTime.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.complete)) {
				this.completePurchaseInvoice()
			}
		}
		if (!_.isEqual(prevProps.warning.isClose, this.props.warning.isClose)) {
			if (this.props.warning.isClose) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.complete)) {
					this.props.setValue(SET_PI_GL_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
				}
			}
		}
	}

	completePurchaseInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.purchaseInvoice.completePurchaseInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PI.location.id,
				purchaseInvoiceId: this.props.PI.selectedPI.PI.id,
				userId: this.props.login.user.id,
				generalLedgers: this.props.PI.purchaseTable.body.map((each, idx) => {
					return {
						dateTime: this.props.PI.generalLedgerDateTime,
						documentCode: ResourceAssistance.Enum.purchaseInvoiceDocumentCode,
						documentId: this.props.PI.selectedPI.PI.id,
						code: each[0],
						name: each[1],
						category: "",
						transactionDescription: each[2],
						debit: Utils.formatNumberFromStr(each[10]).toFixed(2),
						credit: Utils.formatNumberFromStr(each[11]).toFixed(2),
						workflow: ResourceAssistance.Enum.purchaseInvoice,
						systemGenerated: this.props.PI.purchaseTable.rowColor[idx][0],
					}
				}),
			},
		}
		let callback = (res) => {
			let invoices = this.props.PI.purchaseInvoiceTable.original.filter((each) => each.id !== this.props.PI.selectedPI.PI.id)
			if (res.data.invoices.length > 0) {
				invoices.push(res.data.invoices[0])
			}
			this.props.setPurchaseInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isCompleteDisabled() {
		return !this.props.PI.selectedPI.PI || !this.props.PI.selectedPI.PI.inspectorComplete || this.props.PI.selectedPI.PI.complete
	}

	isEditDisabled() {
		return !this.props.PI.selectedPI.PI || this.props.PI.selectedPI.PI.inspectorComplete
	}

	onComplete(e, row, rIdx, cIdx) {
		let selectedPI = this.props.PI.selectedPI.PI
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.complete)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceNum)} span={1}>
						{selectedPI.invoice}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedPI.paymentTermDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
						{selectedPI.payment}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierCode)} span={1}>
						{selectedPI.supplierCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierName)} span={1}>
						{selectedPI.supplierName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPI.remark}
					</Descriptions.Item>
					<Descriptions.Item labelStyle={{ alignItems: "center" }} label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
						<AntDatePicker
							allowClear={false}
							size={"middle"}
							format={"DD-MM-YYYY"}
							defaultValues={[moment(this.props.PI.generalLedgerDateTime)]}
							onChange={this.onGeneralLedgerDateTime}
						/>
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onGeneralLedgerDateTime(value) {
		this.props.setValue(SET_PI_GL_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_PI_SEARCH_DISPLAY, true)
	}

	onNew() {
		this.props.setSelected(SET_PI_SELECTED_PI, -1, null)
		this.props.setSelected(SET_PI_SELECTED_PURCHASE, -1, null)
		this.props.onModalDisplayAction(SET_PI_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.setSelected(SET_PI_SELECTED_PURCHASE, -1, null)
		this.props.onModalDisplayAction(SET_PI_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PurchaseInvoice.Approver, this.props.login.user.roles) ? (
					<>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.isCompleteDisabled()}
								onClick={this.onComplete}
							>
								<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.complete)}
							</Button>
						</Col>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
					</>
				) : (
					<Col />
				)}
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearch}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.isEditDisabled()}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setPurchaseInvoices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceLeftAction))
