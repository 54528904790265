import _ from "lodash"
import React from "react"
import { Button, Col, Form, FormCheck, FormGroup, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setIPDPatients, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_PATIENTS_LOADING,
	SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY,
	SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import RegisterModalClinicalRecordDetails from "./RegisterModalClinicalRecordDetails"

class RegisterModalClinicalRecord extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RegisterModalClinicalRecord",
		}

		this.statusRef = React.createRef()

		this.onCarAccident = this.onCarAccident.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY, false)
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.ipd.selectedAdmission.admission) {
			return false
		}

		let admission = this.props.ipd.selectedAdmission.admission
		let target = Object.assign({}, admission, {
			clinicalRecord: {
				...admission.clinicalRecord,
				healthcareCode: this.props.ipd.modalClinicalRecord.healthcareCode,
				healthcareName: this.props.ipd.modalClinicalRecord.healthcareName,
				healthcareDescription: this.props.ipd.modalClinicalRecord.healthcareDescription,
				healthcareOther: Utils.trim(this.props.ipd.modalClinicalRecord.healthcareOther),
				primaryHospitalCode: Utils.trim(this.props.ipd.modalClinicalRecord.primaryHospitalCode),
				primaryHospitalName: Utils.trim(this.props.ipd.modalClinicalRecord.primaryHospitalName),
				authenNumber: Utils.trim(this.props.ipd.modalClinicalRecord.authenNumber),
				ucae: Utils.trim(this.props.ipd.modalClinicalRecord.ucae),
				hn: this.props.ipd.modalClinicalRecord.hn,
				arrival: Utils.trim(this.props.ipd.modalClinicalRecord.arrival),
				triage: this.props.ipd.modalClinicalRecord.selectedTriage.triage ? this.props.ipd.modalClinicalRecord.selectedTriage.triage.displayName : "",
				isolationPrecaution: this.statusRef.current ? this.statusRef.current.state.iso : "",
				fallRisk: this.statusRef.current ? this.statusRef.current.state.fallRisk : "",
				language: Utils.trim(this.props.ipd.modalClinicalRecord.language),
			},
		})
		return _.isEqual(admission, target)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onCarAccident(event) {
		this.props.setValue(SET_REGISTRATION_MODAL_CLINICAL_RECORD_CAR_ACCIDENT_DISPLAY, event.target.checked)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let admissionId = 0
		let admissions = this.props.ipd.selectedPatient.patient.admissions.filter((admission) => !admission.dischargedDateTime)
		if (this.props.ipd.selectedAdmission.admission) {
			admissionId = this.props.ipd.selectedAdmission.admission.id
		} else if (admissions.length > 0) {
			admissionId = admissions[0].id
		}

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.registration.createClinicalRecord,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.ipd.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				admissionId: admissionId,
				clinicalRecord: {
					id: this.props.ipd.selectedAdmission.admission ? this.props.ipd.selectedAdmission.admission.clinicalRecord.id : 0,
					healthcareCode: this.props.ipd.modalClinicalRecord.healthcareCode,
					healthcareName: this.props.ipd.modalClinicalRecord.healthcareName,
					healthcareDescription: this.props.ipd.modalClinicalRecord.healthcareDescription,
					healthcareOther: Utils.trim(this.props.ipd.modalClinicalRecord.healthcareOther),
					primaryHospitalCode: Utils.trim(this.props.ipd.modalClinicalRecord.primaryHospitalCode),
					primaryHospitalName: Utils.trim(this.props.ipd.modalClinicalRecord.primaryHospitalName),
					authenNumber: Utils.trim(this.props.ipd.modalClinicalRecord.authenNumber),
					ucae: Utils.trim(this.props.ipd.modalClinicalRecord.ucae),
					hn: this.props.ipd.modalClinicalRecord.hn,
					arrival: Utils.trim(this.props.ipd.modalClinicalRecord.arrival),
					triage: this.props.ipd.modalClinicalRecord.selectedTriage.triage.displayName,
					isolationPrecaution: this.statusRef.current.state.iso,
					fallRisk: this.statusRef.current.state.fallRisk,
					operationType: this.props.ipd.modalClinicalRecord.operationType,
					language: Utils.trim(this.props.ipd.modalClinicalRecord.language),
				},
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setIPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				size={ResourceAssistance.Modal.size.md}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.registerOPD)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<RegisterModalClinicalRecordDetails statusRef={this.statusRef} onRenderParentCallback={this.onRenderParentCallback} {...this.props} />
					</ModalBody>

					<ModalFooter>
						<FormGroup controlId={"car-accident"}>
							<FormCheck
								label={translate(ResourceAssistance.Message.carAccident)}
								checked={this.props.ipd.modalClinicalRecord.isCarAccidentDisplay}
								onChange={this.onCarAccident}
							/>
						</FormGroup>
						<Col />
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={
								(_.isEmpty(this.props.ipd.modalClinicalRecord.healthcareName) && _.isEmpty(this.props.ipd.modalClinicalRecord.healthcareOther)) ||
								!this.props.ipd.modalClinicalRecord.selectedTriage.triage ||
								this.isDoneBtnDisabled()
							}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.ipd.isClinicalRecordDisplay,
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setIPDPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterModalClinicalRecord)
