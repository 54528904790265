const PreOperativeCheckList = {
	identifyThePatientNameSurnameAndDateOfBirth: "Identify the patient's name/Date of birth",
	markingSiteForSurgeryProcedure: "Marking site for surgery/procedure",
	letterOfConsentToUndergoSurgeryDiagnosis: "Letter of consent to undergo surgery/diagnosis",
	letterOfConsentToReceiveAnesthesia: "Letter of consent to receive anesthesia",
	labResult: "Lab result",
	imagingResult: "Imaging result",
	EKGResult: "EKG result",
	cleanPatientBodyForSurgery: "Clean the patient's body for surgery",
	unisonEnema: "Unison enema",
	NPOTime: "NPO time",
	removeDentures: "Remove dentures",
	eyeGlassesContactLensEyelashesRemoved: "Eyeglasses/contact lens/eyelashes removed",
	jewelryAndHairpiecesRemoved: "Jewelry and hairpieces removed",
	bodyPiercingRemoved: "Body piercing removed",
	changePatientClothes: "Change patient clothes",
	haveThePatientUrinateBeforeBeingReferralOR: "Have the patient urinate before being referral OR",
	bloodRequested: "Blood requested",
	preEducation: "Pre-education",
	otherMedicalDevice: "Other medical device",
	toolSpecialEquipment: "Tool/special equipment",
	implant: "Implant",
}

export default PreOperativeCheckList
