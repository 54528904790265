import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setInventoryLots, setInventoryPendingOrder } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class InventoryFourthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InventoryFourthRow",
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.selectedInventoryDetail.inventoryDetail) !==
			JSON.stringify(this.props.selectedInventoryDetail.inventoryDetail)
		) {
			this.props.setInventoryLots(
				this.props.selectedInventoryDetail.index === -1 ? [] : this.props.selectedInventoryDetail.inventoryDetail.lots
			)
			this.props.setInventoryPendingOrder(
				this.props.selectedInventoryDetail.index === -1 ? {} : this.props.selectedInventoryDetail.inventoryDetail.pr
			)
		}

		if (prevProps.lotExpiredDate !== this.props.lotExpiredDate) {
			this.props.setInventoryLots(
				this.props.selectedInventoryDetail.index === -1 ? [] : this.props.selectedInventoryDetail.inventoryDetail.lots
			)
		}
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"g-0"}>
				<Col>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						responsive
						hover
						enableHighlight
						onClick={this.onSelectInventoryClick}
						data={this.props.lots}
						isClearHighlight={false}
					/>
				</Col>
				<Col md={2}>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pendingOrder)}</legend>
						<Row className={"g-0 label-info-row"}>
							<Col md="auto">
								<label>
									{translate(ResourceAssistance.Message.hashSymbol)}
									{ResourceAssistance.Symbol.colon}
								</label>
							</Col>
							<Col>
								<FormControl
									disabled
									type={ResourceAssistance.FormControl.type.text}
									size={ResourceAssistance.FormControl.size.sm}
									value={this.props.pendingOrder.prId}
								></FormControl>
							</Col>
						</Row>
						<Row className={"g-0 label-info-row"}>
							<Col md="auto">
								<label>
									{translate(ResourceAssistance.Message.dateOfPo)}
									{ResourceAssistance.Symbol.colon}
								</label>
							</Col>
							<Col>
								<FormControl
									disabled
									type={ResourceAssistance.FormControl.type.text}
									size={ResourceAssistance.FormControl.size.sm}
									value={this.props.pendingOrder.dateOfPo}
								></FormControl>
							</Col>
						</Row>
						<Row className={"g-0 label-info-row"}>
							<Col md="auto">
								<label>
									{translate(ResourceAssistance.Message.package)}
									{ResourceAssistance.Symbol.colon}
								</label>
							</Col>
							<Col>
								<FormControl
									disabled
									type={ResourceAssistance.FormControl.type.text}
									size={ResourceAssistance.FormControl.size.sm}
									value={this.props.pendingOrder.package}
								></FormControl>
							</Col>
						</Row>
						<Row className={"g-0 label-info-row"}>
							<Col md="auto">
								<label>
									{translate(ResourceAssistance.Message.amount)}
									{ResourceAssistance.Symbol.colon}
								</label>
							</Col>
							<Col>
								<FormControl
									disabled
									type={ResourceAssistance.FormControl.type.text}
									size={ResourceAssistance.FormControl.size.sm}
									value={this.props.pendingOrder.amount}
								></FormControl>
							</Col>
						</Row>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	lotExpiredDate: state.inventory.lotExpiredDate,
	lots: state.inventory.lots,
	pendingOrder: state.inventory.pendingOrder,
	selectedInventoryDetail: state.inventory.selectedInventoryDetail,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setInventoryLots, setInventoryPendingOrder }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFourthRow)
