import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_OR_OPERATING_ROOMS, SET_OR_OPERATING_ROOMS_LOADING } from "~/redux/type"
import OperatingRoomLeftDate from "./OperatingRoomLeftDate"
import OperatingRoomLeftOperatingRequest from "./OperatingRoomLeftOperatingRequest"

class OperatingRoomLeft extends React.Component {
	componentDidMount() {
		this.loadOperatingRooms()
	}

	loadOperatingRooms() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getOperatingRooms,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				branchId: this.props.operatingRoom.location.id,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_OPERATING_ROOMS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_OPERATING_ROOMS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_OPERATING_ROOMS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_OPERATING_ROOMS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<OperatingRoomLeftDate />
				{/* <SurgicalAuthLeftFilter /> */}
				{/* <SurgicalAuthLeftAction /> */}
				<OperatingRoomLeftOperatingRequest />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(OperatingRoomLeft)
