import { Checkbox, Space } from "antd"
import React from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES,
	SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalAdjustmentServiceDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCheckEndDate = this.onCheckEndDate.bind(this)
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onNote = this.onNote.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.modalAdjustmentService.service && this.props.hospitel.modalAdjustmentService.service.startDateTime) {
			this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME, this.props.hospitel.modalAdjustmentService.service.startDateTime)
			this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES, this.props.hospitel.modalAdjustmentService.service.notes)
			if (this.props.hospitel.modalAdjustmentService.service.endDateTime) {
				this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND, false)
				this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME, this.props.hospitel.modalAdjustmentService.service.endDateTime)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.hospitel.modalAdjustmentService.startDateTime !== this.props.hospitel.modalAdjustmentService.startDateTime) {
			this.props.setValue(
				SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME,
				Utils.generateDateFromLong(this.props.hospitel.modalAdjustmentService.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND, true)
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME, Utils.generateDate().getTime())
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES, "")
	}

	onCheckEndDate(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND, !event.target.checked)
	}

	onStartDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME, date.getTime())
	}

	onNote(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES, event.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0 label-info-row"} style={{ justifyContent: "center" }}>
					<Col md="auto">
						<DatePicker
							isShowTime={false}
							startDate={Utils.generateDateFromLong(this.props.hospitel.modalAdjustmentService.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col>
						<label style={{ textAlign: "center" }}>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md="auto">
						<Space size={"small"}>
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={!this.props.hospitel.modalAdjustmentService.isDisableEndDate}
								onChange={this.onCheckEndDate}
							/>
							<DatePicker
								isShowTime={false}
								minDate={Utils.generateDateFromLong(this.props.hospitel.modalAdjustmentService.startDateTime)}
								showDisabledMonthNavigation
								disabled={this.props.hospitel.modalAdjustmentService.isDisableEndDate}
								startDate={Utils.generateDateFromLong(this.props.hospitel.modalAdjustmentService.endDateTime)}
								onChange={this.onEndDate}
							/>
						</Space>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.note}>
							{(placeholder) => (
								<FormControl
									autoFocus
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									placeholder={placeholder}
									value={this.props.hospitel.modalAdjustmentService.notes}
									onChange={this.onNote}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalAdjustmentServiceDetails)
