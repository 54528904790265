import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import FrontInventoryTabStock from "./FrontInventoryTabStock"
import FrontInventoryTabWaste from "./FrontInventoryTabWaste"

class FrontInventoryTab extends React.Component {
	EFrontInventory = {
		STOCK: "STOCK",
		WASTE: "WASTE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EFrontInventory.STOCK,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Row>
					<Col>
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey={this.EFrontInventory.STOCK}>{translate(ResourceAssistance.Message.stock)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EFrontInventory.WASTE}>{translate(ResourceAssistance.Message.waste)}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
				<Row className="full-size" style={{ maxHeight: "200px" }}>
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.EFrontInventory.STOCK}>{this.state.tab === this.EFrontInventory.STOCK && <FrontInventoryTabStock />}</Tab.Pane>
							<Tab.Pane eventKey={this.EFrontInventory.WASTE}>{this.state.tab === this.EFrontInventory.WASTE && <FrontInventoryTabWaste />}</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FrontInventoryTab)
