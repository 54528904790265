import { TreeSelect } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK,
	SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class ARModalEditInvoiceDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onHealthCarePlan = this.onHealthCarePlan.bind(this)
		this.onDiscount = this.onDiscount.bind(this)
		this.onDiscountPercent = this.onDiscountPercent.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.AR.selectedInvoice.invoice) {
			let healthcarePlanIdx = this.props.AR.healthcarePlans.findIndex(
				(healthcarePlan) => healthcarePlan.code === this.props.AR.selectedInvoice.invoice.billing.billingPlan.code
			)
			if (healthcarePlanIdx > -1) {
				let healthcarePlan = this.props.AR.healthcarePlans[healthcarePlanIdx]
				if (
					healthcarePlan.displayName === this.props.AR.selectedInvoice.invoice.billing.billingPlan.name &&
					healthcarePlan.description === this.props.AR.selectedInvoice.invoice.billing.billingPlan.description
				) {
					this.props.setSelected(SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN, healthcarePlanIdx, healthcarePlan)
				} else {
					let healthcareCompanyIdx = healthcarePlan.companies.findIndex(
						(company) =>
							company.displayName === this.props.AR.selectedInvoice.invoice.billing.billingPlan.name &&
							company.description === this.props.AR.selectedInvoice.invoice.billing.billingPlan.description
					)
					if (healthcareCompanyIdx > -1) {
						this.props.setSelected(
							SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN,
							healthcarePlanIdx + "-" + healthcareCompanyIdx,
							healthcarePlan.companies[healthcareCompanyIdx]
						)
					}
				}
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT, 0)
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT, 0)
		this.props.setSelected(SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN, -1, null)
	}

	onHealthCarePlan(value) {
		if (value === undefined) {
			this.props.setSelected(SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN, -1, null)
			return
		}
		if (typeof value === "string") {
			let entry = value.split("-")
			this.props.setSelected(SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN, value, this.props.AR.healthcarePlans[entry[0]].companies[entry[1]])
		} else {
			this.props.setSelected(SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN, value, this.props.AR.healthcarePlans[value])
		}
	}

	onDiscount(event) {
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT, event.target.value)
	}

	onRemark(event) {
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK, event.target.value)
	}

	onDiscountPercent(event) {
		this.props.setValue(SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT, event.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							allowClear
							treeLine={{ showLeafIcon: false }}
							placeholder={translate(ResourceAssistance.Hospitel.healthCarePlan)}
							status={this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan ? undefined : "error"}
							value={
								this.props.AR.modalEditInvoice.selectedHealthCarePlan.healthcarePlan ? this.props.AR.modalEditInvoice.selectedHealthCarePlan.index : undefined
							}
							onChange={this.onHealthCarePlan}
							treeData={Utils.getTreeNodesFromHealthCarePlan(this.props.AR.healthcarePlans, true, false)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							placeholder={translate(ResourceAssistance.Message.currencySign)}
							step={0.01}
							min={0}
							max={this.props.AR.modalEditInvoice.discountMax}
							disabled={Utils.BigNumber(this.props.AR.modalEditInvoice.discountPercent).isGreaterThan(0)}
							value={this.props.AR.modalEditInvoice.discount}
							onChange={this.onDiscount}
						/>
					</Col>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.number}
							placeholder={"%"}
							step={1}
							min={0}
							max={100}
							disabled={Utils.BigNumber(this.props.AR.modalEditInvoice.discount).isGreaterThan(0)}
							value={this.props.AR.modalEditInvoice.discountPercent}
							onChange={this.onDiscountPercent}
						/>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<GInput
							autoSize
							required={
								Utils.BigNumber(this.props.AR.modalEditInvoice.discount).isGreaterThan(0) ||
								Utils.BigNumber(this.props.AR.modalEditInvoice.discountPercent).isGreaterThan(0)
							}
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.AR.modalEditInvoice.remark}
							onChange={this.onRemark}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ARModalEditInvoiceDetails))
