import React from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmRoleModalNRActive, setAdmRoleModalNRDescription, setAdmRoleModalNRName } from "~/redux/action"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class AdmRoleModalNRInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onActive = this.onActive.bind(this)
	}

	componentDidMount() {
		if (this.props.role.selectedRole.index !== -1) {
			this.props.setAdmRoleModalNRName(this.props.role.selectedRole.role.name)
			this.props.setAdmRoleModalNRDescription(this.props.role.selectedRole.role.description)
			this.props.setAdmRoleModalNRActive(this.props.role.selectedRole.role.active)
		}
	}

	componentWillUnmount() {
		this.props.setAdmRoleModalNRName("")
		this.props.setAdmRoleModalNRDescription("")
		this.props.setAdmRoleModalNRActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.role.modalNewRole.name !== this.props.role.modalNewRole.name ||
			prevProps.role.modalNewRole.description !== this.props.role.modalNewRole.description
		) {
			this.validateInfo()
		}
	}

	validateInfo() {
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.role.modalNR.name)

		if (
			this.props.role.roleTable.original
				.filter((each) => !this.props.role.selectedRole.role || this.props.role.selectedRole.role.id !== each.id)
				.some((each) => each.name.toLowerCase().trim() === this.props.role.modalNewRole.name.toLowerCase().trim())
		) {
			nameHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning }))
		} else {
			nameHtml.setCustomValidity("")
		}
	}

	onName(event) {
		this.props.setAdmRoleModalNRName(event.target.value)
	}

	onDescription(event) {
		this.props.setAdmRoleModalNRDescription(event.target.value)
	}

	onActive(event) {
		this.props.setAdmRoleModalNRActive(event.target.checked)
	}

	render() {
		return (
			<Container fluid="small" className="full-flex">
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.name)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.role.modalNR.name}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.role.modalNewRole.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.description)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.role.modalNR.description}
							as={ResourceAssistance.FormControl.as.textArea}
							value={this.props.role.modalNewRole.description}
							rows="10"
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.role.modalNewRole.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	role: state.admin.userConfig.role,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setAdmRoleModalNRName, setAdmRoleModalNRActive, setAdmRoleModalNRDescription }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmRoleModalNRInfo))
