import { Descriptions } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

class HospitalModalPatientClinicalRecordHistory extends React.Component {
	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.healthHistory)}</legend>
				<Row>
					<Col>
						<Descriptions size={"small"} contentStyle={{ fontStyle: "italic" }} column={3}>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.height)} span={1}>
								{this.props.patient.height} cm
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.weight)} span={2}>
								{this.props.patient.weight} kg
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.underlyingDisease)} span={3}>
								{this.props.patient.underlyingDisease}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.allergies)} span={3}>
								{this.props.patient.allergies}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.pastIllnesses)} span={3}>
								{this.props.patient.pastIllnesses}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.pastSurgicalHistory)} span={3}>
								{this.props.patient.pastSurgicalHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.familyHealthHistory)} span={3}>
								{this.props.patient.familyHealthHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.accidentHistory)} span={3}>
								{this.props.patient.accidentHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.supplementOrHerbsHistory)} span={3}>
								{this.props.patient.supplementOrHerbsHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.alcohol)} span={1}>
								{this.props.patient.alcoholHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tobacco)} span={1}>
								{this.props.patient.tobaccoHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.passiveSmokeExposure)} span={1}>
								{this.props.patient.passiveSmokeExposure}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.medicineHistory)} span={3}>
								{this.props.patient.medicineHistory}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.doctorNote)} span={3}>
								{this.props.patient.doctorNote}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

HospitalModalPatientClinicalRecordHistory.propTypes = {
	patient: propTypes.object,
}

HospitalModalPatientClinicalRecordHistory.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitalModalPatientClinicalRecordHistory
