import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setObjArray } from "~/redux/action"
import { SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES, SET_PAGE_LOADING } from "~/redux/type"
import CashierModalFinancialPlanAction from "./CashierModalFinancialPlanAction"
import CashierModalFinancialPlanService from "./CashierModalFinancialPlanService"

class CashierModalFinancialPlanDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadHealthCares = this.loadHealthCares.bind(this)
	}

	loadHealthCares() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.cashier.getHealthCares,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_CASHIER_MODAL_FINANCIAL_PLAN_HEALTH_CARES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<CashierModalFinancialPlanAction loadHealthCares={this.loadHealthCares} />
				<CashierModalFinancialPlanService />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setObjArray,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalFinancialPlanDetails)
