import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { IconFont } from "~/icons"
import { onModalDisplayAction, setPharmacyDispensedOrders, setSelected, setValue } from "~/redux/action"
import {
	SET_PHARMACY_MODAL_RETURN_DOCTOR_ORDER_IND,
	SET_PHARMACY_RETURN_DISPLAY,
	SET_PHARMACY_SELECTED_DISPENSED_ORDER,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyRightDispensedOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRightDispensedOrder",
		}

		this.onSelectDispensed = this.onSelectDispensed.bind(this)
		this.onReturn = this.onReturn.bind(this)
	}

	componentDidMount() {
		if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
			this.props.setPharmacyDispensedOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyOrders)
		} else {
			this.props.setPharmacyDispensedOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.selectedDoctorOrder, this.props.pharmacy.selectedDoctorOrder)) {
			if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
				this.props.setPharmacyDispensedOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyOrders)
			} else {
				this.props.setPharmacyDispensedOrders([])
			}
		}
		if (!_.isEqual(prevProps.pharmacy.dispensedOrderTable.filtered, this.props.pharmacy.dispensedOrderTable.filtered)) {
			let index = this.props.pharmacy.selectedDispensedOrder.dispensedOrder
				? this.props.pharmacy.dispensedOrderTable.filtered.findIndex(
						(dispensedOrder) => dispensedOrder.id === this.props.pharmacy.selectedDispensedOrder.dispensedOrder.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(
					SET_PHARMACY_SELECTED_DISPENSED_ORDER,
					index,
					this.props.pharmacy.dispensedOrderTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_PHARMACY_SELECTED_DISPENSED_ORDER, -1, null)
			}
		}
	}

	onSelectDispensed(order, index) {
		this.props.setSelected(
			SET_PHARMACY_SELECTED_DISPENSED_ORDER,
			index,
			this.props.pharmacy.dispensedOrderTable.filtered[index]
		)
	}

	onReturn() {
		this.props.setValue(SET_PHARMACY_MODAL_RETURN_DOCTOR_ORDER_IND, this.props.isDoctorOrder)
		this.props.onModalDisplayAction(SET_PHARMACY_RETURN_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[
							<IconFont type={"icon-ali-pmgcare-tuihuo"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
						]}
						onCheckedArray={[this.onReturn]}
						data={this.props.pharmacy.dispensedOrderTable}
						onClick={this.onSelectDispensed}
						isClearHighlight={this.props.pharmacy.selectedDispensedOrder.index === -1}
						highlightedRow={this.props.pharmacy.selectedDispensedOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setPharmacyDispensedOrders,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyRightDispensedOrder))
