import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS,
	SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN,
	SET_CONTRACT_BILLING_FILTER_BILLING_ID,
	SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG,
	SET_CONTRACT_BILLING_LOCATION,
	SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING,
	SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING,
	SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES,
	SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS,
	SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN,
	SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY,
	SET_CONTRACT_BILLING_MODAL_NEW_INVOICES,
	SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID,
	SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME,
	SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE,
	SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_PENDING_INVOICE,
	SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS,
	SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF,
	SET_CONTRACT_BILLING_MODAL_NEW_PENDING_INVOICES,
	SET_CONTRACT_BILLING_BILLINGS,
	SET_CONTRACT_BILLING_START_DATE_TIME,
	SET_CONTRACT_BILLING_END_DATE_TIME,
	SET_CONTRACT_BILLING_SELECTED_BILLING,
	SET_CONTRACT_BILLING_INVOICES,
	SET_CONTRACT_BILLING_SELECTED_INVOICE,
	SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID,
	SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY,
	SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME,
	SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME,
	SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES,
	SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING,
	SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE,
	SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA,
	SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM,
	SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS,
	SET_CONTRACT_BILLING_MODAL_INFO_TEL,
	SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"

const init = {
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterContractBillingId: "",
	filterHealthCarePlan: "",
	location: null,
	selectedBilling: {
		index: -1,
		billing: null,
	},
	selectedInvoice: {
		index: -1,
		invoice: null,
	},
	billingTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.time, ResourceAssistance.Message.id, ResourceAssistance.Message.insuranceCompany],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	invoiceTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.invoiceNum,
			ResourceAssistance.Message.patientId,
			ResourceAssistance.Message.patientName,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.chiefComplaint,
			ResourceAssistance.Message.fundOrClaimNum,
			ResourceAssistance.Message.insuranceReference,
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.receiptId,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalSearch: {
		searchContractBillingId: "",
		searchRangeStartDateTime: undefined,
		searchRangeEndDateTime: undefined,
		isInsuranceCompanyLoading: false,
		insuranceCompanies: [],
		searchInsuranceCompany: {
			index: -1,
			insuranceCompany: null,
		},
	},
	modalNew: {
		filterInvoiceId: "",
		filterPatientName: "",
		isHealthCarePlanLoading: false,
		isInsuranceCompanyLoading: false,
		healthcarePlans: [],
		insuranceCompanies: [],
		searchHealthCarePlan: {
			index: -1,
			healthcarePlan: null,
		},
		searchInsuranceCompany: {
			index: -1,
			insuranceCompany: null,
		},
		selectedInvoice: {
			index: -1,
			invoice: null,
		},
		selectedPendingInvoice: {
			index: -1,
			invoice: null,
		},
		invoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.invoiceNum,
				ResourceAssistance.Message.patientName,
				ResourceAssistance.Hospitel.balance,
				ResourceAssistance.Hospitel.healthCarePlan,
				ResourceAssistance.Message.insuranceCompany,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			isLoading: false,
		},
		pendingInvoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.invoiceNum,
				ResourceAssistance.Message.patientName,
				ResourceAssistance.Hospitel.balance,
				ResourceAssistance.Hospitel.healthCarePlan,
				ResourceAssistance.Message.insuranceCompany,
				ResourceAssistance.Message.chiefComplaint,
				ResourceAssistance.Message.fundOrClaimNum,
				ResourceAssistance.Message.insuranceReference,
			],
			body: [],
			rowColor: [],
		},
	},
	modalInvoiceInfo: {
		diagnosis: "",
		claimNum: "",
		insuranceRef: "",
	},
	modalInfo: {
		dueDateTime: "",
		factoryArea: "",
		address: "",
		tel: "",
	},
}

const contractBillingReducer = (state = init, action) => {
	switch (action.type) {
		case SET_CONTRACT_BILLING_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_CONTRACT_BILLING_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_CONTRACT_BILLING_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_CONTRACT_BILLING_FILTER_BILLING_ID:
			return Object.assign({}, state, {
				filterContractBillingId: action.payload.value,
			})
		case SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				filterHealthCarePlan: action.payload.value,
			})
		case SET_CONTRACT_BILLING_BILLINGS: {
			return Object.assign({}, state, {
				billingTable: {
					...state.billingTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		}
		case SET_CONTRACT_BILLING_SELECTED_BILLING:
			return Object.assign({}, state, {
				selectedBilling: {
					index: action.payload.index,
					billing: action.payload.selected,
				},
			})
		case SET_CONTRACT_BILLING_INVOICES:
			return Object.assign({}, state, {
				invoiceTable: {
					...state.invoiceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_CONTRACT_BILLING_SELECTED_INVOICE:
			return Object.assign({}, state, {
				selectedInvoice: {
					index: action.payload.index,
					invoice: action.payload.selected,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_SEARCH_CONTRACT_BILLING_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchContractBillingId: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_START_DATE_TIME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchRangeStartDateTime: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_SEARCH_RANGE_END_DATE_TIME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchRangeEndDateTime: action.payload.value,
				},
			})
		case SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANIES:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					insuranceCompanies: action.payload.value,
				},
			})
		case SET_CONTRACT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING: {
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					isInsuranceCompanyLoading: action.payload.value,
				},
			})
		}
		case SET_CONTRACT_BILLING_MODAL_SEARCH_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchInsuranceCompany: {
						index: action.payload.index,
						insuranceCompany: action.payload.selected,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					healthcarePlans: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isHealthCarePlanLoading: action.payload.isLoading,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES: {
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					insuranceCompanies: action.payload.value,
				},
			})
		}
		case SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isInsuranceCompanyLoading: action.payload.isLoading,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchHealthCarePlan: {
						index: action.payload.index,
						healthcarePlan: action.payload.selected,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_SEARCH_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchInsuranceCompany: {
						index: action.payload.index,
						insuranceCompany: action.payload.selected,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_FILTER_INVOICE_ID:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterInvoiceId: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_FILTER_PATIENT_NAME:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterPatientName: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_PENDING_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPendingInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_INVOICES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					invoiceTable: {
						...state.modalNew.invoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_DIAGNOSIS:
			return Object.assign({}, state, {
				modalInvoiceInfo: {
					...state.modalInvoiceInfo,
					diagnosis: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_CLAIM_NUM:
			return Object.assign({}, state, {
				modalInvoiceInfo: {
					...state.modalInvoiceInfo,
					claimNum: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INVOICE_INFO_INSURANCE_REF:
			return Object.assign({}, state, {
				modalInvoiceInfo: {
					...state.modalInvoiceInfo,
					insuranceRef: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_NEW_PENDING_INVOICES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					pendingInvoiceTable: {
						...state.modalNew.pendingInvoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INFO_DUE_DATE:
			return Object.assign({}, state, {
				modalInfo: {
					...state.modalInfo,
					dueDateTime: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INFO_FACTORY_AREA:
			return Object.assign({}, state, {
				modalInfo: {
					...state.modalInfo,
					factoryArea: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INFO_ADDRESS:
			return Object.assign({}, state, {
				modalInfo: {
					...state.modalInfo,
					address: action.payload.value,
				},
			})
		case SET_CONTRACT_BILLING_MODAL_INFO_TEL:
			return Object.assign({}, state, {
				modalInfo: {
					...state.modalInfo,
					tel: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default contractBillingReducer
