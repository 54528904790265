import { Checkbox, Space } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION,
	SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBAnesthesiaPlan = {
	General_Anesthesia: "G",
	Total_Intravenous_Anesthesia: "TI",
	Regional_Anesthesia: "R",
	Monitor_Anesthesia_Care: "M",
	Sedate: "S",
}
class OperatingRoomModalEditPreAnesthesiaPlan extends React.Component {
	constructor(props) {
		super(props)

		this.onPlanDetail = this.onPlanDetail.bind(this)
		this.onRegional = this.onRegional.bind(this)
		this.onPreMedication = this.onPreMedication.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let anesthesiaPreOP = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
			if (anesthesiaPreOP) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL, anesthesiaPreOP.anesthesiaPlan.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL, anesthesiaPreOP.anesthesiaPlanRegional)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION, anesthesiaPreOP.anesthesiaPlanPreMedication)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.anesthesiaPlanDetail, this.props.operatingRoom.modalEditAnPre.anesthesiaPlanDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.anesthesiaPlanDetail, CBAnesthesiaPlan.Regional_Anesthesia)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION, "")
	}

	render() {
		return (
			<>
				<Row>
					<Col md={2} />
					<Col md="auto">
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.generalAnesthesia),
									value: CBAnesthesiaPlan.General_Anesthesia,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.totalIntravenousAnesthesia),
									value: CBAnesthesiaPlan.Total_Intravenous_Anesthesia,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.regionalAnesthesia)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.anesthesiaPlanDetail, CBAnesthesiaPlan.Regional_Anesthesia)}
												value={this.props.operatingRoom.modalEditAnPre.anesthesiaPlanRegional}
												onChange={this.onRegional}
											/>
										</Space>,
									],
									value: CBAnesthesiaPlan.Regional_Anesthesia,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.monitorAnesthesiaCare),
									value: CBAnesthesiaPlan.Monitor_Anesthesia_Care,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.sedate),
									value: CBAnesthesiaPlan.Sedate,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.anesthesiaPlanDetail}
							onChange={this.onPlanDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							autoSize
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.anesthesiaPremedication)}
							value={this.props.operatingRoom.modalEditAnPre.anesthesiaPlanPreMedication}
							onChange={this.onPreMedication}
						/>
					</Col>
				</Row>
			</>
		)
	}
	onPlanDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL, values)
	}
	onRegional(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL, e.target.value)
	}
	onPreMedication(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPreAnesthesiaPlan))
