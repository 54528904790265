import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import ORModalIntraAneServiceBtn from "./ORModalIntraAneServiceBtn"
import ORModalIntraAneServiceFilter from "./ORModalIntraAneServiceFilter"
import ORModalIntraAneServicePending from "./ORModalIntraAneServicePending"
import ORModalIntraAneServiceService from "./ORModalIntraAneServiceService"

class ORModalIntraAneServiceDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<ORModalIntraAneServiceFilter />
				<ORModalIntraAneServiceService />
				<ORModalIntraAneServiceBtn />
				<ORModalIntraAneServicePending />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ORModalIntraAneServiceDetails)
