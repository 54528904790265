import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue } from "~/redux/action"
import { SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import OperatingRoomModalIntraAneBabyDeliveryDetails from "./OperatingRoomModalIntraAneBabyDeliveryDetails"
import { axios } from "~/axios"
import _ from "lodash"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalIntraAneBabyDelivery extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.operatingRoom.selectedRequest.request || this.props.operatingRoom.modalIntraAneBabyDelivery.gender === "") {
			return true
		}
		let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
			? undefined
			: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
		let target = Object.assign({}, op, {
			timeOfBirth: Utils.trim(this.props.operatingRoom.modalIntraAneBabyDelivery.timeOfBirth),
			gender: this.props.operatingRoom.modalIntraAneBabyDelivery.gender,
		})
		return _.isEqual(op, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.operatingRoom.saveAnesthesiaIntraOperatives}?type=BABY_DELIVERY`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				anesthesiaIntraOperatives: [
					{
						id: _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
							? 0
							: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0].id,
						timeOfBirth: Utils.trim(this.props.operatingRoom.modalIntraAneBabyDelivery.timeOfBirth),
						gender: this.props.operatingRoom.modalIntraAneBabyDelivery.gender,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				size={ResourceAssistance.Modal.size.md}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.babyDelivery)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalIntraAneBabyDeliveryDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomIntraAnesthesiaBabyDeliveryDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalIntraAneBabyDelivery)
