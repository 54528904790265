import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalDoctorNoteO extends React.Component {
	constructor(props) {
		super(props)

		this.onObjective = this.onObjective.bind(this)
		this.onObjectiveStable = this.onObjectiveStable.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedSOAP.soap) {
			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE, this.props.hospitel.selectedSOAP.soap.objective)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE, "")
	}

	modifyObjective(objective, isAdd) {
		let value = this.props.hospitel.modalDoctorNote.objective
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : ", ", objective)
		} else {
			value = value.replace(objective, "")
		}
		value = Utils.replaceDuplicateSpaces(value)
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE, Utils.trim(value))
	}

	onObjective(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE, event.target.value)
	}

	onObjectiveStable(event) {
		this.modifyObjective(event.target.value, event.target.checked)
	}

	render() {
		return (
			<Row>
				<Col md={"auto"}>
					<Row>
						<Col>
							<FormGroup controlId={"stable"}>
								<FormCheck
									label={"V/S stable"}
									checked={_.includes(this.props.hospitel.modalDoctorNote.objective, "V/S stable")}
									onChange={this.onObjectiveStable}
									value={"V/S stable"}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col>
							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows={3}
								value={this.props.hospitel.modalDoctorNote.objective}
								onChange={this.onObjective}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNoteO))
