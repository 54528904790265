import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setContractBillingModalNewInvoices, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS,
	SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING,
	SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES,
	SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING,
	SET_PAGE_LOADING,
} from "~/redux/type"
import ContractBillingModalNewFilter from "./ContractBillingModalNewFilter"
import ContractBillingModalNewInvoice from "./ContractBillingModalNewInvoice"
import ContractBillingModalNewInvoiceAction from "./ContractBillingModalNewInvoiceAction"
import ContractBillingModalNewPendingInvoice from "./ContractBillingModalNewPendingInvoice"

class ContractBillingModalNewDetails extends React.Component {
	constructor(props) {
		super(props)

		this.getInvoices = this.getInvoices.bind(this)
	}

	componentDidMount() {
		this.getHealthCarePlans()
		this.getInsuranceCompanies()
	}

	getHealthCarePlans() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.contractBilling.getHealthCarePlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLANS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_CONTRACT_BILLING_MODAL_NEW_HEALTH_CARE_PLAN_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getInsuranceCompanies() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.contractBilling.getInsuranceCompanies,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANIES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_CONTRACT_BILLING_MODAL_NEW_INSURANCE_COMPANY_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getInvoices() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.contractBilling.getInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				orgId: this.props.contract.location.id,
				healthcarePlanCode: this.props.contract.modalNew.searchHealthCarePlan.healthcarePlan
					? typeof this.props.contract.modalNew.searchHealthCarePlan.index === "string"
						? this.props.contract.modalNew.healthcarePlans[
								this.props.contract.modalNew.searchHealthCarePlan.index.split("-")[0]
						  ].code
						: this.props.contract.modalNew.searchHealthCarePlan.healthcarePlan.code
					: "",
				healthcarePlanName:
					this.props.contract.modalNew.searchHealthCarePlan.healthcarePlan &&
					typeof this.props.contract.modalNew.searchHealthCarePlan.index === "string"
						? this.props.contract.modalNew.searchHealthCarePlan.healthcarePlan.displayName
						: "",
				insuranceCompany: this.props.contract.modalNew.searchInsuranceCompany.insuranceCompany
					? this.props.contract.modalNew.searchInsuranceCompany.insuranceCompany.displayName
					: "",
			},
		}
		let callback = (res) => {
			this.props.setContractBillingModalNewInvoices(res.data.invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<ContractBillingModalNewFilter getInvoices={this.getInvoices} />
				<ContractBillingModalNewInvoice />
				<ContractBillingModalNewInvoiceAction />
				<ContractBillingModalNewPendingInvoice />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setContractBillingModalNewInvoices,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalNewDetails)
