import {} from "~/redux/type"

const init = {}

const radiologyReducer = (state = init, action) => {
	switch (action.type) {
		default:
			return state
	}
}

export default radiologyReducer
