import { Card } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import PreOperativeCheckList from "~/view/component/pre-operative-checklist/PreOperativeCheckList"

class OPDModalNursePreOperativeCheckList extends React.Component {
	constructor(props) {
		super(props)
		this.preOperativeCheckListRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.confirmNursePreOperativeCheckList)) {
					this.confirm()
				}
			}
		}
	}

	isDoneBtnDisabled() {
		return true
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	confirm() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveNursePreOperativeCheckLists,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				operatingRoomRequestId: this.props.opd.selectedORRequest.request.id,
				userId: this.props.login.user.id,
				preOperativeCheckLists: [
					{
						identifyPatient: `${this.preOperativeCheckListRef.current.state.isIdentifyPatient},${this.preOperativeCheckListRef.current.state.identifyPatientRemark}`,
						markingSurgerySite: `${this.preOperativeCheckListRef.current.state.isMarkingSiteForSurgery},${this.preOperativeCheckListRef.current.state.markingSiteForSurgeryRemark}`,
						consentSurgery: `${this.preOperativeCheckListRef.current.state.isLetterConsentSurgery},${this.preOperativeCheckListRef.current.state.letterConsentSurgeryRemark}`,
						consentAnesthesia: `${this.preOperativeCheckListRef.current.state.isLetterConsentAnesthesia},${this.preOperativeCheckListRef.current.state.letterConsentAnesthesiaRemark}`,
						labResult: `${this.preOperativeCheckListRef.current.state.isLabResult},${this.preOperativeCheckListRef.current.state.labResultRemark}`,
						imagingResult: `${this.preOperativeCheckListRef.current.state.isImagingResult},${this.preOperativeCheckListRef.current.state.imagingResultRemark}`,
						ekgResult: `${this.preOperativeCheckListRef.current.state.isEKGResult},${this.preOperativeCheckListRef.current.state.EKGResultRemark}`,
						cleanPatientBody: `${this.preOperativeCheckListRef.current.state.isCleanPatientBody},${this.preOperativeCheckListRef.current.state.cleanPatientBodyRemark}`,
						unisonEnema: `${this.preOperativeCheckListRef.current.state.isUnisonEnema},${this.preOperativeCheckListRef.current.state.unisonEnemaRemark}`,
						npoTime: `${this.preOperativeCheckListRef.current.state.isNPOTime},${this.preOperativeCheckListRef.current.state.NPOTimeRemark}`,
						removeDentures: `${this.preOperativeCheckListRef.current.state.isRemoveDentures},${this.preOperativeCheckListRef.current.state.removeDenturesRemark}`,
						removeEyeglasses: `${this.preOperativeCheckListRef.current.state.isEyeglassesRemoved},${this.preOperativeCheckListRef.current.state.eyeglassesRemovedRemark}`,
						removeJewelry: `${this.preOperativeCheckListRef.current.state.isJewelryRemoved},${this.preOperativeCheckListRef.current.state.jewelryRemovedRemark}`,
						removeBodyPiercing: `${this.preOperativeCheckListRef.current.state.isBodyPiercingRemoved},${this.preOperativeCheckListRef.current.state.bodyPiercingRemovedRemark}`,
						changePatientClothes: `${this.preOperativeCheckListRef.current.state.isChangePaitentClothes},${this.preOperativeCheckListRef.current.state.changePatientClothesRemark}`,
						urinateBeforeOR: `${this.preOperativeCheckListRef.current.state.isPatientUrinate},${this.preOperativeCheckListRef.current.state.patientUrinateRemark}`,
						bloodRequested: `${this.preOperativeCheckListRef.current.state.isBloodRequested},${this.preOperativeCheckListRef.current.state.bloodRequestedRemark}`,
						preEducation: `${this.preOperativeCheckListRef.current.state.isPreEducation},${this.preOperativeCheckListRef.current.state.preEducationRemark}`,
						otherMedicalDevice: `${this.preOperativeCheckListRef.current.state.isOtherMedicalDevice},${this.preOperativeCheckListRef.current.state.otherMedicalDeviceRemark}`,
						toolSpecialEquipment: `${this.preOperativeCheckListRef.current.state.isToolSpecialEquipment},${this.preOperativeCheckListRef.current.state.toolSpecialEquipmentRemark}`,
						implant: `${this.preOperativeCheckListRef.current.state.isImplant},${this.preOperativeCheckListRef.current.state.implantRemark}`,
						confirmDateTime: moment().milliseconds(0).valueOf(),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
			this.props.onModalDisplayAction(SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY, false)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_OPD_NURSE_PRE_OPERATIVE_CHECK_LIST_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.confirmNursePreOperativeCheckList)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.confirm)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			/>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
				scrollable
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.preOperativeCheckList)}</ModalTitle>
					</ModalHeader>

					<ModalBody ref={this.props.modalBodyRef}>
						<PreOperativeCheckList
							ref={this.preOperativeCheckListRef}
							onRenderParentCallback={this.onRenderParentCallback}
							checkList={
								this.props.opd.selectedORRequest.request
									? !_.isEmpty(this.props.opd.selectedORRequest.request.preOperativeCheckLists)
										? this.props.opd.selectedORRequest.request.preOperativeCheckLists[0]
										: undefined
									: undefined
							}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button type={ResourceAssistance.Button.type.submit} variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientNursePreOperativeCheckListDisplay,
	login: state.login,
	warning: state.modal.warning,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OPDModalNursePreOperativeCheckList)
