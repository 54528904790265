import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setCashierAdmissions, setLoadingAction, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_CASHIER_PATIENT, SET_CASHIER_PAYMENT_DISPLAY, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import ServerUtils from "~/utils/ServerUtils"
import { Utils } from "~/utils/Utils"
import CashierModalPaymentDetails from "./CashierModalPaymentDetails"

class CashierModalPayment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierModalPayment",
		}
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.cashier.modalPayment.saveBilling)) {
					this.saveBilling()
				}
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_CASHIER_PAYMENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			((!this.props.cashier.modalPayment.selectedPayment.payment ||
				((_.isEmpty(this.props.cashier.modalPayment.total) || Utils.BigNumber(this.props.cashier.modalPayment.total).lt(0)) &&
					!this.props.cashier.modalPayment.selectedPayment.payment.savingAccount) ||
				(this.props.cashier.modalPayment.selectedPayment.payment.savingAccount &&
					Utils.BigNumber(Utils.calculatePatientDeposit(this.props.cashier.patient.transactions)).lte(0))) &&
				!this.props.cashier.modalPayment.isSkipPayment) ||
			(this.props.cashier.modalPayment.selectedPayment.payment &&
				this.props.cashier.modalPayment.selectedPayment.payment.bankAccount &&
				!this.props.cashier.modalPayment.selectedBank.bank)
		)
	}

	saveBilling() {
		let balance = this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
			.filter((bs) => !bs.billing)
			.reduce((total, cur) => {
				return total.plus(cur.charge).minus(cur.adjustment)
			}, Utils.BigNumber(0))
			.minus(
				ServerUtils.calculateBillingPlanAdjustments(
					this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingPlanAdjustments.filter((bpAdj) => !bpAdj.billing)
				)
			)
			.toFixed(2)
		let deposit = Utils.calculatePatientDeposit(this.props.cashier.patient.transactions)
		let total = 0
		let unpaid = 0
		let change = 0
		if (this.props.cashier.modalPayment.selectedPayment.payment && this.props.cashier.modalPayment.selectedPayment.payment.savingAccount) {
			if (Utils.BigNumber(deposit).minus(balance).isGreaterThanOrEqualTo(0)) {
				total = balance
			} else {
				total = deposit
				unpaid = Utils.BigNumber(balance).minus(deposit).toFixed(2)
			}
		} else {
			total = this.props.cashier.modalPayment.total
			unpaid = Utils.BigNumber(this.props.cashier.modalPayment.total).minus(balance).isLessThan(0)
				? Utils.BigNumber(balance).minus(this.props.cashier.modalPayment.total).toFixed(2)
				: 0
			change = Utils.BigNumber(this.props.cashier.modalPayment.total).minus(balance).isGreaterThan(0)
				? Utils.BigNumber(this.props.cashier.modalPayment.total).minus(balance).toFixed(2)
				: Utils.BigNumber(0).toFixed(2)
		}
		let allocatedBillingStatements = Utils.allocateBillDiscount(
			this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements.filter((each) => !each.billing),
			ServerUtils.calculateBillingPlanAdjustments(
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingPlanAdjustments.filter((bpAdj) => !bpAdj.billing)
			)
		)

		let GL = Utils.getGeneralLedgerCode(
			this.props.cashier.location.accountsReceivable,
			[],
			this.props.cashier.modalPayment.isSkipPayment
				? []
				: this.props.cashier.modalPayment.selectedPayment.payment.bankAccount
				? this.props.cashier.modalPayment.selectedBank.bank.activities
				: this.props.cashier.modalPayment.selectedPayment.payment.activities
		)
		let data = {
			pid: this.props.cashier.patient.id,
			admissionId: this.props.cashier.selectedAdmission.admission.id,
			userId: this.props.login.user.id,
			billingPlans: [
				{
					...this.props.cashier.selectedHealthCarePlan.healthCarePlan,
					billingStatements: this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
						.filter((bs) => !bs.billing && (!bs.doctorOrder || !bs.doctorOrder.prescriptionOnly))
						.map((billingStatement) => {
							return {
								id: billingStatement.id,
								billAdjustment: allocatedBillingStatements[billingStatement.id],
							}
						}),
					billingPlanAdjustments: this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingPlanAdjustments.filter((each) => !each.billing),
				},
			],
			billings: [
				{
					branchId: this.props.cashier.location.id,
					billingPlanId: this.props.cashier.selectedHealthCarePlan.healthCarePlan.id,
					total: total,
					unpaid: unpaid,
					change: change,
					payment: this.props.cashier.modalPayment.isSkipPayment ? "" : this.props.cashier.modalPayment.selectedPayment.payment.displayName,
					paymentDescription: this.props.cashier.modalPayment.isSkipPayment ? "" : this.props.cashier.modalPayment.selectedPayment.payment.description,
					bank:
						this.props.cashier.modalPayment.isSkipPayment || !this.props.cashier.modalPayment.selectedPayment.payment.bankAccount
							? ""
							: this.props.cashier.banks[this.props.cashier.modalPayment.selectedBank.index.split("-")[0]].displayName,
					bankBranch:
						this.props.cashier.modalPayment.isSkipPayment || !this.props.cashier.modalPayment.selectedPayment.payment.bankAccount
							? ""
							: this.props.cashier.modalPayment.selectedBank.bank.displayName,
					accountingCode: GL.fullCode,
					accountingChartName: GL.displayName,
					accountingChartDescription: GL.description,
					accountingTransDescr: GL.transDescr,
				},
			],
			billingStatements: this.props.cashier.selectedAdmission.admission.billingStatements
				.filter((bs) => bs.doctorOrder && bs.doctorOrder.prescriptionOnly)
				.map((bs) => {
					return Object.assign(bs, {
						//paid/debt/deletable
						refund: bs.billing !== undefined,
						billingId: bs.billing ? bs.billing.id : -1,
					})
				}),
		}
		if (this.props.cashier.modalPayment.selectedPayment.payment && this.props.cashier.modalPayment.selectedPayment.payment.savingAccount) {
			data = Object.assign(data, {
				deposit: {
					amount: Utils.BigNumber(total).negated().toFixed(2),
					payment: this.props.intl.formatMessage({ id: ResourceAssistance.Message.hospitalBilling }),
				},
			})
		}
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.cashier.saveBilling,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: data,
		}
		let callback = (res) => {
			if (res.data.admissions.length > 0) {
				let admissions = this.props.cashier.admissionTable.original.filter((each) => each.id !== res.data.admissions[0].id)
				admissions.push(res.data.admissions[0])
				this.props.setCashierAdmissions(admissions)
			} else if (res.data.patients.length > 0) {
				this.props.setValue(SET_CASHIER_PATIENT, res.data.patients[0])
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let balance = Utils.calculateBillingStatementBalance(this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements).minus(
			ServerUtils.calculateBillingPlanAdjustments(
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingPlanAdjustments.filter((bpAdj) => !bpAdj.billing)
			)
		)
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.cashier.modalPayment.saveBilling)
		let message = ""
		if (this.props.cashier.modalPayment.isSkipPayment) {
			message = translate(ResourceAssistance.Warning.createBillingInvoice)
		} else if (
			(this.props.cashier.modalPayment.selectedPayment.payment.savingAccount &&
				Utils.BigNumber(Utils.calculatePatientDeposit(this.props.cashier.patient.transactions)).minus(balance).lt(0)) ||
			(Utils.BigNumber(this.props.cashier.modalPayment.total).minus(balance).isLessThan(0) &&
				!this.props.cashier.modalPayment.selectedPayment.payment.savingAccount)
		) {
			message = translate(ResourceAssistance.Warning.createBillinReceiptAndInvoice)
		} else {
			message = translate(ResourceAssistance.Warning.createBillingReceipt)
		}
		this.props.setWarningMsgAction(message)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				// dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				size={ResourceAssistance.Modal.size.lg}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.pay)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<CashierModalPaymentDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isCashierPaymentDisplay,
	login: state.login,
	warning: state.modal.warning,
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setCashierAdmissions,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierModalPayment))
