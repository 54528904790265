import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PV_CHART_OF_ACCOUNTS, SET_PV_CHART_OF_ACCOUNTS_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PaymentVoucherRightInfo from "./PaymentVoucherRightInfo"
import PaymentVoucherRightPurchase from "./PaymentVoucherRightPurchase"

class PaymentVoucherRight extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.PV.chartOfAccounts)) {
			this.loadChartOfAccounts()
		}
	}

	loadChartOfAccounts() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.chartOfAccounts.getChartOfAccounts,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PV_CHART_OF_ACCOUNTS, Utils.getChartOfAccountsByGroup(Utils.groupBy(res.data.accounts, "coreCodeName")))
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PV_CHART_OF_ACCOUNTS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PV_CHART_OF_ACCOUNTS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PV_CHART_OF_ACCOUNTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<PaymentVoucherRightInfo />
				<PaymentVoucherRightPurchase />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentVoucherRight)
