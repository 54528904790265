import * as React from "react";

function SvgSvgLab(props) {
  return (
    <svg
      id="SvgLab_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <defs>
        <style>
          {
            ".SvgLab_svg__cls-1{fill:none;stroke:#295183;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}.SvgLab_svg__cls-2{fill:#295183}.SvgLab_svg__cls-3{fill:#a8d2f0}.SvgLab_svg__cls-4{fill:#389ad6}"
          }
        </style>
      </defs>
      <path
        className="SvgLab_svg__cls-1"
        d="M22.92 41.2l-3.08-5.33M22.92 30.53h6.16l3.08 5.34M30.37 38.98l1.8-3.11h6.16l1.92 3.33M22.92 50.48l-3.08-3.94 3.08-5.34M13.68 35.87h6.16l3.08 5.33-3.08 5.34h-6.16M37.33 25.19h-5.16l-3.08-5.33 3.08-5.34h6.16l2.7 4.68"
      />
      <path
        className="SvgLab_svg__cls-1"
        d="M38.33 35.87h-6.16l-3.08-5.34M29.09 30.53h-6.17M19.84 35.87h-6.16l-3.08-5.34"
      />
      <circle className="SvgLab_svg__cls-2" cx={30.66} cy={27.76} r={1} />
      <circle className="SvgLab_svg__cls-2" cx={31.66} cy={49.95} r={1} />
      <circle className="SvgLab_svg__cls-2" cx={21.22} cy={33.26} r={1} />
      <path
        className="SvgLab_svg__cls-3"
        d="M23.94 22.22a.35.35 0 00-.09.11l-.46.71c-.25.39-.51.78-.73 1.2-.66 1.24.14 1.76.79 2.76.3.46.6 1.36 1.22 1.46a6.57 6.57 0 001.24-.09 12 12 0 002.2-.09c.95-.22 1.24-1.5 1.37-2.32s-.33-1.28-.71-1.91a5.91 5.91 0 00-.92-1.5c-.59-.53-1.3-.34-2-.34a5.37 5.37 0 00-1.91.01zM14.64 38.13a.35.35 0 00-.09.11l-.46.71c-.25.39-.51.78-.73 1.2-.66 1.25.14 1.77.79 2.76.3.46.6 1.36 1.22 1.46a6.57 6.57 0 001.24-.09 12 12 0 002.2-.09c.95-.22 1.24-1.5 1.37-2.32s-.33-1.28-.71-1.91a5.91 5.91 0 00-.92-1.5c-.59-.53-1.3-.34-2-.34a5.37 5.37 0 00-1.91.01zM33.23 38.13a.35.35 0 00-.09.11l-.46.71c-.25.39-.51.78-.73 1.2-.66 1.25.14 1.77.79 2.76.3.46.6 1.36 1.22 1.46a6.57 6.57 0 001.24-.09 12 12 0 002.2-.09c.95-.22 1.24-1.5 1.37-2.32s-.33-1.28-.71-1.91a5.91 5.91 0 00-.92-1.5c-.59-.53-1.3-.34-2-.34a5.37 5.37 0 00-1.91.01z"
      />
      <path
        className="SvgLab_svg__cls-3"
        d="M35.15 43.31s2.32 2.67-1 3.33.68.75.68.75 3.11 2.13.37 4-13.85.92-13-1.46a10.4 10.4 0 012.8-4.1s-2.27-3.56 10.15-2.52z"
      />
      <path
        className="SvgLab_svg__cls-4"
        d="M48.11 44.1c-.2.37-1.32.28-1.68.32A3.94 3.94 0 0043.49 46a2.5 2.5 0 00.67 3c.56.54 1.42.6 1.93 1.18.3.34 1 1.08 1 .18.11-1.36-.09-4.22-.09-4.22z"
      />
      <path
        className="SvgLab_svg__cls-3"
        d="M44.19 21.81a1.59 1.59 0 012.37.23 4.35 4.35 0 001.77 1.73c1.35.51 2.36-.69 3.34-1.4a.82.82 0 01.69-.23c.36.12.37.63.25 1a6.5 6.5 0 01-1.13 1.95 16.89 16.89 0 01-2.37 2.44C47.68 28.74 45.95 30 44 30a12.61 12.61 0 00-2.42-.06.87.87 0 00-.51.25.7.7 0 00-.09.68 2.07 2.07 0 00.38.6 5.87 5.87 0 01-2.25-2 1.57 1.57 0 01-.23-.83 1.69 1.69 0 01.37-.89 2.22 2.22 0 011.8-1 11.68 11.68 0 013.14-4.94z"
      />
      <path
        className="SvgLab_svg__cls-4"
        d="M42.37 24.47l-.26-.53s-1.24 2.2-.86 2.64S42.6 29.5 45 29.39a4.71 4.71 0 001-.17.85.85 0 000-1.64 6.24 6.24 0 01-3.63-3.11zM31.89 47.55s.75 1 0 1.59c-.58.43-1.78.3-2.44.3s-1.68 0-2.51-.07a3.06 3.06 0 00-1.7.25 1 1 0 00-.39 1.52c1 1.22-3.89-1.16-1.67-3.59s8.71 0 8.71 0z"
      />
      <path
        className="SvgLab_svg__cls-1"
        d="M44.11 21.64L46.74 19a3.83 3.83 0 013.17-1.1 3.76 3.76 0 012.28 1.1 3.82 3.82 0 011.1 2.29v.44a3.82 3.82 0 01-1.12 2.73l-4.39 4.4a3.85 3.85 0 01-6.56-2.3"
      />
      <path
        className="SvgLab_svg__cls-1"
        d="M44.63 30l-1.93 1.9a1.06 1.06 0 01-1.49 0L39.32 30a1.06 1.06 0 010-1.49l1.93-1.93A3.82 3.82 0 0044.63 30zM53.31 16l1.89 1.89a1.06 1.06 0 010 1.49l-1.9 1.91M49.91 26.77l2.82 2.8c4.81 4.62 1.14 10.15-2 13.26l-4.34 4.34M42.56 46.88H26.74a1.9 1.9 0 01-1.9-1.88v-.2a1.91 1.91 0 011.9-1.9h14.82"
      />
      <path
        className="SvgLab_svg__cls-1"
        d="M46.42 47.15a2.32 2.32 0 011.23 2v.22a2.32 2.32 0 01-2.3 2.31H25a2.32 2.32 0 01-2.3-2.31v-.22a2.32 2.32 0 012.3-2.27h2.48"
      />
      <circle className="SvgLab_svg__cls-2" cx={42.37} cy={23.47} r={1} />
      <circle className="SvgLab_svg__cls-2" cx={44.75} cy={42.9} r={1} />
      <path
        className="SvgLab_svg__cls-3"
        d="M16 15.42c1.52.26 1.16 1.33.92 2.47a8.66 8.66 0 00.15 4 6.17 6.17 0 00.49 1.43c.23.4.84.76.9 1.24a.72.72 0 01-.36.65 2.51 2.51 0 01-1.33.32 4.25 4.25 0 01-1.66-.36s-3.69-2.05-3.86-4c-.14-1.56 1.18-3.41 2.18-4.48a4 4 0 012.09-1.36 1.61 1.61 0 01.48.09z"
      />
      <path
        className="SvgLab_svg__cls-1"
        d="M22.89 22.3a5.49 5.49 0 01-.71 1.47 5.57 5.57 0 01-.47.6 5.25 5.25 0 01-.91.82 5.6 5.6 0 01-2.34 1 5.33 5.33 0 01-1 .1 5.73 5.73 0 01-1-.07 5.59 5.59 0 01-2.37-.94 5.42 5.42 0 01-1.42-1.37 5.5 5.5 0 01-1-2.34 5 5 0 01-.11-1 5.1 5.1 0 01.07-1 5.76 5.76 0 01.93-2.35 5.65 5.65 0 011-1.15 2.4 2.4 0 01.34-.27 5.39 5.39 0 011.25-.69 1.28 1.28 0 01.25-.1 4.14 4.14 0 01.84-.22 5.56 5.56 0 012 0 5.52 5.52 0 012.1.77l.25.16a5.79 5.79 0 011.43 1.38l.07.09a5.63 5.63 0 01.94 2.25 5.33 5.33 0 01.1 1 4.91 4.91 0 01-.07 1A3.79 3.79 0 0123 22a1.56 1.56 0 01-.11.3z"
      />
      <circle
        className="SvgLab_svg__cls-1"
        cx={17.38}
        cy={20.51}
        r={2.33}
        transform="rotate(-.87 17.626 20.55)"
      />
      <rect
        className="SvgLab_svg__cls-2"
        x={15.99}
        y={11.63}
        width={2.56}
        height={3.3}
        rx={0.58}
        ry={0.58}
        transform="rotate(-.87 17.681 13.306)"
      />
      <path
        className="SvgLab_svg__cls-2"
        d="M13.88 16.09l-.08.1-.8.81a.7.7 0 01-.37.2.67.67 0 01-.6-.18l-1.39-1.34a.69.69 0 010-1l.22-.22.61-.63a.68.68 0 011 0l1.38 1.34a.68.68 0 01.19.61.63.63 0 01-.16.31z"
      />
      <rect
        className="SvgLab_svg__cls-2"
        x={8.87}
        y={18.97}
        width={2.56}
        height={3.3}
        rx={0.58}
        ry={0.58}
        transform="rotate(-90.87 10.15 20.617)"
      />
      <rect
        className="SvgLab_svg__cls-2"
        x={11.07}
        y={24.05}
        width={2.56}
        height={3.3}
        rx={0.58}
        ry={0.58}
        transform="rotate(-135.87 12.349 25.697)"
      />
      <path
        className="SvgLab_svg__cls-2"
        d="M18.76 26.76v1.93a.68.68 0 01-.67.69H16.9a.68.68 0 01-.69-.67v-1.93a.68.68 0 01.29-.56.62.62 0 01.39-.13h1.19a.62.62 0 01.39.12.68.68 0 01.29.55zM24.17 26.26l-.4.41-.43.45a.68.68 0 01-1 0L21 25.79a.68.68 0 01-.2-.6.62.62 0 01.18-.37l.73-.74.11-.11a.66.66 0 01.37-.2.7.7 0 01.6.18l1.39 1.35a.68.68 0 01-.01.96z"
      />
      <rect
        className="SvgLab_svg__cls-2"
        x={23.34}
        y={18.75}
        width={2.56}
        height={3.3}
        rx={0.58}
        ry={0.58}
        transform="rotate(89.13 24.62 20.397)"
      />
      <rect
        className="SvgLab_svg__cls-2"
        x={21.14}
        y={13.67}
        width={2.56}
        height={3.3}
        rx={0.58}
        ry={0.58}
        transform="rotate(44.13 22.421 15.312)"
      />
    </svg>
  );
}

export default SvgSvgLab;
