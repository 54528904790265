import * as React from "react"

function SvgSvgInpatient(props) {
	return (
		<svg
			id="SvgInpatient_svg__Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x={0}
			y={0}
			viewBox="0 0 500 424.01"
			xmlSpace="preserve"
			{...props}
		>
			<style>{`.SvgInpatient_svg__st0{fill:${
				props["fill-bed"] ? props["fill-bed"] : "#41b0e7"
			}}.SvgInpatient_svg__st1{fill:${props["fill-patient"] ? props["fill-patient"] : "#65c8c6"}`}</style>
			<path
				className="SvgInpatient_svg__st0"
				d="M130.27 123.47c.66 15.15-11.3 33.2-33.01 33.46-20.25.24-33.31-15.21-33.8-32.32-.55-19.09 14.35-34.73 32.87-34.86 20-.14 34.62 16.41 33.94 33.72z"
			/>
			<path
				className="SvgInpatient_svg__st1"
				d="M443.64 268.89c0-8.8-.01-17.08 0-25.37.01-9.69 6.39-16.76 15.18-16.83 8.78-.07 15.36 6.95 15.36 16.62.03 50.45.04 100.91 0 151.36-.01 9.66-6.62 16.63-15.44 16.54-8.46-.09-14.98-7.22-15.08-16.54-.06-6.19-.06-12.38-.11-18.58 0-.28-.19-.57-.41-1.2H59.55c0 6.39.13 12.56-.03 18.72-.21 8.33-4.24 14.26-10.96 16.59-10.97 3.81-21.87-4.18-21.92-16.31-.1-25.53-.04-51.05-.04-76.58 0-44.26-.01-88.52.02-132.78.01-8.24 4.2-14.47 11.17-16.92 9.38-3.3 19.6 2.63 21.27 12.43.38 2.22.49 4.51.49 6.76.04 25.68.11 51.36-.04 77.04-.02 3.96.79 5.41 5.16 5.41 124.77-.13 249.55-.1 374.32-.11 1.33.01 2.66-.13 4.65-.25zm-.22 33.93H59.82v38.12h383.6v-38.12z"
			/>
			<path
				className="SvgInpatient_svg__st1"
				d="M78.16 172.07c1.92.84 3.86 1.08 4.8 2.18 21.03 24.53 41.95 49.15 62.89 73.75.07.08.01.28.01.69h-67.7v-76.62z"
			/>
			<path
				className="SvgInpatient_svg__st0"
				d="M384.1 11.53c-33.74.28-61.91 26.85-62.01 61.67-.1 34.96 27.13 62.19 61.65 62.23 35.6.04 62.16-27.53 62.16-62.04.01-34.84-27.89-61.4-61.8-61.86zm-2.35 109.6c-24.13-1-45.89-21.68-45.34-48.66.52-25.38 21.44-46.59 47.47-46.62 27.72-.03 48.21 22.89 47.59 47.62.88 24.81-20.74 48.86-49.72 47.66z"
			/>
			<path
				className="SvgInpatient_svg__st0"
				d="M410.68 62.28h-15.49V46.79c0-3.5-2.84-6.34-6.34-6.34h-9.7c-3.5 0-6.34 2.84-6.34 6.34v15.49h-15.49c-3.5 0-6.34 2.84-6.34 6.34v9.7c0 3.5 2.84 6.34 6.34 6.34h15.49v15.49c0 3.5 2.84 6.34 6.34 6.34h9.7c3.5 0 6.34-2.84 6.34-6.34V84.67h15.49c3.5 0 6.34-2.84 6.34-6.34v-9.7c0-3.51-2.84-6.35-6.34-6.35zM183.89 173.87c-2.58-3.04-5.15-6.09-7.78-9.08-14.27-16.19-36.57-18.72-53.31-6.04-3.73 2.82-7.05 6.17-10.78 8.99-2.55 1.94-2.63 3.41-.55 5.83 19.48 22.68 38.88 45.41 58.24 68.19 3.14 3.69 6.65 5.53 11.7 5.51h2.49v-73.4zM427.63 262.45h-232.6v-89.41c0-10.07 8.16-18.23 18.23-18.23H379c26.85 0 48.62 21.77 48.62 48.62l.01 59.02z"
			/>
		</svg>
	)
}

export default SvgSvgInpatient
