const PreOperativeCheckList = {
	identifyThePatientNameSurnameAndDateOfBirth: "ระบุตัวผู้ป่วยชื่อ-สกุล/วัน เดือนปี เกิด",
	markingSiteForSurgeryProcedure: "การทำเครื่องหมายบริเวณตำแหน่งที่จะผ่าตัด/หัตถการ(Mark site) ในห้องผ่าตัด",
	letterOfConsentToUndergoSurgeryDiagnosis: "หนังสือแสดงความยินยอมรับการผ่าตัด/วินิจฉัย",
	letterOfConsentToReceiveAnesthesia: "หนังสือแสดงความยินยอมรับการใช้ยาระงับความรู้สึก",
	labResult: "ผล Lab",
	imagingResult: "ผลการตรวจทางรังสี",
	EKGResult: "ผล EKG",
	cleanPatientBodyForSurgery: "เตรียมความสะอาดบริเวณที่จะทำการผ่าตัด",
	unisonEnema: "สวนอุจจาระ",
	NPOTime: "งดอาหารและน้ำ",
	removeDentures: "ถอดฟันปลอม",
	eyeGlassesContactLensEyelashesRemoved: "ถอดแว่นตา Contact Lens ขนตาปลอม",
	jewelryAndHairpiecesRemoved: "ถอดของมีค่า และโลหะติดผม",
	bodyPiercingRemoved: "ล้างเล็บ",
	changePatientClothes: "เปลี่ยนเสื้อผ้าผู้ป่วย",
	haveThePatientUrinateBeforeBeingReferralOR: "ให้ผู้ป่วยถ่ายปัสสาวะก่อนส่ง OR",
	bloodRequested: "Blood requested",
	preEducation: "Pre-education",
	otherMedicalDevice: "อุปกรณ์ติดตัวคนไข้อื่นๆ",
	toolSpecialEquipment: "เครื่องมือ/อุปกรณ์พิเศษ",
	implant: "Implant",
}

export default PreOperativeCheckList
