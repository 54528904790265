const Warning = {
	appointmentDelete: "ลบการนัดหมายแผนกผู้ป่วยนอก",
	approvedByAnotherOrderWarning: "รายการนี้มีการสั่งซื้อไปแล้ว ยังไม่ได้รับสินค้า",
	canNotChangeAfterSaved: "ไม่สามารถเปลี่ยนแปลงได้หลังจากบันทึกแล้ว",
	emptyDisapproveReasonWarning: "ไม่มีเหตุผลในการยกเลิก! เหตุผลในการยกเลิกไม่สามารถเปลี่ยนแปลงหลังจากการอนุมัติ",
	emptyNoteWarning: "กล่องข้อความว่างเปล่า! ข้อความไม่สามารถเปลี่ยนแปลงได้หลังการอนุมัติ",
	emptyWarning: "ว่างเปล่าไม่ได้",
	fileUploadSuccess: "อัปโหลดไฟล์สำเร็จแล้ว",
	forAnyComplications: "กรณีมีภาวะแทรกซ้อนหรือเปลี่ยนแปลงการวินิจฉัยโรคจะต้องมีการประเมินค่าใช้จ่ายใหม่ โดยประเมินค่าใช้จ่ายตามความเป็นจริง",
	inCaseRefundPatient: "ในกรณีที่ผู้ป่วยไม่สามารถทำการผ่าตัดได้โรงพยาบาลจะคืนเงินมัดจำโดยการโอนเงินผ่านบัญชีธนาคาร ภายใน 7 วันทำการ",
	opdCheckOut: "เวลาสิ้นสุดการปรึกษาแพทย์",
	pharmacyCannotMeetDoctorOrder: "คำสั่งซื้อหมดสต็อก",
	smartCardReaderIsNotWorking: "ID Smart Card Reader is not working! \n Note* (Make sure the SMC Reader app is running.)",
	taxedTotalPriceDoesNotMatch: "ราคารวมไม่ตรงกับราคาที่คำนวณจากในระบบ",
	theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling: "ใบสั่งแพทย์เป็นใบสั่งยาเท่านั้นและจะไม่รวมอยู่ในการเรียกเก็บเงิน",
	warningAdjustmentBillingPlanCannotModify: "โปรดเปลี่ยนการปรับแผนการเรียกเก็บเงินก่อนแก้ไขแผนการเรียกเก็บเงิน",
	warningApproveTransfer: "อนุมัติการโอนย้ายรายการที่เลือก",
	warningApproveTransferRequest: "อนุมัติคำขอโอนสต็อคสินค้า",
	warningAuthorizationFailed: "การให้สิทธิ์ล้มเหลว",
	warningAuthorizationSameLoginUser: "ผู้ใช้ที่เข้าสู่ระบบในปัจจุบันไม่ได้รับอนุญาตให้ดำเนินการ โปรดเข้าสู่ระบบในฐานะผู้ใช้รายอื่นเพื่ออนุญาตให้ดำเนินการ",
	warningCreateBillingInvoice: "สิ่งนี้จะสร้างใบแจ้งหนี้",
	warningCreateBillingReceipt: "นี้จะสร้างใบเสร็จรับเงิน",
	warningCreateBillingReceiptAndInvoice: "นี้จะสร้างใบเสร็จรับเงินและใบแจ้งหนี้",
	warningDischargePatient: "การปล่อยผู้ป่วย",
	warningFileMustSmallerThan: "ไฟล์ต้องมีขนาดเล็กกว่า ",
	warningFileUploadFailed: "การอัปโหลดไฟล์ล้มเหลว",
	warningMedicationCanBeUsedMultipleTimes: "สามารถใช้ยาได้หลายครั้ง",
	warningOrderTimeInteractWithAnotherOrder: "เวลาของใบสั่งยาของแพทย์กำลังโต้ตอบกับใบสั่งยาของแพทย์คนอื่น",
	warningPermissionDenied: "ถูกปฏิเสธการอนุญาต",
	warningSavingWithholdingTaxCertificate: "บันทึกใบรับรองการหักภาษี ณ ที่จ่าย...",
	warningSavingWithholdingTaxCertificateFailed: "การบันทึกใบรับรองการหักภาษี ณ ที่จ่ายล้มเหลว",
	warningSavingWithholdingTaxCertificateSuccess: "ประหยัดใบรับรองการหักภาษี ณ ที่จ่ายได้สำเร็จ",
	warningTheDoctorOrderIsNotPrescriptionOnlyIncludeInBilling: "ใบสั่งแพทย์ไม่ใช่ใบสั่งยาเท่านั้น และจะรวมอยู่ในการเรียกเก็บเงิน",
	warningUploadPDFOnly: "กรุณาอัปโหลดไฟล์ PDF",
	warningWrongFrequency: "ความถี่ของใบสั่งยาไม่ถูกต้อง",
	warningWrongUnit: "หน่วยวัดผิด",
	wrongAmount: "จำนวนที่สั่งต้องเป็นทวีคูณของหน่วยใหญ่",
	wrongTotalPrice: "ราคารวมคำนวณไม่ถูกต้อง",
	wrongTransferAmount: "จำนวนที่โอนต้องน้อยกว่าหรือเท่ากับจำนวนปัจจุบัน",
	wrongTransferAmountGreaterThanRequested: "ไม่สามารถโอนมากกว่าจำนวนที่ขอ",
    wrongWithdrawAmount: "จำนวนเงินที่ถอนผิด",
}

export default Warning
