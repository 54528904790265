import { Checkbox, Radio, Space, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT,
	SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT,
	SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION,
	SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP,
	SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK,
	SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE,
	SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import ConfigGLModalPayablePaymentSlip from "./ConfigGLModalPayablePaymentSlip"
import ConfigGLModalPayableRC from "./ConfigGLModalPayableRC"

class ConfigGLModalPayableDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onBalance = this.onBalance.bind(this)
		this.onDiscount = this.onDiscount.bind(this)
		this.onTax = this.onTax.bind(this)
		this.onWithholdingTax = this.onWithholdingTax.bind(this)
		this.onTransactionFee = this.onTransactionFee.bind(this)
		this.onBalanceAdjustment = this.onBalanceAdjustment.bind(this)
		this.onCOA = this.onCOA.bind(this)
		this.onPayment = this.onPayment.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onDate = this.onDate.bind(this)
	}

	componentDidMount() {
		if (this.props.configGL.accountsPayable.selectedAP.AP) {
			let selectedAP = this.props.configGL.accountsPayable.selectedAP.AP
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK, selectedAP.includeBalance)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK, selectedAP.includeDiscount)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK, selectedAP.includeTax)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK, selectedAP.includeWithholdingTax)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE, selectedAP.includeTransactionFee)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT, selectedAP.includeBalanceAdjustment)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT, selectedAP.debit)
			this.props.setValue(
				SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION,
				Utils.trim(selectedAP.description.replace(ResourceAssistance.PROGRAM_DEFINED.supplier, "").replace(ResourceAssistance.PROGRAM_DEFINED.date, ""))
			)
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK, selectedAP.description.includes(ResourceAssistance.PROGRAM_DEFINED.supplier))
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK, selectedAP.description.includes(ResourceAssistance.PROGRAM_DEFINED.date))

			let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.configGL.chartOfAccounts, selectedAP.chartOfAccounts)
			if (!_.isEmpty(selectedAry)) {
				this.props.setSelected(SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP, selectedAry[0], selectedAry[1])
			} else {
				this.props.setSelected(SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP, -1, null)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION, "")
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK, false)
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK, false)
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.configGL.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_ADM_GL_PAYABLE_MODAL_AP_SELECTED_AP, value, this.props.configGL.chartOfAccounts[value])
		}
	}

	onBalance(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_CHECK, e.target.checked)
	}

	onDiscount(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DISCOUNT_CHECK, e.target.checked)
	}

	onTax(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_TAX_CHECK, e.target.checked)
	}

	onWithholdingTax(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_WHT_CHECK, e.target.checked)
	}

	onTransactionFee(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_TRANSACTION_FEE, e.target.checked)
	}

	onBalanceAdjustment(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_BALANCE_ADJUSTMENT, e.target.checked)
	}

	onPayment(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DEBIT, e.target.value)
	}

	onDescription(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCRIPTION, e.target.value)
	}

	onSupplier(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_SUPPLIER_CHECK, e.target.checked)
	}

	onDate(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_DESCR_DATE_CHECK, e.target.checked)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeExpandAction="click"
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							value={this.props.configGL.accountsPayable.modalAP.selectedAP.AP ? this.props.configGL.accountsPayable.modalAP.selectedAP.index : undefined}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(
								this.props.configGL.chartOfAccounts,
								true,
								[],
								undefined,
								["displayName", "fullCode"],
								false
							)}
						/>
					</Col>
				</Row>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.calculation)}</legend>
					<Row className="g-2">
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsPayable.modalAP.isIncludeBalance} onChange={this.onBalance}>
								{translate(ResourceAssistance.Hospitel.balance)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsPayable.modalAP.isIncludeDiscount} onChange={this.onDiscount}>
								{translate(ResourceAssistance.Message.discount)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsPayable.modalAP.isIncludeTax} onChange={this.onTax}>
								{translate(ResourceAssistance.Message.taxRate)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsPayable.modalAP.isIncludeWithholdingTax}
								onChange={this.onWithholdingTax}
							>
								{translate(ResourceAssistance.Message.withholdingTax)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsPayable.modalAP.isIncludeTransactionFee}
								onChange={this.onTransactionFee}
							>
								{translate(ResourceAssistance.Message.transactionFee)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsPayable.modalAP.isIncludeBalanceAdjustment}
								onChange={this.onBalanceAdjustment}
							>
								{translate(ResourceAssistance.Message.balanceAdjustment)}
							</Checkbox>
						</Col>
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.payment)}</legend>
					<Row>
						<Col />
						<Col>
							<Radio.Group value={this.props.configGL.accountsPayable.modalAP.isDebit} onChange={this.onPayment}>
								<Space direction="horizontal" size={"large"}>
									<Radio value={false}>{translate(ResourceAssistance.Message.credit)}</Radio>
									<Radio value={true}>{translate(ResourceAssistance.Message.debit)}</Radio>
								</Space>
							</Radio.Group>
						</Col>
						<Col />
					</Row>
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.transactionDescription)}</legend>
					<Row>
						<Col>
							<GInput
								required
								placeholder={translate(ResourceAssistance.Message.description)}
								value={this.props.configGL.accountsPayable.modalAP.description}
								onChange={this.onDescription}
							/>
						</Col>
					</Row>
					<Row>
						<Col />
						<Col md="auto">
							<Checkbox
								style={{ flexDirection: "row" }}
								checked={this.props.configGL.accountsPayable.modalAP.isDescriptionIncludesSupplier}
								onChange={this.onSupplier}
							>
								{translate(ResourceAssistance.Message.supplier)}
							</Checkbox>
						</Col>
						<Col md="auto">
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.configGL.accountsPayable.modalAP.isDescriptionIncludesDate} onChange={this.onDate}>
								{translate(ResourceAssistance.Message.date)}
							</Checkbox>
						</Col>
						<Col />
					</Row>
				</fieldset>
				<ConfigGLModalPayableRC />
				<ConfigGLModalPayablePaymentSlip />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	configGL: state.admin.configGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGLModalPayableDetails))
