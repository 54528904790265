import { Button as AButton, Card, Descriptions, Tooltip } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { FaCheck } from "react-icons/fa6"
import { LiaFileMedicalAltSolid } from "react-icons/lia"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_POST_ANESTHESIA_COMPLICATION_DISPLAY,
	SET_OR_POST_ANESTHESIA_DISCHARGE_DISPLAY,
	SET_OR_POST_ANESTHESIA_NURSE_DISPLAY,
	SET_OR_POST_ANESTHESIA_PADSS_DISPLAY,
	SET_OR_POST_ANESTHESIA_RECORD_DISPLAY,
	SET_OR_POST_ANESTHESIA_RECORD_TIME_DISPLAY,
	SET_OR_POST_ANESTHESIA_TRANSFER_DISPLAY,
	SET_OR_SELECTED_POST_ANE_RECORD,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { CheckBoxGroupUtils } from "~/utils/component/CheckBoxGroupUtils"
import { Utils } from "~/utils/Utils"
import { CBComplication } from "../../../_modal/anesthesia-post/complication/OperatingRoomModalPostAneComplicationDetails"
import { CBDischargeCriteria } from "../../../_modal/anesthesia-post/discharge-criteria/OperatingRoomModalPostAneDischargeDetails"
import { RTransferTo } from "../../../_modal/anesthesia-post/transfer/OperatingRoomModalPostAneTransferDetails"

class OperatingRoomAnesthesiaTabPost extends React.Component {
	constructor(props) {
		super(props)

		this.onPACURecord = this.onPACURecord.bind(this)
		this.onApprove = this.onApprove.bind(this)
		this.onNurse = this.onNurse.bind(this)
		this.onComplication = this.onComplication.bind(this)
		this.onPADSS = this.onPADSS.bind(this)
		this.onDischarge = this.onDischarge.bind(this)
		this.onTransfer = this.onTransfer.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPostOperatives)) {
			this.props.setSelected(SET_OR_SELECTED_POST_ANE_RECORD, 0, this.props.operatingRoom.selectedRequest.request.anesthesiaPostOperatives[0])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
		// 	if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.approveIntraAnesthesiaRecord)) {
		// 		this.approve()
		// 	}
		// }
		if (!_.isEqual(prevProps.operatingRoom.selectedRequest.request, this.props.operatingRoom.selectedRequest.request)) {
			if (this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPostOperatives)) {
				this.props.setSelected(SET_OR_SELECTED_POST_ANE_RECORD, 0, this.props.operatingRoom.selectedRequest.request.anesthesiaPostOperatives[0])
			} else {
				this.props.setSelected(SET_OR_SELECTED_POST_ANE_RECORD, -1, null)
			}
		}
	}

	isApproveDisabled() {
		// return !this.props.operatingRoom.selectedIntraAneRecord.record || this.props.operatingRoom.selectedIntraAneRecord.record.approver
	}

	approve() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.approveAnesthesiaRecord,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				postAnesthesiaRecordId: this.props.operatingRoom.selectedPostAneRecord.record.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		let op = this.props.operatingRoom.selectedPostAneRecord.record
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className="full-size">
					<Col>
						<Descriptions
							style={{
								flex: "1",
								flexDirection: "column",
								overflowY: "auto",
								overflowX: "hidden",
							}}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={1}
							extra={
								<Container fluid className={"full-size full-flex"}>
									<Row className="g-2" style={{ marginBottom: "0" }}>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.PACURecord)}>
												<AButton
													type="primary"
													size="middle"
													shape="round"
													icon={<LiaFileMedicalAltSolid size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onPACURecord}
												/>
											</Tooltip>
										</Col>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.approve)}>
												<AButton
													style={{
														background: this.isApproveDisabled() ? undefined : ResourceAssistance.CSS.Color.dark_green,
													}}
													type="primary"
													size="middle"
													shape="round"
													icon={<FaCheck size={ResourceAssistance.ReactIcon.size} />}
													disabled={this.isApproveDisabled()}
													onClick={this.onApprove}
												/>
											</Tooltip>
										</Col>
									</Row>
								</Container>
							}
						>
							<Descriptions.Item labelStyle={{ fontWeight: "bold", marginRight: "5px" }} label={translate(ResourceAssistance.Message.nurseNote)} span={1}>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										{this.props.operatingRoom.selectedRequest.request && (
											<Col md="auto">
												<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onNurse}>
													<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
												</Button>
											</Col>
										)}
										<Col>{op ? op.nurseNote : ""}</Col>
									</Row>
								</Container>
							</Descriptions.Item>
							<Descriptions.Item
								labelStyle={{ fontWeight: "bold", marginRight: "5px" }}
								label={translate(ResourceAssistance.Message.complicationInPACU)}
								span={1}
							>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										{this.props.operatingRoom.selectedRequest.request && (
											<Col md="auto">
												<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onComplication}>
													<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
												</Button>
											</Col>
										)}
										<Col>{op ? `${CheckBoxGroupUtils.getKeyStr(CBComplication, op.complication, [CBComplication.Other])}, ${op.complicationOther}` : ""}</Col>
									</Row>
								</Container>
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							bordered
							style={{
								flex: "1",
								flexDirection: "column",
							}}
							size={"small"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={4}
						>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ fontStyle: "normal" }} span={1}>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										<Col md="auto">{translate(ResourceAssistance.Message.modifiedPADSSBeforeDischargeToHome)}</Col>
										{this.props.operatingRoom.selectedRequest.request && (
											<Col md="auto">
												<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onPADSS}>
													<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
												</Button>
											</Col>
										)}
									</Row>
								</Container>
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1} />
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1} />
							<Descriptions.Item
								className={"exclude-colon"}
								labelStyle={{ display: "none" }}
								contentStyle={{ textAlign: "center", fontStyle: "normal" }}
								span={1}
							>
								{translate(ResourceAssistance.Message.discharge)}
							</Descriptions.Item>

							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message["BP&Pulse"])}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message["20%OfPreoperation"])}
								{"\n"}
								{translate(ResourceAssistance.Message["20%To40%OfPreoperation"])}
								{"\n"}
								{translate(ResourceAssistance.Message["greaterThan40%OfPreoperation"])}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								2{"\n"}1{"\n"}0
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{op ? op.padss.split(",")[0] : ""}
							</Descriptions.Item>

							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.ambulation)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.steadyGaitNoDizziness)}
								{"\n"}
								{translate(ResourceAssistance.Message.ambulateWithAssistance)}
								{"\n"}
								{translate(ResourceAssistance.Message.unableToAmbulate)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								2{"\n"}1{"\n"}0
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{op ? op.padss.split(",")[1] : ""}
							</Descriptions.Item>

							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.nauseaOrVomitting)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.minimalTreatedWithOralMedication)}
								{"\n"}
								{translate(ResourceAssistance.Message.moderateTreatedWithIVMedication)}
								{"\n"}
								{translate(ResourceAssistance.Message.continuteAfterRepeatedTreatment)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								2{"\n"}1{"\n"}0
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{op ? op.padss.split(",")[2] : ""}
							</Descriptions.Item>

							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.pain)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.controlledWithOralAnalgesicsAndAcceptableToPatient)}
								{"\n"}
								{translate(ResourceAssistance.Message.painNotAcceptableToPatient)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								2{"\n"}1
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{op ? op.padss.split(",")[3] : ""}
							</Descriptions.Item>

							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.surgicalBleeding)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}>
								{translate(ResourceAssistance.Message.minimalBleeding)}
								{"\n"}
								{translate(ResourceAssistance.Message.moderateBleeding)}
								{"\n"}
								{translate(ResourceAssistance.Message.severeBleeding)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								2{"\n"}1{"\n"}0
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{op ? op.padss.split(",")[4] : ""}
							</Descriptions.Item>

							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}></Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} span={1}></Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{translate(ResourceAssistance.Message.total)}
							</Descriptions.Item>
							<Descriptions.Item className={"exclude-colon"} labelStyle={{ display: "none" }} contentStyle={{ textAlign: "center" }} span={1}>
								{op
									? op.padss
											.split(",")
											.reduce((total, cur) => {
												return total.plus(cur)
											}, Utils.BigNumber(0))
											.toNumber()
									: 0}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions style={{ flex: "1", flexDirection: "column" }} contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }} column={3}>
							<Descriptions.Item
								labelStyle={{ fontWeight: "bold" }}
								contentStyle={{ minWidth: "400px", maxWidth: "400px" }}
								label={translate(ResourceAssistance.Message.dischargeCriteria)}
								span={1}
							>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										{this.props.operatingRoom.selectedRequest.request && (
											<Col md="auto">
												<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onDischarge}>
													<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
												</Button>
											</Col>
										)}
										<Col>{op ? `${CheckBoxGroupUtils.getKeyStr(CBDischargeCriteria, op.dischargeCriteria)}` : ""}</Col>
									</Row>
								</Container>
							</Descriptions.Item>
							<Descriptions.Item
								labelStyle={{ fontWeight: "bold" }}
								contentStyle={{ minWidth: "320px", maxWidth: "320px" }}
								label={translate(ResourceAssistance.Message.transferTo)}
								span={1}
							>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										{this.props.operatingRoom.selectedRequest.request && (
											<Col md="auto">
												<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onTransfer}>
													<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
												</Button>
											</Col>
										)}
										<Col>{op ? (op.transferTo === RTransferTo.Home ? translate(ResourceAssistance.Message.home) : op.transferToSBU) : ""}</Col>
									</Row>
								</Container>
							</Descriptions.Item>
							<Descriptions.Item
								labelStyle={{ fontWeight: "bold" }}
								contentStyle={{ minWidth: "320px", maxWidth: "320px" }}
								label={translate(ResourceAssistance.Message.approvedBy)}
								span={1}
							>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										<Col>{op && op.approver ? op.approver.displayName : ""}</Col>
									</Row>
								</Container>
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Container>
		)
	}
	onPACURecord() {
		if (
			this.props.operatingRoom.selectedPostAneRecord.record &&
			this.props.operatingRoom.selectedPostAneRecord.record.startDateTime &&
			this.props.operatingRoom.selectedPostAneRecord.record.endDateTime
		) {
			this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_RECORD_DISPLAY, true)
		} else {
			this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_RECORD_TIME_DISPLAY, true)
		}
	}
	onApprove() {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.approveIntraAnesthesiaRecord)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.approve)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions
					style={{ flex: "unset", display: "unset" }}
					size={"small"}
					contentStyle={{
						fontStyle: "italic",
						fontWeight: "normal",
						textAlign: "left",
					}}
					column={1}
				>
					<Descriptions.Item label={translate(ResourceAssistance.Message.approvedBy)} span={1}>
						{this.props.login.user.displayName}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
	onNurse() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_NURSE_DISPLAY, true)
	}
	onComplication() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_COMPLICATION_DISPLAY, true)
	}
	onPADSS() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_PADSS_DISPLAY, true)
	}
	onDischarge() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_DISCHARGE_DISPLAY, true)
	}
	onTransfer() {
		this.props.onModalDisplayAction(SET_OR_POST_ANESTHESIA_TRANSFER_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomAnesthesiaTabPost))
