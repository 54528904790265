import _ from "lodash"
import React from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setHospitelNurseOrders, setHospitelOrders, setSelected } from "~/redux/action"
import { SET_HOSPITEL_SELECTED_NURSE_ORDER, SET_HOSPITEL_SELECTED_ORDER } from "~/redux/type"
import HospitelRightActionRow from "./HospitelRightActionRow"
import HospitelRightDoctorNoteRow from "./HospitelRightDoctorNoteRow"
import HospitelRightNurseOrderRow from "./HospitelRightNurseOrderRow"
import HospitelRightOrderRow from "./HospitelRightOrderRow"
import IPDRightDataTab from "./IPDRightDataTab"
import IPDRightMedicalCertificate from "./IPDRightMedicalCertificate"
import IPDRightNoteTab from "./IPDRightNoteTab"

class HospitelRight extends React.Component {
	EOrder = {
		DOCTOR: "HOSPITEL_DOCTOR_ORDER",
		NURSE: "HOSPITEL_NURSE_ORDER",
		MEDICAL_CERTIFICATE: "IPD_MEDICAL_CERTIFICATE",
	}
	EVitalSignTab = {
		TABLE: "IPD_VITAL_SIGN_TABLE",
		GRAPH: "IPD_VITAL_SIGN_GRAPH",
	}
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelRight",
			tab: this.EOrder.DOCTOR,
			vsTab: this.EVitalSignTab.TABLE,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.onVitalSignTabClick = this.onVitalSignTabClick.bind(this)
	}

	componentDidUpdate(prevProp, prevState) {
		if (!_.isEqual(prevState.tab, this.state.tab)) {
			if (
				this.props.hospitel.selectedHospitel.hospitel &&
				this.props.hospitel.selectedHospitel.hospitel.admission &&
				this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord
			) {
				this.props.setSelected(SET_HOSPITEL_SELECTED_ORDER, -1, null)
				this.props.setSelected(SET_HOSPITEL_SELECTED_NURSE_ORDER, -1, null)
				switch (this.state.tab) {
					case this.EOrder.DOCTOR:
						this.props.setHospitelOrders(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders)
						break

					case this.EOrder.NURSE:
						this.props.setHospitelNurseOrders(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.nurseOrders)
						break

					default:
						break
				}
			}
		}
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	onVitalSignTabClick(event) {
		this.setState({ vsTab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={`${ResourceAssistance.CSS.fullFlex} border`}>
				<HospitelRightActionRow />
				<HospitelRightDoctorNoteRow />
				<Tabs
					variant="tabs"
					// transition={false}
					activeKey={this.state.tab}
					onSelect={this.onTabClick}
					mountOnEnter={false}
				>
					<Tab eventKey={this.EOrder.DOCTOR} title={translate(ResourceAssistance.Hospitel.doctor)} name={this.EOrder.DOCTOR}>
						{this.state.tab === this.EOrder.DOCTOR && <HospitelRightOrderRow {...this.state} />}
					</Tab>
					<Tab eventKey={this.EOrder.NURSE} title={translate(ResourceAssistance.Hospitel.nurse)} name={this.EOrder.NURSE}>
						{this.state.tab === this.EOrder.NURSE && <HospitelRightNurseOrderRow {...this.state} />}
					</Tab>
					<Tab
						eventKey={this.EOrder.MEDICAL_CERTIFICATE}
						title={translate(ResourceAssistance.Hospitel.medicalCertificate)}
						name={this.EOrder.MEDICAL_CERTIFICATE}
					>
						{this.state.tab === this.EOrder.MEDICAL_CERTIFICATE && <IPDRightMedicalCertificate {...this.state} />}
					</Tab>
				</Tabs>
				<IPDRightNoteTab />
				<IPDRightDataTab />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setHospitelOrders,
			setHospitelNurseOrders,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelRight)
