import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import GInput from "../input/GInput"

class IntakeOutputIntake extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			amount: "",
			note: "",
			selectedIntake: {
				index: -1,
				intake: null,
			},
		}

		this.onClear = this.onClear.bind(this)
		this.onIntake = this.onIntake.bind(this)
		this.onAmount = this.onAmount.bind(this)
		this.onNote = this.onNote.bind(this)
	}

	componentDidMount() {
		if (this.props.intake) {
			let intakeIdx = this.props.intakes.findIndex((each) => _.isEqual(Utils.trim(each.displayName), Utils.trim(this.props.intake.method)))
			this.setState({
				amount: this.props.intake.amount,
				note: this.props.intake.note,
				selectedIntake: {
					index: intakeIdx,
					intake: intakeIdx > -1 ? this.props.intakes[intakeIdx] : null,
				},
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
		if (!_.isEqual(prevProps.intakes, this.props.intakes)) {
			if (this.props.intake) {
				let intakeIdx = this.props.intakes.findIndex((each) => _.isEqual(Utils.trim(each.displayName), Utils.trim(this.props.intake.method)))
				this.setState({
					selectedIntake: {
						index: intakeIdx,
						intake: intakeIdx > -1 ? this.props.intakes[intakeIdx] : null,
					},
				})
			}
		}
	}

	onIntake(value) {
		this.setState({
			selectedIntake: {
				index: value > -1 ? Number(value) : -1,
				intake: value > -1 ? this.props.intakes[value] : null,
			},
		})
	}

	onClear() {
		this.setState({
			selectedIntake: {
				index: -1,
				intake: null,
			},
		})
	}

	onAmount(e) {
		this.setState({
			amount: e.target.value,
		})
	}

	onNote(e) {
		this.setState({
			note: e.target.value,
		})
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.intake)}</legend>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							size={ResourceAssistance.Select.size.large}
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.method)}
							value={this.state.selectedIntake.intake ? this.state.selectedIntake.index : undefined}
							onChange={this.onIntake}
							onClear={this.onClear}
						>
							{Utils.renderSelects(this.props.intakes, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required={this.state.selectedIntake.intake !== null}
							type={ResourceAssistance.FormControl.type.number}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount })}(${ResourceAssistance.CONSTANT.ML})`}
							onChange={this.onAmount}
							value={this.state.amount}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.note })}
							onChange={this.onNote}
							value={this.state.note}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

IntakeOutputIntake.propTypes = {}

IntakeOutputIntake.defaultProps = {}

export default injectIntl(IntakeOutputIntake, { forwardRef: true })
