import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import TypeModalNewBankField from "./TypeModalNewBankField"
import TypeModalNewGeneralFields from "./TypeModalNewGeneralFields"
import TypeModalNewHealthCarePlanFields from "./TypeModalNewHealthCarePlanFields"
import TypeModalNewPaymentFields from "./TypeModalNewPaymentFields"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { Property } from "./TypeModalNew"
import TypeModalNewWHT from "./TypeModalNewWHT"
import TypeModalNewInventory from "./TypeModalNewInventory"
import TypeModalNewAddressField from "./TypeModalNewAddressField"
import TypeModalNewServiceField from "./TypeModalNewServiceField"

class TypeModalNewDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<TypeModalNewGeneralFields {...this.props} />
				<TypeModalNewAddressField {...this.props} />
				<TypeModalNewWHT {...this.props} />
				<TypeModalNewPaymentFields {...this.props} />
				<TypeModalNewHealthCarePlanFields {...this.props} />
				<TypeModalNewBankField {...this.props} />
				<TypeModalNewInventory {...this.props} />
				<TypeModalNewServiceField {...this.props} />
				<Row>
					<Col />
					<Col>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox name={Property.ACTIVE} onClick={this.props.onPropertyChange} checked={this.props.active} />
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewDetails)
