import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setObjArray, setValue } from "~/redux/action"
import { SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES, SET_PAGE_LOADING } from "~/redux/type"
import AdmHealthCarePlanModalCompanyInfo from "./AdmHealthCarePlanModalCompanyInfo"

class AdmHealthCarePlanModalCompanyDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadInsuranceCompanies = this.loadInsuranceCompanies.bind(this)
	}

	loadInsuranceCompanies() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.type.insuranceCompany.loadTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_ADM_HEALTH_CARE_PLAN_INSURANCE_COMPANIES, res.data.uts)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<AdmHealthCarePlanModalCompanyInfo loadInsuranceCompanies={this.loadInsuranceCompanies} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setObjArray,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmHealthCarePlanModalCompanyDetails))
