

export const SET_DOCUMENT_DOCUMENTS = "SET_DOCUMENT_DOCUMENTS"
export const SET_DOCUMENT_DOCUMENTS_LOADING = "SET_DOCUMENT_DOCUMENTS_LOADING"
export const SET_DOCUMENT_FILTER_NAME = "SET_DOCUMENT_FILTER_NAME"
export const SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION = "SET_DOCUMENT_MODAL_UPLOAD_DESCRIPTION"
export const SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL = "SET_DOCUMENT_MODAL_UPLOAD_DISPLAY_PATIENT_LABEL"
export const SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST = "SET_DOCUMENT_MODAL_UPLOAD_FILE_LIST"
export const SET_DOCUMENT_PATIENT = "SET_DOCUMENT_PATIENT"
export const SET_DOCUMENT_SEARCH_PID_LOADING = "SET_DOCUMENT_SEARCH_PID_LOADING"
export const SET_DOCUMENT_SELECTED_DOCUMENT = "SET_DOCUMENT_SELECTED_DOCUMENT"
export const SET_DOCUMENT_UPLOAD_DISPLAY = "SET_DOCUMENT_UPLOAD_DISPLAY"
export const SET_MENU_DOCUMENT_DISPLAY = "SET_MENU_DOCUMENT_DISPLAY"