import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { IconFont } from "~/icons"
import { onModalDisplayAction, setFrontInventoryDistributions, setSelected, setValue } from "~/redux/action"
import { SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS, SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION, SET_FRONT_INVENTORY_WASTE_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FrontInventoryDistribution extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onWaste = this.onWaste.bind(this)
	}
	componentDidMount() {
		if (this.props.frontInventory.selectedInventory.inventory) {
			this.props.setFrontInventoryDistributions(this.props.frontInventory.selectedInventory.inventory.distributions)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.frontInventory.selectedInventory, this.props.frontInventory.selectedInventory) ||
			!_.isEqual(prevProps.frontInventory.lotExpiredDateTime, this.props.frontInventory.lotExpiredDateTime) ||
			!_.isEqual(prevProps.frontInventory.isDisplayExpired, this.props.frontInventory.isDisplayExpired)
		) {
			if (this.props.frontInventory.selectedInventory.inventory) {
				this.props.setFrontInventoryDistributions(this.props.frontInventory.selectedInventory.inventory.distributions)
			} else {
				this.props.setFrontInventoryDistributions([])
			}
		}
		if (!_.isEqual(prevProps.frontInventory.distributionTable.filtered, this.props.frontInventory.distributionTable.filtered)) {
			let index = this.props.frontInventory.selectedDistribution.distribution
				? this.props.frontInventory.distributionTable.filtered.findIndex(
						(inventory) => inventory.id === this.props.frontInventory.selectedDistribution.distribution.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION, index, this.props.frontInventory.distributionTable.filtered[index])
			} else {
				this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION, -1, null)
	}

	onSelectRow(row, idx) {
		this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION, idx, this.props.frontInventory.distributionTable.filtered[idx])
	}

	onWaste(e, row, rIdx, cIdx) {
		let selectedDistribution = this.props.frontInventory.distributionTable.filtered[rIdx]
		this.props.setValue(SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS, [selectedDistribution])
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_WASTE_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[<IconFont type={"icon-ali-pmgcare-huishouzhan"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />]}
						onCheckedArray={[this.onWaste]}
						data={this.props.frontInventory.distributionTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.frontInventory.selectedDistribution.index === -1}
						highlightedRow={this.props.frontInventory.selectedDistribution.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setFrontInventoryDistributions,
			onModalDisplayAction,
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontInventoryDistribution)
