import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_PV_MODAL_NEW_WHT_ISSUED_DATE_TIME } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import { setValue } from "~/redux/action"

class PVModalNewWithholdingTax extends React.Component {
	constructor(props) {
		super(props)

		this.onIssuedDateTime = this.onIssuedDateTime.bind(this)
	}

	componentDidMount() {
		if (this.props.PV.selectedPV.PV) {
			let selectedPV = this.props.PV.selectedPV.PV
			this.props.setValue(SET_PV_MODAL_NEW_WHT_ISSUED_DATE_TIME, selectedPV.withholdingTaxIssuedDateTime)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_PV_MODAL_NEW_WHT_ISSUED_DATE_TIME, "")
	}

	onIssuedDateTime(v) {
		this.props.setValue(SET_PV_MODAL_NEW_WHT_ISSUED_DATE_TIME, moment(v).endOf("day").milliseconds(0).valueOf())
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.withholdingTax)}</legend>
				<Row>
					<Col>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })]}
							status={this.props.PV.modalNew.whtIssuedDateTime ? undefined : "error"}
							values={this.props.PV.modalNew.whtIssuedDateTime ? [moment(this.props.PV.modalNew.whtIssuedDateTime)] : [undefined]}
							onChange={this.onIssuedDateTime}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PVModalNewWithholdingTax))
