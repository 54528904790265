import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS,
} from "~/redux/type"

class IPDModalMedicalCertificateInvestigation extends React.Component {
	constructor(props) {
		super(props)

		this.onBloodTest = this.onBloodTest.bind(this)
		this.onBloodTestDetails = this.onBloodTestDetails.bind(this)
		this.onRadiology = this.onRadiology.bind(this)
		this.onRadiologyDetails = this.onRadiologyDetails.bind(this)
		this.onInvestigation = this.onInvestigation.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedCertificate.certificate) {
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK,
				this.props.ipd.selectedCertificate.certificate.bloodTestChecked
			)
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS,
				this.props.ipd.selectedCertificate.certificate.bloodTestDetails
			)
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK,
				this.props.ipd.selectedCertificate.certificate.radiologyChecked
			)
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS,
				this.props.ipd.selectedCertificate.certificate.radiologyDetails
			)
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION,
				this.props.ipd.selectedCertificate.certificate.investigation
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.ipd.modalCertificate.bloodTestChecked !== this.props.ipd.modalCertificate.bloodTestChecked) {
			if (!this.props.ipd.modalCertificate.bloodTestChecked) {
				this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK, false)
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS, "")
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK, false)
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS, "")
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION, "")
	}

	onBloodTest(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK, event.target.checked)
	}

	onBloodTestDetails(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS, event.target.value)
	}

	onRadiology(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK, event.target.checked)
	}

	onRadiologyDetails(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS, event.target.value)
	}

	onInvestigation(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.investigation)}</legend>
				<Row className={"g-0 label-info-row"}>
					<Col md={"auto"}>
						<FormGroup controlId={"blood_test"}>
							<FormCheck
								label={translate(ResourceAssistance.Message.bloodTest)}
								checked={this.props.ipd.modalCertificate.bloodTestChecked}
								onChange={this.onBloodTest}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={3}
							disabled={!this.props.ipd.modalCertificate.bloodTestChecked}
							value={this.props.ipd.modalCertificate.bloodTestDetails}
							onChange={this.onBloodTestDetails}
						/>
					</Col>

					<Col md={"auto"}>
						<FormGroup controlId={"radiology"}>
							<FormCheck
								label={translate(ResourceAssistance.Message.radiology)}
								checked={this.props.ipd.modalCertificate.radiologyChecked}
								onChange={this.onRadiology}
							/>
						</FormGroup>
					</Col>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={3}
							disabled={!this.props.ipd.modalCertificate.radiologyChecked}
							value={this.props.ipd.modalCertificate.radiologyDetails}
							onChange={this.onRadiologyDetails}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={2}
							value={this.props.ipd.modalCertificate.investigation}
							onChange={this.onInvestigation}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalMedicalCertificateInvestigation)
