import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Container } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import GlucoseDate from "./GlucoseDate"
import GlucoseLevel from "./GlucoseLevel"

class Glucose extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			datetime: "",
			level: "",
		}
		this.onDateChange = this.onDateChange.bind(this)
		this.onLevel = this.onLevel.bind(this)
	}

	componentDidMount() {
		if (this.props.glucose) {
			this.setState({
				datetime: this.props.glucose.datetime,
				level: this.props.glucose.level,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	onDateChange(dateObj) {
		this.setState({
			datetime: dateObj ? dateObj.valueOf() : "",
		})
	}

	onLevel(e) {
		this.setState({
			level: e.target.value,
		})
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<GlucoseDate {...this.props} {...this.state} onDateChange={this.onDateChange} />
				<GlucoseLevel {...this.props} {...this.state} onLevel={this.onLevel} />
			</Container>
		)
	}
}

Glucose.propTypes = {
	glucose: propTypes.object,
}

Glucose.defaultProps = {
	glucose: null,
}

export default Glucose
