import { Descriptions } from "antd"
import React from "react"
import { ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { ImAttachment } from "react-icons/im"
import { MdOutlineClear } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class RegisterRightReference extends React.Component {
	constructor(props) {
		super(props)

		this.onUploadCovidResult = this.onUploadCovidResult.bind(this)
		this.onUploadSatCode = this.onUploadSatCode.bind(this)
		this.onUploadNovel = this.onUploadNovel.bind(this)
		this.onUploadExpenseEstimation = this.onUploadExpenseEstimation.bind(this)
	}

	onUploadCovidResult(event) {
		let covidResultFileBlob = event.target.files[0]
		if (!covidResultFileBlob || !covidResultFileBlob.name.toLowerCase().endsWith(".pdf")) {
			return
		}
		this.props.uploadAdmissionFile(ResourceAssistance.Url.registration.uploadCovidResultFile, covidResultFileBlob)
	}

	onUploadSatCode(event) {
		let satCodeFile = event.target.files[0]
		if (!satCodeFile || (!satCodeFile.name.toLowerCase().endsWith(".jpg") && !satCodeFile.name.toLowerCase().endsWith(".png"))) {
			return
		}
		Utils.convertBlob(satCodeFile, "image/jpeg").then((jpegBlob) => {
			this.props.uploadAdmissionFile(ResourceAssistance.Url.registration.uploadSatCodeFile, jpegBlob)
		})
	}

	onUploadNovel(event) {
		let novelFileBlob = event.target.files[0]
		if (!novelFileBlob || !novelFileBlob.name.toLowerCase().endsWith(".pdf")) {
			return
		}
		this.props.uploadAdmissionFile(ResourceAssistance.Url.registration.uploadNovelFile, novelFileBlob)
	}

	onUploadExpenseEstimation(event) {}

	render() {
		return (
			<Row>
				<Col>
					<fieldset className={ResourceAssistance.CSS.fullFlex}>
						<legend>{translate(ResourceAssistance.Message.references)}</legend>
						<Descriptions
							size={"small"}
							style={{ flex: "1", flexDirection: "column" }}
							contentStyle={{ fontStyle: "italic" }}
							column={6}
							extra={
								Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.CRUD, this.props.login.user.roles) && (
									<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.primary} size={ResourceAssistance.FormControl.size.sm}>
										<DropdownToggle variant={ResourceAssistance.Button.variant.primary} disabled={this.props.ipd.selectedAdmission.index === -1}>
											<ImAttachment size={ResourceAssistance.ReactIcon.size} />
											{translate(ResourceAssistance.Message.attachment)}
										</DropdownToggle>
										<DropdownMenu variant={"info"}>
											<label htmlFor="expenseEstimation" className={`dropdown-item ${!this.props.ipd.selectedAdmission.admission || true ? "disabled" : ""}`}>
												{translate(ResourceAssistance.Message.expenseEstimation)}
											</label>
											<DropdownItem
												id={"expenseEstimation"}
												as={"input"}
												type={ResourceAssistance.FormControl.type.file}
												style={{ display: "none" }}
												disabled={!this.props.ipd.selectedAdmission.admission || true}
												accept={"application/pdf"}
												onChange={this.onUploadExpenseEstimation}
											/>
											<Dropdown.Divider />
											<label htmlFor="covidResult" className={`dropdown-item ${!this.props.ipd.selectedAdmission.admission ? "disabled" : ""}`}>
												{translate(ResourceAssistance.Hospitel.covid19Lab)}
											</label>
											<DropdownItem
												id={"covidResult"}
												as={"input"}
												type={ResourceAssistance.FormControl.type.file}
												style={{ display: "none" }}
												disabled={!this.props.ipd.selectedAdmission.admission}
												accept={"application/pdf"}
												onChange={this.onUploadCovidResult}
											/>
											<label htmlFor="satCode" className={`dropdown-item ${!this.props.ipd.selectedAdmission.admission ? "disabled" : ""}`}>
												{translate(ResourceAssistance.Message.claimFile)}
											</label>
											<DropdownItem
												id={"satCode"}
												as={"input"}
												type={ResourceAssistance.FormControl.type.file}
												style={{ display: "none" }}
												disabled={!this.props.ipd.selectedAdmission.admission}
												// accept={["image/png", "image/jpeg"]}
												accept={"image/*"}
												onChange={this.onUploadSatCode}
											/>
											<label htmlFor="novel" className={`dropdown-item ${!this.props.ipd.selectedAdmission.admission ? "disabled" : ""}`}>
												{translate(ResourceAssistance.Message.novelDiseaseInvestigationSheet)}
											</label>
											<DropdownItem
												id={"novel"}
												as={"input"}
												type={ResourceAssistance.FormControl.type.file}
												style={{ display: "none" }}
												disabled={!this.props.ipd.selectedAdmission.admission}
												accept={"application/pdf"}
												onChange={this.onUploadNovel}
											/>
										</DropdownMenu>
									</Dropdown>
								)
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.hn)} span={6}>
								{this.props.ipd.selectedAdmission.admission ? this.props.ipd.selectedAdmission.admission.clinicalRecord.hn : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.identification)}>
								{this.props.ipd.selectedPatient.patient && this.props.ipd.selectedPatient.patient.idFileName ? (
									<ImAttachment size={ResourceAssistance.ReactIcon.size} />
								) : (
									<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCare)}>
								{this.props.ipd.selectedAdmission.admission && this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareFileName ? (
									<ImAttachment size={ResourceAssistance.ReactIcon.size} />
								) : (
									<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.expenseEstimation)}>
								{this.props.ipd.selectedAdmission.admission && this.props.ipd.selectedAdmission.admission.clinicalRecord.expenseEstimationFileName ? (
									<ImAttachment size={ResourceAssistance.ReactIcon.size} />
								) : (
									<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Hospitel.covid19Lab)}>
								{this.props.ipd.selectedAdmission.admission && this.props.ipd.selectedAdmission.admission.clinicalRecord.covidLabResultFileName ? (
									<ImAttachment size={ResourceAssistance.ReactIcon.size} />
								) : (
									<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.claimFile)}>
								{this.props.ipd.selectedAdmission.admission && this.props.ipd.selectedAdmission.admission.clinicalRecord.satCodeFileName ? (
									<ImAttachment size={ResourceAssistance.ReactIcon.size} />
								) : (
									<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
								)}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.novelDiseaseInvestigationSheet)}>
								{this.props.ipd.selectedAdmission.admission && this.props.ipd.selectedAdmission.admission.clinicalRecord.novelFileName ? (
									<ImAttachment size={ResourceAssistance.ReactIcon.size} />
								) : (
									<MdOutlineClear size={ResourceAssistance.ReactIcon.size} />
								)}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterRightReference)
