import { Divider } from "antd"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDiploma1 } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgMedicalEquipmentBox, SvgPatientOnHand, SvgVitalSign } from "~/icons"
import { onModalDisplayAction, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY,
	SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY,
	SET_HOSPITEL_SELECTED_VITAL_SIGN,
	SET_IPD_DTX_DISPLAY,
	SET_IPD_GCS_DISPLAY,
	SET_IPD_INTAKE_OUTPUT_DISPLAY,
	SET_IPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_IPD_PAIN_SCALE_DISPLAY,
	SET_IPD_PUPIL_SIZE_DISPLAY,
	SET_IPD_SELECTED_CERTIFICATE,
	SET_IPD_SELECTED_DTX,
	SET_IPD_SELECTED_GCS,
	SET_IPD_SELECTED_INTAKE_OUTPUT,
	SET_IPD_SELECTED_PAIN_SCALE,
	SET_IPD_SELECTED_PUPIL_SIZE,
} from "~/redux/type"

class HospitelModalNurseRecordAction extends React.Component {
	constructor(props) {
		super(props)

		this.onVitalSign = this.onVitalSign.bind(this)
		this.onMedicalSupply = this.onMedicalSupply.bind(this)
		this.onService = this.onService.bind(this)
		this.onMedicalCertificate = this.onMedicalCertificate.bind(this)
		this.onIntakeOutput = this.onIntakeOutput.bind(this)
		this.onGlasgowComaScale = this.onGlasgowComaScale.bind(this)
		this.onPainScale = this.onPainScale.bind(this)
		this.onPupilSize = this.onPupilSize.bind(this)
		this.onGlucose = this.onGlucose.bind(this)
	}

	onVitalSign() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY, true)
		this.props.setSelected(SET_HOSPITEL_SELECTED_VITAL_SIGN, -1, null)
	}

	onMedicalSupply() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY, true)
	}

	onService() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY, true)
	}

	onMedicalCertificate() {
		this.props.onModalDisplayAction(SET_IPD_MEDICAL_CERTIFICATE_DISPLAY, true)
		this.props.setSelected(SET_IPD_SELECTED_CERTIFICATE, -1, null)
	}

	onIntakeOutput() {
		this.props.onModalDisplayAction(SET_IPD_INTAKE_OUTPUT_DISPLAY, true)
		this.props.setSelected(SET_IPD_SELECTED_INTAKE_OUTPUT, -1, null)
	}

	onGlasgowComaScale() {
		this.props.setSelected(SET_IPD_SELECTED_GCS, -1, null)
		this.props.onModalDisplayAction(SET_IPD_GCS_DISPLAY, true)
	}

	onPainScale() {
		this.props.setSelected(SET_IPD_SELECTED_PAIN_SCALE, -1, null)
		this.props.onModalDisplayAction(SET_IPD_PAIN_SCALE_DISPLAY, true)
	}

	onPupilSize() {
		this.props.setSelected(SET_IPD_SELECTED_PUPIL_SIZE, -1, null)
		this.props.onModalDisplayAction(SET_IPD_PUPIL_SIZE_DISPLAY, true)
	}

	onGlucose() {
		this.props.setSelected(SET_IPD_SELECTED_DTX, -1, null)
		this.props.onModalDisplayAction(SET_IPD_DTX_DISPLAY, true)
	}

	render() {
		return (
			<>
				<Row>
					<Col />
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onVitalSign}>
							<SvgVitalSign width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Hospitel.vitalSign)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onIntakeOutput}>
							<SvgVitalSign width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.intakeOutput)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onGlasgowComaScale}>
							<SvgVitalSign width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.glasgowComaScale)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onPainScale}>
							<SvgVitalSign width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.painScale)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onPupilSize}>
							<SvgVitalSign width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.pupilSize)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onGlucose}>
							<SvgVitalSign width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.DTX)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onMedicalSupply}>
							<SvgMedicalEquipmentBox width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Message.medicalSupply)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onService}>
							<SvgPatientOnHand width={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Hospitel.service)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onMedicalCertificate}>
							<FcDiploma1 size={ResourceAssistance.ReactIcon.size_md} />
							{translate(ResourceAssistance.Hospitel.medicalCertificate)}
						</Button>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseRecordAction)
