import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_ADM_BANK_BRANCHES,
	SET_ADM_CATEGORY_INVENTORY_PAYABLE_ACTIVITIES,
	SET_ADM_CATEGORY_INVENTORY_RECEIVABLE_ACTIVITIES,
	SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLES,
	SET_ADM_HEALTH_CARE_PLAN_COMPANIES,
	SET_GENERAL_TYPES,
	SET_ADM_TYPES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setAdminTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ResourceAssistance.Message.date]

		let body = filtered.map((type) => {
			return [type.displayName, type.description, type.createdBy.displayName, Utils.formatDate(type.creationDateTime)]
		})
		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmBankTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))
		let rowColor = []
		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}
		let header = [
			"",
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]
		let body = filtered.map((type) => {
			return [
				[!_.isEmpty(type.ipayBatchFormat) || !_.isEmpty(type.ipayDetailForamt)],
				type.code,
				type.displayName,
				type.description,
				type.createdBy.displayName,
				Utils.formatDate(type.creationDateTime),
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.iPayFormat]]]]
		})
		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminBankBranches = (branches) => {
	return (dispatch, getState) => {
		let filtered = branches.sort((a, b) => Utils.sort(a.name, b.name))

		let rowColor = []

		let body = filtered.map((branch) => {
			return [
				branch.code,
				branch.name,
				branch.account,
				branch.address,
				branch.description,
				branch.lastModifiedBy.displayName,
				Utils.formatDate(branch.creationDateTime),
				[true, true],
			]
		})
		dispatch({
			type: SET_ADM_BANK_BRANCHES,
			payload: {
				original: branches,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminHealthCareTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [
			"",
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]

		let body = filtered.map((type) => {
			return [
				[type.primaryHealthCarePlan, type.primaryHospital],
				type.code,
				type.displayName,
				type.description,
				type.createdBy.displayName,
				Utils.formatDate(type.creationDateTime),
			]
		})

		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.primaryPlan]], [[true, ResourceAssistance.Message.primaryHospital]]]]
		})

		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminHealthCarePlanCompanies = (companies) => {
	return (dispatch, getState) => {
		let filtered = companies.sort((a, b) => Utils.sort(a.name, b.name))

		let rowColor = []

		let body = filtered.map((type) => {
			return [type.name, type.description, type.insuranceCompany, type.lastModifiedBy.displayName, Utils.formatDate(type.creationDateTime), [true, true]]
		})
		dispatch({
			type: SET_ADM_HEALTH_CARE_PLAN_COMPANIES,
			payload: {
				original: companies,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminHealthCarePlanAccountsReceivables = (activities) => {
	return (dispatch, getState) => {
		let filtered = activities.sort((a, b) => Utils.sort(a.chartOfAccounts.fullCode, b.chartOfAccounts.fullCode))

		let rowColor = []

		let body = filtered.map((activity) => {
			return [
				[activity.linkGL],
				activity.chartOfAccounts.fullCode,
				activity.chartOfAccounts.displayName,
				activity.chartOfAccounts.description,
				activity.lastModifiedBy.displayName,
				Utils.formatDate(activity.lastModifiedDateTime),
				[true, true],
			]
		})
		let popoverMsg = filtered.map(() => {
			return [[[[true, ResourceAssistance.Message.linkGL]]]]
		})
		dispatch({
			type: SET_ADM_HEALTH_CARE_PLAN_ACCOUNTS_RECEIVABLES,
			payload: {
				original: activities,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminInsuranceCompanyTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.address,
			ResourceAssistance.Message.tel,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]

		let body = filtered.map((type) => {
			return [type.code, type.displayName, type.description, type.address, type.tel, type.createdBy.displayName, Utils.formatDate(type.creationDateTime)]
		})

		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmInventoryTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = ["", ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ResourceAssistance.Message.date]

		let body = filtered.map((type) => {
			return [
				[type.role === ResourceAssistance.Enum.doctor, type.role === ResourceAssistance.Enum.nurse],
				type.displayName,
				type.description,
				type.createdBy.displayName,
				Utils.formatDate(type.creationDateTime),
			]
		})

		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.doctor]], [[true, ResourceAssistance.Message.nurse]]]]
		})

		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmCategoryAccountingReceivable = (activities) => {
	return (dispatch, getState) => {
		let filtered = activities.filter((each) => each.receivable).sort((a, b) => Utils.sort(a.chartOfAccounts.fullCode, b.chartOfAccounts.fullCode))

		let rowColor = []

		let body = filtered.map((each) => {
			return [
				[each.linkGL],
				each.chartOfAccounts.fullCode,
				each.chartOfAccounts.displayName,
				each.chartOfAccounts.description,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
				[true, true],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.linkGL]]]]
		})
		dispatch({
			type: SET_ADM_CATEGORY_INVENTORY_RECEIVABLE_ACTIVITIES,
			payload: {
				original: activities,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmCategoryAccountingPayable = (activities) => {
	return (dispatch, getState) => {
		let filtered = activities.filter((each) => !each.receivable).sort((a, b) => Utils.sort(a.chartOfAccounts.fullCode, b.chartOfAccounts.fullCode))

		let rowColor = []

		let body = filtered.map((each) => {
			return [
				[each.linkGL, each.paymentSlip, each.financialOperations],
				each.chartOfAccounts.fullCode,
				each.chartOfAccounts.displayName,
				each.chartOfAccounts.description,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
				[true, true],
			]
		})
		let popoverMsg = filtered.map((order) => {
			return [
				[[[true, ResourceAssistance.Message.linkGL]], [[true, ResourceAssistance.Message.paymentSlip]], [[true, ResourceAssistance.Message.financialOperations]]],
			]
		})
		dispatch({
			type: SET_ADM_CATEGORY_INVENTORY_PAYABLE_ACTIVITIES,
			payload: {
				original: activities,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmIPayTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))
		let rowColor = []
		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}
		let header = [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]
		let body = filtered.map((type) => {
			return [type.code, type.displayName, type.description, type.createdBy.displayName, Utils.formatDate(type.creationDateTime)]
		})
		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmGLDocumentTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))
		let rowColor = []
		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}
		let header = [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]
		let body = filtered.map((type) => {
			return [type.code, type.displayName, type.description, type.createdBy.displayName, Utils.formatDate(type.creationDateTime)]
		})
		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminPaymentTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = ["", ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ResourceAssistance.Message.date]

		let body = filtered.map((type) => {
			return [
				[type.bankAccount, type.savingAccount, type.checkAccount, type.creditCardAccount],
				type.displayName,
				type.description,
				type.createdBy.displayName,
				Utils.formatDate(type.creationDateTime),
			]
		})

		let popoverMsg = filtered.map((order) => {
			return [
				[
					[[true, ResourceAssistance.Message.bank]],
					[[true, ResourceAssistance.Message.patientSaving]],
					[[true, ResourceAssistance.Message.moneyCheck]],
					[[true, ResourceAssistance.Message.creditCard]],
				],
			]
		})

		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmRoleTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = ["", ResourceAssistance.Message.name, ResourceAssistance.Message.description, ResourceAssistance.Message.by, ResourceAssistance.Message.date]

		let body = filtered.map((type) => {
			return [
				[type.role === ResourceAssistance.Enum.doctor, type.role === ResourceAssistance.Enum.nurse],
				type.displayName,
				type.description,
				type.createdBy.displayName,
				Utils.formatDate(type.creationDateTime),
			]
		})

		let popoverMsg = filtered.map((order) => {
			return [[[[true, ResourceAssistance.Message.doctor]], [[true, ResourceAssistance.Message.nurse]]]]
		})

		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmWithholdingTaxTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))
		let rowColor = []
		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}
		let header = [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.type,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]
		let body = filtered.map((type) => {
			return [type.code, type.displayName, type.description, type.withholdingTaxType, type.createdBy.displayName, Utils.formatDate(type.creationDateTime)]
		})
		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})
		dispatch({
			type: SET_GENERAL_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setAdminServiceTypes = (types) => {
	return (dispatch, getState) => {
		let filtered = types.filter((type) => type.displayName.toLowerCase().includes(getState().admin.type.searchText.toLowerCase()))

		let rowColor = []

		if (!getState().admin.type.displayInactive) {
			filtered = filtered.filter((type) => type.active)
		} else {
			rowColor = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let header = [
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.chrgItem,
			ResourceAssistance.Message.by,
			ResourceAssistance.Message.date,
		]

		let body = filtered.map((type) => {
			return [type.displayName, type.description, type.chrgItem, type.createdBy.displayName, Utils.formatDate(type.creationDateTime)]
		})
		dispatch({
			type: SET_ADM_TYPES,
			payload: {
				types: types,
				filtered: filtered,
				header: header,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}
