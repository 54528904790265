import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"

class IPDModalPRNRecordDescription extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions column={{ xxl: 4, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
						<Descriptions.Item label={translate(ResourceAssistance.Message.genericName)}>
							{this.props.hospitel.selectedOrder.order.description}
						</Descriptions.Item>
						<Descriptions.Item
							label={translate(ResourceAssistance.Message.description)}
							contentStyle={{ whiteSpace: "normal" }}
						>
							{this.props.hospitel.selectedOrder.order.notes}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalPRNRecordDescription)
