import * as React from "react";

function SvgSvgPatient(props) {
  return (
    <svg
      stroke="currentColor"
      strokeWidth={0}
      fill="currentColor"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <style>{".SvgPatient_svg__st1{fill:#16ade1}"}</style>
      <path
        className="SvgPatient_svg__st1"
        d="M418 336h-19l4-3a50 50 0 007-71 50 50 0 00-88 32c0 18 9 33 23 42h-19c-23 0-41 18-41 41v82h33v-51c0-4 3-7 7-7s7 3 7 7v51h80v-51c0-4 3-7 7-7s7 3 7 7v51h33v-82c0-23-18-41-41-41z"
      />
      <path
        d="M315 323a64 64 0 0197-79l8-89c1-7-4-13-11-13h-61c-6 0-10 4-11 9l-8 35c-1 6-6 10-11 10H65c-7 0-13 6-12 13l24 198c1 6 6 11 12 11h182v-41c0-27 19-49 44-54zm-67 8h-32v32h-50v-32h-32v-50h32v-32h50v32h32v50z"
        fill="#1b3954"
      />
      <path
        className="SvgPatient_svg__st1"
        d="M323 149c2-12 13-21 25-21h34V53H92v76h69c4 0 7 3 7 7s-3 7-7 7H92v39h224l7-33zm-120-11v1l-1 1-1 1-1 1-1 1h-3l-5-2-2-5 2-5 1-1h2l1-1h3l1 1h1l1 1 2 5v2z"
      />
    </svg>
  );
}

export default SvgSvgPatient;
