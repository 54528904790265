import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY,
	SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalVitalSignDetails from "./HospitelModalVitalSignDetails"

class HospitelModalVitalSign extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalVitalSign",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneBtnDisabled() {
		let target = Object.assign({}, this.props.hospitel.selectedVitalSign.vitalSign, {
			...this.props.hospitel.selectedVitalSign.vitalSign,
			dateTime: this.props.hospitel.modalVitalSign.dateTime,
			temperature: this.props.hospitel.modalVitalSign.temperature,
			oxygenSat: this.props.hospitel.modalVitalSign.oxygen,
			rr: this.props.hospitel.modalVitalSign.rr,
			bp: Utils.trim(this.props.hospitel.modalVitalSign.bp),
			pr: this.props.hospitel.modalVitalSign.pr,
		})
		return _.isEqual(target, this.props.hospitel.selectedVitalSign.vitalSign)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveVitalSign,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.hospitel.selectedHospitel.hospitel.admission.id,
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				vitalSigns: [
					{
						id: this.props.hospitel.selectedVitalSign.vitalSign
							? this.props.hospitel.selectedVitalSign.vitalSign.id
							: 0,
						dateTime: this.props.hospitel.modalVitalSign.dateTime,
						temperature: this.props.hospitel.modalVitalSign.temperature,
						pr: this.props.hospitel.modalVitalSign.pr,
						rr: this.props.hospitel.modalVitalSign.rr,
						bp: Utils.trim(this.props.hospitel.modalVitalSign.bp),
						oxygen: this.props.hospitel.modalVitalSign.oxygen,
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY, false)
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.vitalSign)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalVitalSignDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isVitalSignDisplay,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalVitalSign))
