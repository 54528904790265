import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { GiPayMoney } from "react-icons/gi"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setPSSuppliers, setSelected } from "~/redux/action"
import { SET_PS_PAYMENT_PLAN_DISPLAY, SET_PS_SELECTED_PAYMENT_PLAN, SET_PS_SELECTED_SUPPLIER } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentSlipRightSupplier extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
		this.onPaymentPlan = this.onPaymentPlan.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PS.selectedPaymentSlip, this.props.PS.selectedPaymentSlip)) {
			if (this.props.PS.selectedPaymentSlip.ps) {
				this.props.setPSSuppliers(this.props.PS.selectedPaymentSlip.ps.paymentSlips)
			} else {
				this.props.setPSSuppliers([])
			}
		}
		if (!_.isEqual(prevProps.PS.supplierTable.filtered, this.props.PS.supplierTable.filtered)) {
			let index = this.props.PS.selectedSupplier.supplier
				? this.props.PS.supplierTable.filtered.findIndex((each) => each.id === this.props.PS.selectedSupplier.supplier.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PS_SELECTED_SUPPLIER, index, this.props.PS.supplierTable.filtered[index])
			} else {
				this.props.setSelected(SET_PS_SELECTED_SUPPLIER, -1, null)
			}
		}
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_PS_SELECTED_SUPPLIER, index, this.props.PS.supplierTable.filtered[index])
	}

	onPaymentPlan(e, row, rIdx, cIdx) {
		this.props.setSelected(SET_PS_SELECTED_PAYMENT_PLAN, -1, null)
		this.props.onModalDisplayAction(SET_PS_PAYMENT_PLAN_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.paymentPlan })]}
						btnIcons={[<GiPayMoney size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onPaymentPlan]}
						data={this.props.PS.supplierTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.PS.selectedSupplier.index === -1}
						highlightedRow={this.props.PS.selectedSupplier.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPSSuppliers,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipRightSupplier))
