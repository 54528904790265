import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setHospitelPatients,
	setHospitelSOAPs,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY,
	SET_HOSPITEL_SELECTED_SOAP,
	SET_IPD_DOCTOR_SOAP_INFO_DISPLAY,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelRightDoctorNoteRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelRightDoctorNoteRow",
		}

		this.onSOAP = this.onSOAP.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onDoubleSOAP = this.onDoubleSOAP.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.modalSOAP.delete)) {
				this.deleteSOAP()
			}
		}

		if (
			!_.isEqual(prevProps.hospitel.selectedHospitel.hospitel, this.props.hospitel.selectedHospitel.hospitel) ||
			!_.isEqual(prevProps.hospitel.orderEndDateTime, this.props.hospitel.orderEndDateTime)
		) {
			this.props.setSelected(SET_HOSPITEL_SELECTED_SOAP, -1, null)
			if (this.props.hospitel.selectedHospitel.hospitel) {
				this.props.setHospitelSOAPs(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders)
			} else {
				this.props.setHospitelSOAPs([])
			}
		}
	}

	deleteSOAP() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.deleteSOAP,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.hospitel.selectedPatient.patient.id,
				orders: [
					{
						id: this.props.hospitel.selectedSOAP.soap.id,
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSOAP(soap, index) {
		this.props.setSelected(SET_HOSPITEL_SELECTED_SOAP, index, this.props.hospitel.soapTable.filtered[index])
	}

	onNew() {
		this.props.setSelected(SET_HOSPITEL_SELECTED_SOAP, -1, null)
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY, true)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedSOAP = this.props.hospitel.soapTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.modalSOAP.delete)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.soap })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(selectedSOAP.startDateTime)}
		    `
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onDoubleSOAP(row, idx) {
		this.props.onModalDisplayAction(SET_IPD_DOCTOR_SOAP_INFO_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"} style={{ maxHeight: "9rem" }}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.soap)}</legend>
						<ScrollableTable
							enableHighlight
							enableOverlay
							striped
							hover
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
							btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
							onCheckedArray={[this.onModify, this.onDelete]}
							data={this.props.hospitel.soapTable}
							onClick={this.onSOAP}
							onDoubleClick={this.onDoubleSOAP}
							isClearHighlight={this.props.hospitel.selectedSOAP.index === -1}
							highlightedRow={this.props.hospitel.selectedSOAP.index}
							extra={
								Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Doctor, this.props.login.user.roles) &&
								this.props.hospitel.selectedHospitel.hospitel &&
								!this.props.hospitel.selectedHospitel.hospitel.dischargeSummary && (
									<Button
										style={{ fontSize: "medium" }}
										variant={ResourceAssistance.Button.variant.link}
										size={ResourceAssistance.Button.size.sm}
										onClick={this.onNew}
									>
										<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
										{translate(ResourceAssistance.Message.new)}
									</Button>
								)
							}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelPatients,
			setHospitelSOAPs,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelRightDoctorNoteRow))
