import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"

class OperatingRoomRightTabGeneral extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		// if (this.props.ARC.selectedReceipt.receipt) {
		// 	this.props.setARCTabReceiptGroups([this.props.ARC.selectedReceipt.receipt])
		// } else {
		// 	this.props.setARCTabReceiptGroups([])
		// }
	}

	componentDidUpdate(prevProps, prevState) {
		// if (!_.isEqual(prevProps.ARC.selectedReceipt, this.props.ARC.selectedReceipt)) {
		// 	if (this.props.ARC.selectedReceipt.receipt) {
		// 		this.props.setARCTabReceiptGroups([this.props.ARC.selectedReceipt.receipt])
		// 	} else {
		// 		this.props.setARCTabReceiptGroups([])
		// 	}
		// }
		// if (!_.isEqual(prevProps.ARC.tabReceiptTable.filtered, this.props.ARC.tabReceiptTable.filtered)) {
		// 	let index = this.props.ARC.selectedGroup.group
		// 		? this.props.ARC.tabReceiptTable.filtered.findIndex(
		// 				(group) => group[0] && this.props.ARC.selectedGroup.group[0] && group[0].id === this.props.ARC.selectedGroup.group[0].id
		// 		  )
		// 		: -1
		// 	if (index !== -1) {
		// 		this.props.setSelected(SET_ARC_SELECTED_GROUP, index, this.props.ARC.tabReceiptTable.filtered[index])
		// 	} else {
		// 		this.props.setSelected(SET_ARC_SELECTED_GROUP, -1, null)
		// 	}
		// }
	}

	componentWillUnmount() {
		// this.props.setSelected(SET_ARC_SELECTED_GROUP, -1, null)
	}

	onSelectRow(group, index) {
		// this.props.setSelected(SET_ARC_SELECTED_GROUP, index, this.props.ARC.tabReceiptTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col></Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomRightTabGeneral))
