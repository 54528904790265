// Message:{
//  key : the translate id used by {<FormattedMessage id={}/>} from react-intl.
//  key : {
//      text : the text that will not be translated, so it will display the same for different language.
//      translateId: the translate id used for translation.
//  }
// }
//
import PreOperativeCheckList from "./message/PreOperativeCheckList"
import IntraAnesthesiaRecord from "./message/IntraAnesthesiaRecord"
import PostAnesthesiaRecord from "./message/PostAnesthesiaRecord"
import SurgicalSafetyCheckList from "./message/SurgicalSafetyCheckList"
import FileDepositSlip from "./message/FileDepositSlip"
const Message = {
	Message: {
		...PreOperativeCheckList,
		...SurgicalSafetyCheckList,
		...IntraAnesthesiaRecord,
		...PostAnesthesiaRecord,
		...FileDepositSlip,
		"": "",
		stockManagement: {
			text: "Stock Manager",
			translateId: "stockManagement",
		},
		ICD: "ICD",
		abdomen: "abdomen",
		abnormalFlexion: "abnormalFlexion",
		accidentHistory: "accidentHistory",
		accounting: "accounting",
		accountsCode: "accountsCode",
		accountsPayable: "accountsPayable",
		accountsPayableRC: "accountsPayableRC",
		accountsPayablePI: "accountsPayablePI",
		accountsPayablePV: "accountsPayablePV",
		accountsReceivable: "accountsReceivable",
		active: "active",
		adHocOrder: "adHocOrder",
		add: "add",
		address: "address",
		addressEng: "addressEng",
		adpType: "adpType",
		admin: "admin",
		admissionDate: "admissionDate",
		admissionPlan: "admissionPlan",
		afterMeal: "afterMeal",
		alcohol: "alcohol",
		amount: "amount",
		amountML: "amountML",
		amountOfPackage: "amountOfPackage",
		amountPerOrder: "amountPerOrder",
		antigenComment: "antigenComment",
		appointment: "appointment",
		appointmentDate: "appointmentDate",
		appointmentTime: "appointmentTime",
		approve: "approve",
		approved: "approved",
		approvedBy: "approvedBy",
		approvedByAnotherOrderWarning: "approvedByAnotherOrderWarning",
		approvedInventories: "approvedInventories",
		approver: "approver",
		approverCheck: "approverCheck",
		arrival: "arrival",
		assets: "assets",
		attachment: "attachment",
		authenNumber: "authenNumber",
		authentication: "authentication",
		authorization: "authorization",
		authorize: "authorize",
		back: "back",
		bank: "bank",
		bankAccNum: "bankAccNum",
		bankBranch: "bankBranch",
		bankName: "bankName",
		beforeBed: "beforeBed",
		beforeMeal: "beforeMeal",
		bill: "bill",
		billByCategory: "billByCategory",
		billByHealthCarePlan: "billByHealthCarePlan",
		billDetails: "billDetails",
		billSummary: "billSummary",
		billTo: "billTo",
		billingAddress: "billingAddress",
		billingContact: "billingContact",
		billingToTodayByAdmission: "billingToTodayByAdmission",
		bloodTest: "bloodTest",
		bonus: "bonus",
		bpmmHg: "bpmmHg",
		HRbpm: "HRbpm",
		branch: "branch",
		brandName: "brandName",
		brandNameAndCode: "brandNameAndCode",
		budget: "budget",
		by: "by",
		byAdmission: "byAdmission",
		byUser: "byUser",
		calculation: "calculation",
		cancel: "cancel",
		cancelDoctorPrescriptionOnly: "cancelDoctorPrescriptionOnly",
		cancelNotReceived: "cancelNotReceived",
		cancelled: "cancelled",
		cannotDownloadLogo: "cannotDownloadLogo",
		carAccident: "carAccident",
		carAccidentReference: "carAccidentReference",
		career: "career",
		category: "category",
		categoryNum: "categoryNum",
		certified: "certified",
		certifiedBy: "certifiedBy",
		change: "change",
		changeEndDate: "changeEndDate",
		changePassword: "changePassword",
		chartOfAccounts: "chartOfAccounts",
		checkIn: "checkIn",
		checkInDate: "checkInDate",
		checkInTime: "checkInTime",
		checkNum: "checkNum",
		checkOut: "checkOut",
		checkOutDate: "checkOutDate",
		checkOutTime: "checkOutTime",
		checkbox: "checkbox",
		chiefComplaint: "chiefComplaint",
		chinese: "中文",
		chrgItem: "chrgItem",
		city: "city",
		cityEng: "cityEng",
		claimFile: "claimFile",
		clear: "clear",
		close: "close",
		code: "code",
		companyOrResponsiblePersonContact: "companyOrResponsiblePersonContact",
		complete: "complete",
		confirm: "confirm",
		confirmPassword: "confirmPassword",
		confused: "confused",
		contact: "contact",
		contactInfo: "contactInfo",
		contactPerson: "contactPerson",
		continue: "continue",
		contractBilling: "contractBilling",
		contractBillingId: "contractBillingId",
		contraindicationsAndWarnings: "contraindicationsAndWarnings",
		coordinator: "coordinator",
		costPerOrder: "costPerOrder",
		createNewPR: "createNewPR",
		credit: "credit",
		creditCard: "creditCard",
		currencySign: "currencySign",
		current: "current",
		currentBalance: "currentBalance",
		currentLevel: "currentLevel",
		date: "date",
		dateOfPo: "dateOfPo",
		day: "day",
		days: "days",
		debit: "debit",
		debt: "debt",
		default: "default",
		delete: "delete",
		deleteSkippedPharmacyOrder: "deleteSkippedPharmacyOrder",
		deleteVerifiedPharmacyOrder: "deleteVerifiedPharmacyOrder",
		department: "department",
		deposit: "deposit",
		description: "description",
		deselectAll: "deselectAll",
		dischargeDate: "dischargeDate",
		discharged: "discharged",
		discount: "discount",
		discountBill: "discountBill",
		discountItem: "discountItem",
		discountPricePerOrder: "discountPricePerOrder",
		discountPricePerUnit: "discountPricePerUnit",
		discountService: "discountService",
		discountTaxPricePerUnit: "discountTaxPricePerUnit",
		discountWithSymbol: "discountWithSymbol",
		dispense: "dispense",
		dispensed: "dispensed",
		dispensedBy: "dispensedBy",
		dispensingTime: "dispensingTime",
		displayAll: "displayAll",
		displayExpire: "displayExpire",
		displayInactive: "displayInactive",
		displayMinStock: "displayMinStock",
		doctorConsultation: "doctorConsultation",
		doctorNote: "doctorNote",
		doctorOptionAfter: "doctorOptionAfter",
		doctorPrefix: "doctorPrefix",
		doctorPrescription: "doctorPrescription",
		doctorPrescriptionOnly: "doctorPrescriptionOnly",
		documentNum: "documentNum",
		done: "done",
		dose: "dose",
		doubleClickToDelete: "doubleClickToDelete",
		drinking: "drinking",
		driver: "driver",
		dueDate: "dueDate",
		dupplicateCodeWarning: "dupplicateCodeWarning",
		dupplicateNameWarning: "dupplicateNameWarning",
		dupplicateWarning: "dupplicateWarning",
		duration: "duration",
		eclaim: "eclaim",
		eclaimFiles: "eclaimFiles",
		eclaimUc: "eclaimUc",
		eclaimVariable: "eclaimVariable",
		edit: "edit",
		email: "email",
		empty: "empty",
		emptyCancelReason: "emptyCancelReason",
		emptyDisapproveReasonWarning: "emptyDisapproveReasonWarning",
		emptyNoteWarning: "emptyNoteWarning",
		emptyWarning: "emptyWarning",
		endDate: "endDate",
		endDateTime: "endDateTime",
		english: "EN",
		equity: "equity",
		estimatedDateOfConfinement: "estimatedDateOfConfinement",
		every: "every",
		examinationDate: "examinationDate",
		expected: "expected",
		expenseEstimation: "expenseEstimation",
		expenses: "expenses",
		expire: "expire",
		expired: "expired",
		exportTypes: "exportTypes",
		expiredDate: "expiredDate",
		extension: "extension",
		extremities: "extremities",
		eyeOpeningResponse: "eyeOpeningResponse",
		factoryArea: "factoryArea",
		fallRisk: "fallRisk",
		familyHealthHistory: "familyHealthHistory",
		fax: "fax",
		faxNo: "faxNo",
		female: "female",
		fileName: "fileName",
		files: "files",
		filter: "filter",
		financialPlan: "financialPlan",
		firstName: "firstName",
		firstNameEng: "firstNameEng",
		focus: "focus",
		form: "form",
		frequency: "frequency",
		friday: "friday",
		from: "from",
		fsCode: "fsCode",
		general: "general",
		generalAppearance: "generalAppearance",
		generalInfo: "generalInfo",
		generalLedger: "generalLedger",
		generic: "generic",
		genericName: "genericName",
		genericNameOrTradeName: "genericNameOrTradeName",
		glasgowComaScale: "glasgowComaScale",
		governmentCode: "governmentCode",
		grandTotal: "grandTotal",
		graph: "graph",
		hashSymbol: "hashSymbol",
		heart: "heart",
		heent: "heent",
		hello: "hello",
		home: "home",
		hospitalBilling: "hospitalBilling",
		hospitalCode: "hospitalCode",
		hospitalInformation: "hospitalInformation",
		hours: "hours",
		id: "id",
		idCard: "idCard",
		idType: "idType",
		inPatient: "inPatient",
		inProgress: "inProgress",
		inactive: "inactive",
		incomplete: "incomplete",
		inpatientPlan: "inpatientPlan",
		inspector: "inspector",
		inspectorCheck: "inspectorCheck",
		instruction: "instruction",
		insuranceCompany: "insuranceCompany",
		insuranceReference: "insuranceReference",
		intake: "intake",
		intakeOutput: "intakeOutput",
		internationalClassificationDiseases: "internationalClassificationDiseases",
		inventory: "inventory",
		inventoryByLocation: "inventoryByLocation",
		investigation: "investigation",
		invoice: "invoice",
		invoiceId: "invoiceId",
		invoiceInfo: "invoiceInfo",
		invoiceNum: "invoiceNum",
		ioBalance: "ioBalance",
		isolationPrecaution: "isolationPrecaution",
		issued: "issued",
		issuedBy: "issuedBy",
		item: "item",
		itemSupplier: "itemSupplier",
		itemType: "itemType",
		keyword: "keyword",
		label: "label",
		labTest: "labTest",
		language: "language",
		last: "last",
		lastFor: "lastFor",
		lastFourDigitOfCreditCard: "lastFourDigitOfCreditCard",
		lastLocation: "lastLocation",
		lastMenstrualPeriod: "lastMenstrualPeriod",
		lastName: "lastName",
		lastNameEng: "lastNameEng",
		lastPurchasedFrom: "lastPurchasedFrom",
		lastReceivedDate: "lastReceivedDate",
		left: "left",
		leftEye: "leftEye",
		leftEyePupilSizeMM: "leftEyePupilSizeMM",
		leftEyeReactionToLight: "leftEyeReactionToLight",
		level: "level",
		liabilities: "liabilities",
		licenseNum: "licenseNum",
		linkGL: "linkGL",
		loadPreviousMedicalRecords: "loadPreviousMedicalRecords",
		loading: "loading",
		localising: "localising",
		location: "location",
		login: "login",
		loginError: "loginError",
		loginName: "loginName",
		logo: "logo",
		logoDragDropTitle: "logoDragDropTitle",
		logoDragDropDescription: "logoDragDropDescription",
		logout: "logout",
		lot: "lot",
		lotNum: "lotNum",
		lung: "lung",
		male: "male",
		management: "management",
		marriage: "marriage",
		max: "max",
		maxCharacters: "maxCharacters",
		maxLevel: "maxLevel",
		medicalCertificateEng: "medicalCertificateEng",
		medicalCertificateIPD: "medicalCertificateIPD",
		medicalCertificateOPD: "medicalCertificateOPD",
		medicalRecord: "medicalRecord",
		medicalSupply: "medicalSupply",
		medicationUsage: "medicationUsage",
		medicine: "medicine",
		medicineHistory: "medicineHistory",
		memoId: "memoId",
		menopause: "menopause",
		menstruation: "menstruation",
		message: "message",
		method: "method",
		min: "min",
		minLevel: "minLevel",
		minQtyPerOrder: "minQtyPerOrder",
		minStock: "minStock",
		minutes: "minutes",
		modified: "modified",
		modifyDateRange: "modifyDateRange",
		monday: "monday",
		moneyCheck: "moneyCheck",
		motorResponse: "motorResponse",
		multiTime: "multiTime",
		n2geneCT: "n2geneCT",
		name: "name",
		nameEng: "nameEng",
		nation: "nation",
		neuro: "neuro",
		new: "new",
		newForm: "newForm",
		newItem: "newItem",
		newItemSupplier: "newItemSupplier",
		newItemWarning: "newItemWarning",
		newReceive: "newReceive",
		newRecord: "newRecord",
		newRequest: "newRequest",
		newRole: "newRole",
		newSupplier: "newSupplier",
		newTax: "newTax",
		newTransfer: "newTransfer",
		newUnit: "newUnit",
		newUser: "newUser",
		nickName: "nickName",
		no: "no",
		noEyeOpening: "noEyeOpening",
		noMotor: "noMotor",
		noVerbal: "noVerbal",
		normalFlexion: "normalFlexion",
		note: "note",
		notification: "notification",
		novelDiseaseInvestigationSheet: "novelDiseaseInvestigationSheet",
		number: "number",
		nurseName: "nurseName",
		nurseOrder: "nurseOrder",
		o2Sat: "o2Sat",
		obeysCommands: "obeysCommands",
		oneMonth: "oneMonth",
		oneWeek: "oneWeek",
		opd: "opd",
		operationalService: "operationalService",
		operationType: "operationType",
		or: "or",
		order: "order",
		org: "org",
		orientated: "orientated",
		other: "other",
		output: "output",
		package: "package",
		paid: "paid",
		painScale: "painScale",
		parentLoc: "parentLoc",
		passenger: "passenger",
		passiveSmokeExposure: "passiveSmokeExposure",
		password: "password",
		passwordDoesNotMatch: "passwordDoesNotMatch",
		pastSurgicalHistory: "pastSurgicalHistory",
		patientName: "patientName",
		patientSaving: "patientSaving",
		pay: "pay",
		payee: "payee",
		payment: "payment",
		paymentSlip: "paymentSlip",
		paymentTerms: "paymentTerms",
		paymentType: "paymentType",
		pcrComment: "pcrComment",
		pedestrian: "pedestrian",
		pending: "pending",
		pendingItems: "pendingItems",
		pendingOrder: "pendingOrder",
		pendingTransfers: "pendingTransfers",
		permission: "permission",
		pharmacist: "pharmacist",
		pharmacistVerified: "pharmacistVerified",
		pharmacy: "pharmacy",
		pharmacyDispensed: "pharmacyDispensed",
		pharmacyInventory: "pharmacyInventory",
		phone: "phone",
		placeOfIncident: "placeOfIncident",
		poDate: "poDate",
		poId: "poId",
		poNumber: "poNumber",
		policeName: "policeName",
		policeStation: "policeStation",
		postcode: "postcode",
		prNumber: "prNumber",
		prPerMin: "prPerMin",
		pregnancy: "pregnancy",
		presentIllness: "presentIllness",
		pricePerOrder: "pricePerOrder",
		pricePerOrderBeforeTax: "pricePerOrderBeforeTax",
		pricePerUnit: "pricePerUnit",
		pricePerUnitBeforeTax: "pricePerUnitBeforeTax",
		primaryHospital: "primaryHospital",
		primaryHospitalCode: "primaryHospitalCode",
		primaryHospitalName: "primaryHospitalName",
		primaryPlan: "primaryPlan",
		print: "print",
		printDate: "printDate",
		prnRecord: "prnRecord",
		professionalServiceExpenseByUser: "professionalServiceExpenseByUser",
		province: "province",
		provinceEng: "provinceEng",
		pulse: "pulse",
		pupilSize: "pupilSize",
		purchase: "purchase",
		purchaseFrom: "purchaseFrom",
		purchaseOrder: "purchaseOrder",
		purchaseOrderById: "purchaseOrderById",
		purchaseOrderByLocation: "purchaseOrderByLocation",
		purchaseRequest: "purchaseRequest",
		qty: "qty",
		qtyPerDay: "qtyPerDay",
		questionnaire: "questionnaire",
		radiology: "radiology",
		rc: "rc",
		reactionToLight: "reactionToLight",
		reason: "reason",
		receipt: "receipt",
		receiptCategory: "receiptCategory",
		receiptId: "receiptId",
		receive: "receive",
		receiveId: "receiveId",
		receiveItemDetails: "receiveItemDetails",
		received: "received",
		receivedBy: "receivedBy",
		references: "references",
		refresh: "refresh",
		refund: "refund",
		registerOPD: "registerOPD",
		registrationDate: "registrationDate",
		reject: "reject",
		rejectReason: "rejectReason",
		relationship: "relationship",
		relativeInfo: "relativeInfo",
		remark: "remark",
		report: "report",
		reportById: "reportById",
		reportByLocation: "reportByLocation",
		request: "request",
		requestFrom: "requestFrom",
		requestId: "requestId",
		requestedInventories: "requestedInventories",
		requester: "requester",
		requesterCheck: "requesterCheck",
		requiredByDate: "requiredByDate",
		reset: "reset",
		return: "return",
		returned: "returned",
		returning: "returning",
		revenues: "revenues",
		right: "right",
		rightEye: "rightEye",
		rightEyePupilSizeMM: "rightEyePupilSizeMM",
		rightEyeReactionToLight: "rightEyeReactionToLight",
		riskOfAbuse: "riskOfAbuse",
		role: "role",
		room: "room",
		rrPerMin: "rrPerMin",
		run: "run",
		sarsCov2RnaPcr: "sarsCov2RnaPcr",
		satCode: "satCode",
		saturday: "saturday",
		save: "save",
		saveAll: "saveAll",
		saveFail: "saveFail",
		saveSuccess: "saveSuccess",
		scheduled: "scheduled",
		scheduledDate: "scheduledDate",
		score: "score",
		search: "search",
		searchIncomplete: "searchIncomplete",
		selectAll: "selectAll",
		sell: "sell",
		sellingPricePerUnit: "sellingPricePerUnit",
		seq: "seq",
		setAmount: "setAmount",
		shipAddress: "shipAddress",
		shippingContact: "shippingContact",
		singleTime: "singleTime",
		size: "size",
		skipPayment: "skipPayment",
		skipVerify: "skipVerify",
		skipped: "skipped",
		smoking: "smoking",
		somethingWrong: "somethingWrong",
		sounds: "sounds",
		spontaneous: "spontaneous",
		startDate: "startDate",
		startDateTime: "startDateTime",
		status: "status",
		stock: "stock",
		stockAndPurchasing: "stockAndPurchasing",
		stockManager: "stockManager",
		stools: "stools",
		stop: "stop",
		stretcher: "stretcher",
		subLevel: "subLevel",
		subunit: "subunit",
		sunday: "sunday",
		supplementOrHerbsHistory: "supplementOrHerbsHistory",
		supplier: "supplier",
		supplierCode: "supplierCode",
		supplierDiscount: "supplierDiscount",
		supplierName: "supplierName",
		table: "table",
		taxId: "taxId",
		taxRate: "taxRate",
		taxRateWithSymbol: "taxRateWithSymbol",
		taxedTotalPriceDoesNotMatch: "taxedTotalPriceDoesNotMatch",
		tel: "tel",
		telNo: "telNo",
		temperature: "temperature",
		terms: "terms",
		thai: "thai",
		thisMonth: "thisMonth",
		thursday: "thursday",
		time: "time",
		timeOfIncident: "timeOfIncident",
		timeline: "timeline",
		tmltTmtCode: "tmltTmtCode",
		to: "to",
		toPressure: "toPressure",
		toSound: "toSound",
		tobacco: "tobacco",
		today: "today",
		tomorrow: "tomorrow",
		total: "total",
		totalAdjustments: "totalAdjustments",
		totalBalance: "totalBalance",
		totalBudget: "totalBudget",
		totalDeposit: "totalDeposit",
		totalEstimationCost: "totalEstimationCost",
		totalIntake: "totalIntake",
		totalOutput: "totalOutput",
		totalPaid: "totalPaid",
		totalPrice: "totalPrice",
		totalPriceDiscounted: "totalPriceDiscounted",
		totalPriceDiscountedTaxed: "totalPriceDiscountedTaxed",
		totalPriceTaxed: "totalPriceTaxed",
		totalUnpaid: "totalUnpaid",
		totalWithdraw: "totalWithdraw",
		tradeName: "tradeName",
		tradeNameOrBrand: "tradeNameOrBrand",
		transaction: "transaction",
		transactionFee: "transactionFee",
		transfer: "transfer",
		transferBankBranch: "transferBankBranch",
		transferBankName: "transferBankName",
		transferByLocation: "transferByLocation",
		transferFrom: "transferFrom",
		transferId: "transferId",
		transferingItems: "transferingItems",
		transferred: "transferred",
		translator: "translator",
		treatment: "treatment",
		triage: "triage",
		tuesday: "tuesday",
		twoWeeks: "twoWeeks",
		type: "type",
		typeOfIncident: "typeOfIncident",
		typesOfDiagnosis: "typesOfDiagnosis",
		ucae: "ucae",
		uid: "uid",
		unapproveReason: "unapproveReason",
		unapproved: "unapproved",
		unit: "unit",
		unitConversion: "unitConversion",
		unitPrice: "unitPrice",
		unlinkGL: "unlinkGL",
		unpaid: "unpaid",
		uom: "uom",
		upload: "upload",
		uploaded: "uploaded",
		urine: "urine",
		url: "url",
		user: "user",
		username: "username",
		usingContraceptive: "usingContraceptive",
		uuc: "uuc",
		vaccine: "vaccine",
		value: "value",
		variablesFormat: "variablesFormat",
		vat: "vat",
		verbalResponse: "verbalResponse",
		verified: "verified",
		verify: "verify",
		verifyAllPrescription: "verifyAllPrescription",
		verifyTodayPrescription: "verifyTodayPrescription",
		victim: "victim",
		view: "view",
		visitId: "visitId",
		volume: "volume",
		walk: "walk",
		warning: "warning",
		waste: "waste",
		wednesday: "wednesday",
		wheelchair: "wheelchair",
		withdraw: "withdraw",
		womenOnly: "womenOnly",
		words: "words",
		workAccidentReference: "workAccidentReference",
		wrongAmount: "wrongAmount",
		wrongTotalPrice: "wrongTotalPrice",
		wrongTransferAmount: "wrongTransferAmount",
		wrongTransferAmountGreaterThanRequested: "wrongTransferAmountGreaterThanRequested",
		xpertXpressSarsCov2: "xpertXpressSarsCov2",
		yes: "yes",
		fee: "fee",
		transactionDescription: "transactionDescription",
		paymentPlan: "paymentPlan",
		creator: "creator",
		finance: "finance",
		financialOperations: "financialOperations",
		branchCode: "branchCode",
		psId: "psId",
		lastModifiedDate: "lastModifiedDate",
		export: "export",
		bankCode: "bankCode",
		financialTransaction: "financialTransaction",
		iPayFormat: "iPayFormat",
		batchRecordFormat: "batchRecordFormat",
		detailRecordFormat: "detailRecordFormat",
		variable: "variable",
		fillWithCharacter: "fillWithCharacter",
		iPay: "iPay",
		serviceType: "serviceType",
		purchaseInvoice: "purchaseInvoice",
		journalVoucher: "journalVoucher",
		withholdingTax: "withholdingTax",
		percentSymbol: "percentSymbol",
		percent: "percent",
		skipPercent: "skipPercent",
		purchaseInvoiceId: "purchaseInvoiceId",
		financialOfficer: "financialOfficer",
		cheifFinancialOfficer: "cheifFinancialOfficer",
		withholdingTaxCertificate: "withholdingTaxCertificate",
		section50BisOfTheRevenueCode: "section50BisOfTheRevenueCode",
		formNum: "formNum",
		pnd1a: "pnd1a",
		pnd1aEx: "pnd1aEx",
		pnd2: "pnd2",
		pnd3: "pnd3",
		pnd2a: "pnd2a",
		pnd3a: "pnd3a",
		pnd53: "pnd53",
		socialSecurityFund: "socialSecurityFund",
		providentFund: "providentFund",
		socialSecurityCard: "socialSecurityCard",
		employerAccountNum: "employerAccountNum",
		payer: "payer",
		accountsReceive: "accountsReceive",
		list: "list",
		subTotal: "subTotal",
		collectFrom: "collectFrom",
		postingGL: "postingGL",
		glDocument: "glDocument",
		journalEntry: "journalEntry",
		byLocation: "byLocation",
		byId: "byId",
		split: "split",
		STAT: "STAT",
		taxWithheld: "taxWithheld",
		adjustments: "adjustments",
		admission: "admission",
		age: "age",
		aie: "aie",
		allergies: "allergies",
		analyte: "analyte",
		antigen: "antigen",
		antigenCOI: "antigenCOI",
		assessment: "assessment",
		balance: "balance",
		cannotReadSMC: "cannotReadSMC",
		cashier: "cashier",
		certifiesThat: "certifiesThat",
		checkout: "checkout",
		chromatography: "chromatography",
		clinicalRecord: "clinicalRecord",
		covid19Lab: "covid19Lab",
		covid19Negative: "covid19Negative",
		covid19Positive: "covid19Positive",
		dateOfBirth: "dateOfBirth",
		daysOfRest: "daysOfRest",
		depressionAssessment: "depressionAssessment",
		diagnosis: "diagnosis",
		discharge: "discharge",
		doctor: "doctor",
		doctorName: "doctorName",
		doctorOpinion: "doctorOpinion",
		doctorStandingOrder: "doctorStandingOrder",
		download: "download",
		eGene: "eGene",
		evaluation: "evaluation",
		firstAssessment: "firstAssessment",
		fluorescence: "fluorescence",
		gender: "gender",
		googleDrive: "googleDrive",
		hasExaminedOn: "hasExaminedOn",
		health: "health",
		healthCare: "healthCare",
		healthCarePlan: "healthCarePlan",
		healthHistory: "healthHistory",
		height: "height",
		hn: "hn",
		hospital: "hospital",
		hospitel: "hospitel",
		identification: "identification",
		intervention: "intervention",
		ipd: "ipd",
		lab: "lab",
		labNumber: "labNumber",
		letterOfConsent: "letterOfConsent",
		licenseNo: "licenseNo",
		md: "md",
		medicalCertificate: "medicalCertificate",
		medicationSheet: "medicationSheet",
		month: "month",
		nGene: "nGene",
		nameSurname: "nameSurname",
		nameTitle: "nameTitle",
		negative: "negative",
		newLab: "newLab",
		nurse: "nurse",
		nurseProgressNote: "nurseProgressNote",
		oGene: "oGene",
		objective: "objective",
		opdClinicalRecord: "opdClinicalRecord",
		pastIllnesses: "pastIllnesses",
		patient: "patient",
		patientId: "patientId",
		patientInfo: "patientInfo",
		pcr: "pcr",
		physicalExamination: "physicalExamination",
		plan: "plan",
		positive: "positive",
		printLabel: "printLabel",
		registerPatient: "registerPatient",
		registration: "registration",
		requestCovid19MedicalCertificate: "requestCovid19MedicalCertificate",
		result: "result",
		sGene: "sGene",
		searchPatient: "searchPatient",
		sequence: "sequence",
		service: "service",
		signature: "signature",
		smartCardReaderIsNotWorking: "smartCardReaderIsNotWorking",
		soap: "soap",
		subjective: "subjective",
		symptoms: "symptoms",
		theAboveNamedPersonRequires: "theAboveNamedPersonRequires",
		title: "title",
		underlyingDisease: "underlyingDisease",
		vitalSign: "vitalSign",
		weight: "weight",
		year: "year",
		takeHome: "takeHome",
		totalBroughtForward: "totalBroughtForward",
		january: "january",
		february: "february",
		march: "march",
		april: "april",
		may: "may",
		june: "june",
		july: "july",
		august: "august",
		september: "september",
		october: "october",
		november: "november",
		december: "december",
		accountsPayableAging: "accountsPayableAging",
		accountsReceivableAging: "accountsReceivableAging",
		pastDue1To30: "pastDue1To30",
		pastDue31To90: "pastDue31To90",
		pastDue91To180: "pastDue91To180",
		pastDue181To360: "pastDue181To360",
		pastDueOver360: "pastDueOver360",
		numberOfInvoices: "numberOfInvoices",
		fundOrClaimNum: "fundOrClaimNum",
		contractingCompany: "contractingCompany",
		acceptedBy: "acceptedBy",
		operatingRoom: "operatingRoom",
		anesthesia: "anesthesia",
		fastingDateTime: "fastingDateTime",
		preOperativeDiag: "preOperativeDiag",
		operativeDoctor: "operativeDoctor",
		operationProcedure: "operationProcedure",
		anesthesiologist: "anesthesiologist",
		confirmation: "confirmation",
		orCase: "orCase",
		notDueYet: "notDueYet",
		overDue: "overDue",
		overDueDays: "overDueDays",
		calendar: "calendar",
		week: "week",
		balanceAdjustment: "balanceAdjustment",
		exclusiveWokflow: "exclusiveWokflow",
		selectable: "selectable",
		cashierPayment: "cashierPayment",
		supplierPricePerOrder: "supplierPricePerOrder",
		discountReceipt: "discountReceipt",
		doctorFee: "doctorFee",
		anesthesiologistFee: "anesthesiologistFee",
		estimatedLengthOfStay: "estimatedLengthOfStay",
		surgicalAuthorization: "surgicalAuthorization",
		load: "load",
		preOperativeDiagICD: "preOperativeDiagICD",
		operationProcedureICD: "operationProcedureICD",
		DRG: "DRG",
		pricePerDRG: "pricePerDRG",
		rejectedBy: "rejectedBy",
		inspectedBy: "inspectedBy",
		cancelledBy: "cancelledBy",
		totalEstimatedBudget: "totalEstimatedBudget",
		workflowProperty: "workflowProperty",
		minimumCost: "minimumCost",
		success: "success",
		failed: "failed",
		surgeon: "surgeon",
		reschedule: "reschedule",
		appointmentHistory: "appointmentHistory",
		preAnesthesiaRecord: "preAnesthesiaRecord",
		intraAnesthesiaRecord: "intraAnesthesiaRecord",
		postAnesthesiaRecord: "postAnesthesiaRecord",
		ga: "ga",
		ASAClass: "ASAClass",
		mouthOpen: "mouthOpen",
		dentures: "dentures",
		dentalDamage: "dentalDamage",
		thyromental: "thyromental",
		hyomentalDistance: "hyomentalDistance",
		neck: "neck",
		mallampatiScore: "mallampatiScore",
		expectedDifficultAirway: "expectedDifficultAirway",
		neurologicalAssessment: "neurologicalAssessment",
		backExamination: "backExamination",
		cardiovascular: "cardiovascular",
		respiratory: "respiratory",
		gastrointestinal: "gastrointestinal",
		endocrine: "endocrine",
		hematoOncology: "hematoOncology",
		renal: "renal",
		completeBloodCount: "completeBloodCount",
		WBC: "WBC",
		hgb: "hgb",
		hct: "hct",
		platelets: "platelets",
		electrolytes: "electrolytes",
		na: "na",
		cl: "cl",
		k: "k",
		hco3: "hco3",
		bun: "bun",
		cr: "cr",
		glucose: "glucose",
		liverFunctionTest: "liverFunctionTest",
		albumin: "albumin",
		globulin: "globulin",
		AST: "AST",
		ALT: "ALT",
		totalBilirubin: "totalBilirubin",
		directBilirubin: "directBilirubin",
		alkalinePhosphatase: "alkalinePhosphatase",
		coagulation: "coagulation",
		PT: "PT",
		PTT: "PTT",
		INR: "INR",
		EKG: "EKG",
		CXR: "CXR",
		antiHIV: "antiHIV",
		crossMatching: "crossMatching",
		packedRedCell: "packedRedCell",
		freshFrozenPlasma: "freshFrozenPlasma",
		plateletConcentrate: "plateletConcentrate",
		anesthesiaPlan: "anesthesiaPlan",
		anesthesiaPremedication: "anesthesiaPremedication",
		airwayAssessment: "airwayAssessment",
		normal: "normal",
		abnormal: "abnormal",
		obese: "obese",
		cachexia: "cachexia",
		pale: "pale",
		jaundice: "jaundice",
		cyanosis: "cyanosis",
		emergency: "emergency",
		limited: "limited",
		ventilation: "ventilation",
		intubation: "intubation",
		mentalRetard: "mentalRetard",
		oldCVAs: "oldCVAs",
		seizuresEpilepsy: "seizuresEpilepsy",
		chronicPain: "chronicPain",
		myopathy: "myopathy",
		hypertension: "hypertension",
		controlled: "controlled",
		poorlyControlled: "poorlyControlled",
		functionalCapacity: "functionalCapacity",
		CAD: "CAD",
		arrhythmia: "arrhythmia",
		dyslipidemia: "dyslipidemia",
		asthma: "asthma",
		COPD: "COPD",
		TB: "TB",
		recentURI: "recentURI",
		fullStomach: "fullStomach",
		hepatitis: "hepatitis",
		cirrhosis: "cirrhosis",
		DM: "DM",
		hypoHyperthyroid: "hypoHyperthyroid",
		steroidUse: "steroidUse",
		anemia: "anemia",
		bleeding: "bleeding",
		recentChemotherapy: "recentChemotherapy",
		insufficiency: "insufficiency",
		ESRD: "ESRD",
		hemodialysis: "hemodialysis",
		bloodComponentsPrepared: "bloodComponentsPrepared",
		generalAnesthesia: "generalAnesthesia",
		totalIntravenousAnesthesia: "totalIntravenousAnesthesia",
		regionalAnesthesia: "regionalAnesthesia",
		monitorAnesthesiaCare: "monitorAnesthesiaCare",
		sedate: "sedate",
		class1: "class1",
		class2: "class2",
		class3: "class3",
		class4: "class4",
		packPerDay: "packPerDay",
		preOperativeCheckList: "preOperativeCheckList",
		surgicalSafetyChecklist: "surgicalSafetyChecklist",
		sender: "sender",
		receiver: "receiver",
		signIn: "signIn",
		timeOut: "timeOut",
		signOut: "signOut",
		BMI: "BMI",
		SpO2: "SpO2",
		NPOHour: "NPOHour",
		premedication: "premedication",
		anesthesiaTechnique: "anesthesiaTechnique",
		NPO: "NPO",
		problem: "problem",
		oxygen: "oxygen",
		anestheticAgent: "anestheticAgent",
		monitoring: "monitoring",
		anesthesiaRecord: "anesthesiaRecord",
		anesthesiaStartDateTime: "anesthesiaStartDateTime",
		anesthesiaEndDateTime: "anesthesiaEndDateTime",
		operativeStartDateTime: "operativeStartDateTime",
		operativeEndDateTime: "operativeEndDateTime",
		medication: "medication",
		fixAmount: "fixAmount",
		operation: "operation",
		NIBP: "NIBP",
		ALine: "ALine",
		upper: "upper",
		lower: "lower",
		systolic: "systolic",
		diastolic: "diastolic",
		surgeonRecord: "surgeonRecord",
		nurseNote: "nurseNote",
		ambulation: "ambulation",
		nauseaOrVomitting: "nauseaOrVomitting",
		surgicalBleeding: "surgicalBleeding",
		pain: "pain",
		dischargeCriteria: "dischargeCriteria",
		hypotension: "hypotension",
		airwayObstruction: "airwayObstruction",
		hypoxia: "hypoxia",
		urinaryRetention: "urinaryRetention",
		shivering: "shivering",
		hypoventilation: "hypoventilation",
		SBU: "SBU",
		anestheticTime: "anestheticTime",
		PACURecord: "PACURecord",
		invoiceDate: "invoiceDate",
		nonDispensingRx: "nonDispensingRx",
		DTX: "DTX",
		depositSlip: "depositSlip",
		document: "document",
	},

	Hospitel: {
		// uploadGDrive: "uploadGDrive",
		adjustments: "adjustments",
		admission: "admission",
		age: "age",
		aie: "aie",
		allergies: "allergies",
		analyte: "analyte",
		antigen: "antigen",
		antigenCOI: "antigenCOI",
		assessment: "assessment",
		balance: "balance",
		cannotReadSMC: "cannotReadSMC",
		cashier: "cashier",
		certifiesThat: "certifiesThat",
		checkout: "checkout",
		chromatography: "chromatography",
		clinicalRecord: "clinicalRecord",
		covid19Lab: "covid19Lab",
		covid19Negative: "covid19Negative",
		covid19Positive: "covid19Positive",
		dateOfBirth: "dateOfBirth",
		days: "days",
		daysOfRest: "daysOfRest",
		depressionAssessment: "depressionAssessment",
		diagnosis: "diagnosis",
		discharge: "discharge",
		doctor: "doctor",
		doctorName: "doctorName",
		doctorOpinion: "doctorOpinion",
		doctorStandingOrder: "doctorStandingOrder",
		download: "download",
		eGene: "eGene",
		evaluation: "evaluation",
		firstAssessment: "firstAssessment",
		fluorescence: "fluorescence",
		gender: "gender",
		googleDrive: "googleDrive",
		hasExaminedOn: "hasExaminedOn",
		health: "health",
		healthCare: "healthCare",
		healthCarePlan: "healthCarePlan",
		healthHistory: "healthHistory",
		height: "height",
		hn: "hn",
		hospital: "hospital",
		hospitel: "hospitel",
		identification: "identification",
		intervention: "intervention",
		ipd: "ipd",
		lab: "lab",
		labNumber: "labNumber",
		letterOfConsent: "letterOfConsent",
		licenseNo: "licenseNo",
		md: "md",
		medicalCertificate: "medicalCertificate",
		medicationSheet: "medicationSheet",
		month: "month",
		nGene: "nGene",
		nameSurname: "nameSurname",
		nameTitle: "nameTitle",
		negative: "negative",
		newLab: "newLab",
		nurse: "nurse",
		nurseProgressNote: "nurseProgressNote",
		oGene: "oGene",
		objective: "objective",
		opdClinicalRecord: "opdClinicalRecord",
		pastIllnesses: "pastIllnesses",
		patient: "patient",
		patientId: "patientId",
		patientInfo: "patientInfo",
		pcr: "pcr",
		physicalExamination: "physicalExamination",
		plan: "plan",
		positive: "positive",
		printLabel: "printLabel",
		registerPatient: "registerPatient",
		registration: "registration",
		requestCovid19MedicalCertificate: "requestCovid19MedicalCertificate",
		result: "result",
		sGene: "sGene",
		searchPatient: "searchPatient",
		sequence: "sequence",
		service: "service",
		signature: "signature",
		smartCardReaderIsNotWorking: "smartCardReaderIsNotWorking",
		soap: "soap",
		subjective: "subjective",
		symptoms: "symptoms",
		theAboveNamedPersonRequires: "theAboveNamedPersonRequires",
		title: "title",
		underlyingDisease: "underlyingDisease",
		vitalSign: "vitalSign",
		weight: "weight",
		year: "year",
	},
}

export default Message
