import { ResourceAssistance } from "~/i18n"
import {
	SET_HOSPITEL_LAB_MODAL_APPROVE_RESULTS,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_ANALYTE,
	SET_HOSPITEL_LAB_MODAL_NEGATIVE_TEST_METHOD,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_ANALYTE,
	SET_HOSPITEL_LAB_MODAL_POSITIVE_TEST_METHOD,
	SET_HOSPITEL_LAB_MODAL_REGISTER_ADDRESS,
	SET_HOSPITEL_LAB_MODAL_REGISTER_DOB,
	SET_HOSPITEL_LAB_MODAL_REGISTER_FIRST_NAME,
	SET_HOSPITEL_LAB_MODAL_REGISTER_GENDER,
	SET_HOSPITEL_LAB_MODAL_REGISTER_IDENTIFICATION_NUMBER,
	SET_HOSPITEL_LAB_MODAL_REGISTER_LAST_NAME,
	SET_HOSPITEL_LAB_MODAL_REGISTER_NAME_TITLE,
	SET_HOSPITEL_LAB_MODAL_REGISTER_TEL,
	SET_HOSPITEL_LAB_PATIENTS,
	SET_HOSPITEL_LAB_PATIENT_LABS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setLabPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients
			.filter(
				(patient) =>
					String(patient.id).includes(getState().hospitel.lab.searchPatientId.trim().toLowerCase()) &&
					String(patient.identificationNumber).includes(getState().hospitel.lab.searchId.trim().toLowerCase())
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let color = []
		// if (getState().purchaseOrder.isDisplayAll) {
		// 	color = filtered.map((order) => {
		// 		let success = ResourceAssistance.CSS.Color.green
		// 		let isDisplay = order.inspectorApproved && order.approvalApproved
		// 		return [isDisplay, isDisplay ? success : ""]
		// 	})
		// } else {
		// 	filtered = filtered.filter((order) => !(order.inspectorApproved && order.approvalApproved))
		// }

		let body = filtered.map((patient, index) => {
			return [
				index + 1,
				Utils.formatDate(patient.creationDateTime),
				Utils.formatTime(patient.creationDateTime),
				patient.id,
				patient.identificationNumber,
			]
		})
		dispatch({
			type: SET_HOSPITEL_LAB_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
				rowColor: color,
			},
		})
		return Promise.resolve()
	}
}

export const setLabPatientLabs = (labs) => {
	return (dispatch, getState) => {
		let filtered = labs.sort((a, b) => Utils.sort(b.issuedDateTime, a.issuedDateTime))

		let rowColor = []
		rowColor = filtered.map((lab) => {
			let negative = ResourceAssistance.CSS.Color.green
			let positive = ResourceAssistance.CSS.Color.warning
			let cancelled = ResourceAssistance.CSS.Color.red
			let color = ""
			if (lab.cancelledBy) {
				color = cancelled
			} else if (
				lab.detail.result === ResourceAssistance.CONSTANT.POSITIVE ||
				lab.detail.result === ResourceAssistance.CONSTANT.DETECTED
			) {
				color = positive
			} else if (
				lab.detail.result === ResourceAssistance.CONSTANT.NEGATIVE ||
				lab.detail.result === ResourceAssistance.CONSTANT.NOT_DETECTED
			) {
				color = negative
			}

			return [true, color]
		})

		let body = filtered.map((lab) => {
			return [
				Utils.formatDate(lab.issuedDateTime),
				Utils.formatTime(lab.issuedDateTime),
				lab.id,
				lab.service.name,
				lab.detail.analyte,
				lab.detail.result,
				lab.status,
				lab.url ? true : false,
			]
		})
		dispatch({
			type: SET_HOSPITEL_LAB_PATIENT_LABS,
			payload: {
				original: labs,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setLabModalRegisterNameTitle = (title) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_NAME_TITLE,
		payload: {
			title: title,
		},
	}
}

export const setLabModalRegisterFirstName = (firstName) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_FIRST_NAME,
		payload: {
			firstName: firstName,
		},
	}
}

export const setLabModalRegisterLastName = (lastName) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_LAST_NAME,
		payload: {
			lastName: lastName,
		},
	}
}

export const setLabModalRegisterIdentificationNumber = (id) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_IDENTIFICATION_NUMBER,
		payload: {
			id: id,
		},
	}
}

export const setLabModalRegisterGender = (gender) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_GENDER,
		payload: {
			gender: gender,
		},
	}
}

export const setLabModalRegisterTel = (tel) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_TEL,
		payload: {
			tel: tel,
		},
	}
}

export const setLabModalRegisterAddress = (address) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_ADDRESS,
		payload: {
			address: address,
		},
	}
}

export const setLabModalRegisterDOB = (dateitme) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_REGISTER_DOB,
		payload: {
			datetime: dateitme,
		},
	}
}

export const setLabModalPositiveTestMethod = (method) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_POSITIVE_TEST_METHOD,
		payload: {
			method: method,
		},
	}
}

export const setLabModalPositiveAnalyte = (analyte) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_POSITIVE_ANALYTE,
		payload: {
			analyte: analyte,
		},
	}
}

export const setLabModalNegativeTestMethod = (method) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_NEGATIVE_TEST_METHOD,
		payload: {
			method: method,
		},
	}
}

export const setLabModalNegativeAnalyte = (analyte) => {
	return {
		type: SET_HOSPITEL_LAB_MODAL_NEGATIVE_ANALYTE,
		payload: {
			analyte: analyte,
		},
	}
}

export const setLabModalApproveResults = (results) => {
	return (dispatch, getState) => {
		let filtered = results

		let body = filtered.map((result) => {
			return [result.antigenCOI, result.egene, result.ngene, result.n2Gene, result.ogene, result.sgene]
		})
		dispatch({
			type: SET_HOSPITEL_LAB_MODAL_APPROVE_RESULTS,
			payload: {
				original: results,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
