import { Table, Typography } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setReportFinanceARAAccountsCodeGroup } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import ATable from "~/view/component/ant_table/ATable"

class ReportFinanceARATabAccountCode extends React.Component {
	componentDidMount() {
		this.props.setReportFinanceARAAccountsCodeGroup(this.props.filter.accountsReceivableAgings)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.filter.accountsReceivableAgings, this.props.filter.accountsReceivableAgings) ||
			!_.isEqual(prevProps.filter.filterDateTime, this.props.filter.filterDateTime) ||
			!_.isEqual(prevProps.filter.filterInsuranceCompanies, this.props.filter.filterInsuranceCompanies)
		) {
			this.props.setReportFinanceARAAccountsCodeGroup(this.props.filter.accountsReceivableAgings)
		}
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ATable
						pagination={false}
						data={this.props.filter.accountingCodeTable}
						expandable={{
							expandedRowRender: (row, idx) => {
								let selected = this.props.filter.accountingCodeTable.filtered[idx]
								let original = selected[1]
								let filtered = original.sort((a, b) => Utils.sort(a.paymentTermDateTime, b.paymentTermDateTime))
								let body = filtered.map((eachAR) => {
									return [
										Utils.formatDate(eachAR.paymentTermDateTime),
										eachAR.accountsReceivable.contractBillingChild.contractBilling.id,
										eachAR.accountsReceivable.debtPlan ? eachAR.accountsReceivable.debtPlan.id : "",
										eachAR.documentCode + "-" + eachAR.documentId,
										eachAR.insuranceCompany,
										Utils.formatDate(eachAR.accountsReceivable.creationDateTime),
										eachAR.invoice,
										eachAR.vid,
										eachAR.pid,
										eachAR.patient,
										Utils.formatNumWithComma(Utils.BigNumber(eachAR.total).toFixed(2)),
									]
								})
								let colStyle = body.map((each) => {
									return [
										[false, ""],
										[false, ""],
										[false, ""],
										[false, ""],
										[true, { textAlign: "left", whiteSpace: "pre-line" }],
										[false, ""],
										[true, { textAlign: "left" }],
										[false, ""],
										[false, ""],
										[false, ""],
										[true, { textAlign: "right" }],
									]
								})
								let width = ["110px", "130px", "100px", "130px", undefined, "100px", "130px", "80px", "80px", undefined, "120px"]
								return (
									<ATable
										pagination={false}
										data={{
											original: original,
											filtered: filtered,
											header: [
												ResourceAssistance.Message.dueDate,
												ResourceAssistance.Message.contractBilling,
												ResourceAssistance.Message.receiptId,
												ResourceAssistance.Message.documentNum,
												ResourceAssistance.Message.insuranceCompany,
												ResourceAssistance.Message.date,
												ResourceAssistance.Message.invoiceId,
												ResourceAssistance.Message.visitId,
												ResourceAssistance.Message.patientId,
												ResourceAssistance.Message.patientName,
												ResourceAssistance.Message.total,
											],
											body: body,
											colStyle: colStyle,
											width: width,
										}}
										footer={() => ""}
									/>
								)
							},
							expandRowByClick: true,
						}}
						summary={() => {
							let totalCurrent = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[3]))
							}, Utils.BigNumber(0))
							let total_1To30 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[4]))
							}, Utils.BigNumber(0))
							let total_31To90 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[5]))
							}, Utils.BigNumber(0))
							let total_91To180 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[6]))
							}, Utils.BigNumber(0))
							let total_181To360 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[7]))
							}, Utils.BigNumber(0))
							let totalOver360 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[8]))
							}, Utils.BigNumber(0))
							let total = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[9]))
							}, Utils.BigNumber(0))
							return (
								<Table.Summary fixed>
									<Table.Summary.Row>
										<Table.Summary.Cell index={0} colSpan={3} align="center">
											{translate(ResourceAssistance.Message.total)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={1} align="center">
											{Utils.formatNumWithComma(
												this.props.filter.accountingCodeTable.body
													.reduce((total, cur) => {
														return total.plus(Utils.formatNumberFromStr(cur[2]))
													}, Utils.BigNumber(0))
													.toNumber()
											)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={2} align="right">
											<Typography.Text style={{ color: totalCurrent.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(totalCurrent.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={3} align="right">
											<Typography.Text style={{ color: total_1To30.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_1To30.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={4} align="right">
											<Typography.Text style={{ color: total_31To90.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_31To90.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={5} align="right">
											<Typography.Text style={{ color: total_91To180.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_91To180.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={6} align="right">
											<Typography.Text style={{ color: total_181To360.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_181To360.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={7} align="right">
											<Typography.Text style={{ color: totalOver360.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(totalOver360.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={8} align="right">
											<Typography.Text style={{ color: total.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
									</Table.Summary.Row>
								</Table.Summary>
							)
						}}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.finance.accountsReceivableAging,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setReportFinanceARAAccountsCodeGroup,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportFinanceARATabAccountCode))
