import { SET_CMENU_SELECTABLE_ACCOUNTS_CODES, SET_CMENU_SELECTABLE_SELECTED_ACCOUNTING_CODE } from "~/redux/type"

const init = {
	accountingCodes: [],
	selectedAccountingCode: {
		index: -1,
		accountingCode: null,
	},
}

const selectableAccountsCodeReducer = (state = init, action) => {
	switch (action.type) {
		case SET_CMENU_SELECTABLE_ACCOUNTS_CODES:
			return Object.assign({}, state, {
				accountingCodes: action.payload.value,
			})
		case SET_CMENU_SELECTABLE_SELECTED_ACCOUNTING_CODE:
			return Object.assign({}, state, {
				selectedAccountingCode: {
					index: action.payload.index,
					accountingCode: action.payload.selected,
				},
			})
		default:
			return state
	}
}

export default selectableAccountsCodeReducer
