import _ from "lodash"
import React from "react"
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { AiFillWarning } from "react-icons/ai"
import { GrRotateLeft } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_AUTHORIZATION_AUTHORIZE_IND,
	SET_AUTHORIZATION_DISPLAY,
	SET_AUTHORIZATION_ID,
	SET_AUTHORIZATION_PRIVILEGE,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class Authorization extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Authorization",
			username: "",
			password: "",
		}

		this.onUsername = this.onUsername.bind(this)
		this.onPassword = this.onPassword.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onAuthorize = this.onAuthorize.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (!this.props.isDisplay) {
				this.props.setValue(SET_AUTHORIZATION_ID, "")
				this.props.setValue(SET_AUTHORIZATION_PRIVILEGE, "")
				this.props.setValue(SET_AUTHORIZATION_AUTHORIZE_IND, false)
				this.setState({
					username: "",
					password: "",
				})
			}
		}
	}

	onUsername(e) {
		this.setState({
			username: Utils.trim(e.target.value),
		})
	}

	onPassword(e) {
		this.setState({
			password: Utils.trim(e.target.value),
		})
	}

	onClose(event) {
		this.props.setValue(SET_AUTHORIZATION_AUTHORIZE_IND, false)
		this.props.onModalDisplayAction(SET_AUTHORIZATION_DISPLAY, false)
	}

	onAuthorize(event) {
		event.preventDefault()
		event.stopPropagation()
		if (_.isEqual(this.props.login.user.username, this.state.username)) {
			alert(
				this.props.intl.formatMessage({
					id: ResourceAssistance.Warning.authorizationSameLoginUser,
				})
			)
		} else {
			let params = {
				method: "POST",
				url: ResourceAssistance.Url.authorization.getWitness,
				withCredentials: true,
				headers: {
					"content-type": "application/json",
				},
				data: {
					username: this.state.username,
					password: this.state.password,
				},
			}
			let callback = (res) => {
				if (_.isEmpty(res.data.users)) {
					alert(
						`${this.props.intl.formatMessage({
							id: ResourceAssistance.Warning.authorizationFailed,
						})}\n - ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.loginError })}`
					)
				} else if (!Utils.hasPrivilege(this.props.authorization.privilege, res.data.users[0].roles)) {
					alert(
						`${this.props.intl.formatMessage({
							id: ResourceAssistance.Warning.authorizationFailed,
						})}\n - ${this.props.intl.formatMessage({ id: ResourceAssistance.Warning.permissionDenied })}`
					)
				} else {
					this.props.setValue(SET_AUTHORIZATION_AUTHORIZE_IND, true)
					this.props.onModalDisplayAction(SET_AUTHORIZATION_DISPLAY, false)
				}
			}
			let errorHandler = (error) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			let reqInterceptor = (config) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			}
			let resInterceptor = (response) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		}
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				show={this.props.isDisplay}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.sm}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onAuthorize}
				>
					<ModalHeader className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>{translate(ResourceAssistance.Message.authorization)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<Row>
								<Col>
									<GInput
										required
										style={{ textAlign: "center" }}
										type={ResourceAssistance.FormControl.type.text}
										placeholder={translate(ResourceAssistance.Message.username)}
										onChange={this.onUsername}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<GInput
										required
										style={{ textAlign: "center" }}
										type={ResourceAssistance.FormControl.type.password}
										placeholder={translate(ResourceAssistance.Message.password)}
										onChange={this.onPassword}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.red}
							disabled={_.isEmpty(this.state.username) || _.isEmpty(this.state.password)}
							type={ResourceAssistance.Button.type.submit}
						>
							<AiFillWarning size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.authorize)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAuthorizationDisplay,
	login: state.login,
	authorization: state.component.authorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Authorization))
