import { Drawer } from "antd"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setValue } from "~/redux/action"
import { SET_TIMELINE_DISPLAY, SET_TIMELINE_PATIENT, SET_TIMELINE_SEARCH_PATIENT_DISPLAY } from "~/redux/type"
import TimelineDetails from "./TimelineDetails"

class Timeline extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Timeline",
		}

		this.onClose = this.onClose.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay && !this.props.timeline.patient) {
				this.props.onModalDisplayAction(SET_TIMELINE_SEARCH_PATIENT_DISPLAY, true)
			}
		}
		if (prevProps.modal.isMenuTimelineSearchDisplay !== this.props.modal.isMenuTimelineSearchDisplay) {
			if (!this.props.modal.isMenuTimelineSearchDisplay && !this.props.timeline.patient) {
				this.props.onModalDisplayAction(SET_TIMELINE_DISPLAY, false)
			}
		}
	}

	reset() {
		this.props.setValue(SET_TIMELINE_PATIENT, null)
		this.props.onModalDisplayAction(SET_TIMELINE_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	render() {
		if (!this.props.timeline.patient) {
			return <></>
		}
		return (
			<Drawer
				styles={{
					body: {
						paddingLeft: 0,
						paddingRight: 0,
					},
				}}
				width={600}
				mask={false}
				maskClosable={false}
				title={translate(ResourceAssistance.Message.timeline)}
				onClose={this.onClose}
				open={this.props.isDisplay}
			>
				<TimelineDetails />
			</Drawer>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isMenuTimelineDisplay,
	modal: state.modal,
	timeline: state.menu.timeline,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
