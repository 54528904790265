import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_PS_COMPLETE_PAYMENT_PLANS,
	SET_PS_GL_GROUPS,
	SET_PS_INVOICES,
	SET_PS_MODAL_NEW_INVOICES,
	SET_PS_MODAL_NEW_PENDING_INVOICES,
	SET_PS_MODAL_PAYMENT_PLAN_INVOICES,
	SET_PS_MODAL_PAYMENT_PLAN_PENDING_INVOICES,
	SET_PS_PAYMENT_PLANS,
	SET_PS_PAYMENT_SLIPS,
	SET_PS_REQUESTS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setPSRequests = (requests) => {
	return (dispatch, getState) => {
		let filtered = requests
		if (_.includes(getState().accounting.paymentSlip.filters, ResourceAssistance.Message.incomplete)) {
			filtered = filtered.filter((request) => !request.complete)
		}
		filtered = filtered
			.filter((request) => request.id.includes(getState().accounting.paymentSlip.filterPaymentSlipId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((request) => {
			return [Utils.formatDate(request.creationDateTime), request.id]
		})

		let rowColor = filtered.map((request) => {
			let color = ""
			if (request.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		dispatch({
			type: SET_PS_REQUESTS,
			payload: {
				original: requests,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPSSuppliers = (paymentSlips) => {
	return (dispatch, getState) => {
		let filtered = paymentSlips.sort((a, b) => Utils.sort(a.supplierCode, b.supplierCode))

		let body = filtered.map((ps) => {
			return [
				ps.supplierCode,
				ps.supplierName,
				ps.supplierBankAccount,
				ps.supplierBankCode,
				ps.supplierPhone,
				ps.supplierAddress,
				ps.supplierTaxId,
				[ps.invoices.some((each) => !each.paymentPlan)],
			]
		})

		let rowColor = filtered.map((ps) => {
			let color = ""
			if (ps.invoices.some((invoice) => !invoice.paymentPlan) || ps.paymentPlans.some((paymentPlan) => !paymentPlan.approver)) {
				color = ResourceAssistance.CSS.Color.warning
			} else {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		dispatch({
			type: SET_PS_PAYMENT_SLIPS,
			payload: {
				original: paymentSlips,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPSIncompletePaymentPlans = (plans) => {
	return (dispatch, getState) => {
		let filtered = plans.sort((a, b) => Utils.sort(a.paymentTermDateTime, b.paymentTermDateTime))

		let body = filtered.map((plan) => {
			let invoices = getState().accounting.paymentSlip.selectedSupplier.supplier.invoices.filter(
				(invoice) => invoice.paymentPlan && invoice.paymentPlan.id === plan.id
			)
			let credit = invoices.reduce((total, cur) => {
				return total.plus(cur.taxedTotalPrice)
			}, Utils.BigNumber(0))

			let debit = invoices.reduce((total, cur) => {
				return cur.receiveInvoice
					? total.plus(
							cur.accountsPayableGLs
								.filter((gl) => !_.isEmpty(gl.credit) && !gl.excludePaymentSlip)
								.reduce((apTotal, curAP) => {
									return apTotal.plus(curAP.credit)
								}, Utils.BigNumber(0))
					  )
					: cur.purchaseInvoice
					? total.plus(
							cur.purchaseInvoiceGLs
								.filter((gl) => !_.isEmpty(gl.credit))
								.reduce((piTotal, curPI) => {
									return piTotal.plus(curPI.credit)
								}, Utils.BigNumber(0))
					  )
					: total.plus(cur.taxedTotalPrice)
			}, Utils.BigNumber(0))

			return [
				plan.id,
				Utils.formatDate(plan.paymentTermDateTime),
				plan.paymentMethod,
				plan.bankAccount,
				!_.isEmpty(plan.bankAccount) ? plan.bankCode + "-" + plan.bankName : "",
				!_.isEmpty(plan.bankAccount) ? plan.bankBranchCode + "-" + plan.bankBranchName : "",
				Utils.formatNumWithComma(Utils.BigNumber(plan.amount).toFixed(2)),
				plan.remark,
				[
					!plan.creator,
					!plan.creator,
					!plan.creator && !invoices.some((invoice) => _.isEmpty(invoice.accountingCode)) && credit.eq(debit),
					plan.creator && !plan.inspector,
					plan.inspector && !plan.approver,
					!plan.approver && (plan.creator || plan.inspector),
					plan.approver,
				],
			]
		})

		let isDisplayDropDownItems = filtered.map((paymentPlan) => {
			return [paymentPlan.approver, !_.isEmpty(paymentPlan.certificates)]
		})

		let rowColor = filtered.map((paymentPlan) => {
			let color = ""
			if (paymentPlan.approver) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PS_PAYMENT_PLANS,
			payload: {
				original: plans,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
				isDisplayDropDownItems: isDisplayDropDownItems,
			},
		})
		return Promise.resolve()
	}
}

export const setPSCompletePaymentPlans = (plans) => {
	return (dispatch, getState) => {
		let filtered = plans
			.filter((plan) => plan.financialRequest && plan.financialRequest.complete)
			.sort((a, b) => Utils.sort(a.paymentTermDateTime, b.paymentTermDateTime))

		let body = filtered.map((plan) => {
			return [
				plan.id,
				Utils.formatDate(plan.paymentTermDateTime),
				plan.paymentMethod,
				plan.bankAccount,
				!_.isEmpty(plan.bankAccount) ? plan.bankCode + "-" + plan.bankName : "",
				!_.isEmpty(plan.bankAccount) ? plan.bankBranchCode + "-" + plan.bankBranchName : "",
				Utils.formatNumWithComma(Utils.BigNumber(plan.amount).toFixed(2)),
				plan.remark,
				plan.financialRequest.id,
				[
					true,
					!plan.financialOperationInspected,
					plan.financialOperationInspected && !plan.financialOperationApproved,
					plan.financialOperationInspected && !plan.financialOperationApproved,
					plan.financialOperationApproved,
				],
			]
		})

		let rowColor = filtered.map((paymentPlan) => {
			let color = ""
			if (paymentPlan.financialOperationApproved) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PS_COMPLETE_PAYMENT_PLANS,
			payload: {
				original: plans,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPSInvoices = (invoices) => {
	return (dispatch, getState) => {
		let selectedPaymentPlan =
			getState().accounting.paymentSlip.selectedPaymentPlan.paymentPlan || getState().accounting.paymentSlip.selectedCompletePaymentPlan.paymentPlan
		let isPaymentPlanCompleteTab = getState().accounting.paymentSlip.isPaymentPlanCompleteTab

		let filtered = invoices
			.filter((invoice) => {
				if (selectedPaymentPlan) {
					return invoice.paymentPlan && invoice.paymentPlan.id === selectedPaymentPlan.id
				} else {
					return isPaymentPlanCompleteTab
						? invoice.paymentPlan && invoice.paymentPlan.financialRequest && invoice.paymentPlan.financialRequest.complete
						: !invoice.paymentPlan
				}
			})
			.sort((a, b) => Utils.sort(a.receivedDateTime, b.receivedDateTime))

		let body = filtered.map((invoice) => {
			return [
				invoice.receiveInvoice ? Utils.formatDate(invoice.receivedDateTime) : Utils.formatDate(invoice.creationDateTime),
				invoice.receiveInvoice ? "RC-" + invoice.receive.id : invoice.purchaseInvoice ? "PI-" + invoice.id : "PV-" + invoice.id,
				invoice.invoice,
				invoice.payment,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
				[!invoice.paymentPlan],
			]
		})

		let rowColor = filtered.map((invoice) => {
			let color = ""
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PS_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPSGLGroups = (invoices) => {
	return (dispatch, getState) => {
		let selectedPaymentPlan =
			getState().accounting.paymentSlip.selectedPaymentPlan.paymentPlan || getState().accounting.paymentSlip.selectedCompletePaymentPlan.paymentPlan
		let isPaymentPlanCompleteTab = getState().accounting.paymentSlip.isPaymentPlanCompleteTab

		let filteredInvoices = invoices.filter((invoice) => selectedPaymentPlan && invoice.paymentPlan && invoice.paymentPlan.id === selectedPaymentPlan.id)

		let filtered = Object.entries(
			Utils.groupBy(filteredInvoices, "accountingCode", "accountingChartName", "accountingTransactionDescription", "paymentPlan/paymentMethod")
		).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = []
		if (isPaymentPlanCompleteTab) {
			if (selectedPaymentPlan && selectedPaymentPlan.financialOperationApproved) {
				body = selectedPaymentPlan.paymentSlipGLs
					.filter((GL) => Number(GL.workflow) === ResourceAssistance.Enum.financePayment)
					.sort((a, b) => Utils.sort(a.credit, b.credit))
					.map((GL) => {
						return {
							color: GL.systemGenerated ? ResourceAssistance.CSS.Color.green : "",
							values: [
								GL.code,
								GL.name,
								GL.category,
								Utils.decodeAccountingTransDescr(
									GL.transactionDescription,
									getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
									Utils.formatDate(getState().accounting.paymentSlip.selectedSupplier.supplier.creationDateTime)
								),
								Utils.formatNumWithComma(Utils.BigNumber(GL.debit).toFixed(2)),
								Utils.formatNumWithComma(Utils.BigNumber(GL.credit).toFixed(2)),
								[false],
							],
						}
					})
			} else {
				body = filtered.map(([key, values]) => {
					let total = values.reduce((total, cur) => {
						return total.plus(cur.receiveInvoice ? cur.taxedTotalPrice : cur.withholdingTaxedTotalPrice)
					}, Utils.BigNumber(0))
					let keys = key.split("#")
					return {
						key: true,
						values: [
							keys.length === 1 ? [true] : keys[0],
							keys.length > 1 ? keys[1] : "",
							keys.length > 1 ? keys[3] : keys[0],
							keys.length > 1
								? Utils.decodeAccountingTransDescr(
										keys[2],
										getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
										Utils.formatDate(getState().accounting.paymentSlip.selectedSupplier.supplier.creationDateTime)
								  )
								: "",
							Utils.formatNumWithComma(total.toFixed(2)),
							Utils.BigNumber(0).toFixed(2),
							[keys.length > 1 && selectedPaymentPlan && !selectedPaymentPlan.approver],
						],
					}
				})
				// //withholding tax
				// let withholdingTaxJVs = selectedPaymentPlan
				// 	? selectedPaymentPlan.paymentSlipGLs.filter(
				// 			(GL) =>
				// 				Utils.BigNumber(GL.credit).gt(0) &&
				// 				Utils.BigNumber(GL.workflow).isEqualTo(ResourceAssistance.Enum.paymentPlan) &&
				// 				!filtered.some((key) => key[0].split("#").filter((each) => each)[0] === GL.code)
				// 	  )
				// 	: []
				// withholdingTaxJVs.forEach((JV) => {
				// 	filtered.push(["", []])
				// 	body.push({
				// 		color: "",
				// 		values: [
				// 			JV.code,
				// 			JV.name,
				// 			"",
				// 			JV.transactionDescription,
				// 			Utils.formatNumWithComma(Utils.BigNumber(JV.credit).toFixed(2)),
				// 			Utils.BigNumber(0).toFixed(2),
				// 			[false],
				// 		],
				// 	})
				// })
				// //Auto Generate GL from payment method
				// if (!_.isEmpty(filtered)) {
				// 	let purchases = filteredInvoices
				// 		.filter((invoice) => invoice.purchaseInvoice)
				// 		.reduce((ary, invoice) => {
				// 			return ary.concat(invoice.purchases.filter((purchase) => !_.isEmpty(purchase.withholdingTaxAccountingCode)))
				// 		}, [])
				// 	let groupedPurchases = Utils.groupBy(purchases, "withholdingTaxCode")
				// 	let selectedPaymentType = getState().accounting.paymentSlip.paymentTypes.find(
				// 		(each) => each.displayName.toLowerCase() === selectedPaymentPlan.paymentMethod.toLowerCase()
				// 	)
				// 	let selectedBranch = getState()
				// 		.accounting.paymentSlip.banks.reduce((ary, cur) => {
				// 			return ary.concat(cur.branches)
				// 		}, [])
				// 		.find((branch) => branch.code === selectedPaymentPlan.bankBranchCode && branch.displayName === selectedPaymentPlan.bankBranchName)
				// 	let withholdingTaxes = getState().accounting.paymentSlip.withholdingTaxes.filter((wht) =>
				// 		Object.keys(groupedPurchases).some((keys) => keys.split("#").filter((each) => each)[0] === wht.code)
				// 	)
				// 	let APs = getState().accounting.paymentSlip.accountsPayableActivities
				// 	APs.forEach((each) => {
				// 		if (
				// 			selectedPaymentType.activities.some((typeActivity) => typeActivity.chartOfAccounts.id === each.chartOfAccounts.id) ||
				// 			selectedBranch.activities.some((branchActivity) => branchActivity.chartOfAccounts.id === each.chartOfAccounts.id) ||
				// 			withholdingTaxes.some((wht) => wht.activities.some((whtActivity) => whtActivity.chartOfAccounts.id === each.chartOfAccounts.id))
				// 		) {
				// 			let total = Utils.BigNumber(0)
				// 			if (each.includeBalance) {
				// 				total = total.plus(selectedPaymentPlan.amount).minus(selectedPaymentPlan.withholdingTaxAmount)
				// 			}
				// 			if (each.includeTransactionFee) {
				// 				total = total.minus(selectedPaymentPlan.fee)
				// 			}
				// 			if (each.includeWithholdingTax) {
				// 				let selectedWHT = withholdingTaxes.find((wht) =>
				// 					wht.activities.some((whtActivity) => whtActivity.chartOfAccounts.id === each.chartOfAccounts.id)
				// 				)
				// 				total = total.plus(
				// 					groupedPurchases[selectedWHT.code].reduce((total, purchase) => {
				// 						return total.plus(
				// 							Utils.BigNumber(purchase.taxedTotalPrice)
				// 								.dividedBy(1 + purchase.tax / 100)
				// 								.times(purchase.withholdingTax / 100)
				// 						)
				// 					}, Utils.BigNumber(0))
				// 				)
				// 			}
				// 			if (total.abs().isGreaterThan(0)) {
				// 				if (each.debit) {
				// 					filtered.unshift(["", filteredInvoices])
				// 					body.unshift({
				// 						color: ResourceAssistance.CSS.Color.green,
				// 						values: [
				// 							each.chartOfAccounts.fullCode,
				// 							each.chartOfAccounts.displayName,
				// 							"",
				// 							Utils.decodeAccountingTransDescr(
				// 								each.description,
				// 								getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
				// 								Utils.formatDate(selectedPaymentPlan.financialRequest.supplierReceivedDateTime)
				// 							),
				// 							each.debit ? total.abs().toFixed(2) : Utils.BigNumber(0).toFixed(2),
				// 							each.credit ? total.abs().toFixed(2) : Utils.BigNumber(0).toFixed(2),
				// 							[false],
				// 						],
				// 					})
				// 				} else {
				// 					filtered.push(["", filteredInvoices])
				// 					body.push({
				// 						color: ResourceAssistance.CSS.Color.green,
				// 						values: [
				// 							each.chartOfAccounts.fullCode,
				// 							each.chartOfAccounts.displayName,
				// 							"",
				// 							Utils.decodeAccountingTransDescr(
				// 								each.description,
				// 								getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
				// 								Utils.formatDate(selectedPaymentPlan.financialRequest.supplierReceivedDateTime)
				// 							),
				// 							each.debit ? total.abs().toFixed(2) : Utils.BigNumber(0).toFixed(2),
				// 							each.credit ? total.abs().toFixed(2) : Utils.BigNumber(0).toFixed(2),
				// 							[false],
				// 						],
				// 					})
				// 				}
				// 			}
				// 		}
				// 	})
				// }
				if (selectedPaymentPlan) {
					filtered.push(["", filteredInvoices])
					body.push({
						color: "",
						values: [
							selectedPaymentPlan.financialRequest.accountingCode,
							selectedPaymentPlan.financialRequest.accountingChartName,
							"",
							Utils.decodeAccountingTransDescr(
								selectedPaymentPlan.financialRequest.accountingTransDescr,
								getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
								Utils.formatDate(selectedPaymentPlan.financialRequest.fileLastModifiedDateTime)
							),
							Utils.BigNumber(0).toFixed(2),
							Utils.formatNumWithComma(Utils.BigNumber(selectedPaymentPlan.amount).minus(selectedPaymentPlan.withholdingTaxAmount).toFixed(2)),
							[!selectedPaymentPlan.financialOperationInspected],
						],
					})
				}
			}
		} else {
			//credit
			body = filtered.map(([key, values]) => {
				let total = values.reduce((total, invoice) => {
					return total.plus(invoice.receiveInvoice ? invoice.taxedTotalPrice : invoice.withholdingTaxedTotalPrice)
				}, Utils.BigNumber(0))
				let keys = key.split("#")
				return {
					color: "",
					values: [
						keys.length === 1 ? [true] : keys[0],
						keys.length > 1 ? keys[1] : "",
						keys.length > 1 ? keys[3] : keys[0],
						keys.length > 1
							? Utils.decodeAccountingTransDescr(
									keys[2],
									getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
									Utils.formatDate(getState().accounting.paymentSlip.selectedSupplier.supplier.creationDateTime)
							  )
							: "",
						Utils.BigNumber(0).toFixed(2),
						Utils.formatNumWithComma(total.toFixed(2)),
						[keys.length > 1 && selectedPaymentPlan && !selectedPaymentPlan.creator],
					],
				}
			})

			//withholding tax
			let purchases = filteredInvoices
				.filter((invoice) => invoice.purchaseInvoice || invoice.paymentVoucher)
				.reduce((ary, invoice) => {
					return ary.concat(invoice.purchases.filter((purchase) => !_.isEmpty(purchase.withholdingTaxAccountingCode)))
				}, [])
			Object.entries(
				Utils.groupBy(purchases, "withholdingTaxAccountingCode", "withholdingTaxAccountingChartName", "withholdingTaxAccountingTransDescr")
			).forEach(([key, values]) => {
				let keys = key.split("#")
				let total = values.reduce((total, purchase) => {
					return total.plus(
						Utils.BigNumber(purchase.taxedTotalPrice)
							.dividedBy(1 + purchase.tax / 100)
							.times(purchase.withholdingTax / 100)
					)
				}, Utils.BigNumber(0))
				filtered.push([
					{
						key: key,
						isPurchaseInvoice: true,
					},
					values,
				])
				body.push({
					color: "",
					values: [
						keys[0],
						keys[1],
						"",
						Utils.decodeAccountingTransDescr(
							keys[2],
							getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
							Utils.formatDate(getState().accounting.paymentSlip.selectedSupplier.supplier.creationDateTime)
						),
						Utils.BigNumber(0).toFixed(2),
						Utils.formatNumWithComma(total.toFixed(2)),
						[false],
					],
				})
			})

			//debit
			let GLs = filteredInvoices
				.reduce((ary, cur) => {
					if (cur.receiveInvoice) {
						return ary.concat(cur.accountsPayableGLs.filter((apGL) => !apGL.excludePaymentSlip))
					} else if (cur.purchaseInvoice) {
						return ary.concat(cur.purchaseInvoiceGLs)
					} else {
						return ary.concat(
							cur.purchases.map((purchase) => {
								return {
									code: purchase.accountingCode,
									name: purchase.accountingChartName,
									transactionDescription: purchase.accountingTransDescr,
									credit: purchase.taxedTotalPrice,
								}
							})
						)
					}
				}, [])
				.filter((each) => Utils.BigNumber(each.credit).isGreaterThan(0))
			Object.entries(Utils.groupBy(GLs, "code", "name", "transactionDescription")).forEach(([key, values]) => {
				let keys = key.split("#")
				let total = values.reduce((total, cur) => {
					return total.plus(cur.credit)
				}, Utils.BigNumber(0))
				filtered.unshift([key, values])
				body.unshift({
					color: "",
					values: [
						keys[0],
						keys[1],
						"",
						Utils.decodeAccountingTransDescr(
							keys[2],
							getState().accounting.paymentSlip.selectedSupplier.supplier.supplierName,
							Utils.formatDate(getState().accounting.paymentSlip.selectedSupplier.supplier.creationDateTime)
						),
						Utils.formatNumWithComma(total.toFixed(2)),
						Utils.BigNumber(0).toFixed(2),
						[false],
					],
				})
			})
		}

		let rowColor = body.map((each) => {
			return [!_.isEmpty(each.color), each.color]
		})

		let colStyle = body.map(() => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PS_GL_GROUPS,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body.map((each) => each.values),
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPSModalNewInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
			.filter(
				(invoice) =>
					invoice.invoice.includes(getState().accounting.paymentSlip.modalNew.filterInvoiceNum) &&
					((invoice.receiveInvoice && invoice.receive.id.includes(getState().accounting.paymentSlip.modalNew.filterInvoiceId)) ||
						invoice.id.includes(getState().accounting.paymentSlip.modalNew.filterInvoiceId)) &&
					!getState().accounting.paymentSlip.modalNew.pendingInvoiceTable.original.some(
						(pendingInvoice) =>
							pendingInvoice.id === invoice.id &&
							pendingInvoice.receiveInvoice === invoice.receiveInvoice &&
							pendingInvoice.purchaseInvoice === invoice.purchaseInvoice &&
							pendingInvoice.paymentVoucher === invoice.paymentVoucher
					)
			)
			.sort((a, b) => Utils.sort(a.receivedDateTime, b.receivedDateTime))

		let body = filtered.map((invoice) => {
			return [
				invoice.receiveInvoice ? Utils.formatDate(invoice.receivedDateTime) : Utils.formatDate(invoice.creationDateTime),
				invoice.receiveInvoice ? "RC-" + invoice.receive.id : invoice.purchaseInvoice ? "PI-" + invoice.id : "PV-" + invoice.id,
				invoice.invoice,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_PS_MODAL_NEW_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPSModalNewPendingInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
		let body = filtered.map((invoice) => {
			return [
				invoice.receiveInvoice ? Utils.formatDate(invoice.receivedDateTime) : Utils.formatDate(invoice.creationDateTime),
				invoice.receiveInvoice ? "RC-" + invoice.receive.id : invoice.purchaseInvoice ? "PI-" + invoice.id : "PV-" + invoice.id,
				invoice.invoice,
				invoice.supplierCode,
				invoice.supplierName,
				invoice.supplierBankAccount,
				invoice.supplierBankCode,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_PS_MODAL_NEW_PENDING_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPSModalPaymentPlanInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
			.filter((invoice) => {
				return (
					!getState().accounting.paymentSlip.modalPaymentPlan.pendingInvoiceTable.original.some(
						(pendingInvoice) =>
							pendingInvoice.id === invoice.id &&
							pendingInvoice.receiveInvoice === invoice.receiveInvoice &&
							pendingInvoice.purchaseInvoice === invoice.purchaseInvoice &&
							pendingInvoice.paymentVoucher === invoice.paymentVoucher
					) && !invoice.paymentPlan
				)
			})
			.sort((a, b) => Utils.sort(a.receivedDateTime, b.receivedDateTime))

		let body = filtered.map((invoice) => {
			return [
				invoice.receiveInvoice ? Utils.formatDate(invoice.receivedDateTime) : Utils.formatDate(invoice.creationDateTime),
				invoice.receiveInvoice ? "RC-" + invoice.receive.id : invoice.purchaseInvoice ? "PI-" + invoice.id : "PV-" + invoice.id,
				invoice.invoice,
				invoice.payment,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_PS_MODAL_PAYMENT_PLAN_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPSModalPaymentPlanPendingInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices

		let body = filtered.map((invoice) => {
			return [
				invoice.receiveInvoice ? Utils.formatDate(invoice.receivedDateTime) : Utils.formatDate(invoice.creationDateTime),
				invoice.receiveInvoice ? "RC-" + invoice.receive.id : invoice.purchaseInvoice ? "PI-" + invoice.id : "PV-" + invoice.id,
				invoice.invoice,
				invoice.payment,
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
			]
		})

		let colStyle = filtered.map((transaction) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_PS_MODAL_PAYMENT_PLAN_PENDING_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
