import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setReportPOByIdSearchId } from "~/redux/action"
import GInput from "~/view/component/input/GInput"

class ReportPOByIdFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onId = this.onId.bind(this)
	}

	componentWillUnmount() {
		this.props.setReportPOByIdSearchId("")
	}

	onId(event) {
		this.props.setReportPOByIdSearchId(event.target.value.trim())
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.id })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							onChange={this.onId}
							value={this.props.filter.searchId}
						/>
					</Col>
					<Col md={2} />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.po.reportById,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setReportPOByIdSearchId,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportPOByIdFilterDetails))
