import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPharmacyModalVerifyLots } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyModalVerifyItem extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			lots: [],
			isVerified: false,
		}

		this.onChecked = this.onChecked.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pharmacy.modalVerify.pharmacyInventory, this.props.pharmacy.modalVerify.pharmacyInventory)) {
			this.props.setPharmacyModalVerifyLots(
				this.props.pharmacy.modalVerify.pharmacyInventory.inventories.filter(
					(inventory) => inventory.unit === this.props.pharmacy.selectedDoctorOrder.doctorOrder.unit
				)
			)
		}

		if (!_.isEqual(prevState.lots, this.state.lots)) {
			let orderAmount = this.props.pharmacy.modalVerify.isDoctorOrder
				? Utils.calculateDoctorOrderQty(
						this.props.pharmacy.selectedDoctorOrder.doctorOrder,
						this.props.pharmacy.modalVerify.startDateTime,
						this.props.pharmacy.modalVerify.endDateTime
				  )
				: Utils.calculateNurseOrderQty(
						this.props.pharmacy.selectedDoctorOrder.doctorOrder,
						this.props.pharmacy.modalVerify.startDateTime,
						this.props.pharmacy.modalVerify.endDateTime
				  )
			let verifiedAmount = this.state.lots.reduce((total, cur) => {
				return total.plus(cur.lot.total)
			}, Utils.BigNumber(0))
			this.setState({
				isVerified: verifiedAmount.gte(orderAmount),
			})
		}

		if (!_.isEqual(prevState.isVerified, this.state.isVerified)) {
			this.props.onRenderParentCallback()
		}
	}

	componentWillUnmount() {
		this.props.setPharmacyModalVerifyLots([])
	}

	getDisabledLots() {
		let disabledArray = new Array(this.props.pharmacy.modalVerify.itemTable.filtered.length).fill(false)
		if (this.state.isVerified) {
			disabledArray = new Array(this.props.pharmacy.modalVerify.itemTable.filtered.length).fill(true)
			this.state.lots.forEach((each) => {
				disabledArray[each.index] = false
			})
		}
		return disabledArray
	}

	onChecked(event, row, rKey, cKey) {
		let modifiedLots = Object.assign([], this.state.lots)
		if (event.target.checked) {
			modifiedLots.push({
				index: rKey,
				lot: this.props.pharmacy.modalVerify.itemTable.filtered[rKey],
			})
		} else {
			modifiedLots = modifiedLots.filter((each) => !_.isEqual(each.lot.id, this.props.pharmacy.modalVerify.itemTable.filtered[rKey].id))
		}
		this.setState({
			lots: modifiedLots,
		})
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col style={{ minHeight: "200px", maxHeight: "200px" }}>
					<ScrollableTable
						enableOverlay
						striped
						displayCheckBox
						onChecked={this.onChecked}
						isCheckBoxDisabledArray={this.getDisabledLots()}
						data={this.props.pharmacy.modalVerify.itemTable}
						onClick={this.onSelectVerified}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPharmacyModalVerifyLots,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PharmacyModalVerifyItem)
