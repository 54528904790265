import moment from "moment"
import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { FormattedMessage } from "react-intl"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class Adjustment extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isEndDateChecked: false,
			qtyPerDay: "",
			startDateTime: moment().startOf("day").valueOf(),
			endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
			notes: "",
		}

		this.onCheckEndDate = this.onCheckEndDate.bind(this)
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onQty = this.onQty.bind(this)
		this.onNote = this.onNote.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.startDateTime !== this.state.startDateTime) {
			this.setState({
				endDateTime: moment(this.state.startDateTime).endOf("d").valueOf(),
			})
		}
	}

	onCheckEndDate(event) {
		this.setState({
			isEndDateChecked: event.target.checked,
		})
	}

	onStartDate(date) {
		this.setState({
			startDateTime: date.getTime(),
		})
	}

	onEndDate(date) {
		this.setState({
			endDateTime: date.getTime(),
		})
	}

	onQty(event) {
		this.setState({
			qtyPerDay: Number(event.target.value),
		})
	}

	onNote(event) {
		this.setState({
			notes: Utils.trim(event.target.value),
		})
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0 label-info-row"} style={{ justifyContent: "center" }}>
					<Col md={"auto"}>
						<DatePicker
							isShowTime={false}
							startDate={Utils.generateDateFromLong(this.state.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md={"auto"}>
						<InputGroup size={"sm"}>
							<InputGroup.Checkbox checked={this.state.isEndDateChecked} onChange={this.onCheckEndDate} />
							<DatePicker
								isShowTime={false}
								minDate={Utils.generateDateFromLong(this.state.startDateTime)}
								showDisabledMonthNavigation
								disabled={!this.state.isEndDateChecked}
								startDate={Utils.generateDateFromLong(this.state.endDateTime)}
								onChange={this.onEndDate}
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<InputGroup size={"sm"}>
							<InputGroup.Text>{translate(ResourceAssistance.Message.qty)}</InputGroup.Text>
							<FormControl
								required
								autoFocus
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.number}
								step={1}
								min={1}
								value={this.state.qtyPerDay}
								onChange={this.onQty}
							/>
							<InputGroup.Text>/{translate(ResourceAssistance.Message.day)}</InputGroup.Text>
						</InputGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.note}>
							{(placeholder) => (
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									placeholder={placeholder}
									value={this.state.notes}
									onChange={this.onNote}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default Adjustment
