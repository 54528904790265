import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setAccountsReceiveReceipts, setSelected } from "~/redux/action"
import { SET_ARC_SELECTED_RECEIPT } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceiveLeftReceipt extends React.Component {
	constructor(props) {
		super(props)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ARC.receiptTable.filtered, this.props.ARC.receiptTable.filtered)) {
			let index = this.props.ARC.selectedReceipt.receipt
				? this.props.ARC.receiptTable.filtered.findIndex((receipt) => receipt.id === this.props.ARC.selectedReceipt.receipt.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_ARC_SELECTED_RECEIPT, index, this.props.ARC.receiptTable.filtered[index])
			} else {
				this.props.setSelected(SET_ARC_SELECTED_RECEIPT, -1, null)
			}
		}
		if (!_.isEqual(prevProps.ARC.filterReceiptId, this.props.ARC.filterReceiptId) || !_.isEqual(prevProps.ARC.filters, this.props.ARC.filters)) {
			this.props.setAccountsReceiveReceipts(this.props.ARC.receiptTable.original)
		}
	}

	onSelectRow(plan, index) {
		this.props.setSelected(SET_ARC_SELECTED_RECEIPT, index, this.props.ARC.receiptTable.filtered[index])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.ARC.receiptTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.ARC.selectedReceipt.index === -1}
						highlightedRow={this.props.ARC.selectedReceipt.index}
					/>
					{this.props.ARC.receiptTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAccountsReceiveReceipts,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceiveLeftReceipt))
