import { Checkbox } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_AP_MODAL_SEARCH_ALL_INCOMPLETE, SET_AP_MODAL_SEARCH_RECEIVE_ID } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class APModalSearchDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onReceiveID = this.onReceiveID.bind(this)
		this.onSearchAll = this.onSearchAll.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_AP_MODAL_SEARCH_RECEIVE_ID, "")
		this.props.setValue(SET_AP_MODAL_SEARCH_ALL_INCOMPLETE, false)
	}

	onReceiveID(e) {
		this.props.setValue(SET_AP_MODAL_SEARCH_RECEIVE_ID, Utils.trim(e.target.value))
	}

	onSearchAll(e) {
		this.props.setValue(SET_AP_MODAL_SEARCH_ALL_INCOMPLETE, e.target.checked)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.id })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							disabled={this.props.AP.modalSearch.isSearchAllIncomplete}
							onChange={this.onReceiveID}
							value={this.props.AP.modalSearch.searchReceiveId}
						/>
					</Col>
					<Col md={2} />
				</Row>
				<Row>
					<Col />
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.or)}</label>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<Checkbox
							style={{ flexDirection: "row" }}
							disabled={this.props.AP.modalSearch.searchReceiveId}
							checked={this.props.AP.modalSearch.isSearchAllIncomplete}
							onChange={this.onSearchAll}
						>
							{translate(ResourceAssistance.Message.searchIncomplete)}
						</Checkbox>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	AP: state.accounting.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(APModalSearchDetails))
