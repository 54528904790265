import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmUSelectedUnit, setAdmUUnits, setLoadingAction } from "~/redux/action"
import { SET_ADM_UNIT_NEW_UNIT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import AdmUModalNUDetails from "./AdmUModalNUDetails"

class AdmUModalNU extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmUModalNU",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.unit.selectedUnit.unit, {
			...this.props.unit.selectedUnit.unit,
			displayName: this.props.unit.modalNewUnit.name.trim(),
			description: this.props.unit.modalNewUnit.description.trim(),
			active: this.props.unit.modalNewUnit.isActive,
		})
		return _.isEqual(this.props.unit.selectedUnit.unit, target)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_UNIT_NEW_UNIT_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.unit.saveUnit}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.unit.selectedUnit.index === -1 ? 0 : this.props.unit.selectedUnit.unit.id,
				name: this.props.unit.modalNewUnit.name.trim(),
				description: this.props.unit.modalNewUnit.description.trim(),
				active: this.props.unit.modalNewUnit.isActive,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			this.props.setAdmUSelectedUnit(-1, null)
			let units = this.props.unit.unitTable.original.filter((each) => each.id !== res.data.units[0].id)
			units.push(res.data.units[0])
			this.props.setAdmUUnits(units)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_UNIT_NEW_UNIT_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.newUnit)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						{/*  */}
						{<AdmUModalNUDetails />}
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmUNewUnitDisplay,
	login: state.login,
	unit: state.admin.itemConfig.unit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setAdmUUnits, setAdmUSelectedUnit }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUModalNU)
