import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmUDisplayAll,
	setAdmUModalNUActive,
	setAdmUModalNUDescription,
	setAdmUModalNUName,
	setAdmUSelectedUnit,
	setAdmUUnits,
	setSearchText,
} from "~/redux/action"
import { SET_ADMIN_UNIT_SEARCH_NAME, SET_ADM_UNIT_NEW_UNIT_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaRegEdit } from "react-icons/fa"
import { Divider } from "antd"

class AdmUnit extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentWillUnmount() {
		this.props.setSearchText(SET_ADMIN_UNIT_SEARCH_NAME, "")
		this.props.setAdmUDisplayAll(true)
		this.props.setAdmUSelectedUnit(-1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.unit.searchName !== this.props.unit.searchName ||
			prevProps.unit.displayInactive !== this.props.unit.displayInactive
		) {
			this.props.setAdmUSelectedUnit(-1, null)
			this.props.setAdmUUnits(this.props.unit.unitTable.original)
		}
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_UNIT_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmUDisplayAll(event.target.checked)
	}

	onAdd(event) {
		this.props.setAdmUSelectedUnit(-1, null)
		this.props.onModalDisplayAction(SET_ADM_UNIT_NEW_UNIT_DISPLAY, true)
	}

	onEdit(event) {
		this.props.setAdmUModalNUName(this.props.unit.selectedUnit.unit.displayName)
		this.props.setAdmUModalNUDescription(this.props.unit.selectedUnit.unit.description)
		this.props.setAdmUModalNUActive(this.props.unit.selectedUnit.unit.active)

		this.props.onModalDisplayAction(SET_ADM_UNIT_NEW_UNIT_DISPLAY, true)
	}

	onSelectRow(row, index) {
		this.props.setAdmUSelectedUnit(index, this.props.unit.unitTable.filtered[index])
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.unit.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.unit.selectedUnit.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.unit.selectedUnit.index === -1}
							data={this.props.unit.unitTable}
							onClick={this.onSelectRow}
						/>
						{this.props.unit.unitTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	unit: state.admin.itemConfig.unit,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmUDisplayAll,
			setAdmUModalNUActive,
			setAdmUModalNUDescription,
			setAdmUModalNUName,
			setAdmUSelectedUnit,
			setAdmUUnits,
			setSearchText,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUnit)
