import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_PGL_CHART_OF_ACCOUNTS,
	SET_PGL_CHART_OF_ACCOUNTS_LOADING,
	SET_PGL_END_DATE_TIME,
	SET_PGL_ENTRIES,
	SET_PGL_FILTERS,
	SET_PGL_FILTER_POSTING_GL_ID,
	SET_PGL_GL_DATE_TIME,
	SET_PGL_GL_DOCUMENTS,
	SET_PGL_GL_DOCUMENTS_LOADING,
	SET_PGL_LOCATION,
	SET_PGL_MODAL_ENTRY_AMOUNT,
	SET_PGL_MODAL_ENTRY_DEBIT_IND,
	SET_PGL_MODAL_ENTRY_DESCRIPTION,
	SET_PGL_MODAL_ENTRY_SELECTED_GL,
	SET_PGL_MODAL_LOCATION_ORGS,
	SET_PGL_MODAL_LOCATION_ORGS_LOADING,
	SET_PGL_MODAL_LOCATION_SELECTED_ORG,
	SET_PGL_MODAL_NEW_ENTRIES,
	SET_PGL_MODAL_NEW_REMARK,
	SET_PGL_MODAL_NEW_SELECTED_ENTRY,
	SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT,
	SET_PGL_MODAL_SEARCH_INCOMPLETE_IND,
	SET_PGL_MODAL_SEARCH_POSTING_GL_ID,
	SET_PGL_REQUESTS,
	SET_PGL_SELECTED_ENTRY,
	SET_PGL_SELECTED_PGL,
	SET_PGL_START_DATE_TIME,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	generalLedgerDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterPostingGLId: "",
	isChartOfAccountsLoading: false,
	isGLDocumentsLoading: false,
	filters: [],
	chartOfAccounts: [],
	glDocuments: [],
	selectedPGL: {
		index: -1,
		PGL: null,
	},
	selectedEntry: {
		index: -1,
		entry: null,
	},
	postingGLTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		colStyle: [],
		isLoading: false,
	},
	entryTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.debit,
			ResourceAssistance.Message.credit,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalNew: {
		remark: "",
		selectedGLDocument: {
			index: -1,
			document: null,
		},
		selectedEntry: {
			index: -1,
			entry: null,
		},
		entryTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.debit,
				ResourceAssistance.Message.credit,
				"",
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
	},
	modalEntry: {
		description: "",
		isDebit: true,
		amount: "",
		selectedGL: {
			index: -1,
			GL: null,
		},
	},
	modalSearch: {
		searchPostingGLId: "",
		isSearchIncomplete: false,
	},
}

const postingGLReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PGL_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_PGL_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_PGL_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_PGL_GL_DATE_TIME:
			return Object.assign({}, state, {
				generalLedgerDateTime: action.payload.value,
			})
		case SET_PGL_FILTERS:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_PGL_FILTER_POSTING_GL_ID:
			return Object.assign({}, state, {
				filterPostingGLId: action.payload.value,
			})
		case SET_PGL_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_PGL_CHART_OF_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				isChartOfAccountsLoading: action.payload.isLoading,
			})
		case SET_PGL_GL_DOCUMENTS:
			return Object.assign({}, state, {
				glDocuments: action.payload.value,
			})
		case SET_PGL_GL_DOCUMENTS_LOADING:
			return Object.assign({}, state, {
				isGLDocumentsLoading: action.payload.isLoading,
			})
		case SET_PGL_REQUESTS:
			return Object.assign({}, state, {
				postingGLTable: {
					...state.postingGLTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PGL_SELECTED_PGL:
			return Object.assign({}, state, {
				selectedPGL: {
					index: action.payload.index,
					PGL: action.payload.selected,
				},
			})
		case SET_PGL_ENTRIES:
			return Object.assign({}, state, {
				entryTable: {
					...state.entryTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_PGL_SELECTED_ENTRY:
			return Object.assign({}, state, {
				selectedEntry: {
					index: action.payload.index,
					entry: action.payload.selected,
				},
			})
		case SET_PGL_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_PGL_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_PGL_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedGLDocument: {
						index: action.payload.index,
						document: action.payload.selected,
					},
				},
			})
		case SET_PGL_MODAL_NEW_REMARK:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					remark: action.payload.value,
				},
			})
		case SET_PGL_MODAL_NEW_ENTRIES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					entryTable: {
						...state.modalNew.entryTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_PGL_MODAL_NEW_SELECTED_ENTRY:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedEntry: {
						index: action.payload.index,
						entry: action.payload.selected,
					},
				},
			})
		case SET_PGL_MODAL_ENTRY_SELECTED_GL:
			return Object.assign({}, state, {
				modalEntry: {
					...state.modalEntry,
					selectedGL: {
						index: action.payload.index,
						GL: action.payload.selected,
					},
				},
			})
		case SET_PGL_MODAL_ENTRY_DESCRIPTION:
			return Object.assign({}, state, {
				modalEntry: {
					...state.modalEntry,
					description: action.payload.value,
				},
			})
		case SET_PGL_MODAL_ENTRY_DEBIT_IND:
			return Object.assign({}, state, {
				modalEntry: {
					...state.modalEntry,
					isDebit: action.payload.value,
				},
			})
		case SET_PGL_MODAL_ENTRY_AMOUNT:
			return Object.assign({}, state, {
				modalEntry: {
					...state.modalEntry,
					amount: action.payload.value,
				},
			})
		case SET_PGL_MODAL_SEARCH_POSTING_GL_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchPostingGLId: action.payload.value,
				},
			})
		case SET_PGL_MODAL_SEARCH_INCOMPLETE_IND:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					isSearchIncomplete: action.payload.value,
				},
			})
		default:
			return state
	}
}
export default postingGLReducer
