import { Descriptions, Divider, Select, TreeSelect } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, ButtonGroup, Col, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { MdMoreHoriz } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setFOModalNewPaymentPlans,
	setLoadingAction,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_FO_MODAL_NEW_FILTER_PS_ID,
	SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE,
	SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME,
	SET_FO_MODAL_NEW_SEARCH_DUE_DATE,
	SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK,
	SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE,
	SET_FO_SEARCH_MORE_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import SearchBox from "~/view/component/search_box/SearchBox"

class FOModalNewSearchFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onDueDate = this.onDueDate.bind(this)
		this.onPaymentType = this.onPaymentType.bind(this)
		this.onBank = this.onBank.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onMore = this.onMore.bind(this)
		this.onFilterSupplierCode = this.onFilterSupplierCode.bind(this)
		this.onFilterSupplierName = this.onFilterSupplierName.bind(this)
		this.onFilterPaymentSlipId = this.onFilterPaymentSlipId.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.FO.modalNew.searchSelectedPaymentType, this.props.FO.modalNew.searchSelectedPaymentType)) {
			this.props.setSelected(SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_FO_MODAL_NEW_SEARCH_DUE_DATE, "")
		this.props.setSelected(SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE, -1, null)
		this.props.setSelected(SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK, -1, null)
		this.props.setValue(SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE, "")
		this.props.setValue(SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME, "")
		this.props.setValue(SET_FO_MODAL_NEW_FILTER_PS_ID, "")
	}

	onSearch() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.financialOperations.getPaymentPlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				orgId: this.props.FO.location.id,
				dueDate: this.props.FO.modalNew.searchDueDate ? this.props.FO.modalNew.searchDueDate : 0,
				paymentType: this.props.FO.modalNew.searchSelectedPaymentType.type
					? this.props.FO.modalNew.searchSelectedPaymentType.type.displayName
					: "",
				bankCode: this.props.FO.modalNew.searchSelectedBank.bank
					? this.props.FO.modalNew.banks[this.props.FO.modalNew.searchSelectedBank.index.split("-")[0]].code
					: "",
				branchCode: this.props.FO.modalNew.searchSelectedBank.bank
					? this.props.FO.modalNew.searchSelectedBank.bank.code
					: "",
			},
		}
		let callback = (res) => {
			this.props.setFOModalNewPaymentPlans(res.data.paymentPlans)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onDueDate(value) {
		if (value) {
			this.props.setValue(SET_FO_MODAL_NEW_SEARCH_DUE_DATE, moment(value).endOf("day").milliseconds(0).valueOf())
		} else {
			this.props.setValue(SET_FO_MODAL_NEW_SEARCH_DUE_DATE, "")
		}
	}

	onPaymentType(value) {
		if (value === undefined) {
			this.props.setSelected(SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE, -1, null)
			return
		}
		this.props.setSelected(
			SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE,
			value,
			this.props.FO.modalNew.paymentTypes[value]
		)
	}

	onBank(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK, -1, null)
			return
		}
		let entry = value.split("-")
		this.props.setSelected(
			SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK,
			value,
			this.props.FO.modalNew.banks[entry[0]].branches[entry[1]]
		)
	}

	onMore() {
		this.props.setSelected(SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE, -1, null)
		this.props.onModalDisplayAction(SET_FO_SEARCH_MORE_DISPLAY, true)
	}

	onFilterSupplierCode(e) {
		this.props.setValue(SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE, e.target.value)
	}

	onFilterSupplierName(e) {
		this.props.setValue(SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME, e.target.value)
	}

	onFilterPaymentSlipId(e) {
		this.props.setValue(SET_FO_MODAL_NEW_FILTER_PS_ID, e.target.value)
	}

	render() {
		return (
			<>
				<Row className={"g-2"}>
					<Col md={2}>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.dueDate })]}
							values={[
								_.isEmpty(this.props.FO.modalNew.searchDueDate)
									? undefined
									: moment(this.props.FO.modalNew.searchDueDate),
							]}
							onChange={this.onDueDate}
						/>
					</Col>
					<Col md={2}>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							placeholder={translate(ResourceAssistance.Message.payment)}
							loading={this.props.FO.modalNew.isPaymentTypeLoading}
							value={
								this.props.FO.modalNew.searchSelectedPaymentType.type
									? this.props.FO.modalNew.searchSelectedPaymentType.index
									: undefined
							}
							onChange={this.onPaymentType}
						>
							{Utils.renderSelects(this.props.FO.modalNew.paymentTypes, false)}
						</Select>
					</Col>
					{this.props.FO.modalNew.searchSelectedPaymentType.type &&
						this.props.FO.modalNew.searchSelectedPaymentType.type.bankAccount && (
							<Col md={2}>
								<TreeSelect
									style={{
										textAlign: "center",
									}}
									showSearch
									allowClear
									treeLine={{ showLeafIcon: false }}
									treeExpandAction="click"
									placeholder={translate(ResourceAssistance.Message.bank)}
									filterTreeNode={(input, treeNode) => {
										return treeNode.title.toLowerCase().includes(input)
									}}
									status={this.props.FO.modalNew.searchSelectedBank.bank ? undefined : "error"}
									value={
										this.props.FO.modalNew.searchSelectedBank.bank
											? this.props.FO.modalNew.searchSelectedBank.index
											: undefined
									}
									onChange={this.onBank}
									treeData={Utils.getTreeNodesFromBank(this.props.FO.modalNew.banks, true, false)}
								/>
							</Col>
						)}
					<Col md="auto">
						<ButtonGroup>
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								disabled={
									!this.props.FO.modalNew.searchDueDate &&
									(!this.props.FO.modalNew.searchSelectedPaymentType.type ||
										(this.props.FO.modalNew.searchSelectedPaymentType.type.bankAccount &&
											!this.props.FO.modalNew.searchSelectedBank.bank))
								}
								onClick={this.onSearch}
							>
								<GoSearch size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.search)}
							</Button>
							<Button variant={ResourceAssistance.Button.variant.info} onClick={this.onMore}>
								<MdMoreHoriz size={ResourceAssistance.ReactIcon.size} />
							</Button>
						</ButtonGroup>
					</Col>
					<Col md="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col>
						<SearchBox
							num={3}
							lableName=""
							controlTypes={[
								ResourceAssistance.FormControl.type.number,
								ResourceAssistance.FormControl.type.text,
								ResourceAssistance.FormControl.type.text,
							]}
							placeholders={[
								ResourceAssistance.Message.psId,
								ResourceAssistance.Message.supplierCode,
								ResourceAssistance.Message.supplierName,
							]}
							callbacks={[this.onFilterPaymentSlipId, this.onFilterSupplierCode, this.onFilterSupplierName]}
							values={[
								this.props.FO.modalNew.filterPaymentSlipId,
								this.props.FO.modalNew.filterSupplierCode,
								this.props.FO.modalNew.filterSupplierName,
							]}
						/>
					</Col>
				</Row>
				{this.props.FO.modalNew.searchSelectedPaymentType.type &&
					this.props.FO.modalNew.searchSelectedPaymentType.type.bankAccount &&
					this.props.FO.modalNew.searchSelectedBank.bank && (
						<Row>
							<Col md={2} />
							<Col md={6}>
								<Descriptions
									style={{ flex: "unset", display: "unset" }}
									contentStyle={{ fontStyle: "italic", fontWeight: "normal" }}
									column={3}
								>
									<Descriptions.Item label={translate(ResourceAssistance.Message.bank)}>
										{this.props.FO.modalNew.banks[this.props.FO.modalNew.searchSelectedBank.index.split("-")[0]].code}
									</Descriptions.Item>
									<Descriptions.Item label={translate(ResourceAssistance.Message.branch)}>
										{this.props.FO.modalNew.searchSelectedBank.bank.code}
									</Descriptions.Item>
									<Descriptions.Item label={translate(ResourceAssistance.Message.bankAccNum)}>
										{this.props.FO.modalNew.searchSelectedBank.bank.account}
									</Descriptions.Item>
								</Descriptions>
							</Col>
						</Row>
					)}
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
			onModalDisplayAction,
			setFOModalNewPaymentPlans,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FOModalNewSearchFilter))
