import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OR_MODAL_EDIT_ANE_PRE_LMP,
	SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE,
	SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT,
	SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_SMOKING,
	SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OperatingRoomModalEditPreHistory extends React.Component {
	constructor(props) {
		super(props)

		this.onLMP = this.onLMP.bind(this)
		this.onPregnant = this.onPregnant.bind(this)
		this.onPregantDetail = this.onPregantDetail.bind(this)
		this.onSmoking = this.onSmoking.bind(this)
		this.onSmokingDetail = this.onSmokingDetail.bind(this)
		this.onMedicationUsage = this.onMedicationUsage.bind(this)
		this.onMedicationUsageDetail = this.onMedicationUsageDetail.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let anesthesiaPreOP = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
			if (anesthesiaPreOP) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LMP, anesthesiaPreOP.lastMenstrualPeriod)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT, !_.isEmpty(anesthesiaPreOP.pregnant))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL, anesthesiaPreOP.pregnant)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING, !_.isEmpty(anesthesiaPreOP.smoking))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL, anesthesiaPreOP.smoking)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE, !_.isEmpty(anesthesiaPreOP.medicationUsage))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL, anesthesiaPreOP.medicationUsage)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isPregnant, this.props.operatingRoom.modalEditAnPre.isPregnant)) {
			if (!this.props.operatingRoom.modalEditAnPre.isPregnant) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isSmoking, this.props.operatingRoom.modalEditAnPre.isSmoking)) {
			if (!this.props.operatingRoom.modalEditAnPre.isSmoking) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isMedicationUsed, this.props.operatingRoom.modalEditAnPre.isMedicationUsed)) {
			if (!this.props.operatingRoom.modalEditAnPre.isMedicationUsed) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LMP, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL, "")
	}

	render() {
		return (
			<>
				<Row>
					<Col md={2} />
					<Col md="auto">
						<GInput
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastMenstrualPeriod })}
							value={this.props.operatingRoom.modalEditAnPre.LMP}
							onChange={this.onLMP}
						/>
					</Col>
					<Col md="auto">
						<label style={{ fontWeight: "bold", alignSelf: "center" }}>{translate(ResourceAssistance.Message.pregnancy)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isPregnant} onChange={this.onPregnant}>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<GInput
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={""}
							prefix={translate(ResourceAssistance.Message.ga)}
							suffix={translate(ResourceAssistance.Message.week)}
							disabled={!this.props.operatingRoom.modalEditAnPre.isPregnant}
							value={this.props.operatingRoom.modalEditAnPre.pregnantDetail}
							onChange={this.onPregantDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.smoking)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isSmoking} onChange={this.onSmoking}>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<GInput
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={""}
							suffix={translate(ResourceAssistance.Message.packPerDay)}
							disabled={!this.props.operatingRoom.modalEditAnPre.isSmoking}
							value={this.props.operatingRoom.modalEditAnPre.smokingDetail}
							onChange={this.onSmokingDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.medicationUsage)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isMedicationUsed} onChange={this.onMedicationUsage}>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={3}
							placeholder={""}
							disabled={!this.props.operatingRoom.modalEditAnPre.isMedicationUsed}
							value={this.props.operatingRoom.modalEditAnPre.medicationUsageDetail}
							onChange={this.onMedicationUsageDetail}
						/>
					</Col>
				</Row>
			</>
		)
	}

	onLMP(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LMP, e.target.value)
	}
	onPregnant(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT, e.target.value)
	}
	onPregantDetail(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL, e.target.value)
	}
	onSmoking(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING, e.target.value)
	}
	onSmokingDetail(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL, e.target.value)
	}
	onMedicationUsage(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE, e.target.value)
	}
	onMedicationUsageDetail(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPreHistory))
