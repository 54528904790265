import { Timeline } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgInpatient, SvgOutpatient } from "~/icons"
import { Utils } from "~/utils/Utils"

class TimelineActivities extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Timeline mode={"left"}>
						{this.props.timeline.patient.admissions.map((admission) => {
							let ary = []
							if (admission.clinicalRecord) {
								ary.push(
									<Timeline.Item
										name={admission.clinicalRecord.creationDateTime}
										color={"blue"}
										label={Utils.formatDateTime(admission.clinicalRecord.creationDateTime)}
									>
										{translate(ResourceAssistance.Message.registerOPD)}
									</Timeline.Item>
								)
								admission.clinicalRecord.medicalRecords
									.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
									.map((medicalRecord) => {
										ary.push(
											<Timeline.Item
												name={medicalRecord.appointmentDateTime}
												dot={<SvgOutpatient width={ResourceAssistance.ReactIcon.size} />}
												color={"gray"}
												label={Utils.formatDateTime(medicalRecord.appointmentDateTime)}
											>
												{translate(ResourceAssistance.Message.appointment)}{" "}
												{medicalRecord.department.branch.displayName}/{medicalRecord.department.displayName}
											</Timeline.Item>
										)
										if (medicalRecord.checkInDateTime) {
											ary.push(
												<Timeline.Item
													name={medicalRecord.checkInDateTime}
													dot={<SvgOutpatient width={ResourceAssistance.ReactIcon.size} />}
													color={"blue"}
													label={Utils.formatDateTime(medicalRecord.checkInDateTime)}
												>
													{translate(ResourceAssistance.Message.checkIn)} {medicalRecord.department.branch.displayName}/
													{medicalRecord.department.displayName}
												</Timeline.Item>
											)
										}
										if (medicalRecord.checkOutDateTime > 0) {
											ary.push(
												<Timeline.Item
													name={medicalRecord.checkOutDateTime}
													dot={<SvgOutpatient width={ResourceAssistance.ReactIcon.size} />}
													color={"green"}
													label={Utils.formatDateTime(medicalRecord.checkOutDateTime)}
												>
													{translate(ResourceAssistance.Message.checkOut)} {medicalRecord.department.branch.displayName}
													/{medicalRecord.department.displayName}
												</Timeline.Item>
											)
										}
										return ary
									})
							}
							admission.ipds
								.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
								.map((ipd) => {
									ary.push(
										<Timeline.Item
											name={ipd.creationDateTime}
											dot={<SvgInpatient width={ResourceAssistance.ReactIcon.size} />}
											color={"blue"}
											label={Utils.formatDateTime(ipd.creationDateTime)}
										>
											{translate(ResourceAssistance.Message.checkIn)} {ipd.department.branch.displayName}/
											{ipd.department.displayName}
										</Timeline.Item>
									)
									if (ipd.dischargeSummary) {
										ary.push(
											<Timeline.Item
												name={ipd.dischargeSummary.creationDateTime}
												dot={<SvgInpatient width={ResourceAssistance.ReactIcon.size} />}
												color={"green"}
												label={Utils.formatDateTime(ipd.dischargeSummary.creationDateTime)}
											>
												{translate(ResourceAssistance.Hospitel.discharge)}
											</Timeline.Item>
										)
									}
									return ary
								})
							return ary.sort((a, b) => Utils.sort(a.props.name, b.props.name))
						})}
					</Timeline>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	timeline: state.menu.timeline,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TimelineActivities)
