import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setDateAction, setValue } from "~/redux/action"
import {
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END,
	SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class IPDModalMedicalCertificateDocOpt extends React.Component {
	constructor(props) {
		super(props)

		this.onDoctorOpinion = this.onDoctorOpinion.bind(this)
		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onDefaultAfter = this.onDefaultAfter.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedCertificate.certificate) {
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION,
				this.props.ipd.selectedCertificate.certificate.doctorOpinion
			)

			this.props.setDateAction(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START,
				this.props.ipd.selectedCertificate.certificate.restStartDateTime
			)
			this.props.setDateAction(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END,
				this.props.ipd.selectedCertificate.certificate.restEndDateTime
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.ipd.modalCertificate.examinationEndDateTime,
				this.props.ipd.modalCertificate.examinationEndDateTime
			)
		) {
			this.props.setDateAction(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START,
				Utils.generateDateFromLong(
					this.props.ipd.modalCertificate.examinationEndDateTime,
					0,
					0,
					0,
					-23,
					-59,
					-59
				).getTime()
			)
		}

		if (
			!_.isEqual(prevProps.ipd.modalCertificate.startDateTime, this.props.ipd.modalCertificate.startDateTime) &&
			this.props.ipd.modalCertificate.startDateTime > this.props.ipd.modalCertificate.endDateTime
		) {
			this.props.setDateAction(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END,
				Utils.generateDateFromLong(this.props.ipd.modalCertificate.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION, "")
		this.props.setDateAction(SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START, Utils.generateDate().getTime())
		this.props.setDateAction(
			SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END,
			Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
		)
	}

	modifyOpinion(opinion, isAdd) {
		let value = this.props.ipd.modalCertificate.doctorOpinion
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : ", ", opinion)
		} else {
			value = value.replace(opinion, "")
		}
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION, Utils.trim(value))
	}

	onDoctorOpinion(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION, event.target.value)
	}

	onStartDate(date) {
		this.props.setDateAction(SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START, date.getTime())
	}

	onEndDate(date) {
		this.props.setDateAction(SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END, date.getTime())
	}

	onDefaultAfter(event) {
		this.modifyOpinion(event.target.value, event.target.checked)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.doctorOpinion)}</legend>
				<Row>
					<Col md={"auto"}>
						<FormGroup controlId={"default_1"}>
							<FormCheck
								label={translate(ResourceAssistance.Message.doctorOptionAfter)}
								checked={_.includes(
									this.props.ipd.modalCertificate.doctorOpinion,
									ResourceAssistance.CONSTANT.DOCTOR_OPITION_DEFAULT_AFTER
								)}
								onChange={this.onDefaultAfter}
								value={ResourceAssistance.CONSTANT.DOCTOR_OPITION_DEFAULT_AFTER}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={2}
							value={this.props.ipd.modalCertificate.doctorOpinion}
							onChange={this.onDoctorOpinion}
						/>
					</Col>
				</Row>
				<Row className={"g-0"}>
					<Col />
					<Col md="auto">
						<DatePicker
							readOnly
							disabled
							isShowTime={false}
							startDate={Utils.generateDateFromLong(this.props.ipd.modalCertificate.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col>
						<label style={{ alignSelf: ResourceAssistance.CSS.center }}>
							{translate(ResourceAssistance.Message.to)}
						</label>
					</Col>
					<Col md="auto">
						<DatePicker
							isShowTime={false}
							minDate={Utils.generateDateFromLong(this.props.ipd.modalCertificate.startDateTime)}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.ipd.modalCertificate.endDateTime)}
							onChange={this.onEndDate}
						/>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setDateAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalMedicalCertificateDocOpt)
