const Warning = {
	Warning: {
		adjustmentBillingPlanCannotModify: "warningAdjustmentBillingPlanCannotModify",
		appointmentDelete: "appointmentDelete",
		approveTransfer: "warningApproveTransfer",
		approveTransferRequest: "warningApproveTransferRequest",
		authorizationFailed: "warningAuthorizationFailed",
		authorizationSameLoginUser: "warningAuthorizationSameLoginUser",
		canNotChangeAfterSaved: "canNotChangeAfterSaved",
		createBillinReceiptAndInvoice: "warningCreateBillingReceiptAndInvoice",
		createBillingInvoice: "warningCreateBillingInvoice",
		createBillingReceipt: "warningCreateBillingReceipt",
		dischargePatient: "warningDischargePatient",
		fileMustSmallerThan: "warningFileMustSmallerThan",
		fileUploadFailed: "warningFileUploadFailed",
		fileUploadSuccess: "warningFileUploadSuccess",
		forAnyComplications: "forAnyComplications",
		inCaseRefundPatient: "inCaseRefundPatient",
		medicationCanBeUsedMultipleTimes: "warningMedicationCanBeUsedMultipleTimes",
		opdCheckOut: "opdCheckOut",
		orderTimeInteractWithAnotherOrder: "warningOrderTimeInteractWithAnotherOrder",
		permissionDenied: "warningPermissionDenied",
		pharmacyCannotMeetDoctorOrder: "pharmacyCannotMeetDoctorOrder",
		savingWithholdingTaxCertificate: "warningSavingWithholdingTaxCertificate",
		savingWithholdingTaxCertificateFailed: "warningSavingWithholdingTaxCertificateFailed",
		savingWithholdingTaxCertificateSuccess: "warningSavingWithholdingTaxCertificateSuccess",
		theDoctorOrderIsNotPrescriptionOnlyIncludeInBilling: "warningTheDoctorOrderIsNotPrescriptionOnlyIncludeInBilling",
		theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling: "theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling",
		uploadPDFOnly: "warningUploadPDFOnly",
		wrongFrequency: "warningWrongFrequency",
		wrongWithdrawAmount: "wrongWithdrawAmount",
		wrongunit: "warningWrongUnit",
	},
}

export default Warning
