import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setPoEndDate, setPoStartDate } from "~/redux/action"
import DatePicker from "~/view/component/date_picker/DatePicker"

class POLeftFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.onStartDateChange = this.onStartDateChange.bind(this)
		this.onEndDateChange = this.onEndDateChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	onStartDateChange(date) {
		this.props.setPoStartDate(date)
	}

	onEndDateChange(date) {
		this.props.setPoEndDate(date)
	}

	onSearch(event) {
		event.preventDefault()
		this.props.loadOrders()
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col sm="auto">
					<DatePicker startDate={this.props.searchDate.startDate} onChange={this.onStartDateChange} />
				</Col>
				<Col>
					<label style={{ alignSelf: ResourceAssistance.CSS.center }}>{translate(ResourceAssistance.Message.to)}</label>
				</Col>
				<Col sm="auto">
					<DatePicker startDate={this.props.searchDate.endDate} onChange={this.onEndDateChange} />
				</Col>
				<Col sm="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						type={ResourceAssistance.Button.type.submit}
						variant={ResourceAssistance.Button.variant.primary}
						onClick={this.onSearch}
					>
						<IoCalendarSharp size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	searchDate: state.purchaseOrder.searchDate,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setPoStartDate, setPoEndDate }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POLeftFirstRow)
