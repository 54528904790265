import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import AccountsReceivableRightGL from "./AccountsReceivableRightGL"
import AccountsReceivableRightGeneral from "./AccountsReceivableRightGeneral"

class AccountsReceivableRightGroupTab extends React.Component {
	EAccountsReceivable = {
		GENERAL: "ACCOUNTS_RECEIVABLE_GENERAL",
		GL: "ACCOUNTS_RECEIVABLE_GL",
	}
	constructor(props) {
		super(props)

		this.state = {
			componentName: "AccountsReceivableRightGroupTab",
			tab: this.EAccountsReceivable.GL,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Tab.Container id={this.state.componentName} activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Row>
					<Col>
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey={this.EAccountsReceivable.GL}>{translate(ResourceAssistance.Message.generalLedger)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EAccountsReceivable.GENERAL}>{translate(ResourceAssistance.Message.invoice)}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.EAccountsReceivable.GL}>{this.state.tab === this.EAccountsReceivable.GL && <AccountsReceivableRightGL />}</Tab.Pane>
							<Tab.Pane eventKey={this.EAccountsReceivable.GENERAL}>
								{this.state.tab === this.EAccountsReceivable.GENERAL && <AccountsReceivableRightGeneral />}
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AccountsReceivableRightGroupTab)
