import { Checkbox, Switch, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_CATEGORY_MODAL_COA_DATE_IND,
	SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION,
	SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND,
	SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP,
	SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class AdmCategoryInventoryModalPayableDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCOA = this.onCOA.bind(this)
		this.onLink = this.onLink.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onDate = this.onDate.bind(this)
	}

	componentDidMount() {
		if (this.props.coa.selectedAP.activity) {
			let selectedActivity = this.props.coa.selectedAP.activity
			let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.type.chartOfAccounts, selectedActivity.chartOfAccounts)
			if (!_.isEmpty(selectedAry)) {
				this.props.setSelected(SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP, selectedAry[0], selectedAry[1])
			} else {
				this.props.setSelected(SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP, -1, null)
			}
			this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND, selectedActivity.linkGL)
			this.props.setValue(
				SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION,
				Utils.trim(selectedActivity.description.replace(ResourceAssistance.PROGRAM_DEFINED.supplier, "").replace(ResourceAssistance.PROGRAM_DEFINED.date, ""))
			)
			this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND, selectedActivity.description.includes(ResourceAssistance.PROGRAM_DEFINED.supplier))
			this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_DATE_IND, selectedActivity.description.includes(ResourceAssistance.PROGRAM_DEFINED.date))
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP, -1, null)
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION, "")
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND, false)
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND, false)
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_DATE_IND, false)
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.type.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_ADM_CATEGORY_MODAL_COA_SELECTED_AP, value, this.props.type.chartOfAccounts[value])
		}
	}

	onDescription(e) {
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_DESCRIPTION, e.target.value)
	}

	onLink(value) {
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_LINK_GL_IND, value)
	}

	onSupplier(e) {
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_SUPPLIER_IND, e.target.checked)
	}

	onDate(e) {
		this.props.setValue(SET_ADM_CATEGORY_MODAL_COA_DATE_IND, e.target.checked)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.accountsPayable)}</legend>
				<Container fluid className={ResourceAssistance.CSS.fullFlex}>
					<Row>
						<Col>
							<TreeSelect
								style={{
									textAlign: "center",
								}}
								showSearch
								allowClear
								treeExpandAction="click"
								treeLine={{ showLeafIcon: false }}
								filterTreeNode={(input, treeNode) => {
									return treeNode.title.toLowerCase().includes(input)
								}}
								loading={this.props.type.isChartOfAccountsLoading}
								placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
								value={this.props.coa.modalAccount.selectedAP.account ? this.props.coa.modalAccount.selectedAP.index : undefined}
								onChange={this.onCOA}
								treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.type.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"], false)}
							/>
						</Col>
					</Row>
					{!this.props.coa.modalAccount.isLinkGL && (
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.transactionDescription)}</legend>
							<Row>
								<Col>
									<GInput
										required
										placeholder={translate(ResourceAssistance.Message.description)}
										value={this.props.coa.modalAccount.description}
										onChange={this.onDescription}
									/>
								</Col>
							</Row>
							<Row>
								<Col />
								<Col md="auto">
									<Checkbox style={{ flexDirection: "row" }} checked={this.props.coa.modalAccount.isDescriptionIncludesSupplier} onChange={this.onSupplier}>
										{translate(ResourceAssistance.Message.supplier)}
									</Checkbox>
								</Col>
								<Col md="auto">
									<Checkbox style={{ flexDirection: "row" }} checked={this.props.coa.modalAccount.isDescriptionIncludesDate} onChange={this.onDate}>
										{translate(ResourceAssistance.Message.date)}
									</Checkbox>
								</Col>
								<Col />
							</Row>
						</fieldset>
					)}
					<Row>
						<Col />
						<Col md="auto">
							<Switch
								style={this.props.coa.modalAccount.isLinkGL ? { backgroundColor: ResourceAssistance.CSS.Color.dark_green } : undefined}
								checkedChildren={translate(ResourceAssistance.Message.linkGL)}
								unCheckedChildren={translate(ResourceAssistance.Message.unlinkGL)}
								checked={this.props.coa.modalAccount.isLinkGL}
								onChange={this.onLink}
							/>
						</Col>
						<Col />
					</Row>
				</Container>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
	coa: state.admin.type.COA,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmCategoryInventoryModalPayableDetails))
