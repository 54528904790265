import { Descriptions, Tabs } from "antd"
import React from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { BsCalculator } from "react-icons/bs"
import { ImAttachment } from "react-icons/im"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class IPDRightHealthCareRow extends React.Component {
	constructor(props) {
		super(props)

		this.onUploadHealthCare = this.onUploadHealthCare.bind(this)
		this.onExpenseEstimation = this.onExpenseEstimation.bind(this)
	}

	onUploadHealthCare(event) {
		let healthCareBlob = event.target.files[0]
		if (!healthCareBlob || !healthCareBlob.name.toLowerCase().endsWith(".pdf")) {
			return
		}
		this.props.uploadAdmissionFile(ResourceAssistance.Url.registration.uploadHealthCareFile, healthCareBlob)
	}

	onExpenseEstimation(event) {
		this.props.onModalDisplayAction(SET_REGISTRATION_EXPENSE_ESTIMATION_DISPLAY, true)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset className={ResourceAssistance.CSS.fullFlex}>
						<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
						<Descriptions
							size={"small"}
							style={{ flex: "1", flexDirection: "column" }}
							column={1}
							extra={
								Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.CRUD, this.props.login.user.roles) ||
								Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.EXPENSE_EST, this.props.login.user.roles) ? (
									<Row>
										{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.EXPENSE_EST, this.props.login.user.roles) && (
											<Col md="auto">
												<Button
													variant={ResourceAssistance.Button.variant.primary}
													size={ResourceAssistance.Button.size.sm}
													disabled={this.props.ipd.selectedAdmission.index === -1 || this.props.ipd.selectedAdmission.admission.dischargedDateTime}
													onClick={this.onExpenseEstimation}
												>
													<BsCalculator size={ResourceAssistance.ReactIcon.size} />
													{translate(ResourceAssistance.Message.expenseEstimation)}
												</Button>
											</Col>
										)}
										{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.CRUD, this.props.login.user.roles) && (
											<Col md="auto">
												<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.primary} size={ResourceAssistance.FormControl.size.sm}>
													<DropdownToggle disabled={this.props.ipd.selectedAdmission.index === -1}>
														<ImAttachment size={ResourceAssistance.ReactIcon.size} />
														{translate(ResourceAssistance.Message.attachment)}
													</DropdownToggle>
													<DropdownMenu variant={"info"}>
														<label htmlFor="healthCare" className={`dropdown-item ${!this.props.ipd.selectedAdmission.admission ? "disabled" : ""}`}>
															{translate(ResourceAssistance.Hospitel.healthCare)}
														</label>
														<DropdownItem
															id={"healthCare"}
															as={"input"}
															type={ResourceAssistance.FormControl.type.file}
															style={{ display: "none" }}
															disabled={!this.props.ipd.selectedAdmission.admission}
															accept={"application/pdf"}
															onChange={this.onUploadHealthCare}
														/>
													</DropdownMenu>
												</Dropdown>
											</Col>
										)}
									</Row>
								) : undefined
							}
						>
							{this.props.ipd.selectedAdmission.admission && (
								<Descriptions.Item>
									<Tabs
										style={{ flex: 1 }}
										defaultActiveKey={0}
										items={Utils.renderHealthCareTabs(
											this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareCode,
											this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareName,
											this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareDescription,
											this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareOther
										)}
									/>
								</Descriptions.Item>
							)}
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDRightHealthCareRow)
