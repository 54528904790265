import { Image, StyleSheet, Text, View } from "@react-pdf/renderer"
import PropTypes from "prop-types"
import React from "react"
import { ResourceAssistance } from "~/i18n"

class SignatureFooter extends React.Component {
	render() {
		return (
			<View style={[ResourceAssistance.Report.styles.row, styles.signature]} wrap={false} fixed>
				{this.props.isIssuerDisplay && (
					<View style={ResourceAssistance.Report.styles.col}>
						<Text style={ResourceAssistance.Report.styles.textBold}>Issued By/{this.props.data.issuedByIntl} </Text>
						{this.props.data.issuedBySignature ? (
							<Image style={styles.signatureImg} src={this.props.data.issuedBySignature} />
						) : (
							<Text>{"\n\n\n\n"}</Text>
						)}
						<View style={styles.line}></View>
						<Text>{this.props.data.issuedByFirstName + " " + this.props.data.issuedByLastName}</Text>
						<Text>{this.props.data.issuedDate}</Text>
					</View>
				)}

				{this.props.isCertifierDisplay && (
					<View View style={ResourceAssistance.Report.styles.col}>
						<Text style={ResourceAssistance.Report.styles.textBold}>
							Certified By/{this.props.data.certifiedByIntl}{" "}
						</Text>
						{this.props.data.certifiedBySignature ? (
							<Image style={styles.signatureImg} src={this.props.data.certifiedBySignature} />
						) : (
							<Text>{"\n\n\n\n"}</Text>
						)}
						<View style={styles.line}></View>
						<Text>{this.props.data.certifiedByFirstName + " " + this.props.data.certifiedByLastName}</Text>
						<Text>{this.props.data.certifiedDate}</Text>
					</View>
				)}

				{this.props.isApprovalDisplay && (
					<View style={ResourceAssistance.Report.styles.col}>
						<Text style={ResourceAssistance.Report.styles.textBold}>Approved By/{this.props.data.approvedByIntl} </Text>
						{this.props.data.approvedBySignature ? (
							<Image style={styles.signatureImg} src={this.props.data.approvedBySignature} />
						) : (
							<Text>{"\n\n\n\n"}</Text>
						)}
						<View style={styles.line}></View>
						<Text>{this.props.data.approvedByFirstName + " " + this.props.data.approvedByLastName}</Text>
						<Text>{this.props.data.approvedDate}</Text>
					</View>
				)}
			</View>
		)
	}
}

const styles = StyleSheet.create({
	signatureImg: {
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
		height: "25%",
		width: "25%",
	},
	signature: {
		position: "absolute",
		left: 25,
		bottom: 25,
		right: 25,
		textAlign: "center",
		height: 90,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
})

SignatureFooter.propTypes = {
	data: PropTypes.any.isRequired,
	isIssuerDisplay: PropTypes.bool,
	isCertifierDisplay: PropTypes.bool,
	isApprovalDisplay: PropTypes.bool,
}

SignatureFooter.defaultProps = {
	// data: { header: [], body: [] },
	isIssuerDisplay: true,
	isCertifierDisplay: true,
	isApprovalDisplay: true,
}

export default SignatureFooter
