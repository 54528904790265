import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalDoctorNoteA extends React.Component {
	X_RAY = "X_RAY"
	EXRay = {
		NORMAL: "NORMAL",
		ABNORMAL: "ABNORMAL",
	}

	constructor(props) {
		super(props)

		this.onAssessment = this.onAssessment.bind(this)
		this.onAssessmentXray = this.onAssessmentXray.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedSOAP.soap) {
			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT, this.props.hospitel.selectedSOAP.soap.assessment)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT, "")
	}

	modifyAssessment(assessment, isAdd) {
		if (isAdd) {
		} else {
		}
	}

	onAssessment(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT, event.target.value)
	}

	onAssessmentXray(event) {
		let value = this.props.hospitel.modalDoctorNote.assessment
		switch (event.target.value) {
			case this.EXRay.NORMAL:
				value = value.replace("ผล Xray ผิดปกติ", "")
				event.target.value = "ผล Xray ปกติ"
				break

			case this.EXRay.ABNORMAL:
				value = value.replace("ผล Xray ปกติ", "")
				event.target.value = "ผล Xray ผิดปกติ"
				break

			default:
				break
		}

		value = value.replace(/, +/g, "")

		if (event.target.checked) {
			value = value.concat(_.isEmpty(value) ? "" : ", ", event.target.value)
		} else {
			value = value.replace("ผล Xray ปกติ", "")
			value = value.replace("ผล Xray ผิดปกติ", "")
		}

		value = Utils.replaceDuplicateSpaces(value)
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT, Utils.trim(value))
	}

	render() {
		return (
			<Row>
				<Col md={"auto"}>
					<Row>
						<Col>
							<FormGroup controlId={"xray"}>
								<FormCheck
									label={"ผล Xray"}
									checked={_.includes(this.props.hospitel.modalDoctorNote.assessment, "ผล Xray")}
									onChange={this.onAssessmentXray}
									value={"ผล Xray ปกติ"}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={"auto"}>
							<FormGroup controlId={"xray_normal"}>
								<FormCheck
									type={ResourceAssistance.FormControl.type.radio}
									name={this.X_RAY}
									value={this.EXRay.NORMAL}
									disabled={!_.includes(this.props.hospitel.modalDoctorNote.assessment, "ผล Xray")}
									checked={_.includes(this.props.hospitel.modalDoctorNote.assessment, "ผล Xray ปกติ")}
									onChange={this.onAssessmentXray}
									label={"ปกติ"}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col md={"auto"}>
							<FormGroup controlId={"xray_abnormal"}>
								<FormCheck
									type={ResourceAssistance.FormControl.type.radio}
									name={this.X_RAY}
									value={this.EXRay.ABNORMAL}
									disabled={!_.includes(this.props.hospitel.modalDoctorNote.assessment, "ผล Xray")}
									checked={_.includes(this.props.hospitel.modalDoctorNote.assessment, "ผล Xray ผิดปกติ")}
									onChange={this.onAssessmentXray}
									label={"ผิดปกติ"}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col>
							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows={4}
								value={this.props.hospitel.modalDoctorNote.assessment}
								onChange={this.onAssessment}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNoteA))
