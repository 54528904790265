import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_CASHIER_MODAL_ADJUSTMENT_BALANCE } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class CashierModalAdjustmentBalance extends React.Component {
	constructor(props) {
		super(props)

		this.onBalance = this.onBalance.bind(this)
	}

	onBalance(event) {
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.balance)}</legend>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.totalPrice })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^[0-9]*\.[0-9]{2}$"
							min={0}
							max={this.props.cashier.modalAdjustment.balanceMax}
							value={this.props.cashier.modalAdjustment.balance}
							onChange={this.onBalance}
						/>
					</Col>
					<Col md={2} />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CashierModalAdjustmentBalance))
