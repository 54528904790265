import _ from "lodash"
import React from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { GiHealing } from "react-icons/gi"
import { ImPrinter } from "react-icons/im"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setValue, setWarningId, setWarningMsgAction, setLoadingAction, setHospitelPatients } from "~/redux/action"
import { SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class HospitelLeftHospitelActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onPrintVitalSign = this.onPrintVitalSign.bind(this)
		this.onDischarge = this.onDischarge.bind(this)
		this.onPrintPatientLabel = this.onPrintPatientLabel.bind(this)
		this.onPrintIntakeOutput = this.onPrintIntakeOutput.bind(this)
		this.onPrintGlasgowComaScale = this.onPrintGlasgowComaScale.bind(this)
		this.onPrintPainScale = this.onPrintPainScale.bind(this)
		this.onPrintPupilSize = this.onPrintPupilSize.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.discharge)) {
				this.discharge()
			}
		}
	}

	isDischargeDisabled() {
		return (
			this.props.hospitel.selectedHospitel.index === -1 ||
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary ||
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders.some(
				(order) => order.endDateTime > Utils.generateDate(0, 0, 0, 23, 59, 59).getTime() && !order.cancelledDateTime && !order.takeHome
			) ||
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.nurseOrders.some(
				(nurseOrder) => (!nurseOrder.endDateTime || nurseOrder.endDateTime > Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()) && !nurseOrder.takeHome
			)
		)
	}

	discharge() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.dischargeHospitel,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.hospitel.selectedHospitel.hospitel.id,
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
			},
		}

		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPrintVitalSign() {
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.vitalSign, "_blank")
		myWindow.data = PrintableDataFactory.generateVitalSignReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.vitalSigns,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onDischarge() {
		let patient = this.props.hospitel.selectedPatient.patient
		let hospitel = this.props.hospitel.selectedHospitel.hospitel
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.discharge)
		this.props.setWarningMsgAction(
			patient.title +
				" " +
				patient.firstName +
				" " +
				patient.lastName +
				"\n" +
				Utils.formatDateTime(hospitel.creationDateTime) +
				"\n" +
				this.props.hospitel.department.displayName
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onPrintPatientLabel() {
		let hospitel = this.props.hospitel.selectedHospitel.hospitel
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.label, "_blank")
		myWindow.data = PrintableDataFactory.generatePatientLabelData(hospitel.patient, hospitel.admission.clinicalRecord.hn, hospitel.room)
	}

	onPrintIntakeOutput() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.intakeOutput, "_blank")
		myWindow.data = PrintableDataFactory.generateIntakeOutputReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.intakeOutputs,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPrintGlasgowComaScale() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.glasgowComaScale, "_blank")
		myWindow.data = PrintableDataFactory.generateGlasgowComaScaleReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.glasgowComaScales,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPrintPainScale() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.painScale, "_blank")
		myWindow.data = PrintableDataFactory.generatePainScaleReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.painScales,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	onPrintPupilSize() {
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.pupilSize, "_blank")
		myWindow.data = PrintableDataFactory.generatePupilSizeReportData(
			this.props.hospitel.selectedPatient.patient,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.pupilSizes,
			this.props.hospitel.selectedHospitel.hospitel.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary.creationDateTime,
			this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.hn
		)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<DropdownToggle
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.info}
							disabled={this.props.hospitel.selectedHospitel.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</DropdownToggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem
								variant={ResourceAssistance.Button.variant.info}
								disabled={this.props.hospitel.selectedHospitel.index === -1}
								onClick={this.onPrintPatientLabel}
							>
								{translate(ResourceAssistance.Hospitel.printLabel)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={this.props.hospitel.selectedHospitel.index === -1 || !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary}
								onClick={this.onPrintVitalSign}
							>
								{translate(ResourceAssistance.Hospitel.vitalSign)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.hospitel.selectedHospitel.index === -1 || !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary}
								onClick={this.onPrintIntakeOutput}
							>
								{translate(ResourceAssistance.Message.intakeOutput)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.hospitel.selectedHospitel.index === -1 || !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary}
								onClick={this.onPrintGlasgowComaScale}
							>
								{translate(ResourceAssistance.Message.glasgowComaScale)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.hospitel.selectedHospitel.index === -1 || !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary}
								onClick={this.onPrintPainScale}
							>
								{translate(ResourceAssistance.Message.painScale)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.hospitel.selectedHospitel.index === -1 || !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary}
								onClick={this.onPrintPupilSize}
							>
								{translate(ResourceAssistance.Message.pupilSize)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
				<Col />

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Discharge, this.props.login.user.roles) && (
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDischargeDisabled()}
							onClick={this.onDischarge}
						>
							<GiHealing size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Hospitel.discharge)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setLoadingAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelLeftHospitelActionRow))
