const Symbol = {
	Symbol: {
		2: "\u0032",
		arrowIcon: "\ue5da",
		checkBox: "\u2611",
		checkMark: "\u02713",
		colon: "\u003A",
		comma: "\u002C",
		degreeCelsius: "\u2103",
		emptyCheckBox: "\u2610",
		percent: "\u0025",
		slash: "\u2215",
		space: "\u00A0",
		plus: "\u1429",
		minus: "\u207b",
		dot: "\u2022",
		whiteDot: "\u25E6",

		Report: {
			check: "\ue5ca",
			cancel: "\ue5cd",
			checkBox: "\ue834",
			emptyCheckBox: "\ue835",
		},
	},
}

export default Symbol
