import {
	SET_ADM_ICD_DISPLAY_ALL_IND,
	SET_ADM_ICD_ICDS,
	SET_ADM_ICD_ICDS_LOADING,
	SET_ADM_ICD_NEW_ICD_ACTIVE,
	SET_ADM_ICD_NEW_ICD_CODE,
	SET_ADM_ICD_NEW_ICD_DESCRIPTION,
	SET_ADM_ICD_NEW_ICD_SELECTED_TYPE,
	SET_ADM_ICD_SEARCH_CODE,
	SET_ADM_ICD_SEARCH_NAME,
	SET_ADM_ICD_SELECTED_ICD,
	SET_ADM_ICD_SELECTED_TYPE,
	SET_ADM_ICD_TYPES,
	SET_ADM_LEVEL_DISPLAY_ALL_IND,
	SET_ADM_LEVEL_LEVELS,
	SET_ADM_LEVEL_LEVELS_LOADING,
	SET_ADM_LEVEL_MODAL_NEW_ACTIVE,
	SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION,
	SET_ADM_LEVEL_MODAL_NEW_NAME,
	SET_ADM_LEVEL_SEARCH_NAME,
	SET_ADM_LEVEL_SELECTED_LEVEL,
} from "~/redux/type"

const { ResourceAssistance } = require("~/i18n")

const init = {
	icd: {
		searchCode: "",
		searchName: "",
		displayInactive: true,
		types: [],
		selectedICD: {
			index: -1,
			icd: null,
		},
		selectedType: {
			index: -1,
			type: null,
		},
		icdTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewICD: {
			code: "",
			description: "",
			isActive: true,
			selectedType: {
				index: -1,
				type: null,
			},
		},
	},
	level: {
		searchName: "",
		displayInactive: true,
		selectedLevel: {
			index: -1,
			level: null,
		},
		levelTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.by,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		modalNewLevel: {
			name: "",
			description: "",
			isActive: true,
		},
	},
}

const ConfigICDReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_ICD_TYPES:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					types: action.payload.objs,
				},
			})
		case SET_ADM_ICD_SELECTED_TYPE:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_ADM_ICD_SEARCH_CODE:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					searchCode: action.payload.value,
				},
			})
		case SET_ADM_ICD_SEARCH_NAME:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_ICD_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_ICD_ICDS:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					icdTable: {
						...state.icd.icdTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_ICD_ICDS_LOADING:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					icdTable: {
						...state.icd.icdTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_ICD_SELECTED_ICD:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					selectedICD: {
						index: action.payload.index,
						icd: action.payload.selected,
					},
				},
			})
		case SET_ADM_ICD_NEW_ICD_SELECTED_TYPE:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					modalNewICD: {
						...state.icd.modalNewICD,
						selectedType: {
							index: action.payload.index,
							type: action.payload.selected,
						},
					},
				},
			})
		case SET_ADM_ICD_NEW_ICD_CODE:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					modalNewICD: {
						...state.icd.modalNewICD,
						code: action.payload.value,
					},
				},
			})
		case SET_ADM_ICD_NEW_ICD_DESCRIPTION:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					modalNewICD: {
						...state.icd.modalNewICD,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_ICD_NEW_ICD_ACTIVE:
			return Object.assign({}, state, {
				icd: {
					...state.icd,
					modalNewICD: {
						...state.icd.modalNewICD,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_LEVEL_LEVELS:
			return Object.assign({}, state, {
				level: {
					...state.level,
					levelTable: {
						...state.level.levelTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_LEVEL_LEVELS_LOADING:
			return Object.assign({}, state, {
				level: {
					...state.level,
					levelTable: {
						...state.level.levelTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_LEVEL_SELECTED_LEVEL:
			return Object.assign({}, state, {
				level: {
					...state.level,
					selectedLevel: {
						index: action.payload.index,
						level: action.payload.selected,
					},
				},
			})
		case SET_ADM_LEVEL_SEARCH_NAME:
			return Object.assign({}, state, {
				level: {
					...state.level,
					searchName: action.payload.value,
				},
			})
		case SET_ADM_LEVEL_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				level: {
					...state.level,
					displayInactive: action.payload.value,
				},
			})
		case SET_ADM_LEVEL_MODAL_NEW_ACTIVE:
			return Object.assign({}, state, {
				level: {
					...state.level,
					modalNewLevel: {
						...state.level.modalNewLevel,
						isActive: action.payload.value,
					},
				},
			})
		case SET_ADM_LEVEL_MODAL_NEW_DESCRIPTION:
			return Object.assign({}, state, {
				level: {
					...state.level,
					modalNewLevel: {
						...state.level.modalNewLevel,
						description: action.payload.value,
					},
				},
			})
		case SET_ADM_LEVEL_MODAL_NEW_NAME:
			return Object.assign({}, state, {
				level: {
					...state.level,
					modalNewLevel: {
						...state.level.modalNewLevel,
						name: action.payload.value,
					},
				},
			})

		default:
			return state
	}
}

export default ConfigICDReducer
