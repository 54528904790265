import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_STOP_END_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class HospitelModalStopDate extends React.Component {
	constructor(props) {
		super(props)

		this.onEndDate = this.onEndDate.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_HOSPITEL_MODAL_STOP_END_DATETIME, this.props.hospitel.selectedOrder.order.endDateTime)
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_STOP_END_DATETIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_STOP_END_DATETIME, date.getTime())
	}

	render() {
		return (
			<Fragment>
				<Row style={{ alignSelf: "center" }}>
					<Col md="auto">
						<DatePicker
							inline
							isShowTime={
								this.props.hospitel.selectedOrder.order
									? !_.isEmpty(this.props.hospitel.selectedOrder.order.code)
									: false
							}
							minDate={Utils.generateDateFromLong(this.props.hospitel.selectedOrder.order.startDateTime)}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.hospitel.selectedOrder.order.endDateTime)}
							onChange={this.onEndDate}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalStopDate))
