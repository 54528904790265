import { Descriptions } from "antd"
import _ from "lodash"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "./Utils"
/**
 * Helper methods apply to UTS objects from Server directly.
 */

class ServerUtils {
	static decodeAccountingTransDescr(description, supplier, date) {
		if (description.includes(ResourceAssistance.PROGRAM_DEFINED.supplier)) {
			description = description.replace(ResourceAssistance.PROGRAM_DEFINED.supplier, supplier)
		}
		if (description.includes(ResourceAssistance.PROGRAM_DEFINED.date)) {
			description = description.replace(ResourceAssistance.PROGRAM_DEFINED.date, date)
		}
		return description
	}

	static getQtyPerOrderStr(amount, minQtyPerOrder) {
		return Utils.formatNumWithComma(Utils.BigNumber(amount).dividedBy(minQtyPerOrder).toNumber()) + " x " + Utils.formatNumWithComma(minQtyPerOrder)
	}
	static calculateBillingPlanAdjustments(adjustments) {
		return adjustments.reduce((total, cur) => {
			return total.plus(cur.discount)
		}, Utils.BigNumber(0))
	}

	static findPharmacyInventory = (code, unit, isOneTimeUnit, pharmacyInventories) => {
		let pharmacyInventory = pharmacyInventories.find((each) => each.code === code)
		if (pharmacyInventory) {
			if (unit === undefined) {
				return Utils.BigNumber(pharmacyInventory.totalAmount).isGreaterThan(0) ||
					pharmacyInventory.distributions.some((eachDistribution) => Utils.BigNumber(eachDistribution.totalAmount).isGreaterThan(0))
					? pharmacyInventory
					: undefined
			} else {
				if (pharmacyInventory.unit === unit) {
					return pharmacyInventory
				}
				let found = Object.entries(Utils.groupBy(pharmacyInventory.distributions, "unit")).find(([key, values]) => key === unit)
				if (found) {
					return {
						totalAmount: found[1]
							.reduce((total, cur) => {
								return total.plus(cur.totalAmount)
							}, Utils.BigNumber(0))
							.toNumber(),
					}
				}
			}
		}
		return undefined
	}

	static getLastVisitedLocationFrom = (admission) => {
		if (_.isEmpty(admission.ipds)) {
			if (admission.clinicalRecord) {
				return admission.clinicalRecord.medicalRecords
					.filter((medicalRecord) => medicalRecord.checkInDateTime > 0)
					.sort((a, b) => Utils.sort(b.checkInDateTime, a.checkInDateTime))
					.find((medicalRecord) => true)
			}
		} else {
			return admission.ipds.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime)).find((ipd) => true)
		}
	}

	static getTreeNodesFromOrg = (orgs, displayEmptyChildren = true, displayBranch = true, displayDepartment = true) => {
		return orgs
			.filter((org) => displayEmptyChildren || !_.isEmpty(org.locations))
			.map((org, orgIdx) => {
				return {
					title: org.displayName,
					value: orgIdx,
					selectable: !displayBranch,
					children: displayBranch
						? org.locations
								.filter((branch) => displayEmptyChildren || !_.isEmpty(branch.locations))
								.map((branch, branchIdx) => {
									return {
										title: branch.displayName,
										value: orgIdx + "-" + branchIdx,
										selectable: !displayDepartment,
										children: displayDepartment
											? branch.locations.map((department, departmentIdx) => {
													return {
														title: department.displayName,
														value: orgIdx + "-" + branchIdx + "-" + departmentIdx,
														extra: {
															org: {
																index: orgIdx,
																org: org,
															},
															branch: {
																index: branchIdx,
																branch: branch,
															},
															department: {
																index: departmentIdx,
																department: department,
															},
														},
													}
											  })
											: [],
									}
								})
						: [],
				}
			})
			.filter((org) => displayEmptyChildren || org.children.some((branch) => !_.isEmpty(branch.children)))
	}

	static getTreeNodesFromHealthCarePlan = (healthcarePlans, displayEmptyChildren = true, isNonEmptyParentSelectable = true) => {
		return healthcarePlans
			.filter((hcp) => displayEmptyChildren || !_.isEmpty(hcp.companies))
			.map((hcp, hcpIdx) => {
				return {
					title: hcp.displayName,
					key: hcpIdx,
					value: hcpIdx,
					// value: hcp.displayName,
					// selectable: isNonEmptyParentSelectable || _.isEmpty(hcp.companies),
					selectable: isNonEmptyParentSelectable,
					extra: {
						isParent: true,
						idx: hcpIdx,
						code: hcp.code,
						description: hcp.description,
					},
					children: hcp.companies.map((company, companyIdx) => {
						return {
							title: company.name,
							key: hcpIdx + "-" + companyIdx,
							value: hcpIdx + "-" + companyIdx,
							// value: hcp.displayName + "-" + company.name,
							extra: {
								idx: companyIdx,
								code: hcp.code,
								description: company.description,
								healthcarePlan: hcp.displayName,
							},
						}
					}),
				}
			})
	}

	static getTreeNodesFromBank = (banks, displayEmptyChildren = true, isNonEmptyParentSelectable = true) => {
		return banks
			.filter((bank) => displayEmptyChildren || !_.isEmpty(bank.branches))
			.map((bank, bankIdx) => {
				return {
					title: bank.displayName,
					key: bankIdx,
					value: bankIdx,
					selectable: isNonEmptyParentSelectable || _.isEmpty(bank.branches),
					extra: {
						isParent: true,
						idx: bankIdx,
						code: bank.code,
						description: bank.description,
					},
					children: bank.branches.map((branch, branchIdx) => {
						return {
							title: branch.displayName,
							key: bankIdx + "-" + branchIdx,
							value: bankIdx + "-" + branchIdx,
							extra: {
								idx: branchIdx,
								code: bank.code,
								description: branch.description,
								bank: bank.displayName,
							},
						}
					}),
				}
			})
	}

	static getChartOfAccountsByGroup(groupAccounts) {
		let results = []
		for (let key in groupAccounts) {
			results.push({
				name: key,
				values: this.getChartOfAccounts(groupAccounts[key]),
			})
		}
		return results
	}

	static getChartOfAccounts(accounts) {
		let assetMap = new Map()
		accounts.forEach((account) => {
			if (account.parent) {
				if (!assetMap.get(account.parent.id)) {
					assetMap.set(account.parent.id, {
						...account.parent,
						accounts: [],
					})
				}
				if (!assetMap.get(account.id)) {
					assetMap.set(account.id, {
						...account,
						accounts: [],
					})
				} else {
					assetMap.set(
						account.id,
						Object.assign(assetMap.get(account.id), {
							...account,
							isRoot: !account.parent,
						})
					)
				}
				assetMap.get(account.parent.id).accounts.push(assetMap.get(account.id))
				assetMap.get(account.parent.id).accounts.sort((a, b) => Utils.sort(a.displayName, b.displayName))
			} else {
				if (!assetMap.get(account.id)) {
					assetMap.set(account.id, {
						...account,
						isRoot: true,
						accounts: [],
					})
				} else {
					assetMap.set(
						account.id,
						Object.assign(assetMap.get(account.id), {
							...account,
							isRoot: true,
						})
					)
				}
			}
		})
		return [...assetMap.values()].filter((each) => each.isRoot).sort((a, b) => Utils.sort(a.displayName, b.displayName))
	}

	static getTreeNodesFromChartOfAccountsGroup(
		accountsGroup,
		isDisplayLeaf = true,
		excludeTargetIDs = [],
		parentIdx = undefined,
		title = "displayName",
		isParentSelectable = true
	) {
		let results = []
		for (let i = 0; i < accountsGroup.length; i++) {
			results.push({
				title: accountsGroup[i].name,
				key: i,
				value: i,
				selectable: false,
				checkable: false,
				children: this.getTreeNodesFromChartOfAccounts(accountsGroup[i].values, isDisplayLeaf, excludeTargetIDs, i, title, isParentSelectable),
			})
		}
		return results
	}

	static getTreeNodesFromChartOfAccounts(
		accounts,
		isDisplayLeaf = true,
		excludeTargetIDs = [],
		parentIdx = undefined,
		title = "displayName",
		isParentSelectable = true
	) {
		return accounts.reduce((rtnArry, account, accountIdx) => {
			let idx = parentIdx === undefined ? accountIdx : parentIdx + "-" + accountIdx
			if (account.subLevel || isDisplayLeaf) {
				rtnArry.push({
					title: Array.isArray(title)
						? title.reduce((rtn, cur) => {
								if (rtn === "") {
									rtn = account[cur]
								} else {
									rtn = rtn + "/" + account[cur]
								}
								return rtn
						  }, "")
						: account[title],
					key: idx,
					value: idx,
					disabled: !_.isEmpty(excludeTargetIDs) && excludeTargetIDs.includes(account.id),
					selectable: account.subLevel ? isParentSelectable : true,
					children: account.subLevel
						? this.getTreeNodesFromChartOfAccounts(account.accounts, isDisplayLeaf, excludeTargetIDs, idx, title, isParentSelectable)
						: [],
				})
			}
			return rtnArry
		}, [])
	}

	static getNameAndBrandFromDoctorOrder(description) {
		const regex = /(.*)\(([^()]*)\)\s*$/
		const matches = description.match(regex)
		return [Utils.trim(matches[1]), matches[2]]
	}

	static findChartOfAccountsFromGroup(source, target) {
		if (!source || !target) {
			return []
		}

		for (let i = 0; i < source.length; i++) {
			let found = this.findChartOfAccounts(source[i].values, target)
			if (!_.isEmpty(found)) {
				found[0] = i + "-" + found[0]
				return found
			}
		}
		return []
	}

	static findChartOfAccounts(source, target) {
		if (!target) {
			return []
		}
		return (function findAccount(source, target) {
			if (!source) {
				return []
			}
			let found = []
			for (let i = 0; i < source.length; i++) {
				if (source[i].id === target.id || source[i].fullCode === target) {
					found[0] = i
					found[1] = source[i]
					break
				}
				found = findAccount(source[i].accounts, target)
				if (!_.isEmpty(found)) {
					found[0] = i + "-" + found[0]
					break
				}
			}
			return found
		})(source, target)
	}

	static getBase64Img(base64Str) {
		return "data:image/png;base64," + base64Str
	}

	static calculateAccountsPayableActivityTotal = (activity, items, discountSupplier, discountReceipt, supplierPayment = 0) => {
		if (_.isEmpty(items)) {
			return Utils.BigNumber(0)
		}
		let total = Utils.BigNumber(0)
		let balance = items.reduce((total, cur) => {
			return total.plus(Utils.BigNumber(cur.totalPrice).dividedBy(1 - cur.discount / 100))
		}, Utils.BigNumber(0))
		let discount = items
			.reduce((total, cur) => {
				let vDiscount = Utils.BigNumber(cur.totalPrice).times(discountSupplier).dividedBy(100)
				let iDiscount = Utils.BigNumber(cur.totalPrice).times(cur.discount).dividedBy(100)
				return total.plus(vDiscount).plus(iDiscount)
			}, Utils.BigNumber(0))
			.plus(discountReceipt)
		let tax = items.reduce((total, cur) => {
			return total.plus(
				Utils.BigNumber(cur.totalPrice)
					.times(1 - discountSupplier / 100)
					.times(cur.taxRate)
					.dividedBy(100)
			)
		}, Utils.BigNumber(0))
		let adjustment = Utils.BigNumber(supplierPayment).minus(balance.toFixed(2)).plus(discount.toFixed(2)).minus(tax.toFixed(2))
		if (activity.includeBalance) {
			total = total.plus(balance)
		}
		if (activity.includeDiscount) {
			total = total.minus(discount)
		}
		if (activity.includeTax) {
			total = total.plus(tax)
		}
		if (activity.includeBalanceAdjustment) {
			total = total.plus(adjustment)
		}

		if (total.eq(adjustment) && activity.includeBalanceAdjustment) {
			if ((total.isGreaterThan(0) && activity.credit) || (total.isLessThan(0) && activity.debit)) {
				return Utils.BigNumber(0)
			}
		}
		return total.abs()
	}

	static renderHealthCareTabs(healthcareCode, healthcareName, healthcareDescription, healthcareOther) {
		return _.isEmpty(healthcareName)
			? [
					{
						label: translate(ResourceAssistance.Message.other),
						key: 0,
						children: (
							<Descriptions size={"small"} style={{ flex: "unset", display: "unset" }} contentStyle={{ fontStyle: "italic" }}>
								<Descriptions.Item label={translate(ResourceAssistance.Message.other)} span={3}>
									{healthcareOther}
								</Descriptions.Item>
							</Descriptions>
						),
					},
			  ]
			: healthcareName.split("?").map((healthcare, idx) => {
					return {
						label: healthcare,
						key: idx,
						children: (
							<Descriptions size={"small"} style={{ flex: "unset", display: "unset" }} contentStyle={{ fontStyle: "italic" }}>
								<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
									{healthcareCode.split("?")[idx]}
								</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={2}>
									{healthcareDescription.split("?")[idx]}
								</Descriptions.Item>
							</Descriptions>
						),
					}
			  })
	}

	static splitPreOperativeCheckListProperty(input) {
		if (!_.isEmpty(input)) {
			let [first, ...rest] = input.split(/,(.+)/).map((s) => s.trim())
			return [first.replace(",", "") === "true", rest.join("")]
		}
		return ["", ""]
	}
}

export default ServerUtils
