import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY } from "~/redux/type"
import HospitalModalPatientClinicalRecordHistory from "./HospitalModalPatientClinicalRecordHistory"
import HospitelModalPatientClinicalRecordHealthcare from "./HospitelModalPatientClinicalRecordHealthcare"
import HospitelModalPatientClinicalRecordPatient from "./HospitelModalPatientClinicalRecordPatient"
import HospitalModalPatientClinicalRecordPE from "./HospitalModalPatientClinicalRecordPE"
import HospitalModalPatientClinicalRecordStatus from "./HospitalModalPatientClinicalRecordStatus"
import HospitalModalPatientClinicalRecordOther from "./HospitalModalPatientClinicalRecordOther"
import HospitalModalPatientClinicalRecordWomen from "./HospitalModalPatientClinicalRecordWomen"

class HospitalModalPatientClinicalRecordDetails extends React.Component {
	componentWillUnmount() {
		this.props.setValue(SET_HOSPITAL_MODAL_CLINICAL_RECORD_EDIT_DISPLAY, false)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitalModalPatientClinicalRecordStatus {...this.props} />
				<HospitelModalPatientClinicalRecordPatient {...this.props} />
				<HospitelModalPatientClinicalRecordHealthcare {...this.props} />
				<HospitalModalPatientClinicalRecordOther {...this.props} />
				<HospitalModalPatientClinicalRecordHistory {...this.props} />
				{this.props.patient.clinicalRecord.menstruation && <HospitalModalPatientClinicalRecordWomen {...this.props} />}
				<HospitalModalPatientClinicalRecordPE {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitalModalPatientClinicalRecordDetails)
