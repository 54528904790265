import { Select } from "antd"
import React, { Component, Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { Property } from "./TypeModalNew"
import _ from "lodash"

class TypeModalNewServiceField extends Component {
	constructor(props) {
		super(props)

		this.state = {
			chrgItems: [],
			isLoading: false,
		}

		this.onChrgItemChange = this.onChrgItemChange.bind(this)
		this.getChrgItemValue = this.getChrgItemValue.bind(this)
	}

	onChrgItemChange(value) {
		this.props.onSelectChange(Property.CHRG_ITEM, this.props.type.service.chrgItems[value].name)
	}

	getChrgItemValue() {
		let values = []
		this.props.type.service.chrgItems.forEach((chrgItem, idx) => {
			if (_.includes(this.props.chrgItem, chrgItem.name)) {
				values.push(idx)
			}
		})
		return values
	}

	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayChrgItem && (
					<>
						<Row>
							<Col>
								<legend>{translate(ResourceAssistance.Message.chrgItem)}</legend>
							</Col>
						</Row>
						<Row>
							<Col>
								<Select
									showSearch
									status={this.props.chrgItem ? "" : "error"}
									size={ResourceAssistance.Select.size.middle}
									style={{ textAlign: "center", width: "100%" }}
									loading={this.state.isLoading}
									optionFilterProp="children"
									filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
									onChange={this.onChrgItemChange}
									maxTagCount={"responsive"}
									placeholder={translate(ResourceAssistance.Message.chrgItem)}
									value={this.getChrgItemValue()}
								>
									{Utils.renderSelects(this.props.type.service.chrgItems, false, -1, "name", false)}
								</Select>
							</Col>
						</Row>
					</>
				)}
			</Fragment>
		)
	}
}

export default TypeModalNewServiceField
