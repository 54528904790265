import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFrontInventoryInventories, setLoadingAction, setValue } from "~/redux/action"
import {
	SET_AUTHORIZATION_DISPLAY,
	SET_AUTHORIZATION_ID,
	SET_AUTHORIZATION_PRIVILEGE,
	SET_FRONT_INVENTORY_WASTE_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import FrontInventoryModalWasteDetails from "./FrontInventoryModalWasteDetails"

class FrontInventoryModalWaste extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "FrontInventoryModalWaste",
		}

		this.lotRef = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.authorization.isAuthorized, this.props.authorization.isAuthorized)) {
			if (
				this.props.authorization.isAuthorized &&
				_.isEqual(this.props.authorization.id, ResourceAssistance.ID.HOSPITAL.frontInventory.modalWaste.authorization)
			) {
				this.saveWaste()
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_WASTE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.lotRef.current) {
			return true
		}
		return (
			!this.props.frontInventory.modalWaste.lotTable.filtered.some((lot) => lot.isWaste) ||
			_.isEmpty(Utils.trim(this.props.frontInventory.modalWaste.reason))
		)
	}

	saveWaste() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.frontInventory.saveWaste,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				locationId: this.props.frontInventory.selectedDepartment.department.id,
				userId: this.props.login.user.id,
				wasteItems: this.props.frontInventory.modalWaste.lotTable.filtered
					.filter((lot) => lot.isWaste)
					.map((lot) => ({
						pharmacyInventoryId: this.props.frontInventory.selectedInventory.inventory.id,
						code: lot.code,
						name: lot.name,
						lotNum: lot.lotNum,
						unit: lot.unit,
						amount: lot.wasteAmount,
						reason: Utils.trim(this.props.frontInventory.modalWaste.reason),
					})),
			},
		}
		let callback = (res) => {
			this.props.setFrontInventoryInventories(res.data.pharmacyInventories)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		this.props.setValue(SET_AUTHORIZATION_ID, ResourceAssistance.ID.HOSPITAL.frontInventory.modalWaste.authorization)
		this.props.setValue(SET_AUTHORIZATION_PRIVILEGE, ResourceAssistance.Privilege.Witness.WASTE)
		this.props.onModalDisplayAction(SET_AUTHORIZATION_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.waste)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<FrontInventoryModalWasteDetails
							lotRef={this.lotRef}
							onRenderParentCallback={this.onRenderParentCallback}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isFrontInventoryWasteDisplay,
	login: state.login,
	authorization: state.component.authorization,
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setFrontInventoryInventories,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontInventoryModalWaste)
