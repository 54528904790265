import {
	SET_REPORT_HOSPITEL_PATIENT_BRANCHES,
	SET_REPORT_HOSPITEL_PATIENT_DEPARTMENTS,
	SET_REPORT_HOSPITEL_PATIENT_ORGS,
	SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH,
	SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT,
	SET_REPORT_HOSPITEL_PATIENT_SELECTED_ORG,
} from "~/redux/type"

const init = {
	patient: {
		orgs: [],
		branches: [],
		departments: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
		selectedDepartment: {
			index: -1,
			department: null,
		},
	},
}

const reportHospitelReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_HOSPITEL_PATIENT_ORGS:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					orgs: action.payload.objs,
				},
			})

		case SET_REPORT_HOSPITEL_PATIENT_SELECTED_ORG:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})

		case SET_REPORT_HOSPITEL_PATIENT_BRANCHES:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					branches: action.payload.objs,
				},
			})

		case SET_REPORT_HOSPITEL_PATIENT_SELECTED_BRANCH:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})

		case SET_REPORT_HOSPITEL_PATIENT_DEPARTMENTS:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					departments: action.payload.objs,
				},
			})

		case SET_REPORT_HOSPITEL_PATIENT_SELECTED_DEPARTMENT:
			return Object.assign({}, state, {
				patient: {
					...state.patient,
					selectedDepartment: {
						index: action.payload.index,
						department: action.payload.selected,
					},
				},
			})

		default:
			return state
	}
}

export default reportHospitelReducer
