import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN, SET_CONTRACT_BILLING_FILTER_BILLING_ID } from "~/redux/type"
import SearchBox from "~/view/component/search_box/SearchBox"

class ContractBillingLeftFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onFilterContractBillingId = this.onFilterContractBillingId.bind(this)
		this.onFilterHealthCarePlan = this.onFilterHealthCarePlan.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_CONTRACT_BILLING_FILTER_BILLING_ID, "")
		this.props.setValue(SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN, "")
	}

	onFilterContractBillingId(event) {
		this.props.setValue(SET_CONTRACT_BILLING_FILTER_BILLING_ID, event.target.value)
	}

	onFilterHealthCarePlan(event) {
		this.props.setValue(SET_CONTRACT_BILLING_FILTER_HEALTH_CARE_PLAN, event.target.value)
	}

	render() {
		return (
			<Row>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.id, ResourceAssistance.Message.insuranceCompany]}
						callbacks={[this.onFilterContractBillingId, this.onFilterHealthCarePlan]}
						values={[this.props.contract.filterContractBillingId, this.props.contract.filterHealthCarePlan]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractBillingLeftFilter)
