import { LOCALES, ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"

class PaymentSlipPrintDataFactory extends PrintableDataFactory {
	static generateFinancePaymentJournalVoucherData = (org, paymentSlip, paymentPlan, GLs) => {
		let totalDebit = Utils.BigNumber(0)
		let totalCredit = Utils.BigNumber(0)
		return {
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.financialPlan }),
			idIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.id }),
			invoiceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceNum }),
			supplierIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.supplier }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			codeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.code }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			debitIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.debit }),
			creditIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.credit }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),

			name: org.displayName,
			address: org.address.displayName,

			id: "PS-" + paymentPlan.id,
			supplier: paymentSlip.supplierCode + "-" + paymentSlip.supplierName,
			date: Utils.formatDate(paymentPlan.paymentTermDateTime),

			GLs: GLs.sort((a, b) => Utils.sort(a.credit, b.credit)).map((each) => {
				totalDebit = totalDebit.plus(each.debit)
				totalCredit = totalCredit.plus(each.credit)
				return {
					code: each.code,
					name: each.name,
					debit: Utils.formatNumWithComma(Utils.BigNumber(each.debit).toFixed(2)),
					credit: Utils.formatNumWithComma(Utils.BigNumber(each.credit).toFixed(2)),
				}
			}),

			totalDebit: Utils.formatNumWithComma(totalDebit.toFixed(2)),
			totalCredit: Utils.formatNumWithComma(totalCredit.toFixed(2)),

			signatureNames: [paymentPlan.financialOperationInspector.displayName, paymentPlan.financialOperationApprover.displayName],
		}
	}

	static generatePaymentJournalVoucherData = (org, paymentSlip, paymentPlan, invoices, GLs) => {
		let totalInvoice = Utils.BigNumber(0)
		let totalWHT = Utils.BigNumber(0)
		let totalDebit = Utils.BigNumber(0)
		let totalCredit = Utils.BigNumber(0)
		return {
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			approverIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.approver }),
			bankIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.bank }),
			codeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.code }),
			creatorIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.creator }),
			creditIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.credit }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			debitIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.debit }),
			dueDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dueDate }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),
			idIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.id }),
			inspectorIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.inspector }),
			invoiceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.invoiceNum }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			paymentIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.payment }),
			printDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.printDate }),
			supplierIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.supplier }),
			taxIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxRate }),
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.paymentSlip }),
			withholdingTaxIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.withholdingTax }),

			name: org.displayName,
			address: org.address.displayName,

			id: paymentPlan.id,
			dueDate: Utils.formatDate(paymentPlan.paymentTermDateTime),
			payment: paymentPlan.paymentMethod,
			bank: paymentPlan.bankName,
			supplier: paymentSlip.supplierCode + "-" + paymentSlip.supplierName,
			printDate: Utils.formatDate(new Date().getTime()),

			invoices: invoices.map((invoice) => {
				totalInvoice = totalInvoice.plus(invoice.taxedTotalPrice)
				return {
					date: invoice.receiveInvoice ? Utils.formatDate(invoice.receivedDateTime) : Utils.formatDate(invoice.creationDateTime),
					id: invoice.receiveInvoice ? "RC-" + invoice.id : invoice.purchaseInvoice ? "PI-" + invoice.id : "PV-" + invoice.id,
					invoiceNum: invoice.invoice,
					amount: Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
					withholdingTaxes: invoice.receiveInvoice
						? []
						: invoice.purchases
								.filter((purchase) => Utils.BigNumber(purchase.withholdingTax).isGreaterThan(0))
								.map((purchase) => {
									let withholdingTax = Utils.formatNumWithComma(
										Utils.BigNumber(purchase.taxedTotalPrice)
											.dividedBy(1 + purchase.tax / 100)
											.times(purchase.withholdingTax / 100)
											.toFixed(2)
									)
									totalWHT = totalWHT.plus(withholdingTax)
									return {
										taxedTotalAmount: Utils.formatNumWithComma(Utils.BigNumber(purchase.taxedTotalPrice).toFixed(2)),
										tax: purchase.tax,
										withholdingTax: purchase.withholdingTax,
										amount: withholdingTax,
									}
								}),
				}
			}),

			GLs: GLs.sort((a, b) => Utils.sort(a.credit, b.credit)).map((each) => {
				totalDebit = totalDebit.plus(each.debit)
				totalCredit = totalCredit.plus(each.credit)
				return {
					code: each.code,
					name: each.name,
					debit: Utils.formatNumWithComma(Utils.BigNumber(each.debit).toFixed(2)),
					credit: Utils.formatNumWithComma(Utils.BigNumber(each.credit).toFixed(2)),
				}
			}),

			invoiceAmount: Utils.formatNumWithComma(totalInvoice.minus(totalWHT).toFixed(2)),
			invoiceGrandWithholdingTax: Utils.formatNumWithComma(totalWHT.toFixed(2)),
			invoiceGrandTotal: Utils.formatNumWithComma(totalInvoice.toFixed(2)),
			totalDebit: Utils.formatNumWithComma(totalDebit.toFixed(2)),
			totalCredit: Utils.formatNumWithComma(totalCredit.toFixed(2)),

			creator: paymentPlan.creator.displayName,
			inspector: paymentPlan.inspector.displayName,
			approver: paymentPlan.approver.displayName,
			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Message.financialOfficer }),
				this.getIntl().formatMessage({ id: ResourceAssistance.Message.cheifFinancialOfficer }),
				this.getIntl().formatMessage({ id: ResourceAssistance.Message.payee }),
			],
		}
	}

	static generateWithholdingTaxData = (certificate) => {
		return {
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			amountInt: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			certifyAboveIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.iHereByCertify }),
			dateCertificateIssuedIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.dayMonthYearThatTheCertificateWasIssued }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			formNumClarifyIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.formNumClarify }),
			formNumIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.formNum }),
			fundIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.governmentPensionFundOrGovernmentPermanentExployee }),
			totalAmountIncomeAndWithholdingTaxIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.totalAmountIncomeAndWithholdingTaxIntl }),
			identificationIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			numberIntl: ResourceAssistance.CONSTANT.NUMBER,
			otherIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.other }),
			payEveryTimeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.payEveryTime }),
			payOneTimeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.payOneTime }),
			payerIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.payer }),
			pnd1aExIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd1aEx }),
			pnd1aIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd1a }),
			pnd2Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd2 }),
			pnd2aIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd2a }),
			pnd3Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd3 }),
			pnd3aIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd3a }),
			pnd53Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.pnd53 }),
			providentFundInt: this.getIntl().formatMessage({ id: ResourceAssistance.Message.providentFund }),
			section401Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.salaryWagesEtcSection401 }),
			section402Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.feeCommissionsSection402 }),
			section403Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.copyrightSection403 }),
			section404aIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.interestSection404a }),
			section404b11Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg._30PercentOfNetProfit }),
			section404b12Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg._25PercentOfNetProfit }),
			section404b13Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg._20PercentOfNetProfit }),
			section404b14Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.otherPercentOfNetProfit }),
			section404b1Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.dividendEntitledToTaxCreditByRate }),
			section404b21Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.netProfitOfBusinessExemptFromCorporateIncomeTax }),
			section404b22Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.dividendOrProfitSharesAreExcept }),
			section404b23Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.netProfitDeductedFromNetLosses }),
			section404b24Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.profitRecognizedByTheEquityMethod }),
			section404b2Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.dividendEntitledToTaxCreditBy }),
			section404bIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.dividendsSection404b }),
			section5Intl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.paymentOfIncomeSubjectToWithholdingTaxAccordingTo }),
			socialSecurityFundIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.socialSecurityFund }),
			stampIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.stampedByLegalEntity }),
			subTitleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.section50BisOfTheRevenueCode }),
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			taxPayerIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.withholdingTaxPayer }),
			taxWithholderIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.withholdingTaxWithholder }),
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.withholdingTaxCertificate }),
			typeOfIncomePaidIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.typeOfIncomePaid }),
			warningIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.thePersonResponsibleForIssuingWithholdingTaxCertificate }),
			withholdAtSourceIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.withholdAtSource }),
			taxWithheldIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxWithheld }),
			grandTotalLettersIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Report.Msg.totalWithholdingTaxInLetters }),

			isDisplayEn: this.getIntl().locale === LOCALES.ENGLISH,

			number: certificate.id,
			taxWithholderName: certificate.withholderName,
			taxWithholderIdentification: certificate.withholderId,
			taxWithholderTaxId: certificate.withholderTaxId,
			taxWithholderAddress: certificate.withholderAddress,
			taxPayerName: certificate.taxerName,
			taxPayerIdentification: certificate.taxerId,
			taxPayerTaxId: certificate.taxerTaxId,
			taxPayerAddress: certificate.taxerAddress,

			section401Date: certificate._401DateTime > 0 ? Utils.formatDate(certificate._401DateTime) : "",
			section401Amount: certificate._401Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._401Amount).toFixed(2)) : "",
			section401WithholdingTax: certificate._401WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._401WithholdingTax).toFixed(2)) : "",
			section402Date: certificate._402DateTime > 0 ? Utils.formatDate(certificate._402DateTime) : "",
			section402Amount: certificate._402Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._402Amount).toFixed(2)) : "",
			section402WithholdingTax: certificate._402WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._402WithholdingTax).toFixed(2)) : "",
			section403Date: certificate._403DateTime > 0 ? Utils.formatDate(certificate._403DateTime) : "",
			section403Amount: certificate._403Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._403Amount).toFixed(2)) : "",
			section403WithholdingTax: certificate._403WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._403WithholdingTax).toFixed(2)) : "",
			section404aDate: certificate._404_aDateTime > 0 ? Utils.formatDate(certificate._404_aDateTime) : "",
			section404aAmount: certificate._404_aAmount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_aAmount).toFixed(2)) : "",
			section404aWithholdingTax:
				certificate._404_aWithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_aWithholdingTax).toFixed(2)) : "",
			section404bDate: certificate._404_bDateTime > 0 ? Utils.formatDate(certificate._404_bDateTime) : "",
			section404bAmount: certificate._404_bAmount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_bAmount).toFixed(2)) : "",
			section404bWithholdingTax:
				certificate._404_bWithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_bWithholdingTax).toFixed(2)) : "",
			section404b1Date: certificate._404_b_1DateTime > 0 ? Utils.formatDate(certificate._404_b_1DateTime) : "",
			section404b1Amount: certificate._404_b_1Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1Amount).toFixed(2)) : "",
			section404b1WithholdingTax:
				certificate._404_b_1WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1WithholdingTax).toFixed(2)) : "",
			section404b11Date: certificate._404_b_1_1DateTime > 0 ? Utils.formatDate(certificate._404_b_1_1DateTime) : "",
			section404b11Amount: certificate._404_b_1_1Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_1Amount).toFixed(2)) : "",
			section404b11WithholdingTax:
				certificate._404_b_1_1WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_1WithholdingTax).toFixed(2)) : "",
			section404b12Date: certificate._404_b_1_2DateTime > 0 ? Utils.formatDate(certificate._404_b_1_2DateTime) : "",
			section404b12Amount: certificate._404_b_1_2Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_2Amount).toFixed(2)) : "",
			section404b12WithholdingTax:
				certificate._404_b_1_2WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_2WithholdingTax).toFixed(2)) : "",
			section404b13Date: certificate._404_b_1_3DateTime > 0 ? Utils.formatDate(certificate._404_b_1_3DateTime) : "",
			section404b13Amount: certificate._404_b_1_3Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_3Amount).toFixed(2)) : "",
			section404b13WithholdingTax:
				certificate._404_b_1_3WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_3WithholdingTax).toFixed(2)) : "",
			section404b14Date: certificate._404_b_1_4DateTime > 0 ? Utils.formatDate(certificate._404_b_1_4DateTime) : "",
			section404b14Amount: certificate._404_b_1_4Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_4Amount).toFixed(2)) : "",
			section404b14WithholdingTax:
				certificate._404_b_1_4WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_1_4WithholdingTax).toFixed(2)) : "",
			section404b14Other: certificate._404_b_1_4Other
				.split(ResourceAssistance.PROGRAM_DEFINED.split)
				.filter((each) => each)
				.join("/"),
			section404b2Date: certificate._404_b_2DateTime > 0 ? Utils.formatDate(certificate._404_b_2DateTime) : "",
			section404b2Amount: certificate._404_b_2Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2Amount).toFixed(2)) : "",
			section404b2WithholdingTax:
				certificate._404_b_2WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2WithholdingTax).toFixed(2)) : "",
			section404b21Date: certificate._404_b_2_1DateTime > 0 ? Utils.formatDate(certificate._404_b_2_1DateTime) : "",
			section404b21Amount: certificate._404_b_2_1Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_1Amount).toFixed(2)) : "",
			section404b21WithholdingTax:
				certificate._404_b_2_1WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_1WithholdingTax).toFixed(2)) : "",
			section404b22Date: certificate._404_b_2_2DateTime > 0 ? Utils.formatDate(certificate._404_b_2_2DateTime) : "",
			section404b22Amount: certificate._404_b_2_2Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_2Amount).toFixed(2)) : "",
			section404b22WithholdingTax:
				certificate._404_b_2_2WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_2WithholdingTax).toFixed(2)) : "",
			section404b23Date: certificate._404_b_2_3DateTime > 0 ? Utils.formatDate(certificate._404_b_2_3DateTime) : "",
			section404b23Amount: certificate._404_b_2_3Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_3Amount).toFixed(2)) : "",
			section404b23WithholdingTax:
				certificate._404_b_2_3WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_3WithholdingTax).toFixed(2)) : "",
			section404b24Date: certificate._404_b_2_4DateTime > 0 ? Utils.formatDate(certificate._404_b_2_4DateTime) : "",
			section404b24Amount: certificate._404_b_2_4Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_4Amount).toFixed(2)) : "",
			section404b24WithholdingTax:
				certificate._404_b_2_4WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_4WithholdingTax).toFixed(2)) : "",
			section404b25Date: certificate._404_b_2_5DateTime > 0 ? Utils.formatDate(certificate._404_b_2_5DateTime) : "",
			section404b25Amount: certificate._404_b_2_5Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_5Amount).toFixed(2)) : "",
			section404b25WithholdingTax:
				certificate._404_b_2_5WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._404_b_2_5WithholdingTax).toFixed(2)) : "",
			section404b25Other: certificate._404_b_2_5Other
				.split(ResourceAssistance.PROGRAM_DEFINED.split)
				.filter((each) => each)
				.join("/"),
			section5Date: certificate._5DateTime > 0 ? Utils.formatDate(certificate._5DateTime) : "",
			section5Amount: certificate._5Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._5Amount).toFixed(2)) : "",
			section5WithholdingTax: certificate._5WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._5WithholdingTax).toFixed(2)) : "",
			section5Other: certificate._5Other
				.split(ResourceAssistance.PROGRAM_DEFINED.split)
				.filter((each) => each)
				.join("/"),
			section6Date: certificate._6DateTime > 0 ? Utils.formatDate(certificate._6DateTime) : "",
			section6Amount: certificate._6Amount > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._6Amount).toFixed(2)) : "",
			section6WithholdingTax: certificate._6WithholdingTax > 0 ? Utils.formatNumWithComma(Utils.BigNumber(certificate._6WithholdingTax).toFixed(2)) : "",
			section6Other: certificate._6Other
				.split(ResourceAssistance.PROGRAM_DEFINED.split)
				.filter((each) => each)
				.join("/"),

			totalAmount: Utils.formatNumWithComma(
				Utils.BigNumber(certificate._401Amount)
					.plus(certificate._402Amount)
					.plus(certificate._403Amount)
					.plus(certificate._404_aAmount)
					.plus(certificate._404_bAmount)
					.plus(certificate._404_b_1Amount)
					.plus(certificate._404_b_1_1Amount)
					.plus(certificate._404_b_1_2Amount)
					.plus(certificate._404_b_1_3Amount)
					.plus(certificate._404_b_1_4Amount)
					.plus(certificate._404_b_2Amount)
					.plus(certificate._404_b_2_1Amount)
					.plus(certificate._404_b_2_2Amount)
					.plus(certificate._404_b_2_3Amount)
					.plus(certificate._404_b_2_4Amount)
					.plus(certificate._404_b_2_5Amount)
					.plus(certificate._5Amount)
					.plus(certificate._6Amount)
					.toFixed(2)
			),
			totalWithholdingTax: Utils.formatNumWithComma(
				Utils.BigNumber(certificate._401WithholdingTax)
					.plus(certificate._402WithholdingTax)
					.plus(certificate._403WithholdingTax)
					.plus(certificate._404_aWithholdingTax)
					.plus(certificate._404_bWithholdingTax)
					.plus(certificate._404_b_1WithholdingTax)
					.plus(certificate._404_b_1_1WithholdingTax)
					.plus(certificate._404_b_1_2WithholdingTax)
					.plus(certificate._404_b_1_3WithholdingTax)
					.plus(certificate._404_b_1_4WithholdingTax)
					.plus(certificate._404_b_2WithholdingTax)
					.plus(certificate._404_b_2_1WithholdingTax)
					.plus(certificate._404_b_2_2WithholdingTax)
					.plus(certificate._404_b_2_3WithholdingTax)
					.plus(certificate._404_b_2_4WithholdingTax)
					.plus(certificate._404_b_2_5WithholdingTax)
					.plus(certificate._5WithholdingTax)
					.plus(certificate._6WithholdingTax)
					.toFixed(2)
			),
			grandTotalLetters:
				this.getIntl().locale === LOCALES.ENGLISH
					? Utils.convertNumberToUSLetters(
							Utils.BigNumber(certificate._401WithholdingTax)
								.plus(certificate._402WithholdingTax)
								.plus(certificate._403WithholdingTax)
								.plus(certificate._404_aWithholdingTax)
								.plus(certificate._404_bWithholdingTax)
								.plus(certificate._404_b_1WithholdingTax)
								.plus(certificate._404_b_1_1WithholdingTax)
								.plus(certificate._404_b_1_2WithholdingTax)
								.plus(certificate._404_b_1_3WithholdingTax)
								.plus(certificate._404_b_1_4WithholdingTax)
								.plus(certificate._404_b_2WithholdingTax)
								.plus(certificate._404_b_2_1WithholdingTax)
								.plus(certificate._404_b_2_2WithholdingTax)
								.plus(certificate._404_b_2_3WithholdingTax)
								.plus(certificate._404_b_2_4WithholdingTax)
								.plus(certificate._404_b_2_5WithholdingTax)
								.plus(certificate._5WithholdingTax)
								.plus(certificate._6WithholdingTax)
								.toFixed(2)
					  )
					: Utils.convertNumberToThaiLetters(
							Utils.BigNumber(certificate._401WithholdingTax)
								.plus(certificate._402WithholdingTax)
								.plus(certificate._403WithholdingTax)
								.plus(certificate._404_aWithholdingTax)
								.plus(certificate._404_bWithholdingTax)
								.plus(certificate._404_b_1WithholdingTax)
								.plus(certificate._404_b_1_1WithholdingTax)
								.plus(certificate._404_b_1_2WithholdingTax)
								.plus(certificate._404_b_1_3WithholdingTax)
								.plus(certificate._404_b_1_4WithholdingTax)
								.plus(certificate._404_b_2WithholdingTax)
								.plus(certificate._404_b_2_1WithholdingTax)
								.plus(certificate._404_b_2_2WithholdingTax)
								.plus(certificate._404_b_2_3WithholdingTax)
								.plus(certificate._404_b_2_4WithholdingTax)
								.plus(certificate._404_b_2_5WithholdingTax)
								.plus(certificate._5WithholdingTax)
								.plus(certificate._6WithholdingTax)
								.toFixed(2)
					  ),
			isWithholdAtSource: certificate.withholdAtSource,
			isPND1a: certificate.type === ResourceAssistance.CONSTANT.PND1a,
			isPND1aEx: certificate.type === ResourceAssistance.CONSTANT.PND1aEx,
			isPND2: certificate.type === ResourceAssistance.CONSTANT.PND2,
			isPND2a: certificate.type === ResourceAssistance.CONSTANT.PND2a,
			isPND3: certificate.type === ResourceAssistance.CONSTANT.PND3,
			isPND3a: certificate.type === ResourceAssistance.CONSTANT.PND3a,
			isPND53: certificate.type === ResourceAssistance.CONSTANT.PND53,

			issuedDateTime: Utils.formatDate(certificate.issuedDateTime),
		}
	}
}

export default PaymentSlipPrintDataFactory
