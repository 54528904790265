import { Checkbox, Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBGeneralAnesthesia = {
	Preoxygention: "P",
	Rapid_Sequence: "R",
	Cricoid_Pressure: "C",
}
export const CBEquipment = {
	Ultrasound: "U",
	Nerve_Stimulator: "NS",
	"No Muscle Contraction At 0.2mA": "NMCA",
}
export const CBMonitoredAnesthesiaCare = {
	Sedate: "S",
}
export const RInduction = {
	Intravenous: "Int",
	Inhalation: "Inh",
}
export const RMaintenance = {
	Balanced: "B",
	TIVA: "T",
	Inhalation: "I",
	Other: "Ot",
}
export const RRegionalAnesthesia = {
	Spinal: "S",
	Epidural: "E",
	CSE: "Cs",
	Caudal: "Ca",
	Brachial_Plexus: "BP",
	Other: "Ot",
}
export const RType = {
	Cut: "C",
	Pencil: "P",
	Touhy: "T",
}
class OperatingRoomModalIntraAneTechniqueDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onGeneralAnesthesiaDetail = this.onGeneralAnesthesiaDetail.bind(this)
		this.onInduction = this.onInduction.bind(this)
		this.onMaintenance = this.onMaintenance.bind(this)
		this.onMaintenanceOther = this.onMaintenanceOther.bind(this)
		this.onRegionalAnesthesia = this.onRegionalAnesthesia.bind(this)
		this.onRegionalAnesthesiaOther = this.onRegionalAnesthesiaOther.bind(this)
		this.onAseptic = this.onAseptic.bind(this)
		this.onTechniqueApproach = this.onTechniqueApproach.bind(this)
		this.onTechniqueApproachOther = this.onTechniqueApproachOther.bind(this)
		this.onEquipmentDetail = this.onEquipmentDetail.bind(this)
		this.onSite = this.onSite.bind(this)
		this.onPosition = this.onPosition.bind(this)
		this.onNeedleSize = this.onNeedleSize.bind(this)
		this.onType = this.onType.bind(this)
		this.onCatheter = this.onCatheter.bind(this)
		this.onPunctureAttemptingCounts = this.onPunctureAttemptingCounts.bind(this)
		this.onAnesthesiaLevel = this.onAnesthesiaLevel.bind(this)
		this.onAgentUsed = this.onAgentUsed.bind(this)
		this.onMonitorAnesthesiaCare = this.onMonitorAnesthesiaCare.bind(this)
		this.onSedate = this.onSedate.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA, op.generalAnesthesia.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION, op.induction)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE, op.maintenance)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER, op.maintenanceOther)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA, op.regionalAnesthesia)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER, op.regionalAnesthesiaOther)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC, op.asepticTechniqueDone)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH, op.techniqueApproach)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER, op.techniqueApproachOther)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT, op.equipment.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE, op.site)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION, op.regionalAnesthesiaPosition)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE, op.needleSize)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE, op.type)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER, op.catheter)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS, op.punctureAttemptingCounts)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL, op.anesthesiaLevel)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED, op.agentUsed)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA, op.monitorAnesthesiaCare.split(",")[0])
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE, [op.monitorAnesthesiaCare.split(",")[1]])
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneTechnique.maintenance, this.props.operatingRoom.modalIntraAneTechnique.maintenance)) {
			if (this.props.operatingRoom.modalIntraAneTechnique.maintenance !== RMaintenance.Other) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneTechnique.regionalAnesthesia, this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia)) {
			if (this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia !== RRegionalAnesthesia.Other) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneTechnique.isTechniqueApproach, this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach)) {
			if (this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach === "false") {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE, false)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.generalAnesthesia)}:</label>
					</Col>
					<Col>
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.preoxygention),
									value: CBGeneralAnesthesia.Preoxygention,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.rapidSequence),
									value: CBGeneralAnesthesia.Rapid_Sequence,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.cricoidPressure),
									value: CBGeneralAnesthesia.Cricoid_Pressure,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail}
							onChange={this.onGeneralAnesthesiaDetail}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.induction)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							disabled={_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail)}
							value={this.props.operatingRoom.modalIntraAneTechnique.induction}
							onChange={this.onInduction}
						>
							<Radio style={{ flexDirection: "row" }} value={RInduction.Intravenous}>
								{translate(ResourceAssistance.Message.intravenous)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RInduction.Inhalation}>
								{translate(ResourceAssistance.Message.inhalation)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.maintenance)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							disabled={_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail)}
							value={this.props.operatingRoom.modalIntraAneTechnique.maintenance}
							onChange={this.onMaintenance}
						>
							<Radio style={{ flexDirection: "row" }} value={RMaintenance.Balanced}>
								{translate(ResourceAssistance.Message.balanced)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RMaintenance.TIVA}>
								{translate(ResourceAssistance.Message.TIVA)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RMaintenance.Inhalation}>
								{translate(ResourceAssistance.Message.inhalation)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RMaintenance.Other}>
								{translate(ResourceAssistance.Message.other)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalIntraAneTechnique.maintenance, RMaintenance.Other)}
							value={this.props.operatingRoom.modalIntraAneTechnique.maintenanceOther}
							onChange={this.onMaintenanceOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.regionalAnesthesia)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia} onChange={this.onRegionalAnesthesia}>
							<Radio style={{ flexDirection: "row" }} value={RRegionalAnesthesia.Spinal}>
								{translate(ResourceAssistance.Message.spinal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRegionalAnesthesia.Epidural}>
								{translate(ResourceAssistance.Message.epidural)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRegionalAnesthesia.CSE}>
								{translate(ResourceAssistance.Message.CSE)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRegionalAnesthesia.Caudal}>
								{translate(ResourceAssistance.Message.caudal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRegionalAnesthesia.Brachial_Plexus}>
								{translate(ResourceAssistance.Message.brachialPlexus)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRegionalAnesthesia.Other}>
								{translate(ResourceAssistance.Message.other)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia !== RRegionalAnesthesia.Other}
							value={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesiaOther}
							onChange={this.onRegionalAnesthesiaOther}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.asepticTechniqueDone)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							value={this.props.operatingRoom.modalIntraAneTechnique.isAseptic}
							onChange={this.onAseptic}
						>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.techniqueApproach)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							value={this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach}
							onChange={this.onTechniqueApproach}
						>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={
								this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach === "" ||
								this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach === "false"
							}
							value={this.props.operatingRoom.modalIntraAneTechnique.techniqueApproachOther}
							onChange={this.onTechniqueApproachOther}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.equipment)}:</label>
					</Col>
					<Col>
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							options={[
								{
									label: translate(ResourceAssistance.Message.ultrasound),
									value: CBEquipment.Ultrasound,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.nerveSimulator),
									value: CBEquipment.Nerve_Stimulator,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.noMuscleContractionAt),
									value: CBEquipment["No Muscle Contraction At 0.2mA"],
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneTechnique.equipmentDetail}
							onChange={this.onEquipmentDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder={translate(ResourceAssistance.Message.site)}
							value={this.props.operatingRoom.modalIntraAneTechnique.site}
							onChange={this.onSite}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder={translate(ResourceAssistance.Message.position)}
							value={this.props.operatingRoom.modalIntraAneTechnique.position}
							onChange={this.onPosition}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder={translate(ResourceAssistance.Message.needleSize)}
							value={this.props.operatingRoom.modalIntraAneTechnique.needleSize}
							onChange={this.onNeedleSize}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.type)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							value={this.props.operatingRoom.modalIntraAneTechnique.type}
							onChange={this.onType}
						>
							<Radio style={{ flexDirection: "row" }} value={RType.Cut}>
								{translate(ResourceAssistance.Message.cut)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RType.Pencil}>
								{translate(ResourceAssistance.Message.pencil)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RType.Touhy}>
								{translate(ResourceAssistance.Message.touhy)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.catheter)}:</label>
					</Col>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder={translate(ResourceAssistance.Message.skinMarking)}
							suffix={ResourceAssistance.CONSTANT.CM}
							value={this.props.operatingRoom.modalIntraAneTechnique.catheter}
							onChange={this.onCatheter}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.punctureAttemptingCounts)}:</label>
					</Col>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder=""
							value={this.props.operatingRoom.modalIntraAneTechnique.punctureAttemptingCounts}
							onChange={this.onPunctureAttemptingCounts}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.anesthesiaLevel)}:</label>
					</Col>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder=""
							value={this.props.operatingRoom.modalIntraAneTechnique.anesthesiaLevel}
							onChange={this.onAnesthesiaLevel}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={3}
							disabled={this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia === ""}
							placeholder={translate(ResourceAssistance.Message.agentUsed)}
							value={this.props.operatingRoom.modalIntraAneTechnique.agentUsed}
							onChange={this.onAgentUsed}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.monitorAnesthesiaCare)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTechnique.isMonitorAnesthesiaCare} onChange={this.onMonitorAnesthesiaCare}>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={
								this.props.operatingRoom.modalIntraAneTechnique.isMonitorAnesthesiaCare === "" ||
								this.props.operatingRoom.modalIntraAneTechnique.isMonitorAnesthesiaCare === "false"
							}
							options={[
								{
									label: translate(ResourceAssistance.Message.sedate),
									value: CBMonitoredAnesthesiaCare.Sedate,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneTechnique.monitorAnesthesiaCareDetail}
							onChange={this.onSedate}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onGeneralAnesthesiaDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA, values)
	}
	onInduction(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION, e.target.value)
	}
	onMaintenance(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE, e.target.value)
	}
	onMaintenanceOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER, e.target.value)
	}
	onRegionalAnesthesia(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA, e.target.value)
	}
	onRegionalAnesthesiaOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER, e.target.value)
	}
	onAseptic(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC, e.target.value)
	}
	onTechniqueApproach(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH, e.target.value)
	}
	onTechniqueApproachOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER, e.target.value)
	}
	onEquipmentDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT, values)
	}
	onSite(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE, e.target.value)
	}
	onPosition(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION, e.target.value)
	}
	onNeedleSize(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE, e.target.value)
	}
	onType(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE, e.target.value)
	}
	onCatheter(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER, e.target.value)
	}
	onPunctureAttemptingCounts(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS, e.target.value)
	}
	onAnesthesiaLevel(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL, e.target.value)
	}
	onAgentUsed(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED, e.target.value)
	}
	onMonitorAnesthesiaCare(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA, e.target.value)
	}
	onSedate(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE, values)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneTechniqueDetails)
