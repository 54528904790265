import _ from "lodash"
import { createIntl, createIntlCache } from "react-intl"
import { ResourceAssistance } from "~/i18n"
import Messages from "~/i18n/message"
import { store } from "~/redux/Store"
import { Utils } from "../Utils"

const locale = store.getState().language.locale
const messages = Messages[locale]
const cache = createIntlCache()
const intl = createIntl({ locale, messages }, cache)

class ExcelDataFactory {
	static generateHospitelPatients = (hospitels, department) => {
		let headers = [
			intl.formatMessage({ id: ResourceAssistance.Message.date }),
			intl.formatMessage({ id: ResourceAssistance.Message.time }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.patientId }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.hn }),
			intl.formatMessage({ id: ResourceAssistance.Message.name }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			intl.formatMessage({ id: ResourceAssistance.Message.room }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.discharge }),
		]

		let excel = {
			filename: department.displayName,
			sheets: [
				{
					name: intl.formatMessage({ id: ResourceAssistance.Hospitel.patient }),
					headers: headers,
					data: hospitels
						.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
						.map((hospitel) => {
							let body = [
								Utils.formatDate(hospitel.creationDateTime),
								Utils.formatTime(hospitel.creationDateTime),
								hospitel.patient.id,
								hospitel.patient.identificationNumber,
								hospitel.admission.clinicalRecord.hn,
								hospitel.patient.firstName + " " + hospitel.patient.lastName,
								Utils.formatDate(hospitel.patient.dobtime),

								Utils.calculateAge(hospitel.patient.dobtime) +
									" " +
									intl.formatMessage({ id: ResourceAssistance.Hospitel.year }) +
									" " +
									Utils.calculateAgeMonth(hospitel.patient.dobtime) +
									" " +
									intl.formatMessage({ id: ResourceAssistance.Hospitel.month }),
								hospitel.room,
								hospitel.dischargeSummary ? Utils.formatDateTime(hospitel.dischargeSummary.creationDateTime) : "",
							]
							return _.zipObject(Object.keys(Object.assign({}, headers)), body)
						}),
				},
			],
		}
		return excel
	}

	static generateCashierAdmission = (admissionId, patient, billingStatements) => {
		let headers = [
			intl.formatMessage({ id: ResourceAssistance.Message.date }),
			intl.formatMessage({ id: ResourceAssistance.Message.time }),
			intl.formatMessage({ id: ResourceAssistance.Message.fsCode }),
			intl.formatMessage({ id: ResourceAssistance.Message.code }),
			intl.formatMessage({ id: ResourceAssistance.Message.categoryNum }),
			intl.formatMessage({ id: ResourceAssistance.Message.description }),
			intl.formatMessage({ id: ResourceAssistance.Message.unit }),
			intl.formatMessage({ id: ResourceAssistance.Message.totalPrice }),
		]
		let excel = {
			filename: patient.id + "_" + patient.firstName + "_" + patient.lastName,
			sheets: [
				{
					name: admissionId,
					headers: headers,
					data: billingStatements
						.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
						.map((billingStatement) => {
							let body = [
								Utils.formatDate(billingStatement.dateTime),
								Utils.formatTime(billingStatement.dateTime),
								billingStatement.fsCode,
								billingStatement.code,
								billingStatement.categoryNum,
								billingStatement.description,
								billingStatement.unit,
								Utils.formatNumWithComma(Utils.BigNumber(billingStatement.totalPrice).toFixed(2)),
							]
							return _.zipObject(Object.keys(Object.assign({}, headers)), body)
						}),
				},
			],
		}
		return excel
	}

	static generateCashierByUser = (role, user, startDateTime, endDateTime, orders) => {
		let headers = [
			intl.formatMessage({ id: ResourceAssistance.Message.date }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.patientId }),
			intl.formatMessage({ id: ResourceAssistance.Hospitel.healthCare }),
			intl.formatMessage({ id: ResourceAssistance.Message.description }),
			intl.formatMessage({ id: ResourceAssistance.Message.amount }),
		]
		let excel = {
			filename:
				user.firstName +
				"_" +
				user.lastName +
				"_" +
				user.licenseNum +
				"_" +
				Utils.formatDateTime(startDateTime) +
				"_" +
				Utils.formatDateTime(endDateTime),
			sheets: [
				{
					name: role.displayName,
					headers: headers,
					data: orders
						.sort((a, b) => Utils.sort(a.date, b.date))
						.map((order) => {
							let body = [order.date, order.pid, order.healthCare, order.description, order.amount]
							return _.zipObject(Object.keys(Object.assign({}, headers)), body)
						}),
				},
			],
		}
		return excel
	}
}

export { ExcelDataFactory }
