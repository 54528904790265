import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setRcReceived } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RCRightSecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCRightSecondRow",
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.selectedInvoice.invoice) !== JSON.stringify(this.props.selectedInvoice.invoice)) {
			if (this.props.selectedInvoice.invoice) {
				this.props.setRcReceived(this.props.selectedInvoice.invoice.receivedItems)
			} else {
				this.props.setRcReceived([])
			}
		}
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable striped bordered hover enableOverlay data={this.props.received} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	received: state.receive.received,
	selectedInvoice: state.receive.selectedInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setRcReceived }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCRightSecondRow)
