import { Checkbox, Divider } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setHospitelPatients, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_FILTER_INPATIENT_CHECKED,
	SET_HOSPITEL_FILTER_NURSE_CHECKED,
	SET_HOSPITEL_FILTER_SOAP_CHECKED,
	SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED,
} from "~/redux/type"

class HospitelLeftFilterRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSOAP = this.onSOAP.bind(this)
		this.onNurseNote = this.onNurseNote.bind(this)
		this.onVitalSign = this.onVitalSign.bind(this)
		this.onInpatient = this.onInpatient.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.hospitel.soapChecked !== this.props.hospitel.soapChecked ||
			prevProps.hospitel.nurseChecked !== this.props.hospitel.nurseChecked ||
			prevProps.hospitel.vitalSignChecked !== this.props.hospitel.vitalSignChecked ||
			prevProps.hospitel.inpatientChecked !== this.props.hospitel.inpatientChecked
		) {
			this.props.setHospitelPatients(this.props.hospitel.patientTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_FILTER_SOAP_CHECKED, false)
		this.props.setValue(SET_HOSPITEL_FILTER_NURSE_CHECKED, false)
		this.props.setValue(SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED, false)
		this.props.setValue(SET_HOSPITEL_FILTER_INPATIENT_CHECKED, false)
	}

	onSOAP(event) {
		this.props.setValue(SET_HOSPITEL_FILTER_SOAP_CHECKED, event.target.checked)
	}

	onNurseNote(event) {
		this.props.setValue(SET_HOSPITEL_FILTER_NURSE_CHECKED, event.target.checked)
	}

	onVitalSign(event) {
		this.props.setValue(SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED, event.target.checked)
	}

	onInpatient(event) {
		this.props.setValue(SET_HOSPITEL_FILTER_INPATIENT_CHECKED, event.target.checked)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md={"auto"}>
					<Checkbox style={{ flexDirection: "row" }} onChange={this.onInpatient} checked={this.props.hospitel.inpatientChecked}>
						{translate(ResourceAssistance.Message.inPatient)}
					</Checkbox>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md={"auto"}>
					<Checkbox style={{ flexDirection: "row" }} onChange={this.onSOAP} checked={this.props.hospitel.soapChecked}>
						{translate(ResourceAssistance.Hospitel.soap)}
					</Checkbox>
				</Col>
				<Col md={"auto"}>
					<Checkbox style={{ flexDirection: "row" }} onChange={this.onNurseNote} checked={this.props.hospitel.nurseChecked}>
						{translate(ResourceAssistance.Hospitel.nurse)}
					</Checkbox>
				</Col>
				<Col md={"auto"}>
					<Checkbox style={{ flexDirection: "row" }} onChange={this.onVitalSign} checked={this.props.hospitel.vitalSignChecked}>
						{translate(ResourceAssistance.Hospitel.vitalSign)}
					</Checkbox>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setHospitelPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelLeftFilterRow)
