import { Select } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setPharmacyPatients, setValue } from "~/redux/action"
import { SET_PHARMACY_FILTER, SET_PHARMACY_SEARCH_IDENTIFICATION, SET_PHARMACY_SEARCH_PID } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

export const CPharmacyFilters = [
	{
		displayName: ResourceAssistance.CONSTANT.STAT,
	},
]

class PharmacyLeftSearch extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectFilter = this.onSelectFilter.bind(this)
		this.onDeselectFilter = this.onDeselectFilter.bind(this)
		this.onPatientId = this.onPatientId.bind(this)
		this.onId = this.onId.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.pharmacy.searchPID !== this.props.pharmacy.searchPID ||
			prevProps.pharmacy.searchIdentification !== this.props.pharmacy.searchIdentification
		) {
			this.props.setPharmacyPatients(this.props.pharmacy.patientTable.original)
		}
	}

	onSelectFilter(value) {
		let filters = Object.assign([], this.props.pharmacy.filters)
		filters.push(CPharmacyFilters[value].displayName)
		this.props.setValue(SET_PHARMACY_FILTER, filters)
	}

	onDeselectFilter(value) {
		let filters = Object.assign([], this.props.pharmacy.filters)
		filters = filters.filter((each) => each !== CPharmacyFilters[value].displayName)
		this.props.setValue(SET_PHARMACY_FILTER, filters)
	}

	onPatientId(event) {
		this.props.setValue(SET_PHARMACY_SEARCH_PID, event.target.value)
	}

	onId(event) {
		this.props.setValue(SET_PHARMACY_SEARCH_IDENTIFICATION, event.target.value)
	}

	render() {
		return (
			<Row className={"g-0"}>
				<Col md="auto" style={{ marginRight: "0.3rem" }}>
					<Select
						mode={ResourceAssistance.Select.mode.multiple}
						allowClear
						showSearch
						style={{ flexGrow: 1, textAlign: "center", width: "100px" }}
						maxTagCount="responsive"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
						}
						placeholder={translate(ResourceAssistance.Message.filter)}
						onSelect={this.onSelectFilter}
						onDeselect={this.onDeselectFilter}
					>
						{Utils.renderSelects(CPharmacyFilters, false)}
					</Select>
				</Col>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Hospitel.patientId, ResourceAssistance.Hospitel.identification]}
						callbacks={[this.onPatientId, this.onId]}
						values={[this.props.pharmacy.searchPID, this.props.pharmacy.searchIdentification]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPharmacyPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyLeftSearch)
