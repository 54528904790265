import { TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_PS_MODAL_GL_DESCRIPTION, SET_PS_MODAL_GL_SELECTED_GL } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class PSModalGeneralLedgerDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCOA = this.onCOA.bind(this)
		this.onDescription = this.onDescription.bind(this)
	}

	componentDidMount() {
		let selectedGroup = this.props.PS.selectedGroup
		let keys = selectedGroup.group[0].split("#")
		if (keys.length > 1) {
			let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.PS.chartOfAccounts, keys[0])
			if (!_.isEmpty(selectedAry)) {
				this.props.setSelected(SET_PS_MODAL_GL_SELECTED_GL, selectedAry[0], selectedAry[1])
			} else {
				this.props.setSelected(SET_PS_MODAL_GL_SELECTED_GL, -1, null)
			}
			this.props.setValue(
				SET_PS_MODAL_GL_DESCRIPTION,
				Utils.decodeAccountingTransDescr(
					keys[2],
					this.props.PS.selectedSupplier.supplier.supplierName,
					Utils.formatDate(this.props.PS.selectedSupplier.supplier.creationDateTime)
				)
			)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PS_MODAL_GL_SELECTED_GL, -1, null)
		this.props.setValue(SET_PS_MODAL_GL_DESCRIPTION, "")
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PS_MODAL_GL_SELECTED_GL, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_PS_MODAL_GL_SELECTED_GL,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.PS.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_PS_MODAL_GL_SELECTED_GL, value, this.props.PS.chartOfAccounts[value])
		}
	}

	onDescription(e) {
		this.props.setValue(SET_PS_MODAL_GL_DESCRIPTION, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							loading={this.props.PS.isChartOfAccountsLoading}
							value={this.props.PS.modalGeneralLedger.selectedGL.GL ? this.props.PS.modalGeneralLedger.selectedGL.index : undefined}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.PS.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"], false)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.text}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.PS.modalGeneralLedger.description}
							onChange={this.onDescription}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalGeneralLedgerDetails))
