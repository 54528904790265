import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import OperatingRoomModalEditPreHistory from "./OperatingRoomModalEditPreHistory"
import OperatingRoomModalEditPrePhysicalExam from "./OperatingRoomModalEditPrePhysicalExam"
import OperatingRoomModalEditPreInvestigation from "./OperatingRoomModalEditPreInvestigation"
import OperatingRoomModalEditPrePrepared from "./OperatingRoomModalEditPrePrepared"
import OperatingRoomModalEditPreAnesthesiaPlan from "./OperatingRoomModalEditPreAnesthesiaPlan"

class OperatingRoomModalEditPreDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.healthHistory)}</legend>
					<OperatingRoomModalEditPreHistory />
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.physicalExamination)}</legend>
					<OperatingRoomModalEditPrePhysicalExam />
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.investigation)}</legend>
					<OperatingRoomModalEditPreInvestigation />
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.bloodComponentsPrepared)}</legend>
					<OperatingRoomModalEditPrePrepared />
				</fieldset>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.anesthesiaPlan)}</legend>
					<OperatingRoomModalEditPreAnesthesiaPlan />
				</fieldset>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPreDetails)
