
export const SET_HOSPITEL_DEPARTMENT = "SET_HOSPITEL_DEPARTMENT"
export const SET_HOSPITEL_END_DATETIME = "SET_HOSPITEL_END_DATETIME"
export const SET_HOSPITEL_FILTER_INPATIENT_CHECKED = "SET_HOSPITEL_FILTER_INPATIENT_CHECKED"
export const SET_HOSPITEL_FILTER_NURSE_CHECKED = "SET_HOSPITEL_FILTER_NURSE_CHECKED"
export const SET_HOSPITEL_FILTER_SOAP_CHECKED = "SET_HOSPITEL_FILTER_SOAP_CHECKED"
export const SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED = "SET_HOSPITEL_FILTER_VITAL_SIGN_CHECKED"
export const SET_HOSPITEL_HOSPITELS = "SET_HOSPITEL_HOSPITELS"
export const SET_HOSPITEL_HOSPITEL_NEW_DISPLAY = "SET_HOSPITEL_HOSPITEL_NEW_DISPLAY"
export const SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY = "SET_HOSPITEL_HOSPITEL_SEARCH_DISPLAY"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND = "SET_HOSPITEL_MODAL_ADJUSTMENT_DISABLE_END_DATE_IND"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY = "SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME = "SET_HOSPITEL_MODAL_ADJUSTMENT_END_DATE_TIME"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES = "SET_HOSPITEL_MODAL_ADJUSTMENT_NOTES"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY = "SET_HOSPITEL_MODAL_ADJUSTMENT_QTY_PER_DAY"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND = "SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISABLE_END_DATE_IND"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY = "SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_DISPLAY"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME = "SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_END_DATE_TIME"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES = "SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_NOTES"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE = "SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_SERVICE"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME = "SET_HOSPITEL_MODAL_ADJUSTMENT_SERVICE_START_DATE_TIME"
export const SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME = "SET_HOSPITEL_MODAL_ADJUSTMENT_START_DATE_TIME"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_ADMIT_HOSPITEL"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_DESCRIPTION"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_DIET"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_END_DATETIME"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_MONITOR"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_CANNULAR"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_OXYGEN_MASK"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_START_DATETIME"
export const SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN = "SET_HOSPITEL_MODAL_DEFAULT_ORDER_VITAL_SIGN"
export const SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT = "SET_HOSPITEL_MODAL_DOCTOR_NOTE_ASSESSMENT"
export const SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME = "SET_HOSPITEL_MODAL_DOCTOR_NOTE_DATETIME"
export const SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY = "SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY"
export const SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE = "SET_HOSPITEL_MODAL_DOCTOR_NOTE_OBJECTIVE"
export const SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN = "SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN"
export const SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE = "SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_DURATION_QTY"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_END_DATETIME"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEMS = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEMS"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_ITEM_LOADING"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_NOTES"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_PENDING_ITEMS = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_PENDING_ITEMS"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_PRN_CHECK"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_SEARCH_DESCRIPTION"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_DURATION"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_TYPE"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_UNIT"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_START_DATETIME"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_TYPES = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_TYPES"
export const SET_HOSPITEL_MODAL_DOCTOR_ORDER_UNITS = "SET_HOSPITEL_MODAL_DOCTOR_ORDER_UNITS"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_CATEGORIES = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_CATEGORIES"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_PENDING_SERVICES = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_PENDING_SERVICES"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SEARCH_NAME = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SEARCH_NAME"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_CATEGORY"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICES = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICES"
export const SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING = "SET_HOSPITEL_MODAL_DOCTOR_SERVICE_SERVICE_LOADING"
export const SET_HOSPITEL_MODAL_LOCATION_DISPLAY = "SET_HOSPITEL_MODAL_LOCATION_DISPLAY"
export const SET_HOSPITEL_MODAL_LOCATION_ORGS = "SET_HOSPITEL_MODAL_LOCATION_ORGS"
export const SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH = "SET_HOSPITEL_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT = "SET_HOSPITEL_MODAL_LOCATION_SELECTED_DEPARTMENT"
export const SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG = "SET_HOSPITEL_MODAL_LOCATION_SELECTED_ORG"
export const SET_HOSPITEL_MODAL_MEDICAL_SUPPLY_TYPES = "SET_HOSPITEL_MODAL_MEDICAL_SUPPLY_TYPES"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY = "SET_HOSPITEL_MODAL_NURSE_ORDER_DISPLAY"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_ITEMS = "SET_HOSPITEL_MODAL_NURSE_ORDER_ITEMS"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING = "SET_HOSPITEL_MODAL_NURSE_ORDER_ITEM_LOADING"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_PENDING_ITEMS = "SET_HOSPITEL_MODAL_NURSE_ORDER_PENDING_ITEMS"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_SEARCH_NAME = "SET_HOSPITEL_MODAL_NURSE_ORDER_SEARCH_NAME"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM = "SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_ITEM"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM = "SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_PENDING_ITEM"
export const SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_TYPE = "SET_HOSPITEL_MODAL_NURSE_ORDER_SELECTED_TYPE"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT = "SET_HOSPITEL_MODAL_NURSE_RECORD_ASSESSMENT"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES = "SET_HOSPITEL_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME = "SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY = "SET_HOSPITEL_MODAL_NURSE_RECORD_DISPLAY"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_EVALUATION = "SET_HOSPITEL_MODAL_NURSE_RECORD_EVALUATION"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_EVALUATION_SELECTED_CATEGORY = "SET_HOSPITEL_MODAL_NURSE_RECORD_EVALUATION_SELECTED_CATEGORY"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS = "SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS_SELECTED_CATEGORY = "SET_HOSPITEL_MODAL_NURSE_RECORD_FOCUS_SELECTED_CATEGORY"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION = "SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION"
export const SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION_SELECTED_CATEGORY = "SET_HOSPITEL_MODAL_NURSE_RECORD_INTERVENTION_SELECTED_CATEGORY"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_CATEGORIES = "SET_HOSPITEL_MODAL_NURSE_SERVICE_CATEGORIES"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY = "SET_HOSPITEL_MODAL_NURSE_SERVICE_DISPLAY"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_PENDING_SERVICES = "SET_HOSPITEL_MODAL_NURSE_SERVICE_PENDING_SERVICES"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_SEARCH_NAME = "SET_HOSPITEL_MODAL_NURSE_SERVICE_SEARCH_NAME"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_CATEGORY = "SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_CATEGORY"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE = "SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_PENDING_SERVICE"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE = "SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICES = "SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICES"
export const SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING = "SET_HOSPITEL_MODAL_NURSE_SERVICE_SERVICE_LOADING"
export const SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM = "SET_HOSPITEL_MODAL_REGISTER_IDENTIFICATION_NUM"
export const SET_HOSPITEL_MODAL_REGISTER_PATIENT = "SET_HOSPITEL_MODAL_REGISTER_PATIENT"
export const SET_HOSPITEL_MODAL_REGISTER_ROOM = "SET_HOSPITEL_MODAL_REGISTER_ROOM"
export const SET_HOSPITEL_MODAL_SEARCH_FIRST_NAME = "SET_HOSPITEL_MODAL_SEARCH_FIRST_NAME"
export const SET_HOSPITEL_MODAL_SEARCH_ID = "SET_HOSPITEL_MODAL_SEARCH_ID"
export const SET_HOSPITEL_MODAL_SEARCH_LAST_NAME = "SET_HOSPITEL_MODAL_SEARCH_LAST_NAME"
export const SET_HOSPITEL_MODAL_SEARCH_PID = "SET_HOSPITEL_MODAL_SEARCH_PID"
export const SET_HOSPITEL_MODAL_STOP_END_DATETIME = "SET_HOSPITEL_MODAL_STOP_END_DATETIME"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_BP = "SET_HOSPITEL_MODAL_VITAL_SIGN_BP"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME = "SET_HOSPITEL_MODAL_VITAL_SIGN_DATETIME"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY = "SET_HOSPITEL_MODAL_VITAL_SIGN_DISPLAY"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN = "SET_HOSPITEL_MODAL_VITAL_SIGN_OXYGEN"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_PR = "SET_HOSPITEL_MODAL_VITAL_SIGN_PR"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_RR = "SET_HOSPITEL_MODAL_VITAL_SIGN_RR"
export const SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE = "SET_HOSPITEL_MODAL_VITAL_SIGN_TEMPERATURE"
export const SET_HOSPITEL_NURSE_NOTES = "SET_HOSPITEL_NURSE_NOTES"
export const SET_HOSPITEL_NURSE_ORDERS = "SET_HOSPITEL_NURSE_ORDERS"
export const SET_HOSPITEL_ORDERS = "SET_HOSPITEL_ORDERS"
export const SET_HOSPITEL_ORDER_END_DATE = "SET_HOSPITEL_ORDER_END_DATE"
export const SET_HOSPITEL_PATIENTS = "SET_HOSPITEL_PATIENTS"
export const SET_HOSPITEL_PATIENTS_LOADING = "SET_HOSPITEL_PATIENTS_LOADING"
export const SET_HOSPITEL_SEARCH_ID = "SET_HOSPITEL_SEARCH_ID"
export const SET_HOSPITEL_SEARCH_PATIENT_ID = "SET_HOSPITEL_SEARCH_PATIENT_ID"
export const SET_HOSPITEL_SELECTED_HOSPITEL = "SET_HOSPITEL_SELECTED_HOSPITEL"
export const SET_HOSPITEL_SELECTED_NURSE_NOTE = "SET_HOSPITEL_SELECTED_NURSE_NOTE"
export const SET_HOSPITEL_SELECTED_NURSE_ORDER = "SET_HOSPITEL_SELECTED_NURSE_ORDER"
export const SET_HOSPITEL_SELECTED_ORDER = "SET_HOSPITEL_SELECTED_ORDER"
export const SET_HOSPITEL_SELECTED_PAITENT = "SET_HOSPITEL_SELECTED_PAITENT"
export const SET_HOSPITEL_SELECTED_SOAP = "SET_HOSPITEL_SELECTED_SOAP"
export const SET_HOSPITEL_SELECTED_VITAL_SIGN = "SET_HOSPITEL_SELECTED_VITAL_SIGN"
export const SET_HOSPITEL_SOAPS = "SET_HOSPITEL_SOAPS"
export const SET_HOSPITEL_START_DATETIME = "SET_HOSPITEL_START_DATETIME"
export const SET_HOSPITEL_STOP_DISPLAY = "SET_HOSPITEL_STOP_DISPLAY"
export const SET_HOSPITEL_VITAL_SIGNS = "SET_HOSPITEL_VITAL_SIGNS"
export const SET_IPD_CERTIFICATES = "SET_IPD_CERTIFICATES"
export const SET_IPD_DOCTOR_INFO_DISPLAY = "SET_IPD_DOCTOR_INFO_DISPLAY"
export const SET_IPD_DOCTOR_SOAP_INFO_DISPLAY = "SET_IPD_DOCTOR_SOAP_INFO_DISPLAY"
export const SET_IPD_DTX_DISPLAY = "SET_IPD_DTX_DISPLAY"
export const SET_IPD_GCS = "SET_IPD_GCS"
export const SET_IPD_GCS_DISPLAY = "SET_IPD_GCS_DISPLAY"
export const SET_IPD_GLUCOSE = "SET_IPD_GLUCOSE"
export const SET_IPD_ICD_DISPLAY = "SET_IPD_ICD_DISPLAY"
export const SET_IPD_ICD_TYPES = "SET_IPD_ICD_TYPES"
export const SET_IPD_INTAKE_OUTPUTS = "SET_IPD_INTAKE_OUTPUTS"
export const SET_IPD_INTAKE_OUTPUT_DISPLAY = "SET_IPD_INTAKE_OUTPUT_DISPLAY"
export const SET_IPD_INTAKE_TYPES = "SET_IPD_INTAKE_TYPES"
export const SET_IPD_ITEMS = "SET_IPD_ITEMS"
export const SET_IPD_LEVELS = "SET_IPD_LEVELS"
export const SET_IPD_MEDICAL_CERTIFICATE_DISPLAY = "SET_IPD_MEDICAL_CERTIFICATE_DISPLAY"
export const SET_IPD_MODAL_ADJUSTMENT_SELECTED_UNIT = "SET_IPD_MODAL_ADJUSTMENT_SELECTED_UNIT"
export const SET_IPD_MODAL_ADJUSTMENT_TAKE_HOME_CHECK = "SET_IPD_MODAL_ADJUSTMENT_TAKE_HOME_CHECK"
export const SET_IPD_MODAL_ADJUSTMENT_UNITS = "SET_IPD_MODAL_ADJUSTMENT_UNITS"
export const SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME = "SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_DATETIME"
export const SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY = "SET_IPD_MODAL_CREATE_PRN_RECORD_DISPENSING_QTY"
export const SET_IPD_MODAL_DOCTOR_ORDER_EXCLUSIVE_DAYS = "SET_IPD_MODAL_DOCTOR_ORDER_EXCLUSIVE_DAYS"
export const SET_IPD_MODAL_DOCTOR_ORDER_ONE_TIME_CHECK = "SET_IPD_MODAL_DOCTOR_ORDER_ONE_TIME_CHECK"
export const SET_IPD_MODAL_DOCTOR_ORDER_RATE_DURATION = "SET_IPD_MODAL_DOCTOR_ORDER_RATE_DURATION"
export const SET_IPD_MODAL_DOCTOR_ORDER_SELECTED_RATE = "SET_IPD_MODAL_DOCTOR_ORDER_SELECTED_RATE"
export const SET_IPD_MODAL_DOCTOR_ORDER_STAT_CHECK = "SET_IPD_MODAL_DOCTOR_ORDER_STAT_CHECK"
export const SET_IPD_MODAL_DOCTOR_ORDER_TAKE_HOME_CHECK = "SET_IPD_MODAL_DOCTOR_ORDER_TAKE_HOME_CHECK"
export const SET_IPD_MODAL_LOCATION_ORGS_LOADING = "SET_IPD_MODAL_LOCATION_ORGS_LOADING"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_CHECK"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_BLOOD_TEST_DETAILS"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_CATEGORIES"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_DOCTOR_OPINION"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_END"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_EXAMINATION_START"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_INVESTIGATION"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_CHECK"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_RADIOLOGY_DETAILS"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_END"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_REST_START"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_ROLES = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_ROLES"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_ROLE"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_SERVICES = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_SERVICES"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_SYMPTOMS"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT"
export const SET_IPD_MODAL_MEDICAL_CERTIFICATE_USERS = "SET_IPD_MODAL_MEDICAL_CERTIFICATE_USERS"
export const SET_IPD_MODAL_NURSE_RECORD_ASSESSMENT_SELECTED_CATEGORY = "SET_IPD_MODAL_NURSE_RECORD_ASSESSMENT_SELECTED_CATEGORY"
export const SET_IPD_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES_LOADING = "SET_IPD_MODAL_NURSE_RECORD_CUSTOM_DEFAULT_CATEGORIES_LOADING"
export const SET_IPD_MODAL_PRN_RECORD_RECORDS = "SET_IPD_MODAL_PRN_RECORD_RECORDS"
export const SET_IPD_NURSE_INFO_DISPLAY = "SET_IPD_NURSE_INFO_DISPLAY"
export const SET_IPD_OUTPUT_TYPES = "SET_IPD_OUTPUT_TYPES"
export const SET_IPD_PAIN_SCALE = "SET_IPD_PAIN_SCALE"
export const SET_IPD_PAIN_SCALE_DISPLAY = "SET_IPD_PAIN_SCALE_DISPLAY"
export const SET_IPD_PUPIL_SIZE = "SET_IPD_PUPIL_SIZE"
export const SET_IPD_PUPIL_SIZE_DISPLAY = "SET_IPD_PUPIL_SIZE_DISPLAY"
export const SET_IPD_SELECTED_CERTIFICATE = "SET_IPD_SELECTED_CERTIFICATE"
export const SET_IPD_SELECTED_DTX = "SET_IPD_SELECTED_DTX"
export const SET_IPD_SELECTED_GCS = "SET_IPD_SELECTED_GCS"
export const SET_IPD_SELECTED_INTAKE_OUTPUT = "SET_IPD_SELECTED_INTAKE_OUTPUT"
export const SET_IPD_SELECTED_PAIN_SCALE = "SET_IPD_SELECTED_PAIN_SCALE"
export const SET_IPD_SELECTED_PUPIL_SIZE = "SET_IPD_SELECTED_PUPIL_SIZE"
export const SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY = "SET_REPORT_HOSPITAL_HOSPITEL_PATIENT_FILTER_DISPLAY"