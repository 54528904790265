import { Radio, Space } from "antd"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ADM_MODAL_NEW_SKIP_IND, SET_ADM_MODAL_NEW_WHT_TYPE } from "~/redux/type"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class TypeModalNewWHT extends React.Component {
	ERadio = {
		None: {
			description: ResourceAssistance.CONSTANT.NONE,
			value: ResourceAssistance.CONSTANT.NONE,
		},
		PND1a: {
			description: translate(ResourceAssistance.Message.pnd1a),
			value: ResourceAssistance.CONSTANT.PND1a,
		},
		PND1aEx: {
			description: translate(ResourceAssistance.Message.pnd1aEx),
			value: ResourceAssistance.CONSTANT.PND1aEx,
		},
		PND2: {
			description: translate(ResourceAssistance.Message.pnd2),
			value: ResourceAssistance.CONSTANT.PND2,
		},
		PND2a: {
			description: translate(ResourceAssistance.Message.pnd2a),
			value: ResourceAssistance.CONSTANT.PND2a,
		},
		PND3: {
			description: translate(ResourceAssistance.Message.pnd3),
			value: ResourceAssistance.CONSTANT.PND3,
		},
		PND3a: {
			description: translate(ResourceAssistance.Message.pnd3a),
			value: ResourceAssistance.CONSTANT.PND3a,
		},
		PND53: {
			description: translate(ResourceAssistance.Message.pnd53),
			value: ResourceAssistance.CONSTANT.PND53,
		},
	}
	constructor(props) {
		super(props)

		this.state = {
			eye: this.ERadio.PND1a.value,
		}
		this.onWithholdingTaxType = this.onWithholdingTaxType.bind(this)
		this.onSkip = this.onSkip.bind(this)
	}

	componentDidMount() {
		if (this.props.type.selectedType.type) {
			this.props.setValue(SET_ADM_MODAL_NEW_WHT_TYPE, this.props.type.selectedType.type.withholdingTaxType)
			this.props.setValue(SET_ADM_MODAL_NEW_SKIP_IND, this.props.type.selectedType.type.skipPercent)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_MODAL_NEW_WHT_TYPE, ResourceAssistance.CONSTANT.NONE)
		this.props.setValue(SET_ADM_MODAL_NEW_SKIP_IND, false)
	}

	onWithholdingTaxType(e) {
		this.props.setValue(SET_ADM_MODAL_NEW_WHT_TYPE, e.target.value)
	}

	onSkip(e) {
		this.props.setValue(SET_ADM_MODAL_NEW_SKIP_IND, e.target.checked)
	}

	render() {
		return (
			<Fragment>
				{this.props.type.modal.displayPercent && (
					<>
						<Row>
							<Col md={1} />
							<Col>
								<Radio.Group value={this.props.type.modalNew.withholdingTaxType} onChange={this.onWithholdingTaxType}>
									<Space wrap>
										<Radio value={this.ERadio.None.value}>{this.ERadio.None.description}</Radio>
										<Radio value={this.ERadio.PND1a.value}>{this.ERadio.PND1a.description}</Radio>
										<Radio value={this.ERadio.PND1aEx.value}>{this.ERadio.PND1aEx.description}</Radio>
										<Radio value={this.ERadio.PND2.value}>{this.ERadio.PND2.description}</Radio>
										<Radio value={this.ERadio.PND2a.value}>{this.ERadio.PND2a.description}</Radio>
										<Radio value={this.ERadio.PND3.value}>{this.ERadio.PND3.description}</Radio>
										<Radio value={this.ERadio.PND3a.value}>{this.ERadio.PND3a.description}</Radio>
										<Radio value={this.ERadio.PND53.value}>{this.ERadio.PND53.description}</Radio>
									</Space>
								</Radio.Group>
							</Col>
						</Row>
						<Row>
							<Col />
							<Col>
								<label>{translate(ResourceAssistance.Message.skipPercent)}</label>
							</Col>
							<Col>
								<ToggleCheckBox name={ResourceAssistance.Message.skipPercent} onClick={this.onSkip} checked={this.props.type.modalNew.isSkipPercent} />
							</Col>
							<Col />
						</Row>
					</>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewWHT)
