export const SET_SA_BUDGET_DISPLAY = "SET_SA_BUDGET_DISPLAY"
export const SET_SA_COST_DISPLAY = "SET_SA_COST_DISPLAY"
export const SET_SA_LOCATION = "SET_SA_LOCATION"
export const SET_SA_LOCATION_DISPLAY = "SET_SA_LOCATION_DISPLAY"
export const SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE = "SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE"
export const SET_SA_MODAL_BUDGET_DOCTOR_FEE = "SET_SA_MODAL_BUDGET_DOCTOR_FEE"
export const SET_SA_MODAL_BUDGET_DRG = "SET_SA_MODAL_BUDGET_DRG"
export const SET_SA_MODAL_LOCATION_SELECTED_BRANCH = "SET_SA_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_SA_MODAL_LOCATION_SELECTED_ORG = "SET_SA_MODAL_LOCATION_SELECTED_ORG"
export const SET_SA_MODAL_REJECT_REASON = "SET_SA_MODAL_REJECT_REASON"
export const SET_SA_MODAL_INSPECT_REASON = "SET_SA_MODAL_INSPECT_REASON"
export const SET_SA_OPERATING_ROOM_REQUESTS = "SET_SA_OPERATING_ROOM_REQUESTS"
export const SET_SA_OPERATING_ROOM_REQUESTS_LOADING = "SET_SA_OPERATING_ROOM_REQUESTS_LOADING"
export const SET_SA_ORGS = "SET_SA_ORGS"
export const SET_SA_ORGS_LOADING = "SET_SA_ORGS_LOADING"
export const SET_SA_REJECT_DISPLAY = "SET_SA_REJECT_DISPLAY"
export const SET_SA_INSPECT_DISPLAY = "SET_SA_INSPECT_DISPLAY"
export const SET_SA_SEARCH_DISPLAY = "SET_SA_SEARCH_DISPLAY"
export const SET_SA_SELECTED_OR_REQUEST = "SET_SA_SELECTED_OR_REQUEST"
export const SET_SA_HEALTH_CARE_PLANS = "SET_SA_HEALTH_CARE_PLANS"
export const SET_SA_HEALTH_CARE_PLANS_LOADING = "SET_SA_HEALTH_CARE_PLANS_LOADING"
export const SET_SA_SERVICES = "SET_SA_SERVICES"
export const SET_SA_SERVICES_LOADING = "SET_SA_SERVICES_LOADING"
export const SET_SA_TYPES_LOADING = "SET_SA_TYPES_LOADING"
export const SET_SA_TYPES = "SET_SA_TYPES"
export const SET_SA_ITEMS_LOADING = "SET_SA_ITEMS_LOADING"
export const SET_SA_ITEMS = "SET_SA_ITEMS"
export const SET_SA_WORKFLOW_PROPERTY = "SET_SA_WORKFLOW_PROPERTY"
export const SET_SA_START_DATETIME = "SET_SA_START_DATETIME"
export const SET_SA_END_DATETIME = "SET_SA_END_DATETIME"
export const SET_SA_FILTERS = "SET_SA_FILTERS"
