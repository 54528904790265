import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportPdfBillingDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				patients: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
						{/* Title */}
						<ReportTitle data={this.state.data} />

						{/* General Info */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.dateIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.documentDate}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.documentNumIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.documentNum}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.insuranceCompanyIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.insuranceCompany}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.factoryAreaIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.factoryArea}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.addressIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.insuranceCompanyAddress}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.telIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.insuranceCompanyTel}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>{this.state.data.dueDateIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.dueDate}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* GL Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.seqIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.dateIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.patient]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.patientIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.cc]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.chiefComplainIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.claim]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.claimIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.insuranceRef]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.insuranceRefIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{this.state.data.patients.map((patient, idx) => {
							return (
								<View key={idx} style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.seq]}>
										<Text>{patient.seq}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{patient.date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.patient]}>
										<Text>{patient.name}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.cc]}>
										<Text>{patient.cc}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.claim]}>
										<Text>{patient.claim}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.insuranceRef]}>
										<Text>{patient.insuranceRef}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.amount, { textAlign: "right" }]}>
										<Text>{patient.amount}</Text>
									</View>
								</View>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Grand Total */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, { textAlign: "center", fontStyle: "italic" }]}>
								<Text>{this.state.data.grandTotalText}</Text>
							</View>

							<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
								<View style={ResourceAssistance.Report.styles.row}>
									<View style={[ResourceAssistance.Report.styles.col, { minWidth: 75 }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.grandTotalIntl}:</Text>
									</View>
									<View style={ResourceAssistance.Report.styles.col}>
										<Text>{this.state.data.grandTotal}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Signature */}
						<Signature
							numOfSignature={2}
							displaySignatureDate={false}
							displayCustomSignatureDate
							signatureTitles={this.state.data.signatureTitles}
							signatureNames={this.state.data.signatureNames}
							customSignatureDates={this.state.data.customSignatureDates}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		marginRight: 5,
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 12,
	},
	seq: {
		minWidth: "4%",
		maxWidth: "4%",
	},
	date: {
		minWidth: "9%",
		maxWidth: "9%",
	},
	patient: {
		minWidth: "17%",
		maxWidth: "17%",
	},
	cc: {
		minWidth: "30%",
		maxWidth: "30%",
	},
	claim: {
		minWidth: "17%",
		maxWidth: "17%",
	},
	insuranceRef: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	amount: {
		minWidth: "11%",
		maxWidth: "11%",
	},
})

export default ReportPdfBillingDoc
