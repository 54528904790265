import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import {
	onModalDisplayAction,
	setAdmIDisplayAll,
	setAdmIItems,
	setAdmIModalNIActive,
	setAdmIModalNIBonus,
	setAdmIModalNIBrand,
	setAdmIModalNICode,
	setAdmIModalNIDiscount,
	setAdmIModalNIDose,
	setAdmIModalNIForms,
	setAdmIModalNIItems,
	setAdmIModalNIPricePerUnit,
	setAdmIModalNIQtyPerOrder,
	setAdmIModalNISuppliers,
	setAdmIModalNITaxes,
	setAdmIModalNITypes,
	setAdmIModalNIUnits,
	setAdmISelectedItem,
	setAdmISelectedType,
	setAdmITypes,
	setAdmItemUnitConversions,
	setLoadingAction,
	setObjArray,
	setSearchText,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPES,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEMS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE,
	SET_ADMIN_ITEM_SEARCH_CODE,
	SET_ADMIN_ITEM_SEARCH_NAME,
	SET_ADM_ITEM_CONFIG_UNIT_DISPLAY,
	SET_ADM_ITEM_INFO_DISPLAY,
	SET_ADM_ITEM_MODAL_UNIT_UNITS,
	SET_ADM_ITEM_NEW_ITEM_DISPLAY,
	SET_ADM_ITEM_SELECTED_UNIT,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class AdmItem extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onDoubleItem = this.onDoubleItem.bind(this)
		this.onSelectUnit = this.onSelectUnit.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onUnitConversion = this.onUnitConversion.bind(this)
		this.onModifyUnitConversion = this.onModifyUnitConversion.bind(this)
		this.onDeleteUnitConversion = this.onDeleteUnitConversion.bind(this)
	}

	async componentDidMount() {
		this.props.loadTypes((types) => {
			this.props.setAdmITypes(types)
			this.props.setAdmIModalNITypes(types)
		})
		this.props.loadItemSuppliers((itemSuppliers) => {
			this.props.setAdmIItems(itemSuppliers)
		})
		this.props.loadItems((items) => {
			this.props.setAdmIModalNIItems(items)
		}, false)
		this.props.loadForms((forms) => {
			this.props.setAdmIModalNIForms(forms)
		})
		this.props.loadUnits((units) => {
			this.props.setAdmIModalNIUnits(units)
			this.props.setObjArray(SET_ADM_ITEM_MODAL_UNIT_UNITS, units)
		})
		this.props.loadSuppliers((suppliers) => {
			this.props.setAdmIModalNISuppliers(suppliers)
		})
		this.props.loadTaxes((taxes) => {
			this.props.setAdmIModalNITaxes(taxes)
		})
		this.props.loadHealthCarePlan((healthcarePlans) => {
			this.props.setObjArray(SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLANS, healthcarePlans)
		})
		await this.props.loadChrgItem((chrgItems) => {
			this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEMS, chrgItems)
		})
		await this.props.loadAdpType((adpTypes) => {
			this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPES, adpTypes)
		})
	}

	componentWillUnmount() {
		this.props.setAdmISelectedType(-1, null)
		this.props.setAdmISelectedItem(-1, null)
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_CODE, "")
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_NAME, "")
		this.props.setAdmIDisplayAll(true)
		this.props.setAdmIItems([])
		this.props.setAdmITypes([])
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEMS, [])
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPES, [])
		this.props.setSelected(SET_ADM_ITEM_SELECTED_UNIT, -1, null)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ADM.item.deleteUnitConversion)) {
					this.deleteUnitConversion()
				}
			}
		}

		if (!_.isEqual(prevProps.item.itemTable.filtered, this.props.item.itemTable.filtered)) {
			let index = this.props.item.selectedItem.item
				? this.props.item.itemTable.filtered.findIndex((itemSupplier) => itemSupplier.id === this.props.item.selectedItem.item.id)
				: -1
			if (index > -1) {
				this.props.setAdmISelectedItem(index, this.props.item.itemTable.filtered[index])
			} else {
				this.props.setAdmISelectedItem(-1, null)
			}
		}

		if (!_.isEqual(prevProps.item.types, this.props.item.types)) {
			this.props.setAdmISelectedType(0, this.props.item.types[0])
		}

		if (
			prevProps.item.selectedType.index !== this.props.item.selectedType.index ||
			prevProps.item.searchCode !== this.props.item.searchCode ||
			prevProps.item.searchName !== this.props.item.searchName ||
			prevProps.item.displayInactive !== this.props.item.displayInactive
		) {
			this.props.setAdmISelectedItem(-1, null)
			this.props.setAdmIItems(this.props.item.itemTable.original)
		}

		if (!_.isEqual(prevProps.item.selectedItem, this.props.item.selectedItem)) {
			this.props.setSelected(SET_ADM_ITEM_SELECTED_UNIT, -1, null)
			if (this.props.item.selectedItem.item) {
				this.props.setAdmItemUnitConversions(this.props.item.selectedItem.item.unitConversions)
			} else {
				this.props.setAdmItemUnitConversions([])
			}
		}
	}

	isDisplayModifyUnitIcon() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Admin.Item.Config_Unit, this.props.login.user.roles)
	}

	deleteUnitConversion() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.item.deleteUnitConversion,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				unitConversions: [
					{
						id: this.props.item.selectedUnit.unit.id,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setAdmIItems(res.data.itemSuppliers)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	onType(value) {
		if (value === undefined) {
			this.props.setAdmISelectedType(-1, null)
			return
		}
		this.props.setAdmISelectedType(value, this.props.item.types[value])
	}

	onSearchCode(event) {
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_ITEM_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmIDisplayAll(event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setAdmISelectedItem(index, this.props.item.itemTable.filtered[index])
	}

	onSelectUnit(row, index) {
		this.props.setSelected(SET_ADM_ITEM_SELECTED_UNIT, index, this.props.item.unitTable.filtered[index])
	}

	onDoubleItem() {
		this.props.onModalDisplayAction(SET_ADM_ITEM_INFO_DISPLAY, true)
	}

	onAdd() {
		this.props.setAdmISelectedItem(-1, null)
		this.props.onModalDisplayAction(SET_ADM_ITEM_NEW_ITEM_DISPLAY, true)
	}

	onEdit() {
		this.props.setAdmIModalNICode(this.props.item.selectedItem.item.code)
		this.props.setAdmIModalNIDose(this.props.item.selectedItem.item.dose)
		this.props.setAdmIModalNIBrand(this.props.item.selectedItem.item.brand)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE, this.props.item.selectedItem.item.medicationUsage)
		this.props.setAdmIModalNIPricePerUnit(this.props.item.selectedItem.item.pricePerUnit)
		this.props.setAdmIModalNIDiscount(this.props.item.selectedItem.item.discount)
		this.props.setAdmIModalNIQtyPerOrder(this.props.item.selectedItem.item.minQtyPerOrder)
		this.props.setAdmIModalNIBonus(this.props.item.selectedItem.item.bonus)
		this.props.setAdmIModalNIActive(this.props.item.selectedItem.item.active)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE, this.props.item.selectedItem.item.fsCode)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT, this.props.item.selectedItem.item.sellPricePerUnit)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM, this.props.item.selectedItem.item.categoryNum)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN, this.props.item.selectedItem.item.healthcarePlan)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM, this.props.item.selectedItem.item.chrgItem)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE, this.props.item.selectedItem.item.adpType)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE, this.props.item.selectedItem.item.governmentCode)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE, this.props.item.selectedItem.item.tmltTmtCode)
		this.props.onModalDisplayAction(SET_ADM_ITEM_NEW_ITEM_DISPLAY, true)
	}

	onUnitConversion() {
		this.props.onModalDisplayAction(SET_ADM_ITEM_CONFIG_UNIT_DISPLAY, true)
	}

	onModifyUnitConversion(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_ADM_ITEM_CONFIG_UNIT_DISPLAY, true)
	}

	onDeleteUnitConversion(event, row, rIdx, cIdx) {
		let selectedUnit = this.props.item.unitTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ADM.item.deleteUnitConversion)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })} 1 ${
				this.props.item.selectedItem.item.unit.displayName
			} = ${Utils.formatNumWithComma(selectedUnit.amount)} ${selectedUnit.unit}`
		)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row className={"g-1"}>
					<Col md={2}>
						<Select
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.inventory)}
							value={this.props.item.selectedType.type ? this.props.item.selectedType.index : undefined}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.item.types, false)}
						</Select>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.item.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.item.selectedItem.index === -1} onClick={this.onEdit}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							enableHighlight
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
							btnIcons={[
								this.isDisplayModifyUnitIcon() ? (
									<IconFont type={"icon-ali-pmgcare-navicon-jldwsz"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
								) : undefined,
							]}
							btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.unit })]}
							onCheckedArray={[this.onUnitConversion]}
							data={this.props.item.itemTable}
							isClearHighlight={this.props.item.selectedItem.index === -1}
							highlightedRow={this.props.item.selectedItem.index}
							onDoubleClick={this.onDoubleItem}
							onClick={this.onSelectRow}
						/>
						{this.props.item.itemTable.isLoading && <Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />}
					</Col>
				</Row>
				<Row>
					<Col>
						<ScrollableTable
							style={{ minHeight: "200px", maxHeight: "200px" }}
							striped
							enableOverlay
							enableHighlight
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
							btnIcons={[
								this.isDisplayModifyUnitIcon() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayModifyUnitIcon() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
							]}
							btnMessages={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
							]}
							onCheckedArray={[this.onModifyUnitConversion, this.onDeleteUnitConversion]}
							data={this.props.item.unitTable}
							onClick={this.onSelectUnit}
							isClearHighlight={this.props.item.selectedUnit.index === -1}
							highlightedRow={this.props.item.selectedUnit.index}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	item: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmIDisplayAll,
			setAdmIItems,
			setAdmIModalNIActive,
			setAdmIModalNIBonus,
			setAdmIModalNIBrand,
			setAdmIModalNICode,
			setAdmIModalNIDiscount,
			setAdmIModalNIDose,
			setAdmIModalNIForms,
			setAdmIModalNIItems,
			setAdmIModalNIPricePerUnit,
			setAdmIModalNIQtyPerOrder,
			setAdmIModalNISuppliers,
			setAdmIModalNITaxes,
			setAdmIModalNITypes,
			setAdmIModalNIUnits,
			setAdmISelectedItem,
			setAdmISelectedType,
			setAdmITypes,
			setAdmItemUnitConversions,
			setLoadingAction,
			setObjArray,
			setSearchText,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmItem))
