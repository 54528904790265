const IntraAnesthesiaRecord = {
	patientSafety: "patientSafety",
	anestheticMachineEquipmentsChecked: "anestheticMachineEquipmentsChecked",
	pressurePointsChecked: "pressurePointsChecked",
	armsRestrained: "armsRestrained",
	eyesCare: "eyesCare",
	ointment: "ointment",
	gel: "gel",
	taped: "taped",
	warming: "warming",
	blanket: "blanket",
	forceAirWarmer: "forceAirWarmer",
	HMEFilter: "HMEFilter",
	fluidWarmer: "fluidWarmer",
	preinductionAssessment: "preinductionAssessment",
	changesFromThePreOpAssessment: "changesFromThePreOpAssessment",
	significantChange: "significantChange",
	PtIdentified: "PtIdentified",
	consentSigned: "consentSigned",
	riskForAspiration: "riskForAspiration",
	HxPELabReviewed: "HxPELabReviewed",
	predictedDifficultAirway: "predictedDifficultAirway",
	consciousness: "consciousness",
	alert: "alert",
	drowsiness: "drowsiness",
	stupor: "stupor",
	coma: "coma",
	patientCondition: "patientCondition",
	position: "position",
	respiration: "respiration",
	supine: "supine",
	LUD: "LUD",
	lateralRight: "lateralRight",
	lateralLeft: "lateralLeft",
	prone: "prone",
	semilateral: "semilateral",
	lithotomy: "lithotomy",
	sitting: "sitting",
	jackknife: "jackknife",
	trendelenburg: "trendelenburg",
	reverseTrend: "reverseTrend",
	spontaneous: "spontaneous",
	assisted: "assisted",
	continuous: "continuous",
	preoxygention: "preoxygention",
	rapidSequence: "rapidSequence",
	cricoidPressure: "cricoidPressure",
	induction: "induction",
	intravenous: "intravenous",
	inhalation: "inhalation",
	maintenance: "maintenance",
	balanced: "balanced",
	TIVA: "TIVA",
	spinal: "spinal",
	epidural: "epidural",
	CSE: "CSE",
	caudal: "caudal",
	brachialPlexus: "brachialPlexus",
	asepticTechniqueDone: "asepticTechniqueDone",
	techniqueApproach: "techniqueApproach",
	equipment: "equipment",
	ultrasound: "ultrasound",
	nerveSimulator: "nerveSimulator",
	noMuscleContractionAt: "noMuscleContractionAt",
	site: "site",
	needleSize: "needleSize",
	cut: "cut",
	pencil: "pencil",
	touhy: "touhy",
	catheter: "catheter",
	skinMarking: "skinMarking",
	punctureAttemptingCounts: "punctureAttemptingCounts",
	anesthesiaLevel: "anesthesiaLevel",
	agentUsed: "agentUsed",
	airwayManagement: "airwayManagement",
	underMask: "underMask",
	oralAirwayNo: "oralAirwayNo",
	LMANo: "LMANo",
	tracheostomyNo: "tracheostomyNo",
	nasalAirwayNo: "nasalAirwayNo",
	ETTubeNo: "ETTubeNo",
	cuff: "cuff",
	uncuff: "uncuff",
	oral: "oral",
	nasal: "nasal",
	depth: "depth",
	PVC: "PVC",
	flexible: "flexible",
	RAE: "RAE",
	MLT: "MLT",
	DLTRtNo: "DLTRtNo",
	DLTLtNo: "DLTLtNo",
	laryngealView: "laryngealView",
	difficulty: "difficulty",
	attempt: "attempt",
	intubationTechnique: "intubationTechnique",
	awake: "awake",
	alreadyIntubated: "alreadyIntubated",
	blind: "blind",
	flexibleFiberopticEndoscope: "flexibleFiberopticEndoscope",
	videoLaryngoscope: "videoLaryngoscope",
	directLaryngoscope: "directLaryngoscope",
	bladeType: "bladeType",
	macintosh: "macintosh",
	miller: "miller",
	bladeSize: "bladeSize",
	styletUsed: "styletUsed",
	assistedDevices: "assistedDevices",
	breathSoundChecked: "breathSoundChecked",
	ETCO2Present: "ETCO2Present",
	afterIntubation: "afterIntubation",
	ETTubeSecuredWithTape: "ETTubeSecuredWithTape",
	cuffedInflation: "cuffedInflation",
	minimalOcclusiveVolume: "minimalOcclusiveVolume",
	manometer2030cmH2O: "manometer2030cmH2O",
	breathingSystem: "breathingSystem",
	semiClosedCircle: "semiClosedCircle",
	semiOpenJacksonReesBain: "semiOpenJacksonReesBain",
	beforeExtubation: "beforeExtubation",
	afterExtubation: "afterExtubation",
	verbalCommand: "verbalCommand",
	dyspnea: "dyspnea",
	coughOnSuction: "coughOnSuction",
	UAO: "UAO",
	adeqSpontVentilator: "adeqSpontVentilator",
	wheezing: "wheezing",
	swallowingGag: "swallowingGag",
	cyanosis: "cyanosis",
	handGripHeadLift: "handGripHeadLift",
	babyDelivery: "babyDelivery",
	timeOfBirth: "timeOfBirth",
	patientTransfer: "patientTransfer",
	conditionBeforeTransfer: "conditionBeforeTransfer",
	oxygenCanula: "oxygenCanula",
	oxygenMaskWithBag: "oxygenMaskWithBag",
	tPiece: "tPiece",
	ambulatoryBag: "ambulatoryBag",
	airwaySupport: "airwaySupport",
	retainedETTube: "retainedETTube",
	tracheostomy: "tracheostomy",
	oralNasalAirway: "oralNasalAirway",
	arousableOnSimulation: "arousableOnSimulation",
	nonResponding: "nonResponding",
	transferTo: "transferTo",
	none: "none",
	PACU: "PACU",
	ICU: "ICU",
	ward: "ward",
}

export default IntraAnesthesiaRecord
