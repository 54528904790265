import { Checkbox, Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBConsciousness = {
	Alert: "A",
	Drowsiness: "D",
	Stupor: "S",
	Coma: "C",
}

class OperatingRoomModalIntraAnesthePatientSafetyDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onNPO = this.onNPO.bind(this)
		this.onASAClass = this.onASAClass.bind(this)
		this.onASAClassEmergency = this.onASAClassEmergency.bind(this)
		this.onChangesFromPreAssessment = this.onChangesFromPreAssessment.bind(this)
		this.onChagnesFromPreAssessmentOther = this.onChagnesFromPreAssessmentOther.bind(this)
		this.onPatientIdentified = this.onPatientIdentified.bind(this)
		this.onConsentSigned = this.onConsentSigned.bind(this)
		this.onRiskForAspiration = this.onRiskForAspiration.bind(this)
		this.onLabReviewed = this.onLabReviewed.bind(this)
		this.onPredictedDifficultAirway = this.onPredictedDifficultAirway.bind(this)
		this.onBP = this.onBP.bind(this)
		this.onHR = this.onHR.bind(this)
		this.onSpO2 = this.onSpO2.bind(this)
		this.onConsciousnessDetail = this.onConsciousnessDetail.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO, op.npoHours)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA, op.asaClass.split(",")[0])
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY, op.asaClass.split(",")[1] === "true")
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES, op.changesFromPreAssessment)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER, op.changesFromPreAssessmentOther)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED, op.patientIdentified)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED, op.consentSigned)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION, op.riskForAspiration)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED, op.labReviewed)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT, op.predictedDifficultAirway)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP, op.bp)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR, op.hr)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2, op.spo2)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS, op.consciousness.split(","))
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.operatingRoom.modalIntraAnePreinductionAssessment.isChangesFromPreAssessment,
				this.props.operatingRoom.modalIntraAnePreinductionAssessment.isChangesFromPreAssessment
			)
		) {
			if (!this.props.operatingRoom.modalIntraAnePreinductionAssessment.isChangesFromPreAssessment) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY, false)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS, [])
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.NPO)}
							suffix={ResourceAssistance.CONSTANT.HOUR}
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.npoHours}
							onChange={this.onNPO}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.bpmmHg)}
							suffix={ResourceAssistance.CONSTANT.MMHG}
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.BP}
							onChange={this.onBP}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.HRbpm)}
							suffix={ResourceAssistance.CONSTANT.BPM}
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.HR}
							onChange={this.onHR}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.SpO2)}
							suffix={"%"}
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.SpO2}
							onChange={this.onSpO2}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.ASAClass)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.ASAClass} onChange={this.onASAClass}>
							<Radio style={{ flexDirection: "row" }} value={"1"}>
								{1}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"2"}>
								{2}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"3"}>
								{3}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"4"}>
								{4}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"5"}>
								{5}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox
							style={{ flexDirection: "row" }}
							checked={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isASAClassEmergency}
							onChange={this.onASAClassEmergency}
						>
							{translate(ResourceAssistance.Message.emergency)}
						</Checkbox>
					</Col>
				</Row>
				<Row>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.changesFromThePreOpAssessment)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isChangesFromPreAssessment}
							onChange={this.onChangesFromPreAssessment}
						>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							required
							autoSize
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={
								this.props.operatingRoom.modalIntraAnePreinductionAssessment.isChangesFromPreAssessment === "false" ||
								this.props.operatingRoom.modalIntraAnePreinductionAssessment.isChangesFromPreAssessment === ""
							}
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.changesFromPreAssessmentOther}
							onChange={this.onChagnesFromPreAssessmentOther}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.PtIdentified)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isPatientIdentified} onChange={this.onPatientIdentified}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.consentSigned)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isConsentSigned} onChange={this.onConsentSigned}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.riskForAspiration)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isRiskForAspiration} onChange={this.onRiskForAspiration}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.HxPELabReviewed)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isLabReviewed} onChange={this.onLabReviewed}>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.predictedDifficultAirway)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.isPredictedDifficultAirway}
							onChange={this.onPredictedDifficultAirway}
						>
							<Radio style={{ flexDirection: "row" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.consciousness)}:</label>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.alert),
									value: CBConsciousness.Alert,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.drowsiness),
									value: CBConsciousness.Drowsiness,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.stupor),
									value: CBConsciousness.Stupor,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.coma),
									value: CBConsciousness.Coma,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAnePreinductionAssessment.consciousnessDetail}
							onChange={this.onConsciousnessDetail}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onNPO(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO, e.target.value)
	}
	onASAClass(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA, e.target.value)
	}
	onASAClassEmergency(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY, e.target.checked)
	}
	onChangesFromPreAssessment(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES, e.target.value)
	}
	onChagnesFromPreAssessmentOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER, e.target.value)
	}
	onPatientIdentified(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED, e.target.value)
	}
	onConsentSigned(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED, e.target.value)
	}
	onRiskForAspiration(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION, e.target.value)
	}
	onLabReviewed(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED, e.target.value)
	}
	onPredictedDifficultAirway(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT, e.target.value)
	}
	onBP(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP, e.target.value)
	}
	onHR(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR, e.target.value)
	}
	onSpO2(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2, e.target.value)
	}
	onConsciousnessDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS, values)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAnesthePatientSafetyDetails)
