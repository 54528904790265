export const SET_RC_END_DATE = "SET_RC_END_DATE"
export const SET_RC_INVOICES = "SET_RC_INVOICES"
export const SET_RC_INVOICE_NUM = "SET_RC_INVOICE_NUM"
export const SET_RC_MODAL_CANCEL_PENDING_RECEIVE = "SET_RC_MODAL_CANCEL_PENDING_RECEIVE"
export const SET_RC_MODAL_CANCEL_REASON = "SET_RC_MODAL_CANCEL_REASON"
export const SET_RC_MODAL_CANCEL_SEARCH_CODE = "SET_RC_MODAL_CANCEL_SEARCH_CODE"
export const SET_RC_MODAL_CANCEL_SEARCH_NAME = "SET_RC_MODAL_CANCEL_SEARCH_NAME"
export const SET_RC_MODAL_CANCEL_WAITING_RECEIVES = "SET_RC_MODAL_CANCEL_WAITING_RECEIVES"
export const SET_RC_MODAL_RECEIVE_WAITING_RECEIVES = "SET_RC_MODAL_RECEIVE_WAITING_RECEIVES"
export const SET_RC_ORDERS = "SET_RC_ORDERS"
export const SET_RC_ORDERS_LOADING = "SET_RC_ORDERS_LOADING"
export const SET_RC_PENDINGS = "SET_RC_PENDINGS"
export const SET_RC_RECEIVED = "SET_RC_RECEIVED"
export const SET_RC_RECEIVED_DATE = "SET_RC_RECEIVED_DATE"
export const SET_RC_RECEIVE_DETAIL_AMOUNT = "SET_RC_RECEIVE_DETAIL_AMOUNT"
export const SET_RC_RECEIVE_DETAIL_BONUS = "SET_RC_RECEIVE_DETAIL_BONUS"
export const SET_RC_RECEIVE_DETAIL_CODE = "SET_RC_RECEIVE_DETAIL_CODE"
export const SET_RC_RECEIVE_DETAIL_DISCOUNT = "SET_RC_RECEIVE_DETAIL_DISCOUNT"
export const SET_RC_RECEIVE_DETAIL_DISCOUNT_PRICE_PER_UNIT = "SET_RC_RECEIVE_DETAIL_DISCOUNT_PRICE_PER_UNIT"
export const SET_RC_RECEIVE_DETAIL_DISCOUNT_TAX_PRICE_PER_UNIT = "SET_RC_RECEIVE_DETAIL_DISCOUNT_TAX_PRICE_PER_UNIT"
export const SET_RC_RECEIVE_DETAIL_EXPIRE_DATE = "SET_RC_RECEIVE_DETAIL_EXPIRE_DATE"
export const SET_RC_RECEIVE_DETAIL_LOT = "SET_RC_RECEIVE_DETAIL_LOT"
export const SET_RC_RECEIVE_DETAIL_NAME = "SET_RC_RECEIVE_DETAIL_NAME"
export const SET_RC_RECEIVE_DETAIL_PRICE_PER_UNIT = "SET_RC_RECEIVE_DETAIL_PRICE_PER_UNIT"
export const SET_RC_RECEIVE_DETAIL_SUPPLIER_DISCOUNT = "SET_RC_RECEIVE_DETAIL_SUPPLIER_DISCOUNT"
export const SET_RC_RECEIVE_DETAIL_TAX = "SET_RC_RECEIVE_DETAIL_TAX"
export const SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE = "SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE"
export const SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE_MODIFIED_IND = "SET_RC_RECEIVE_DETAIL_TAXED_TOTAL_PRICE_MODIFIED_IND"
export const SET_RC_RECEIVE_DETAIL_TOTAL_PRICE = "SET_RC_RECEIVE_DETAIL_TOTAL_PRICE"
export const SET_RC_RECEIVE_DETAIL_TOTAL_PRICE_MODIFIED_IND = "SET_RC_RECEIVE_DETAIL_TOTAL_PRICE_MODIFIED_IND"
export const SET_RC_RECEIVE_DETAIL_UNIT = "SET_RC_RECEIVE_DETAIL_UNIT"
export const SET_RC_SEARCH_CODE = "SET_RC_SEARCH_CODE"
export const SET_RC_SEARCH_NAME = "SET_RC_SEARCH_NAME"
export const SET_RC_FILTER_RECEIVE_ID = "SET_RC_FILTER_RECEIVE_ID"
export const SET_RC_SELECTED_INVOICE = "SET_RC_SELECTED_INVOICE"
export const SET_RC_SELECTED_PENDING = "SET_RC_SELECTED_PENDING"
export const SET_RC_SELECTED_RC = "SET_RC_SELECTED_RC"
export const SET_RC_SELECTED_SUPPLIER = "SET_RC_SELECTED_SUPPLIER"
export const SET_RC_SELECTED_WAITING = "SET_RC_SELECTED_WAITING"
export const SET_RC_START_DATE = "SET_RC_START_DATE"
export const SET_RC_TAXED_TOTAL_PRICE = "SET_RC_TAXED_TOTAL_PRICE"
export const SET_RC_MODAL_RECEIVE_DISCOUNT_RECEIPT = "SET_RC_MODAL_RECEIVE_DISCOUNT_RECEIPT"
export const SET_RC_WAITING_RECEIVES = "SET_RC_WAITING_RECEIVES"
export const SET_RC_WAITING_RECEIVE_DISPLAY_ALL_IND = "SET_RC_WAITING_RECEIVE_DISPLAY_ALL_IND"
export const SET_RC_RECEIVE_DETAI_SUPPLIER_DISCOUNT_PRICE_PER_UNIT = "SET_RC_RECEIVE_DETAI_SUPPLIER_DISCOUNT_PRICE_PER_UNIT"
export const SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE = "SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE"
export const SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE_MODIFIED_IND = "SET_RC_RECEIVE_DETAIL_DISCOUNTED_TOTAL_PRICE_MODIFIED_IND"
export const SET_RC_FILTERS = "SET_RC_FILTERS"
export const SET_RC_SEARCH_DISPLAY = "SET_RC_SEARCH_DISPLAY"
export const SET_RC_MODAL_SEARCH_RECEIVE_ID = "SET_RC_MODAL_SEARCH_RECEIVE_ID"
