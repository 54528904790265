import { Button, Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { ImPrinter } from "react-icons/im"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPostingGLs, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PostingGLPrintDataFactory from "~/utils/factory/print/fiannce/PostingGLPrintDataFactory"

class PostingGLRightInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onInspector = this.onInspector.bind(this)
		this.onReject = this.onReject.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.postingGL.inspectRequest)) {
					this.inspectRequest()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.postingGL.rejectRequest)) {
					this.rejectRequest()
				}
			}
		}
	}

	isInspectorCompleteDisabled() {
		return (
			this.props.PGL.selectedPGL.PGL.inspectorComplete ||
			!this.props.PGL.entryTable.body
				.reduce((total, cur) => {
					return total.plus(Utils.formatNumberFromStr(cur[3]))
				}, Utils.BigNumber(0))
				.eq(
					this.props.PGL.entryTable.body.reduce((total, cur) => {
						return total.plus(Utils.formatNumberFromStr(cur[4]))
					}, Utils.BigNumber(0))
				)
		)
	}

	inspectRequest() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.postingGL.inspectPostingGL,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PGL.location.id,
				postingGLId: this.props.PGL.selectedPGL.PGL.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let postingGLs = this.props.PGL.postingGLTable.original.filter((PGL) => PGL.id !== this.props.PGL.selectedPGL.PGL.id)
			if (res.data.postingGLs.length > 0) {
				postingGLs.push(res.data.postingGLs[0])
			}
			this.props.setPostingGLs(postingGLs)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	rejectRequest() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.postingGL.rejectPostingGL,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PGL.location.id,
				postingGLId: this.props.PGL.selectedPGL.PGL.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let postingGLs = this.props.PGL.postingGLTable.original.filter((PGL) => PGL.id !== this.props.PGL.selectedPGL.PGL.id)
			if (res.data.postingGLs.length > 0) {
				postingGLs.push(res.data.postingGLs[0])
			}
			this.props.setPostingGLs(postingGLs)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	displayWarning(warningId, warningMsg) {
		let selectedPGL = this.props.PGL.selectedPGL.PGL
		this.props.setWarningId(warningId)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(warningMsg)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPGL.remark}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onInspector() {
		this.displayWarning(ResourceAssistance.ID.ACCOUNTING.postingGL.inspectRequest, ResourceAssistance.Message.inspector)
	}

	onReject() {
		this.displayWarning(ResourceAssistance.ID.ACCOUNTING.postingGL.rejectRequest, ResourceAssistance.Message.reject)
	}

	onPrint() {
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.journalVoucher, "_blank")
		myWindow.data = PostingGLPrintDataFactory.generateJournalVoucherData(this.props.PGL.location, this.props.PGL.selectedPGL.PGL)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset style={{ minHeight: "95px" }}>
						<legend>{translate(ResourceAssistance.Message.generalInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={3}
							extra={
								this.props.PGL.selectedPGL.PGL && (
									<Row>
										{!this.props.PGL.selectedPGL.PGL.complete &&
											!this.props.PGL.selectedPGL.PGL.inspectorComplete &&
											Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PostingGL.Inspector, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														style={{
															background: this.isInspectorCompleteDisabled() ? undefined : ResourceAssistance.CSS.Color.dark_green,
														}}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.inspector })}
														type="primary"
														size="middle"
														shape="round"
														icon={<AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} />}
														disabled={this.isInspectorCompleteDisabled()}
														onClick={this.onInspector}
													/>
												</Col>
											)}
										{!this.props.PGL.selectedPGL.PGL.complete &&
											this.props.PGL.selectedPGL.PGL.inspectorComplete &&
											Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PostingGL.Approver, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject })}
														type="primary"
														size="middle"
														shape="round"
														danger
														icon={<TiArrowBack size={ResourceAssistance.ReactIcon.size} />}
														onClick={this.onReject}
													/>
												</Col>
											)}
										{this.props.PGL.selectedPGL.PGL.complete && (
											<Col md="auto">
												<Button
													style={{ background: ResourceAssistance.CSS.Color.info }}
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.print })}
													type="primary"
													size="middle"
													shape="round"
													icon={<ImPrinter size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onPrint}
												/>
											</Col>
										)}
									</Row>
								)
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={3}>
								{this.props.PGL.selectedPGL.PGL ? this.props.PGL.selectedPGL.PGL.remark : ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setPostingGLs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PostingGLRightInfo))
