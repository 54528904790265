import { Radio } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED,
} from "~/redux/type"

class OperatingRoomModalSurgicalSafetyCheckListSignInDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onConfirmPatientIdentity = this.onConfirmPatientIdentity.bind(this)
		this.onPatientSignConsent = this.onPatientSignConsent.bind(this)
		this.onSiteMarked = this.onSiteMarked.bind(this)
		this.onAnesthesiaMachineCheckComplete = this.onAnesthesiaMachineCheckComplete.bind(this)
		this.onPluseOximeterFunctioning = this.onPluseOximeterFunctioning.bind(this)
		this.onKnowAllergy = this.onKnowAllergy.bind(this)
		this.onDifficultAirway = this.onDifficultAirway.bind(this)
		this.onRiskBloodLoss = this.onRiskBloodLoss.bind(this)
		this.onOperatingRoomEquipment = this.onOperatingRoomEquipment.bind(this)
		this.onMedicalDeviceImplant = this.onMedicalDeviceImplant.bind(this)
	}

	componentDidMount() {
		let checklist = this.props.operatingRoom.selectedSurgicalSafetyCheckList.checklist
		if (this.props.operatingRoom.selectedRequest.request && checklist) {
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY, checklist.signInPatientIdentity)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT, checklist.signInPatientSignConsent)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED, checklist.signInSiteMarked)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK, checklist.signInAnesthesiaMachineCheckComplete)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING, checklist.signInPulseOximeterFunctioning)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY, checklist.signInKnowAllergy)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY, checklist.signInDifficultAirway)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS, checklist.signInRiskOfBloodLoss)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT, checklist.signInOREquipment)
			this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT, checklist.signInMedicalDeviceImplant)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT, "")
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.confirmPatientIdentity)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientIdentity} onChange={this.onConfirmPatientIdentity}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.hasThePatientSignConsent)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPatientSignConsent} onChange={this.onPatientSignConsent}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.isTheSiteMarked)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isSiteMarked} onChange={this.onSiteMarked}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.isTheAnesthesiaMachineAndMedicationCheckComplete)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isAnesthesiaMachineCheckComplete}
							onChange={this.onAnesthesiaMachineCheckComplete}
						>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.isThePulseOximeterOnthePatient)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isPulseOximeterFunctioning}
							onChange={this.onPluseOximeterFunctioning}
						>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.doesThePatientHave)}</legend>
					<Row>
						<Col md={8}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.knowAllergy)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isKnowAllergy} onChange={this.onKnowAllergy}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.difficultAirwayOrAspirationRisk)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isDifficultAirway} onChange={this.onDifficultAirway}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
					<Row>
						<Col md={8}>
							<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.riskOfGreaterThan500mlBloodLoss)}:</label>
						</Col>
						<Col md="auto">
							<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isRiskOfBloodLoss} onChange={this.onRiskBloodLoss}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.yes)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.no)}
								</Radio>
							</Radio.Group>
						</Col>
					</Row>
				</fieldset>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.orEquipmentAndInstrument)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isOREquipment} onChange={this.onOperatingRoomEquipment}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.medicalDeviceImplant)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalSurgicalSafetyCheckListSignIn.isMedicalDeviceImplant} onChange={this.onMedicalDeviceImplant}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
			</Container>
		)
	}

	onConfirmPatientIdentity(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY, e.target.value)
	}
	onPatientSignConsent(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT, e.target.value)
	}
	onSiteMarked(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED, e.target.value)
	}
	onAnesthesiaMachineCheckComplete(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK, e.target.value)
	}
	onPluseOximeterFunctioning(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING, e.target.value)
	}
	onKnowAllergy(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY, e.target.value)
	}
	onDifficultAirway(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY, e.target.value)
	}
	onRiskBloodLoss(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS, e.target.value)
	}
	onOperatingRoomEquipment(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT, e.target.value)
	}
	onMedicalDeviceImplant(e) {
		this.props.setValue(SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalSurgicalSafetyCheckListSignInDetails)
