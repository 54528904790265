import _ from "lodash"
import React, { Fragment } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { onModalDisplayAction, setSelectedType, setValue } from "~/redux/action"
import {
	SET_ADM_NEW_TYPE_DISPLAY_CODE,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL,
	SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN,
} from "~/redux/type"
import { SET_NEW_TYPE_DISPLAY } from "~/redux/type/type/MODAL"
import AbstractTypeView from "../AbstractTypeView"
import AdmHealthCarePlanCompany from "./AdmHealthCarePlanCompany"
import { ResourceAssistance } from "~/i18n"
import { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import { MdLabelImportant } from "react-icons/md"
import { Col, Row } from "react-bootstrap"
import AdmHealthCarePlanAccountsReceivable from "./AdmHealthCarePlanAccountsReceivable"
import { FaHospitalAlt } from "react-icons/fa"

class AdmHealthCarePlan extends React.Component {
	constructor(props) {
		super(props)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.type.types.filtered, this.props.type.types.filtered)) {
			let index = this.props.type.selectedType.type
				? this.props.type.types.filtered.findIndex((healthcarePlan) => healthcarePlan.id === this.props.type.selectedType.type.id)
				: -1
			if (index > -1) {
				this.props.setSelectedType(index, this.props.type.types.filtered[index])
			} else {
				this.props.setSelectedType(-1, null)
			}
		}
	}

	onEditBtnClick(event) {
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CODE, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL, true)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	onAddBtnClick(event) {
		this.props.setSelectedType(-1, null)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CODE, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_PLAN, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_PRIMARY_HEALTH_CARE_HOSPITAL, true)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<AbstractTypeView
					scrollableTableData={this.props.type.types}
					onAddBtnClick={this.onAddBtnClick}
					onEditBtnClick={this.onEditBtnClick}
					onRefreshClick={this.props.onRefreshClick}
					displayBtn
					buttonColors={[ResourceAssistance.Button.variant.outlineInfo, ResourceAssistance.Button.variant.outlineInfo]}
					btnMessages={["", ""]}
					btnTypes={[EBtnType.POPOVER, EBtnType.POPOVER]}
					btnIcons={[<MdLabelImportant size={ResourceAssistance.ReactIcon.size} />, <FaHospitalAlt size={ResourceAssistance.ReactIcon.size} />]}
				/>
				<Row className={"full-size"}>
					<Col>
						<AdmHealthCarePlanCompany />
					</Col>
					<Col>
						<AdmHealthCarePlanAccountsReceivable />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setSelectedType, setValue }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmHealthCarePlan)
