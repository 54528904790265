import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ICDPendingDiagnosis extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			icdTable: {
				original: [],
				filtered: [],
				header: [
					ResourceAssistance.Message.type,
					ResourceAssistance.Message.code,
					ResourceAssistance.Message.name,
					ResourceAssistance.Message.typesOfDiagnosis,
				],
				body: [],
			},
		}
		this.onSelectICD = this.onSelectICD.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.pendingICDs, this.props.pendingICDs)) {
			this.renderICDs(this.props.pendingICDs)
		}
	}

	renderICDs(icds) {
		let filtered = icds
		let body = filtered.map((each) => {
			return [each.type, each.code, each.description, each.level]
		})
		this.setState({
			icdTable: {
				...this.state.icdTable,
				original: icds,
				filtered: filtered,
				body: body,
			},
		})
	}

	onSelectICD(icd, index) {
		this.props.onSelectPendingICD(this.state.icdTable.filtered[index], index)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectICD}
						data={this.state.icdTable}
						isClearHighlight={this.props.selectedPendingICD.index === -1}
						highlightedRow={this.props.selectedPendingICD.index}
					/>
				</Col>
			</Row>
		)
	}
}

ICDPendingDiagnosis.propTypes = {
	onSelectPendingICD: propTypes.func,
}

ICDPendingDiagnosis.defaultProps = {}

export default ICDPendingDiagnosis
