import { LOCALES } from "~/i18n"
import { LANGUAGE_CHANGE } from "../type"
import { Utils } from "~/utils/Utils"

const initial = {
	locale: Utils.isDevMode() ? LOCALES.ENGLISH : LOCALES.THAI,
}

export const languageReducer = (state = initial, action) => {
	switch (action.type) {
		case LANGUAGE_CHANGE:
			return Object.assign({}, state, {
				locale: action.payload.locale,
			})

		default:
			return state
	}
}
