import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setBoolean, setValue } from "~/redux/action"
import { SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OPDModalMedicalRecordAdmission extends React.Component {
	constructor(props) {
		super(props)

		this.onAdmissionPlan = this.onAdmissionPlan.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			let clinicalRecord = this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord
			this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN, clinicalRecord.admissionPlan)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN, "")
	}

	onAdmissionPlan(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_RECORD_ADMISSION_PLAN, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.admission)}</legend>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={2}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.inpatientPlan })}
							value={this.props.opd.modalMedicalRecord.admissionPlan}
							onChange={this.onAdmissionPlan}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setBoolean,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecordAdmission))
