import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"

class ReportLetterOfConsentDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "Letter of Consent to Receive Medical Treatment as An Inpatient",
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
							styles={{
								title: {
									fontSize: 16,
								},
							}}
						/>

						{/* Patient Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.id}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Name: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody, { marginRight: 10 }]}>
									<Text>{this.state.data.dob}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Representive */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]} />
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon, { fontSize: 16 }]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text>ผู้ป่วย</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]} />
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text style={[ResourceAssistance.Report.styles.textIcon, { fontSize: 16 }]}>
									{ResourceAssistance.Symbol.Report.emptyCheckBox}
								</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colAuto]}>
								<Text>ผู้แทนตามกฎหมายมีอํานาจตัดสินใจทําการแทนผู้ป่วย</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]} />
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/*  Policy details */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]} />
							<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
								<Text>ข้าพเจ้าขอทําหนังสือฉบับนี้เพื่อแสดงเจตนาของข้าพเจ้าดังมีข้อความและรายละเอียดดังต่อไป</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]} />
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Details */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}>
								<Text>1.</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									ข้าพเจ้าได้รับการชี้แจงจากแพทย์และเจ้าหน้าที่ทางการแพทย์ในการอํานวยการของโรงพยาบาลพระราม 2
									ถึงการวินิจฉัยโรคแผนการรักษารวมถึงผลดี
									ผลเสียต่างๆอันอาจจะเกิดจากการตรวจรักษาพยาบาลและยินยอมเข้ารับการตรวจรักษาตลอดจนปฏิบัติตามคําแนะนําทุกประการ
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}>
								<Text>2.</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									ข้าพเจ้ายินยอมโดยสมัครใจให้แพทย์และเจ้าหน้าที่ทางการแพทย์ในการอํานวยการของโรงพยาบาลพระราม 2
									ทําการตรวจรักษาและกระทําการใดๆตามหลักวิชาชีพได้ตลอดระยะเวลาที่ผู้ป่วยเข้ารับการตรวจรักษา
									โดยจะต้องอธิบายข้อมูลรายละเอียดให้แก่ข้าพเจ้า เปิดโอกาสให้ข้าพเจ้าซักถามจนเป็นที่พอใจ
									รวมทั้งให้ข้าพเจ้ายินยอมก่อนจะดําเนินการ
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}>
								<Text>3.</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									ข้าพเจ้าเข้าใจดีว่า
									ข้อมูลในการตรวจรักษาของผู้ป่วยใช้เพื่อการตรวจวินิจฉัยและการรักษาของผู้ป่วยดังนั้นคณะแพทย์
									และเจ้าหน้าที่ทางการแพทย์ในการอํานวยการของโรงพยาบาลผู้ซึ่งเกี่ยวข้องกับการตรวจวินิจฉัยและการรักษาของผู้ป่วย
									มีความจําเป็นในการเข้าถึงข้อมูลของผู้ป่วย
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}>
								<Text>4.</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								{/* <Text>
									ในกรณีฉุกเฉินที่ผู้ป่วยไม่อยู่ในสภาวะที่จะตัดสินใจหรือรับผิดชอบได้ผู้ป่วยยินยอมให้___________________________
									ซึ่งมีความสัมพันธ์เป็น
									__________________________กับผู้ป่วยเบอร์โทรศัพท์ติดต่อ_____________________________มีอํานาจทําการแทนผู้ป่วยได้ในขณะนั้น
								</Text> */}
								<Text>
									ในกรณีฉุกเฉินที่ผู้ป่วยไม่อยู่ในสภาวะที่จะตัดสินใจหรือรับผิดชอบได้ผู้ป่วยยินยอมให้__________________
									ซึ่งมีความสัมพันธ์เป็น
									__________________กับผู้ป่วยเบอร์โทรศัพท์ติดต่อ__________________มีอํานาจทําการแทนผู้ป่วยได้ในขณะนั้น
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}>
								<Text>5.</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									ข้ในกรณีที่ไม่สามารถติดต่อผู้มีอํานาจทําการแทนผู้ป่วยได้ในขณะนั้นผู้ป่วยยินยอมให้เป็นไปตามดุลยพินิจของแพทย์ผู้ทําการรักษาที่จะเลือกแนวทางการรักษาที่เหมาะสมให้แก่ผู้ป่วยรวมทั้งการผ่าตัดและการใช้ยาระงับความรู้สึกที่อาจจําเป็น
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}>
								<Text>6.</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									ข้าพเจ้าขอรับรองว่าได้อ่านและเข้าใจข้อความทั้งหมดในหนังสือฉบับนี้เป็นอย่างดีและยอมรับตามเงื่อนไขทุกประการจึงได้ลงลายมือชื่อไว้เป็นหลักฐานสําคัญต่อหน้าพยาน
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View
										style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader, { marginBottom: 5 }]}
									>
										<Text style={{ marginBottom: 5 }}>ผู้ป่วย</Text>
										<Text style={{ marginBottom: 5 }}>__________________</Text>
										<Text>(__________________)</Text>
										<Text>{this.state.data.signatureDate}</Text>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View
										style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader, { marginBottom: 5 }]}
									>
										<Text style={{ marginBottom: 5 }}>ผู้แทนตามกฎหมาย</Text>
										<Text style={{ marginBottom: 5 }}>__________________</Text>
										<Text>(__________________)</Text>
										<Text>{this.state.data.signatureDate}</Text>
									</View>
								</View>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}></View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									พิมพ์ลายนิ้วมือให้ระบุนิ้วมือข้างที่พิมพ์และเหตุผล: นิ้ว______________ข้าง_______________
									เหตุผล______________
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}></View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, { marginBottom: 5 }]}>
								<Text>
									บัตรประชาชน/บัตรข้าราชการเลขที่__________________________วันที่บัตรฯหมดอายุ_________________________
								</Text>
							</View>
						</View>

						{/* Doctor Sign */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View
										style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader, { marginBottom: 5 }]}
									>
										<Text style={{ marginBottom: 5 }}>แพทย์</Text>
										<Text style={{ marginBottom: 5 }}>__________________</Text>
										<Text>(__________________)</Text>
										<Text>{this.state.data.signatureDate}</Text>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View
										style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader, { marginBottom: 5 }]}
									>
										<Text style={{ marginBottom: 5 }}>พยาบาล</Text>
										<Text style={{ marginBottom: 5 }}>__________________</Text>
										<Text>(__________________)</Text>
										<Text>{this.state.data.signatureDate}</Text>
									</View>
								</View>
							</View>
						</View>
						{/* Witness Sign */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View
										style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader, { marginBottom: 5 }]}
									>
										<Text style={{ marginBottom: 5 }}>พยาน</Text>
										<Text style={{ marginBottom: 5 }}>_________________</Text>
										<Text>(__________________)</Text>
										<Text>{this.state.data.signatureDate}</Text>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.col]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View
										style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoHeader, { marginBottom: 5 }]}
									>
										<Text style={{ marginBottom: 5 }}>พยาน</Text>
										<Text style={{ marginBottom: 5 }}>_________________</Text>
										<Text>(__________________)</Text>
										<Text>{this.state.data.signatureDate}</Text>
									</View>
								</View>
							</View>
						</View>

						{/* Reason Patient Cannot Sign */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}></View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody]}>
								<Text>
									พิมพ์ลายนิ้วมือให้ระบุนิ้วมือข้างที่พิมพ์และเหตุผล: นิ้ว______________ข้าง_______________
									เหตุผล_______________
								</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}></View>
							<View style={[ResourceAssistance.Report.styles.col, styles.sectionBody, styles.infoHeader]}>
								<Text>โปรดระบุเหตุผลที่ทําให้ผู้ป่วยไม่สามารถเซ็นยินยอมเพื่อรับการรักษาด้วยตนเองได้</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.section]}></View>
							<View style={[ResourceAssistance.Report.styles.col, { paddingLeft: 10, justifyContent: "flex-start" }]}>
								<View style={[ResourceAssistance.Report.styles.row, { marginTop: 5, marginBottom: 10 }]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text style={[ResourceAssistance.Report.styles.textIcon, { fontSize: 16 }]}>
											{ResourceAssistance.Symbol.Report.emptyCheckBox}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>ยังไม่บรรลุนิติภาวะ - ผู้ป่วยอายุไม่ครบ 20ปี บริบูรณ์</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row, { marginBottom: 10 }]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text style={[ResourceAssistance.Report.styles.textIcon, { fontSize: 16 }]}>
											{ResourceAssistance.Symbol.Report.emptyCheckBox}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>ผู้บกพร่องทางกายหรือจิต(ระบุ)_________________</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row, { marginBottom: 10 }]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text style={[ResourceAssistance.Report.styles.textIcon, { fontSize: 16 }]}>
											{ResourceAssistance.Symbol.Report.emptyCheckBox}
										</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>อื่นๆ(โปรดระบุ) ____________________________</Text>
									</View>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.colAuto, { borderWidth: 1 }]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text>ปฏิเสธการรับฝากทรัพย์สิน</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text>ชื่อ..................................................................ผู้ป่วย/ญาติ</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text>ชื่อ..................................................................พยาน</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text>ชื่อ..................................................................พยาน</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text>วันที่...............................................................</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text style={{ color: "red" }}>กรณีทรัพย์สินเกิดความสูญหายทางรพ.ไม่รับผิดชอบใดๆทั้งสิ้น</Text>
									</View>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingTop: 15,
		paddingBottom: 15,
		fontSize: 16,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	dashBottom: {
		borderBottomStyle: "dashed",
		borderBottomWidth: 1,
	},
	section: {
		justifyContent: "flex-start",
		minWidth: "5%",
		maxWidth: "5%",
	},
	sectionBody: {
		minWidth: "95%",
		maxWidth: "95%",
	},
})

export default ReportLetterOfConsentDocument
