import { Descriptions } from "antd"
import propTypes from "prop-types"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

class HospitalModalPatientClinicalRecordPE extends React.Component {
	render() {
		return (
			<Fragment>
				<Row className={"g-0 label-info-row"}>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.physicalExamination)}</legend>
							<Row className={"g-0"} style={{ paddingBottom: "8px" }}>
								<Col>
									<Descriptions size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
										<Descriptions.Item label={translate(ResourceAssistance.Message.riskOfAbuse)} span={1}>
											{this.props.patient.clinicalRecord.riskOfAbuse}
										</Descriptions.Item>
										<Descriptions.Item label={translate(ResourceAssistance.Message.generalAppearance)} span={1}>
											{this.props.patient.clinicalRecord.ga}
										</Descriptions.Item>
									</Descriptions>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>HEENT</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.heent === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.heent === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.heentNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>Heart</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.heart === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.heart === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.heartNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>Lung</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.lung === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.lung === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.lungNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>Abdomen</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.abdomen === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.abdomen === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.abdomenNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>Back</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.back === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.back === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.backNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>Extremities</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.extremities === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.extremities === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.extremitiesNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>Neuro</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.neuro === ResourceAssistance.CONSTANT.NORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.NORMAL}</label>
								</Col>
								<Col md={"auto"}>
									<label>
										{this.props.patient.clinicalRecord.neuro === ResourceAssistance.CONSTANT.ABNORMAL
											? ResourceAssistance.Symbol.checkBox
											: ResourceAssistance.Symbol.emptyCheckBox}
									</label>
								</Col>
								<Col md={"auto"}>
									<label>{ResourceAssistance.CONSTANT.ABNORMAL}: </label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.neuroNote}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md={3}>
									<label>{translate(ResourceAssistance.Message.other)}</label>
								</Col>
								<Col style={{ borderBottom: "1px dashed" }}>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.physicalExaminationOthers}</label>
								</Col>
							</Row>
						</fieldset>
					</Col>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.doctorConsultation)}</legend>
							<Row className={"g-0 label-info-row"}>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.chiefComplaint)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.cc}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.presentIllness)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.pi}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Hospitel.diagnosis)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.diagnosis}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.treatment)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.treatment}</label>
								</Col>
							</Row>
							<Row className={"g-0 label-info-row"}>
								<Col md="auto">
									<label>
										{translate(ResourceAssistance.Message.admissionPlan)}
										{ResourceAssistance.Symbol.colon}
									</label>
								</Col>
								<Col>
									<label className={"italic-text"}>{this.props.patient.clinicalRecord.admissionPlan}</label>
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

HospitalModalPatientClinicalRecordPE.propTypes = {
	patient: propTypes.object,
}

HospitalModalPatientClinicalRecordPE.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitalModalPatientClinicalRecordPE
