import { Utils } from "~/utils/Utils"
import { SET_DOCUMENT_DOCUMENTS } from "~/redux/type"

export const setUploadDocuments = (uploadDocuments) => {
	return (dispatch, getState) => {
		let filtered = uploadDocuments
			.filter((uploadDocument) => uploadDocument.fileName.toLowerCase().includes(getState().menu.document.filterName.toLowerCase()))
			.sort((a, b) => Utils.sort(a.fileName, b.fileName))

		let body = filtered.map((uploadDocument) => {
			return [
				[uploadDocument.displayPatientLabel],
				Utils.formatDate(uploadDocument.lastModifiedDateTime),
				Utils.formatTime(uploadDocument.lastModifiedDateTime),
				uploadDocument.fileName,
				uploadDocument.description,
				uploadDocument.lastModifiedBy.displayName,
				[true, true, true],
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[false, ""],
				[false, ""],
			]
		})

		let popoverMsg = filtered.map((order) => {
			return [[[[false]]]]
		})

		dispatch({
			type: SET_DOCUMENT_DOCUMENTS,
			payload: {
				original: uploadDocuments,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				popoverMsg: popoverMsg,
			},
		})
		return Promise.resolve()
	}
}
