import {
	SET_REPORT_TF_BY_LOCATION_BRANCHES,
	SET_REPORT_TF_BY_LOCATION_END_DATETIME,
	SET_REPORT_TF_BY_LOCATION_ORGS,
	SET_REPORT_TF_BY_LOCATION_SELECTED_BRANCH,
	SET_REPORT_TF_BY_LOCATION_SELECTED_ORG,
	SET_REPORT_TF_BY_LOCATION_START_DATETIME,
	SET_REPORT_TF_BY_LOCATION_CERTIFIED_IND,
	SET_REPORT_TF_BY_LOCATION_APPROVED_IND,
} from "../../type"

const init = {
	reportByLocation: {
		startDateTime: null,
		endDateTime: null,
		isCertified: true,
		isApproved: true,
		orgs: [],
		branches: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
}

const reportPOReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_TF_BY_LOCATION_START_DATETIME:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					startDateTime: action.payload.date,
				},
			})

		case SET_REPORT_TF_BY_LOCATION_END_DATETIME:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					endDateTime: action.payload.date,
				},
			})

		case SET_REPORT_TF_BY_LOCATION_ORGS:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					orgs: action.payload.orgs,
				},
			})

		case SET_REPORT_TF_BY_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.org,
					},
				},
			})

		case SET_REPORT_TF_BY_LOCATION_BRANCHES:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					branches: action.payload.branches,
				},
			})

		case SET_REPORT_TF_BY_LOCATION_SELECTED_BRANCH:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.branch,
					},
				},
			})

		case SET_REPORT_TF_BY_LOCATION_CERTIFIED_IND:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					isCertified: action.payload.isCertified,
				},
			})

		case SET_REPORT_TF_BY_LOCATION_APPROVED_IND:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					isApproved: action.payload.isApproved,
				},
			})

		default:
			return state
	}
}

export default reportPOReducer
