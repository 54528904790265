import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients } from "~/redux/action"
import { SET_OPD_OPERATING_ROOM_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OutpatientModalORDetails from "./OutpatientModalORDetails"
import moment from "moment"

class OutpatientModalOR extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OPD_OPERATING_ROOM_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.opd.modalOR.selectedORCaseType.type ||
			!this.props.opd.modalOR.selectedOperativeDoctor.doctor ||
			!this.props.opd.modalOR.selectedPreDiagICD.icd ||
			_.isEmpty(this.props.opd.modalOR.preOperativeDiag) ||
			!this.props.opd.modalOR.selectedOperativeICD.icd ||
			_.isEmpty(this.props.opd.modalOR.operationProcedure) ||
			!this.props.opd.modalOR.selectedAnesthesiologist.anesthesiologist ||
			!this.props.opd.modalOR.selectedAnesthesiaType.type ||
			!this.props.opd.modalOR.selectedRoom.room ||
			(!this.props.opd.selectedORRequest.request && _.isEmpty(this.props.opd.modalOR.newEvents)) ||
			(this.props.opd.selectedORRequest.request &&
				_.isEmpty(this.props.opd.selectedORRequest.request.operatingRoomSchedules) &&
				_.isEmpty(this.props.opd.modalOR.newEvents))
		) {
			return true
		}
		if (!_.isEmpty(this.props.opd.modalOR.newEvents)) {
			return false
		}
		let target = Object.assign({}, this.props.opd.selectedORRequest.request, {
			operatingRoom: this.props.opd.modalOR.selectedRoom.room,
			operatingRoomCase: this.props.opd.modalOR.selectedORCaseType.type.displayName,
			lengthOfStay: this.props.opd.modalOR.lengthOfStay,
			operativeDoctor: this.props.opd.modalOR.selectedOperativeDoctor.doctor.displayName,
			doctorFee: Utils.BigNumber(this.props.opd.modalOR.doctorFee).toNumber(),
			preDiagICD: this.props.opd.modalOR.selectedPreDiagICD.icd.description,
			preOperativeDiag: Utils.trim(this.props.opd.modalOR.preOperativeDiag),
			operativeICD: this.props.opd.modalOR.selectedOperativeICD.icd.description,
			operationProcedure: Utils.trim(this.props.opd.modalOR.operationProcedure),
			anesthesiologist: this.props.opd.modalOR.selectedAnesthesiologist.anesthesiologist.displayName,
			anesthesiaType: this.props.opd.modalOR.selectedAnesthesiaType.type.displayName,
			anesthesiologistFee: Utils.BigNumber(this.props.opd.modalOR.anesthesiologistFee).toNumber(),
		})
		return _.isEqual(this.props.opd.selectedORRequest.request, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveOperatingRoomRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				operatingRoomRequests: [
					{
						id: this.props.opd.selectedORRequest.request ? this.props.opd.selectedORRequest.request.id : 0,
						operatingRoomId: this.props.opd.modalOR.selectedRoom.room.id,
						operatingRoomCase: this.props.opd.modalOR.selectedORCaseType.type.displayName,
						lengthOfStay: this.props.opd.modalOR.lengthOfStay,
						operativeDoctor: this.props.opd.modalOR.selectedOperativeDoctor.doctor.displayName,
						doctorFee: this.props.opd.modalOR.doctorFee,
						preDiagICD: this.props.opd.modalOR.selectedPreDiagICD.icd.description,
						preOperativeDiag: Utils.trim(this.props.opd.modalOR.preOperativeDiag),
						operativeICD: this.props.opd.modalOR.selectedOperativeICD.icd.description,
						operationProcedure: Utils.trim(this.props.opd.modalOR.operationProcedure),
						anesthesiologist: this.props.opd.modalOR.selectedAnesthesiologist.anesthesiologist.displayName,
						anesthesiaType: this.props.opd.modalOR.selectedAnesthesiaType.type.displayName,
						anesthesiologistFee: this.props.opd.modalOR.anesthesiologistFee,
						schedules: this.props.opd.modalOR.newEvents.map((each) => {
							return {
								id: each.id,
								startDateTime: moment(each.start).milliseconds(0).valueOf(),
								endDateTime: moment(each.end).milliseconds(0).valueOf(),
								description: Utils.trim(each.title),
							}
						}),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullWidth}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.operatingRoom)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<OutpatientModalORDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientOperatingRoomDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalOR))
