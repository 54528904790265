import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setIPDPainScales } from "~/redux/action"
import IPDRightPainScaleChart from "./IPDRightPainScaleChart"
import IPDRightPainScaleTable from "./IPDRightPainScaleTable"

class IPDRightPainScale extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightPainScale",
		}
	}

	componentDidMount() {
		if (this.props.ipd.selectedHospitel.hospitel) {
			this.props.setIPDPainScales(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.painScales)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel) ||
			!_.isEqual(prevProps.ipd.orderEndDateTime, this.props.ipd.orderEndDateTime)
		) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.props.setIPDPainScales(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.painScales)
			} else {
				this.props.setIPDPainScales([])
			}
		}
	}

	render() {
		return this.props.graphic ? <IPDRightPainScaleChart /> : <IPDRightPainScaleTable />
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDPainScales,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightPainScale))
