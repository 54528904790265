import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_REPORT_CASHIER_USER_END_DATE_TIME, SET_REPORT_CASHIER_USER_START_DATE_TIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class ReportCashierUserFilterDate extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_REPORT_CASHIER_USER_START_DATE_TIME, new Date(Utils.generateDate().getFullYear(), Utils.generateDate().getMonth(), 1).getTime())
		this.props.setValue(
			SET_REPORT_CASHIER_USER_END_DATE_TIME,
			new Date(Utils.generateDate().getFullYear(), Utils.generateDate().getMonth() + 1, 0, 23, 59, 59).getTime()
		)
	}

	onStartDate(date) {
		this.props.setValue(SET_REPORT_CASHIER_USER_START_DATE_TIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_REPORT_CASHIER_USER_END_DATE_TIME, date.getTime())
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<DatePicker startDate={Utils.generateDateFromLong(this.props.filter.startDateTime)} onChange={this.onStartDate} />
				</Col>
				<Col md="auto">
					<label style={{ alignSelf: ResourceAssistance.CSS.center }}>{translate(ResourceAssistance.Message.to)}</label>
				</Col>
				<Col md="auto">
					<DatePicker startDate={Utils.generateDateFromLong(this.props.filter.endDateTime)} onChange={this.onEndDate} />
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.cashier.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportCashierUserFilterDate))
