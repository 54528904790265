const InputType = {
	password: "password",
	button: "button",
	checkBox: "checkbox",
	color: "color",
	date: "date",
	datetimeLocal: "datetime-local",
	email: "email",
	file: "file",
	hidden: "hidden",
	image: "image",
	month: "month",
	number: "number",
	radio: "radio",
	range: "range",
	reset: "reset",
	search: "search",
	submit: "submit",
	tel: "tel",
	text: "text",
	time: "time",
	url: "url",
	week: "week",
}

export default InputType
