import { TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import { SET_SA_MODAL_LOCATION_SELECTED_BRANCH, SET_SA_MODAL_LOCATION_SELECTED_ORG, SET_SA_ORGS, SET_SA_ORGS_LOADING } from "~/redux/type/"
import { TreeSelectUtils } from "~/utils/component/TreeSelectUtils"

class SugicalAuthModalLocationDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadLocations = this.loadLocations.bind(this)
		this.onLocation = this.onLocation.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.surgicalAuth.orgs)) {
			this.loadLocations()
		}
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.surgicalAuth.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_SA_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_SA_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_SA_ORGS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_SA_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onLocation(v) {
		if (v === undefined) {
			this.props.setSelected(SET_SA_MODAL_LOCATION_SELECTED_ORG, -1, null)
			this.props.setSelected(SET_SA_MODAL_LOCATION_SELECTED_BRANCH, -1, null)
			return
		}
		let keys = v.split("-")
		this.props.setSelected(SET_SA_MODAL_LOCATION_SELECTED_ORG, keys[0], this.props.surgicalAuth.orgs[keys[0]])
		this.props.setSelected(SET_SA_MODAL_LOCATION_SELECTED_BRANCH, keys[1], this.props.surgicalAuth.orgs[keys[0]].locations[keys[1]])
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							treeLine={{ showLeafIcon: false }}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.location)}
							loading={this.props.surgicalAuth.isOrgsLoading}
							treeData={TreeSelectUtils.displayOrgBranch(this.props.surgicalAuth.orgs)}
							onChange={this.onLocation}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(SugicalAuthModalLocationDetails)
