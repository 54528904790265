import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setOPDModalDoctorOrderPendingItems } from "~/redux/action"
import { SET_OPD_DOCTOR_INSTRUCTION_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OPDModalDoctorInstructionDetails from "./OPDModalDoctorInstructionDetails"

class OPDModalDoctorInstruction extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalDoctorInstruction",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OPD_DOCTOR_INSTRUCTION_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return _.isEmpty(Utils.trim(this.props.opd.modalDoctorInstruction.description))
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let pendingItems = this.props.opd.modalDoctorOrder.pendingItemTable.original

		Object.entries(this.props.opd.modalDoctorInstruction).forEach(([key, value]) => {
			if ((!_.isEqual(key, "startDateTime") || !_.isEqual(key, "endDateTime")) && !_.isEmpty(value)) {
				pendingItems = pendingItems.filter((item) => !_.isEqual(item.displayName, value))
				let defaultOrder = {}
				Object.assign(defaultOrder, {
					startDateTime: this.props.opd.modalDoctorInstruction.startDateTime,
					endDateTime: this.props.opd.modalDoctorInstruction.endDateTime,
					displayName: value,
				})
				pendingItems.push(defaultOrder)
			}
		})

		this.props.setOPDModalDoctorOrderPendingItems(pendingItems)

		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>
							{translate(ResourceAssistance.Message.instruction)}
						</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<OPDModalDoctorInstructionDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientDoctorInstructionDisplay,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setOPDModalDoctorOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalDoctorInstruction)
