import { Button as AButton, Descriptions, Input } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { RiUserSearchFill } from "react-icons/ri"
import { RxReset } from "react-icons/rx"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_DOCUMENT_PATIENT, SET_DOCUMENT_SEARCH_PID_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class DocumentPatient extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchPatient = this.onSearchPatient.bind(this)
		this.onReset = this.onReset.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_DOCUMENT_PATIENT, null)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.patientInfo)}</legend>
				<Row>
					<Col>
						<Descriptions
							style={{
								flex: "1",
								flexDirection: "column",
								overflowY: "auto",
								overflowX: "hidden",
							}}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={4}
							extra={
								<Container fluid="small" className={"full-size full-flex"}>
									<Row className="g-2" style={{ marginBottom: "0" }}>
										<Col>
											<Input.Search
												placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.patientId })}
												enterButton={<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />}
												loading={this.props.document.isSearchPIDLoading}
												onSearch={this.onSearchPatient}
											/>
										</Col>
										<Col md="auto">
											<AButton
												style={{ background: ResourceAssistance.CSS.Color.red }}
												type="primary"
												size="middle"
												shape="round"
												icon={<RxReset size={ResourceAssistance.ReactIcon.size} />}
												onClick={this.onReset}
											/>
										</Col>
									</Row>
								</Container>
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.patientId)} span={1}>
								{this.props.document.patient ? this.props.document.patient.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
								{this.props.document.patient ? this.props.document.patient.displayName : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dateOfBirth)} span={1}>
								{this.props.document.patient ? Utils.formatDate(this.props.document.patient.dobtime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.age)} span={1}>
								{this.props.document.patient ? Utils.calculateAge(this.props.document.patient.dobtime) : ""}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</fieldset>
		)
	}

	onSearchPatient(value) {
		if (!value) {
			return
		}
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.document.getPatient,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				pid: value,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DOCUMENT_PATIENT, res.data.patients[0])
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_DOCUMENT_SEARCH_PID_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_DOCUMENT_SEARCH_PID_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_DOCUMENT_SEARCH_PID_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onReset() {
		this.props.setValue(SET_DOCUMENT_PATIENT, null)
	}
}

const mapStateToProps = (state) => ({
	document: state.menu.document,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DocumentPatient))
