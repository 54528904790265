import { Button, Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { ImPrinter } from "react-icons/im"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPurchaseInvoices, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PurchaseInvoicePrintDataFactory from "~/utils/factory/print/fiannce/PurchaseInvoicePrintDataFactory"

class PurchaseInvoiceRightInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onInspector = this.onInspector.bind(this)
		this.onReject = this.onReject.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.inspectInvoice)) {
					this.inspectInvoice()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.rejectInvoice)) {
					this.rejectInvoice()
				}
			}
		}
	}

	isInspectorCompleteDisabled() {
		return (
			this.props.PI.selectedPI.PI.inspectorComplete ||
			!this.props.PI.purchaseTable.body
				.reduce((total, cur) => {
					return total.plus(Utils.formatNumberFromStr(cur[10]))
				}, Utils.BigNumber(0))
				.eq(
					this.props.PI.purchaseTable.body.reduce((total, cur) => {
						return total.plus(Utils.formatNumberFromStr(cur[11]))
					}, Utils.BigNumber(0))
				)
		)
	}

	inspectInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.purchaseInvoice.inspectPurchaseInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PI.location.id,
				purchaseInvoiceId: this.props.PI.selectedPI.PI.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let invoices = this.props.PI.purchaseInvoiceTable.original.filter((each) => each.id !== this.props.PI.selectedPI.PI.id)
			if (res.data.invoices.length > 0) {
				invoices.push(res.data.invoices[0])
			}
			this.props.setPurchaseInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	rejectInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.purchaseInvoice.rejectPurchaseInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PI.location.id,
				purchaseInvoiceId: this.props.PI.selectedPI.PI.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let invoices = this.props.PI.purchaseInvoiceTable.original.filter((each) => each.id !== this.props.PI.selectedPI.PI.id)
			if (res.data.invoices.length > 0) {
				invoices.push(res.data.invoices[0])
			}
			this.props.setPurchaseInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	displayWarning(warningId, warningMsg) {
		let selectedPI = this.props.PI.selectedPI.PI
		this.props.setWarningId(warningId)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(warningMsg)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceNum)} span={1}>
						{selectedPI.invoice}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedPI.paymentTermDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
						{selectedPI.payment}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierCode)} span={1}>
						{selectedPI.supplierCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierName)} span={1}>
						{selectedPI.supplierName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPI.remark}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onInspector() {
		this.displayWarning(ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.inspectInvoice, ResourceAssistance.Message.inspector)
	}

	onReject() {
		this.displayWarning(ResourceAssistance.ID.ACCOUNTING.purchaseInvoice.rejectInvoice, ResourceAssistance.Message.reject)
	}

	onPrint() {
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.journalVoucher, "_blank")
		myWindow.data = PurchaseInvoicePrintDataFactory.generateJournalVoucherData(this.props.PI.location, this.props.PI.selectedPI.PI)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.generalInfo)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={3}
							extra={
								this.props.PI.selectedPI.PI && (
									<Row>
										{!this.props.PI.selectedPI.PI.complete &&
											!this.props.PI.selectedPI.PI.inspectorComplete &&
											Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PurchaseInvoice.Inspector, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														style={{
															background: this.isInspectorCompleteDisabled() ? undefined : ResourceAssistance.CSS.Color.dark_green,
														}}
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.inspector })}
														type="primary"
														size="middle"
														shape="round"
														icon={<AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} />}
														disabled={this.isInspectorCompleteDisabled()}
														onClick={this.onInspector}
													/>
												</Col>
											)}
										{!this.props.PI.selectedPI.PI.complete &&
											this.props.PI.selectedPI.PI.inspectorComplete &&
											Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PurchaseInvoice.Approver, this.props.login.user.roles) && (
												<Col md="auto">
													<Button
														title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject })}
														type="primary"
														size="middle"
														shape="round"
														danger
														icon={<TiArrowBack size={ResourceAssistance.ReactIcon.size} />}
														onClick={this.onReject}
													/>
												</Col>
											)}
										{this.props.PI.selectedPI.PI.complete && (
											<Col md="auto">
												<Button
													style={{ background: ResourceAssistance.CSS.Color.info }}
													title={this.props.intl.formatMessage({ id: ResourceAssistance.Message.print })}
													type="primary"
													size="middle"
													shape="round"
													icon={<ImPrinter size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onPrint}
												/>
											</Col>
										)}
									</Row>
								)
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
								{this.props.PI.selectedPI.PI ? Utils.formatDate(this.props.PI.selectedPI.PI.paymentTermDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
								{this.props.PI.selectedPI.PI ? this.props.PI.selectedPI.PI.payment : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.withholdingTax)} span={1}>
								{this.props.PI.selectedPI.PI ? Utils.formatDate(this.props.PI.selectedPI.PI.withholdingTaxIssuedDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
								{this.props.PI.selectedPI.PI ? Utils.formatDate(this.props.PI.selectedPI.PI.invoiceDateTime) : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceNum)} span={1}>
								{this.props.PI.selectedPI.PI ? this.props.PI.selectedPI.PI.invoice : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.psId)} span={1}>
								{this.props.PI.selectedPI.PI && this.props.PI.selectedPI.PI.paymentPlan ? this.props.PI.selectedPI.PI.paymentPlan.id : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.supplierCode)} span={1}>
								{this.props.PI.selectedPI.PI ? this.props.PI.selectedPI.PI.supplierCode : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.supplierName)} span={1}>
								{this.props.PI.selectedPI.PI ? this.props.PI.selectedPI.PI.supplierName : ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={3}>
								{this.props.PI.selectedPI.PI ? this.props.PI.selectedPI.PI.remark : ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPurchaseInvoices,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceRightInfo))
