import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_OR_REMARK_DISPLAY, SET_OR_SCHEDULES, SET_PAGE_LOADING } from "~/redux/type"
import OperatingRoomModalRemarkDetails from "./OperatingRoomModalRemarkDetails"
import moment from "moment"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalRemark extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_REMARK_DISPLAY, false)
	}

	isDoneBtnDisabled() {}

	getRemark() {
		let res = this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.remark
		res = res.concat(
			ResourceAssistance.PROGRAM_DEFINED.split,
			`${Utils.formatDateTime(this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.startDateTime)} - ${Utils.formatDateTime(
				this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.endDateTime
			)}`,
			ResourceAssistance.PROGRAM_DEFINED.split,
			`${Utils.formatDateTime(moment(this.props.operatingRoom.selectedEvent.event.start).valueOf())} - ${Utils.formatDateTime(
				moment(this.props.operatingRoom.selectedEvent.event.end).valueOf()
			)}`,
			ResourceAssistance.PROGRAM_DEFINED.split,
			this.props.login.user.displayName,
			ResourceAssistance.PROGRAM_DEFINED.split,
			Utils.trim(this.props.operatingRoom.modalRemark.reason)
		)
		return res
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>{translate(ResourceAssistance.Message.reason)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<OperatingRoomModalRemarkDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
	onClose() {
		this.props.operatingRoom.selectedEvent.event.setStart(moment(this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.startDateTime).toDate())
		this.props.operatingRoom.selectedEvent.event.setEnd(moment(this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.endDateTime).toDate())
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.updateOperatingRoomSchedules,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.id,
				departmentId: this.props.operatingRoom.selectedOperatingRoom.operatingRoom.id,
				startDateTime: this.props.operatingRoom.calendarStartDateTime,
				endDateTime: this.props.operatingRoom.calendarEndDateTime,
				operatingRoomSchedules: [
					{
						locationId: this.props.operatingRoom.selectedOperatingRoom.operatingRoom.id,
						id: this.props.operatingRoom.selectedEvent.event.id,
						startDateTime: moment(this.props.operatingRoom.selectedEvent.event.start).milliseconds(0).valueOf(),
						endDateTime: moment(this.props.operatingRoom.selectedEvent.event.end).milliseconds(0).valueOf(),
						remark: this.getRemark(),
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_SCHEDULES, res.data.schedules)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomRemarkDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalRemark))
