import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE, SET_SA_MODAL_BUDGET_DOCTOR_FEE } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import SurgicalAuthModalBudgetOther from "./SurgicalAuthModalBudgetOther"

class SurgicalAuthModalBudgetDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDoctorFee = this.onDoctorFee.bind(this)
		this.onAnesthesiologistFee = this.onAnesthesiologistFee.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_SA_MODAL_BUDGET_DOCTOR_FEE, Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.doctorFee).toFixed(2))
		this.props.setValue(
			SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE,
			Utils.BigNumber(this.props.surgicalAuth.selectedORRequest.request.anesthesiologistFee).toFixed(2)
		)
	}

	componentWillUnmount() {
		this.props.setValue(SET_SA_MODAL_BUDGET_DOCTOR_FEE, undefined)
		this.props.setValue(SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE, undefined)
	}

	onDoctorFee(e) {
		this.props.setValue(SET_SA_MODAL_BUDGET_DOCTOR_FEE, e.target.value)
	}

	onAnesthesiologistFee(e) {
		this.props.setValue(SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.doctorFee)}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onDoctorFee}
							value={this.props.surgicalAuth.modalBudget.doctorFee}
						/>
					</Col>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.anesthesiologistFee)}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onAnesthesiologistFee}
							value={this.props.surgicalAuth.modalBudget.anesthesiologistFee}
						/>
					</Col>
				</Row>
				<SurgicalAuthModalBudgetOther ref={this.props.firstLevelRef} onRenderParentCallback={this.props.onRenderParentCallback} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(SurgicalAuthModalBudgetDetails)
