import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setValue, setOperatingRoomRequests } from "~/redux/action"
import { SET_OR_SELECTED_REQUEST } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OperatingRoomLeftOperatingRequest extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.operatingRequestTable.filtered, this.props.operatingRoom.operatingRequestTable.filtered)) {
			let index = this.props.operatingRoom.selectedRequest.request
				? this.props.operatingRoom.operatingRequestTable.filtered.findIndex((request) => request.id === this.props.operatingRoom.selectedRequest.request.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_OR_SELECTED_REQUEST, index, this.props.operatingRoom.operatingRequestTable.filtered[index])
			} else {
				this.props.setSelected(SET_OR_SELECTED_REQUEST, -1, null)
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.startDateTime, this.props.operatingRoom.startDateTime)) {
			this.props.setOperatingRoomRequests([])
		}
	}

	onSelect(patient, index) {
		if (this.props.operatingRoom.selectedRequest.request && this.props.operatingRoom.selectedRequest.index === index) {
			this.props.setSelected(SET_OR_SELECTED_REQUEST, -1, null)
		} else {
			this.props.setSelected(SET_OR_SELECTED_REQUEST, index, this.props.operatingRoom.operatingRequestTable.filtered[index])
		}
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.operatingRoom.operatingRequestTable}
						onClick={this.onSelect}
						isClearHighlight={this.props.operatingRoom.selectedRequest.index === -1}
						highlightedRow={this.props.operatingRoom.selectedRequest.index}
					/>
					{this.props.operatingRoom.operatingRequestTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
			setOperatingRoomRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomLeftOperatingRequest))
