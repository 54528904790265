import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { TbDiscount2 } from "react-icons/tb"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setAccountsReceivableInvoices, setARBillingStatements, setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_AR_ADJUSTMENT_DISPLAY,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT_REMARK,
	SET_AR_MODAL_ADJUSTMENT_ID,
	SET_AR_SELECTED_BILLING_STATEMENT,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceivableRightBillingStatement extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AccountsReceivableRightBillingStatement",
		}
		this.selectRow = this.selectRow.bind(this)
		this.onDiscount = this.onDiscount.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.AR.modalAdjustment.isContinue, this.props.AR.modalAdjustment.isContinue)) {
			if (
				this.props.AR.modalAdjustment.isContinue &&
				_.isEqual(this.props.AR.modalAdjustment.id, ResourceAssistance.ID.ACCOUNTING.accountsReceivable.modalAdjustment.billingStatementAdjustment)
			) {
				this.saveBillingStatementAdjustments()
			}
		}
		if (!_.isEqual(prevProps.AR.selectedGroup, this.props.AR.selectedGroup)) {
			if (this.props.AR.selectedGroup.group) {
				this.props.setARBillingStatements(this.props.AR.selectedGroup.group[1])
			} else {
				this.props.setARBillingStatements([])
			}
		}
		if (!_.isEqual(prevProps.AR.billingStatementTable.filtered, this.props.AR.billingStatementTable.filtered)) {
			let index = this.props.AR.selectedBS.billingStatement
				? this.props.AR.billingStatementTable.filtered.findIndex((bs) => bs.id === this.props.AR.selectedBS.billingStatement.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AR_SELECTED_BILLING_STATEMENT, index, this.props.AR.billingStatementTable.filtered[index])
			} else {
				this.props.setSelected(SET_AR_SELECTED_BILLING_STATEMENT, -1, null)
			}
		}
	}

	saveBillingStatementAdjustments() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsReceivable.updateBillingStatements,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				invoiceId: this.props.AR.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
				billingStatements: [
					{
						id: this.props.AR.selectedBS.billingStatement.id,
						adjustments: Utils.BigNumber(this.props.AR.modalAdjustment.discountPercent).isGreaterThan(0)
							? Utils.BigNumber(this.props.AR.selectedBS.billingStatement.charge).times(this.props.AR.modalAdjustment.discountPercent).dividedBy(100).toFixed(2)
							: Utils.BigNumber(this.props.AR.modalAdjustment.discount).toFixed(2),
						adjustmentsDescription: Utils.trim(this.props.AR.modalAdjustment.remark),
					},
				],
			},
		}
		let callback = (res) => {
			let invoices = this.props.AR.invoiceTable.original.filter((each) => each.id !== res.data.invoices[0].id)
			invoices.push(res.data.invoices[0])
			this.props.setAccountsReceivableInvoices(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_AR_SELECTED_BILLING_STATEMENT, index, this.props.AR.billingStatementTable.filtered[index])
	}

	onDiscount(event, row, rIdx, cIdx) {
		let selectedBS = this.props.AR.billingStatementTable.filtered[rIdx]
		this.props.setValue(SET_AR_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.ACCOUNTING.accountsReceivable.modalAdjustment.billingStatementAdjustment)
		this.props.setValue(SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX, selectedBS.charge)
		this.props.setValue(SET_AR_MODAL_ADJUSTMENT_DISCOUNT, Utils.BigNumber(selectedBS.adjustment).toFixed(2))
		this.props.setValue(SET_AR_MODAL_ADJUSTMENT_DISCOUNT_REMARK, selectedBS.adjustmentDescription)
		this.props.onModalDisplayAction(SET_AR_ADJUSTMENT_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[
							this.props.AR.selectedInvoice.invoice && this.props.AR.selectedInvoice.invoice.accountsReceivableInspected ? undefined : (
								<TbDiscount2 size={ResourceAssistance.ReactIcon.size} />
							),
						]}
						onCheckedArray={[this.onDiscount]}
						data={this.props.AR.billingStatementTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.AR.selectedBS.index === -1}
						highlightedRow={this.props.AR.selectedBS.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setARBillingStatements,
			setAccountsReceivableInvoices,
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceivableRightBillingStatement))
