import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_FO_END_DATE_TIME,
	SET_FO_FILTERS,
	SET_FO_FILTER_FINANCIAL_PLAN_ID,
	SET_FO_LOCATION,
	SET_FO_MODAL_EDIT_REQUEST_FEE,
	SET_FO_MODAL_LOCATION_ORGS,
	SET_FO_MODAL_LOCATION_SELECTED_ORG,
	SET_FO_MODAL_NEW_BANKS,
	SET_FO_MODAL_NEW_BANK_LOADING,
	SET_FO_MODAL_NEW_FILTER_PS_ID,
	SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE,
	SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME,
	SET_FO_MODAL_NEW_SERVICE_TYPES,
	SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING,
	SET_FO_MODAL_NEW_PAYMENT_PLANS,
	SET_FO_MODAL_NEW_PAYMENT_TYPES,
	SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING,
	SET_FO_MODAL_NEW_PENDING_PAYMENT_PLANS,
	SET_FO_MODAL_NEW_SEARCH_DUE_DATE,
	SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK,
	SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE,
	SET_FO_MODAL_NEW_SELECTED_BANK,
	SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE,
	SET_FO_MODAL_NEW_SELECTED_PAYMENT_PLAN,
	SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE,
	SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN,
	SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME,
	SET_FO_MODAL_SEARCH_FINANCIAL_REQUEST_ID,
	SET_FO_MODAL_SEARCH_MORE_PAYMENT_SLIP_ID,
	SET_FO_PAYMENT_PLANS,
	SET_FO_REQUESTS,
	SET_FO_SELECTED_FINANCIAL_REQUEST,
	SET_FO_SELECTED_PAYMENT_PLAN,
	SET_FO_START_DATE_TIME,
	SET_FO_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("month").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("month").milliseconds(0).valueOf(),
	filterFinancialPlanId: "",
	filters: [],
	selectedFinancialPlan: {
		index: -1,
		financialPlan: null,
	},
	selectedPaymentPlan: {
		index: -1,
		paymentPlan: null,
	},
	financialPlanTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		colStyle: [],
		isLoading: false,
	},
	paymentPlanTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.psId,
			ResourceAssistance.Message.supplierCode,
			ResourceAssistance.Message.supplierName,
			ResourceAssistance.Message.bankAccNum,
			ResourceAssistance.Message.bank,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.withholdingTax,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
			ResourceAssistance.Message.remark,
			ResourceAssistance.Message.fee,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalSearch: {
		searchFinancialRequestId: "",
	},
	modalNew: {
		supplierReceivedDateTime: moment().endOf("day").milliseconds(0).valueOf(),
		searchDueDate: "",
		filterSupplierCode: "",
		filterSupplierName: "",
		filterPaymentSlipId: "",
		isPaymentTypeLoading: "",
		isBankLoading: false,
		isServiceTypeLoading: false,
		paymentTypes: [],
		banks: [],
		serviceTypes: [],
		searchSelectedPaymentType: {
			index: -1,
			type: null,
		},
		searchSelectedBank: {
			index: -1,
			bank: null,
		},
		selectedPaymentType: {
			index: -1,
			type: null,
		},
		selectedBank: {
			index: -1,
			bank: null,
		},
		selectedServiceType: {
			index: -1,
			type: null,
		},
		selectedPaymentPlan: {
			index: -1,
			paymentPlan: null,
		},
		selectedPendingPaymentPlan: {
			index: -1,
			paymentPlan: null,
		},
		paymentPlanTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.psId,
				ResourceAssistance.Message.dueDate,
				ResourceAssistance.Message.payment,
				ResourceAssistance.Message.supplierCode,
				ResourceAssistance.Message.supplierName,
				ResourceAssistance.Message.bankAccNum,
				ResourceAssistance.Message.bank,
				ResourceAssistance.Message.amount,
				ResourceAssistance.Message.remark,
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
		pendingPaymentPlanTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.psId,
				ResourceAssistance.Message.dueDate,
				ResourceAssistance.Message.payment,
				ResourceAssistance.Message.supplierCode,
				ResourceAssistance.Message.supplierName,
				ResourceAssistance.Message.bankAccNum,
				ResourceAssistance.Message.bank,
				ResourceAssistance.Message.amount,
				ResourceAssistance.Message.remark,
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
	},
	modalSearchMore: {
		paymentSlipId: "",
	},
	modalEditRequest: {
		fee: "",
	},
}

const financialOperationsReducer = (state = init, action) => {
	switch (action.type) {
		case SET_FO_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_FO_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_FO_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_FO_FILTERS:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_FO_FILTER_FINANCIAL_PLAN_ID:
			return Object.assign({}, state, {
				filterFinancialPlanId: action.payload.value,
			})
		case SET_FO_REQUESTS:
			return Object.assign({}, state, {
				financialPlanTable: {
					...state.financialPlanTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_FO_SELECTED_FINANCIAL_REQUEST:
			return Object.assign({}, state, {
				selectedFinancialPlan: {
					index: action.payload.index,
					financialPlan: action.payload.selected,
				},
			})
		case SET_FO_PAYMENT_PLANS:
			return Object.assign({}, state, {
				paymentPlanTable: {
					...state.paymentPlanTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_FO_SELECTED_PAYMENT_PLAN:
			return Object.assign({}, state, {
				selectedPaymentPlan: {
					index: action.payload.index,
					paymentPlan: action.payload.selected,
				},
			})
		case SET_FO_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_FO_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_FO_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					supplierReceivedDateTime: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_SEARCH_DUE_DATE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchDueDate: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isPaymentTypeLoading: action.payload.isLoading,
				},
			})
		case SET_FO_MODAL_NEW_PAYMENT_TYPES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					paymentTypes: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchSelectedPaymentType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPaymentType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_BANK_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isBankLoading: action.payload.isLoading,
				},
			})
		case SET_FO_MODAL_NEW_BANKS:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					banks: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchSelectedBank: {
						index: action.payload.index,
						bank: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_SELECTED_BANK:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedBank: {
						index: action.payload.index,
						bank: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isServiceTypeLoading: action.payload.isLoading,
				},
			})
		case SET_FO_MODAL_NEW_SERVICE_TYPES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					serviceTypes: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedServiceType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterSupplierCode: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterSupplierName: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_FILTER_PS_ID:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterPaymentSlipId: action.payload.value,
				},
			})
		case SET_FO_MODAL_SEARCH_MORE_PAYMENT_SLIP_ID:
			return Object.assign({}, state, {
				modalSearchMore: {
					...state.modalSearchMore,
					paymentSlipId: action.payload.value,
				},
			})
		case SET_FO_MODAL_NEW_PAYMENT_PLANS:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					paymentPlanTable: {
						...state.modalNew.paymentPlanTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_FO_MODAL_NEW_SELECTED_PAYMENT_PLAN:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPaymentPlan: {
						index: action.payload.index,
						paymentPlan: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_NEW_PENDING_PAYMENT_PLANS:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					pendingPaymentPlanTable: {
						...state.modalNew.pendingPaymentPlanTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPendingPaymentPlan: {
						index: action.payload.index,
						paymentPlan: action.payload.selected,
					},
				},
			})
		case SET_FO_MODAL_EDIT_REQUEST_FEE:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					fee: action.payload.value,
				},
			})
		case SET_FO_MODAL_SEARCH_FINANCIAL_REQUEST_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchFinancialRequestId: action.payload.value,
				},
			})
		default:
			return state
	}
}
export default financialOperationsReducer
