import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"

class ContractBillingDataFactory extends PrintableDataFactory {
	static generatePdfBillingData = (org, contract, logo) => {
		let grandTotal = Utils.BigNumber(0)

		return {
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.contractingCompany }),
			taxIdIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.taxId }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			documentNumIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.documentNum }),
			insuranceCompanyIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.insuranceCompany }),
			factoryAreaIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.factoryArea }),
			addressIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.address }),
			telIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.tel }),
			dueDateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.dueDate }),
			seqIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.seq }),
			patientIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patient }),
			chiefComplainIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.chiefComplaint }),
			claimIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.fundOrClaimNum }),
			insuranceRefIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.insuranceReference }),
			amountIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),

			logo: logo,
			name: org.displayName,
			address: org.address.displayName,
			tel: org.address.tel,
			fax: org.address.fax,
			taxId: org.taxNo,

			documentDate: Utils.formatDate(contract.creationDateTime),
			documentNum: contract.id,
			insuranceCompany: contract.insuranceCompany,
			factoryArea: contract.factoryArea,
			insuranceCompanyAddress: contract.insuranceCompanyAddress,
			insuranceCompanyTel: contract.insuranceCompanyTel,
			dueDate: Utils.formatDate(contract.dueDateTime),

			patients: contract.contractBillingInvoices.map((invoice, idx) => {
				let amount = Utils.BigNumber(invoice.debt.total).minus(invoice.debt.billing.total)
				grandTotal = grandTotal.plus(amount)
				return {
					seq: idx + 1,
					date: Utils.formatDate(invoice.debt.billing.billingPlan.admission.creationDateTime),
					name: invoice.debt.billing.billingPlan.admission.patient.displayName,
					cc: invoice.patientDiagnosis,
					claim: invoice.claimNum,
					insuranceRef: invoice.insuranceRef,
					amount: Utils.formatNumWithComma(amount.toFixed(2)),
				}
			}),

			grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),
			grandTotalText: Utils.convertNumberToThaiLetters(grandTotal.toFixed(2)),

			signatureTitles: [
				this.getIntl().formatMessage({ id: ResourceAssistance.Message.acceptedBy }),
				this.getIntl().formatMessage({ id: ResourceAssistance.Message.issuedBy }),
			],
			signatureNames: ["", contract.lastModifiedBy.displayName],
			customSignatureDates: ["", Utils.formatDate(contract.creationDateTime)],
		}
	}
}

export default ContractBillingDataFactory
