const SurgicalSafetyCheckList = {
	confirmPatientIdentity: "confirmPatientIdentity",
	hasThePatientSignConsent: "hasThePatientSignConsent",
	isTheSiteMarked: "isTheSiteMarked",
	isTheAnesthesiaMachineAndMedicationCheckComplete: "isTheAnesthesiaMachineAndMedicationCheckComplete",
	isThePulseOximeterOnthePatient: "isThePulseOximeterOnthePatient",
	doesThePatientHave: "doesThePatientHave",
	knowAllergy: "knowAllergy",
	difficultAirwayOrAspirationRisk: "difficultAirwayOrAspirationRisk",
	riskOfGreaterThan500mlBloodLoss: "riskOfGreaterThan500mlBloodLoss",
	orEquipmentAndInstrument: "orEquipmentAndInstrument",
	medicalDeviceImplant: "medicalDeviceImplant",

	allTeamMembersHaveIntroducedThemselves: "allTeamMembersHaveIntroducedThemselves",
	patientNameDateOfBirth: "patientNameDateOfBirth",
	procedureSiteAndSide: "procedureSiteAndSide",
	hasAntibioticProphylaxisBeenGivenWithinTheLast60Min: "hasAntibioticProphylaxisBeenGivenWithinTheLast60Min",
	anticipatedCriticalEvents: "anticipatedCriticalEvents",
	toSurgeon: "toSurgeon",
	whatAreTheCriticalOrNonroutineSteps: "whatAreTheCriticalOrNonroutineSteps",
	howLongWillTheCaseTake: "howLongWillTheCaseTake",
	whatIsTheAnticipatedBloodLoss: "whatIsTheAnticipatedBloodLoss",
	toAnesthesiologist: "toAnesthesiologist",
	areThereAnyPatientSpecificConcerns: "areThereAnyPatientSpecificConcerns",
	toNursingTeam: "toNursingTeam",
	hasSterilizationBeenConfirmed: "hasSterilizationBeenConfirmed",
	areThereEquipmentIssuesOrAnyConcerns: "areThereEquipmentIssuesOrAnyConcerns",
	essentialImagingDisplayed: "essentialImagingDisplayed",

	nurseVerballyConfirms: "nurseVerballyConfirms",
	theNameOfTheProcedure: "theNameOfTheProcedure",
	completionOfInstrumentSpongeAndNeedleCounts: "completionOfInstrumentSpongeAndNeedleCounts",
	specimenLabeling: "specimenLabeling",
	whetherThereAreAnyEquipmentProblemsToBeAddressed: "whetherThereAreAnyEquipmentProblemsToBeAddressed",
	toSurgeonAnesthesiologistAndNurse: "toSurgeonAnesthesiologistAndNurse",
	whatAreTheKeyConcernsForRecoveryAndManagementOfThisPatient: "whatAreTheKeyConcernsForRecoveryAndManagementOfThisPatient",

	beforeInductionOfAnesthesia: "beforeInductionOfAnesthesia",
	beforeSkinIncision: "beforeSkinIncision",
	beforePatientLeavesOperatingRoom: "beforePatientLeavesOperatingRoom",
}

export default SurgicalSafetyCheckList
