import {
	SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY,
	SET_ADM_WP_ECLAIMUC_ISDISPLAYINACTIVE,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHDESCRIPTION,
	SET_ADM_WP_ECLAIMUC_GETURL,
	SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT,
	SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT,
	SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT,
	SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT,
	SET_ADM_WP_ECLAIMUC_SELECTEDITEM,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE,
	SET_ADM_WP_ECLAIMUC_MODAL_TITLE,
	SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL,
	SET_ADM_WP_ECLAIMUC_MODAL_CODE,
	SET_ADM_WP_ECLAIMUC_MODAL_NAME,
	SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION,
	SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT,
	SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT,
	SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE,
} from "~/redux/type"

const init = {
	eclaimUC: {
		isModalDisplay: false,
		displayInactive: true,
		displaySearchCode: false,
		displaySearchName: false,
		displaySearchVariable: false,
		displaySearchDescription: false,
		getUrl: "",
		searchCodeText: "",
		searchNameText: "",
		searchVariableText: "",
		searchDescriptionText: "",
		selectedItem: { index: -1, item: {} },
		itemTable: {
			isLoading: false,
			dataKeys: [],
			header: [],
			original: [],
			filtered: [],
			body: [],
			colStyle: [],
			rowColor: [],
		},
		newModal: {
			displayCode: false,
			displayName: false,
			displayFiles: false,
			displayDescription: false,
			displayDefault: false,
			title: "",
			saveUrl: "",
			code: "",
			name: "",
			description: "",
			variablesFormat: "",
			default: false,
			active: true,
		},
	},
}

const configEclaimReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					isModalDisplay: action.payload.isDisplay,
				},
			})
		case SET_ADM_WP_ECLAIMUC_ISDISPLAYINACTIVE:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					displayInactive: action.payload.isDisplay,
				},
			})
		case SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					displaySearchCode: action.payload.isDisplay,
				},
			})
		case SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					displaySearchName: action.payload.isDisplay,
				},
			})
		case SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					displaySearchVariable: action.payload.isDisplay,
				},
			})
		case SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHDESCRIPTION:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					displaySearchDescription: action.payload.isDisplay,
				},
			})
		case SET_ADM_WP_ECLAIMUC_GETURL:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					getUrl: action.payload.value,
				},
			})
		case SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					searchCodeText: action.payload.value,
				},
			})
		case SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					searchNameText: action.payload.value,
				},
			})
		case SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					searchVariableText: action.payload.value,
				},
			})
		case SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					searchDescriptionText: action.payload.value,
				},
			})
		case SET_ADM_WP_ECLAIMUC_SELECTEDITEM:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					itemTable: {
						...state.eclaimUC.itemTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_ITEMTABLE:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					itemTable: {
						...state.eclaimUC.itemTable,
						dataKeys: action.payload.dataKeys,
						header: action.payload.header,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						displayCode: action.payload.isDisplay,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						displayName: action.payload.isDisplay,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						displayDescription: action.payload.isDisplay,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						displayDefault: action.payload.isDisplay,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						displayFiles: action.payload.isDisplay,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_MODAL_TITLE: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						title: action.payload.value,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL:
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						saveUrl: action.payload.value,
					},
				},
			})
		case SET_ADM_WP_ECLAIMUC_MODAL_CODE: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						code: action.payload.value,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_NAME: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						name: action.payload.value,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						description: action.payload.value,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						variablesFormat: action.payload.value,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_DEFAULT: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						default: action.payload.value,
					},
				},
			})
		}
		case SET_ADM_WP_ECLAIMUC_MODAL_ACTIVE: {
			return Object.assign({}, state, {
				eclaimUC: {
					...state.eclaimUC,
					newModal: {
						...state.eclaimUC.newModal,
						active: action.payload.value,
					},
				},
			})
		}
		default:
			return state
	}
}

export default configEclaimReducer
