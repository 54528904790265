import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import OPDModalMedicalCertificateDiaRow from "./OPDModalMedicalCertificateDiaRow"
import OPDModalMedicalCertificateDocOptRow from "./OPDModalMedicalCertificateDocOptRow"
import OPDModalMedicalCertificateDoctorRow from "./OPDModalMedicalCertificateDoctorRow"
import OPDModalMedicalCertificateExamDate from "./OPDModalMedicalCertificateExamDate"
import OPDModalMedicalCertificateInvestigation from "./OPDModalMedicalCertificateInvestigation"
import OPDModalMedicalCertificateService from "./OPDModalMedicalCertificateService"
import OPDModalMedicalCertificateSymptomRow from "./OPDModalMedicalCertificateSymptomRow"
import OPDModalMedicalCertificateTreatment from "./OPDModalMedicalCertificateTreatment"

class OPDModalMedicalCertificateDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<OPDModalMedicalCertificateService />
				<OPDModalMedicalCertificateDoctorRow />
				<OPDModalMedicalCertificateExamDate />
				<OPDModalMedicalCertificateSymptomRow />
				<OPDModalMedicalCertificateDiaRow />
				<OPDModalMedicalCertificateInvestigation />
				<OPDModalMedicalCertificateTreatment />
				<OPDModalMedicalCertificateDocOptRow />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateDetails)
