import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { FiCheckSquare } from "react-icons/fi"
import { ImPrinter } from "react-icons/im"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setTfModalTransferOrgs,
	setTfModalTransferPendingItems,
	setTfRequests,
	setTfSelectedTransfer,
	setTfTransfers,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_TF_NEW_TRANSFER_DISPLAY, SET_TF_REQUEST_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class TFRightTransferRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onTransfer = this.onTransfer.bind(this)
		this.onPrint = this.onPrint.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.TF.transfer)) {
					this.approveTransfer()
				}
			}
		}

		if (JSON.stringify(prevProps.transfer.selectedRequest) !== JSON.stringify(this.props.transfer.selectedRequest)) {
			if (this.props.transfer.selectedRequest.index === -1) {
				this.props.setTfTransfers([])
			} else {
				this.props.setTfTransfers(this.props.transfer.selectedRequest.request.transferOrders)
			}
		}

		if (JSON.stringify(prevProps.transfer.transferTable.original) !== JSON.stringify(this.props.transfer.transferTable.original)) {
			let index = this.props.transfer.selectedTransfer.transfer
				? this.props.transfer.transferTable.filtered.findIndex((each) => each.id === this.props.transfer.selectedTransfer.transfer.id)
				: -1
			if (index !== -1) {
				this.props.setTfSelectedTransfer(index, this.props.transfer.transferTable.filtered[index])
			} else {
				this.props.setTfSelectedTransfer(-1, null)
			}
		}
	}

	isDisplayEdit() {
		return this.props.transfer.selectedRequest.request && !this.props.transfer.selectedRequest.request.approvalApproved
	}

	isDisplayTransfer() {
		return this.props.transfer.selectedRequest.request && !this.props.transfer.selectedRequest.request.approvalApproved
	}

	approveTransfer() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.transfer.approve,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.transfer.selectedRequest.request.id,
				transferId: this.props.transfer.selectedTransfer.transfer.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let requests = this.props.transfer.requestTable.original.filter((each) => each.id !== res.data.requests[0].id)
			requests.push(res.data.requests[0])
			this.props.setTfRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_TF_REQUEST_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(row, index) {
		this.props.setTfSelectedTransfer(index, this.props.transfer.transferTable.filtered[index])
	}

	onEdit(e, row, rIdx, cIdx) {
		this.props.loadOrgs(
			ResourceAssistance.Url.transfer.getOrgs,
			(res) => {
				this.props.setTfModalTransferOrgs(res.data.locations)
				//assign transfer order infos to item
				let selectedTransfer = this.props.transfer.transferTable.filtered[rIdx]
				let items = selectedTransfer.items
				items.forEach((each) => {
					Object.assign(each, {
						location: selectedTransfer.location.parent.displayName + "-" + selectedTransfer.location.displayName,
						locationId: selectedTransfer.location.id,
					})
				})
				this.props.setTfModalTransferPendingItems(items)
				this.props.onModalDisplayAction(SET_TF_NEW_TRANSFER_DISPLAY, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			{ orgId: this.props.transfer.selectedRequest.request.org.id }
		)
	}

	onTransfer() {
		this.props.setWarningId(ResourceAssistance.ID.TF.transfer)
		this.props.setWarningMsgAction(translate(ResourceAssistance.Warning.approveTransfer))
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onPrint(e, row, rIdx, cIdx) {
		this.props.print(this.props.transfer.transferTable.filtered[rIdx])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.pendingTransfers)}</legend>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							enableHighlight
							striped
							hover
							enableOverlay
							displayBtn
							buttonColors={[
								ResourceAssistance.Button.variant.outlinePrimary,
								ResourceAssistance.Button.variant.outlineGreen,
								ResourceAssistance.Button.variant.outlineInfo,
							]}
							btnMessages={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.transfer }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.print }),
							]}
							btnIcons={[
								this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayTransfer() ? <FiCheckSquare size={ResourceAssistance.ReactIcon.size} /> : undefined,
								<ImPrinter size={ResourceAssistance.ReactIcon.size} />,
							]}
							onCheckedArray={[this.onEdit, this.onTransfer, this.onPrint]}
							data={this.props.transfer.transferTable}
							isClearHighlight={this.props.transfer.selectedTransfer.index === -1}
							highlightedRow={this.props.transfer.selectedTransfer.index}
							onClick={this.onSelectRow}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setTfTransfers,
			setTfSelectedTransfer,
			setLoadingAction,
			setWarningId,
			setWarningMsgAction,
			setValue,
			setTfModalTransferPendingItems,
			setTfRequests,
			setTfModalTransferOrgs,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TFRightTransferRow))
