import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { IoMdDoneAll } from "react-icons/io"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setPSModalNewPendingInvoices } from "~/redux/action"

class PSModalNewInvoiceAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	isDownBtnDisabled() {
		if (!this.props.PS.modalNew.selectedInvoice.invoice) {
			return true
		}
	}

	isSelectAllDisabled() {
		if (this.props.PS.modalNew.invoiceTable.filtered.length === 0) {
			return true
		}
	}

	onDownBtn() {
		let selectedInvoice = Object.assign({}, this.props.PS.modalNew.selectedInvoice.invoice, {
			supplierBankCode: this.props.PS.modalNew.searchSupplier.supplier.bankCode,
			supplierBankAccount: this.props.PS.modalNew.searchSupplier.supplier.bankAccount,
			supplierPhone: this.props.PS.modalNew.searchSupplier.supplier.phone,
			supplierEmail: this.props.PS.modalNew.searchSupplier.supplier.email,
			supplierAddress: this.props.PS.modalNew.searchSupplier.supplier.address,
			supplierTaxId: this.props.PS.modalNew.searchSupplier.supplier.taxId,
		})
		let pendingInvoices = Array.from(this.props.PS.modalNew.pendingInvoiceTable.filtered)
		pendingInvoices.push(selectedInvoice)
		this.props.setPSModalNewPendingInvoices(pendingInvoices)
	}

	onUpBtn() {
		let pendingInvoices = this.props.PS.modalNew.pendingInvoiceTable.filtered.filter(
			(each, index) => index !== this.props.PS.modalNew.selectedPendingInvoice.index
		)
		this.props.setPSModalNewPendingInvoices(pendingInvoices)
	}

	onSelectAll() {
		let pendingInvoices = Array.from(this.props.PS.modalNew.pendingInvoiceTable.filtered)
		let selectedInvoices = Array.from(this.props.PS.modalNew.invoiceTable.filtered).map((each) =>
			Object.assign({}, each, {
				supplierBankCode: this.props.PS.modalNew.searchSupplier.supplier.bankCode,
				supplierBankAccount: this.props.PS.modalNew.searchSupplier.supplier.bankAccount,
				supplierPhone: this.props.PS.modalNew.searchSupplier.supplier.phone,
				supplierEmail: this.props.PS.modalNew.searchSupplier.supplier.email,
				supplierAddress: this.props.PS.modalNew.searchSupplier.supplier.address,
				supplierTaxId: this.props.PS.modalNew.searchSupplier.supplier.taxId,
			})
		)
		this.props.setPSModalNewPendingInvoices(pendingInvoices.concat(selectedInvoices))
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} disabled={this.isDownBtnDisabled()} onClick={this.onDownBtn}>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button variant={ResourceAssistance.Button.variant.primary} disabled={this.isSelectAllDisabled()} onClick={this.onSelectAll}>
						<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.selectAll)}
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button variant={ResourceAssistance.Button.variant.link} disabled={this.props.PS.modalNew.selectedPendingInvoice.index === -1} onClick={this.onUpBtn}>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setPSModalNewPendingInvoices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PSModalNewInvoiceAction)
