import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { IoHourglassOutline } from "react-icons/io5"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients } from "~/redux/action"
import { SET_OPD_SEARCH_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OPDModalSearchDetails from "./OPDModalSearchDetails"

class OPDModalSearch extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalSearch",
			pid: "",
			identificationNumber: "",
			firstName: "",
			lastName: "",
		}

		this.onClose = this.onClose.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.setSearchableState = this.setSearchableState.bind(this)
	}

	reset() {
		this.setState({
			pid: "",
			identificationNumber: "",
			firstName: "",
			lastName: "",
		})
		this.props.onModalDisplayAction(SET_OPD_SEARCH_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return false
	}

	setSearchableState(key, value) {
		this.setState({
			[key]: value,
		})
	}

	onClose() {
		this.reset()
	}

	onSearch(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.opd.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				departmentId: this.props.opd.location.id,
				pid: this.state.pid ? this.state.pid : 0,
				identificationNumber: this.state.identificationNumber,
				firstName: Utils.trim(this.state.firstName),
				lastName: Utils.trim(this.state.lastName),
			},
		}
		let callback = (res) => {
			this.props.setOPDPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onSearch}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.search)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<OPDModalSearchDetails {...this.state} setSearchableState={this.setSearchableState} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.primary}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.search)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientSearchDisplay,
	opd: state.hospital.opd,
	login: state.login,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalSearch)
