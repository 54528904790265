import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setCashierModalSearchPatients } from "~/redux/action"
import { SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierModalSearchPatientResult extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentWillUnmount() {
		this.props.setCashierModalSearchPatients([])
		this.props.setSelected(SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT, -1, null)
	}

	onSelect(lab, index) {
		this.props.setSelected(
			SET_CASHIER_MODAL_SEARCH_SELECTED_PATIENT,
			index,
			this.props.cashier.modalSearch.patientTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col id={ResourceAssistance.ID.HOSPITEL.cashier.modalSearch.patientTable}>
					<ScrollableTable
						enableHighlight
						striped
						bordered
						hover
						isClearHighlight={this.props.cashier.modalSearch.selectedPatient.index === -1}
						data={this.props.cashier.modalSearch.patientTable}
						onClick={this.onSelect}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierModalSearchPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalSearchPatientResult)
