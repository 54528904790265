import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION,
	SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME,
	SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class OPDModalDoctorInstructionOrder extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onDescription = this.onDescription.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.opd.modalDoctorInstruction.startDateTime !== this.props.opd.modalDoctorInstruction.startDateTime) {
			if (this.props.opd.modalDoctorInstruction.startDateTime > this.props.opd.modalDoctorInstruction.endDateTime) {
				this.props.setValue(
					SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME,
					Utils.generateDateFromLong(this.props.opd.modalDoctorInstruction.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
				)
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME, Utils.generateDate().getTime())
		this.props.setValue(
			SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME,
			Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
		)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION, "")
	}

	onStartDate(date) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_INSTRUCTION_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_INSTRUCTION_END_DATETIME, date.getTime())
	}

	onDescription(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_INSTRUCTION_DESCRIPTION, event.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row style={{ alignSelf: "center" }}>
					<Col md="auto">
						<DatePicker
							isShowTime={false}
							minDate={Utils.generateDate()}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.opd.modalDoctorInstruction.startDateTime)}
							onChange={this.onStartDate}
						/>
					</Col>
					<Col md={"auto"}>
						<label>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md={"auto"}>
						<DatePicker
							isShowTime={false}
							minDate={Utils.generateDateFromLong(this.props.opd.modalDoctorInstruction.startDateTime)}
							showDisabledMonthNavigation
							startDate={Utils.generateDateFromLong(this.props.opd.modalDoctorInstruction.endDateTime)}
							onChange={this.onEndDate}
							isShowDateRange
							shouldCloseOnSelect={false}
							rangeStartDate={Utils.generateDateFromLong(this.props.opd.modalDoctorInstruction.startDateTime)}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "0.3rem" }}>
					<Col>
						<FormattedMessage id={ResourceAssistance.Message.description}>
							{(placeholder) => (
								<FormControl
									autoFocus
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									placeholder={placeholder}
									value={this.props.opd.modalDoctorInstruction.description}
									onChange={this.onDescription}
								/>
							)}
						</FormattedMessage>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalDoctorInstructionOrder))
