import { TreeSelect } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_LOCATION_ORGS,
	SET_OPD_MODAL_LOCATION_SELECTED_BRANCH,
	SET_OPD_MODAL_LOCATION_SELECTED_DEPARTMENT,
	SET_OPD_MODAL_LOCATION_SELECTED_ORG,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalLocationOPD extends React.Component {
	constructor(props) {
		super(props)

		this.onLocation = this.onLocation.bind(this)
	}

	componentDidMount() {
		this.props.loadLocations()
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_LOCATION_ORGS, [])
	}

	render() {
		return (
			<Row>
				<Col>
					<TreeSelect
						loading={this.props.opd.modalLocation.isOrgsLoading}
						treeLine={{ showLeafIcon: false }}
						style={{
							textAlign: "center",
						}}
						treeExpandAction="click"
						placeholder={translate(ResourceAssistance.Message.department)}
						treeData={Utils.getTreeNodesFromOrg(this.props.opd.modalLocation.orgs, false)}
						onSelect={this.onLocation}
					/>
				</Col>
			</Row>
		)
	}
	onLocation(value, node) {
		this.props.setSelected(SET_OPD_MODAL_LOCATION_SELECTED_ORG, node.extra.org.index, node.extra.org.org)
		this.props.setSelected(SET_OPD_MODAL_LOCATION_SELECTED_BRANCH, node.extra.branch.index, node.extra.branch.branch)
		this.props.setSelected(SET_OPD_MODAL_LOCATION_SELECTED_DEPARTMENT, node.extra.department.index, node.extra.department.department)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalLocationOPD)
