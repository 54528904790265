import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setInventoryInventoryDetails, setInventorySelectedInventoryDetail } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class InventoryThirdRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "InventoryThirdRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			JSON.stringify(prevProps.selectedBranch) !== JSON.stringify(this.props.selectedBranch) ||
			JSON.stringify(prevProps.selectedInventory) !== JSON.stringify(this.props.selectedInventory) ||
			prevProps.searchCode !== this.props.searchCode ||
			prevProps.searchName !== this.props.searchName ||
			prevProps.isDisplayMinStock !== this.props.isDisplayMinStock ||
			prevProps.isDisplayExpire !== this.props.isDisplayExpire ||
			prevProps.lotExpiredDate !== this.props.lotExpiredDate
		) {
			if (this.props.selectedInventory.index === -1) {
				this.props.setInventoryInventoryDetails(
					this.props.selectedBranch.index === -1 ? [] : this.props.selectedBranch.loc.inStockItems
				)
			} else {
				this.props.setInventoryInventoryDetails(this.props.selectedInventory.loc.inventoryDetails)
			}
		}

		if (prevProps.selectedType.index !== this.props.selectedType.index) {
			if (this.props.selectedInventory.loc) {
				this.props.setInventoryInventoryDetails(this.props.selectedInventory.loc.inventoryDetails)
			} else if (this.props.selectedBranch.loc) {
				this.props.setInventoryInventoryDetails(this.props.selectedBranch.loc.inStockItems)
			} else {
				this.props.setInventoryInventoryDetails([])
			}
		}
	}

	onSelectRow(item, index) {
		this.props.setInventorySelectedInventoryDetail(index, this.props.inventories.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"g-0"}>
				<Col>
					<ScrollableTable
						className={ResourceAssistance.CSS.fullSize}
						striped
						responsive
						hover
						enableHighlight
						onClick={this.onSelectRow}
						data={this.props.inventories}
						isClearHighlight={this.props.selectedInventoryDetail.index === -1}
					/>
					{this.props.inventories.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	inventories: state.inventory.inventories,
	selectedType: state.inventory.selectedType,
	searchCode: state.inventory.searchCodeText,
	searchName: state.inventory.searchNameText,
	selectedBranch: state.inventory.selectedBranch,
	selectedInventory: state.inventory.selectedInventory,
	selectedInventoryDetail: state.inventory.selectedInventoryDetail,
	isDisplayExpire: state.inventory.isDisplayExpireChecked,
	isDisplayMinStock: state.inventory.isDisplayMinStockChecked,
	lotExpiredDate: state.inventory.lotExpiredDate,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setInventoryInventoryDetails, setInventorySelectedInventoryDetail }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(InventoryThirdRow)
