import { Card, Divider, Select } from "antd"
import React, { Fragment } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { FaBalanceScale, FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { TbDiscount2 } from "react-icons/tb"

import _ from "lodash"
import { ImPriceTags } from "react-icons/im"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont, SvgInpatient, SvgOutpatient } from "~/icons"
import {
	onModalDisplayAction,
	setConfigGLReceivableActivities,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING,
	SET_ADM_GL_RECEIVABLE_DISPLAY,
	SET_ADM_GL_RECEIVABLE_FILTER_CODE,
	SET_ADM_GL_RECEIVABLE_FILTER_NAME,
	SET_ADM_GL_RECEIVABLE_SELECTED_AR,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaMoneyBillTransfer } from "react-icons/fa6"

class ConfigGeneralLedgerAR extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigGeneralLedgerAR",
			EConfigGLFilters: [
				// {
				// 	id: ResourceAssistance.Message.category,
				// 	displayName: translate(ResourceAssistance.Message.category),
				// },
				// {
				// 	id: ResourceAssistance.Hospitel.healthCarePlan,
				// 	displayName: translate(ResourceAssistance.Hospitel.healthCarePlan),
				// },
			],
		}

		this.onChangeFilter = this.onChangeFilter.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		this.props.loadData(
			ResourceAssistance.Url.admin.generalLedger.getAccountsReceivableActivities,
			(res) => {
				this.props.setConfigGLReceivableActivities(res.data.activities)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_ADM_GL_RECEIVABLE_ACTIVITIES_LOADING, false)
			}
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ADM.generalLedger.accountsReceivable.delete)) {
				this.deleteAccountsReceivable()
			}
		}

		// if (!_.isEqual(prevProps.language.locale, this.props.language.locale)) {
		// 	this.setState({
		// 		EConfigGLFilters: [
		// 			{
		// 				id: ResourceAssistance.Message.incomplete,
		// 				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.incomplete }),
		// 			},
		// 		],
		// 	})
		// }
		if (prevProps.AR.filterCode !== this.props.AR.filterCode || prevProps.AR.filterName !== this.props.AR.filterName) {
			this.props.setConfigGLReceivableActivities(this.props.AR.activityTable.original)
		}
		if (!_.isEqual(prevProps.AR.activityTable.filtered, this.props.AR.activityTable.filtered)) {
			this.props.setSelected(SET_ADM_GL_RECEIVABLE_SELECTED_AR, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setConfigGLReceivableActivities([])
		this.props.setSelected(SET_ADM_GL_RECEIVABLE_SELECTED_AR, -1, null)
		this.props.setValue(SET_ADM_GL_RECEIVABLE_FILTER_CODE, "")
		this.props.setValue(SET_ADM_GL_RECEIVABLE_FILTER_NAME, "")
	}

	deleteAccountsReceivable() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.generalLedger.deleteAccountsReceivableActivity,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				activities: [
					{
						id: this.props.AR.selectedAR.AR.id,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setConfigGLReceivableActivities(res.data.activities)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getFilters() {
		let filters = []
		// this.props.AR.filters.forEach((each) => {
		// 	this.state.CAccountsReceivableFilters.forEach((filter,idx) => {
		// 		if (filter.id === each) {
		// 			filters.push(idx)
		// 		}
		// 	})
		// })
		return filters
	}

	onChangeFilter(values) {
		// let filters = values.map((each) => this.state.CAccountsReceivableFilters[each].id)
		// this.props.setValue(SET_AR_FILTER, filters)
	}

	onSearchCode(event) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_FILTER_CODE, event.target.value)
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_GL_RECEIVABLE_FILTER_NAME, event.target.value)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADM_GL_RECEIVABLE_SELECTED_AR, index, this.props.AR.activityTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADM_GL_RECEIVABLE_SELECTED_AR, -1, null)
		this.props.onModalDisplayAction(SET_ADM_GL_RECEIVABLE_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_GL_RECEIVABLE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedAR = this.props.AR.activityTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningId(ResourceAssistance.ID.ADM.generalLedger.accountsReceivable.delete)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				hoverable
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>{selectedAR.chartOfAccounts.fullCode}</p>
				<p>{selectedAR.chartOfAccounts.displayName}</p>
				<p>{selectedAR.chartOfAccounts.description}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md="auto">
						<Select
							mode={ResourceAssistance.Select.mode.multiple}
							allowClear
							showSearch
							style={{ flexGrow: 1, textAlign: "center", width: "200px" }}
							maxTagCount="responsive"
							optionFilterProp="children"
							filterOption={(input, option) => {
								return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}}
							filterSort={(optionA, optionB) => {
								return optionA.children.props.id.toLowerCase().localeCompare(optionB.children.props.id.toLowerCase())
							}}
							placeholder={translate(ResourceAssistance.Message.filter)}
							onChange={this.onChangeFilter}
							value={this.getFilters()}
						>
							{Utils.renderSelects(this.state.EConfigGLFilters, false)}
						</Select>
					</Col>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
							callbacks={[this.onSearchCode, this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.AR.selectedAR.index === -1} onClick={this.onEdit}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							hover
							striped
							enableHighlight
							displayBtn
							buttonColors={[
								[
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
									ResourceAssistance.Button.variant.outlineInfo,
								],
								[],
								[],
								[],
								[],
								[],
								[],
								[ResourceAssistance.Button.variant.outlineRed],
							]}
							btnMessages={[[], [], [], [], [], [], [], [this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })]]}
							btnTypes={[
								[
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
									EBtnType.POPOVER,
								],
								[],
								[],
								[],
								[],
								[],
								[],
								[EBtnType.DEFAULT],
							]}
							btnIcons={[
								[
									<ImPriceTags size={ResourceAssistance.ReactIcon.size} />,
									<TbDiscount2 size={ResourceAssistance.ReactIcon.size} />,
									<IconFont type={"icon-ali-pmgcare-WithholdingTaxType"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
									<FaMoneyBillTransfer size={ResourceAssistance.ReactIcon.size} />,
									<FaBalanceScale size={ResourceAssistance.ReactIcon.size} />,
									<SvgInpatient width={ResourceAssistance.ReactIcon.size} />,
									<SvgOutpatient width={ResourceAssistance.ReactIcon.size} />,
									<IconFont type={"icon-ali-pmgcare-jiechu"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
									<IconFont type={"icon-ali-pmgcare-guihuan"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
								],
								[],
								[],
								[],
								[],
								[],
								[],
								[<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />],
							]}
							onCheckedArray={[this.onDelete]}
							data={this.props.AR.activityTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.AR.selectedAR.index === -1}
							highlightedRow={this.props.AR.selectedAR.index}
						/>
						{this.props.AR.activityTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	AR: state.admin.configGL.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setConfigGLReceivableActivities,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGeneralLedgerAR))
