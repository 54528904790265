import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmSSelectedSupplier, setAdmSSuppliers, setLoadingAction } from "~/redux/action"
import { SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import AdmSModalNSDetails from "./AdmSModalNSDetails"
import { Utils } from "~/utils/Utils"

class AdmSModalNS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmSModalNS",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	isDoneDisabled() {
		let target = Object.assign({}, this.props.supplier.selectedSupplier.supplier, {
			...this.props.supplier.selectedSupplier.supplier,
			code: this.props.supplier.newSupplierModal.code.trim(),
			displayName: this.props.supplier.newSupplierModal.name.trim(),
			phone: this.props.supplier.newSupplierModal.phone,
			email: Utils.trim(this.props.supplier.newSupplierModal.email),
			address: this.props.supplier.newSupplierModal.address.trim(),
			taxNum: Utils.trim(this.props.supplier.newSupplierModal.taxId),
			bankCode: Utils.trim(this.props.supplier.newSupplierModal.bankCode),
			bankAccount: Utils.trim(this.props.supplier.newSupplierModal.account),
			active: this.props.supplier.newSupplierModal.isActive,
		})
		return _.isEqual(this.props.supplier.selectedSupplier.supplier, target)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.admin.supplier.saveSupplier}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.supplier.selectedSupplier.index === -1 ? 0 : this.props.supplier.selectedSupplier.supplier.id,
				code: this.props.supplier.newSupplierModal.code.trim(),
				name: this.props.supplier.newSupplierModal.name.trim(),
				phone: this.props.supplier.newSupplierModal.phone,
				email: Utils.trim(this.props.supplier.newSupplierModal.email),
				address: this.props.supplier.newSupplierModal.address.trim(),
				taxNum: Utils.trim(this.props.supplier.newSupplierModal.taxId),
				bankCode: Utils.trim(this.props.supplier.newSupplierModal.bankCode),
				bankAccount: Utils.trim(this.props.supplier.newSupplierModal.bankAccount),
				active: this.props.supplier.newSupplierModal.isActive,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			this.props.setAdmSSelectedSupplier(-1, null)
			let suppliers = this.props.supplier.supplierTable.original.filter((each) => each.id !== res.data.suppliers[0].id)
			suppliers.push(res.data.suppliers[0])
			this.props.setAdmSSuppliers(suppliers)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)

		this.props.onModalDisplayAction(SET_ADM_SUPPLIER_NEW_SUPPLIER_DISPLAY, false)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.newSupplier)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						{/*  */}
						{<AdmSModalNSDetails />}
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmSNewSupplierDisplay,
	login: state.login,
	supplier: state.admin.itemConfig.supplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction, setAdmSSuppliers, setAdmSSelectedSupplier }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmSModalNS)
