import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
// import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile"
// import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn"
// import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet"
import ReactExport from "react-export-excel"
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

class ExcelDocument extends React.Component {
	constructor(props) {
		super(props)

		this.ref = React.createRef()
		this.state = {
			excel: {
				sheets: [
					{
						headers: [],
						data: [],
					},
				],
			},
			isReadyToDownload: false,
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.isReadyToDownload, this.state.isReadyToDownload)) {
			if (this.ref.current && this.state.isReadyToDownload) {
				this.ref.current.handleDownload()
				this.setState({
					isReadyToDownload: false,
				})
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.setState({
			excel: {
				sheets: [
					{
						headers: [],
						data: [],
					},
				],
			},
			isReadyToDownload: false,
		})
	}

	resetExcel = () => {
		this.reset()
	}

	click() {
		this.setState({
			isReadyToDownload: true,
		})
	}

	updateExcel = (excel) => {
		this.setState({
			excel: excel,
		})
	}

	render() {
		return (
			<div style={this.props.style}>
				<ExcelFile ref={this.ref} element={this.props.element} filename={this.state.excel.filename}>
					{this.state.excel.sheets.map((sheet, sheetIdx) => {
						return (
							<ExcelSheet key={sheetIdx} data={sheet.data} dataSet={sheet.dataSet} name={sheet.name}>
								{sheet.headers &&
									sheet.headers.map((header, headerIdx) => {
										return (
											<ExcelColumn
												key={headerIdx}
												label={header}
												value={(col) => {
													return col[headerIdx]
												}}
											/>
										)
									})}
							</ExcelSheet>
						)
					})}
				</ExcelFile>
			</div>
		)
	}
}

ExcelDocument.propTypes = {
	element: propTypes.object,
}

ExcelDocument.defaultProps = {}

export default ExcelDocument
