import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { BiBarcodeReader } from "react-icons/bi"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected, setUploadDocuments, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_COMMON_DOCUMENT_DATA,
	SET_DOCUMENT_SELECTED_DOCUMENT,
	SET_DOCUMENT_UPLOAD_DISPLAY,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import { Utils } from "~/utils/Utils"

class DocumentTable extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
		this.onPrint = this.onPrint.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.deleteDocument = this.deleteDocument.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.MENU.document.deleteUploadDocument)) {
				this.deleteDocument()
			}
		}

		if (!_.isEqual(prevProps.document.filterName, this.props.document.filterName)) {
			this.props.setUploadDocuments(this.props.document.documentTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setUploadDocuments([])
		this.props.setSelected(SET_DOCUMENT_SELECTED_DOCUMENT, -1, null)
	}

	deleteDocument() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.document.deleteUploadDocument,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				uploadDocuments: [
					{
						id: this.props.document.selectedDocument.document.id,
						name: this.props.document.selectedDocument.document.fileName,
						path: this.props.document.selectedDocument.document.filePath,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setUploadDocuments(res.data.uploadDocuments)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	async print(data, isDisplayPatientLabel) {
		let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
		if (isDisplayPatientLabel && this.props.document.patient) {
			const { displayName, id, dobtime } = this.props.document.patient
			const dob = Utils.formatDate(dobtime)
			const age = Utils.calculateAge(dobtime)
			const patientInfo = `${displayName}\nPID:${id}\nDOB:${dob} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.age })}:${age}`
			myWindow.data = await PrintableDataFactory.generatePdfFileWithInfo(data, patientInfo)
		} else {
			myWindow.data = PrintableDataFactory.generatePdfFileData(data)
		}
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						style={{ minHeight: "500px", maxHeight: "500px" }}
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							[ResourceAssistance.Button.variant.outlineInfo],
							[],
							[],
							[],
							[],
							[],
							[ResourceAssistance.Button.variant.outlineInfo, ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed],
						]}
						btnIcons={[
							[<BiBarcodeReader size={ResourceAssistance.ReactIcon.size} />],
							[],
							[],
							[],
							[],
							[],
							[
								<ImPrinter size={ResourceAssistance.ReactIcon.size} />,
								<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
								<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
							],
						]}
						btnTypes={[[EBtnType.POPOVER], [], [], [], [], [], [EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT]]}
						onCheckedArray={[this.onPrint, this.onModify, this.onDelete]}
						isClearHighlight={this.props.document.selectedDocument.index === -1}
						higlightedRow={this.props.document.selectedDocument.index}
						data={this.props.document.documentTable}
						onClick={this.onSelect}
					/>
					{this.props.document.documentTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}

	onSelect(row, index) {
		this.props.setSelected(SET_DOCUMENT_SELECTED_DOCUMENT, index, this.props.document.documentTable.filtered[index])
	}

	onPrint(event, row, rIdx, cIdx) {
		let selectedDocument = this.props.document.documentTable.filtered[rIdx]
		if (this.props.common.documents[selectedDocument.id]) {
			this.print(this.props.common.documents[selectedDocument.id].data, this.props.common.documents[selectedDocument.id].isDisplayPatientLabel)
			return
		}
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.document.downloadUploadDocument,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				uploadDocuments: [
					{
						id: selectedDocument.id,
						name: selectedDocument.fileName,
						path: selectedDocument.filePath,
					},
				],
			},
		}
		let callback = (res) => {
			let rst = Object.assign({}, this.props.common.documents)
			res.data.uploadDocuments.forEach((each) => {
				if (each.encodedData) {
					rst[each.id] = {
						data: each.encodedData,
						isDisplayPatientLabel: each.displayPatientLabel,
					}
				}
			})
			this.props.setValue(SET_COMMON_DOCUMENT_DATA, rst)
			this.print(rst[this.props.document.selectedDocument.document.id].data, rst[this.props.document.selectedDocument.document.id].isDisplayPatientLabel)
			this.props.setUploadDocuments(res.data.uploadDocuments)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_DOCUMENT_UPLOAD_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedDocument = this.props.document.documentTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.MENU.document.deleteUploadDocument)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.fileName)} span={1}>
						{selectedDocument.fileName}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	common: state.common,
	document: state.menu.document,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setUploadDocuments,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DocumentTable))
