import Chart from "chart.js/auto"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalIntraAneRecordChart extends React.Component {
	chart = null

	EDataType = {
		Pulse: "Pulse",
		NIBP_Upper: "NIBP_Upper",
		NIBP_Lower: "NIBP_Lower",
		ALine_Upper: "ALine_Upper",
		ALine_Lower: "ALine_Lower",
	}
	constructor(props) {
		super(props)

		this.chartRef = React.createRef()
	}

	componentDidMount() {
		this.buildChart()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.selectedRequest.request, this.props.operatingRoom.selectedRequest.request)) {
			if (this.props.operatingRoom.selectedRequest.request) {
				if (this.chart) {
					let labels = this.getChartLabels()
					this.chart.data.labels = labels
					this.chart.data.datasets[0].data = this.getDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.pr),
						this.EDataType.Pulse
					)
					this.chart.data.datasets[1].data = this.getDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.bp),
						this.EDataType.NIBP_Upper
					)
					this.chart.data.datasets[2].data = this.getDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.bp),
						this.EDataType.NIBP_Lower
					)
					this.chart.data.datasets[3].data = this.getDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.aline),
						this.EDataType.ALine_Upper
					)
					this.chart.data.datasets[4].data = this.getDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.bp),
						this.EDataType.ALine_Lower
					)
					this.chart.data.datasets[5].data = this.getStartEndDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime,
						this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime
					)
					this.chart.data.datasets[6].data = this.getStartEndDatas(
						labels,
						this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime,
						this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime
					)
					this.chart.update()
				} else {
					this.buildChart()
				}
			} else {
				if (this.chart) {
					this.chart.destroy()
					this.chart = null
				}
			}
		}
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.destroy()
			this.chart = null
		}
	}

	getChartLabels() {
		let xAxis = []
		if (this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime) {
			xAxis.push(this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime)
			xAxis.push(this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime)
		}
		if (this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime) {
			xAxis.push(this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime)
			xAxis.push(this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime)
		}
		xAxis = xAxis.concat(this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.map((each) => each.dateTime))
		return [...new Set(xAxis)].sort((a, b) => Utils.sort(a, b)).map((each) => Utils.formatDateTime(each))
	}

	getStartEndDatas(labels, start, end) {
		let rst = []
		labels.forEach((each) => {
			if (each === Utils.formatDateTime(start)) {
				rst.push(0)
			} else if (each === Utils.formatDateTime(end)) {
				rst.push(0)
			} else {
				rst.push(null)
			}
		})
		return rst
	}

	getDatas(labels, values, type) {
		let rst = []
		labels.forEach((label) => {
			let value = values.find((eachValue) => Utils.formatDateTime(eachValue.dateTime) === label)
			if (value) {
				switch (type) {
					case this.EDataType.Pulse:
						rst.push(value.pr)
						break
					case this.EDataType.NIBP_Upper:
						rst.push(value.bp.split("/")[0])
						break
					case this.EDataType.NIBP_Lower:
						rst.push(value.bp.split("/")[1])
						break
					case this.EDataType.ALine_Upper:
						rst.push(value.aline.split("/")[0])
						break
					case this.EDataType.ALine_Lower:
						rst.push(value.aline.split("/")[1])
						break
					default:
						break
				}
			} else {
				rst.push(null)
			}
		})
		return rst
	}

	buildChart() {
		const ctx = this.chartRef.current.getContext("2d")
		this.chartRef.current.style.maxHeight = "735px"
		this.chartRef.current.style.maxWidth = this.chartRef.current.clientWidth + "px"
		let labels = this.getChartLabels()
		this.chart = new Chart(ctx, {
			type: "line",
			data: {
				labels: labels,
				datasets: [
					{
						data: this.getDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.pr),
							this.EDataType.Pulse
						),
						label: this.props.intl.formatMessage({ id: ResourceAssistance.Message.pulse }),
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.red,
						backgroundColor: "white",
						fill: false,
						borderWidth: 1,
						pointRadius: 5,
						spanGaps: true,
					},
					{
						data: this.getDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.bp),
							this.EDataType.NIBP_Upper
						),
						label: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.NIBP })} ${this.props.intl.formatMessage({
							id: ResourceAssistance.Message.systolic,
						})}`,
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.blue,
						backgroundColor: "white",
						fill: false,
						borderWidth: 1,
						pointRadius: 5,
						spanGaps: true,
					},
					{
						data: this.getDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.bp),
							this.EDataType.NIBP_Lower
						),
						label: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.NIBP })} ${this.props.intl.formatMessage({
							id: ResourceAssistance.Message.diastolic,
						})}`,
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.dark_blue,
						backgroundColor: "white",
						fill: false,
						borderWidth: 1,
						pointRadius: 5,
						spanGaps: true,
					},
					{
						data: this.getDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.aline),
							this.EDataType.ALine_Upper
						),
						label: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.ALine })} ${this.props.intl.formatMessage({
							id: ResourceAssistance.Message.systolic,
						})}`,
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.warning,
						backgroundColor: "white",
						fill: false,
						borderWidth: 1,
						pointRadius: 5,
						spanGaps: true,
					},
					{
						data: this.getDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.vitalSigns.filter((each) => each.aline),
							this.EDataType.ALine_Lower
						),
						label: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.ALine })} ${this.props.intl.formatMessage({
							id: ResourceAssistance.Message.diastolic,
						})}`,
						yAxisID: "y",
						borderColor: ResourceAssistance.CSS.Color.dark_warning,
						backgroundColor: "white",
						fill: false,
						borderWidth: 1,
						pointRadius: 5,
						spanGaps: true,
					},
					{
						label: this.props.intl.formatMessage({ id: ResourceAssistance.Message.anesthesia }),
						type: "scatter",
						data: this.getStartEndDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime,
							this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime
						),
						yAxisID: "y",
						fill: false,
						borderWidth: 1,
						pointStyle: "crossRot",
						pointRadius: 15,
						pointBorderColor: function (context) {
							if (context.dataset.pointStyle === "crossRot") {
								if (context.dataset.data.findIndex((each) => each === context.raw) === context.dataIndex) {
									return ResourceAssistance.CSS.Color.dark_green
								} else {
									return ResourceAssistance.CSS.Color.dark_red
								}
							} else {
								return undefined
							}
						},
					},
					{
						label: this.props.intl.formatMessage({ id: ResourceAssistance.Message.operation }),
						type: "scatter",
						data: this.getStartEndDatas(
							labels,
							this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime,
							this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime
						),
						yAxisID: "y",
						fill: false,
						borderWidth: 1,
						pointStyle: "triangle",
						pointRadius: 15,
						pointBorderColor: function (context) {
							if (context.dataset.pointStyle === "triangle") {
								if (context.dataset.data.findIndex((each) => each === context.raw) === context.dataIndex) {
									return ResourceAssistance.CSS.Color.dark_green
								} else {
									return ResourceAssistance.CSS.Color.dark_red
								}
							} else {
								return undefined
							}
						},
					},
				],
			},
			options: {
				stacked: false,
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					duration: 0,
				},
				interaction: {
					mode: "index",
					intersect: true,
				},
				plugins: {
					legend: {
						labels: {
							usePointStyle: true,
						},
					},
				},
				scales: {
					y: {
						type: "linear",
						display: true,
						position: "left",
						suggestedMin: 50,
						suggestedMax: 150,
						beginAtZero: false,
						ticks: {
							precision: 0,
							stepSize: 5,
						},
					},
				},
			},
		})
	}

	render() {
		return (
			<Row>
				<Col>
					<canvas ref={this.chartRef} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneRecordChart))
