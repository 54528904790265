import React from "react"
import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"
import { ResourceAssistance } from "~/i18n"

export const ProtectedRoute = ({ component: Component, ...rest }) => {
	const loginState = useSelector((state) => state.login)
	return (
		<Route
			{...rest}
			render={(props) => {
				if (loginState.isLogin) {
					return <Component {...props} />
				} else {
					return (
						<Redirect
							to={{
								pathname: ResourceAssistance.Path.root,
								// state: {
								// 	from: props.location,
								// },
							}}
						/>
					)
				}
			}}
		/>
	)
}
