const CSS = {
	SpacingUtility: {
		mr: (num) => {
			return `mr-${num}`
		},
	},

	Color: {
		// #198754
		// dark_green: "#198754",
		black: "#00008B",
		blue: "#4bb8ef",
		dark_blue: "#0d47a1",
		dark_green: "#52c41a",
		dark_info: "#00695c",
		dark_red: "#A52A2A",
		dark_warning: "#FF8800",
		green: "#adff2f",
		info: "#0dcaf0",
		orange: "#e65722",
		primary: "#0d6efd",
		red: "#F08080",
		warning: "#ffc107",
	},

	TextCenter: "text-center",
	JustifyContentMdCenter: "justify-content-md-center",
	center: "center",
	marginTop: "margin-top",
	labelInfoRow: "label-info-row",
	backgroundWarning: "background-warning",
	backgourndLightSkyBlue: "background-light-blue",
	backgroundInfo: "background-info",
	fullSize: "full-size",
	flexSelfCenter: "flex-self-center",
	disabled: "disabled",
	vertical: "vertical",
	fullFlex: "full-flex",
	textCenter: "text-center",
	noGutters: "g-0",

	ScrollableTable: {
		highlightSelected: "highlight-selected",
		success: "success",
	},

	Modal: {
		fullSize: "modal-full-size",
		fullHeight: "modal-full-height",
		fullWidth: "modal-full-width",
	},
}

export default CSS
