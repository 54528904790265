import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setFrontInventoryWaste } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FrontInventoryTabWaste extends React.Component {
	componentDidMount() {
		if (this.props.frontInventory.selectedInventory.inventory) {
			this.props.setFrontInventoryWaste(this.props.frontInventory.selectedInventory.inventory.wasteItems)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.frontInventory.selectedInventory, this.props.frontInventory.selectedInventory)) {
			if (this.props.frontInventory.selectedInventory.inventory) {
				this.props.setFrontInventoryWaste(this.props.frontInventory.selectedInventory.inventory.wasteItems)
			} else {
				this.props.setFrontInventoryWaste([])
			}
		}
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable enableHighlight striped hover data={this.props.frontInventory.wasteTable} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setFrontInventoryWaste,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FrontInventoryTabWaste))
