import { TreeSelect } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected } from "~/redux/action"
import { SET_DEPOSIT_MODAL_LOCATION_SELECTED_BRANCH, SET_DEPOSIT_MODAL_LOCATION_SELECTED_ORG } from "~/redux/type"
import { TreeSelectUtils } from "~/utils/component/TreeSelectUtils"

class DepositModalLocationDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onLocation = this.onLocation.bind(this)
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							treeLine={{ showLeafIcon: false }}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.branch)}
							treeData={TreeSelectUtils.displayOrgBranch(this.props.common.orgs)}
							onChange={this.onLocation}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onLocation(v) {
		if (v === undefined) {
			this.props.setSelected(SET_DEPOSIT_MODAL_LOCATION_SELECTED_ORG, -1, null)
			this.props.setSelected(SET_DEPOSIT_MODAL_LOCATION_SELECTED_BRANCH, -1, null)
			return
		}
		let keys = v.split("-")
		this.props.setSelected(SET_DEPOSIT_MODAL_LOCATION_SELECTED_ORG, keys[0], this.props.common.orgs[keys[0]])
		this.props.setSelected(SET_DEPOSIT_MODAL_LOCATION_SELECTED_BRANCH, keys[1], this.props.common.orgs[keys[0]].locations[keys[1]])
	}
}

const mapStateToProps = (state) => ({
	common: state.common,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DepositModalLocationDetails)
