import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests } from "~/redux/action"
import { SET_OR_VITAL_SIGN_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OperatingRoomModalVitalSignDetails from "./OperatingRoomModalVitalSignDetails"

class OperatingRoomModalVitalSign extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_VITAL_SIGN_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			_.isEmpty(Utils.trim(this.props.operatingRoom.modalVitalSign.vsTemp)) ||
			_.isEmpty(Utils.trim(this.props.operatingRoom.modalVitalSign.vsPR)) ||
			_.isEmpty(Utils.trim(this.props.operatingRoom.modalVitalSign.vsRR)) ||
			_.isEmpty(Utils.trim(this.props.operatingRoom.modalVitalSign.vsBP)) ||
			_.isEmpty(Utils.trim(this.props.operatingRoom.modalVitalSign.vsOxygenSat))
		) {
			return true
		}
		let target = Object.assign({}, this.props.operatingRoom.vitalSign, {
			dateTime: this.props.operatingRoom.modalVitalSign.vsDateTime,
			temperature: Number(Utils.trim(this.props.operatingRoom.modalVitalSign.vsTemp)),
			pr: Number(Utils.trim(this.props.operatingRoom.modalVitalSign.vsPR)),
			rr: Number(Utils.trim(this.props.operatingRoom.modalVitalSign.vsRR)),
			bp: Utils.trim(this.props.operatingRoom.modalVitalSign.vsBP),
			oxygenSat: Number(Utils.trim(this.props.operatingRoom.modalVitalSign.vsOxygenSat)),
		})
		return _.isEqual(this.props.operatingRoom.vitalSign, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveVitalSigns,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				clinicalRecordId: this.props.operatingRoom.selectedRequest.request.clinicalRecord.id,
				userId: this.props.login.user.id,
				vitalSigns: [
					{
						id: this.props.operatingRoom.vitalSign ? this.props.operatingRoom.vitalSign.id : 0,
						dateTime: this.props.operatingRoom.modalVitalSign.vsDateTime,
						temperature: Utils.trim(this.props.operatingRoom.modalVitalSign.vsTemp),
						pr: Utils.trim(this.props.operatingRoom.modalVitalSign.vsPR),
						rr: Utils.trim(this.props.operatingRoom.modalVitalSign.vsRR),
						bp: Utils.trim(this.props.operatingRoom.modalVitalSign.vsBP),
						oxygen: Utils.trim(this.props.operatingRoom.modalVitalSign.vsOxygenSat),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				size={ResourceAssistance.Modal.size.xl}
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.vitalSign)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<OperatingRoomModalVitalSignDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomVitalSignDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalVitalSign))
