import _ from "lodash"
import React from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import { ImPrinter } from "react-icons/im"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgDoctor, SvgNurse } from "~/icons"
import { onModalDisplayAction, setSelected } from "~/redux/action"
import { SET_OPD_DOCTOR_DISPLAY, SET_OPD_NURSE_DISPLAY, SET_OPD_SELECTED_NURSE_NOTE, SET_OPD_SELECTED_NURSE_ORDER } from "~/redux/type"
import OPDPrintableDataFactory from "~/utils/factory/print/HIS/OPDPrintableDataFactory"
import { Utils } from "~/utils/Utils"
import moment from "moment"

class OutpatientRightAction extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightAction",
		}

		this.onDoctorOrder = this.onDoctorOrder.bind(this)
		this.onNurseRecord = this.onNurseRecord.bind(this)
		this.onPrintDoctorPrescription = this.onPrintDoctorPrescription.bind(this)
		this.onPrintNurseOrder = this.onPrintNurseOrder.bind(this)
		this.onPrintMedicalCertificate = this.onPrintMedicalCertificate.bind(this)
		this.onPrintNurseNote = this.onPrintNurseNote.bind(this)
		this.onPrintVitalSign = this.onPrintVitalSign.bind(this)
		this.onPrintMedicalCertificateEng = this.onPrintMedicalCertificateEng.bind(this)
	}

	onDoctorOrder(event) {
		this.props.onModalDisplayAction(SET_OPD_DOCTOR_DISPLAY, true)
	}

	onNurseRecord(event) {
		this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, true)
		this.props.setSelected(SET_OPD_SELECTED_NURSE_NOTE, -1, null)
		this.props.setSelected(SET_OPD_SELECTED_NURSE_ORDER, -1, null)
	}

	onPrintDoctorPrescription() {
		let data = OPDPrintableDataFactory.generateDoctorPrescriptionReportData(
			this.props.login.user,
			this.props.opd.selectedPatient.patient,
			this.props.opd.modalLocation.selectedOrg.org,
			this.props.opd.modalLocation.selectedBranch.branch,
			this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.doctorOrders.filter(
				(order) => order.code && order.startDateTime >= moment().startOf("day").milliseconds(0).valueOf()
			),
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.opd.doctorPrescription, "_blank")
		myWindow.data = data
	}

	onPrintNurseOrder() {
		let data = OPDPrintableDataFactory.generateNurseOrderReportData(
			this.props.opd.selectedPatient.patient,
			this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseOrders
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.opd.nurseOrder, "_blank")
		myWindow.data = data
	}

	onPrintNurseNote() {
		let data = OPDPrintableDataFactory.generateNurseNoteReportData(
			this.props.opd.selectedPatient.patient,
			this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseNotes
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.opd.nurseNote, "_blank")
		myWindow.data = data
	}

	onPrintVitalSign() {
		let data = OPDPrintableDataFactory.generateVitalSignReportData(
			this.props.opd.selectedPatient.patient,
			this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.vitalSigns
		)
		let myWindow = window.open(ResourceAssistance.Path.Report.opd.vitalSign, "_blank")
		myWindow.data = data
	}

	onPrintMedicalCertificate() {
		let data = OPDPrintableDataFactory.generateMedicalCertificateReportData(
			this.props.opd.selectedPatient.patient,
			this.props.opd.selectedCertificate.certificate,
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.opd.medicalCertificate, "_blank")
		myWindow.data = data
	}

	onPrintMedicalCertificateEng() {
		let patient = Object.assign({}, this.props.opd.selectedPatient.patient, {
			firstName: this.props.opd.selectedPatient.patient.firstNameEng,
			lastName: this.props.opd.selectedPatient.patient.lastNameEng,
		})
		let certificate = Object.assign({}, this.props.opd.selectedCertificate.certificate, {
			doctorName: this.props.opd.selectedCertificate.certificate.doctorNameEng,
		})
		let location = {
			name: certificate.location.parent.parent ? certificate.location.parent.parent.displayNameEng : certificate.location.parent.displayNameEng,
			branchName: certificate.location.parent.parent ? certificate.location.parent.displayNameEng : certificate.location.displayNameEng,
			address: certificate.location.parent.parent ? certificate.location.parent.address.displayNameEng : certificate.location.address.displayNameEng,
		}
		let data = OPDPrintableDataFactory.generateMedicalCertificateReportData(
			patient, 
			certificate, 
			location,
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs)
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.opd.medicalCertificate, "_blank")
		myWindow.data = data
	}

	render() {
		return (
			<Row>
				<Col />
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Doctor, this.props.login.user.roles) && (
					<Col md={"auto"}>
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							disabled={
								this.props.opd.selectedMedicalRecord.index === -1 ||
								this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
								this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime > 0
							}
							onClick={this.onDoctorOrder}
						>
							<SvgDoctor width={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Hospitel.doctor)}
						</Button>
					</Col>
				)}

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) && (
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.primary}
							disabled={
								this.props.opd.selectedMedicalRecord.index === -1 ||
								this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
								this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime > 0
							}
							onClick={this.onNurseRecord}
						>
							<SvgNurse width={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Hospitel.nurse)}
						</Button>
					</Col>
				)}

				<Col md={"auto"}>
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<Dropdown.Toggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={this.props.opd.selectedMedicalRecord.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</Dropdown.Toggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem
								disabled={
									this.props.opd.selectedMedicalRecord.index === -1 ||
									this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
									_.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.doctorOrders)
								}
								onClick={this.onPrintDoctorPrescription}
							>
								{translate(ResourceAssistance.Message.doctorPrescription)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={
									this.props.opd.selectedMedicalRecord.index === -1 ||
									this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
									_.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseOrders)
								}
								onClick={this.onPrintNurseOrder}
							>
								{translate(ResourceAssistance.Message.nurseOrder)}
							</DropdownItem>
							<DropdownItem
								disabled={
									this.props.opd.selectedMedicalRecord.index === -1 ||
									this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
									_.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.nurseNotes)
								}
								onClick={this.onPrintNurseNote}
							>
								{translate(ResourceAssistance.Hospitel.nurseProgressNote)}
							</DropdownItem>
							<DropdownItem
								disabled={
									this.props.opd.selectedMedicalRecord.index === -1 ||
									this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
									_.isEmpty(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.vitalSigns)
								}
								onClick={this.onPrintVitalSign}
							>
								{translate(ResourceAssistance.Hospitel.vitalSign)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={
									this.props.opd.selectedMedicalRecord.index === -1 ||
									this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
									this.props.opd.selectedCertificate.index === -1
								}
								onClick={this.onPrintMedicalCertificate}
							>
								{translate(ResourceAssistance.Hospitel.medicalCertificate)}
							</DropdownItem>
							<DropdownItem
								disabled={
									this.props.opd.selectedMedicalRecord.index === -1 ||
									this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime === 0 ||
									this.props.opd.selectedCertificate.index === -1
								}
								onClick={this.onPrintMedicalCertificateEng}
							>
								{translate(ResourceAssistance.Message.medicalCertificateEng)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>

				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	opd: state.hospital.opd,
	common: state.common
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightAction))
