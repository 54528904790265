import { createFromIconfontCN } from "@ant-design/icons"

export { default as SvgCashier } from "./SvgCashier"
export { default as SvgDeposit } from "./SvgDeposit"
export { default as SvgDoctor } from "./SvgDoctor"
export { default as SvgDoctorNote } from "./SvgDoctorNote"
export { default as SvgDoctorServiceLightBlue } from "./SvgDoctorServiceLightBlue"
export { default as SvgFace } from "./SvgFace"
export { default as SvgHumanBody } from "./SvgHumanBody"
export { default as SvgInpatient } from "./SvgInpatient"
export { default as SvgLab } from "./SvgLab"
export { default as SvgMedical } from "./SvgMedical"
export { default as SvgMedicalEquipmentBox } from "./SvgMedicalEquipmentBox"
export { default as SvgMedicalRecord } from "./SvgMedicalRecord"
export { default as SvgMedication } from "./SvgMedication"
export { default as SvgNurse } from "./SvgNurse"
export { default as SvgOutpatient } from "./SvgOutpatient"
export { default as SvgPainScale } from "./SvgPainScale"
export { default as SvgPatient } from "./SvgPatient"
export { default as SvgPatientOnHand } from "./SvgPatientOnHand"
export { default as SvgStethoscope } from "./SvgStethoscope"
export { default as SvgVitalSign } from "./SvgVitalSign"

export const IconFont = createFromIconfontCN({
	scriptUrl: "//at.alicdn.com/t/c/font_3437917_cyxycn4wiyu.js",
	// scriptUrl: "iconfont.js",
})
