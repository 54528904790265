import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import AdmIModalNILeft from "./AdmIModalNILeft"
import AdmIModalNIRight from "./AdmIModalNIRight"

class AdmIModalNIDetails extends React.Component {
	componentDidUpdate(prevProps, prevState) {}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0 full-size"}>
					<Col style={{ height: "1030px" }}>
						<AdmIModalNILeft {...this.props} />
					</Col>
					<Col>
						<AdmIModalNIRight />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmIModalNIDetails)
