import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmSSuppliers, setAdmTTaxes, setAdmUUnits, setLoadingAction } from "~/redux/action"
import { setAdmFForms } from "~/redux/action"
import {
	SET_ADMIN_FORM_FORMS_LOADING,
	SET_ADMIN_ITEM_ITEMS_LOADING,
	SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING,
	SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING,
	SET_ADMIN_TAX_TAXS_LOADING,
	SET_ADMIN_UNIT_UNITS_LOADING,
} from "~/redux/type"
import AdmForm from "./form/AdmForm"
import AdmItem from "./item/AdmItem"
import "./itemConfig.css"
import AdmItemSource from "./item_source/AdmItemSource"
import AdmSupplier from "./supplier/AdmSupplier"
import AdmTax from "./tax/AdmTax"
import AdmUnit from "./unit/AdmUnit"

export const EItemConfig = {
	ITEM_SUPPLIER: "ITEM_SUPPLIER",
	ITEM: "ITEM",
	SUPPLIER: "SUPPLIER",
	UNIT: "UNIT",
	FORM: "FORM",
	TAX: "TAX",
}

class ItemConfig extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			componentName: "ItemConfig",
			tab: EItemConfig.ITEM_SUPPLIER,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadItems = this.loadItems.bind(this)
		this.loadItemSuppliers = this.loadItemSuppliers.bind(this)
		this.loadTypes = this.loadTypes.bind(this)
		this.loadForms = this.loadForms.bind(this)
		this.loadUnits = this.loadUnits.bind(this)
		this.loadSuppliers = this.loadSuppliers.bind(this)
		this.loadTaxs = this.loadTaxs.bind(this)
		this.loadHealthCarePlan = this.loadHealthCarePlan.bind(this)
		this.loadChrgItem = this.loadChrgItem.bind(this)
		this.loadAdpType = this.loadAdpType.bind(this)
	}

	async loadOption(url, param = {}, func = () => {}, loadingType = "") {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: param,
		}
		let callback = (res) => {
			func(res.data)
		}
		let errorHandler = (error) => {
			if (loadingType) {
				this.props.setLoadingAction(loadingType, false)
			}
		}
		let reqInterceptor = () => {
			if (loadingType) {
				this.props.setLoadingAction(loadingType, true)
			}
		}
		let resInterceptor = () => {
			if (loadingType) {
				this.props.setLoadingAction(loadingType, false)
			}
		}
		await axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadTypes(func = () => {}) {
		this.loadOption(ResourceAssistance.Url.admin.type.item.loadTypes, {}, (data) => {
			func(data.uts)
		})
	}

	loadItems(func = () => {}, isExcludeInActive = false) {
		this.loadOption(
			ResourceAssistance.Url.admin.itemSource.loadItems,
			{ excludeInActive: isExcludeInActive },
			(data) => {
				func(data.items)
			},
			SET_ADMIN_ITEM_SOURCE_ITEMS_LOADING
		)
	}

	loadItemSuppliers(func = () => {}) {
		this.loadOption(
			ResourceAssistance.Url.admin.item.loadItemSuppliers,
			{},
			(data) => {
				func(data.itemSuppliers)
			},
			SET_ADMIN_ITEM_ITEMS_LOADING
		)
	}

	loadSuppliers(func = () => {}) {
		this.loadOption(
			ResourceAssistance.Url.admin.supplier.loadSuppliers,
			{},
			(data) => {
				func(data.suppliers)
			},
			SET_ADMIN_SUPPLIER_SUPPLIERS_LOADING
		)
	}

	loadUnits(func = () => {}) {
		this.loadOption(
			ResourceAssistance.Url.admin.unit.loadUnits,
			{},
			(data) => {
				func(data.units)
			},
			SET_ADMIN_UNIT_UNITS_LOADING
		)
	}

	loadForms(func = () => {}) {
		this.loadOption(
			ResourceAssistance.Url.admin.form.loadForms,
			{},
			(data) => {
				func(data.forms)
			},
			SET_ADMIN_FORM_FORMS_LOADING
		)
	}

	loadTaxs(func = () => {}) {
		this.loadOption(
			ResourceAssistance.Url.admin.tax.loadTaxes,
			{},
			(data) => {
				func(data.taxes)
			},
			SET_ADMIN_TAX_TAXS_LOADING
		)
	}

	loadHealthCarePlan(func = () => {}) {
		this.loadOption(ResourceAssistance.Url.admin.type.healthCarePlan.loadTypes, {}, (data) => {
			func(data.uts)
		})
	}

	async loadChrgItem(func = () => {}) {
		await this.loadOption(ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getChrgItem, { excludeInActive: true }, (data) => {
			func(data.wfep)
		})
	}

	async loadAdpType(func = () => {}) {
		await this.loadOption(ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getAdpType, { excludeInActive: true }, (data) => {
			func(data.wfep)
		})
	}

	onTabClick(event) {
		this.setState({ tab: event })

		switch (event) {
			case EItemConfig.SUPPLIER:
				this.loadSuppliers((suppliers) => {
					this.props.setAdmSSuppliers(suppliers)
				})
				break

			case EItemConfig.UNIT:
				this.loadUnits((units) => {
					this.props.setAdmUUnits(units)
				})
				break

			case EItemConfig.FORM:
				this.loadForms((forms) => {
					this.props.setAdmFForms(forms)
				})
				break

			case EItemConfig.TAX:
				this.loadTaxs((taxes) => {
					this.props.setAdmTTaxes(taxes)
				})
				break

			default:
				break
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<div className={` ${ResourceAssistance.CSS.fullFlex}`}>
							<Tabs justify variant="tabs" activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
								<Tab eventKey={EItemConfig.ITEM_SUPPLIER} title={translate(ResourceAssistance.Message.itemSupplier)} name={EItemConfig.ITEM_SUPPLIER}>
									{this.state.tab === EItemConfig.ITEM_SUPPLIER && (
										<AdmItem
											loadItemSuppliers={this.loadItemSuppliers}
											loadTypes={this.loadTypes}
											loadItems={this.loadItems}
											loadForms={this.loadForms}
											loadUnits={this.loadUnits}
											loadSuppliers={this.loadSuppliers}
											loadTaxes={this.loadTaxs}
											loadHealthCarePlan={this.loadHealthCarePlan}
											loadChrgItem={this.loadChrgItem}
											loadAdpType={this.loadAdpType}
										/>
									)}
								</Tab>
								<Tab eventKey={EItemConfig.ITEM} title={translate(ResourceAssistance.Message.item)} name={EItemConfig.ITEM}>
									{this.state.tab === EItemConfig.ITEM && <AdmItemSource loadItems={this.loadItems} loadTypes={this.loadTypes} />}
								</Tab>

								<Tab eventKey={EItemConfig.SUPPLIER} title={translate(ResourceAssistance.Message.supplier)} name={EItemConfig.SUPPLIER}>
									{this.state.tab === EItemConfig.SUPPLIER && <AdmSupplier />}
								</Tab>
								<Tab eventKey={EItemConfig.UNIT} title={translate(ResourceAssistance.Message.unit)} name={EItemConfig.UNIT}>
									{this.state.tab === EItemConfig.UNIT && <AdmUnit />}
								</Tab>
								<Tab eventKey={EItemConfig.FORM} title={translate(ResourceAssistance.Message.form)} name={EItemConfig.FORM}>
									{this.state.tab === EItemConfig.FORM && <AdmForm />}
								</Tab>
								<Tab eventKey={EItemConfig.TAX} title={translate(ResourceAssistance.Message.taxRate)} name={EItemConfig.TAX}>
									{this.state.tab === EItemConfig.TAX && <AdmTax />}
								</Tab>
							</Tabs>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setAdmSSuppliers,
			setAdmUUnits,
			setAdmFForms,
			setAdmTTaxes,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ItemConfig)
