import React, { Component } from "react"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import ConfigEclaimUcItems from "./ConfigEclaimUcItems"
import { injectIntl } from "react-intl"
import { bindActionCreators } from "redux"
import { setDisplay, setEclaimUcTable, setLoadingAction, setValue } from "~/redux/action"
import { connect } from "react-redux"
import {
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE,
	SET_ADM_WP_ECLAIMUC_GETURL,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME,
	SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL,
	SET_ADM_WP_ECLAIMUC_MODAL_TITLE,
} from "~/redux/type"
import { axios } from "~/axios"

export class ConfigEclaimUc extends Component {
	configEclaimUc = {
		exportTypes: "EXPORT_TYPE",
		files: "FILE",
		marriage: "MARRIAGE",
		career: "CAREER",
		nation: "NATION",
		idType: "IDTYPE",
		uuc: "UUC",
		operationType: "OPERATION_TYPE",
		chrgItem: "CHRG_ITEM",
		ucae: "UCAE",
		adpType: "ADP_TYPE",
		labTest: "LAB_TEST",
	}

	constructor(props) {
		super(props)

		this.state = {
			tab: "",
		}

		this.onTabClick = this.onTabClick.bind(this)
	}

	componentDidMount() {
		this.setState({ tab: this.configEclaimUc.exportTypes })
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.tab !== this.state.tab)
			switch (this.state.tab) {
				case this.configEclaimUc.exportTypes:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getExportType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.exportTypes)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveExportType)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getExportType,
						["code", "name", "description"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name, ResourceAssistance.Message.description]
					)
					break
				case this.configEclaimUc.files:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getFiles)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.files)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveFiles)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getFiles,
						["code", "variablesFormat", "description", "defaultSelected"],
						[
							ResourceAssistance.Message.fileName,
							ResourceAssistance.Message.variablesFormat,
							ResourceAssistance.Message.description,
							ResourceAssistance.Message.default,
						]
					)
					break
				case this.configEclaimUc.marriage:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getMarriage)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.marriage)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveMarriage)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getMarriage,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.career:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getCareer)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.career)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveCareer)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getCareer,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.nation:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getNation)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.nation)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveNation)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getNation,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.idType:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getIdType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.idType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveIdType)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getIdType,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.uuc:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getUuc)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.uuc)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveUuc)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getUuc,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.operationType:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getOperationType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.operationType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveOperationtype)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getOperationType,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.chrgItem:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getChrgItem)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.chrgItem)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveChrgItem)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getChrgItem,
						["code", "name", "description"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name, ResourceAssistance.Message.description]
					)
					break
				case this.configEclaimUc.ucae:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getUcae)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.ucae)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveUcae)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getUcae,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.adpType:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getAdpType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.adpType)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveAdpType)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getAdpType,
						["code", "name"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name]
					)
					break
				case this.configEclaimUc.labTest:
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, true)
					this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION, true)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getLabTest)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_TITLE, ResourceAssistance.Message.labTest)
					this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, ResourceAssistance.Url.admin.workflowProperty.eclaimUc.saveLabTest)
					this.loadEclaimUcTable(
						ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getLabTest,
						["code", "name", "description"],
						[ResourceAssistance.Message.code, ResourceAssistance.Message.name, ResourceAssistance.Message.description]
					)
					break
				default:
					break
			}
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	loadEclaimUcTable(url, dataKeys, header) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setEclaimUcTable(SET_ADM_WP_ECLAIMUC_ITEMTABLE, dataKeys, header, res.data.wfep)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setEclaimUcTable(SET_ADM_WP_ECLAIMUC_ITEMTABLE, dataKeys, header, [])
			this.props.setLoadingAction(SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false} transition={false}>
						<Tab eventKey={this.configEclaimUc.exportTypes} title={translate(ResourceAssistance.Message.exportTypes)} name={this.configEclaimUc.exportTypes}>
							{this.state.tab === this.configEclaimUc.exportTypes && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.files} title={translate(ResourceAssistance.Message.files)} name={this.configEclaimUc.eclaimFiles}>
							{this.state.tab === this.configEclaimUc.files && <ConfigEclaimUcItems searchCodePlaceholders={ResourceAssistance.Message.fileName} />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.marriage} title={translate(ResourceAssistance.Message.marriage)} name={this.configEclaimUc.marriage}>
							{this.state.tab === this.configEclaimUc.marriage && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.career} title={translate(ResourceAssistance.Message.career)} name={this.configEclaimUc.career}>
							{this.state.tab === this.configEclaimUc.career && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.nation} title={translate(ResourceAssistance.Message.nation)} name={this.configEclaimUc.nation}>
							{this.state.tab === this.configEclaimUc.nation && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.idType} title={translate(ResourceAssistance.Message.idType)} name={this.configEclaimUc.idType}>
							{this.state.tab === this.configEclaimUc.idType && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.uuc} title={translate(ResourceAssistance.Message.uuc)} name={this.configEclaimUc.uuc}>
							{this.state.tab === this.configEclaimUc.uuc && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.operationType} title={translate(ResourceAssistance.Message.operationType)} name={this.configEclaimUc.operationType}>
							{this.state.tab === this.configEclaimUc.operationType && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.chrgItem} title={translate(ResourceAssistance.Message.chrgItem)} name={this.configEclaimUc.chrgItem}>
							{this.state.tab === this.configEclaimUc.chrgItem && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.ucae} title={translate(ResourceAssistance.Message.ucae)} name={this.configEclaimUc.ucae}>
							{this.state.tab === this.configEclaimUc.ucae && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.adpType} title={translate(ResourceAssistance.Message.adpType)} name={this.configEclaimUc.adpType}>
							{this.state.tab === this.configEclaimUc.adpType && <ConfigEclaimUcItems />}
						</Tab>
						<Tab eventKey={this.configEclaimUc.labTest} title={translate(ResourceAssistance.Message.labTest)} name={this.configEclaimUc.labTest}>
							{this.state.tab === this.configEclaimUc.labTest && <ConfigEclaimUcItems />}
						</Tab>
					</Tabs>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	configEclaimUc: state.admin.configEclaim.eclaimUC,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setDisplay,
			setLoadingAction,
			setEclaimUcTable,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigEclaimUc))
