import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setPVModalNewPurchases, setSelected } from "~/redux/action"
import { SET_PV_MODAL_NEW_SELECTED_PURCHASE, SET_PV_PURCHASE_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PVModalNewPurchase extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.PV.selectedPV.PV) {
			this.props.setPVModalNewPurchases(this.props.PV.selectedPV.PV.purchases)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PV.modalNew.purchaseTable.filtered, this.props.PV.modalNew.purchaseTable.filtered)) {
			this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PURCHASE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setPVModalNewPurchases([])
		this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PURCHASE, -1, null)
	}

	onNew() {
		this.props.onModalDisplayAction(SET_PV_PURCHASE_DISPLAY, true)
	}

	onSelectRow(plan, index) {
		this.props.setSelected(SET_PV_MODAL_NEW_SELECTED_PURCHASE, index, this.props.PV.modalNew.purchaseTable.filtered[index])
	}

	onDelete(e, row, rIdx, cIdx) {
		let purchases = Array.from(this.props.PV.modalNew.purchaseTable.original.filter((each, idx) => idx !== rIdx))
		this.props.setPVModalNewPurchases(purchases)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
						btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })]}
						btnIcons={[<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onDelete]}
						data={this.props.PV.modalNew.purchaseTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.PV.modalNew.selectedPurchase.index === -1}
						highlightedRow={this.props.PV.modalNew.selectedPurchase.index}
						extra={
							<Button
								style={{ fontSize: "medium" }}
								variant={ResourceAssistance.Button.variant.link}
								size={ResourceAssistance.Button.size.sm}
								onClick={this.onNew}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			onModalDisplayAction,
			setPVModalNewPurchases,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PVModalNewPurchase))
