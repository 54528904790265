import { Radio } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OR_MODAL_POST_ANE_PADSS_AMBULATION,
	SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE,
	SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING,
	SET_OR_MODAL_POST_ANE_PADSS_PAIN,
	SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING,
} from "~/redux/type"

export const RBP_Pluse = {
	"20%OfPreoperation": 2,
	"20%To40%OfPreoperation": 1,
	"40%OfPreoperation": 0,
}
export const RAmbulation = {
	SteadyGaitNoDizziness: 2,
	AmbulateWithAssistance: 1,
	UnableToAmbulate: 0,
}
export const RNauseaVomitting = {
	MinimalTreatedWithOralMedication: 2,
	ModerateTreatedWithIVMedication: 1,
	ContinuteAfterRepeatedTreatment: 0,
}
export const RPain = {
	ControlledWithOralAnalgesicsAndAcceptableToPatient: 2,
	PainNotAcceptableToPatient: 1,
}
export const RSurgicalBleeding = {
	MinimalBleeding: 2,
	ModerateBleeding: 1,
	SevereBleeding: 0,
}

class OperatingRoomModalPostAnePADSSDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onBPAndPulse = this.onBPAndPulse.bind(this)
		this.onAmbulation = this.onAmbulation.bind(this)
		this.onNauseaVomitting = this.onNauseaVomitting.bind(this)
		this.onPain = this.onPain.bind(this)
		this.onSurgicalBleeding = this.onSurgicalBleeding.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedPostAneRecord.record) {
			let values = this.props.operatingRoom.selectedPostAneRecord.record.padss.split(",").map(Number)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE, values[0] !== undefined ? values[0] : RBP_Pluse["20%OfPreoperation"])
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_AMBULATION, values[1] !== undefined ? values[1] : RAmbulation.SteadyGaitNoDizziness)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING, values[2] !== undefined ? values[2] : RNauseaVomitting.MinimalTreatedWithOralMedication)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_PAIN, values[3] !== undefined ? values[3] : RPain.ControlledWithOralAnalgesicsAndAcceptableToPatient)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING, values[4] !== undefined ? values[4] : RSurgicalBleeding.MinimalBleeding)
		} else {
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE, RBP_Pluse["20%OfPreoperation"])
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_AMBULATION, RAmbulation.SteadyGaitNoDizziness)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING, RNauseaVomitting.MinimalTreatedWithOralMedication)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_PAIN, RPain.ControlledWithOralAnalgesicsAndAcceptableToPatient)
			this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING, RSurgicalBleeding.MinimalBleeding)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE, RBP_Pluse["20%OfPreoperation"])
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_AMBULATION, RAmbulation.SteadyGaitNoDizziness)
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING, RNauseaVomitting.MinimalTreatedWithOralMedication)
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_PAIN, RPain.ControlledWithOralAnalgesicsAndAcceptableToPatient)
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING, RSurgicalBleeding.MinimalBleeding)
	}

	render() {
		return (
			<Container fluid className={"full-flex space-top-each-row-parent"}>
				<Row className="g-1">
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message["BP&Pulse"])}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
							value={this.props.operatingRoom.modalPostAnePADSS.BPAndPulse}
							onChange={this.onBPAndPulse}
						>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBP_Pluse["20%OfPreoperation"]}>
								{translate(ResourceAssistance.Message["20%OfPreoperation"])}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBP_Pluse["20%To40%OfPreoperation"]}>
								{translate(ResourceAssistance.Message["20%To40%OfPreoperation"])}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBP_Pluse["40%OfPreoperation"]}>
								{translate(ResourceAssistance.Message["greaterThan40%OfPreoperation"])}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row className="g-1">
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.ambulation)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
							value={this.props.operatingRoom.modalPostAnePADSS.ambulation}
							onChange={this.onAmbulation}
						>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAmbulation.SteadyGaitNoDizziness}>
								{translate(ResourceAssistance.Message.steadyGaitNoDizziness)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAmbulation.AmbulateWithAssistance}>
								{translate(ResourceAssistance.Message.ambulateWithAssistance)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAmbulation.UnableToAmbulate}>
								{translate(ResourceAssistance.Message.unableToAmbulate)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row className="g-1">
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.nauseaOrVomitting)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
							value={this.props.operatingRoom.modalPostAnePADSS.nauseaVomitting}
							onChange={this.onNauseaVomitting}
						>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RNauseaVomitting.MinimalTreatedWithOralMedication}>
								{translate(ResourceAssistance.Message.minimalTreatedWithOralMedication)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RNauseaVomitting.ModerateTreatedWithIVMedication}>
								{translate(ResourceAssistance.Message.moderateTreatedWithIVMedication)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RNauseaVomitting.ContinuteAfterRepeatedTreatment}>
								{translate(ResourceAssistance.Message.continuteAfterRepeatedTreatment)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row className="g-1">
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.pain)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
							value={this.props.operatingRoom.modalPostAnePADSS.pain}
							onChange={this.onPain}
						>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RPain.ControlledWithOralAnalgesicsAndAcceptableToPatient}>
								{translate(ResourceAssistance.Message.controlledWithOralAnalgesicsAndAcceptableToPatient)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RPain.PainNotAcceptableToPatient}>
								{translate(ResourceAssistance.Message.painNotAcceptableToPatient)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row className="g-1">
					<Col md={3}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.surgicalBleeding)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group
							style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}
							value={this.props.operatingRoom.modalPostAnePADSS.surgicalBleeding}
							onChange={this.onSurgicalBleeding}
						>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RSurgicalBleeding.MinimalBleeding}>
								{translate(ResourceAssistance.Message.minimalBleeding)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RSurgicalBleeding.ModerateBleeding}>
								{translate(ResourceAssistance.Message.moderateBleeding)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RSurgicalBleeding.SevereBleeding}>
								{translate(ResourceAssistance.Message.severeBleeding)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
			</Container>
		)
	}
	onBPAndPulse(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE, e.target.value)
	}
	onAmbulation(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_AMBULATION, e.target.value)
	}
	onNauseaVomitting(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING, e.target.value)
	}
	onPain(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_PAIN, e.target.value)
	}
	onSurgicalBleeding(e) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalPostAnePADSSDetails)
