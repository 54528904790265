import { Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected, setValue, setWarningId, setWarningMsgAction, setPSRequests } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PS_NEW_DISPLAY,
	SET_PS_SEARCH_DISPLAY,
	SET_PS_SELECTED_PAYMENT_SLIP,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class PaymentSlipLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.completePaymentSlipRequest)) {
				this.completePaymentSlipRequest()
			}
		}
	}

	completePaymentSlipRequest() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.completePaymentSlipRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				requests: [
					{
						id: this.props.PS.selectedPaymentSlip.ps.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isCompleteDisabled() {
		return (
			!this.props.PS.selectedPaymentSlip.ps ||
			this.props.PS.selectedPaymentSlip.ps.complete ||
			this.props.PS.selectedPaymentSlip.ps.paymentSlips.some(
				(ps) => ps.invoices.some((invoice) => !invoice.paymentPlan) || ps.paymentPlans.some((paymentPlan) => !paymentPlan.financialOperationApproved)
			)
		)
	}

	onComplete() {
		let selectedPS = this.props.PS.selectedPaymentSlip.ps
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.paymentSlip.completePaymentSlipRequest)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
						{Utils.formatDate(selectedPS.creationDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.id)} span={1}>
						{selectedPS.id}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_PS_SEARCH_DISPLAY, true)
	}

	onNew() {
		this.props.setSelected(SET_PS_SELECTED_PAYMENT_SLIP, -1, null)
		this.props.onModalDisplayAction(SET_PS_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_PS_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.green}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isCompleteDisabled()}
						onClick={this.onComplete}
					>
						<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.complete)}
					</Button>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearch}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={!this.props.PS.selectedPaymentSlip.ps || this.props.PS.selectedPaymentSlip.ps.complete}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setWarningId,
			setWarningMsgAction,
			setValue,
			setLoadingAction,
			setPSRequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipLeftAction))
