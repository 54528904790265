import { Card, Descriptions, message } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import { AiOutlineFileDone } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdDeleteForever } from "react-icons/md"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setPSIncompletePaymentPlans,
	setPSRequests,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PS_GL_DATE_TIME,
	SET_PS_PAYMENT_PLAN_DISPLAY,
	SET_PS_SELECTED_PAYMENT_PLAN,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { WithholdingTaxCertificateUtils } from "~/utils/WithholdingTaxCertificateUtils"
import PaymentSlipPrintDataFactory from "~/utils/factory/print/fiannce/PaymentSlipPrintDataFactory"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"

class PaymentSlipRightPaymentPlanTabInComplete extends React.Component {
	constructor(props) {
		super(props)

		this.isActionClicked = false

		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.selectRow = this.selectRow.bind(this)
		this.onCreator = this.onCreator.bind(this)
		this.onInspector = this.onInspector.bind(this)
		this.onApprover = this.onApprover.bind(this)
		this.onGeneralLedgerDateTime = this.onGeneralLedgerDateTime.bind(this)
		this.onReject = this.onReject.bind(this)
		this.onPrint = this.onPrint.bind(this)
		this.onPrintJV = this.onPrintJV.bind(this)
		this.onPrintWHT = this.onPrintWHT.bind(this)
	}

	componentDidMount() {
		if (this.props.PS.selectedSupplier.supplier) {
			this.props.setPSIncompletePaymentPlans(this.props.PS.selectedSupplier.supplier.paymentPlans)
		} else {
			this.props.setPSIncompletePaymentPlans([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.deletePaymentPlan)) {
					this.deletePaymentPlan()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanCreator)) {
					this.updatePaymentPlan(ResourceAssistance.Url.paymentSlip.updatePaymentPlanCreator)
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanInspector)) {
					this.updatePaymentPlan(ResourceAssistance.Url.paymentSlip.updatePaymentPlanInspector)
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanApprover)) {
					this.updatePaymentPlan(ResourceAssistance.Url.paymentSlip.updatePaymentPlanApprover).then(() => {
						this.saveWithholdingTaxCertificate()
					})
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanReject)) {
					this.updatePaymentPlan(ResourceAssistance.Url.paymentSlip.updatePaymentPlanReject)
				}
			}
		}
		if (!_.isEqual(prevProps.warning.isClose, this.props.warning.isClose)) {
			if (this.props.warning.isClose) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanApprover)) {
					this.props.setValue(SET_PS_GL_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
				}
			}
		}

		if (!_.isEqual(prevProps.PS.selectedSupplier, this.props.PS.selectedSupplier)) {
			if (this.props.PS.selectedSupplier.supplier) {
				this.props.setPSIncompletePaymentPlans(this.props.PS.selectedSupplier.supplier.paymentPlans)
			} else {
				this.props.setPSIncompletePaymentPlans([])
			}
		}
		if (!_.isEqual(prevProps.PS.paymentPlanTable.original, this.props.PS.paymentPlanTable.original)) {
			let index = this.props.PS.selectedPaymentPlan.paymentPlan
				? this.props.PS.paymentPlanTable.filtered.findIndex((each) => each.id === this.props.PS.selectedPaymentPlan.paymentPlan.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PS_SELECTED_PAYMENT_PLAN, index, this.props.PS.paymentPlanTable.filtered[index])
			} else {
				this.props.setSelected(SET_PS_SELECTED_PAYMENT_PLAN, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PS_SELECTED_PAYMENT_PLAN, -1, null)
	}

	saveWithholdingTaxCertificate() {
		let invoices = this.props.PS.selectedSupplier.supplier.invoices.filter(
			(invoice) =>
				invoice.paymentPlan &&
				(invoice.purchaseInvoice || invoice.paymentVoucher) &&
				Utils.BigNumber(invoice.withholdingTaxIssuedDateTime).isGreaterThan(0) &&
				invoice.paymentPlan.id === this.props.PS.selectedPaymentPlan.paymentPlan.id
		)
		let purchases = invoices.reduce((ary, invoice) => {
			return ary.concat(invoice.purchases.filter((purchase) => Utils.BigNumber(purchase.withholdingTax).isGreaterThan(0)))
		}, [])
		let issuedDateTime = Math.max(invoices.map((invoice) => invoice.withholdingTaxIssuedDateTime))
		let certificates = Object.entries(Utils.groupBy(purchases, "withholdingTaxType")).map(([type, typeValues]) => {
			return Object.entries(Utils.groupBy(typeValues, "withholdingTaxSubLevel")).reduce(
				(obj, [key, values]) => {
					let dataAry = values.reduce(
						(dataAry, purchase) => {
							if (!_.isEmpty(purchase.withholdingTaxSubLevelOther) && !dataAry[2].includes(purchase.withholdingTaxSubLevelOther)) {
								dataAry[2] = dataAry[2].concat(ResourceAssistance.PROGRAM_DEFINED.split, purchase.withholdingTaxSubLevelOther)
							}
							let beforeTaxAmount = Utils.BigNumber(purchase.taxedTotalPrice).dividedBy(1 + purchase.tax / 100)
							dataAry[0] = dataAry[0].plus(beforeTaxAmount)
							dataAry[1] = dataAry[1].plus(beforeTaxAmount.times(purchase.withholdingTax / 100))
							return dataAry
						},
						[Utils.BigNumber(0), Utils.BigNumber(0), ""]
					)
					let keys = WithholdingTaxCertificateUtils.convertHttpRequestKeys(key)
					return {
						...obj,
						[keys.dateTime]: issuedDateTime,
						[keys.amount]: dataAry[0].toFixed(2),
						[keys.withholdingTax]: dataAry[1].toFixed(2),
						[keys.other]: dataAry[2],
					}
				},
				{
					type: type,
					issuedDateTime: issuedDateTime,
					withholderName: this.props.PS.location.displayName,
					withholderAddress: this.props.PS.location.address.displayName,
					withholderId: "",
					withholderTaxId: this.props.PS.location.taxNo,
					taxerName: this.props.PS.selectedSupplier.supplier.supplierName,
					taxerAddress: this.props.PS.selectedSupplier.supplier.supplierAddress,
					taxerId: "",
					taxerTaxId: this.props.PS.selectedSupplier.supplier.supplierTaxId,
					withholdAtSource: true,
				}
			)
		})
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.saveWithholdingTaxCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedPaymentPlan.paymentPlan.id,
					},
				],
				withholdingTaxCertificates: certificates,
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			message.open({
				key: ResourceAssistance.Url.paymentSlip.saveWithholdingTaxCertificate,
				type: "error",
				content: this.props.intl.formatMessage({ id: ResourceAssistance.Warning.savingWithholdingTaxCertificateFailed }),
			})
		}
		let reqInterceptor = () => {
			message.open({
				key: ResourceAssistance.Url.paymentSlip.saveWithholdingTaxCertificate,
				type: "loading",
				content: this.props.intl.formatMessage({ id: ResourceAssistance.Warning.savingWithholdingTaxCertificate }),
			})
		}
		let resInterceptor = () => {
			message.open({
				key: ResourceAssistance.Url.paymentSlip.saveWithholdingTaxCertificate,
				type: "success",
				content: this.props.intl.formatMessage({ id: ResourceAssistance.Warning.savingWithholdingTaxCertificateSuccess }),
				duration: 2,
			})
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	updatePaymentPlan(url) {
		let params = {
			method: "POST",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				userId: this.props.login.user.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedPaymentPlan.paymentPlan.id,
					},
				],
				generalLedgers: this.props.PS.generalLedgerGroupTable.body.map((each) => {
					return {
						dateTime: this.props.PS.generalLedgerDateTime,
						documentCode: ResourceAssistance.Enum.paymentPlanDocumentCode,
						documentId: this.props.PS.selectedPaymentPlan.paymentPlan.id,
						code: each[0],
						name: each[1],
						category: each[2],
						transactionDescription: each[3],
						debit: Utils.formatNumberFromStr(each[4]).toFixed(2),
						credit: Utils.formatNumberFromStr(each[5]).toFixed(2),
						workflow: ResourceAssistance.Enum.paymentPlan,
						systemGenerated: false,
					}
				}),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setPSRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		return axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deletePaymentPlan() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentSlip.deletePaymentPlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				paymentSlipRequestId: this.props.PS.selectedPaymentSlip.ps.id,
				orgId: this.props.PS.location.id,
				paymentPlans: [
					{
						id: this.props.PS.selectedPaymentPlan.paymentPlan.id,
					},
				],
			},
		}
		let callback = (res) => {
			let requests = this.props.PS.paymentSlipTable.original.filter((each) => each.id !== this.props.PS.selectedPaymentSlip.ps.id)
			if (res.data.requests.length > 0) {
				requests.push(res.data.requests[0])
			}
			this.props.setPSRequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isDisplayCreator() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.Creator, this.props.login.user.roles)
	}

	isDisplayInspector() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.Inspector, this.props.login.user.roles)
	}

	isDisplayApprover() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.Approver, this.props.login.user.roles)
	}

	isDisplayReject() {
		return (
			Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.Approver, this.props.login.user.roles) ||
			Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentSlip.Inspector, this.props.login.user.roles)
		)
	}

	isDisplayPrint() {
		return true
	}

	displayPaymentPlanWarning(rIdx, warningId, warningMsg, isDisplayDate) {
		this.isActionClicked = true
		let selectedPaymentPlan = this.props.PS.paymentPlanTable.filtered[rIdx]
		this.props.setWarningId(warningId)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(warningMsg)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.id)} span={1}>
						{selectedPaymentPlan.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedPaymentPlan.paymentTermDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
						{selectedPaymentPlan.paymentMethod}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedPaymentPlan.amount).toFixed(2))}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPaymentPlan.remark}
					</Descriptions.Item>
					{isDisplayDate && (
						<Descriptions.Item labelStyle={{ alignItems: "center" }} label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
							<AntDatePicker
								allowClear={false}
								size={"middle"}
								format={"DD-MM-YYYY"}
								defaultValues={[moment(this.props.PS.generalLedgerDateTime)]}
								onChange={this.onGeneralLedgerDateTime}
							/>
						</Descriptions.Item>
					)}
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	selectRow(plan, index) {
		if (this.props.PS.selectedPaymentPlan.paymentPlan && this.props.PS.selectedPaymentPlan.index === index && !this.isActionClicked) {
			this.props.setSelected(SET_PS_SELECTED_PAYMENT_PLAN, -1, null)
		} else {
			this.props.setSelected(SET_PS_SELECTED_PAYMENT_PLAN, index, this.props.PS.paymentPlanTable.filtered[index])
			this.isActionClicked = false
		}
	}

	onEdit(e, row, rIdx, cIdx) {
		this.isActionClicked = true
		this.props.onModalDisplayAction(SET_PS_PAYMENT_PLAN_DISPLAY, true)
	}

	onDelete(e, row, rIdx, cIdx) {
		this.displayPaymentPlanWarning(rIdx, ResourceAssistance.ID.ACCOUNTING.paymentSlip.deletePaymentPlan, ResourceAssistance.Message.delete)
	}

	onCreator(e, row, rIdx, cIdx) {
		this.displayPaymentPlanWarning(rIdx, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanCreator, ResourceAssistance.Message.approve)
	}

	onInspector(e, row, rIdx, cIdx) {
		this.displayPaymentPlanWarning(rIdx, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanInspector, ResourceAssistance.Message.approve)
	}

	onApprover(e, row, rIdx, cIdx) {
		this.displayPaymentPlanWarning(rIdx, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanApprover, ResourceAssistance.Message.approve, true)
	}

	onGeneralLedgerDateTime(value) {
		this.props.setValue(SET_PS_GL_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onReject(e, row, rIdx, cIdx) {
		this.displayPaymentPlanWarning(rIdx, ResourceAssistance.ID.ACCOUNTING.paymentSlip.updatePaymentPlanReject, ResourceAssistance.Message.reject)
	}

	onPrint(e, row, rIdx, cIdx) {
		this.isActionClicked = true
	}

	onPrintJV() {
		let selectedPaymentPlan = this.props.PS.selectedPaymentPlan.paymentPlan
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.paymentJournalVoucher, "_blank")
		myWindow.data = PaymentSlipPrintDataFactory.generatePaymentJournalVoucherData(
			this.props.PS.location,
			this.props.PS.selectedSupplier.supplier,
			selectedPaymentPlan,
			this.props.PS.selectedSupplier.supplier.invoices.filter((invoice) => invoice.paymentPlan && invoice.paymentPlan.id === selectedPaymentPlan.id),
			selectedPaymentPlan.paymentSlipGLs.filter((GL) => Number(GL.workflow) === ResourceAssistance.Enum.paymentPlan)
		)
	}

	onPrintWHT(e, row, rIdx, cIdx) {
		this.props.PS.selectedPaymentPlan.paymentPlan.certificates.forEach((certificate) => {
			let myWindow = window.open(ResourceAssistance.Path.Report.finance.withholdingTaxCertificate, "_blank")
			myWindow.data = PaymentSlipPrintDataFactory.generateWithholdingTaxData(certificate)
		})
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineRed,
							ResourceAssistance.Button.variant.outlineInfo,
						]}
						btnMessages={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.creator }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.inspector }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.approver }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.print }),
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
							this.isDisplayCreator() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayInspector() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayApprover() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayReject() ? <TiArrowBack size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayPrint() ? <ImPrinter size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						btnTypes={[EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DROP_DOWN]}
						dropdownItems={[
							<DropdownItem key={"payment_slip_payment_plan_tab_incomplete_jv"} onClick={this.onPrintJV}>
								{translate(ResourceAssistance.Message.journalVoucher)}
							</DropdownItem>,
							<DropdownItem key={"payment_slip_payment_plan_tab_incomplete_wht"} onClick={this.onPrintWHT}>
								{translate(ResourceAssistance.Message.withholdingTax)}
							</DropdownItem>,
						]}
						onCheckedArray={[this.onEdit, this.onDelete, this.onCreator, this.onInspector, this.onApprover, this.onReject, this.onPrint]}
						data={this.props.PS.paymentPlanTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.PS.selectedPaymentPlan.index === -1}
						highlightedRow={this.props.PS.selectedPaymentPlan.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPSIncompletePaymentPlans,
			onModalDisplayAction,
			setLoadingAction,
			setPSRequests,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentSlipRightPaymentPlanTabInComplete))
