import { Table, Typography } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setReportFinanceAPAAccountsCodeGroup } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import ATable from "~/view/component/ant_table/ATable"

class ReportFinanceAPATabAccountingCode extends React.Component {
	componentDidMount() {
		this.props.setReportFinanceAPAAccountsCodeGroup(this.props.filter.accountsPayableAgings)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.filter.accountsPayableAgings, this.props.filter.accountsPayableAgings) ||
			!_.isEqual(prevProps.filter.filterDateTime, this.props.filter.filterDateTime) ||
			!_.isEqual(prevProps.filter.filterSuppliers, this.props.filter.filterSuppliers)
		) {
			this.props.setReportFinanceAPAAccountsCodeGroup(this.props.filter.accountsPayableAgings)
		}
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col ref={this.ref}>
					<ATable
						pagination={false}
						data={this.props.filter.accountingCodeTable}
						expandable={{
							expandedRowRender: (row, idx) => {
								let selected = this.props.filter.accountingCodeTable.filtered[idx]
								let original = selected[1]
								let filtered = original.sort((a, b) => Utils.sort(a.paymentTermDateTime, b.paymentTermDateTime))
								let body = filtered.map((eachAP) => {
									return [
										Utils.formatDate(eachAP.paymentTermDateTime),
										eachAP.accountsPayable.paymentPlan ? eachAP.accountsPayable.paymentPlan.id : "",
										eachAP.documentCode + "-" + eachAP.documentId,
										Utils.formatDate(eachAP.accountsPayable.invoiceDateTime),
										eachAP.accountsPayable.invoice,
										eachAP.supplier,
										eachAP.accountsPayable.payment,
										Utils.formatNumWithComma(
											eachAP.accountsPayable.details
												.reduce((total, cur) => {
													if (eachAP.isAccountsPayableRC) {
														total = total.plus(cur.discountedTotalPrice)
													} else {
														total = Utils.BigNumber(cur.taxedTotalPrice)
															.dividedBy(1 + cur.tax / 100)
															.plus(total)
													}
													return total
												}, Utils.BigNumber(0))
												.toFixed(2)
										),
										Utils.formatNumWithComma(
											eachAP.accountsPayable.details
												.reduce((total, cur) => {
													if (eachAP.isAccountsPayableRC) {
														total = Utils.BigNumber(cur.taxedTotalPrice).minus(cur.discountedTotalPrice).plus(total)
													} else {
														total = Utils.BigNumber(cur.taxedTotalPrice)
															.minus(Utils.BigNumber(cur.taxedTotalPrice).dividedBy(1 + cur.tax / 100))
															.plus(total)
													}
													return total
												}, Utils.BigNumber(0))
												.toFixed(2)
										),
										Utils.formatNumWithComma(Utils.BigNumber(eachAP.credit).toFixed(2)),
									]
								})
								let colStyle = body.map((each) => {
									return [
										[false, ""],
										[false, ""],
										[true, { textAlign: "left" }],
										[false, ""],
										[true, { textAlign: "left" }],
										[true, { textAlign: "left", whiteSpace: "pre-line" }],
										[false, ""],
										[true, { textAlign: "right" }],
										[true, { textAlign: "right" }],
										[true, { textAlign: "right" }],
									]
								})
								let width = ["110px", "130px", "130px", "100px", "150px", undefined, "120px", "120px", "120px", "120px"]
								return (
									<ATable
										pagination={false}
										data={{
											original: original,
											filtered: filtered,
											header: [
												ResourceAssistance.Message.dueDate,
												ResourceAssistance.Message.paymentPlan,
												ResourceAssistance.Message.documentNum,
												ResourceAssistance.Message.date,
												ResourceAssistance.Message.invoiceId,
												ResourceAssistance.Message.supplier,
												ResourceAssistance.Message.paymentType,
												ResourceAssistance.Message.totalPrice,
												ResourceAssistance.Message.taxRate,
												ResourceAssistance.Message.totalPriceTaxed,
											],
											body: body,
											colStyle: colStyle,
											width: width,
										}}
										footer={() => ""}
									/>
								)
							},
							expandRowByClick: true,
						}}
						summary={() => {
							let totalCurrent = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[3]))
							}, Utils.BigNumber(0))
							let total_1To30 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[4]))
							}, Utils.BigNumber(0))
							let total_31To90 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[5]))
							}, Utils.BigNumber(0))
							let total_91To180 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[6]))
							}, Utils.BigNumber(0))
							let total_181To360 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[7]))
							}, Utils.BigNumber(0))
							let totalOver360 = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[8]))
							}, Utils.BigNumber(0))
							let total = this.props.filter.accountingCodeTable.body.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[9]))
							}, Utils.BigNumber(0))
							return (
								<Table.Summary fixed>
									<Table.Summary.Row>
										<Table.Summary.Cell index={0} colSpan={3} align="center">
											{translate(ResourceAssistance.Message.total)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={1} align="center">
											{Utils.formatNumWithComma(
												this.props.filter.accountingCodeTable.body
													.reduce((total, cur) => {
														return total.plus(Utils.formatNumberFromStr(cur[2]))
													}, Utils.BigNumber(0))
													.toNumber()
											)}
										</Table.Summary.Cell>
										<Table.Summary.Cell index={2} align="right">
											<Typography.Text style={{ color: totalCurrent.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(totalCurrent.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={3} align="right">
											<Typography.Text style={{ color: total_1To30.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_1To30.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={4} align="right">
											<Typography.Text style={{ color: total_31To90.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_31To90.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={5} align="right">
											<Typography.Text style={{ color: total_91To180.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_91To180.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={6} align="right">
											<Typography.Text style={{ color: total_181To360.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total_181To360.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={7} align="right">
											<Typography.Text style={{ color: totalOver360.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(totalOver360.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell index={8} align="right">
											<Typography.Text style={{ color: total.isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red }}>
												{Utils.formatNumWithComma(total.toFixed(2))}
											</Typography.Text>
										</Table.Summary.Cell>
									</Table.Summary.Row>
								</Table.Summary>
							)
						}}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.finance.accountsPayableAging,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setReportFinanceAPAAccountsCodeGroup,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportFinanceAPATabAccountingCode))
