import { Descriptions, Select } from "antd"
import _ from "lodash"
import moment from "moment"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_PS_MODAL_PAYMENT_PLAN_BRANCHES,
	SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME,
	SET_PS_MODAL_PAYMENT_PLAN_REMARK,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class PSModalPaymentPlanField extends React.Component {
	constructor(props) {
		super(props)

		this.onDateChange = this.onDateChange.bind(this)
		this.onType = this.onType.bind(this)
		this.onBank = this.onBank.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.PS.selectedPaymentPlan.paymentPlan) {
			let selectedPaymentPlan = this.props.PS.selectedPaymentPlan.paymentPlan
			this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME, selectedPaymentPlan.paymentTermDateTime)
			this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_REMARK, selectedPaymentPlan.remark)
			if (!_.isEmpty(this.props.PS.paymentTypes)) {
				this.initialPaymentType()
			}
			if (!_.isEmpty(this.props.PS.banks)) {
				this.initialBank()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PS.paymentTypes, this.props.PS.paymentTypes)) {
			if (this.props.PS.selectedPaymentPlan.paymentPlan && !_.isEmpty(this.props.PS.paymentTypes)) {
				this.initialPaymentType()
			}
		}
		if (!_.isEqual(prevProps.PS.banks, this.props.PS.banks)) {
			if (this.props.PS.selectedPaymentPlan.paymentPlan && !_.isEmpty(this.props.PS.banks)) {
				this.initialBank()
			}
		}

		if (!_.isEqual(prevProps.PS.modalPaymentPlan.selectedBank, this.props.PS.modalPaymentPlan.selectedBank)) {
			if (this.props.PS.modalPaymentPlan.selectedBank.bank) {
				this.props.setValue(
					SET_PS_MODAL_PAYMENT_PLAN_BRANCHES,
					this.props.PS.modalPaymentPlan.selectedBank.bank.branches
				)
			} else {
				this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH, -1, null)
				this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_BRANCHES, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
		this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE, -1, null)
		this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK, -1, null)
		this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH, -1, null)
		this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_REMARK, "")
		this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_BRANCHES, [])
	}

	initialPaymentType() {
		let paymentTypeIdx = this.props.PS.paymentTypes.findIndex(
			(each) => each.displayName === this.props.PS.selectedPaymentPlan.paymentPlan.paymentMethod
		)
		if (paymentTypeIdx > -1) {
			this.props.setSelected(
				SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE,
				paymentTypeIdx,
				this.props.PS.paymentTypes[paymentTypeIdx]
			)
		}
	}

	initialBank() {
		let bankIdx = this.props.PS.banks.findIndex(
			(each) => each.code === this.props.PS.selectedPaymentPlan.paymentPlan.bankCode
		)
		if (bankIdx > -1) {
			this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK, bankIdx, this.props.PS.banks[bankIdx])
			let branchIdx = this.props.PS.banks[bankIdx].branches.findIndex(
				(each) => each.displayName === this.props.PS.selectedPaymentPlan.paymentPlan.bankBranchName
			)
			if (branchIdx > -1) {
				this.props.setSelected(
					SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH,
					branchIdx,
					this.props.PS.banks[bankIdx].branches[branchIdx]
				)
			}
		}
	}

	onDateChange(value) {
		this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onType(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE, value, this.props.PS.paymentTypes[value])
	}

	onBank(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK, -1, null)
			return
		}
		this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK, value, this.props.PS.banks[value])
	}

	onBranch(value) {
		if (value === undefined) {
			this.props.setSelected(SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH, -1, null)
			return
		}
		this.props.setSelected(
			SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH,
			value,
			this.props.PS.modalPaymentPlan.branches[value]
		)
	}

	onRemark(e) {
		this.props.setValue(SET_PS_MODAL_PAYMENT_PLAN_REMARK, e.target.value)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							allowClear={false}
							placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.paymentTerms })]}
							values={[moment(this.props.PS.modalPaymentPlan.dateTime)]}
							onChange={this.onDateChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							placeholder={translate(ResourceAssistance.Message.paymentType)}
							loading={this.props.PS.isPaymentTypeLoading}
							value={
								this.props.PS.modalPaymentPlan.selectedPaymentType.type
									? this.props.PS.modalPaymentPlan.selectedPaymentType.index
									: undefined
							}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.PS.paymentTypes, false)}
						</Select>
					</Col>
				</Row>
				{this.props.PS.modalPaymentPlan.selectedPaymentType.type &&
					this.props.PS.modalPaymentPlan.selectedPaymentType.type.bankAccount && (
						<Fragment>
							<Row>
								<Col>
									<Select
										showSearch
										allowClear
										size={"large"}
										style={{ textAlign: "center", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) =>
											optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
										}
										placeholder={translate(ResourceAssistance.Message.bank)}
										loading={this.props.PS.modalPaymentPlan.isBankLoading}
										status={this.props.PS.modalPaymentPlan.selectedBank.bank ? undefined : "error"}
										value={
											this.props.PS.modalPaymentPlan.selectedBank.bank
												? this.props.PS.modalPaymentPlan.selectedBank.index
												: undefined
										}
										onChange={this.onBank}
									>
										{Utils.renderSelects(this.props.PS.banks, false)}
									</Select>
								</Col>
							</Row>
							{this.props.PS.modalPaymentPlan.selectedBank.bank && (
								<Row>
									<Col>
										<Descriptions
											style={{ flex: "unset", display: "unset" }}
											contentStyle={{ fontStyle: "italic", fontWeight: "normal" }}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.code)}>
												{this.props.PS.modalPaymentPlan.selectedBank.bank
													? this.props.PS.modalPaymentPlan.selectedBank.bank.code
													: ""}
											</Descriptions.Item>
										</Descriptions>
									</Col>
								</Row>
							)}
							<Row>
								<Col>
									<Select
										showSearch
										allowClear
										size={"large"}
										style={{ textAlign: "center", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) =>
											optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
										}
										placeholder={translate(ResourceAssistance.Message.bankBranch)}
										disabled={!this.props.PS.modalPaymentPlan.selectedBank.bank}
										status={this.props.PS.modalPaymentPlan.selectedBranch.branch ? undefined : "error"}
										value={
											this.props.PS.modalPaymentPlan.selectedBranch.branch
												? this.props.PS.modalPaymentPlan.selectedBranch.index
												: undefined
										}
										onChange={this.onBranch}
									>
										{Utils.renderSelects(this.props.PS.modalPaymentPlan.branches, false)}
									</Select>
								</Col>
							</Row>
							{this.props.PS.modalPaymentPlan.selectedBranch.branch && (
								<Row>
									<Col>
										<Descriptions
											style={{ flex: "unset", display: "unset" }}
											contentStyle={{ fontStyle: "italic", fontWeight: "normal" }}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.branchCode)}>
												{this.props.PS.modalPaymentPlan.selectedBranch.branch
													? this.props.PS.modalPaymentPlan.selectedBranch.branch.code
													: ""}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.bankAccNum)}>
												{this.props.PS.modalPaymentPlan.selectedBranch.branch
													? this.props.PS.modalPaymentPlan.selectedBranch.branch.account
													: ""}
											</Descriptions.Item>
										</Descriptions>
									</Col>
								</Row>
							)}
						</Fragment>
					)}
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={3}
							style={{ textAlign: "left" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark })}
							type={ResourceAssistance.FormControl.type.text}
							onChange={this.onRemark}
							value={this.props.PS.modalPaymentPlan.remark}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalPaymentPlanField))
