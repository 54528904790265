import _ from "lodash"
import propTypes from "prop-types"
import React, { Component } from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"
import "./date_picker.css"
import moment from "moment"

// import th from "date-fns/locale/th"
// registerLocale("th", th)

class DatePicker extends Component {
	rangeType = [{ displayName: "Day" }]

	constructor(props) {
		super(props)

		this.state = {
			componentName: "DatePicker",
			startDate: this.getOffSetDate(this.props.startDate, this.props.locale.yearOffSet),
			range: 0,
			rangeType: 0,
		}
		this.rangInputRef = React.createRef()
		this.onDateTime = this.onDateTime.bind(this)
		this.onRange = this.onRange.bind(this)
		this.onRangeType = this.onRangeType.bind(this)
		this.onKeyPress = this.onKeyPress.bind(this)
		this.onDateTimeBlur = this.onDateTimeBlur.bind(this)
	}

	componentDidMount() {
		this.validDate()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.startDate, this.props.startDate)) {
			this.setState({
				startDate: this.getOffSetDate(this.props.startDate, this.props.locale.yearOffSet),
				range: this.calculateRange(this.getOffSetDate(this.props.startDate, this.props.locale.yearOffSet)),
			})
		}
		if (!_.isEqual(prevProps.rangeStartDate, this.props.rangeStartDate)) {
			this.setState({
				range: this.props.rangStartDate
					? this.calculateRange(this.getOffSetDate(this.state.startDate, this.props.locale.yearOffSet))
					: 0,
			})
		}
		if (!_.isEqual(prevState.startDate, this.state.startDate)) {
			this.validDate()
		}
	}

	componentWillUnmount() {
		this.setState({
			range: 0,
			rangeType: 0,
		})
	}

	getOffSetDate(date, year = 0) {
		if (!date) {
			return null
		}
		return new Date(
			date.getFullYear() + year,
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		)
	}

	calculateRange(date) {
		switch (this.state.rangeType) {
			case 0:
				let days = Utils.calculateDaysBetween(
					this.getOffSetDate(this.props.rangeStartDate, this.props.locale.yearOffSet),
					date
				)
				return days

			default:
				break
		}
	}

	validDate() {
		if (this.props.inputClassName) {
			let inputDOM = document.getElementsByClassName(this.props.inputClassName)
			if (inputDOM.length > 0) {
				if (
					this.props.minimumDate &&
					this.state.startDate.getTime() <
						this.getOffSetDate(this.props.minimumDate, this.props.locale.yearOffSet).getTime()
				) {
					inputDOM[0].style.borderColor = "red"
				} else {
					inputDOM[0].style.borderColor = ""
				}

				if (
					this.props.maximumDate &&
					this.state.startDate.getTime() >
						this.getOffSetDate(this.props.maximumDate, this.props.locale.yearOffSet).getTime()
				) {
					inputDOM[0].style.borderColor = "red"
				} else {
					inputDOM[0].style.borderColor = ""
				}
			}
		}
	}

	onDateTime(localeDate) {
		if (!localeDate) {
			return
		}
		this.setState({
			startDate: localeDate,
		})
		if (this.props.rangeStartDate) {
			this.setState({
				range: this.calculateRange(localeDate),
			})
		}
		if (this.props.onChange) {
			this.props.onChange(
				this.getOffSetDate(localeDate, Utils.BigNumber(this.props.locale.yearOffSet).negated().toNumber())
			)
		}
	}

	onRange(event) {
		let localeDate =
			event.target.value && Utils.BigNumber(event.target.value).gt(0)
				? Utils.generateDateFromLong(
						this.getOffSetDate(this.props.rangeStartDate, this.props.locale.yearOffSet).getTime(),
						0,
						0,
						Utils.BigNumber(event.target.value).toNumber(),
						0,
						0,
						0
				  )
				: moment(this.getOffSetDate(this.props.rangeStartDate, this.props.locale.yearOffSet).getTime())
						.endOf("day")
						.milliseconds(0)
						.toDate()

		this.setState({
			range: event.target.value ? Utils.BigNumber(event.target.value).toNumber() : "",
			startDate: localeDate,
		})

		if (this.props.onChange) {
			this.props.onChange(
				_.isEmpty(event.target.value)
					? moment(this.getOffSetDate(this.props.rangeStartDate, this.props.locale.yearOffSet).getTime())
							.endOf("day")
							.milliseconds(0)
							.toDate()
					: this.getOffSetDate(localeDate, Utils.BigNumber(this.props.locale.yearOffSet).negated().toNumber())
			)
		}
	}

	onRangeType(event) {
		this.setState({
			rangeType: event.target.value,
		})
	}

	onKeyPress(e) {
		if (e.key === "Enter" || e.keyCode === 13) {
			this.rangInputRef.current.blur()
		}
	}

	onDateTimeBlur() {
		if (_.isEmpty(this.state.range)) {
			this.setState({
				range: 0,
			})
		}
	}

	render() {
		// const CustomInput = forwardRef(
		// 	(
		// 		// { value, onClick, className, onChange, onFocus }
		// 		{ ...props },
		// 		ref
		// 	) => {
		// 		return (
		// 			<input
		// 				// id={this.props.id}
		// 				// type="text"
		// 				// className={props.className}
		// 				// onClick={props.onClick}
		// 				// onChange={props.onChange}
		// 				// onFocus={props.onFocus}
		// 				// ref={ref}
		// 				// onBlur={props.onBlur}
		// 				// onKeyDown={props.onKeyDown}
		// 				// value={props.value}
		// 				// autoComplete={false}
		// 				{...props}
		// 			/>
		// 		)
		// 	}
		// )
		return (
			<div id={this.state.componentName} className={this.props.className}>
				<ReactDatePicker
					// customInput={<CustomInput />}
					className={this.props.inputClassName}
					disabled={this.props.disabled}
					minDate={this.props.minDate}
					showTimeSelect={_.isEmpty(this.props.includeTimes) ? false : this.props.isShowTime}
					includeTimes={this.props.includeTimes}
					timeIntervals={this.props.timeIntervals}
					maxDate={this.props.maxDate}
					// showDisabledMonthNavigation={this.props.showDisabledMonthNavigation}
					selected={this.state.startDate}
					openToDate={this.state.startDate}
					// startDate={this.state.startDate}
					// endDate={null}
					// selectsRange={this.props.selectsRange}
					// inline={this.props.inline}
					onChange={this.onDateTime}
					// showTimeSelect={this.props.isShowTime}
					// timeIntervals={1}
					// timeCaption="Time"
					dateFormat={this.props.isShowTime ? this.props.dateFormat : "dd-MM-yyyy"}
					showTimeInput={_.isEmpty(this.props.includeTimes) ? this.props.isShowTime : false}
					timeFormat="HH:mm"
					readOnly={this.props.readOnly}
					inline={this.props.inline}
					todayButton={translate(ResourceAssistance.Message.today)}
					rangeStartDate={this.props.rangeStartDate}
					shouldCloseOnSelect={this.props.shouldCloseOnSelect}
					onBlur={this.onDateTimeBlur}
				>
					{this.props.isShowDateRange && this.props.rangeStartDate && (
						<Container>
							<Row>
								<Col>
									<FormControl
										ref={this.rangInputRef}
										required
										autoFocus
										size={ResourceAssistance.FormControl.size.sm}
										type={ResourceAssistance.FormControl.type.number}
										step={1}
										min={0}
										value={this.state.range}
										onChange={this.onRange}
										onKeyPress={this.onKeyPress}
									/>
								</Col>
								<Col>
									<FormControl
										required
										as={ResourceAssistance.FormControl.as.select}
										size={ResourceAssistance.FormControl.size.sm}
										onChange={this.onRangeType}
									>
										{Utils.renderOptions(this.rangeType, false)}
									</FormControl>
								</Col>
							</Row>
						</Container>
					)}
				</ReactDatePicker>
			</div>
		)
	}
}

DatePicker.propTypes = {
	id: propTypes.string,
	className: propTypes.string,
	inputClassName: propTypes.string,
	disabled: propTypes.bool,
	dateFormat: propTypes.string,
	isShowTime: propTypes.bool,
	isShowDateRange: propTypes.bool,
	locale: propTypes.object,
	onChange: propTypes.func,
	readOnly: propTypes.bool,
	startDate: propTypes.object,
	minimumDate: propTypes.object,
	maximumDate: propTypes.object,
	showDisabledMonthNavigation: propTypes.bool,
	rangeStartDate: propTypes.object,
	shouldCloseOnSelect: propTypes.bool,
}

DatePicker.defaultProps = {
	startDate: new Date(),
	isShowTime: true,
	isShowDateRange: false,
	dateFormat: "dd-MM-yyyy h:mm aa",
	locale: {
		yearOffSet: 0,
	},
	showDisabledMonthNavigation: false,
}

export default DatePicker
