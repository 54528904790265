import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_AP_ACTIVITIES, SET_AP_SELECTABLE_ACCOUNTING_CODES, SET_PAGE_LOADING } from "~/redux/type"
import AccountsPayableRightInvoice from "./AccountsPayableRightInvoice"
import AccountsPayableRightItem from "./AccountsPayableRightItem"
import AccountsPayableRightTab from "./AccountsPayableRightTab"

class AccountsPayableRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AccountsPayableRight",
		}
	}

	componentDidMount() {
		if (_.isEmpty(this.props.AP.accountsPayableActivities)) {
			this.loadAccountsPayableActivities()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.AP.selectedInvoice, this.props.AP.selectedInvoice)) {
			this.props.setValue(SET_AP_SELECTABLE_ACCOUNTING_CODES, [])
		}
	}

	loadAccountsPayableActivities() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.generalLedger.getAccountsPayableActivities,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_AP_ACTIVITIES, res.data.activities)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<AccountsPayableRightInvoice />
				<AccountsPayableRightTab />
				<AccountsPayableRightItem />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	AP: state.accounting.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccountsPayableRight)
