import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setAdmIModalNIItems, setAdmIModalNISelectedItem, setAdmIModalNISelectedType, setSearchText } from "~/redux/action"
import { SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class AdmIModalNILeft extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		//set selected item's type for edit
		let typeHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.type)
		if (this.props.item.selectedItem.item) {
			let typeIdx = this.props.item.modalNewItem.types.findIndex((each) => each.id === this.props.item.selectedItem.item.type.id)
			if (typeIdx > -1) {
				typeHtml.selectedIndex = typeIdx
				this.props.setAdmIModalNISelectedType(typeIdx, this.props.item.modalNewItem.types[typeIdx])
			}
		} else {
			typeHtml.selectedIndex = 0
			this.props.setAdmIModalNISelectedType(0, this.props.item.modalNewItem.types[0])
		}
	}

	componentWillUnmount() {
		this.props.setAdmIModalNISelectedType(-1, null)
		this.props.setAdmIModalNISelectedItem(-1, null)
		this.props.setSearchText(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME, "")
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.item.modalNewItem.selectedType.index !== this.props.item.modalNewItem.selectedType.index ||
			prevProps.item.modalNewItem.searchName !== this.props.item.modalNewItem.searchName
		) {
			this.props.setAdmIModalNIItems(this.props.item.modalNewItem.itemTable.original)
		}

		if (
			!_.isEqual(prevProps.item.modalNewItem.itemTable.filtered, this.props.item.modalNewItem.itemTable.filtered) ||
			prevProps.item.modalNewItem.selectedType.index !== this.props.item.modalNewItem.selectedType.index
		) {
			//set selected item at the item table for edit
			if (this.props.item.selectedItem.item) {
				let itemIdx = this.props.item.modalNewItem.itemTable.filtered.findIndex((each) => each.id === this.props.item.selectedItem.item.item.id)
				if (itemIdx > -1) {
					this.props.setAdmIModalNISelectedItem(itemIdx, this.props.item.modalNewItem.itemTable.filtered[itemIdx])
				} else {
					this.props.setAdmIModalNISelectedItem(-1, null)
				}
			} else {
				this.props.setAdmIModalNISelectedItem(-1, null)
			}
		}
	}

	onType(event) {
		this.props.setAdmIModalNISelectedType(event.target.value, this.props.item.modalNewItem.types[event.target.value])
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SEARCH_NAME, event.target.value)
	}

	onSelectRow(row, index) {
		this.props.setAdmIModalNISelectedItem(index, this.props.item.modalNewItem.itemTable.filtered[index])
	}

	render() {
		return (
			<Container fluid="small" className={"full-flex"}>
				<Row>
					<Col md={4}>
						<FormControl
							id={ResourceAssistance.ID.ADM.item.modalNI.type}
							as={ResourceAssistance.FormControl.as.select}
							size={ResourceAssistance.FormControl.size.sm}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.item.modalNewItem.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
				</Row>
				<Row className={"g-0 full-size"}>
					<Col>
						<ScrollableTable
							striped
							enableHighlight
							enableOverlay
							isClearHighlight={this.props.item.modalNewItem.selectedItem.index === -1}
							highlightedRow={this.props.item.modalNewItem.selectedItem.index}
							data={this.props.item.modalNewItem.itemTable}
							onClick={this.onSelectRow}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setAdmIModalNISelectedType, setSearchText, setAdmIModalNISelectedItem, setAdmIModalNIItems }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmIModalNILeft)
