import { Checkbox, Radio, Space } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBMethod = {
	Under_Mask: "UM",
	Oral_Airway_No: "OAN",
	LMA_No: "LN",
	Tracheostomy_No: "TN",
	Nasal_Airway_No: "NAN",
	ET_Tube_No: "ETN",
}
export const RETTubeNoCuff = {
	Cuff: "C",
	Uncuff: "U",
}
export const RETTubeNoOral = {
	Oral: "O",
	Nasal: "N",
}
export const RType = {
	PVC: "P",
	Flexible: "F",
	RAE: "R",
	MLT: "M",
	DLT_Rt_No: "DRN",
	DLT_Lt_No: "DLN",
}
export const RIntubationTechnique = {
	Awake: "A",
	Already_Intubated: "AI",
	Blind: "B",
	Flexible_Fiberoptic_Endoscope: "FFE",
	Video_Laryngoscope: "VL",
	Direct_Laryngoscope: "DL",
}
export const RBladeType = {
	Macintosh: "Ma",
	Miller: "Mi",
}
export const RBreathingSystem = {
	"Semi-closed/Circle": "SC",
	"Semi-open:Jackson Rees/Bain": "SO",
}
export const CBAssistedDevices = {
	Breath_Sound_Checked: "BSC",
	ETCO2_Present: "EP",
}
export const CBAfterIntubation = {
	ET_Tube_Secured_With_Tape: "E",
	Cuffed_Inflation: "CI",
}
export const CBCuffedInflation = {
	Minimal_Occlusive_Volume: "MOV",
	Manometer_20_to_30_cmH2O: "M",
}
export const CBBladeType = {
	Stylet_Used: "SU",
}
class OperatingRoomModalIntraAneAirwayDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onMethodDetail = this.onMethodDetail.bind(this)
		this.onMethodOralAirwayNo = this.onMethodOralAirwayNo.bind(this)
		this.onMethodLMANo = this.onMethodLMANo.bind(this)
		this.onMethodTracheostomyNo = this.onMethodTracheostomyNo.bind(this)
		this.onMethodNasalAirwayNo = this.onMethodNasalAirwayNo.bind(this)
		this.onMethodETTubeNo = this.onMethodETTubeNo.bind(this)
		this.onMethodETTubeNoCuff = this.onMethodETTubeNoCuff.bind(this)
		this.onMethodETTubeNoIntubation = this.onMethodETTubeNoIntubation.bind(this)
		this.onMethodETTubeNoDepth = this.onMethodETTubeNoDepth.bind(this)
		this.onType = this.onType.bind(this)
		this.onTypeDLTRtNo = this.onTypeDLTRtNo.bind(this)
		this.onTypeDLTLtNo = this.onTypeDLTLtNo.bind(this)
		this.onLaryngealView = this.onLaryngealView.bind(this)
		this.onLaryngealViewDifficulty = this.onLaryngealViewDifficulty.bind(this)
		this.onLaryngealViewAttempt = this.onLaryngealViewAttempt.bind(this)
		this.onIntubationTechnique = this.onIntubationTechnique.bind(this)
		this.onBladeType = this.onBladeType.bind(this)
		this.onBladeSize = this.onBladeSize.bind(this)
		this.onBladeTypeDetail = this.onBladeTypeDetail.bind(this)
		this.onAssistedDeviceDetail = this.onAssistedDeviceDetail.bind(this)
		this.onAfterIntubationDetail = this.onAfterIntubationDetail.bind(this)
		this.onCuffedInflationDetail = this.onCuffedInflationDetail.bind(this)
		this.onBreathingSystem = this.onBreathingSystem.bind(this)
		this.onVerbalCommand = this.onVerbalCommand.bind(this)
		this.onCough = this.onCough.bind(this)
		this.onAdeq = this.onAdeq.bind(this)
		this.onSwallowing = this.onSwallowing.bind(this)
		this.onHandGrip = this.onHandGrip.bind(this)
		this.onDyspnea = this.onDyspnea.bind(this)
		this.onUAO = this.onUAO.bind(this)
		this.onWheezing = this.onWheezing.bind(this)
		this.onCyanosis = this.onCyanosis.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD, op.method.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY, op.methodOralAirwayNo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA, op.methodLMANo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY, op.methodTracheostomyNo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY, op.methodNasalAirwayNo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE, op.methodETTubeNo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF, op.methodETTubeNoCuff)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION, op.methodETTubeNoOral)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH, op.methodETTubeNoDepth)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE, op.airwayType)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT, op.typeDLTRtNo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT, op.typeDLTLtNo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW, op.laryngealView)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY, op.laryngealViewDifficulty)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT, op.laryngealViewAttempt)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE, op.intubationTechnique)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE, op.bladeType)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE, op.bladeSize)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE, op.bladeStylet.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES, op.assistedDevice.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION, op.afterIntubation.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION, op.cuffedInflation.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM, op.breathingSystem)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND, op.verbalCommand)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION, op.coughOnSuction)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR, op.adeqSpontVentilator)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG, op.swallowingGag)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP, op.handGrip)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA, op.dyspnea)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO, op.uao)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING, op.wheezing)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS, op.cyanosis)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneAirwayManagement.methodDetail, this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail)) {
			if (!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.Oral_Airway_No)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY, "")
			}
			if (!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.LMA_No)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA, "")
			}
			if (!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.Tracheostomy_No)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY, "")
			}
			if (!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.Nasal_Airway_No)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY, "")
			}
			if (!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.ET_Tube_No)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE, "")
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF, "")
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION, "")
			}
		}
		if (
			!_.isEqual(
				prevProps.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail,
				this.props.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail
			)
		) {
			if (!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail, CBAfterIntubation.Cuffed_Inflation)) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION, [])
			}
		}
		if (
			!_.isEqual(
				prevProps.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral,
				this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral
			)
		) {
			if (this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral !== RETTubeNoOral.Nasal) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneAirwayManagement.type, this.props.operatingRoom.modalIntraAneAirwayManagement.type)) {
			if (this.props.operatingRoom.modalIntraAneAirwayManagement.type !== RType.DLT_Rt_No) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT, "")
			}
			if (this.props.operatingRoom.modalIntraAneAirwayManagement.type !== RType.DLT_Lt_No) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.method)}:</label>
					</Col>
					<Col>
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.underMask),
									value: CBMethod.Under_Mask,
									style: { flexDirection: "row", alignItems: "center", marginTop: "10px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.oralAirwayNo)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px", width: "80px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.Oral_Airway_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodOralAirwayNo}
												onChange={this.onMethodOralAirwayNo}
											/>
										</Space>,
									],
									value: CBMethod.Oral_Airway_No,
									style: { flexDirection: "row", alignItems: "center", marginTop: "2px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.LMANo)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px", width: "80px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.LMA_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodLMANo}
												onChange={this.onMethodLMANo}
											/>
										</Space>,
									],
									value: CBMethod.LMA_No,
									style: { flexDirection: "row", alignItems: "center", marginTop: "2px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.tracheostomyNo)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px", width: "80px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.Tracheostomy_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodTracheostomyNo}
												onChange={this.onMethodTracheostomyNo}
											/>
										</Space>,
									],
									value: CBMethod.Tracheostomy_No,
									style: { flexDirection: "row", alignItems: "center", marginTop: "2px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.nasalAirwayNo)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px", width: "80px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.Nasal_Airway_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodNasalAirwayNo}
												onChange={this.onMethodNasalAirwayNo}
											/>
										</Space>,
									],
									value: CBMethod.Nasal_Airway_No,
									style: { flexDirection: "row", alignItems: "center", marginTop: "2px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.ETTubeNo)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px", width: "80px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.ET_Tube_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNo}
												onChange={this.onMethodETTubeNo}
											/>
											<Radio.Group
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.ET_Tube_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoCuff}
												onChange={this.onMethodETTubeNoCuff}
											>
												<Radio style={{ flexDirection: "row" }} value={RETTubeNoCuff.Cuff}>
													{translate(ResourceAssistance.Message.cuff)}
												</Radio>
												<Radio style={{ flexDirection: "row" }} value={RETTubeNoCuff.Uncuff}>
													{translate(ResourceAssistance.Message.uncuff)}
												</Radio>
											</Radio.Group>
											<Radio.Group
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail, CBMethod.ET_Tube_No)}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral}
												onChange={this.onMethodETTubeNoIntubation}
											>
												<Radio style={{ flexDirection: "row" }} value={RETTubeNoOral.Oral}>
													{translate(ResourceAssistance.Message.oral)}
												</Radio>
												<Radio style={{ flexDirection: "row" }} value={RETTubeNoOral.Nasal}>
													{translate(ResourceAssistance.Message.nasal)}
												</Radio>
											</Radio.Group>
											<label>{translate(ResourceAssistance.Message.depth)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px", width: "80px" }}
												styles={{ input: { textAlign: "center" } }}
												disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoOral, RETTubeNoOral.Nasal)}
												placeholder=""
												suffix={ResourceAssistance.CONSTANT.CM}
												value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodETTubeNoDepth}
												onChange={this.onMethodETTubeNoDepth}
											/>
										</Space>,
									],
									value: CBMethod.ET_Tube_No,
									style: { flexDirection: "row", alignItems: "center", marginTop: "2px" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.methodDetail}
							onChange={this.onMethodDetail}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.type)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.type} onChange={this.onType}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RType.PVC}>
								{translate(ResourceAssistance.Message.PVC)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RType.Flexible}>
								{translate(ResourceAssistance.Message.flexible)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RType.RAE}>
								{translate(ResourceAssistance.Message.RAE)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RType.MLT}>
								{translate(ResourceAssistance.Message.MLT)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RType.DLT_Rt_No}>
								<Space>
									<label>{translate(ResourceAssistance.Message.DLTRtNo)}</label>
									<GInput
										size={"small"}
										style={{ marginTop: "-10px", width: "80px" }}
										styles={{ input: { textAlign: "center" } }}
										disabled={!_.isEqual(this.props.operatingRoom.modalIntraAneAirwayManagement.type, RType.DLT_Rt_No)}
										suffix={ResourceAssistance.CONSTANT.CM}
										value={this.props.operatingRoom.modalIntraAneAirwayManagement.typeDLTRtNo}
										onChange={this.onTypeDLTRtNo}
									/>
								</Space>
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RType.DLT_Lt_No}>
								<Space>
									<label>{translate(ResourceAssistance.Message.DLTLtNo)}</label>
									<GInput
										size={"small"}
										style={{ marginTop: "-10px", width: "80px" }}
										styles={{ input: { textAlign: "center" } }}
										placeholder=""
										disabled={!_.isEqual(this.props.operatingRoom.modalIntraAneAirwayManagement.type, RType.DLT_Lt_No)}
										suffix={ResourceAssistance.CONSTANT.CM}
										value={this.props.operatingRoom.modalIntraAneAirwayManagement.typeDLTLtNo}
										onChange={this.onTypeDLTLtNo}
									/>
								</Space>
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2} />
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.laryngealView)}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealView}
							onChange={this.onLaryngealView}
						/>
					</Col>
					<Col md="auto">
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.difficulty)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewDifficulty} onChange={this.onLaryngealViewDifficulty}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.attempt)}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.laryngealViewAttempt}
							onChange={this.onLaryngealViewAttempt}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.intubationTechnique)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.intubationTechnique} onChange={this.onIntubationTechnique}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RIntubationTechnique.Awake}>
								{translate(ResourceAssistance.Message.awake)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RIntubationTechnique.Already_Intubated}>
								{translate(ResourceAssistance.Message.alreadyIntubated)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RIntubationTechnique.Blind}>
								{translate(ResourceAssistance.Message.blind)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RIntubationTechnique.Flexible_Fiberoptic_Endoscope}>
								{translate(ResourceAssistance.Message.flexibleFiberopticEndoscope)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RIntubationTechnique.Video_Laryngoscope}>
								{translate(ResourceAssistance.Message.videoLaryngoscope)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RIntubationTechnique.Direct_Laryngoscope}>
								{translate(ResourceAssistance.Message.directLaryngoscope)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.bladeType)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.bladeType} onChange={this.onBladeType}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBladeType.Macintosh}>
								{translate(ResourceAssistance.Message.macintosh)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBladeType.Miller}>
								{translate(ResourceAssistance.Message.miller)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							disabled={this.props.operatingRoom.modalIntraAneAirwayManagement.bladeType === ""}
							placeholder={translate(ResourceAssistance.Message.bladeSize)}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.bladeSize}
							onChange={this.onBladeSize}
						/>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalIntraAneAirwayManagement.bladeType === ""}
							options={[
								{
									label: translate(ResourceAssistance.Message.styletUsed),
									value: CBBladeType.Stylet_Used,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.bladeTypeDetail}
							onChange={this.onBladeTypeDetail}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.assistedDevices)}:</label>
					</Col>
					<Col>
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.breathSoundChecked),
									value: CBAssistedDevices.Breath_Sound_Checked,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.ETCO2Present),
									value: CBAssistedDevices.ETCO2_Present,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.assistedDeviceDetail}
							onChange={this.onAssistedDeviceDetail}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.afterIntubation)}:</label>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.ETTubeSecuredWithTape),
									value: CBAfterIntubation.ET_Tube_Secured_With_Tape,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.cuffedInflation),
									value: CBAfterIntubation.Cuffed_Inflation,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail}
							onChange={this.onAfterIntubationDetail}
						/>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={!_.includes(this.props.operatingRoom.modalIntraAneAirwayManagement.afterIntubationDetail, CBAfterIntubation.Cuffed_Inflation)}
							options={[
								{
									label: translate(ResourceAssistance.Message.minimalOcclusiveVolume),
									value: CBCuffedInflation.Minimal_Occlusive_Volume,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.manometer2030cmH2O),
									value: CBCuffedInflation.Manometer_20_to_30_cmH2O,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAneAirwayManagement.cuffedInflationDetail}
							onChange={this.onCuffedInflationDetail}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2} style={{ alignSelf: "center" }}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.breathingSystem)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.breathingSystem} onChange={this.onBreathingSystem}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBreathingSystem["Semi-closed/Circle"]}>
								{translate(ResourceAssistance.Message.semiClosedCircle)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RBreathingSystem["Semi-open:Jackson Rees/Bain"]}>
								{translate(ResourceAssistance.Message.semiOpenJacksonReesBain)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col>
						<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
							<Row>
								<Col>
									<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.beforeExtubation)}</label>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.verbalCommand)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isVerbalCommand} onChange={this.onVerbalCommand}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.coughOnSuction)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isCough} onChange={this.onCough}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.adeqSpontVentilator)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isAdeq} onChange={this.onAdeq}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.swallowingGag)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isSwallowing} onChange={this.onSwallowing}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.handGripHeadLift)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isHandGrip} onChange={this.onHandGrip}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
						</Container>
					</Col>
					<Col>
						<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
							<Row>
								<Col>
									<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.afterExtubation)}</label>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.dyspnea)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isDyspnea} onChange={this.onDyspnea}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.UAO)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isUAO} onChange={this.onUAO}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.wheezing)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isWheezing} onChange={this.onWheezing}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
							<Row>
								<Col md={4} style={{ alignSelf: "center" }}>
									<label>{translate(ResourceAssistance.Message.cyanosis)}</label>
								</Col>
								<Col>
									<Radio.Group value={this.props.operatingRoom.modalIntraAneAirwayManagement.isCyanosis} onChange={this.onCyanosis}>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"true"}>
											{translate(ResourceAssistance.Message.yes)}
										</Radio>
										<Radio style={{ flexDirection: "row", alignItems: "center" }} value={"false"}>
											{translate(ResourceAssistance.Message.no)}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
	onMethodDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD, values)
	}
	onMethodOralAirwayNo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY, e.target.value)
	}
	onMethodLMANo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA, e.target.value)
	}
	onMethodTracheostomyNo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY, e.target.value)
	}
	onMethodNasalAirwayNo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY, e.target.value)
	}
	onMethodETTubeNo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE, e.target.value)
	}
	onMethodETTubeNoCuff(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF, e.target.value)
	}
	onMethodETTubeNoIntubation(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION, e.target.value)
	}
	onMethodETTubeNoDepth(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH, e.target.value)
	}
	onType(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE, e.target.value)
	}
	onTypeDLTRtNo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT, e.target.value)
	}
	onTypeDLTLtNo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT, e.target.value)
	}
	onLaryngealView(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW, e.target.value)
	}
	onLaryngealViewDifficulty(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY, e.target.value)
	}
	onLaryngealViewAttempt(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT, e.target.value)
	}
	onIntubationTechnique(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE, e.target.value)
	}
	onBladeType(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE, e.target.value)
	}
	onBladeSize(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE, e.target.value)
	}
	onBladeTypeDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE, values)
	}
	onAssistedDeviceDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES, values)
	}
	onAfterIntubationDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION, values)
	}
	onCuffedInflationDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION, values)
	}
	onBreathingSystem(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM, e.target.value)
	}
	onVerbalCommand(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND, e.target.value)
	}
	onCough(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION, e.target.value)
	}
	onAdeq(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR, e.target.value)
	}
	onSwallowing(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG, e.target.value)
	}
	onHandGrip(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP, e.target.value)
	}
	onDyspnea(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA, e.target.value)
	}
	onUAO(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO, e.target.value)
	}
	onWheezing(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING, e.target.value)
	}
	onCyanosis(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneAirwayDetails)
