import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setOPDModalDoctorServiceServices, setSelected } from "~/redux/action"
import { SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OPDModalDoctorServiceService extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectService = this.onSelectService.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.opd.modalDoctorService.searchName !== this.props.opd.modalDoctorService.searchName ||
			!_.isEqual(prevProps.opd.modalDoctorService.selectedCategory, this.props.opd.modalDoctorService.selectedCategory)
		) {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE, -1, null)
			this.props.setOPDModalDoctorServiceServices(this.props.opd.modalDoctorService.selectedCategory.category.services)
		}
	}

	componentWillUnmount() {
		this.props.setOPDModalDoctorServiceServices([])
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE, -1, null)
	}

	onSelectService(service, index) {
		let selectedService = this.props.opd.modalDoctorService.serviceTable.filtered[index]
		if (selectedService.role && selectedService.role.id !== this.props.login.user.userType.id) {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE, -1, null)
		} else {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_SERVICE, index, selectedService)
		}
	}

	render() {
		return (
			<Fragment>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectService}
							data={this.props.opd.modalDoctorService.serviceTable}
							isClearHighlight={this.props.opd.modalDoctorService.selectedService.index === -1}
							highlightedRow={this.props.opd.modalDoctorService.selectedService.index}
						/>
						{this.props.opd.modalDoctorService.serviceTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setOPDModalDoctorServiceServices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalDoctorServiceService))
