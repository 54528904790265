import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setARBillingStatementGroups, setSelected } from "~/redux/action"
import { SET_AR_SELECTED_GROUP } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceivableRightGeneral extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		if (this.props.AR.selectedInvoice.invoice) {
			this.props.setARBillingStatementGroups(this.props.AR.selectedInvoice.invoice.billing.billingStatements)
		} else {
			this.props.setARBillingStatementGroups([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.AR.selectedInvoice, this.props.AR.selectedInvoice)) {
			if (this.props.AR.selectedInvoice.invoice) {
				this.props.setARBillingStatementGroups(this.props.AR.selectedInvoice.invoice.billing.billingStatements)
			} else {
				this.props.setARBillingStatementGroups([])
			}
		}
		if (
			!_.isEqual(prevProps.AR.billingStatementGroupTable.filtered, this.props.AR.billingStatementGroupTable.filtered)
		) {
			let index = this.props.AR.selectedGroup.group
				? this.props.AR.billingStatementGroupTable.filtered.findIndex(
						(group) => group[0] === this.props.AR.selectedGroup.group[0]
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AR_SELECTED_GROUP, index, this.props.AR.billingStatementGroupTable.filtered[index])
			} else {
				this.props.setSelected(SET_AR_SELECTED_GROUP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_AR_SELECTED_GROUP, -1, null)
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_AR_SELECTED_GROUP, index, this.props.AR.billingStatementGroupTable.filtered[index])
	}

	render() {
		let totalBalance = this.props.AR.billingStatementGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[1]))
		}, Utils.BigNumber(0))
		let totalDiscountService = this.props.AR.billingStatementGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[2]))
		}, Utils.BigNumber(0))
		let totalDiscountHealthCarePlan = this.props.AR.selectedInvoice.invoice
			? this.props.AR.selectedInvoice.invoice.billing.billingPlanAdjustments.reduce((total, cur) => {
					return total.plus(cur.adjustments)
			  }, Utils.BigNumber(0))
			: Utils.BigNumber(0)
		let totalDiscountedBalance = totalBalance.minus(totalDiscountService).minus(totalDiscountHealthCarePlan)
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						data={this.props.AR.billingStatementGroupTable}
						isClearHighlight={this.props.AR.selectedGroup.index === -1}
						highlightedRow={this.props.AR.selectedGroup.index}
						onClick={this.onSelectRow}
						extra={
							!_.isEmpty(this.props.AR.billingStatementGroupTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalBalance)}>
												{Utils.formatNumWithComma(totalBalance.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.discountService)}>
												{Utils.formatNumWithComma(totalDiscountService.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.discountBill)}>
												{Utils.formatNumWithComma(totalDiscountHealthCarePlan.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item
												label={translate(ResourceAssistance.Message.totalPriceDiscounted)}
												contentStyle={{ color: totalDiscountedBalance.isLessThan(0) ? "red" : undefined }}
											>
												{Utils.formatNumWithComma(totalDiscountedBalance.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setARBillingStatementGroups,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceivableRightGeneral))
