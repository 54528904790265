import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AdmCategoryServiceCOAReceivable from "./AdmCategoryServiceCOAReceivable"

class AdmCategoryServiceCOA extends React.Component {
	render() {
		return (
			<Row className={"full-size g-2"}>
				<Col>
					<AdmCategoryServiceCOAReceivable />
				</Col>
				<Col></Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmCategoryServiceCOA))
