import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_ARC_BANKS,
	SET_ARC_BANKS_LOADING,
	SET_ARC_INSURANCE_COMPANIES,
	SET_ARC_INSURANCE_COMPANIES_LOADING,
	SET_ARC_PAYMENTS,
	SET_ARC_PAYMENTS_LOADING,
	SET_ARC_SELECTABLE_ACCOUNTING_CODES,
} from "~/redux/type"
import AccountsReceiveRightBillingStatement from "./AccountsReceiveRightBillingStatement"
import AccountsReceiveRightInfo from "./AccountsReceiveRightInfo"
import AccountsReceiveRightTab from "./AccountsReceiveRightTab"

class AccountsReceiveRight extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.ARC.insuranceCompanies)) {
			this.loadInsuranceCompanies()
		}
		if (_.isEmpty(this.props.ARC.payments)) {
			this.loadPayments()
		}
		if (_.isEmpty(this.props.ARC.banks)) {
			this.loadBanks()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.ARC.selectedInvoice, this.props.ARC.selectedInvoice)) {
			this.props.setValue(SET_ARC_SELECTABLE_ACCOUNTING_CODES, [])
		}
	}

	loadInsuranceCompanies() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.accountsReceive.getInsuranceCompanies,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_ARC_INSURANCE_COMPANIES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ARC_INSURANCE_COMPANIES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_ARC_INSURANCE_COMPANIES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ARC_INSURANCE_COMPANIES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadPayments() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.accountsReceive.getPayments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_ARC_PAYMENTS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ARC_PAYMENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_ARC_PAYMENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ARC_PAYMENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadBanks() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.accountsReceive.getBanks,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_ARC_BANKS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ARC_BANKS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_ARC_BANKS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ARC_BANKS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<AccountsReceiveRightInfo />
				<AccountsReceiveRightTab />
				<AccountsReceiveRightBillingStatement />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccountsReceiveRight)
