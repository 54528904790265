import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setORModalIntraAneMedicationItems, setORModalPostAneMedicationItems, setValue } from "~/redux/action"
import {
	SET_OR_ANESTHESIAS,
	SET_OR_ANESTHESIAS_LOADING,
	SET_OR_DOCTOR_ITEM_TYPES,
	SET_OR_DOCTOR_ITEM_TYPES_LOADING,
	SET_OR_DOCTORS,
	SET_OR_DOCTORS_LOADING,
	SET_OR_ICDS,
	SET_OR_ICDS_LOADING,
	SET_OR_INTAKES,
	SET_OR_INTAKES_LOADING,
	SET_OR_ITEMS,
	SET_OR_ITEMS_LOADING,
	SET_OR_LOCATION_DISPLAY,
	SET_OR_OPERATING_ROOM_CASES,
	SET_OR_OPERATING_ROOM_CASES_LOADING,
	SET_OR_OUTPUTS,
	SET_OR_OUTPUTS_LOADING,
	SET_OR_SERVICE_TYPES,
	SET_OR_SERVICE_TYPES_LOADING,
} from "~/redux/type"
import OperatingRoomLeft from "./left/OperatingRoomLeft"
import OperatingRoomRight from "./right/OperatingRoomRight"

class OperatingRoom extends React.Component {
	componentDidMount() {
		this.loadOperatingRoomCases()
		this.loadUsers()
		this.loadICDs()
		this.loadAnesthesias()
		this.loadItems()
		this.loadTypes()
		this.loadServices()
		this.loadIntakes()
		this.loadOutputs()
		this.props.onModalDisplayAction(SET_OR_LOCATION_DISPLAY, true)
	}
	loadUsers() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getUsers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(
				SET_OR_DOCTORS,
				res.data.types.filter((each) => each.role === ResourceAssistance.Enum.doctor).flatMap((role) => role.users)
			)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_DOCTORS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_DOCTORS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_DOCTORS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadOperatingRoomCases() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getOperatingRoomCases,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_OPERATING_ROOM_CASES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_OPERATING_ROOM_CASES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_OPERATING_ROOM_CASES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_OPERATING_ROOM_CASES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadICDs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getICDs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_ICDS, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_ICDS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_ICDS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_ICDS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadAnesthesias() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getAnesthesias,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_ANESTHESIAS, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_ANESTHESIAS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_ANESTHESIAS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_ANESTHESIAS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(
				SET_OR_DOCTOR_ITEM_TYPES,
				res.data.types.filter((type) => type.role === ResourceAssistance.Enum.doctor)
			)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_DOCTOR_ITEM_TYPES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_DOCTOR_ITEM_TYPES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_DOCTOR_ITEM_TYPES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadItems() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			let items = res.data.items.reduce((obj, cur) => {
				return Array.prototype.concat.apply(
					obj,
					cur.itemSupplierRelps.map((each) => {
						return {
							...each,
							type: cur.type,
							item: {
								id: cur.id,
								displayName: cur.displayName,
								keyword: cur.keyword,
								warnings: cur.warnings,
							},
						}
					})
				)
			}, [])
			this.props.setValue(SET_OR_ITEMS, items)
			this.props.setORModalIntraAneMedicationItems(items)
			this.props.setORModalPostAneMedicationItems(items)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_ITEMS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_ITEMS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_ITEMS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadServices() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getServices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_SERVICE_TYPES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_SERVICE_TYPES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_SERVICE_TYPES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_SERVICE_TYPES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadIntakes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getIntakes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_INTAKES, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_INTAKES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_INTAKES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_INTAKES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	loadOutputs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.operatingRoom.getOutputs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_OUTPUTS, res.data.types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_OR_OUTPUTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_OR_OUTPUTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_OR_OUTPUTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
	render() {
		return (
			<Fragment>
				{this.props.operatingRoom.location && (
					<Container fluid className={ResourceAssistance.CSS.fullFlex}>
						<Row className={"g-0 full-size"} style={{ flexWrap: "nowrap" }}>
							<Col md={3} style={{ width: "23.15%" }}>
								<OperatingRoomLeft />
							</Col>
							<Col md={9} style={{ width: "76.85%" }}>
								<OperatingRoomRight />
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setORModalIntraAneMedicationItems,
			setORModalPostAneMedicationItems,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoom))
