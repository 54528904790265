import { Button, Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_CONTRACT_BILLING_INFO_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import RequiredLabel from "~/view/component/required/RequiredLabel"

class ContractBillingRightGeneral extends React.Component {
	constructor(props) {
		super(props)
		this.onEdit = this.onEdit.bind(this)
	}

	onEdit(e) {
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_INFO_DISPLAY, true)
	}

	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.general)}</legend>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic" }}
							column={3}
							extra={
								<>
									{this.props.contract.selectedBilling.billing && !this.props.contract.selectedBilling.billing.complete && (
										<Col md="auto">
											<Button type="primary" size="middle" shape="round" icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />} onClick={this.onEdit} />
										</Col>
									)}
								</>
							}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
								{this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.insuranceCompanyCode : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.insuranceCompany)} span={1}>
								{this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.insuranceCompany : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.factoryArea)} span={1}>
								{this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.factoryArea : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.address)} span={2}>
								{this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.insuranceCompanyAddress : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={1}>
								{this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.insuranceCompanyTel : ""}
							</Descriptions.Item>
							<Descriptions.Item label={<RequiredLabel>{translate(ResourceAssistance.Message.dueDate)}</RequiredLabel>} span={3}>
								{this.props.contract.selectedBilling.billing ? Utils.formatDate(this.props.contract.selectedBilling.billing.dueDateTime) : ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractBillingRightGeneral)
