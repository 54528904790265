import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_AR_BILLING_STATEMENT, SET_AR_BILLING_STATEMENT_GL_GROUP, SET_AR_BILLING_STATEMENT_GROUP, SET_AR_INVOICES } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setAccountsReceivableInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
			.filter((invoice) => invoice.id.includes(getState().accounting.accountsReceivable.filterInvoiceId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))
		if (_.includes(getState().accounting.accountsReceivable.filters, ResourceAssistance.Message.incomplete)) {
			filtered = filtered.filter((invocie) => !invocie.accountsReceivableComplete)
		}
		let rowColor = filtered.map((billing) => {
			let color = ""
			if (billing.accountsReceivableComplete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})
		let body = filtered.map((billing) => {
			return [Utils.formatDate(billing.creationDateTime), billing.id]
		})

		dispatch({
			type: SET_AR_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setARBillingStatementGroups = (billingStatements) => {
	return (dispatch, getState) => {
		let filtered = Object.entries(Utils.groupBy(billingStatements, "categoryName")).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = filtered.map(([key, values]) => {
			let totals = values.reduce(
				(totalArray, cur) => {
					totalArray[0] = totalArray[0].plus(cur.charge)
					totalArray[1] = totalArray[1].plus(cur.adjustment)
					return totalArray
				},
				[Utils.BigNumber(0), Utils.BigNumber(0)]
			)
			return [
				key,
				Utils.formatNumWithComma(totals[0].toFixed(2)),
				Utils.formatNumWithComma(totals[1].toFixed(2)),
				Utils.formatNumWithComma(totals[0].minus(totals[1]).toFixed(2)),
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_AR_BILLING_STATEMENT_GROUP,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setARGLGroups = (generalLedgers) => {
	return (dispatch, getState) => {
		let sortedGLs = generalLedgers.sort((a, b) => Utils.sort(b.debit, a.debit, [[a.code, b.code]]))
		let filtered = sortedGLs.map((gl) => {
			if (gl.systemGenerated) {
				let foundItems = getState().accounting.accountsReceivable.selectedInvoice.invoice.billing.billingStatements.filter((bs) =>
					bs.type.activities.some((activity) => activity.chartOfAccounts.fullCode === gl.code)
				)
				return ["", foundItems]
			} else {
				return ["", getState().accounting.accountsReceivable.selectedInvoice.invoice.billing.billingStatements.filter((bs) => bs.accountingCode === gl.code)]
			}
		})
		let body = sortedGLs.map((gl) => {
			return [
				gl.code,
				gl.name,
				gl.category,
				gl.transactionDescription,
				Utils.formatNumWithComma(Utils.BigNumber(gl.debit).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(gl.credit).toFixed(2)),
			]
		})

		let rowColor = sortedGLs.map((each) => {
			let color = ""
			if (each.systemGenerated) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		let colStyle = body.map(() => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_AR_BILLING_STATEMENT_GL_GROUP,
			payload: {
				original: generalLedgers,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setARBillingStatementGLGroups = (billingStatements) => {
	return (dispatch, getState) => {
		let invoice = getState().accounting.accountsReceivable.selectedInvoice.invoice
		let filtered = Object.entries(
			Utils.groupBy(billingStatements, "accountingCode", "accountingChartName", "accountingTransactionDescription", "categoryName")
		).sort((a, b) => Utils.sort(a[0], b[0]))

		let body = filtered.map(([key, values]) => {
			let total = values.reduce((total, cur) => {
				total = total.plus(cur.charge)
				return total
			}, Utils.BigNumber(0))
			let keys = key.split("#")
			return {
				color: "",
				values: [
					keys.length === 1 ? [true] : keys[0],
					keys.length > 1 ? keys[1] : "",
					keys.length > 1 ? keys[3] : keys[0],
					keys.length > 1
						? Utils.decodeAccountingTransDescr(keys[2], "", Utils.formatDate(getState().accounting.accountsReceivable.selectedInvoice.invoice.creationDateTime))
						: "",
					"",
					Utils.formatNumWithComma(total.minus(invoice.billing.total).toFixed(2)),
					[keys.length > 1],
				],
			}
		})

		//Configed GL Activities
		let ARs = getState().accounting.accountsReceivable.accountsReceivableActivities
		ARs.forEach((each) => {
			let filteredBillingStatements
			if (each.includeIPD && each.includeOPD) {
				filteredBillingStatements = billingStatements
			} else if (each.includeOPD) {
				filteredBillingStatements = billingStatements.filter((each) => each.order.outpatient)
			} else {
				filteredBillingStatements = billingStatements.filter((each) => !each.order.outpatient)
			}
			let foundBSs = filteredBillingStatements.filter((bs) =>
				bs.type.activities
					.filter((activity) => activity.receivable && activity.linkGL)
					.some((activity) => activity.chartOfAccounts.id === each.chartOfAccounts.id)
			)
			let total = Utils.BigNumber(0)

			if (each.includeBalance && each.includeDiscount) {
				total = foundBSs.reduce((total, cur) => {
					return total.plus(cur.charge).minus(cur.adjustment).minus(cur.billAdjustment)
				}, Utils.BigNumber(0))
				//if partially paid at cashier
				if (!_.isEmpty(foundBSs) && invoice) {
					total = total.minus(invoice.billing.total)
				}
			} else if (each.includeBalance) {
				total = foundBSs.reduce((total, cur) => {
					return total.plus(cur.charge)
				}, Utils.BigNumber(0))
				//if partially paid at cashier
				if (!_.isEmpty(foundBSs) && invoice) {
					total = total.minus(invoice.billing.total)
				}
			} else if (each.includeDiscount) {
				total = foundBSs.reduce((total, cur) => {
					return total.plus(cur.adjustment).plus(cur.billAdjustment)
				}, Utils.BigNumber(0))
			}
			if (total.abs().isGreaterThan(0)) {
				if (each.debit) {
					filtered.unshift(["", foundBSs])
					body.unshift({
						color: ResourceAssistance.CSS.Color.green,
						values: [
							each.chartOfAccounts.fullCode,
							each.chartOfAccounts.displayName,
							"",
							Utils.decodeAccountingTransDescr(
								each.description,
								"",
								Utils.formatDate(getState().accounting.accountsReceivable.selectedInvoice.invoice.creationDateTime)
							),
							each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
							each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
							[false],
						],
					})
				} else {
					filtered.push(["", foundBSs])
					body.push({
						color: ResourceAssistance.CSS.Color.green,
						values: [
							each.chartOfAccounts.fullCode,
							each.chartOfAccounts.displayName,
							"",
							Utils.decodeAccountingTransDescr(
								each.description,
								"",
								Utils.formatDate(getState().accounting.accountsReceivable.selectedInvoice.invoice.creationDateTime)
							),
							each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
							each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
							[false],
						],
					})
				}
			}

			if (getState().accounting.accountsReceivable.selectedInvoice.invoice) {
				let selectedInvoice = getState().accounting.accountsReceivable.selectedInvoice.invoice
				let selectedHealthCarePlan = getState().accounting.accountsReceivable.healthcarePlans.find(
					(healthcarePlan) => healthcarePlan.code === selectedInvoice.billing.billingPlan.code
				)
				if (selectedHealthCarePlan) {
					let selectedCompany = selectedHealthCarePlan.companies.find((company) => Utils.trim(company.name) === selectedInvoice.billing.billingPlan.name)
					if (
						selectedCompany &&
						selectedCompany.activities
							.filter((activity) => activity.receivable && activity.linkGL)
							.some((activity) => activity.chartOfAccounts.id === each.chartOfAccounts.id)
					) {
						let total = Utils.BigNumber(0)

						if (each.includeBalance && each.includeDiscount) {
							total = filteredBillingStatements.reduce((total, cur) => {
								return total.plus(cur.charge).minus(cur.adjustment).minus(cur.billAdjustment)
							}, Utils.BigNumber(0))
							//if partially paid at cashier
							if (!_.isEmpty(filteredBillingStatements) && invoice) {
								total = total.minus(invoice.billing.total)
							}
						} else if (each.includeBalance) {
							total = filteredBillingStatements.reduce((total, cur) => {
								return total.plus(cur.charge)
							}, Utils.BigNumber(0))
							//if partially paid at cashier
							if (!_.isEmpty(filteredBillingStatements) && invoice) {
								total = total.minus(invoice.billing.total)
							}
						} else if (each.includeDiscount) {
							total = filteredBillingStatements.reduce((total, cur) => {
								return total.plus(cur.adjustment).plus(cur.billAdjustment)
							}, Utils.BigNumber(0))
						}
						if (total.abs().isGreaterThan(0)) {
							if (each.debit) {
								filtered.unshift(["", filteredBillingStatements])
								body.unshift({
									color: ResourceAssistance.CSS.Color.green,
									values: [
										each.chartOfAccounts.fullCode,
										each.chartOfAccounts.displayName,
										"",
										Utils.decodeAccountingTransDescr(
											each.description,
											"",
											Utils.formatDate(getState().accounting.accountsReceivable.selectedInvoice.invoice.creationDateTime)
										),
										each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
										each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
										[false],
									],
								})
							} else {
								filtered.push(["", filteredBillingStatements])
								body.push({
									color: ResourceAssistance.CSS.Color.green,
									values: [
										each.chartOfAccounts.fullCode,
										each.chartOfAccounts.displayName,
										"",
										Utils.decodeAccountingTransDescr(
											each.description,
											"",
											Utils.formatDate(getState().accounting.accountsReceivable.selectedInvoice.invoice.creationDateTime)
										),
										each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
										each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
										[false],
									],
								})
							}
						}
					}
				}
			}
		})

		let rowColor = body.map((each) => {
			return [!_.isEmpty(each.color), each.color]
		})

		let colStyle = body.map(() => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_AR_BILLING_STATEMENT_GL_GROUP,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body.map((each) => each.values),
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setARBillingStatements = (billingStatements) => {
	return (dispatch, getState) => {
		let filtered = billingStatements.sort((a, b) => Utils.sort(a.name, b.name))

		let body = filtered.map((bs) => {
			return [
				bs.name,
				Utils.formatNumWithComma(Utils.BigNumber(bs.charge).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(bs.adjustment).toFixed(2)) +
					" (" +
					Utils.BigNumber(bs.adjustment).dividedBy(bs.charge).times(100).toFixed(2) +
					"%)",
				bs.adjustmentDescription,
				Utils.formatNumWithComma(Utils.BigNumber(bs.billAdjustment).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(bs.charge).minus(bs.adjustment).toFixed(2)),
				[true],
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_AR_BILLING_STATEMENT,
			payload: {
				original: billingStatements,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
