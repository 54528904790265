import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setOPDPatients,
	setSelected,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_OPD_GCS_DISPLAY, SET_OPD_SELECTED_GCS, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightGCSTable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightGCSTable",
		}

		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onSelect = this.onSelect.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteGCS)
			) {
				this.deleteGCS()
			}
		}
		if (!_.isEqual(prevProps.opd.gcsTable.filtered, this.props.opd.gcsTable.filtered)) {
			let index = this.props.opd.selectedGCS.gcs
				? this.props.opd.gcsTable.filtered.findIndex((each) => each.id === this.props.opd.selectedGCS.gcs.id)
				: -1
			if (index > -1) {
				this.props.setSelected(SET_OPD_SELECTED_GCS, index, this.props.opd.gcsTable.filtered[index])
			} else {
				this.props.setSelected(SET_OPD_SELECTED_GCS, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_SELECTED_GCS, -1, null)
	}

	deleteGCS() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteGCSs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				glasgowComaScales: [
					{
						id: this.props.opd.selectedGCS.gcs.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onNew(e) {
		this.props.setSelected(SET_OPD_SELECTED_GCS, -1, null)
		this.props.onModalDisplayAction(SET_OPD_GCS_DISPLAY, true)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.setSelected(SET_OPD_SELECTED_GCS, rIdx, this.props.opd.gcsTable.filtered[rIdx])
		this.props.onModalDisplayAction(SET_OPD_GCS_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedGCS = this.props.opd.gcsTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteGCS)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.glasgowComaScale })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(
				selectedGCS.dateTime
			)}
		    `
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSelect(io, idx) {
		this.props.setSelected(SET_OPD_SELECTED_GCS, idx, this.props.opd.gcsTable.filtered[idx])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.opd.gcsTable}
						isClearHighlight={this.props.opd.selectedGCS.index === -1}
						highlightedRow={this.props.opd.selectedGCS.index}
						onClick={this.onSelect}
						extra={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Nurse, this.props.login.user.roles) &&
							this.props.opd.selectedMedicalRecord.medicalRecord &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setWarningId,
			setWarningMsgAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightGCSTable))
