import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setDebtPlans, setLoadingAction } from "~/redux/action"
import { SET_DEBT_NEW_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import DebtModalNewDetails from "./DebtModalNewDetails"
import { Utils } from "~/utils/Utils"

class DebtModalNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "DebtModalNew",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_DEBT_NEW_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.debt.modalNew.pendingInvoiceTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let insuranceCompany = this.props.debt.modalNew.insuranceCompanies.find(
			(each) => each.displayName === this.props.debt.modalNew.pendingInvoiceTable.filtered[0].billing.billingPlan.insuranceCompany
		)
		let GL = Utils.getGeneralLedgerCode(this.props.debt.location.accountsReceivable, [], insuranceCompany ? insuranceCompany.activities : [])
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.debt.saveDebtPlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.debt.location.id,
				userId: this.props.login.user.id,
				debtPlans: [
					{
						id: this.props.debt.selectedPlan.plan ? this.props.debt.selectedPlan.plan.id : "",
						insuranceCompany: this.props.debt.modalNew.pendingInvoiceTable.filtered[0].billing.billingPlan.insuranceCompany,
						invoices: this.props.debt.modalNew.pendingInvoiceTable.filtered,
						accountingCode: GL.fullCode,
						accountingChartName: GL.displayName,
						accountingChartDescription: GL.description,
						accountingTransDescr: GL.transDescr,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.debtPlans.length > 0) {
				let plans = this.props.debt.planTable.original.filter((each) => each.id !== res.data.debtPlans[0].id)
				plans.push(res.data.debtPlans[0])
				this.props.setDebtPlans(plans)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.new)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<DebtModalNewDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isDebtNewDisplay,
	login: state.login,
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtPlans,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalNew))
