import { Button as AButton, Descriptions, Tooltip } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FaRegEdit, FaSignature, FaTimes } from "react-icons/fa"
import { FaCheck } from "react-icons/fa6"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgVitalSign } from "~/icons"
import { onModalDisplayAction, setValue } from "~/redux/action"
import { SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY, SET_OR_VITAL_SIGN, SET_OR_VITAL_SIGN_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { CheckBoxGroupUtils } from "~/utils/component/CheckBoxGroupUtils"
import { CBAnesthesiaPlan } from "../../../_modal/edit-pre-operative/OperatingRoomModalEditPreAnesthesiaPlan"
import {
	CBAirwayExpectedDifficult,
	CBBackExamination,
	CBCardiovascular,
	CBEndocrine,
	CBGastrointestinal,
	CBGeneralAppearance,
	CBHematoOncology,
	CBNeurologicalAssessment,
	CBRenal,
	CBRespiratory,
} from "../../../_modal/edit-pre-operative/OperatingRoomModalEditPrePhysicalExam"
import { CBCrossMatching } from "../../../_modal/edit-pre-operative/OperatingRoomModalEditPrePrepared"

class OperatingRoomAnesthesiologistTabPre extends React.Component {
	constructor(props) {
		super(props)

		this.onEdit = this.onEdit.bind(this)
		this.onVitalSign = this.onVitalSign.bind(this)
		this.onEditVitalSign = this.onEditVitalSign.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_VITAL_SIGN, null)
	}

	setVitalSign() {
		if (this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)) {
			this.props.setValue(
				SET_OR_VITAL_SIGN,
				this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0]
			)
		}
	}

	render() {
		let anesthesiaPreOP =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
				? this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
				: {}
		let cbc = anesthesiaPreOP.completeBloodCount ? anesthesiaPreOP.completeBloodCount.split(",") : []
		let electrolytes = anesthesiaPreOP.electrolytes ? anesthesiaPreOP.electrolytes.split(",") : []
		let liverFunctionTest = anesthesiaPreOP.liverFunctionTest ? anesthesiaPreOP.liverFunctionTest.split(",") : []
		let coagulation = anesthesiaPreOP.coagulation ? anesthesiaPreOP.coagulation.split(",") : []
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className="full-size">
					<Col>
						<Descriptions
							style={{ flex: "1", flexDirection: "column", overflowY: "auto", overflowX: "hidden" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={8}
							extra={
								<Container fluid className={"full-size full-flex"}>
									<Row className="g-2" style={{ marginBottom: "0" }}>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.edit)}>
												<AButton
													type="primary"
													size="middle"
													shape="round"
													icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onEdit}
												/>
											</Tooltip>
										</Col>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.vitalSign)}>
												<AButton
													type="primary"
													size="middle"
													shape="round"
													icon={<SvgVitalSign width={ResourceAssistance.ReactIcon.size} height={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onVitalSign}
												/>
											</Tooltip>
										</Col>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.approve)}>
												<AButton
													style={{
														background: ResourceAssistance.CSS.Color.dark_green,
													}}
													type="primary"
													size="middle"
													shape="round"
													icon={<FaCheck size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onApprove}
												/>
											</Tooltip>
										</Col>
										<Col md="auto">
											<Tooltip title={translate(ResourceAssistance.Message.reject)}>
												<AButton
													style={{
														background: ResourceAssistance.CSS.Color.red,
													}}
													type="primary"
													size="middle"
													shape="round"
													icon={<FaTimes size={ResourceAssistance.ReactIcon.size} />}
													onClick={this.onReject}
												/>
											</Tooltip>
										</Col>
									</Row>
								</Container>
							}
						>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.womenOnly)} span={8} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.lastMenstrualPeriod)} span={3}>
								{anesthesiaPreOP.lastMenstrualPeriod}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.pregnancy)} span={5}>
								{anesthesiaPreOP.pregnant === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.pregnant)
									? translate(ResourceAssistance.Message.no)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.yes })}, ${this.props.intl.formatMessage({
											id: ResourceAssistance.Message.ga,
									  })} ${anesthesiaPreOP.pregnant} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.week })}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.smoking)} span={8}>
								{anesthesiaPreOP.smoking === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.smoking)
									? translate(ResourceAssistance.Message.no)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.yes })}, ${anesthesiaPreOP.pregnant} ${this.props.intl.formatMessage({
											id: ResourceAssistance.Message.packPerDay,
									  })}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.medicationUsage)} span={8}>
								{anesthesiaPreOP.medicationUsage === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.medicationUsage)
									? translate(ResourceAssistance.Message.no)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.yes })}, ${anesthesiaPreOP.medicationUsage}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.vitalSign)} span={8}>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										<Col md="auto">
											{this.props.operatingRoom.selectedRequest.request &&
											!_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
												? Utils.formatDateTime(
														this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0]
															.dateTime
												  )
												: ""}
										</Col>
										{this.props.operatingRoom.selectedRequest.request &&
											!_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns) && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onEditVitalSign}>
														<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
									</Row>
								</Container>
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.temperature)} span={1}>
								{this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0].temperature
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.prPerMin)} span={1}>
								{this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0].pr
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.rrPerMin)} span={1}>
								{this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0].rr
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bpmmHg)} span={1}>
								{this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0].bp
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.o2Sat)} span={4}>
								{this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0].oxygenSat
									: ""}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.generalAppearance)} span={8}>
								{anesthesiaPreOP.generalAppearance === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.generalAppearance)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBGeneralAppearance,
											anesthesiaPreOP.generalAppearance,
											[CBGeneralAppearance.Other]
									  )}, ${anesthesiaPreOP.generalAppearanceOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.ASAClass)} span={8}>
								{anesthesiaPreOP.asaClass
									? `${anesthesiaPreOP.asaClass.split(",")[0]} ${
											anesthesiaPreOP.asaClass.split(",")[1] ? this.props.intl.formatMessage({ id: ResourceAssistance.Message.emergency }) : ""
									  }`
									: ""}
							</Descriptions.Item>
							<Descriptions.Item
								labelStyle={{ fontWeight: "bold" }}
								label={translate(ResourceAssistance.Message.airwayAssessment)}
								span={8}
							></Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.mouthOpen)} span={1}>
								{anesthesiaPreOP.airwayMouthOpen === undefined
									? ""
									: anesthesiaPreOP.airwayMouthOpen
									? ResourceAssistance.CONSTANT.greaterOrEqualTo2FB
									: ResourceAssistance.CONSTANT.lessThan2FB}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dentures)} span={1}>
								{anesthesiaPreOP.airwayDentures === undefined
									? ""
									: anesthesiaPreOP.airwayDentures
									? this.props.intl.formatMessage({ id: ResourceAssistance.Message.yes })
									: this.props.intl.formatMessage({ id: ResourceAssistance.Message.no })}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dentalDamage)} span={1}>
								{anesthesiaPreOP.airwayDentalDamage === undefined
									? ""
									: anesthesiaPreOP.airwayDentalDamage
									? this.props.intl.formatMessage({ id: ResourceAssistance.Message.yes })
									: this.props.intl.formatMessage({ id: ResourceAssistance.Message.no })}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.thyromental)} span={1}>
								{anesthesiaPreOP.airwayThyromental === undefined
									? ""
									: anesthesiaPreOP.airwayThyromental
									? ResourceAssistance.CONSTANT.greaterOrEqualTo6cm
									: ResourceAssistance.CONSTANT.lessThan6cm}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.hyomentalDistance)} span={1}>
								{anesthesiaPreOP.airwayHyomentalDistance === undefined
									? ""
									: anesthesiaPreOP.airwayHyomentalDistance
									? ResourceAssistance.CONSTANT.greaterOrEqualTo2FB
									: ResourceAssistance.CONSTANT.lessThan2FB}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.neck)} span={3}>
								{anesthesiaPreOP.airwayNeck === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.airwayNeck)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${anesthesiaPreOP.airwayNeck}`}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.mallampatiScore)} span={1}>
								{anesthesiaPreOP.airwayMallampatiScore === undefined ? "" : anesthesiaPreOP.airwayMallampatiScore}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.expectedDifficultAirway)} span={7}>
								{anesthesiaPreOP.airwayExpectedDifficult === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.airwayExpectedDifficult)
									? translate(ResourceAssistance.Message.no)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.yes })}, ${CheckBoxGroupUtils.getKeyStr(
											CBAirwayExpectedDifficult,
											anesthesiaPreOP.airwayExpectedDifficult
									  )}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.neurologicalAssessment)} span={8}>
								{anesthesiaPreOP.neurologicalAssessment === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.backExamination)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBNeurologicalAssessment,
											anesthesiaPreOP.neurologicalAssessment,
											[CBNeurologicalAssessment.Other]
									  )}, ${anesthesiaPreOP.neurologicalAssessmentOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.backExamination)} span={8}>
								{anesthesiaPreOP.backExamination === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.backExamination)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBBackExamination,
											anesthesiaPreOP.backExamination,
											[CBBackExamination.Other]
									  )}, ${anesthesiaPreOP.backExaminationOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.cardiovascular)} span={8}>
								{anesthesiaPreOP.cardiovascular === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.cardiovascular)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBCardiovascular,
											anesthesiaPreOP.cardiovascular,
											[CBCardiovascular.Other],
											{
												[CBCardiovascular.Hypertension]: anesthesiaPreOP.cardiovascularHypertension
													? this.props.intl.formatMessage({ id: ResourceAssistance.Message.controlled })
													: this.props.intl.formatMessage({ id: ResourceAssistance.Message.poorlyControlled }),
												[CBCardiovascular.Functional_Capacity]: `${anesthesiaPreOP.cardiovascularFunctionalCapacity} ${ResourceAssistance.CONSTANT.METs}`,
											}
									  )}, ${anesthesiaPreOP.cardiovascularOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.respiratory)} span={8}>
								{anesthesiaPreOP.respiratory === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.respiratory)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBRespiratory,
											anesthesiaPreOP.respiratory,
											[CBRespiratory.Other]
									  )}, ${anesthesiaPreOP.respiratoryOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.gastrointestinal)} span={8}>
								{anesthesiaPreOP.gastrointestinal === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.gastrointestinal)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBGastrointestinal,
											anesthesiaPreOP.gastrointestinal,
											[CBGastrointestinal.Other]
									  )}, ${anesthesiaPreOP.gastrointestinalOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.endocrine)} span={8}>
								{anesthesiaPreOP.endocrine === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.endocrine)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBEndocrine,
											anesthesiaPreOP.endocrine,
											[CBEndocrine.Other],
											{
												[CBEndocrine.DM]: `${anesthesiaPreOP.endocrineDM}`,
											}
									  )}, ${anesthesiaPreOP.endocrineOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.hematoOncology)} span={8}>
								{anesthesiaPreOP.hematoOncology === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.hematoOncology)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBHematoOncology,
											anesthesiaPreOP.hematoOncology,
											[CBHematoOncology.Other]
									  )}, ${anesthesiaPreOP.hematoOncologyOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.renal)} span={8}>
								{anesthesiaPreOP.renal === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.renal)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${CheckBoxGroupUtils.getKeyStr(
											CBRenal,
											anesthesiaPreOP.renal,
											[CBRenal.Other],
											{
												[CBRenal.Insufficiency]: `${ResourceAssistance.CONSTANT.CrCl} ${anesthesiaPreOP.renalInsufficiency} ${ResourceAssistance.CONSTANT.ML_MIN}`,
											}
									  )}, ${anesthesiaPreOP.renalOther}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.completeBloodCount)} span={8} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.WBC)} span={2}>
								{cbc[0]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.hgb)} span={2}>
								{cbc[1]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.hct)} span={2}>
								{cbc[2]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.platelets)} span={2}>
								{cbc[3]}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.electrolytes)} span={8} />
							<Descriptions.Item label={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.na })}${ResourceAssistance.Symbol.plus}`} span={1}>
								{electrolytes[0]}
							</Descriptions.Item>
							<Descriptions.Item label={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.cl })}${ResourceAssistance.Symbol.minus}`} span={1}>
								{electrolytes[1]}
							</Descriptions.Item>
							<Descriptions.Item label={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.k })}${ResourceAssistance.Symbol.plus}`} span={1}>
								{electrolytes[2]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.hco3)} span={1}>
								{electrolytes[3]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.bun)} span={1}>
								{electrolytes[4]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.cr)} span={1}>
								{electrolytes[5]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.glucose)} span={2}>
								{electrolytes[6]}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.liverFunctionTest)} span={8} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.albumin)} span={1}>
								{liverFunctionTest[0]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.globulin)} span={1}>
								{liverFunctionTest[1]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.AST)} span={1}>
								{liverFunctionTest[2]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.ALT)} span={1}>
								{liverFunctionTest[3]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.totalBilirubin)} span={4}>
								{liverFunctionTest[4]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.directBilirubin)} span={1}>
								{liverFunctionTest[5]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.alkalinePhosphatase)} span={7}>
								{liverFunctionTest[6]}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.coagulation)} span={8} />
							<Descriptions.Item label={translate(ResourceAssistance.Message.PT)} span={2}>
								{coagulation[0]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.PTT)} span={2}>
								{coagulation[1]}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.INR)} span={4}>
								{coagulation[2]}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.EKG)} span={8}>
								{anesthesiaPreOP.ekg === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.ekg)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${anesthesiaPreOP.ekg}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.CXR)} span={8}>
								{anesthesiaPreOP.cxr === undefined
									? ""
									: _.isEmpty(anesthesiaPreOP.cxr)
									? translate(ResourceAssistance.Message.normal)
									: `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.abnormal })}, ${anesthesiaPreOP.cxr}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.other)} span={8}>
								{anesthesiaPreOP.otherInvestigation}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.antiHIV)} span={8}>
								{anesthesiaPreOP.antiHIV === undefined
									? ""
									: anesthesiaPreOP.antiHIV
									? translate(ResourceAssistance.Message.positive)
									: translate(ResourceAssistance.Message.negative)}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.crossMatching)} span={8}>
								{anesthesiaPreOP.crossMatching === undefined
									? ""
									: `${CheckBoxGroupUtils.getKeyStr(CBCrossMatching, anesthesiaPreOP.crossMatching, [], {
											[CBCrossMatching.Packed_Red_Cell]: `${anesthesiaPreOP.crossMatchingPackedRedCell} ${this.props.intl.formatMessage({
												id: ResourceAssistance.Message.unit,
											})}`,
											[CBCrossMatching.Fresh_Frozen_Plasma]: `${anesthesiaPreOP.crossMatchingFreshFrozenPlasma} ${this.props.intl.formatMessage({
												id: ResourceAssistance.Message.unit,
											})}`,
											[CBCrossMatching.Platelet_Concentrate]: `${anesthesiaPreOP.crossMatchingPlateletConcentrate} ${this.props.intl.formatMessage({
												id: ResourceAssistance.Message.unit,
											})}`,
									  })}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.anesthesiaPlan)} span={8}>
								{anesthesiaPreOP.anesthesiaPlan === undefined
									? ""
									: `${CheckBoxGroupUtils.getKeyStr(CBAnesthesiaPlan, anesthesiaPreOP.anesthesiaPlan, [], {
											[CBAnesthesiaPlan.Regional_Anesthesia]: `${anesthesiaPreOP.anesthesiaPlanRegional}`,
									  })}`}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.anesthesiaPremedication)} span={8}>
								{anesthesiaPreOP.anesthesiaPlanPreMedication}
							</Descriptions.Item>
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={"Consent"} span={8}>
								<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
									<Row className="g-1" style={{ marginBottom: 0 }}>
										<Col md="auto">
											{this.props.operatingRoom.selectedRequest.request &&
											!_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns)
												? Utils.formatDateTime(
														this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns.sort((a, b) => Utils.sort(b.dateTime, a.dateTime))[0]
															.dateTime
												  )
												: ""}
										</Col>
										{this.props.operatingRoom.selectedRequest.request &&
											!_.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.vitalSigns) && (
												<Col md="auto">
													<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.link} onClick={this.onEditVitalSign}>
														<FaSignature size={ResourceAssistance.ReactIcon.size} />
													</Button>
												</Col>
											)}
									</Row>
								</Container>
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Container>
		)
	}
	onEdit() {
		this.props.onModalDisplayAction(SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY, true)
	}
	onVitalSign() {
		this.props.setValue(SET_OR_VITAL_SIGN, null)
		this.props.onModalDisplayAction(SET_OR_VITAL_SIGN_DISPLAY, true)
	}
	onEditVitalSign() {
		this.setVitalSign()
		this.props.onModalDisplayAction(SET_OR_VITAL_SIGN_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomAnesthesiologistTabPre))
