import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { IoMdDoneAll } from "react-icons/io"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setCashierModalCertificatePending, setValue, onModalDisplayAction } from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_MAX,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CashierModalCertificateAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalAdjustment.isContinue, this.props.cashier.modalAdjustment.isContinue)) {
			if (
				this.props.cashier.modalAdjustment.isContinue &&
				_.isEqual(this.props.cashier.modalAdjustment.id, ResourceAssistance.ID.HOSPITEL.cashier.modalCertificate.id)
			) {
				let pendingCertificates = this.props.cashier.modalCertificate.pendingTable.filtered
				let pendingCertificate = pendingCertificates.find((each) => each.id === this.props.cashier.modalCertificate.selectedUncharged.certificate.id)

				let selectedUncharged = Object.assign({}, this.props.cashier.modalCertificate.selectedUncharged.certificate, {
					charge: Utils.BigNumber(this.props.cashier.modalAdjustment.balance)
						.plus(pendingCertificate ? pendingCertificate.charge : 0)
						.toFixed(2),
					adjustment: Utils.BigNumber(this.props.cashier.modalAdjustment.amount)
						.plus(pendingCertificate ? pendingCertificate.adjustment : 0)
						.toFixed(2),
					adjustmentDescription: pendingCertificate
						? Utils.trim(this.props.cashier.modalAdjustment.description).concat(" ", Utils.trim(pendingCertificate.adjustmentDescription))
						: Utils.trim(this.props.cashier.modalAdjustment.description),
				})

				pendingCertificates = pendingCertificates.filter((each) => each.id !== selectedUncharged.id)
				pendingCertificates.push(selectedUncharged)
				this.props.setCashierModalCertificatePending(pendingCertificates)
			}
		}
	}

	onDownBtn() {
		if (this.props.cashier.modalCertificate.selectedUncharged.index === -1) {
			return
		}
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.HOSPITEL.cashier.modalCertificate.id)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY, false)
		this.props.setValue(
			SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
			this.props.calculateCertificateBalance(this.props.cashier.modalCertificate.selectedUncharged.certificate)
		)
		this.props.setValue(
			SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_MAX,
			this.props.calculateCertificateBalance(this.props.cashier.modalCertificate.selectedUncharged.certificate)
		)

		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onUpBtn() {
		if (this.props.cashier.modalCertificate.selectedPending.index === -1) {
			return
		}

		let pendingCertificates = this.props.cashier.modalCertificate.pendingTable.filtered.filter(
			(each) => each.id !== this.props.cashier.modalCertificate.selectedPending.certificate.id
		)
		this.props.setCashierModalCertificatePending(pendingCertificates)
	}

	onSelectAll() {
		let pendingCertificates = this.props.cashier.modalCertificate.pendingTable.filtered
		this.props.cashier.modalCertificate.unchargedTable.filtered.forEach((certificate) => {
			let pendingCertificate = pendingCertificates.find((each) => each.id === certificate.id)

			let selectedCertificate = Object.assign({}, certificate, {
				charge: Utils.BigNumber(this.props.calculateCertificateBalance(certificate))
					.plus(pendingCertificate ? pendingCertificate.charge : 0)
					.toFixed(2),
				adjustment: pendingCertificate ? pendingCertificate.adjustment : 0,
				adjustmentDescription: pendingCertificate ? Utils.trim(pendingCertificate.adjustmentDescription) : "",
			})

			pendingCertificates = pendingCertificates.filter((each) => each.id !== selectedCertificate.id)
			pendingCertificates.push(selectedCertificate)
		})
		this.props.setCashierModalCertificatePending(pendingCertificates)
	}

	render() {
		return (
			<Row>
				<Col className={this.props.cashier.modalCertificate.selectedUncharged.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
					<FcDownload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onDownBtn} />
				</Col>
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.props.cashier.modalCertificate.unchargedTable.filtered.length === 0}
						onClick={this.onSelectAll}
					>
						<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.selectAll)}
					</Button>
				</Col>
				<Col className={this.props.cashier.modalCertificate.selectedPending.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
					<FcUpload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onUpBtn} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setCashierModalCertificatePending,
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalCertificateAction)
