import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setPharmacyModalReturnLots, setSelected } from "~/redux/action"
import { SET_PHARMACY_MODAL_RETURN_SELECTED_LOT, SET_PHARMACY_RETURN_EDIT_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyModalReturnItem extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			lots: [],
			isVerified: false,
		}

		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		let items = this.props.pharmacy.selectedDispensedOrder.dispensedOrder.items.map((item) =>
			Object.assign({}, item, {
				returned: item.returnItems
					.reduce((total, cur) => {
						total = total.plus(cur.amount)
						return total
					}, Utils.BigNumber(0))
					.toNumber(),
				returnAmount: 0,
				isReturn: false,
			})
		)
		this.props.setPharmacyModalReturnLots(items)
	}

	componentWillUnmount() {
		this.props.setPharmacyModalReturnLots([])
		this.props.setSelected(SET_PHARMACY_MODAL_RETURN_SELECTED_LOT, -1, null)
	}

	onEdit(event, row, rKey, cKey) {
		this.props.setSelected(
			SET_PHARMACY_MODAL_RETURN_SELECTED_LOT,
			rKey,
			this.props.pharmacy.modalReturn.itemTable.filtered[rKey]
		)
		this.props.onModalDisplayAction(SET_PHARMACY_RETURN_EDIT_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col style={{ minHeight: "200px", maxHeight: "200px" }}>
					<ScrollableTable
						enableOverlay
						striped
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onEdit]}
						data={this.props.pharmacy.modalReturn.itemTable}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPharmacyModalReturnLots,
			onModalDisplayAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PharmacyModalReturnItem)
