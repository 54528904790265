import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled, ScheduleFilled } from "@ant-design/icons"
import { Descriptions, Divider } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class OutpatientModalORRequestInfoCriteria extends React.Component {
	render() {
		return (
			<>
				<Row>
					<Col>
						<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }} column={4}>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.startDateTime)}>
								{this.props.opd.selectedORRequest.request.operatingRoomSchedules[0]
									? Utils.formatDateTime(this.props.opd.selectedORRequest.request.operatingRoomSchedules[0].startDateTime)
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.endDateTime)}>
								{this.props.opd.selectedORRequest.request.operatingRoomSchedules[0]
									? Utils.formatDateTime(this.props.opd.selectedORRequest.request.operatingRoomSchedules[0].endDateTime)
									: ""}
							</Descriptions.Item>
							<Descriptions.Item span={4} label={translate(ResourceAssistance.Message.orCase)}>
								{this.props.opd.selectedORRequest.request.operatingRoomCase}
							</Descriptions.Item>
							<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.operativeDoctor)}>
								{this.props.opd.selectedORRequest.request.operativeDoctor}
							</Descriptions.Item>
							<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.doctorFee)}>
								{Utils.formatNumWithComma(Utils.BigNumber(this.props.opd.selectedORRequest.request.doctorFee).toFixed(2))}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.preOperativeDiagICD)}>
								{this.props.opd.selectedORRequest.request.preDiagICD}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.preOperativeDiag)}>
								{this.props.opd.selectedORRequest.request.preOperativeDiag}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.operationProcedureICD)}>
								{this.props.opd.selectedORRequest.request.operativeICD}
							</Descriptions.Item>
							<Descriptions.Item span={3} label={translate(ResourceAssistance.Message.operationProcedure)}>
								{this.props.opd.selectedORRequest.request.operationProcedure}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.anesthesiologist)}>
								{this.props.opd.selectedORRequest.request.anesthesiologist}
							</Descriptions.Item>
							<Descriptions.Item span={1} label={translate(ResourceAssistance.Message.anesthesia)}>
								{this.props.opd.selectedORRequest.request.anesthesiaType}
							</Descriptions.Item>
							<Descriptions.Item span={2} label={translate(ResourceAssistance.Message.anesthesiologistFee)}>
								{Utils.formatNumWithComma(Utils.BigNumber(this.props.opd.selectedORRequest.request.anesthesiologistFee).toFixed(2))}
							</Descriptions.Item>
							<Descriptions.Item span={4} label={translate(ResourceAssistance.Message.estimatedLengthOfStay)}>
								{this.props.opd.selectedORRequest.request.lengthOfStay}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row>
					<Col>
						<Divider />
					</Col>
				</Row>
				<Row>
					<Col>
						<Descriptions
							style={{ flex: "unset", display: "unset" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap", alignItems: "center" }}
							column={1}
						>
							<Descriptions.Item label={translate(ResourceAssistance.Message.status)} span={1}>
								{this.getStatus()}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
								{this.props.opd.selectedORRequest.request.remark}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</>
		)
	}

	getStatus() {
		if (this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.cancelled) {
			return <CloseCircleFilled style={{ color: ResourceAssistance.CSS.Color.red, fontSize: "20px" }} />
		}
		if (this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.completed) {
			return <InfoCircleFilled style={{ color: ResourceAssistance.CSS.Color.warning, fontSize: "20px" }} />
		}
		if (
			this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.approved ||
			this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.reschedule
		) {
			return <ScheduleFilled style={{ color: ResourceAssistance.CSS.Color.info, fontSize: "20px" }} />
		}
		if (this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.confirmed) {
			return <CheckCircleFilled style={{ color: ResourceAssistance.CSS.Color.dark_green, fontSize: "20px" }} />
		}
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientModalORRequestInfoCriteria)
