import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class CLRightPatientInfoRow extends React.Component {
	componentDidUpdate(prevProps, prevState) {}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.patientInfo)}</legend>
						<Descriptions size={"default"} contentStyle={{ fontStyle: "italic" }} column={2}>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
								{this.props.lab.selectedPatient.patient
									? this.props.lab.selectedPatient.patient.title +
									  " " +
									  this.props.lab.selectedPatient.patient.firstName +
									  " " +
									  this.props.lab.selectedPatient.patient.lastName
									: " "}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.dateOfBirth)} span={1}>
								{this.props.lab.selectedPatient.patient ? Utils.formatDate(this.props.lab.selectedPatient.patient.dobtime) : " "}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLRightPatientInfoRow)
