export const SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY =
	"SET_REPORT_HOSPITAL_CASHIER_ADMISSION_FILTER_DISPLAY"
export const SET_REPORT_CASHIER_ADMISSION_ID = "SET_REPORT_CASHIER_ADMISSION_ID"
export const SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY =
	"SET_REPORT_HOSPITAL_CASHIER_USER_ROLE_FILTER_DISPLAY"
export const SET_REPORT_CASHIER_USER_START_DATE_TIME = "SET_REPORT_CASHIER_USER_START_DATE_TIME"
export const SET_REPORT_CASHIER_USER_END_DATE_TIME = "SET_REPORT_CASHIER_USER_END_DATE_TIME"
export const SET_REPORT_CASHIER_USER_ROLES = "SET_REPORT_CASHIER_USER_ROLES"
export const SET_REPORT_CASHIER_USER_USERS = "SET_REPORT_CASHIER_USER_USERS"
export const SET_REPORT_CASHIER_USER_SELECTED_ROLE = "SET_REPORT_CASHIER_USER_SELECTED_ROLE"
export const SET_REPORT_CASHIER_USER_SELECTED_USER = "SET_REPORT_CASHIER_USER_SELECTED_USER"
export const SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED = "SET_REPORT_CASHIER_USER_PATIENT_DISCHARGED_CHECKED"
export const SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY =
	"SET_REPORT_HOSPITAL_CASHIER_BILLING_TO_TODAY_FILTER_DISPLAY"
export const SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID = "SET_REPORT_CASHIER_BILLING_TO_TODAY_ADMISSION_ID"
