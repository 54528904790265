import { ResourceAssistance } from "~/i18n"
import { SET_ADM_ICD_ICDS, SET_ADM_LEVEL_LEVELS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setConfigICDICDs = (icds) => {
	return (dispatch, getState) => {
		let filtered = icds
			.filter((each) => {
				if (getState().admin.configICD.icd.displayInactive) {
					return (
						each.code.toLowerCase().includes(getState().admin.configICD.icd.searchCode.toLowerCase()) &&
						each.description.toLowerCase().includes(getState().admin.configICD.icd.searchName.toLowerCase())
					)
				} else {
					return (
						each.active &&
						each.code.toLowerCase().includes(getState().admin.configICD.icd.searchCode.toLowerCase()) &&
						each.description.toLowerCase().includes(getState().admin.configICD.icd.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.code, b.code))

		let colors = []
		if (getState().admin.configICD.icd.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.code, each.description, each.lastModifiedBy.displayName, Utils.formatDate(each.lastModifiedDateTime)]
		})
		dispatch({
			type: SET_ADM_ICD_ICDS,
			payload: {
				original: icds,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setConfigICDLevels = (levels) => {
	return (dispatch, getState) => {
		let filtered = levels
			.filter((each) => {
				if (getState().admin.configICD.level.displayInactive) {
					return each.displayName.toLowerCase().includes(getState().admin.configICD.level.searchName.toLowerCase())
				} else {
					return (
						each.active &&
						each.displayName.toLowerCase().includes(getState().admin.configICD.level.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.configICD.level.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [
				each.displayName,
				each.description,
				each.lastModifiedBy.displayName,
				Utils.formatDate(each.lastModifiedDateTime),
			]
		})
		dispatch({
			type: SET_ADM_LEVEL_LEVELS,
			payload: {
				original: levels,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}
