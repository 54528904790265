import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPaymentVouchers } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PV_NEW_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PVModalNewDetails from "./PVModalNewDetails"

class PVModalNew extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PV_NEW_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			!this.props.PV.modalNew.dueDateTime ||
			!this.props.PV.modalNew.selectedSupplier.supplier ||
			!this.props.PV.modalNew.selectedPayment.payment ||
			this.props.PV.modalNew.purchaseTable.original.length === 0 ||
			(this.props.PV.modalNew.purchaseTable.original.some((purchase) => Utils.BigNumber(purchase.withholdingTax).isGreaterThan(0)) &&
				!this.props.PV.modalNew.whtIssuedDateTime)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentVoucher.savePaymentVouchers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PV.location.id,
				userId: this.props.login.user.id,
				invoices: [
					{
						id: this.props.PV.selectedPV.PV ? this.props.PV.selectedPV.PV.id : "",
						payment: this.props.PV.modalNew.selectedPayment.payment.displayName,
						paymentTermDateTime: this.props.PV.modalNew.dueDateTime,
						supplierCode: this.props.PV.modalNew.selectedSupplier.supplier.code,
						supplierName: this.props.PV.modalNew.selectedSupplier.supplier.displayName,
						remark: Utils.trim(this.props.PV.modalNew.remark),
						withholdingTaxIssuedDateTime: this.props.PV.modalNew.whtIssuedDateTime,
						purchases: this.props.PV.modalNew.purchaseTable.original,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.invoices.length > 0) {
				let invoices = this.props.PV.paymentVoucherTable.original.filter((PV) => PV.id !== res.data.invoices[0].id)
				invoices.push(res.data.invoices[0])
				this.props.setPaymentVouchers(invoices)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsPayablePV)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PVModalNewDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPaymentVoucherNewDisplay,
	login: state.login,
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPaymentVouchers,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PVModalNew))
