import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class HospitelModalNurseRecordDate extends React.Component {
	constructor(props) {
		super(props)

		this.onRecordDateTime = this.onRecordDateTime.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME, this.props.hospitel.selectedNurseNote.nurseNote.dateTime)
		} else {
			this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME, moment().valueOf())
		}
	}

	onRecordDateTime(date) {
		this.props.setValue(SET_HOSPITEL_MODAL_NURSE_RECORD_DATETIME, date.valueOf())
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<AntDatePicker
						size={"large"}
						format={"DD-MM-YYYY  HH:mm"}
						showTime={{ format: "HH:mm" }}
						values={[moment(this.props.hospitel.modalNurseRecord.recordDateTime)]}
						onChange={this.onRecordDateTime}
					/>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseRecordDate))
