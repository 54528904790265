import { Divider } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { ImPrinter } from "react-icons/im"
import { RiUserAddFill, RiUserSearchFill, RiUserSettingsFill } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected } from "~/redux/action"
import { SET_HOSPITEL_IPD_REGISTER_DISPLAY, SET_HOSPITEL_IPD_SEARCH_DISPLAY, SET_HOSPITEL_IPD_SELECTED_PAITENT, SET_PAGE_LOADING } from "~/redux/type/"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import { Utils } from "~/utils/Utils"

class IPDLeftActionRow extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchPatient = this.onSearchPatient.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModifyPatient = this.onModifyPatient.bind(this)
		this.onPrintPatientInfo = this.onPrintPatientInfo.bind(this)
		this.onPrintFirstAssessment = this.onPrintFirstAssessment.bind(this)
		this.onLetterOfConsent = this.onLetterOfConsent.bind(this)
		this.onDepressionAssessment = this.onDepressionAssessment.bind(this)
		this.onPrintId = this.onPrintId.bind(this)
	}

	downloadFile(path, name, func) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.downloadFile,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				path: path,
				name: name,
			},
		}

		let callback = (res) => {
			func(res)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSearchPatient(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_IPD_SEARCH_DISPLAY, true)
	}

	onModifyPatient(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_IPD_REGISTER_DISPLAY, true)
	}

	onNew(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_IPD_REGISTER_DISPLAY, true)
		this.props.setSelected(SET_HOSPITEL_IPD_SELECTED_PAITENT, -1, null)
	}

	onPrintPatientInfo(event) {
		let patient = this.props.ipd.selectedPatient.patient
		let data = {
			titleIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.patientInfo }),
			patientNameIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.name }),
			identificationIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			dobIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			ageIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			yearIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.year }),
			monthIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.month }),
			genderIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.gender }),
			telIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.tel }),
			addressIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.address }),
			healthCareIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.healthCare }),
			healthCareNoteIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark }),
			heightIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.height }),
			weightIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.weight }),
			underlyingDiseaseIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.underlyingDisease }),
			allergiesIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.allergies }),
			pastIllnessesIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.pastIllnesses }),
			dateIntl: this.props.intl.formatMessage({ id: ResourceAssistance.Message.registrationDate }),
			branchName: "Mali Interdisciplinary Hospital",
			address: "771/1 Ekkachai 85 Alley, Ekkachai Rd., Bang Bon, Bang Bon, Bangkok, Bangkok, 10150",
			tel: "02-089-1111",

			patient: {
				registrationDate: Utils.formatDateTime(patient.creationDateTime),
				pid: patient.id,
				name: patient.title + " " + patient.firstName + " " + patient.lastName,
				identification: patient.identificationNumber,
				dob: Utils.formatDate(patient.dobtime),
				age: Utils.calculateAge(patient.dobtime),
				ageMonth: Utils.calculateAgeMonth(patient.dobtime),
				gender: patient.gender,
				tel: patient.tel,
				address: patient.address,
				healthCare: patient.healthCare,
				healthCareNote: patient.healthCareNote,
				height: patient.height,
				weight: patient.weight,
				underlyingDisease: patient.underlyingDisease,
				allergies: patient.allergies,
				pastIllnesses: patient.pastIllnesses,
			},
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.ipd.patient, "_blank")
		myWindow.data = data
	}

	onPrintFirstAssessment() {
		let patient = {
			...this.props.ipd.selectedPatient.patient,
			clinicalRecord: this.props.ipd.selectedPatient.patient.admission.clinicalRecord,
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.firstAssessment, "_blank")
		myWindow.data = PrintableDataFactory.generateFirstAssessmentReportData(patient)
	}

	onLetterOfConsent() {
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.letterOfConsent, "_blank")
		myWindow.data = PrintableDataFactory.generateLetterOfConsentReportData(this.props.ipd.selectedPatient.patient)
	}

	onDepressionAssessment() {
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.depressionAssessment, "_blank")
		myWindow.data = PrintableDataFactory.generateDepressionAssessmentReportData(this.props.ipd.selectedPatient.patient)
	}

	onPrintId() {
		this.downloadFile(this.props.ipd.selectedPatient.patient.idFilePath, this.props.ipd.selectedPatient.patient.idFileName, (res) => {
			let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
			myWindow.data = PrintableDataFactory.generatePdfFileData(res.data)
		})
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<DropdownToggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={this.props.ipd.selectedPatient.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</DropdownToggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem
								disabled={this.props.ipd.selectedPatient.index === -1 || _.isEmpty(this.props.ipd.selectedPatient.patient.idFileName)}
								onClick={this.onPrintId}
							>
								{translate(ResourceAssistance.Message.id)}
							</DropdownItem>
							<DropdownItem onClick={this.onPrintPatientInfo}>{translate(ResourceAssistance.Hospitel.patientInfo)}</DropdownItem>
							<DropdownItem onClick={this.onDepressionAssessment}>{translate(ResourceAssistance.Hospitel.depressionAssessment)}</DropdownItem>
							<DropdownItem onClick={this.onLetterOfConsent}>{translate(ResourceAssistance.Hospitel.letterOfConsent)}</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={
									this.props.ipd.selectedPatient.index === -1 ||
									!this.props.ipd.selectedPatient.patient.admission ||
									!this.props.ipd.selectedPatient.patient.admission.clinicalRecord
								}
								onClick={this.onPrintFirstAssessment}
							>
								{translate(ResourceAssistance.Hospitel.firstAssessment)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearchPatient}>
						<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>

				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.CRUD, this.props.login.user.roles) && (
					<Fragment>
						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={this.props.ipd.selectedPatient.index === -1}
								onClick={this.onModifyPatient}
							>
								<RiUserSettingsFill size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.edit)}
							</Button>
						</Col>
						<Col md="auto">
							<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
								<RiUserAddFill size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						</Col>
					</Fragment>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDLeftActionRow))
