import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont, SvgCashier, SvgDeposit, SvgInpatient, SvgLab, SvgMedical, SvgOutpatient, SvgPatient } from "~/icons"
import SvgSvgMedication from "~/icons/SvgMedication"
import imgOperatingRoom from "~/img/operating_room.png"
import { Utils } from "~/utils/Utils"
import { EType } from "../Home"

class Hospital extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"g-0"} style={{ justifyContent: "center" }}>
					<Col md="auto">
						<SvgMedical width={32} height={32} />
					</Col>
					<Col md="auto">
						<h2 style={{ marginBottom: "0.5rem" }}>{translate(ResourceAssistance.Hospitel.hospital)}</h2>
					</Col>
				</Row>
				<Row style={{ justifyContent: "center" }}>
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Deposit.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.DEPOSIT} onClick={this.props.onBtnClick}>
								<SvgDeposit width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.deposit)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Cashier.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.CASHIER} onClick={this.props.onBtnClick}>
								<SvgCashier width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Hospitel.cashier)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.IPD} onClick={this.props.onBtnClick}>
								<SvgInpatient width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Hospitel.ipd)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.OPD} onClick={this.props.onBtnClick}>
								<SvgOutpatient width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.opd)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.REGISTRATION} onClick={this.props.onBtnClick}>
								<SvgPatient width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Hospitel.registration)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Lab.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.COVID19} onClick={this.props.onBtnClick}>
								<SvgLab width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Hospitel.lab)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Pharmacy.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.PHARMACY} onClick={this.props.onBtnClick}>
								<SvgSvgMedication width={ResourceAssistance.ReactIcon.size_md} height={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.pharmacy)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Front_Inventory.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.FRONT_INVENTORY} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-kucuntongji"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.inventory)}
							</Button>
						</Col>
					)}
					{/* {Utils.hasPrivilege(
									ResourceAssistance.Privilege.Hospital.Cashier.View,
									this.props.login.user.roles
								) && (
									<Col sm="auto">
										<Button
											variant={ResourceAssistance.Button.variant.link}
											value={EType.HOSPITAL.DEBT}
											onClick={this.props.onBtnClick}
										>
											<FcDebt size={ResourceAssistance.ReactIcon.size_md} />
											{translate(ResourceAssistance.Message.debt)}
										</Button>
									</Col>
								)} */}
					{/* {Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Lab.View, this.props.login.user.roles) && (
								<Col sm="auto">
									<Button
										variant={ResourceAssistance.Button.variant.link}
										value={EType.HOSPITAL.RADIOLOGY}
										onClick={this.props.onBtnClick}
									>
										<FcBiohazard size={ResourceAssistance.ReactIcon.size_md} />
										{translate(ResourceAssistance.Message.radiology)}
									</Button>
								</Col>
								)} */}
				</Row>
				<Row style={{ justifyContent: "center" }}>
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Operating_Room.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.SURGICAL_AUTHORIZATION} onClick={this.props.onBtnClick}>
								<img
									src={imgOperatingRoom}
									alt="surgical-authorization"
									style={{ width: ResourceAssistance.ReactIcon.size_md, height: ResourceAssistance.ReactIcon.size_md }}
								/>
								{translate(ResourceAssistance.Message.surgicalAuthorization)}
							</Button>
						</Col>
					)}
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Operating_Room.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.HOSPITAL.OPERATING_ROOM} onClick={this.props.onBtnClick}>
								<img
									src={imgOperatingRoom}
									alt="operating-room"
									style={{ width: ResourceAssistance.ReactIcon.size_md, height: ResourceAssistance.ReactIcon.size_md }}
								/>
								{translate(ResourceAssistance.Message.operatingRoom)}
							</Button>
						</Col>
					)}
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default injectIntl(connect(mapStateToProps, {})(Hospital))
