import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setAccountsPayableItems, setSelected } from "~/redux/action"
import { SET_AP_SELECTED_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsPayableRightItem extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.AP.selectedGroup, this.props.AP.selectedGroup)) {
			if (this.props.AP.selectedGroup.group) {
				this.props.setAccountsPayableItems(this.props.AP.selectedGroup.group[1])
			} else {
				this.props.setAccountsPayableItems([])
			}
		}
		if (!_.isEqual(prevProps.AP.itemTable.filtered, this.props.AP.itemTable.filtered)) {
			let index = this.props.AP.selectedItem.item
				? this.props.AP.itemTable.filtered.findIndex((item) => item.id === this.props.AP.selectedItem.item.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AP_SELECTED_ITEM, index, this.props.AP.itemTable.filtered[index])
			} else {
				this.props.setSelected(SET_AP_SELECTED_ITEM, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_AP_SELECTED_ITEM, -1, null)
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_AP_SELECTED_ITEM, index, this.props.AP.itemTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						data={this.props.AP.itemTable}
						isClearHighlight={this.props.AP.selectedItem.index === -1}
						highlightedRow={this.props.AP.selectedItem.index}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	AP: state.accounting.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAccountsPayableItems,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsPayableRightItem))
