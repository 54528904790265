import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { IconFont } from "~/icons"
import { onModalDisplayAction, setFrontInventoryInventories, setSelected, setValue } from "~/redux/action"
import {
	SET_FRONT_INVENTORY_EDIT_DISPLAY,
	SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS,
	SET_FRONT_INVENTORY_SELECTED_INVENTORY,
	SET_FRONT_INVENTORY_SPLIT_DISPLAY,
	SET_FRONT_INVENTORY_WASTE_DISPLAY,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FrontInventoryItem extends React.Component {
	constructor(props) {
		super(props)

		this.selectRow = this.selectRow.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onSplit = this.onSplit.bind(this)
		this.onWaste = this.onWaste.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.frontInventory.inventoryTable.filtered, this.props.frontInventory.inventoryTable.filtered)) {
			let index = this.props.frontInventory.selectedInventory.inventory
				? this.props.frontInventory.inventoryTable.filtered.findIndex((inventory) => inventory.id === this.props.frontInventory.selectedInventory.inventory.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_INVENTORY, index, this.props.frontInventory.inventoryTable.filtered[index])
			} else {
				this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_INVENTORY, -1, null)
			}
		}

		if (
			!_.isEqual(prevProps.frontInventory.selectedType, this.props.frontInventory.selectedType) ||
			!_.isEqual(prevProps.frontInventory.searchCode, this.props.frontInventory.searchCode) ||
			!_.isEqual(prevProps.frontInventory.searchGenericName, this.props.frontInventory.searchGenericName) ||
			!_.isEqual(prevProps.frontInventory.lotExpiredDateTime, this.props.frontInventory.lotExpiredDateTime) ||
			!_.isEqual(prevProps.frontInventory.isDisplayExpired, this.props.frontInventory.isDisplayExpired) ||
			!_.isEqual(prevProps.frontInventory.isDisplayMinStock, this.props.frontInventory.isDisplayMinStock)
		) {
			this.props.setFrontInventoryInventories(this.props.frontInventory.inventoryTable.original)
		}

		if (!_.isEqual(prevProps.frontInventory.selectedDepartment, this.props.frontInventory.selectedDepartment)) {
			if (this.props.frontInventory.selectedDepartment.department) {
				this.props.getPharmacyInventories(this.props.frontInventory.selectedDepartment.department.id)
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_INVENTORY, index, this.props.frontInventory.inventoryTable.filtered[index])
	}

	onEdit(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_EDIT_DISPLAY, true)
	}

	onSplit() {
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_SPLIT_DISPLAY, true)
	}

	onWaste(event, row, rIdx, cIdx) {
		let selectedInventory = this.props.frontInventory.inventoryTable.filtered[rIdx]
		this.props.setValue(SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS, selectedInventory.transferOrderItems)
		this.props.onModalDisplayAction(SET_FRONT_INVENTORY_WASTE_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlinePrimary,
							ResourceAssistance.Button.variant.outlineRed,
						]}
						btnIcons={[
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />,
							<IconFont type={"icon-ali-pmgcare-navicon-jldwsz"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
							<IconFont type={"icon-ali-pmgcare-huishouzhan"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />,
						]}
						onCheckedArray={[this.onEdit, this.onSplit, this.onWaste]}
						data={this.props.frontInventory.inventoryTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.frontInventory.selectedInventory.index === -1}
						highlightedRow={this.props.frontInventory.selectedInventory.index}
					/>
					{this.props.frontInventory.inventoryTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setFrontInventoryInventories,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FrontInventoryItem))
