import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setTfModalRequestSearchText, setTfModalRequestSelectedType } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class TFModalNRSearchRow extends React.Component {
	constructor(props) {
		super(props)
		this.onChangeType = this.onChangeType.bind(this)
		this.onSearchChange = this.onSearchChange.bind(this)
	}

	componentDidMount() {
		if (this.props.transfer.types.length > 0) {
			let type = document.getElementById(ResourceAssistance.ID.TF.modalRequest.type)
			this.props.setTfModalRequestSelectedType(type.selectedIndex, this.props.transfer.types[type.selectedIndex])
		}
	}

	componentWillUnmount() {
		//reset
		this.props.setTfModalRequestSelectedType(-1, null)
		this.props.setTfModalRequestSearchText("")
	}

	componentDidUpdate(prevProps, prevState) {}

	onChangeType(event) {
		this.props.setTfModalRequestSelectedType(event.target.value, this.props.transfer.types[event.target.value])
	}

	onSearchChange(event) {
		this.props.setTfModalRequestSearchText(event.target.value)
	}

	render() {
		return (
			<Row>
				<Col md="auto">
					<FormControl
						id={ResourceAssistance.ID.TF.modalRequest.type}
						size={ResourceAssistance.FormControl.size.sm}
						as={ResourceAssistance.FormControl.as.select}
						onChange={this.onChangeType}
					>
						{Utils.renderOptions(this.props.transfer.types, false)}
					</FormControl>
				</Col>
				<Col>
					<SearchBox num={1} placeholders={[ResourceAssistance.Message.name]} callbacks={[this.onSearchChange]} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isTFNewRequestDisplay,
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setTfModalRequestSelectedType, setTfModalRequestSearchText }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFModalNRSearchRow)
