export const SET_PGL_CHART_OF_ACCOUNTS = "SET_PGL_CHART_OF_ACCOUNTS"
export const SET_PGL_CHART_OF_ACCOUNTS_LOADING = "SET_PGL_CHART_OF_ACCOUNTS_LOADING"
export const SET_PGL_END_DATE_TIME = "SET_PGL_END_DATE_TIME"
export const SET_PGL_ENTRIES = "SET_PGL_ENTRIES"
export const SET_PGL_ENTRY_DISPLAY = "SET_PGL_ENTRY_DISPLAY"
export const SET_PGL_FILTERS = "SET_PGL_FILTERS"
export const SET_PGL_FILTER_POSTING_GL_ID = "SET_PGL_FILTER_POSTING_GL_ID"
export const SET_PGL_GL_DATE_TIME = "SET_PGL_GL_DATE_TIME"
export const SET_PGL_GL_DOCUMENTS = "SET_PGL_GL_DOCUMENTS"
export const SET_PGL_GL_DOCUMENTS_LOADING = "SET_PGL_GL_DOCUMENTS_LOADING"
export const SET_PGL_LOCATION = "SET_PGL_LOCATION"
export const SET_PGL_LOCATION_DISPLAY = "SET_PGL_LOCATION_DISPLAY"
export const SET_PGL_MODAL_ENTRY_AMOUNT = "SET_PGL_MODAL_ENTRY_AMOUNT"
export const SET_PGL_MODAL_ENTRY_DEBIT_IND = "SET_PGL_MODAL_ENTRY_DEBIT_IND"
export const SET_PGL_MODAL_ENTRY_DESCRIPTION = "SET_PGL_MODAL_ENTRY_DESCRIPTION"
export const SET_PGL_MODAL_ENTRY_SELECTED_GL = "SET_PGL_MODAL_ENTRY_SELECTED_GL"
export const SET_PGL_MODAL_LOCATION_ORGS = "SET_PGL_MODAL_LOCATION_ORGS"
export const SET_PGL_MODAL_LOCATION_ORGS_LOADING = "SET_PGL_MODAL_LOCATION_ORGS_LOADING"
export const SET_PGL_MODAL_LOCATION_SELECTED_ORG = "SET_PGL_MODAL_LOCATION_SELECTED_ORG"
export const SET_PGL_MODAL_NEW_ENTRIES = "SET_PGL_MODAL_NEW_ENTRIES"
export const SET_PGL_MODAL_NEW_REMARK = "SET_PGL_MODAL_NEW_REMARK"
export const SET_PGL_MODAL_NEW_SELECTED_ENTRY = "SET_PGL_MODAL_NEW_SELECTED_ENTRY"
export const SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT = "SET_PGL_MODAL_NEW_SELECTED_GL_DOCUMENT"
export const SET_PGL_NEW_DISPLAY = "SET_PGL_NEW_DISPLAY"
export const SET_PGL_REQUESTS = "SET_PGL_REQUESTS"
export const SET_PGL_SEARCH_DISPLAY = "SET_PGL_SEARCH_DISPLAY"
export const SET_PGL_SELECTED_ENTRY = "SET_PGL_SELECTED_ENTRY"
export const SET_PGL_SELECTED_PGL = "SET_PGL_SELECTED_PGL"
export const SET_PGL_START_DATE_TIME = "SET_PGL_START_DATE_TIME"
export const SET_PGL_MODAL_SEARCH_POSTING_GL_ID = "SET_PGL_MODAL_SEARCH_POSTING_GL_ID"
export const SET_PGL_MODAL_SEARCH_INCOMPLETE_IND = "SET_PGL_MODAL_SEARCH_INCOMPLETE_IND"
