import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Container } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import PainScaleDate from "./PainScaleDate"
import PainScaleSlider from "./PainScaleSlider"

class PainScale extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			datetime: "",
			score: 0,
		}
		this.onDateChange = this.onDateChange.bind(this)
		this.onPainScaleChange = this.onPainScaleChange.bind(this)
	}

	componentDidMount() {
		if (this.props.painScale) {
			this.setState({
				datetime: this.props.painScale.datetime,
				score: this.props.painScale.score,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	onDateChange(dateObj) {
		this.setState({
			datetime: dateObj ? dateObj.valueOf() : "",
		})
	}

	onPainScaleChange(value) {
		this.setState({
			score: value,
		})
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<PainScaleDate {...this.props} {...this.state} onDateChange={this.onDateChange} />
				<PainScaleSlider {...this.props} {...this.state} onPainScaleChange={this.onPainScaleChange} />
			</Container>
		)
	}
}

PainScale.propTypes = {
	painScale: propTypes.object,
}

PainScale.defaultProps = {
	painScale: null,
}

export default PainScale
