import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_AR_BILLING_STATEMENT,
	SET_AR_BILLING_STATEMENT_GL_GROUP,
	SET_AR_BILLING_STATEMENT_GROUP,
	SET_AR_END_DATE_TIME,
	SET_AR_FILTER,
	SET_AR_FILTER_INVOICE_ID,
	SET_AR_INVOICES,
	SET_AR_LOCATION,
	SET_AR_MODAL_ADJUSTMENT_CONTINUE,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT_PERCENT,
	SET_AR_MODAL_ADJUSTMENT_DISCOUNT_REMARK,
	SET_AR_MODAL_ADJUSTMENT_ID,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_MAX,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT,
	SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK,
	SET_AR_HEALTH_CARE_PLANS,
	SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN,
	SET_AR_CHART_OF_ACCOUNTS,
	SET_AR_MODAL_LOCATION_ORGS,
	SET_AR_MODAL_LOCATION_SELECTED_ORG,
	SET_AR_SELECTED_BILLING_STATEMENT,
	SET_AR_SELECTED_GROUP,
	SET_AR_SELECTED_INVOICE,
	SET_AR_START_DATE_TIME,
	SET_AR_MODAL_GL_SELECTED_GL,
	SET_AR_ACTIVITIES,
	SET_AR_MODAL_GL_DESCRIPTION,
	SET_AR_GL_DATE_TIME,
	SET_AR_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	generalLedgerDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterInvoiceId: "",
	filters: [],
	chartOfAccounts: [],
	accountsReceivableActivities: [],
	healthcarePlans: [],
	selectedInvoice: {
		index: -1,
		invoice: null,
	},
	selectedGroup: {
		index: -1,
		group: null,
	},
	selectedBS: {
		index: -1,
		billingStatement: null,
	},
	invoiceTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	billingStatementGroupTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.totalPriceDiscounted,
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	billingStatementGLGroupTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.category,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.debit,
			ResourceAssistance.Message.credit,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	billingStatementTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.remark,
			ResourceAssistance.Message.discountBill,
			ResourceAssistance.Message.totalPriceDiscounted,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalAdjustment: {
		id: "",
		isContinue: false,
		discount: 0,
		discountPercent: 0,
		discountMax: 0,
		remark: "",
	},
	modalEditInvoice: {
		discount: 0,
		discountPercent: 0,
		discountMax: 0,
		remark: "",
		selectedHealthCarePlan: {
			index: -1,
			healthcarePlan: null,
		},
	},
	modalGeneralLedger: {
		description: "",
		selectedGL: {
			index: -1,
			GL: null,
		},
	},
}

const accountsReceivableReducer = (state = init, action) => {
	switch (action.type) {
		case SET_AR_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_AR_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_AR_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_AR_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_AR_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_AR_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_AR_GL_DATE_TIME:
			return Object.assign({}, state, {
				generalLedgerDateTime: action.payload.value,
			})
		case SET_AR_FILTER:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_AR_FILTER_INVOICE_ID:
			return Object.assign({}, state, {
				filterInvoiceId: action.payload.value,
			})
		case SET_AR_INVOICES: {
			return Object.assign({}, state, {
				invoiceTable: {
					...state.invoiceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		}
		case SET_AR_SELECTED_INVOICE:
			return Object.assign({}, state, {
				selectedInvoice: {
					index: action.payload.index,
					invoice: action.payload.selected,
				},
			})
		case SET_AR_BILLING_STATEMENT_GROUP:
			return Object.assign({}, state, {
				billingStatementGroupTable: {
					...state.billingStatementGroupTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AR_BILLING_STATEMENT_GL_GROUP:
			return Object.assign({}, state, {
				billingStatementGLGroupTable: {
					...state.billingStatementGLGroupTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AR_SELECTED_GROUP:
			return Object.assign({}, state, {
				selectedGroup: {
					index: action.payload.index,
					group: action.payload.selected,
				},
			})
		case SET_AR_BILLING_STATEMENT:
			return Object.assign({}, state, {
				billingStatementTable: {
					...state.billingStatementTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_AR_SELECTED_BILLING_STATEMENT:
			return Object.assign({}, state, {
				selectedBS: {
					index: action.payload.index,
					billingStatement: action.payload.selected,
				},
			})
		case SET_AR_MODAL_ADJUSTMENT_ID:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					id: action.payload.value,
				},
			})
		case SET_AR_MODAL_ADJUSTMENT_CONTINUE:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isContinue: action.payload.value,
				},
			})
		case SET_AR_MODAL_ADJUSTMENT_DISCOUNT:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discount: action.payload.value,
				},
			})
		case SET_AR_MODAL_ADJUSTMENT_DISCOUNT_PERCENT:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discountPercent: action.payload.value,
				},
			})
		case SET_AR_MODAL_ADJUSTMENT_DISCOUNT_MAX:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discountMax: action.payload.value,
				},
			})
		case SET_AR_MODAL_ADJUSTMENT_DISCOUNT_REMARK:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					remark: action.payload.value,
				},
			})
		case SET_AR_HEALTH_CARE_PLANS:
			return Object.assign({}, state, {
				healthcarePlans: action.payload.value,
			})
		case SET_AR_MODAL_EDIT_INVOICE_SELECTED_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					selectedHealthCarePlan: {
						index: action.payload.index,
						healthcarePlan: action.payload.selected,
					},
				},
			})
		case SET_AR_MODAL_EDIT_INVOICE_DISCOUNT:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					discount: action.payload.value,
				},
			})
		case SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_PERCENT:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					discountPercent: action.payload.value,
				},
			})
		case SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_MAX:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					discountMax: action.payload.value,
				},
			})
		case SET_AR_MODAL_EDIT_INVOICE_DISCOUNT_REMARK:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					remark: action.payload.value,
				},
			})
		case SET_AR_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_AR_MODAL_GL_SELECTED_GL:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					selectedGL: {
						index: action.payload.index,
						GL: action.payload.selected,
					},
				},
			})
		case SET_AR_MODAL_GL_DESCRIPTION:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					description: action.payload.value,
				},
			})
		case SET_AR_ACTIVITIES:
			return Object.assign({}, state, {
				accountsReceivableActivities: action.payload.value,
			})
		default:
			return state
	}
}

export default accountsReceivableReducer
