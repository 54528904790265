import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setOPDGlucoses } from "~/redux/action"
import OutpatientRightDTXTable from "./OutpatientRightDTXTable"
import OutpatientRightDTXChart from "./OutpatientRightDTXChart"

class OutpatientRightDTX extends React.Component {
	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDGlucoses(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.glucoses)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDGlucoses(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.glucoses)
			} else {
				this.props.setOPDGlucoses([])
			}
		}
	}

	render() {
		return this.props.graphic ? <OutpatientRightDTXChart /> : <OutpatientRightDTXTable />
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setOPDGlucoses,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightDTX))
