import * as React from "react";

function SvgSvgCashier(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M79 39a4 4 0 00-4-4H35a4 4 0 00-4 4v12h48V39z" fill="#bbe7f2" />
      <path
        d="M75 35H35a4 4 0 00-4 4v12h29.744L76.46 35.284A3.983 3.983 0 0075 35z"
        fill="#e1f5fa"
      />
      <path fill="#0c333d" d="M23 75h64v12H23z" />
      <path fill="#e1f5fa" d="M31 51h48l8 20H23z" />
      <path
        fill="#fff"
        d="M31 51l-8 20h17.744l20-20zM35 43V27l4 4 4-4 4 4 4-4v16z"
      />
      <path fill="#bbe7f2" d="M23 71h64v4H23z" />
      <path fill="#009fc7" d="M55 39h20v8H55z" />
      <path fill="#00b7e5" d="M72.744 39H55v8h9.744z" />
      <path fill="#468595" d="M69 32h4v3h-4z" />
      <path
        d="M83 24a1 1 0 00-1-1H60a1 1 0 00-1 1v7a1 1 0 001 1h22a1 1 0 001-1v-7z"
        fill="#0c333d"
      />
      <path fill="#81d2eb" d="M34 43h18v2H34z" />
      <path
        d="M47 35.5c0 .275-.225.5-.5.5h-7a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5h7c.275 0 .5.225.5.5zM47 39.5c0 .275-.225.5-.5.5h-7a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5h7c.275 0 .5.225.5.5z"
        fill="#bbe7f2"
      />
      <circle cx={55} cy={81} r={2} fill="#53bad4" />
      <circle cx={35} cy={57} r={2} fill="#81d2eb" />
      <circle cx={43} cy={57} r={2} fill="#81d2eb" />
      <circle cx={51} cy={57} r={2} fill="#81d2eb" />
      <circle cx={33} cy={65} r={2} fill="#81d2eb" />
      <circle cx={41} cy={65} r={2} fill="#81d2eb" />
      <circle cx={49} cy={65} r={2} fill="#81d2eb" />
      <circle cx={61} cy={65} r={2} fill="#81d2eb" />
      <circle cx={69} cy={65} r={2} fill="#81d2eb" />
      <circle cx={77} cy={65} r={2} fill="#81d2eb" />
      <circle cx={59} cy={57} r={2} fill="#81d2eb" />
      <circle cx={67} cy={57} r={2} fill="#81d2eb" />
      <circle cx={75} cy={57} r={2} fill="#81d2eb" />
      <g>
        <path
          d="M74.272 23H60a1 1 0 00-1 1v7a1 1 0 001 1h5.272l9-9z"
          fill="#0f6175"
        />
      </g>
    </svg>
  );
}

export default SvgSvgCashier;
