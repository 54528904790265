import * as React from "react"

function SvgSvgDoctorNote(props) {
	return (
		<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8 58c-.827 0-.5.327-.5-.5V11c0-.827.673-1.5 1.5-1.5h36c.827 0 1.5.673 1.5 1.5v46.5c0 .827-.673.5-1.5.5H8z"
				fill="#18FFFF"
			/>
			<path fill="#FFF" d="M21 55L11 45V13h32v42z" />
			<path fill="#ECEFF1" d="M32 56H21V45z" />
			<path fill="#84FFFF" d="M11.207 45.707H20.5V55z" />
			<path
				d="M38.025 12.658C37.819 12.041 36.873 11.5 36 11.5h-4.5v-2h-.862l-.149-.174a8.06 8.06 0 00-.65-.664C29.394 8.247 29.042 7.918 29 7c0-1.103-.897-2-2-2s-2 .897-2.005 1.93l.009.061-.003.052c-.043.875-.395 1.203-.839 1.619a7.902 7.902 0 00-.65.664l-.15.174H22.5v2H18c-.873 0-1.819.541-2.025 1.158-.161.483-.308 1.296-.395 1.842h22.811l-.366-1.842zM27 9a2 2 0 110-4 2 2 0 010 4z"
				fill="#ECEFF1"
			/>
			<path
				d="M22 9v2h-4c-.869 0-1.741.419-2.211 1H10v33l11 11h23V12h-5.789c-.47-.581-1.342-1-2.211-1h-4V9h-1.133c-.855-.993-1.32-1.002-1.369-1.981L29.5 7c0-1.378-1.121-2.5-2.5-2.5a2.5 2.5 0 00-2.458 2.083A2.607 2.607 0 0024.5 7l.002.019c-.049.979-.514.988-1.369 1.981H22zm-2 44.586L12.414 46H20v7.586zM38.6 13H43v42H21V45H11V13h4.362c-.209.864-.362 2-.362 2h24l-.4-2zM25.502 6.985c0-.058.01-.114.017-.17.007-.047.009-.094.02-.14h-.001A1.502 1.502 0 0127 5.5c.813 0 1.472.649 1.496 1.456l-.002.008.003.064A1.5 1.5 0 0127 8.5a1.5 1.5 0 01-1.498-1.481v-.034zM23 10h.591l.3-.347c.232-.27.435-.459.612-.625a4.35 4.35 0 00.507-.531A2.491 2.491 0 0027 9.5c.814 0 1.533-.397 1.989-1.002.155.201.333.366.508.53.178.167.38.355.612.625l.3.347H31v2h5c.737 0 1.388.452 1.535.776L37.78 14H16.173c.08-.437.178-.889.275-1.184.109-.324.783-.816 1.552-.816h5v-2z"
				fill="#37474F"
			/>
			<path
				d="M45 9H33v1h12c.552 0 1 .449 1 1v46c0 .551-.448 1-1 1H9c-.552 0-1-.449-1-1V11c0-.551.448-1 1-1h12V9H9c-1.1 0-2 .9-2 2v46c0 1.1.9 2 2 2h36c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2z"
				fill="#37474F"
			/>
			<g>
				<path d="M55.103 9.367L49 9v41h6V12h1v17l1 1V12c0-1.104-.85-2.283-1.897-2.633z" fill="#37474F" />
				<path fill="#FFF" d="M54.875 10.222l-5.75-1.284v40.125h5.75z" />
				<path fill="#FF5252" d="M51 10v40h3.563l.089-39.184z" />
				<path fill="#37474F" d="M49 28h6v1h-6z" />
				<path d="M50 9.248l4 .894V49h-4V9.248zM49 8v42h6V9.34L49 8z" fill="#37474F" />
				<path fill="#F0F2F4" d="M49.5 49.919V49.5h5v.419l-2.5 7.5z" />
				<path d="M53.945 50L52 55.838 50.055 50h3.89zM55 49h-6v1l3 9 3-9v-1z" fill="#37474F" />
			</g>
			<g>
				<circle cx={34.5} cy={21.5} fill="#FF8A80" r={4} />
				<path
					d="M34.5 26c-2.481 0-4.5-2.019-4.5-4.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-8c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5z"
					fill="#37474F"
				/>
				<path fill="#37474F" d="M34 19h1v5h-1z" />
				<g>
					<path fill="#37474F" d="M32 21h5v1h-5z" />
				</g>
			</g>
			<g>
				<path fill="#37474F" d="M23 31h17v1H23zM23 28h17v1H23zM20 38.477V43h-6v-6h3.709l.572-1H13v8h8v-7.27z" />
				<path fill="#FF5722" d="M17.033 41.951l-2.449-3.674.832-.554 1.551 2.326 3.599-6.297.868.496z" />
				<path fill="#37474F" d="M20 26.477V31h-6v-6h3.709l.572-1H13v8h8v-7.27z" />
				<path fill="#FF5722" d="M17.033 29.951l-2.449-3.674.832-.554 1.551 2.326 3.599-6.297.868.496z" />
			</g>
			<g>
				<path fill="#37474F" d="M23 43h17v1H23zM23 40h17v1H23z" />
			</g>
		</svg>
	)
}

export default SvgSvgDoctorNote
