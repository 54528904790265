import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

class RadiologyLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RadiologyLeft",
		}
	}

	render() {
		return <Container fluid id={this.state.componentName} className={"full-size full-flex border"}></Container>
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RadiologyLeft)
