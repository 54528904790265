import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDCertificates, setOPDPatients, setSelected, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_OPD_MEDICAL_CERTIFICATE_DISPLAY, SET_OPD_SELECTED_MEDICAL_CERTIFICATE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightMedicalCertificate extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			let admission = this.props.opd.selectedPatient.patient.admissions.find(
				(admission) => admission.id === this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id
			)
			this.props.setOPDCertificates(admission.certificates)
		} else {
			this.props.setOPDCertificates([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteCertificate)) {
				this.deleteCertificate()
			}
		}
		if (
			!_.isEqual(prevProps.opd.selectedPatient.patient, this.props.opd.selectedPatient.patient) ||
			!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)
		) {
			if (this.props.opd.selectedPatient.patient && this.props.opd.selectedMedicalRecord.medicalRecord) {
				let admission = this.props.opd.selectedPatient.patient.admissions.find(
					(admission) => admission.id === this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id
				)
				if (admission) {
					this.props.setOPDCertificates(admission.certificates)
				} else {
					this.props.setOPDCertificates([])
				}
			} else {
				this.props.setOPDCertificates([])
			}
		}
		if (!_.isEqual(prevProps.opd.certificateTable.filtered, this.props.opd.certificateTable.filtered) && this.props.opd.selectedCertificate.certificate) {
			let index = this.props.opd.certificateTable.filtered.findIndex((certificate) => certificate.id === this.props.opd.selectedCertificate.certificate.id)
			if (index > -1) {
				this.props.setSelected(SET_OPD_SELECTED_MEDICAL_CERTIFICATE, index, this.props.opd.certificateTable.filtered[index])
			} else {
				this.props.setSelected(SET_OPD_SELECTED_MEDICAL_CERTIFICATE, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_SELECTED_MEDICAL_CERTIFICATE, -1, null)
	}

	deleteCertificate() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteMedicalCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				medicalCertificates: [
					{
						id: this.props.opd.selectedCertificate.certificate.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_OPD_SELECTED_MEDICAL_CERTIFICATE, index, this.props.opd.certificateTable.filtered[index])
	}

	onNew() {
		this.props.onModalDisplayAction(SET_OPD_MEDICAL_CERTIFICATE_DISPLAY, true)
		this.props.setSelected(SET_OPD_SELECTED_MEDICAL_CERTIFICATE, -1, null)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_OPD_MEDICAL_CERTIFICATE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedCertificate = this.props.opd.certificateTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteCertificate)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
            ${this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.medicalCertificate })}
            ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(selectedCertificate.creationDateTime)}
            `
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.opd.certificateTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.opd.selectedCertificate.index === -1}
						highlightedRow={this.props.opd.selectedCertificate.index}
						extra={
							this.props.opd.selectedMedicalRecord.medicalRecord &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
							this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0 && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDCertificates,
			setOPDPatients,
			setSelected,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightMedicalCertificate))
