import React from "react"
import { Col, FormControl, InputGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setBoolean, setObjArray, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY,
	SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE,
	SET_HOSPITEL_LAB_MODAL_LAB_SERVICES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CLModalNewLabType extends React.Component {
	constructor(props) {
		super(props)

		this.onService = this.onService.bind(this)
		this.onCategory = this.onCategory.bind(this)
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.lab.modalLab.selectedCategory.index !== this.props.lab.modalLab.selectedCategory.index) {
			let serviceDOM = document.getElementById(ResourceAssistance.ID.HOSPITEL.lab.modalLab.service)
			serviceDOM.selectedIndex = 0
			this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE, -1, null)
			if (this.props.lab.modalLab.selectedCategory.category) {
				this.props.setObjArray(
					SET_HOSPITEL_LAB_MODAL_LAB_SERVICES,
					this.props.lab.modalLab.selectedCategory.category.services
				)
			} else {
				this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_SERVICES, [])
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_SERVICES, [])
		this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY, -1, null)
		this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE, -1, null)
	}

	onCategory(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY,
				event.target.value,
				this.props.lab.modalLab.categories[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_CATEGORY, -1, null)
		}
	}

	onService(event) {
		if (event.target.value) {
			this.props.setSelected(
				SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE,
				event.target.value,
				this.props.lab.modalLab.services[event.target.value]
			)
		} else {
			this.props.setSelected(SET_HOSPITEL_LAB_MODAL_LAB_SELECTED_SERVICE, -1, null)
		}
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.service)}</legend>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Message.category)}</InputGroup.Text>
							<FormControl required as={ResourceAssistance.FormControl.as.select} onChange={this.onCategory}>
								{Utils.renderOptions(this.props.lab.modalLab.categories)}
							</FormControl>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Text>{translate(ResourceAssistance.Hospitel.service)}</InputGroup.Text>
							<FormControl
								required
								id={ResourceAssistance.ID.HOSPITEL.lab.modalLab.service}
								as={ResourceAssistance.FormControl.as.select}
								onChange={this.onService}
							>
								{Utils.renderOptions(this.props.lab.modalLab.services, true, "", "name")}
							</FormControl>
						</InputGroup>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setBoolean,
			setSelected,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalNewLabType)
