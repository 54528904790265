import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PHARMACY_MODAL_LOCATION_ORGS, SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING } from "~/redux/type/"
import PharmacyModalLocationPharamcy from "./PharmacyModalLocationPharamcy"

class PharmacyModalLocationDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadLocations = this.loadLocations.bind(this)
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.pharmacy.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PHARMACY_MODAL_LOCATION_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid>
				<PharmacyModalLocationPharamcy loadLocations={this.loadLocations} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyModalLocationDetails)
