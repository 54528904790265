import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_OR_ANESTHESIAS,
	SET_OR_ANESTHESIAS_LOADING,
	SET_OR_CALENDAR_END_DATETIME,
	SET_OR_CALENDAR_START_DATETIME,
	SET_OR_DOCTORS,
	SET_OR_DOCTORS_LOADING,
	SET_OR_DOCTOR_ITEM_TYPES,
	SET_OR_DOCTOR_ITEM_TYPES_LOADING,
	SET_OR_END_DATETIME,
	SET_OR_ICDS,
	SET_OR_ICDS_LOADING,
	SET_OR_INTAKES,
	SET_OR_INTAKES_LOADING,
	SET_OR_INTRA_ANE_END_DATE_TIME,
	SET_OR_INTRA_ANE_START_DATE_TIME,
	SET_OR_ITEMS,
	SET_OR_ITEMS_LOADING,
	SET_OR_LOCATION,
	SET_OR_MODAL_CANCEL_REASON,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL,
	SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION,
	SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL,
	SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV,
	SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS,
	SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY,
	SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION,
	SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC,
	SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR,
	SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT,
	SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL,
	SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE,
	SET_OR_MODAL_EDIT_ANE_PRE_CXR,
	SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_EKG,
	SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_GA,
	SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL,
	SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY,
	SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LMP,
	SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE,
	SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT,
	SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION,
	SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT,
	SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY,
	SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_SMOKING,
	SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL,
	SET_OR_MODAL_EDIT_REQUEST_OPERATION_PROCEDURE,
	SET_OR_MODAL_EDIT_REQUEST_PRE_OPERATIVE_DIAG,
	SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA,
	SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST,
	SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE,
	SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_DOCTOR,
	SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_ICD,
	SET_OR_MODAL_EDIT_REQUEST_SELECTED_PRE_DIAG_ICD,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND,
	SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING,
	SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER,
	SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB,
	SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE,
	SET_OR_MODAL_INTRA_ANE_IO_FILTER_OUTPUT,
	SET_OR_MODAL_INTRA_ANE_IO_INTAKES,
	SET_OR_MODAL_INTRA_ANE_IO_OUTPUTS,
	SET_OR_MODAL_INTRA_ANE_IO_PENDING_INTAKES,
	SET_OR_MODAL_INTRA_ANE_IO_PENDING_OUTPUTS,
	SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE,
	SET_OR_MODAL_INTRA_ANE_IO_SELECTED_OUTPUT,
	SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE,
	SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_OUTPUT,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_FILTER_GENERIC_NAME,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_ITEMS,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_PENDING_ITEMS,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_ITEM,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_PENDING_ITEM,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_TYPE,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS,
	SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION,
	SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL,
	SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION,
	SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2,
	SET_OR_MODAL_INTRA_ANE_PREMEDICATION_DESCRIPTION,
	SET_OR_MODAL_INTRA_ANE_PROBLEM_DESCRIPTION,
	SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME,
	SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME,
	SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME,
	SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME,
	SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION,
	SET_OR_MODAL_INTRA_ANE_SERVICE_PENDING_SERVICES,
	SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_PENDING_SERVICE,
	SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE,
	SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE,
	SET_OR_MODAL_INTRA_ANE_SERVICE_SERVICES,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE,
	SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_BP,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_HR,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_TO,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER,
	SET_OR_MODAL_INTRA_ANE_VITAL_SIGNS,
	SET_OR_MODAL_INTRA_ANE_VS_ALINE,
	SET_OR_MODAL_INTRA_ANE_VS_DATE_TIME,
	SET_OR_MODAL_INTRA_ANE_VS_NIBP,
	SET_OR_MODAL_INTRA_ANE_VS_PULSE,
	SET_OR_MODAL_INTRA_ANE_VS_SELECTED_VS,
	SET_OR_MODAL_LOCATION_SELECTED_BRANCH,
	SET_OR_MODAL_LOCATION_SELECTED_ORG,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK,
	SET_OR_MODAL_POST_ANE_COMPLICATION,
	SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER,
	SET_OR_MODAL_POST_ANE_IO_FILTER_INTAKE,
	SET_OR_MODAL_POST_ANE_IO_FILTER_OUTPUT,
	SET_OR_MODAL_POST_ANE_IO_INTAKES,
	SET_OR_MODAL_POST_ANE_IO_OUTPUTS,
	SET_OR_MODAL_POST_ANE_IO_PENDING_INTAKES,
	SET_OR_MODAL_POST_ANE_IO_PENDING_OUTPUTS,
	SET_OR_MODAL_POST_ANE_IO_SELECTED_INTAKE,
	SET_OR_MODAL_POST_ANE_IO_SELECTED_OUTPUT,
	SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_INTAKE,
	SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_OUTPUT,
	SET_OR_MODAL_POST_ANE_MEDICATION_DATE_TIME,
	SET_OR_MODAL_POST_ANE_MEDICATION_DURATION_QTY,
	SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME,
	SET_OR_MODAL_POST_ANE_MEDICATION_ITEMS,
	SET_OR_MODAL_POST_ANE_MEDICATION_NOTES,
	SET_OR_MODAL_POST_ANE_MEDICATION_PENDING_ITEMS,
	SET_OR_MODAL_POST_ANE_MEDICATION_RATE_DURATION,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_RATE,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE,
	SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_UNIT,
	SET_OR_MODAL_POST_ANE_MEDICATION_UNITS,
	SET_OR_MODAL_POST_ANE_NURSE_DESCRIPTION,
	SET_OR_MODAL_POST_ANE_PADSS_AMBULATION,
	SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE,
	SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING,
	SET_OR_MODAL_POST_ANE_PADSS_PAIN,
	SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING,
	SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME,
	SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME,
	SET_OR_MODAL_POST_ANE_SERVICE_FILTER_DESCRIPTION,
	SET_OR_MODAL_POST_ANE_SERVICE_PENDING_SERVICES,
	SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE,
	SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_SERVICE,
	SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_TYPE,
	SET_OR_MODAL_POST_ANE_SERVICE_SERVICES,
	SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB,
	SET_OR_MODAL_POST_ANE_TRANSFER_TO,
	SET_OR_MODAL_POST_ANE_VITAL_SIGNS,
	SET_OR_MODAL_POST_ANE_VS_ALINE,
	SET_OR_MODAL_POST_ANE_VS_DATE_TIME,
	SET_OR_MODAL_POST_ANE_VS_NIBP,
	SET_OR_MODAL_POST_ANE_VS_PULSE,
	SET_OR_MODAL_POST_ANE_VS_RR,
	SET_OR_MODAL_POST_ANE_VS_SELECTED_VS,
	SET_OR_MODAL_POST_ANE_VS_SPO2,
	SET_OR_MODAL_REMARK_REASON,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE,
	SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED,
	SET_OR_MODAL_VS_BP,
	SET_OR_MODAL_VS_DATE_TIME,
	SET_OR_MODAL_VS_OXYGEN_SAT,
	SET_OR_MODAL_VS_PR,
	SET_OR_MODAL_VS_RR,
	SET_OR_MODAL_VS_TEMP,
	SET_OR_OPERATING_ROOMS,
	SET_OR_OPERATING_ROOMS_LOADING,
	SET_OR_OPERATING_ROOM_CASES,
	SET_OR_OPERATING_ROOM_CASES_LOADING,
	SET_OR_OPERATING_ROOM_REQUESTS,
	SET_OR_OPERATING_ROOM_REQUESTS_LOADING,
	SET_OR_ORGS,
	SET_OR_ORGS_LOADING,
	SET_OR_OUTPUTS,
	SET_OR_OUTPUTS_LOADING,
	SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA,
	SET_OR_SBUS,
	SET_OR_SBUS_LOADING,
	SET_OR_SCHEDULES,
	SET_OR_SELECTED_EVENT,
	SET_OR_SELECTED_INTRA_ANE_RECORD,
	SET_OR_SELECTED_OPERATING_ROOM,
	SET_OR_SELECTED_POST_ANE_RECORD,
	SET_OR_SELECTED_REQUEST,
	SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
	SET_OR_SERVICE_TYPES,
	SET_OR_SERVICE_TYPES_LOADING,
	SET_OR_START_DATETIME,
	SET_OR_VITAL_SIGN,
} from "~/redux/type"

const init = {
	startDateTime: undefined,
	endDateTime: undefined,
	calendarStartDateTime: undefined,
	calendarEndDateTime: undefined,
	intraAneStartDateTime: undefined,
	intraAneEndDateTime: undefined,
	location: null,
	vitalSign: null,
	orgs: [],
	operatingRooms: [],
	schedules: [],
	operatingRoomCases: [],
	doctors: [],
	icds: [],
	anesthesias: [],
	items: [],
	doctorItemTypes: [],
	serviceTypes: [],
	intakes: [],
	outputs: [],
	SBUs: [],
	isOperatingRoomCasesLoading: false,
	isOrgsLoading: false,
	isOperatingRoomsLoading: false,
	isDoctorsLoading: false,
	isICDsLoading: false,
	isAnesthesiasLoading: false,
	isItemsLoading: false,
	isDoctorItemTypesLoading: false,
	isServiceTypesLoading: false,
	isIntakesLoading: false,
	isOutputLoading: false,
	isSBUsLoading: false,
	selectedRequest: {
		index: -1,
		request: null,
	},
	selectedOperatingRoom: {
		index: -1,
		operatingRoom: null,
	},
	selectedEvent: {
		index: -1,
		event: null,
	},
	selectedSurgicalSafetyCheckList: {
		index: -1,
		checklist: null,
	},
	selectedIntraAneRecord: {
		index: -1,
		record: null,
	},
	selectedPostAneRecord: {
		index: -1,
		record: null,
	},
	operatingRequestTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.startDate,
			ResourceAssistance.Message.endDate,
			ResourceAssistance.Message.patientId,
			ResourceAssistance.Message.patientName,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	modalLocation: {
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
	modalRemark: {
		reason: "",
	},
	modalCancel: {
		reason: "",
	},
	modalEditRequest: {
		preOperativeDiag: "",
		operationProcedure: "",
		selectedORCase: {
			index: -1,
			case: null,
		},
		selectedOperativeDoctor: {
			index: -1,
			doctor: null,
		},
		selectedOperativeICD: {
			index: -1,
			icd: null,
		},
		selectedPreDiagICD: {
			index: -1,
			icd: null,
		},
		selectedAnesthesiologist: {
			index: -1,
			anesthesiologist: null,
		},
		selectedAnesthesiaType: {
			index: -1,
			type: null,
		},
	},
	modalVitalSign: {
		vsDateTime: moment().milliseconds(0).valueOf(),
		vsTemp: "",
		vsPR: "",
		vsRR: "",
		vsBP: "",
		vsOxygenSat: "",
	},
	modalEditAnPre: {
		LMP: "",
		isPregnant: false,
		pregnantDetail: "",
		isSmoking: false,
		smokingDetail: "",
		isMedicationUsed: false,
		medicationUsageDetail: "",
		// physical Examination
		isGA: true,
		generalAppearanceDetail: [],
		generalAppearanceOther: "",
		ASAClass: "1",
		isASAClassEmergency: false,
		isAirwayDentures: false,
		isAirwayDentalDamage: false,
		isAirwayThyromental: true,
		isAirwayMouthOpen: true,
		isAirwayHyomentalDistance: true,
		isAirwayNeck: true,
		airwayNeckDetail: "",
		airwayMallampatiScore: "1",
		isAirwayExpectedDifficult: true,
		airwayExpectedDifficultDetail: [],
		isNeurologicalAssessment: true,
		neurologicalAssessmentDetail: [],
		neurologicalAssessmentOther: "",
		isBackExamination: true,
		backExaminationDetail: [],
		backExaminationOther: "",
		isCardiovascular: true,
		isCardiovascularHypertension: true,
		cardiovascularFunctionalCapacity: "",
		cardiovascularDetail: [],
		cardiovascularOther: "",
		isRespiratory: true,
		respiratoryDetail: [],
		respiratoryOther: "",
		isGastrointestinal: true,
		gastrointestinalDetail: [],
		gastrointestinalOther: "",
		isEndocrine: true,
		endocrineDetail: [],
		endocrineDMType: "",
		endocrineOther: "",
		isHematoOncology: true,
		hematoOncologyDetail: [],
		hematoOncologyOther: "",
		isRenal: true,
		renalDetail: [],
		renalInsufficiency: "",
		renalOther: "",
		// Investigation
		cbcWBC: "",
		cbcHgb: "",
		cbcHct: "",
		cbcPlatelets: "",
		cbcDate: "",
		electrolytesNa: "",
		electrolytesCl: "",
		electrolytesK: "",
		electrolytesHCO3: "",
		electrolytesBun: "",
		electrolytesCr: "",
		electrolytesGlucose: "",
		electrolytesDate: "",
		liverFunctionAlbumin: "",
		liverFunctionGlobulin: "",
		liverFunctionAST: "",
		liverFunctionALT: "",
		liverFunctionTotalBilirubin: "",
		liverFunctionDirectBilirubin: "",
		liverFunctionAlkalinePhosphatase: "",
		liverFunctionOther: "",
		coagulationPT: "",
		coagulationPTT: "",
		coagulationINR: "",
		isEKG: true,
		EKGDetail: "",
		isCXR: true,
		CXRDetail: "",
		otherInvestigation: "",
		//Blood/Components Prepared
		isAntiHIV: false,
		crossMatchingDetail: [],
		crossMatchingRedCell: "",
		crossMatchingFreshFrozenPlasma: "",
		crossMatchingPlateletConcentrate: "",
		//Anesthesia Plan
		anesthesiaPlanDetail: [],
		anesthesiaPlanRegional: "",
		anesthesiaPlanPreMedication: "",
	},
	modalEditPreOperativeCheckList: {
		isIdentifyPatient: true,
		identifyPatientRemark: "",
		isMarkingSiteForSurgery: true,
		markingSiteForSurgeryRemark: "",
		isLetterConsentSurgery: true,
		letterConsentSurgeryRemark: "",
		isLetterConsentAnesthesia: true,
		letterConsentAnesthesiaRemark: "",
		isLabResult: true,
		labResultRemark: "",
		isImagingResult: true,
		imagingResultRemark: "",
		isEKGResult: true,
		EKGResultRemark: "",
		isCleanPatientBody: true,
		cleanPatientBodyRemark: "",
		isUnisonEnema: true,
		unisonEnemaRemark: "",
		isNPOTime: true,
		NPOTimeRemark: "",
		isRemoveDentures: true,
		removeDenturesRemark: "",
		isEyeglassesRemoved: true,
		eyeglassesRemovedRemark: "",
		isJewelryRemoved: true,
		jewelryRemovedRemark: "",
		isBodyPiercingRemoved: true,
		bodyPiercingRemovedRemark: "",
		isChangePaitentClothes: true,
		changePatientClothesRemark: "",
		isPatientUrinate: true,
		patientUrinateRemark: "",
		isBloodRequested: true,
		bloodRequestedRemark: "",
		isPreEducation: true,
		preEducationRemark: "",
		isOtherMedicalDevice: true,
		otherMedicalDeviceRemark: "",
		isToolSpecialEquipment: true,
		toolSpecialEquipmentRemark: "",
		isImplant: true,
		implantRemark: "",
	},
	modalSurgicalSafetyCheckListSignIn: {
		isPatientIdentity: "",
		isPatientSignConsent: "",
		isSiteMarked: "",
		isAnesthesiaMachineCheckComplete: "",
		isPulseOximeterFunctioning: "",
		isKnowAllergy: "",
		isDifficultAirway: "",
		isRiskOfBloodLoss: "",
		isOREquipment: "",
		isMedicalDeviceImplant: "",
	},
	modalSurgicalSafetyCheckListTimeOut: {
		isConfirmTeamMembers: "",
		isPatientName: "",
		isProcedureSiteAndSide: "",
		isAntibiotic: "",
		isCriticalSteps: "",
		isCaseDuration: "",
		isAnticipatedBloodLoss: "",
		isPatientSpecificConcerns: "",
		isSterilizationConfirmed: "",
		isEquipmentIssues: "",
		isEssentialImagingDisplayed: "",
	},
	modalSurgicalSafetyCheckListSignOut: {
		isProcedureName: "",
		isCompletionOfCounts: "",
		isSpecimenLabeling: "",
		isEquipmentProblem: "",
		isRecoveryConcern: "",
	},
	modalIntraAnePatientSafety: {
		isAnestheticMachineChecked: "",
		isPressurePointsChecked: "",
		isArmsRestrained: "",
		eyesCareDetail: [],
		eyesCareOther: "",
		warmingDetail: [],
		warmingOther: "",
	},
	modalIntraAnePreinductionAssessment: {
		npoHours: "",
		ASAClass: "",
		isASAClassEmergency: false,
		isChangesFromPreAssessment: "",
		changesFromPreAssessmentOther: "",
		isPatientIdentified: "",
		isConsentSigned: "",
		isRiskForAspiration: "",
		isLabReviewed: "",
		isPredictedDifficultAirway: "",
		BP: "",
		HR: "",
		SpO2: "",
		consciousnessDetail: [],
	},
	modalIntraAneProblem: {
		description: "",
	},
	modalIntraAnePremedication: {
		description: "",
	},
	modalIntraAnePatientCondition: {
		positionDetail: [],
		respiration: "",
	},
	modalIntraAneTechnique: {
		generalAnesthesiaDetail: [],
		induction: "",
		maintenance: "",
		maintenanceOther: "",
		regionalAnesthesia: "",
		regionalAnesthesiaOther: "",
		isAseptic: "",
		isTechniqueApproach: "",
		techniqueApproachOther: "",
		equipmentDetail: [],
		site: "",
		position: "",
		needleSize: "",
		type: "",
		catheter: "",
		punctureAttemptingCounts: "",
		anesthesiaLevel: "",
		agentUsed: "",
		isMonitorAnesthesiaCare: "",
		monitorAnesthesiaCareDetail: [],
	},
	modalIntraAneAirwayManagement: {
		methodDetail: [],
		methodOralAirwayNo: "",
		methodLMANo: "",
		methodTracheostomyNo: "",
		methodNasalAirwayNo: "",
		methodETTubeNo: "",
		methodETTubeNoCuff: "",
		methodETTubeNoOral: "",
		methodETTubeNoDepth: "",
		type: "",
		typeDLTRtNo: "",
		typeDLTLtNo: "",
		laryngealView: "",
		laryngealViewDifficulty: "",
		laryngealViewAttempt: "",
		intubationTechnique: "",
		bladeType: "",
		bladeSize: "",
		bladeTypeDetail: [],
		assistedDeviceDetail: [],
		afterIntubationDetail: [],
		cuffedInflationDetail: [],
		breathingSystem: "",
		isVerbalCommand: "",
		isCough: "",
		isAdeq: "",
		isSwallowing: "",
		isHandGrip: "",
		isDyspnea: "",
		isUAO: "",
		isWheezing: "",
		isCyanosis: "",
	},
	modalIntraAneBabyDelivery: {
		timeOfBirth: "",
		gender: "",
	},
	modalIntraAneTransfer: {
		bp: "",
		hr: "",
		SpO2: "",
		oxygen: "",
		airwaySupport: "",
		respiration: "",
		consciousness: "",
		transferTo: "",
		transferToOther: "",
	},
	modalIntrAneRecord: {
		anesthesiaStartDateTime: undefined,
		anesthesiaEndDateTime: undefined,
		operativeStartDateTime: undefined,
		operativeEndDateTime: undefined,
	},
	modalIntraAneMedication: {
		dateTime: undefined,
		filterGenericName: "",
		durationQty: "",
		rateDuration: "",
		notes: "",
		units: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedUnit: {
			index: -1,
			unit: null,
		},
		selectedRate: {
			index: -1,
			rate: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.genericName, ResourceAssistance.Message.brandName, ResourceAssistance.Message.unit],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.order,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalIntraAneService: {
		filterDescription: "",
		selectedType: {
			index: -1,
			type: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedPendingService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.description, ResourceAssistance.Message.receiptCategory],
			body: [],
			rowColor: [],
		},
		pendingServiceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.description, ResourceAssistance.Message.receiptCategory],
			body: [],
		},
	},
	modalIntraAneIO: {
		filterIntake: "",
		filterOutput: "",
		selectedIntake: {
			index: -1,
			intake: null,
		},
		selectedPendingIntake: {
			index: -1,
			intake: null,
		},
		selectedOutput: {
			index: -1,
			output: null,
		},
		selectedPendingOutput: {
			index: -1,
			output: null,
		},
		intakeTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		pendingIntakeTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		outputTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		pendingOutputTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
	},
	modalIntraAneVS: {
		dateTime: undefined,
		pulse: "",
		NIBP: "",
		ALine: "",
		selectedVS: {
			index: -1,
			vs: null,
		},
		vitalSignTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.date, ResourceAssistance.Message.prPerMin, ResourceAssistance.Message.NIBP, ResourceAssistance.Message.ALine, ""],
			body: [],
			rowColor: [],
		},
	},
	modalPostAneNurse: {
		description: "",
	},
	modalPostAneComplication: {
		complicationDetail: [],
		complicationOther: "",
	},
	modalPostAneDischarge: {
		dischargeCriteriaDetail: [],
	},
	modalPostAneTransfer: {
		transferTo: "",
		selectedSBU: {
			index: -1,
			SBU: null,
		},
	},
	modalPostAnePADSS: {
		BPAndPulse: "",
		ambulation: "",
		nauseaVomitting: "",
		pain: "",
		surgicalBleeding: "",
	},
	modalPostAneRecord: {
		startDateTime: undefined,
		endDateTime: undefined,
	},
	modalPostAneMedication: {
		dateTime: undefined,
		filterGenericName: "",
		durationQty: "",
		rateDuration: "",
		notes: "",
		units: [],
		selectedType: {
			index: -1,
			type: null,
		},
		selectedItem: {
			index: -1,
			item: null,
		},
		selectedUnit: {
			index: -1,
			unit: null,
		},
		selectedRate: {
			index: -1,
			rate: null,
		},
		selectedPendingItem: {
			index: -1,
			item: null,
		},
		itemTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.genericName, ResourceAssistance.Message.brandName, ResourceAssistance.Message.unit],
			body: [],
			rowColor: [],
			isLoading: false,
		},
		pendingItemTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.order,
				ResourceAssistance.Message.brandName,
				ResourceAssistance.Message.note,
				ResourceAssistance.Message.startDate,
				ResourceAssistance.Message.endDate,
			],
			body: [],
		},
	},
	modalPostAneService: {
		filterDescription: "",
		selectedType: {
			index: -1,
			type: null,
		},
		selectedService: {
			index: -1,
			service: null,
		},
		selectedPendingService: {
			index: -1,
			service: null,
		},
		serviceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.description, ResourceAssistance.Message.receiptCategory],
			body: [],
			rowColor: [],
		},
		pendingServiceTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.code, ResourceAssistance.Message.description, ResourceAssistance.Message.receiptCategory],
			body: [],
		},
	},
	modalPostAneIO: {
		filterIntake: "",
		filterOutput: "",
		selectedIntake: {
			index: -1,
			intake: null,
		},
		selectedPendingIntake: {
			index: -1,
			intake: null,
		},
		selectedOutput: {
			index: -1,
			output: null,
		},
		selectedPendingOutput: {
			index: -1,
			output: null,
		},
		intakeTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		pendingIntakeTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		outputTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
		pendingOutputTable: {
			original: [],
			filtered: [],
			header: [ResourceAssistance.Message.method, ResourceAssistance.Message.description],
			body: [],
			rowColor: [],
		},
	},
	modalPostAneVS: {
		dateTime: undefined,
		pulse: "",
		NIBP: "",
		ALine: "",
		RR: "",
		SpO2: "",
		selectedVS: {
			index: -1,
			vs: null,
		},
		vitalSignTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.prPerMin,
				ResourceAssistance.Message.NIBP,
				ResourceAssistance.Message.ALine,
				ResourceAssistance.Message.rrPerMin,
				ResourceAssistance.Message.SpO2,
				"",
			],
			body: [],
			rowColor: [],
		},
	},
}

const operatingRoomReducer = (state = init, action) => {
	switch (action.type) {
		case SET_OR_ORGS:
			return Object.assign({}, state, {
				orgs: action.payload.value,
			})
		case SET_OR_ORGS_LOADING:
			return Object.assign({}, state, {
				isOrgsLoading: action.payload.isLoading,
			})
		case SET_OR_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_OR_VITAL_SIGN:
			return Object.assign({}, state, {
				vitalSign: action.payload.value,
			})
		case SET_OR_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_OR_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_OR_CALENDAR_START_DATETIME:
			return Object.assign({}, state, {
				calendarStartDateTime: action.payload.value,
			})
		case SET_OR_CALENDAR_END_DATETIME:
			return Object.assign({}, state, {
				calendarEndDateTime: action.payload.value,
			})
		case SET_OR_INTRA_ANE_START_DATE_TIME:
			return Object.assign({}, state, {
				intraAneStartDateTime: action.payload.value,
			})
		case SET_OR_INTRA_ANE_END_DATE_TIME:
			return Object.assign({}, state, {
				intraAneEndDateTime: action.payload.value,
			})
		case SET_OR_OPERATING_ROOM_REQUESTS:
			return Object.assign({}, state, {
				operatingRequestTable: {
					...state.operatingRequestTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_OR_OPERATING_ROOM_REQUESTS_LOADING:
			return Object.assign({}, state, {
				operatingRequestTable: {
					...state.operatingRequestTable,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_OR_SELECTED_REQUEST:
			return Object.assign({}, state, {
				selectedRequest: {
					index: action.payload.index,
					request: action.payload.selected,
				},
			})
		case SET_OR_OPERATING_ROOMS:
			return Object.assign({}, state, {
				operatingRooms: action.payload.value,
			})
		case SET_OR_OPERATING_ROOMS_LOADING:
			return Object.assign({}, state, {
				isOperatingRoomsLoading: action.payload.isLoading,
			})
		case SET_OR_SELECTED_OPERATING_ROOM:
			return Object.assign({}, state, {
				selectedOperatingRoom: {
					index: action.payload.index,
					operatingRoom: action.payload.selected,
				},
			})
		case SET_OR_SCHEDULES:
			return Object.assign({}, state, {
				schedules: action.payload.value,
			})
		case SET_OR_OPERATING_ROOM_CASES:
			return Object.assign({}, state, {
				operatingRoomCases: action.payload.value,
			})
		case SET_OR_OPERATING_ROOM_CASES_LOADING:
			return Object.assign({}, state, {
				isOperatingRoomCasesLoading: action.payload.isLoading,
			})
		case SET_OR_DOCTORS:
			return Object.assign({}, state, {
				doctors: action.payload.value,
			})
		case SET_OR_DOCTORS_LOADING:
			return Object.assign({}, state, {
				isDoctorsLoading: action.payload.isLoading,
			})
		case SET_OR_ICDS:
			return Object.assign({}, state, {
				icds: action.payload.value,
			})
		case SET_OR_ICDS_LOADING:
			return Object.assign({}, state, {
				isICDsLoading: action.payload.isLoading,
			})
		case SET_OR_ANESTHESIAS:
			return Object.assign({}, state, {
				anesthesias: action.payload.value,
			})
		case SET_OR_ANESTHESIAS_LOADING:
			return Object.assign({}, state, {
				isAnesthesiasLoading: action.payload.isLoading,
			})
		case SET_OR_SELECTED_EVENT:
			return Object.assign({}, state, {
				selectedEvent: {
					index: action.payload.index,
					event: action.payload.selected,
				},
			})
		case SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST:
			return Object.assign({}, state, {
				selectedSurgicalSafetyCheckList: {
					index: action.payload.index,
					checklist: action.payload.selected,
				},
			})
		case SET_OR_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_LOCATION_SELECTED_BRANCH:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_REMARK_REASON:
			return Object.assign({}, state, {
				modalRemark: {
					...state.modalRemark,
					reason: action.payload.value,
				},
			})
		case SET_OR_MODAL_CANCEL_REASON:
			return Object.assign({}, state, {
				modalCancel: {
					...state.modalCancel,
					reason: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_PRE_OPERATIVE_DIAG:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					preOperativeDiag: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_OPERATION_PROCEDURE:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					operationProcedure: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					selectedORCase: {
						index: action.payload.index,
						case: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_DOCTOR:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					selectedOperativeDoctor: {
						index: action.payload.index,
						doctor: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_ICD:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					selectedOperativeICD: {
						index: action.payload.index,
						icd: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_SELECTED_PRE_DIAG_ICD:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					selectedPreDiagICD: {
						index: action.payload.index,
						icd: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					selectedAnesthesiologist: {
						index: action.payload.index,
						anesthesiologist: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA:
			return Object.assign({}, state, {
				modalEditRequest: {
					...state.modalEditRequest,
					selectedAnesthesiaType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LMP:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					LMP: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isPregnant: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					pregnantDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_SMOKING:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isSmoking: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					smokingDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isMedicationUsed: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					medicationUsageDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_GA:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isGA: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					generalAppearanceDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					generalAppearanceOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					ASAClass: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isASAClassEmergency: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayDentures: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayDentalDamage: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayThyromental: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayMouthOpen: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayHyomentalDistance: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayNeck: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					airwayNeckDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					airwayMallampatiScore: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAirwayExpectedDifficult: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					airwayExpectedDifficultDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isNeurologicalAssessment: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					neurologicalAssessmentDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					neurologicalAssessmentOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isBackExamination: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					backExaminationDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					backExaminationOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isCardiovascular: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isCardiovascularHypertension: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cardiovascularFunctionalCapacity: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cardiovascularDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cardiovascularOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isRespiratory: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					respiratoryDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					respiratoryOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isGastrointestinal: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					gastrointestinalDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					gastrointestinalOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isEndocrine: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					endocrineDMType: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					endocrineDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					endocrineOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isHematoOncology: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					hematoOncologyDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					hematoOncologyOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RENAL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isRenal: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					renalDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					renalInsufficiency: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					renalOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cbcWBC: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cbcHgb: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cbcHct: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cbcPlatelets: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					cbcDate: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesNa: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesCl: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesK: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesHCO3: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesBun: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesCr: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesGlucose: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					electrolytesDate: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionAlbumin: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionGlobulin: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionAST: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionALT: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionTotalBilirubin: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionDirectBilirubin: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionAlkalinePhosphatase: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					liverFunctionOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					coagulationPT: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					coagulationPTT: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					coagulationINR: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_EKG:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isEKG: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					EKGDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CXR:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isCXR: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					CXRDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					otherInvestigation: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					isAntiHIV: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					crossMatchingDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					crossMatchingRedCell: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					crossMatchingFreshFrozenPlasma: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					crossMatchingPlateletConcentrate: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					anesthesiaPlanDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					anesthesiaPlanRegional: action.payload.value,
				},
			})
		case SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION:
			return Object.assign({}, state, {
				modalEditAnPre: {
					...state.modalEditAnPre,
					anesthesiaPlanPreMedication: action.payload.value,
				},
			})
		case SET_OR_MODAL_VS_DATE_TIME:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					vsDateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_VS_TEMP:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					vsTemp: action.payload.value,
				},
			})
		case SET_OR_MODAL_VS_PR:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					vsPR: action.payload.value,
				},
			})
		case SET_OR_MODAL_VS_RR:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					vsRR: action.payload.value,
				},
			})
		case SET_OR_MODAL_VS_BP:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					vsBP: action.payload.value,
				},
			})
		case SET_OR_MODAL_VS_OXYGEN_SAT:
			return Object.assign({}, state, {
				modalVitalSign: {
					...state.modalVitalSign,
					vsOxygenSat: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isIdentifyPatient: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					identifyPatientRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isMarkingSiteForSurgery: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					markingSiteForSurgeryRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isLetterConsentSurgery: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					letterConsentSurgeryRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isLetterConsentAnesthesia: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					letterConsentAnesthesiaRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isLabResult: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					labResultRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isImagingResult: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					imagingResultRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isEKGResult: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					EKGResultRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isCleanPatientBody: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					cleanPatientBodyRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isUnisonEnema: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					unisonEnemaRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isNPOTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					NPOTimeRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isRemoveDentures: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					removeDenturesRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isEyeglassesRemoved: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					eyeglassesRemovedRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isJewelryRemoved: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					jewelryRemovedRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isBodyPiercingRemoved: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					bodyPiercingRemovedRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isChangePaitentClothes: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					changePatientClothesRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isPatientUrinate: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					patientUrinateRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isBloodRequested: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					bloodRequestedRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isPreEducation: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					preEducationRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isOtherMedicalDevice: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					otherMedicalDeviceRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isToolSpecialEquipment: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					toolSpecialEquipmentRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					isImplant: action.payload.value,
				},
			})
		case SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK:
			return Object.assign({}, state, {
				modalEditPreOperativeCheckList: {
					...state.modalEditPreOperativeCheckList,
					implantRemark: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isPatientIdentity: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isPatientSignConsent: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isSiteMarked: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isAnesthesiaMachineCheckComplete: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isPulseOximeterFunctioning: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isKnowAllergy: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isDifficultAirway: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isRiskOfBloodLoss: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isOREquipment: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignIn: {
					...state.modalSurgicalSafetyCheckListSignIn,
					isMedicalDeviceImplant: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isConfirmTeamMembers: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isPatientName: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isProcedureSiteAndSide: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isAntibiotic: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isCriticalSteps: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isCaseDuration: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isAnticipatedBloodLoss: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isPatientSpecificConcerns: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isSterilizationConfirmed: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isEquipmentIssues: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListTimeOut: {
					...state.modalSurgicalSafetyCheckListTimeOut,
					isEssentialImagingDisplayed: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignOut: {
					...state.modalSurgicalSafetyCheckListSignOut,
					isProcedureName: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignOut: {
					...state.modalSurgicalSafetyCheckListSignOut,
					isCompletionOfCounts: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignOut: {
					...state.modalSurgicalSafetyCheckListSignOut,
					isSpecimenLabeling: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignOut: {
					...state.modalSurgicalSafetyCheckListSignOut,
					isEquipmentProblem: action.payload.value,
				},
			})
		case SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN:
			return Object.assign({}, state, {
				modalSurgicalSafetyCheckListSignOut: {
					...state.modalSurgicalSafetyCheckListSignOut,
					isRecoveryConcern: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					isAnestheticMachineChecked: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					isPressurePointsChecked: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					isArmsRestrained: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					eyesCareDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					eyesCareOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					warmingDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER:
			return Object.assign({}, state, {
				modalIntraAnePatientSafety: {
					...state.modalIntraAnePatientSafety,
					warmingOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					npoHours: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					ASAClass: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isASAClassEmergency: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isChangesFromPreAssessment: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					changesFromPreAssessmentOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isPatientIdentified: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isConsentSigned: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isRiskForAspiration: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isLabReviewed: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					isPredictedDifficultAirway: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					BP: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					HR: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					SpO2: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS:
			return Object.assign({}, state, {
				modalIntraAnePreinductionAssessment: {
					...state.modalIntraAnePreinductionAssessment,
					consciousnessDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PROBLEM_DESCRIPTION:
			return Object.assign({}, state, {
				modalIntraAneProblem: {
					...state.modalIntraAneProblem,
					description: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PREMEDICATION_DESCRIPTION:
			return Object.assign({}, state, {
				modalIntraAnePremedication: {
					...state.modalIntraAnePremedication,
					description: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION:
			return Object.assign({}, state, {
				modalIntraAnePatientCondition: {
					...state.modalIntraAnePatientCondition,
					positionDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION:
			return Object.assign({}, state, {
				modalIntraAnePatientCondition: {
					...state.modalIntraAnePatientCondition,
					respiration: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					generalAnesthesiaDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					induction: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					maintenance: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					maintenanceOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					regionalAnesthesia: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					regionalAnesthesiaOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					isAseptic: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					isTechniqueApproach: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					techniqueApproachOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					equipmentDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					site: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					position: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					needleSize: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					type: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					catheter: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					punctureAttemptingCounts: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					anesthesiaLevel: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					agentUsed: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					isMonitorAnesthesiaCare: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE:
			return Object.assign({}, state, {
				modalIntraAneTechnique: {
					...state.modalIntraAneTechnique,
					monitorAnesthesiaCareDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodOralAirwayNo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodLMANo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodTracheostomyNo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodNasalAirwayNo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodETTubeNo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodETTubeNoCuff: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodETTubeNoOral: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					methodETTubeNoDepth: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					type: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					typeDLTRtNo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					typeDLTLtNo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					laryngealView: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					laryngealViewDifficulty: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					laryngealViewAttempt: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					intubationTechnique: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					bladeType: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					bladeSize: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					bladeTypeDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					assistedDeviceDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					afterIntubationDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					cuffedInflationDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					breathingSystem: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isVerbalCommand: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isCough: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isAdeq: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isSwallowing: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isHandGrip: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isDyspnea: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isUAO: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isWheezing: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS:
			return Object.assign({}, state, {
				modalIntraAneAirwayManagement: {
					...state.modalIntraAneAirwayManagement,
					isCyanosis: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB:
			return Object.assign({}, state, {
				modalIntraAneBabyDelivery: {
					...state.modalIntraAneBabyDelivery,
					timeOfBirth: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER:
			return Object.assign({}, state, {
				modalIntraAneBabyDelivery: {
					...state.modalIntraAneBabyDelivery,
					gender: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_BP:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					bp: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_HR:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					hr: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					SpO2: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					oxygen: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					airwaySupport: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					respiration: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					consciousness: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_TO:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					transferTo: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER:
			return Object.assign({}, state, {
				modalIntraAneTransfer: {
					...state.modalIntraAneTransfer,
					transferToOther: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME:
			return Object.assign({}, state, {
				modalIntrAneRecord: {
					...state.modalIntrAneRecord,
					anesthesiaStartDateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME:
			return Object.assign({}, state, {
				modalIntrAneRecord: {
					...state.modalIntrAneRecord,
					anesthesiaEndDateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME:
			return Object.assign({}, state, {
				modalIntrAneRecord: {
					...state.modalIntrAneRecord,
					operativeStartDateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME:
			return Object.assign({}, state, {
				modalIntrAneRecord: {
					...state.modalIntrAneRecord,
					operativeEndDateTime: action.payload.value,
				},
			})
		case SET_OR_SELECTED_INTRA_ANE_RECORD:
			return Object.assign({}, state, {
				selectedIntraAneRecord: {
					index: action.payload.index,
					record: action.payload.selected,
				},
			})
		case SET_OR_ITEMS:
			return Object.assign({}, state, {
				items: action.payload.value,
			})
		case SET_OR_ITEMS_LOADING:
			return Object.assign({}, state, {
				isItemsLoading: action.payload.isLoading,
			})
		case SET_OR_DOCTOR_ITEM_TYPES:
			return Object.assign({}, state, {
				doctorItemTypes: action.payload.value,
			})
		case SET_OR_DOCTOR_ITEM_TYPES_LOADING:
			return Object.assign({}, state, {
				isDoctorItemTypesLoading: action.payload.isLoading,
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_FILTER_GENERIC_NAME:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					filterGenericName: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_ITEM:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_ITEMS:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					itemTable: {
						...state.modalIntraAneMedication.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					dateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					durationQty: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					selectedUnit: {
						index: action.payload.index,
						unit: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					units: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					selectedRate: {
						index: action.payload.index,
						rate: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					rateDuration: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					notes: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_PENDING_ITEMS:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					pendingItemTable: {
						...state.modalIntraAneMedication.pendingItemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				modalIntraAneMedication: {
					...state.modalIntraAneMedication,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_OR_SERVICE_TYPES:
			return Object.assign({}, state, {
				serviceTypes: action.payload.value,
			})
		case SET_OR_SERVICE_TYPES_LOADING:
			return Object.assign({}, state, {
				isServiceTypesLoading: action.payload.isLoading,
			})
		case SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalIntraAneService: {
					...state.modalIntraAneService,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION:
			return Object.assign({}, state, {
				modalIntraAneService: {
					...state.modalIntraAneService,
					filterDescription: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_SERVICE_SERVICES:
			return Object.assign({}, state, {
				modalIntraAneService: {
					...state.modalIntraAneService,
					serviceTable: {
						...state.modalIntraAneService.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalIntraAneService: {
					...state.modalIntraAneService,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_SERVICE_PENDING_SERVICES:
			return Object.assign({}, state, {
				modalIntraAneService: {
					...state.modalIntraAneService,
					pendingServiceTable: {
						...state.modalIntraAneService.pendingServiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_PENDING_SERVICE:
			return Object.assign({}, state, {
				modalIntraAneService: {
					...state.modalIntraAneService,
					selectedPendingService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_OR_INTAKES:
			return Object.assign({}, state, {
				intakes: action.payload.value,
			})
		case SET_OR_INTAKES_LOADING:
			return Object.assign({}, state, {
				isIntakesLoading: action.payload.isLoading,
			})
		case SET_OR_OUTPUTS:
			return Object.assign({}, state, {
				outputs: action.payload.value,
			})
		case SET_OR_OUTPUTS_LOADING:
			return Object.assign({}, state, {
				isOutputsLoading: action.payload.isLoading,
			})
		case SET_OR_SBUS:
			return Object.assign({}, state, {
				SBUs: action.payload.value,
			})
		case SET_OR_SBUS_LOADING:
			return Object.assign({}, state, {
				isSBUsLoading: action.payload.isLoading,
			})
		case SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					filterIntake: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_FILTER_OUTPUT:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					filterOutput: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_INTAKES:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					intakeTable: {
						...state.modalIntraAneIO.intakeTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					selectedIntake: {
						index: action.payload.index,
						intake: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_PENDING_INTAKES:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					pendingIntakeTable: {
						...state.modalIntraAneIO.pendingIntakeTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					selectedPendingIntake: {
						index: action.payload.index,
						intake: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_OUTPUTS:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					outputTable: {
						...state.modalIntraAneIO.outputTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_SELECTED_OUTPUT:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					selectedOutput: {
						index: action.payload.index,
						output: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_PENDING_OUTPUTS:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					pendingOutputTable: {
						...state.modalIntraAneIO.pendingOutputTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_OUTPUT:
			return Object.assign({}, state, {
				modalIntraAneIO: {
					...state.modalIntraAneIO,
					selectedPendingOutput: {
						index: action.payload.index,
						output: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_VS_DATE_TIME:
			return Object.assign({}, state, {
				modalIntraAneVS: {
					...state.modalIntraAneVS,
					dateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_VS_PULSE:
			return Object.assign({}, state, {
				modalIntraAneVS: {
					...state.modalIntraAneVS,
					pulse: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_VS_NIBP:
			return Object.assign({}, state, {
				modalIntraAneVS: {
					...state.modalIntraAneVS,
					NIBP: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_VS_ALINE:
			return Object.assign({}, state, {
				modalIntraAneVS: {
					...state.modalIntraAneVS,
					ALine: action.payload.value,
				},
			})
		case SET_OR_MODAL_INTRA_ANE_VITAL_SIGNS:
			return Object.assign({}, state, {
				modalIntraAneVS: {
					...state.modalIntraAneVS,
					vitalSignTable: {
						...state.modalIntraAneVS.vitalSignTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_INTRA_ANE_VS_SELECTED_VS:
			return Object.assign({}, state, {
				modalIntraAneVS: {
					...state.modalIntraAneVS,
					selectedVS: {
						index: action.payload.index,
						vs: action.payload.selected,
					},
				},
			})
		case SET_OR_SELECTED_POST_ANE_RECORD:
			return Object.assign({}, state, {
				selectedPostAneRecord: {
					index: action.payload.index,
					record: action.payload.selected,
				},
			})
		case SET_OR_MODAL_POST_ANE_NURSE_DESCRIPTION:
			return Object.assign({}, state, {
				modalPostAneNurse: {
					...state.modalPostAneNurse,
					description: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_COMPLICATION:
			return Object.assign({}, state, {
				modalPostAneComplication: {
					...state.modalPostAneComplication,
					complicationDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER:
			return Object.assign({}, state, {
				modalPostAneComplication: {
					...state.modalPostAneComplication,
					complicationOther: action.payload.value,
				},
			})
		case SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA:
			return Object.assign({}, state, {
				modalPostAneDischarge: {
					...state.modalPostAneDischarge,
					dischargeCriteriaDetail: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_TRANSFER_TO:
			return Object.assign({}, state, {
				modalPostAneTransfer: {
					...state.modalPostAneTransfer,
					transferTo: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB:
			return Object.assign({}, state, {
				modalPostAneTransfer: {
					...state.modalPostAneTransfer,
					selectedSBU: {
						index: action.payload.index,
						SBU: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE:
			return Object.assign({}, state, {
				modalPostAnePADSS: {
					...state.modalPostAnePADSS,
					BPAndPulse: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_PADSS_AMBULATION:
			return Object.assign({}, state, {
				modalPostAnePADSS: {
					...state.modalPostAnePADSS,
					ambulation: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING:
			return Object.assign({}, state, {
				modalPostAnePADSS: {
					...state.modalPostAnePADSS,
					nauseaVomitting: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_PADSS_PAIN:
			return Object.assign({}, state, {
				modalPostAnePADSS: {
					...state.modalPostAnePADSS,
					pain: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING:
			return Object.assign({}, state, {
				modalPostAnePADSS: {
					...state.modalPostAnePADSS,
					surgicalBleeding: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME:
			return Object.assign({}, state, {
				modalPostAneRecord: {
					...state.modalPostAneRecord,
					startDateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME:
			return Object.assign({}, state, {
				modalPostAneRecord: {
					...state.modalPostAneRecord,
					endDateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					filterGenericName: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					selectedItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_ITEMS:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					itemTable: {
						...state.modalPostAneMedication.itemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_DATE_TIME:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					dateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_DURATION_QTY:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					durationQty: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_UNIT:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					selectedUnit: {
						index: action.payload.index,
						unit: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_UNITS:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					units: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_RATE:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					selectedRate: {
						index: action.payload.index,
						rate: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_RATE_DURATION:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					rateDuration: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_NOTES:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					notes: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_PENDING_ITEMS:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					pendingItemTable: {
						...state.modalPostAneMedication.pendingItemTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM:
			return Object.assign({}, state, {
				modalPostAneMedication: {
					...state.modalPostAneMedication,
					selectedPendingItem: {
						index: action.payload.index,
						item: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalPostAneService: {
					...state.modalPostAneService,
					selectedType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_SERVICE_FILTER_DESCRIPTION:
			return Object.assign({}, state, {
				modalPostAneService: {
					...state.modalPostAneService,
					filterDescription: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_SERVICE_SERVICES:
			return Object.assign({}, state, {
				modalPostAneService: {
					...state.modalPostAneService,
					serviceTable: {
						...state.modalPostAneService.serviceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_SERVICE:
			return Object.assign({}, state, {
				modalPostAneService: {
					...state.modalPostAneService,
					selectedService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_SERVICE_PENDING_SERVICES:
			return Object.assign({}, state, {
				modalPostAneService: {
					...state.modalPostAneService,
					pendingServiceTable: {
						...state.modalPostAneService.pendingServiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE:
			return Object.assign({}, state, {
				modalPostAneService: {
					...state.modalPostAneService,
					selectedPendingService: {
						index: action.payload.index,
						service: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_FILTER_INTAKE:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					filterIntake: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_FILTER_OUTPUT:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					filterOutput: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_INTAKES:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					intakeTable: {
						...state.modalPostAneIO.intakeTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_SELECTED_INTAKE:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					selectedIntake: {
						index: action.payload.index,
						intake: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_PENDING_INTAKES:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					pendingIntakeTable: {
						...state.modalPostAneIO.pendingIntakeTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_INTAKE:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					selectedPendingIntake: {
						index: action.payload.index,
						intake: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_OUTPUTS:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					outputTable: {
						...state.modalPostAneIO.outputTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_SELECTED_OUTPUT:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					selectedOutput: {
						index: action.payload.index,
						output: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_PENDING_OUTPUTS:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					pendingOutputTable: {
						...state.modalPostAneIO.pendingOutputTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_OUTPUT:
			return Object.assign({}, state, {
				modalPostAneIO: {
					...state.modalPostAneIO,
					selectedPendingOutput: {
						index: action.payload.index,
						output: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_DATE_TIME:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					dateTime: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_PULSE:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					pulse: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_NIBP:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					NIBP: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_ALINE:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					ALine: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_VITAL_SIGNS:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					vitalSignTable: {
						...state.modalPostAneVS.vitalSignTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_SELECTED_VS:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					selectedVS: {
						index: action.payload.index,
						vs: action.payload.selected,
					},
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_RR:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					RR: action.payload.value,
				},
			})
		case SET_OR_MODAL_POST_ANE_VS_SPO2:
			return Object.assign({}, state, {
				modalPostAneVS: {
					...state.modalPostAneVS,
					SpO2: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default operatingRoomReducer
