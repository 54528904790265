import { Descriptions } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"

class RegistrationRightRelative extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset className={ResourceAssistance.CSS.fullFlex}>
						<legend>{translate(ResourceAssistance.Message.relativeInfo)}</legend>
						<Descriptions style={{ flex: "1", flexDirection: "column" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={2}>
							<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={2}>
								{this.props.ipd.selectedPatient.patient
									? this.props.ipd.selectedPatient.patient.relativeFirstName + " " + this.props.ipd.selectedPatient.patient.relativeLastName
									: ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.relationship)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.relationship : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.tel)} span={1}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.relativeTel : ""}
							</Descriptions.Item>
							<Descriptions.Item label={translate(ResourceAssistance.Message.address)} span={2}>
								{this.props.ipd.selectedPatient.patient ? this.props.ipd.selectedPatient.patient.relativeAddress : ""}
							</Descriptions.Item>
						</Descriptions>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationRightRelative)
