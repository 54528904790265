import { Checkbox, Radio, Space } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL,
	SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS,
	SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY,
	SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION,
	SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION,
	SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM,
	SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_GA,
	SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL,
	SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY,
	SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT,
	SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY,
	SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY,
	SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const CBGeneralAppearance = {
	Obese: "O",
	Cachexia: "C",
	Pale: "P",
	Jaundice: "J",
	Cyanosis: "Cy",
	Other: "Ot",
}
export const CBAirwayExpectedDifficult = {
	Ventilation: "V",
	Intubation: "I",
}
export const CBNeurologicalAssessment = {
	Mental_Retard: "M",
	Old_CVAs: "O",
	Seizures_Epilepsy: "S",
	Other: "Ot",
}
export const CBBackExamination = {
	ChronicPain: "C",
	Myopathy: "M",
	Other: "Ot",
}
export const CBCardiovascular = {
	Hypertension: "H",
	CAD: "C",
	Arrhythmia: "A",
	Dyslipidemia: "D",
	Functional_Capacity: "F",
	Other: "Ot",
}
export const CBRespiratory = {
	Asthma: "A",
	COPD: "C",
	TB: "T",
	RecentURI: "R",
	Other: "Ot",
}
export const CBGastrointestinal = {
	Full_Stomach: "F",
	Hepatitis: "H",
	Cirrhosis: "C",
	Other: "Ot",
}
export const CBEndocrine = {
	DM: "D",
	Hyperthyroid: "H",
	Steroid_Use: "S",
	Other: "Ot",
}
export const CBHematoOncology = {
	Anemia: "A",
	Bleeding: "B",
	Recent_Chemotherapy: "R",
	Other: "O",
}
export const CBRenal = {
	Insufficiency: "I",
	ESRD: "E",
	Hemodialysis: "H",
	Other: "Ot",
}
class OperatingRoomModalEditPrePhysicalExam extends React.Component {
	constructor(props) {
		super(props)

		this.onGA = this.onGA.bind(this)
		this.onGeneralAppearanceDetail = this.onGeneralAppearanceDetail.bind(this)
		this.onGeneralAppearanceOther = this.onGeneralAppearanceOther.bind(this)
		this.onASAClass = this.onASAClass.bind(this)
		this.onASAClassEmergency = this.onASAClassEmergency.bind(this)
		this.onAirwayDentures = this.onAirwayDentures.bind(this)
		this.onAirwayDentalDamage = this.onAirwayDentalDamage.bind(this)
		this.onAirwayThyromental = this.onAirwayThyromental.bind(this)
		this.onAirwayMouthOpen = this.onAirwayMouthOpen.bind(this)
		this.onAirwayHyomentalDistance = this.onAirwayHyomentalDistance.bind(this)
		this.onAirwayNeck = this.onAirwayNeck.bind(this)
		this.onAirwayNeckDetail = this.onAirwayNeckDetail.bind(this)
		this.onAirwayMallampatiScore = this.onAirwayMallampatiScore.bind(this)
		this.onAirwayExpectedDifficult = this.onAirwayExpectedDifficult.bind(this)
		this.onAirwayExpectedDifficultDetail = this.onAirwayExpectedDifficultDetail.bind(this)
		this.onNeurologicalAssessment = this.onNeurologicalAssessment.bind(this)
		this.onNeurologicalAssessmentDetail = this.onNeurologicalAssessmentDetail.bind(this)
		this.onNeurologicalAssessmentOther = this.onNeurologicalAssessmentOther.bind(this)
		this.onBackExamination = this.onBackExamination.bind(this)
		this.onBackExaminationDetail = this.onBackExaminationDetail.bind(this)
		this.onBackExaminationOther = this.onBackExaminationOther.bind(this)
		this.onCardiovascular = this.onCardiovascular.bind(this)
		this.onCardiovascularHypertension = this.onCardiovascularHypertension.bind(this)
		this.onCardiovascularFunctionalCapacity = this.onCardiovascularFunctionalCapacity.bind(this)
		this.onCardiovascularDetail = this.onCardiovascularDetail.bind(this)
		this.onCardiovascularOther = this.onCardiovascularOther.bind(this)
		this.onRespiratory = this.onRespiratory.bind(this)
		this.onRespiratoryDetail = this.onRespiratoryDetail.bind(this)
		this.onRespiratoryOther = this.onRespiratoryOther.bind(this)
		this.onGastrointestinal = this.onGastrointestinal.bind(this)
		this.onGastrointestinalDetail = this.onGastrointestinalDetail.bind(this)
		this.onGastrointestinalOther = this.onGastrointestinalOther.bind(this)
		this.onEndocrine = this.onEndocrine.bind(this)
		this.onEndocrineDetail = this.onEndocrineDetail.bind(this)
		this.onEndocrineDMType = this.onEndocrineDMType.bind(this)
		this.onEndocrineOther = this.onEndocrineOther.bind(this)
		this.onHematoOncology = this.onHematoOncology.bind(this)
		this.onHematoOncologyDetail = this.onHematoOncologyDetail.bind(this)
		this.onHematoOncologyOther = this.onHematoOncologyOther.bind(this)
		this.onRenal = this.onRenal.bind(this)
		this.onRenalDetail = this.onRenalDetail.bind(this)
		this.onRenalInsufficiency = this.onRenalInsufficiency.bind(this)
		this.onRenalOther = this.onRenalOther.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let anesthesiaPreOP = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
			if (anesthesiaPreOP) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA, _.isEmpty(anesthesiaPreOP.generalAppearance))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL, anesthesiaPreOP.generalAppearance.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER, anesthesiaPreOP.generalAppearanceOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS, anesthesiaPreOP.asaClass.split(",")[0])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY, anesthesiaPreOP.asaClass.split(",")[1])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES, anesthesiaPreOP.airwayDentures)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE, anesthesiaPreOP.airwayDentalDamage)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL, anesthesiaPreOP.airwayThyromental)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN, anesthesiaPreOP.airwayMouthOpen)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE, anesthesiaPreOP.airwayHyomentalDistance)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK, _.isEmpty(anesthesiaPreOP.airwayNeck))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL, anesthesiaPreOP.airwayNeck)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE, anesthesiaPreOP.airwayMallampatiScore)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT, _.isEmpty(anesthesiaPreOP.airwayExpectedDifficult))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL, anesthesiaPreOP.airwayExpectedDifficult.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT, _.isEmpty(anesthesiaPreOP.neurologicalAssessment))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL, anesthesiaPreOP.neurologicalAssessment.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER, anesthesiaPreOP.neurologicalAssessmentOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION, _.isEmpty(anesthesiaPreOP.backExamination))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL, anesthesiaPreOP.backExamination.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER, anesthesiaPreOP.backExaminationOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR, _.isEmpty(anesthesiaPreOP.cardiovascular))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL, anesthesiaPreOP.cardiovascular.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION, anesthesiaPreOP.cardiovascularHypertension)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY, anesthesiaPreOP.cardiovascularFunctionalCapacity)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER, anesthesiaPreOP.cardiovascularOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY, _.isEmpty(anesthesiaPreOP.respiratory))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL, anesthesiaPreOP.respiratory.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER, anesthesiaPreOP.respiratoryOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL, _.isEmpty(anesthesiaPreOP.gastrointestinal))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL, anesthesiaPreOP.gastrointestinal.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER, anesthesiaPreOP.gastrointestinalOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE, _.isEmpty(anesthesiaPreOP.endocrine))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL, anesthesiaPreOP.endocrine.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM, anesthesiaPreOP.endocrineDM)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER, anesthesiaPreOP.endocrineOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY, _.isEmpty(anesthesiaPreOP.hematoOncology))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL, anesthesiaPreOP.hematoOncology.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER, anesthesiaPreOP.hematoOncologyOther)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL, _.isEmpty(anesthesiaPreOP.renal))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL, anesthesiaPreOP.renal.split(","))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY, anesthesiaPreOP.renalInsufficiency)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER, anesthesiaPreOP.renalOther)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isGA, this.props.operatingRoom.modalEditAnPre.isGA)) {
			if (this.props.operatingRoom.modalEditAnPre.isGA) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.generalAppearanceDetail, this.props.operatingRoom.modalEditAnPre.generalAppearanceDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.generalAppearanceDetail, CBGeneralAppearance.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isAirwayNeck, this.props.operatingRoom.modalEditAnPre.isAirwayNeck)) {
			if (this.props.operatingRoom.modalEditAnPre.isAirwayNeck) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isAirwayExpectedDifficult, this.props.operatingRoom.modalEditAnPre.isAirwayExpectedDifficult)) {
			if (this.props.operatingRoom.modalEditAnPre.isAirwayExpectedDifficult) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isNeurologicalAssessment, this.props.operatingRoom.modalEditAnPre.isNeurologicalAssessment)) {
			if (this.props.operatingRoom.modalEditAnPre.isNeurologicalAssessment) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail, this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail, CBNeurologicalAssessment.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isBackExamination, this.props.operatingRoom.modalEditAnPre.isBackExamination)) {
			if (this.props.operatingRoom.modalEditAnPre.isBackExamination) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.backExaminationDetail, this.props.operatingRoom.modalEditAnPre.backExaminationDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.backExaminationDetail, CBBackExamination.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isCardiovascular, this.props.operatingRoom.modalEditAnPre.isCardiovascular)) {
			if (this.props.operatingRoom.modalEditAnPre.isCardiovascular) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.cardiovascularDetail, this.props.operatingRoom.modalEditAnPre.cardiovascularDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.cardiovascularDetail, CBCardiovascular.Hypertension)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION, true)
			}
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.cardiovascularDetail, CBCardiovascular.Functional_Capacity)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY, "")
			}
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.cardiovascularDetail, CBCardiovascular.Functional_Capacity)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isRespiratory, this.props.operatingRoom.modalEditAnPre.isRespiratory)) {
			if (this.props.operatingRoom.modalEditAnPre.isRespiratory) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.respiratoryDetail, this.props.operatingRoom.modalEditAnPre.respiratoryDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.respiratoryDetail, CBRespiratory.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isGastrointestinal, this.props.operatingRoom.modalEditAnPre.isGastrointestinal)) {
			if (this.props.operatingRoom.modalEditAnPre.isGastrointestinal) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.gastrointestinalDetail, this.props.operatingRoom.modalEditAnPre.gastrointestinalDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.gastrointestinalDetail, CBGastrointestinal.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isEndocrine, this.props.operatingRoom.modalEditAnPre.isEndocrine)) {
			if (this.props.operatingRoom.modalEditAnPre.isEndocrine) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.endocrineDetail, this.props.operatingRoom.modalEditAnPre.endocrineDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.endocrineDetail, CBEndocrine.DM)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM, "")
			}
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.endocrineDetail, CBEndocrine.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isHematoOncology, this.props.operatingRoom.modalEditAnPre.isHematoOncology)) {
			if (this.props.operatingRoom.modalEditAnPre.isHematoOncology) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.hematoOncologyDetail, this.props.operatingRoom.modalEditAnPre.hematoOncologyDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.hematoOncologyDetail, CBHematoOncology.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isRenal, this.props.operatingRoom.modalEditAnPre.isRenal)) {
			if (this.props.operatingRoom.modalEditAnPre.isRenal) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL, [])
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.renalDetail, this.props.operatingRoom.modalEditAnPre.renalDetail)) {
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.renalDetail, CBRenal.Insufficiency)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY, "")
			}
			if (!_.includes(this.props.operatingRoom.modalEditAnPre.renalDetail, CBRenal.Other)) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS, "1")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE, false)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE, "1")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL, [])
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER, "")
	}

	render() {
		return (
			<>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.generalAppearance)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isGA} onChange={this.onGA}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isGA}
							options={[
								{
									label: translate(ResourceAssistance.Message.obese),
									value: CBGeneralAppearance.Obese,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.cachexia),
									value: CBGeneralAppearance.Cachexia,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.pale),
									value: CBGeneralAppearance.Pale,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.jaundice),
									value: CBGeneralAppearance.Jaundice,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.cyanosis),
									value: CBGeneralAppearance.Cyanosis,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBGeneralAppearance.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.generalAppearanceDetail}
							onChange={this.onGeneralAppearanceDetail}
						/>
					</Col>
					<Col>
						<GInput
							required
							autoSize
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.generalAppearanceDetail, CBGeneralAppearance.Other)}
							value={this.props.operatingRoom.modalEditAnPre.generalAppearanceOther}
							onChange={this.onGeneralAppearanceOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.ASAClass)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.ASAClass} onChange={this.onASAClass}>
							<Radio style={{ flexDirection: "row" }} value={"1"}>
								{1}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"2"}>
								{2}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"3"}>
								{3}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"4"}>
								{4}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"5"}>
								{5}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox
							style={{ flexDirection: "row" }}
							checked={this.props.operatingRoom.modalEditAnPre.isASAClassEmergency}
							onChange={this.onASAClassEmergency}
						>
							{translate(ResourceAssistance.Message.emergency)}
						</Checkbox>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.airwayAssessment)}:</label>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.dentures)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayDentures} onChange={this.onAirwayDentures}>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.dentalDamage)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayDentalDamage} onChange={this.onAirwayDentalDamage}>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.thyromental)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayThyromental} onChange={this.onAirwayThyromental}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{ResourceAssistance.CONSTANT.greaterOrEqualTo6cm}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{ResourceAssistance.CONSTANT.lessThan6cm}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.mouthOpen)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayMouthOpen} onChange={this.onAirwayMouthOpen}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{ResourceAssistance.CONSTANT.greaterOrEqualTo2FB}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{ResourceAssistance.CONSTANT.lessThan2FB}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.hyomentalDistance)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayHyomentalDistance} onChange={this.onAirwayHyomentalDistance}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{ResourceAssistance.CONSTANT.greaterOrEqualTo2FB}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{ResourceAssistance.CONSTANT.lessThan2FB}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.neck)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayNeck} onChange={this.onAirwayNeck}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							required
							autoSize
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={this.props.operatingRoom.modalEditAnPre.isAirwayNeck}
							value={this.props.operatingRoom.modalEditAnPre.airwayNeckDetail}
							onChange={this.onAirwayNeckDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.mallampatiScore)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.airwayMallampatiScore} onChange={this.onAirwayMallampatiScore}>
							<Radio style={{ flexDirection: "row" }} value={"1"}>
								{translate(ResourceAssistance.Message.class1)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"2"}>
								{translate(ResourceAssistance.Message.class2)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"3"}>
								{translate(ResourceAssistance.Message.class3)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={"4"}>
								{translate(ResourceAssistance.Message.class4)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label>{translate(ResourceAssistance.Message.expectedDifficultAirway)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isAirwayExpectedDifficult} onChange={this.onAirwayExpectedDifficult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isAirwayExpectedDifficult}
							options={[
								{
									label: translate(ResourceAssistance.Message.ventilation),
									value: CBAirwayExpectedDifficult.Ventilation,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.intubation),
									value: CBAirwayExpectedDifficult.Intubation,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.airwayExpectedDifficultDetail}
							onChange={this.onAirwayExpectedDifficultDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.neurologicalAssessment)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isNeurologicalAssessment} onChange={this.onNeurologicalAssessment}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isNeurologicalAssessment}
							options={[
								{
									label: translate(ResourceAssistance.Message.mentalRetard),
									value: CBNeurologicalAssessment.Mental_Retard,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.oldCVAs),
									value: CBNeurologicalAssessment.Old_CVAs,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.seizuresEpilepsy),
									value: CBNeurologicalAssessment.Seizures_Epilepsy,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBNeurologicalAssessment.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail}
							onChange={this.onNeurologicalAssessmentDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentDetail, CBNeurologicalAssessment.Other)}
							value={this.props.operatingRoom.modalEditAnPre.neurologicalAssessmentOther}
							onChange={this.onNeurologicalAssessmentOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.backExamination)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isBackExamination} onChange={this.onBackExamination}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isBackExamination}
							options={[
								{
									label: translate(ResourceAssistance.Message.chronicPain),
									value: CBBackExamination.ChronicPain,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.myopathy),
									value: CBBackExamination.Myopathy,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBBackExamination.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.backExaminationDetail}
							onChange={this.onBackExaminationDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.backExaminationDetail, CBBackExamination.Other)}
							value={this.props.operatingRoom.modalEditAnPre.backExaminationOther}
							onChange={this.onBackExaminationOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.cardiovascular)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isCardiovascular} onChange={this.onCardiovascular}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isCardiovascular}
							options={[
								{
									label: [
										<Container key={1} fluid="small" className={ResourceAssistance.CSS.fullFlex}>
											<Row style={{ marginBottom: 0 }}>
												<Col md="auto">
													<label>{translate(ResourceAssistance.Message.hypertension)}</label>
												</Col>
												<Col>
													<Radio.Group
														disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.cardiovascularDetail, CBCardiovascular.Hypertension)}
														value={this.props.operatingRoom.modalEditAnPre.isCardiovascularHypertension}
														onChange={this.onCardiovascularHypertension}
													>
														<Radio style={{ flexDirection: "row" }} value={true}>
															{translate(ResourceAssistance.Message.controlled)}
														</Radio>
														<Radio style={{ flexDirection: "row" }} value={false}>
															{translate(ResourceAssistance.Message.poorlyControlled)}
														</Radio>
													</Radio.Group>
												</Col>
											</Row>
										</Container>,
									],
									value: CBCardiovascular.Hypertension,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.CAD),
									value: CBCardiovascular.CAD,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.arrhythmia),
									value: CBCardiovascular.Arrhythmia,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.dyslipidemia),
									value: CBCardiovascular.Dyslipidemia,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.functionalCapacity)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												suffix={ResourceAssistance.CONSTANT.METs}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.cardiovascularDetail, CBCardiovascular.Functional_Capacity)}
												value={this.props.operatingRoom.modalEditAnPre.cardiovascularFunctionalCapacity}
												onChange={this.onCardiovascularFunctionalCapacity}
											/>
										</Space>,
									],
									value: CBCardiovascular.Functional_Capacity,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBCardiovascular.Other,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.cardiovascularDetail}
							onChange={this.onCardiovascularDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.cardiovascularDetail, CBCardiovascular.Other)}
							placeholder=""
							value={this.props.operatingRoom.modalEditAnPre.cardiovascularOther}
							onChange={this.onCardiovascularOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.respiratory)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isRespiratory} onChange={this.onRespiratory}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isRespiratory}
							options={[
								{
									label: translate(ResourceAssistance.Message.asthma),
									value: CBRespiratory.Asthma,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.COPD),
									value: CBRespiratory.COPD,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.TB),
									value: CBRespiratory.TB,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.recentURI),
									value: CBRespiratory.RecentURI,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBRespiratory.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.respiratoryDetail}
							onChange={this.onRespiratoryDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.respiratoryDetail, CBRespiratory.Other)}
							value={this.props.operatingRoom.modalEditAnPre.respiratoryOther}
							onChange={this.onRespiratoryOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.gastrointestinal)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isGastrointestinal} onChange={this.onGastrointestinal}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isGastrointestinal}
							options={[
								{
									label: translate(ResourceAssistance.Message.fullStomach),
									value: CBGastrointestinal.Full_Stomach,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.hepatitis),
									value: CBGastrointestinal.Hepatitis,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.cirrhosis),
									value: CBGastrointestinal.Cirrhosis,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBGastrointestinal.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.gastrointestinalDetail}
							onChange={this.onGastrointestinalDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.gastrointestinalDetail, CBGastrointestinal.Other)}
							value={this.props.operatingRoom.modalEditAnPre.gastrointestinalOther}
							onChange={this.onGastrointestinalOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.endocrine)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isEndocrine} onChange={this.onEndocrine}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isEndocrine}
							options={[
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.DM)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												prefix={translate(ResourceAssistance.Message.type)}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.endocrineDetail, CBEndocrine.DM)}
												value={this.props.operatingRoom.modalEditAnPre.endocrineDMType}
												onChange={this.onEndocrineDMType}
											/>
										</Space>,
									],
									value: CBEndocrine.DM,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.hypoHyperthyroid),
									value: CBEndocrine.Hyperthyroid,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.steroidUse),
									value: CBEndocrine.Steroid_Use,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBEndocrine.Other,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.endocrineDetail}
							onChange={this.onEndocrineDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.endocrineDetail, CBEndocrine.Other)}
							value={this.props.operatingRoom.modalEditAnPre.endocrineOther}
							onChange={this.onEndocrineOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.hematoOncology)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isHematoOncology} onChange={this.onHematoOncology}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isHematoOncology}
							options={[
								{
									label: translate(ResourceAssistance.Message.anemia),
									value: CBHematoOncology.Anemia,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.bleeding),
									value: CBHematoOncology.Bleeding,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.recentChemotherapy),
									value: CBHematoOncology.Recent_Chemotherapy,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBHematoOncology.Other,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.hematoOncologyDetail}
							onChange={this.onHematoOncologyDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.hematoOncologyDetail, CBHematoOncology.Other)}
							value={this.props.operatingRoom.modalEditAnPre.hematoOncologyOther}
							onChange={this.onHematoOncologyOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.renal)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isRenal} onChange={this.onRenal}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col md="auto">
						<Checkbox.Group
							disabled={this.props.operatingRoom.modalEditAnPre.isRenal}
							options={[
								{
									label: [
										<Space key={1}>
											<label>{translate(ResourceAssistance.Message.insufficiency)}</label>
											<GInput
												size={"small"}
												style={{ marginTop: "-10px" }}
												styles={{ input: { textAlign: "center" } }}
												prefix={ResourceAssistance.CONSTANT.CrCl}
												suffix={ResourceAssistance.CONSTANT.ML_MIN}
												disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.renalDetail, CBRenal.Insufficiency)}
												value={this.props.operatingRoom.modalEditAnPre.renalInsufficiency}
												onChange={this.onRenalInsufficiency}
											/>
										</Space>,
									],
									value: CBRenal.Insufficiency,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.ESRD),
									value: CBRenal.ESRD,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.hemodialysis),
									value: CBRenal.Hemodialysis,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
								{
									label: translate(ResourceAssistance.Message.other),
									value: CBRenal.Other,
									style: { flexDirection: "row", alignItems: "center", marginTop: "7px" },
								},
							]}
							value={this.props.operatingRoom.modalEditAnPre.renalDetail}
							onChange={this.onRenalDetail}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.includes(this.props.operatingRoom.modalEditAnPre.renalDetail, CBRenal.Other)}
							value={this.props.operatingRoom.modalEditAnPre.renalOther}
							onChange={this.onRenalOther}
						/>
					</Col>
				</Row>
			</>
		)
	}

	onGA(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA, e.target.value)
	}
	onGeneralAppearanceDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL, values)
	}
	onGeneralAppearanceOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER, e.target.value)
	}
	onASAClass(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS, e.target.value)
	}
	onASAClassEmergency(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY, e.target.checked)
	}
	onAirwayDentures(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES, e.target.value)
	}
	onAirwayDentalDamage(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE, e.target.value)
	}
	onAirwayThyromental(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL, e.target.value)
	}
	onAirwayMouthOpen(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN, e.target.value)
	}
	onAirwayHyomentalDistance(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE, e.target.value)
	}
	onAirwayNeck(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK, e.target.value)
	}
	onAirwayNeckDetail(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL, e.target.value)
	}
	onAirwayMallampatiScore(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE, e.target.value)
	}
	onAirwayExpectedDifficult(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT, e.target.value)
	}
	onAirwayExpectedDifficultDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL, values)
	}
	onNeurologicalAssessment(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT, e.target.value)
	}
	onNeurologicalAssessmentDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL, values)
	}
	onNeurologicalAssessmentOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER, e.target.value)
	}
	onBackExamination(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION, e.target.value)
	}
	onBackExaminationDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL, values)
	}
	onBackExaminationOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER, e.target.value)
	}
	onCardiovascular(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR, e.target.value)
	}
	onCardiovascularHypertension(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION, e.target.value)
	}
	onCardiovascularFunctionalCapacity(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY, e.target.value)
	}
	onCardiovascularDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL, values)
	}
	onCardiovascularOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER, e.target.value)
	}
	onRespiratory(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY, e.target.value)
	}
	onRespiratoryDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL, values)
	}
	onRespiratoryOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER, e.target.value)
	}
	onGastrointestinal(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL, e.target.value)
	}
	onGastrointestinalDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL, values)
	}
	onGastrointestinalOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER, e.target.value)
	}
	onEndocrine(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE, e.target.value)
	}
	onEndocrineDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL, values)
	}
	onEndocrineDMType(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM, e.target.value)
	}
	onEndocrineOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER, e.target.value)
	}
	onHematoOncology(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY, e.target.value)
	}
	onHematoOncologyDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL, values)
	}
	onHematoOncologyOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER, e.target.value)
	}
	onRenal(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL, e.target.value)
	}
	onRenalDetail(values) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL, values)
	}
	onRenalInsufficiency(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY, e.target.value)
	}
	onRenalOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPrePhysicalExam))
