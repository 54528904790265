import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import OperatingRoomModalIntraAneRecordDetails from "./OperatingRoomModalIntraAneRecordDetails"
import _ from "lodash"

class OperatingRoomModalIntraAneRecord extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.operatingRoom.selectedIntraAneRecord.record &&
			this.props.isDisplay &&
			(!_.isEqual(
				prevProps.operatingRoom.selectedIntraAneRecord.record ? prevProps.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime : undefined,
				this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaStartDateTime
			) ||
				!_.isEqual(
					prevProps.operatingRoom.selectedIntraAneRecord.record ? prevProps.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime : undefined,
					this.props.operatingRoom.selectedIntraAneRecord.record.anesthesiaEndDateTime
				) ||
				!_.isEqual(
					prevProps.operatingRoom.selectedIntraAneRecord.record ? prevProps.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime : undefined,
					this.props.operatingRoom.selectedIntraAneRecord.record.operativeStartDateTime
				) ||
				!_.isEqual(
					prevProps.operatingRoom.selectedIntraAneRecord.record ? prevProps.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime : undefined,
					this.props.operatingRoom.selectedIntraAneRecord.record.operativeEndDateTime
				))
		) {
			this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY, true)
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.anesthesiaRecord)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalIntraAneRecordDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomIntraAnesthesiaRecordDisplay,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalIntraAneRecord)
