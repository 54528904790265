import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction } from "~/redux/action"
import ConfigService from "./service/ConfigService"
import "./ConfigServiceHome.css"

class ConfigServiceHome extends React.Component {
	EConfigService = {
		SERVICE: "CONFIG_SERVICE",
	}

	constructor(props) {
		super(props)

		this.state = {
			componentName: "ConfigServiceHome",
			tab: this.EConfigService.SERVICE,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadTypes = this.loadTypes.bind(this)
		this.loadRoles = this.loadRoles.bind(this)
		this.loadAdpType = this.loadAdpType.bind(this)
	}

	loadTypes(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.service.getServices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res.data.types)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	loadRoles(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.service.getRoles,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: true },
		}
		let callback = (res) => {
			func(res.data.types)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	async loadAdpType(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getAdpType,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.wfep)
		}
		await axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	async loadLabTest(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getLabTest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.wfep)
		}
		await axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
							<Tab eventKey={this.EConfigService.SERVICE} title={translate(ResourceAssistance.Hospitel.service)} name={this.EConfigService.SERVICE}>
								{this.state.tab === this.EConfigService.SERVICE && (
									<ConfigService loadTypes={this.loadTypes} loadRoles={this.loadRoles} loadAdpType={this.loadAdpType} loadLabTest={this.loadLabTest} />
								)}
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigServiceHome)
