import { Divider } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setContractBillingBillings, setLoadingAction, setSelected } from "~/redux/action"
import {
	SET_CONTRACT_BILLING_NEW_DISPLAY,
	SET_CONTRACT_BILLING_SEARCH_DISPLAY,
	SET_CONTRACT_BILLING_SELECTED_BILLING,
	SET_CONTRACT_BILLING_SELECTED_INVOICE,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ContractBillingDataFactory from "~/utils/factory/print/fiannce/ContractBillingDataFactory"

class ContractBillingLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onPrint = this.onPrint.bind(this)
		this.onSearchContractBilling = this.onSearchContractBilling.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	isCompleteDisabled() {
		return (
			!this.props.contract.selectedBilling.billing ||
			this.props.contract.selectedBilling.billing.complete ||
			!this.props.contract.selectedBilling.billing.dueDateTime ||
			_.isEmpty(this.props.contract.selectedBilling.billing.factoryArea) ||
			_.isEmpty(this.props.contract.selectedBilling.billing.insuranceCompanyAddress) ||
			_.isEmpty(this.props.contract.selectedBilling.billing.insuranceCompanyTel)
		)
	}

	onComplete() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.contractBilling.completeContractBilling,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.contract.selectedBilling.billing.id,
				userId: this.props.login.user.id,
				orgId: this.props.contract.location.id,
				complete: true,
			},
		}
		let callback = (res) => {
			if (res.data.contractBillings.length > 0) {
				let billings = this.props.contract.billingTable.original.filter((each) => each.id !== res.data.contractBillings[0].id)
				billings.push(res.data.contractBillings[0])
				this.props.setContractBillingBillings(billings)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPrint() {
		let data = ContractBillingDataFactory.generatePdfBillingData(
			this.props.contract.location,
			this.props.contract.selectedBilling.billing,
			Utils.getLogoImg(this.props.common.orgId, this.props.common.orgs),
		)

		let myWindow = window.open(ResourceAssistance.Path.Report.finance.contractBilling.billing, "_blank")
		myWindow.data = data
	}

	onSearchContractBilling(event) {
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_SEARCH_DISPLAY, true)
	}

	onNew(event) {
		this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_BILLING, -1, null)
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_INVOICE, -1, null)
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.green}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isCompleteDisabled()}
						onClick={this.onComplete}
					>
						<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.complete)}
					</Button>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={!this.props.contract.selectedBilling.billing || !this.props.contract.selectedBilling.billing.complete}
						onClick={this.onPrint}
					>
						<ImPrinter size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.print)}
					</Button>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearchContractBilling}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={!this.props.contract.selectedBilling.billing || this.props.contract.selectedBilling.billing.complete}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	contract: state.accounting.contract,
	common: state.common
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setContractBillingBillings,
			setLoadingAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingLeftAction))
