

export const SET_OR_ANESTHESIAS = "SET_OR_ANESTHESIAS"
export const SET_OR_ANESTHESIAS_LOADING = "SET_OR_ANESTHESIAS_LOADING"
export const SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY = "SET_OR_ANESTHESIOLOGIST_EDIT_PRE_DISPLAY"
export const SET_OR_CALENDAR_END_DATETIME = "SET_OR_CALENDAR_END_DATETIME"
export const SET_OR_CALENDAR_START_DATETIME = "SET_OR_CALENDAR_START_DATETIME"
export const SET_OR_CANCEL_DISPLAY = "SET_OR_CANCEL_DISPLAY"
export const SET_OR_DOCTORS = "SET_OR_DOCTORS"
export const SET_OR_DOCTORS_LOADING = "SET_OR_DOCTORS_LOADING"
export const SET_OR_DOCTOR_ITEM_TYPES = "SET_OR_DOCTOR_ITEM_TYPES"
export const SET_OR_DOCTOR_ITEM_TYPES_LOADING = "SET_OR_DOCTOR_ITEM_TYPES_LOADING"
export const SET_OR_EDIT_REQUEST_DISPLAY = "SET_OR_EDIT_REQUEST_DISPLAY"
export const SET_OR_END_DATETIME = "SET_OR_END_DATETIME"
export const SET_OR_ICDS = "SET_OR_ICDS"
export const SET_OR_ICDS_LOADING = "SET_OR_ICDS_LOADING"
export const SET_OR_INTAKES = "SET_OR_INTAKES"
export const SET_OR_INTAKES_LOADING = "SET_OR_INTAKES_LOADING"
export const SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY = "SET_OR_INTRA_ANESTHESIA_AIRWAY_MANAGEMENT_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY = "SET_OR_INTRA_ANESTHESIA_BABY_DELIVERY_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY = "SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_MEDICATION_DISPLAY = "SET_OR_INTRA_ANESTHESIA_MEDICATION_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY = "SET_OR_INTRA_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_PATIENT_CONDITION_DISPLAY = "SET_OR_INTRA_ANESTHESIA_PATIENT_CONDITION_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_PATIENT_SAFETY_DISPLAY = "SET_OR_INTRA_ANESTHESIA_PATIENT_SAFETY_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_PREINDUCTION_ASSESSMENT_DISPLAY = "SET_OR_INTRA_ANESTHESIA_PREINDUCTION_ASSESSMENT_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_PREMEDICATION_DISPLAY = "SET_OR_INTRA_ANESTHESIA_PREMEDICATION_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_PROBLEM_DISPLAY = "SET_OR_INTRA_ANESTHESIA_PROBLEM_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY = "SET_OR_INTRA_ANESTHESIA_RECORD_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY = "SET_OR_INTRA_ANESTHESIA_RECORD_TIME_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY = "SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY = "SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_TRANSFER_DISPLAY = "SET_OR_INTRA_ANESTHESIA_TRANSFER_DISPLAY"
export const SET_OR_INTRA_ANESTHESIA_VITAL_SIGN_DISPLAY = "SET_OR_INTRA_ANESTHESIA_VITAL_SIGN_DISPLAY"
export const SET_OR_INTRA_ANE_END_DATE_TIME = "SET_OR_INTRA_ANE_END_DATE_TIME"
export const SET_OR_INTRA_ANE_START_DATE_TIME = "SET_OR_INTRA_ANE_START_DATE_TIME"
export const SET_OR_ITEMS = "SET_OR_ITEMS"
export const SET_OR_ITEMS_LOADING = "SET_OR_ITEMS_LOADING"
export const SET_OR_LOCATION = "SET_OR_LOCATION"
export const SET_OR_LOCATION_DISPLAY = "SET_OR_LOCATION_DISPLAY"
export const SET_OR_MODAL_CANCEL_REASON = "SET_OR_MODAL_CANCEL_REASON"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTAL_DAMAGE"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_DENTURES"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_EXPECTED_DIFFICULT_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_HYOMENTAL_DISTANCE"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MALLAMPATI_SCORE"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_MOUTH_OPEN"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_NECK_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL = "SET_OR_MODAL_EDIT_ANE_PRE_AIRWAY_THYROMENTAL"
export const SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION = "SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_PRE_MEDICATION"
export const SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL = "SET_OR_MODAL_EDIT_ANE_PRE_ANESTHESIA_PLAN_REGIONAL"
export const SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV = "SET_OR_MODAL_EDIT_ANE_PRE_ANTI_HIV"
export const SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS = "SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS"
export const SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY = "SET_OR_MODAL_EDIT_ANE_PRE_ASA_CLASS_EMERGENCY"
export const SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION = "SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION"
export const SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_BACK_EXAMINATION_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR = "SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR"
export const SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY = "SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_FUNCTIONAL_CAPACITY"
export const SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION = "SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_HYPERTENSION"
export const SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_CARDIOVASCULAR_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE = "SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE"
export const SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT = "SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT"
export const SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB = "SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB"
export const SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS = "SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS"
export const SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC = "SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC"
export const SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR = "SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR"
export const SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT = "SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT"
export const SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT = "SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT"
export const SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING = "SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING"
export const SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA = "SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_FRESH_FROZEN_PLASMA"
export const SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL = "SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PACKED_RED_CELL"
export const SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE = "SET_OR_MODAL_EDIT_ANE_PRE_CROSS_MATCHING_PLATELET_CONCENTRATE"
export const SET_OR_MODAL_EDIT_ANE_PRE_CXR = "SET_OR_MODAL_EDIT_ANE_PRE_CXR"
export const SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_EKG = "SET_OR_MODAL_EDIT_ANE_PRE_EKG"
export const SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3 = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K"
export const SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA = "SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA"
export const SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE = "SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE"
export const SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM = "SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_DM"
export const SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_ENDOCRINE_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_GA = "SET_OR_MODAL_EDIT_ANE_PRE_GA"
export const SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL = "SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL"
export const SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_GASTROINTESTINAL_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_GA_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_GA_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY = "SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY"
export const SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_HEMATO_ONCOLOGY_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN = "SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN"
export const SET_OR_MODAL_EDIT_ANE_PRE_LMP = "SET_OR_MODAL_EDIT_ANE_PRE_LMP"
export const SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE = "SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE"
export const SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_MEDICATION_USAGE_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT = "SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT"
export const SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_NEUROLOGICAL_ASSESSMENT_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION = "SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION"
export const SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT = "SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT"
export const SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_PREGNANT_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_RENAL = "SET_OR_MODAL_EDIT_ANE_PRE_RENAL"
export const SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_RENAL_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY = "SET_OR_MODAL_EDIT_ANE_PRE_RENAL_INSUFFICIENCY"
export const SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_RENAL_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY = "SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY"
export const SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_DETAIL"
export const SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER = "SET_OR_MODAL_EDIT_ANE_PRE_RESPIRATORY_OTHER"
export const SET_OR_MODAL_EDIT_ANE_PRE_SMOKING = "SET_OR_MODAL_EDIT_ANE_PRE_SMOKING"
export const SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL = "SET_OR_MODAL_EDIT_ANE_PRE_SMOKING_DETAIL"
export const SET_OR_MODAL_EDIT_REQUEST_OPERATION_PROCEDURE = "SET_OR_MODAL_EDIT_REQUEST_OPERATION_PROCEDURE"
export const SET_OR_MODAL_EDIT_REQUEST_PRE_OPERATIVE_DIAG = "SET_OR_MODAL_EDIT_REQUEST_PRE_OPERATIVE_DIAG"
export const SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA = "SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA"
export const SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST = "SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST"
export const SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE = "SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE"
export const SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_DOCTOR = "SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_DOCTOR"
export const SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_ICD = "SET_OR_MODAL_EDIT_REQUEST_SELECTED_OPERATIVE_ICD"
export const SET_OR_MODAL_EDIT_REQUEST_SELECTED_PRE_DIAG_ICD = "SET_OR_MODAL_EDIT_REQUEST_SELECTED_PRE_DIAG_ICD"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR="SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ADEQ_SPONT_VENTILATOR"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_AFTER_INTUBATION"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_ASSISTED_DEVICES"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_SIZE"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BLADE_TYPE_STYLE"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_BREATHING_SYSTEM"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_COUGH_ON_SUCTION"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CUFFED_INFLATION"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_CYANOSIS"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_LT"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DLT_RT"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_DYSPNEA"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_HAND_GRIP"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_INTUBATION_TECHNIQUE"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_ATTEMPT"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_LARYNGEAL_VIEW_DIFFICULTY"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_CUFF"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_DEPTH"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ET_TUBE_INTUBATION"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_LMA"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_NASAL_AIRWAY"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_ORAL_AIRWAY"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_METHOD_TRACHEOSTOMY"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG ="SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_SWALLOWING_GAG"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_TYPE"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_UAO"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_VERBAL_COMMAND"
export const SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING = "SET_OR_MODAL_INTRA_ANE_AIRWAY_MANAGEMENT_WHEEZING"
export const SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER = "SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER"
export const SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB = "SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB"
export const SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE = "SET_OR_MODAL_INTRA_ANE_IO_FILTER_INTAKE"
export const SET_OR_MODAL_INTRA_ANE_IO_FILTER_OUTPUT = "SET_OR_MODAL_INTRA_ANE_IO_FILTER_OUTPUT"
export const SET_OR_MODAL_INTRA_ANE_IO_INTAKES = "SET_OR_MODAL_INTRA_ANE_IO_INTAKES"
export const SET_OR_MODAL_INTRA_ANE_IO_OUTPUTS = "SET_OR_MODAL_INTRA_ANE_IO_OUTPUTS"
export const SET_OR_MODAL_INTRA_ANE_IO_PENDING_INTAKES = "SET_OR_MODAL_INTRA_ANE_IO_PENDING_INTAKES"
export const SET_OR_MODAL_INTRA_ANE_IO_PENDING_OUTPUTS = "SET_OR_MODAL_INTRA_ANE_IO_PENDING_OUTPUTS"
export const SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE = "SET_OR_MODAL_INTRA_ANE_IO_SELECTED_INTAKE"
export const SET_OR_MODAL_INTRA_ANE_IO_SELECTED_OUTPUT = "SET_OR_MODAL_INTRA_ANE_IO_SELECTED_OUTPUT"
export const SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE = "SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_INTAKE"
export const SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_OUTPUT = "SET_OR_MODAL_INTRA_ANE_IO_SELECTED_PENDING_OUTPUT"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME = "SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY = "SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_FILTER_GENERIC_NAME = "SET_OR_MODAL_INTRA_ANE_MEDICATION_FILTER_GENERIC_NAME"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_ITEMS = "SET_OR_MODAL_INTRA_ANE_MEDICATION_ITEMS"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES = "SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_PENDING_ITEMS = "SET_OR_MODAL_INTRA_ANE_MEDICATION_PENDING_ITEMS"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION = "SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_ITEM = "SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_ITEM"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_PENDING_ITEM = "SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_PENDING_ITEM"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE = "SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_TYPE = "SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_TYPE"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT = "SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT"
export const SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS = "SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION = "SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION = "SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED="SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_ARMS_CHECKED"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL = "SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_DETAIL"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER = "SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_EYES_CARE_OTHER"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED = "SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_MACHINE_CHECKED"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED = "SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_PRESSURE_CHECKED"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL = "SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_DETAIL"
export const SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER = "SET_OR_MODAL_INTRA_ANE_PATIENT_SAFETY_WARMING_OTHER"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_ASA_EMERGENCY"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_BP"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CHANGES_OTHER"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSCIOUSNESS"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_CONSENT_SIGNED"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_HR"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_LAB_REVIEWED"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_NPO"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PATIENT_IDENTIFIED"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_PREDICTED_DIFFICULT"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_RISK_ASPIRATION"
export const SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2 = "SET_OR_MODAL_INTRA_ANE_PREINDUCTION_ASSESSMENT_SPO2"
export const SET_OR_MODAL_INTRA_ANE_PREMEDICATION_DESCRIPTION = "SET_OR_MODAL_INTRA_ANE_PREMEDICATION_DESCRIPTION"
export const SET_OR_MODAL_INTRA_ANE_PROBLEM_DESCRIPTION = "SET_OR_MODAL_INTRA_ANE_PROBLEM_DESCRIPTION"
export const SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME = "SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME"
export const SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME = "SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME"
export const SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME = "SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME"
export const SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME = "SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME"
export const SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION = "SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION"
export const SET_OR_MODAL_INTRA_ANE_SERVICE_PENDING_SERVICES = "SET_OR_MODAL_INTRA_ANE_SERVICE_PENDING_SERVICES"
export const SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_PENDING_SERVICE = "SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_PENDING_SERVICE"
export const SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE = "SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE"
export const SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE = "SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE"
export const SET_OR_MODAL_INTRA_ANE_SERVICE_SERVICES = "SET_OR_MODAL_INTRA_ANE_SERVICE_SERVICES"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_AGENT_USED"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ANESTHESIA_LEVEL"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_APPROACH_OTHER"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_ASEPTIC"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_CATHETER"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_EQUIPMENT"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_GENERAL_ANESTHESIA"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_INDUCTION"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MAINTENANCE_OTHER"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_MONITOR_ANESTHESIA"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_NEEDLE_SIZE"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_POSITION"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_PUNCTURE_ATTEMPTING_COUNTS"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_REGIONAL_ANESTHESIA_OTHER"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SEDATE"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_SITE"
export const SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE = "SET_OR_MODAL_INTRA_ANE_TECHNIQUE_TYPE"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT=  "SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_BP = "SET_OR_MODAL_INTRA_ANE_TRANSFER_BP"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS = "SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_HR = "SET_OR_MODAL_INTRA_ANE_TRANSFER_HR"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN = "SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION = "SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2 = "SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_TO = "SET_OR_MODAL_INTRA_ANE_TRANSFER_TO"
export const SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER = "SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER"
export const SET_OR_MODAL_INTRA_ANE_VITAL_SIGNS = "SET_OR_MODAL_INTRA_ANE_VITAL_SIGNS"
export const SET_OR_MODAL_INTRA_ANE_VS_ALINE = "SET_OR_MODAL_INTRA_ANE_VS_ALINE"
export const SET_OR_MODAL_INTRA_ANE_VS_DATE_TIME = "SET_OR_MODAL_INTRA_ANE_VS_DATE_TIME"
export const SET_OR_MODAL_INTRA_ANE_VS_NIBP = "SET_OR_MODAL_INTRA_ANE_VS_NIBP"
export const SET_OR_MODAL_INTRA_ANE_VS_PULSE = "SET_OR_MODAL_INTRA_ANE_VS_PULSE"
export const SET_OR_MODAL_INTRA_ANE_VS_SELECTED_VS = "SET_OR_MODAL_INTRA_ANE_VS_SELECTED_VS"
export const SET_OR_MODAL_LOCATION_SELECTED_BRANCH = "SET_OR_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_OR_MODAL_LOCATION_SELECTED_ORG = "SET_OR_MODAL_LOCATION_SELECTED_ORG"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA"
export const SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK = "SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK"
export const SET_OR_MODAL_POST_ANE_COMPLICATION = "SET_OR_MODAL_POST_ANE_COMPLICATION"
export const SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER = "SET_OR_MODAL_POST_ANE_COMPLICATION_OTHER"
export const SET_OR_MODAL_POST_ANE_IO_FILTER_INTAKE = "SET_OR_MODAL_POST_ANE_IO_FILTER_INTAKE"
export const SET_OR_MODAL_POST_ANE_IO_FILTER_OUTPUT = "SET_OR_MODAL_POST_ANE_IO_FILTER_OUTPUT"
export const SET_OR_MODAL_POST_ANE_IO_INTAKES = "SET_OR_MODAL_POST_ANE_IO_INTAKES"
export const SET_OR_MODAL_POST_ANE_IO_OUTPUTS = "SET_OR_MODAL_POST_ANE_IO_OUTPUTS"
export const SET_OR_MODAL_POST_ANE_IO_PENDING_INTAKES = "SET_OR_MODAL_POST_ANE_IO_PENDING_INTAKES"
export const SET_OR_MODAL_POST_ANE_IO_PENDING_OUTPUTS = "SET_OR_MODAL_POST_ANE_IO_PENDING_OUTPUTS"
export const SET_OR_MODAL_POST_ANE_IO_SELECTED_INTAKE = "SET_OR_MODAL_POST_ANE_IO_SELECTED_INTAKE"
export const SET_OR_MODAL_POST_ANE_IO_SELECTED_OUTPUT = "SET_OR_MODAL_POST_ANE_IO_SELECTED_OUTPUT"
export const SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_INTAKE = "SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_INTAKE"
export const SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_OUTPUT = "SET_OR_MODAL_POST_ANE_IO_SELECTED_PENDING_OUTPUT"
export const SET_OR_MODAL_POST_ANE_MEDICATION_DATE_TIME= "SET_OR_MODAL_POST_ANE_MEDICATION_DATE_TIME"
export const SET_OR_MODAL_POST_ANE_MEDICATION_DURATION_QTY = "SET_OR_MODAL_POST_ANE_MEDICATION_DURATION_QTY"
export const SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME = "SET_OR_MODAL_POST_ANE_MEDICATION_FILTER_GENERIC_NAME"
export const SET_OR_MODAL_POST_ANE_MEDICATION_ITEMS = "SET_OR_MODAL_POST_ANE_MEDICATION_ITEMS"
export const SET_OR_MODAL_POST_ANE_MEDICATION_NOTES = "SET_OR_MODAL_POST_ANE_MEDICATION_NOTES"
export const SET_OR_MODAL_POST_ANE_MEDICATION_PENDING_ITEMS = "SET_OR_MODAL_POST_ANE_MEDICATION_PENDING_ITEMS"
export const SET_OR_MODAL_POST_ANE_MEDICATION_RATE_DURATION = "SET_OR_MODAL_POST_ANE_MEDICATION_RATE_DURATION"
export const SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM = "SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_ITEM"
export const SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM = "SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_PENDING_ITEM"
export const SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_RATE = "SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_RATE"
export const SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE = "SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_TYPE"
export const SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_UNIT = "SET_OR_MODAL_POST_ANE_MEDICATION_SELECTED_UNIT"
export const SET_OR_MODAL_POST_ANE_MEDICATION_UNITS = "SET_OR_MODAL_POST_ANE_MEDICATION_UNITS"
export const SET_OR_MODAL_POST_ANE_NURSE_DESCRIPTION = "SET_OR_MODAL_POST_ANE_NURSE_DESCRIPTION"
export const SET_OR_MODAL_POST_ANE_PADSS_AMBULATION = "SET_OR_MODAL_POST_ANE_PADSS_AMBULATION"
export const SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE = "SET_OR_MODAL_POST_ANE_PADSS_BP_PULSE"
export const SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING = "SET_OR_MODAL_POST_ANE_PADSS_NAUSEA_VOMITTING"
export const SET_OR_MODAL_POST_ANE_PADSS_PAIN = "SET_OR_MODAL_POST_ANE_PADSS_PAIN"
export const SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING = "SET_OR_MODAL_POST_ANE_PADSS_SURGICAL_BLEEDING"
export const SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME = "SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME"
export const SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME = "SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME"
export const SET_OR_MODAL_POST_ANE_SERVICE_FILTER_DESCRIPTION = "SET_OR_MODAL_POST_ANE_SERVICE_FILTER_DESCRIPTION"
export const SET_OR_MODAL_POST_ANE_SERVICE_PENDING_SERVICES = "SET_OR_MODAL_POST_ANE_SERVICE_PENDING_SERVICES"
export const SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE = "SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_PENDING_SERVICE"
export const SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_SERVICE = "SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_SERVICE"
export const SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_TYPE = "SET_OR_MODAL_POST_ANE_SERVICE_SELECTED_TYPE"
export const SET_OR_MODAL_POST_ANE_SERVICE_SERVICES = "SET_OR_MODAL_POST_ANE_SERVICE_SERVICES"
export const SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB = "SET_OR_MODAL_POST_ANE_TRANSFER_SELECTED_SUB"
export const SET_OR_MODAL_POST_ANE_TRANSFER_TO = "SET_OR_MODAL_POST_ANE_TRANSFER_TO"
export const SET_OR_MODAL_POST_ANE_VITAL_SIGNS = "SET_OR_MODAL_POST_ANE_VITAL_SIGNS"
export const SET_OR_MODAL_REMARK_REASON = "SET_OR_MODAL_REMARK_REASON"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_ANE_MACHINE_CHECK"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_CONFIRM_PATIENT_IDENTITY"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DIFFICULT_AIRWAY"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_KNOW_ALLERGY"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_MEDICAL_DEVICE_IMPLANT"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_OR_EQUIPMENT"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PATIENT_SIGN_CONSENT"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_PULSE_OXIMETER_FUNCTIONING"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_RISK_BLOOD_LOSS"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_SITE_MARKED"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_COMPLETION_OF_COUNTS"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_EQUIPMENT_PROBLEM"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_PROCEDURE_NAME"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_RECOVERY_CONCERN"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_SPECIMEN_LABELING"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ANTICIPATED_BLOOD_LOSS"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CASE_TAKE_DURATION"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CONFIRM_TEAM_MEMBERS"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_CRITICAL_STEPS"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_EQUIPMENT_ISSUES"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_ESSENTIAL_IMAGING_DISPLAYED"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_HAS_ANTIBIOTIC"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_NAME"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PATIENT_SPECIFIC_CONCERNS"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_PROCEDURE_SITE_AND_SIDE"
export const SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED = "SET_OR_MODAL_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_STERILIZATION_CONFIRMED"
export const SET_OR_MODAL_VS_BP = "SET_OR_MODAL_VS_BP"
export const SET_OR_MODAL_VS_DATE_TIME = "SET_OR_MODAL_VS_DATE_TIME"
export const SET_OR_MODAL_VS_OXYGEN_SAT = "SET_OR_MODAL_VS_OXYGEN_SAT"
export const SET_OR_MODAL_VS_PR = "SET_OR_MODAL_VS_PR"
export const SET_OR_MODAL_VS_RR = "SET_OR_MODAL_VS_RR"
export const SET_OR_MODAL_VS_TEMP = "SET_OR_MODAL_VS_TEMP"
export const SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST = "SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST"
export const SET_OR_OPERATING_ROOMS = "SET_OR_OPERATING_ROOMS"
export const SET_OR_OPERATING_ROOMS_LOADING = "SET_OR_OPERATING_ROOMS_LOADING"
export const SET_OR_OPERATING_ROOM_CASES = "SET_OR_OPERATING_ROOM_CASES"
export const SET_OR_OPERATING_ROOM_CASES_LOADING = "SET_OR_OPERATING_ROOM_CASES_LOADING"
export const SET_OR_OPERATING_ROOM_REQUESTS = "SET_OR_OPERATING_ROOM_REQUESTS"
export const SET_OR_OPERATING_ROOM_REQUESTS_LOADING = "SET_OR_OPERATING_ROOM_REQUESTS_LOADING"
export const SET_OR_ORGS = "SET_OR_ORGS"
export const SET_OR_ORGS_LOADING = "SET_OR_ORGS_LOADING"
export const SET_OR_OUTPUTS = "SET_OR_OUTPUTS"
export const SET_OR_OUTPUTS_LOADING = "SET_OR_OUTPUTS_LOADING"
export const SET_OR_POST_ANESTHESIA_COMPLICATION_DISPLAY = "SET_OR_POST_ANESTHESIA_COMPLICATION_DISPLAY"
export const SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA = "SET_OR_POST_ANESTHESIA_DISCHARGE_CRITERIA"
export const SET_OR_POST_ANESTHESIA_DISCHARGE_DISPLAY = "SET_OR_POST_ANESTHESIA_DISCHARGE_DISPLAY"
export const SET_OR_POST_ANESTHESIA_INTAKE_OUTPUT_DISPLAY = "SET_OR_POST_ANESTHESIA_INTAKE_OUTPUT_DISPLAY"
export const SET_OR_POST_ANESTHESIA_MEDICATION_DISPLAY = "SET_OR_POST_ANESTHESIA_MEDICATION_DISPLAY"
export const SET_OR_POST_ANESTHESIA_NURSE_DISPLAY = "SET_OR_POST_ANESTHESIA_NURSE_DISPLAY"
export const SET_OR_POST_ANESTHESIA_PADSS_DISPLAY = "SET_OR_POST_ANESTHESIA_PADSS_DISPLAY"
export const SET_OR_POST_ANESTHESIA_RECORD_DISPLAY = "SET_OR_POST_ANESTHESIA_RECORD_DISPLAY"
export const SET_OR_POST_ANESTHESIA_RECORD_TIME_DISPLAY = "SET_OR_POST_ANESTHESIA_RECORD_TIME_DISPLAY"
export const SET_OR_POST_ANESTHESIA_SERVICE_DISPLAY = "SET_OR_POST_ANESTHESIA_SERVICE_DISPLAY"
export const SET_OR_POST_ANESTHESIA_TRANSFER_DISPLAY = "SET_OR_POST_ANESTHESIA_TRANSFER_DISPLAY"
export const SET_OR_REMARK_DISPLAY = "SET_OR_REMARK_DISPLAY"
export const SET_OR_SBUS = "SET_OR_SBUS"
export const SET_OR_SBUS_LOADING = "SET_OR_SBUS_LOADING"
export const SET_OR_SCHEDULES = "SET_OR_SCHEDULES"
export const SET_OR_SCHEDULE_DISPLAY = "SET_OR_SCHEDULE_DISPLAY"
export const SET_OR_SELECTED_EVENT = "SET_OR_SELECTED_EVENT"
export const SET_OR_SELECTED_INTRA_ANE_RECORD = "SET_OR_SELECTED_INTRA_ANE_RECORD"
export const SET_OR_SELECTED_OPERATING_ROOM = "SET_OR_SELECTED_OPERATING_ROOM"
export const SET_OR_SELECTED_POST_ANE_RECORD = "SET_OR_SELECTED_POST_ANE_RECORD"
export const SET_OR_SELECTED_REQUEST = "SET_OR_SELECTED_REQUEST"
export const SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST = "SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST"
export const SET_OR_SERVICE_TYPES = "SET_OR_SERVICE_TYPES"
export const SET_OR_SERVICE_TYPES_LOADING = "SET_OR_SERVICE_TYPES_LOADING"
export const SET_OR_START_DATETIME = "SET_OR_START_DATETIME"
export const SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY = "SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY"
export const SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_DISPLAY = "SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_DISPLAY"
export const SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY = "SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY"
export const SET_OR_VITAL_SIGN = "SET_OR_VITAL_SIGN"
export const SET_OR_VITAL_SIGN_DISPLAY = "SET_OR_VITAL_SIGN_DISPLAY"
export const SET_OR_POST_ANESTHESIA_VITAL_SIGN_DISPLAY = "SET_OR_POST_ANESTHESIA_VITAL_SIGN_DISPLAY"
export const SET_OR_MODAL_POST_ANE_VS_DATE_TIME = "SET_OR_MODAL_POST_ANE_VS_DATE_TIME"
export const SET_OR_MODAL_POST_ANE_VS_SELECTED_VS = "SET_OR_MODAL_POST_ANE_VS_SELECTED_VS"
export const SET_OR_MODAL_POST_ANE_VS_ALINE = "SET_OR_MODAL_POST_ANE_VS_ALINE"
export const SET_OR_MODAL_POST_ANE_VS_NIBP = "SET_OR_MODAL_POST_ANE_VS_NIBP"
export const SET_OR_MODAL_POST_ANE_VS_PULSE = "SET_OR_MODAL_POST_ANE_VS_PULSE"
export const SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY = "SET_OR_POST_ANESTHESIA_NEW_VITAL_SIGN_DISPLAY"
export const SET_OR_MODAL_POST_ANE_VS_RR = "SET_OR_MODAL_POST_ANE_VS_RR"
export const SET_OR_MODAL_POST_ANE_VS_SPO2 = "SET_OR_MODAL_POST_ANE_VS_SPO2"