export const SET_DEBT_ADJUSTMENT_DISPLAY = "SET_DEBT_ADJUSTMENT_DISPLAY"
export const SET_DEBT_EDIT_INVOICE_DISPLAY = "SET_DEBT_EDIT_INVOICE_DISPLAY"
export const SET_DEBT_END_DATE_TIME = "SET_DEBT_END_DATE_TIME"
export const SET_DEBT_FILTER_DEBT_PLAN_ID = "SET_DEBT_FILTER_DEBT_PLAN_ID"
export const SET_DEBT_FILTER_INSURANCE_COMPANY = "SET_DEBT_FILTER_INSURANCE_COMPANY"
export const SET_DEBT_INVOICES = "SET_DEBT_INVOICES"
export const SET_DEBT_LOCATION = "SET_DEBT_LOCATION"
export const SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT = "SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT"
export const SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX = "SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX"
export const SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT = "SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT"
export const SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK = "SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK"
export const SET_DEBT_MODAL_ADJUSTMENT_CONTINUE = "SET_DEBT_MODAL_ADJUSTMENT_CONTINUE"
export const SET_DEBT_MODAL_ADJUSTMENT_ID = "SET_DEBT_MODAL_ADJUSTMENT_ID"
export const SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT = "SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT"
export const SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT_GROUP = "SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT_GROUP"
export const SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS = "SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS"
export const SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP = "SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP"
export const SET_DEBT_MODAL_LOCATION_DISPLAY = "SET_DEBT_MODAL_LOCATION_DISPLAY"
export const SET_DEBT_MODAL_LOCATION_ORGS = "SET_DEBT_MODAL_LOCATION_ORGS"
export const SET_DEBT_MODAL_LOCATION_ORGS_LOADING = "SET_DEBT_MODAL_LOCATION_ORGS_LOADING"
export const SET_DEBT_MODAL_LOCATION_SELECTED_ORG = "SET_DEBT_MODAL_LOCATION_SELECTED_ORG"
export const SET_DEBT_MODAL_NEW_FILTER_INVOICE_ID = "SET_DEBT_MODAL_NEW_FILTER_INVOICE_ID"
export const SET_DEBT_MODAL_NEW_HEALTH_CARE_PLANS = "SET_DEBT_MODAL_NEW_HEALTH_CARE_PLANS"
export const SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING = "SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING"
export const SET_DEBT_MODAL_NEW_INSURANCE_COMPANIES = "SET_DEBT_MODAL_NEW_INSURANCE_COMPANIES"
export const SET_DEBT_MODAL_NEW_INSURANCE_COMPANY_LOADING = "SET_DEBT_MODAL_NEW_INSURANCE_COMPANY_LOADING"
export const SET_DEBT_MODAL_NEW_INVOICES = "SET_DEBT_MODAL_NEW_INVOICES"
export const SET_DEBT_MODAL_NEW_PENDING_INVOICES = "SET_DEBT_MODAL_NEW_PENDING_INVOICES"
export const SET_DEBT_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN = "SET_DEBT_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN"
export const SET_DEBT_MODAL_NEW_SEARCH_INSURANCE_COMPANY = "SET_DEBT_MODAL_NEW_SEARCH_INSURANCE_COMPANY"
export const SET_DEBT_MODAL_NEW_SELECTED_INVOICE = "SET_DEBT_MODAL_NEW_SELECTED_INVOICE"
export const SET_DEBT_MODAL_NEW_SELECTED_PENDING_INVOICE = "SET_DEBT_MODAL_NEW_SELECTED_PENDING_INVOICE"
export const SET_DEBT_MODAL_PAYMENT_BANKS = "SET_DEBT_MODAL_PAYMENT_BANKS"
export const SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES = "SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES"
export const SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME = "SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME"
export const SET_DEBT_MODAL_PAYMENT_CHECK_NUM = "SET_DEBT_MODAL_PAYMENT_CHECK_NUM"
export const SET_DEBT_MODAL_PAYMENT_CREDIT_CARD_NUM = "SET_DEBT_MODAL_PAYMENT_CREDIT_CARD_NUM"
export const SET_DEBT_MODAL_PAYMENT_DATE_TIME = "SET_DEBT_MODAL_PAYMENT_DATE_TIME"
export const SET_DEBT_MODAL_PAYMENT_DISPLAY = "SET_DEBT_MODAL_PAYMENT_DISPLAY"
export const SET_DEBT_MODAL_PAYMENT_PAYMENTS = "SET_DEBT_MODAL_PAYMENT_PAYMENTS"
export const SET_DEBT_MODAL_PAYMENT_REMARK = "SET_DEBT_MODAL_PAYMENT_REMARK"
export const SET_DEBT_MODAL_PAYMENT_SELECTED_BANK = "SET_DEBT_MODAL_PAYMENT_SELECTED_BANK"
export const SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH = "SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH"
export const SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT = "SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT"
export const SET_DEBT_MODAL_PAYMENT_WHT = "SET_DEBT_MODAL_PAYMENT_WHT"
export const SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT = "SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT"
export const SET_DEBT_MODAL_PAYMENT_AMOUNT = "SET_DEBT_MODAL_PAYMENT_AMOUNT"
export const SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE = "SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE"
export const SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH = "SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH"
export const SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME = "SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME"
export const SET_DEBT_MODAL_SEARCH_DEBT_PLAN_ID = "SET_DEBT_MODAL_SEARCH_DEBT_PLAN_ID"
export const SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANIES = "SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANIES"
export const SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY = "SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY"
export const SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING = "SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING"
export const SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID = "SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID"
export const SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME = "SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME"
export const SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME = "SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME"
export const SET_DEBT_MODAL_SEARCH_MORE_PID = "SET_DEBT_MODAL_SEARCH_MORE_PID"
export const SET_DEBT_MODAL_SEARCH_RANGE_END_DATE_TIME = "SET_DEBT_MODAL_SEARCH_RANGE_END_DATE_TIME"
export const SET_DEBT_MODAL_SEARCH_RANGE_START_DATE_TIME = "SET_DEBT_MODAL_SEARCH_RANGE_START_DATE_TIME"
export const SET_DEBT_NEW_DISPLAY = "SET_DEBT_NEW_DISPLAY"
export const SET_DEBT_PLANS = "SET_DEBT_PLANS"
export const SET_DEBT_SEARCH_DISPLAY = "SET_DEBT_SEARCH_DISPLAY"
export const SET_DEBT_SEARCH_MORE_DISPLAY = "SET_DEBT_SEARCH_MORE_DISPLAY"
export const SET_DEBT_SELECTED_INVOICE = "SET_DEBT_SELECTED_INVOICE"
export const SET_DEBT_SELECTED_PLAN = "SET_DEBT_SELECTED_PLAN"
export const SET_DEBT_START_DATE_TIME = "SET_DEBT_START_DATE_TIME"
