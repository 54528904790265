import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setCustomDefaultFocus, setLoadingAction, setSelected } from "~/redux/action"
import {
	SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS,
	SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ConfigFocusModalNFDetails from "./ConfigFocusModalNFDetails"

class ConfigFocusModalNF extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigFocusModalNF",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_CUSTOM_DEFAULT_NEW_FOCUS_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.customDefault.saveFocus,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				categoryId: this.props.focus.selectedType.type.id,
				userId: this.props.login.user.id,
				id: this.props.focus.selectedFocus.focus ? this.props.focus.selectedFocus.focus.id : 0,
				name: Utils.trim(this.props.focus.modalNewFocus.name),
				description: Utils.trim(this.props.focus.modalNewFocus.description),
				active: this.props.focus.modalNewFocus.isActive,
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.customDefaults)) {
				Object.assign(this.props.focus.selectedType.type, {
					focuses: this.props.focus.selectedType.type.focuses.filter(
						(each) => each.id !== res.data.customDefaults[0].id
					),
				})
				this.props.focus.selectedType.type.focuses.push(res.data.customDefaults[0])
				this.props.setCustomDefaultFocus(this.props.focus.selectedType.type.focuses)
				this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_FOCUS_SELECTED_FOCUS, -1, null)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.focus)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ConfigFocusModalNFDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							type={ResourceAssistance.Button.type.submit}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmCustomDefaultNewFocusDisplay,
	login: state.login,
	focus: state.admin.customDefaultConfig.focus,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setCustomDefaultFocus,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigFocusModalNF))
