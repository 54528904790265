import { Space } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { BiCheckSquare, BiRepeat, BiSelectMultiple } from "react-icons/bi"
import { FiSkipForward } from "react-icons/fi"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setPharmacyDoctorOrders,
	setPharmacyPatients,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PHARMACY_MODAL_VERIFY_DAILY_IND,
	SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND,
	SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME,
	SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME,
	SET_PHARMACY_SELECTED_DOCTOR_ORDER,
	SET_PHARMACY_VERIFY_DISPLAY,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"

class PharmacyRightDoctorOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRightDoctorOrder",
		}

		this.onSelectOrder = this.onSelectOrder.bind(this)
		this.onSkip = this.onSkip.bind(this)
		this.onVerify = this.onVerify.bind(this)
		this.onVerifyAll = this.onVerifyAll.bind(this)
	}

	componentDidMount() {
		if (this.props.pharmacy.selectedAdmission.admission) {
			this.props.setPharmacyDoctorOrders(this.props.pharmacy.selectedAdmission.admission.clinicalRecord.doctorOrders)
		} else {
			this.props.setPharmacyDoctorOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.pharmacy.skipVerify)) {
					this.skipVerifyDoctorOrder()
				}
			}
		}

		if (!_.isEqual(prevProps.pharmacy.selectedAdmission, this.props.pharmacy.selectedAdmission)) {
			if (this.props.pharmacy.selectedAdmission.admission) {
				this.props.setPharmacyDoctorOrders(this.props.pharmacy.selectedAdmission.admission.clinicalRecord.doctorOrders)
			} else {
				this.props.setPharmacyDoctorOrders([])
			}
		}
		if (!_.isEqual(prevProps.pharmacy.doctorOrderTable.filtered, this.props.pharmacy.doctorOrderTable.filtered)) {
			let index = this.props.pharmacy.selectedDoctorOrder.doctorOrder
				? this.props.pharmacy.doctorOrderTable.filtered.findIndex(
						(doctorOrder) => doctorOrder.id === this.props.pharmacy.selectedDoctorOrder.doctorOrder.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(
					SET_PHARMACY_SELECTED_DOCTOR_ORDER,
					index,
					this.props.pharmacy.doctorOrderTable.filtered[index]
				)
			} else {
				this.props.setSelected(SET_PHARMACY_SELECTED_DOCTOR_ORDER, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_PHARMACY_SELECTED_DOCTOR_ORDER, -1, null)
	}

	skipVerifyDoctorOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.pharmacy.savePharmacyOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.pharmacy.selectedPatient.patient.isSearchPatient
					? this.props.pharmacy.selectedPatient.patient.id
					: 0,
				startDateTime: this.props.pharmacy.startDateTime,
				endDateTime: this.props.pharmacy.endDateTime,
				departmentId: this.props.pharmacy.location.id,
				branchId: this.props.pharmacy.modalLocation.selectedBranch.branch.id,
				userId: this.props.login.user.id,
				skipVerifyDoctorOrder: true,
				pharmacyOrders: [
					{
						doctorOrderId: this.props.pharmacy.selectedDoctorOrder.doctorOrder.id,
						unit: this.props.pharmacy.selectedDoctorOrder.doctorOrder.unit,
						startDateTime: this.props.pharmacy.startDateTime,
						endDateTime: this.props.pharmacy.endDateTime,
						daily: true,
						amount: Utils.calculateDoctorOrderQty(
							this.props.pharmacy.selectedDoctorOrder.doctorOrder,
							this.props.pharmacy.startDateTime,
							this.props.pharmacy.endDateTime
						),
					},
				],
			},
		}
		let callback = (res) => {
			if (this.props.pharmacy.selectedPatient.patient.isSearchPatient) {
				res.data.patients.forEach((patient) =>
					Object.assign(patient, {
						isSearchPatient: true,
					})
				)
			}
			this.props.setPharmacyPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isDisplayRepeatIcon() {
		return this.props.pharmacy.selectedPatient.patient && this.props.pharmacy.selectedAdmission.admission
	}

	isDisplaySkipIcon() {
		return (
			this.props.pharmacy.selectedPatient.patient &&
			this.props.pharmacy.selectedAdmission.admission &&
			!this.props.pharmacy.selectedPatient.patient.isSearchPatient &&
			!this.props.pharmacy.selectedAdmission.admission.dischargedDateTime &&
			this.props.pharmacy.selectedAdmission.admission.ipds.some((ipd) => !ipd.dischargeSummary)
		)
	}

	isDisplayVerifyDayIcon() {
		return (
			this.props.pharmacy.selectedPatient.patient &&
			this.props.pharmacy.selectedAdmission.admission &&
			!this.props.pharmacy.selectedPatient.patient.isSearchPatient &&
			!this.props.pharmacy.selectedAdmission.admission.dischargedDateTime &&
			this.props.pharmacy.selectedAdmission.admission.ipds.some((ipd) => !ipd.dischargeSummary)
		)
	}

	isDisplayVerifyAllIcon() {
		return (
			this.props.pharmacy.selectedAdmission.admission &&
			(this.props.pharmacy.selectedAdmission.admission.dischargedDateTime > 0 ||
				this.props.pharmacy.selectedAdmission.admission.clinicalRecord.medicalRecords.some(
					(mr) => mr.checkOutDateTime === 0
				))
		)
	}

	onSelectOrder(order, index) {
		this.props.setSelected(
			SET_PHARMACY_SELECTED_DOCTOR_ORDER,
			index,
			this.props.pharmacy.doctorOrderTable.filtered[index]
		)
	}

	onSkip(e, row, rIdx, cIdx) {
		let selectedDoctorOrder = this.props.pharmacy.doctorOrderTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.pharmacy.skipVerify)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.lg)
		this.props.setWarningMsgAction(
			<Space direction="vertical" size={"middle"} align={"center"}>
				<span style={{ fontSize: "28px" }}>{translate(ResourceAssistance.Message.skipVerify)}</span>
				<span>{selectedDoctorOrder.code}</span>
				<span>{selectedDoctorOrder.description}</span>
				<span>{selectedDoctorOrder.notes}</span>
				<span>
					{Utils.formatDate(this.props.pharmacy.startDateTime)} - {Utils.formatDate(this.props.pharmacy.endDateTime)}
				</span>
				<span>
					{translate(ResourceAssistance.Message.amount)}:{" "}
					{Utils.formatNumWithComma(
						Utils.calculateDoctorOrderQty(
							selectedDoctorOrder,
							this.props.pharmacy.startDateTime,
							this.props.pharmacy.endDateTime
						)
					)}{" "}
					{selectedDoctorOrder.unit}
				</span>
			</Space>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onVerify(event, row, rIdx, cIdx) {
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME, this.props.pharmacy.startDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME, this.props.pharmacy.endDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DAILY_IND, true)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND, true)
		this.props.onModalDisplayAction(SET_PHARMACY_VERIFY_DISPLAY, true)
	}

	onVerifyAll(event, row, rIdx, cIdx) {
		let selectedDoctorOrder = this.props.pharmacy.doctorOrderTable.filtered[rIdx]
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME, selectedDoctorOrder.startDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME, selectedDoctorOrder.endDateTime)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DAILY_IND, false)
		this.props.setValue(SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND, true)
		this.props.onModalDisplayAction(SET_PHARMACY_VERIFY_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							[ResourceAssistance.Button.variant.outlineInfo],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								ResourceAssistance.Button.variant.outlinePrimary,
								ResourceAssistance.Button.variant.outlinePrimary,
								ResourceAssistance.Button.variant.outlinePrimary,
							],
						]}
						btnMessages={[
							[""],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.skipVerify }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.verifyTodayPrescription }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.verifyAllPrescription }),
							],
						]}
						btnTypes={[[EBtnType.POPOVER], [], [], [], [], [], [], [], [], []]}
						btnIcons={[
							[this.isDisplayRepeatIcon() ? <BiRepeat size={ResourceAssistance.ReactIcon.size} /> : undefined],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								this.isDisplaySkipIcon() ? <FiSkipForward size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayVerifyDayIcon() ? <BiCheckSquare size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayVerifyAllIcon() ? (
									<BiSelectMultiple size={ResourceAssistance.ReactIcon.size} />
								) : undefined,
							],
						]}
						onCheckedArray={[this.onSkip, this.onVerify, this.onVerifyAll]}
						data={this.props.pharmacy.doctorOrderTable}
						onClick={this.onSelectOrder}
						isClearHighlight={this.props.pharmacy.selectedDoctorOrder.index === -1}
						highlightedRow={this.props.pharmacy.selectedDoctorOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPharmacyDoctorOrders,
			setPharmacyPatients,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyRightDoctorOrder))
