import { TreeSelect, message } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import EnumAssistance from "~/enum/EnumAssistance"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, onModalElementDisplayAction, setLocationType, setSelected, setSelectedLoc, setValue } from "~/redux/action"
import {
	SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_SELECTED_LOC,
	SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY,
	SET_LOC_BRANCH,
	SET_LOC_GENERAL,
	SET_LOC_INVENTORY,
	SET_LOC_IPD,
	SET_LOC_MODAL_NEW_AP_CODES,
	SET_LOC_MODAL_NEW_AR_CODES,
	SET_LOC_OPD,
	SET_LOC_OPERATING_ROOM,
	SET_LOC_ORG,
	SET_NEW_LOCATION_DISPLAY,
	SET_NEW_LOCATION_PARENT_ORG_DISPLAY,
	SET_NEW_LOCATION_TAX_NO_DISPLAY,
	SET_ORGS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import FileUpload from "~/view/component/fileUpload/FileUpload"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

const Property = {
	NAME: 1,
	DESCRIPTION: 2,
	ACTIVE: 3,
	PARENT_LOC: "ADMIN_LOC_NEW_LOC_PARENT_LOC",
	ADDRESS: 5,
	CITY: 6,
	PROVINCE: 7,
	POSTCODE: 8,
	PARENT_ORG: "ADMIN_LOC_NEW_LOC_PARENT_ORG",
	TEL: 9,
	FAX: 10,
	TAX_NO: 11,
	BILLING_ADDRESS: 12,
	NAME_ENG: 13,
	ADDRESS_ENG: 14,
	CITY_ENG: 15,
	PROVINCE_ENG: 16,
	PHARMACY_IND: 17,
	HOSPITALCODE: 18,
}

class NewLocation extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(Property)

		this.state = this.initState()
		this.reset = this.reset.bind(this)
		this.onDoneBtn = this.onDoneBtn.bind(this)
		this.onCloseBtn = this.onCloseBtn.bind(this)
		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onAR = this.onAR.bind(this)
		this.onAP = this.onAP.bind(this)
		this.onLocation = this.onLocation.bind(this)
		this.setLogoFile = this.setLogoFile.bind(this)
		this.setLogoFile = this.setLogoFile.bind(this)
	}

	initState() {
		return {
			componentName: "NewLocation",
			name: "",
			nameEng: "",
			description: "",
			active: true,
			address: "",
			addressEng: "",
			city: "",
			cityEng: "",
			province: "",
			provinceEng: "",
			postcode: "",
			tel: "",
			fax: "",
			taxNo: "",
			billingAddress: "",
			isPharmacy: false,
			logoFile: "",
			logoFileName: "",
			logoFilePath: "",
			hospitalCode: "",
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				if (this.props.loc.selectedLoc.loc) {
					//init loc info
					this.setState({
						name: this.props.loc.selectedLoc.loc.displayName,
						nameEng: this.props.loc.selectedLoc.loc.displayNameEng,
						description: this.props.loc.selectedLoc.loc.description,
						active: this.props.loc.selectedLoc.loc.active,
						isPharmacy: this.props.loc.selectedLoc.loc.code.displayName === EnumAssistance.PHARMACY,
						address: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.street : "",
						addressEng: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.streetEng : "",
						city: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.city : "",
						cityEng: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.cityEng : "",
						province: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.province : "",
						provinceEng: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.provinceEng : "",
						postcode: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.postcode : "",
						tel: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.tel : "",
						fax: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.fax : "",
						taxNo: this.props.loc.selectedLoc.loc.taxNo,
						billingAddress: this.props.loc.selectedLoc.loc.address ? this.props.loc.selectedLoc.loc.address.billingAddress : "",
						logoFileName: this.props.loc.selectedLoc.loc.logoName ? this.props.loc.selectedLoc.loc.logoName : "",
						logoFilePath: this.props.loc.selectedLoc.loc.logoPath ? this.props.loc.selectedLoc.loc.logoPath : "",
						hospitalCode: this.props.loc.selectedLoc.loc.hospitalCode ? this.props.loc.selectedLoc.loc.hospitalCode : "",
					})

					//init org tag dropdown
					if (this.props.loc.isBranch) {
						let orgIdx = this.props.loc.orgs.findIndex((org) => org.id === this.props.loc.selectedLoc.loc.parent.id)
						if (orgIdx > -1) {
							this.props.setSelected(SET_ADM_LOC_MODAL_NEW_SELECTED_LOC, orgIdx, this.props.loc.orgs[orgIdx])
						}
					} else if (!this.props.loc.isOrg) {
						let orgIdx = this.props.loc.orgs.findIndex((org) => org.locations.some((branch) => branch.id === this.props.loc.selectedLoc.loc.parent.id))
						if (orgIdx > -1) {
							let branchIdx = this.props.loc.orgs[orgIdx].locations.findIndex((branch) => branch.id === this.props.loc.selectedLoc.loc.parent.id)
							if (branchIdx > -1) {
								this.props.setSelected(SET_ADM_LOC_MODAL_NEW_SELECTED_LOC, orgIdx + "-" + branchIdx, this.props.loc.orgs[orgIdx].locations[branchIdx])
							}
						}
					}

					//init accounts receivable
					if (this.props.loc.isOPD || this.props.loc.isIPD || this.props.loc.isBranch || this.props.loc.isOrg) {
						this.props.setValue(SET_LOC_MODAL_NEW_AR_CODES, this.props.loc.selectedLoc.loc.accountsReceivable)
					}

					//init accounts Payable
					if (this.props.loc.isBranch || this.props.loc.isOrg) {
						this.props.setValue(SET_LOC_MODAL_NEW_AP_CODES, this.props.loc.selectedLoc.loc.accountsPayable)
					}
				}
			} else {
				this.setState(this.initState())
				this.props.setSelected(SET_ADM_LOC_MODAL_NEW_SELECTED_LOC, -1, null)
			}
		}
	}

	reset() {
		this.setState(this.initState())
		this.props.setSelectedLoc(-1, null)
		this.props.setValue(SET_LOC_MODAL_NEW_AR_CODES, "")
		this.props.setValue(SET_LOC_MODAL_NEW_AP_CODES, "")
		this.props.setLocationType(SET_LOC_ORG, false)
		this.props.setLocationType(SET_LOC_BRANCH, false)
		this.props.setLocationType(SET_LOC_INVENTORY, false)
		this.props.setLocationType(SET_LOC_GENERAL, false)
		this.props.setLocationType(SET_LOC_IPD, false)
		this.props.setLocationType(SET_LOC_OPD, false)
		this.props.setLocationType(SET_LOC_OPERATING_ROOM, false)
		this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_NEW_LOCATION_TAX_NO_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, false)
		this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY, false)
		this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, false)
	}

	isDoneDisabled() {
		if (this.props.loc.isIPD || this.props.loc.isOPD) {
			if (_.isEmpty(this.props.loc.newLoc.accountsReceivableCodes)) {
				return true
			}
		}
		if (this.props.loc.isBranch || this.props.loc.isOrg) {
			if (_.isEmpty(this.props.loc.newLoc.accountsReceivableCodes) || _.isEmpty(this.props.loc.newLoc.accountsPayableCodes)) {
				return true
			}
		}

		if (this.props.loc.isOrg && !this.state.logoFile && !this.state.logoFileName) {
			return true
		}

		let target = Object.assign({}, this.props.loc.selectedLoc.loc, {
			...this.props.loc.selectedLoc.loc,
			displayName: this.state.name.trim(),
			description: this.state.description.trim(),
			active: this.state.active,
		})

		if (this.props.loc.isOrg) {
			target = Object.assign({}, this.props.loc.selectedLoc.loc, {
				...this.props.loc.selectedLoc.loc,
				taxNo: this.state.taxNo.trim(),
				displayNameEng: Utils.trim(this.state.nameEng),
				accountsReceivable: this.props.loc.newLoc.accountsReceivableCodes,
				accountsPayable: this.props.loc.newLoc.accountsPayableCodes,
				address: Object.assign({}, target.address, {
					...target.address,
					street: this.state.address.trim(),
					streetEng: Utils.trim(this.state.addressEng),
					city: this.state.city.trim(),
					cityEng: Utils.trim(this.state.cityEng),
					province: this.state.province.trim(),
					provinceEng: Utils.trim(this.state.provinceEng),
					postcode: Number(this.state.postcode),
					tel: this.state.tel,
					fax: this.state.fax,
				}),
			})

			if (this.state.logoFile) {
				target = Object.assign(target, { logoFile: true })
			}
		} else if (this.props.loc.isBranch) {
			target = Object.assign({}, target, {
				accountsReceivable: this.props.loc.newLoc.accountsReceivableCodes,
				accountsPayable: this.props.loc.newLoc.accountsPayableCodes,
				hospitalCode: this.state.hospitalCode,
				address: Object.assign({}, target.address, {
					...target.address,
					street: this.state.address.trim(),
					streetEng: Utils.trim(this.state.addressEng),
					city: this.state.city.trim(),
					cityEng: Utils.trim(this.state.cityEng),
					province: this.state.province.trim(),
					provinceEng: Utils.trim(this.state.provinceEng),
					postcode: Number(this.state.postcode),
					tel: this.state.tel,
					fax: this.state.fax,
					billingAddress: this.state.billingAddress.trim(),
				}),
				parent: Object.assign({}, target.parent, {
					...target.parent,
					id: this.props.loc.newLoc.selectedLocation.location ? this.props.loc.newLoc.selectedLocation.location.id : 0,
				}),
			})
		} else if (this.props.loc.isInventory || this.props.loc.isOR) {
			target = Object.assign({}, target, {
				parent: Object.assign({}, target.parent, {
					...target.parent,
					id: this.props.loc.newLoc.selectedLocation.location ? this.props.loc.newLoc.selectedLocation.location.id : 0,
				}),
			})
		} else if (this.props.loc.isGeneralLocation) {
			target = Object.assign({}, target, {
				parent: Object.assign({}, target.parent, {
					...target.parent,
					id: this.props.loc.newLoc.selectedLocation.location ? this.props.loc.newLoc.selectedLocation.location.id : 0,
					pharmacy: this.state.isPharmacy,
				}),
			})
		} else if (this.props.loc.isOPD || this.props.loc.isIPD) {
			target = Object.assign({}, target, {
				accountsReceivable: this.props.loc.newLoc.accountsReceivableCodes,
			})
		}

		return _.isEqual(this.props.loc.selectedLoc.loc, target)
	}

	renderTypes(locs) {
		return locs.map((loc, key) => {
			return (
				<option key={key} value={key}>
					{loc.displayName}
				</option>
			)
		})
	}

	saveLoc() {
		try {
			let raw = {
				id: this.props.loc.selectedLoc.loc ? this.props.loc.selectedLoc.loc.id : 0,
				name: Utils.trim(this.state.name),
				nameEng: Utils.trim(this.state.nameEng),
				description: Utils.trim(this.state.description),
				hospitalCode: Utils.trim(this.state.hospitalCode),
				address: Utils.trim(this.state.address),
				addressEng: Utils.trim(this.state.addressEng),
				city: Utils.trim(this.state.city),
				cityEng: Utils.trim(this.state.cityEng),
				province: Utils.trim(this.state.province),
				provinceEng: Utils.trim(this.state.provinceEng),
				postcode: Utils.trim(this.state.postcode) || "0",
				tel: Utils.trim(this.state.tel),
				fax: Utils.trim(this.state.fax),
				taxNo: Utils.trim(this.state.taxNo),
				billingAddress: Utils.trim(this.state.billingAddress),
				accountsReceivable: this.props.loc.newLoc.accountsReceivableCodes,
				accountsPayable: this.props.loc.newLoc.accountsPayableCodes,
				logoName: Utils.trim(this.state.logoFileName),
				logoPath: Utils.trim(this.state.logoFilePath),
				parentLocId: this.props.loc.isBranch
					? this.props.loc.newLoc.selectedLocation.location.id
					: this.props.loc.newLoc.selectedLocation.index === -1
					? 0
					: this.props.loc.newLoc.selectedLocation.location.id,
				active: this.state.active,
				userId: this.props.login.user.id,
				org: this.props.loc.isOrg,
				branch: this.props.loc.isBranch,
				inventory: this.props.loc.isInventory,
				generalLocation: this.props.loc.isGeneralLocation,
				ipd: this.props.loc.isIPD,
				opd: this.props.loc.isOPD,
				pharmacy: this.state.isPharmacy,
				or: this.props.loc.isOR,
			}

			const formData = new FormData()
			formData.append("id", raw.id)
			formData.append("locationName", raw.name)
			formData.append("locationNameEng", raw.nameEng)
			formData.append("locationDescription", raw.description)
			formData.append("hospitalCode", raw.hospitalCode)
			formData.append("address", raw.address)
			formData.append("addressEng", raw.addressEng)
			formData.append("city", raw.city)
			formData.append("cityEng", raw.cityEng)
			formData.append("province", raw.province)
			formData.append("provinceEng", raw.provinceEng)
			formData.append("postcode", raw.postcode)
			formData.append("tel", raw.tel)
			formData.append("fax", raw.fax)
			formData.append("taxNo", raw.taxNo)
			formData.append("billingAddress", raw.billingAddress)
			formData.append("accountsReceivable", raw.accountsPayable)
			formData.append("accountsPayable", raw.accountsPayable)
			formData.append("path", raw.logoPath)
			formData.append("parentLocId", raw.parentLocId)
			formData.append("active", raw.active)
			formData.append("userId", raw.userId)
			formData.append("org", raw.org)
			formData.append("branch", raw.branch)
			formData.append("inventory", raw.inventory)
			formData.append("generalLocation", raw.generalLocation)
			formData.append("ipd", raw.ipd)
			formData.append("opd", raw.opd)
			formData.append("pharmacy", raw.pharmacy)
			formData.append("or", raw.or)

			let callback = (res) => {
				if (!_.isEmpty(res.data.locations)) {
					this.onCloseBtn()
					message.success(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveSuccess }), 2)
				} else {
					message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveFail }), 2)
				}
			}

			let errorHandler = (error) => {
				this.setState(this.initState())
				message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveFail }), 2)
			}

			if (this.state.logoFile !== "") {
				const fileName = this.state.logoFile.name
				const logoFileType = this.getImageType(fileName)

				Utils.convertBlob(this.state.logoFile, logoFileType).then((jpegBlob) => {
					try {
						formData.append("file", jpegBlob)
						formData.append("name", this.getLogoFileName(raw.id, raw.nameEng, fileName))
						formData.append("description", jpegBlob.name)

						let params = {
							method: "POST",
							url: ResourceAssistance.Url.admin.location.saveLoc,
							withCredentials: true,
							headers: {
								"content-type": "multipart/form-data",
							},
							data: formData,
						}

						this.props.setValue(SET_ORGS, [])

						axios(params, callback, errorHandler)
					} catch (err) {
						message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.somethingWrong }), 2)
					}
				})
			} else {
				formData.append("name", raw.logoName)

				let params = {
					method: "POST",
					url: ResourceAssistance.Url.admin.location.saveLoc,
					withCredentials: true,
					headers: {
						"content-type": "multipart/form-data",
					},
					data: formData,
				}

				axios(params, callback, errorHandler)
			}
		} catch (err) {
			message.error(this.props.intl.formatMessage({ id: ResourceAssistance.Message.saveFail }), 2)
		}
	}

	getLogoFileName(id, org, fileName) {
		const orgName = org.replace(/[^a-zA-Z0-9]+/g, "").substring(0, 15)
		const imageNameArr = fileName.split(".")
		const fileType = imageNameArr[imageNameArr.length - 1] === "png" ? "png" : "jpg"
		const logoFileName = id + "_" + orgName + "." + fileType

		return logoFileName
	}

	getImageType(fileName) {
		const imageNameArr = fileName.split(".")
		const imageType = imageNameArr[imageNameArr.length - 1] === "png" ? "image/png" : "image/jpeg"
		return imageType
	}

	setLogoFile(logoFile) {
		if (logoFile) {
			this.setState({
				logoFile: logoFile,
			})
		} else {
			this.setState({
				logoFile: "",
				logoFileName: "",
				logoFileType: "",
			})
		}
	}

	onCloseBtn() {
		this.reset()
	}

	onDoneBtn(event) {
		event.preventDefault()
		event.stopPropagation()

		this.saveLoc()
		this.reset()
	}

	onPropertyChange(event) {
		switch (Number(event.target.name)) {
			case Property.NAME:
				this.setState({ name: event.target.value })
				break
			case Property.DESCRIPTION:
				this.setState({ description: event.target.value })
				break
			case Property.ACTIVE:
				this.setState({ active: event.target.checked })
				break
			case Property.ADDRESS:
				this.setState({ address: event.target.value })
				break
			case Property.CITY:
				this.setState({ city: event.target.value })
				break
			case Property.PROVINCE:
				this.setState({ province: event.target.value })
				break
			case Property.POSTCODE:
				this.setState({ postcode: event.target.value })
				break
			case Property.TEL:
				this.setState({ tel: event.target.value })
				break
			case Property.FAX:
				this.setState({ fax: event.target.value })
				break
			case Property.TAX_NO:
				this.setState({ taxNo: event.target.value })
				break
			case Property.BILLING_ADDRESS:
				this.setState({ billingAddress: event.target.value })
				break
			case Property.NAME_ENG:
				this.setState({ nameEng: event.target.value })
				break
			case Property.ADDRESS_ENG:
				this.setState({ addressEng: event.target.value })
				break
			case Property.CITY_ENG:
				this.setState({ cityEng: event.target.value })
				break
			case Property.PROVINCE_ENG:
				this.setState({ provinceEng: event.target.value })
				break
			case Property.PHARMACY_IND:
				this.setState({ isPharmacy: event.target.checked })
				break
			case Property.HOSPITALCODE:
				this.setState({ hospitalCode: event.target.value })
				break
			default:
				break
		}
	}

	onLocation(v) {
		if (v === undefined) {
			this.props.setSelected(SET_ADM_LOC_MODAL_NEW_SELECTED_LOC, -1, null)
			return
		}
		if (typeof v === "string") {
			let keys = v.split("-")
			this.props.setSelected(SET_ADM_LOC_MODAL_NEW_SELECTED_LOC, v, this.props.loc.orgs[keys[0]].locations[keys[1]])
		} else {
			this.props.setSelected(SET_ADM_LOC_MODAL_NEW_SELECTED_LOC, v, this.props.loc.orgs[v])
		}
	}

	onAR(value) {
		if (_.isEmpty(value)) {
			this.props.setValue(SET_LOC_MODAL_NEW_AR_CODES, "")
			return
		}
		let accountsReceivableCodes = ""
		value.forEach((each, idx) => {
			let keys = each.split("-")
			let coa = (function getSelectedAccount(accounts, idx) {
				if (idx === keys.length - 1) {
					return accounts[keys[idx]]
				}
				return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
			})(this.props.loc.chartOfAccounts[keys[0]].values, 1)
			accountsReceivableCodes = [accountsReceivableCodes, coa.fullCode].filter((each) => Boolean(each)).join(ResourceAssistance.PROGRAM_DEFINED.split)
		})
		this.props.setValue(SET_LOC_MODAL_NEW_AR_CODES, accountsReceivableCodes)
	}

	getARs() {
		let accountsReceivableCodes = this.props.loc.newLoc.accountsReceivableCodes
		let selected = []
		accountsReceivableCodes
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
			.forEach((code) => {
				let coaAry = Utils.findChartOfAccountsFromGroup(this.props.loc.chartOfAccounts, code)
				if (!_.isEmpty(coaAry)) {
					selected.push(coaAry[0])
				}
			})
		return selected
	}

	onAP(value, node, extra) {
		if (_.isEmpty(value)) {
			this.props.setValue(SET_LOC_MODAL_NEW_AP_CODES, "")
			return
		}
		let accountsPayableCodes = ""
		value.forEach((each, idx) => {
			let keys = each.split("-")
			let coa = (function getSelectedAccount(accounts, idx) {
				if (idx === keys.length - 1) {
					return accounts[keys[idx]]
				}
				return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
			})(this.props.loc.chartOfAccounts[keys[0]].values, 1)
			accountsPayableCodes = [accountsPayableCodes, coa.fullCode].filter((each) => Boolean(each)).join(ResourceAssistance.PROGRAM_DEFINED.split)
		})
		this.props.setValue(SET_LOC_MODAL_NEW_AP_CODES, accountsPayableCodes)
	}

	getAPs() {
		let accountsPayableCodes = this.props.loc.newLoc.accountsPayableCodes
		let selected = []
		accountsPayableCodes
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
			.forEach((code) => {
				let coaAry = Utils.findChartOfAccountsFromGroup(this.props.loc.chartOfAccounts, code)
				if (!_.isEmpty(coaAry)) {
					selected.push(coaAry[0])
				}
			})
		return selected
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.md}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onCloseBtn}
			>
				<Form onSubmit={this.onDoneBtn}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{this.props.loc.newLoc.title}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={"full-flex space-top-each-row-parent"}>
							<Row>
								<Col>
									<GInput
										required
										style={{ textAlign: "center" }}
										placeholder={translate(ResourceAssistance.Message.name)}
										name={Property.NAME}
										value={this.state.name}
										onChange={this.onPropertyChange}
									/>
								</Col>
							</Row>
							{this.props.modal.isNameEngDisplay && (
								<Row>
									<Col>
										<GInput
											required
											style={{ textAlign: "center" }}
											placeholder={translate(ResourceAssistance.Message.nameEng)}
											name={Property.NAME_ENG}
											value={this.state.nameEng}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							<Row>
								<Col>
									<GInput
										required
										autoSize
										minRows={3}
										style={{ textAlign: "center" }}
										placeholder={translate(ResourceAssistance.Message.description)}
										name={Property.DESCRIPTION}
										value={this.state.description}
										onChange={this.onPropertyChange}
									/>
								</Col>
							</Row>
							{this.props.modal.isTaxNoDisplay && (
								<Row>
									<Col>
										<GInput
											required
											style={{ textAlign: "center" }}
											placeholder={translate(ResourceAssistance.Message.taxId)}
											name={Property.TAX_NO}
											value={this.state.taxNo}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.modal.isParentOrgDisplay && (
								<Row>
									<Col>
										<TreeSelect
											size="large"
											treeLine={{ showLeafIcon: false }}
											style={{
												textAlign: "center",
											}}
											treeExpandAction="click"
											placeholder={translate(ResourceAssistance.Message.location)}
											value={this.props.loc.newLoc.selectedLocation.location ? this.props.loc.newLoc.selectedLocation.index : undefined}
											treeData={Utils.getTreeNodesFromOrg(this.props.loc.orgs, true, !this.props.loc.isBranch, false)}
											onChange={this.onLocation}
										/>
									</Col>
								</Row>
							)}
							{this.props.modal.isAccountsReceivableDisplay && (
								<Row>
									<Col>
										<TreeSelect
											style={{
												textAlign: "center",
											}}
											multiple
											showSearch
											allowClear
											treeLine={{ showLeafIcon: false }}
											filterTreeNode={(input, treeNode) => {
												return treeNode.title.toLowerCase().includes(input)
											}}
											placeholder={translate(ResourceAssistance.Message.accountsReceivable)}
											status={_.isEmpty(this.getARs()) ? "error" : undefined}
											value={this.getARs()}
											onChange={this.onAR}
											treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.loc.chartOfAccounts, false, [], undefined, ["displayName", "fullCode"])}
										/>
									</Col>
								</Row>
							)}
							{this.props.modal.isAccountsPayableDisplay && (
								<>
									<Row>
										<Col>
											<TreeSelect
												style={{
													textAlign: "center",
												}}
												multiple
												allowClear
												treeLine={{ showLeafIcon: false }}
												filterTreeNode={(input, treeNode) => {
													return treeNode.title.toLowerCase().includes(input)
												}}
												placeholder={translate(ResourceAssistance.Message.accountsPayable)}
												status={_.isEmpty(this.getAPs()) ? "error" : undefined}
												value={this.getAPs()}
												onChange={this.onAP}
												treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.loc.chartOfAccounts, false, [], undefined, ["displayName", "fullCode"])}
											/>
										</Col>
									</Row>
								</>
							)}
							{this.props.loc.isBranch && (
								<Row>
									<Col>
										<GInput
											required
											style={{ textAlign: "center" }}
											placeholder={translate(ResourceAssistance.Message.hospitalCode)}
											name={Property.HOSPITALCODE}
											value={this.state.hospitalCode}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.modal.isAddressDisplay && (
								<Fragment>
									<Row>
										<Col>
											<GInput
												required
												autoSize
												minRows={2}
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.address)}
												name={Property.ADDRESS}
												value={this.state.address}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												autoSize
												minRows={2}
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.addressEng)}
												name={Property.ADDRESS_ENG}
												value={this.state.addressEng}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.city)}
												name={Property.CITY}
												value={this.state.city}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.cityEng)}
												name={Property.CITY_ENG}
												value={this.state.cityEng}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.province)}
												name={Property.PROVINCE}
												value={this.state.province}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.provinceEng)}
												name={Property.PROVINCE_ENG}
												value={this.state.provinceEng}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.postcode)}
												min="0"
												name={Property.POSTCODE}
												value={this.state.postcode}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.tel)}
												min="0"
												name={Property.TEL}
												value={this.state.tel}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<GInput
												required
												style={{ textAlign: "center" }}
												placeholder={translate(ResourceAssistance.Message.fax)}
												min="0"
												name={Property.FAX}
												value={this.state.fax}
												onChange={this.onPropertyChange}
											/>
										</Col>
									</Row>
								</Fragment>
							)}
							{this.props.modal.isBillingAddressDisplay && (
								<Row>
									<Col>
										<GInput
											required
											autoSize
											minRows={3}
											style={{ textAlign: "center" }}
											placeholder={translate(ResourceAssistance.Message.billingAddress)}
											name={Property.BILLING_ADDRESS}
											value={this.state.billingAddress}
											onChange={this.onPropertyChange}
										/>
									</Col>
								</Row>
							)}
							{this.props.modal.isPharmacyIndDisplay && (
								<Row>
									<Col />
									<Col md={"auto"}>
										<label>{translate(ResourceAssistance.Message.pharmacy)}</label>
									</Col>
									<Col>
										<ToggleCheckBox name={String(Property.PHARMACY_IND)} onClick={this.onPropertyChange} checked={this.state.isPharmacy} />
									</Col>
									<Col />
								</Row>
							)}
							{this.props.loc.isOrg && (
								<Row>
									<Col>
										<FileUpload data={this.props.loc.selectedLoc.loc} setLogoFile={this.setLogoFile} />
									</Col>
								</Row>
							)}
							<Row>
								<Col />
								<Col md={"auto"}>
									<label>{translate(ResourceAssistance.Message.active)}</label>
								</Col>
								<Col>
									<ToggleCheckBox name={String(Property.ACTIVE)} onClick={this.onPropertyChange} checked={this.state.active} />
								</Col>
								<Col />
							</Row>
						</Container>
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onCloseBtn}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isNewLocationDisplay,
	modal: state.modal.newLoc,
	loc: state.admin.location,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			onModalElementDisplayAction,
			setLocationType,
			setSelected,
			setSelectedLoc,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewLocation))
