import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_REPORT_FINANCE_APA_ORGS,
	SET_REPORT_FINANCE_APA_ORGS_LOADING,
	SET_REPORT_FINANCE_APA_SUPPLIERS,
	SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING,
} from "~/redux/type"
import ReportFinanceAPAFilters from "./ReportFinanceAPAFilters"
import ReportFinanceAPATab from "./ReportFinanceAPATab"

class ReportFinanceAPADetails extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.filter.orgs)) {
			this.loadOrgs()
		}
		if (_.isEmpty(this.props.filter.suppliers)) {
			this.loadSuppliers()
		}
	}

	loadOrgs() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.finance.getOrgs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_REPORT_FINANCE_APA_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_APA_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_APA_ORGS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_APA_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadSuppliers() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.report.finance.getSuppliers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_REPORT_FINANCE_APA_SUPPLIERS, res.data.suppliers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<ReportFinanceAPAFilters />
				<ReportFinanceAPATab />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.finance.accountsPayableAging,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportFinanceAPADetails))
