import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_TAX_DISPLAY_ALL_IND,
	SET_ADMIN_TAX_MODAL_NEW_TAX_ACTIVE,
	SET_ADMIN_TAX_MODAL_NEW_TAX_DESC,
	SET_ADMIN_TAX_MODAL_NEW_TAX_NAME,
	SET_ADMIN_TAX_MODAL_NEW_TAX_TAX_RATE,
	SET_ADMIN_TAX_SELECTED_TAX,
	SET_ADMIN_TAX_TAXS,
} from "../../type/type/ADMIN"

export const setAdmTTaxes = (taxes) => {
	return (dispatch, getState) => {
		let filtered = taxes
			.filter((each) => {
				if (getState().admin.itemConfig.tax.displayInactive) {
					return each.displayName.toLowerCase().includes(getState().admin.itemConfig.tax.searchName.toLowerCase())
				} else {
					return (
						each.active &&
						each.displayName.toLowerCase().includes(getState().admin.itemConfig.tax.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.itemConfig.tax.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [
				each.displayName,
				each.description,
				each.taxRate,
				each.createdBy.displayName,
				Utils.formatDate(each.creationDateTime),
			]
		})

		dispatch({
			type: SET_ADMIN_TAX_TAXS,
			payload: {
				original: taxes,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmTSelectedTax = (index, tax) => {
	return {
		type: SET_ADMIN_TAX_SELECTED_TAX,
		payload: {
			index: index,
			tax: tax,
		},
	}
}

export const setAdmTDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_TAX_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmTModalNTName = (name) => {
	return {
		type: SET_ADMIN_TAX_MODAL_NEW_TAX_NAME,
		payload: {
			name: name,
		},
	}
}

export const setAdmTModalNTDescription = (desc) => {
	return {
		type: SET_ADMIN_TAX_MODAL_NEW_TAX_DESC,
		payload: {
			desc: desc,
		},
	}
}

export const setAdmTModalNTTaxRate = (taxRate) => {
	return {
		type: SET_ADMIN_TAX_MODAL_NEW_TAX_TAX_RATE,
		payload: {
			taxRate: taxRate,
		},
	}
}

export const setAdmTModalNTActive = (isActive) => {
	return {
		type: SET_ADMIN_TAX_MODAL_NEW_TAX_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}
