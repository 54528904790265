import { Select } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setValue } from "~/redux/action"
import { SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalEditRequestGeneral extends React.Component {
	constructor(props) {
		super(props)

		this.onORCaseType = this.onORCaseType.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedEvent.event) {
			let request = this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request
			let idx = this.props.operatingRoom.operatingRoomCases.findIndex((orCase) => orCase.displayName === request.operatingRoomCase)
			if (idx > -1) {
				this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE, idx, this.props.operatingRoom.operatingRoomCases[idx])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE, -1, null)
	}

	onORCaseType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE, -1, null)
			return
		}
		this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_CASE, v, this.props.operatingRoom.operatingRoomCases[v])
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.general)}</legend>
				<Container fluid="small">
					<Row>
						<Col>
							<Select
								showSearch
								style={{ textAlign: "center", margin: 0 }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Message.orCase)}
								loading={this.props.operatingRoom.isOperatingRoomCasesLoading}
								value={
									this.props.operatingRoom.modalEditRequest.selectedORCase.case ? this.props.operatingRoom.modalEditRequest.selectedORCase.index : undefined
								}
								onChange={this.onORCaseType}
							>
								{Utils.renderSelects(this.props.operatingRoom.operatingRoomCases, false)}
							</Select>
						</Col>
					</Row>
				</Container>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditRequestGeneral))
