import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import GInput from "~/view/component/input/GInput"
import { setValue } from "~/redux/action"
import { SET_NOTIFICATION_MODAL_NEW_MESSAGE } from "~/redux/type"
import { injectIntl } from "react-intl"

class NotificationModalNewMessage extends React.Component {
	constructor(props) {
		super(props)

		this.onMessage = this.onMessage.bind(this)
	}

	componentDidMount() {
		if (this.props.notification.selectedNotification.notification) {
			this.props.setValue(
				SET_NOTIFICATION_MODAL_NEW_MESSAGE,
				this.props.notification.selectedNotification.notification.message
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_NOTIFICATION_MODAL_NEW_MESSAGE, "")
	}

	onMessage(e) {
		this.props.setValue(SET_NOTIFICATION_MODAL_NEW_MESSAGE, e.target.value)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<GInput
						required
						autoSize
						minRows={3}
						placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.message })}
						value={this.props.notification.modalNew.message}
						onChange={this.onMessage}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	notification: state.menu.notification,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NotificationModalNewMessage))
