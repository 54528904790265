import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setContractBillingBillings, setLoadingAction, setObjArray } from "~/redux/action"
import { SET_CONTRACT_BILLING_NEW_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import ContractBillingModalNewDetails from "./ContractBillingModalNewDetails"

class ContractBillingModalNew extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ContractBillingModalNew",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_NEW_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.contract.modalNew.pendingInvoiceTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.contractBilling.saveContractBilling,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.id : "",
				userId: this.props.login.user.id,
				startDateTime: this.props.contract.startDateTime,
				endDateTime: this.props.contract.endDateTime,
				orgId: this.props.contract.location.id,
				insuranceCompanyCode: this.props.contract.modalNew.pendingInvoiceTable.original[0].debt.billing.billingPlan.insuranceCompanyCode,
				insuranceCompany: this.props.contract.modalNew.pendingInvoiceTable.original[0].debt.billing.billingPlan.insuranceCompany,
				insuranceCompanyAddress: this.props.contract.modalNew.pendingInvoiceTable.original[0].debt.billing.billingPlan.insuranceCompanyAddress,
				insuranceCompanyTel: this.props.contract.modalNew.pendingInvoiceTable.original[0].debt.billing.billingPlan.insuranceCompanyTel,
				dueDateTime: this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.dueDateTime : 0,
				factoryArea: this.props.contract.selectedBilling.billing ? this.props.contract.selectedBilling.billing.factoryArea : "",
				invoices: this.props.contract.modalNew.pendingInvoiceTable.original.map((pending) => {
					return {
						id: pending.debt.id,
						patientDiagnosis: pending.patientDiagnosis,
						claimNum: pending.claimNum,
						insuranceRef: pending.insuranceRef,
					}
				}),
			},
		}
		let callback = (res) => {
			if (res.data.contractBillings.length > 0) {
				let billings = this.props.contract.billingTable.original.filter((each) => each.id !== res.data.contractBillings[0].id)
				billings.push(res.data.contractBillings[0])
				this.props.setContractBillingBillings(billings)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.contractBilling)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<ContractBillingModalNewDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isContractBillingNewDisplay,
	login: state.login,
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setContractBillingBillings,
			setLoadingAction,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalNew))
