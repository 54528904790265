export const SET_ADM_COA_ASSETS = "SET_ADM_COA_ASSETS"
export const SET_ADM_COA_ASSETS_ACCOUNTS = "SET_ADM_COA_ASSETS_ACCOUNTS"
export const SET_ADM_COA_ASSETS_ACCOUNTS_LOADING = "SET_ADM_COA_ASSETS_ACCOUNTS_LOADING"
export const SET_ADM_COA_ASSETS_ADD_DISPLAY = "SET_ADM_COA_ASSETS_ADD_DISPLAY"
export const SET_ADM_COA_ASSETS_FILTER_CODE = "SET_ADM_COA_ASSETS_FILTER_CODE"
export const SET_ADM_COA_ASSETS_FILTER_NAME = "SET_ADM_COA_ASSETS_FILTER_NAME"
export const SET_ADM_COA_ASSETS_MODAL_ADD_CODE = "SET_ADM_COA_ASSETS_MODAL_ADD_CODE"
export const SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION = "SET_ADM_COA_ASSETS_MODAL_ADD_DESCRIPTION"
export const SET_ADM_COA_ASSETS_MODAL_ADD_NAME = "SET_ADM_COA_ASSETS_MODAL_ADD_NAME"
export const SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS = "SET_ADM_COA_ASSETS_MODAL_ADD_SELECTED_ASSETS"
export const SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL = "SET_ADM_COA_ASSETS_MODAL_ADD_SUB_LEVEL"
export const SET_ADM_COA_ASSETS_ORIGINAL = "SET_ADM_COA_ASSETS_ORIGINAL"
export const SET_ADM_COA_ASSETS_SELECTED_ACCOUNT = "SET_ADM_COA_ASSETS_SELECTED_ACCOUNT"
export const SET_ADM_COA_ASSETS_SELECTED_ASSETS = "SET_ADM_COA_ASSETS_SELECTED_ASSETS"
export const SET_ADM_COA_LIABILITIES = "SET_ADM_COA_LIABILITIES"
export const SET_ADM_COA_LIABILITIES_ACCOUNTS = "SET_ADM_COA_LIABILITIES_ACCOUNTS"
export const SET_ADM_COA_LIABILITIES_ACCOUNTS_LOADING = "SET_ADM_COA_LIABILITIES_ACCOUNTS_LOADING"
export const SET_ADM_COA_LIABILITIES_ADD_DISPLAY = "SET_ADM_COA_LIABILITIES_ADD_DISPLAY"
export const SET_ADM_COA_LIABILITIES_FILTER_CODE = "SET_ADM_COA_LIABILITIES_FILTER_CODE"
export const SET_ADM_COA_LIABILITIES_FILTER_NAME = "SET_ADM_COA_LIABILITIES_FILTER_NAME"
export const SET_ADM_COA_LIABILITIES_MODAL_ADD_CODE = "SET_ADM_COA_LIABILITIES_MODAL_ADD_CODE"
export const SET_ADM_COA_LIABILITIES_MODAL_ADD_DESCRIPTION = "SET_ADM_COA_LIABILITIES_MODAL_ADD_DESCRIPTION"
export const SET_ADM_COA_LIABILITIES_MODAL_ADD_NAME = "SET_ADM_COA_LIABILITIES_MODAL_ADD_NAME"
export const SET_ADM_COA_LIABILITIES_MODAL_ADD_SELECTED_LIABILITIES = "SET_ADM_COA_LIABILITIES_MODAL_ADD_SELECTED_LIABILITIES"
export const SET_ADM_COA_LIABILITIES_MODAL_ADD_SUB_LEVEL = "SET_ADM_COA_LIABILITIES_MODAL_ADD_SUB_LEVEL"
export const SET_ADM_COA_LIABILITIES_ORIGINAL = "SET_ADM_COA_LIABILITIES_ORIGINAL"
export const SET_ADM_COA_LIABILITIES_SELECTED_ACCOUNT = "SET_ADM_COA_LIABILITIES_SELECTED_ACCOUNT"
export const SET_ADM_COA_LIABILITIES_SELECTED_LIABILITIES = "SET_ADM_COA_LIABILITIES_SELECTED_LIABILITIES"
export const SET_ADM_COA_EQUITIES = "SET_ADM_COA_EQUITIES"
export const SET_ADM_COA_EQUITY_ACCOUNTS = "SET_ADM_COA_EQUITY_ACCOUNTS"
export const SET_ADM_COA_EQUITY_ACCOUNTS_LOADING = "SET_ADM_COA_EQUITY_ACCOUNTS_LOADING"
export const SET_ADM_COA_EQUITY_ADD_DISPLAY = "SET_ADM_COA_EQUITY_ADD_DISPLAY"
export const SET_ADM_COA_EQUITY_FILTER_CODE = "SET_ADM_COA_EQUITY_FILTER_CODE"
export const SET_ADM_COA_EQUITY_FILTER_NAME = "SET_ADM_COA_EQUITY_FILTER_NAME"
export const SET_ADM_COA_EQUITY_MODAL_ADD_CODE = "SET_ADM_COA_EQUITY_MODAL_ADD_CODE"
export const SET_ADM_COA_EQUITY_MODAL_ADD_DESCRIPTION = "SET_ADM_COA_EQUITY_MODAL_ADD_DESCRIPTION"
export const SET_ADM_COA_EQUITY_MODAL_ADD_NAME = "SET_ADM_COA_EQUITY_MODAL_ADD_NAME"
export const SET_ADM_COA_EQUITY_MODAL_ADD_SELECTED_EQUITY = "SET_ADM_COA_EQUITY_MODAL_ADD_SELECTED_EQUITY"
export const SET_ADM_COA_EQUITY_MODAL_ADD_SUB_LEVEL = "SET_ADM_COA_EQUITY_MODAL_ADD_SUB_LEVEL"
export const SET_ADM_COA_EQUITY_ORIGINAL = "SET_ADM_COA_EQUITY_ORIGINAL"
export const SET_ADM_COA_EQUITY_SELECTED_ACCOUNT = "SET_ADM_COA_EQUITY_SELECTED_ACCOUNT"
export const SET_ADM_COA_EQUITY_SELECTED_EQUITY = "SET_ADM_COA_EQUITY_SELECTED_EQUITY"
export const SET_ADM_COA_EXPENSES = "SET_ADM_COA_EXPENSES"
export const SET_ADM_COA_EXPENSES_ACCOUNTS = "SET_ADM_COA_EXPENSES_ACCOUNTS"
export const SET_ADM_COA_EXPENSES_ACCOUNTS_LOADING = "SET_ADM_COA_EXPENSES_ACCOUNTS_LOADING"
export const SET_ADM_COA_EXPENSES_ADD_DISPLAY = "SET_ADM_COA_EXPENSES_ADD_DISPLAY"
export const SET_ADM_COA_EXPENSES_FILTER_CODE = "SET_ADM_COA_EXPENSES_FILTER_CODE"
export const SET_ADM_COA_EXPENSES_FILTER_NAME = "SET_ADM_COA_EXPENSES_FILTER_NAME"
export const SET_ADM_COA_EXPENSES_MODAL_ADD_CODE = "SET_ADM_COA_EXPENSES_MODAL_ADD_CODE"
export const SET_ADM_COA_EXPENSES_MODAL_ADD_DESCRIPTION = "SET_ADM_COA_EXPENSES_MODAL_ADD_DESCRIPTION"
export const SET_ADM_COA_EXPENSES_MODAL_ADD_NAME = "SET_ADM_COA_EXPENSES_MODAL_ADD_NAME"
export const SET_ADM_COA_EXPENSES_MODAL_ADD_SELECTED_EXPENSE = "SET_ADM_COA_EXPENSES_MODAL_ADD_SELECTED_EXPENSE"
export const SET_ADM_COA_EXPENSES_MODAL_ADD_SUB_LEVEL = "SET_ADM_COA_EXPENSES_MODAL_ADD_SUB_LEVEL"
export const SET_ADM_COA_EXPENSES_ORIGINAL = "SET_ADM_COA_EXPENSES_ORIGINAL"
export const SET_ADM_COA_EXPENSES_SELECTED_ACCOUNT = "SET_ADM_COA_EXPENSES_SELECTED_ACCOUNT"
export const SET_ADM_COA_EXPENSES_SELECTED_EXPENSE = "SET_ADM_COA_EXPENSES_SELECTED_EXPENSE"
export const SET_ADM_COA_REVENUES = "SET_ADM_COA_REVENUES"
export const SET_ADM_COA_REVENUES_ACCOUNTS = "SET_ADM_COA_REVENUES_ACCOUNTS"
export const SET_ADM_COA_REVENUES_ACCOUNTS_LOADING = "SET_ADM_COA_REVENUES_ACCOUNTS_LOADING"
export const SET_ADM_COA_REVENUES_ADD_DISPLAY = "SET_ADM_COA_REVENUES_ADD_DISPLAY"
export const SET_ADM_COA_REVENUES_FILTER_CODE = "SET_ADM_COA_REVENUES_FILTER_CODE"
export const SET_ADM_COA_REVENUES_FILTER_NAME = "SET_ADM_COA_REVENUES_FILTER_NAME"
export const SET_ADM_COA_REVENUES_MODAL_ADD_CODE = "SET_ADM_COA_REVENUES_MODAL_ADD_CODE"
export const SET_ADM_COA_REVENUES_MODAL_ADD_DESCRIPTION = "SET_ADM_COA_REVENUES_MODAL_ADD_DESCRIPTION"
export const SET_ADM_COA_REVENUES_MODAL_ADD_NAME = "SET_ADM_COA_REVENUES_MODAL_ADD_NAME"
export const SET_ADM_COA_REVENUES_MODAL_ADD_SELECTED_REVENUE = "SET_ADM_COA_REVENUES_MODAL_ADD_SELECTED_REVENUE"
export const SET_ADM_COA_REVENUES_MODAL_ADD_SUB_LEVEL = "SET_ADM_COA_REVENUES_MODAL_ADD_SUB_LEVEL"
export const SET_ADM_COA_REVENUES_ORIGINAL = "SET_ADM_COA_REVENUES_ORIGINAL"
export const SET_ADM_COA_REVENUES_SELECTED_ACCOUNT = "SET_ADM_COA_REVENUES_SELECTED_ACCOUNT"
export const SET_ADM_COA_REVENUES_SELECTED_REVENUE = "SET_ADM_COA_REVENUES_SELECTED_REVENUE"
export const SET_ADM_COA_CHART_OF_ACCOUNTS_LOADING = "SET_ADM_COA_CHART_OF_ACCOUNTS_LOADING"
export const SET_ADM_COA_CHART_OF_ACCOUNTS = "SET_ADM_COA_CHART_OF_ACCOUNTS"
