import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalNurseServiceServices, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelModalNurseServiceService extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectService = this.onSelectService.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.hospitel.modalNurseService.searchName !== this.props.hospitel.modalNurseService.searchName ||
			!_.isEqual(
				prevProps.hospitel.modalNurseService.selectedCategory,
				this.props.hospitel.modalNurseService.selectedCategory
			)
		) {
			this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE, -1, null)
			this.props.setHospitelModalNurseServiceServices(
				this.props.hospitel.modalNurseService.selectedCategory.category.services
			)
		}
	}

	componentWillUnmount() {
		this.props.setHospitelModalNurseServiceServices([])
		this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE, -1, null)
	}

	onSelectService(service, index) {
		let selectedService = this.props.hospitel.modalNurseService.serviceTable.filtered[index]
		if (selectedService.role && selectedService.role.id !== this.props.login.user.userType.id) {
			this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE, -1, null)
		} else {
			this.props.setSelected(SET_HOSPITEL_MODAL_NURSE_SERVICE_SELECTED_SERVICE, index, selectedService)
		}
	}

	render() {
		return (
			<Fragment>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							hover
							enableHighlight
							onClick={this.onSelectService}
							data={this.props.hospitel.modalNurseService.serviceTable}
							isClearHighlight={this.props.hospitel.modalNurseService.selectedService.index === -1}
						/>
						{this.props.hospitel.modalNurseService.serviceTable.isLoading && (
							<Spinner
								animation={ResourceAssistance.Spinner.border}
								variant={ResourceAssistance.Button.variant.primary}
							/>
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setHospitelModalNurseServiceServices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalNurseServiceService))
