import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import ReportFinanceARATabCustomer from "./ReportFinanceARATabCustomer"
import ReportFinanceARATabAccountCode from "./ReportFinanceARATabAccountCode"

class ReportFinanceARATab extends React.Component {
	EReportFinanceARA = {
		CUSTOMER: "CUSTOMER",
		ACCOUNTING_CODE: "ACCOUNTING_CODE",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EReportFinanceARA.CUSTOMER,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Row>
					<Col>
						<Nav variant="tabs" justify>
							<Nav.Item>
								<Nav.Link eventKey={this.EReportFinanceARA.CUSTOMER}>{translate(ResourceAssistance.Message.insuranceCompany)}</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EReportFinanceARA.ACCOUNTING_CODE}>{translate(ResourceAssistance.Message.accountsCode)}</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.EReportFinanceARA.CUSTOMER}>
								{this.state.tab === this.EReportFinanceARA.CUSTOMER && <ReportFinanceARATabCustomer />}
							</Tab.Pane>
							<Tab.Pane eventKey={this.EReportFinanceARA.ACCOUNTING_CODE}>
								{this.state.tab === this.EReportFinanceARA.ACCOUNTING_CODE && <ReportFinanceARATabAccountCode />}
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportFinanceARATab)
