import React from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelModalDoctorOrderPendingItems, setSelected } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM } from "~/redux/type"
import { BigNumberRoundingMode, Utils } from "~/utils/Utils"
import HospitelModalDoctorOrderAction from "./HospitelModalDoctorOrderAction"
import HospitelModalDoctorOrderFilter from "./HospitelModalDoctorOrderFilter"
import HospitelModalDoctorOrderItem from "./HospitelModalDoctorOrderItem"
import HospitelModalDoctorOrderModify from "./HospitelModalDoctorOrderModify"
import HospitelModalDoctorOrderPending from "./HospitelModalDoctorOrderPending"

class HospitelModalDoctorOrderDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onKeyPress = this.onKeyPress.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
	}

	onKeyPress(e) {
		if (e.key === "Enter" || e.keyCode === 13) {
			e.preventDefault()
		}
	}

	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()

		let qty = Utils.calculateDoctorOrderQtyByRate(
			this.props.ipd.modalDoctorOrder.durationQty,
			this.props.ipd.modalDoctorOrder.rateDuration,
			this.props.ipd.modalDoctorOrder.selectedUnit.unit.amount,
			this.props.ipd.modalDoctorOrder.selectedUnit.unit.oneTime
		)
		let selectedItem = this.props.ipd.modalDoctorOrder.selectedItem.item
		let newItem = Object.assign({}, selectedItem, {
			...selectedItem,
			startDateTime: this.props.ipd.modalDoctorOrder.startDateTime,
			endDateTime: this.props.ipd.modalDoctorOrder.endDateTime,
			notes: this.props.ipd.modalDoctorOrder.notes,
			categoryId: this.props.ipd.modalDoctorOrder.selectedType.type.id,
			categoryName: this.props.ipd.modalDoctorOrder.selectedType.type.description,
			durationQty: this.props.ipd.modalDoctorOrder.isOneTime ? qty.dp(0, BigNumberRoundingMode.ROUND_UP) : qty.toNumber(),
			duration: Utils.BigNumber(this.props.ipd.modalDoctorOrder.duration).times(this.props.ipd.modalDoctorOrder.selectedDuration.duration.value).toNumber(),
			unit: this.props.ipd.modalDoctorOrder.selectedUnit.unit.oneTime
				? selectedItem.unit
				: { displayName: this.props.ipd.modalDoctorOrder.selectedUnit.unit.displayName },
			sellPricePerUnit: this.props.ipd.modalDoctorOrder.selectedUnit.unit.oneTime
				? selectedItem.sellPricePerUnit
				: this.props.ipd.modalDoctorOrder.selectedUnit.unit.sellingPricePerUnit,
			isPRN: this.props.ipd.modalDoctorOrder.isPRN,
			isOneTimeOnly: this.props.ipd.modalDoctorOrder.isOneTime,
			isSTAT: this.props.ipd.modalDoctorOrder.isSTAT,
			isTakeHome: this.props.ipd.modalDoctorOrder.isTakeHome,
			isNonDisposable: this.props.ipd.modalDoctorOrder.selectedUnit.unit.oneTime !== undefined && !this.props.ipd.modalDoctorOrder.selectedUnit.unit.oneTime,
			exclusiveDays: this.props.ipd.modalDoctorOrder.exclusiveDays.toString(),
		})
		let pendingItems = Array.from(this.props.ipd.modalDoctorOrder.pendingItemTable.filtered)
		pendingItems.push(newItem)
		this.props.setHospitelModalDoctorOrderPendingItems(pendingItems)
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitelModalDoctorOrderAction />
				<HospitelModalDoctorOrderFilter />
				<Form onKeyPress={this.onKeyPress} onSubmit={this.onDownBtn}>
					<Row className={"full-size"} style={{ height: "525px" }}>
						<Col style={{ marginTop: 0, alignSelf: "flex-start" }}>
							<HospitelModalDoctorOrderItem />
						</Col>
						{this.props.ipd.modalDoctorOrder.selectedItem.item && (
							<Col md={3} style={{ minWidth: "500px" }}>
								<HospitelModalDoctorOrderModify />
							</Col>
						)}
					</Row>
				</Form>
				<HospitelModalDoctorOrderPending />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setHospitelModalDoctorOrderPendingItems,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderDetails)
