import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPurchaseInvoices, setValue } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PI_LINK_DISPLAY } from "~/redux/type"
import PIModalLinkDetails from "./PIModalLinkDetails"
import _ from "lodash"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"

class PIModalLink extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PI_LINK_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			!this.props.PI.modalLink.selectedLink.link ||
			!this.props.PI.selectedPurchase.purchase ||
			(_.isEmpty(this.props.PI.selectedPurchase.purchase.linkedAccountingCode) &&
				this.props.PI.modalLink.links[0].id === this.props.PI.modalLink.selectedLink.link.id)
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.purchaseInvoice.updatePurchaseLink,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PI.location.id,
				purchaseInvoiceId: this.props.PI.selectedPI.PI.id,
				userId: this.props.login.user.id,
				invoices: [
					{
						purchases: [
							{
								id: this.props.PI.selectedPurchase.purchase.id,
								linkedAccountingCode: this.props.PI.modalLink.selectedLink.link.fullCode,
								linkedAccountingChartName: this.props.PI.modalLink.selectedLink.link.displayName,
								linkedAccountingChartDescription: this.props.PI.modalLink.selectedLink.link.description,
								linkedAccountingTransDescr: Utils.decodeAccountingTransDescr(
									this.props.PI.modalLink.selectedLink.link.transDescr,
									this.props.PI.selectedPI.PI.supplierName,
									Utils.formatDate(this.props.PI.selectedPI.PI.creationDateTime)
								),
							},
						],
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.invoices.length > 0) {
				let invoices = this.props.PI.purchaseInvoiceTable.original.filter((PI) => PI.id !== res.data.invoices[0].id)
				invoices.push(res.data.invoices[0])
				this.props.setPurchaseInvoices(invoices)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.linkGL)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PIModalLinkDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isPurchaseInvoiceLinkDisplay,
	login: state.login,
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPurchaseInvoices,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PIModalLink)
