import React from "react"
import {
	Button,
	Col,
	Container,
	Form,
	FormControl,
	Modal,
	ModalBody,
	ModalFooter,
	ModalTitle,
	Row,
	Spinner,
} from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { FcDownload, FcUpload } from "react-icons/fc"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setInventoryInventoryDetails,
	setInventoryNewInventorySelectedType,
	setInventoryPendingSuppliers,
	setInventorySelectedPendingSupplier,
	setInventorySelectedSupplier,
	setInventorySuppliers,
	setInventorySupplierSearchText,
	setLoadingAction,
} from "~/redux/action"
import { SET_INVENTORY_INVENTORY_DETAILS_LOADING } from "~/redux/type"
import { SET_ADD_INVENTORY_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

const MIN = "MIN"
const MAX = "MAX"

class AddInventory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AddInventory",
		}

		this.onSearchChange = this.onSearchChange.bind(this)
		this.onCloseBtn = this.onCloseBtn.bind(this)
		this.onDoneBtn = this.onDoneBtn.bind(this)
		this.onSelectSupplierClick = this.onSelectSupplierClick.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectPendingSupplierClick = this.onSelectPendingSupplierClick.bind(this)
		this.onChangeType = this.onChangeType.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				this.props.setInventorySuppliers(this.props.inventory.supplier.items)

				//initial type
				let type = document.getElementById(ResourceAssistance.ID.INV.newInventoryType)
				this.props.setInventoryNewInventorySelectedType(
					type.selectedIndex,
					this.props.inventory.type.types[type.selectedIndex]
				)
			} else {
				this.props.setInventoryNewInventorySelectedType(-1, null)
			}
		}

		if (this.props.isDisplay) {
			if (
				prevProps.inventory.newInventory.selectedType.index !== this.props.inventory.newInventory.selectedType.index
			) {
				this.props.setInventorySuppliers(this.props.inventory.supplier.items)
				this.props.setInventorySelectedSupplier(-1, null)
			}

			if (
				JSON.stringify(prevProps.inventory.pendingSupplier.suppliers) !==
				JSON.stringify(this.props.inventory.pendingSupplier.suppliers)
			) {
				this.props.setInventorySelectedPendingSupplier(-1, null)
			}
		}
	}

	reset() {
		this.props.setInventorySelectedSupplier(-1, null)
		this.props.setInventorySelectedPendingSupplier(-1, null)
		this.props.setInventoryPendingSuppliers([])
		this.props.onModalDisplayAction(SET_ADD_INVENTORY_DISPLAY, false)
	}

	onCloseBtn(event) {
		this.reset()
	}

	onDoneBtn(event) {
		let raw = {
			locationId: this.props.inventory.selectedInventory.loc.id,
			userId: this.props.login.user.id,
			inventoryDetails: this.props.inventory.pendingSupplier.suppliers.map((supplier) => {
				return {
					itemSupplierRelpId: supplier.id,
					min: supplier.min,
					max: supplier.max,
				}
			}),
		}

		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.inventory.saveInventory}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: raw,
		}

		let errorHandler = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, false)
			this.reset()
		}

		let callback = (res) => {
			let inventories = this.props.inventory.locs.inventories
			res.data.inventoryDetails.forEach((invD) => {
				inventories[this.props.inventory.selectedInventory.index].inventoryDetails.push(invD)
			})
			this.props.setInventoryInventoryDetails(
				inventories[this.props.inventory.selectedInventory.index].inventoryDetails
			)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_INVENTORY_INVENTORY_DETAILS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onSelectSupplierClick(item, index) {
		this.props.setInventorySelectedSupplier(index, this.props.inventory.supplier.filtered[index])
	}

	onSelectPendingSupplierClick(item, index) {
		this.props.setInventorySelectedPendingSupplier(index, this.props.inventory.pendingSupplier.suppliers[index])
	}

	onSearchChange(event) {
		this.props.setInventorySupplierSearchText(event.target.value)
		this.props.setInventorySelectedSupplier(-1, null)
		this.props.setInventorySuppliers(this.props.inventory.supplier.items)
	}

	onDownBtn(event) {
		if (this.props.inventory.selectedSupplier.index === -1) {
			return
		}
		let selectedSupplier = Object.assign({}, this.props.inventory.selectedSupplier.supplier, {
			min: document.getElementById(MIN).value,
			max: document.getElementById(MAX).value,
		})

		let pendingSuppliers = Utils.convertArrayToObject(this.props.inventory.pendingSupplier.suppliers, "id")

		let suppliers = Object.assign({}, pendingSuppliers, {
			[selectedSupplier.id]: selectedSupplier,
		})

		this.props.setInventoryPendingSuppliers(Object.values(suppliers))
	}

	onUpBtn(event) {
		if (this.props.inventory.selectedPendingSupplier.index === -1) {
			return
		}

		let selectedSupplier = this.props.inventory.selectedPendingSupplier.supplier
		// let pendingSuppliers = Utils.convertArrayToObject(this.props.inventory.pendingSupplier.suppliers,"id")
		let pendingSuppliers = this.props.inventory.pendingSupplier.suppliers.filter(
			(supplier) => supplier.id !== selectedSupplier.id
		)

		this.props.setInventoryPendingSuppliers(pendingSuppliers)
	}

	onChangeType(event) {
		this.props.setInventoryNewInventorySelectedType(
			event.target.value,
			this.props.inventory.type.types[event.target.value]
		)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onCloseBtn}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.inventory)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<Row>
								<Col md="auto">
									<FormControl
										id={ResourceAssistance.ID.INV.newInventoryType}
										size={ResourceAssistance.FormControl.size.sm}
										as={ResourceAssistance.FormControl.as.select}
										onChange={this.onChangeType}
									>
										{Utils.renderOptions(this.props.inventory.type.types, false)}
									</FormControl>
								</Col>
								<Col>
									<SearchBox
										callbacks={[this.onSearchChange]}
										placeholders={[ResourceAssistance.Message.name]}
										values={[this.props.inventory.supplier.searchText]}
									/>
								</Col>
							</Row>
							<Row className={"g-0 full-size"}>
								<Col>
									<ScrollableTable
										striped
										responsive
										hover
										enableHighlight
										onClick={this.onSelectSupplierClick}
										data={this.props.inventory.supplier}
										isClearHighlight={false}
									/>
									{this.props.inventory.supplier.isLoading && (
										<Spinner
											animation={ResourceAssistance.Spinner.border}
											variant={ResourceAssistance.Button.variant.primary}
										/>
									)}
									<Row>
										<Col
											className={
												this.props.inventory.selectedSupplier.index === -1 ? ResourceAssistance.CSS.disabled : ""
											}
										>
											<FcDownload size={"3rem"} onClick={this.onDownBtn} style={{ alignSelf: "center" }} />
										</Col>
										<Col
											className={
												this.props.inventory.selectedPendingSupplier.index === -1 ? ResourceAssistance.CSS.disabled : ""
											}
										>
											<FcUpload size={"3rem"} onClick={this.onUpBtn} style={{ alignSelf: "center" }} />
										</Col>
									</Row>
								</Col>
								<Col sm="auto">
									<Container>
										<Row>
											<Col sm="auto">
												<label>{translate(ResourceAssistance.Message.min)}</label>
											</Col>
											<Col>
												<FormControl
													id={MIN}
													type={ResourceAssistance.FormControl.type.number}
													size="sm"
													disabled={this.props.inventory.selectedSupplier.index === -1}
													defaultValue={this.props.inventory.selectedSupplier.index === -1 ? "" : 0}
												/>
											</Col>
										</Row>
										<Row>
											<Col sm="auto">
												<label>{translate(ResourceAssistance.Message.max)}</label>
											</Col>
											<Col>
												<FormControl
													id={MAX}
													type={ResourceAssistance.FormControl.type.number}
													size="sm"
													disabled={this.props.inventory.selectedSupplier.index === -1}
													defaultValue={this.props.inventory.selectedSupplier.index === -1 ? "" : 0}
												/>
											</Col>
										</Row>
									</Container>
								</Col>
							</Row>
							<Row className={"full-size"}>
								<Col>
									<ScrollableTable
										striped
										responsive
										hover
										enableHighlight
										onClick={this.onSelectPendingSupplierClick}
										data={this.props.inventory.pendingSupplier}
										isClearHighlight={this.props.inventory.selectedPendingSupplier.index === -1}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onCloseBtn}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							onClick={this.onDoneBtn}
							disabled={this.props.inventory.pendingSupplier.suppliers.length === 0}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isAddInventoryDisplay,
	inventory: state.inventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setInventorySelectedSupplier,
			setInventorySupplierSearchText,
			setInventorySuppliers,
			setLoadingAction,
			setInventoryPendingSuppliers,
			setInventorySelectedPendingSupplier,
			setInventoryInventoryDetails,
			setInventoryNewInventorySelectedType,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AddInventory)
