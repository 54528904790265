import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { IoMdDoneAll } from "react-icons/io"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setPSModalPaymentPlanPendingInvoices } from "~/redux/action"

class PSModalPaymentPlanInvoiceAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	isDownBtnDisabled() {
		return !this.props.PS.modalPaymentPlan.selectedInvoice.invoice
	}

	isSelectAllDisabled() {
		return this.props.PS.modalPaymentPlan.invoiceTable.filtered.length === 0
	}

	onDownBtn() {
		let selectedInvoice = Object.assign({}, this.props.PS.modalPaymentPlan.selectedInvoice.invoice)
		let pendingInvoices = Array.from(this.props.PS.modalPaymentPlan.pendingInvoiceTable.original)
		pendingInvoices.push(selectedInvoice)
		this.props.setPSModalPaymentPlanPendingInvoices(pendingInvoices)
	}

	onUpBtn() {
		let pendingInvoices = this.props.PS.modalPaymentPlan.pendingInvoiceTable.original.filter(
			(each, index) => index !== this.props.PS.modalPaymentPlan.selectedPendingInvoice.index
		)
		this.props.setPSModalPaymentPlanPendingInvoices(pendingInvoices)
	}

	onSelectAll() {
		let pendingInvoices = Array.from(this.props.PS.modalPaymentPlan.pendingInvoiceTable.original)
		let selectedInvoices = Array.from(this.props.PS.modalPaymentPlan.invoiceTable.filtered)
		this.props.setPSModalPaymentPlanPendingInvoices(pendingInvoices.concat(selectedInvoices))
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.isDownBtnDisabled()}
						onClick={this.onDownBtn}
					>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.isSelectAllDisabled()}
						onClick={this.onSelectAll}
					>
						<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.selectAll)}
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.PS.modalPaymentPlan.selectedPendingInvoice.index === -1}
						onClick={this.onUpBtn}
					>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setPSModalPaymentPlanPendingInvoices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PSModalPaymentPlanInvoiceAction)
