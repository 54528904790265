import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_TF_DISPLAY_ALL_IND,
	SET_TF_MODAL_CANCEL_TRI_REASON,
	SET_TF_MODAL_REQUEST_FROM_LOCATIONS,
	SET_TF_MODAL_REQUEST_ITEMS,
	SET_TF_MODAL_REQUEST_PENDING_ITEMS,
	SET_TF_MODAL_REQUEST_PENDING_SELECTED_ITEM,
	SET_TF_MODAL_REQUEST_SEARCH,
	SET_TF_MODAL_REQUEST_SELECTED_DUE_DATE,
	SET_TF_MODAL_REQUEST_SELECTED_ITEM,
	SET_TF_MODAL_REQUEST_SELECTED_ITEM_AMOUNT,
	SET_TF_MODAL_REQUEST_SELECTED_TO_BRANCH,
	SET_TF_MODAL_REQUEST_SELECTED_TO_LOCATION,
	SET_TF_MODAL_REQUEST_SELECTED_TO_ORG,
	SET_TF_MODAL_REQUEST_SELECTED_TYPE,
	SET_TF_MODAL_REQUEST_TO_BRANCHES,
	SET_TF_MODAL_REQUEST_TO_LOCATIONS,
	SET_TF_MODAL_REQUEST_TO_ORGS,
	SET_TF_MODAL_TRANSFER_BRANCHES,
	SET_TF_MODAL_TRANSFER_CURRENT,
	SET_TF_MODAL_TRANSFER_EXPIRE,
	SET_TF_MODAL_TRANSFER_ITEMS,
	SET_TF_MODAL_TRANSFER_LOTS,
	SET_TF_MODAL_TRANSFER_ORGS,
	SET_TF_MODAL_TRANSFER_PENDING_ITEMS,
	SET_TF_MODAL_TRANSFER_SELECTED_BRANCH,
	SET_TF_MODAL_TRANSFER_SELECTED_ITEM,
	SET_TF_MODAL_TRANSFER_SELECTED_LOT,
	SET_TF_MODAL_TRANSFER_SELECTED_ORG,
	SET_TF_MODAL_TRANSFER_SELECTED_PENDING_ITEM,
	SET_TF_MODAL_TRANSFER_TRANSFER,
	SET_TF_REQUESTS,
	SET_TF_REQUEST_ITEMS,
	SET_TF_SELECTED_REQUEST,
	SET_TF_SELECTED_REQUEST_ITEM,
	SET_TF_SELECTED_TRANSFER,
	SET_TF_SELECTED_TRANSFER_ITEM,
	SET_TF_TRANSFERS,
	SET_TF_TRANSFER_ITEMS,
	SET_TF_TYPES,
} from "../../type/type/TF"

export const setTfDisplayAllInd = (isDisplayAll) => {
	return {
		type: SET_TF_DISPLAY_ALL_IND,
		payload: {
			isDisplayAll: isDisplayAll,
		},
	}
}

export const setTfRequests = (requests) => {
	return (dispatch, getState) => {
		let filtered = requests
			.filter(
				(each) =>
					String(each.id).includes(getState().transfer.searchNum.toLowerCase()) &&
					each.locationName.toLowerCase().includes(getState().transfer.searchLocation.toLowerCase())
			)
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let color = []
		if (getState().transfer.isDisplayAll) {
			color = filtered.map((each) => {
				let success = ResourceAssistance.CSS.Color.green
				let isDisplay = each.requesterApproved && each.approvalApproved
				return [isDisplay, isDisplay ? success : ""]
			})
		} else {
			filtered = filtered.filter((each) => !(each.requesterApproved && each.approvalApproved))
		}

		let body = filtered.map((each) => {
			return [each.creationDate, each.creationTime, each.id, each.locationName, each.requesterApproved, each.approvalApproved]
		})

		dispatch({
			type: SET_TF_REQUESTS,
			payload: {
				original: requests,
				filtered: filtered,
				body: body,
				rowColor: color,
			},
		})
		return Promise.resolve()
	}
}

export const setTfSelectedRequest = (index, request) => {
	return {
		type: SET_TF_SELECTED_REQUEST,
		payload: {
			index: index,
			request: request,
		},
	}
}

export const setTfRequestItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items.sort((a, b) =>
			Utils.sort(a.dueDateTime, b.dueDateTime, [
				[a.name, b.name],
				[a.code, b.code],
			])
		)

		let colors = filtered.map((each) => {
			let color = ""

			if (each.amount === each.transferredAmount) {
				color = ResourceAssistance.CSS.Color.green
			} else if (each.cancelled) {
				color = ResourceAssistance.CSS.Color.red
			} else if (each.transferredAmount > 0) {
				color = ResourceAssistance.CSS.Color.warning
			}

			return [true, color]
		})

		let overlayMsgs = filtered.map((each) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[each.cancelled, each.reason],
			]
		})

		let body = filtered.map((each) => [
			each.code,
			each.name,
			each.brand,
			each.unit,
			Utils.formatNumWithComma(each.amount),
			Utils.formatDate(each.dueDateTime),
			Utils.formatNumWithComma(each.transferredAmount),
			[true, !each.cancelled],
		])

		dispatch({
			type: SET_TF_REQUEST_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
				rowColor: colors,
				overlayMsg: overlayMsgs,
			},
		})

		return Promise.resolve()
	}
}

export const setTfSelectedRequestItem = (index, item) => {
	return {
		type: SET_TF_SELECTED_REQUEST_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setTfTransfers = (transfers) => {
	return (dispatch, getState) => {
		let filtered = transfers.sort((a, b) => Utils.sort(b.lastModifiedDateTime, a.lastModifiedDateTime))

		let colors = []
		colors = filtered.map((each) => {
			let allReceived = ResourceAssistance.CSS.Color.green
			let allCancelled = ResourceAssistance.CSS.Color.red
			let partiallyReceived = ResourceAssistance.CSS.Color.warning

			let color = ""
			if (each.items.every((transferItem) => transferItem.status === "RECEIVED")) {
				color = allReceived
			} else if (each.items.every((transferItem) => transferItem.status === "CANCELLED")) {
				color = allCancelled
			} else if (each.items.some((transferItem) => transferItem.status === "RECEIVED") || each.items.some((transferItem) => transferItem.status === "CANCELLED")) {
				color = partiallyReceived
			}

			return [true, color]
		})

		let body = filtered.map((each) => {
			let location = each.location.parent.displayName + "-" + each.location.displayName
			return [
				each.id,
				Utils.formatDate(each.lastModifiedDateTime),
				location,
				each.approval ? each.approval.displayName : "",
				[!each.approval, !each.approval, each.approval],
			]
		})
		dispatch({
			type: SET_TF_TRANSFERS,
			payload: {
				transfers: transfers,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})

		return Promise.resolve()
	}
}

export const setTfSelectedTransfer = (index, transfer) => {
	return {
		type: SET_TF_SELECTED_TRANSFER,
		payload: {
			index: index,
			transfer: transfer,
		},
	}
}

export const setTfTransferItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items.sort((a, b) => Utils.sort(a, b))

		let colors = []
		colors = filtered.map((transferItem) => {
			let allReceived = ResourceAssistance.CSS.Color.green
			let allCancelled = ResourceAssistance.CSS.Color.red

			let color = ""
			if (transferItem.status === "RECEIVED") {
				color = allReceived
			} else if (transferItem.status === "CANCELLED") {
				color = allCancelled
			}

			return [true, color]
		})

		let body = filtered.map((each) => {
			return [
				each.lotNum,
				Utils.formatDate(each.expiredDateTime),
				each.code,
				each.name,
				each.brand,
				each.unit,
				Utils.formatNumWithComma(each.amount),
				Utils.convertEnum(each.status),
				[each.status !== "RECEIVED", each.status !== "CANCELLED"],
			]
		})
		dispatch({
			type: SET_TF_TRANSFER_ITEMS,
			payload: {
				items: items,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
	}
}

export const setTfSelectedTransferItem = (index, item) => {
	return {
		type: SET_TF_SELECTED_TRANSFER_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setTfModalRequestToOrgs = (locations) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_TO_ORGS,
			payload: {
				locations: locations,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalRequestToBranches = (locations) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_TO_BRANCHES,
			payload: {
				locations: locations,
			},
		})
	}
}

export const setTfModalRequestToLocations = (locations) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_TO_LOCATIONS,
			payload: {
				locations: locations,
			},
		})
	}
}

export const setTfModalRequestSelectedToOrg = (index, location) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_TO_ORG,
			payload: {
				index: index,
				location: location,
			},
		})
	}
}

export const setTfModalRequestSelectedToBranch = (index, location) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_TO_BRANCH,
			payload: {
				index: index,
				location: location,
			},
		})
	}
}

export const setTfModalRequestSelectedToLocation = (index, location) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_TO_LOCATION,
			payload: {
				index: index,
				location: location,
			},
		})
	}
}

export const setTfModalRequestFromLocations = (locations) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_FROM_LOCATIONS,
			payload: {
				locations: locations,
			},
		})
	}
}

export const setTfTypes = (types) => {
	return (dispatch, getState) => {
		types.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		dispatch({
			type: SET_TF_TYPES,
			payload: {
				types: types,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestSelectedType = (index, type) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_TYPE,
			payload: {
				index: index,
				type: type,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestSearchText = (text) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SEARCH,
			payload: {
				text: text,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestItems = (items) => {
	return (dispatch, getState) => {
		let itemSupplierRelps = []
		items.forEach((item) => {
			item.itemSupplierRelps.forEach((itemSupplierRelp) => {
				itemSupplierRelps.push({
					...itemSupplierRelp,
					typeId: item.type.id,
				})
			})
		})

		// let selectedInventoryDetails = getState().inventory.inventories.inventories.reduce((object, item) => {
		// 	return [...object, item.itemSupplierRelpId]
		// }, [])

		itemSupplierRelps.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		let filtered = itemSupplierRelps.filter(
			(itemSupplierRelp) =>
				getState().transfer.newRequestModal.selectedType.type &&
				itemSupplierRelp.typeId === getState().transfer.newRequestModal.selectedType.type.id &&
				itemSupplierRelp.displayName.toLowerCase().includes(getState().transfer.newRequestModal.searchText.toLowerCase())
			// &&
			// !selectedInventoryDetails.includes(itemSupplierRelp.id)
		)

		let body = filtered.map((itemSupplierRelp) => {
			return [itemSupplierRelp.code, itemSupplierRelp.displayName, itemSupplierRelp.brand, itemSupplierRelp.unit]
		})

		dispatch({
			type: SET_TF_MODAL_REQUEST_ITEMS,
			payload: {
				items: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestSelectedItem = (index, item) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_ITEM,
			payload: {
				index: index,
				item: item,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestSelectedItemAmount = (amount) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_ITEM_AMOUNT,
			payload: {
				amount: amount,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestSelectedDueDate = (date) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_SELECTED_DUE_DATE,
			payload: {
				date: date,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalRequestPendingItems = (items) => {
	return (dispatch, getState) => {
		items.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		let filtered = items

		let body = filtered.map((item) => {
			return [
				item.code,
				item.displayName ? item.displayName : item.name,
				item.brand,
				item.unit,
				Utils.formatNumWithComma(item.amount),
				Utils.formatDate(item.dueDateTime),
			]
		})

		dispatch({
			type: SET_TF_MODAL_REQUEST_PENDING_ITEMS,
			payload: {
				items: items,
				filtered: filtered,
				body: body,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalRequestPendingSelectedItem = (index, item) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_REQUEST_PENDING_SELECTED_ITEM,
			payload: {
				index: index,
				item: item,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalTransferOrgs = (locations) => {
	return {
		type: SET_TF_MODAL_TRANSFER_ORGS,
		payload: {
			locations: locations,
		},
	}
}

export const setTfModalTransferBranches = (locations) => {
	return (dispatch, getState) => {
		let filtered = locations
		if (locations.length > 0) {
			let selectedItem = getState().transfer.newTransferModal.selectedItem.item
			let key = (selectedItem.code + selectedItem.name + selectedItem.brand + selectedItem.unit).trim().toLowerCase()
			filtered = locations.filter((location) => {
				return location.inStockItems.some((item) => {
					return (item.code + item.displayName + item.brand + item.unit).trim().toLowerCase() === key && item.lots.some((lot) => lot.current > 0)
				})
			})
		}
		dispatch({
			type: SET_TF_MODAL_TRANSFER_BRANCHES,
			payload: {
				locations: filtered,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalTransferLots = (branch) => {
	return (dispatch, getState) => {
		let lots = []
		if (branch) {
			let selectedItem = getState().transfer.newTransferModal.selectedItem.item
			let inStockItem = branch.inStockItems.find(
				(item) =>
					(item.code + item.name + item.brand + item.unit).trim().toLowerCase() ===
					(selectedItem.code + selectedItem.displayName + selectedItem.brand + selectedItem.unit).trim().toLowerCase()
			)
			lots = inStockItem ? inStockItem.lots.filter((lot) => lot.current > 0).sort((a, b) => Utils.sort(a.expiredDateTime, b.expiredDateTime)) : []
		}
		dispatch({
			type: SET_TF_MODAL_TRANSFER_LOTS,
			payload: {
				lots: lots,
			},
		})
		return Promise.resolve()
	}
}

export const setTfModalTransferOrgLots = (selectedItem, location, lotNumber, amount) => {
	return (dispatch, getState) => {
		const orgs = getState().transfer.newTransferModal.orgs

		if (orgs.length > 0) {
			const key = (selectedItem.code + selectedItem.name + selectedItem.brand + selectedItem.unit).trim().toLowerCase()

			orgs.forEach((org, o) => {
				org.locations.forEach((loc, i) => {
					if (org.displayName + "-" + loc.displayName === location) {
						loc.inStockItems.forEach((item, j) => {
							item.lots.forEach((lot, k) => {
								if ((item.code + item.displayName + item.brand + item.unit).trim().toLowerCase() === key && lot.number === lotNumber) {
									lot.current = lot.current + amount
								}
							})
						})
					}
				})
			})
		}

		dispatch({
			type: SET_TF_MODAL_TRANSFER_ORGS,
			payload: {
				locations: orgs,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalTransferItems = (items) => {
	return (dispatch, getState) => {
		let body = []
		let filtered = items
			.filter((item) => !item.cancelled)
			.filter((each) => {
				//calculate current item amount by substract all existing transfer order but current selected transfer order
				let amount = getState()
					.transfer.transferTable.original.filter(
						(each) => !getState().transfer.selectedTransfer.transfer || each.id !== getState().transfer.selectedTransfer.transfer.id
					)
					.reduce((obj, cur) => {
						cur.items
							.filter((item) => item.status !== "CANCELLED")
							.forEach((item) => {
								if ((each.code + each.name + each.brand + each.unit).trim().toLowerCase() === (item.code + item.name + item.brand + item.unit).trim().toLowerCase()) {
									obj = obj.minus(item.amount)
								}
							})
						return obj
					}, Utils.BigNumber(each.amount))

				//calculate current item amount by substract current selected transfer order's pending item table in the transfer modal.
				amount = getState().transfer.newTransferModal.pendingItemTable.original.reduce((obj, cur) => {
					if ((each.code + each.name + each.brand + each.unit).trim().toLowerCase() === (cur.code + cur.name + cur.brand + cur.unit).trim().toLowerCase()) {
						return obj.minus(cur.amount)
					}
					return obj
				}, amount)

				if (amount.gt(0)) {
					body.push([each.code, each.name, each.brand, each.unit, amount.toNumber(), Utils.formatDate(each.dueDateTime)])
					return true
				}
				return false
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		dispatch({
			type: SET_TF_MODAL_TRANSFER_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalTransferSelectedItem = (index, item) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_TF_MODAL_TRANSFER_SELECTED_ITEM,
			payload: {
				index: index,
				item: item,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalTransferSelectedOrg = (index, location) => {
	return {
		type: SET_TF_MODAL_TRANSFER_SELECTED_ORG,
		payload: {
			index: index,
			location: location,
		},
	}
}

export const setTfModalTransferSelectedBranch = (index, location) => {
	return {
		type: SET_TF_MODAL_TRANSFER_SELECTED_BRANCH,
		payload: {
			index: index,
			location: location,
		},
	}
}

export const setTfModalTransferSelectedLot = (index, lot) => {
	return {
		type: SET_TF_MODAL_TRANSFER_SELECTED_LOT,
		payload: {
			index: index,
			lot: lot,
		},
	}
}

export const setTfModalTransferSelectedPendingItem = (index, item) => {
	return {
		type: SET_TF_MODAL_TRANSFER_SELECTED_PENDING_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setTfModalTransferCurrent = (location, qty) => {
	return (dispatch, getState) => {
		let current = ""
		if (location) {
			let selectedItem = getState().transfer.newTransferModal.selectedItem.item
			let inStockItem = location.inStockItems.find(
				(item) =>
					(item.code + item.displayName + item.brand + item.unit).trim().toLowerCase() ===
					(selectedItem.code + selectedItem.name + selectedItem.brand + selectedItem.unit).trim().toLowerCase()
			)
			current = inStockItem.lots.reduce((object, lot) => {
				return object + lot.current
			}, 0)
		} else {
			current = qty
		}
		dispatch({
			type: SET_TF_MODAL_TRANSFER_CURRENT,
			payload: {
				current: current,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalTransferExpireDate = (time) => {
	return {
		type: SET_TF_MODAL_TRANSFER_EXPIRE,
		payload: {
			time: time,
		},
	}
}

export const setTfModalTransferTransferQty = (qty) => {
	return {
		type: SET_TF_MODAL_TRANSFER_TRANSFER,
		payload: {
			amount: qty,
		},
	}
}

export const setTfModalTransferPendingItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items.sort((a, b) => Utils.sort(a.displayName, b.displayName))
		let body = filtered.map((each) => {
			return [
				each.location,
				each.lotNum,
				Utils.formatDate(each.expiredDateTime),
				each.code,
				each.name,
				each.brand,
				each.unit,
				Utils.formatNumWithComma(each.amount),
			]
		})

		dispatch({
			type: SET_TF_MODAL_TRANSFER_PENDING_ITEMS,
			payload: {
				items: items,
				filtered: filtered,
				body: body,
			},
		})

		return Promise.resolve()
	}
}

export const setTfModalCancelTRIReason = (reason) => {
	return {
		type: SET_TF_MODAL_CANCEL_TRI_REASON,
		payload: {
			reason: reason,
		},
	}
}
