import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_AR_HEALTH_CARE_PLANS, SET_PAGE_LOADING } from "~/redux/type"
import AccountsReceivableRightBillingStatement from "./AccountsReceivableRightBillingStatement"
import AccountsReceivableRightGroupTab from "./AccountsReceivableRightGroupTab"
import AccountsReceivableRightInfo from "./AccountsReceivableRightInfo"

class AccountsReceivableRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AccountsReceivableRight",
		}
	}

	componentDidMount() {
		this.loadHealthCarePlans()
	}

	loadHealthCarePlans() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.accountsReceivable.getHealthCarePlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_AR_HEALTH_CARE_PLANS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<AccountsReceivableRightInfo />
				<AccountsReceivableRightGroupTab />
				<AccountsReceivableRightBillingStatement />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AccountsReceivableRight)
