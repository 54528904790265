import { SET_ADM_WP_DRG, SET_ADM_WP_MIN_COST, SET_ADM_WP_PROPERTY } from "~/redux/type"

const init = {
	property: undefined,
	surgicalAuth: {
		drg: undefined,
		minCost: undefined,
	},
}

const configWorkflowPropertyReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ADM_WP_PROPERTY: {
			return Object.assign({}, state, {
				property: action.payload.value,
			})
		}
		case SET_ADM_WP_DRG:
			return Object.assign({}, state, {
				surgicalAuth: {
					...state.surgicalAuth,
					drg: action.payload.value,
				},
			})
		case SET_ADM_WP_MIN_COST:
			return Object.assign({}, state, {
				surgicalAuth: {
					...state.surgicalAuth,
					minCost: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default configWorkflowPropertyReducer
