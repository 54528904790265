import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_AP_GENERAL_GROUPS, SET_AP_GL_GROUPS, SET_AP_INVOICES, SET_AP_ITEMS, SET_AP_RECEIVES } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setAccountsPayableReceives = (receives) => {
	return (dispatch, getState) => {
		let filtered = receives
			.filter((receives) => receives.id.includes(getState().accounting.accountsPayable.filterReceiveId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))
		if (_.includes(getState().accounting.accountsPayable.filters, ResourceAssistance.Message.incomplete)) {
			filtered = filtered.filter((receive) => receive.receiveInvoices.some((each) => !each.accountsPayableApproverComplete))
		}
		let rowColor = filtered.map((receive) => {
			let color = ""
			if (!receive.receiveInvoices.some((each) => !each.accountsPayableApproverComplete)) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})
		let body = filtered.map((receive) => {
			return [Utils.formatDate(receive.creationDateTime), receive.id]
		})

		dispatch({
			type: SET_AP_RECEIVES,
			payload: {
				original: receives,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setAccountsPayableInvoices = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices.sort((a, b) => Utils.sort(a.receivedDate, b.receivedDate))

		let isDebitCreditEqualAry = []
		let body = filtered.map((invoice) => {
			let debit = Utils.BigNumber(0)
			let credit = Utils.BigNumber(0)
			let totalBalance = invoice.items.reduce(
				(totalAry, cur) => {
					totalAry[0] = totalAry[0].plus(cur.totalPrice)
					totalAry[1] = totalAry[1].plus(
						Utils.BigNumber(cur.totalPrice)
							.times(1 - invoice.discount / 100)
							.times(cur.taxRate / 100)
					)
					return totalAry
				},
				[Utils.BigNumber(0), Utils.BigNumber(0)]
			)
			let totalDiscounted = totalBalance[0].times(1 - invoice.discount / 100)

			if (_.isEmpty(invoice.accountsPayableGLs)) {
				debit = invoice.items.reduce((total, cur) => {
					let vDiscount = Utils.BigNumber(cur.totalPrice).times(invoice.discount).dividedBy(100)
					let tax = Utils.BigNumber(cur.totalPrice).minus(vDiscount).times(cur.taxRate).dividedBy(100)
					total = total.plus(cur.totalPrice).minus(vDiscount).plus(tax)
					return total
				}, Utils.BigNumber(0))

				//GL AP
				credit = Utils.BigNumber(0)
				let APs = getState()
					.accounting.accountsPayable.accountsPayableActivities.filter((each) => !each.accountsPayableRCSelectable)
					.concat(getState().accounting.accountsPayable.selectableAccountingCodes)
				APs.forEach((each) => {
					let foundItems = invoice.items.filter((item) => item.category.activities.some((activity) => activity.chartOfAccounts.id === each.chartOfAccounts.id))
					let total = Utils.calculateAccountsPayableActivityTotal(each, foundItems, invoice.discount, invoice.discountReceipt, invoice.taxedTotalPrice)
					if (each.debit) {
						debit = debit.plus(total)
					} else {
						credit = credit.plus(total)
					}
				})
				isDebitCreditEqualAry.push(credit.eq(debit))
			} else {
				isDebitCreditEqualAry.push(true)
			}
			return [
				Utils.formatDate(invoice.receivedDate),
				invoice.invoice,
				invoice.supplierCode,
				invoice.supplierName,
				Utils.formatNumWithComma(totalBalance[0].toFixed(2)),
				Utils.formatNumWithComma(totalBalance[0].times(invoice.discount).dividedBy(100).toFixed(2)) + " (" + invoice.discount + "%)",
				Utils.formatNumWithComma(totalDiscounted.toFixed(2)),
				Utils.formatNumWithComma(totalBalance[1].toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(invoice.taxedTotalPrice).toFixed(2)),
				[
					!invoice.accountsPayableInspectorComplete && !invoice.items.some((each) => _.isEmpty(each.accountingCode)) && credit.eq(debit),
					!invoice.accountsPayableApproverComplete && invoice.accountsPayableInspectorComplete,
					!invoice.accountsPayableApproverComplete && invoice.accountsPayableInspectorComplete,
					invoice.accountsPayableApproverComplete,
				],
			]
		})

		let rowColor = filtered.map((invoice, index) => {
			let color = ""
			if (invoice.accountsPayableApproverComplete) {
				color = ResourceAssistance.CSS.Color.green
			} else if (!isDebitCreditEqualAry[index]) {
				color = ResourceAssistance.CSS.Color.warning
			}
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_AP_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setAPGeneralGroups = (items) => {
	return (dispatch, getState) => {
		let filtered = Object.entries(Utils.groupBy(items, "categoryName")).sort((a, b) => Utils.sort(a[0], b[0]))

		let body = filtered.map(([key, values]) => {
			let totals = values.reduce(
				(totalArray, cur) => {
					let balance = Utils.BigNumber(cur.amount).dividedBy(cur.minPerOrder).times(cur.pricePerOrder)
					let discount = Utils.BigNumber(cur.amount).dividedBy(cur.minPerOrder).times(cur.pricePerOrder).times(cur.discount).dividedBy(100)
					totalArray[0] = totalArray[0].plus(balance)
					totalArray[1] = totalArray[1].plus(discount)
					return totalArray
				},
				[Utils.BigNumber(0), Utils.BigNumber(0)]
			)
			return [
				key,
				Utils.formatNumWithComma(totals[0].toFixed(2)),
				Utils.formatNumWithComma(totals[1].toFixed(2)),
				Utils.formatNumWithComma(totals[0].minus(totals[1]).toFixed(2)),
			]
		})

		let colStyle = filtered.map((bs) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_AP_GENERAL_GROUPS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setAPGLGroupsByGL = (generalLedgers) => {
	return (dispatch, getState) => {
		let sortedGLs = generalLedgers.sort((a, b) => Utils.sort(b.debit, a.debit, [[a.code, b.code]]))
		let filtered = sortedGLs.map((gl) => {
			if (gl.systemGenerated) {
				let foundItems = getState().accounting.accountsPayable.selectedInvoice.invoice.items.filter((item) =>
					item.category.activities.some((activity) => activity.chartOfAccounts.fullCode === gl.code)
				)
				return [gl.id, foundItems]
			} else {
				return [gl.id, getState().accounting.accountsPayable.selectedInvoice.invoice.items.filter((item) => item.accountingCode === gl.code)]
			}
		})
		let body = sortedGLs.map((gl) => {
			return [
				gl.code,
				gl.name,
				gl.category,
				gl.transactionDescription,
				Utils.formatNumWithComma(Utils.BigNumber(gl.debit).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(gl.credit).toFixed(2)),
			]
		})

		let rowColor = sortedGLs.map((each) => {
			let color = ""
			if (each.systemGenerated) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		let colStyle = body.map(() => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_AP_GL_GROUPS,
			payload: {
				original: generalLedgers,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setAPGLGroupsByItem = (items) => {
	return (dispatch, getState) => {
		let filtered = Object.entries(Utils.groupBy(items, "accountingCode", "accountingChartName", "accountingTransactionDescription", "categoryName")).sort(
			(a, b) => Utils.sort(a[0], b[0])
		)

		let body = filtered.map(([key, values]) => {
			let total = values.reduce((total, cur) => {
				let vDiscount = Utils.BigNumber(cur.totalPrice).times(getState().accounting.accountsPayable.selectedInvoice.invoice.discount).dividedBy(100)
				let tax = Utils.BigNumber(cur.totalPrice).minus(vDiscount).times(cur.taxRate).dividedBy(100).toFixed(2)
				total = total.plus(cur.totalPrice).minus(vDiscount).plus(tax)
				return total
			}, Utils.BigNumber(0))
			let keys = key.split("#")
			return {
				isConfigred: false,
				isDebit: true,
				values: [
					keys.length === 1 ? [true] : keys[0],
					keys.length > 1 ? keys[1] : "",
					keys.length > 1 ? keys[3] : keys[0],
					keys.length > 1
						? Utils.decodeAccountingTransDescr(
								keys[2],
								getState().accounting.accountsPayable.selectedInvoice.invoice.supplierName,
								Utils.formatDate(getState().accounting.accountsPayable.selectedInvoice.invoice.receivedDateTime)
						  )
						: "",
					Utils.formatNumWithComma(total.toFixed(2)),
					Utils.BigNumber(0).toFixed(2),
					[keys.length > 1, false],
				],
			}
		})

		// //Configed GL Activities
		if (!_.isEmpty(filtered)) {
			let APs = getState()
				.accounting.accountsPayable.accountsPayableActivities.filter((each) => !each.accountsPayableRCSelectable)
				.concat(getState().accounting.accountsPayable.selectableAccountingCodes)
				.sort((a, b) => Utils.sort(a.chartOfAccounts.fullCode, b.chartOfAccounts.fullCode))
			APs.forEach((each) => {
				let foundItems = items.filter((item) => item.category.activities.some((activity) => activity.chartOfAccounts.id === each.chartOfAccounts.id))
				let total = Utils.calculateAccountsPayableActivityTotal(
					each,
					foundItems,
					getState().accounting.accountsPayable.selectedInvoice.invoice.discount,
					getState().accounting.accountsPayable.selectedInvoice.invoice.discountReceipt,
					getState().accounting.accountsPayable.selectedInvoice.invoice.taxedTotalPrice
				)
				if (total.abs().isGreaterThan(0)) {
					if (each.debit) {
						let idx = body.findIndex((data) => data.isConfigred && !data.isDebit)
						let position = idx > -1 ? idx : body.length
						filtered.splice(position, 0, [each, foundItems])
						body.splice(position, 0, {
							isConfigred: true,
							isDebit: true,
							values: [
								each.chartOfAccounts.fullCode,
								each.chartOfAccounts.displayName,
								"",
								Utils.decodeAccountingTransDescr(
									each.description,
									getState().accounting.accountsPayable.selectedInvoice.invoice.supplierName,
									Utils.formatDate(getState().accounting.accountsPayable.selectedInvoice.invoice.receivedDateTime)
								),
								each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
								each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
								[false, each.accountsPayableRCSelectable],
							],
						})
					} else {
						filtered.push([each, foundItems])
						body.push({
							isConfigred: true,
							isDebit: false,
							values: [
								each.chartOfAccounts.fullCode,
								each.chartOfAccounts.displayName,
								"",
								Utils.decodeAccountingTransDescr(
									each.description,
									getState().accounting.accountsPayable.selectedInvoice.invoice.supplierName,
									Utils.formatDate(getState().accounting.accountsPayable.selectedInvoice.invoice.receivedDateTime)
								),
								each.debit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
								each.credit ? Utils.formatNumWithComma(total.abs().toFixed(2)) : "",
								[false, each.accountsPayableRCSelectable],
							],
						})
					}
				}
			})
		}

		let rowColor = body.map((each) => {
			let color = ""
			if (each.isConfigred) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		let colStyle = body.map(() => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_AP_GL_GROUPS,
			payload: {
				original: items,
				filtered: filtered,
				body: body.map((each) => each.values),
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setAccountsPayableItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((item) => {
			return [
				item.code,
				item.displayName,
				item.unit,
				Utils.getQtyPerOrderStr(item.amount, item.minPerOrder),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerOrder).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minPerOrder).times(item.pricePerOrder).toFixed(2)),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.amount).dividedBy(item.minPerOrder).times(item.pricePerOrder).times(item.discount).dividedBy(100).toFixed(2)
				) +
					" (" +
					item.discount +
					"%)",
				Utils.formatNumWithComma(item.taxRate) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.totalPrice)
						.times(1 + item.taxRate / 100)
						.toFixed(2)
				),
			]
		})

		let rowColor = []

		let colStyle = filtered.map((bs) => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
			]
		})

		dispatch({
			type: SET_AP_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
