import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME, SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class OperatingRoomModalPostAneRecordTimeDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onDateRange = this.onDateRange.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedPostAneRecord.record) {
			this.props.setValue(SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME, this.props.operatingRoom.selectedPostAneRecord.record.startDateTime)
			this.props.setValue(SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME, this.props.operatingRoom.selectedPostAneRecord.record.endDateTime)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME, undefined)
		this.props.setValue(SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME, undefined)
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<AntDatePicker
							displayRange
							size={"large"}
							format={"DD-MM-YYYY  HH:mm"}
							showTime={{ format: "HH:mm" }}
							placeholder={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDateTime }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDateTime }),
							]}
							values={[
								this.props.operatingRoom.modalPostAneRecord.startDateTime ? moment(this.props.operatingRoom.modalPostAneRecord.startDateTime) : undefined,
								this.props.operatingRoom.modalPostAneRecord.endDateTime ? moment(this.props.operatingRoom.modalPostAneRecord.endDateTime) : undefined,
							]}
							onChange={this.onDateRange}
						/>
					</Col>
				</Row>
			</>
		)
	}

	onDateRange(v) {
		this.props.setValue(SET_OR_MODAL_POST_ANE_RECORD_START_DATETIME, v ? v[0].milliseconds(0).valueOf() : undefined)
		this.props.setValue(SET_OR_MODAL_POST_ANE_RECORD_END_DATETIME, v ? v[1].milliseconds(0).valueOf() : undefined)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalPostAneRecordTimeDetails))
