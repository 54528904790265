import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setPharmacyPatients } from "~/redux/action"
import { SET_PHARMACY_PATIENTS_LOADING } from "~/redux/type"
import PharmacyLeftAdmission from "./PharmacyLeftAdmission"
import PharmacyLeftDate from "./PharmacyLeftDate"
import PharmacyLeftPatient from "./PharmacyLeftPatient"
import PharmacyLeftPatientAction from "./PharmacyLeftPatientAction"
import PharmacyLeftSearch from "./PharmacyLeftSearch"

class PharmacyLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyLeft",
		}

		this.loadPatients = this.loadPatients.bind(this)
	}

	componentDidMount() {
		this.loadPatients()
	}

	loadPatients() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.pharmacy.getPatients,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				branchId: this.props.pharmacy.modalLocation.selectedBranch.branch.id,
				startDateTime: this.props.pharmacy.startDateTime,
				endDateTime: this.props.pharmacy.endDateTime,
			},
		}
		let callback = (res) => {
			this.props.setPharmacyPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PHARMACY_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PHARMACY_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PHARMACY_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<PharmacyLeftDate loadPatients={this.loadPatients} />
				<PharmacyLeftSearch />
				<PharmacyLeftPatientAction />
				<PharmacyLeftPatient />
				<PharmacyLeftAdmission />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setPharmacyPatients,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PharmacyLeft)
