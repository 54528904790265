import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import CashierModalNurseAction from "./CashierModalNurseAction"
import CashierModalNurseOrder from "./CashierModalNurseOrder"
import CashierModalNursePendingOrder from "./CashierModalNursePendingOrder"

class CashierModalNurseDetails extends React.Component {
	constructor(props) {
		super(props)

		this.calculateNurseOrderBalance = this.calculateNurseOrderBalance.bind(this)
	}

	calculateNurseOrderBalance(order) {
		return Utils.BigNumber(Utils.calculateNurseOrderBalance(order))
			.minus(
				this.props.cashier.selectedAdmission.admission.billingStatements
					.filter((billingStatement) => billingStatement.nurseOrder && billingStatement.nurseOrder.id === order.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.minus(
				this.props.cashier.modalFinancialPlan.serviceTable.original
					.filter(
						(billingStatement) =>
							!billingStatement.id &&
							billingStatement.isNurseOrder &&
							billingStatement.order &&
							billingStatement.order.id === order.id
					)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.minus(
				this.props.cashier.modalNurse.pendingOrderTable.original
					.filter((pendingOrder) => pendingOrder.id === order.id)
					.reduce((total, cur) => {
						return total.plus(cur.charge)
					}, Utils.BigNumber(0))
			)
			.toFixed(2)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<CashierModalNurseOrder />
				<CashierModalNurseAction {...this.props} calculateNurseOrderBalance={this.calculateNurseOrderBalance} />
				<CashierModalNursePendingOrder />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalNurseDetails)
