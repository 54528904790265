import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"

class ReportPurchaseRequest extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportPurchaseRequest",
		}

		this.onBtnClick = this.onBtnClick.bind(this)
	}

	onBtnClick(event) {
		switch (event.target.value) {
			default:
				break
		}
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Container fluid id={this.state.componentName} className={`border ${ResourceAssistance.CSS.fullFlex}`}>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col sm="auto"></Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportPurchaseRequest)
