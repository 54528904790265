import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportCashierBillingToTodayDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: ResourceAssistance.Report.intl.billingToTodayByAdmission,
				services: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	renderDoc() {
		return (
			<Document style={ResourceAssistance.Report.styles.document}>
				<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
					{/* Title */}
					<ReportTitle
						data={this.state.data}
						displayOrg={false}
						displayTaxNum={false}
						displayFax={false}
						displayTel={false}
						displayLogo={false}
						displayAddress={false}
					/>

					{/* Patient Info */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Admission/{this.state.data.admissionIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.admission}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Date/{this.state.data.dateIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.admissionDate}</Text>
								</View>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.col]}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.pid}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient/{this.state.data.patientIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patient}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB/{this.state.data.dobIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age/{this.state.data.ageIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
							</View>
						</View>
					</View>

					<View style={ResourceAssistance.Report.styles.separator} />

					{/* Service Header */}
					<View wrap={false}>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.Date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.scheduledIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceName]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.serviceIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceDescription]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.descriptionIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pricePerUnit]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.pricePerUnitIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.qtyIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.balanceIntl}</Text>
							</View>
						</View>

						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Scheduled</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceName]}>
								<Text style={[ResourceAssistance.Report.styles.textBold]}>Service</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceDescription]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>List</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pricePerUnit]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Price</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Qty</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Balance</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
					</View>

					{/* Services */}
					{Object.entries(this.state.data.services).map(([key, value]) => {
						return [
							<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontStyle: "bold" }]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text>{key}</Text>
								</View>
							</View>,
						].concat(
							value
								.sort((a, b) => Utils.sort(a.date, b.date))
								.map((service) => {
									return (
										<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
											<View style={[ResourceAssistance.Report.styles.col, styles.date]}>
												<Text>{service.date}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, service.description ? styles.serviceName : styles.noServiceDescription]}>
												<Text>{service.name}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, service.description ? styles.serviceDescription : ""]}>
												<Text>{service.description}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.pricePerUnit, { textAlign: "right" }]}>
												<Text>{service.pricePerUnit}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.qty, { textAlign: "right" }]}>
												<Text>{service.qty}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.balance, { textAlign: "right" }]}>
												<Text>{service.total}</Text>
											</View>
										</View>
									)
								})
						)
					})}
					<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />

					{/* Grand Total */}
					<View style={[ResourceAssistance.Report.styles.row]}>
						<View style={[ResourceAssistance.Report.styles.col, { justifyContent: "flex-start" }]}></View>

						<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
							<View style={ResourceAssistance.Report.styles.row}>
								<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Grand Total/{this.state.data.grandTotalIntl}</Text>
								</View>
								<View style={ResourceAssistance.Report.styles.col}>
									<Text>{this.state.data.grandTotal}</Text>
								</View>
							</View>
						</View>
					</View>
					<View style={ResourceAssistance.Report.styles.separator} />
				</Page>
			</Document>
		)
	}

	render() {
		return <PDFViewer style={ResourceAssistance.Report.styles.iframe}>{this.renderDoc()}</PDFViewer>
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
		textAlign: "right",
		marginRight: 5,
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	line: {
		borderTopColor: "black",
		borderTopWidth: 1,
		flexGrow: 1,
	},
	date: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	serviceName: {
		minWidth: "35%",
		maxWidth: "35%",
	},
	serviceDescription: {
		minWidth: "25%",
		maxWidth: "25%",
	},
	noServiceDescription: {
		minWidth: "60%",
		maxWidth: "60%",
	},
	pricePerUnit: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	qty: {
		minWidth: "7%",
		maxWidth: "7%",
	},
	balance: {
		minWidth: "11%",
		maxWidth: "11%",
	},
})

export default ReportCashierBillingToTodayDoc
