import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_OR_CANCEL_DISPLAY, SET_OR_SCHEDULES, SET_PAGE_LOADING } from "~/redux/type"
import OperatingRoomModalCancelDetails from "./OperatingRoomModalCancelDetails"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalCancel extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_CANCEL_DISPLAY, false)
	}

	isDoneBtnDisabled() {}

	render() {
		return (
			<Modal
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgroundWarning}>
						<ModalTitle>{translate(ResourceAssistance.Message.reason)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<OperatingRoomModalCancelDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.cancelOperatingRoomSchedules,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.id,
				departmentId: this.props.operatingRoom.selectedOperatingRoom.operatingRoom.id,
				userId: this.props.login.user.id,
				startDateTime: this.props.operatingRoom.calendarStartDateTime,
				endDateTime: this.props.operatingRoom.calendarEndDateTime,
				operatingRoomRequests: [
					{
						id: this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request.id,
						remark: Utils.trim(this.props.operatingRoom.modalCancel.reason),
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_OR_SCHEDULES, res.data.schedules)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomCancelDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalCancel))
