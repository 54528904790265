export const SET_INVENTORY_LOCS = "SET_INVENTORY_LOCS"
export const SET_INVENTORY_LOC_LOADING = "SET_INVENTORY_LOC_LOADING"
export const SET_INVENTORY_ORGS = "SET_INVENTORY_ORGS"
export const SET_INVENTORY_BRANCHES = "SET_INVENTORY_BRANCHES"
export const SET_INVENTORY_INVENTORIES = "SET_INVENTORY_INVENTORIES"
export const SET_INVENTORY_SELECTED_ORG = "SET_INVENTORY_SELECTED_ORG"
export const SET_INVENTORY_SELECTED_BRANCH = "SET_INVENTORY_SELECTED_BRANCH"
export const SET_INVENTORY_SELECTED_INVENTORY = "SET_INVENTORY_SELECTED_INVENTORY"
export const SET_INVENTORY_SUPPLIERS = "SET_INVENTORY_SUPPLIERS"
export const SET_INVENTORY_SUPPLIERS_LOADING = "SET_INVENTORY_SUPPLIERS_LOADING"
export const SET_INVENTORY_SUPPLIER_SEARCH_TEXT = "SET_INVENTORY_SUPPLIER_SEARCH_TEXT"
export const SET_INVENTORY_SELECTED_SUPPLIER = "SET_INVENTORY_SELECTED_SUPPLIER"
export const SET_INVENTORY_PENDING_SUPPLIERS = "SET_INVENTORY_PENDING_SUPPLIERS"
export const SET_INVENTORY_SELECTED_PENDING_SUPPLIER = "SET_INVENTORY_SELECTED_PENDING_SUPPLIER"
export const SET_INVENTORY_SEARCH_CODE_TEXT = "SET_INVENTORY_SEARCH_CODE_TEXT"
export const SET_INVENTORY_SEARCH_NAME_TEXT = "SET_INVENTORY_SEARCH_NAME_TEXT"
export const SET_INVENTORY_INVENTORY_DETAILS = "SET_INVENTORY_INVENTORY_DETAILS"
export const SET_INVENTORY_INVENTORY_DETAILS_LOADING = "SET_INVENTORY_INVENTORY_DETAILS_LOADING"
export const SET_INVENTORY_TYPES = "SET_INVENTORY_TYPES"
export const SET_INVENTORY_TYPE_LOADING = "SET_INVENTORY_TYPE_LOADING"
export const SET_INVENTORY_SELECTED_TYPE = "SET_INVENTORY_SELECTED_TYPE"
export const SET_INVENTORY_SELECTED_INVENTORY_DETAIL = "SET_INVENTORY_SELECTED_INVENTORY_DETAIL"
export const SET_INVENTORY_DISPLAY_EXPIRE_CHECKED = "SET_INVENTORY_DISPLAY_EXPIRE_CHECKED"
export const SET_INVENTORY_DISPLAY_MIN_STOCK_CHECKED = "SET_INVENTORY_DISPLAY_MIN_STOCK_CHECKED"
export const SET_INVENTORY_LOTS = "SET_INVENTORY_LOTS"
export const SET_INVENTORY_PENDING_ORDER = "SET_INVENTORY_PENDING_ORDER"
export const SET_INVENTORY_LOT_EXPIRED_DATE = "SET_INVENTORY_LOT_EXPIRED_DATE"
export const SET_INVENTORY_NEW_INVENTORY_SELECTED_TYPE = "SET_INVENTORY_NEW_INVENTORY_SELECTED_TYPE"
