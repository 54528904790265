import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import { setLabModalApproveResults } from "~/redux/action"

class CLModalApproveDetails extends React.Component {
	componentDidMount() {
		this.props.setLabModalApproveResults([this.props.lab.selectedLab.lab.detail])
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col
						style={{
							minHeight: "185px",
						}}
					>
						<ScrollableTable enableHighlight striped hover data={this.props.lab.modalApprove.resultTable} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLabModalApproveResults,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalApproveDetails)
