import { Select } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPE,
	SET_ADM_SERVICE_NEW_SERVICE_GOVERNMENT_CODE,
	SET_ADM_SERVICE_NEW_SERVICE_LAB_TEST,
	SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE,
	SET_ADM_SERVICE_NEW_SERVICE_TMLT_TMT_CODE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ASelect from "~/view/component/ant_select/ASelect"
import GInput from "~/view/component/input/GInput"

const Property = {
	ROLE: "ROLE",
	ADP_TYPE: "",
	GOVERNMENT_CODE: "GOVERNMENT_CODE",
	TMIL_TMT_CODE: "TMIL_TMT_CODE",
	LAB_TEST: "LAB_TEST",
}

class ConfigServiceModalNSRole extends React.Component {
	constructor(props) {
		super(props)

		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onSelectChange = this.onSelectChange.bind(this)
	}

	componentDidMount() {
		let service = this.props.service.selectedService.service
		if (service && service.role) {
			let roleIdx = this.props.service.roles.findIndex((role) => role.id === service.role.id)
			this.props.setSelected(SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE, roleIdx, this.props.service.roles[roleIdx])
		}
		if (service) {
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPE, service.adpType)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_LAB_TEST, service.labTest)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_GOVERNMENT_CODE, service.governmentCode)
			this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_TMLT_TMT_CODE, service.tmltTmtCode)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE, -1, null)
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPE, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_GOVERNMENT_CODE, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_TMLT_TMT_CODE, "")
		this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_LAB_TEST, "")
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.ROLE:
				this.props.setSelected(SET_ADM_SERVICE_NEW_SERVICE_SELECTED_ROLE, event.target.value, this.props.service.roles[event.target.value])
				break
			case Property.GOVERNMENT_CODE:
				this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_GOVERNMENT_CODE, event.target.value)
				break
			case Property.TMIL_TMT_CODE:
				this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_TMLT_TMT_CODE, event.target.value)
				break
			default:
				break
		}
	}

	onSelectChange(name, value) {
		switch (name) {
			case Property.ADP_TYPE:
				this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_ADP_TYPE, value)
				break
			case Property.LAB_TEST:
				this.props.setValue(SET_ADM_SERVICE_NEW_SERVICE_LAB_TEST, value)
				break
			default:
				break
		}
	}

	render() {
		return (
			<Container fluid="small">
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.payee)}</legend>
					<Row>
						<Col>
							<Select
								showSearch
								style={{ textAlign: "center", margin: 0 }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Message.role)}
								value={this.props.service.modalNewService.selectedRole.role ? this.props.service.modalNewService.selectedRole.index : undefined}
								onChange={this.onRole}
							>
								{Utils.renderSelects(this.props.service.roles, false)}
							</Select>
						</Col>
					</Row>
				</fieldset>

				<fieldset>
					<legend>{translate(ResourceAssistance.Message.eclaim)}</legend>
					<Row>
						<Col>
							<ASelect
								required
								showSearch
								allowClear
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "normal" }}
								placeholder={translate(ResourceAssistance.Message.adpType)}
								value={this.props.service.modalNewService.adpType}
								onSelectChange={(value) => this.onSelectChange(Property.ADP_TYPE, value)}
								options={this.props.service.modalNewService.adpTypes}
								displayProperty={"name"}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<ASelect
								showSearch
								allowClear
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "normal" }}
								placeholder={translate(ResourceAssistance.Message.labTest)}
								value={this.props.service.modalNewService.labTest}
								onSelectChange={(value) => this.onSelectChange(Property.LAB_TEST, value)}
								options={this.props.service.modalNewService.labTests}
								displayProperty={"name"}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<GInput
								required
								name={Property.GOVERNMENT_CODE}
								type={ResourceAssistance.FormControl.type.text}
								placeholder={translate(ResourceAssistance.Message.governmentCode)}
								value={this.props.service.modalNewService.governmentCode}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<GInput
								required
								type={ResourceAssistance.FormControl.type.text}
								name={Property.TMIL_TMT_CODE}
								placeholder={translate(ResourceAssistance.Message.tmltTmtCode)}
								value={this.props.service.modalNewService.tmltTmtCode}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
				</fieldset>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigServiceModalNSRole))
