import { Select } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA, SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OperatingRoomModalEditRequestAnesthesia extends React.Component {
	constructor(props) {
		super(props)

		this.onAnesthesiologist = this.onAnesthesiologist.bind(this)
		this.onAnesthesiaType = this.onAnesthesiaType.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedEvent.event) {
			let request = this.props.operatingRoom.selectedEvent.event.extendedProps.schedule.request
			let anesthesiologistIdx = this.props.operatingRoom.doctors.findIndex((doctor) => doctor.displayName === request.anesthesiologist)
			if (anesthesiologistIdx > -1) {
				this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST, anesthesiologistIdx, this.props.operatingRoom.doctors[anesthesiologistIdx])
			}
			let anesthesiaIdx = this.props.operatingRoom.anesthesias.findIndex((anethesia) => anethesia.displayName === request.anesthesiaType)
			if (anesthesiaIdx > -1) {
				this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA, anesthesiaIdx, this.props.operatingRoom.anesthesias[anesthesiaIdx])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST, -1, null)
		this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA, -1, null)
	}

	onAnesthesiologist(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST, -1, null)
			return
		}
		this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIOLOGIST, v, this.props.operatingRoom.doctors[v])
	}

	onAnesthesiaType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA, -1, null)
			return
		}
		this.props.setSelected(SET_OR_MODAL_EDIT_REQUEST_SELECTED_ANESTHESIA, v, this.props.operatingRoom.anesthesias[v])
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.anesthesia)}</legend>
				<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
					<Row>
						<Col>
							<Select
								showSearch
								style={{ textAlign: "center", margin: 0 }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Message.anesthesiologist)}
								value={
									this.props.operatingRoom.modalEditRequest.selectedAnesthesiologist.anesthesiologist
										? this.props.operatingRoom.modalEditRequest.selectedAnesthesiologist.index
										: undefined
								}
								onChange={this.onAnesthesiologist}
							>
								{Utils.renderSelects(this.props.operatingRoom.doctors, false)}
							</Select>
						</Col>
					</Row>
					<Row>
						<Col>
							<Select
								style={{ textAlign: "center", margin: 0 }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Message.type)}
								loading={this.props.operatingRoom.isAnesthesiasLoading}
								value={
									this.props.operatingRoom.modalEditRequest.selectedAnesthesiaType.type
										? this.props.operatingRoom.modalEditRequest.selectedAnesthesiaType.index
										: undefined
								}
								onChange={this.onAnesthesiaType}
							>
								{Utils.renderSelects(this.props.operatingRoom.anesthesias, false)}
							</Select>
						</Col>
					</Row>
				</Container>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditRequestAnesthesia)
