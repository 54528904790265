import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setRegModalExpenseEstPendings, setSelected } from "~/redux/action"
import { SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class RegModalExpenseEstPending extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.reg.modalExpenseEst.pendingTable.filtered, this.props.reg.modalExpenseEst.pendingTable.filtered)) {
			this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING, -1, null)
		}

		if (
			!_.isEqual(prevProps.reg.modalExpenseEst.filterDoctor, this.props.reg.modalExpenseEst.filterDoctor) ||
			!_.isEqual(prevProps.reg.modalExpenseEst.filterNurse, this.props.reg.modalExpenseEst.filterNurse) ||
			!_.isEqual(prevProps.reg.modalExpenseEst.filterCoordinator, this.props.reg.modalExpenseEst.filterCoordinator) ||
			!_.isEqual(prevProps.reg.modalExpenseEst.filterService, this.props.reg.modalExpenseEst.filterService) ||
			!_.isEqual(prevProps.reg.modalExpenseEst.filterMedicine, this.props.reg.modalExpenseEst.filterMedicine) ||
			!_.isEqual(prevProps.reg.modalExpenseEst.filterMedicalSupply, this.props.reg.modalExpenseEst.filterMedicalSupply)
		) {
			this.props.setRegModalExpenseEstPendings(this.props.reg.modalExpenseEst.pendingTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setRegModalExpenseEstPendings([])
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING, -1, null)
	}

	onSelectItem(item, index) {
		this.props.setSelected(SET_REGISTRATION_MODAL_EXPENSE_EST_SELECTED_PENDING, index, this.props.reg.modalExpenseEst.pendingTable.filtered[index])
	}

	render() {
		return (
			<Row>
				<Col>
					<ScrollableTable
						style={{ minHeight: "300px", maxHeight: "300px" }}
						striped
						hover
						enableHighlight
						onClick={this.onSelectItem}
						data={this.props.reg.modalExpenseEst.pendingTable}
						isClearHighlight={this.props.reg.modalExpenseEst.selectedPending.index === -1}
						highlightedRow={this.props.reg.modalExpenseEst.selectedPending.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	reg: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setRegModalExpenseEstPendings,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegModalExpenseEstPending))
