import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setObjArray } from "~/redux/action"
import { SET_OPD_MODAL_DOCTOR_ORDER_PHARMACY_INVENTORIES, SET_PAGE_LOADING } from "~/redux/type"
import OutpatientModalDoctorAction from "./OutpatientModalDoctorAction"
import OutpatientModalDoctorItems from "./OutpatientModalDoctorItems"
import OutpatientModalDoctorOrderFilter from "./OutpatientModalDoctorOrderFilter"
import OutpatientModalDoctorPending from "./OutpatientModalDoctorPending"

class OutpatientModalDoctorDetails extends React.Component {
	componentDidMount() {
		this.getPharmacyInventories()
	}

	getPharmacyInventories() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.getFrontInventoriesByBranch,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				branchId: this.props.opd.modalLocation.selectedBranch.branch.id,
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_OPD_MODAL_DOCTOR_ORDER_PHARMACY_INVENTORIES, res.data.pharmacyInventories)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<OutpatientModalDoctorAction />
				<OutpatientModalDoctorOrderFilter />
				<OutpatientModalDoctorItems />
				<OutpatientModalDoctorPending />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctorDetails)
