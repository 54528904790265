import { combineReducers } from "redux"
import accountsPayableReducer from "./accountsPayableReducer"
import accountsReceivableReducer from "./accountsReceivableReducer"
import contractBillingReducer from "./contractBillingReducer"
import debtReducer from "./debtReducer"
import paymentSlipReducer from "./paymentSlipReducer"
import financialOperationsReducer from "./financialOperationsReducer"
import purchaseInvoiceReducer from "./purchaseInvoiceReducer"
import paymentVoucherReducer from "./paymentVoucherReducer"
import accountsReceiveReducer from "./accountsReceiveReducer"
import postingGLReducer from "./postingGLReducer"

export default combineReducers({
	accountsPayable: accountsPayableReducer,
	accountsReceivable: accountsReceivableReducer,
	accountsReceive: accountsReceiveReducer,
	contract: contractBillingReducer,
	debt: debtReducer,
	financialOperations: financialOperationsReducer,
	paymentSlip: paymentSlipReducer,
	paymentVoucher: paymentVoucherReducer,
	postingGL: postingGLReducer,
	purchaseInvoice: purchaseInvoiceReducer,
})
