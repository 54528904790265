import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Container } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import GCSDate from "./GCSDate"
import GCSEyeOpening from "./GCSEyeOpening"
import GCSMotor from "./GCSMotor"
import GCSVerbal from "./GCSVerbal"

class GCS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			datetime: "",
			eyeOpening: 1,
			verbal: 1,
			motor: 1,
		}
		this.onDateChange = this.onDateChange.bind(this)
		this.onEyeOpening = this.onEyeOpening.bind(this)
		this.onVerbal = this.onVerbal.bind(this)
		this.onMotor = this.onMotor.bind(this)
	}

	componentDidMount() {
		if (this.props.gcs) {
			this.setState({
				datetime: this.props.gcs.datetime,
				eyeOpening: this.props.gcs.eyeOpening,
				verbal: this.props.gcs.verbal,
				motor: this.props.gcs.motor,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	onDateChange(dateObj) {
		this.setState({
			datetime: dateObj ? dateObj.valueOf() : "",
		})
	}

	onEyeOpening(e) {
		this.setState({
			eyeOpening: e.target.value,
		})
	}

	onVerbal(e) {
		this.setState({
			verbal: e.target.value,
		})
	}

	onMotor(e) {
		this.setState({
			motor: e.target.value,
		})
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<GCSDate {...this.props} {...this.state} onDateChange={this.onDateChange} />
				<GCSEyeOpening {...this.props} {...this.state} onEyeOpening={this.onEyeOpening} />
				<GCSVerbal {...this.props} {...this.state} onVerbal={this.onVerbal} />
				<GCSMotor {...this.props} {...this.state} onMotor={this.onMotor} />
			</Container>
		)
	}
}

GCS.propTypes = {
	gcs: propTypes.object,
}

GCS.defaultProps = {
	gcs: null,
}

export default GCS
