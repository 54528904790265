import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setAdmSModalNSActive, setAdmSModalNSAddress, setAdmSModalNSCode, setAdmSModalNSName, setAdmSModalNSPhone, setValue } from "~/redux/action"
import {
	SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT,
	SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE,
	SET_ADM_SUPPLIER_MODAL_NEW_EMAIL,
	SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class AdmSModalNSDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCode = this.onCode.bind(this)
		this.onName = this.onName.bind(this)
		this.onPhone = this.onPhone.bind(this)
		this.onAddress = this.onAddress.bind(this)
		this.onTaxId = this.onTaxId.bind(this)
		this.onAccount = this.onAccount.bind(this)
		this.onBankCode = this.onBankCode.bind(this)
		this.onActive = this.onActive.bind(this)
		this.onEmail = this.onEmail.bind(this)
	}

	componentWillUnmount() {
		this.props.setAdmSModalNSCode("")
		this.props.setAdmSModalNSName("")
		this.props.setAdmSModalNSPhone("")
		this.props.setAdmSModalNSAddress("")
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID, "")
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT, "")
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE, "")
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_EMAIL, "")
		this.props.setAdmSModalNSActive(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.supplier.newSupplierModal.code !== this.props.supplier.newSupplierModal.code ||
			prevProps.supplier.newSupplierModal.name !== this.props.supplier.newSupplierModal.name ||
			prevProps.supplier.newSupplierModal.address !== this.props.supplier.newSupplierModal.address
		) {
			this.validateInput()
		}
	}

	validateInput() {
		let codeHtml = document.getElementById(ResourceAssistance.ID.ADM.supplier.modalNS.code)
		let nameHtml = document.getElementById(ResourceAssistance.ID.ADM.supplier.modalNS.name)
		let addressHtml = document.getElementById(ResourceAssistance.ID.ADM.supplier.modalNS.address)
		let codeInput = this.props.supplier.newSupplierModal.code.trim().toLowerCase()
		let nameInput = this.props.supplier.newSupplierModal.name.trim().toLowerCase()
		let addressInput = this.props.supplier.newSupplierModal.address.trim()

		if (
			!codeInput ||
			this.props.supplier.supplierTable.original
				.filter((each) => !this.props.supplier.selectedSupplier.supplier || this.props.supplier.selectedSupplier.supplier.id !== each.id)
				.some((each) => each.code.trim().toLowerCase() === codeInput)
		) {
			codeHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateCodeWarning }))
			nameHtml.setCustomValidity("")
			addressHtml.setCustomValidity("")
		} else if (
			!nameInput ||
			this.props.supplier.supplierTable.original
				.filter((each) => !this.props.supplier.selectedSupplier.supplier || this.props.supplier.selectedSupplier.supplier.id !== each.id)
				.some((each) => each.displayName.trim().toLowerCase() === nameInput)
		) {
			codeHtml.setCustomValidity("")
			nameHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning }))
			addressHtml.setCustomValidity("")
		} else if (!addressInput) {
			codeHtml.setCustomValidity("")
			nameHtml.setCustomValidity("")
			addressHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.emptyWarning }))
		} else {
			codeHtml.setCustomValidity("")
			nameHtml.setCustomValidity("")
			addressHtml.setCustomValidity("")
		}
	}

	onCode(event) {
		this.props.setAdmSModalNSCode(event.target.value.toUpperCase())
	}

	onName(event) {
		this.props.setAdmSModalNSName(event.target.value)
	}

	onPhone(event) {
		this.props.setAdmSModalNSPhone(event.target.value)
	}

	onEmail(e) {
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_EMAIL, e.target.value)
	}

	onAddress(event) {
		this.props.setAdmSModalNSAddress(event.target.value)
	}

	onTaxId(e) {
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_TAX_ID, e.target.value)
	}

	onAccount(e) {
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_BANK_ACCOUNT, e.target.value)
	}

	onBankCode(e) {
		this.props.setValue(SET_ADM_SUPPLIER_MODAL_NEW_BANK_CODE, e.target.value.toUpperCase())
	}

	onActive(event) {
		this.props.setAdmSModalNSActive(event.target.checked)
	}

	render() {
		return (
			<Container>
				<Row>
					<Col>
						<GInput
							required
							id={ResourceAssistance.ID.ADM.supplier.modalNS.code}
							placeholder={translate(ResourceAssistance.Message.code)}
							value={this.props.supplier.newSupplierModal.code}
							onChange={this.onCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							id={ResourceAssistance.ID.ADM.supplier.modalNS.name}
							placeholder={translate(ResourceAssistance.Message.name)}
							value={this.props.supplier.newSupplierModal.name}
							onChange={this.onName}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.phone}
							placeholder={translate(ResourceAssistance.Message.phone)}
							value={this.props.supplier.newSupplierModal.phone}
							onChange={this.onPhone}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							type={ResourceAssistance.FormControl.type.email}
							placeholder={translate(ResourceAssistance.Message.email)}
							value={this.props.supplier.newSupplierModal.email}
							onChange={this.onEmail}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							id={ResourceAssistance.ID.ADM.supplier.modalNS.address}
							placeholder={translate(ResourceAssistance.Message.address)}
							value={this.props.supplier.newSupplierModal.address}
							minRows={10}
							onChange={this.onAddress}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={translate(ResourceAssistance.Message.taxId)}
							value={this.props.supplier.newSupplierModal.taxId}
							onChange={this.onTaxId}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={translate(ResourceAssistance.Message.bankAccNum)}
							value={this.props.supplier.newSupplierModal.bankAccount}
							onChange={this.onAccount}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							placeholder={translate(ResourceAssistance.Message.bankCode)}
							value={this.props.supplier.newSupplierModal.bankCode}
							onChange={this.onBankCode}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.supplier.newSupplierModal.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	supplier: state.admin.itemConfig.supplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmSModalNSCode,
			setAdmSModalNSName,
			setAdmSModalNSPhone,
			setAdmSModalNSAddress,
			setAdmSModalNSActive,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmSModalNSDetails))
