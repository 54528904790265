import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { IoMdDoneAll } from "react-icons/io"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setCashierModalLabPendingLabs, setValue, onModalDisplayAction } from "~/redux/action"
import {
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE,
	SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_MAX,
	SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY,
	SET_CASHIER_MODAL_ADJUSTMENT_ID,
	SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class CashierModalLabAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.modalAdjustment.isContinue, this.props.cashier.modalAdjustment.isContinue)) {
			if (
				this.props.cashier.modalAdjustment.isContinue &&
				_.isEqual(this.props.cashier.modalAdjustment.id, ResourceAssistance.ID.HOSPITEL.cashier.modalLab.id)
			) {
				let pendingLabs = this.props.cashier.modalLab.pendingTable.filtered
				let pendingLab = pendingLabs.find((each) => each.id === this.props.cashier.modalLab.selectedUncharged.lab.id)

				let selectedLab = Object.assign({}, this.props.cashier.modalLab.selectedUncharged.lab, {
					charge: Utils.BigNumber(this.props.cashier.modalAdjustment.balance)
						.plus(pendingLab ? pendingLab.charge : 0)
						.toFixed(2),
					adjustment: Utils.BigNumber(this.props.cashier.modalAdjustment.amount)
						.plus(pendingLab ? pendingLab.adjustment : 0)
						.toFixed(2),
					adjustmentDescription: pendingLab
						? Utils.trim(this.props.cashier.modalAdjustment.description).concat(" ", Utils.trim(pendingLab.adjustmentDescription))
						: Utils.trim(this.props.cashier.modalAdjustment.description),
				})

				pendingLabs = pendingLabs.filter((each) => each.id !== selectedLab.id)
				pendingLabs.push(selectedLab)
				this.props.setCashierModalLabPendingLabs(pendingLabs)
			}
		}
	}

	onDownBtn() {
		if (this.props.cashier.modalLab.selectedUncharged.index === -1) {
			return
		}

		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_ID, ResourceAssistance.ID.HOSPITEL.cashier.modalLab.id)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_QTY_PER_DAY_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_DISCOUNT_DISPLAY, false)
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE, this.props.calculateLabBalance(this.props.cashier.modalLab.selectedUncharged.lab))
		this.props.setValue(SET_CASHIER_MODAL_ADJUSTMENT_BALANCE_MAX, this.props.calculateLabBalance(this.props.cashier.modalLab.selectedUncharged.lab))
		this.props.onModalDisplayAction(SET_CASHIER_MODAL_ADJUSTMENT_DISPLAY, true)
	}

	onUpBtn() {
		if (this.props.cashier.modalLab.selectedPending.index === -1) {
			return
		}
		let pendingLabs = this.props.cashier.modalLab.pendingTable.filtered.filter((each) => each.id !== this.props.cashier.modalLab.selectedPending.lab.id)
		this.props.setCashierModalLabPendingLabs(pendingLabs)
	}

	onSelectAll() {
		let pendingLabs = this.props.cashier.modalLab.pendingTable.filtered
		this.props.cashier.modalLab.unchargedTable.filtered.forEach((lab) => {
			let pendingLab = pendingLabs.find((each) => each.id === lab.id)

			let selectedLab = Object.assign({}, lab, {
				charge: Utils.BigNumber(this.props.calculateLabBalance(lab))
					.plus(pendingLab ? pendingLab.charge : 0)
					.toFixed(2),
				adjustment: pendingLab ? pendingLab.adjustment : 0,
				adjustmentDescription: pendingLab ? Utils.trim(pendingLab.adjustmentDescription) : "",
			})

			pendingLabs = pendingLabs.filter((each) => each.id !== selectedLab.id)
			pendingLabs.push(selectedLab)
		})
		this.props.setCashierModalLabPendingLabs(pendingLabs)
	}

	render() {
		return (
			<Row>
				<Col className={this.props.cashier.modalLab.selectedUncharged.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
					<FcDownload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onDownBtn} />
				</Col>
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.props.cashier.modalLab.unchargedTable.filtered.length === 0}
						onClick={this.onSelectAll}
					>
						<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.selectAll)}
					</Button>
				</Col>
				<Col className={this.props.cashier.modalLab.selectedPending.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
					<FcUpload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onUpBtn} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setCashierModalLabPendingLabs,
			onModalDisplayAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalLabAction)
