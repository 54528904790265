export const SET_FO_EDIT_REQUEST_DISPLAY = "SET_FO_EDIT_REQUEST_DISPLAY"
export const SET_FO_END_DATE_TIME = "SET_FO_END_DATE_TIME"
export const SET_FO_FILTERS = "SET_FO_FILTERS"
export const SET_FO_FILTER_FINANCIAL_PLAN_ID = "SET_FO_FILTER_FINANCIAL_PLAN_ID"
export const SET_FO_LOCATION = "SET_FO_LOCATION"
export const SET_FO_LOCATION_DISPLAY = "SET_FO_LOCATION_DISPLAY"
export const SET_FO_MODAL_EDIT_REQUEST_FEE = "SET_FO_MODAL_EDIT_REQUEST_FEE"
export const SET_FO_MODAL_LOCATION_ORGS = "SET_FO_MODAL_LOCATION_ORGS"
export const SET_FO_MODAL_LOCATION_ORGS_LOADING = "SET_FO_MODAL_LOCATION_ORGS_LOADING"
export const SET_FO_MODAL_LOCATION_SELECTED_ORG = "SET_FO_MODAL_LOCATION_SELECTED_ORG"
export const SET_FO_MODAL_NEW_BANKS = "SET_FO_MODAL_NEW_BANKS"
export const SET_FO_MODAL_NEW_BANK_LOADING = "SET_FO_MODAL_NEW_BANK_LOADING"
export const SET_FO_MODAL_NEW_FILTER_PS_ID = "SET_FO_MODAL_NEW_FILTER_PS_ID"
export const SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE = "SET_FO_MODAL_NEW_FILTER_SUPPLIER_CODE"
export const SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME = "SET_FO_MODAL_NEW_FILTER_SUPPLIER_NAME"
export const SET_FO_MODAL_NEW_SERVICE_TYPES = "SET_FO_MODAL_NEW_SERVICE_TYPES"
export const SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING = "SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING"
export const SET_FO_MODAL_NEW_PAYMENT_PLANS = "SET_FO_MODAL_NEW_PAYMENT_PLANS"
export const SET_FO_MODAL_NEW_PAYMENT_TYPES = "SET_FO_MODAL_NEW_PAYMENT_TYPES"
export const SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING = "SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING"
export const SET_FO_MODAL_NEW_PENDING_PAYMENT_PLANS = "SET_FO_MODAL_NEW_PENDING_PAYMENT_PLANS"
export const SET_FO_MODAL_NEW_SEARCH_DUE_DATE = "SET_FO_MODAL_NEW_SEARCH_DUE_DATE"
export const SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK = "SET_FO_MODAL_NEW_SEARCH_SELECTED_BANK"
export const SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE = "SET_FO_MODAL_NEW_SEARCH_SELECTED_PAYMENT_TYPE"
export const SET_FO_MODAL_NEW_SELECTED_BANK = "SET_FO_MODAL_NEW_SELECTED_BANK"
export const SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE = "SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE"
export const SET_FO_MODAL_NEW_SELECTED_PAYMENT_PLAN = "SET_FO_MODAL_NEW_SELECTED_PAYMENT_PLAN"
export const SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE = "SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE"
export const SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN = "SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN"
export const SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME = "SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME"
export const SET_FO_MODAL_SEARCH_FINANCIAL_REQUEST_ID = "SET_FO_MODAL_SEARCH_FINANCIAL_REQUEST_ID"
export const SET_FO_MODAL_SEARCH_MORE_PAYMENT_SLIP_ID = "SET_FO_MODAL_SEARCH_MORE_PAYMENT_SLIP_ID"
export const SET_FO_NEW_DISPLAY = "SET_FO_NEW_DISPLAY"
export const SET_FO_PAYMENT_PLANS = "SET_FO_PAYMENT_PLANS"
export const SET_FO_REQUESTS = "SET_FO_REQUESTS"
export const SET_FO_SEARCH_DISPLAY = "SET_FO_SEARCH_DISPLAY"
export const SET_FO_SEARCH_MORE_DISPLAY = "SET_FO_SEARCH_MORE_DISPLAY"
export const SET_FO_SELECTED_FINANCIAL_REQUEST = "SET_FO_SELECTED_FINANCIAL_REQUEST"
export const SET_FO_SELECTED_PAYMENT_PLAN = "SET_FO_SELECTED_PAYMENT_PLAN"
export const SET_FO_START_DATE_TIME = "SET_FO_START_DATE_TIME"
