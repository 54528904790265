import { Tabs } from "antd"
import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class HospitelModalPatientClinicalRecordHealthcare extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Hospitel.healthCarePlan)}</legend>
						<Tabs
							defaultActiveKey={0}
							items={Utils.renderHealthCareTabs(
								this.props.patient.clinicalRecord.healthcareCode,
								this.props.patient.clinicalRecord.healthcareName,
								this.props.patient.clinicalRecord.healthcareDescription,
								this.props.patient.clinicalRecord.healthcareOther
							)}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

HospitelModalPatientClinicalRecordHealthcare.propTypes = {
	patient: propTypes.object,
}

HospitelModalPatientClinicalRecordHealthcare.defaultProps = {
	patient: {
		clinicalRecord: {},
	},
}

export default HospitelModalPatientClinicalRecordHealthcare
