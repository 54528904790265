const ID = {
	PO: {
		type: "po_type",
		modalEdit: {
			paymentType: "po_modal_edit_payment_type",
		},
	},

	PR: {
		type: "pr_type",
		modalNewPr: {
			tableItem: "pr_modal_npr_table_item",
			tableSelectedItem: "pr_modal_npr_table_selected_item",
		},
		modalMinStock: {
			amount: "pr_modal_min_stock_amount",
			tableItem: "pr_modal_min_stock_table_item",
			tableSelectedItem: "pr_modal_min_stock_table_selected_item",
		},
	},
	RC: {
		type: "rc_type",
		unit: "rc_unit",
		modalCancelSupplier: "modal_cancel_supplier",
		modalReceive: {
			tableReceive: "rc_modal_receive_table_receive",
			tableReceived: "rc_modal_receive_table_received",
		},
		modalCancel: {
			tableReceive: "rc_modal_cancel_table_receive",
			tableCancelled: "rc_modal_cancel_table_cancelled",
		},
	},
	INV: {
		type: "inv_type",
		newInventoryType: "inv_new_inv_type",
	},
	TF: {
		request: "hospital_stock_management_transfer_request",
		delete_request_item: "stock_management_transfer_delete_request_item",
		transfer: "hospital_stock_management_transfer_transfer",
		modalRequest: {
			type: "modal_request_type",
			toOrgs: "modal_request_to_orgs",
			toBranches: "modal_request_to_branches",
			toLocations: "modal_request_to_locations",
			tableItem: "tf_modal_request_table_item",
			tableSelectedItem: "tf_modal_request_table_selected_item",
		},
		modalTransfer: {
			type: "modal_transfer_type",
			transferAmount: "modal_transfer_transfer_amount",
			org: "modal_transfer_org",
			branch: "modal_transfer_branch",
			lot: "modal_transfer_lot",
			tableItem: "tf_modal_transfer_table_item",
			tableTransferredItem: "tf_modal_transfer_table_transferred_item",
		},
	},
	ADM: {
		category: {
			bank: {
				deleteBranch: "adm_category_bank_delete_branch",
				deleteAccountsPayableActivity: "adm_category_bank_delete_accounts_payable_activity",
				deleteAccountsReceivableActivity: "adm_category_bank_delete_accounts_receivable_activity",
			},
			healthcarePlan: {
				deleteCompany: "adm_category_healthcarePlan_delete_company",
				deleteAccountsReceivable: "adm_category_healthcarePlan_delete_accounts_receivable",
			},
			inventory: {
				deleteReceivableActivity: "adm_category_inventory_delete_receivable_activity",
				deletePayableActivity: "adm_category_inventory_delete_payable_activity",
			},
			service: {
				deleteReceivableActivity: "adm_category_service_delete_receivable_activity",
				deletePayableActivity: "adm_category_service_delete_payable_activity",
			},
			payment: {
				deleteReceivableActivity: "adm_category_payment_delete_receivable_activity",
				deletePayableActivity: "adm_category_payment_delete_payable_activity",
			},
			withholdingTax: {
				deletePayableActivity: "adm_category_withholding_tax_delete_payable_activity",
			},
			insuranceCompany: {
				deleteAccountsPayableActivity: "adm_category_insurance_company_delete_accounts_payable_activity",
			},
		},
		item: {
			type: "adm_item_type",
			deleteUnitConversion: "adm_item_delete_unit_conversion",
			modalNI: {
				code: "adm_item_modal_ni_code",
				categoryNum: "adm_item_modal_ni_category_num",
				fsCode: "adm_item_modal_ni_fs_code",
				dose: "adm_item_modal_ni_dose",
				brand: "adm_item_modal_ni_brand",
				medicationUsage: "adm_item_modal_ni_medication_usage",
				pricePerUnit: "adm_item_modal_ni_pricePerUnit",
				sellPricePerUnit: "adm_item_modal_ni_sell_price_per_unit",
				discount: "adm_item_modal_ni_discount",
				minQtyPerOrder: "adm_item_modal_ni_minQtyPerOrder",
				bonus: "adm_item_modal_ni_bonus",
				type: "adm_item_modal_ni_type",
				form: "adm_item_modal_ni_form",
				unit: "adm_item_modal_ni_unit",
				supplier: "adm_item_modal_ni_supplier",
				tax: "adm_item_modal_ni_tax",
				governmentCode: "adm_item_modal_ni_governmentCode",
				tmltTmtCode: "adm_item_modal_ni_tmltTmtCode",
			},
		},
		itemSource: {
			type: "adm_item_source_type",
			modalNIS: {
				type: "adm_item_source_modal_nis_type",
				name: "adm_item_source_modal_nis_name",
				keyword: "adm_item_source_modal_nis_keyword",
			},
		},
		supplier: {
			modalNS: {
				code: "adm_supplier_modal_ns_code",
				name: "adm_supplier_modal_ns_name",
				address: "adm_supplier_modal_ns_address",
			},
		},
		unit: {
			modalNU: {
				name: "adm_unit_modal_nu_name",
				description: "adm_unit_modal_nu_description",
			},
		},
		form: {
			modalNF: {
				name: "adm_form_modal_nf_name",
				description: "adm_form_modal_nf_description",
			},
		},
		tax: {
			modalNT: {
				name: "adm_tax_modal_nt_name",
				description: "adm_tax_modal_nt_description",
				taxRate: "adm_tax_modal_nt_taxRate",
			},
		},
		user: {
			modalNU: {
				lastName: "adm_user_modal_nu_lastName",
				firstName: "adm_user_modal_nu_firstName",
				loginName: "adm_user_modal_nu_loginName",
				nickname: "adm_user_modal_nu_nickname",
				password: "adm_user_modal_nu_password",
				confirmPassword: "adm_user_modal_nu_confirmPassword",
				role: "adm_user_modal_nu_role",
			},
			modalCPW: {
				password: "adm_user_modal_cpw_password",
				confirmPassword: "adm_user_modal_cpw_confirmPassword",
			},
		},
		role: {
			modalNR: {
				name: "adm_role_modal_nr_name",
				description: "adm_role_modal_nr_description",
			},
		},
		service: {
			type: "adm_service_type",
		},
		icd: {
			type: "adm_icd_type",
			modalNewICD: {
				type: "adm_icd_modal_new_ICD_type",
			},
		},
		laboratory: {
			pcrComment: {
				type: "adm_laboratory_pcr_comment_type",
				modalNewComment: {
					type: "adm_laboratory_pcr_comment_modal_new_comment_type",
				},
			},
			antigenComment: {
				type: "adm_laboratory_antigen_comment_type",
				modalNewComment: {
					type: "adm_laboratory_antigen_comment_modal_new_comment_type",
				},
			},
		},
		generalLedger: {
			accountsReceivable: {
				delete: "adm_general_ledger_accounts_receivable_delete",
			},
			accountsPayable: {
				delete: "adm_general_ledger_accounts_payable_delete",
			},
		},
		workflowProperty: {
			operatingRoom: {
				deleteAnestheticAgent: "adm_workflow_property_or_delete_anesthetic_agent",
				deleteMonitoring: "adm_workflow_property_or_delete_monitoring",
			},
		},
		customDefault: {
			assessment: {
				delete: "adm_custom_default_assessment_delete",
			},
			focus: {
				delete: "adm_custom_default_focus_delete",
			},
			intervention: {
				delete: "adm_custom_default_itervention_delete",
			},
			evaluation: {
				delete: "adm_custom_default_evaluation_delete",
			},
		},
	},
	REPORT: {
		inv: {
			byLocation: {
				branch: "report_inv_by_location_branch",
			},
		},
		tf: {
			byLocation: {
				branch: "report_tf_by_location_branch",
			},
		},
		po: {
			byLocation: {
				branch: "report_po_by_location_branch",
			},
		},
		hospitel: {
			patient: {
				branch: "report_hospitel_patient_branch",
				department: "report_hospitel_patient_department",
			},
		},
	},
	HOSPITEL: {
		cashier: {
			id: "hospital_cashier_id",
			doctorPrescriptionOnly: "hospital_cashier_doctor_prescription_only",
			cancelDoctorPrescriptionOnly: "hospital_cashier_cancel_doctor_prescription_only",
			dischargePatient: "hospital_cashier_discharge_patient",
			modalSearch: {
				patientTable: "hospital_cashier_modal_search_table_patient",
			},
			modalLab: {
				id: "hospital_cashier_modal_lab_id",
				tableUncharged: "hospital_cashier_modal_lab_table_uncharged",
				tablePending: "hospital_cashier_modal_lab_table_pending",
			},
			modalCertificate: {
				id: "hospital_cashier_modal_certificate_id",
				tableUncharged: "hospital_cashier_modal_certificate_table_uncharged",
				tablePending: "hospital_cashier_modal_certificate_table_pending",
			},
			modalDoctor: {
				id: "hospital_cashier_modal_doctor_id",
			},
			modalNurse: {
				id: "hospital_cashier_modal_nurse_id",
			},
			modalPayment: {
				type: "hospital_cashier_modal_payment_type",
				saveBilling: "hospital_cashier_modal_payment_save_billing",
			},
			modalAdjustment: {
				healthCarePlanDiscount: "hospital_cashier_modal_adjustment_healthCarePlan_discount",
			},
		},
		hospitel: {
			cancelOrder: "hospital_ipd_cancel_order",
			deleteOrder: "hospital_ipd_delete_order",
			discharge: "hospital_ipd_discharge_hospitel",
			modalDoctorOrder: {
				pendingItemTable: "hospital_ipd_modal_doctor_order_pending_item",
				durationType: "hospital_ipd_modal_doctor_order_duration_type",
				unit: "hospital_ipd_modal_doctor_order_unit",
			},
			modalSOAP: {
				delete: "hospital_ipd_modal_soap_delete",
			},
			modalVitalSign: {
				delete: "hospitel_ipd_modal_vital_sign_delete",
			},
			modalNurseNote: {
				delete: "hospitel_ipd_modal_nurse_note_delete",
			},
			modalRegister: {
				done: "hospital_ipd_modal_register_done",
				category: "hospital_ipd_modal_register_category",
				service: "hospital_ipd_modal_register_service",
			},
			modalNurseOrder: {
				itemTable: "hospital_ipd_modal_nurse_order_item",
				pendingItemTable: "hospital_ipd_modal_nurse_order_pending_item",
			},
			modalAdjustment: {
				delete: "hospital_ipd_modal_adjustment_delete",
			},
			modalAdjustmentService: {
				delete: "hospital_ipd_modal_adjustment_service_delete",
			},
		},
		lab: {
			modalLab: {
				branch: "hospitel_lab_modal_lab_branch",
				department: "hospital_lab_modal_lab_department",
				service: "hosptiel_lab_modal_lab_service",
			},
			modalRegister: {
				dob: "hospitel_lab_modal_register_dob",
				title: "hospitel_lab_modal_register_title",
				gender: "hospitel_lab_modal_register_gender",
			},
		},
		ipd: {
			modalRegister: {
				dob: "hospitel_ipd_modal_register_dob",
			},
		},
	},
	HOSPITAL: {
		deposit: {
			modalSave: {
				payment: "hospital_deposit_modal_save_payment",
			},
			modalWithdraw: {
				payment: "hospital_deposit_modal_withdraw_payment",
				amount: "hospital_deposit_modal_withdraw_amount",
			},
		},
		registration: {
			modalClinicalRecord: {
				healthcare: "hospital_registration_modal_clinical_record_healthcare",
			},
		},
		opd: {
			appointmentDelete: "hospital_opd_appointment_delete",
			checkOut: "hospital_opd_check_out",
			completeOperatingRoomRequest: "hospital_opd_complete_operating_room_request",
			confirmNursePreOperativeCheckList: "hospital_opd_confirm_nurse_pre_operative_checklist",
			deleteCertificate: "hospital_opd_delete_certificate",
			deleteDTX: "hospital_opd_delete_glucose",
			deleteDoctorOrder: "hospital_opd_delete_doctor_order",
			deleteGCS: "hospital_opd_delete_gcs",
			deleteICD: "hospital_opd_delete_icd",
			deleteIO: "hospital_opd_delete_io",
			deleteNurseMedicalSupply: "hospital_opd_delete_nurse_medical_supply",
			deleteNurseService: "hospital_opd_delete_nurse_service",
			deleteOperatingRoomRequest: "hospital_opd_delete_operating_room_request",
			deletePainScale: "hospital_opd_delete_pain_scale",
			deletePupilSize: "hospital_opd_delete_pupil_size",
			modalCertificate: {
				service: "hospital_opd_modal_certificate_service",
			},
			modalNurse: {
				delete: "hospital_opd_modal_nurse_delete",
			},
			modalVitalSign: {
				delete: "hospital_opd_modal_vital_sign_delete",
			},
			modalAdjustment: {
				delete: "hospital_opd_modal_adjustment_delete",
			},
		},
		ipd: {
			deleteCertificate: "hospital_ipd_delete_certificate",
			deleteDTX: "hospital_ipd_delete_glucose",
			deleteGCS: "hospital_ipd_delete_gcs",
			deleteICD: "hospital_ipd_delete_icd",
			deleteIO: "hospital_ipd_delete_io",
			deleteNurseMedicalSupply: "hospital_ipd_delete_nurse_medical_supply",
			deleteNurseService: "hospital_ipd_delete_nurse_service",
			deletePainScale: "hospital_ipd_delete_pain_scale",
			deletePupilSize: "hospital_ipd_delete_pupil_size",
			modalCertificate: {
				service: "hospital_ipd_modal_certificate_service",
				role: "hospital_ipd_modal_certificate_role",
				user: "hospital_ipd_modal_certificate_user",
				category: "hospital_ipd_modal_certificate_category",
			},
		},
		pharmacy: {
			deleteVerifiedOrder: "hospital_pharmacy_delete_verified_order",
			deleteSkippedOrder: "hospital_pharmacy_delete_skipped_order",
			dispenseVerifiedOrder: "hospital_pharmacy_dispense_verified_order",
			skipVerify: "hospital_pharmacy_skip_verify",
		},
		frontInventory: {
			modalWaste: {
				authorization: "hospital_front_inventory_modal_waste_authorization",
			},
		},
		surgicalAuthorization: {
			inspectOperatingRoomRequest: "hospital_surgical_authorization_inspect_operating_room_request",
			approveOperatingRoomRequest: "hospital_surgical_authorization_approve_operating_room_request",
			rejectOperatingRoomRequest: "hospital_surgical_authorization_reject_operating_room_request",
		},
		operatingRoom: {
			approveIntraAnesthesiaRecord: "hospital_operating_room_approve_intra_anesthesia_record",
			confirmNursePreOperativeCheckList: "hospital_operating_room_confirm_nurse_pre_operative_check_list",
			confirmSchedule: "hospital_operating_room_confirm_operating_room_schedule",
			confirmSurgicalSafetyCheckList: "hospital_operating_room_confirm_surgical_safety_checklist",
			deleteIntraAneDoctorOrder: "hospital_operating_room_delete_intra_ane_doctor_order",
			deleteIntraAneIntakeOutput: "hospital_operating_room_delete_intra_ane_io",
			deleteIntraAneModalService: "hospital_operating_room_delete_intra_ane_modal_service",
			deleteIntraAneService: "hospital_operating_room_delete_intra_ane_service",
			deletePostAneDoctorOrder: "hospital_operating_room_delete_post_ane_doctor_order",
			deletePostAneIntake: "hospital_operating_room_delete_post_ane_intake",
			deletePostAneIntakeOutput: "hospital_operating_room_delete_post_ane_io",
			deletePostAneModalService: "hospital_operating_room_delete_post_ane_modal_service",
			deletePostAneOutput: "hospital_operating_room_delete_post_ane_output",
			deletePostAneService: "hospital_operating_room_delete_post_ane_service",
			deleteSurgicalSafetyCheckList: "hospital_operating_room_delete_surgical_safety_checklist",
			modalIntraAneVS: {
				deleteVS: "hospital_operating_room_modal_intra_ane_vs_delete_vs",
			},
			modalPostAneVS: {
				deleteVS: "hospital_operating_room_modal_post_ane_vs_delete_vs",
			},
		},
	},
	ACCOUNTING: {
		accountsReceivable: {
			rejectInvoice: "accounting_AR_reject_invoice",
			inspectInvoice: "accounting_AR_inspect_invoice",
			approveInvoice: "accounting_AR_approve_invoice",
			modalAdjustment: {
				billingStatementAdjustment: "accounting_AR_modal_adjustment_billing_statement",
			},
			modalGeneralLedger: {
				updateGL: "accounting_AR_modal_general_ledger_update_GL",
			},
		},
		accountsReceive: {
			modalGeneralLedger: {
				updateGL: "accounts_receive_modal_GL_update_GL",
			},
			rejectReceipt: "accounts_receive_reject_receipt",
			inspectReceipt: "accounts_receive_inspect_receipt",
			approveReceipt: "accounts_receive_approve_receipt",
		},
		accountsPayable: {
			inspectInvoice: "finance_AP_inspect_invoice",
			approveInvoice: "finance_AP_approve_invoice",
			rejectInvoice: "finance_AP_reject_invoice",
			modalGeneralLedger: {
				updateGL: "finance_AP_modal_general_ledger_update_GL",
			},
		},
		debt: {
			deleteInvoice: "accounting_debt_delete_invoice",
			modalAdjustment: {
				billingStatementAdjustment: "accounting_debt_modal_adjustment_billing_statement",
				billingPlanAdjustment: "accounting_debt_modal_adjustment_billing_plan",
			},
		},
		paymentSlip: {
			approvePaymentPlan: "accounting_payment_slip_approve_payment_plan",
			completePaymentSlipRequest: "accounting_payment_slip_complete_payment_slip_request",
			deleteInvoice: "accounting_payment_slip_delete_invoice",
			deletePaymentPlan: "accounting_payment_slip_delete_payment_plan",
			inspectPaymentPlan: "accounting_payment_slip_inspect_payment_plan",
			rejectPaymentPlan: "accounting_payment_slip_reject_payment_plan",
			updatePaymentPlanApprover: "accounting_payment_slip_update_payment_plan_approver",
			updatePaymentPlanCreator: "accounting_payment_slip_update_payment_plan_creator",
			updatePaymentPlanInspector: "accounting_payment_slip_update_payment_plan_inspector",
			updatePaymentPlanReject: "accounting_payment_slip_update_payment_plan_reject",
			modalGeneralLedger: {
				updateGL: "accounting_PS_modal_general_ledger_update_GL",
			},
		},
		financialOperations: {
			complete: "finance_financial_operations_complete",
			complete_financial_transaction: "finance_financial_operations_complete_financial_transaction",
			deletePaymentPlan: "finance_financial_operations_delete_payment_plan",
		},
		purchaseInvoice: {
			complete: "finance_purchase_invoice_complete",
			deletePurchase: "finance_purchase_invoice_delete_purchase",
			inspectInvoice: "finance_purchase_invoice_inspect_invoice",
			rejectInvoice: "finance_purchase_invoice_reject_invoice",
		},
		paymentVoucher: {
			complete: "finance_payment_voucher_complete",
			deletePurchase: "finance_payment_voucher_delete_purchase",
		},
		postingGL: {
			complete: "finance_posting_GL_complete",
			deleteGL: "finance_posting_GL_delete_GL",
			inspectRequest: "finance_posting_GL_inspect_request",
			rejectRequest: "finance_posting_GL_reject_request",
		},
	},
	MENU: {
		notification: {},
		selectableAccountingCode: {
			save: "context_menu_selectable_accounting_code_save",
		},
		document: {
			deleteUploadDocument: "context_menu_document_delete_upload_document",
		},
	},
}

export default ID
