import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setFORequests } from "~/redux/action"
import { SET_FO_NEW_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import FOModalNewDetails from "./FOModalNewDetails"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"

class FOModalNew extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_FO_NEW_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return this.props.FO.modalNew.pendingPaymentPlanTable.original.length === 0
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let GL = undefined
		if (this.props.FO.modalNew.selectedPaymentType.type.bankAccount) {
			GL = Utils.getGeneralLedgerCode(
				this.props.FO.location.accountsPayable,
				[],
				this.props.FO.modalNew.selectedBank.bank.activities.filter((activity) => activity.financialOperations),
				false
			)
		} else {
			GL = Utils.getGeneralLedgerCode(
				this.props.FO.location.accountsPayable,
				[],
				this.props.FO.modalNew.selectedPaymentType.type.activities.filter((activity) => activity.financialOperations),
				false
			)
		}

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.financialOperations.saveFinancialRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.FO.location.id,
				userId: this.props.login.user.id,
				requests: [
					{
						id: this.props.FO.selectedFinancialPlan.financialPlan ? this.props.FO.selectedFinancialPlan.financialPlan.id : "",
						supplierReceivedDateTime: this.props.FO.modalNew.supplierReceivedDateTime,
						paymentMethod: this.props.FO.modalNew.selectedPaymentType.type.displayName,
						bankAccount: this.props.FO.modalNew.selectedPaymentType.type.bankAccount ? this.props.FO.modalNew.selectedBank.bank.account : "",
						bankCode: this.props.FO.modalNew.selectedPaymentType.type.bankAccount
							? this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].code
							: "",
						bankName: this.props.FO.modalNew.selectedPaymentType.type.bankAccount
							? this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].displayName
							: "",
						bankBranchCode: this.props.FO.modalNew.selectedPaymentType.type.bankAccount ? this.props.FO.modalNew.selectedBank.bank.code : "",
						bankBranchName: this.props.FO.modalNew.selectedPaymentType.type.bankAccount ? this.props.FO.modalNew.selectedBank.bank.displayName : "",
						serviceTypeCode: this.props.FO.modalNew.selectedPaymentType.type.bankAccount ? this.props.FO.modalNew.selectedServiceType.type.code : "",
						serviceType: this.props.FO.modalNew.selectedPaymentType.type.bankAccount ? this.props.FO.modalNew.selectedServiceType.type.displayName : "",
						amount: this.props.FO.modalNew.pendingPaymentPlanTable.body
							.reduce((total, cur) => {
								return total.plus(Utils.formatNumberFromStr(cur[7]))
							}, Utils.BigNumber(0))
							.toNumber(),
						exportAvailable:
							this.props.FO.modalNew.selectedPaymentType.type.bankAccount &&
							this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].internetPayFormatAvailable,
						batchRecordFormat: this.props.FO.modalNew.selectedPaymentType.type.bankAccount
							? this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].ipayBatchFormat
							: "",
						detailRecordFormat: this.props.FO.modalNew.selectedPaymentType.type.bankAccount
							? this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].ipayDetailFormat
							: "",
						accountingCode: GL.fullCode,
						accountingChartName: GL.displayName,
						accountingChartDescription: GL.description,
						accountingTransDescr: GL.transDescr,
						paymentPlans: this.props.FO.modalNew.pendingPaymentPlanTable.filtered.map((each) => {
							return {
								id: each.id,
							}
						}),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.FO.financialPlanTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setFORequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.financialOperations)}</ModalTitle>
				</ModalHeader>

				<ModalBody>
					<FOModalNewDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isFinancialOperationsNewDisplay,
	login: state.login,
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setFORequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FOModalNew))
