import { Radio, Space } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_REPORT_INV_BY_LOCATION_VIEW_QTY } from "~/redux/type"

class ReportInvByLocationFilterViewDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onView = this.onView.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_REPORT_INV_BY_LOCATION_VIEW_QTY, true)
	}

	onView(e) {
		this.props.setValue(SET_REPORT_INV_BY_LOCATION_VIEW_QTY, e.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.view)}</legend>
				<Row>
					<Col />
					<Col md="auto">
						<Radio.Group value={this.props.filter.isQty} onChange={this.onView}>
							<Space size={"large"}>
								<Radio style={{ flexDirection: "row" }} value={true}>
									{translate(ResourceAssistance.Message.qty)}
								</Radio>
								<Radio style={{ flexDirection: "row" }} value={false}>
									{translate(ResourceAssistance.Message.value)}
								</Radio>
							</Space>
						</Radio.Group>
					</Col>
					<Col />
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.inv.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportInvByLocationFilterViewDetails))
