import { Select, TreeSelect } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setReportFinanceGLs, setSelected, setValue } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_REPORT_FINANCE_GL_ACCOUNT_CODES,
	SET_REPORT_FINANCE_GL_END_DATE_TIME,
	SET_REPORT_FINANCE_GL_SELECTED_ORG,
	SET_REPORT_FINANCE_GL_START_DATE_TIME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ATable from "~/view/component/ant_table/ATable"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class ReportFinanceGeneralLedgerFilters extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
		this.onDateRange = this.onDateRange.bind(this)
		this.onAccountCode = this.onAccountCode.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_REPORT_FINANCE_GL_START_DATE_TIME, moment().startOf("day").milliseconds(0).valueOf())
		this.props.setValue(SET_REPORT_FINANCE_GL_END_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.filter.startDateTime, this.props.filter.startDateTime) ||
			!_.isEqual(prevProps.filter.endDateTime, this.props.filter.endDateTime)
		) {
			this.props.setReportFinanceGLs([])
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_REPORT_FINANCE_GL_START_DATE_TIME, moment().startOf("day").milliseconds(0).valueOf())
		this.props.setValue(SET_REPORT_FINANCE_GL_END_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
		this.props.setValue(SET_REPORT_FINANCE_GL_ACCOUNT_CODES, "")
		this.props.setSelected(SET_REPORT_FINANCE_GL_SELECTED_ORG, -1, null)
		this.props.setReportFinanceGLs([])
	}

	getAccountCodes() {
		let codes = this.props.filter.accountCodes
		let selected = []
		codes
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
			.forEach((code) => {
				let coaAry = Utils.findChartOfAccountsFromGroup(this.props.filter.chartOfAccounts, code)
				if (!_.isEmpty(coaAry)) {
					selected.push(coaAry[0])
				}
			})
		return selected
	}

	onOrg(value) {
		if (value === undefined) {
			this.props.setSelected(SET_REPORT_FINANCE_GL_SELECTED_ORG, -1, null)
			return
		}
		this.props.setSelected(SET_REPORT_FINANCE_GL_SELECTED_ORG, value, this.props.filter.orgs[value])
	}

	onDateRange(v) {
		this.props.setValue(SET_REPORT_FINANCE_GL_START_DATE_TIME, v ? v[0].startOf("day").milliseconds(0).valueOf() : "")
		this.props.setValue(SET_REPORT_FINANCE_GL_END_DATE_TIME, v ? v[1].endOf("day").milliseconds(0).valueOf() : "")
	}

	onAccountCode(value) {
		if (_.isEmpty(value)) {
			this.props.setValue(SET_REPORT_FINANCE_GL_ACCOUNT_CODES, "")
			return
		}
		let codes = ""
		value.forEach((each, idx) => {
			let keys = each.split("-")
			let coa = (function getSelectedAccount(accounts, idx) {
				if (idx === keys.length - 1) {
					return accounts[keys[idx]]
				}
				return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
			})(this.props.filter.chartOfAccounts[keys[0]].values, 1)
			codes = [codes, coa.fullCode].filter((each) => Boolean(each)).join(ResourceAssistance.PROGRAM_DEFINED.split)
		})
		this.props.setValue(SET_REPORT_FINANCE_GL_ACCOUNT_CODES, codes)
	}

	onSearch() {
		let accountCodes = []
		this.props.filter.accountCodes
			.split(ResourceAssistance.PROGRAM_DEFINED.split)
			.filter((each) => Boolean(each))
			.forEach((code) => {
				let coaAry = Utils.findChartOfAccountsFromGroup(this.props.filter.chartOfAccounts, code)
				if (!coaAry[1].subLevel) {
					accountCodes.push(coaAry[1].fullCode)
				} else {
					;(function get(accounts) {
						accounts.forEach((each) => {
							if (!each.subLevel) {
								accountCodes.push(each.fullCode)
							} else {
								get(each.accounts)
							}
						})
					})(coaAry[1].accounts)
				}
			})

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.report.finance.getGeneralLedgers,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.filter.selectedOrg.org.id,
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				accountCodes: accountCodes.filter((each) => Boolean(each)),
			},
		}
		let callback = (res) => {
			this.props.setReportFinanceGLs(res.data.generalLedgers)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<>
				<Row className="g-1">
					<Col md={2}>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.org)}
							loading={this.props.filter.isOrgsLoading}
							value={this.props.filter.selectedOrg.org ? this.props.filter.selectedOrg.index : undefined}
							onChange={this.onOrg}
						>
							{Utils.renderSelects(this.props.filter.orgs, false)}
						</Select>
					</Col>
					<Col md="auto">
						<AntDatePicker
							displayRange
							format={"DD-MM-YYYY"}
							placeholder={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.startDate }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.endDate }),
							]}
							values={[moment(this.props.filter.startDateTime), moment(this.props.filter.endDateTime)]}
							onChange={this.onDateRange}
						/>
					</Col>
					<Col>
						<TreeSelect
							multiple
							style={{
								textAlign: "center",
							}}
							listHeight={700}
							showSearch
							allowClear
							maxTagCount={"responsive"}
							treeCheckable
							showCheckedStrategy={TreeSelect.SHOW_PARENT}
							treeExpandAction={"doubleClick"}
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							value={this.getAccountCodes()}
							onChange={this.onAccountCode}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.filter.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"], true)}
						/>
					</Col>
					<Col md="auto">
						<Col md="auto">
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								disabled={_.isEmpty(this.props.filter.accountCodes) || !this.props.filter.selectedOrg.org}
								onClick={this.onSearch}
							>
								<GoSearch size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.search)}
							</Button>
						</Col>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ATable
							pagination={false}
							data={this.props.filter.generalLedgerTable}
							expandable={{
								expandedRowRender: (row, idx) => {
									let selectedGL = this.props.filter.generalLedgerTable.filtered[idx]
									let original = selectedGL.trans ? selectedGL.trans : []
									let filtered = original.sort((a, b) => Utils.sort(a.dateTime, b.dateTime))
									let cumulativeTotal = Utils.BigNumber(selectedGL.totalDebit).minus(selectedGL.totalCredit)
									let body = filtered.map((each) => {
										cumulativeTotal = cumulativeTotal.plus(each.debit).minus(each.credit)
										return [
											Utils.formatDate(each.dateTime),
											each.documentCode.concat("-", each.documentId),
											each.transactionDescription,
											Utils.formatNumWithComma(Utils.BigNumber(each.debit).toFixed(2)),
											Utils.formatNumWithComma(Utils.BigNumber(each.credit).toFixed(2)),
											Utils.formatNumWithComma(cumulativeTotal.toFixed(2)),
										]
									})
									let colStyle = body.map((each) => {
										return [
											[false, ""],
											[true, { textAlign: "left" }],
											[true, { textAlign: "left" }],
											[
												true,
												{
													textAlign: "right",
												},
											],
											[
												true,
												{
													textAlign: "right",
												},
											],
											[
												true,
												{
													textAlign: "right",
													color: Utils.BigNumber(each[5]).isZero()
														? undefined
														: Utils.BigNumber(each[5]).isNegative()
														? ResourceAssistance.CSS.Color.dark_red
														: ResourceAssistance.CSS.Color.dark_green,
												},
											],
										]
									})
									let width = ["6%", "15%", undefined, "10%", "10%", "10%"]
									return (
										<ATable
											pagination={false}
											loading={this.props.filter.isGeneralLedgerTransactionsLoading}
											data={{
												original: original,
												filtered: filtered,
												header: [
													ResourceAssistance.Message.date,
													ResourceAssistance.Message.id,
													ResourceAssistance.Message.description,
													ResourceAssistance.Message.debit,
													ResourceAssistance.Message.credit,
													ResourceAssistance.Message.total,
												],
												body: body,
												colStyle: colStyle,
												width: width,
											}}
											footer={() => ""}
										/>
									)
								},
								expandRowByClick: true,
								rowExpandable: (record) => !(Utils.BigNumber(record.debit).isEqualTo(0) && Utils.BigNumber(record.credit).isEqualTo(0)),
								onExpand: (isOpen, record) => {
									let selected = this.props.filter.generalLedgerTable.filtered[record.key]
									if (isOpen && !selected.trans) {
										this.props.fetchGLTrans([record.code], (trans) => {
											Object.assign(selected, {
												trans: trans,
											})
										})
									}
								},
							}}
						/>
					</Col>
				</Row>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.finance.generalLedger,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setReportFinanceGLs,
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportFinanceGeneralLedgerFilters))
