import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class TFLeftZthRow extends React.Component {
	render() {
		return (
			<Row className={"g-0"}>
				<Col>
					<Row className={"g-0 label-info-row"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.requester)}</label>
						</Col>
						<Col md={4}>
							<FormControl
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.text}
								disabled
								value={
									this.props.transfer.selectedRequest.request &&
									this.props.transfer.selectedRequest.request.requesterApproved
										? this.props.transfer.selectedRequest.request.requester.displayName
										: ""
								}
							/>
						</Col>
						<Col>
							<FormControl
								disabled
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.text}
								value={
									this.props.transfer.selectedRequest.request &&
									this.props.transfer.selectedRequest.request.requesterApproved
										? Utils.formatDateTime(this.props.transfer.selectedRequest.request.requesterApprovedDateTime)
										: ""
								}
							/>
						</Col>
					</Row>

					<Row className={"g-0 label-info-row"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.approver)}</label>
						</Col>
						<Col md={4}>
							<FormControl
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.text}
								disabled
								value={
									this.props.transfer.selectedRequest.request &&
									this.props.transfer.selectedRequest.request.approvalApproved
										? this.props.transfer.selectedRequest.request.approval.displayName
										: ""
								}
							/>
						</Col>
						<Col>
							<FormControl
								disabled
								size={ResourceAssistance.FormControl.size.sm}
								type={ResourceAssistance.FormControl.type.text}
								value={
									this.props.transfer.selectedRequest.request &&
									this.props.transfer.selectedRequest.request.approvalApproved
										? Utils.formatDateTime(this.props.transfer.selectedRequest.request.approvalApprovedDateTime)
										: ""
								}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	transfer: state.transfer,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TFLeftZthRow)
