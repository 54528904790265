import { Button as AButton, Card, ConfigProvider, Descriptions, Space, Steps, Tooltip } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FaCheck, FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
	SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY,
	SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_DISPLAY,
	SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OperatingRoomNurseTabSurgicalSafetyCheckList extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			width: 0,
			current: 0,
		}

		this.cardRowRef = React.createRef()

		this.onEditSignIn = this.onEditSignIn.bind(this)
		this.onConfirmSignIn = this.onConfirmSignIn.bind(this)
		this.onNewTimeOut = this.onNewTimeOut.bind(this)
		this.onEditTimeOut = this.onEditTimeOut.bind(this)
		this.onEditSignOut = this.onEditSignOut.bind(this)
		this.onConfirmSignOut = this.onConfirmSignOut.bind(this)
	}

	componentDidMount() {
		this.updateWidth()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.confirmSurgicalSafetyCheckList)) {
					this.confirm()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteSurgicalSafetyCheckList)) {
					this.delete()
				}
			}
		}
		if (this.cardRowRef.current && !_.isEqual(prevProps.operatingRoom.selectedRequest.request, this.props.operatingRoom.selectedRequest.request)) {
			this.updateWidth()
		}
	}
	updateWidth = () => {
		if (this.cardRowRef.current) {
			const elementScrollWidth = this.cardRowRef.current.scrollWidth
			this.setState({ width: elementScrollWidth })
		}
	}

	confirm() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.confirmSurgicalSafetyCheckList,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				surgicalSafetyCheckLists: [
					{
						id: this.props.operatingRoom.selectedSurgicalSafetyCheckList.checklist.id,
						confirmDateTime: moment().milliseconds(0).valueOf(),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	delete() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.deleteSurgicalSafetyCheckList,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				surgicalSafetyCheckLists: [
					{
						id: this.props.operatingRoom.selectedSurgicalSafetyCheckList.checklist.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getStepItems() {
		let rst = []
		let signIn =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists)
				? this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists[0]
				: {}
		let timeOuts = this.props.operatingRoom.selectedRequest.request
			? this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
			: []
		let signOut =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists)
				? this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists[0]
				: {}

		rst.push({
			key: "signIn",
			title: translate(ResourceAssistance.Message.signIn),
			subTitle: Utils.formatDateTime(signIn.confirmDateTime),
			description: signIn.confirmBy ? `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.by })} ${signIn.confirmBy.displayName}` : "",
			status: signIn.confirmBy ? "finish" : "process",
			style: {
				paddingLeft: "100px",
				maxWidth: "579px",
			},
		})

		_.isEmpty(timeOuts)
			? rst.push({
					key: "timeOut",
					title: translate(ResourceAssistance.Message.timeOut),
					status: signIn.confirmBy ? "process" : "wait",
					style: {
						maxWidth: "495px",
					},
			  })
			: timeOuts.forEach((timeOut, idx) => {
					rst.push({
						key: `timeOut-${timeOut.id}`,
						title: translate(ResourceAssistance.Message.timeOut),
						subTitle: Utils.formatDateTime(timeOut.confirmDateTime),
						description: timeOut.confirmBy ? `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.by })} ${timeOut.confirmBy.displayName}` : "",
						status: signIn.confirmBy ? (timeOut.confirmBy ? "finish" : "process") : "wait",
						style: {
							maxWidth: idx === timeOuts.length - 1 ? "495px" : "479px",
						},
					})
			  })

		rst.push({
			key: "signOut",
			title: translate(ResourceAssistance.Message.signOut),
			subTitle: Utils.formatDateTime(signOut.confirmDateTime),
			description: signOut.confirmBy ? `${this.props.intl.formatMessage({ id: ResourceAssistance.Message.by })} ${signOut.confirmBy.displayName}` : "",
			status: !_.isEmpty(timeOuts) && timeOuts.every((timeOut) => timeOut.confirmBy) ? (signOut.confirmBy ? "finish" : "process") : "wait",
		})
		return rst
	}

	getSignInOutActions(input, editFunc, confirmFunc) {
		let rst = []
		if (_.isEmpty(input) || !input.confirmBy) {
			rst.push(
				<Tooltip key={"edit"} title={translate(ResourceAssistance.Message.edit)}>
					<AButton type="link" icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />} onClick={() => editFunc(input.id)} />
				</Tooltip>
			)
		}
		if (!_.isEmpty(input) && !input.confirmBy) {
			rst.push(
				<Tooltip key={"complete"} title={translate(ResourceAssistance.Message.confirm)}>
					<AButton
						type="link"
						style={{
							color: ResourceAssistance.CSS.Color.dark_green,
						}}
						icon={<FaCheck size={ResourceAssistance.ReactIcon.size} />}
						onClick={() => confirmFunc(input.id)}
					/>
				</Tooltip>
			)
		}
		return rst
	}

	getTimeOutActions(input, idx) {
		let timeOuts = this.props.operatingRoom.selectedRequest.request ? this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists : []
		let signOuts = this.props.operatingRoom.selectedRequest.request ? this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists : []
		let rst = []
		if (
			!_.isEmpty(input) &&
			timeOuts.every((each) => each.confirmBy) &&
			idx === timeOuts.length - 1 &&
			(_.isEmpty(signOuts) || signOuts.some((each) => !each.confirmBy))
		) {
			rst.push(
				<Tooltip key={"new"} title={translate(ResourceAssistance.Message.new)}>
					<AButton type="link" icon={<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />} onClick={this.onNewTimeOut} />
				</Tooltip>
			)
		}
		if (_.isEmpty(input) || !input.confirmBy) {
			rst.push(
				<Tooltip key={"edit"} title={translate(ResourceAssistance.Message.edit)}>
					<AButton type="link" icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />} onClick={() => this.onEditTimeOut(input.id)} />
				</Tooltip>
			)
		}
		if (!_.isEmpty(input) && !input.confirmBy && idx > 0 && idx === timeOuts.length - 1) {
			rst.push(
				<Tooltip key={"edit"} title={translate(ResourceAssistance.Message.delete)}>
					<AButton
						type="link"
						style={{
							color: ResourceAssistance.CSS.Color.dark_red,
						}}
						icon={<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />}
						onClick={() => this.onDeleteTimeOut(input.id)}
					/>
				</Tooltip>
			)
		}
		if (!_.isEmpty(input) && !input.confirmBy) {
			rst.push(
				<Tooltip key={"complete"} title={translate(ResourceAssistance.Message.confirm)}>
					<AButton
						type="link"
						style={{
							color: ResourceAssistance.CSS.Color.dark_green,
						}}
						icon={<FaCheck size={ResourceAssistance.ReactIcon.size} />}
						onClick={() => this.onConfirmTimeOut(input.id)}
					/>
				</Tooltip>
			)
		}
		return rst
	}

	displayConfirmWarning() {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.confirmSurgicalSafetyCheckList)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.confirm)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				<Space>
					<span>{translate(ResourceAssistance.Warning.canNotChangeAfterSaved)}</span>
				</Space>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		let signIn =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists)
				? this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists[0]
				: {}
		let signOut =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists)
				? this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists[0]
				: {}
		return (
			<Container
				fluid="small"
				className={ResourceAssistance.CSS.fullFlex}
				style={{
					overflow: "auto",
				}}
			>
				<Row style={{ width: `${this.state.width}px` }}>
					<Col>
						<ConfigProvider
							theme={{
								components: {
									Steps: {
										descriptionMaxWidth: "250px",
									},
								},
							}}
						>
							<Steps status="finish" current={this.state.current} items={this.getStepItems()} />
						</ConfigProvider>
					</Col>
				</Row>
				<Row ref={this.cardRowRef} className="g-2 full-size" style={{ flexWrap: "nowrap", alignItems: "stretch", marginRight: 0, marginLeft: 0 }}>
					<Col style={{ minWidth: "479px", paddingBottom: "10px" }}>
						<Card
							style={{
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								borderColor: "transparent",
								boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
							}}
							styles={{
								body: {
									flexGrow: 1,
								},
							}}
							size={"small"}
							bordered={false}
							title={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.signIn })} (${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.beforeInductionOfAnesthesia,
							})})`}
							actions={this.getSignInOutActions(signIn, this.onEditSignIn, this.onConfirmSignIn)}
						>
							<Descriptions
								style={{ flex: "1", flexDirection: "column" }}
								size={"small"}
								colon={false}
								labelStyle={{ color: "initial" }}
								contentStyle={{ fontStyle: "italic", minWidth: "19px" }}
								column={2}
							>
								<Descriptions.Item label={translate(ResourceAssistance.Message.confirmPatientIdentity)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInPatientIdentity)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.hasThePatientSignConsent)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInPatientSignConsent)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.isTheSiteMarked)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInSiteMarked)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.isTheAnesthesiaMachineAndMedicationCheckComplete)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInAnesthesiaMachineCheckComplete)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.isThePulseOximeterOnthePatient)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInPulseOximeterFunctioning)}</Descriptions.Item>
								<Descriptions.Item className={"exclude-colon"} label={translate(ResourceAssistance.Message.doesThePatientHave)} span={2} />
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.knowAllergy })}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInKnowAllergy)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.difficultAirwayOrAspirationRisk,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInDifficultAirway)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.riskOfGreaterThan500mlBloodLoss,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInRiskOfBloodLoss)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.orEquipmentAndInstrument)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInOREquipment)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.medicalDeviceImplant)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signIn.signInMedicalDeviceImplant)}</Descriptions.Item>
							</Descriptions>
						</Card>
					</Col>
					{this.renderTimeOut()}
					<Col style={{ minWidth: "479px", paddingBottom: "10px" }}>
						<Card
							style={{
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								borderColor: "transparent",
								boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
							}}
							styles={{
								body: {
									flexGrow: 1,
								},
							}}
							size={"small"}
							title={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.signOut })} (${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.beforePatientLeavesOperatingRoom,
							})})`}
							bordered={false}
							actions={this.getSignInOutActions(signOut, this.onEditSignOut, this.onConfirmSignOut)}
						>
							<Descriptions
								style={{ flex: "1", flexDirection: "column" }}
								size={"small"}
								colon={false}
								labelStyle={{ color: "initial" }}
								contentStyle={{ fontStyle: "italic", minWidth: "19px" }}
								column={2}
							>
								<Descriptions.Item className={"exclude-colon"} label={translate(ResourceAssistance.Message.nurseVerballyConfirms)} span={2} />
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.theNameOfTheProcedure,
									})}`}
									span={1}
								/>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signOut.signOutProcedureName)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.completionOfInstrumentSpongeAndNeedleCounts,
									})}`}
									span={1}
								/>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signOut.signOutCompletionOfCounts)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.specimenLabeling,
									})}`}
									span={1}
								/>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signOut.signOutSpecimenLabeling)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.whetherThereAreAnyEquipmentProblemsToBeAddressed,
									})}`}
									span={1}
								/>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signOut.signOutEquipmentProblem)}</Descriptions.Item>
								<Descriptions.Item className={"exclude-colon"} label={translate(ResourceAssistance.Message.toSurgeonAnesthesiologistAndNurse)} span={2} />
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.whatAreTheKeyConcernsForRecoveryAndManagementOfThisPatient,
									})}`}
									span={1}
								/>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(signOut.signOutRecoveryConcern)}</Descriptions.Item>
							</Descriptions>
						</Card>
					</Col>
				</Row>
			</Container>
		)
	}

	renderTimeOut() {
		let timeOuts = this.props.operatingRoom.selectedRequest.request
			? this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists.sort((a, b) => Utils.sort(a.creationDateTime, b.creationDateTime))
			: []
		return _.isEmpty(timeOuts) ? (
			<Col style={{ minWidth: "479px", paddingBottom: "10px" }}>
				<Card
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						borderColor: "transparent",
						boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
					}}
					styles={{
						body: {
							flexGrow: 1,
						},
					}}
					size={"small"}
					bordered={false}
					title={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.timeOut })} (${this.props.intl.formatMessage({
						id: ResourceAssistance.Message.beforeSkinIncision,
					})})`}
					actions={this.getTimeOutActions({}, 0)}
				>
					<Descriptions
						style={{ flex: "1", flexDirection: "column" }}
						size={"small"}
						colon={false}
						labelStyle={{ color: "initial" }}
						contentStyle={{ fontStyle: "italic", minWidth: "19px" }}
						column={2}
					>
						<Descriptions.Item label={translate(ResourceAssistance.Message.allTeamMembersHaveIntroducedThemselves)} span={2}></Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.patientNameDateOfBirth)} span={2}></Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.procedureSiteAndSide)} span={2}></Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.hasAntibioticProphylaxisBeenGivenWithinTheLast60Min)} span={2}></Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.anticipatedCriticalEvents)} span={2} />
						<Descriptions.Item
							labelStyle={{ paddingLeft: "0.5em" }}
							label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.toSurgeon })}`}
							span={2}
						/>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "1em" }}
							label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.whatAreTheCriticalOrNonroutineSteps,
							})}`}
							span={2}
						></Descriptions.Item>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "1em" }}
							label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.howLongWillTheCaseTake,
							})}`}
							span={2}
						></Descriptions.Item>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "1em" }}
							label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.whatIsTheAnticipatedBloodLoss,
							})}`}
							span={2}
						></Descriptions.Item>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "0.5em" }}
							label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.toAnesthesiologist })}`}
							span={2}
						/>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "1em" }}
							label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.areThereAnyPatientSpecificConcerns,
							})}`}
							span={2}
						></Descriptions.Item>
						<Descriptions.Item
							className={"exclude-colon"}
							labelStyle={{ paddingLeft: "0.5em" }}
							label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.toNursingTeam })}`}
							span={2}
						/>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "1em" }}
							label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.hasSterilizationBeenConfirmed,
							})}`}
							span={2}
						></Descriptions.Item>
						<Descriptions.Item
							labelStyle={{ paddingLeft: "1em" }}
							label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.areThereEquipmentIssuesOrAnyConcerns,
							})}`}
							span={2}
						></Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.essentialImagingDisplayed)} span={2}></Descriptions.Item>
					</Descriptions>
				</Card>
			</Col>
		) : (
			timeOuts.map((timeOut, idx) => {
				return (
					<Col key={idx} style={{ minWidth: "479px", paddingBottom: "10px" }}>
						<Card
							style={{
								flexGrow: 1,
								display: "flex",
								flexDirection: "column",
								borderColor: "transparent",
								boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
							}}
							styles={{
								body: {
									flexGrow: 1,
								},
							}}
							size={"small"}
							bordered={false}
							title={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.timeOut })} (${this.props.intl.formatMessage({
								id: ResourceAssistance.Message.beforeSkinIncision,
							})})`}
							actions={this.getTimeOutActions(timeOut, idx)}
						>
							<Descriptions
								style={{ flex: "1", flexDirection: "column" }}
								size={"small"}
								colon={false}
								labelStyle={{ color: "initial" }}
								contentStyle={{ fontStyle: "italic", minWidth: "19px" }}
								column={2}
							>
								<Descriptions.Item label={translate(ResourceAssistance.Message.allTeamMembersHaveIntroducedThemselves)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutConfirmTeamMembers)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.patientNameDateOfBirth)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutPatientName)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.procedureSiteAndSide)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutProcedureSiteAndSide)}</Descriptions.Item>
								<Descriptions.Item
									label={translate(ResourceAssistance.Message.hasAntibioticProphylaxisBeenGivenWithinTheLast60Min)}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutAntibiotic)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.anticipatedCriticalEvents)} span={2} />
								<Descriptions.Item
									labelStyle={{ paddingLeft: "0.5em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.toSurgeon })}`}
									span={2}
								/>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.whatAreTheCriticalOrNonroutineSteps,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutCriticalSteps)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.howLongWillTheCaseTake,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutCaseDuration)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.whatIsTheAnticipatedBloodLoss,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutAnticipatedBloodLoss)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "0.5em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.toAnesthesiologist })}`}
									span={2}
								/>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.areThereAnyPatientSpecificConcerns,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutPatientSpecificConcerns)}</Descriptions.Item>
								<Descriptions.Item
									className={"exclude-colon"}
									labelStyle={{ paddingLeft: "0.5em" }}
									label={`${ResourceAssistance.Symbol.dot} ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.toNursingTeam })}`}
									span={2}
								/>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.hasSterilizationBeenConfirmed,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutSterilizationConfirmed)}</Descriptions.Item>
								<Descriptions.Item
									labelStyle={{ paddingLeft: "1em" }}
									label={`${ResourceAssistance.Symbol.whiteDot} ${this.props.intl.formatMessage({
										id: ResourceAssistance.Message.areThereEquipmentIssuesOrAnyConcerns,
									})}`}
									span={1}
								></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutEquipmentIssues)}</Descriptions.Item>
								<Descriptions.Item label={translate(ResourceAssistance.Message.essentialImagingDisplayed)} span={1}></Descriptions.Item>
								<Descriptions.Item span={1}>{Utils.getYesNoMsg(timeOut.timeOutEssentialImagingDisplayed)}</Descriptions.Item>
							</Descriptions>
						</Card>
					</Col>
				)
			})
		)
	}

	onEditSignIn(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists[idx] : null
		)
		this.props.onModalDisplayAction(SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_IN_DISPLAY, true)
	}

	onConfirmSignIn(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.signInSurgicalSafetyCheckLists[idx] : null
		)
		this.displayConfirmWarning()
	}

	onNewTimeOut(e) {
		this.props.setSelected(SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST, -1, null)
		this.props.onModalDisplayAction(SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY, true)
	}

	onEditTimeOut(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists[idx] : null
		)
		this.props.onModalDisplayAction(SET_OR_SURGICAL_SAFETY_CHECKLIST_TIME_OUT_DISPLAY, true)
	}

	onDeleteTimeOut(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists[idx] : null
		)
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.deleteSurgicalSafetyCheckList)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			></Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onConfirmTimeOut(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.timeOutSurgicalSafetyCheckLists[idx] : null
		)
		this.displayConfirmWarning()
	}

	onEditSignOut(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists[idx] : null
		)
		this.props.onModalDisplayAction(SET_OR_SURGICAL_SAFETY_CHECKLIST_SIGN_OUT_DISPLAY, true)
	}

	onConfirmSignOut(id) {
		let idx = this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists.findIndex((each) => each.id === id)
		this.props.setSelected(
			SET_OR_SELECTED_SURGICAL_SAFETY_CHECKLIST,
			idx,
			idx > -1 ? this.props.operatingRoom.selectedRequest.request.signOutSurgicalSafetyCheckLists[idx] : null
		)
		this.displayConfirmWarning()
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomNurseTabSurgicalSafetyCheckList))
