import { Checkbox } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP } from "~/redux/type"

class ConfigGLModalPayablePaymentSlip extends React.Component {
	constructor(props) {
		super(props)

		this.onExclude = this.onExclude.bind(this)
	}

	componentDidMount() {
		if (this.props.configGL.accountsPayable.selectedAP.AP) {
			let selectedAP = this.props.configGL.accountsPayable.selectedAP.AP
			this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP, selectedAP.excludeWorkflowPaymentSlip)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP, false)
	}

	onExclude(e) {
		this.props.setValue(SET_ADM_GL_PAYABLE_MODAL_AP_EXCLUDE_PAYMENT_SLIP, e.target.checked)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.paymentSlip)}</legend>
				<Row className="g-2">
					<Col md="auto">
						<Checkbox
							style={{ flexDirection: "row" }}
							checked={this.props.configGL.accountsPayable.modalAP.isExcludeWorkflowPaymentSlip}
							onChange={this.onExclude}
						>
							{translate(ResourceAssistance.Message.exclusiveWokflow)}
						</Checkbox>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	configGL: state.admin.configGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGLModalPayablePaymentSlip))
