export const SET_REPORT_FINANCE_GL_FILTER_DISPLAY = "SET_REPORT_FINANCE_GL_FILTER_DISPLAY"
export const SET_REPORT_FINANCE_GL_COA = "SET_REPORT_FINANCE_GL_COA"
export const SET_REPORT_FINANCE_GL_ACCOUNT_CODES = "SET_REPORT_FINANCE_GL_ACCOUNT_CODES"
export const SET_REPORT_FINANCE_GL_START_DATE_TIME = "SET_REPORT_FINANCE_GL_START_DATE_TIME"
export const SET_REPORT_FINANCE_GL_END_DATE_TIME = "SET_REPORT_FINANCE_GL_END_DATE_TIME"
export const SET_REPORT_FINANCE_GLS = "SET_REPORT_FINANCE_GLS"
export const SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING = "SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING"
export const SET_REPORT_FINANCE_GL_ORGS = "SET_REPORT_FINANCE_GL_ORGS"
export const SET_REPORT_FINANCE_GL_ORGS_LOADING = "SET_REPORT_FINANCE_GL_ORGS_LOADING"
export const SET_REPORT_FINANCE_GL_SELECTED_ORG = "SET_REPORT_FINANCE_GL_SELECTED_ORG"
export const SET_REPORT_FINANCE_APA_FILTER_DISPLAY = "SET_REPORT_FINANCE_APA_FILTER_DISPLAY"
export const SET_REPORT_FINANCE_APA_ORGS = "SET_REPORT_FINANCE_APA_ORGS"
export const SET_REPORT_FINANCE_APA_ORGS_LOADING = "SET_REPORT_FINANCE_APA_ORGS_LOADING"
export const SET_REPORT_FINANCE_APA_SELECTED_ORG = "SET_REPORT_FINANCE_APA_SELECTED_ORG"
export const SET_REPORT_FINANCE_APA_FILTER_DATE_TIME = "SET_REPORT_FINANCE_APA_FILTER_DATE_TIME"
export const SET_REPORT_FINANCE_APA_FILTER_SUPPLIERS = "SET_REPORT_FINANCE_APA_FILTER_SUPPLIERS"
export const SET_REPORT_FINANCE_APA_AGINGS = "SET_REPORT_FINANCE_APA_AGINGS"
export const SET_REPORT_FINANCE_APA_ACCOUNTS_CODES = "SET_REPORT_FINANCE_APA_ACCOUNTS_CODES"
export const SET_REPORT_FINANCE_APA_SUPPLIER_AGINGS = "SET_REPORT_FINANCE_APA_SUPPLIER_AGINGS"
export const SET_REPORT_FINANCE_ARA_FILTER_DISPLAY = "SET_REPORT_FINANCE_ARA_FILTER_DISPLAY"
export const SET_REPORT_FINANCE_ARA_ORGS = "SET_REPORT_FINANCE_ARA_ORGS"
export const SET_REPORT_FINANCE_ARA_ORGS_LOADING = "SET_REPORT_FINANCE_ARA_ORGS_LOADING"
export const SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME = "SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME"
export const SET_REPORT_FINANCE_ARA_AGINGS = "SET_REPORT_FINANCE_ARA_AGINGS"
export const SET_REPORT_FINANCE_ARA_SELECTED_ORG = "SET_REPORT_FINANCE_ARA_SELECTED_ORG"
export const SET_REPORT_FINANCE_ARA_CUSTOMERS = "SET_REPORT_FINANCE_ARA_CUSTOMERS"
export const SET_REPORT_FINANCE_ARA_ACCOUNTS_CODES = "SET_REPORT_FINANCE_ARA_ACCOUNTS_CODES"
export const SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING = "SET_REPORT_FINANCE_APA_SUPPLIERS_LOADING"
export const SET_REPORT_FINANCE_APA_SUPPLIERS = "SET_REPORT_FINANCE_APA_SUPPLIERS"
export const SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES = "SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES"
export const SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING = "SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES_LOADING"
export const SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY = "SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY"
