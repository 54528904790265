import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { RiFileExcel2Line } from "react-icons/ri"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, resetAction, setValue, setLoadingAction } from "~/redux/action"
import { SET_REPORT_FINANCE_GL_FILTER_DISPLAY, SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import ReportFinanceGeneralLdegerDetails from "./ReportFinanceGeneralLdegerDetails"
import { ReportFinanceDataFactory } from "~/utils/factory/report/ReportFinanceDataFactory"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"

class ReportFinanceGeneralLedger extends React.Component {
	constructor(props) {
		super(props)

		this.ref = React.createRef()
		this.fetchGLTrans = this.fetchGLTrans.bind(this)
		this.onClose = this.onClose.bind(this)
		this.onExport = this.onExport.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_REPORT_FINANCE_GL_FILTER_DISPLAY, false)
	}

	fetchGLTrans(codes, callbackFunc) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.report.finance.getGeneralLedgerTrans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.filter.selectedOrg.org.id,
				startDateTime: this.props.filter.startDateTime,
				endDateTime: this.props.filter.endDateTime,
				accountCodes: codes,
			},
		}
		let callback = (res) => {
			callbackFunc(res.data.transactions)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_REPORT_FINANCE_GL_TRANSACTIONS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onClose() {
		this.reset()
	}

	onExport() {
		let codes = this.props.filter.generalLedgerTable.filtered
			.filter((gl) => _.isEmpty(gl.trans))
			.map((gl) => {
				return gl.code
			})
		this.fetchGLTrans(codes, (trans) => {
			Object.entries(Utils.groupBy(trans, "code")).forEach(([key, values]) => {
				let found = this.props.filter.generalLedgerTable.filtered.find((gl) => gl.code === key)
				Object.assign(found, {
					trans: values,
				})
			})
			let excel = ReportFinanceDataFactory.generateGeneralLedgerExcelData(
				this.props.filter.generalLedgerTable.filtered,
				this.props.filter.selectedOrg.org,
				this.props.filter.startDateTime,
				this.props.filter.endDateTime
			)
			this.ref.current.updateExcel(excel)
			this.ref.current.click()
		})
	}

	render() {
		return (
			<Modal
				centered
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				animation={false}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.generalLedger)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ReportFinanceGeneralLdegerDetails fetchGLTrans={this.fetchGLTrans} />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							variant={ResourceAssistance.Button.variant.green}
							disabled={_.isEmpty(this.props.filter.generalLedgerTable.filtered)}
							onClick={this.onExport}
						>
							<RiFileExcel2Line size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.export)}
						</Button>
						<ExcelDocument ref={this.ref} style={{ visibility: "hidden", display: "none" }} />
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isReportFinanceGeneralLedgerDisplay,
	filter: state.report.finance.generalLedger,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			resetAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportFinanceGeneralLedger)
