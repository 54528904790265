import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setPIModalNewPurchases, setSelected } from "~/redux/action"
import { SET_PI_MODAL_NEW_SELECTED_PURCHASE, SET_PI_PURCHASE_DISPLAY } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PIModalNewPurchase extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.PI.selectedPI.PI) {
			this.props.setPIModalNewPurchases(this.props.PI.selectedPI.PI.purchases)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.PI.modalNew.purchaseTable.filtered, this.props.PI.modalNew.purchaseTable.filtered)) {
			this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PURCHASE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setPIModalNewPurchases([])
		this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PURCHASE, -1, null)
	}

	onNew() {
		this.props.onModalDisplayAction(SET_PI_PURCHASE_DISPLAY, true)
	}

	onSelectRow(plan, index) {
		this.props.setSelected(SET_PI_MODAL_NEW_SELECTED_PURCHASE, index, this.props.PI.modalNew.purchaseTable.filtered[index])
	}

	onDelete(e, row, rIdx, cIdx) {
		let purchases = Array.from(this.props.PI.modalNew.purchaseTable.original.filter((each, idx) => idx !== rIdx))
		this.props.setPIModalNewPurchases(purchases)
	}

	render() {
		return (
			<Container fluid="small" className="full-flex" style={{ marginTop: "10px" }}>
				<Row className={"full-size"}>
					<Col>
						<ScrollableTable
							enableHighlight
							striped
							hover
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
							btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })]}
							btnIcons={[<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
							onCheckedArray={[this.onDelete]}
							data={this.props.PI.modalNew.purchaseTable}
							onClick={this.onSelectRow}
							isClearHighlight={this.props.PI.modalNew.selectedPurchase.index === -1}
							highlightedRow={this.props.PI.modalNew.selectedPurchase.index}
							extra={
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			onModalDisplayAction,
			setPIModalNewPurchases,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PIModalNewPurchase))
