import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setORModalIntraAneServices } from "~/redux/action"
import { SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ORModalIntraAneServiceService extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectService = this.onSelectService.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.operatingRoom.modalIntraAneService.filterDescription !== this.props.operatingRoom.modalIntraAneService.filterDescription ||
			!_.isEqual(prevProps.operatingRoom.modalIntraAneService.selectedType, this.props.operatingRoom.modalIntraAneService.selectedType) ||
			!_.isEqual(
				prevProps.operatingRoom.modalIntraAneService.pendingServiceTable.filtered,
				this.props.operatingRoom.modalIntraAneService.pendingServiceTable.filtered
			)
		) {
			this.props.setSelected(SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE, -1, null)
			this.props.setORModalIntraAneServices(
				this.props.operatingRoom.modalIntraAneService.selectedType.type ? this.props.operatingRoom.modalIntraAneService.selectedType.type.services : []
			)
		}
	}

	componentWillUnmount() {
		this.props.setORModalIntraAneServices([])
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE, -1, null)
	}

	onSelectService(service, index) {
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_SERVICE, index, this.props.operatingRoom.modalIntraAneService.serviceTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectService}
						data={this.props.operatingRoom.modalIntraAneService.serviceTable}
						isClearHighlight={this.props.operatingRoom.modalIntraAneService.selectedService.index === -1}
						highlightedRow={this.props.operatingRoom.modalIntraAneService.selectedService.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setORModalIntraAneServices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalIntraAneServiceService))
