import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setIPDModalPRNRecordRecords } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class IPDModalPRNRecordDispensing extends React.Component {
	componentDidMount() {
		if (this.props.hospitel.selectedOrder.order) {
			this.props.setIPDModalPRNRecordRecords(this.props.hospitel.selectedOrder.order.prnDispensingRecords)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.hospitel.selectedOrder, this.props.hospitel.selectedOrder)) {
			if (this.props.hospitel.selectedOrder.order) {
				this.props.setIPDModalPRNRecordRecords(this.props.hospitel.selectedOrder.order.prnDispensingRecords)
			} else {
				this.props.setIPDModalPRNRecordRecords([])
			}
		}
	}

	componentWillUnmount() {
		this.props.setIPDModalPRNRecordRecords([])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable striped hover enableOverlay data={this.props.hospitel.modalPRNRecord.recordTable} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDModalPRNRecordRecords,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalPRNRecordDispensing)
