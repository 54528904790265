import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { contextMenu } from "react-contexify"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAPGLGroupsByGL, setAPGLGroupsByItem, setSelected, setValue } from "~/redux/action"
import { SET_AP_MODAL_GENERAL_LEDGER_DISPLAY, SET_AP_SELECTABLE_ACCOUNTING_CODES, SET_AP_SELECTED_GROUP } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsPayableRightTabGL extends React.Component {
	constructor(props) {
		super(props)

		this.onContextMenu = this.onContextMenu.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onCOA = this.onCOA.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.AP.selectedInvoice.invoice) {
			if (_.isEmpty(this.props.AP.selectedInvoice.invoice.accountsPayableGLs)) {
				this.props.setAPGLGroupsByItem(this.props.AP.selectedInvoice.invoice.items)
			} else {
				this.props.setAPGLGroupsByGL(this.props.AP.selectedInvoice.invoice.accountsPayableGLs)
			}
		} else {
			this.props.setAPGLGroupsByItem([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.MENU.selectableAccountingCode.save)) {
					let newSelectableAccountingCodes = this.props.AP.selectableAccountingCodes.concat([this.props.SAC.selectedAccountingCode.accountingCode])
					this.props.setValue(SET_AP_SELECTABLE_ACCOUNTING_CODES, newSelectableAccountingCodes)
				}
			}
		}
		if (
			!_.isEqual(prevProps.AP.selectedInvoice, this.props.AP.selectedInvoice) ||
			!_.isEqual(prevProps.AP.accountsPayableActivities, this.props.AP.accountsPayableActivities) ||
			!_.isEqual(prevProps.AP.selectableAccountingCodes, this.props.AP.selectableAccountingCodes)
		) {
			if (this.props.AP.selectedInvoice.invoice) {
				if (_.isEmpty(this.props.AP.selectedInvoice.invoice.accountsPayableGLs)) {
					this.props.setAPGLGroupsByItem(this.props.AP.selectedInvoice.invoice.items)
				} else {
					this.props.setAPGLGroupsByGL(this.props.AP.selectedInvoice.invoice.accountsPayableGLs)
				}
			} else {
				this.props.setAPGLGroupsByItem([])
			}
		}
		if (!_.isEqual(prevProps.AP.generalLedgerGroupTable.filtered, this.props.AP.generalLedgerGroupTable.filtered)) {
			let index = this.props.AP.selectedGroup.group
				? this.props.AP.generalLedgerGroupTable.filtered.findIndex(
						(group) => typeof group[0] !== "object" && group[0].includes(this.props.AP.selectedGroup.group[0])
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AP_SELECTED_GROUP, index, this.props.AP.generalLedgerGroupTable.filtered[index])
			} else {
				this.props.setSelected(SET_AP_SELECTED_GROUP, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_AP_SELECTED_GROUP, -1, null)
	}

	onContextMenu(e) {
		if (!this.props.AP.selectedInvoice.invoice || this.props.AP.selectedInvoice.invoice.accountsPayableInspectorComplete) {
			return
		}
		contextMenu.show({
			id: ResourceAssistance.ContextMenu.accountsCode,
			event: e,
			props: {
				accountingCodes: this.props.AP.accountsPayableActivities.filter(
					(each) =>
						each.accountsPayableRCSelectable &&
						this.props.AP.selectedInvoice.invoice.items.some((item) =>
							item.category.activities.some((activity) => activity.chartOfAccounts.id === each.chartOfAccounts.id)
						) &&
						!this.props.AP.generalLedgerGroupTable.filtered.some(
							(eachR) => typeof eachR[0] === "object" && eachR[0].chartOfAccounts.id === each.chartOfAccounts.id
						)
				),
			},
		})
	}

	isDisplayEdit() {
		return this.props.AP.selectedInvoice.invoice && !this.props.AP.selectedInvoice.invoice.accountsPayableInspectorComplete
	}

	isDisplayDelete() {
		return this.props.AP.selectedInvoice.invoice && !this.props.AP.selectedInvoice.invoice.accountsPayableInspectorComplete
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_AP_SELECTED_GROUP, index, this.props.AP.generalLedgerGroupTable.filtered[index])
	}

	onCOA() {
		this.props.onModalDisplayAction(SET_AP_MODAL_GENERAL_LEDGER_DISPLAY, true)
	}

	onDelete(e, row, rIdx, cIdx) {
		let newSelectableAccountingCodes = Array.from(
			this.props.AP.selectableAccountingCodes.filter(
				(each) => each.chartOfAccounts.id !== this.props.AP.generalLedgerGroupTable.filtered[rIdx][0].chartOfAccounts.id
			)
		)
		this.props.setValue(SET_AP_SELECTABLE_ACCOUNTING_CODES, newSelectableAccountingCodes)
	}

	render() {
		let totalDebit = this.props.AP.generalLedgerGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[4]))
		}, Utils.BigNumber(0))
		let totalCredit = this.props.AP.generalLedgerGroupTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[5]))
		}, Utils.BigNumber(0))
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col onContextMenu={this.onContextMenu}>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[
							this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onCOA, this.onDelete]}
						data={this.props.AP.generalLedgerGroupTable}
						isClearHighlight={this.props.AP.selectedGroup.index === -1}
						highlightedRow={this.props.AP.selectedGroup.index}
						onClick={this.onSelectRow}
						extra={
							!_.isEmpty(this.props.AP.generalLedgerGroupTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
												color: totalCredit.eq(totalDebit) ? undefined : ResourceAssistance.CSS.Color.red,
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.debit)}>
												{Utils.formatNumWithComma(totalDebit.toFixed(2))}
											</Descriptions.Item>
											<Descriptions.Item label={translate(ResourceAssistance.Message.credit)}>
												{Utils.formatNumWithComma(totalCredit.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	SAC: state.menu.selectableAccountsCode,
	AP: state.accounting.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAPGLGroupsByItem,
			setSelected,
			setAPGLGroupsByGL,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsPayableRightTabGL))
