import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"
import { Property } from "./TypeModalNew"

class TypeModalNewPaymentFields extends React.Component {
	render() {
		return (
			<Fragment>
				{this.props.type.modal.displaySavingAccount && (
					<Row>
						<Col />
						<Col>
							<label>{translate(ResourceAssistance.Message.patientSaving)}</label>
						</Col>
						<Col>
							<ToggleCheckBox
								name={Property.SAVING_ACCOUNT}
								onClick={this.props.onPropertyChange}
								checked={this.props.isSavingAccount}
							/>
						</Col>
						<Col />
					</Row>
				)}
				{this.props.type.modal.displayBankAccount && (
					<Row>
						<Col />
						<Col>
							<label>{translate(ResourceAssistance.Message.bank)}</label>
						</Col>
						<Col>
							<ToggleCheckBox
								name={Property.BANK_ACCOUNT}
								onClick={this.props.onPropertyChange}
								checked={this.props.isBankAccount}
							/>
						</Col>
						<Col />
					</Row>
				)}
				{this.props.type.modal.displayCheckAccount && (
					<Row>
						<Col />
						<Col>
							<label>{translate(ResourceAssistance.Message.moneyCheck)}</label>
						</Col>
						<Col>
							<ToggleCheckBox
								name={Property.CHECK_ACCOUNT}
								onClick={this.props.onPropertyChange}
								checked={this.props.isCheckAccount}
							/>
						</Col>
						<Col />
					</Row>
				)}
				{this.props.type.modal.displayCreditCardAccount && (
					<Row>
						<Col />
						<Col>
							<label>{translate(ResourceAssistance.Message.creditCard)}</label>
						</Col>
						<Col>
							<ToggleCheckBox
								name={Property.CREDIT_CARD_ACCOUNT}
								onClick={this.props.onPropertyChange}
								checked={this.props.isCreditCardAccount}
							/>
						</Col>
						<Col />
					</Row>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(TypeModalNewPaymentFields)
