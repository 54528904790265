import { Card } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaTimes } from "react-icons/fa"
import { GiMedicines, GiStopwatch } from "react-icons/gi"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import {
	onModalDisplayAction,
	setHospitelOrders,
	setHospitelPatients,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_HOSPITEL_SELECTED_ORDER,
	SET_HOSPITEL_STOP_DISPLAY,
	SET_IPD_DOCTOR_INFO_DISPLAY,
	SET_IPD_PRN_RECORD_DISPLAY,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"

class HospitelRightOrderRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelRightOrderRow",
		}

		this.onSelectOrder = this.onSelectOrder.bind(this)
		this.onDoubleDoctorOrder = this.onDoubleDoctorOrder.bind(this)
		this.onChangeEndDate = this.onChangeEndDate.bind(this)
		this.onCancel = this.onCancel.bind(this)
		this.onPRNRecord = this.onPRNRecord.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedHospitel.hospitel) {
			this.props.setHospitelOrders(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders)
		} else {
			this.props.setHospitelOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.cancelOrder)) {
				this.cancelOrder()
			} else if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.deleteOrder)) {
				this.deleteOrder()
			}
		}
		if (
			!_.isEqual(prevProps.hospitel.selectedHospitel.hospitel, this.props.hospitel.selectedHospitel.hospitel) ||
			!_.isEqual(prevProps.hospitel.orderEndDateTime, this.props.hospitel.orderEndDateTime)
		) {
			if (this.props.hospitel.selectedHospitel.hospitel) {
				this.props.setHospitelOrders(this.props.hospitel.selectedHospitel.hospitel.admission.clinicalRecord.orders)
			} else {
				this.props.setHospitelOrders([])
			}
		}

		if (!_.isEqual(prevProps.hospitel.orderTable.filtered, this.props.hospitel.orderTable.filtered)) {
			let index = this.props.hospitel.selectedOrder.order
				? this.props.hospitel.orderTable.filtered.findIndex((doctorOrder) => doctorOrder.id === this.props.hospitel.selectedOrder.order.id)
				: -1
			if (index > -1) {
				this.props.setSelected(SET_HOSPITEL_SELECTED_ORDER, index, this.props.hospitel.orderTable.filtered[index])
			} else {
				this.props.setSelected(SET_HOSPITEL_SELECTED_ORDER, -1, null)
			}
		}
	}

	isDisplayWarning() {
		return true
	}

	isDisplayModifyEndDate() {
		return this.props.hospitel.selectedHospitel.hospitel && !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
	}

	isDisplayCancel() {
		return this.props.hospitel.selectedHospitel.hospitel && !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
	}

	isDisplayPRNRecord() {
		return this.props.hospitel.selectedHospitel.hospitel && this.props.hospitel.orderEndDateTime <= Utils.generateDate(0, 0, 0, 23, 59, 59).getTime()
	}

	isDisplayDelete() {
		return this.props.hospitel.selectedHospitel.hospitel && !this.props.hospitel.selectedHospitel.hospitel.dischargeSummary
	}

	onSelectOrder(order, index) {
		this.props.setSelected(SET_HOSPITEL_SELECTED_ORDER, index, this.props.hospitel.orderTable.filtered[index])
	}

	onDoubleDoctorOrder(row, idx) {
		this.props.onModalDisplayAction(SET_IPD_DOCTOR_INFO_DISPLAY, true)
	}

	cancelOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.cancelDoctorOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				orders: [
					{
						id: this.props.hospitel.selectedOrder.order.id,
						endDateTime:
							this.props.hospitel.selectedOrder.order.startDateTime > new Date().getTime()
								? Utils.generateDateFromLong(this.props.hospitel.selectedOrder.order.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
								: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
						cancelDateTime: new Date().getTime(),
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	deleteOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.deleteDoctorOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				orders: [
					{
						id: this.props.hospitel.selectedOrder.order.id,
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onChangeEndDate() {
		this.props.onModalDisplayAction(SET_HOSPITEL_STOP_DISPLAY, true)
	}

	onCancel(event, row, rIdx, cIdx) {
		let selectedOrder = this.props.hospitel.orderTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.cancelOrder)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.cancel)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>
					{Utils.formatDateTime(selectedOrder.startDateTime)} - {Utils.formatDateTime(selectedOrder.endDateTime)}
				</p>
				<p>{selectedOrder.description}</p>
				<p>{selectedOrder.notes}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onPRNRecord() {
		this.props.onModalDisplayAction(SET_IPD_PRN_RECORD_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedOrder = this.props.hospitel.orderTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.deleteOrder)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>
					{Utils.formatDateTime(selectedOrder.startDateTime)} - {Utils.formatDateTime(selectedOrder.endDateTime)}
				</p>
				<p>{selectedOrder.description}</p>
				<p>{selectedOrder.notes}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableOverlay
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							[ResourceAssistance.Button.variant.outlineInfo],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								ResourceAssistance.Button.variant.outlineWarning,
								ResourceAssistance.Button.variant.outlineRed,
								ResourceAssistance.Button.variant.outlinePrimary,
								ResourceAssistance.Button.variant.outlineRed,
							],
						]}
						btnMessages={[
							[this.props.intl.formatMessage({ id: ResourceAssistance.Message.contraindicationsAndWarnings })],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.changeEndDate }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.cancel }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.prnRecord }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
							],
						]}
						btnTypes={[[EBtnType.POPOVER], [], [], [], [], [], [], [], [EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT, EBtnType.DEFAULT]]}
						btnIcons={[
							[
								this.isDisplayWarning() ? (
									<IconFont type={"icon-ali-pmgcare-ico_yaokuguanli_caigoushenqingdan"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
								) : undefined,
							],
							[],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								this.isDisplayModifyEndDate() ? <GiStopwatch size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayCancel() ? <FaTimes size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayPRNRecord() ? <GiMedicines size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
							],
						]}
						onCheckedArray={[this.onChangeEndDate, this.onCancel, this.onPRNRecord, this.onDelete]}
						data={this.props.hospitel.orderTable}
						onClick={this.onSelectOrder}
						onDoubleClick={this.onDoubleDoctorOrder}
						isClearHighlight={this.props.hospitel.selectedOrder.index === -1}
						highlightedRow={this.props.hospitel.selectedOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelOrders,
			setSelected,
			setLoadingAction,
			setHospitelPatients,
			setWarningMsgAction,
			setWarningId,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelRightOrderRow))
