import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelPatients, setLoadingAction } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY,
	SET_PAGE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalDoctorNoteDetails from "./HospitelModalDoctorNoteDetails"

class HospitelModalDoctorNote extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalDoctorNote",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY, false)
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_ORDER_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			_.isEmpty(Utils.trim(this.props.hospitel.modalDoctorNote.objective)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDoctorNote.subjective)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDoctorNote.assessment)) &&
			_.isEmpty(Utils.trim(this.props.hospitel.modalDoctorNote.plan))
		)
	}

	onClose() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let startDate = new Date(this.props.hospitel.modalDoctorNote.noteDateTime)
		let endDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 59).getTime()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.saveOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				admissionId: this.props.hospitel.selectedHospitel.hospitel.admission.id,
				patientId: this.props.hospitel.selectedPatient.patient.id,
				userId: this.props.login.user.id,
				orders: [
					{
						id: this.props.hospitel.selectedSOAP.soap ? this.props.hospitel.selectedSOAP.soap.id : 0,
						startDateTime: this.props.hospitel.modalDoctorNote.noteDateTime,
						endDateTime: endDateTime,
						subjective: this.props.hospitel.modalDoctorNote.subjective,
						objective: this.props.hospitel.modalDoctorNote.objective,
						assessment: this.props.hospitel.modalDoctorNote.assessment,
						plan: this.props.hospitel.modalDoctorNote.plan,
					},
				],
			},
		}
		let callback = (res) => {
			let patients = this.props.hospitel.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
			patients.push(res.data.patients[0])
			this.props.setHospitelPatients(patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.soap)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalDoctorNoteDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isDoctorNoteDisplay,
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNote))
