import { Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setFORequests, setLoadingAction, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_FO_NEW_DISPLAY,
	SET_FO_SEARCH_DISPLAY,
	SET_FO_SELECTED_FINANCIAL_REQUEST,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class FinancialOperationsLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.financialOperations.complete)) {
				this.completeFinancialRequest()
			}
		}
	}

	completeFinancialRequest() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.financialOperations.completeFinancialRequest,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.FO.location.id,
				financialRequestId: this.props.FO.selectedFinancialPlan.financialPlan.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let requests = this.props.FO.financialPlanTable.original.filter((each) => each.id !== this.props.FO.selectedFinancialPlan.financialPlan.id)
			if (res.data.requests.length > 0) {
				requests.push(res.data.requests[0])
			}
			this.props.setFORequests(requests)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isCompleteDisabled() {
		return (
			!this.props.FO.selectedFinancialPlan.financialPlan ||
			!this.props.FO.selectedFinancialPlan.financialPlan.filePath ||
			this.props.FO.selectedFinancialPlan.financialPlan.complete
		)
	}

	onComplete(e, row, rIdx, cIdx) {
		let selectedFinancialPlan = this.props.FO.selectedFinancialPlan.financialPlan
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.financialOperations.complete)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.id)} span={1}>
						{selectedFinancialPlan.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedFinancialPlan.supplierReceivedDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.paymentType)} span={1}>
						{selectedFinancialPlan.paymentMethod}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.bankAccNum)} span={1}>
						{selectedFinancialPlan.bankAccount}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.bank)} span={1}>
						{selectedFinancialPlan.bankCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.branch)} span={1}>
						{selectedFinancialPlan.bankBranchCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.fee)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedFinancialPlan.fee).toFixed(2))}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.amount)} span={1}>
						{Utils.formatNumWithComma(Utils.BigNumber(selectedFinancialPlan.amount).toFixed(2))}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_FO_SEARCH_DISPLAY, true)
	}

	onNew() {
		this.props.setSelected(SET_FO_SELECTED_FINANCIAL_REQUEST, -1, null)
		this.props.onModalDisplayAction(SET_FO_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_FO_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.FinancialOperations.Approver, this.props.login.user.roles) ? (
					<>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.isCompleteDisabled()}
								onClick={this.onComplete}
							>
								<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.complete)}
							</Button>
						</Col>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
					</>
				) : (
					<Col />
				)}
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearch}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={!this.props.FO.selectedFinancialPlan.financialPlan || this.props.FO.selectedFinancialPlan.financialPlan.transactionComplete}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setFORequests,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FinancialOperationsLeftAction))
