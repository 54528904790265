import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue, setConfigGLReceivableActivities } from "~/redux/action"
import { SET_ADM_GL_RECEIVABLE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import ConfigGLModalReceivableDetails from "./ConfigGLModalReceivableDetails"
import { axios } from "~/axios"
import _ from "lodash"
import { Utils } from "~/utils/Utils"

class ConfigGLModalReceivable extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigGLModalReceivable",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_GL_RECEIVABLE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.AR.modalAR.selectedAR.AR ||
			(!this.props.AR.modalAR.isIncludeBalance &&
				!this.props.AR.modalAR.isIncludeDiscount &&
				!this.props.AR.modalAR.isIncludeTransactionFee &&
				!this.props.AR.modalAR.isIncludeWithholdingTax &&
				!this.props.AR.modalAR.isIncludeBalanceAdjustment) ||
			(!this.props.AR.modalAR.isIncludeIPD && !this.props.AR.modalAR.isIncludeOPD)
		) {
			return true
		}
		if (this.props.AR.selectedAR.AR) {
			let target = Object.assign({}, this.props.AR.selectedAR.AR, {
				...this.props.AR.selectedAR.AR,
				chartOfAccounts: {
					...this.props.AR.selectedAR.AR.chartOfAccounts,
					id: this.props.AR.modalAR.selectedAR.AR ? this.props.AR.modalAR.selectedAR.AR.id : 0,
				},
				includeBalance: this.props.AR.modalAR.isIncludeBalance,
				includeDiscount: this.props.AR.modalAR.isIncludeDiscount,
				includeTransactionFee: this.props.AR.modalAR.isIncludeTransactionFee,
				includeWithholdingTax: this.props.AR.modalAR.isIncludeWithholdingTax,
				includeBalanceAdjustment: this.props.AR.modalAR.isIncludeBalanceAdjustment,
				includeIPD: this.props.AR.modalAR.isIncludeIPD,
				includeOPD: this.props.AR.modalAR.isIncludeOPD,
				debit: this.props.AR.modalAR.isDebit,
				description: Utils.trim(
					this.props.AR.modalAR.description.concat(" ", this.props.AR.modalAR.isDescriptionIncludesDate ? ResourceAssistance.PROGRAM_DEFINED.date : "")
				),
				accountsReceiveSelectable: this.props.AR.modalAR.isAccountsReceiveSelectable,
			})
			return _.isEqual(target, this.props.AR.selectedAR.AR)
		}
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.generalLedger.saveAccountsReceivableActivity,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				activities: [
					{
						id: this.props.AR.selectedAR.AR ? this.props.AR.selectedAR.AR.id : 0,
						chartOfAccountsId: this.props.AR.modalAR.selectedAR.AR.id,
						includeBalance: this.props.AR.modalAR.isIncludeBalance,
						includeDiscount: this.props.AR.modalAR.isIncludeDiscount,
						includeTransactionFee: this.props.AR.modalAR.isIncludeTransactionFee,
						includeWithholdingTax: this.props.AR.modalAR.isIncludeWithholdingTax,
						includeBalanceAdjustment: this.props.AR.modalAR.isIncludeBalanceAdjustment,
						includeIPD: this.props.AR.modalAR.isIncludeIPD,
						includeOPD: this.props.AR.modalAR.isIncludeOPD,
						debit: this.props.AR.modalAR.isDebit,
						description: Utils.trim(
							this.props.AR.modalAR.description.concat(" ", this.props.AR.modalAR.isDescriptionIncludesDate ? ResourceAssistance.PROGRAM_DEFINED.date : "")
						),
						accountsReceiveSelectable: this.props.AR.modalAR.isAccountsReceiveSelectable,
					},
				],
			},
		}
		let callback = (res) => {
			this.props.setConfigGLReceivableActivities(res.data.activities)
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.md}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsReceivable)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ConfigGLModalReceivableDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isConfigGLReceivableDisplay,
	login: state.login,
	AR: state.admin.configGL.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setConfigGLReceivableActivities,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigGLModalReceivable))
