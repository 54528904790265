import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction } from "~/redux/action"
import ConfigAssessment from "./assessment/ConfigAssessment"
import ConfigEvaluation from "./evaluation/ConfigEvaluation"
import ConfigFocus from "./focus/ConfigFocus"
import ConfigIntervention from "./intervention/ConfigIntervention"
import { SET_PAGE_LOADING } from "~/redux/type"

class CustomDefaultHome extends React.Component {
	ECustomDefault = {
		ASSESSMENT: "CONFIG_ASSESSMENT",
		FOCUS: "CONFIG_FOCUS",
		INTERVENTION: "CONFIG_INTERVENTION",
		EVALUATION: "CONFIG_EVALUATION",
	}

	constructor(props) {
		super(props)

		this.state = {
			componentName: "CustomDefaultHome",
			tab: this.ECustomDefault.ASSESSMENT,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadTypes = this.loadTypes.bind(this)
		this.deleteCustomDefault = this.deleteCustomDefault.bind(this)
	}

	loadTypes(func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}, isExcludeInActive = true) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.customDefault.getCategories,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: isExcludeInActive },
		}
		let callback = (res) => {
			func(res.data.types)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	deleteCustomDefault(id, func = () => {}) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.customDefault.deleteCustomDefault,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: id,
			},
		}
		let callback = (res) => {
			func(res)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
							<Tab eventKey={this.ECustomDefault.ASSESSMENT} title={translate(ResourceAssistance.Message.assessment)} name={this.ECustomDefault.ASSESSMENT}>
								{this.state.tab === this.ECustomDefault.ASSESSMENT && (
									<ConfigAssessment loadTypes={this.loadTypes} deleteCustomDefault={this.deleteCustomDefault} />
								)}
							</Tab>
							<Tab eventKey={this.ECustomDefault.FOCUS} title={translate(ResourceAssistance.Message.focus)} name={this.ECustomDefault.FOCUS}>
								{this.state.tab === this.ECustomDefault.FOCUS && <ConfigFocus loadTypes={this.loadTypes} deleteCustomDefault={this.deleteCustomDefault} />}
							</Tab>
							<Tab
								eventKey={this.ECustomDefault.INTERVENTION}
								title={translate(ResourceAssistance.Hospitel.intervention)}
								name={this.ECustomDefault.INTERVENTION}
							>
								{this.state.tab === this.ECustomDefault.INTERVENTION && (
									<ConfigIntervention loadTypes={this.loadTypes} deleteCustomDefault={this.deleteCustomDefault} />
								)}
							</Tab>
							<Tab eventKey={this.ECustomDefault.EVALUATION} title={translate(ResourceAssistance.Hospitel.evaluation)} name={this.ECustomDefault.EVALUATION}>
								{this.state.tab === this.ECustomDefault.EVALUATION && (
									<ConfigEvaluation loadTypes={this.loadTypes} deleteCustomDefault={this.deleteCustomDefault} />
								)}
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomDefaultHome)
