import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DebtRightInfo from "./DebtRightInfo"
import DebtRightInvoice from "./DebtRightInvoice"

class DebtRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "DebtRight",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={"full-size full-flex border"}>
				<DebtRightInfo />
				<DebtRightInvoice />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DebtRight)
