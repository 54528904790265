import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setContractBillingBillings, setSelected } from "~/redux/action"
import { SET_CONTRACT_BILLING_SELECTED_BILLING } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ContractBillingLeftBilling extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ContractBillingLeftBilling",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.contract.billingTable.filtered, this.props.contract.billingTable.filtered)) {
			let index = this.props.contract.selectedBilling.billing
				? this.props.contract.billingTable.filtered.findIndex((billing) => billing.id === this.props.contract.selectedBilling.billing.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_BILLING, index, this.props.contract.billingTable.filtered[index])
			} else {
				this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_BILLING, -1, null)
			}
		}

		if (
			!_.isEqual(prevProps.contract.filterContractBillingId, this.props.contract.filterContractBillingId) ||
			!_.isEqual(prevProps.contract.filterHealthCarePlan, this.props.contract.filterHealthCarePlan)
		) {
			this.props.setContractBillingBillings(this.props.contract.billingTable.original)
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_BILLING, index, this.props.contract.billingTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.contract.billingTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.contract.selectedBilling.index === -1}
						highlightedRow={this.props.contract.selectedBilling.index}
					/>
					{this.props.contract.billingTable.isLoading && (
						<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setContractBillingBillings,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingLeftBilling))
