import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_ADM_COA_EXPENSES_ADD_DISPLAY, SET_ADM_COA_EXPENSES_ORIGINAL, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ConfigCOAExpensesModalAddDetails from "./ConfigCOAExpensesModalAddDetails"

class ConfigCOAExpensesModalAdd extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigCOAExpensesModalAdd",
		}

		this.firstLevelRef = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_COA_EXPENSES_ADD_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (this.props.expense.selectedAccount.account) {
			let selectedAccount = this.props.expense.selectedAccount.account
			let target = Object.assign({}, selectedAccount, {
				code: Utils.trim(this.props.expense.modalAdd.code),
				displayName: Utils.trim(this.props.expense.modalAdd.name),
				description: Utils.trim(this.props.expense.modalAdd.description),
				level: this.props.expense.modalAdd.selectedExpense.expense
					? String(String(this.props.expense.modalAdd.selectedExpense.index).split("-").length + 1)
					: "1",
				subLevel: this.props.expense.modalAdd.isSubLevel,
			})
			return (
				this.firstLevelRef.current &&
				Utils.isListsEqual(
					selectedAccount.linkedAccountingCharts,
					this.firstLevelRef.current.state.PIs.map((each) => {
						return {
							accountingChart: {
								id: each.selectedPI.PI ? each.selectedPI.PI.id : undefined,
							},
							transDescr: Utils.trim(
								each.purchaseInvoiceTransDescr.concat(
									" ",
									each.isPurchaseInvoiceTransDescrIncludeSupplier ? ResourceAssistance.PROGRAM_DEFINED.supplier : "",
									" ",
									each.isPurchaseInvoiceTransDescrIncludeDate ? ResourceAssistance.PROGRAM_DEFINED.date : ""
								)
							),
						}
					}),
					[["accountingChart", "transDescr"], ["id"]]
				) &&
				((_.isEqual(selectedAccount, target) &&
					selectedAccount.parent &&
					this.props.expense.modalAdd.selectedExpense.expense &&
					selectedAccount.parent.id === this.props.expense.modalAdd.selectedExpense.expense.id) ||
					(_.isEqual(selectedAccount, target) && !selectedAccount.parent && !this.props.expense.modalAdd.selectedExpense.expense))
			)
		}
		return false
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.chartOfAccounts.saveExpenses,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				accounts: [
					{
						id: this.props.expense.selectedAccount.account ? this.props.expense.selectedAccount.account.id : 0,
						parentId: this.props.expense.modalAdd.selectedExpense.expense ? this.props.expense.modalAdd.selectedExpense.expense.id : 0,
						code: Utils.trim(this.props.expense.modalAdd.code),
						fullCode: this.props.expense.modalAdd.selectedExpense.expense
							? this.props.expense.modalAdd.selectedExpense.expense.fullCode + "" + Utils.trim(this.props.expense.modalAdd.code)
							: ResourceAssistance.CONSTANT.GL_EXPENSES_CODE + Utils.trim(this.props.expense.modalAdd.code),
						name: Utils.trim(this.props.expense.modalAdd.name),
						description: Utils.trim(this.props.expense.modalAdd.description),
						links: this.firstLevelRef.current.state.PIs.filter((each) => each.selectedPI.PI).map((each) => {
							return {
								accountingChartId: each.selectedPI.PI.id,
								transDescr: Utils.trim(
									each.purchaseInvoiceTransDescr.concat(
										" ",
										each.isPurchaseInvoiceTransDescrIncludeSupplier ? ResourceAssistance.PROGRAM_DEFINED.supplier : "",
										" ",
										each.isPurchaseInvoiceTransDescrIncludeDate ? ResourceAssistance.PROGRAM_DEFINED.date : ""
									)
								),
							}
						}),
						level: this.props.expense.modalAdd.selectedExpense.expense ? String(this.props.expense.modalAdd.selectedExpense.index).split("-").length + 1 : 1,
						subLevel: this.props.expense.modalAdd.isSubLevel,
					},
				],
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.accounts)) {
				let expenses = this.props.expense.originalExpenses.filter((each) => each.id !== res.data.accounts[0].id)
				expenses.push(res.data.accounts[0])
				this.props.setValue(SET_ADM_COA_EXPENSES_ORIGINAL, expenses)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.expenses)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ConfigCOAExpensesModalAddDetails firstLevelRef={this.firstLevelRef} onRenderParentCallback={this.onRenderParentCallback} />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isConfigExpenseAddDisplay,
	login: state.login,
	expense: state.admin.configCOA.expense,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigCOAExpensesModalAdd))
