import React from "react"
import img from "~/img/404.jpg"

class NotFoundPage extends React.Component {
	render() {
		return <img style={img_style} src={img} alt="" />
	}
}

const img_style = {
	display: "block",
	marginLeft: "auto",
	marginRight: "auto",
	width: "70%",
}

export default NotFoundPage
