const Remark = {
	Remark: {
		reactionToLight: "remarkReactionToLight",
		primaryHealthCarePlan: "remarkPrimaryHealthCarePlan",
		configureAtCategoryBank: "remarkConfigureAtCategoryBank",
		theReceiptWillBeValidOnceThePaymentHasBeenReceived: "remarkTheReceiptWillBeValidOnceThePaymentHasBeenReceived",
	},
}

export default Remark
