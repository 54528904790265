import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setIPDGlucoses } from "~/redux/action"
import IPDRightDTXTable from "./IPDRightDTXTable"
import IPDRightDTXChart from "./IPDRightDTXChart"

class IPDRightDTX extends React.Component {
	componentDidMount() {
		if (this.props.ipd.selectedHospitel.hospitel) {
			this.props.setIPDGlucoses(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.glucoses)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel) ||
			!_.isEqual(prevProps.ipd.orderEndDateTime, this.props.ipd.orderEndDateTime)
		) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.props.setIPDGlucoses(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.glucoses)
			} else {
				this.props.setIPDGlucoses([])
			}
		}
	}

	render() {
		return this.props.graphic ? <IPDRightDTXChart /> : <IPDRightDTXTable />
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDGlucoses,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightDTX))
