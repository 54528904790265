import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { selectPRAction, selectPrItemAction } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PRLeftFourthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRLeftFourthRow",
		}
		this.myRef = React.createRef()
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.pr.selectedPR.index === this.props.pr.selectedPR.index &&
			prevProps.pr.selectedPR.index !== -1 &&
			this.props.pr.selectedPR.index !== -1 &&
			!prevProps.pr.selectedPR.pr.approverApproved &&
			this.props.pr.selectedPR.pr.approverApproved
		) {
			this.props.selectPRAction(-1, null)
		}

		if (!_.isEqual(prevProps.pr.prs, this.props.pr.prs)) {
			this.props.loadLocations()
		}

		if (!_.isEqual(prevProps.pr.filtered, this.props.pr.filtered)) {
			let index = this.props.pr.selectedPR.pr
				? this.props.pr.filtered.findIndex((pr) => pr.id === this.props.pr.selectedPR.pr.id)
				: -1
			if (index !== -1) {
				this.props.selectPRAction(index, this.props.pr.filtered[index])
			} else {
				this.props.selectPRAction(-1, null)
			}
		}
	}

	selectRow(item, index) {
		this.props.selectPRAction(index, this.props.pr.filtered[index])
		this.props.selectPrItemAction(-1, null)
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						refs={this.myRef}
						enableHighlight
						striped
						hover
						data={this.props.prTableData}
						onClick={this.selectRow}
						isClearHighlight={this.props.selectedPR.index === -1}
						highlightedRow={this.props.selectedPR.index}
					/>
					{this.props.prTableData.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	prTableData: state.purchaseRequest.prTableData,
	pr: state.purchaseRequest,
	selectedPR: state.purchaseRequest.selectedPR,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ selectPRAction, selectPrItemAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PRLeftFourthRow)
