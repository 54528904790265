import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "../../../Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"

class FinancialOperationsFactory extends PrintableDataFactory {
	static generateExcelData = (financialPlan) => {
		let headers = [
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.psId }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.supplierName }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.amount }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.withholdingTax }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.fee }),
			this.getIntl().formatMessage({ id: ResourceAssistance.Message.remark }),
		]
		let excel = {
			filename: ResourceAssistance.Enum.financePaymentDocumentCode + "_" + financialPlan.id,
			sheets: [
				{
					name: financialPlan.id,
					headers: headers,
					data: financialPlan.paymentPlans
						.sort((a, b) => Utils.sort(a.id, b.id))
						.map((pp) => {
							let body = [
								pp.id,
								pp.paymentSlip.supplierName,
								Utils.formatNumWithComma(Utils.BigNumber(pp.amount).minus(pp.withholdingTaxAmount).toFixed(2)),
								Utils.formatNumWithComma(Utils.BigNumber(pp.withholdingTaxAmount).toFixed(2)),
								Utils.formatNumWithComma(Utils.BigNumber(pp.fee).toFixed(2)),
								pp.remark,
							]
							return _.zipObject(Object.keys(Object.assign({}, headers)), body)
						}),
				},
			],
		}
		return excel
	}
}

export { FinancialOperationsFactory }
