import React from "react"
import { Col, Nav, Row, Tab } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import PaymentSlipRightTabGL from "./PaymentSlipRightTabGL"
import PaymentSlipRightTabInvoice from "./PaymentSlipRightTabInvoice"

class PaymentSlipRightTab extends React.Component {
	EPaymentSlip = {
		INVOICE: "PAYMENT_SLIP_INVOICE",
		GL: "PAYMENT_SLIP_GL",
	}
	constructor(props) {
		super(props)

		this.state = {
			tab: this.EPaymentSlip.GL,
		}
		this.onTabClick = this.onTabClick.bind(this)
	}

	onTabClick(event) {
		this.setState({
			tab: event,
		})
	}

	render() {
		return (
			<Tab.Container activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false}>
				<Row>
					<Col>
						<Nav variant="tabs">
							<Nav.Item>
								<Nav.Link eventKey={this.EPaymentSlip.GL}>
									{translate(ResourceAssistance.Message.generalLedger)}
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link eventKey={this.EPaymentSlip.INVOICE}>
									{translate(ResourceAssistance.Message.invoice)}
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
				</Row>
				<Row className="full-size">
					<Col>
						<Tab.Content>
							<Tab.Pane eventKey={this.EPaymentSlip.GL}>
								{this.state.tab === this.EPaymentSlip.GL && <PaymentSlipRightTabGL />}
							</Tab.Pane>
							<Tab.Pane eventKey={this.EPaymentSlip.INVOICE}>
								{this.state.tab === this.EPaymentSlip.INVOICE && <PaymentSlipRightTabInvoice />}
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentSlipRightTab)
