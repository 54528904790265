import _ from "lodash"
import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_ADM_GL_CHART_OF_ACCOUNTS, SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ConfigGeneralLedgerAR from "./accounts_receivable/ConfigGeneralLedgerAR"
import ConfigGeneralLedgerAP from "./accounts_payable/ConfigGeneralLedgerAP"

class ConfigGeneralLedger extends React.Component {
	EConfigGL = {
		ACCOUNTS_RECEIVABLE: "ACCOUNTS_RECEIVABLE",
		ACCOUNTS_PAYABLE: "ACCOUNTS_PAYABLE",
	}

	constructor(props) {
		super(props)

		this.state = {
			componentName: "ConfigGeneralLedger",
			tab: this.EConfigGL.ACCOUNTS_RECEIVABLE,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadData = this.loadData.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.configGL.chartOfAccounts)) {
			this.loadChartOfAccounts()
		}
	}

	loadData(url, func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	loadChartOfAccounts() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.chartOfAccounts.getChartOfAccounts,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(
				SET_ADM_GL_CHART_OF_ACCOUNTS,
				Utils.getChartOfAccountsByGroup(Utils.groupBy(res.data.accounts, "coreCodeName"))
			)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_ADM_GL_CHART_OF_ACCOUNTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false} transition={false} fill>
							<Tab
								eventKey={this.EConfigGL.ACCOUNTS_RECEIVABLE}
								title={translate(ResourceAssistance.Message.accountsReceivable)}
								name={this.EConfigGL.ACCOUNTS_RECEIVABLE}
							>
								{this.state.tab === this.EConfigGL.ACCOUNTS_RECEIVABLE && (
									<ConfigGeneralLedgerAR loadData={this.loadData} />
								)}
							</Tab>
							<Tab
								eventKey={this.EConfigGL.ACCOUNTS_PAYABLE}
								title={translate(ResourceAssistance.Message.accountsPayable)}
								name={this.EConfigGL.ACCOUNTS_PAYABLE}
							>
								{this.state.tab === this.EConfigGL.ACCOUNTS_PAYABLE && (
									<ConfigGeneralLedgerAP loadData={this.loadData} />
								)}
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	configGL: state.admin.configGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigGeneralLedger)
