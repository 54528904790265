import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { SET_PV_INVOICES, SET_PV_MODAL_NEW_PURCHASES, SET_PV_PURCHASES } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setPaymentVouchers = (invoices) => {
	return (dispatch, getState) => {
		let filtered = invoices
		// if (_.includes(getState().accounting.paymentSlip.filters, ResourceAssistance.Message.incomplete)) {
		// 	filtered = filtered.filter((request) => !request.complete)
		// }
		filtered = filtered
			// .filter((request) => request.id.includes(getState().accounting.paymentSlip.filterPaymentSlipId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((invoice) => {
			return [Utils.formatDate(invoice.creationDateTime), invoice.id]
		})

		let rowColor = filtered.map((invoice) => {
			let color = ""
			if (invoice.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [!_.isEmpty(color), color]
		})

		dispatch({
			type: SET_PV_INVOICES,
			payload: {
				original: invoices,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setPVPurchases = (purchases) => {
	return (dispatch, getState) => {
		let filtered = purchases.sort((a, b) => Utils.sort(a.accountingCode, b.accountingCode))
		let body = filtered.map((purchase) => {
			return {
				color: "",
				values: [
					purchase.accountingCode,
					purchase.accountingChartName,
					purchase.accountingTransDescr,
					purchase.withholdingTaxCode + "-" + purchase.withholdingTaxName,
					purchase.withholdingTax,
					purchase.withholdingTaxSubLevel,
					purchase.withholdingTaxSubLevelOther,
					purchase.withholdingTaxAccountingCode,
					purchase.withholdingTaxAccountingChartName,
					purchase.tax,
					Utils.formatNumWithComma(Utils.BigNumber(purchase.taxedTotalPrice).toFixed(2)),
					[true, true],
				],
			}
		})
		let rowColor = body.map((each) => {
			return [!_.isEmpty(each.color), each.color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PV_PURCHASES,
			payload: {
				original: purchases,
				filtered: filtered,
				body: body.map((each) => each.values),
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setPVModalNewPurchases = (purchases) => {
	return (dispatch, getState) => {
		let filtered = purchases

		let body = filtered.map((purchase) => {
			return [
				purchase.accountingCode,
				purchase.accountingChartName,
				purchase.accountingTransDescr,
				purchase.withholdingTaxCode + "-" + purchase.withholdingTaxName,
				purchase.withholdingTax,
				purchase.withholdingTaxSubLevel,
				purchase.withholdingTaxSubLevelOther,
				purchase.withholdingTaxAccountingCode,
				purchase.withholdingTaxAccountingChartName,
				purchase.tax,
				Utils.formatNumWithComma(Utils.BigNumber(purchase.taxedTotalPrice).toFixed(2)),
				[true],
			]
		})

		let rowColor = filtered.map((request) => {
			let color = ""
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_PV_MODAL_NEW_PURCHASES,
			payload: {
				original: purchases,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
