import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME,
	SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME,
	SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME,
	SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME,
} from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class OperatingRoomModalIntraAneRecordTimeDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onAnesthesiaDateRange = this.onAnesthesiaDateRange.bind(this)
		this.onOperativeDateRange = this.onOperativeDateRange.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME, op.anesthesiaStartDateTime)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME, op.anesthesiaEndDateTime)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME, op.operativeStartDateTime)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME, op.operativeEndDateTime)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME, undefined)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME, undefined)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME, undefined)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME, undefined)
	}

	render() {
		return (
			<>
				<Row>
					<Col>
						<AntDatePicker
							displayRange
							size={"large"}
							format={"DD-MM-YYYY  HH:mm"}
							showTime={{ format: "HH:mm" }}
							placeholder={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.anesthesiaStartDateTime }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.anesthesiaEndDateTime }),
							]}
							values={[
								this.props.operatingRoom.modalIntrAneRecord.anesthesiaStartDateTime
									? moment(this.props.operatingRoom.modalIntrAneRecord.anesthesiaStartDateTime)
									: undefined,
								this.props.operatingRoom.modalIntrAneRecord.anesthesiaEndDateTime
									? moment(this.props.operatingRoom.modalIntrAneRecord.anesthesiaEndDateTime)
									: undefined,
							]}
							onChange={this.onAnesthesiaDateRange}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col>
						<AntDatePicker
							displayRange
							size={"large"}
							format={"DD-MM-YYYY  HH:mm"}
							showTime={{ format: "HH:mm" }}
							placeholder={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.operativeStartDateTime }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.operativeEndDateTime }),
							]}
							values={[
								this.props.operatingRoom.modalIntrAneRecord.operativeStartDateTime
									? moment(this.props.operatingRoom.modalIntrAneRecord.operativeStartDateTime)
									: undefined,
								this.props.operatingRoom.modalIntrAneRecord.operativeEndDateTime
									? moment(this.props.operatingRoom.modalIntrAneRecord.operativeEndDateTime)
									: undefined,
							]}
							onChange={this.onOperativeDateRange}
						/>
					</Col>
				</Row>
			</>
		)
	}

	onAnesthesiaDateRange(v) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_START_DATETIME, v ? v[0].milliseconds(0).valueOf() : undefined)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_ANESTHESIA_END_DATETIME, v ? v[1].milliseconds(0).valueOf() : undefined)
	}
	onOperativeDateRange(v) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_START_DATETIME, v ? v[0].milliseconds(0).valueOf() : undefined)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_RECORD_OPERATIVE_END_DATETIME, v ? v[1].milliseconds(0).valueOf() : undefined)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneRecordTimeDetails))
