import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import FOModalNewSearchFilter from "./FOModalNewSearchFilter"
import _ from "lodash"
import {
	SET_FO_MODAL_NEW_BANKS,
	SET_FO_MODAL_NEW_BANK_LOADING,
	SET_FO_MODAL_NEW_SERVICE_TYPES,
	SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING,
	SET_FO_MODAL_NEW_PAYMENT_TYPES,
	SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING,
} from "~/redux/type"
import { axios } from "~/axios"
import FOModalNewPaymentPlan from "./FOModalNewPaymentPlan"
import FOModalNewPaymentPlanAction from "./FOModalNewPaymentPlanAction"
import FOModalNewPendingPaymentPlan from "./FOModalNewPendingPaymentPlan"
import FOModalNewFields from "./FOModalNewFields"

class FOModalNewDetails extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.FO.modalNew.paymentTypes)) {
			this.loadPaymentTypes()
		}
		if (_.isEmpty(this.props.FO.modalNew.banks)) {
			this.loadBanks()
		}
		if (_.isEmpty(this.props.FO.modalNew.iPays)) {
			this.loadServiceTypes()
		}
	}

	loadPaymentTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.financialOperations.getPayments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			let types = res.data.uts.filter((type) => !type.savingAccount)
			this.props.setValue(SET_FO_MODAL_NEW_PAYMENT_TYPES, types)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_PAYMENT_TYPE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadBanks() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.financialOperations.getBanks,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_FO_MODAL_NEW_BANKS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_BANK_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_BANK_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_BANK_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadServiceTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.financialOperations.getServiceTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_FO_MODAL_NEW_SERVICE_TYPES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_FO_MODAL_NEW_SERVICE_TYPES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={"full-size"}>
					<Col>
						<Container fluid className={ResourceAssistance.CSS.fullFlex} style={{ padding: 0 }}>
							<FOModalNewSearchFilter />
							<FOModalNewPaymentPlan />
							<FOModalNewPaymentPlanAction />
							<FOModalNewPendingPaymentPlan />
						</Container>
					</Col>
					<Col md={2}>
						<Container fluid className={ResourceAssistance.CSS.fullFlex} style={{ padding: 0 }}>
							<FOModalNewFields />
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(FOModalNewDetails)
