import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_FORM_MODAL_NEW_FORM_NAME,
	SET_ADMIN_FORM_FORMS,
	SET_ADMIN_FORM_DISPLAY_ALL_IND,
	SET_ADMIN_FORM_SELECTED_FORM,
	SET_ADMIN_FORM_MODAL_NEW_FORM_DESC,
	SET_ADMIN_FORM_MODAL_NEW_FORM_ACTIVE,
} from "../../type/type/ADMIN"

export const setAdmFForms = (forms) => {
	return (dispatch, getState) => {
		let filtered = forms
			.filter((each) => {
				if (getState().admin.itemConfig.form.displayInactive) {
					return each.displayName.toLowerCase().includes(getState().admin.itemConfig.form.searchName.toLowerCase())
				} else {
					return (
						each.active &&
						each.displayName.toLowerCase().includes(getState().admin.itemConfig.form.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.itemConfig.form.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.displayName, each.description, each.createdBy.displayName, Utils.formatDate(each.creationDateTime)]
		})

		dispatch({
			type: SET_ADMIN_FORM_FORMS,
			payload: {
				original: forms,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmFDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_FORM_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmFSelectedForm = (index, form) => {
	return {
		type: SET_ADMIN_FORM_SELECTED_FORM,
		payload: {
			index: index,
			form: form,
		},
	}
}

export const setAdmFModalNFName = (name) => {
	return {
		type: SET_ADMIN_FORM_MODAL_NEW_FORM_NAME,
		payload: {
			name: name,
		},
	}
}

export const setAdmFModalNFDescription = (desc) => {
	return {
		type: SET_ADMIN_FORM_MODAL_NEW_FORM_DESC,
		payload: {
			desc: desc,
		},
	}
}

export const setAdmFModalNFActive = (isActive) => {
	return {
		type: SET_ADMIN_FORM_MODAL_NEW_FORM_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}
