import { Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPaymentVouchers, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PV_NEW_DISPLAY,
	SET_PV_SEARCH_DISPLAY,
	SET_PV_SELECTED_PURCHASE,
	SET_PV_SELECTED_PV,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class PaymentVoucherLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentVoucher.complete)) {
				this.completePaymentVoucher()
			}
		}
	}

	completePaymentVoucher() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentVoucher.completePaymentVoucher,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PV.location.id,
				paymentVoucherId: this.props.PV.selectedPV.PV.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let invoices = this.props.PV.paymentVoucherTable.original.filter((each) => each.id !== this.props.PV.selectedPV.PV.id)
			if (res.data.invoices.length > 0) {
				invoices.push(res.data.invoices[0])
			}
			this.props.setPaymentVouchers(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isCompleteDisabled() {
		return !this.props.PV.selectedPV.PV || this.props.PV.selectedPV.PV.complete
	}
	isEditDisabled() {
		return !this.props.PV.selectedPV.PV || this.props.PV.selectedPV.PV.complete
	}

	onComplete(e, row, rIdx, cIdx) {
		let selectedPV = this.props.PV.selectedPV.PV
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.paymentVoucher.complete)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dueDate)} span={1}>
						{Utils.formatDate(selectedPV.paymentTermDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.payment)} span={1}>
						{selectedPV.payment}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierCode)} span={1}>
						{selectedPV.supplierCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplierName)} span={1}>
						{selectedPV.supplierName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPV.remark}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_PV_SEARCH_DISPLAY, true)
	}

	onNew() {
		this.props.setSelected(SET_PV_SELECTED_PV, -1, null)
		this.props.setSelected(SET_PV_SELECTED_PURCHASE, -1, null)
		this.props.onModalDisplayAction(SET_PV_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.setSelected(SET_PV_SELECTED_PURCHASE, -1, null)
		this.props.onModalDisplayAction(SET_PV_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PaymentVoucher.Approver, this.props.login.user.roles) ? (
					<>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.isCompleteDisabled()}
								onClick={this.onComplete}
							>
								<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.complete)}
							</Button>
						</Col>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
					</>
				) : (
					<Col />
				)}
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearch}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.isEditDisabled()}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setPaymentVouchers,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentVoucherLeftAction))
