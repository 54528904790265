import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA,
	SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class OperatingRoomModalEditPreOperativeCheckListDetail extends React.Component {
	constructor(props) {
		super(props)

		this.onIdentifyPatient = this.onIdentifyPatient.bind(this)
		this.onIdentifyPatientRemark = this.onIdentifyPatientRemark.bind(this)
		this.onMarkingSiteForSurgery = this.onMarkingSiteForSurgery.bind(this)
		this.onMarkingSiteForSurgeryRemark = this.onMarkingSiteForSurgeryRemark.bind(this)
		this.onLetterConsentSurgery = this.onLetterConsentSurgery.bind(this)
		this.onLetterConsentSurgeryRemark = this.onLetterConsentSurgeryRemark.bind(this)
		this.onLetterConsentAnesthesia = this.onLetterConsentAnesthesia.bind(this)
		this.onLetterConsentAnesthesiaRemark = this.onLetterConsentAnesthesiaRemark.bind(this)
		this.onLabResult = this.onLabResult.bind(this)
		this.onLabResultRemark = this.onLabResultRemark.bind(this)
		this.onImagingResult = this.onImagingResult.bind(this)
		this.onImagingResultRemark = this.onImagingResultRemark.bind(this)
		this.onEKGResult = this.onEKGResult.bind(this)
		this.onEKGResultRemark = this.onEKGResultRemark.bind(this)
		this.onCleanPatientBody = this.onCleanPatientBody.bind(this)
		this.onCleanPatientBodyRemark = this.onCleanPatientBodyRemark.bind(this)
		this.onUnisonEnema = this.onUnisonEnema.bind(this)
		this.onUnisonEnemaRemark = this.onUnisonEnemaRemark.bind(this)
		this.onNPOTime = this.onNPOTime.bind(this)
		this.onNPOTimeRemark = this.onNPOTimeRemark.bind(this)
		this.onRemoveDentures = this.onRemoveDentures.bind(this)
		this.onRemoveDenturesRemark = this.onRemoveDenturesRemark.bind(this)
		this.onEyeglassesRemoved = this.onEyeglassesRemoved.bind(this)
		this.onEyeglassesRemovedRemark = this.onEyeglassesRemovedRemark.bind(this)
		this.onJewelryRemoved = this.onJewelryRemoved.bind(this)
		this.onJewelryRemovedRemark = this.onJewelryRemovedRemark.bind(this)
		this.onBodyPiercingRemoved = this.onBodyPiercingRemoved.bind(this)
		this.onBodyPiercingRemovedRemark = this.onBodyPiercingRemovedRemark.bind(this)
		this.onChangePatientClothes = this.onChangePatientClothes.bind(this)
		this.onChangePatientClothesRemark = this.onChangePatientClothesRemark.bind(this)
		this.onPatientUrinate = this.onPatientUrinate.bind(this)
		this.onPatientUrinateRemark = this.onPatientUrinateRemark.bind(this)
		this.onBloodRequested = this.onBloodRequested.bind(this)
		this.onBloodRequestedRemark = this.onBloodRequestedRemark.bind(this)
		this.onPreEducation = this.onPreEducation.bind(this)
		this.onPreEducationRemark = this.onPreEducationRemark.bind(this)
		this.onOtherMedicalDevice = this.onOtherMedicalDevice.bind(this)
		this.onOtherMedicalDeviceRemark = this.onOtherMedicalDeviceRemark.bind(this)
		this.onToolSpecialEquipment = this.onToolSpecialEquipment.bind(this)
		this.onToolSpecialEquipmentRemark = this.onToolSpecialEquipmentRemark.bind(this)
		this.onImplant = this.onImplant.bind(this)
		this.onImplantRemark = this.onImplantRemark.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let preOPCheckList = _.isEmpty(this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists[0]
			if (preOPCheckList) {
				let [isIdentifyPatient, identifyPatientRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.identifyPatient)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT, isIdentifyPatient)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK, identifyPatientRemark)

				let [isMarkingSurgerySite, markingSurgerySiteRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.markingSurgerySite)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY, isMarkingSurgerySite)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK, markingSurgerySiteRemark)

				let [isConsentSurgery, consentSurgeryRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.consentSurgery)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY, isConsentSurgery)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK, consentSurgeryRemark)

				let [isConsentAnesthesia, consentAnesthesiaRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.consentAnesthesia)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA, isConsentAnesthesia)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK, consentAnesthesiaRemark)

				let [isLabResult, labResultRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.labResult)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT, isLabResult)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK, labResultRemark)

				let [isImagingResult, imagingResultRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.imagingResult)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT, isImagingResult)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK, imagingResultRemark)

				let [isEkgResult, ekgResultRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.ekgResult)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT, isEkgResult)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK, ekgResultRemark)

				let [isCleanPatientBody, cleanPatientBodyRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.cleanPatientBody)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY, isCleanPatientBody)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK, cleanPatientBodyRemark)

				let [isUnisonEnema, unisonEnemaRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.unisonEnema)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA, isUnisonEnema)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK, unisonEnemaRemark)

				let [isNpoTime, npoTimeRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.npoTime)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME, isNpoTime)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK, npoTimeRemark)

				let [isRemoveDentures, removeDenturesRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeDentures)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES, isRemoveDentures)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK, removeDenturesRemark)

				let [isRemoveEyeglasses, removeEyeglassesRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeEyeglasses)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED, isRemoveEyeglasses)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK, removeEyeglassesRemark)

				let [removeJewelry, removeJewelryRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeJewelry)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED, removeJewelry)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK, removeJewelryRemark)

				let [removeBodyPiercing, removeBodyPiercingRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.removeBodyPiercing)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED, removeBodyPiercing)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK, removeBodyPiercingRemark)

				let [changePatientClothes, changePatientClothesRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.changePatientClothes)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES, changePatientClothes)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK, changePatientClothesRemark)

				let [urinateBeforeOR, urinateBeforeORRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.urinateBeforeOR)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE, urinateBeforeOR)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK, urinateBeforeORRemark)

				let [bloodRequested, bloodRequestedRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.bloodRequested)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED, bloodRequested)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK, bloodRequestedRemark)

				let [preEducation, preEducationRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.preEducation)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION, preEducation)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK, preEducationRemark)

				let [otherMedicalDevice, otherMedicalDeviceRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.otherMedicalDevice)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE, otherMedicalDevice)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK, otherMedicalDeviceRemark)

				let [toolSpecialEquipment, toolSpecialEquipmentRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.toolSpecialEquipment)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT, toolSpecialEquipment)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK, toolSpecialEquipmentRemark)

				let [implant, implantRemark] = Utils.splitPreOperativeCheckListProperty(preOPCheckList.implant)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT, implant)
				this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK, implantRemark)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK, "")
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT, true)
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.identifyThePatientNameSurnameAndDateOfBirth)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isIdentifyPatient} onChange={this.onIdentifyPatient}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.identifyPatientRemark}
							onChange={this.onIdentifyPatientRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.markingSiteForSurgeryProcedure)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isMarkingSiteForSurgery} onChange={this.onMarkingSiteForSurgery}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.markingSiteForSurgeryRemark}
							onChange={this.onMarkingSiteForSurgeryRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.letterOfConsentToUndergoSurgeryDiagnosis)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isLetterConsentSurgery} onChange={this.onLetterConsentSurgery}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.letterConsentSurgeryRemark}
							onChange={this.onLetterConsentSurgeryRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.letterOfConsentToReceiveAnesthesia)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isLetterConsentAnesthesia} onChange={this.onLetterConsentAnesthesia}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.letterConsentAnesthesiaRemark}
							onChange={this.onLetterConsentAnesthesiaRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.labResult)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isLabResult} onChange={this.onLabResult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.labResultRemark}
							onChange={this.onLabResultRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.imagingResult)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isImagingResult} onChange={this.onImagingResult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.imagingResultRemark}
							onChange={this.onImagingResultRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.EKGResult)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isEKGResult} onChange={this.onEKGResult}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.EKGResultRemark}
							onChange={this.onEKGResultRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.cleanPatientBodyForSurgery)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isCleanPatientBody} onChange={this.onCleanPatientBody}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.cleanPatientBodyRemark}
							onChange={this.onCleanPatientBodyRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.unisonEnema)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isUnisonEnema} onChange={this.onUnisonEnema}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.unisonEnemaRemark}
							onChange={this.onUnisonEnemaRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.NPOTime)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isNPOTime} onChange={this.onNPOTime}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.NPOTimeRemark}
							onChange={this.onNPOTimeRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.removeDentures)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isRemoveDentures} onChange={this.onRemoveDentures}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.removeDenturesRemark}
							onChange={this.onRemoveDenturesRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.eyeGlassesContactLensEyelashesRemoved)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isEyeglassesRemoved} onChange={this.onEyeglassesRemoved}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.eyeglassesRemovedRemark}
							onChange={this.onEyeglassesRemovedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.jewelryAndHairpiecesRemoved)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isJewelryRemoved} onChange={this.onJewelryRemoved}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.jewelryRemovedRemark}
							onChange={this.onJewelryRemovedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.bodyPiercingRemoved)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isBodyPiercingRemoved} onChange={this.onBodyPiercingRemoved}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.bodyPiercingRemovedRemark}
							onChange={this.onBodyPiercingRemovedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.changePatientClothes)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isChangePaitentClothes} onChange={this.onChangePatientClothes}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.changePatientClothesRemark}
							onChange={this.onChangePatientClothesRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.haveThePatientUrinateBeforeBeingReferralOR)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isPatientUrinate} onChange={this.onPatientUrinate}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.patientUrinateRemark}
							onChange={this.onPatientUrinateRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.bloodRequested)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isBloodRequested} onChange={this.onBloodRequested}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.bloodRequestedRemark}
							onChange={this.onBloodRequestedRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.preEducation)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isPreEducation} onChange={this.onPreEducation}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.preEducationRemark}
							onChange={this.onPreEducationRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.otherMedicalDevice)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isOtherMedicalDevice} onChange={this.onOtherMedicalDevice}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.otherMedicalDeviceRemark}
							onChange={this.onOtherMedicalDeviceRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.toolSpecialEquipment)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isToolSpecialEquipment} onChange={this.onToolSpecialEquipment}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.toolSpecialEquipmentRemark}
							onChange={this.onToolSpecialEquipmentRemark}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.implant)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditPreOperativeCheckList.isImplant} onChange={this.onImplant}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.yes)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.no)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							minRows={2}
							placeholder={translate(ResourceAssistance.Message.remark)}
							value={this.props.operatingRoom.modalEditPreOperativeCheckList.implantRemark}
							onChange={this.onImplantRemark}
						/>
					</Col>
				</Row>
			</Container>
		)
	}

	onIdentifyPatient(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT, e.target.value)
	}
	onIdentifyPatientRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IDENTIFY_PATIENT_REMARK, e.target.value)
	}
	onMarkingSiteForSurgery(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY, e.target.value)
	}
	onMarkingSiteForSurgeryRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_MARKING_SITE_SURGERY_REMARK, e.target.value)
	}
	onLetterConsentSurgery(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY, e.target.value)
	}
	onLetterConsentSurgeryRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_SURGERY_REMARK, e.target.value)
	}
	onLetterConsentAnesthesia(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA, e.target.value)
	}
	onLetterConsentAnesthesiaRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LETTER_CONSENT_ANESTHESIA_REMARK, e.target.value)
	}
	onLabResult(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT, e.target.value)
	}
	onLabResultRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_LAB_RESULT_REMARK, e.target.value)
	}
	onImagingResult(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT, e.target.value)
	}
	onImagingResultRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMAGING_RESULT_REMARK, e.target.value)
	}
	onEKGResult(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT, e.target.value)
	}
	onEKGResultRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EKG_RESULT_REMARK, e.target.value)
	}
	onCleanPatientBody(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY, e.target.value)
	}
	onCleanPatientBodyRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CLEAN_PATIENT_BODY_REMARK, e.target.value)
	}
	onUnisonEnema(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA, e.target.value)
	}
	onUnisonEnemaRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_UNISON_ENEMA_REMARK, e.target.value)
	}
	onNPOTime(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME, e.target.value)
	}
	onNPOTimeRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_NPO_TIME_REMARK, e.target.value)
	}
	onRemoveDentures(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES, e.target.value)
	}
	onRemoveDenturesRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_REMOVE_DENTURES_REMARK, e.target.value)
	}
	onEyeglassesRemoved(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED, e.target.value)
	}
	onEyeglassesRemovedRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_EYEGLASSES_REMOVED_REMARK, e.target.value)
	}
	onJewelryRemoved(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED, e.target.value)
	}
	onJewelryRemovedRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_JEWELRY_REMOVED_REMARK, e.target.value)
	}
	onBodyPiercingRemoved(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED, e.target.value)
	}
	onBodyPiercingRemovedRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BODY_PIERCING_REMOVED_REMARK, e.target.value)
	}
	onChangePatientClothes(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES, e.target.value)
	}
	onChangePatientClothesRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_CHANGE_PATIENT_CLOTHES_REMARK, e.target.value)
	}
	onPatientUrinate(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE, e.target.value)
	}
	onPatientUrinateRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PATIENT_URINATE_REMARK, e.target.value)
	}
	onBloodRequested(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED, e.target.value)
	}
	onBloodRequestedRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_BLOOD_REQUESTED_REMARK, e.target.value)
	}
	onPreEducation(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION, e.target.value)
	}
	onPreEducationRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_PRE_EDUCATION_REMARK, e.target.value)
	}
	onOtherMedicalDevice(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEIVCE, e.target.value)
	}
	onOtherMedicalDeviceRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_OTHER_MEDICAL_DEVICE_REMARK, e.target.value)
	}
	onToolSpecialEquipment(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT, e.target.value)
	}
	onToolSpecialEquipmentRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_TOOL_SPECIAL_EQUIPMENT_REMARK, e.target.value)
	}
	onImplant(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT, e.target.value)
	}
	onImplantRemark(e) {
		this.props.setValue(SET_OR_MODAL_NURSE_EDIT_PRE_OP_CHECK_LIST_IMPLANT_REMARK, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPreOperativeCheckListDetail)
