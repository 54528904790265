import _ from "lodash"
import React, { Fragment } from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME, SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class OPDModalMedicalSupplyFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onName = this.onName.bind(this)
		this.onType = this.onType.bind(this)
	}

	componentDidMount() {
		if (!_.isEmpty(this.props.opd.modalNurseOrder.types)) {
			this.props.setSelected(SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE, 0, this.props.opd.modalNurseOrder.types[0])
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME, "")
	}

	onName(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_ORDER_SEARCH_NAME, Utils.trim(event.target.value))
	}

	onType(event) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_ORDER_SELECTED_TYPE,
			event.target.value,
			this.props.opd.modalNurseOrder.types[event.target.value]
		)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={3}>
						<FormControl
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onType}
						>
							{Utils.renderOptions(this.props.opd.modalNurseOrder.types, false)}
						</FormControl>
					</Col>
					<Col>
						<SearchBox
							num={1}
							lableName=""
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.description]}
							callbacks={[this.onName]}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalSupplyFilter))
