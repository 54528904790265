import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setOPDGlasgowComaScales } from "~/redux/action"
import OutpatientRightGCSChart from "./OutpatientRightGCSChart"
import OutpatientRightGCSTable from "./OutpatientRightGCSTable"

class OutpatientRightGCS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightGCS",
		}
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDGlasgowComaScales(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.glasgowComaScales)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDGlasgowComaScales(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.glasgowComaScales)
			} else {
				this.props.setOPDGlasgowComaScales([])
			}
		}
	}

	render() {
		return this.props.graphic ? <OutpatientRightGCSChart /> : <OutpatientRightGCSTable />
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setOPDGlasgowComaScales,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightGCS))
