import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

const DIAGNOSIS = "IPD_MODAL_MC_DIAGNOSIS"
const EDiagnosis = {
	COVID19_POSITIVE: "IPD_MODAL_MC_DIAGNOSIS_COVID19_POSITIVE",
	COVID19_NEGATIVE: "IPD_MODAL_MC_DIAGNOSIS_COVID19_NEGATIVE",
}

class OPDModalMedicalCertificateDiaRow extends React.Component {
	constructor(props) {
		super(props)

		this.onCovidResult = this.onCovidResult.bind(this)
		this.onDiagnosis = this.onDiagnosis.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedCertificate.certificate) {
			this.props.setValue(
				SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS,
				this.props.opd.selectedCertificate.certificate.diagnosis
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS, ResourceAssistance.CONSTANT.COVID19_NEGATIVE)
	}

	onCovidResult(event) {
		let value = this.props.opd.modalCertificate.diagnosis
		switch (event.target.value) {
			case EDiagnosis.COVID19_NEGATIVE:
				value = value.replace(ResourceAssistance.CONSTANT.COVID19_POSITIVE, "")
				event.target.value = ResourceAssistance.CONSTANT.COVID19_NEGATIVE
				break

			case EDiagnosis.COVID19_POSITIVE:
				value = value.replace(ResourceAssistance.CONSTANT.COVID19_NEGATIVE, "")
				event.target.value = ResourceAssistance.CONSTANT.COVID19_POSITIVE
				break

			default:
				break
		}

		// value = value.replace(/, +/g, "")

		if (event.target.checked) {
			value = value.concat(_.isEmpty(value) ? "" : " ", event.target.value)
		} else {
			value = value.replace(ResourceAssistance.CONSTANT.COVID19_NEGATIVE, "")
			value = value.replace(ResourceAssistance.CONSTANT.COVID19_POSITIVE, "")
		}

		value = Utils.replaceDuplicateSpaces(value)
		this.props.setValue(SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS, Utils.trim(value))
	}

	onDiagnosis(event) {
		this.props.setValue(SET_OPD_MODAL_MEDICAL_CERTIFICATE_DIAGNOSIS, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.diagnosis)}</legend>
				<Row>
					<Col />
					<Col md={"auto"}>
						<FormGroup controlId={"covid_result"}>
							<FormCheck
								label={translate(ResourceAssistance.Hospitel.covid19Lab)}
								checked={
									_.includes(this.props.opd.modalCertificate.diagnosis, ResourceAssistance.CONSTANT.COVID19_NEGATIVE) ||
									_.includes(this.props.opd.modalCertificate.diagnosis, ResourceAssistance.CONSTANT.COVID19_POSITIVE)
								}
								onChange={this.onCovidResult}
								value={ResourceAssistance.CONSTANT.COVID19_NEGATIVE}
							/>
						</FormGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col />

					<Col md={"auto"}>
						<FormGroup controlId={"covid_negative"}>
							<FormCheck
								type={ResourceAssistance.FormControl.type.radio}
								name={DIAGNOSIS}
								value={EDiagnosis.COVID19_NEGATIVE}
								disabled={
									!_.includes(
										this.props.opd.modalCertificate.diagnosis,
										ResourceAssistance.CONSTANT.COVID19_NEGATIVE
									) &&
									!_.includes(this.props.opd.modalCertificate.diagnosis, ResourceAssistance.CONSTANT.COVID19_POSITIVE)
								}
								checked={_.includes(
									this.props.opd.modalCertificate.diagnosis,
									ResourceAssistance.CONSTANT.COVID19_NEGATIVE
								)}
								onChange={this.onCovidResult}
								label={translate(ResourceAssistance.Hospitel.covid19Negative)}
							/>
						</FormGroup>
					</Col>
					<Col md={"auto"}>
						<FormGroup controlId={"covid_positive"}>
							<FormCheck
								type={ResourceAssistance.FormControl.type.radio}
								name={DIAGNOSIS}
								value={EDiagnosis.COVID19_POSITIVE}
								disabled={
									!_.includes(
										this.props.opd.modalCertificate.diagnosis,
										ResourceAssistance.CONSTANT.COVID19_NEGATIVE
									) &&
									!_.includes(this.props.opd.modalCertificate.diagnosis, ResourceAssistance.CONSTANT.COVID19_POSITIVE)
								}
								checked={_.includes(
									this.props.opd.modalCertificate.diagnosis,
									ResourceAssistance.CONSTANT.COVID19_POSITIVE
								)}
								onChange={this.onCovidResult}
								label={translate(ResourceAssistance.Hospitel.covid19Positive)}
							/>
						</FormGroup>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={2}
							value={this.props.opd.modalCertificate.diagnosis}
							onChange={this.onDiagnosis}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateDiaRow)
