import { TreeSelect, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { Utils } from "~/utils/Utils"
import { TreeSelectUtils } from "~/utils/component/TreeSelectUtils"
import GInput from "~/view/component/input/GInput"

class SurgicalAuthModalCostOther extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			costs: [],
		}

		this.onAddCost = this.onAddCost.bind(this)
		this.onGroup = this.onGroup.bind(this)
		this.onDescription = this.onDescription.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onQuantity = this.onQuantity.bind(this)
		this.onDeleteOther = this.onDeleteOther.bind(this)
	}

	componentDidMount() {
		let ary = this.props.surgicalAuth.selectedORRequest.request.otherCosts.split(ResourceAssistance.PROGRAM_DEFINED.split).filter((each) => Boolean(each))
		let costs = []
		for (let i = 0; i < ary.length; i += 6) {
			let groupIdx = -1
			let group = null
			let descriptionIdx = -1
			let description = null
			let unitIdx = -1
			let unit = null
			let descriptions = []
			let units = []
			if (ary[i + 1] === "true") {
				let idx = this.props.surgicalAuth.types.findIndex((each) => each.displayName === ary[i])
				if (idx > -1) {
					groupIdx = "0-" + idx
					group = this.props.surgicalAuth.types[idx]
					descriptions = this.props.surgicalAuth.items
						.filter((each) => each.type.id === this.props.surgicalAuth.types[idx].id)
						.sort((a, b) => Utils.sort(a.displayName, b.displayName))
					descriptionIdx = descriptions.findIndex((each) => each.displayName === ary[i + 2])
					if (descriptionIdx > -1) {
						description = descriptions[descriptionIdx]
						units = description.unitConversions
							.concat([
								{
									amount: 1,
									displayName: description.unit.displayName,
									sellingPricePerUnit: description.sellPricePerUnit,
									oneTime: true,
								},
							])
							.sort((a, b) => Utils.sort(a.displayName, b.displayName))
						unitIdx = units.findIndex((each) => each.displayName === ary[i + 3])
						if (unitIdx > -1) {
							unit = units[unitIdx]
						}
					}
				}
			} else {
				let idx = this.props.surgicalAuth.services.findIndex((each) => each.displayName === ary[i])
				if (idx > -1) {
					groupIdx = "1-" + idx
					group = this.props.surgicalAuth.services[idx]
					descriptions = this.props.surgicalAuth.services[idx].services
					descriptionIdx = descriptions.findIndex((each) => each.displayName === ary[i + 2])
					if (descriptionIdx > -1) {
						description = descriptions[descriptionIdx]
					}
				}
			}
			costs.push({
				quantity: ary[i + 4],
				isService: ary[i + 1] === "false",
				isInventory: ary[i + 1] === "true",
				descriptions: descriptions,
				units: units,
				selectedGroup: {
					index: groupIdx,
					group: group,
				},
				selectedDescription: {
					index: descriptionIdx,
					description: description,
				},
				selectedUnit: {
					index: unitIdx,
					unit: unit,
				},
			})
		}
		this.setState({
			costs: costs,
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.costs, this.state.costs)) {
			this.props.onRenderParentCallback()
		}
	}

	componentWillUnmount() {
		this.setState({
			costs: [],
		})
	}

	getEmptyObj() {
		return {
			quantity: undefined,
			isService: false,
			isInventory: false,
			descriptions: [],
			units: [],
			selectedGroup: {
				index: -1,
				group: null,
			},
			selectedDescription: {
				index: -1,
				description: null,
			},
			selectedUnit: {
				index: -1,
				unit: null,
			},
		}
	}

	calculateAmount(selectInfo) {
		if (selectInfo.isInventory) {
			if (selectInfo.selectedUnit.unit.oneTime) {
				return Utils.formatNumWithComma(Utils.BigNumber(selectInfo.quantity).times(selectInfo.selectedDescription.description.sellPricePerUnit).toFixed(2))
			} else {
				return Utils.formatNumWithComma(Utils.BigNumber(selectInfo.quantity).times(selectInfo.selectedUnit.unit.sellingPricePerUnit).toFixed(2))
			}
		} else {
			return Utils.formatNumWithComma(Utils.BigNumber(selectInfo.quantity).times(selectInfo.selectedDescription.description.pricePerUnit).toFixed(2))
		}
	}

	onAddCost(e) {
		this.setState((prevState) => {
			const updateCosts = [...prevState.costs]
			updateCosts.push(this.getEmptyObj())
			return {
				costs: updateCosts,
			}
		})
	}

	onGroup(value, idx) {
		if (value === undefined) {
			this.setState((prevState) => {
				const updateCosts = [...prevState.costs]
				updateCosts[idx] = {
					...updateCosts[idx],
					selectedGroup: {
						index: -1,
						group: null,
					},
				}
				return { costs: updateCosts }
			})
			return
		}
		let keys = value.split("-")
		this.setState((prevState) => {
			const updateCosts = [...prevState.costs]
			updateCosts[idx] = {
				...updateCosts[idx],
				isService: keys[0] !== "0",
				isInventory: keys[0] === "0",
				descriptions:
					keys[0] === "0"
						? this.props.surgicalAuth.items.filter((each) => each.type.id === this.props.surgicalAuth.types[keys[1]].id)
						: this.props.surgicalAuth.services[keys[1]].services,
				selectedGroup: {
					index: value,
					group: keys[0] === "0" ? this.props.surgicalAuth.types[keys[1]] : this.props.surgicalAuth.services[keys[1]],
				},
			}
			return { costs: updateCosts }
		})
	}

	onDescription(value, idx) {
		if (value === undefined) {
			this.setState((prevState) => {
				const updateCosts = [...prevState.costs]
				updateCosts[idx] = {
					...updateCosts[idx],
					units: [],
					selectedDescription: {
						index: -1,
						description: null,
					},
				}
				return { costs: updateCosts }
			})
			return
		}
		this.setState((prevState) => {
			const updateCosts = [...prevState.costs]
			updateCosts[idx] = {
				...updateCosts[idx],
				units: this.state.costs[idx].isInventory
					? this.state.costs[idx].descriptions[value].unitConversions.concat([
							{
								amount: 1,
								displayName: this.state.costs[idx].descriptions[value].unit.displayName,
								sellingPricePerUnit: this.state.costs[idx].descriptions[value].sellPricePerUnit,
								oneTime: true,
							},
					  ])
					: [],
				selectedDescription: {
					index: value,
					description: this.state.costs[idx].descriptions[value],
				},
			}
			return { costs: updateCosts }
		})
	}

	onUnit(value, idx) {
		if (value === undefined) {
			this.setState((prevState) => {
				const updateCosts = [...prevState.costs]
				updateCosts[idx] = {
					...updateCosts[idx],
					selectedUnit: {
						index: -1,
						unit: null,
					},
				}
				return { costs: updateCosts }
			})
			return
		}
		this.setState((prevState) => {
			const updateCosts = [...prevState.costs]
			updateCosts[idx] = {
				...updateCosts[idx],
				selectedUnit: {
					index: value,
					unit: this.state.costs[idx].units[value],
				},
			}
			return { costs: updateCosts }
		})
	}

	onQuantity(e) {
		let idx = JSON.parse(e.target.getAttribute("extra-value")).index
		this.setState((prevState) => {
			const updateCosts = [...prevState.costs]
			updateCosts[idx] = {
				...updateCosts[idx],
				quantity: e.target.value,
			}
			return { costs: updateCosts }
		})
	}

	onDeleteOther(idx) {
		this.setState((prevState) => {
			const updateCosts = [...prevState.costs]
			updateCosts.splice(idx, 1)
			return { costs: updateCosts }
		})
	}

	render() {
		return (
			<>
				<Row>
					<Col />
					<Col sm="auto">
						<Button variant={ResourceAssistance.Button.variant.primary} size={ResourceAssistance.Button.size.sm} onClick={this.onAddCost}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.other)}
						</Button>
					</Col>
				</Row>
				{this.getCosts()}
			</>
		)
	}

	getCosts() {
		return this.state.costs.map((each, i) => {
			return (
				<Row key={i} className="g-1">
					<Col>
						<TreeSelect
							size={"large"}
							style={{
								textAlign: "center",
							}}
							showSearch
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							treeLine={{ showLeafIcon: false }}
							treeExpandAction={"click"}
							placeholder={translate(ResourceAssistance.Message.category)}
							status={each.selectedGroup.group ? undefined : "error"}
							value={each.selectedGroup.group ? each.selectedGroup.index : undefined}
							onChange={(v) => {
								this.onGroup(v, i)
							}}
							treeData={TreeSelectUtils.displayInventoryAndService(this.props.surgicalAuth.types, this.props.surgicalAuth.services, true)}
						/>
					</Col>
					<Col md={6}>
						<Select
							showSearch
							size={"large"}
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.description)}
							disabled={!each.selectedGroup.group}
							status={!each.selectedGroup.group || each.selectedDescription.description ? undefined : "error"}
							value={each.selectedDescription.description ? each.selectedDescription.index : undefined}
							onChange={(v) => {
								this.onDescription(v, i)
							}}
						>
							{Utils.renderSelects(each.descriptions, false)}
						</Select>
					</Col>
					{each.isInventory && (
						<Col md={1}>
							<Select
								showSearch
								size={"large"}
								style={{ textAlign: "center", margin: 0 }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Message.unit)}
								disabled={!each.selectedDescription.description}
								status={!each.selectedDescription.description || each.selectedUnit.unit ? undefined : "error"}
								value={each.selectedUnit.unit ? each.selectedUnit.index : undefined}
								onChange={(v) => {
									this.onUnit(v, i)
								}}
							>
								{Utils.renderSelects(each.units, false)}
							</Select>
						</Col>
					)}
					<Col md={2}>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.qty)}
							disabled={!each.selectedDescription.description || (each.isInventory && !each.selectedUnit.unit)}
							pattern="^\d+$"
							onChange={this.onQuantity}
							value={each.quantity}
							addonAfter={each.quantity ? this.calculateAmount(each) : undefined}
							extraValue={JSON.stringify({
								index: i,
							})}
						/>
					</Col>
					<Col md="auto">
						<Button
							variant={ResourceAssistance.Button.variant.outlineRed}
							style={{ borderColor: "transparent", backgroundColor: "unset" }}
							size={ResourceAssistance.Button.size.sm}
							onClick={() => this.onDeleteOther(i)}
						>
							<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
						</Button>
					</Col>
				</Row>
			)
		})
	}
}

const mapStateToProps = (state) => ({
	surgicalAuth: state.hospital.surgicalAuthorization,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SurgicalAuthModalCostOther)
