import { Font, StyleSheet } from "@react-pdf/renderer"
import materialIcons from "~/fonts/MaterialIconsOutlined-Regular.otf"
import sarabunBoldItalic from "~/fonts/TH-Sarabun-New-Bold-Italic.ttf"
import sarabunBold from "~/fonts/TH-Sarabun-New-Bold.ttf"
import sarabunItalic from "~/fonts/TH-Sarabun-New-Italic.ttf"
import sarabun from "~/fonts/TH-Sarabun-New-Regular.ttf"

const Report = {
	size: {
		page: {
			A4: "A4",
			LL: [142, 57],
			PL: [50, 20],
			ML: [240, 125],
		},
	},
	styles: StyleSheet.create({
		iframe: {
			height: "100%",
		},
		document: {
			fontFamily: "Sarabun",
			height: "100%",
			lineHeight: 1,
		},
		pageLabel: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			height: "100%",
			paddingVertical: 1.5,
			paddingHorizontal: 3,
			fontSize: 12,
			justifyContent: "center",
		},
		page: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			height: "100%",
			paddingTop: 35,
			paddingBottom: 25,
			paddingHorizontal: 25,
			fontSize: 14,
			fontFamily: "Sarabun",
		},
		row: {
			display: "flex",
			flexWrap: "wrap",
			flexDirection: "row",
			// width: "100%",
			// justifyContent: "space-between",
			marginVertical: 0,
			paddingVertical: 0,
		},
		colAuto: {
			width: "auto",
			maxWidth: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			marginVertical: 0,
			paddingVertical: 0,
		},
		col: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			// flexGrow: 1,
			flex: 1,
			flexBasis: 0,
			marginVertical: 0,
			paddingVertical: 0,
		},
		colTextCenter: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			// flexGrow: 1,
			flex: 1,
			flexBasis: 0,
			textAlign: "center",
			marginVertical: 0,
			paddingVertical: 0,
		},
		title: {
			fontSize: 36,
			textAlign: "center",
		},
		separator: {
			borderBottomWidth: 3,
			marginTop: 5,
			marginBottom: 5,
		},
		separatorDash: {
			borderBottomStyle: "dashed",
			borderBottomWidth: 1,
			marginTop: 5,
			marginBottom: 5,
		},
		separatorFlexGrow: {
			borderBottomWidth: 3,
			marginTop: 5,
			marginBottom: 5,
			flexGrow: 10,
		},
		horizontalLine: {
			borderBottomWidth: 1,
			marginBottom: 5,
		},
		dashBottom: {
			borderBottomStyle: "dashed",
			borderBottomWidth: 1,
		},
		horizontalSubline: {
			borderBottom: 1,
			borderStyle: "dotted",
			marginBottom: 5,
		},
		verticalMiddleLine: {
			borderLeftWidth: 1,
			minHeight: "100%",
			maxHeight: "100%",
			position: "absolute",
			left: "50%",
		},
		textBold: {
			fontStyle: "bold",
		},
		textItalic: {
			fontStyle: "italic",
		},
		textBoldIntalic: {
			fontStyle: "bold-italic",
		},
		textIcon: {
			fontFamily: "Icon",
			fontSize: 12,
			marginRight: 1,
			lineHeight: 1,
		},
	}),
}

Font.register({
	family: "Sarabun",
	fonts: [
		{ src: sarabun },
		{ src: sarabunItalic, fontStyle: "italic" },
		{ src: sarabunBold, fontStyle: "bold" },
		{ src: sarabunBoldItalic, fontStyle: "bold-italic" },
	],
})

Font.register({
	family: "Icon",
	fonts: [{ src: materialIcons }],
})

Font.registerHyphenationCallback((word) => {
	if (word.length <= 1) {
		return [word]
	}

	let words = []
	while (word.length > 1) {
		let subStr = word.substring(0, 1)
		words.push(subStr)
		word = word.substring(1, word.length + 1)
	}
	words.push(word)
	return words
})

export default Report
