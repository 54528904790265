import _ from "lodash"
import React, { Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { BiLogoInternetExplorer } from "react-icons/bi"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setSelectedType, setValue } from "~/redux/action"
import { SET_ADM_NEW_TYPE_DISPLAY_CODE, SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT, SET_NEW_TYPE_DISPLAY } from "~/redux/type"
import { EBtnType } from "~/view/component/scroll_table/ScrollableTable"
import AbstractTypeView from "../AbstractTypeView"
import AdmBankAccountsPayable from "./AdmBankAccountsPayable"
import AdmBankBranch from "./AdmBankBranch"
import AdmBankAccountsReceivable from "./AdmBankAccountsReceivable"

class AdmBank extends React.Component {
	constructor(props) {
		super(props)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.type.types.filtered, this.props.type.types.filtered)) {
			let index = this.props.type.selectedType.type ? this.props.type.types.filtered.findIndex((each) => each.id === this.props.type.selectedType.type.id) : -1
			if (index > -1) {
				this.props.setSelectedType(index, this.props.type.types.filtered[index])
			} else {
				this.props.setSelectedType(-1, null)
			}
		}
	}

	onEditBtnClick(event) {
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CODE, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT, true)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	onAddBtnClick(event) {
		this.props.setSelectedType(-1, null)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_CODE, true)
		this.props.setValue(SET_ADM_NEW_TYPE_DISPLAY_I_PAY_FORMAT, true)
		this.props.onModalDisplayAction(SET_NEW_TYPE_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<AbstractTypeView
					displayBtn
					buttonColors={[ResourceAssistance.Button.variant.outlineInfo]}
					btnMessages={[""]}
					btnTypes={[EBtnType.POPOVER]}
					btnIcons={[<BiLogoInternetExplorer size={ResourceAssistance.ReactIcon.size} />]}
					scrollableTableData={this.props.type.types}
					onAddBtnClick={this.onAddBtnClick}
					onEditBtnClick={this.onEditBtnClick}
					onRefreshClick={this.props.onRefreshClick}
				/>
				<AdmBankBranch />
				<Row className={"full-size g-1"}>
					<Col>
						<AdmBankAccountsReceivable />
					</Col>
					<Col>
						<AdmBankAccountsPayable />
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelectedType,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmBank)
