import {
	SET_REPORT_PO_BY_ID_SEARCH_ID,
	SET_REPORT_PO_BY_LOCATION_APPROVAL_IND,
	SET_REPORT_PO_BY_LOCATION_BRANCHES,
	SET_REPORT_PO_BY_LOCATION_END_DATETIME,
	SET_REPORT_PO_BY_LOCATION_INSPECTOR_IND,
	SET_REPORT_PO_BY_LOCATION_ORGS,
	SET_REPORT_PO_BY_LOCATION_REQUESTER_IND,
	SET_REPORT_PO_BY_LOCATION_SELECTED_BRANCH,
	SET_REPORT_PO_BY_LOCATION_SELECTED_ORG,
	SET_REPORT_PO_BY_LOCATION_START_DATETIME,
} from "../../type"

const init = {
	reportByLocation: {
		startDateTime: null,
		endDateTime: null,
		isRequester: true,
		isInspector: true,
		isApproval: true,
		orgs: [],
		branches: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
	reportById: {
		searchId: "",
	},
}

const reportPOReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_PO_BY_LOCATION_START_DATETIME:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					startDateTime: action.payload.date,
				},
			})

		case SET_REPORT_PO_BY_LOCATION_END_DATETIME:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					endDateTime: action.payload.date,
				},
			})

		case SET_REPORT_PO_BY_LOCATION_ORGS:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					orgs: action.payload.orgs,
				},
			})

		case SET_REPORT_PO_BY_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.org,
					},
				},
			})

		case SET_REPORT_PO_BY_LOCATION_BRANCHES:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					branches: action.payload.branches,
				},
			})

		case SET_REPORT_PO_BY_LOCATION_SELECTED_BRANCH:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.branch,
					},
				},
			})

		case SET_REPORT_PO_BY_LOCATION_REQUESTER_IND:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					isRequester: action.payload.isRequester,
				},
			})

		case SET_REPORT_PO_BY_LOCATION_INSPECTOR_IND:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					isInspector: action.payload.isInspector,
				},
			})

		case SET_REPORT_PO_BY_LOCATION_APPROVAL_IND:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					isApproval: action.payload.isApproval,
				},
			})

		case SET_REPORT_PO_BY_ID_SEARCH_ID:
			return Object.assign({}, state, {
				reportById: {
					...state.reportById,
					searchId: action.payload.searchId,
				},
			})

		default:
			return state
	}
}

export default reportPOReducer
