import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import POLeftFifthRow from "./POLeftFifthRow"
import POLeftFirstRow from "./POLeftFirstRow"
import POLeftFourthRow from "./POLeftFourthRow"
import POLeftSecondRow from "./POLeftSecondRow"
import POLeftSixthRow from "./POLeftSixthRow"
import POLeftThirdRow from "./POLeftThirdRow"

class POLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "POLeft",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<POLeftFirstRow {...this.props} />
				<POLeftSecondRow {...this.props} />
				<POLeftThirdRow {...this.props} />
				<POLeftFourthRow {...this.props} />
				<POLeftFifthRow {...this.props} />
				<POLeftSixthRow {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(POLeft)
