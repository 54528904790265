export const SET_PV_CHART_OF_ACCOUNTS = "SET_PV_CHART_OF_ACCOUNTS"
export const SET_PV_CHART_OF_ACCOUNTS_LOADING = "SET_PV_CHART_OF_ACCOUNTS_LOADING"
export const SET_PV_END_DATE_TIME = "SET_PV_END_DATE_TIME"
export const SET_PV_FILTERS = "SET_PV_FILTERS"
export const SET_PV_FILTER_PAYMENT_VOUCHER_ID = "SET_PV_FILTER_PAYMENT_VOUCHER_ID"
export const SET_PV_INVOICES = "SET_PV_INVOICES"
export const SET_PV_LOCATION = "SET_PV_LOCATION"
export const SET_PV_LOCATION_DISPLAY = "SET_PV_LOCATION_DISPLAY"
export const SET_PV_MODAL_LOCATION_ORGS = "SET_PV_MODAL_LOCATION_ORGS"
export const SET_PV_MODAL_LOCATION_ORGS_LOADING = "SET_PV_MODAL_LOCATION_ORGS_LOADING"
export const SET_PV_MODAL_LOCATION_SELECTED_ORG = "SET_PV_MODAL_LOCATION_SELECTED_ORG"
export const SET_PV_MODAL_NEW_DUE_DATE_TIME = "SET_PV_MODAL_NEW_DUE_DATE_TIME"
export const SET_PV_MODAL_NEW_PURCHASES = "SET_PV_MODAL_NEW_PURCHASES"
export const SET_PV_MODAL_NEW_REMARK = "SET_PV_MODAL_NEW_REMARK"
export const SET_PV_MODAL_NEW_SELECTED_PAYMENT = "SET_PV_MODAL_NEW_SELECTED_PAYMENT"
export const SET_PV_MODAL_NEW_SELECTED_PURCHASE = "SET_PV_MODAL_NEW_SELECTED_PURCHASE"
export const SET_PV_MODAL_NEW_SELECTED_SUPPLIER = "SET_PV_MODAL_NEW_SELECTED_SUPPLIER"
export const SET_PV_MODAL_NEW_WHT_ISSUED_DATE_TIME = "SET_PV_MODAL_NEW_WHT_ISSUED_DATE_TIME"
export const SET_PV_MODAL_PURCHASE_AMOUNT = "SET_PV_MODAL_PURCHASE_AMOUNT"
export const SET_PV_MODAL_PURCHASE_DESCRIPTION = "SET_PV_MODAL_PURCHASE_DESCRIPTION"
export const SET_PV_MODAL_PURCHASE_SELECTED_GL = "SET_PV_MODAL_PURCHASE_SELECTED_GL"
export const SET_PV_MODAL_PURCHASE_SELECTED_TAX = "SET_PV_MODAL_PURCHASE_SELECTED_TAX"
export const SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE = "SET_PV_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE"
export const SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX = "SET_PV_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX"
export const SET_PV_MODAL_PURCHASE_WHT_PERCENT = "SET_PV_MODAL_PURCHASE_WHT_PERCENT"
export const SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER = "SET_PV_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER"
export const SET_PV_MODAL_SEARCH_PAYMENT_VOUCHER_ID = "SET_PV_MODAL_SEARCH_PAYMENT_VOUCHER_ID"
export const SET_PV_NEW_DISPLAY = "SET_PV_NEW_DISPLAY"
export const SET_PV_PAYMENT_TYPES = "SET_PV_PAYMENT_TYPES"
export const SET_PV_PAYMENT_TYPES_LOADING = "SET_PV_PAYMENT_TYPES_LOADING"
export const SET_PV_PURCHASES = "SET_PV_PURCHASES"
export const SET_PV_PURCHASE_DISPLAY = "SET_PV_PURCHASE_DISPLAY"
export const SET_PV_SEARCH_DISPLAY = "SET_PV_SEARCH_DISPLAY"
export const SET_PV_SELECTED_PURCHASE = "SET_PV_SELECTED_PURCHASE"
export const SET_PV_SELECTED_PV = "SET_PV_SELECTED_PV"
export const SET_PV_START_DATE_TIME = "SET_PV_START_DATE_TIME"
export const SET_PV_SUPPLIERS = "SET_PV_SUPPLIERS"
export const SET_PV_SUPPLIERS_LOADING = "SET_PV_SUPPLIERS_LOADING"
export const SET_PV_TAXES = "SET_PV_TAXES"
export const SET_PV_TAXES_LOADING = "SET_PV_TAXES_LOADING"
export const SET_PV_WITHHOLDING_TAXES = "SET_PV_WITHHOLDING_TAXES"
export const SET_PV_WITHHOLDING_TAXES_LOADING = "SET_PV_WITHHOLDING_TAXES_LOADING"
