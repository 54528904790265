import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_DEBT_END_DATE_TIME,
	SET_DEBT_FILTER_DEBT_PLAN_ID,
	SET_DEBT_FILTER_INSURANCE_COMPANY,
	SET_DEBT_INVOICES,
	SET_DEBT_LOCATION,
	SET_DEBT_MODAL_ADJUSTMENT_CONTINUE,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT,
	SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK,
	SET_DEBT_MODAL_ADJUSTMENT_ID,
	SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT,
	SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT_GROUP,
	SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS,
	SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP,
	SET_DEBT_MODAL_LOCATION_ORGS,
	SET_DEBT_MODAL_LOCATION_SELECTED_ORG,
	SET_DEBT_MODAL_NEW_FILTER_INVOICE_ID,
	SET_DEBT_MODAL_NEW_HEALTH_CARE_PLANS,
	SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING,
	SET_DEBT_MODAL_NEW_INSURANCE_COMPANIES,
	SET_DEBT_MODAL_NEW_INVOICES,
	SET_DEBT_MODAL_NEW_PENDING_INVOICES,
	SET_DEBT_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN,
	SET_DEBT_MODAL_NEW_SEARCH_INSURANCE_COMPANY,
	SET_DEBT_MODAL_NEW_SELECTED_INVOICE,
	SET_DEBT_MODAL_NEW_SELECTED_PENDING_INVOICE,
	SET_DEBT_MODAL_PAYMENT_BANKS,
	SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES,
	SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME,
	SET_DEBT_MODAL_PAYMENT_CHECK_NUM,
	SET_DEBT_MODAL_PAYMENT_CREDIT_CARD_NUM,
	SET_DEBT_MODAL_PAYMENT_DATE_TIME,
	SET_DEBT_MODAL_PAYMENT_PAYMENTS,
	SET_DEBT_MODAL_PAYMENT_REMARK,
	SET_DEBT_MODAL_PAYMENT_SELECTED_BANK,
	SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH,
	SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT,
	SET_DEBT_MODAL_PAYMENT_WHT,
	SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE,
	SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH,
	SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME,
	SET_DEBT_MODAL_SEARCH_DEBT_PLAN_ID,
	SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANIES,
	SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY,
	SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING,
	SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID,
	SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME,
	SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME,
	SET_DEBT_MODAL_SEARCH_MORE_PID,
	SET_DEBT_MODAL_SEARCH_RANGE_END_DATE_TIME,
	SET_DEBT_MODAL_SEARCH_RANGE_START_DATE_TIME,
	SET_DEBT_PLANS,
	SET_DEBT_SELECTED_INVOICE,
	SET_DEBT_SELECTED_PLAN,
	SET_DEBT_START_DATE_TIME,
	SET_DEBT_MODAL_PAYMENT_AMOUNT,
	SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT,
	SET_DEBT_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterDebtPlanId: "",
	filterInsuranceCompany: "",
	selectedPlan: {
		index: -1,
		plan: null,
	},
	selectedInvoice: {
		index: -1,
		invoice: null,
	},
	planTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.time, ResourceAssistance.Message.id, ResourceAssistance.Message.insuranceCompany],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	invoiceTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.visitId,
			ResourceAssistance.Message.invoiceId,
			ResourceAssistance.Hospitel.patientId,
			ResourceAssistance.Message.patientName,
			ResourceAssistance.Message.totalBalance,
			ResourceAssistance.Message.discountService,
			ResourceAssistance.Message.discountBill,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.paid,
			ResourceAssistance.Message.contractBillingId,
			ResourceAssistance.Message.date,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalSearch: {
		searchDebtPlanId: "",
		searchRangeStartDateTime: undefined,
		searchRangeEndDateTime: undefined,
		isInsuranceCompanyLoading: false,
		insuranceCompanies: [],
		searchInsuranceCompany: {
			index: -1,
			insuranceCompany: null,
		},
	},
	modalNew: {
		isHealthCarePlanLoading: false,
		isInsuranceCompanyLoading: false,
		filterInvoiceId: "",
		healthcarePlans: [],
		insuranceCompanies: [],
		searchHealthCarePlan: {
			index: -1,
			healthcarePlan: null,
		},
		searchInsuranceCompany: {
			index: -1,
			insuranceCompany: null,
		},
		selectedInvoice: {
			index: -1,
			invoice: null,
		},
		selectedPendingInvoice: {
			index: -1,
			invoice: null,
		},
		invoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.visitId,
				ResourceAssistance.Message.invoiceNum,
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Message.patientName,
				ResourceAssistance.Hospitel.balance,
				ResourceAssistance.Hospitel.healthCarePlan,
				ResourceAssistance.Message.insuranceCompany,
				ResourceAssistance.Message.contractBillingId,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
			colStyle: [],
			isLoading: false,
		},
		pendingInvoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.visitId,
				ResourceAssistance.Message.invoiceNum,
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Message.patientName,
				ResourceAssistance.Hospitel.balance,
				ResourceAssistance.Hospitel.healthCarePlan,
				ResourceAssistance.Message.insuranceCompany,
				ResourceAssistance.Message.contractBillingId,
				ResourceAssistance.Message.date,
			],
			body: [],
			rowColor: [],
		},
	},
	modalSearchMore: {
		contractBillingId: "",
		pid: "",
		firstName: "",
		lastName: "",
	},
	modalPayment: {
		paymentDateTime: moment().startOf("day").milliseconds(0).valueOf(),
		checkDateTime: moment().startOf("day").milliseconds(0).valueOf(),
		transferBankName: "",
		transferBankBranch: "",
		creditCardNum: "",
		checkNum: "",
		amount: "",
		transactionFee: "",
		withholdingTax: "",
		balanceAdjustment: "",
		remark: "",
		payments: [],
		banks: [],
		branches: [],
		selectedPayment: {
			index: -1,
			payment: null,
		},
		selectedBank: {
			index: -1,
			bank: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
	modalEditInvoice: {
		selectedBillingStatement: {
			index: -1,
			billingStatement: null,
		},
		selectedGroup: {
			index: -1,
			group: null,
		},
		billingStatementGroupTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Hospitel.balance,
				ResourceAssistance.Message.discount,
				ResourceAssistance.Message.totalPriceDiscounted,
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
		billingStatementTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.description,
				ResourceAssistance.Hospitel.balance,
				ResourceAssistance.Message.discount,
				ResourceAssistance.Message.remark,
				ResourceAssistance.Message.totalPriceDiscounted,
				"",
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
	},
	modalAdjustment: {
		id: "",
		isContinue: false,
		discount: 0,
		discountPercent: 0,
		discountMax: 0,
		remark: ResourceAssistance.CONSTANT.NONE,
	},
}

const debtReducer = (state = init, action) => {
	switch (action.type) {
		case SET_DEBT_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_DEBT_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_DEBT_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_DEBT_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_DEBT_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_DEBT_FILTER_DEBT_PLAN_ID:
			return Object.assign({}, state, {
				filterDebtPlanId: action.payload.value,
			})
		case SET_DEBT_FILTER_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				filterInsuranceCompany: action.payload.value,
			})
		case SET_DEBT_PLANS:
			return Object.assign({}, state, {
				planTable: {
					...state.planTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_DEBT_SELECTED_PLAN:
			return Object.assign({}, state, {
				selectedPlan: {
					index: action.payload.index,
					plan: action.payload.selected,
				},
			})
		case SET_DEBT_INVOICES:
			return Object.assign({}, state, {
				invoiceTable: {
					...state.invoiceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_DEBT_SELECTED_INVOICE:
			return Object.assign({}, state, {
				selectedInvoice: {
					index: action.payload.index,
					invoice: action.payload.selected,
				},
			})
		case SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANIES:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					insuranceCompanies: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY_LOADING:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					isInsuranceCompanyLoading: action.payload.isLoading,
				},
			})
		case SET_DEBT_MODAL_SEARCH_DEBT_PLAN_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchDebtPlanId: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_RANGE_START_DATE_TIME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchRangeStartDateTime: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_RANGE_END_DATE_TIME:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchRangeEndDateTime: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchInsuranceCompany: {
						index: action.payload.index,
						insuranceCompany: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_NEW_HEALTH_CARE_PLANS:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					healthcarePlans: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_NEW_INSURANCE_COMPANIES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					insuranceCompanies: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_NEW_SEARCH_HEALTH_CARE_PLAN:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchHealthCarePlan: {
						index: action.payload.index,
						healthcarePlan: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_NEW_SEARCH_INSURANCE_COMPANY:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchInsuranceCompany: {
						index: action.payload.index,
						insuranceCompany: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_NEW_FILTER_INVOICE_ID:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterInvoiceId: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_NEW_HEALTH_CARE_PLAN_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					isHealthCarePlanLoading: action.payload.isLoading,
				},
			})
		case SET_DEBT_MODAL_SEARCH_MORE_CONTRACT_BILLING_ID:
			return Object.assign({}, state, {
				modalSearchMore: {
					...state.modalSearchMore,
					contractBillingId: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_MORE_PID:
			return Object.assign({}, state, {
				modalSearchMore: {
					...state.modalSearchMore,
					pid: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_MORE_FIRST_NAME:
			return Object.assign({}, state, {
				modalSearchMore: {
					...state.modalSearchMore,
					firstName: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_SEARCH_MORE_LAST_NAME:
			return Object.assign({}, state, {
				modalSearchMore: {
					...state.modalSearchMore,
					lastName: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_NEW_INVOICES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					invoiceTable: {
						...state.modalNew.invoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_DEBT_MODAL_NEW_SELECTED_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_NEW_PENDING_INVOICES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					pendingInvoiceTable: {
						...state.modalNew.pendingInvoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_DEBT_MODAL_NEW_SELECTED_PENDING_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPendingInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_PAYMENT_PAYMENTS:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					payments: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					selectedPayment: {
						index: action.payload.index,
						payment: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_PAYMENT_BANKS:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					banks: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_SELECTED_BANK:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					selectedBank: {
						index: action.payload.index,
						bank: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_PAYMENT_BANK_BRANCHES:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					branches: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_SELECTED_BRANCH:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_PAYMENT_DATE_TIME:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					paymentDateTime: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_NAME:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					transferBankName: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_TRANSFER_BANK_BRANCH:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					transferBankBranch: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_CHECK_NUM:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					checkNum: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_CHECK_DATE_TIME:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					checkDateTime: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_CREDIT_CARD_NUM:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					creditCardNum: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_AMOUNT:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					amount: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					transactionFee: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_WHT:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					withholdingTax: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					balanceAdjustment: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_PAYMENT_REMARK:
			return Object.assign({}, state, {
				modalPayment: {
					...state.modalPayment,
					remark: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT_GROUP:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					billingStatementGroupTable: {
						...state.modalEditInvoice.billingStatementGroupTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_GROUP:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					selectedGroup: {
						index: action.payload.index,
						group: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_EDIT_INVOICE_BILLING_STATEMENT:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					billingStatementTable: {
						...state.modalEditInvoice.billingStatementTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_DEBT_MODAL_EDIT_INVOICE_SELECTED_BS:
			return Object.assign({}, state, {
				modalEditInvoice: {
					...state.modalEditInvoice,
					selectedBillingStatement: {
						index: action.payload.index,
						billingStatement: action.payload.selected,
					},
				},
			})
		case SET_DEBT_MODAL_ADJUSTMENT_ID:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					id: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_ADJUSTMENT_CONTINUE:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					isContinue: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discount: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_PERCENT:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discountPercent: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_MAX:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					discountMax: action.payload.value,
				},
			})
		case SET_DEBT_MODAL_ADJUSTMENT_DISCOUNT_REMARK:
			return Object.assign({}, state, {
				modalAdjustment: {
					...state.modalAdjustment,
					remark: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default debtReducer
