import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients } from "~/redux/action"
import { SET_OPD_GCS_DISPLAY, SET_OPD_NURSE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import GCS from "~/view/component/glasgow-coma-scale/GCS"

class OPDModalGCS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalGCS",
		}
		this.gcsRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onRenderParentCallback = this.onRenderParentCallback.bind(this)
	}

	isDoneBtnDisabled() {
		if (!this.gcsRef.current || !this.gcsRef.current.state.datetime) {
			return true
		}
		let target = Object.assign({}, this.props.opd.selectedGCS.gcs, {
			dateTime: this.gcsRef.current.state.datetime,
			eyeOpening: this.gcsRef.current.state.eyeOpening,
			verbal: this.gcsRef.current.state.verbal,
			motor: this.gcsRef.current.state.motor,
		})
		return _.isEqual(target, this.props.opd.selectedGCS.gcs)
	}

	onRenderParentCallback() {
		this.forceUpdate()
	}

	onClose() {
		this.props.onModalDisplayAction(SET_OPD_GCS_DISPLAY, false)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveGCSs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				glasgowComaScales: [
					{
						id: this.props.opd.selectedGCS.gcs ? this.props.opd.selectedGCS.gcs.id : 0,
						dateTime: this.gcsRef.current.state.datetime,
						eyeOpening: this.gcsRef.current.state.eyeOpening,
						verbal: this.gcsRef.current.state.verbal,
						motor: this.gcsRef.current.state.motor,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
			this.props.onModalDisplayAction(SET_OPD_GCS_DISPLAY, false)
			this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, false)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.glasgowComaScale)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<GCS
							ref={this.gcsRef}
							onRenderParentCallback={this.onRenderParentCallback}
							gcs={
								this.props.opd.selectedGCS.gcs
									? {
											datetime: this.props.opd.selectedGCS.gcs.dateTime,
											eyeOpening: this.props.opd.selectedGCS.gcs.eyeOpening,
											verbal: this.props.opd.selectedGCS.gcs.verbal,
											motor: this.props.opd.selectedGCS.gcs.motor,
									  }
									: null
							}
						/>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button
							type={ResourceAssistance.Button.type.submit}
							variant={ResourceAssistance.Button.variant.green}
							disabled={this.isDoneBtnDisabled()}
						>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientGCSDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalGCS))
