import { ResourceAssistance } from "~/i18n"
import {
	SET_SA_END_DATETIME,
	SET_SA_FILTERS,
	SET_SA_HEALTH_CARE_PLANS,
	SET_SA_HEALTH_CARE_PLANS_LOADING,
	SET_SA_ITEMS,
	SET_SA_ITEMS_LOADING,
	SET_SA_LOCATION,
	SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE,
	SET_SA_MODAL_BUDGET_DOCTOR_FEE,
	SET_SA_MODAL_BUDGET_DRG,
	SET_SA_MODAL_INSPECT_REASON,
	SET_SA_MODAL_LOCATION_SELECTED_BRANCH,
	SET_SA_MODAL_LOCATION_SELECTED_ORG,
	SET_SA_MODAL_REJECT_REASON,
	SET_SA_OPERATING_ROOM_REQUESTS,
	SET_SA_OPERATING_ROOM_REQUESTS_LOADING,
	SET_SA_ORGS,
	SET_SA_ORGS_LOADING,
	SET_SA_SELECTED_OR_REQUEST,
	SET_SA_SERVICES,
	SET_SA_SERVICES_LOADING,
	SET_SA_START_DATETIME,
	SET_SA_TYPES,
	SET_SA_TYPES_LOADING,
	SET_SA_WORKFLOW_PROPERTY,
} from "~/redux/type"

const init = {
	startDateTime: undefined,
	endDateTime: undefined,
	location: null,
	workflowProperty: undefined,
	orgs: [],
	healthcarePlans: [],
	items: [],
	services: [],
	types: [],
	filters: [],
	isOrgsLoading: false,
	isHealthCarePlansLoading: false,
	isItemsLoading: false,
	isServicesLoading: false,
	isTypesLoading: false,
	selectedORRequest: {
		index: -1,
		request: null,
	},
	operatingRequestTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.startDate, ResourceAssistance.Message.endDate, ResourceAssistance.Message.orCase],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	modalLocation: {
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
	modalBudget: {
		doctorFee: undefined,
		anesthesiologistFee: undefined,
		DRG: undefined,
	},
	modalInspect: {
		reason: "",
	},
	modalReject: {
		reason: "",
	},
}

const surgicalAuthorizationReducer = (state = init, action) => {
	switch (action.type) {
		case SET_SA_ORGS:
			return Object.assign({}, state, {
				orgs: action.payload.value,
			})
		case SET_SA_ORGS_LOADING:
			return Object.assign({}, state, {
				isOrgsLoading: action.payload.isLoading,
			})
		case SET_SA_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_SA_WORKFLOW_PROPERTY:
			return Object.assign({}, state, {
				workflowProperty: action.payload.value,
			})
		case SET_SA_START_DATETIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_SA_END_DATETIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_SA_FILTERS:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_SA_HEALTH_CARE_PLANS:
			return Object.assign({}, state, {
				healthcarePlans: action.payload.value,
			})
		case SET_SA_HEALTH_CARE_PLANS_LOADING:
			return Object.assign({}, state, {
				isHealthCarePlansLoading: action.payload.isLoading,
			})
		case SET_SA_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_SA_MODAL_LOCATION_SELECTED_BRANCH:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		case SET_SA_OPERATING_ROOM_REQUESTS:
			return Object.assign({}, state, {
				operatingRequestTable: {
					...state.operatingRequestTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_SA_OPERATING_ROOM_REQUESTS_LOADING:
			return Object.assign({}, state, {
				operatingRequestTable: {
					...state.operatingRequestTable,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_SA_SELECTED_OR_REQUEST:
			return Object.assign({}, state, {
				selectedORRequest: {
					index: action.payload.index,
					request: action.payload.selected,
				},
			})
		case SET_SA_SERVICES:
			return Object.assign({}, state, {
				services: action.payload.value,
			})
		case SET_SA_SERVICES_LOADING:
			return Object.assign({}, state, {
				isServicesLoading: action.payload.isLoading,
			})
		case SET_SA_ITEMS:
			return Object.assign({}, state, {
				items: action.payload.value,
			})
		case SET_SA_ITEMS_LOADING:
			return Object.assign({}, state, {
				isItemsLoading: action.payload.isLoading,
			})
		case SET_SA_TYPES:
			return Object.assign({}, state, {
				types: action.payload.value,
			})
		case SET_SA_TYPES_LOADING:
			return Object.assign({}, state, {
				isTypesLoading: action.payload.isLoading,
			})
		case SET_SA_MODAL_BUDGET_DOCTOR_FEE:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					doctorFee: action.payload.value,
				},
			})
		case SET_SA_MODAL_BUDGET_ANESTHESIOLOGIST_FEE:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					anesthesiologistFee: action.payload.value,
				},
			})
		case SET_SA_MODAL_BUDGET_DRG:
			return Object.assign({}, state, {
				modalBudget: {
					...state.modalBudget,
					DRG: action.payload.value,
				},
			})
		case SET_SA_MODAL_REJECT_REASON:
			return Object.assign({}, state, {
				modalReject: {
					...state.modalReject,
					reason: action.payload.value,
				},
			})
		case SET_SA_MODAL_INSPECT_REASON:
			return Object.assign({}, state, {
				modalInspect: {
					...state.modalInspect,
					reason: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default surgicalAuthorizationReducer
