import { Select, Button as AntdButton } from "antd"
import React, { Component, Fragment } from "react"
import { Col, Row } from "react-bootstrap"
import { GrAdd } from "react-icons/gr"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setEclaimUcTable, setLoadingAction, setSelected, setValue } from "~/redux/action"
import { SET_ADM_WP_ECLAIMUC_MODAL_CODE, SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION, SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"
import { configEclaimVariable } from "../eclaimUcConfiguration/eclaimUcVariable"
import _ from "lodash"

const Property = {
	FILENAME: "FILE_NAME",
	FILEDESCRIPTION: "FILE_DESCRIPTION",
	VARIABLESFORMAT: "VARIABLES_FORMAT",
}

export class ConfigEclaimUcNewFile extends Component {
	constructor(props) {
		super(props)

		this.state = {
			componentName: "ConfigEclaimUcFile",
			variables: [],
			variable: {},
		}

		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onVariableChange = this.onVariableChange.bind(this)
		this.onAddDisabled = this.onAddDisabled.bind(this)
		this.onAddVariablesFormat = this.onAddVariablesFormat.bind(this)
		this.getVariableValue = this.getVariableValue.bind(this)
	}

	componentDidMount() {
		this.setState({ variables: configEclaimVariable.sort((a, b) => Utils.sort(a.displayName, b.displayName)) })
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.FILENAME:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_CODE, event.target.value)
				break
			case Property.FILEDESCRIPTION:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_DESCRIPTION, event.target.value)
				break
			case Property.VARIABLESFORMAT:
				this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT, event.target.value)
				break
			default:
				break
		}
	}

	onVariableChange(value) {
		this.setState((prev) => ({ variable: prev.variables[value] }))
	}

	getVariableValue() {
		let value = []

		this.state.variables.forEach((variable, idx) => {
			if (_.includes(this.state.variable.displayName, variable.displayName)) {
				value.push(idx)
			}
		})
		return value
	}

	onAddDisabled() {
		return this.state.variable.id ? false : true
	}

	onAddVariablesFormat() {
		const separator = ResourceAssistance.PROGRAM_DEFINED.split
		const variable = `${separator}${this.state.variable.id}${separator}`
		this.props.setValue(SET_ADM_WP_ECLAIMUC_MODAL_VARIABLESFORMAT, `${this.props.configEclaimUc.newModal.variablesFormat}${variable}`)
		this.setState({ variable: {} })
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.FILENAME}
							placeholder={translate(ResourceAssistance.Message.fileName)}
							value={this.props.configEclaimUc.newModal.code}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							name={Property.FILEDESCRIPTION}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.configEclaimUc.newModal.description}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<legend>{translate(ResourceAssistance.Message.variable)}</legend>
					</Col>
				</Row>
				<Row className="g-2 justify-content-end">
					<Col>
						<Select
							size={"large"}
							style={{ textAlign: "center", margin: 0 }}
							showSearch
							optionFilterProp="children"
							value={this.getVariableValue()}
							onChange={this.onVariableChange}
						>
							{Utils.renderSelects(this.state.variables, false, -1, "displayName", false)}
						</Select>
					</Col>
					<Col md="auto" style={{ justifyContent: "end" }}>
						<AntdButton
							title={"Add"}
							type="text"
							size="middle"
							shape="circle"
							disabled={this.onAddDisabled()}
							icon={<GrAdd size={ResourceAssistance.ReactIcon.size} onClick={this.onAddVariablesFormat} />}
						/>
					</Col>
				</Row>
				<Row style={{ paddingBottom: "20px" }}>
					<Col>
						<GInput
							required
							autoSize
							showCount
							minRows={10}
							name={Property.VARIABLESFORMAT}
							placeholder={translate(ResourceAssistance.Message.variablesFormat)}
							value={this.props.configEclaimUc.newModal.variablesFormat}
							onChange={this.onPropertyChange}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	configEclaimUc: state.admin.configEclaim.eclaimUC,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setLoadingAction,
			setSelected,
			setEclaimUcTable,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigEclaimUcNewFile))
