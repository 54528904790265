import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_PO_ADDRESS,
	SET_PO_DISPLAY_ALL_IND,
	SET_PO_EDIT_DISCOUNT,
	SET_PO_EDIT_TOTAL_PRICE_BEFORE_TAX,
	SET_PO_END_DATE,
	SET_PO_ITEMS,
	SET_PO_MODAL_EDIT_PAYMENTS,
	SET_PO_MODAL_EDIT_PAYMENT_TERMS,
	SET_PO_MODAL_EDIT_SELECTED_PAYMENT,
	SET_PO_ORDERS,
	SET_PO_REJECTED_REASON,
	SET_PO_SEARCH_NUM,
	SET_PO_SELECTED_PO,
	SET_PO_SELECTED_SUPPLIER,
	SET_PO_START_DATE,
	SET_PO_SUPPLIERS,
	SET_PO_MODAL_EDIT_REQUIRED_DATE,
	SET_PO_MODAL_EDIT_ROUTING_ID,
	SET_PO_SHIPPING_CONTACT_TEL,
	SET_PO_BILLING_CONTACT_TEL,
	SET_PO_MODAL_EDIT_SHIPPING_CONTACT_TEL,
	SET_PO_MODAL_EDIT_BILLING_CONTACT_TEL,
} from "../../type/type/PO"

export const setPoOrders = (orders) => {
	return (dispatch, getState) => {
		let filtered = orders
			.filter((order) => String(order.pr.id).includes(getState().purchaseOrder.searchNum.toLowerCase()))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let color = []
		if (getState().purchaseOrder.isDisplayAll) {
			color = filtered.map((order) => {
				let success = ResourceAssistance.CSS.Color.green
				let isDisplay = order.inspectorApproved && order.approvalApproved
				return [isDisplay, isDisplay ? success : ""]
			})
		} else {
			filtered = filtered.filter((order) => !(order.inspectorApproved && order.approvalApproved))
		}

		let body = filtered.map((order) => {
			return [Utils.formatDate(order.creationDateTime), Utils.formatTime(order.creationDateTime), order.pr.id, order.inspectorApproved, order.approvalApproved]
		})
		dispatch({
			type: SET_PO_ORDERS,
			payload: {
				original: orders,
				filtered: filtered,
				body: body,
				rowColor: color,
			},
		})
		return Promise.resolve()
	}
}

export const setPoSuppliers = (suppliers) => {
	return (dispatch, getState) => {
		let body = suppliers.map((supplier) => {
			let totalPriceBeforeTax = supplier.items.reduce((totalPrice, currentItem) => {
				return totalPrice.plus(
					Utils.BigNumber(currentItem.pricePerUnit)
						.times(1 - currentItem.discount / 100)
						.times(Utils.BigNumber(currentItem.amount).dividedBy(currentItem.minQtyPerOrder))
				)
			}, Utils.BigNumber(0))

			let taxedTotalPrice = supplier.items.reduce((total, item) => {
				return total.plus(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(1 + item.tax / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
				)
			}, Utils.BigNumber(0))

			let discountedTaxedTotalPrice = taxedTotalPrice.times(1 - supplier.orderDetail.discount / 100)

			let totalPriceAfterDiscount = totalPriceBeforeTax.times(1 - supplier.orderDetail.discount / 100)
			let supplierTax = _.isEmpty(supplier.items) ? 0 : supplier.items[0].tax
			supplierTax = supplier.items.some((item) => item.tax !== supplierTax) ? 0 : supplierTax
			Object.assign(supplier, {
				...supplier,
				totalPriceBeforeTax: totalPriceBeforeTax.toFixed(2),
				discountPrice: totalPriceBeforeTax.times(supplier.orderDetail.discount / 100).toFixed(2),
				totalPriceAfterDiscount: totalPriceAfterDiscount.toFixed(2),
				taxPrice: totalPriceAfterDiscount.times(supplierTax / 100).toFixed(2),
				totalPriceAfterDiscountAndTax: discountedTaxedTotalPrice.toFixed(2),
			})

			return [
				supplier.orderDetail.routingId,
				supplier.code,
				supplier.name,
				supplier.phone,
				Utils.formatNumWithComma(totalPriceBeforeTax.dp(2).toFixed(2)),
				Utils.formatNumWithComma(taxedTotalPrice.dp(2).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(supplier.orderDetail.discount).toFixed(2)),
				Utils.formatNumWithComma(discountedTaxedTotalPrice.dp(2).toFixed(2)),
				supplier.orderDetail.payment ? supplier.orderDetail.payment.displayName : "",
				supplier.orderDetail.paymentTerms,
				Utils.formatDate(supplier.orderDetail.requiredDateTime),
				supplier.orderDetail.attachmentFileName ? true : false,
			]
		})
		dispatch({
			type: SET_PO_SUPPLIERS,
			payload: {
				original: suppliers,
				filtered: suppliers,
				body: body,
			},
		})

		return Promise.resolve()
	}
}

export const setPoItems = (items) => {
	return (dispatch, getState) => {
		let body = items.map((item) => {
			return [
				item.code,
				item.displayName,
				Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()) +
					" x " +
					Utils.formatNumWithComma(item.minQtyPerOrder),
				item.displayUnit,
				Utils.BigNumber(item.configuredPricePerUnit).isEqualTo(0)
					? Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2))
					: Utils.formatNumWithComma(Utils.BigNumber(item.configuredPricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(item.discount).toFixed(2)),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.bonus),
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.dp(2)
						.toFixed(2)
				),
				Utils.formatNumWithComma(item.tax) + "%",
				Utils.formatNumWithComma(
					Utils.BigNumber(item.pricePerUnit)
						.times(1 - item.discount / 100)
						.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
						.times(1 + item.tax / 100)
						.dp(2)
						.toFixed(2)
				),
				[true],
			]
		})
		dispatch({
			type: SET_PO_ITEMS,
			payload: {
				original: items,
				filtered: items,
				body: body,
			},
		})

		return Promise.resolve()
	}
}

export const setPoStartDate = (date) => {
	return {
		type: SET_PO_START_DATE,
		payload: {
			date: date,
		},
	}
}

export const setPoEndDate = (date) => {
	return {
		type: SET_PO_END_DATE,
		payload: {
			date: date,
		},
	}
}

export const setPoSearchNum = (num) => {
	return {
		type: SET_PO_SEARCH_NUM,
		payload: {
			num: num,
		},
	}
}

export const setRejectedReason = (reason) => {
	return {
		type: SET_PO_REJECTED_REASON,
		payload: {
			reason: reason,
		},
	}
}

export const setPoSelectedPo = (index, po) => {
	return {
		type: SET_PO_SELECTED_PO,
		payload: {
			po: po,
			index: index,
		},
	}
}

export const setPoAddress = (address) => {
	return {
		type: SET_PO_ADDRESS,
		payload: {
			address: address,
		},
	}
}

export const setPoSelectedSupplier = (index, supplier) => {
	return {
		type: SET_PO_SELECTED_SUPPLIER,
		payload: {
			index: index,
			supplier: supplier,
		},
	}
}

export const setPoEditTaxedTotalPrice = (supplier) => {
	let total = supplier.items.reduce((totalPrice, currentItem) => {
		return totalPrice.plus(
			Utils.BigNumber(currentItem.pricePerUnit)
				.times(1 - currentItem.discount / 100)
				.times(1 + currentItem.tax / 100)
				.times(Utils.BigNumber(currentItem.amount).dividedBy(currentItem.minQtyPerOrder))
		)
	}, Utils.BigNumber(0))

	return {
		type: SET_PO_EDIT_TOTAL_PRICE_BEFORE_TAX,
		payload: {
			total: total.dp(2).toNumber(),
		},
	}
}

export const setPoEditDiscount = (discount) => {
	return (dispatch, getState) => {
		let price = Utils.BigNumber(getState().purchaseOrder.edit.totalPriceBeforeTax).times(discount / 100)
		dispatch({
			type: SET_PO_EDIT_DISCOUNT,
			payload: {
				discount: discount,
				discountPrice: Utils.formatNumWithComma(price.dp(2).toNumber()),
			},
		})

		return Promise.resolve()
	}
}

export const setPoDisplayAllInd = (isDisplayAll) => {
	return {
		type: SET_PO_DISPLAY_ALL_IND,
		payload: {
			isDisplayAll: isDisplayAll,
		},
	}
}

export const setPoModalEditPayments = (payments) => {
	return (dispatch, getState) => {
		dispatch({
			type: SET_PO_MODAL_EDIT_PAYMENTS,
			payload: {
				payments: payments,
			},
		})

		return Promise.resolve()
	}
}

export const setPoModalEditSelectedPayment = (index, payment) => {
	return {
		type: SET_PO_MODAL_EDIT_SELECTED_PAYMENT,
		payload: {
			index: index,
			payment: payment,
		},
	}
}

export const setPoModalEditPaymentTerms = (terms) => {
	return {
		type: SET_PO_MODAL_EDIT_PAYMENT_TERMS,
		payload: {
			terms: terms,
		},
	}
}

export const setPoModalEditRequiredDate = (date) => {
	return {
		type: SET_PO_MODAL_EDIT_REQUIRED_DATE,
		payload: {
			date: date,
		},
	}
}

export const setPoModalEditRoutingId = (id) => {
	return {
		type: SET_PO_MODAL_EDIT_ROUTING_ID,
		payload: {
			id: id,
		},
	}
}

export const setPoModalEditShippingContact = (tel) => {
	return {
		type: SET_PO_MODAL_EDIT_SHIPPING_CONTACT_TEL,
		payload: {
			tel: tel,
		},
	}
}

export const setPoModalEditBillingContact = (tel) => {
	return {
		type: SET_PO_MODAL_EDIT_BILLING_CONTACT_TEL,
		payload: {
			tel: tel,
		},
	}
}

export const setPoShippingContact = (tel) => {
	return {
		type: SET_PO_SHIPPING_CONTACT_TEL,
		payload: {
			tel: tel,
		},
	}
}

export const setPoBillingContact = (tel) => {
	return {
		type: SET_PO_BILLING_CONTACT_TEL,
		payload: {
			tel: tel,
		},
	}
}
