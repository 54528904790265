import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ReportCashierBillingToTodayFilterAdmission from "./ReportCashierBillingToTodayFilterAdmission"

class ReportCashierBillingToTodayFilterDetails extends React.Component {
	render() {
		return (
			<Container fluid>
				<ReportCashierBillingToTodayFilterAdmission />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportCashierBillingToTodayFilterDetails)
