import { Divider, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Dropdown, FormCheck, Row, Spinner } from "react-bootstrap"
import DropdownItem from "react-bootstrap/DropdownItem"
import DropdownMenu from "react-bootstrap/DropdownMenu"
import DropdownToggle from "react-bootstrap/DropdownToggle"
import { FaRegEdit } from "react-icons/fa"
import { FiRefreshCw } from "react-icons/fi"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	onModalElementDisplayAction,
	setAdminLocDisplayBranch,
	setAdminLocDisplayInventory,
	setAdminLocDisplayLocation,
	setAdminLocDisplayOrg,
	setAdminlocModalNLTitle,
	setLoadingAction,
	setLocSearchText,
	setLocation,
	setLocationDisplayInactive,
	setLocationType,
	setParentLocs,
	setParentOrgLocs,
	setSelectedLoc,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY,
	SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY,
	SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY,
	SET_LOCATION_LOADING,
	SET_LOC_BRANCH,
	SET_LOC_CHART_OF_ACCOUNTS,
	SET_LOC_GENERAL,
	SET_LOC_INVENTORY,
	SET_LOC_IPD,
	SET_LOC_OPD,
	SET_LOC_OPERATING_ROOM,
	SET_LOC_ORG,
	SET_NEW_LOCATION_DISPLAY,
	SET_NEW_LOCATION_PARENT_ORG_DISPLAY,
	SET_NEW_LOCATION_TAX_NO_DISPLAY,
	SET_PAGE_LOADING,
	SET_ADM_LOC_ORGS,
	SET_ADM_LOCATION_FILTER,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import "./location.css"
import { injectIntl } from "react-intl"

const EType = {
	Org: "ORG",
	Branch: "BRANCH",
	Inventory: "INVENTORY",
	General_Location: "GENERAL_LOCATION",
	IPD: "IPD",
	OPD: "OPD",
	PHARMACY: "PHARMACY",
	OR: "OPERATING_ROOM",
}
class Location extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(EType)
		this.state = {
			componentName: "Location",
			CADMLocationFilters: this.getInitialFilters(),
		}

		this.onChangeFilter = this.onChangeFilter.bind(this)
		this.onSelectRowClick = this.onSelectRowClick.bind(this)
		this.onAddLocationClick = this.onAddLocationClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
		this.onRefreshClick = this.onRefreshClick.bind(this)
		this.loadLocs = this.loadLocs.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSearchChange = this.onSearchChange.bind(this)
	}

	componentDidMount() {
		this.loadLocs()
		if (_.isEmpty(this.props.loc.chartOfAccounts)) {
			this.loadChartOfAccounts()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.language.locale, this.props.language.locale)) {
			this.setState({
				CAccountsPayableFilters: this.getInitialFilters(),
			})
		}

		if (
			!_.isEqual(prevProps.loc.searchText, this.props.loc.searchText) ||
			!_.isEqual(prevProps.loc.filters, this.props.loc.filters) ||
			!_.isEqual(prevProps.loc.displayInactive, this.props.loc.displayInactive)
		) {
			this.props.setSelectedLoc(-1, null)
			this.props.setLocation(this.props.loc.locations.locations)
		}
	}

	getInitialFilters() {
		return [
			{
				id: ResourceAssistance.Message.org,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.org }),
			},
			{
				id: ResourceAssistance.Message.branch,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.branch }),
			},
			{
				id: ResourceAssistance.Message.inventory,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.inventory }),
			},
			{
				id: ResourceAssistance.Message.opd,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.opd }),
			},
			{
				id: ResourceAssistance.Message.ipd,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.ipd }),
			},
			{
				id: ResourceAssistance.Message.pharmacy,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.pharmacy }),
			},
			{
				id: ResourceAssistance.Message.operatingRoom,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.operatingRoom }),
			},
			{
				id: ResourceAssistance.Message.location,
				displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.location }),
			},
		]
	}

	loadChartOfAccounts() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.chartOfAccounts.getChartOfAccounts,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_LOC_CHART_OF_ACCOUNTS, Utils.getChartOfAccountsByGroup(Utils.groupBy(res.data.accounts, "coreCodeName")))
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadLocs() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.admin.location.loadLocs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			Utils.convertToObject(res.data.locations)
			this.props.setLocation(res.data.locations)
			this.props.setValue(
				SET_ADM_LOC_ORGS,
				res.data.locations.filter((loc) => loc.code.displayName === "ORG" && loc.active)
			)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_LOCATION_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_LOCATION_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	onSelectRowClick(item, index) {
		this.props.setSelectedLoc(index, this.props.loc.locations.filtered[index])
	}

	onAddLocationClick(event) {
		this.props.setSelectedLoc(-1, null)
		switch (event.target.name) {
			case EType.Org:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.org))
				this.props.setLocationType(SET_LOC_ORG, true)
				this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_TAX_NO_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.Branch:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.branch))
				this.props.setLocationType(SET_LOC_BRANCH, true)
				this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.Inventory:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.inventory))
				this.props.setLocationType(SET_LOC_INVENTORY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.General_Location:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_GENERAL, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.IPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Hospitel.ipd))
				this.props.setLocationType(SET_LOC_IPD, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.OPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.opd))
				this.props.setLocationType(SET_LOC_OPD, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			case EType.OR:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.operatingRoom))
				this.props.setLocationType(SET_LOC_OPERATING_ROOM, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			default:
				break
		}
	}

	onEditBtnClick(event) {
		switch (this.props.loc.selectedLoc.loc.code.displayName) {
			case EType.Org:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.org))
				this.props.setLocationType(SET_LOC_ORG, true)
				this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_TAX_NO_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				break
			case EType.Branch:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.branch))
				this.props.setLocationType(SET_LOC_BRANCH, true)
				this.props.onModalElementDisplayAction(SET_INVENTORY_NEW_LOCATION_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_BILLING_ADDRESS_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_NAME_ENG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_PAYABLE_DISPLAY, true)
				break
			case EType.Inventory:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.inventory))
				this.props.setLocationType(SET_LOC_INVENTORY, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				break
			case EType.PHARMACY:
			case EType.General_Location:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_GENERAL, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_LOCATION_PHARMACY_IND_DISPLAY, true)
				break
			case EType.IPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_IPD, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				break
			case EType.OPD:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.department))
				this.props.setLocationType(SET_LOC_OPD, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalElementDisplayAction(SET_ADM_LOC_MODAL_NEW_ACCOUNTS_RECEIVABLE_DISPLAY, true)
				break
			case EType.OR:
				this.props.setAdminlocModalNLTitle(translate(ResourceAssistance.Message.operatingRoom))
				this.props.setLocationType(SET_LOC_OPERATING_ROOM, true)
				this.props.onModalElementDisplayAction(SET_NEW_LOCATION_PARENT_ORG_DISPLAY, true)
				this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
				break

			default:
				break
		}

		this.props.onModalDisplayAction(SET_NEW_LOCATION_DISPLAY, true)
	}

	onRefreshClick(event) {
		this.loadLocs()
	}

	getFilters() {
		let filters = []
		this.props.loc.filters.forEach((each) => {
			this.state.CADMLocationFilters.forEach((filter, idx) => {
				if (filter.id === each) {
					filters.push(idx)
				}
			})
		})
		return filters
	}

	onChangeFilter(values) {
		let filters = values.map((each) => this.state.CADMLocationFilters[each].id)
		this.props.setValue(SET_ADM_LOCATION_FILTER, filters)
	}

	onDisplayAll(event) {
		this.props.setLocationDisplayInactive(event.target.checked)
	}

	onSearchChange(event) {
		this.props.setLocSearchText(event.target.value)
	}

	render() {
		return (
			<div className={`${ResourceAssistance.CSS.fullFlex}`} style={{ padding: "0px 15px" }}>
				<Container fluid id={this.state.componentName} className={`border ${ResourceAssistance.CSS.fullFlex}`}>
					<Row>
						<Col>
							<SearchBox
								controlTypes={[ResourceAssistance.FormControl.type.text]}
								placeholders={[ResourceAssistance.Message.name]}
								callbacks={[this.onSearchChange]}
								values={[this.props.loc.searchText]}
							/>
						</Col>
						<Col sm="auto">
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
						<Col md={3}>
							<Select
								mode={ResourceAssistance.Select.mode.multiple}
								allowClear
								style={{ flexGrow: 1, textAlign: "center" }}
								maxTagCount="responsive"
								optionFilterProp="children"
								placeholder={translate(ResourceAssistance.Message.filter)}
								onChange={this.onChangeFilter}
								value={this.getFilters()}
							>
								{Utils.renderSelects(this.state.CADMLocationFilters, false, -1, "displayName", false)}
							</Select>
						</Col>
						<Col sm="auto">
							<FormCheck
								type={ResourceAssistance.FormControl.type.checkBox}
								label={translate(ResourceAssistance.Message.displayInactive)}
								checked={this.props.loc.displayInactive}
								onChange={this.onDisplayAll}
							/>
						</Col>
						<Col sm="auto">
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
						<Col sm="auto">
							<Dropdown>
								<DropdownToggle variant={ResourceAssistance.Button.variant.primary} size={ResourceAssistance.Button.size.sm}>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.add)}
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem name={EType.Org} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.org)}
									</DropdownItem>
									<DropdownItem name={EType.Branch} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.branch)}
									</DropdownItem>
									<DropdownItem name={EType.Inventory} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.inventory)}
									</DropdownItem>
									<DropdownItem name={EType.General_Location} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.department)}
									</DropdownItem>
									<DropdownItem name={EType.IPD} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Hospitel.ipd)}
									</DropdownItem>
									<DropdownItem name={EType.OPD} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.opd)}
									</DropdownItem>
									<DropdownItem name={EType.OR} onClick={this.onAddLocationClick}>
										{translate(ResourceAssistance.Message.operatingRoom)}
									</DropdownItem>
								</DropdownMenu>
							</Dropdown>
						</Col>

						<Col sm="auto">
							<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.loc.selectedLoc.index === -1} onClick={this.onEditBtnClick}>
								<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.edit)}
							</Button>
						</Col>
						<Col sm="auto">
							<Button size={ResourceAssistance.Button.size.sm} onClick={this.onRefreshClick}>
								<FiRefreshCw size={ResourceAssistance.ReactIcon.size} />
							</Button>
						</Col>
					</Row>
					<Row className={ResourceAssistance.CSS.fullSize}>
						<Col>
							<ScrollableTable
								striped
								enableHighlight
								isClearHighlight={this.props.loc.selectedLoc.index === -1}
								data={this.props.loc.locations}
								onClick={this.onSelectRowClick}
							/>
							{this.props.loc.locations.isLoading && (
								<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
							)}
						</Col>
					</Row>
				</Container>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	language: state.language,
	loc: state.admin.location,
	modal: state.modal.newLoc,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			onModalElementDisplayAction,
			setAdminLocDisplayBranch,
			setAdminLocDisplayInventory,
			setAdminLocDisplayLocation,
			setAdminLocDisplayOrg,
			setAdminlocModalNLTitle,
			setLoadingAction,
			setLocSearchText,
			setLocation,
			setLocationDisplayInactive,
			setLocationType,
			setParentLocs,
			setParentOrgLocs,
			setSelectedLoc,
			setValue,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Location))
