import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests } from "~/redux/action"
import { SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import ORModalIntraAneServiceDetails from "./ORModalIntraAneServiceDetails"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"
import _ from "lodash"

class ORModalIntraAneService extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_SERVICE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return _.isEmpty(this.props.operatingRoom.modalIntraAneService.pendingServiceTable.filtered.filter((each) => !each.isExistService))
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let locationAccountsReceivable = _.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.ipds)
			? this.props.operatingRoom.selectedRequest.request.clinicalRecord.medicalRecords[0].location.accountsReceivable
			: this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.ipds[0].location.accountsReceivable
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveAnesthesiaDoctorOrders,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				pid: this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.id,
				clinicalRecordId: this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.id,
				userId: this.props.login.user.id,
				doctorOrders: this.props.operatingRoom.modalIntraAneService.pendingServiceTable.original
					.filter((each) => !each.isExistService)
					.map((order) => ({
						intraAnesthesiaRecordId: this.props.operatingRoom.selectedIntraAneRecord.record.id,
						categoryId: order.categoryId,
						serviceCode: order.code,
						fsCode: order.fsCode,
						description: Utils.trim(order.name),
						startDateTime: order.startDateTime,
						endDateTime: order.endDateTime,
						duration: order.treatmentTime,
						pricePerUnit: order.pricePerUnit,
						qtyPerDay: 1,
						categoryName: order.description,
						categoryNum: order.categoryNum,
						outpatient: _.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.ipds),
						locationAccountsReceivable: locationAccountsReceivable,
					})),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.service)}</ModalTitle>
				</ModalHeader>
				<ModalBody ref={this.props.modalBodyRef}>
					<ORModalIntraAneServiceDetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isOperatingRoomIntraAnesthesiaServiceDisplay,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setOperatingRoomRequests,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(ORModalIntraAneService)
