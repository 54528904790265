export const SET_PI_CHART_OF_ACCOUNTS = "SET_PI_CHART_OF_ACCOUNTS"
export const SET_PI_CHART_OF_ACCOUNTS_LOADING = "SET_PI_CHART_OF_ACCOUNTS_LOADING"
export const SET_PI_END_DATE_TIME = "SET_PI_END_DATE_TIME"
export const SET_PI_FILTERS = "SET_PI_FILTERS"
export const SET_PI_FILTER_PURCHASE_INVOICE_ID = "SET_PI_FILTER_PURCHASE_INVOICE_ID"
export const SET_PI_INVOICES = "SET_PI_INVOICES"
export const SET_PI_LOCATION = "SET_PI_LOCATION"
export const SET_PI_LOCATION_DISPLAY = "SET_PI_LOCATION_DISPLAY"
export const SET_PI_LINK_DISPLAY = "SET_PI_LINK_DISPLAY"
export const SET_PI_MODAL_LINK_LINKS = "SET_PI_MODAL_LINK_LINKS"
export const SET_PI_MODAL_LINK_SELECTED_LINK = "SET_PI_MODAL_LINK_SELECTED_LINK"
export const SET_PI_MODAL_LOCATION_ORGS = "SET_PI_MODAL_LOCATION_ORGS"
export const SET_PI_MODAL_LOCATION_ORGS_LOADING = "SET_PI_MODAL_LOCATION_ORGS_LOADING"
export const SET_PI_MODAL_LOCATION_SELECTED_ORG = "SET_PI_MODAL_LOCATION_SELECTED_ORG"
export const SET_PI_MODAL_NEW_DUE_DATE_TIME = "SET_PI_MODAL_NEW_DUE_DATE_TIME"
export const SET_PI_MODAL_NEW_INVOICE_DATE_TIME = "SET_PI_MODAL_NEW_INVOICE_DATE_TIME"
export const SET_PI_MODAL_NEW_INVOICE = "SET_PI_MODAL_NEW_INVOICE"
export const SET_PI_MODAL_NEW_PURCHASES = "SET_PI_MODAL_NEW_PURCHASES"
export const SET_PI_MODAL_NEW_REMARK = "SET_PI_MODAL_NEW_REMARK"
export const SET_PI_MODAL_NEW_SELECTED_PAYMENT = "SET_PI_MODAL_NEW_SELECTED_PAYMENT"
export const SET_PI_MODAL_NEW_SELECTED_PURCHASE = "SET_PI_MODAL_NEW_SELECTED_PURCHASE"
export const SET_PI_MODAL_NEW_SELECTED_SUPPLIER = "SET_PI_MODAL_NEW_SELECTED_SUPPLIER"
export const SET_PI_MODAL_NEW_WHT_ISSUED_DATE_TIME = "SET_PI_MODAL_NEW_WHT_ISSUED_DATE_TIME"
export const SET_PI_MODAL_PURCHASE_AMOUNT = "SET_PI_MODAL_PURCHASE_AMOUNT"
export const SET_PI_MODAL_PURCHASE_DESCRIPTION = "SET_PI_MODAL_PURCHASE_DESCRIPTION"
export const SET_PI_MODAL_PURCHASE_WHT_PERCENT = "SET_PI_MODAL_PURCHASE_WHT_PERCENT"
export const SET_PI_MODAL_PURCHASE_SELECTED_GL = "SET_PI_MODAL_PURCHASE_SELECTED_GL"
export const SET_PI_MODAL_PURCHASE_SELECTED_TAX = "SET_PI_MODAL_PURCHASE_SELECTED_TAX"
export const SET_PI_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE = "SET_PI_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE"
export const SET_PI_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX = "SET_PI_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX"
export const SET_PI_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER = "SET_PI_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER"
export const SET_PI_MODAL_SEARCH_PURCHASE_INVOICE_ID = "SET_PI_MODAL_SEARCH_PURCHASE_INVOICE_ID"
export const SET_PI_NEW_DISPLAY = "SET_PI_NEW_DISPLAY"
export const SET_PI_PAYMENT_TYPES = "SET_PI_PAYMENT_TYPES"
export const SET_PI_PAYMENT_TYPES_LOADING = "SET_PI_PAYMENT_TYPES_LOADING"
export const SET_PI_PURCHASES = "SET_PI_PURCHASES"
export const SET_PI_PURCHASE_DISPLAY = "SET_PI_PURCHASE_DISPLAY"
export const SET_PI_SEARCH_DISPLAY = "SET_PI_SEARCH_DISPLAY"
export const SET_PI_SELECTED_PI = "SET_PI_SELECTED_PI"
export const SET_PI_SELECTED_PURCHASE = "SET_PI_SELECTED_PURCHASE"
export const SET_PI_START_DATE_TIME = "SET_PI_START_DATE_TIME"
export const SET_PI_SUPPLIERS = "SET_PI_SUPPLIERS"
export const SET_PI_SUPPLIERS_LOADING = "SET_PI_SUPPLIERS_LOADING"
export const SET_PI_TAXES = "SET_PI_TAXES"
export const SET_PI_TAXES_LOADING = "SET_PI_TAXES_LOADING"
export const SET_PI_WITHHOLDING_TAXES = "SET_PI_WITHHOLDING_TAXES"
export const SET_PI_WITHHOLDING_TAXES_LOADING = "SET_PI_WITHHOLDING_TAXES_LOADING"
export const SET_PI_GL_DATE_TIME = "SET_PI_GL_DATE_TIME"
