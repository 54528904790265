import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setHospitelPatients, setSelected, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_IPD_DTX_DISPLAY, SET_PAGE_LOADING, SET_IPD_SELECTED_DTX, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class IPDRightDTXTable extends React.Component {
	constructor(props) {
		super(props)

		this.onNew = this.onNew.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
		this.onSelect = this.onSelect.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.ipd.deleteDTX)) {
				this.deleteDTX()
			}
		}
		if (!_.isEqual(prevProps.ipd.glucoseTable.filtered, this.props.ipd.glucoseTable.filtered)) {
			let index = this.props.ipd.selectedDTX.DTX ? this.props.ipd.glucoseTable.filtered.findIndex((each) => each.id === this.props.ipd.selectedDTX.DTX.id) : -1
			if (index > -1) {
				this.props.setSelected(SET_IPD_SELECTED_DTX, index, this.props.ipd.glucoseTable.filtered[index])
			} else {
				this.props.setSelected(SET_IPD_SELECTED_DTX, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_IPD_SELECTED_DTX, -1, null)
	}

	deleteDTX() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.deleteGlucose,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.ipd.selectedPatient.patient.id,
				glucoses: [
					{
						id: this.props.ipd.selectedDTX.DTX.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setHospitelPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onNew(e) {
		this.props.setSelected(SET_IPD_SELECTED_DTX, -1, null)
		this.props.onModalDisplayAction(SET_IPD_DTX_DISPLAY, true)
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.setSelected(SET_IPD_SELECTED_DTX, rIdx, this.props.ipd.glucoseTable.filtered[rIdx])
		this.props.onModalDisplayAction(SET_IPD_DTX_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedDTX = this.props.ipd.glucoseTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.ipd.deleteDTX)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.DTX })}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })}: ${Utils.formatDateTime(selectedDTX.dateTime)}
		    `
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSelect(io, idx) {
		this.props.setSelected(SET_IPD_SELECTED_DTX, idx, this.props.ipd.glucoseTable.filtered[idx])
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.ipd.glucoseTable}
						isClearHighlight={this.props.ipd.selectedDTX.index === -1}
						highlightedRow={this.props.ipd.selectedDTX.index}
						onClick={this.onSelect}
						extra={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, this.props.login.user.roles) &&
							this.props.ipd.selectedHospitel.hospitel &&
							!this.props.ipd.selectedHospitel.hospitel.dischargeSummary && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setWarningId,
			setWarningMsgAction,
			setHospitelPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightDTXTable))
