import { Space } from "antd"
import PropTypes from "prop-types"
import { Button, Form } from "react-bootstrap"
import { ResourceAssistance, translate } from "~/i18n"

const LoginForm = (props) => {
	return (
		<div className={ResourceAssistance.CSS.TextCenter}>
			<Form onSubmit={props.handleSubmit}>
				<Space direction="vertical" style={{ width: "300px" }}>
					<Form.Group>
						<Form.Label>{translate(ResourceAssistance.Message.username)}</Form.Label>
						<Form.Control
							autoFocus
							type={ResourceAssistance.FormControl.type.text}
							name={ResourceAssistance.LoginForm.username}
							onChange={props.handleChange}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>{translate(ResourceAssistance.Message.password)}</Form.Label>
						{/*<Form.Control type="password" name={props.intl.formatMessage({id: "hello"})}*/}
						{/*               onChange={props.handleChange} />*/}
						<Form.Control
							type={ResourceAssistance.FormControl.type.password}
							name={ResourceAssistance.LoginForm.password}
							onChange={props.handleChange}
						/>
					</Form.Group>
					<Button variant={ResourceAssistance.Button.variant.primary} type={ResourceAssistance.Button.type.submit}>
						{translate(ResourceAssistance.Message.login)}
					</Button>
				</Space>
			</Form>
		</div>
	)
}

LoginForm.propTypes = {
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
}

// export default injectIntl(LoginForm)
export default LoginForm
