import { Divider, Select } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_PAGE_LOADING,
	SET_REPORT_FINANCE_ARA_AGINGS,
	SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME,
	SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY,
	SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES,
	SET_REPORT_FINANCE_ARA_SELECTED_ORG,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class ReportFinanceARAFilters extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
		this.onDateChange = this.onDateChange.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onInsuranceCompany = this.onInsuranceCompany.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.filter.selectedOrg, this.props.filter.selectedOrg)) {
			this.props.setValue(SET_REPORT_FINANCE_ARA_AGINGS, [])
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
		this.props.setSelected(SET_REPORT_FINANCE_ARA_SELECTED_ORG, -1, null)
		this.props.setValue(SET_REPORT_FINANCE_ARA_AGINGS, [])
		this.props.setValue(SET_REPORT_FINANCE_ARA_INSURANCE_COMPANIES, [])
		this.props.setValue(SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY, [])
	}

	onOrg(value) {
		if (value === undefined) {
			this.props.setSelected(SET_REPORT_FINANCE_ARA_SELECTED_ORG, -1, null)
			return
		}
		this.props.setSelected(SET_REPORT_FINANCE_ARA_SELECTED_ORG, value, this.props.filter.orgs[value])
	}

	onDateChange(v) {
		this.props.setValue(SET_REPORT_FINANCE_ARA_FILTER_DATE_TIME, v ? v.endOf("day").milliseconds(0).valueOf() : "")
	}

	onInsuranceCompany(values) {
		let insuranceCompanies = values.map((each) => this.props.filter.insuranceCompanies[each].displayCodeName)
		this.props.setValue(SET_REPORT_FINANCE_ARA_FILTER_INSURANCE_COMPANY, insuranceCompanies)
	}

	onSearch() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.report.finance.getAccountsReceivableAgings,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.filter.selectedOrg.org.id,
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_REPORT_FINANCE_ARA_AGINGS, res.data.accountsReceivableAgings)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row className="g-1">
				<Col md={2}>
					<Select
						showSearch
						style={{ textAlign: "center", margin: 0 }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.org)}
						loading={this.props.filter.isOrgsLoading}
						value={this.props.filter.selectedOrg.org ? this.props.filter.selectedOrg.index : undefined}
						onChange={this.onOrg}
					>
						{Utils.renderSelects(this.props.filter.orgs, false)}
					</Select>
				</Col>
				<Col md="auto">
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.primary} disabled={!this.props.filter.selectedOrg.org} onClick={this.onSearch}>
							<GoSearch size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.search)}
						</Button>
					</Col>
				</Col>
				<Col md="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<AntDatePicker
						format={"DD-MM-YYYY"}
						allowClear={false}
						placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.date })]}
						defaultValues={[moment(this.props.filter.filterDateTime)]}
						onChange={this.onDateChange}
					/>
				</Col>
				<Col>
					<Select
						mode={ResourceAssistance.Select.mode.multiple}
						allowClear
						style={{ flexGrow: 1, textAlign: "center" }}
						maxTagCount="responsive"
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.insuranceCompany)}
						loading={this.props.filter.isInsuranceCompaniesLoading}
						onChange={this.onInsuranceCompany}
					>
						{Utils.renderSelects(this.props.filter.insuranceCompanies, false, -1, "displayCodeName")}
					</Select>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.finance.accountsReceivableAging,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportFinanceARAFilters))
