import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setAccountsReceivableInvoices, setSelected } from "~/redux/action"
import { SET_AR_SELECTED_INVOICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsReceivableLeftInvoice extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AccountsReceivableLeftInvoice",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.AR.invoiceTable.filtered, this.props.AR.invoiceTable.filtered)) {
			let index = this.props.AR.selectedInvoice.invoice
				? this.props.AR.invoiceTable.filtered.findIndex(
						(invoice) => invoice.id === this.props.AR.selectedInvoice.invoice.id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AR_SELECTED_INVOICE, index, this.props.AR.invoiceTable.filtered[index])
			} else {
				this.props.setSelected(SET_AR_SELECTED_INVOICE, -1, null)
			}
		}
		if (
			!_.isEqual(prevProps.AR.filterInvoiceId, this.props.AR.filterInvoiceId) ||
			!_.isEqual(prevProps.AR.filters, this.props.AR.filters)
		) {
			this.props.setAccountsReceivableInvoices(this.props.AR.invoiceTable.original)
		}
	}

	selectRow(plan, index) {
		this.props.setSelected(SET_AR_SELECTED_INVOICE, index, this.props.AR.invoiceTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.AR.invoiceTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.AR.selectedInvoice.index === -1}
						highlightedRow={this.props.AR.selectedInvoice.index}
					/>
					{this.props.AR.invoiceTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	AR: state.accounting.accountsReceivable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAccountsReceivableInvoices,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsReceivableLeftInvoice))
