import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import RCRightFirstRow from "./RCRightFirstRow"
import RCRightSecondRow from "./RCRightSecondRow"

class RCRight extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCRight",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<RCRightFirstRow />
				<RCRightSecondRow />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(RCRight)
