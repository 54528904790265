import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportOPDDoctorPrescriptionDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				orders: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayTitle={false}
							displayBranch
							displayFax={false}
							displayTaxNum={false}
						/>

						{/* Doctor Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoBody]}>
									<Text>{this.state.data.doctorName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoBody]}>
									<Text>
										{this.state.data.licenseIntl}: {this.state.data.doctorLicense}
									</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.infoBody]}>
									<Text>{this.state.data.doctorPhone}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/*Patient Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID/{this.state.data.identificationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.identification}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age/{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB/{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Address/{this.state.data.addressIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientAddress}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Tel/{this.state.data.telIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientTel}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Item Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.medicine]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.medicineIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.dose]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.doseIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.duration]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.durationIntl}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.medicine]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Medicine</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.dose]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Dose</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.duration]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Duration</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{/* Items */}
						{this.state.data.orders.map((order) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.medicine]}>
										<Text>{order.description}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.dose]}>
										<Text>{order.notes}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.duration]}>
										<Text>
											{order.duration}
											{"\n"}({order.totalQty})
										</Text>
									</View>
								</View>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />

						{/* Signature */}
						<Signature
							signatureDate={this.state.data.signatureDate}
							numOfSignature={1}
							signatureTitles={this.state.data.signatureTitles}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 95,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	medicine: {
		minWidth: "60%",
		maxWidth: "60%",
	},
	dose: {
		minWidth: "25%",
		maxWidth: "25%",
	},
	duration: {
		minWidth: "15%",
		maxWidth: "15%",
	},
})

export default ReportOPDDoctorPrescriptionDocument
