import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_VS_BP,
	SET_OR_MODAL_VS_DATE_TIME,
	SET_OR_MODAL_VS_OXYGEN_SAT,
	SET_OR_MODAL_VS_PR,
	SET_OR_MODAL_VS_RR,
	SET_OR_MODAL_VS_TEMP,
} from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class OperatingRoomModalVitalSignDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onTemperature = this.onTemperature.bind(this)
		this.onPR = this.onPR.bind(this)
		this.onRR = this.onRR.bind(this)
		this.onBP = this.onBP.bind(this)
		this.onOxygenSat = this.onOxygenSat.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.vitalSign) {
			this.props.setValue(SET_OR_MODAL_VS_DATE_TIME, this.props.operatingRoom.vitalSign.dateTime)
			this.props.setValue(SET_OR_MODAL_VS_TEMP, String(this.props.operatingRoom.vitalSign.temperature))
			this.props.setValue(SET_OR_MODAL_VS_PR, String(this.props.operatingRoom.vitalSign.pr))
			this.props.setValue(SET_OR_MODAL_VS_RR, String(this.props.operatingRoom.vitalSign.rr))
			this.props.setValue(SET_OR_MODAL_VS_BP, String(this.props.operatingRoom.vitalSign.bp))
			this.props.setValue(SET_OR_MODAL_VS_OXYGEN_SAT, String(this.props.operatingRoom.vitalSign.oxygenSat))
		} else {
			this.props.setValue(SET_OR_MODAL_VS_DATE_TIME, moment().milliseconds(0).valueOf())
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_VS_TEMP, "")
		this.props.setValue(SET_OR_MODAL_VS_PR, "")
		this.props.setValue(SET_OR_MODAL_VS_RR, "")
		this.props.setValue(SET_OR_MODAL_VS_BP, "")
		this.props.setValue(SET_OR_MODAL_VS_OXYGEN_SAT, "")
	}
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col />
					<Col md="auto">
						<AntDatePicker
							allowClear={false}
							size={"middle"}
							format={"DD-MM-YYYY  HH:mm"}
							showTime={{ format: "HH:mm" }}
							values={[moment(this.props.operatingRoom.modalVitalSign.vsDateTime)]}
							onChange={this.onDateTime}
						/>
					</Col>
					<Col />
				</Row>
				<Row>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.temperature)}
							value={this.props.operatingRoom.modalVitalSign.vsTemp}
							onChange={this.onTemperature}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.prPerMin)}
							value={this.props.operatingRoom.modalVitalSign.vsPR}
							onChange={this.onPR}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.rrPerMin)}
							value={this.props.operatingRoom.modalVitalSign.vsRR}
							onChange={this.onRR}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.bpmmHg)}
							value={this.props.operatingRoom.modalVitalSign.vsBP}
							onChange={this.onBP}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.o2Sat)}
							value={this.props.operatingRoom.modalVitalSign.vsOxygenSat}
							onChange={this.onOxygenSat}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onTemperature(e) {
		this.props.setValue(SET_OR_MODAL_VS_TEMP, e.target.value)
	}
	onPR(e) {
		this.props.setValue(SET_OR_MODAL_VS_PR, e.target.value)
	}
	onRR(e) {
		this.props.setValue(SET_OR_MODAL_VS_RR, e.target.value)
	}
	onBP(e) {
		this.props.setValue(SET_OR_MODAL_VS_BP, e.target.value)
	}
	onOxygenSat(e) {
		this.props.setValue(SET_OR_MODAL_VS_OXYGEN_SAT, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalVitalSignDetails)
