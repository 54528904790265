import { SET_NOTIFCATION_PATIENTS, SET_NOTIFICATION_NOTIFICATIONS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setNotificationPatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients.sort((a, b) =>
			Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber())
		)

		let body = filtered.map((patient) => {
			return [
				patient.id,
				patient.identificationNumber,
				patient.firstName,
				patient.lastName,
				Utils.formatDate(patient.dobtime),
			]
		})

		dispatch({
			type: SET_NOTIFCATION_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setNotifications = (notifications) => {
	return (dispatch, getState) => {
		let filtered = notifications.sort((a, b) => Utils.sort(a.lastModifiedDateTime, b.lastModifiedDateTime))

		let body = filtered.map((notification) => {
			return [
				Utils.formatDate(notification.lastModifiedDateTime),
				Utils.formatTime(notification.lastModifiedDateTime),
				notification.message,
				notification.lastModifiedBy.displayName,
				true,
			]
		})

		dispatch({
			type: SET_NOTIFICATION_NOTIFICATIONS,
			payload: {
				original: notifications,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
