import { combineReducers } from "redux"
import typeReducer from "./typeReducer"
import locationReducer from "./locationReducer"
import itemConfigReducer from "./itemConfigReducer"
import userConfigReducer from "./userConfigReducer"
import serviceConfigReducer from "./serviceConfigReducer"
import customDefaultConfigReducer from "./customDefaultConfigReducer"
import ConfigICDReducer from "./configICDReducer"
import ConfigLaboratoryReducer from "./configLaboratory"
import ConfigCOAReducer from "./configCOAReducer"
import configGeneralLedgerReducer from "./configGeneralLedgerReducer"
import configWorkflowPropertyReducer from "./configWorkflowPropertyReducer"
import configEclaimReducer from "./workflow-property/eclaimReducer"

export default combineReducers({
	type: typeReducer,
	location: locationReducer,
	itemConfig: itemConfigReducer,
	userConfig: userConfigReducer,
	serviceConfig: serviceConfigReducer,
	customDefaultConfig: customDefaultConfigReducer,
	configICD: ConfigICDReducer,
	configLaboratory: ConfigLaboratoryReducer,
	configCOA: ConfigCOAReducer,
	configGL: configGeneralLedgerReducer,
	configWP: configWorkflowPropertyReducer,
	configEclaim: configEclaimReducer,
})
