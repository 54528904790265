import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_REPORT_FINANCE_APA_ACCOUNTS_CODES,
	SET_REPORT_FINANCE_APA_SUPPLIER_AGINGS,
	SET_REPORT_FINANCE_ARA_ACCOUNTS_CODES,
	SET_REPORT_FINANCE_ARA_CUSTOMERS,
	SET_REPORT_FINANCE_GLS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setReportFinanceGLs = (GLs) => {
	return (dispatch, getState) => {
		let filtered = GLs.sort((a, b) => Utils.sort(a.code, b.code))
		let body = filtered.map((gl) => {
			let cumulativeTotal = Utils.BigNumber(gl.totalDebit).minus(gl.totalCredit)
			let total = cumulativeTotal.plus(gl.debit).minus(gl.credit)
			return [
				gl.code,
				gl.displayName,
				Utils.formatNumWithComma(cumulativeTotal.toFixed(2)),
				Utils.formatNumWithComma(gl.debit.toFixed(2)),
				Utils.formatNumWithComma(gl.credit.toFixed(2)),
				Utils.formatNumWithComma(total.toFixed(2)),
			]
		})
		let colStyle = body.map((each) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[2]).isZero()
							? undefined
							: Utils.BigNumber(each[2]).isNegative()
							? ResourceAssistance.CSS.Color.dark_red
							: ResourceAssistance.CSS.Color.dark_green,
					},
				],
				[
					true,
					{
						textAlign: "right",
					},
				],
				[
					true,
					{
						textAlign: "right",
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[5]).isZero()
							? undefined
							: Utils.BigNumber(each[5]).isNegative()
							? ResourceAssistance.CSS.Color.dark_red
							: ResourceAssistance.CSS.Color.dark_green,
					},
				],
			]
		})
		let width = ["20%", undefined, "10%", "10%", "10%", "10%"]

		dispatch({
			type: SET_REPORT_FINANCE_GLS,
			payload: {
				original: GLs,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				width: width,
			},
		})
		return Promise.resolve()
	}
}

export const setReportFinanceAPAAccountsCodeGroup = (accountsPayableAgings) => {
	return (dispatch, getState) => {
		const groupedAccountsPayables = accountsPayableAgings
			.filter((each) => {
				let filterSuppliers = getState().report.finance.accountsPayableAging.filterSuppliers
				if (_.isEmpty(filterSuppliers)) {
					return true
				}
				let supplierCodeName = each.accountsPayableRC
					? each.accountsPayableRC.supplierCode + "-" + each.accountsPayableRC.supplierName
					: each.accountsPayablePI.supplierCode + "-" + each.accountsPayablePI.supplierName
				return filterSuppliers.some((eachCodeName) => eachCodeName.includes(supplierCodeName))
			})
			.reduce((grouped, cur) => {
				let key = cur.code + ResourceAssistance.PROGRAM_DEFINED.split + cur.name
				if (!grouped[key]) {
					grouped[key] = []
				}
				let found = grouped[key].find((each) => each.documentCode === cur.documentCode && each.documentId === cur.documentId)
				if (found) {
					found["total"] = Utils.BigNumber(found.total).plus(cur.credit).toFixed(2)
				} else {
					grouped[key].push(
						Object.assign(
							{},
							{
								accountsPayable: cur.accountsPayableRC ? cur.accountsPayableRC : cur.accountsPayablePI,
								paymentTermDateTime: cur.accountsPayableRC ? cur.accountsPayableRC.paymentTermDateTime : cur.accountsPayablePI.paymentTermDateTime,
								documentCode: cur.documentCode,
								documentId: cur.documentId,
								total: cur.credit,
								supplier: cur.accountsPayableRC
									? cur.accountsPayableRC.supplierCode + "-" + cur.accountsPayableRC.supplierName
									: cur.accountsPayablePI.supplierCode + "-" + cur.accountsPayablePI.supplierName,
								isAccountsPayableRC: cur.accountsPayableRC !== undefined,
							}
						)
					)
				}
				return grouped
			}, {})
		let filtered = Object.entries(groupedAccountsPayables).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = filtered.map(([key, values]) => {
			let keys = key.split(ResourceAssistance.PROGRAM_DEFINED.split)
			let current = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, Infinity, 0)
			let _1To30 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 1, 30)
			let _31To90 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 31, 90)
			let _91To180 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 91, 180)
			let _181To360 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 181, 360)
			let over360 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 361, Infinity)
			let total = current.plus(_1To30).plus(_31To90).plus(_91To180).plus(_181To360).plus(over360)
			return [
				keys[0],
				keys[1],
				Utils.formatNumWithComma(values.length),
				Utils.formatNumWithComma(current.toFixed(2)),
				Utils.formatNumWithComma(_1To30.toFixed(2)),
				Utils.formatNumWithComma(_31To90.toFixed(2)),
				Utils.formatNumWithComma(_91To180.toFixed(2)),
				Utils.formatNumWithComma(_181To360.toFixed(2)),
				Utils.formatNumWithComma(over360.toFixed(2)),
				Utils.formatNumWithComma(total.toFixed(2)),
			]
		})
		let colStyle = body.map((each) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[false, ""],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[3]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[4]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[5]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[6]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[7]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[8]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[9]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
			]
		})
		let width = ["150px", undefined, "170px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"]

		dispatch({
			type: SET_REPORT_FINANCE_APA_ACCOUNTS_CODES,
			payload: {
				original: accountsPayableAgings,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				width: width,
			},
		})
		return Promise.resolve()
	}
}

export const setReportFinanceAPASupplierGroup = (accountsPayableAgings) => {
	return (dispatch, getState) => {
		let groupedAccountsPayables = accountsPayableAgings
			.filter((each) => {
				let filterSuppliers = getState().report.finance.accountsPayableAging.filterSuppliers
				if (_.isEmpty(filterSuppliers)) {
					return true
				}
				let supplierCodeName = each.accountsPayableRC
					? each.accountsPayableRC.supplierCode + "-" + each.accountsPayableRC.supplierName
					: each.accountsPayablePI.supplierCode + "-" + each.accountsPayablePI.supplierName
				return filterSuppliers.some((eachCodeName) => eachCodeName.includes(supplierCodeName))
			})
			.reduce((grouped, cur) => {
				let key = cur.accountsPayableRC
					? cur.accountsPayableRC.supplierCode + ResourceAssistance.PROGRAM_DEFINED.split + cur.accountsPayableRC.supplierName
					: cur.accountsPayablePI.supplierCode + ResourceAssistance.PROGRAM_DEFINED.split + cur.accountsPayablePI.supplierName
				if (!grouped[key]) {
					grouped[key] = []
				}
				let found = grouped[key].find((each) => each.documentCode === cur.documentCode && each.documentId === cur.documentId)
				if (found) {
					found["total"] = Utils.BigNumber(found.total).plus(cur.credit).toFixed(2)
					if (!found["accountCodes"].find((each) => each.code === cur.code && each.name === cur.name)) {
						found["accountCodes"].push({
							code: cur.code,
							name: cur.name,
						})
					}
				} else {
					grouped[key].push(
						Object.assign(
							{},
							{
								accountsPayable: cur.accountsPayableRC ? cur.accountsPayableRC : cur.accountsPayablePI,
								paymentTermDateTime: cur.accountsPayableRC ? cur.accountsPayableRC.paymentTermDateTime : cur.accountsPayablePI.paymentTermDateTime,
								documentCode: cur.documentCode,
								documentId: cur.documentId,
								total: cur.credit,
								accountCodes: [
									{
										code: cur.code,
										name: cur.name,
									},
								],
								isAccountsPayableRC: cur.accountsPayableRC !== undefined,
							}
						)
					)
				}
				return grouped
			}, {})
		let filtered = Object.entries(groupedAccountsPayables).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = filtered.map(([key, values]) => {
			let keys = key.split(ResourceAssistance.PROGRAM_DEFINED.split)
			let current = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, Infinity, 0)
			let _1To30 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 1, 30)
			let _31To90 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 31, 90)
			let _91To180 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 91, 180)
			let _181To360 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 181, 360)
			let over360 = Utils.getAgingTotal(values, getState().report.finance.accountsPayableAging.filterDateTime, 361, Infinity)
			let total = current.plus(_1To30).plus(_31To90).plus(_91To180).plus(_181To360).plus(over360)
			return [
				keys[0],
				keys[1],
				Utils.formatNumWithComma(values.length),
				Utils.formatNumWithComma(current.toFixed(2)),
				Utils.formatNumWithComma(_1To30.toFixed(2)),
				Utils.formatNumWithComma(_31To90.toFixed(2)),
				Utils.formatNumWithComma(_91To180.toFixed(2)),
				Utils.formatNumWithComma(_181To360.toFixed(2)),
				Utils.formatNumWithComma(over360.toFixed(2)),
				Utils.formatNumWithComma(total.toFixed(2)),
			]
		})
		let colStyle = body.map((each) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[false, ""],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[3]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[4]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[5]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[6]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[7]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[8]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[9]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
			]
		})
		let width = ["80px", undefined, "170px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"]

		dispatch({
			type: SET_REPORT_FINANCE_APA_SUPPLIER_AGINGS,
			payload: {
				original: accountsPayableAgings,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				width: width,
			},
		})
		return Promise.resolve()
	}
}

export const setReportFinanceARACustomerGroup = (accountsReceivableAgings) => {
	return (dispatch, getState) => {
		let groupedAccountsReceivables = accountsReceivableAgings
			.filter((each) => {
				let filterInsuranceCompanies = getState().report.finance.accountsReceivableAging.filterInsuranceCompanies
				if (_.isEmpty(filterInsuranceCompanies)) {
					return true
				}
				let insuranceCompanyCodeName =
					each.accountsReceivable.contractBillingChild.contractBilling.insuranceCompanyCode +
					"-" +
					each.accountsReceivable.contractBillingChild.contractBilling.insuranceCompany
				return filterInsuranceCompanies.some((eachCodeName) => eachCodeName.includes(insuranceCompanyCodeName))
			})
			.reduce((grouped, cur) => {
				let key =
					cur.accountsReceivable.contractBillingChild.contractBilling.insuranceCompanyCode +
					ResourceAssistance.PROGRAM_DEFINED.split +
					cur.accountsReceivable.contractBillingChild.contractBilling.insuranceCompany
				if (!grouped[key]) {
					grouped[key] = []
				}
				let found = grouped[key].find((each) => each.documentCode === cur.documentCode && each.documentId === cur.documentId)
				if (found) {
					found["total"] = Utils.BigNumber(found.total).plus(cur.debit).toFixed(2)
					if (!found["accountCodes"].find((each) => each.code === cur.code && each.name === cur.name)) {
						found["accountCodes"].push({
							code: cur.code,
							name: cur.name,
						})
					}
				} else {
					grouped[key].push(
						Object.assign(
							{},
							{
								accountsReceivable: cur.accountsReceivable,
								paymentTermDateTime: cur.accountsReceivable.contractBillingChild.contractBilling.dueDateTime,
								documentCode: cur.documentCode,
								documentId: cur.documentId,
								total: cur.debit,
								accountCodes: [
									{
										code: cur.code,
										name: cur.name,
									},
								],
							}
						)
					)
				}
				return grouped
			}, {})
		let filtered = Object.entries(groupedAccountsReceivables).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = filtered.map(([key, values]) => {
			let keys = key.split(ResourceAssistance.PROGRAM_DEFINED.split)
			let current = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, Infinity, 0)
			let _1To30 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 1, 30)
			let _31To90 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 31, 90)
			let _91To180 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 91, 180)
			let _181To360 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 181, 360)
			let over360 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 361, Infinity)
			let total = current.plus(_1To30).plus(_31To90).plus(_91To180).plus(_181To360).plus(over360)
			return [
				keys[0],
				keys[1],
				Utils.formatNumWithComma(values.length),
				Utils.formatNumWithComma(current.toFixed(2)),
				Utils.formatNumWithComma(_1To30.toFixed(2)),
				Utils.formatNumWithComma(_31To90.toFixed(2)),
				Utils.formatNumWithComma(_91To180.toFixed(2)),
				Utils.formatNumWithComma(_181To360.toFixed(2)),
				Utils.formatNumWithComma(over360.toFixed(2)),
				Utils.formatNumWithComma(total.toFixed(2)),
			]
		})
		let colStyle = body.map((each) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[false, ""],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[3]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[4]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[5]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[6]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[7]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[8]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[9]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
			]
		})
		let width = ["100px", undefined, "170px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"]

		dispatch({
			type: SET_REPORT_FINANCE_ARA_CUSTOMERS,
			payload: {
				original: accountsReceivableAgings,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				width: width,
			},
		})
		return Promise.resolve()
	}
}

export const setReportFinanceARAAccountsCodeGroup = (accountsReceivableAgings) => {
	return (dispatch, getState) => {
		const groupedAccountsReceivables = accountsReceivableAgings
			.filter((each) => {
				let filterInsuranceCompanies = getState().report.finance.accountsReceivableAging.filterInsuranceCompanies
				if (_.isEmpty(filterInsuranceCompanies)) {
					return true
				}
				let insuranceCompanyCodeName =
					each.accountsReceivable.contractBillingChild.contractBilling.insuranceCompanyCode +
					"-" +
					each.accountsReceivable.contractBillingChild.contractBilling.insuranceCompany
				return filterInsuranceCompanies.some((eachCodeName) => eachCodeName.includes(insuranceCompanyCodeName))
			})
			.reduce((grouped, cur) => {
				let key = cur.code + ResourceAssistance.PROGRAM_DEFINED.split + cur.name
				if (!grouped[key]) {
					grouped[key] = []
				}
				let found = grouped[key].find((each) => each.documentCode === cur.documentCode && each.documentId === cur.documentId)
				if (found) {
					found["total"] = Utils.BigNumber(found.total).plus(cur.total).toFixed(2)
				} else {
					grouped[key].push(
						Object.assign(
							{},
							{
								accountsReceivable: cur.accountsReceivable,
								paymentTermDateTime: cur.accountsReceivable.contractBillingChild.contractBilling.dueDateTime,
								documentCode: cur.documentCode,
								documentId: cur.documentId,
								total: cur.debit,
								invoice: cur.accountsReceivable.id,
								vid: cur.accountsReceivable.billing.billingPlan.admission.id,
								pid: cur.accountsReceivable.billing.billingPlan.admission.patient.id,
								patient: cur.accountsReceivable.billing.billingPlan.admission.patient.displayName,
								insuranceCompany:
									cur.accountsReceivable.contractBillingChild.contractBilling.insuranceCompanyCode +
									"-" +
									cur.accountsReceivable.contractBillingChild.contractBilling.insuranceCompany,
							}
						)
					)
				}
				return grouped
			}, {})
		let filtered = Object.entries(groupedAccountsReceivables).sort((a, b) => Utils.sort(a[0], b[0]))
		let body = filtered.map(([key, values]) => {
			let keys = key.split(ResourceAssistance.PROGRAM_DEFINED.split)
			let current = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, Infinity, 0)
			let _1To30 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 1, 30)
			let _31To90 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 31, 90)
			let _91To180 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 91, 180)
			let _181To360 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 181, 360)
			let over360 = Utils.getAgingTotal(values, getState().report.finance.accountsReceivableAging.filterDateTime, 361, Infinity)
			let total = current.plus(_1To30).plus(_31To90).plus(_91To180).plus(_181To360).plus(over360)
			return [
				keys[0],
				keys[1],
				Utils.formatNumWithComma(values.length),
				Utils.formatNumWithComma(current.toFixed(2)),
				Utils.formatNumWithComma(_1To30.toFixed(2)),
				Utils.formatNumWithComma(_31To90.toFixed(2)),
				Utils.formatNumWithComma(_91To180.toFixed(2)),
				Utils.formatNumWithComma(_181To360.toFixed(2)),
				Utils.formatNumWithComma(over360.toFixed(2)),
				Utils.formatNumWithComma(total.toFixed(2)),
			]
		})
		let colStyle = body.map((each) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[false, ""],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[3]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[4]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[5]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[6]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[7]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[8]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
				[
					true,
					{
						textAlign: "right",
						color: Utils.BigNumber(each[9]).isZero() ? undefined : ResourceAssistance.CSS.Color.dark_red,
					},
				],
			]
		})
		let width = ["150px", undefined, "170px", "120px", "120px", "120px", "120px", "120px", "120px", "120px"]

		dispatch({
			type: SET_REPORT_FINANCE_ARA_ACCOUNTS_CODES,
			payload: {
				original: accountsReceivableAgings,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				width: width,
			},
		})
		return Promise.resolve()
	}
}
