import { Card, Descriptions } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { AiOutlineFileDone } from "react-icons/ai"
import { ImPrinter } from "react-icons/im"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAccountsPayableInvoices,
	setAccountsPayableReceives,
	setLoadingAction,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_AP_GL_DATE_TIME, SET_AP_SELECTED_INVOICE, SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AccountsPayablePrintDataFactory from "~/utils/factory/print/fiannce/AccountsPayablePrintDataFactory"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class AccountsPayableRightInvoice extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onInspector = this.onInspector.bind(this)
		this.onApprover = this.onApprover.bind(this)
		this.onReject = this.onReject.bind(this)
		this.onPrint = this.onPrint.bind(this)
		this.onGeneralLedgerDateTime = this.onGeneralLedgerDateTime.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsPayable.inspectInvoice)) {
					this.inspectInvoice()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsPayable.approveInvoice)) {
					this.completeInvoice()
				}
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsPayable.rejectInvoice)) {
					this.rejectInvoice()
				}
			}
		}
		if (!_.isEqual(prevProps.warning.isClose, this.props.warning.isClose)) {
			if (this.props.warning.isClose) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.accountsPayable.approveInvoice)) {
					this.props.setValue(SET_AP_GL_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
				}
			}
		}
		if (
			!_.isEqual(prevProps.AP.selectedRC, this.props.AP.selectedRC) ||
			!_.isEqual(prevProps.AP.accountsPayableActivities, this.props.AP.accountsPayableActivities) ||
			!_.isEqual(prevProps.AP.selectableAccountingCodes, this.props.AP.selectableAccountingCodes)
		) {
			if (this.props.AP.selectedRC.rc) {
				this.props.setAccountsPayableInvoices(this.props.AP.selectedRC.rc.receiveInvoices)
			} else {
				this.props.setAccountsPayableInvoices([])
			}
		}
		if (!_.isEqual(prevProps.AP.invoiceTable.filtered, this.props.AP.invoiceTable.filtered)) {
			let index = this.props.AP.selectedInvoice.invoice
				? this.props.AP.invoiceTable.filtered.findIndex((each) => each.id === this.props.AP.selectedInvoice.invoice.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_AP_SELECTED_INVOICE, index, this.props.AP.invoiceTable.filtered[index])
			} else {
				this.props.setSelected(SET_AP_SELECTED_INVOICE, -1, null)
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_AP_SELECTED_INVOICE, -1, null)
	}

	isDisplayInspector() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsPayable.Inspector, this.props.login.user.roles)
	}

	isDisplayApprover() {
		return Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.AccountsPayable.Approver, this.props.login.user.roles)
	}

	inspectInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsPayable.inspectInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.AP.location.id,
				rcId: this.props.AP.selectedRC.rc.id,
				invoiceId: this.props.AP.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
				generalLedgers: this.props.AP.generalLedgerGroupTable.body.map((each, idx) => {
					return {
						documentCode: ResourceAssistance.Enum.accountsPayableDocumentCode,
						documentId: this.props.AP.selectedRC.rc.id,
						code: each[0],
						name: each[1],
						category: each[2],
						transactionDescription: each[3],
						debit: Utils.formatNumberFromStr(each[4]).toFixed(2),
						credit: Utils.formatNumberFromStr(each[5]).toFixed(2),
						workflow: ResourceAssistance.Enum.accountsPayable,
						systemGenerated: this.props.AP.generalLedgerGroupTable.rowColor[idx][0],
						finalized: false,
						excludePaymentSlip:
							typeof this.props.AP.generalLedgerGroupTable.filtered[idx][0] === "object"
								? this.props.AP.generalLedgerGroupTable.filtered[idx][0].excludeWorkflowPaymentSlip
								: false,
					}
				}),
			},
		}
		let callback = (res) => {
			if (_.isEmpty(res.data.receives)) {
				this.props.setAccountsPayableReceives([])
			} else {
				let receives = this.props.AP.receiveTable.original.filter((each) => each.id !== res.data.receives[0].id)
				receives.push(res.data.receives[0])
				this.props.setAccountsPayableReceives(receives)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	completeInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsPayable.completeInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.AP.location.id,
				rcId: this.props.AP.selectedRC.rc.id,
				invoiceId: this.props.AP.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
				dateTime: this.props.AP.generalLedgerDateTime,
			},
		}
		let callback = (res) => {
			let receives = this.props.AP.receiveTable.original.filter((each) => each.id !== res.data.receives[0].id)
			receives.push(res.data.receives[0])
			this.props.setAccountsPayableReceives(receives)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	rejectInvoice() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.accountsPayable.rejectInvoice,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.AP.location.id,
				rcId: this.props.AP.selectedRC.rc.id,
				invoiceId: this.props.AP.selectedInvoice.invoice.id,
				userId: this.props.login.user.id,
			},
		}
		let callback = (res) => {
			let receives = this.props.AP.receiveTable.original.filter((each) => each.id !== res.data.receives[0].id)
			receives.push(res.data.receives[0])
			this.props.setAccountsPayableReceives(receives)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	displayWarning(invoice, warningId, warningMsg, isDisplayDate) {
		this.props.setWarningId(warningId)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(warningMsg)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
						{Utils.formatDate(invoice.receivedDateTime)}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.invoiceId)} span={1}>
						{invoice.invoice}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.supplier)} span={1}>
						{invoice.supplierName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)} span={1}>
						{Utils.formatNumWithComma(invoice.taxedTotalPrice)}
					</Descriptions.Item>
					{isDisplayDate && (
						<Descriptions.Item labelStyle={{ alignItems: "center" }} label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
							<AntDatePicker
								allowClear={false}
								size={"middle"}
								format={"DD-MM-YYYY"}
								defaultValues={[moment(this.props.AP.generalLedgerDateTime)]}
								onChange={this.onGeneralLedgerDateTime}
							/>
						</Descriptions.Item>
					)}
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSelectRow(group, index) {
		this.props.setSelected(SET_AP_SELECTED_INVOICE, index, this.props.AP.invoiceTable.filtered[index])
	}

	onInspector(event, row, rIdx, cIdx) {
		this.displayWarning(
			this.props.AP.invoiceTable.filtered[rIdx],
			ResourceAssistance.ID.ACCOUNTING.accountsPayable.inspectInvoice,
			ResourceAssistance.Message.inspector
		)
	}

	onReject(event, row, rIdx, cIdx) {
		this.displayWarning(
			this.props.AP.invoiceTable.filtered[rIdx],
			ResourceAssistance.ID.ACCOUNTING.accountsPayable.rejectInvoice,
			ResourceAssistance.Message.reject
		)
	}

	onApprover(event, row, rIdx, cIdx) {
		this.displayWarning(
			this.props.AP.invoiceTable.filtered[rIdx],
			ResourceAssistance.ID.ACCOUNTING.accountsPayable.approveInvoice,
			ResourceAssistance.Message.approver,
			true
		)
	}

	onGeneralLedgerDateTime(value) {
		this.props.setValue(SET_AP_GL_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onPrint(event, row, rIdx, cIdx) {
		let myWindow = window.open(ResourceAssistance.Path.Report.finance.journalVoucher, "_blank")
		myWindow.data = AccountsPayablePrintDataFactory.generateJournalVoucherData(
			this.props.AP.location,
			this.props.AP.selectedRC.rc,
			this.props.AP.invoiceTable.filtered[rIdx]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						displayBtn
						buttonColors={[
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineRed,
							ResourceAssistance.Button.variant.outlineGreen,
							ResourceAssistance.Button.variant.outlineInfo,
						]}
						btnMessages={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.complete }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.complete }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.print }),
						]}
						btnIcons={[
							this.isDisplayInspector() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							<TiArrowBack size={ResourceAssistance.ReactIcon.size} />,
							this.isDisplayApprover() ? <AiOutlineFileDone size={ResourceAssistance.ReactIcon.size} /> : undefined,
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />,
						]}
						onCheckedArray={[this.onInspector, this.onReject, this.onApprover, this.onPrint]}
						data={this.props.AP.invoiceTable}
						isClearHighlight={this.props.AP.selectedInvoice.index === -1}
						highlightedRow={this.props.AP.selectedInvoice.index}
						onClick={this.onSelectRow}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	AP: state.accounting.accountsPayable,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAccountsPayableInvoices,
			setAccountsPayableReceives,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AccountsPayableRightInvoice))
