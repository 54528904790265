import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"

class ReportPaymentJournalVoucherDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				invoices: [],
				GLs: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page, styles.page]}>
						{/* Title */}
						<ReportTitle displayOrg={true} displayAddress={true} displayFax={false} displayTaxNum={false} displayTel={false} data={this.state.data} />
						{/* General Info */}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.idIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.id}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.dueDateIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.dueDate}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.paymentIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.payment}</Text>
							</View>
						</View>
						{!_.isEmpty(this.state.data.bank) && (
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
									<Text>{this.state.data.bankIntl}:</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.bank}</Text>
								</View>
							</View>
						)}
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.supplierIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.supplier}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoHeader]}>
								<Text>{this.state.data.printDateIntl}:</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
								<Text>{this.state.data.printDate}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Invoice Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.dateIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.id]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.idIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.invoice]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.invoiceIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>
						{this.state.data.invoices.map((invoice) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
											<Text>{invoice.date}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.id]}>
											<Text>{invoice.id}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.invoice]}>
											<Text>{invoice.invoiceNum}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
											<Text>{invoice.amount}</Text>
										</View>
									</View>
									{!_.isEmpty(invoice.withholdingTaxes) && (
										<>
											<View wrap={false}>
												<View style={ResourceAssistance.Report.styles.horizontalSubline} />
												<View style={[ResourceAssistance.Report.styles.row, { fontSize: 14 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter]} />
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
														<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.amountIntl}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.tax]}>
														<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.taxIntl} %</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
														<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.withholdingTaxIntl} %</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
														<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}></View>
												</View>
												<View style={ResourceAssistance.Report.styles.horizontalLine} />
											</View>
											{invoice.withholdingTaxes.map((wht) => {
												return (
													<View style={[ResourceAssistance.Report.styles.row, { fontSize: 14 }]}>
														<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
															<Text style={[ResourceAssistance.Report.styles.textIcon]}>{ResourceAssistance.Symbol.arrowIcon}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
															<Text>{wht.taxedTotalAmount}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.tax]}>
															<Text>{wht.tax}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
															<Text>{wht.withholdingTax}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
															<Text>{wht.amount}</Text>
														</View>
														<View style={[ResourceAssistance.Report.styles.col]}></View>
													</View>
												)
											})}
											<View style={ResourceAssistance.Report.styles.horizontalSubline} />
										</>
									)}
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.grandTotal]}>
								<Text>{this.state.data.amountIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
								<Text>{this.state.data.invoiceAmount}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.grandTotal]}>
								<Text>{this.state.data.withholdingTaxIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
								<Text>{this.state.data.invoiceGrandWithholdingTax}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.grandTotal]}>
								<Text>{this.state.data.grandTotalIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
								<Text>{this.state.data.invoiceGrandTotal}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* GL Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.codeIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.name]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.nameIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.debit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.debitIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.credit]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.creditIntl}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>
						{this.state.data.GLs.map((GL) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
										<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.code]}>
											<Text>{GL.code}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.name]}>
											<Text>{GL.name}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.debit]}>
											<Text>{GL.debit}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.credit]}>
											<Text>{GL.credit}</Text>
										</View>
									</View>
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.horizontalLine} />
						{/* Total */}
						<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
							<View style={[ResourceAssistance.Report.styles.col, styles.grandTotal]}>
								<Text>{this.state.data.grandTotalIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.debit]}>
								<Text>{this.state.data.totalDebit}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, styles.credit]}>
								<Text>{this.state.data.totalCredit}</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.separator} />

						{/* Signatures */}
						<View style={[ResourceAssistance.Report.styles.row, styles.signature]} wrap={false} fixed>
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text>{this.state.data.creatorIntl}</Text>
								<Text>{"\n\n"}</Text>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />
								<Text>{this.state.data.creator}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, { maxWidth: "3%" }]} />
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text>{this.state.data.inspectorIntl}</Text>
								<Text>{"\n\n"}</Text>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />
								<Text>{this.state.data.inspector}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.col, { maxWidth: "3%" }]} />
							<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
								<Text>{this.state.data.approverIntl}</Text>
								<Text>{"\n\n"}</Text>
								<View style={ResourceAssistance.Report.styles.horizontalLine} />
								<Text>{this.state.data.approver}</Text>
							</View>
						</View>
						<Signature numOfSignature={3} displaySignatureDate={false} signatureTitles={this.state.data.signatureTitles} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 180,
	},
	infoHeader: {
		marginRight: 10,
		fontStyle: "bold",
		textAlign: "right",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	signature: {
		position: "absolute",
		left: 25,
		bottom: 95,
		right: 25,
		textAlign: "center",
	},
	date: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	id: {},
	invoice: {},
	amount: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
	tax: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	withholdingTax: {
		minWidth: "25%",
		maxWidth: "25%",
	},
	grandTotal: {
		textAlign: "right",
		fontStyle: "bold",
	},
	code: {
		minWidth: "15%",
		maxWidth: "15%",
	},
	name: {
		minWidth: "55%",
		maxWidth: "55%",
	},
	debit: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
	credit: {
		textAlign: "right",
		minWidth: "15%",
		maxWidth: "15%",
	},
})

export default ReportPaymentJournalVoucherDoc
