import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaPrescription } from "react-icons/fa"
import { GiStopwatch } from "react-icons/gi"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { IconFont } from "~/icons"
import {
	onModalDisplayAction,
	setOPDDoctorOrders,
	setSelected,
	setValue,
	setLoadingAction,
	setWarningMsgAction,
	setWarningId,
	setOPDPatients,
} from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_OPD_DOCTOR_INFO_DISPLAY,
	SET_OPD_MODIFY_DATE_DISPLAY,
	SET_OPD_SELECTED_DOCTOR_ORDER,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable, { EBtnType } from "~/view/component/scroll_table/ScrollableTable"

class OutpatientRightDoctorOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightDoctorOrder",
		}

		this.onSelectOrder = this.onSelectOrder.bind(this)
		this.onDoubleDoctorOrder = this.onDoubleDoctorOrder.bind(this)
		this.onChangeEndDate = this.onChangeEndDate.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDDoctorOrders(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.doctorOrders)
		} else {
			this.props.setOPDDoctorOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.opd.deleteDoctorOrder)) {
				this.deleteDoctorOrder()
			}
		}
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDDoctorOrders(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.doctorOrders)
			} else {
				this.props.setOPDDoctorOrders([])
			}
		}

		if (!_.isEqual(prevProps.opd.doctorOrderTable.filtered, this.props.opd.doctorOrderTable.filtered)) {
			let index = this.props.opd.selectedDoctorOrder.order
				? this.props.opd.doctorOrderTable.filtered.findIndex((doctorOrder) => doctorOrder.id === this.props.opd.selectedDoctorOrder.order.id)
				: -1
			if (index > -1) {
				this.props.setSelected(SET_OPD_SELECTED_DOCTOR_ORDER, index, this.props.opd.doctorOrderTable.filtered[index])
			} else {
				this.props.setSelected(SET_OPD_SELECTED_DOCTOR_ORDER, -1, null)
			}
		}
	}

	isDisplayWarning() {
		return true
	}

	isDisplayModifyDateRange() {
		return (
			Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Doctor, this.props.login.user.roles) &&
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	isDisplayDelete() {
		return (
			Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.OPD.Doctor, this.props.login.user.roles) &&
			this.props.opd.selectedMedicalRecord.medicalRecord &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkInDateTime > 0 &&
			this.props.opd.selectedMedicalRecord.medicalRecord.checkOutDateTime === 0
		)
	}

	deleteDoctorOrder() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.deleteDoctorOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				doctorOrders: [
					{
						id: this.props.opd.selectedDoctorOrder.order.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onChangeEndDate() {
		this.props.onModalDisplayAction(SET_OPD_MODIFY_DATE_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedDoctorOrder = this.props.opd.doctorOrderTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.opd.deleteDoctorOrder)
		this.props.setValue(SET_MODAL_WARNING_SIZE, undefined)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
            ${selectedDoctorOrder.description}
            ${selectedDoctorOrder.notes}
            ${Utils.formatDateTime(selectedDoctorOrder.startDateTime)} - ${Utils.formatDateTime(selectedDoctorOrder.endDateTime)}`
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onSelectOrder(order, index) {
		this.props.setSelected(SET_OPD_SELECTED_DOCTOR_ORDER, index, this.props.opd.doctorOrderTable.filtered[index])
	}

	onDoubleDoctorOrder(row, idx) {
		this.props.onModalDisplayAction(SET_OPD_DOCTOR_INFO_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[
							[ResourceAssistance.Button.variant.outlineInfo, ResourceAssistance.Button.variant.outlineWarning],
							[],
							[],
							[],
							[],
							[],
							[],
							[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed],
						]}
						btnMessages={[
							["", this.props.intl.formatMessage({ id: ResourceAssistance.Message.nonDispensingRx })],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.modifyDateRange }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
							],
						]}
						btnTypes={[[EBtnType.POPOVER, EBtnType.DEFAULT], [], [], [], [], [], [], [EBtnType.DEFAULT, EBtnType.DEFAULT]]}
						btnIcons={[
							[
								this.isDisplayWarning() ? (
									<IconFont type={"icon-ali-pmgcare-ico_yaokuguanli_caigoushenqingdan"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
								) : undefined,
								<FaPrescription size={ResourceAssistance.ReactIcon.size} />,
							],
							[],
							[],
							[],
							[],
							[],
							[],
							[
								this.isDisplayModifyDateRange() ? <GiStopwatch size={ResourceAssistance.ReactIcon.size} /> : undefined,
								this.isDisplayDelete() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
							],
						]}
						data={this.props.opd.doctorOrderTable}
						onCheckedArray={[this.onChangeEndDate, this.onDelete]}
						onClick={this.onSelectOrder}
						onDoubleClick={this.onDoubleDoctorOrder}
						isClearHighlight={this.props.opd.selectedDoctorOrder.index === -1}
						highlightedRow={this.props.opd.selectedDoctorOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setOPDDoctorOrders,
			setSelected,
			setValue,
			setLoadingAction,
			setWarningMsgAction,
			setWarningId,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightDoctorOrder))
