import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_PO_MODAL_EDIT_ITEM_SUPPLIER_PRICE_PER_ORDER } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class POModalEditItemDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onSupplierPricePerOrder = this.onSupplierPricePerOrder.bind(this)
	}

	componentDidMount() {
		if (this.props.po.selectedItem.item) {
			this.props.setValue(SET_PO_MODAL_EDIT_ITEM_SUPPLIER_PRICE_PER_ORDER, Utils.BigNumber(this.props.po.selectedItem.item.pricePerUnit).toFixed(2))
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// if (!_.isEqual(prevProps.PGL.chartOfAccounts, this.props.PGL.chartOfAccounts)) {
		// 	if (this.props.PGL.selectedEntry.entry && !_.isEmpty(this.props.PGL.chartOfAccounts)) {
		// 		this.initialCOA()
		// 	}
		// }
	}

	componentWillUnmount() {
		this.props.setValue(SET_PO_MODAL_EDIT_ITEM_SUPPLIER_PRICE_PER_ORDER, "")
	}

	onSupplierPricePerOrder(e) {
		this.props.setValue(SET_PO_MODAL_EDIT_ITEM_SUPPLIER_PRICE_PER_ORDER, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.supplierPricePerOrder)}
							pattern="^[0-9]*\.[0-9]{2}$"
							value={this.props.po.modalEditItem.supplierPricePerOrder}
							onChange={this.onSupplierPricePerOrder}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	po: state.purchaseOrder,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POModalEditItemDetails)
