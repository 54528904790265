import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons"
import { Popover, Select, Statistic } from "antd"
import _ from "lodash"
import moment from "moment"
import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import EnumAssistance from "~/enum/EnumAssistance"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT,
	SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS,
} from "~/redux/type"
import { BigNumberRoundingMode, Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"

class ORModalIntraAneMedicationDuration extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			durations: [],
			totalRate: 0,
		}

		this.onDateTime = this.onDateTime.bind(this)
		this.onQty = this.onQty.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onRate = this.onRate.bind(this)
		this.onClearRate = this.onClearRate.bind(this)
		this.onRateDuration = this.onRateDuration.bind(this)
		this.onNotes = this.onNotes.bind(this)
	}

	componentDidMount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME, this.props.operatingRoom.intraAneStartDateTime)
		this.setState({
			durations: EnumAssistance.Duration.filter((each) => {
				let start = this.props.operatingRoom.intraAneStartDateTime / 1000
				let end = this.props.operatingRoom.intraAneEndDateTime / 1000
				return Utils.BigNumber(end).minus(start).dividedBy(60).gte(each.value)
			}),
		})
		this.props.setValue(
			SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS,
			this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.unitConversions.concat([
				{
					amount: 1,
					displayName: this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.unit.displayName,
					sellingPricePerUnit: this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.sellPricePerUnit,
					oneTime: true,
				},
			])
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalIntraAneMedication.selectedItem, this.props.operatingRoom.modalIntraAneMedication.selectedItem)) {
			this.reset()
			if (this.props.operatingRoom.modalIntraAneMedication.selectedItem.item) {
				this.props.setValue(
					SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS,
					this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.unitConversions.concat([
						{
							amount: 1,
							displayName: this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.unit.displayName,
							sellingPricePerUnit: this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.sellPricePerUnit,
							oneTime: true,
						},
					])
				)
			}
		}
		if (
			!_.isEqual(prevProps.operatingRoom.modalIntraAneMedication.dateTime, this.props.operatingRoom.modalIntraAneMedication.dateTime) ||
			!_.isEqual(prevProps.operatingRoom.modalIntraAneMedication.durationQty, this.props.operatingRoom.modalIntraAneMedication.durationQty) ||
			!_.isEqual(prevProps.operatingRoom.modalIntraAneMedication.selectedUnit, this.props.operatingRoom.modalIntraAneMedication.selectedUnit) ||
			!_.isEqual(prevProps.operatingRoom.modalIntraAneMedication.selectedRate, this.props.operatingRoom.modalIntraAneMedication.selectedRate) ||
			!_.isEqual(prevProps.operatingRoom.modalIntraAneMedication.rateDuration, this.props.operatingRoom.modalIntraAneMedication.rateDuration)
		) {
			if (
				this.props.operatingRoom.modalIntraAneMedication.durationQty &&
				this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit &&
				this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate &&
				this.props.operatingRoom.modalIntraAneMedication.rateDuration
			) {
				this.setState({
					totalRate: Utils.BigNumber(this.props.operatingRoom.modalIntraAneMedication.durationQty)
						.times(this.props.operatingRoom.modalIntraAneMedication.rateDuration)
						.toNumber(),
				})
			} else {
				this.setState({
					totalRate: 0,
				})
			}
			let usage = Utils.trim(this.props.operatingRoom.modalIntraAneMedication.notes)
			//Previous Notes
			if (prevProps.operatingRoom.modalIntraAneMedication.selectedItem.item && prevProps.operatingRoom.modalIntraAneMedication.selectedUnit.unit) {
				let prevDuration =
					prevProps.operatingRoom.modalIntraAneMedication.durationQty + " " + prevProps.operatingRoom.modalIntraAneMedication.selectedUnit.unit.displayName
				if (prevProps.operatingRoom.modalIntraAneMedication.selectedRate.rate && prevProps.operatingRoom.modalIntraAneMedication.rateDuration) {
					prevDuration =
						prevDuration +
						"/" +
						prevProps.operatingRoom.modalIntraAneMedication.selectedRate.rate.shortName +
						" " +
						this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastFor }) +
						" " +
						prevProps.operatingRoom.modalIntraAneMedication.rateDuration +
						" " +
						prevProps.operatingRoom.modalIntraAneMedication.selectedRate.rate.displayName
				}
				usage = Utils.trim(usage.replace(prevDuration, ""))
			}
			//New Notes
			if (
				this.props.operatingRoom.modalIntraAneMedication.durationQty &&
				this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit &&
				!this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate
			) {
				let duration =
					this.props.operatingRoom.modalIntraAneMedication.durationQty + " " + this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.displayName
				usage = Utils.trim(duration.concat(" ", usage))
				this.setState({
					totalQty: Utils.calculateDoctorOrderQtyByRate(
						this.props.operatingRoom.modalIntraAneMedication.durationQty,
						this.props.operatingRoom.modalIntraAneMedication.rateDuration,
						this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.amount,
						this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.oneTime
					).dp(0, BigNumberRoundingMode.ROUND_UP),
				})
			} else if (
				this.props.operatingRoom.modalIntraAneMedication.durationQty &&
				this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit &&
				this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate &&
				!_.isEmpty(this.props.operatingRoom.modalIntraAneMedication.rateDuration)
			) {
				let duration =
					this.props.operatingRoom.modalIntraAneMedication.durationQty +
					" " +
					this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.displayName +
					"/" +
					this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate.shortName +
					" " +
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastFor }) +
					" " +
					this.props.operatingRoom.modalIntraAneMedication.rateDuration +
					" " +
					this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate.displayName
				usage = Utils.trim(duration.concat(" ", usage))
				this.setState({
					totalQty: Utils.calculateDoctorOrderQtyByRate(
						this.props.operatingRoom.modalIntraAneMedication.durationQty,
						this.props.operatingRoom.modalIntraAneMedication.rateDuration,
						this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.amount,
						this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.oneTime
					).toNumber(),
				})
			} else {
				this.setState({
					totalQty: "",
				})
			}
			this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES, usage)
		}
	}

	componentWillUnmount() {
		this.reset()
		this.setState({
			durations: [],
		})
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME, undefined)
	}

	reset() {
		this.setState({
			totalRate: 0,
		})
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_UNITS, [])
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT, -1, null)
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE, -1, null)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES, "")
		// message.destroy()
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md="auto">
						<AntDatePicker
							allowClear={false}
							size={"large"}
							format={"DD-MM-YYYY  HH:mm"}
							showTime={{ format: "HH:mm" }}
							minDate={moment(this.props.operatingRoom.intraAneStartDateTime)}
							maxDate={moment(this.props.operatingRoom.intraAneEndDateTime)}
							disabledTime={(current, type) => {
								let start = moment(this.props.operatingRoom.intraAneStartDateTime)
								let end = moment(this.props.operatingRoom.intraAneEndDateTime)
								return {
									disabledHours: () => {
										const allHours = []
										for (let i = 0; i < 24; i++) {
											allHours.push(i)
										}
										return allHours.filter((hour) => hour < start.hour() || hour > end.hour())
									},
									disabledMinutes: (selectedHour) => {
										const startMinutes = start.hours() * 60 + start.minutes()
										const endMinutes = end.hours() * 60 + end.minutes()
										const currentHourStart = selectedHour * 60
										const currentHourMinutes = Array.from({ length: 60 }, (_, i) => currentHourStart + i)
										const excludedMinutes = currentHourMinutes.filter((minute) => minute < startMinutes || minute >= endMinutes)
										return excludedMinutes.map((minute) => minute % 60)
									},
								}
							}}
							values={[moment(this.props.operatingRoom.modalIntraAneMedication.dateTime)]}
							onChange={this.onDateTime}
						/>
					</Col>
				</Row>
				<Row className={"g-0"}>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.number}
							placeholder={translate(ResourceAssistance.Message.amount)}
							min={0}
							step={0.01}
							value={this.props.operatingRoom.modalIntraAneMedication.durationQty}
							onChange={this.onQty}
							addonAfter={
								<Fragment>
									<Select
										showSearch
										style={{ textAlign: "center", width: "100px", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
										value={
											this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit
												? this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.displayName
												: undefined
										}
										onChange={this.onUnit}
									>
										{Utils.renderSelects(this.props.operatingRoom.modalIntraAneMedication.units, false)}
									</Select>
									<span style={{ verticalAlign: "middle" }}>
										<IconFont type={"icon-ali-pmgcare-chuhao"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
									</span>
									<Select
										allowClear
										showSearch
										style={{ textAlign: "center", width: "100px", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
										value={
											this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate
												? this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate.displayName
												: undefined
										}
										onChange={this.onRate}
										onClear={this.onClearRate}
									>
										{Utils.renderSelects(this.state.durations, false)}
									</Select>
								</Fragment>
							}
						/>
					</Col>
				</Row>
				{this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate && (
					<Row className={"g-0"}>
						<Col>
							<GInput
								required
								style={{ textAlign: "center" }}
								type={ResourceAssistance.FormControl.type.number}
								placeholder={translate(ResourceAssistance.Message.duration)}
								min={1}
								value={this.props.operatingRoom.modalIntraAneMedication.rateDuration}
								onChange={this.onRateDuration}
								addonBefore={translate(ResourceAssistance.Message.lastFor)}
								addonAfter={this.props.operatingRoom.modalIntraAneMedication.selectedRate.rate.displayName}
							/>
						</Col>
						<Col>
							<Statistic
								style={{ textAlign: "center" }}
								title={translate(ResourceAssistance.Message.total)}
								value={this.state.totalRate}
								suffix={
									this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit
										? this.props.operatingRoom.modalIntraAneMedication.selectedUnit.unit.displayName
										: undefined
								}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col>
						<GInput
							autoSize
							required
							minRows={5}
							placeholder={translate(ResourceAssistance.Message.medicationUsage)}
							value={this.props.operatingRoom.modalIntraAneMedication.notes}
							onChange={this.onNotes}
						/>
					</Col>
				</Row>
				<Row className={"g-0 label-info-row"}>
					{this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.medicationUsage && (
						<Col md="auto">
							<Popover content={this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.medicationUsage} trigger="hover">
								<QuestionCircleOutlined
									style={{
										fontSize: ResourceAssistance.ReactIcon.size,
										alignSelf: "flex-start",
										color: "red",
									}}
								/>
							</Popover>
						</Col>
					)}
					{this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.item.warnings && (
						<Col md="auto">
							<Popover content={this.props.operatingRoom.modalIntraAneMedication.selectedItem.item.item.warnings} trigger="hover">
								<WarningOutlined
									style={{
										fontSize: ResourceAssistance.ReactIcon.size,
										alignSelf: "flex-start",
										color: ResourceAssistance.CSS.Color.dark_warning,
									}}
								/>
							</Popover>
						</Col>
					)}
				</Row>
			</Container>
		)
	}
	onDateTime(v) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_DATE_TIME, v ? v.milliseconds(0).valueOf() : "")
	}
	onQty(event) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_DURATION_QTY, event.target.value)
	}
	onUnit(value) {
		value > -1
			? this.props.setSelected(SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT, value, this.props.operatingRoom.modalIntraAneMedication.units[value])
			: this.props.setSelected(SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_UNIT, -1, null)
	}
	onRate(value) {
		value > -1
			? this.props.setSelected(SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE, value, this.state.durations[value])
			: this.props.setSelected(SET_OR_MODAL_INTRA_ANE_MEDICATION_SELECTED_RATE, -1, null)
	}
	onClearRate() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION, "")
		this.setState({
			totalRate: 0,
		})
	}
	onRateDuration(event) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_RATE_DURATION, event.target.value)
	}
	onNotes(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_MEDICATION_NOTES, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalIntraAneMedicationDuration))
