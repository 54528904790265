import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { IoMdDoneAll } from "react-icons/io"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setDebtModalNewPendingInvoices } from "~/redux/action"

class DebtModalNewInvoiceAction extends React.Component {
	constructor(props) {
		super(props)

		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onSelectAll = this.onSelectAll.bind(this)
	}

	isDownBtnDisabled() {
		if (!this.props.debt.modalNew.selectedInvoice.invoice) {
			return true
		}
		return this.props.debt.modalNew.pendingInvoiceTable.original.some(
			(pending) =>
				!_.isEqual(
					pending.billing.billingPlan.insuranceCompany,
					this.props.debt.modalNew.selectedInvoice.invoice.billing.billingPlan.insuranceCompany
				)
		)
	}

	isSelectAllDisabled() {
		if (this.props.debt.modalNew.invoiceTable.filtered.length === 0) {
			return true
		}
		// some insurance company name in invoice not same as in pending invoice
		return (
			this.props.debt.modalNew.invoiceTable.filtered.some((invoice) =>
				this.props.debt.modalNew.pendingInvoiceTable.original.some(
					(pending) =>
						!_.isEqual(pending.billing.billingPlan.insuranceCompany, invoice.billing.billingPlan.insuranceCompany)
				)
			) ||
			// some insurance company name in invoice not same as others
			this.props.debt.modalNew.invoiceTable.filtered.some(
				(invoice, i, arry) =>
					!_.isEqual(invoice.billing.billingPlan.insuranceCompany, arry[0].billing.billingPlan.insuranceCompany)
			)
		)
	}

	onDownBtn() {
		let selectedInvoice = Object.assign({}, this.props.debt.modalNew.selectedInvoice.invoice)
		let pendingInvoices = Array.from(this.props.debt.modalNew.pendingInvoiceTable.filtered)
		pendingInvoices.push(selectedInvoice)
		this.props.setDebtModalNewPendingInvoices(pendingInvoices)
	}

	onUpBtn() {
		let pendingInvoices = this.props.debt.modalNew.pendingInvoiceTable.filtered.filter(
			(each, index) => index !== this.props.debt.modalNew.selectedPendingInvoice.index
		)
		this.props.setDebtModalNewPendingInvoices(pendingInvoices)
	}

	onSelectAll() {
		let pendingInvoices = Array.from(this.props.debt.modalNew.pendingInvoiceTable.filtered)
		let selectedInvoices = Array.from(this.props.debt.modalNew.invoiceTable.filtered)
		this.props.setDebtModalNewPendingInvoices(pendingInvoices.concat(selectedInvoices))
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.isDownBtnDisabled()}
						onClick={this.onDownBtn}
					>
						<FcDownload size={"3rem"} />
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.isSelectAllDisabled()}
						onClick={this.onSelectAll}
					>
						<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.selectAll)}
					</Button>
				</Col>
				<Col />
				<Col md={"auto"}>
					<Button
						variant={ResourceAssistance.Button.variant.link}
						disabled={this.props.debt.modalNew.selectedPendingInvoice.index === -1}
						onClick={this.onUpBtn}
					>
						<FcUpload size={"3rem"} />
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtModalNewPendingInvoices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtModalNewInvoiceAction)
