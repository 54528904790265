import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setHospitelPatients,
	setLoadingAction,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_IPD_ICD_DISPLAY, SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class IPDRightICD extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightICD",
			selectedICD: {
				index: -1,
				icd: null,
			},
			icdTable: {
				original: [],
				filtered: [],
				header: [
					ResourceAssistance.Message.code,
					ResourceAssistance.Message.name,
					ResourceAssistance.Message.typesOfDiagnosis,
					ResourceAssistance.Message.by,
					"",
				],
				body: [],
			},
		}

		this.onSelectICD = this.onSelectICD.bind(this)
		this.onChecked = this.onChecked.bind(this)
		this.onNew = this.onNew.bind(this)
	}

	componentDidMount() {
		this.renderICDs(this.props.icds)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.ipd.deleteICD)
			) {
				this.deleteICD()
			}
		}

		if (!_.isEqual(prevProps.icds, this.props.icds)) {
			this.renderICDs(this.props.icds)
		}
	}

	renderICDs(icds) {
		let filtered = icds.sort((a, b) => Utils.sort(a.code, b.code))
		let body =
			Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Doctor, this.props.login.user.roles) &&
			!this.props.ipd.selectedHospitel.hospitel.dischargeSummary
				? filtered.map((each) => [each.code, each.description, each.level, each.createdBy.displayName, true])
				: filtered.map((each) => [each.code, each.description, each.level, each.createdBy.displayName])
		this.setState({
			icdTable: {
				...this.state.icdTable,
				original: icds,
				filtered: filtered,
				body: body,
			},
		})
	}

	deleteICD() {
		if (!this.state.selectedICD.icd) {
			return
		}
		let icd = this.state.selectedICD.icd
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.deleteICD,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.ipd.selectedPatient.patient.id,
				icds: [
					{
						id: icd.id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.ipd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setHospitelPatients(patients)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectICD(icd, index) {
		this.setState({
			selectedICD: {
				index: index,
				icd: this.state.icdTable.filtered[index],
			},
		})
	}

	onChecked(event, row, rIdx, cIdx) {
		let selectedICD = this.state.icdTable.filtered[rIdx]
		this.props.setValue(SET_MODAL_WARNING_SIZE, undefined)
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.ipd.deleteICD)
		this.props.setWarningMsgAction(
			`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete })}
		    ${selectedICD.type}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.code })}: ${selectedICD.code}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.name })}: ${selectedICD.description}
		    ${this.props.intl.formatMessage({ id: ResourceAssistance.Message.typesOfDiagnosis })}: ${selectedICD.level}
		    `
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onNew() {
		this.props.onModalDisplayAction(SET_IPD_ICD_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColor={ResourceAssistance.Button.variant.outlineRed}
						btnIcon={<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />}
						data={this.state.icdTable}
						onChecked={this.onChecked}
						onClick={this.onSelectICD}
						isClearHighlight={this.state.selectedICD.index === -1}
						highlightedRow={this.state.selectedICD.index}
						extra={
							Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Doctor, this.props.login.user.roles) &&
							this.props.ipd.selectedHospitel.hospitel &&
							!this.props.ipd.selectedHospitel.hospitel.dischargeSummary && (
								<Button
									style={{ fontSize: "medium" }}
									variant={ResourceAssistance.Button.variant.link}
									size={ResourceAssistance.Button.size.sm}
									onClick={this.onNew}
								>
									<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
									{translate(ResourceAssistance.Message.new)}
								</Button>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	login: state.login,
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelPatients,
			setLoadingAction,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightICD))
