import { Select } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_CMENU_SELECTABLE_SELECTED_ACCOUNTING_CODE } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import { setSelected } from "~/redux/action"

class CMenuSelectableAccountsCodeDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onSelect = this.onSelect.bind(this)
	}

	componentWillUnmount() {
		this.props.setSelected(SET_CMENU_SELECTABLE_SELECTED_ACCOUNTING_CODE, -1, null)
	}

	onSelect(v) {
		if (v === undefined) {
			this.props.setSelected(SET_CMENU_SELECTABLE_SELECTED_ACCOUNTING_CODE, -1, null)
			return
		}
		this.props.setSelected(SET_CMENU_SELECTABLE_SELECTED_ACCOUNTING_CODE, v, this.props.SAC.accountingCodes[v])
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<Select
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.accountsCode)}
							value={this.props.SAC.selectedAccountingCode.accountingCode ? this.props.SAC.selectedAccountingCode.index : undefined}
							onChange={this.onSelect}
						>
							{Utils.renderSelects(
								this.props.SAC.accountingCodes.flatMap((each) => each.chartOfAccounts),
								false,
								-1,
								"displayFullCodeName",
								false
							)}
						</Select>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	SAC: state.menu.selectableAccountsCode,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CMenuSelectableAccountsCodeDetails)
