import { Select } from "antd"
import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_DEBT_MODAL_PAYMENT_DATE_TIME,
	SET_DEBT_MODAL_PAYMENT_PAYMENTS,
	SET_DEBT_MODAL_PAYMENT_REMARK,
	SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT,
	SET_DEBT_MODAL_PAYMENT_WHT,
	SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE,
	SET_PAGE_LOADING,
	SET_DEBT_MODAL_PAYMENT_AMOUNT,
	SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"
import DebtModalPaymentBank from "./DebtModalPaymentBank"
import DebtModalPaymentCheck from "./DebtModalPaymentCheck"
import DebtModalPaymentCreditCard from "./DebtModalPaymentCreditCard"

class DebtModalPaymentDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onPaymentType = this.onPaymentType.bind(this)
		this.onPaymentDateTime = this.onPaymentDateTime.bind(this)
		this.onAmount = this.onAmount.bind(this)
		this.onTransactionFee = this.onTransactionFee.bind(this)
		this.onWithholdingTax = this.onWithholdingTax.bind(this)
		this.onBalanceAdjustment = this.onBalanceAdjustment.bind(this)
		this.onRemark = this.onRemark.bind(this)
	}

	componentDidMount() {
		this.loadPayments()

		this.props.setValue(SET_DEBT_MODAL_PAYMENT_DATE_TIME, moment().startOf("day").milliseconds(0).valueOf())
	}

	componentWillUnmount() {
		this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT, -1, null)
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_PAYMENTS, [])
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_AMOUNT, "")
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE, "")
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_WHT, "")
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT, "")
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_REMARK, "")
	}

	loadPayments() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.debt.getPayments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DEBT_MODAL_PAYMENT_PAYMENTS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onPaymentType(value) {
		if (value === undefined) {
			this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT, -1, null)
			return
		}
		this.props.setSelected(SET_DEBT_MODAL_PAYMENT_SELECTED_PAYMENT, value, this.props.debt.modalPayment.payments[value])
	}

	onPaymentDateTime(value) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_DATE_TIME, moment(value).startOf("day").milliseconds(0).valueOf())
	}

	onAmount(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_AMOUNT, e.target.value)
	}

	onTransactionFee(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_TRANSACTION_FEE, e.target.value)
	}

	onWithholdingTax(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_WHT, e.target.value)
	}

	onBalanceAdjustment(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_BALANCE_ADJUSTMENT, e.target.value)
	}

	onRemark(e) {
		this.props.setValue(SET_DEBT_MODAL_PAYMENT_REMARK, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							size={"large"}
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.paymentType)}
							status={this.props.debt.modalPayment.selectedPayment.payment ? undefined : "error"}
							value={this.props.debt.modalPayment.selectedPayment.payment ? this.props.debt.modalPayment.selectedPayment.index : undefined}
							onChange={this.onPaymentType}
						>
							{Utils.renderSelects(this.props.debt.modalPayment.payments, false)}
						</Select>
					</Col>
					<Col md="auto">
						<AntDatePicker
							allowClear={false}
							size={"large"}
							format={"DD-MM-YYYY"}
							values={[moment(this.props.debt.modalPayment.paymentDateTime)]}
							onChange={this.onPaymentDateTime}
						/>
					</Col>
				</Row>
				{this.props.debt.modalPayment.selectedPayment.payment && this.props.debt.modalPayment.selectedPayment.payment.bankAccount && <DebtModalPaymentBank />}
				{this.props.debt.modalPayment.selectedPayment.payment && this.props.debt.modalPayment.selectedPayment.payment.creditCardAccount && (
					<DebtModalPaymentCreditCard />
				)}
				{this.props.debt.modalPayment.selectedPayment.payment && this.props.debt.modalPayment.selectedPayment.payment.checkAccount && <DebtModalPaymentCheck />}
				<Row className="g-1">
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.amount })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onAmount}
							value={this.props.debt.modalPayment.amount}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.transactionFee })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onTransactionFee}
							value={this.props.debt.modalPayment.transactionFee}
						/>
					</Col>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.withholdingTax })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onWithholdingTax}
							value={this.props.debt.modalPayment.withholdingTax}
						/>
					</Col>
				</Row>
				<Row className="g-1">
					<Col md={4}>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.balanceAdjustment })}
							pattern="^[0-9]*\.[0-9]{2}$"
							onChange={this.onBalanceAdjustment}
							value={this.props.debt.modalPayment.balanceAdjustment}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							autoSize
							minRows={3}
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.remark })}
							onChange={this.onRemark}
							value={this.props.debt.modalPayment.remark}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtModalPaymentDetails))
