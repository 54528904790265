import _ from "lodash"
import { ResourceAssistance } from "~/i18n"
import {
	SET_FO_MODAL_NEW_PAYMENT_PLANS,
	SET_FO_MODAL_NEW_PENDING_PAYMENT_PLANS,
	SET_FO_PAYMENT_PLANS,
	SET_FO_REQUESTS,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setFORequests = (requests) => {
	return (dispatch, getState) => {
		let filtered = requests
			.filter((request) => request.id.includes(getState().accounting.financialOperations.filterFinancialPlanId))
			.sort((a, b) => Utils.sort(b.creationDateTime, a.creationDateTime))

		let body = filtered.map((request) => {
			return [Utils.formatDate(request.creationDateTime), request.id]
		})

		let rowColor = filtered.map((request) => {
			let color = ""
			if (request.complete) {
				color = ResourceAssistance.CSS.Color.green
			}
			return [true, color]
		})

		dispatch({
			type: SET_FO_REQUESTS,
			payload: {
				original: requests,
				filtered: filtered,
				body: body,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFOPaymentPlans = (paymentPlans) => {
	return (dispatch, getState) => {
		let filtered = paymentPlans.sort((a, b) => Utils.sort(a.supplierCode, b.supplierCode))

		let body = filtered.map((ps) => {
			return [
				Utils.formatDate(ps.creationDateTime),
				ps.id,
				ps.paymentSlip.supplierCode,
				ps.paymentSlip.supplierName,
				ps.paymentSlip.supplierBankAccount,
				ps.paymentSlip.supplierBankCode,
				Utils.formatNumWithComma(Utils.BigNumber(ps.amount).minus(ps.withholdingTaxAmount).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(ps.withholdingTaxAmount).toFixed(2)),
				Utils.formatNumWithComma(Utils.BigNumber(ps.amount).toFixed(2)),
				ps.remark,
				Utils.formatNumWithComma(Utils.BigNumber(ps.fee).toFixed(2)),
				[true],
			]
		})

		let rowColor = filtered.map((ps) => {
			let color = ""
			return [!_.isEmpty(color), color]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_FO_PAYMENT_PLANS,
			payload: {
				original: paymentPlans,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
				rowColor: rowColor,
			},
		})
		return Promise.resolve()
	}
}

export const setFOModalNewPaymentPlans = (paymentPlans) => {
	return (dispatch, getState) => {
		let filtered = paymentPlans
			.filter(
				(ps) =>
					ps.id.includes(getState().accounting.financialOperations.modalNew.filterPaymentSlipId) &&
					ps.paymentSlip.supplierCode
						.toLowerCase()
						.includes(getState().accounting.financialOperations.modalNew.filterSupplierCode.toLowerCase()) &&
					ps.paymentSlip.supplierName
						.toLowerCase()
						.includes(getState().accounting.financialOperations.modalNew.filterSupplierName.toLowerCase()) &&
					!getState().accounting.financialOperations.modalNew.pendingPaymentPlanTable.original.some(
						(pendingPaymentPlan) => pendingPaymentPlan.id === ps.id
					)
			)
			.sort((a, b) => Utils.sort(a.id, b.id))

		let body = filtered.map((paymentPlan) => {
			return [
				paymentPlan.id,
				Utils.formatDate(paymentPlan.paymentTermDateTime),
				paymentPlan.paymentMethod,
				paymentPlan.paymentSlip.supplierCode,
				paymentPlan.paymentSlip.supplierName,
				paymentPlan.paymentSlip.supplierBankAccount,
				paymentPlan.paymentSlip.supplierBankCode,
				Utils.formatNumWithComma(Utils.BigNumber(paymentPlan.amount).toFixed(2)),
				paymentPlan.remark,
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_FO_MODAL_NEW_PAYMENT_PLANS,
			payload: {
				original: paymentPlans,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setFOModalNewPendingPaymentPlans = (paymentPlans) => {
	return (dispatch, getState) => {
		let filtered = paymentPlans.sort((a, b) => Utils.sort(a.id, b.id))

		let body = filtered.map((paymentPlan) => {
			return [
				paymentPlan.id,
				Utils.formatDate(paymentPlan.paymentTermDateTime),
				paymentPlan.paymentMethod,
				paymentPlan.paymentSlip.supplierCode,
				paymentPlan.paymentSlip.supplierName,
				paymentPlan.paymentSlip.supplierBankAccount,
				paymentPlan.paymentSlip.supplierBankCode,
				Utils.formatNumWithComma(Utils.BigNumber(paymentPlan.amount).toFixed(2)),
				paymentPlan.remark,
			]
		})

		let colStyle = filtered.map(() => {
			return [
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right", paddingRight: "1rem" }],
				[false, ""],
			]
		})

		dispatch({
			type: SET_FO_MODAL_NEW_PENDING_PAYMENT_PLANS,
			payload: {
				original: paymentPlans,
				filtered: filtered,
				body: body,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}
