import {
	SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE,
	SET_LOCATIONS,
	SET_LOCATION_DISPLAY_INACTIVE,
	SET_LOCATION_LOADING,
	SET_LOC_BRANCH,
	SET_LOC_CHART_OF_ACCOUNTS,
	SET_LOC_GENERAL,
	SET_LOC_INVENTORY,
	SET_LOC_IPD,
	SET_LOC_MODAL_NEW_AP_CODES,
	SET_LOC_MODAL_NEW_AR_CODES,
	SET_LOC_OPD,
	SET_LOC_OPERATING_ROOM,
	SET_LOC_ORG,
	SET_ADM_LOC_ORGS,
	SET_LOC_SEARCH_TEXT,
	SET_SELECTED_LOC,
	SET_ADM_LOC_MODAL_NEW_SELECTED_LOC,
	SET_ADM_LOCATION_FILTER,
} from "~/redux/type"

const init = {
	searchText: "",
	filters: [],
	chartOfAccounts: [],
	orgs: [],
	displayInactive: true,
	isOrg: false,
	isBranch: false,
	isInventory: false,
	isGeneralLocation: false,
	isIPD: false,
	isOPD: false,
	isOR: false,
	isOrgsLoading: false,
	locations: {
		locations: [],
		filtered: [],
		header: [],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	selectedLoc: {
		index: -1,
		loc: null,
	},
	newLoc: {
		title: "",
		accountsReceivableCodes: "",
		accountsPayableCodes: "",
		selectedLocation: {
			index: -1,
			location: null,
		},
	},
}

const locationReducer = (state = init, action) => {
	switch (action.type) {
		case SET_LOCATIONS:
			return Object.assign({}, state, {
				locations: {
					...state.locations,
					locations: action.payload.locations,
					filtered: action.payload.filtered,
					header: action.payload.header,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_LOCATION_LOADING:
			return Object.assign({}, state, {
				locations: {
					...state.locations,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_ADM_LOCATION_FILTER:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_LOCATION_DISPLAY_INACTIVE:
			return Object.assign({}, state, {
				displayInactive: action.payload.isDisplay,
			})
		case SET_SELECTED_LOC:
			return Object.assign({}, state, {
				selectedLoc: {
					...state.selectedLoc,
					index: action.payload.index,
					loc: action.payload.loc,
				},
			})
		case SET_LOC_SEARCH_TEXT:
			return Object.assign({}, state, {
				searchText: action.payload.searchText,
			})
		case SET_LOC_ORG:
			return Object.assign({}, state, {
				isOrg: action.payload.value,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
				isOR: false,
			})
		case SET_LOC_BRANCH:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: action.payload.value,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
				isOR: false,
			})
		case SET_LOC_INVENTORY:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: action.payload.value,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
				isOR: false,
			})
		case SET_LOC_GENERAL:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: action.payload.value,
				isIPD: false,
				isOPD: false,
				isOR: false,
			})
		case SET_LOC_IPD:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: action.payload.value,
				isOPD: false,
				isOR: false,
			})
		case SET_LOC_OPD:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: action.payload.value,
				isOR: false,
			})
		case SET_LOC_OPERATING_ROOM:
			return Object.assign({}, state, {
				isOrg: false,
				isBranch: false,
				isInventory: false,
				isGeneralLocation: false,
				isIPD: false,
				isOPD: false,
				isOR: action.payload.value,
			})
		case SET_ADM_LOC_ORGS:
			return Object.assign({}, state, {
				orgs: action.payload.value,
			})
		case SET_ADM_LOC_MODAL_NEW_SELECTED_LOC:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					selectedLocation: {
						index: action.payload.index,
						location: action.payload.selected,
					},
				},
			})
		case SET_ADMIN_LOC_MODAL_NEW_LOCATION_TITLE:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					title: action.payload.title,
				},
			})
		case SET_LOC_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_LOC_MODAL_NEW_AP_CODES:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					accountsPayableCodes: action.payload.value,
				},
			})
		case SET_LOC_MODAL_NEW_AR_CODES:
			return Object.assign({}, state, {
				newLoc: {
					...state.newLoc,
					accountsReceivableCodes: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default locationReducer
