import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setPSModalNewPendingInvoices, setSelected } from "~/redux/action"
import { SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PSModalNewPendingInvocie extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectInvoice = this.onSelectInvoice.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.PS.modalNew.pendingInvoiceTable.original,
				this.props.PS.modalNew.pendingInvoiceTable.original
			)
		) {
			this.props.setSelected(SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setPSModalNewPendingInvoices([])
		this.props.setSelected(SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE, -1, null)
	}

	onSelectInvoice(service, index) {
		this.props.setSelected(
			SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE,
			index,
			this.props.PS.modalNew.pendingInvoiceTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectInvoice}
						data={this.props.PS.modalNew.pendingInvoiceTable}
						isClearHighlight={this.props.PS.modalNew.selectedPendingInvoice.index === -1}
						highlightedRow={this.props.PS.modalNew.selectedPendingInvoice.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	PS: state.accounting.paymentSlip,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setPSModalNewPendingInvoices,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PSModalNewPendingInvocie))
