import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_ARC_AR_ACTIVITIES,
	SET_ARC_AR_ACTIVITIES_LOADING,
	SET_ARC_BANKS,
	SET_ARC_BANKS_LOADING,
	SET_ARC_BILLING_STATEMENT,
	SET_ARC_CHART_OF_ACCOUNTS,
	SET_ARC_END_DATE_TIME,
	SET_ARC_FILTERS,
	SET_ARC_FILTER_RECEIPT_ID,
	SET_ARC_GL_DATE_TIME,
	SET_ARC_INSURANCE_COMPANIES,
	SET_ARC_INSURANCE_COMPANIES_LOADING,
	SET_ARC_LOCATION,
	SET_ARC_MODAL_GL_DESCRIPTION,
	SET_ARC_MODAL_GL_SELECTED_GL,
	SET_ARC_MODAL_LOCATION_ORGS,
	SET_ARC_MODAL_LOCATION_ORGS_LOADING,
	SET_ARC_MODAL_LOCATION_SELECTED_ORG,
	SET_ARC_MODAL_SEARCH_RECEIPT_ID,
	SET_ARC_PAYMENTS,
	SET_ARC_PAYMENTS_LOADING,
	SET_ARC_RECEIPTS,
	SET_ARC_SELECTABLE_ACCOUNTING_CODES,
	SET_ARC_SELECTED_BILLING_STATEMENT,
	SET_ARC_SELECTED_GROUP,
	SET_ARC_SELECTED_RECEIPT,
	SET_ARC_START_DATE_TIME,
	SET_ARC_TAB_GL_GROUP,
	SET_ARC_TAB_RECEIPT_GROUP,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	generalLedgerDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterReceiptId: "",
	filters: [],
	chartOfAccounts: [],
	accountsReceivableActivities: [],
	selectableAccountingCodes: [],
	insuranceCompanies: [],
	payments: [],
	banks: [],
	isAccountsReceivableActivitiesLoading: false,
	isInsuranceCompaniesLoading: false,
	isPaymentsLoading: false,
	isBanksLoading: false,
	selectedReceipt: {
		index: -1,
		receipt: null,
	},
	selectedGroup: {
		index: -1,
		group: null,
	},
	selectedBS: {
		index: -1,
		billingStatement: null,
	},
	receiptTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	tabReceiptTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.totalPriceDiscounted,
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	tabGLTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.debit,
			ResourceAssistance.Message.credit,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	billingStatementTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.description,
			ResourceAssistance.Hospitel.balance,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.remark,
			ResourceAssistance.Message.discountBill,
			ResourceAssistance.Message.totalPriceDiscounted,
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalGeneralLedger: {
		description: "",
		selectedGL: {
			index: -1,
			GL: null,
		},
	},
	modalSearch: {
		searchReceiptId: "",
	},
}

const accountsReceiveReducer = (state = init, action) => {
	switch (action.type) {
		case SET_ARC_AR_ACTIVITIES:
			return Object.assign({}, state, {
				accountsReceivableActivities: action.payload.value,
			})
		case SET_ARC_AR_ACTIVITIES_LOADING:
			return Object.assign({}, state, {
				isAccountsReceivableActivitiesLoading: action.payload.isLoading,
			})
		case SET_ARC_SELECTABLE_ACCOUNTING_CODES:
			return Object.assign({}, state, {
				selectableAccountingCodes: action.payload.value,
			})
		case SET_ARC_INSURANCE_COMPANIES:
			return Object.assign({}, state, {
				insuranceCompanies: action.payload.value,
			})
		case SET_ARC_INSURANCE_COMPANIES_LOADING:
			return Object.assign({}, state, {
				isInsuranceCompaniesLoading: action.payload.isLoading,
			})
		case SET_ARC_PAYMENTS:
			return Object.assign({}, state, {
				payments: action.payload.value,
			})
		case SET_ARC_PAYMENTS_LOADING:
			return Object.assign({}, state, {
				isPaymentsLoading: action.payload.isLoading,
			})
		case SET_ARC_BANKS:
			return Object.assign({}, state, {
				banks: action.payload.value,
			})
		case SET_ARC_BANKS_LOADING:
			return Object.assign({}, state, {
				isBanksLoading: action.payload.isLoading,
			})
		case SET_ARC_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_ARC_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_ARC_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_ARC_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_ARC_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_ARC_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_ARC_GL_DATE_TIME:
			return Object.assign({}, state, {
				generalLedgerDateTime: action.payload.value,
			})
		case SET_ARC_FILTERS:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_ARC_FILTER_RECEIPT_ID:
			return Object.assign({}, state, {
				filterReceiptId: action.payload.value,
			})
		case SET_ARC_RECEIPTS:
			return Object.assign({}, state, {
				receiptTable: {
					...state.receiptTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_ARC_SELECTED_RECEIPT:
			return Object.assign({}, state, {
				selectedReceipt: {
					index: action.payload.index,
					receipt: action.payload.selected,
				},
			})
		case SET_ARC_TAB_RECEIPT_GROUP:
			return Object.assign({}, state, {
				tabReceiptTable: {
					...state.tabReceiptTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_ARC_TAB_GL_GROUP:
			return Object.assign({}, state, {
				tabGLTable: {
					...state.tabGLTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_ARC_SELECTED_GROUP:
			return Object.assign({}, state, {
				selectedGroup: {
					index: action.payload.index,
					group: action.payload.selected,
				},
			})
		case SET_ARC_BILLING_STATEMENT:
			return Object.assign({}, state, {
				billingStatementTable: {
					...state.billingStatementTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_ARC_SELECTED_BILLING_STATEMENT:
			return Object.assign({}, state, {
				selectedBS: {
					index: action.payload.index,
					billingStatement: action.payload.selected,
				},
			})
		case SET_ARC_MODAL_GL_SELECTED_GL:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					selectedGL: {
						index: action.payload.index,
						GL: action.payload.selected,
					},
				},
			})
		case SET_ARC_MODAL_GL_DESCRIPTION:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					description: action.payload.value,
				},
			})
		case SET_ARC_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_ARC_MODAL_SEARCH_RECEIPT_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchReceiptId: action.payload.value,
				},
			})
		default:
			return state
	}
}

export default accountsReceiveReducer
