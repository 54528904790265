import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import IPDModalMedicalCertificateDiagnosis from "./IPDModalMedicalCertificateDiagnosis"
import IPDModalMedicalCertificateDocOpt from "./IPDModalMedicalCertificateDocOpt"
import IPDModalMedicalCertificateDoctor from "./IPDModalMedicalCertificateDoctor"
import IPDModalMedicalCertificateExamDate from "./IPDModalMedicalCertificateExamDate"
import IPDModalMedicalCertificateInvestigation from "./IPDModalMedicalCertificateInvestigation"
import IPDModalMedicalCertificateService from "./IPDModalMedicalCertificateService"
import IPDModalMedicalCertificateSymptom from "./IPDModalMedicalCertificateSymptom"
import IPDModalMedicalCertificateTreatment from "./IPDModalMedicalCertificateTreatment"

class IPDModalMedicalCertificateDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<IPDModalMedicalCertificateService />
				<IPDModalMedicalCertificateDoctor />
				<IPDModalMedicalCertificateExamDate />
				<IPDModalMedicalCertificateSymptom />
				<IPDModalMedicalCertificateDiagnosis />
				<IPDModalMedicalCertificateInvestigation />
				<IPDModalMedicalCertificateTreatment />
				<IPDModalMedicalCertificateDocOpt />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalMedicalCertificateDetails)
