import React from "react"
import ScrollableTable from "../scroll_table/ScrollableTable"
import propTypes from "prop-types"
import { ResourceAssistance } from "~/i18n"
import _ from "lodash"
import { Utils } from "~/utils/Utils"
import { Col, Row } from "react-bootstrap"

class ICDDiagnosis extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			icdTable: {
				original: [],
				filtered: [],
				header: [ResourceAssistance.Message.code, ResourceAssistance.Message.name],
				body: [],
			},
		}

		this.onSelectICD = this.onSelectICD.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.selectedType, this.props.selectedType) ||
			!_.isEqual(prevProps.searchCode, this.props.searchCode) ||
			!_.isEqual(prevProps.searchName, this.props.searchName)
		) {
			if (this.props.selectedType.type) {
				this.renderICDs(this.props.selectedType.type.icds)
			} else {
				this.renderICDs([])
			}
		}
	}

	renderICDs(icds) {
		let filtered = icds
			.filter(
				(each) =>
					each.code.toLowerCase().includes(this.props.searchCode.toLowerCase()) &&
					each.description.toLowerCase().includes(this.props.searchName.toLowerCase())
			)
			.sort((a, b) => Utils.sort(a.code, b.code))
		let body = filtered.map((each) => {
			return [each.code, each.description]
		})
		this.setState({
			icdTable: {
				...this.state.icdTable,
				original: icds,
				filtered: filtered,
				body: body,
			},
		})
	}

	onSelectICD(icd, index) {
		this.props.onSelectICD(this.state.icdTable.filtered[index], index)
	}

	render() {
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectICD}
						data={this.state.icdTable}
						isClearHighlight={this.props.selectedICD.index === -1}
						highlightedRow={this.props.selectedICD.index}
					/>
				</Col>
			</Row>
		)
	}
}

ICDDiagnosis.propTypes = {
	onSelectICD: propTypes.func,
}

ICDDiagnosis.defaultProps = {}

export default ICDDiagnosis
