import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import GInput from "~/view/component/input/GInput"
import { Utils } from "~/utils/Utils"

class PharmacyModalReturnEditField extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			amount: 0,
		}

		this.onAmount = this.onAmount.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	componentDidMount() {
		this.setState({
			amount: this.props.amount,
		})
	}

	componentWillUnmount() {
		this.setState({
			amount: 0,
		})
	}

	onAmount(event) {
		this.setState({
			amount: event.target.value,
		})
	}

	render() {
		return (
			<Row>
				<Col md={2} />
				<Col>
					<GInput
						style={{ textAlign: "center" }}
						type={ResourceAssistance.FormControl.type.number}
						placeholder={translate(ResourceAssistance.Message.amount)}
						max={Utils.BigNumber(this.props.pharmacy.modalReturn.selectedLot.lot.amount)
							.minus(this.props.pharmacy.modalReturn.selectedLot.lot.returned)
							.toNumber()}
						onChange={this.onAmount}
						value={this.state.amount}
					/>
				</Col>
				<Col md={2} />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PharmacyModalReturnEditField)
