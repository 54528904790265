import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setCashierAdmissions, setSelected } from "~/redux/action"
import { SET_CASHIER_SELECTED_ADMISSION } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierLeftAdmissionRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierLeftAdmissionRow",
		}
		this.selectRow = this.selectRow.bind(this)
	}

	componentDidMount() {
		this.props.setCashierAdmissions(this.props.cashier.patient.admissions)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.patient, this.props.cashier.patient)) {
			if (this.props.cashier.patient) {
				this.props.setCashierAdmissions(this.props.cashier.patient.admissions)
			} else {
				this.props.setCashierAdmissions([])
			}
		}

		if (!_.isEqual(prevProps.cashier.admissionTable.filtered, this.props.cashier.admissionTable.filtered)) {
			let index = this.props.cashier.selectedAdmission.admission
				? this.props.cashier.admissionTable.filtered.findIndex(
						(admission) => admission.id === this.props.cashier.selectedAdmission.admission.id
				  )
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_CASHIER_SELECTED_ADMISSION, index, this.props.cashier.admissionTable.filtered[index])
			} else {
				this.props.setSelected(SET_CASHIER_SELECTED_ADMISSION, -1, null)
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_CASHIER_SELECTED_ADMISSION, index, this.props.cashier.admissionTable.filtered[index])
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.cashier.admissionTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.cashier.selectedAdmission.index === -1}
						highlightedRow={this.props.cashier.selectedAdmission.index}
					/>
					{this.props.cashier.admissionTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierAdmissions,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierLeftAdmissionRow)
