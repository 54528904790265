import { Badge, Select } from "antd"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_OR_LENGTH_OF_STAY,
	SET_OPD_MODAL_OR_NEW_EVENTS,
	SET_OPD_MODAL_OR_SELECTED_CASE,
	SET_OPD_MODAL_OR_SELECTED_ROOM,
	SET_OPD_OPERATING_ROOM_CALENDAR_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class OutpatientModalORGeneral extends React.Component {
	constructor(props) {
		super(props)

		this.onRoom = this.onRoom.bind(this)
		this.onCalendar = this.onCalendar.bind(this)
		this.onORCaseType = this.onORCaseType.bind(this)
		this.onLengthOfStay = this.onLengthOfStay.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedORRequest.request) {
			this.props.setValue(SET_OPD_MODAL_OR_LENGTH_OF_STAY, this.props.opd.selectedORRequest.request.lengthOfStay)
			let idx = this.props.opd.operatingRoomCases.findIndex((orCase) => orCase.displayName === this.props.opd.selectedORRequest.request.operatingRoomCase)
			if (idx > -1) {
				this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_CASE, idx, this.props.opd.operatingRoomCases[idx])
			}
			let roomIdx = this.props.opd.operatingRooms.findIndex((operatingRoom) => operatingRoom.id === this.props.opd.selectedORRequest.request.operatingRoom.id)
			if (roomIdx > -1) {
				this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ROOM, roomIdx, this.props.opd.operatingRooms[roomIdx])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ROOM, -1, null)
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_CASE, -1, null)
		this.props.setValue(SET_OPD_MODAL_OR_NEW_EVENTS, [])
		this.props.setValue(SET_OPD_MODAL_OR_LENGTH_OF_STAY, "")
	}

	isApprovedFieldDisabled() {
		return (
			!this.props.opd.selectedORRequest.request ||
			(this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.pending)
		)
	}

	isPreApprovedFieldDisabled() {
		return this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.approved
	}

	isOperatingRoomDisabled() {
		return (
			(this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.operatingRoomSchedules.length > 0) ||
			this.props.opd.modalOR.newEvents.length > 0
		)
	}

	onRoom(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ROOM, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ROOM, v, this.props.opd.operatingRooms[v])
	}

	onCalendar() {
		this.props.onModalDisplayAction(SET_OPD_OPERATING_ROOM_CALENDAR_DISPLAY, true)
	}

	onORCaseType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_CASE, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_CASE, v, this.props.opd.operatingRoomCases[v])
	}

	onLengthOfStay(v) {
		this.props.setValue(SET_OPD_MODAL_OR_LENGTH_OF_STAY, v.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.general)}</legend>
				<Row className={"g-1"}>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.room)}
							loading={this.props.opd.isOperatingRoomsLoading}
							disabled={this.isPreApprovedFieldDisabled() || this.isOperatingRoomDisabled()}
							value={this.props.opd.modalOR.selectedRoom.room ? this.props.opd.modalOR.selectedRoom.index : undefined}
							onChange={this.onRoom}
						>
							{Utils.renderSelects(this.props.opd.operatingRooms, false)}
						</Select>
					</Col>
					<Col sm="auto">
						<Badge dot={!this.isOperatingRoomDisabled()}>
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={!this.props.opd.modalOR.selectedRoom.room || this.isPreApprovedFieldDisabled()}
								onClick={this.onCalendar}
							>
								<IoCalendarSharp size={ResourceAssistance.ReactIcon.size_smd} />
							</Button>
						</Badge>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.orCase)}
							loading={this.props.opd.isORCasesLoading}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.selectedORCaseType.type ? this.props.opd.modalOR.selectedORCaseType.index : undefined}
							onChange={this.onORCaseType}
						>
							{Utils.renderSelects(this.props.opd.operatingRoomCases, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.estimatedLengthOfStay)}
							disabled={this.isPreApprovedFieldDisabled()}
							onChange={this.onLengthOfStay}
							value={this.props.opd.modalOR.lengthOfStay}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalORGeneral))
