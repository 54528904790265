import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue } from "~/redux/action"
import { SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import OperatingRoomModalIntraAneTechniqueDetails from "./OperatingRoomModalIntraAneTechniqueDetails"
import { axios } from "~/axios"
import { Utils } from "~/utils/Utils"
import _ from "lodash"

class OperatingRoomModalIntraAneTechnique extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_TECHNIQUE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (
			!this.props.operatingRoom.selectedRequest.request ||
			(_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail) &&
				_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia) &&
				_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.isMonitorAnesthesiaCare)) ||
			(!_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail) &&
				(this.props.operatingRoom.modalIntraAneTechnique.induction === "" || this.props.operatingRoom.modalIntraAneTechnique.maintenance === "")) ||
			(!_.isEmpty(this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia) &&
				(this.props.operatingRoom.modalIntraAneTechnique.isAseptic === "" ||
					this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach === "" ||
					this.props.operatingRoom.modalIntraAneTechnique.type === ""))
		) {
			return true
		}
		let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
			? undefined
			: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
		let target = Object.assign({}, op, {
			generalAnesthesia: this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail.join(","),
			induction: this.props.operatingRoom.modalIntraAneTechnique.induction,
			maintenance: this.props.operatingRoom.modalIntraAneTechnique.maintenance,
			maintenanceOther: this.props.operatingRoom.modalIntraAneTechnique.maintenanceOther,
			regionalAnesthesia: this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia,
			regionalAnesthesiaOther: this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesiaOther,
			asepticTechniqueDone: this.props.operatingRoom.modalIntraAneTechnique.isAseptic,
			techniqueApproach: this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach,
			techniqueApproachOther: this.props.operatingRoom.modalIntraAneTechnique.techniqueApproachOther,
			equipment: this.props.operatingRoom.modalIntraAneTechnique.equipmentDetail.join(","),
			site: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.site),
			regionalAnesthesiaPosition: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.position),
			needleSize: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.needleSize),
			type: this.props.operatingRoom.modalIntraAneTechnique.type,
			catheter: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.catheter),
			punctureAttemptingCounts: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.punctureAttemptingCounts),
			anesthesiaLevel: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.anesthesiaLevel),
			agentUsed: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.agentUsed),
			monitorAnesthesiaCare: `${
				this.props.operatingRoom.modalIntraAneTechnique.isMonitorAnesthesiaCare
			},${this.props.operatingRoom.modalIntraAneTechnique.monitorAnesthesiaCareDetail.join(",")}`,
		})
		return _.isEqual(op, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: `${ResourceAssistance.Url.operatingRoom.saveAnesthesiaIntraOperatives}?type=ANESTHESIA_TECHNIQUE`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				anesthesiaIntraOperatives: [
					{
						id: _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
							? 0
							: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0].id,
						generalAnesthesia: this.props.operatingRoom.modalIntraAneTechnique.generalAnesthesiaDetail.join(","),
						induction: this.props.operatingRoom.modalIntraAneTechnique.induction,
						maintenance: this.props.operatingRoom.modalIntraAneTechnique.maintenance,
						maintenanceOther: this.props.operatingRoom.modalIntraAneTechnique.maintenanceOther,
						regionalAnesthesia: this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesia,
						regionalAnesthesiaOther: this.props.operatingRoom.modalIntraAneTechnique.regionalAnesthesiaOther,
						asepticTechniqueDone: this.props.operatingRoom.modalIntraAneTechnique.isAseptic,
						techniqueApproach: this.props.operatingRoom.modalIntraAneTechnique.isTechniqueApproach,
						techniqueApproachOther: this.props.operatingRoom.modalIntraAneTechnique.techniqueApproachOther,
						equipment: this.props.operatingRoom.modalIntraAneTechnique.equipmentDetail.join(","),
						site: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.site),
						position: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.position),
						needleSize: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.needleSize),
						type: this.props.operatingRoom.modalIntraAneTechnique.type,
						catheter: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.catheter),
						punctureAttemptingCounts: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.punctureAttemptingCounts),
						anesthesiaLevel: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.anesthesiaLevel),
						agentUsed: Utils.trim(this.props.operatingRoom.modalIntraAneTechnique.agentUsed),
						monitorAnesthesiaCare: `${
							this.props.operatingRoom.modalIntraAneTechnique.isMonitorAnesthesiaCare
						},${this.props.operatingRoom.modalIntraAneTechnique.monitorAnesthesiaCareDetail.join(",")}`,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.anesthesiaTechnique)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalIntraAneTechniqueDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomIntraAnesthesiaTechniqueDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalIntraAneTechnique)
