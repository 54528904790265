import { Select } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION, SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class ORModalIntraAneServiceFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onFilterDescription = this.onFilterDescription.bind(this)
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE, -1, null)
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION, "")
	}

	render() {
		return (
			<Row>
				<Col md={3}>
					<Select
						showSearch
						style={{ textAlign: "center", margin: 0 }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
						placeholder={translate(ResourceAssistance.Message.serviceType)}
						loading={this.props.operatingRoom.isServiceTypesLoading}
						value={
							this.props.operatingRoom.modalIntraAneService.selectedType.type ? this.props.operatingRoom.modalIntraAneService.selectedType.index : undefined
						}
						onChange={this.onType}
					>
						{Utils.renderSelects(this.props.operatingRoom.serviceTypes, false)}
					</Select>
				</Col>
				<Col>
					<SearchBox
						num={1}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.description]}
						callbacks={[this.onFilterDescription]}
					/>
				</Col>
			</Row>
		)
	}
	onType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_OR_MODAL_INTRA_ANE_SERVICE_SELECTED_TYPE, v, this.props.operatingRoom.serviceTypes[v])
	}
	onFilterDescription(event) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_SERVICE_FILTER_DESCRIPTION, Utils.trim(event.target.value))
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ORModalIntraAneServiceFilter))
