import { Switch, TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND, SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class AdmHealthCarePlanModalARDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onCOA = this.onCOA.bind(this)
		this.onLinkGL = this.onLinkGL.bind(this)
	}

	componentDidMount() {
		if (this.props.type.healthcarePlan.selectedAR.AR) {
			let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.type.chartOfAccounts, this.props.type.healthcarePlan.selectedAR.AR.chartOfAccounts)
			if (!_.isEmpty(selectedAry)) {
				this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR, selectedAry[0], selectedAry[1])
			} else {
				this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR, -1, null)
			}
			this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND, this.props.type.healthcarePlan.selectedAR.AR.linkGL)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR, -1, null)
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND, false)
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.type.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_SELECTED_AR, value, this.props.type.chartOfAccounts[value])
		}
	}

	onLinkGL(value) {
		this.props.setValue(SET_ADM_HEALTH_CARE_PLAN_MODAL_ACCOUNTS_RECEIVABLE_LINK_GL_IND, value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							loading={this.props.type.isChartOfAccountsLoading}
							value={
								this.props.type.healthcarePlan.modalAccountsReceivable.selectedAR.AR
									? this.props.type.healthcarePlan.modalAccountsReceivable.selectedAR.index
									: undefined
							}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.type.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"])}
						/>
					</Col>
				</Row>
				<Row>
					<Col />
					<Col md="auto">
						<Switch
							style={this.props.type.healthcarePlan.modalAccountsReceivable.isLinkGL ? { backgroundColor: ResourceAssistance.CSS.Color.dark_green } : undefined}
							checkedChildren={translate(ResourceAssistance.Message.linkGL)}
							unCheckedChildren={translate(ResourceAssistance.Message.unlinkGL)}
							checked={this.props.type.healthcarePlan.modalAccountsReceivable.isLinkGL}
							onChange={this.onLinkGL}
						/>
					</Col>
					<Col />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmHealthCarePlanModalARDetails))
