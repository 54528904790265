import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import SearchableCriteria from "~/view/component/search/SearchableCriteria"
import NotificationSearchResult from "./NotificationSearchResult"

class NotificationSearchDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{_.isEmpty(this.props.notification.modalSearch.patientTable.original) ? (
					<SearchableCriteria {...this.props} onChange={this.props.onSearchChange} />
				) : (
					<NotificationSearchResult />
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	notification: state.menu.notification,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSearchDetails)
