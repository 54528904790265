import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GoSearch } from "react-icons/go"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setHospitelPatients,
	setLoadingAction,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import {
	SET_HOSPITEL_HOSPITEL_NEW_DISPLAY,
	SET_HOSPITEL_MODAL_REGISTER_PATIENT,
	SET_HOSPITEL_PATIENTS_LOADING,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import HospitelModalRegisterDetails from "./HospitelModalRegisterDetails"

class HospitelModalRegister extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalRegister",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onSearch = this.onSearch.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (
				this.props.warning.isContinue &&
				_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITEL.hospitel.modalRegister.done)
			) {
				this.registerHospitel()
			}
		}
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_HOSPITEL_NEW_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return (
			!this.props.hospitel.modalRegister.patient ||
			(this.props.hospitel.selectedHospitel.hospitel &&
				Utils.trim(this.props.hospitel.department.displayName).toLowerCase() ===
					Utils.trim(this.props.hospitel.selectedHospitel.hospitel.department.displayName).toLowerCase() &&
				Utils.trim(this.props.hospitel.selectedHospitel.hospitel.room).toLowerCase() ===
					Utils.trim(this.props.hospitel.modalRegister.room).toLowerCase())
		)
	}

	registerHospitel() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.ipd.registerHospitel,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.hospitel.selectedHospitel.hospitel ? this.props.hospitel.selectedHospitel.hospitel.id : 0,
				patientId: this.props.hospitel.modalRegister.patient.id,
				admissionId: this.props.hospitel.selectedHospitel.hospitel
					? this.props.hospitel.selectedHospitel.hospitel.admission.id
					: this.props.hospitel.modalRegister.patient.admissions.find((admission) => !admission.dischargedDateTime).id,
				departmentId: this.props.hospitel.department.id,
				room: Utils.trim(this.props.hospitel.modalRegister.room),
			},
		}

		let callback = (res) => {
			this.props.setHospitelPatients(res.data.patients)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_PATIENTS_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)

		this.reset()
	}

	onClose() {
		this.reset()
	}

	onSearch(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.ipd.getPatient,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				identificationNumber: this.props.hospitel.modalRegister.identificationNumber,
			},
		}
		let callback = (res) => {
			if (res.data.patient) {
				this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_PATIENT, res.data.patient)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		this.props.setWarningId(ResourceAssistance.ID.HOSPITEL.hospitel.modalRegister.done)
		this.props.setWarningMsgAction(
			this.props.hospitel.modalRegister.patient.firstName +
				" " +
				this.props.hospitel.modalRegister.patient.lastName +
				"\n" +
				this.props.hospitel.department.displayName +
				"/" +
				this.props.hospitel.modalRegister.room
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={this.props.hospitel.modalRegister.patient ? ResourceAssistance.Modal.size.lg : undefined}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form
					className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}
					onSubmit={this.onDone}
				>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.registerPatient)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalRegisterDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						{this.props.hospitel.modalRegister.patient ? (
							<Button
								variant={ResourceAssistance.Button.variant.green}
								type={ResourceAssistance.Button.type.submit}
								disabled={this.isDoneBtnDisabled()}
							>
								<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.save)}
							</Button>
						) : (
							<Button
								variant={ResourceAssistance.Button.variant.primary}
								type={ResourceAssistance.Button.type.submit}
								disabled={_.isEmpty(this.props.hospitel.modalRegister.identificationNumber)}
								onClick={this.onSearch}
							>
								<GoSearch size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.search)}
							</Button>
						)}
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isNewDisplay,
	warning: state.modal.warning,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setWarningId,
			setWarningMsgAction,
			onModalDisplayAction,
			setLoadingAction,
			setHospitelPatients,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalRegister))
