import { TreeSelect } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import { SET_ARC_CHART_OF_ACCOUNTS, SET_ARC_MODAL_GL_DESCRIPTION, SET_ARC_MODAL_GL_SELECTED_GL, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class ARCModalGeneralLedgerDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadChartOfAccounts = this.loadChartOfAccounts.bind(this)
		this.onCOA = this.onCOA.bind(this)
		this.onDescr = this.onDescr.bind(this)
	}

	componentDidMount() {
		if (_.isEmpty(this.props.ARC.chartOfAccounts)) {
			this.loadChartOfAccounts()
		}

		let selectedGroup = this.props.ARC.selectedGroup
		let coa = selectedGroup.group[2]
		if (coa.accountingCode) {
			let selectedAry = Utils.findChartOfAccountsFromGroup(this.props.ARC.chartOfAccounts, coa.accountingCode)
			if (!_.isEmpty(selectedAry)) {
				this.props.setSelected(SET_ARC_MODAL_GL_SELECTED_GL, selectedAry[0], selectedAry[1])
			} else {
				this.props.setSelected(SET_ARC_MODAL_GL_SELECTED_GL, -1, null)
			}
			this.props.setValue(
				SET_ARC_MODAL_GL_DESCRIPTION,
				Utils.decodeAccountingTransDescr(coa.accountingTransDesc, "", Utils.formatDate(this.props.ARC.selectedReceipt.receipt.creationDateTime))
			)
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_ARC_MODAL_GL_SELECTED_GL, -1, null)
		this.props.setValue(SET_ARC_MODAL_GL_DESCRIPTION, "")
	}

	loadChartOfAccounts() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.chartOfAccounts.getChartOfAccounts,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_ARC_CHART_OF_ACCOUNTS, Utils.getChartOfAccountsByGroup(Utils.groupBy(res.data.accounts, "coreCodeName")))
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onCOA(value) {
		if (value === undefined) {
			this.props.setSelected(SET_ARC_MODAL_GL_SELECTED_GL, -1, null)
			return
		}
		if (typeof value === "string") {
			let keys = value.split("-")
			this.props.setSelected(
				SET_ARC_MODAL_GL_SELECTED_GL,
				value,
				(function getSelectedAccount(accounts, idx) {
					if (idx === keys.length - 1) {
						return accounts[keys[idx]]
					}
					return getSelectedAccount(accounts[keys[idx]].accounts, idx + 1)
				})(this.props.ARC.chartOfAccounts[keys[0]].values, 1)
			)
		} else {
			this.props.setSelected(SET_ARC_MODAL_GL_SELECTED_GL, value, this.props.ARC.chartOfAccounts[value])
		}
	}

	onDescr(e) {
		this.props.setValue(SET_ARC_MODAL_GL_DESCRIPTION, e.target.value)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<TreeSelect
							style={{
								textAlign: "center",
							}}
							showSearch
							allowClear
							treeLine={{ showLeafIcon: false }}
							filterTreeNode={(input, treeNode) => {
								return treeNode.title.toLowerCase().includes(input)
							}}
							treeExpandAction="click"
							placeholder={translate(ResourceAssistance.Message.chartOfAccounts)}
							value={this.props.ARC.modalGeneralLedger.selectedGL.GL ? this.props.ARC.modalGeneralLedger.selectedGL.index : undefined}
							onChange={this.onCOA}
							treeData={Utils.getTreeNodesFromChartOfAccountsGroup(this.props.ARC.chartOfAccounts, true, [], undefined, ["displayName", "fullCode"], false)}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							type={ResourceAssistance.FormControl.type.text}
							placeholder={translate(ResourceAssistance.Message.description)}
							value={this.props.ARC.modalGeneralLedger.description}
							onChange={this.onDescr}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ARCModalGeneralLedgerDetails))
