import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPGLModalNewEntries, setPostingGLs } from "~/redux/action"
import { SET_PAGE_LOADING, SET_PGL_ENTRY_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import PGLModalEntryDetails from "./PGLModalEntryDetails"
import _ from "lodash"
import { axios } from "~/axios"

class PGLModalEntry extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_PGL_ENTRY_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.PGL.modalEntry.selectedGL.GL) {
			return true
		}
		let target = Object.assign({}, this.props.PGL.selectedEntry.entry, {
			accountingCode: this.props.PGL.modalEntry.selectedGL.GL.fullCode,
			accountingChartName: this.props.PGL.modalEntry.selectedGL.GL.displayName,
			accountingChartDescription: this.props.PGL.modalEntry.selectedGL.GL.description,
			accountingTransDescr: Utils.trim(this.props.PGL.modalEntry.description),
			amount: this.props.PGL.modalEntry.amount,
			debit: this.props.PGL.modalEntry.isDebit,
		})
		return _.isEqual(this.props.PGL.selectedEntry.entry, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let entry = {
			id: this.props.PGL.selectedEntry.entry ? this.props.PGL.selectedEntry.entry.id : 0,
			accountingCode: this.props.PGL.modalEntry.selectedGL.GL.fullCode,
			accountingChartName: this.props.PGL.modalEntry.selectedGL.GL.displayName,
			accountingChartDescription: this.props.PGL.modalEntry.selectedGL.GL.description,
			accountingTransDescr: Utils.trim(this.props.PGL.modalEntry.description),
			amount: this.props.PGL.modalEntry.amount,
			debit: this.props.PGL.modalEntry.isDebit,
		}
		if (this.props.modalDisplay.isPostingGLNewDisplay) {
			let entries = Array.from(this.props.PGL.modalNew.entryTable.original)
			entries.push(entry)
			this.props.setPGLModalNewEntries(entries)
		} else {
			let params = {
				method: "POST",
				url: ResourceAssistance.Url.postingGL.updateEntry,
				withCredentials: true,
				headers: {
					"content-type": "application/json",
				},
				data: {
					orgId: this.props.PGL.location.id,
					postingGLId: this.props.PGL.selectedPGL.PGL.id,
					userId: this.props.login.user.id,
					postingGLs: [
						{
							id: this.props.PGL.selectedPGL.PGL.id,
							entries: [entry],
						},
					],
				},
			}
			let callback = (res) => {
				if (res.data.postingGLs.length > 0) {
					let postingGLs = this.props.PGL.postingGLTable.original.filter((PGL) => PGL.id !== res.data.postingGLs[0].id)
					postingGLs.push(res.data.postingGLs[0])
					this.props.setPostingGLs(postingGLs)
				}
			}
			let errorHandler = (error) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			let reqInterceptor = (config) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			}
			let resInterceptor = (response) => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
			axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		}
		this.reset()
	}

	render() {
		return (
			<Modal
				size={ResourceAssistance.Modal.size.md}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.journalEntry)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<PGLModalEntryDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	modalDisplay: state.modal,
	isDisplay: state.modal.isPostingGLEntryDisplay,
	login: state.login,
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPGLModalNewEntries,
			setPostingGLs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PGLModalEntry))
