import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CLLeftDateRow from "./CLLeftDateRow"
import CLLeftActionRow from "./CLLeftActionRow"
import CLLeftPatientRow from "./CLLeftPatientRow"
import CLLeftSearchRow from "./CLLeftSearchRow"

class CLLeft extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLLeft",
		}
	}

	render() {
		return (
			<Container fluid id={this.state.componentName}>
				<CLLeftDateRow {...this.props} />
				<CLLeftSearchRow {...this.props} />
				<CLLeftActionRow {...this.props} />
				<CLLeftPatientRow {...this.props} />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CLLeft)
