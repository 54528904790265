import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT } from "~/redux/type"

class IPDModalMedicalCertificateTreatment extends React.Component {
	constructor(props) {
		super(props)

		this.onTreatment = this.onTreatment.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedCertificate.certificate) {
			this.props.setValue(
				SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT,
				this.props.ipd.selectedCertificate.certificate.treatment
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT, "")
	}

	onTreatment(event) {
		this.props.setValue(SET_IPD_MODAL_MEDICAL_CERTIFICATE_TREATMENT, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.treatment)}</legend>
				<Row>
					<Col>
						<FormControl
							as={ResourceAssistance.FormControl.as.textArea}
							rows={2}
							value={this.props.ipd.modalCertificate.treatment}
							onChange={this.onTreatment}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(IPDModalMedicalCertificateTreatment)
