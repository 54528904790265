import * as React from "react"

function SvgSvgMedicalRecord(props) {
	return (
		<svg
			stroke="currentColor"
			fill="currentColor"
			stroke-width="0"
			viewBox="0 0 128 128"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M105.2 32.3V109c0 8.3-6.7 15-15 15H37.8c-8.3 0-15-6.7-15-15V32.3c0-8.3 6.7-15 15-15h52.4c8.3 0 15 6.7 15 15z"
				fill="#FFF"
			/>
			<path
				d="M64 39.2c-11.3 0-20.5 9.2-20.5 20.5S52.7 80.2 64 80.2 84.5 71 84.5 59.7 75.3 39.2 64 39.2zm13.4 24.4c0 .3-.2.5-.5.5H69c-.3 0-.5.2-.5.5v7.9c0 .3-.2.5-.5.5h-7.9c-.3 0-.5-.2-.5-.5v-7.9c0-.3-.2-.5-.5-.5h-7.9c-.3 0-.5-.2-.5-.5v-7.9c0-.3.2-.5.5-.5H59c.3 0 .5-.2.5-.5v-7.9c0-.3.2-.5.5-.5h7.9c.3 0 .5.2.5.5v7.9c0 .3.2.5.5.5h7.9c.3 0 .5.2.5.5v7.9z"
				fill="#E074A3"
			/>
			<path
				d="M90.2 125.2H37.8c-9 0-16.2-7.3-16.2-16.2V32.3c0-9 7.3-16.2 16.2-16.2h52.4c9 0 16.2 7.3 16.2 16.2V109c.1 9-7.2 16.2-16.2 16.2zM37.8 18.6c-7.6 0-13.8 6.2-13.8 13.8V109c0 7.6 6.2 13.8 13.8 13.8h52.4c7.6 0 13.8-6.2 13.8-13.8V32.3c0-7.6-6.2-13.8-13.8-13.8H37.8z"
				fill="#502D4B"
			/>
			<path
				d="M90.2 110.2H37.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h52.4c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM90.2 100.2H37.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h52.4c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2zM90.2 90.2H37.8c-.7 0-1.2-.6-1.2-1.2s.6-1.2 1.2-1.2h52.4c.7 0 1.2.6 1.2 1.2s-.5 1.2-1.2 1.2z"
				fill="#502D4B"
			/>
			<path
				d="M70.7 17.3v-6.7C70.7 7 67.7 4 64 4s-6.7 3-6.7 6.7v6.7H44V24c0 3.7 3 6.7 6.7 6.7h26.7c3.7 0 6.7-3 6.7-6.7v-6.7H70.7zM64 14.5c-2.1 0-3.8-1.7-3.8-3.8 0-2 1.7-3.7 3.8-3.7s3.8 1.7 3.8 3.8c0 2-1.7 3.7-3.8 3.7z"
				fill="#898288"
			/>
			<path
				d="M77.3 31.9H50.7c-4.4 0-7.9-3.6-7.9-7.9v-6.7c0-.7.6-1.2 1.2-1.2h12.1v-5.4c0-4.4 3.6-7.9 7.9-7.9s7.9 3.6 7.9 7.9v5.4H84c.7 0 1.2.6 1.2 1.2V24c0 4.4-3.5 7.9-7.9 7.9zM45.2 18.6V24c0 3 2.4 5.4 5.4 5.4h26.7c3 0 5.4-2.4 5.4-5.4v-5.4h-12c-.7 0-1.2-.6-1.2-1.2v-6.7c0-3-2.4-5.4-5.4-5.4s-5.4 2.4-5.4 5.4v6.7c0 .7-.6 1.2-1.2 1.2H45.2z"
				fill="#502D4B"
			/>
			<path
				d="M64 15.8c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-7.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zM64 81.4c-12 0-21.8-9.8-21.8-21.8S52 37.9 64 37.9s21.8 9.8 21.8 21.8S76 81.4 64 81.4zm0-41c-10.6 0-19.2 8.6-19.2 19.2S53.4 78.9 64 78.9s19.2-8.6 19.2-19.2S74.6 40.4 64 40.4z"
				fill="#502D4B"
			/>
		</svg>
	)
}

export default SvgSvgMedicalRecord
