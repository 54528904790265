import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS,
	SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC,
	SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR,
	SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT,
	SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT,
	SET_OR_MODAL_EDIT_ANE_PRE_CXR,
	SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_EKG,
	SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K,
	SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER,
	SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN,
	SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION,
} from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class OperatingRoomModalEditPreInvestigation extends React.Component {
	constructor(props) {
		super(props)

		this.onWBC = this.onWBC.bind(this)
		this.onHgb = this.onHgb.bind(this)
		this.onHct = this.onHct.bind(this)
		this.onPlatelets = this.onPlatelets.bind(this)
		this.onCBCDate = this.onCBCDate.bind(this)
		this.onNa = this.onNa.bind(this)
		this.onCl = this.onCl.bind(this)
		this.onK = this.onK.bind(this)
		this.onHCO3 = this.onHCO3.bind(this)
		this.onBun = this.onBun.bind(this)
		this.onCr = this.onCr.bind(this)
		this.onGlucose = this.onGlucose.bind(this)
		this.onElectrolytesDate = this.onElectrolytesDate.bind(this)
		this.onAlbumin = this.onAlbumin.bind(this)
		this.onGlobulin = this.onGlobulin.bind(this)
		this.onAST = this.onAST.bind(this)
		this.onALT = this.onALT.bind(this)
		this.onTotalBilirubin = this.onTotalBilirubin.bind(this)
		this.onDirectBilirubin = this.onDirectBilirubin.bind(this)
		this.onAlkalinePhosphatase = this.onAlkalinePhosphatase.bind(this)
		this.onLiverFunctionOther = this.onLiverFunctionOther.bind(this)
		this.onPT = this.onPT.bind(this)
		this.onPTT = this.onPTT.bind(this)
		this.onINR = this.onINR.bind(this)
		this.onEKG = this.onEKG.bind(this)
		this.onEKGDetail = this.onEKGDetail.bind(this)
		this.onCXR = this.onCXR.bind(this)
		this.onCXRDetail = this.onCXRDetail.bind(this)
		this.onOtherInvestigation = this.onOtherInvestigation.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let anesthesiaPreOP = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaPreOperatives[0]
			if (anesthesiaPreOP) {
				let cbc = anesthesiaPreOP.completeBloodCount.split(",")
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC, cbc[0])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB, cbc[1])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT, cbc[2])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS, cbc[3])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE, cbc[4])
				let electrolytes = anesthesiaPreOP.electrolytes.split(",")
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA, electrolytes[0])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL, electrolytes[1])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K, electrolytes[2])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3, electrolytes[3])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN, electrolytes[4])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR, electrolytes[5])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE, electrolytes[6])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE, electrolytes[7])
				let liverFunctionTest = anesthesiaPreOP.liverFunctionTest.split(",")
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN, liverFunctionTest[0])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN, liverFunctionTest[1])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST, liverFunctionTest[2])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT, liverFunctionTest[3])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN, liverFunctionTest[4])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN, liverFunctionTest[5])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE, liverFunctionTest[6])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER, liverFunctionTest[7])
				let coagulation = anesthesiaPreOP.coagulation.split(",")
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT, coagulation[0])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT, coagulation[1])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR, coagulation[2])
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG, _.isEmpty(anesthesiaPreOP.ekg))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL, anesthesiaPreOP.ekg)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR, _.isEmpty(anesthesiaPreOP.cxr))
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL, anesthesiaPreOP.cxr)
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION, anesthesiaPreOP.otherInvestigation)
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isEKG, this.props.operatingRoom.modalEditAnPre.isEKG)) {
			if (this.props.operatingRoom.modalEditAnPre.isEKG) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL, "")
			}
		}
		if (!_.isEqual(prevProps.operatingRoom.modalEditAnPre.isCXR, this.props.operatingRoom.modalEditAnPre.isCXR)) {
			if (this.props.operatingRoom.modalEditAnPre.isCXR) {
				this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL, "")
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR, true)
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL, "")
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION, "")
	}

	render() {
		return (
			<>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.completeBloodCount)}:</label>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.WBC)}
							value={this.props.operatingRoom.modalEditAnPre.cbcWBC}
							onChange={this.onWBC}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.hgb)}
							value={this.props.operatingRoom.modalEditAnPre.cbcHgb}
							onChange={this.onHgb}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.hct)}
							value={this.props.operatingRoom.modalEditAnPre.cbcHct}
							onChange={this.onHct}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.platelets)}
							value={this.props.operatingRoom.modalEditAnPre.cbcPlatelets}
							onChange={this.onPlatelets}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.date)}
							value={this.props.operatingRoom.modalEditAnPre.cbcDate}
							onChange={this.onCBCDate}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.electrolytes)}:</label>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.na })}${ResourceAssistance.Symbol.plus}`}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesNa}
							onChange={this.onNa}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.cl })}${ResourceAssistance.Symbol.minus}`}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesCl}
							onChange={this.onCl}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.k })}${ResourceAssistance.Symbol.plus}`}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesK}
							onChange={this.onK}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.hco3)}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesHCO3}
							onChange={this.onHCO3}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.bun)}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesBun}
							onChange={this.onBun}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.cr)}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesCr}
							onChange={this.onCr}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.glucose)}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesGlucose}
							onChange={this.onGlucose}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.date)}
							value={this.props.operatingRoom.modalEditAnPre.electrolytesDate}
							onChange={this.onElectrolytesDate}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.liverFunctionTest)}:</label>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.albumin)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionAlbumin}
							onChange={this.onAlbumin}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.globulin)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionGlobulin}
							onChange={this.onGlobulin}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.AST)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionAST}
							onChange={this.onAST}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.ALT)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionALT}
							onChange={this.onALT}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.totalBilirubin)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionTotalBilirubin}
							onChange={this.onTotalBilirubin}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.directBilirubin)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionDirectBilirubin}
							onChange={this.onDirectBilirubin}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.alkalinePhosphatase)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionAlkalinePhosphatase}
							onChange={this.onAlkalinePhosphatase}
						/>
					</Col>
					<Col>
						<GInput
							autoSize
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder={translate(ResourceAssistance.Message.other)}
							value={this.props.operatingRoom.modalEditAnPre.liverFunctionOther}
							onChange={this.onLiverFunctionOther}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.coagulation)}:</label>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.PT)}
							value={this.props.operatingRoom.modalEditAnPre.coagulationPT}
							onChange={this.onPT}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.PTT)}
							value={this.props.operatingRoom.modalEditAnPre.coagulationPTT}
							onChange={this.onPTT}
						/>
					</Col>
					<Col>
						<GInput
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.INR)}
							value={this.props.operatingRoom.modalEditAnPre.coagulationINR}
							onChange={this.onINR}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.EKG)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isEKG} onChange={this.onEKG}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={this.props.operatingRoom.modalEditAnPre.isEKG}
							value={this.props.operatingRoom.modalEditAnPre.EKGDetail}
							onChange={this.onEKGDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.CXR)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalEditAnPre.isCXR} onChange={this.onCXR}>
							<Radio style={{ flexDirection: "row" }} value={true}>
								{translate(ResourceAssistance.Message.normal)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={false}>
								{translate(ResourceAssistance.Message.abnormal)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							minRows={1}
							placeholder=""
							disabled={this.props.operatingRoom.modalEditAnPre.isCXR}
							value={this.props.operatingRoom.modalEditAnPre.CXRDetail}
							onChange={this.onCXRDetail}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							autoSize
							minRows={3}
							placeholder={translate(ResourceAssistance.Message.other)}
							value={this.props.operatingRoom.modalEditAnPre.otherInvestigation}
							onChange={this.onOtherInvestigation}
						/>
					</Col>
				</Row>
			</>
		)
	}

	onWBC(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_WBC, e.target.value)
	}
	onHgb(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_HGB, e.target.value)
	}
	onHct(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_HCT, e.target.value)
	}
	onPlatelets(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_PLATELETS, e.target.value)
	}
	onCBCDate(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CBC_DATE, e.target.value)
	}
	onNa(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_NA, e.target.value)
	}
	onCl(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CL, e.target.value)
	}
	onK(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_K, e.target.value)
	}
	onHCO3(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_HCO3, e.target.value)
	}
	onBun(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_BUN, e.target.value)
	}
	onCr(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_CR, e.target.value)
	}
	onGlucose(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_GLUCOSE, e.target.value)
	}
	onElectrolytesDate(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_ELECTROLYTES_DATE, e.target.value)
	}
	onAlbumin(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALBUMIN, e.target.value)
	}
	onGlobulin(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_GLOBULIN, e.target.value)
	}
	onAST(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_AST, e.target.value)
	}
	onALT(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALT, e.target.value)
	}
	onTotalBilirubin(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_TOTAL_BILIRUBIN, e.target.value)
	}
	onDirectBilirubin(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_DIRECT_BILIRUBIN, e.target.value)
	}
	onAlkalinePhosphatase(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_ALKALINE_PHOSPHATASE, e.target.value)
	}
	onLiverFunctionOther(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_LIVER_FUNCTION_TEST_OTHER, e.target.value)
	}
	onPT(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PT, e.target.value)
	}
	onPTT(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_PTT, e.target.value)
	}
	onINR(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_COAGULATION_INR, e.target.value)
	}
	onEKG(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG, e.target.value)
	}
	onEKGDetail(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_EKG_DETAIL, e.target.value)
	}
	onCXR(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR, e.target.value)
	}
	onCXRDetail(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_CXR_DETAIL, e.target.value)
	}
	onOtherInvestigation(e) {
		this.props.setValue(SET_OR_MODAL_EDIT_ANE_PRE_OTHER_INVESTIGATION, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalEditPreInvestigation))
