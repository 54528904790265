import React from "react"
import { Col, Row } from "react-bootstrap"
import { TiArrowBack } from "react-icons/ti"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { SET_PAGE_LOADING } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PRRightFifthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRRightFifthRow",
		}

		this.onReject = this.onReject.bind(this)
	}

	onReject(event, row, rIdx, cIdx) {
		if (this.props.isAllApproved()) {
			return
		}
		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.setOrderItemSelection}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: JSON.stringify({
				id: this.props.pr.selectedPR.pr ? this.props.pr.selectedPR.pr.id : -1,
				userId: this.props.login.user.id,
				items: [
					{
						id: this.props.pr.prSelectedItemTableData.selectedItems[rIdx].id,
						selected: false,
					},
				],
			}),
		}
		let callback = (res) => {
			this.props.updateOriginalOrders(res)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.approvedInventories)}</legend>
						<ScrollableTable
							striped
							hover
							enableHighlight
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
							btnMessages={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.reject })]}
							btnIcons={[this.props.isAllApproved() ? undefined : <TiArrowBack size={ResourceAssistance.ReactIcon.size} />]}
							onCheckedArray={[this.onReject]}
							enableOverlay={!this.props.isAllApproved()}
							data={this.props.pr.prSelectedItemTableData}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	pr: state.purchaseRequest,
})

export default injectIntl(connect(mapStateToProps, {})(PRRightFifthRow))
