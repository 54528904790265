import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setObjArray, setSearchText, setSelected } from "~/redux/action"
import {
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
	SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalMedicalCertificateService extends React.Component {
	constructor(props) {
		super(props)

		this.onCategory = this.onCategory.bind(this)
		this.onService = this.onService.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedCertificate.certificate) {
			let categoryIdx = this.props.opd.modalCertificate.categories.findIndex(
				(category) => category.id === this.props.opd.selectedCertificate.certificate.service.category.id
			)
			if (categoryIdx > -1) {
				this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY, categoryIdx, this.props.opd.modalCertificate.categories[categoryIdx])
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalCertificate.services, this.props.opd.modalCertificate.services)) {
			if (this.props.opd.selectedCertificate.certificate) {
				let serviceIdx = this.props.opd.modalCertificate.services.findIndex(
					(service) => service.id === this.props.opd.selectedCertificate.certificate.service.id
				)
				if (serviceIdx > -1) {
					this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE, serviceIdx, this.props.opd.modalCertificate.services[serviceIdx])
				}
			}
		}
		if (prevProps.opd.modalCertificate.selectedCategory.index !== this.props.opd.modalCertificate.selectedCategory.index) {
			this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE, -1, null)
			if (this.props.opd.modalCertificate.selectedCategory.category) {
				this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES, this.props.opd.modalCertificate.selectedCategory.category.services)
			} else {
				this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES, [])
			}
		}
	}

	componentWillUnmount() {
		this.props.setObjArray(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SERVICES, [])
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY, -1, null)
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE, -1, null)
	}

	onCategory(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_CATEGORY, v, this.props.opd.modalCertificate.categories[v])
	}

	onService(value) {
		this.props.setSelected(
			SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_SERVICE,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalCertificate.services[value] : null
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.service)}</legend>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.category)}
							value={this.props.opd.modalCertificate.selectedCategory.category ? this.props.opd.modalCertificate.selectedCategory.index : undefined}
							onChange={this.onCategory}
						>
							{Utils.renderSelects(this.props.opd.modalCertificate.categories, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.service)}
							status={this.props.opd.modalCertificate.selectedService.service ? undefined : "error"}
							value={this.props.opd.modalCertificate.selectedService.service ? this.props.opd.modalCertificate.selectedService.index : undefined}
							onChange={this.onService}
						>
							{Utils.renderSelects(this.props.opd.modalCertificate.services, false, "", "name")}
						</Select>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSearchText,
			setObjArray,
			setSelected,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateService)
