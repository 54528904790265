import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setContractBillingModalNewInvoices, setSelected } from "~/redux/action"
import { SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ContractBillingModalNewInvoice extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectInvoice = this.onSelectInvoice.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.contract.modalNew.filterInvoiceId, this.props.contract.modalNew.filterInvoiceId) ||
			!_.isEqual(prevProps.contract.modalNew.filterPatientName, this.props.contract.modalNew.filterPatientName) ||
			!_.isEqual(prevProps.contract.modalNew.searchHealthCarePlan, this.props.contract.modalNew.searchHealthCarePlan) ||
			!_.isEqual(
				prevProps.contract.modalNew.searchInsuranceCompany,
				this.props.contract.modalNew.searchInsuranceCompany
			) ||
			!_.isEqual(
				prevProps.contract.modalNew.pendingInvoiceTable.original,
				this.props.contract.modalNew.pendingInvoiceTable.original
			)
		) {
			this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE, -1, null)
			this.props.setContractBillingModalNewInvoices(this.props.contract.modalNew.invoiceTable.original)
		}
	}

	componentWillUnmount() {
		this.props.setContractBillingModalNewInvoices([])
		this.props.setSelected(SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE, -1, null)
	}

	onSelectInvoice(service, index) {
		this.props.setSelected(
			SET_CONTRACT_BILLING_MODAL_NEW_SELECTED_INVOICE,
			index,
			this.props.contract.modalNew.invoiceTable.filtered[index]
		)
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectInvoice}
						data={this.props.contract.modalNew.invoiceTable}
						isClearHighlight={this.props.contract.modalNew.selectedInvoice.index === -1}
						highlightedRow={this.props.contract.modalNew.selectedInvoice.index}
					/>
					{this.props.contract.modalNew.invoiceTable.isLoading && (
						<Spinner
							animation={ResourceAssistance.Spinner.border}
							variant={ResourceAssistance.Button.variant.primary}
						/>
					)}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setContractBillingModalNewInvoices,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingModalNewInvoice))
