import { Card, Descriptions, Divider } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected, setValue, setWarningId, setWarningMsgAction, setPostingGLs } from "~/redux/action"
import {
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_PGL_GL_DATE_TIME,
	SET_PGL_NEW_DISPLAY,
	SET_PGL_SEARCH_DISPLAY,
	SET_PGL_SELECTED_ENTRY,
	SET_PGL_SELECTED_PGL,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class PostingGLLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearch = this.onSearch.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onGeneralLedgerDateTime = this.onGeneralLedgerDateTime.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.postingGL.complete)) {
				this.completePostingGL()
			}
		}
		if (!_.isEqual(prevProps.warning.isClose, this.props.warning.isClose)) {
			if (this.props.warning.isClose) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.postingGL.complete)) {
					this.props.setValue(SET_PGL_GL_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
				}
			}
		}
	}

	completePostingGL() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.postingGL.completePostingGL,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PGL.location.id,
				postingGLId: this.props.PGL.selectedPGL.PGL.id,
				userId: this.props.login.user.id,
				generalLedgers: this.props.PGL.entryTable.body.map((each, idx) => {
					return {
						dateTime: this.props.PGL.generalLedgerDateTime,
						documentCode: this.props.PGL.selectedPGL.PGL.code,
						documentId: this.props.PGL.selectedPGL.PGL.id,
						code: each[0],
						name: each[1],
						category: "",
						transactionDescription: each[2],
						debit: Utils.formatNumberFromStr(each[3]).toFixed(2),
						credit: Utils.formatNumberFromStr(each[4]).toFixed(2),
						workflow: ResourceAssistance.Enum.postingGL,
						systemGenerated: false,
					}
				}),
			},
		}
		let callback = (res) => {
			let postingGLs = this.props.PGL.postingGLTable.original.filter((PGL) => PGL.id !== this.props.PGL.selectedPGL.PGL.id)
			if (res.data.postingGLs.length > 0) {
				postingGLs.push(res.data.postingGLs[0])
			}
			this.props.setPostingGLs(postingGLs)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isCompleteDisabled() {
		return !this.props.PGL.selectedPGL.PGL || !this.props.PGL.selectedPGL.PGL.inspectorComplete || this.props.PGL.selectedPGL.PGL.complete
	}

	isEditDisabled() {
		return !this.props.PGL.selectedPGL.PGL || this.props.PGL.selectedPGL.PGL.inspectorComplete
	}

	onComplete(e, row, rIdx, cIdx) {
		let selectedPGL = this.props.PGL.selectedPGL.PGL
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.postingGL.complete)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.complete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.id)} span={1}>
						{selectedPGL.code + "-" + selectedPGL.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.remark)} span={1}>
						{selectedPGL.remark}
					</Descriptions.Item>
					<Descriptions.Item labelStyle={{ alignItems: "center" }} label={translate(ResourceAssistance.Message.generalLedger)} span={1}>
						<AntDatePicker
							allowClear={false}
							size={"middle"}
							format={"DD-MM-YYYY"}
							defaultValues={[moment(this.props.PGL.generalLedgerDateTime)]}
							onChange={this.onGeneralLedgerDateTime}
						/>
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	onGeneralLedgerDateTime(value) {
		this.props.setValue(SET_PGL_GL_DATE_TIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onSearch() {
		this.props.onModalDisplayAction(SET_PGL_SEARCH_DISPLAY, true)
	}

	onNew() {
		this.props.setSelected(SET_PGL_SELECTED_PGL, -1, null)
		this.props.setSelected(SET_PGL_SELECTED_ENTRY, -1, null)
		this.props.onModalDisplayAction(SET_PGL_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.setSelected(SET_PGL_SELECTED_ENTRY, -1, null)
		this.props.onModalDisplayAction(SET_PGL_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Finance.PostingGL.Approver, this.props.login.user.roles) ? (
					<>
						<Col sm="auto">
							<Button
								variant={ResourceAssistance.Button.variant.green}
								size={ResourceAssistance.Button.size.sm}
								disabled={this.isCompleteDisabled()}
								onClick={this.onComplete}
							>
								<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.complete)}
							</Button>
						</Col>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
					</>
				) : (
					<Col />
				)}
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearch}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={this.isEditDisabled()}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setPostingGLs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PostingGLLeftAction))
