import { Space } from "antd"
import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import CashierModalSearchPatientDate from "./CashierModalSearchPatientDate"
import CashierModalSearchPatientResult from "./CashierModalSearchPatientResult"
import CashierModalSearchPatientSearch from "./CashierModalSearchPatientSearch"

class CashierModalSearchPatientDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				{_.isEmpty(this.props.cashier.modalSearch.patientTable.original) ? (
					<Space direction="vertical" size={12}>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.date)}</legend>
							<CashierModalSearchPatientDate />
						</fieldset>
						<fieldset>
							<legend>{translate(ResourceAssistance.Hospitel.patient)}</legend>
							<CashierModalSearchPatientSearch />
						</fieldset>
					</Space>
				) : (
					<CashierModalSearchPatientResult />
				)}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalSearchPatientDetails)
