import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import OperatingRoomModalIntraAneRecordTable from "./OperatingRoomModalIntraAneRecordTable"
import OperatingRoomModalIntraAneRecordChart from "./OperatingRoomModalIntraAneRecordChart"

class OperatingRoomModalIntraAneRecordDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<OperatingRoomModalIntraAneRecordTable />
				<OperatingRoomModalIntraAneRecordChart />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneRecordDetails)
