import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests } from "~/redux/action"
import { SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import ORModalIntraAneIODetails from "./ORModalIntraAneIODetails"
import _ from "lodash"
import { axios } from "~/axios"

class ORModalIntraAneIO extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_INTRA_ANESTHESIA_INTAKE_OUTPUT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return _.isEmpty(this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.filtered.filter((each) => !each.isExistIntake))
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.saveAnesthesiaIOs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				clinicalRecordId: this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.id,
				userId: this.props.login.user.id,
				ios: this.props.operatingRoom.modalIntraAneIO.pendingIntakeTable.original
					.filter((each) => !each.isExistIntake)
					.map((each) => ({
						intraAnesthesiaRecordId: this.props.operatingRoom.selectedIntraAneRecord.record.id,
						intake: each.displayName,
					}))
					.concat(
						this.props.operatingRoom.modalIntraAneIO.pendingOutputTable.original
							.filter((each) => !each.isExistOutput)
							.map((each) => ({
								intraAnesthesiaRecordId: this.props.operatingRoom.selectedIntraAneRecord.record.id,
								output: each.displayName,
							}))
					),
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.intakeOutput)}</ModalTitle>
				</ModalHeader>
				<ModalBody ref={this.props.modalBodyRef}>
					<ORModalIntraAneIODetails />
				</ModalBody>
				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button variant={ResourceAssistance.Button.variant.green} disabled={this.isDoneBtnDisabled()} onClick={this.onDone}>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomIntraAnesthesiaIntakeOuputDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setOperatingRoomRequests,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(ORModalIntraAneIO)
