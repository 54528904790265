import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcDocument } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_REPORT_PO_FILTER_BY_ID, SET_REPORT_PO_FILTER_BY_LOCATION } from "~/redux/type"
import "./ReportPurchaseOrder.css"

const EReport = {
	REPORT_BY_LOCATION: "REPORT_BY_LOCATION",
	REPORT_BY_ID: "REPORT_BY_ID",
}

class ReportPurchaseOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportPurchaseOrder",
		}

		this.onBtnClick = this.onBtnClick.bind(this)
	}

	onBtnClick(event) {
		switch (event.target.value) {
			case EReport.REPORT_BY_ID:
				this.props.onModalDisplayAction(SET_REPORT_PO_FILTER_BY_ID, true)
				break

			case EReport.REPORT_BY_LOCATION:
				this.props.onModalDisplayAction(SET_REPORT_PO_FILTER_BY_LOCATION, true)
				break

			default:
				break
		}
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Container fluid id={this.state.componentName} className={`border ${ResourceAssistance.CSS.fullFlex}`}>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col sm="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.lg}
										value={EReport.REPORT_BY_LOCATION}
										onClick={this.onBtnClick}
									>
										<FcDocument size={ResourceAssistance.ReactIcon.size_lg} />
										{translate(ResourceAssistance.Message.reportByLocation)}
									</Button>
								</Col>
								<Col sm="auto">
									<Button
										variant={ResourceAssistance.Button.variant.info}
										size={ResourceAssistance.Button.size.lg}
										value={EReport.REPORT_BY_ID}
										onClick={this.onBtnClick}
									>
										<FcDocument size={ResourceAssistance.ReactIcon.size_lg} />
										{translate(ResourceAssistance.Message.reportById)}
									</Button>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportPurchaseOrder)
