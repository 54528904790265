import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmISDisplayAll,
	setAdmISItems,
	setAdmISSelectedItem,
	setAdmISSelectedType,
	setAdmISTypes,
	setSearchText,
} from "~/redux/action"
import { SET_ADMIN_ITEM_SOURCE_SEARCH_NAME, SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ADMItemSource extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidMount() {
		this.props.loadTypes((types) => {
			this.props.setAdmISTypes(types)
		})
		this.props.loadItems((items) => {
			this.props.setAdmISItems(items)
		})
	}

	componentWillUnmount() {
		this.props.setAdmISSelectedType(-1, null)
		this.props.setAdmISSelectedItem(-1, null)
		this.props.setSearchText(SET_ADMIN_ITEM_SOURCE_SEARCH_NAME, "")
		this.props.setAdmISDisplayAll(true)
		this.props.setAdmISItems([])
		this.props.setAdmISTypes([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.itemSource.types, this.props.itemSource.types)) {
			this.props.setAdmISSelectedType(0, this.props.itemSource.types[0])
		}
		if (
			prevProps.itemSource.selectedType.index !== this.props.itemSource.selectedType.index ||
			prevProps.itemSource.searchName !== this.props.itemSource.searchName ||
			prevProps.itemSource.displayInactive !== this.props.itemSource.displayInactive
		) {
			this.props.setAdmISSelectedItem(-1, null)
			this.props.setAdmISItems(this.props.itemSource.itemTable.original)
		}
	}

	onType(value) {
		if (value === undefined) {
			this.props.setAdmISSelectedType(-1, null)
			return
		}
		this.props.setAdmISSelectedType(value, this.props.itemSource.types[value])
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_ITEM_SOURCE_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmISDisplayAll(event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setAdmISSelectedItem(index, this.props.itemSource.itemTable.filtered[index])
	}

	onAdd() {
		this.props.setAdmISSelectedItem(-1, null)
		this.props.onModalDisplayAction(SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_ITEM_SOURCE_NEW_ITEM_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				<Row className="g-1">
					<Col md={2}>
						<Select
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.inventory)}
							value={this.props.itemSource.selectedType.type ? this.props.itemSource.selectedType.index : undefined}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.itemSource.types, false)}
						</Select>
					</Col>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.itemSource.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.itemSource.selectedItem.index === -1} onClick={this.onEdit}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.itemSource.selectedItem.index === -1}
							data={this.props.itemSource.itemTable}
							onClick={this.onSelectRow}
						/>
						{this.props.itemSource.itemTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	itemSource: state.admin.itemConfig.itemSource,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmISDisplayAll,
			setAdmISItems,
			setAdmISSelectedItem,
			setAdmISSelectedType,
			setAdmISTypes,
			setSearchText,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ADMItemSource)
