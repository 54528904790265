import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import IPDModalRegisterHealth from "./IPDModalRegisterHealth"
import IPDModalRegisterInfo from "./IPDModalRegisterInfo"
import RegistrationModalRegisterRelative from "./RegistrationModalRegisterRelative"

export const nameTitles = [
	{ displayName: "น.ส." },
	{ displayName: "นาง" },
	{ displayName: "นาย" },
	{ displayName: "ด.ญ." },
	{ displayName: "ด.ช." },
	{ displayName: "คุณ" },
	{ displayName: "Mr." },
	{ displayName: "Mrs." },
	{ displayName: "Ms." },
]
export const genders = [{ displayName: "ชาย" }, { displayName: "หญิง" }]

class IPDModalRegisterDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<IPDModalRegisterInfo />
				<RegistrationModalRegisterRelative />
				<IPDModalRegisterHealth />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDModalRegisterDetails))
