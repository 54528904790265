import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setContractBillingBillings, setContractBillingInvoices, setLoadingAction, setSelected } from "~/redux/action"
import { SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY, SET_CONTRACT_BILLING_SELECTED_INVOICE, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class ContractBillingRightInvoice extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ContractBillingRightInvoice",
		}
		this.selectRow = this.selectRow.bind(this)
		this.onModify = this.onModify.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.contract.selectedBilling, this.props.contract.selectedBilling)) {
			if (this.props.contract.selectedBilling.billing) {
				this.props.setContractBillingInvoices(this.props.contract.selectedBilling.billing.contractBillingInvoices)
			} else {
				this.props.setContractBillingInvoices([])
			}
		}
		if (!_.isEqual(prevProps.contract.invoiceTable.filtered, this.props.contract.invoiceTable.filtered)) {
			let index = this.props.contract.selectedInvoice.invoice
				? this.props.contract.invoiceTable.filtered.findIndex((invoice) => invoice.id === this.props.contract.selectedInvoice.invoice.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_INVOICE, index, this.props.contract.invoiceTable.filtered[index])
			} else {
				this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_INVOICE, -1, null)
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_CONTRACT_BILLING_SELECTED_INVOICE, index, this.props.contract.invoiceTable.filtered[index])
	}

	onModify(event, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_INVOICE_INFO_DISPLAY, true)
	}

	onDelete(event, row, rIdx, cIdx) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.contractBilling.deleteContractBillingInvoices,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				id: this.props.contract.selectedBilling.billing.id,
				orgId: this.props.contract.location.id,
				invoices: [
					{
						id: this.props.contract.invoiceTable.filtered[rIdx].id,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.contractBillings.length > 0) {
				let billings = this.props.contract.billingTable.original.filter((each) => each.id !== res.data.contractBillings[0].id)
				billings.push(res.data.contractBillings[0])
				this.props.setContractBillingBillings(billings)
			} else {
				let billings = this.props.contract.billingTable.original.filter((each) => each.id !== this.props.contract.selectedBilling.billing.id)
				this.props.setContractBillingBillings(billings)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[
							this.props.contract.selectedBilling.billing && this.props.contract.selectedBilling.billing.complete ? undefined : (
								<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							),
							this.props.contract.selectedBilling.billing && this.props.contract.selectedBilling.billing.complete ? undefined : (
								<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />
							),
						]}
						onCheckedArray={[this.onModify, this.onDelete]}
						data={this.props.contract.invoiceTable}
						onClick={this.selectRow}
						isClearHighlight={this.props.contract.selectedInvoice.index === -1}
						highlightedRow={this.props.contract.selectedInvoice.index}
						extra={
							!_.isEmpty(this.props.contract.invoiceTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col />
									<Col>
										<Descriptions style={{ flex: "unset", display: "unset" }} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalBalance)}>
												{Utils.formatNumWithComma(
													this.props.contract.invoiceTable.filtered
														.reduce((total, cur) => {
															if (cur.debt.total) {
																total = total.plus(cur.debt.total)
															}
															return total
														}, Utils.BigNumber(0))
														.toFixed(2)
												)}
											</Descriptions.Item>
										</Descriptions>
									</Col>
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setContractBillingBillings,
			setContractBillingInvoices,
			setLoadingAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBillingRightInvoice))
