import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SurgicalAuthRightInfo from "./SurgicalAuthRightInfo"

class SurgicalAuthRight extends React.Component {
	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<SurgicalAuthRightInfo />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SurgicalAuthRight)
