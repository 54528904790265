import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons"
import { Checkbox, Divider, message, Popover, Radio, Select, Statistic } from "antd"
import _ from "lodash"
import moment from "moment"
import React, { Fragment } from "react"
import { Col, Container, FormCheck, FormGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import EnumAssistance from "~/enum/EnumAssistance"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import { setObjArray, setSelected, setValue } from "~/redux/action"
import {
	SET_OPD_MODAL_DOCTOR_ORDER_DURATION,
	SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY,
	SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME,
	SET_OPD_MODAL_DOCTOR_ORDER_EXCLUSIVEDAYS,
	SET_OPD_MODAL_DOCTOR_ORDER_NON_DISPENSING_RX_CHECK,
	SET_OPD_MODAL_DOCTOR_ORDER_NOTES,
	SET_OPD_MODAL_DOCTOR_ORDER_ONE_TIME_CHECK,
	SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK,
	SET_OPD_MODAL_DOCTOR_ORDER_RATE_DURATION,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_RATE,
	SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT,
	SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME,
	SET_OPD_MODAL_DOCTOR_ORDER_STAT_CHECK,
	SET_OPD_MODAL_DOCTOR_ORDER_UNITS,
} from "~/redux/type"
import { BigNumberRoundingMode, Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"
import GInput from "~/view/component/input/GInput"

class OutpatientModalDoctorDuration extends React.Component {
	durations = [
		{
			displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.days }),
			shortName: ResourceAssistance.CONSTANT.DAY,
			value: 1440,
		},
		{
			displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.hours }),
			shortName: ResourceAssistance.CONSTANT.HOUR,
			value: 60,
		},
		{
			displayName: this.props.intl.formatMessage({ id: ResourceAssistance.Message.minutes }),
			shortName: ResourceAssistance.CONSTANT.MINUTE,
			value: 1,
		},
	]
	ETime = {
		NONE: ResourceAssistance.CONSTANT.NONE,
		BEFORE_MEAN: this.props.intl.formatMessage({ id: ResourceAssistance.Message.beforeMeal }),
		AFTER_MEAN: this.props.intl.formatMessage({ id: ResourceAssistance.Message.afterMeal }),
		BEFORE_BED: this.props.intl.formatMessage({ id: ResourceAssistance.Message.beforeBed }),
	}
	constructor(props) {
		super(props)
		this.state = {
			time: this.ETime.NONE,
			totalRate: 0,
			totalQty: "",
		}

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onNotes = this.onNotes.bind(this)
		this.onMedicationUsage = this.onMedicationUsage.bind(this)
		this.onQty = this.onQty.bind(this)
		this.onDuration = this.onDuration.bind(this)
		this.onDurationType = this.onDurationType.bind(this)
		this.onUnit = this.onUnit.bind(this)
		this.onPRN = this.onPRN.bind(this)
		this.onOneTime = this.onOneTime.bind(this)
		this.onSTAT = this.onSTAT.bind(this)
		this.onNonDispensingRx = this.onNonDispensingRx.bind(this)
		this.onTime = this.onTime.bind(this)
		this.onRate = this.onRate.bind(this)
		this.onClearRate = this.onClearRate.bind(this)
		this.onRateDuration = this.onRateDuration.bind(this)
		this.onTotalQty = this.onTotalQty.bind(this)
		this.onTotalQtyBlur = this.onTotalQtyBlur.bind(this)
		this.onFrequncyDay = this.onFrequncyDay.bind(this)
	}

	componentDidMount() {
		this.props.setObjArray(
			SET_OPD_MODAL_DOCTOR_ORDER_UNITS,
			this.props.opd.modalDoctorOrder.selectedItem.item.unitConversions.concat([
				{
					amount: 1,
					displayName: this.props.opd.modalDoctorOrder.selectedItem.item.unit.displayName,
					sellingPricePerUnit: this.props.opd.modalDoctorOrder.selectedItem.item.sellPricePerUnit,
					oneTime: true,
				},
			])
		)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME, moment().seconds(0).milliseconds(0).valueOf())
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME, moment().endOf("day").seconds(0).milliseconds(0).valueOf())
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION, 0, this.durations[0])
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalDoctorOrder.selectedItem, this.props.opd.modalDoctorOrder.selectedItem)) {
			this.reset()
			if (this.props.opd.modalDoctorOrder.selectedItem.item) {
				this.props.setObjArray(
					SET_OPD_MODAL_DOCTOR_ORDER_UNITS,
					this.props.opd.modalDoctorOrder.selectedItem.item.unitConversions.concat([
						{
							amount: 1,
							displayName: this.props.opd.modalDoctorOrder.selectedItem.item.unit.displayName,
							sellingPricePerUnit: this.props.opd.modalDoctorOrder.selectedItem.item.sellPricePerUnit,
							oneTime: true,
						},
					])
				)
			}
		}

		if (prevProps.opd.modalDoctorOrder.startDateTime !== this.props.opd.modalDoctorOrder.startDateTime) {
			if (this.props.opd.modalDoctorOrder.isOneTime) {
				this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME, this.props.opd.modalDoctorOrder.startDateTime)
			} else if (this.props.opd.modalDoctorOrder.startDateTime > this.props.opd.modalDoctorOrder.endDateTime) {
				this.props.setValue(
					SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME,
					Utils.generateDateFromLong(this.props.opd.modalDoctorOrder.startDateTime, 0, 0, 0, 23, 59, 59).getTime()
				)
			}
		}

		if (prevProps.opd.modalDoctorOrder.isOneTime !== this.props.opd.modalDoctorOrder.isOneTime) {
			if (this.props.opd.modalDoctorOrder.isOneTime) {
				this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME, this.props.opd.modalDoctorOrder.startDateTime)
				this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_DURATION, "")
			} else {
				this.props.setValue(
					SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME,
					moment(this.props.opd.modalDoctorOrder.startDateTime).endOf("day").seconds(0).milliseconds(0).valueOf()
				)
			}
		}

		if (
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedItem, this.props.opd.modalDoctorOrder.selectedItem) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.startDateTime, this.props.opd.modalDoctorOrder.startDateTime) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.endDateTime, this.props.opd.modalDoctorOrder.endDateTime)
		) {
			if (this.isExistOrder()) {
				message.destroy()
				message.warning(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.orderTimeInteractWithAnotherOrder }), 10)
			} else {
				message.destroy()
			}
		}

		if (
			!_.isEqual(prevProps.opd.modalDoctorOrder.startDateTime, this.props.opd.modalDoctorOrder.startDateTime) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.endDateTime, this.props.opd.modalDoctorOrder.endDateTime) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.durationQty, this.props.opd.modalDoctorOrder.durationQty) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedUnit, this.props.opd.modalDoctorOrder.selectedUnit) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedRate, this.props.opd.modalDoctorOrder.selectedRate) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.rateDuration, this.props.opd.modalDoctorOrder.rateDuration) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.duration, this.props.opd.modalDoctorOrder.duration) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedDuration, this.props.opd.modalDoctorOrder.selectedDuration) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.isPRN, this.props.opd.modalDoctorOrder.isPRN) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.isOneTime, this.props.opd.modalDoctorOrder.isOneTime) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.isSTAT, this.props.opd.modalDoctorOrder.isSTAT) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.exclusiveDays, this.props.opd.modalDoctorOrder.exclusiveDays) ||
			!_.isEqual(prevState.time, this.state.time)
		) {
			if (
				this.props.opd.modalDoctorOrder.durationQty &&
				this.props.opd.modalDoctorOrder.selectedUnit.unit &&
				this.props.opd.modalDoctorOrder.selectedRate.rate &&
				this.props.opd.modalDoctorOrder.rateDuration
			) {
				this.setState({
					totalRate: Utils.BigNumber(this.props.opd.modalDoctorOrder.durationQty).times(this.props.opd.modalDoctorOrder.rateDuration).toNumber(),
				})
			} else {
				this.setState({
					totalRate: 0,
				})
			}

			let usage = Utils.trim(this.props.opd.modalDoctorOrder.notes)
			//Previous Notes
			if (
				prevProps.opd.modalDoctorOrder.selectedItem.item &&
				prevProps.opd.modalDoctorOrder.selectedDuration.duration &&
				prevProps.opd.modalDoctorOrder.selectedUnit.unit
			) {
				let prevDuration = prevProps.opd.modalDoctorOrder.durationQty + " " + prevProps.opd.modalDoctorOrder.selectedUnit.unit.displayName
				if (prevProps.opd.modalDoctorOrder.selectedRate.rate && prevProps.opd.modalDoctorOrder.rateDuration) {
					prevDuration =
						prevDuration +
						"/" +
						prevProps.opd.modalDoctorOrder.selectedRate.rate.shortName +
						" " +
						this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastFor }) +
						" " +
						prevProps.opd.modalDoctorOrder.rateDuration +
						" " +
						prevProps.opd.modalDoctorOrder.selectedRate.rate.displayName
				}
				if (!prevProps.opd.modalDoctorOrder.isOneTime) {
					prevDuration =
						prevDuration +
						" " +
						this.props.intl.formatMessage({ id: ResourceAssistance.Message.every }) +
						" " +
						prevProps.opd.modalDoctorOrder.duration +
						" " +
						prevProps.opd.modalDoctorOrder.selectedDuration.duration.displayName
				}
				if (prevState.time !== ResourceAssistance.CONSTANT.NONE) {
					prevDuration = prevDuration + " (" + prevState.time + ")"
				}
				if (prevProps.opd.modalDoctorOrder.isPRN) {
					prevDuration = prevDuration.concat(" ", ResourceAssistance.CONSTANT.PRN)
				}
				if (prevProps.opd.modalDoctorOrder.isSTAT) {
					prevDuration = prevDuration.concat(", ", ResourceAssistance.CONSTANT.STAT)
				}
				if (!_.isEmpty(prevProps.opd.modalDoctorOrder.exclusiveDays)) {
					let keys = Object.keys(EnumAssistance.Moment.Week)
					Object.values(EnumAssistance.Moment.Week)
						.filter((each) => !prevProps.opd.modalDoctorOrder.exclusiveDays.includes(each))
						.forEach((each) => {
							prevDuration = prevDuration.concat(", ", this.props.intl.formatMessage({ id: keys[each].toLowerCase() }))
						})
				}
				usage = Utils.trim(usage.replace(prevDuration, ""))
			}

			//New Notes
			if (
				this.props.opd.modalDoctorOrder.durationQty &&
				this.props.opd.modalDoctorOrder.selectedUnit.unit &&
				(this.props.opd.modalDoctorOrder.duration || this.props.opd.modalDoctorOrder.isOneTime) &&
				this.props.opd.modalDoctorOrder.selectedDuration.duration &&
				!this.props.opd.modalDoctorOrder.selectedRate.rate
			) {
				let duration = this.props.opd.modalDoctorOrder.durationQty + " " + this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName
				if (!this.props.opd.modalDoctorOrder.isOneTime) {
					duration =
						duration +
						" " +
						this.props.intl.formatMessage({ id: ResourceAssistance.Message.every }) +
						" " +
						this.props.opd.modalDoctorOrder.duration +
						" " +
						this.props.opd.modalDoctorOrder.selectedDuration.duration.displayName
				}
				if (this.state.time !== ResourceAssistance.CONSTANT.NONE) {
					duration = duration + " (" + this.state.time + ")"
				}
				if (this.props.opd.modalDoctorOrder.isPRN) {
					duration = duration.concat(" ", ResourceAssistance.CONSTANT.PRN)
				}
				if (this.props.opd.modalDoctorOrder.isSTAT) {
					duration = duration.concat(", ", ResourceAssistance.CONSTANT.STAT)
				}
				if (!_.isEmpty(this.props.opd.modalDoctorOrder.exclusiveDays)) {
					let keys = Object.keys(EnumAssistance.Moment.Week)
					Object.values(EnumAssistance.Moment.Week)
						.filter((each) => !this.props.opd.modalDoctorOrder.exclusiveDays.includes(each))
						.forEach((each) => {
							duration = duration.concat(", ", this.props.intl.formatMessage({ id: keys[each].toLowerCase() }))
						})
				}
				usage = Utils.trim(duration.concat(" ", usage))

				this.setState({
					totalQty: this.props.opd.modalDoctorOrder.isOneTime
						? Utils.calculateDoctorOrderQtyByRate(
								this.props.opd.modalDoctorOrder.durationQty,
								this.props.opd.modalDoctorOrder.rateDuration,
								this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
								this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
						  ).dp(0, BigNumberRoundingMode.ROUND_UP)
						: Utils.calculateDoctorOrderQty(
								{
									...this.props.opd.modalDoctorOrder.selectedItem.item,
									startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
									endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
									duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
										.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
										.toNumber(),
									durationQty: Utils.calculateDoctorOrderQtyByRate(
										this.props.opd.modalDoctorOrder.durationQty,
										this.props.opd.modalDoctorOrder.rateDuration,
										this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
										this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
									).toNumber(),
									exclusiveDays: this.props.opd.modalDoctorOrder.exclusiveDays,
								},
								this.props.opd.modalDoctorOrder.startDateTime,
								this.props.opd.modalDoctorOrder.endDateTime
						  ),
				})
			} else if (
				this.props.opd.modalDoctorOrder.durationQty &&
				this.props.opd.modalDoctorOrder.selectedUnit.unit &&
				(this.props.opd.modalDoctorOrder.duration || this.props.opd.modalDoctorOrder.isOneTime) &&
				this.props.opd.modalDoctorOrder.selectedDuration.duration &&
				this.props.opd.modalDoctorOrder.selectedRate.rate &&
				!_.isEmpty(this.props.opd.modalDoctorOrder.rateDuration)
			) {
				let duration =
					this.props.opd.modalDoctorOrder.durationQty +
					" " +
					this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName +
					"/" +
					this.props.opd.modalDoctorOrder.selectedRate.rate.shortName +
					" " +
					this.props.intl.formatMessage({ id: ResourceAssistance.Message.lastFor }) +
					" " +
					this.props.opd.modalDoctorOrder.rateDuration +
					" " +
					this.props.opd.modalDoctorOrder.selectedRate.rate.displayName
				if (!this.props.opd.modalDoctorOrder.isOneTime) {
					duration =
						duration +
						" " +
						this.props.intl.formatMessage({ id: ResourceAssistance.Message.every }) +
						" " +
						this.props.opd.modalDoctorOrder.duration +
						" " +
						this.props.opd.modalDoctorOrder.selectedDuration.duration.displayName
				}
				if (this.state.time !== ResourceAssistance.CONSTANT.NONE) {
					duration = duration + " (" + this.state.time + ")"
				}
				if (this.props.opd.modalDoctorOrder.isPRN) {
					duration = duration.concat(" ", ResourceAssistance.CONSTANT.PRN)
				}
				if (this.props.opd.modalDoctorOrder.isSTAT) {
					duration = duration.concat(", ", ResourceAssistance.CONSTANT.STAT)
				}
				if (!_.isEmpty(this.props.opd.modalDoctorOrder.exclusiveDays)) {
					let keys = Object.keys(EnumAssistance.Moment.Week)
					Object.values(EnumAssistance.Moment.Week)
						.filter((each) => !this.props.opd.modalDoctorOrder.exclusiveDays.includes(each))
						.forEach((each) => {
							duration = duration.concat(", ", this.props.intl.formatMessage({ id: keys[each].toLowerCase() }))
						})
				}
				usage = Utils.trim(duration.concat(" ", usage))
				this.setState({
					totalQty: this.props.opd.modalDoctorOrder.isOneTime
						? Utils.calculateDoctorOrderQtyByRate(
								this.props.opd.modalDoctorOrder.durationQty,
								this.props.opd.modalDoctorOrder.rateDuration,
								this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
								// true
								this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
						  ).toNumber()
						: Utils.calculateDoctorOrderQty(
								{
									...this.props.opd.modalDoctorOrder.selectedItem.item,
									startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
									endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
									duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
										.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
										.toNumber(),
									durationQty: Utils.calculateDoctorOrderQtyByRate(
										this.props.opd.modalDoctorOrder.durationQty,
										this.props.opd.modalDoctorOrder.rateDuration,
										this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
										this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
									).toNumber(),
									exclusiveDays: this.props.opd.modalDoctorOrder.exclusiveDays,
								},
								this.props.opd.modalDoctorOrder.startDateTime,
								this.props.opd.modalDoctorOrder.endDateTime
						  ),
				})
			} else {
				this.setState({
					totalQty: "",
				})
			}
			this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_NOTES, usage)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.setState({
			time: this.ETime.NONE,
			totalRate: 0,
			totalQty: "",
		})
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME, moment().seconds(0).milliseconds(0).valueOf())
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME, moment().endOf("day").seconds(0).milliseconds(0).valueOf())
		this.props.setObjArray(SET_OPD_MODAL_DOCTOR_ORDER_UNITS, [])
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_EXCLUSIVEDAYS, [])
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_NOTES, "")
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK, false)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_ONE_TIME_CHECK, false)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_STAT_CHECK, false)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_NON_DISPENSING_RX_CHECK, false)
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY, "")
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_DURATION, "")
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_RATE_DURATION, "")
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION, 0, this.durations[0])
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT, -1, null)
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_RATE, -1, null)
		message.destroy()
	}

	isExistOrder() {
		if (!this.props.opd.modalDoctorOrder.selectedItem.item) {
			return false
		}

		let pendingOrders = Array.from(this.props.opd.modalDoctorOrder.pendingItemTable.original)
		let orders = Array.from(this.props.opd.doctorOrderTable.original)

		return (
			pendingOrders.find(
				(each) =>
					each.displayName.includes(this.props.opd.modalDoctorOrder.selectedItem.item.displayName) &&
					Utils.isTimeIntersect(
						each.startDateTime,
						each.endDateTime,
						this.props.opd.modalDoctorOrder.startDateTime,
						this.props.opd.modalDoctorOrder.endDateTime
					)
			) ||
			orders.find(
				(each) =>
					each.description.includes(this.props.opd.modalDoctorOrder.selectedItem.item.displayName) &&
					Utils.isTimeIntersect(
						each.startDateTime,
						each.endDateTime,
						this.props.opd.modalDoctorOrder.startDateTime,
						this.props.opd.modalDoctorOrder.endDateTime
					)
			)
		)
	}

	isTotalQtyDisabled() {
		return (
			_.isEmpty(this.props.opd.modalDoctorOrder.durationQty) ||
			!this.props.opd.modalDoctorOrder.selectedUnit.unit ||
			this.props.opd.modalDoctorOrder.isOneTime ||
			_.isEmpty(this.props.opd.modalDoctorOrder.duration) ||
			this.props.opd.modalDoctorOrder.selectedRate.rate !== null ||
			this.props.opd.modalDoctorOrder.selectedUnit.unit.amount > 1
		)
	}

	onStartDate(date) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME, date.getTime())
	}

	onPRN(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_PRN_CHECK, event.target.checked)
	}

	onOneTime(e) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_ONE_TIME_CHECK, e.target.checked)
	}

	onSTAT(e) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_STAT_CHECK, e.target.checked)
	}

	onNonDispensingRx(e) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_NON_DISPENSING_RX_CHECK, e.target.checked)
	}

	onQty(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_DURATION_QTY, event.target.value)
	}

	onUnit(value) {
		value > -1
			? this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT, value, this.props.opd.modalDoctorOrder.units[value])
			: this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_UNIT, -1, null)
	}

	onRate(value) {
		value > -1
			? this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_RATE, value, this.durations[value])
			: this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_RATE, -1, null)
	}

	onClearRate() {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_RATE_DURATION, "")
		this.setState({
			totalRate: 0,
		})
	}

	onRateDuration(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_RATE_DURATION, event.target.value)
	}

	onDuration(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_DURATION, event.target.value)
	}

	onDurationType(value) {
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_DURATION, value, this.durations[value])
	}

	onTotalQty(event) {
		this.setState({
			totalQty: event.target.value,
		})
	}

	onTotalQtyBlur() {
		if (this.state.totalQty && Utils.BigNumber(this.state.totalQty).dividedBy(this.props.opd.modalDoctorOrder.durationQty).isInteger()) {
			let endDateTime =
				this.props.opd.modalDoctorOrder.startDateTime +
				Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
					.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
					.times(60000)
					.times(Utils.BigNumber(this.state.totalQty).dividedBy(this.props.opd.modalDoctorOrder.durationQty).minus(1))
					.toNumber()
			this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_END_DATETIME, moment(endDateTime).seconds(0).milliseconds(0).valueOf())
		} else {
			this.setState({
				totalQty: this.props.opd.modalDoctorOrder.isOneTime
					? Utils.calculateDoctorOrderQtyByRate(
							this.props.opd.modalDoctorOrder.durationQty,
							this.props.opd.modalDoctorOrder.rateDuration,
							this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
							this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
					  ).dp(0, BigNumberRoundingMode.ROUND_UP)
					: Utils.calculateDoctorOrderQty(
							{
								...this.props.opd.modalDoctorOrder.selectedItem.item,
								startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
								endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
								duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
									.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
									.toNumber(),
								durationQty: Utils.BigNumber(this.props.opd.modalDoctorOrder.durationQty).toNumber(),
								exclusiveDays: this.props.opd.modalDoctorOrder.exclusiveDays,
							},
							this.props.opd.modalDoctorOrder.startDateTime,
							this.props.opd.modalDoctorOrder.endDateTime
					  ),
			})
		}
	}

	onFrequncyDay(values) {
		let exclusiveDays = Object.values(EnumAssistance.Moment.Week).filter((each) => !values.includes(each))
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_EXCLUSIVEDAYS, exclusiveDays)
	}

	onTime(e) {
		this.setState({
			time: e.target.value,
		})
	}

	onNotes(event) {
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_NOTES, event.target.value)
	}

	onMedicationUsage(event) {
		let usage = this.props.opd.modalDoctorOrder.notes.replace(/, +/g, "")
		if (event.target.checked) {
			usage = Utils.trim(usage).replace(/, +/g, "")
			usage = usage.concat(_.isEmpty(usage) ? "" : ", ", event.target.value)
		} else {
			usage = usage.replace(event.target.value, "")
		}
		this.props.setValue(SET_OPD_MODAL_DOCTOR_ORDER_NOTES, usage)
	}

	render() {
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.date)}</legend>
							<Row className={"g-3"}>
								<Col />
								<Col md="auto">
									<DatePicker
										minDate={Utils.generateDate()}
										showDisabledMonthNavigation
										shouldCloseOnSelect={false}
										disabled={this.props.opd.modalDoctorOrder.selectedItem.index === -1}
										startDate={Utils.generateDateFromLong(this.props.opd.modalDoctorOrder.startDateTime)}
										onChange={this.onStartDate}
									/>
								</Col>
								<Col md="auto">
									<label style={{ textAlign: "center" }}>{translate(ResourceAssistance.Message.to)}</label>
								</Col>
								<Col md="auto">
									<DatePicker
										minDate={Utils.generateDateFromLong(this.props.opd.modalDoctorOrder.startDateTime)}
										showDisabledMonthNavigation
										disabled={this.props.opd.modalDoctorOrder.selectedItem.index === -1}
										startDate={Utils.generateDateFromLong(this.props.opd.modalDoctorOrder.endDateTime)}
										onChange={this.onEndDate}
										isShowDateRange
										shouldCloseOnSelect={false}
										rangeStartDate={Utils.generateDateFromLong(this.props.opd.modalDoctorOrder.startDateTime)}
									/>
								</Col>
								<Col />
							</Row>
						</fieldset>
					</Col>
				</Row>

				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.doctorPrescription)}</legend>
							<Row>
								<Col md="auto">
									<FormGroup controlId={"prn"}>
										<FormCheck label={ResourceAssistance.CONSTANT.PRN} checked={this.props.opd.modalDoctorOrder.isPRN} onChange={this.onPRN} />
									</FormGroup>
								</Col>
								<Col md="auto">
									<FormGroup controlId={"one-time"}>
										<FormCheck label={ResourceAssistance.CONSTANT.ONE_TIME} checked={this.props.opd.modalDoctorOrder.isOneTime} onChange={this.onOneTime} />
									</FormGroup>
								</Col>
								<Col md="auto">
									<FormGroup controlId={"stat"}>
										<FormCheck
											label={ResourceAssistance.CONSTANT.STAT}
											disabled={this.props.opd.modalDoctorOrder.isNonDispensingRx}
											checked={this.props.opd.modalDoctorOrder.isSTAT}
											onChange={this.onSTAT}
										/>
									</FormGroup>
								</Col>
								<Col md="auto">
									<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
								</Col>
								<Col md="auto">
									<FormGroup controlId={"non-dispensing-rx"}>
										<FormCheck
											label={translate(ResourceAssistance.Message.nonDispensingRx)}
											disabled={this.props.opd.modalDoctorOrder.isSTAT}
											checked={this.props.opd.modalDoctorOrder.isNonDispensingRx}
											onChange={this.onNonDispensingRx}
										/>
									</FormGroup>
								</Col>
							</Row>

							<Row className={"g-0"}>
								<Col>
									<GInput
										required
										style={{ textAlign: "center" }}
										type={ResourceAssistance.FormControl.type.number}
										placeholder={translate(ResourceAssistance.Message.amount)}
										min={0}
										step={0.01}
										value={this.props.opd.modalDoctorOrder.durationQty}
										onChange={this.onQty}
										addonAfter={
											<Fragment>
												<Select
													showSearch
													style={{ textAlign: "center", width: "100px", margin: 0 }}
													optionFilterProp="children"
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
													value={this.props.opd.modalDoctorOrder.selectedUnit.unit ? this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName : undefined}
													onChange={this.onUnit}
												>
													{Utils.renderSelects(this.props.opd.modalDoctorOrder.units, false)}
												</Select>
												<span style={{ verticalAlign: "middle" }}>
													<IconFont type={"icon-ali-pmgcare-chuhao"} style={{ fontSize: ResourceAssistance.ReactIcon.size }} />
												</span>
												<Select
													allowClear
													showSearch
													style={{ textAlign: "center", width: "100px", margin: 0 }}
													optionFilterProp="children"
													filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
													filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
													value={this.props.opd.modalDoctorOrder.selectedRate.rate ? this.props.opd.modalDoctorOrder.selectedRate.rate.displayName : undefined}
													onChange={this.onRate}
													onClear={this.onClearRate}
												>
													{Utils.renderSelects(this.durations, false)}
												</Select>
											</Fragment>
										}
									/>
								</Col>
							</Row>

							{this.props.opd.modalDoctorOrder.selectedRate.rate && (
								<Row className={"g-0"}>
									<Col style={{ marginRight: "0.3rem" }}>
										<GInput
											required
											style={{ textAlign: "center" }}
											type={ResourceAssistance.FormControl.type.number}
											placeholder={translate(ResourceAssistance.Message.duration)}
											min={1}
											value={this.props.opd.modalDoctorOrder.rateDuration}
											onChange={this.onRateDuration}
											addonBefore={translate(ResourceAssistance.Message.lastFor)}
											addonAfter={this.props.opd.modalDoctorOrder.selectedRate.rate.displayName}
										/>
									</Col>
									<Col md="auto">
										<Statistic
											style={{ textAlign: "center" }}
											title={translate(ResourceAssistance.Message.total)}
											value={this.state.totalRate}
											suffix={this.props.opd.modalDoctorOrder.selectedUnit.unit ? this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName : undefined}
										/>
									</Col>
								</Row>
							)}

							<Row className={"g-0"}>
								<Col style={{ marginRight: "0.3rem" }}>
									<GInput
										required
										style={{ textAlign: "center" }}
										type={ResourceAssistance.FormControl.type.number}
										placeholder={translate(ResourceAssistance.Message.frequency)}
										min={1}
										disabled={this.props.opd.modalDoctorOrder.isOneTime}
										value={this.props.opd.modalDoctorOrder.duration}
										onChange={this.onDuration}
										addonBefore={translate(ResourceAssistance.Message.every)}
										addonAfter={
											<Select
												showSearch
												style={{ flexGrow: 1, textAlign: "center", width: "95px" }}
												optionFilterProp="children"
												filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
												disabled={this.props.opd.modalDoctorOrder.isOneTime}
												value={
													this.props.opd.modalDoctorOrder.selectedDuration.duration
														? this.props.opd.modalDoctorOrder.selectedDuration.duration.displayName
														: undefined
												}
												onChange={this.onDurationType}
											>
												{Utils.renderSelects(this.durations, false)}
											</Select>
										}
									/>
								</Col>
								<Col md={4}>
									<GInput
										required
										style={{ textAlign: "center" }}
										type={ResourceAssistance.FormControl.type.number}
										placeholder={translate(ResourceAssistance.Message.total)}
										min={1}
										disabled={this.isTotalQtyDisabled()}
										value={this.state.totalQty}
										onChange={this.onTotalQty}
										onBlur={this.onTotalQtyBlur}
										addonAfter={
											this.props.opd.modalDoctorOrder.selectedItem.item
												? this.props.opd.modalDoctorOrder.selectedUnit.unit && !this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
													? this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName
													: this.props.opd.modalDoctorOrder.selectedItem.item.unit.displayName
												: undefined
										}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Checkbox.Group
										disabled={this.props.opd.modalDoctorOrder.isOneTime}
										value={Object.values(EnumAssistance.Moment.Week).filter((each) => !this.props.opd.modalDoctorOrder.exclusiveDays.includes(each))}
										onChange={this.onFrequncyDay}
									>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.MONDAY}>
											{translate(ResourceAssistance.Message.monday)}
										</Checkbox>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.TUESDAY}>
											{translate(ResourceAssistance.Message.tuesday)}
										</Checkbox>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.WEDNESDAY}>
											{translate(ResourceAssistance.Message.wednesday)}
										</Checkbox>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.THURSDAY}>
											{translate(ResourceAssistance.Message.thursday)}
										</Checkbox>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.FRIDAY}>
											{translate(ResourceAssistance.Message.friday)}
										</Checkbox>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.SATURDAY}>
											{translate(ResourceAssistance.Message.saturday)}
										</Checkbox>
										<Checkbox style={{ flexGrow: 1 }} value={EnumAssistance.Moment.Week.SUNDAY}>
											{translate(ResourceAssistance.Message.sunday)}
										</Checkbox>
									</Checkbox.Group>
								</Col>
							</Row>

							<Row>
								<Col>
									<Radio.Group value={this.state.time} onChange={this.onTime}>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.NONE}>
											{this.ETime.NONE}
										</Radio>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.BEFORE_MEAN}>
											{this.ETime.BEFORE_MEAN}
										</Radio>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.AFTER_MEAN}>
											{this.ETime.AFTER_MEAN}
										</Radio>
										<Radio style={{ flexGrow: 1 }} value={this.ETime.BEFORE_BED}>
											{this.ETime.BEFORE_BED}
										</Radio>
									</Radio.Group>
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>

				<Row>
					<Col>
						<fieldset>
							<legend>{translate(ResourceAssistance.Message.medicationUsage)}</legend>
							{this.props.opd.modalDoctorOrder.selectedItem.item && (
								<Row className={"g-0 label-info-row"}>
									{this.props.opd.modalDoctorOrder.selectedItem.item.medicationUsage && (
										<Col md="auto">
											<Popover content={this.props.opd.modalDoctorOrder.selectedItem.item.medicationUsage} trigger="hover">
												<QuestionCircleOutlined
													style={{
														fontSize: ResourceAssistance.ReactIcon.size,
														alignSelf: "flex-start",
														color: "red",
													}}
												/>
											</Popover>
										</Col>
									)}
									{this.props.opd.modalDoctorOrder.selectedItem.item.item.warnings && (
										<Col md="auto">
											<Popover content={this.props.opd.modalDoctorOrder.selectedItem.item.item.warnings} trigger="hover">
												<WarningOutlined
													style={{
														fontSize: ResourceAssistance.ReactIcon.size,
														alignSelf: "flex-start",
														color: ResourceAssistance.CSS.Color.dark_warning,
													}}
												/>
											</Popover>
										</Col>
									)}
								</Row>
							)}

							<Row>
								<Col>
									<GInput
										autoSize
										required
										minRows={3}
										placeholder={" "}
										disabled={this.props.opd.modalDoctorOrder.selectedItem.index === -1}
										value={this.props.opd.modalDoctorOrder.notes}
										onChange={this.onNotes}
									/>
								</Col>
							</Row>
						</fieldset>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setObjArray,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctorDuration))
