import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class OperatingRoomRightPatientInfo extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions
						style={{ flex: "1", display: "unset", borderBottomStyle: "solid", borderBottomWidth: "1px" }}
						size={"default"}
						contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
						column={6}
					>
						<Descriptions.Item label={translate(ResourceAssistance.Message.patientId)} span={1}>
							{this.props.operatingRoom.selectedRequest.request ? this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.id : ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
							{this.props.operatingRoom.selectedRequest.request
								? this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.displayName
								: ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.dateOfBirth)} span={1}>
							{this.props.operatingRoom.selectedRequest.request
								? Utils.formatDate(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.dobtime)
								: ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.age)} span={1}>
							{this.props.operatingRoom.selectedRequest.request
								? Utils.calculateAge(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.dobtime)
								: ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.gender)} span={1}>
							{this.props.operatingRoom.selectedRequest.request ? this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.gender : ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Hospitel.healthCarePlan)} span={1}>
							{this.props.operatingRoom.selectedRequest.request
								? _.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.healthcareOther)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.healthcareName
									: this.props.operatingRoom.selectedRequest.request.clinicalRecord.healthcareOther
								: ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.weight)} span={1}>
							{this.props.operatingRoom.selectedRequest.request ? this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.weight : ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.height)} span={1}>
							{this.props.operatingRoom.selectedRequest.request ? this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.height : ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.BMI)} span={1}>
							{this.props.operatingRoom.selectedRequest.request
								? Utils.BigNumber(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.weight)
										.dividedBy(
											Utils.BigNumber(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.height / 100).multipliedBy(
												this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.height / 100
											)
										)
										.toFixed(2)
								: ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.allergies)} span={3}>
							{this.props.operatingRoom.selectedRequest.request
								? this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.allergies
								: ""}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.lastLocation)} span={6}>
							{this.props.operatingRoom.selectedRequest.request
								? _.isEmpty(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.ipds)
									? this.props.operatingRoom.selectedRequest.request.clinicalRecord.medicalRecords[0].location.displayName
									: `${this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.ipds[0].location.displayName}/${this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.ipds[0].room}`
								: ""}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomRightPatientInfo))
