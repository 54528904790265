import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_ITEM_DISPLAY_ALL_IND,
	SET_ADMIN_ITEM_ITEMS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ACTIVE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_BONUS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_BRAND,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_DISCOUNT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_DOSE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FORMS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ITEMS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_PRICE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_QTY_PER_ORDER,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_FORM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_ITEM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_SUPPLIER,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TAX,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TYPE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_UNIT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SUPPLIERS,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_TAXES,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_TYPES,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_UNITS,
	SET_ADMIN_ITEM_SELECTED_ITEM,
	SET_ADMIN_ITEM_SELECTED_TYPE,
	SET_ADMIN_ITEM_TYPES,
	SET_ADM_ITEM_UNIT_CONVERSIONS,
} from "../../type/type/ADMIN"

export const setAdmIItems = (items) => {
	return (dispatch, getState) => {
		let itemRelps = items.reduce((obj, cur) => {
			return Array.prototype.concat.apply(
				obj,
				cur.itemSupplierRelps.map((each) => {
					return {
						...each,
						type: cur.type,
						item: {
							id: cur.id,
							displayName: cur.displayName,
						},
					}
				})
			)
		}, [])

		let filtered = itemRelps.filter((each) => {
			if (getState().admin.itemConfig.item.displayInactive) {
				return (
					each.displayName.toLowerCase().includes(getState().admin.itemConfig.item.searchName.toLowerCase()) &&
					each.code.toLowerCase().includes(getState().admin.itemConfig.item.searchCode.trim().toLowerCase())
				)
			} else {
				return (
					each.active &&
					each.displayName.toLowerCase().includes(getState().admin.itemConfig.item.searchName.toLowerCase()) &&
					each.code.toLowerCase().includes(getState().admin.itemConfig.item.searchCode.trim().toLowerCase())
				)
			}
		})

		if (getState().admin.itemConfig.item.selectedType.type) {
			filtered = filtered.filter((each) => {
				return each.type.id === getState().admin.itemConfig.item.selectedType.type.id
			})
		}

		filtered.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let colors = []
		if (getState().admin.itemConfig.item.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [
				each.code,
				each.displayName,
				each.unit.displayName,
				each.medicationUsage,
				each.brand,
				each.supplier.displayName,
				each.healthcarePlan,
				Utils.formatNumWithComma(each.minQtyPerOrder),
				Utils.formatNumWithComma(each.bonus),
				Utils.formatNumWithComma(Utils.BigNumber(each.pricePerUnit).toFixed(4)),
				each.discount,
				each.tax.taxRate,
				Utils.formatNumWithComma(Utils.BigNumber(each.sellPricePerUnit).toFixed(4)),
				each.fsCode,
				each.categoryNum,
				each.createdBy.displayName,
				[true],
			]
		})

		let colStyle = filtered.map((each) => {
			return [
				[true, { textAlign: "left" }],
				[true, { textAlign: "left" }],
				[false, ""],
				[true, { textAlign: "left" }],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right" }],
				[false, ""],
				[false, ""],
				[true, { textAlign: "right" }],
				[false, ""],
				[false, ""],
				[false, ""],
			]
		})

		dispatch({
			type: SET_ADMIN_ITEM_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
				rowColor: colors,
				colStyle: colStyle,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmItemUnitConversions = (unitConversions) => {
	return (dispatch, getState) => {
		let filtered = unitConversions.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [
				Utils.formatNumWithComma(each.amount),
				each.displayName,
				each.description,
				each.oneTime ? "\u2714" : "",
				each.oneTime ? "" : Utils.formatNumWithComma(Utils.BigNumber(each.sellingPricePerUnit).toFixed(2)),
				each.lastModifiedBy.displayName,
				[true, true],
			]
		})

		dispatch({
			type: SET_ADM_ITEM_UNIT_CONVERSIONS,
			payload: {
				original: unitConversions,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmITypes = (types) => {
	return {
		type: SET_ADMIN_ITEM_TYPES,
		payload: {
			types: types,
		},
	}
}

export const setAdmISelectedType = (index, type) => {
	return {
		type: SET_ADMIN_ITEM_SELECTED_TYPE,
		payload: {
			index: index,
			type: type,
		},
	}
}

export const setAdmIDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_ITEM_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmISelectedItem = (index, item) => {
	return {
		type: SET_ADMIN_ITEM_SELECTED_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setAdmIModalNITypes = (types) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_TYPES,
		payload: {
			types: types,
		},
	}
}

export const setAdmIModalNIItems = (items) => {
	return (dispatch, getState) => {
		let filtered = items.filter((each) => each.displayName.toLowerCase().includes(getState().admin.itemConfig.item.modalNewItem.searchName.toLowerCase()))

		if (getState().admin.itemConfig.item.modalNewItem.selectedType.type) {
			filtered = filtered.filter((each) => {
				return each.type.id === getState().admin.itemConfig.item.modalNewItem.selectedType.type.id
			})
		}

		filtered.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName]
		})

		dispatch({
			type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_ITEMS,
			payload: {
				original: items,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmIModalNIForms = (forms) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_FORMS,
		payload: {
			forms: forms,
		},
	}
}

export const setAdmIModalNIUnits = (units) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_UNITS,
		payload: {
			units: units,
		},
	}
}

export const setAdmIModalNISuppliers = (suppliers) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SUPPLIERS,
		payload: {
			suppliers: suppliers,
		},
	}
}

export const setAdmIModalNISelectedType = (index, type) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TYPE,
		payload: {
			index: index,
			type: type,
		},
	}
}

export const setAdmIModalNISelectedItem = (index, item) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_ITEM,
		payload: {
			index: index,
			item: item,
		},
	}
}

export const setAdmIModalNISelectedForm = (index, form) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_FORM,
		payload: {
			index: index,
			form: form,
		},
	}
}

export const setAdmIModalNISelectedUnit = (index, unit) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_UNIT,
		payload: {
			index: index,
			unit: unit,
		},
	}
}

export const setAdmIModalNISelectedSupplier = (index, supplier) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_SUPPLIER,
		payload: {
			index: index,
			supplier: supplier,
		},
	}
}

export const setAdmIModalNITaxes = (taxes) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_TAXES,
		payload: {
			taxes: taxes,
		},
	}
}

export const setAdmIModalNISelectedTax = (index, tax) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELECTED_TAX,
		payload: {
			index: index,
			tax: tax,
		},
	}
}

export const setAdmIModalNICode = (code) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_CODE,
		payload: {
			code: code,
		},
	}
}

export const setAdmIModalNIDose = (dose) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_DOSE,
		payload: {
			dose: dose,
		},
	}
}

export const setAdmIModalNIBrand = (brand) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_BRAND,
		payload: {
			brand: brand,
		},
	}
}

export const setAdmIModalNIPricePerUnit = (price) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_PRICE,
		payload: {
			price: price,
		},
	}
}

export const setAdmIModalNIDiscount = (discount) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_DISCOUNT,
		payload: {
			discount: discount,
		},
	}
}

export const setAdmIModalNIQtyPerOrder = (qty) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_QTY_PER_ORDER,
		payload: {
			qty: qty,
		},
	}
}

export const setAdmIModalNIBonus = (bonus) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_BONUS,
		payload: {
			bonus: bonus,
		},
	}
}

export const setAdmIModalNIActive = (isActive) => {
	return {
		type: SET_ADMIN_ITEM_MODAL_NEW_ITEM_ACTIVE,
		payload: {
			isActive: isActive,
		},
	}
}
