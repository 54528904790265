import { Checkbox, Space } from "antd"
import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setDateAction,
	setLoadingAction,
	setReportPOByLocationApprovalInd,
	setReportPOByLocationBranches,
	setReportPOByLocationInspectorInd,
	setReportPOByLocationOrgs,
	setReportPOByLocationRequesterInd,
	setReportPOByLocationSelectedBranch,
	setReportPOByLocationSelectedOrg,
} from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_PO_BY_LOCATION_END_DATETIME, SET_REPORT_PO_BY_LOCATION_START_DATETIME } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import DatePicker from "~/view/component/date_picker/DatePicker"

class ReportPOByLocationFilterDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onStartDate = this.onStartDate.bind(this)
		this.onEndDate = this.onEndDate.bind(this)
		this.onOrg = this.onOrg.bind(this)
		this.onBranch = this.onBranch.bind(this)
		this.onRequester = this.onRequester.bind(this)
		this.onInspector = this.onInspector.bind(this)
		this.onApproval = this.onApproval.bind(this)
	}

	componentDidMount() {
		this.props.setDateAction(SET_REPORT_PO_BY_LOCATION_START_DATETIME, Utils.generateDate().getTime())
		this.props.setDateAction(SET_REPORT_PO_BY_LOCATION_END_DATETIME, Utils.generateDate(0, 0, 0, 23, 59, 59).getTime())

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.po.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setReportPOByLocationOrgs(res.data.locations)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	componentWillUnmount() {
		this.props.setReportPOByLocationOrgs([])
		this.props.setReportPOByLocationBranches([])
		this.props.setReportPOByLocationSelectedOrg(-1, null)
		this.props.setReportPOByLocationSelectedBranch(-1, null)
		this.props.setReportPOByLocationRequesterInd(true)
		this.props.setReportPOByLocationInspectorInd(true)
		this.props.setReportPOByLocationApprovalInd(true)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.filter.selectedOrg.index !== this.props.filter.selectedOrg.index) {
			let branchHtml = document.getElementById(ResourceAssistance.ID.REPORT.po.byLocation.branch)
			branchHtml.selectedIndex = 0
			this.props.setReportPOByLocationSelectedBranch(-1, null)

			if (this.props.filter.selectedOrg.org) {
				this.props.setReportPOByLocationBranches(this.props.filter.selectedOrg.org.locations)
			} else {
				this.props.setReportPOByLocationBranches([])
			}
		}
	}

	onStartDate(date) {
		this.props.setDateAction(SET_REPORT_PO_BY_LOCATION_START_DATETIME, date.getTime())
	}

	onEndDate(date) {
		this.props.setDateAction(SET_REPORT_PO_BY_LOCATION_END_DATETIME, date.getTime())
	}

	onOrg(event) {
		if (event.target.value) {
			this.props.setReportPOByLocationSelectedOrg(event.target.value, this.props.filter.orgs[event.target.value])
		} else {
			this.props.setReportPOByLocationSelectedOrg(-1, null)
		}
	}

	onBranch(event) {
		if (event.target.value) {
			this.props.setReportPOByLocationSelectedBranch(event.target.value, this.props.filter.branches[event.target.value])
		} else {
			this.props.setReportPOByLocationSelectedBranch(-1, null)
		}
	}

	onRequester(event) {
		this.props.setReportPOByLocationRequesterInd(event.target.checked)
	}

	onInspector(event) {
		this.props.setReportPOByLocationInspectorInd(event.target.checked)
	}

	onApproval(event) {
		this.props.setReportPOByLocationApprovalInd(event.target.checked)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.filter)}</legend>
				<Row>
					<Col />
					<Col md="auto">
						<DatePicker startDate={Utils.generateDate()} onChange={this.onStartDate} />
					</Col>
					<Col md="auto">
						<label style={{ alignSelf: ResourceAssistance.CSS.center }}>{translate(ResourceAssistance.Message.to)}</label>
					</Col>
					<Col md="auto">
						<DatePicker startDate={Utils.generateDate(0, 0, 0, 23, 59, 59)} onChange={this.onEndDate} />
					</Col>
					<Col />
				</Row>
				<Row>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.org)}</label>
					</Col>
					<Col>
						<FormControl required size={ResourceAssistance.FormControl.size.sm} as={ResourceAssistance.FormControl.as.select} onChange={this.onOrg}>
							{Utils.renderOptions(this.props.filter.orgs)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.branch)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.REPORT.po.byLocation.branch}
							size={ResourceAssistance.FormControl.size.sm}
							as={ResourceAssistance.FormControl.as.select}
							onChange={this.onBranch}
						>
							{Utils.renderOptions(this.props.filter.branches)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col>
						<Space align="center" size={"large"} style={{ alignSelf: "center" }}>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.filter.isRequester} onChange={this.onRequester}>
								{translate(ResourceAssistance.Message.issued)}
							</Checkbox>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.filter.isInspector} onChange={this.onInspector}>
								{translate(ResourceAssistance.Message.certified)}
							</Checkbox>
							<Checkbox style={{ flexDirection: "row" }} checked={this.props.filter.isApproval} onChange={this.onApproval}>
								{translate(ResourceAssistance.Message.approved)}
							</Checkbox>
						</Space>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	filter: state.report.po.reportByLocation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setReportPOByLocationOrgs,
			setReportPOByLocationBranches,
			setReportPOByLocationSelectedOrg,
			setReportPOByLocationSelectedBranch,
			setReportPOByLocationRequesterInd,
			setReportPOByLocationInspectorInd,
			setReportPOByLocationApprovalInd,
			setDateAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportPOByLocationFilterDetails))
