import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setAdmIModalNIActive,
	setAdmIModalNIBonus,
	setAdmIModalNIBrand,
	setAdmIModalNICode,
	setAdmIModalNIDiscount,
	setAdmIModalNIDose,
	setAdmIModalNIPricePerUnit,
	setAdmIModalNIQtyPerOrder,
	setAdmIModalNISelectedForm,
	setAdmIModalNISelectedSupplier,
	setAdmIModalNISelectedTax,
	setAdmIModalNISelectedUnit,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT,
	SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ASelect from "~/view/component/ant_select/ASelect"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

const Property = {
	CODE: "CODE",
	FORM: "FORM",
	DOSE: "DOSE",
	UNIT: "UNIT",
	BRAND: "BRAND",
	MEDICATION_USAGE: "MEDICATION_USAGE",
	CHRGITEM: "CHRGITEM",
	PRICE: "PRICE",
	DISCOUNT: "DISCOUNT",
	TAX: "TAX",
	MIN_QTY_PER_ORDER: "MIN_QTY_PER_ORDER",
	BONUS: "BONUS",
	ADP_TYPE: "ADP_TYPE",
	GOVERNMENT_CODE: "GOVERNMENT_CODE",
	TMIL_TMT_CODE: "TMIL_TMT_CODE",
	CATEGORY_NUM: "CATEGORY_NUM",
	FS_CODE: "FS_CODE",
	SELL_PRICE_PER_UNIT: "SELL_PRICE_PER_UNIT",
	ACTIVE: "ACTIVE",
}

class AdmIModalNIRight extends React.Component {
	constructor(props) {
		super(props)

		this.onPropertyChange = this.onPropertyChange.bind(this)
		this.onSelectChange = this.onSelectChange.bind(this)
		this.onSupplier = this.onSupplier.bind(this)
		this.onHealthCarePlan = this.onHealthCarePlan.bind(this)
	}

	componentDidMount() {
		if (this.props.item.selectedItem.item) {
			let formHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.form)
			let formIdx = this.props.item.modalNewItem.forms.findIndex((each) => each.id === this.props.item.selectedItem.item.form.id)
			if (formIdx > -1) {
				formHtml.selectedIndex = formIdx + 1
				this.props.setAdmIModalNISelectedForm(formIdx, this.props.item.modalNewItem.forms[formIdx])
			}

			let unitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.unit)
			let unitIdx = this.props.item.modalNewItem.units.findIndex((each) => each.id === this.props.item.selectedItem.item.unit.id)
			if (unitIdx > -1) {
				unitHtml.selectedIndex = unitIdx + 1
				this.props.setAdmIModalNISelectedUnit(unitIdx, this.props.item.modalNewItem.units[unitIdx])
			}

			let supplierHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.supplier)
			let supplierIdx = this.props.item.modalNewItem.suppliers.findIndex((each) => each.id === this.props.item.selectedItem.item.supplier.id)
			if (supplierIdx > -1) {
				supplierHtml.selectedIndex = supplierIdx + 1
				this.props.setAdmIModalNISelectedSupplier(supplierIdx, this.props.item.modalNewItem.suppliers[supplierIdx])
			}

			let taxHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.tax)
			let taxIdx = this.props.item.modalNewItem.taxes.findIndex((each) => each.id === this.props.item.selectedItem.item.tax.id)
			if (taxIdx > -1) {
				taxHtml.selectedIndex = taxIdx + 1
				this.props.setAdmIModalNISelectedTax(taxIdx, this.props.item.modalNewItem.taxes[taxIdx])
			}
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.item.modalNewItem.selectedItem, this.props.item.modalNewItem.selectedItem)) {
			if (!this.props.item.selectedItem.item) {
				this.reset()

				let formHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.form)
				formHtml.selectedIndex = 0
				let unitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.unit)
				unitHtml.selectedIndex = 0
				let supplierHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.supplier)
				supplierHtml.selectedIndex = 0
				let taxHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.tax)
				if (_.isEmpty(this.props.item.modalNewItem.taxes)) {
					taxHtml.selectedIndex = 0
				} else {
					taxHtml.selectedIndex = 1
					this.props.setAdmIModalNISelectedTax(0, this.props.item.modalNewItem.taxes[0])
				}

				this.props.setAdmIModalNIDiscount(0)
				this.props.setAdmIModalNIBonus(0)
			}
		}

		if (
			prevProps.item.modalNewItem.code !== this.props.item.modalNewItem.code ||
			!_.isEqual(prevProps.item.modalNewItem.selectedForm, this.props.item.modalNewItem.selectedForm) ||
			prevProps.item.modalNewItem.dose !== this.props.item.modalNewItem.dose ||
			!_.isEqual(prevProps.item.modalNewItem.selectedUnit, this.props.item.modalNewItem.selectedUnit) ||
			!_.isEqual(prevProps.item.modalNewItem.selectedSupplier, this.props.item.modalNewItem.selectedSupplier) ||
			prevProps.item.modalNewItem.brand !== this.props.item.modalNewItem.brand ||
			prevProps.item.modalNewItem.pricePerUnit !== this.props.item.modalNewItem.pricePerUnit ||
			prevProps.item.modalNewItem.discount !== this.props.item.modalNewItem.discount ||
			!_.isEqual(prevProps.item.modalNewItem.selectedTax, this.props.item.modalNewItem.selectedTax) ||
			prevProps.item.modalNewItem.qtyPerOrder !== this.props.item.modalNewItem.qtyPerOrder ||
			prevProps.item.modalNewItem.bonus !== this.props.item.modalNewItem.bonus ||
			prevProps.item.modalNewItem.fsCode !== this.props.item.modalNewItem.fsCode ||
			prevProps.item.modalNewItem.sellPricePerUnit !== this.props.item.modalNewItem.sellPricePerUnit
		) {
			this.validateInput()
		}
	}

	reset() {
		this.props.setAdmIModalNICode("")
		this.props.setAdmIModalNISelectedForm(-1, null)
		this.props.setAdmIModalNIDose("")
		this.props.setAdmIModalNISelectedUnit(-1, null)
		this.props.setAdmIModalNISelectedSupplier(-1, null)
		this.props.setAdmIModalNIBrand("")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE, "")
		this.props.setAdmIModalNIPricePerUnit("")
		this.props.setAdmIModalNIDiscount("")
		this.props.setAdmIModalNISelectedTax(-1, null)
		this.props.setAdmIModalNIQtyPerOrder("")
		this.props.setAdmIModalNIBonus("")
		this.props.setAdmIModalNIActive(true)
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN, "")
		this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM, "")
	}

	validateInput() {
		let selectedItem = this.props.item.modalNewItem.selectedItem.item
		let items = this.props.item.itemTable.original

		if (!selectedItem) {
			return
		}

		let codeHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.code)
		let codeInput = this.props.item.modalNewItem.code.trim().toUpperCase()

		let formHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.form)
		let formInput = this.props.item.modalNewItem.selectedForm.form

		let doseHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.dose)
		let doseInput = this.props.item.modalNewItem.dose.trim().toLowerCase()

		let unitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.unit)
		let unitInput = this.props.item.modalNewItem.selectedUnit.unit

		let supplierHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.supplier)
		let supplierInput = this.props.item.modalNewItem.selectedSupplier.supplier

		let brandHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.brand)
		let brandInput = this.props.item.modalNewItem.brand.trim().toLowerCase()

		let pricePerUnitHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.pricePerUnit)
		let pricePerUnitInput = this.props.item.modalNewItem.pricePerUnit

		let discountHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.discount)
		let discountInput = this.props.item.modalNewItem.discount

		let taxHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.tax)
		let taxInput = this.props.item.modalNewItem.selectedTax.tax

		let minQtyPerOrderHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.minQtyPerOrder)
		let minQtyPerOrderInput = this.props.item.modalNewItem.qtyPerOrder

		let bonusHtml = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.bonus)
		let bonusInput = this.props.item.modalNewItem.bonus

		let fsCodeDOM = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.fsCode)
		let fsCodeInput = this.props.item.modalNewItem.fsCode

		let sellPricePerUnitDOM = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.sellPricePerUnit)
		let sellPricePerUnitInput = this.props.item.modalNewItem.sellPricePerUnit

		let categoryNumDOM = document.getElementById(ResourceAssistance.ID.ADM.item.modalNI.categoryNum)
		let categoryNumInput = this.props.item.modalNewItem.categoryNum

		if (
			!codeInput ||
			this.props.item.itemTable.original.some((item) => {
				return item.itemSupplierRelps
					.filter((each) => !this.props.item.selectedItem.item || this.props.item.selectedItem.item.id !== each.id)
					.some((itemSupplier) => itemSupplier.code === codeInput)
			})
		) {
			codeHtml.setCustomValidity(
				// this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateCodeWarning })
				""
			)
			formHtml.setCustomValidity("")
			doseHtml.setCustomValidity("")
			unitHtml.setCustomValidity("")
			supplierHtml.setCustomValidity("")
			brandHtml.setCustomValidity("")
			pricePerUnitHtml.setCustomValidity("")
			discountHtml.setCustomValidity("")
			taxHtml.setCustomValidity("")
			minQtyPerOrderHtml.setCustomValidity("")
			bonusHtml.setCustomValidity("")
			fsCodeDOM.setCustomValidity("")
			sellPricePerUnitDOM.setCustomValidity("")
		} else if (
			items
				.filter(
					(each) =>
						(!selectedItem.item || each.id === selectedItem.id) &&
						(!this.props.item.modalNewItem.selectedType || this.props.item.modalNewItem.selectedType.type.id === each.type.id)
				)
				.some((item) => {
					return item.itemSupplierRelps
						.filter((each) => !this.props.item.selectedItem.item || this.props.item.selectedItem.item.id !== each.id)
						.some((itemSupplier) => {
							return (
								formInput &&
								itemSupplier.form.id === formInput.id &&
								itemSupplier.dose.toLowerCase() === doseInput &&
								unitInput &&
								itemSupplier.unit.id === unitInput.id &&
								supplierInput &&
								itemSupplier.supplier.id === supplierInput.id &&
								itemSupplier.brand.toLowerCase() === brandInput &&
								Utils.BigNumber(itemSupplier.pricePerUnit).eq(pricePerUnitInput) &&
								Utils.BigNumber(itemSupplier.discount).eq(discountInput) &&
								taxInput &&
								itemSupplier.tax.id === taxInput.id &&
								Utils.BigNumber(itemSupplier.minQtyPerOrder).eq(minQtyPerOrderInput) &&
								Utils.BigNumber(itemSupplier.bonus).eq(bonusInput) &&
								itemSupplier.fsCode.toLowerCase() === fsCodeInput &&
								Utils.BigNumber(itemSupplier.sellPricePerUnit).eq(sellPricePerUnitInput) &&
								Utils.BigNumber(itemSupplier.categoryNum).eq(categoryNumInput)
							)
						})
				})
		) {
			codeHtml.setCustomValidity("")
			formHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			doseHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			unitHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			supplierHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			brandHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			pricePerUnitHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			discountHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			taxHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			minQtyPerOrderHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			bonusHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			fsCodeDOM.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			sellPricePerUnitDOM.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
			categoryNumDOM.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateWarning }))
		} else {
			codeHtml.setCustomValidity("")
			formHtml.setCustomValidity("")
			doseHtml.setCustomValidity("")
			unitHtml.setCustomValidity("")
			supplierHtml.setCustomValidity("")
			brandHtml.setCustomValidity("")
			pricePerUnitHtml.setCustomValidity("")
			discountHtml.setCustomValidity("")
			taxHtml.setCustomValidity("")
			minQtyPerOrderHtml.setCustomValidity("")
			bonusHtml.setCustomValidity("")
			fsCodeDOM.setCustomValidity("")
			sellPricePerUnitDOM.setCustomValidity("")
			categoryNumDOM.setCustomValidity("")
		}
	}

	getHealthcarePlanValues() {
		let values = []
		this.props.item.modalNewItem.healthcarePlans.forEach((healthcarePlan, idx) => {
			if (_.includes(this.props.item.modalNewItem.healthcarePlan, healthcarePlan.displayName)) {
				values.push(idx)
			}
		})
		return values
	}

	getChrgItemValue() {
		let value = []
		this.props.item.modalNewItem.chrgItems.forEach((chrgItem, idx) => {
			if (_.includes(this.props.item.modalNewItem.chrgItem, chrgItem.name)) {
				value.push(idx)
			}
		})
		return value
	}

	onPropertyChange(event) {
		switch (event.target.name) {
			case Property.CODE:
				this.props.setAdmIModalNICode(event.target.value.toUpperCase())
				break
			case Property.FORM:
				this.props.setAdmIModalNISelectedForm(event.target.value, this.props.item.modalNewItem.forms[event.target.value])
				break
			case Property.DOSE:
				this.props.setAdmIModalNIDose(event.target.value)
				break
			case Property.UNIT:
				this.props.setAdmIModalNISelectedUnit(event.target.value, this.props.item.modalNewItem.units[event.target.value])
				break
			case Property.BRAND:
				this.props.setAdmIModalNIBrand(event.target.value)
				break
			case Property.MEDICATION_USAGE:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_MEDICATION_USAGE, event.target.value)
				break
			case Property.PRICE:
				this.props.setAdmIModalNIPricePerUnit(event.target.value)
				break
			case Property.DISCOUNT:
				this.props.setAdmIModalNIDiscount(event.target.value)
				break
			case Property.TAX:
				this.props.setAdmIModalNISelectedTax(event.target.value, this.props.item.modalNewItem.taxes[event.target.value])
				break
			case Property.MIN_QTY_PER_ORDER:
				this.props.setAdmIModalNIQtyPerOrder(event.target.value)
				break
			case Property.BONUS:
				this.props.setAdmIModalNIBonus(event.target.value)
				break
			case Property.GOVERNMENT_CODE:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_GOVERNMENT_CODE, event.target.value)
				break
			case Property.TMIL_TMT_CODE:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_TMLT_TMT_CODE, event.target.value)
				break
			case Property.CATEGORY_NUM:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CATEGORY_NUM, event.target.value)
				break
			case Property.FS_CODE:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_FS_CODE, event.target.value.toUpperCase())
				break
			case Property.SELL_PRICE_PER_UNIT:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_SELL_PRICE_PER_UNIT, event.target.value)
				break
			case Property.ACTIVE:
				this.props.setAdmIModalNIActive(event.target.checked)
				break
			default:
				break
		}
	}

	onSelectChange(name, value) {
		switch (name) {
			case Property.CHRGITEM:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_CHRG_ITEM, value)
				break
			case Property.ADP_TYPE:
				this.props.setValue(SET_ADMIN_ITEM_MODAL_NEW_ITEM_ADP_TYPE, value)
				break
			default:
				break
		}
	}

	onSupplier(value) {
		this.props.setAdmIModalNISelectedSupplier(value > -1 ? Number(value) : -1, value > -1 ? this.props.item.modalNewItem.suppliers[value] : null)
	}

	onHealthCarePlan(value) {
		this.props.setValue(
			SET_ADMIN_ITEM_MODAL_NEW_ITEM_HEALTH_CARE_PLAN,
			value.map((idx) => this.props.item.modalNewItem.healthcarePlans[idx].displayName).join("/")
		)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<fieldset>
					<legend>{translate(ResourceAssistance.Message.generalInfo)}</legend>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.code)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.code}
								name={Property.CODE}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.code}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.form)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.form}
								name={Property.FORM}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								onChange={this.onPropertyChange}
							>
								{Utils.renderOptions(this.props.item.modalNewItem.forms, true)}
							</FormControl>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.dose)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.dose}
								name={Property.DOSE}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.dose}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.subunit)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.unit}
								name={Property.UNIT}
								as={ResourceAssistance.FormControl.as.select}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								onChange={this.onPropertyChange}
							>
								{Utils.renderOptions(this.props.item.modalNewItem.units, true)}
							</FormControl>
						</Col>
					</Row>

					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.supplier)}</label>
						</Col>
						<Col>
							<Select
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.supplier}
								showSearch
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.selectedSupplier.supplier ? this.props.item.modalNewItem.selectedSupplier.index : undefined}
								size={ResourceAssistance.Select.size.large}
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "unset" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								onChange={this.onSupplier}
							>
								{Utils.renderSelects(this.props.item.modalNewItem.suppliers, true)}
							</Select>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={4}>
							<label>{translate(ResourceAssistance.Message.tradeNameOrBrand)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.brand}
								name={Property.BRAND}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.brand}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.medicationUsage)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.medicationUsage}
								name={Property.MEDICATION_USAGE}
								as={ResourceAssistance.FormControl.as.textArea}
								rows="3"
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.medicationUsage}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<Select
								allowClear
								mode={ResourceAssistance.Select.mode.multiple}
								size={ResourceAssistance.Select.size.large}
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "normal" }}
								maxTagCount="responsive"
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
								placeholder={translate(ResourceAssistance.Hospitel.healthCarePlan)}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.getHealthcarePlanValues()}
								onChange={this.onHealthCarePlan}
							>
								{Utils.renderSelects(this.props.item.modalNewItem.healthcarePlans, false)}
							</Select>
						</Col>
					</Row>
					<Row>
						<Col>
							<ASelect
								required
								showSearch
								allowClear
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "normal" }}
								size={ResourceAssistance.Select.size.large}
								placeholder={translate(ResourceAssistance.Message.chrgItem)}
								value={this.props.item.modalNewItem.chrgItem}
								onSelectChange={(value) => this.onSelectChange(Property.CHRGITEM, value)}
								options={this.props.item.modalNewItem.chrgItems}
								displayProperty={"name"}
							/>
						</Col>
					</Row>
				</fieldset>

				<fieldset>
					<legend>{translate(ResourceAssistance.Message.purchase)}</legend>
					<Row className={"g-0"}>
						<Col md={6}>
							<label>{translate(ResourceAssistance.Message.costPerOrder)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.pricePerUnit}
								name={Property.PRICE}
								type={ResourceAssistance.FormControl.type.number}
								min="0.0001"
								step="0.0001"
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.pricePerUnit}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.discount)}</label>
						</Col>
						<Col>
							<InputGroup>
								<FormControl
									required
									id={ResourceAssistance.ID.ADM.item.modalNI.discount}
									name={Property.DISCOUNT}
									type={ResourceAssistance.FormControl.type.number}
									min="0"
									step="0.01"
									max="100"
									disabled={this.props.item.modalNewItem.selectedItem.index === -1}
									value={this.props.item.modalNewItem.discount}
									onChange={this.onPropertyChange}
								/>
								<InputGroup.Text>%</InputGroup.Text>
							</InputGroup>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.taxRate)}</label>
						</Col>
						<Col>
							<InputGroup>
								<FormControl
									required
									id={ResourceAssistance.ID.ADM.item.modalNI.tax}
									name={Property.TAX}
									as={ResourceAssistance.FormControl.as.select}
									disabled={this.props.item.modalNewItem.selectedItem.index === -1}
									onChange={this.onPropertyChange}
								>
									{Utils.renderOptions(this.props.item.modalNewItem.taxes)}
								</FormControl>
								{this.props.item.modalNewItem.selectedTax.tax && <InputGroup.Text>{`${this.props.item.modalNewItem.selectedTax.tax.taxRate}%`}</InputGroup.Text>}
							</InputGroup>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={5}>
							<label>{translate(ResourceAssistance.Message.minQtyPerOrder)}</label>
						</Col>
						<Col>
							<FormControl
								required
								name={Property.MIN_QTY_PER_ORDER}
								id={ResourceAssistance.ID.ADM.item.modalNI.minQtyPerOrder}
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.qtyPerOrder}
								min="1"
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.bonus)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.bonus}
								name={Property.BONUS}
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.bonus}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
				</fieldset>

				<fieldset>
					<legend>{translate(ResourceAssistance.Message.eclaim)}</legend>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.adpType)}</label>
						</Col>
						<Col>
							<ASelect
								showSearch
								allowClear
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								style={{ flexGrow: 1, textAlign: "center", alignSelf: "normal" }}
								size={ResourceAssistance.Select.size.large}
								placeholder={translate(ResourceAssistance.Message.adpType)}
								value={this.props.item.modalNewItem.adpType}
								onSelectChange={(value) => this.onSelectChange(Property.ADP_TYPE, value)}
								options={this.props.item.modalNewItem.adpTypes}
								displayProperty={"name"}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.governmentCode)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.governmentCode}
								name={Property.GOVERNMENT_CODE}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.governmentCode}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.tmltTmtCode)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.tmltTmtCode}
								name={Property.TMIL_TMT_CODE}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.tmltTmtCode}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
				</fieldset>

				<fieldset>
					<legend>{translate(ResourceAssistance.Message.sell)}</legend>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.categoryNum)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.categoryNum}
								name={Property.CATEGORY_NUM}
								type={ResourceAssistance.FormControl.type.number}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.categoryNum}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={3}>
							<label>{translate(ResourceAssistance.Message.fsCode)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.fsCode}
								name={Property.FS_CODE}
								type={ResourceAssistance.FormControl.type.text}
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.fsCode}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
					<Row className={"g-0"}>
						<Col md={4}>
							<label>{translate(ResourceAssistance.Message.sellingPricePerUnit)}</label>
						</Col>
						<Col>
							<FormControl
								required
								id={ResourceAssistance.ID.ADM.item.modalNI.sellPricePerUnit}
								name={Property.SELL_PRICE_PER_UNIT}
								type={ResourceAssistance.FormControl.type.number}
								min="0.00"
								step="0.01"
								disabled={this.props.item.modalNewItem.selectedItem.index === -1}
								value={this.props.item.modalNewItem.sellPricePerUnit}
								onChange={this.onPropertyChange}
							/>
						</Col>
					</Row>
				</fieldset>
				<Row style={{ marginTop: "0.3rem" }}>
					<Col md={3}>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox
							name={Property.ACTIVE}
							onClick={this.onPropertyChange}
							disabled={this.props.item.modalNewItem.selectedItem.index === -1}
							checked={this.props.item.modalNewItem.isActive}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	item: state.admin.itemConfig.item,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmIModalNISelectedTax,
			setAdmIModalNISelectedSupplier,
			setAdmIModalNIBrand,
			setAdmIModalNIPricePerUnit,
			setAdmIModalNIDiscount,
			setAdmIModalNIQtyPerOrder,
			setAdmIModalNIBonus,
			setAdmIModalNIActive,
			setAdmIModalNICode,
			setAdmIModalNISelectedForm,
			setAdmIModalNIDose,
			setAdmIModalNISelectedUnit,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmIModalNIRight))
