import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { RiUserSearchFill } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_SA_SEARCH_DISPLAY } from "~/redux/type"

class SurgicalAuthLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onSearchPatient = this.onSearchPatient.bind(this)
	}

	onSearchPatient(event) {
		this.props.onModalDisplayAction(SET_SA_SEARCH_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-1"}>
				<Col />
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearchPatient}>
						<RiUserSearchFill size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SurgicalAuthLeftAction))
