import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"

class AccountsReceivablePrintDataFactory extends PrintableDataFactory {
	static generateJournalVoucherData = (org, invoice) => {
		let totalDebit = Utils.BigNumber(0)
		let totalCredit = Utils.BigNumber(0)
		return {
			titleIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.accountsReceivable }),
			idIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.id }),
			pidIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.patientId }),
			patientIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.patientName }),
			dobIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			identificationIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			healthcarePlanIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Hospitel.healthCarePlan }),
			dateIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.date }),
			codeIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.code }),
			nameIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.name }),
			debitIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.debit }),
			creditIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.credit }),
			grandTotalIntl: this.getIntl().formatMessage({ id: ResourceAssistance.Message.grandTotal }),

			name: org.displayName,
			address: org.address.displayName,

			id: ResourceAssistance.Enum.accountsReceivableDocumentCode + "-" + invoice.id,
			pid: invoice.billing.billingPlan.admission.patient.id,
			patient: invoice.billing.billingPlan.admission.patient.displayName,
			dob: Utils.formatDate(invoice.billing.billingPlan.admission.patient.dobtime),
			identification: invoice.billing.billingPlan.admission.patient.identificationNumber,
			healthcarePlan: invoice.billing.billingPlan.name,
			date: Utils.formatDate(invoice.creationDateTime),

			GLs: invoice.accountsReceivableGLs
				.sort((a, b) => Utils.sort(a.credit, b.credit))
				.map((each) => {
					totalDebit = totalDebit.plus(each.debit)
					totalCredit = totalCredit.plus(each.credit)
					return {
						code: each.code,
						name: each.name,
						debit: Utils.formatNumWithComma(Utils.BigNumber(each.debit).toFixed(2)),
						credit: Utils.formatNumWithComma(Utils.BigNumber(each.credit).toFixed(2)),
					}
				}),
			totalDebit: Utils.formatNumWithComma(totalDebit.toFixed(2)),
			totalCredit: Utils.formatNumWithComma(totalCredit.toFixed(2)),

			signatureNames: [invoice.accountsReceivableInspector.displayName, invoice.accountsReceivableLastModifiedBy.displayName],
		}
	}
}

export default AccountsReceivablePrintDataFactory
