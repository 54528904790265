import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { IoCalendarSharp } from "react-icons/io5"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { changeSearchDateAction, selectPRAction, selectTypeAction, setSearchNumAction } from "~/redux/action"
import DatePicker from "~/view/component/date_picker/DatePicker"

class PRLeftFirstRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: this.props.searchDate.startDate,
			endDate: this.props.searchDate.endDate,
		}
		this.onStartDateChange = this.onStartDateChange.bind(this)
		this.onEndDateChange = this.onEndDateChange.bind(this)
		this.onSearchSubmit = this.onSearchSubmit.bind(this)
	}

	onSearchSubmit(event) {
		event.preventDefault()
		this.props.dispatch(changeSearchDateAction(this.state.startDate, this.state.endDate)).then(() => {
			this.props.selectPRAction(-1, null)
			this.props.setSearchNumAction("")
			this.props.loadPrs()
		})
	}

	onStartDateChange(date) {
		this.setState({
			startDate: date,
		})
	}

	onEndDateChange(date) {
		this.setState({
			endDate: date,
		})
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<DatePicker startDate={this.props.searchDate.startDate} onChange={this.onStartDateChange} />
				</Col>
				<Col>
					<label style={{ alignSelf: ResourceAssistance.CSS.center }}>{translate(ResourceAssistance.Message.to)}</label>
				</Col>
				<Col md="auto">
					<DatePicker startDate={this.props.searchDate.endDate} onChange={this.onEndDateChange} />
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						type={ResourceAssistance.Button.type.submit}
						variant={ResourceAssistance.Button.variant.primary}
						onClick={this.onSearchSubmit}
					>
						<IoCalendarSharp size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	searchDate: state.purchaseRequest.searchDate,
})

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators({ changeSearchDateAction, selectPRAction, setSearchNumAction, selectTypeAction }, dispatch),
		dispatch,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PRLeftFirstRow)
