import _ from "lodash"
import React from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalDoctorNoteP extends React.Component {
	constructor(props) {
		super(props)

		this.onPlan = this.onPlan.bind(this)
		this.onPlanSupportiveTreatment = this.onPlanSupportiveTreatment.bind(this)
		this.onPlanRepeatedCRX = this.onPlanRepeatedCRX.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedSOAP.soap) {
			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN, this.props.hospitel.selectedSOAP.soap.plan)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN, "")
	}

	modifyPlan(plan, isAdd) {
		let value = this.props.hospitel.modalDoctorNote.plan
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : ", ", plan)
		} else {
			value = value.replace(plan, "")
		}
		value = Utils.replaceDuplicateSpaces(value)
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN, Utils.trim(value))
	}

	onPlan(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_PLAN, event.target.value)
	}

	onPlanSupportiveTreatment(event) {
		this.modifyPlan(event.target.value, event.target.checked)
	}

	onPlanRepeatedCRX(event) {
		this.modifyPlan(event.target.value, event.target.checked)
	}

	render() {
		return (
			<Row>
				<Col md={"auto"}>
					<Row>
						<Col>
							<FormGroup controlId={"supportive_treatment"}>
								<FormCheck
									label={"Supportive treatment"}
									checked={_.includes(this.props.hospitel.modalDoctorNote.plan, "Supportive treatment")}
									onChange={this.onPlanSupportiveTreatment}
									value={"Supportive treatment"}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup controlId={"repeatedCRX"}>
								<FormCheck
									label={"Repeated CXR"}
									checked={_.includes(this.props.hospitel.modalDoctorNote.plan, "Repeated CXR")}
									onChange={this.onPlanRepeatedCRX}
									value={"Repeated CXR"}
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row>
						<Col>
							<FormControl
								as={ResourceAssistance.FormControl.as.textArea}
								rows={3}
								value={this.props.hospitel.modalDoctorNote.plan}
								onChange={this.onPlan}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNoteP))
