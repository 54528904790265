import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setSearchText, setOPDPatients } from "~/redux/action"
import { SET_OPD_SEARCH_IDENTIFICATION, SET_OPD_SEARCH_PID } from "~/redux/type"
import SearchBox from "~/view/component/search_box/SearchBox"

class OutpatientLeftSearch extends React.Component {
	constructor(props) {
		super(props)

		this.onPatientId = this.onPatientId.bind(this)
		this.onId = this.onId.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.opd.searchPID !== this.props.opd.searchPID ||
			prevProps.opd.searchIdentification !== this.props.opd.searchIdentification
		) {
			this.props.setOPDPatients(this.props.opd.patientTable.original)
		}
	}

	onPatientId(event) {
		this.props.setSearchText(SET_OPD_SEARCH_PID, event.target.value)
	}

	onId(event) {
		this.props.setSearchText(SET_OPD_SEARCH_IDENTIFICATION, event.target.value)
	}

	render() {
		return (
			<Row>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.number, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Hospitel.patientId, ResourceAssistance.Hospitel.identification]}
						callbacks={[this.onPatientId, this.onId]}
						values={[this.props.opd.searchPID, this.props.opd.searchIdentification]}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSearchText,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientLeftSearch)
