import { Button as AButton, Card, Descriptions, Tooltip } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { FaCheck } from "react-icons/fa6"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST, SET_PAGE_LOADING, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OperatingRoomNurseTabPreOperativeCheckList extends React.Component {
	constructor(props) {
		super(props)

		this.onEdit = this.onEdit.bind(this)
		this.onConfirm = this.onConfirm.bind(this)
		this.confirm = this.confirm.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.operatingRoom.confirmNursePreOperativeCheckList)) {
					this.confirm()
				}
			}
		}
	}

	confirm() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.confirmNursePreOperativeCheckList,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				preOperativeCheckLists: [
					{
						id: this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists[0].id,
						confirmDateTime: moment().milliseconds(0).valueOf(),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	getYesNoMsg(input) {
		if (input === "") {
			return ""
		}
		return input ? translate(ResourceAssistance.Message.yes) : translate(ResourceAssistance.Message.no)
	}

	render() {
		let orCheckList =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists)
				? this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists[0]
				: {}
		let oiCheckList =
			this.props.operatingRoom.selectedRequest.request && !_.isEmpty(this.props.operatingRoom.selectedRequest.request.opdIpdPreOperativeCheckLists)
				? this.props.operatingRoom.selectedRequest.request.opdIpdPreOperativeCheckLists[0]
				: {}
		return (
			<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
				<Row className="full-size">
					<Col>
						<Descriptions
							layout="vertical"
							colon={false}
							style={{ flex: "1", flexDirection: "column", overflowY: "auto", overflowX: "hidden" }}
							size={"small"}
							contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
							column={5}
							extra={
								(_.isEmpty(orCheckList) || !orCheckList.confirmBy) && (
									<Container fluid className={"full-size full-flex"}>
										<Row className={"g-2"} style={{ marginBottom: "0" }}>
											<Col md="auto">
												<Tooltip title={translate(ResourceAssistance.Message.edit)}>
													<AButton
														type="primary"
														size="middle"
														shape="round"
														icon={<FaRegEdit size={ResourceAssistance.ReactIcon.size} />}
														onClick={this.onEdit}
													/>
												</Tooltip>
											</Col>
											{!_.isEmpty(orCheckList) && !orCheckList.confirmBy && (
												<Col md="auto">
													<Tooltip title={translate(ResourceAssistance.Message.confirm)}>
														<AButton
															style={{
																background: ResourceAssistance.CSS.Color.dark_green,
															}}
															type="primary"
															size="middle"
															shape="round"
															icon={<FaCheck size={ResourceAssistance.ReactIcon.size} />}
															onClick={this.onConfirm}
														/>
													</Tooltip>
												</Col>
											)}
										</Row>
									</Container>
								)
							}
						>
							<Descriptions.Item span={1} />
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.sender)} span={1} />
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.remark)} span={1} />
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.operatingRoom)} span={1} />
							<Descriptions.Item labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.remark)} span={1} />

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.identifyThePatientNameSurnameAndDateOfBirth)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.identifyPatient)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.identifyPatient)[1]}</Descriptions.Item>
							<Descriptions.Item contentStyle={{ display: "flex", textAlign: "center", alignSelf: "center" }} span={1}>
								{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.identifyPatient)[0])}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.identifyPatient)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.markingSiteForSurgeryProcedure)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.markingSurgerySite)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.markingSurgerySite)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.markingSurgerySite)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.markingSurgerySite)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.letterOfConsentToUndergoSurgeryDiagnosis)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.consentSurgery)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.consentSurgery)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.consentSurgery)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.consentSurgery)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.letterOfConsentToReceiveAnesthesia)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.consentAnesthesia)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.consentAnesthesia)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.consentAnesthesia)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.consentAnesthesia)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.labResult)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.labResult)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.labResult)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.labResult)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.labResult)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.imagingResult)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.imagingResult)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.imagingResult)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.imagingResult)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.imagingResult)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.EKGResult)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.ekgResult)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.ekgResult)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.ekgResult)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.ekgResult)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.cleanPatientBodyForSurgery)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.cleanPatientBody)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.cleanPatientBody)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.cleanPatientBody)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.cleanPatientBody)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.unisonEnema)}{" "}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.unisonEnema)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.unisonEnema)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.unisonEnema)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.unisonEnema)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.NPOTime)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.npoTime)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.npoTime)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.npoTime)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.npoTime)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.removeDentures)}{" "}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.removeDentures)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.removeDentures)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.removeDentures)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.removeDentures)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.eyeGlassesContactLensEyelashesRemoved)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.removeEyeglasses)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.removeEyeglasses)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.removeEyeglasses)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.removeEyeglasses)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.jewelryAndHairpiecesRemoved)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.removeJewelry)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.removeJewelry)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.removeJewelry)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.removeJewelry)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.bodyPiercingRemoved)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.removeBodyPiercing)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.removeBodyPiercing)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.removeBodyPiercing)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.removeBodyPiercing)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.changePatientClothes)}{" "}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.changePatientClothes)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.changePatientClothes)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.changePatientClothes)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.changePatientClothes)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.haveThePatientUrinateBeforeBeingReferralOR)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.urinateBeforeOR)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.urinateBeforeOR)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.urinateBeforeOR)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.urinateBeforeOR)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.bloodRequested)}{" "}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.bloodRequested)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.bloodRequested)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.bloodRequested)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.bloodRequested)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.preEducation)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.preEducation)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.preEducation)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.preEducation)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.preEducation)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.otherMedicalDevice)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.otherMedicalDevice)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.otherMedicalDevice)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.otherMedicalDevice)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.otherMedicalDevice)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.toolSpecialEquipment)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.toolSpecialEquipment)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.toolSpecialEquipment)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.toolSpecialEquipment)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.toolSpecialEquipment)[1]}</Descriptions.Item>

							<Descriptions.Item contentStyle={{ fontStyle: "normal" }} span={1}>
								{translate(ResourceAssistance.Message.implant)}
							</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(oiCheckList.implant)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(oiCheckList.implant)[1]}</Descriptions.Item>
							<Descriptions.Item span={1}>{this.getYesNoMsg(Utils.splitPreOperativeCheckListProperty(orCheckList.implant)[0])}</Descriptions.Item>
							<Descriptions.Item span={1}>{Utils.splitPreOperativeCheckListProperty(orCheckList.implant)[1]}</Descriptions.Item>

							<Descriptions.Item span={5} />
							<Descriptions.Item span={1} />
							<Descriptions.Item span={2} labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.nurse)}>
								{oiCheckList.confirmBy ? oiCheckList.confirmBy.displayName : ""}
							</Descriptions.Item>
							<Descriptions.Item span={2} labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.nurse)}>
								{orCheckList.confirmBy ? orCheckList.confirmBy.displayName : ""}
							</Descriptions.Item>
							<Descriptions.Item span={1} />
							<Descriptions.Item span={2} labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.date)}>
								{Utils.formatDateTime(oiCheckList.confirmDateTime)}
							</Descriptions.Item>
							<Descriptions.Item span={2} labelStyle={{ fontWeight: "bold" }} label={translate(ResourceAssistance.Message.date)}>
								{Utils.formatDateTime(orCheckList.confirmDateTime)}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Container>
		)
	}

	onEdit(e) {
		this.props.onModalDisplayAction(SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST, true)
	}

	onConfirm(e) {
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.operatingRoom.confirmNursePreOperativeCheckList)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.confirm)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				<Descriptions style={{ flex: "1", flexDirection: "column" }} size={"small"} contentStyle={{ fontStyle: "italic" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.scheduledDate)} span={1}>
						{`${Utils.formatDateTime(this.props.operatingRoom.selectedRequest.request.operatingRoomSchedules[0].startDateTime)} - ${Utils.formatDateTime(
							this.props.operatingRoom.selectedRequest.request.operatingRoomSchedules[0].endDateTime
						)}`}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.patientId)} span={1}>
						{this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.id}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.patientName)} span={1}>
						{this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.displayName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.dateOfBirth)} span={1}>
						{Utils.formatDate(this.props.operatingRoom.selectedRequest.request.clinicalRecord.admission.patient.dobtime)}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setOperatingRoomRequests,
			setValue,
			setWarningId,
			setWarningMsgAction,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OperatingRoomNurseTabPreOperativeCheckList))
