import { Divider } from "antd"
import React, { Component, Fragment } from "react"
import { Button, Col, FormCheck, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { FiRefreshCw } from "react-icons/fi"
import { MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setDisplay, setEclaimUcTable, setLoadingAction, setSelected, setValue } from "~/redux/action"
import {
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHDESCRIPTION,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME,
	SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE,
	SET_ADM_WP_ECLAIMUC_ISDISPLAYINACTIVE,
	SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE,
	SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME,
	SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE,
	SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT,
	SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT,
	SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT,
	SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT,
	SET_ADM_WP_ECLAIMUC_SELECTEDITEM,
	SET_ADM_WP_ECLAIMUC_GETURL,
	SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigEclaimUcItems extends Component {
	constructor(props) {
		super(props)

		this.onSearchCodeTextChange = this.onSearchCodeTextChange.bind(this)
		this.onSearchNameTextChange = this.onSearchNameTextChange.bind(this)
		this.onSearchVariableTextChange = this.onSearchVariableTextChange.bind(this)
		this.onSearchDescriptionTextChange = this.onSearchDescriptionTextChange.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onAddBtnClick = this.onAddBtnClick.bind(this)
		this.onEditBtnClick = this.onEditBtnClick.bind(this)
		this.onRefreshClick = this.onRefreshClick.bind(this)
		this.onSelectRowClick = this.onSelectRowClick.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.configEclaimUc.searchCodeText !== this.props.configEclaimUc.searchCodeText ||
			prevProps.configEclaimUc.searchNameText !== this.props.configEclaimUc.searchNameText ||
			prevProps.configEclaimUc.searchVariableText !== this.props.configEclaimUc.searchVariableText ||
			prevProps.configEclaimUc.searchDescriptionText !== this.props.configEclaimUc.searchDescriptionText ||
			prevProps.configEclaimUc.displayInactive !== this.props.configEclaimUc.displayInactive
		) {
			this.props.setEclaimUcTable(
				SET_ADM_WP_ECLAIMUC_ITEMTABLE,
				this.props.configEclaimUc.itemTable.dataKeys,
				this.props.configEclaimUc.itemTable.header,
				this.props.configEclaimUc.itemTable.original
			)
		}
	}

	componentWillUnmount() {
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHCODE, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHNAME, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHDESCRIPTION, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_DISPLAYSEARCHVARIABLE, false)
		this.props.setValue(SET_ADM_WP_ECLAIMUC_GETURL, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT, "")
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT, "")
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYCODE, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYNAME, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYFILE, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDESCRIPTION, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_DISPLAYDEFAULT, false)
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_MODAL_SAVEURL, false)
		this.props.setSelected(SET_ADM_WP_ECLAIMUC_SELECTEDITEM, -1, {})
		this.props.setEclaimUcTable(SET_ADM_WP_ECLAIMUC_ITEMTABLE, [], [], [])
	}

	onSearchCodeTextChange(event) {
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHCODETEXT, event.target.value)
	}

	onSearchNameTextChange(event) {
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHNAMETEXT, event.target.value)
	}

	onSearchVariableTextChange(event) {
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHVARIABLETEXT, event.target.value)
	}

	onSearchDescriptionTextChange(event) {
		this.props.setValue(SET_ADM_WP_ECLAIMUC_SEARCHDESCRIPTIONTEXT, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_ISDISPLAYINACTIVE, event.target.checked)
	}

	onAddBtnClick() {
		this.props.setSelected(SET_ADM_WP_ECLAIMUC_SELECTEDITEM, -1, {})
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY, true)
	}

	onEditBtnClick() {
		this.props.setDisplay(SET_ADM_WP_ECLAIMUC_ISMODALDISPLAY, true)
	}

	onEditBtnDisable() {
		return this.props.configEclaimUc.selectedItem.index === -1 ? true : false
	}

	onRefreshClick(event) {
		let params = {
			method: "GET",
			url: this.props.configEclaimUc.getUrl,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setEclaimUcTable(
				SET_ADM_WP_ECLAIMUC_ITEMTABLE,
				this.props.configEclaimUc.itemTable.dataKeys,
				this.props.configEclaimUc.itemTable.header,
				res.data.wfep
			)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setEclaimUcTable(SET_ADM_WP_ECLAIMUC_ITEMTABLE, this.props.configEclaimUc.itemTable.dataKeys, this.props.configEclaimUc.itemTable.header, [])
			this.props.setLoadingAction(SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_ADM_WP_ECLAIMUC_ITEMTABLE_ISLOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectRowClick(item, index) {
		this.props.setSelected(SET_ADM_WP_ECLAIMUC_SELECTEDITEM, index, this.props.configEclaimUc.itemTable.filtered[index])
	}

	render() {
		return (
			<Fragment>
				<Row className="g-1">
					{this.props.configEclaimUc.displaySearchCode && (
						<Col>
							<SearchBox
								ontrolTypes={[ResourceAssistance.FormControl.type.text]}
								placeholders={[this.props.searchCodePlaceholders]}
								callbacks={[this.onSearchCodeTextChange]}
								values={[this.props.configEclaimUc.searchCodeText]}
							/>
						</Col>
					)}
					{this.props.configEclaimUc.displaySearchName && (
						<Col>
							<SearchBox
								ontrolTypes={[ResourceAssistance.FormControl.type.text]}
								placeholders={[this.props.searchNamePlaceholders]}
								callbacks={[this.onSearchNameTextChange]}
								values={[this.props.configEclaimUc.searchNameText]}
							/>
						</Col>
					)}
					{this.props.configEclaimUc.displaySearchVariable && (
						<Col>
							<SearchBox
								ontrolTypes={[ResourceAssistance.FormControl.type.text]}
								placeholders={[this.props.searchVariablePlaceholders]}
								callbacks={[this.onSearchVariableTextChange]}
								values={[this.props.configEclaimUc.searchVariableText]}
							/>
						</Col>
					)}
					{this.props.configEclaimUc.displaySearchDescription && (
						<Col>
							<SearchBox
								ontrolTypes={[ResourceAssistance.FormControl.type.text]}
								placeholders={[this.props.searchDescriptionPlaceholders]}
								callbacks={[this.onSearchDescriptionTextChange]}
								values={[this.props.configEclaimUc.searchDescriptionText]}
							/>
						</Col>
					)}
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayInactive)}
							checked={this.props.configEclaimUc.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAddBtnClick}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.onEditBtnDisable()} onClick={this.onEditBtnClick}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onRefreshClick}>
							<FiRefreshCw size={ResourceAssistance.ReactIcon.size} />
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							striped
							enableHighlight
							isClearHighlight={this.props.configEclaimUc.selectedItem.index === -1}
							data={this.props.configEclaimUc.itemTable}
							highlightedRow={this.props.configEclaimUc.selectedItem.index}
							onClick={this.onSelectRowClick}
						/>
						{this.props.configEclaimUc.itemTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
			</Fragment>
		)
	}
}

ConfigEclaimUcItems.defaultProps = {
	searchCodePlaceholders: ResourceAssistance.Message.code,
	searchNamePlaceholders: ResourceAssistance.Message.name,
	searchVariablePlaceholders: ResourceAssistance.Message.variable,
	searchDescriptionPlaceholders: ResourceAssistance.Message.description,
}

const mapStateToProps = (state) => ({
	configEclaimUc: state.admin.configEclaim.eclaimUC,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
			setLoadingAction,
			setEclaimUcTable,
			setDisplay,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigEclaimUcItems))
