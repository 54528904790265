import _ from "lodash"
import { LOCALES, ResourceAssistance } from "~/i18n"
import { store } from "~/redux/Store"
import { Utils } from "~/utils/Utils"
import { PrintableDataFactory } from "../PrintableDataFactory"

class OPDPrintableDataFactory extends PrintableDataFactory {
	static generateDoctorPrescriptionReportData = (doctor, patient, org, branch, orders, logo) => {
		let intl = super.getIntl()

		return {
			identificationIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			patientNameIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			ageIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			dobIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			addressIntl: intl.formatMessage({ id: ResourceAssistance.Message.address }),
			telIntl: intl.formatMessage({ id: ResourceAssistance.Message.tel }),
			licenseIntl: intl.formatMessage({ id: ResourceAssistance.Message.licenseNum }),
			medicineIntl: intl.formatMessage({ id: ResourceAssistance.Message.medicine }),
			doseIntl: intl.formatMessage({ id: ResourceAssistance.Message.dose }),
			durationIntl: intl.formatMessage({ id: ResourceAssistance.Message.duration }),

			logo: logo,
			name: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? org.displayNameEng : org.displayName,
			branchName: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? branch.displayNameEng : branch.displayName,
			address: _.isEqual(store.getState().language.locale, LOCALES.ENGLISH) ? branch.address.displayNameEng : branch.address.displayName,
			tel: branch.address.tel,

			doctorName: intl.formatMessage({ id: ResourceAssistance.Message.doctorPrefix }) + " " + doctor.firstName + " " + doctor.lastName,
			doctorLicense: doctor.licenseNum,
			doctorPhone: doctor.phone,

			pid: patient.id,
			identification: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),
			patientAddress: Utils.trim(patient.address),
			patientTel: patient.tel,

			orders: orders.map((order) => {
				return {
					...order,
					duration:
						Utils.calculateDaysBetween(order.startDateTime, order.endDateTime) > 0
							? Utils.calculateDaysBetween(order.startDateTime, order.endDateTime) + " " + intl.formatMessage({ id: ResourceAssistance.Message.days })
							: 1 + " " + intl.formatMessage({ id: ResourceAssistance.Message.days }),
					totalQty: Utils.formatNumWithComma(Utils.calculateDoctorOrderQty(order)) + " " + order.unit,
				}
			}),

			signatureDate: `${Utils.formatDateTime(new Date().getTime())}`,
			signatureTitles: [intl.formatMessage({ id: ResourceAssistance.Hospitel.doctor })],
		}
	}

	static generateNurseOrderReportData = (patient, orders) => {
		let intl = super.getIntl()
		return {
			identificationIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			patientNameIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			ageIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			dobIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			dateIntl: intl.formatMessage({ id: ResourceAssistance.Message.date }),
			timeIntl: intl.formatMessage({ id: ResourceAssistance.Message.time }),
			orderIntl: intl.formatMessage({ id: ResourceAssistance.Message.order }),

			titleIntl: intl.formatMessage({ id: ResourceAssistance.Message.nurseOrder }),
			title: "Nurse Order",

			pid: patient.id,
			identification: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),

			orders: orders,

			signatureDate: `${Utils.formatDateTime(new Date().getTime())}`,
			signatureTitles: [intl.formatMessage({ id: ResourceAssistance.Hospitel.nurse })],
		}
	}

	static generateNurseNoteReportData = (patient, nurseNotes) => {
		let intl = super.getIntl()
		return {
			identificationIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			patientNameIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			ageIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			dobIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),
			dateIntl: intl.formatMessage({ id: ResourceAssistance.Message.date }),
			timeIntl: intl.formatMessage({ id: ResourceAssistance.Message.time }),
			focusIntl: intl.formatMessage({ id: ResourceAssistance.Message.focus }),
			progressNoteIntl: intl.formatMessage({ id: ResourceAssistance.Message.note }),

			titleIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.nurseProgressNote }),
			title: "Nurse Progress Note",

			pid: patient.id,
			identification: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),

			nurseNotes: nurseNotes,
		}
	}

	static generateVitalSignReportData = (patient, vitalSigns) => {
		let intl = super.getIntl()
		return {
			identificationIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.identification }),
			patientNameIntl: intl.formatMessage({ id: ResourceAssistance.Message.patientName }),
			ageIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.age }),
			dobIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.dateOfBirth }),

			titleIntl: intl.formatMessage({ id: ResourceAssistance.Hospitel.vitalSign }),
			title: "Vital Sign",

			pid: patient.id,
			identification: patient.identificationNumber,
			patientName: patient.title + " " + patient.firstName + " " + patient.lastName,
			age: Utils.calculateAge(patient.dobtime),
			dob: Utils.formatDate(patient.dobtime),

			vitalSigns: vitalSigns,
		}
	}
}

export default OPDPrintableDataFactory
