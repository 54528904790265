const Warning = {
	appointmentDelete: "Delete OPD Appointment.",
	approvedByAnotherOrderWarning: "Item has been already approved by another order.",
	canNotChangeAfterSaved: "Cannot be changed after saved!",
	emptyCancelReason: "Reason can not be empty for the cancelling item.",
	emptyDisapproveReasonWarning: "The disapproved reason is empty! The disapproved reason cannot be changed after disapproved.",
	emptyNoteWarning: "The note is empty! The note cannot be changed after approved.",
	forAnyComplications: "For any complications that might occurred or any changes in diagnosis or treatment, the actual cost will be reassessed.",
	inCaseRefundPatient:"In case that the patient is unable to perform the surgery, the hospital will refund the deposit back by transfer within 7 working days.",
	newItemWarning: "The Item has been approved already!",
	opdCheckOut: "Medical Consultation Complete.",
	pharmacyCannotMeetDoctorOrder: "The order is out of stock.",
	smartCardReaderIsNotWorking: "ID Smart Card Reader is not working! \n Note* (Make sure the SMC Reader app is running.)",
	taxedTotalPriceDoesNotMatch: "Total price does not match total price of all items.",
	theDoctorOrderIsPrescriptionOnlyNotIncludeInBilling: "The Doctor Order is Prescription Only and Will Not Include in Billing.",
	warning: "Warning!",
	warningAdjustmentBillingPlanCannotModify: "Please Change Billing Plan Adjustments Before Modifying the Billing Plan!",
	warningApproveTransfer: "Approve the Transfer for Transferring Stock Items.",
	warningApproveTransferRequest: "Approve the Request for Transferring Stock Items.",
	warningAuthorizationFailed: "Authorization Failed!",
	warningAuthorizationSameLoginUser: "The Currently Logged In User is Not Allowed to Operate! Please Log In as Another User to Allow This.",
	warningCreateBillingInvoice: "This will create an Invoice.",
	warningCreateBillingReceipt: "This will create a Receipt.",
	warningCreateBillingReceiptAndInvoice: "This will create a Receipt and an Invoice.",
	warningDischargePatient: "Discharge Patient!",
	warningFileMustSmallerThan: "The File Must be Smaller Than ",
	warningFileUploadFailed: "File Uploaded Failed.",
	warningFileUploadSuccess: "File Uploaded Successfully.",
	warningMedicationCanBeUsedMultipleTimes: "The Medication Can be Used Multiple Times.",
	warningOrderTimeInteractWithAnotherOrder: "The Order's Time is Interacting With Another Order.",
	warningPermissionDenied: "Permission Denied!",
	warningSavingWithholdingTaxCertificate: "Saving Withholding Tax Certificate...",
	warningSavingWithholdingTaxCertificateFailed: "Saving Withholding Tax Certificate Failed!",
	warningSavingWithholdingTaxCertificateSuccess: "Saving Withholding Tax Certificate Success!",
	warningTheDoctorOrderIsNotPrescriptionOnlyIncludeInBilling: "The Doctor Order is Not Prescription Only and Will Include in Billing.",
	warningUploadPDFOnly: "Please Upload PDF File!",
	warningWrongFrequency: "The Prescription's Frequency is Wrong!",
	warningWrongUnit: "The Unit of Measure is Wrong!",
	wrongAmount: "The amount must be a multiple of quantity per order.",
	wrongTotalPrice: "Taxed total price is not calculated correctly based on Price/Unit.",
	wrongTransferAmount: "The transfer amount must be less than or equal to current amount.",
	wrongTransferAmountGreaterThanRequested: "Cannot transfer more than requested amount.",
}

export default Warning
