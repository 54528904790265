import { Select } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected } from "~/redux/action"
import { SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalMedicalCertificateDoctorRow extends React.Component {
	constructor(props) {
		super(props)

		this.onUser = this.onUser.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedCertificate.certificate) {
			let userIdx = this.props.opd.doctors.findIndex(
				(doctor) =>
					_.isEqual(doctor.displayName, this.props.opd.selectedCertificate.certificate.doctorName) &&
					_.isEqual(doctor.licenseNum, this.props.opd.selectedCertificate.certificate.doctorLicense)
			)
			if (userIdx > -1) {
				this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER, userIdx, this.props.opd.doctors[userIdx])
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER, -1, null)
	}

	onUser(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_MEDICAL_CERTIFICATE_SELECTED_USER, v, this.props.opd.doctors[v])
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.doctor)}</legend>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ flexGrow: 1, textAlign: "center" }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.name)}
							status={this.props.opd.modalCertificate.selectedUser.user ? undefined : "error"}
							value={this.props.opd.modalCertificate.selectedUser.user ? this.props.opd.modalCertificate.selectedUser.index : undefined}
							onChange={this.onUser}
						>
							{Utils.renderSelects(this.props.opd.doctors, false)}
						</Select>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificateDoctorRow))
