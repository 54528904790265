import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import { FcDeployment, FcDonate, FcShipped, FcSurvey } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { ResourceAssistance, translate } from "~/i18n"
import { IconFont } from "~/icons"
import { Utils } from "~/utils/Utils"
import { EType } from "./../Home"

class Management extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row style={{ justifyContent: "center" }}>
					<Col md="auto">
						<h2 style={{ marginBottom: "0.5rem" }}>{translate(ResourceAssistance.Message.stockAndPurchasing)}</h2>
					</Col>
				</Row>
				<Row style={{ justifyContent: "center" }}>
					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Inventory.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.INV} onClick={this.props.onBtnClick}>
								<IconFont type={"icon-ali-pmgcare-kucuntongji"} style={{ fontSize: ResourceAssistance.ReactIcon.size_md, marginRight: "2px" }} />
								{translate(ResourceAssistance.Message.inventory)}
							</Button>
						</Col>
					)}

					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Transfer.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.TF} onClick={this.props.onBtnClick}>
								<FcDeployment size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.transfer)}
							</Button>
						</Col>
					)}

					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.PR} onClick={this.props.onBtnClick}>
								<FcSurvey size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.purchaseRequest)}
							</Button>
						</Col>
					)}

					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.PO} onClick={this.props.onBtnClick}>
								<FcDonate size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.purchaseOrder)}
							</Button>
						</Col>
					)}

					{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Rc.View, this.props.login.user.roles) && (
						<Col sm="auto">
							<Button variant={ResourceAssistance.Button.variant.link} value={EType.RC} onClick={this.props.onBtnClick}>
								<FcShipped size={ResourceAssistance.ReactIcon.size_md} />
								{translate(ResourceAssistance.Message.receive)}
							</Button>
						</Col>
					)}
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
})

export default injectIntl(connect(mapStateToProps, {})(Management))
