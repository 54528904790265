import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import GInput from "~/view/component/input/GInput"
import { setValue } from "~/redux/action"
import {
	SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_BP,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_HR,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_TO,
	SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER,
} from "~/redux/type"

export const ROxygen = {
	None: "N",
	Oxygen_Canula: "OC",
	Oxygen_Mask_With_Bag: "OMWB",
	"T-Piece": "TP",
	Ambulatory_Bag: "AB",
}
export const RAirwaySupport = {
	None: "N",
	Retained_ET_Tube: "RET",
	Tracheostomy: "T",
	"Oral/Nasal Airway": "ONA",
}
export const RRespiration = {
	Spontaneous: "S",
	Assisted: "A",
	Controlled: "C",
}
export const RConsciousness = {
	Alert: "A",
	Arousable_On_Stimulation: "AOS",
	"Non-Responding": "NR",
}
export const RTransferTo = {
	PACU: "P",
	ICU: "I",
	Ward: "W",
	Other: "Ot",
}

class OperatingRoomModalIntraAneTransferDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onBP = this.onBP.bind(this)
		this.onHR = this.onHR.bind(this)
		this.onSpO2 = this.onSpO2.bind(this)
		this.onOxygen = this.onOxygen.bind(this)
		this.onAirwaySupport = this.onAirwaySupport.bind(this)
		this.onRespiration = this.onRespiration.bind(this)
		this.onConsciousness = this.onConsciousness.bind(this)
		this.onTransferTo = this.onTransferTo.bind(this)
		this.onTransferToOther = this.onTransferToOther.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_BP, op.transferBP)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_HR, op.transferHR)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2, op.transferSpo2)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN, op.oxygen)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT, op.airwaySupport)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION, op.transferRespiration)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS, op.transferConsciousness)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_TO, op.transferTo)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER, op.transferToOther)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_BP, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_HR, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_TO, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.conditionBeforeTransfer)}:</label>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.bpmmHg)}
							suffix={ResourceAssistance.CONSTANT.MMHG}
							value={this.props.operatingRoom.modalIntraAneTransfer.bp}
							onChange={this.onBP}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.HRbpm)}
							suffix={ResourceAssistance.CONSTANT.BPM}
							value={this.props.operatingRoom.modalIntraAneTransfer.hr}
							onChange={this.onHR}
						/>
					</Col>
					<Col>
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.SpO2)}
							suffix={"%"}
							value={this.props.operatingRoom.modalIntraAneTransfer.SpO2}
							onChange={this.onSpO2}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.oxygen)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTransfer.oxygen} onChange={this.onOxygen}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={ROxygen.None}>
								{translate(ResourceAssistance.Message.none)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={ROxygen.Oxygen_Canula}>
								{translate(ResourceAssistance.Message.oxygenCanula)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={ROxygen.Oxygen_Mask_With_Bag}>
								{translate(ResourceAssistance.Message.oxygenMaskWithBag)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={ROxygen["T-Piece"]}>
								{translate(ResourceAssistance.Message.tPiece)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={ROxygen.Ambulatory_Bag}>
								{translate(ResourceAssistance.Message.ambulatoryBag)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.airwaySupport)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTransfer.airwaySupport} onChange={this.onAirwaySupport}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAirwaySupport.None}>
								{translate(ResourceAssistance.Message.none)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAirwaySupport.Retained_ET_Tube}>
								{translate(ResourceAssistance.Message.retainedETTube)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAirwaySupport.Tracheostomy}>
								{translate(ResourceAssistance.Message.tracheostomy)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RAirwaySupport["Oral/Nasal Airway"]}>
								{translate(ResourceAssistance.Message.oralNasalAirway)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.respiration)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTransfer.respiration} onChange={this.onRespiration}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RRespiration.Spontaneous}>
								{translate(ResourceAssistance.Message.spontaneous)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RRespiration.Assisted}>
								{translate(ResourceAssistance.Message.assisted)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RRespiration.Controlled}>
								{translate(ResourceAssistance.Message.controlled)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.consciousness)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTransfer.consciousness} onChange={this.onConsciousness}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RConsciousness.Alert}>
								{translate(ResourceAssistance.Message.alert)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RConsciousness.Arousable_On_Stimulation}>
								{translate(ResourceAssistance.Message.arousableOnSimulation)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RConsciousness["Non-Responding"]}>
								{translate(ResourceAssistance.Message.nonResponding)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
				<Row>
					<Col md={2}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.transferTo)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneTransfer.transferTo} onChange={this.onTransferTo}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RTransferTo.PACU}>
								{translate(ResourceAssistance.Message.PACU)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RTransferTo.ICU}>
								{translate(ResourceAssistance.Message.ICU)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RTransferTo.Ward}>
								{translate(ResourceAssistance.Message.ward)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RTransferTo.Other}>
								{translate(ResourceAssistance.Message.other)}
							</Radio>
						</Radio.Group>
					</Col>
					<Col>
						<GInput
							autoSize
							required
							styles={{ input: { textAlign: "center" } }}
							minRows={1}
							placeholder=""
							disabled={!_.isEqual(this.props.operatingRoom.modalIntraAneTransfer.transferTo, RTransferTo.Other)}
							value={this.props.operatingRoom.modalIntraAneTransfer.transferToOther}
							onChange={this.onTransferToOther}
						/>
					</Col>
				</Row>
			</Container>
		)
	}
	onBP(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_BP, e.target.value)
	}
	onHR(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_HR, e.target.value)
	}
	onSpO2(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_SPO2, e.target.value)
	}
	onOxygen(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_OXYGEN, e.target.value)
	}
	onAirwaySupport(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_AIRWAY_SUPPORT, e.target.value)
	}
	onRespiration(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_RESPIRATION, e.target.value)
	}
	onConsciousness(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_CONSCIOUSNESS, e.target.value)
	}
	onTransferTo(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_TO, e.target.value)
	}
	onTransferToOther(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_TRANSFER_TO_OTHER, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneTransferDetails)
