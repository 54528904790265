import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setOPDIntakeOutputs } from "~/redux/action"
import OutpatientRightIntakeOutputChart from "./OutpatientRightIntakeOutputChart"
import OutpatientRightIntakeOutputTable from "./OutpatientRightIntakeOutputTable"

class OutpatientRightIntakeOutput extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OutpatientRightIntakeOutput",
		}
	}

	componentDidMount() {
		if (this.props.opd.selectedMedicalRecord.medicalRecord) {
			this.props.setOPDIntakeOutputs(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.intakeOutputs)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.selectedMedicalRecord.medicalRecord, this.props.opd.selectedMedicalRecord.medicalRecord)) {
			if (this.props.opd.selectedMedicalRecord.medicalRecord) {
				this.props.setOPDIntakeOutputs(this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.intakeOutputs)
			} else {
				this.props.setOPDIntakeOutputs([])
			}
		}
	}

	onGraphic(event) {
		this.setState({
			graphic: !this.state.graphic,
		})
	}

	render() {
		return this.props.graphic ? <OutpatientRightIntakeOutputChart /> : <OutpatientRightIntakeOutputTable />
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setOPDIntakeOutputs,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientRightIntakeOutput))
