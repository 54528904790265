import {
	SET_REPORT_INV_BY_LOCATION_BRANCHES,
	SET_REPORT_INV_BY_LOCATION_CODE,
	SET_REPORT_INV_BY_LOCATION_DESCRIPTION,
	SET_REPORT_INV_BY_LOCATION_ORGS,
	SET_REPORT_INV_BY_LOCATION_SELECTED_BRANCH,
	SET_REPORT_INV_BY_LOCATION_SELECTED_ORG,
	SET_REPORT_INV_BY_LOCATION_VIEW_QTY,
} from "../../type"

const init = {
	reportByLocation: {
		isQty: true,
		code: "",
		description: "",
		orgs: [],
		branches: [],
		selectedOrg: {
			index: -1,
			org: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
	},
}

const reportInvReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_INV_BY_LOCATION_ORGS:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					orgs: action.payload.orgs,
				},
			})

		case SET_REPORT_INV_BY_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.org,
					},
				},
			})

		case SET_REPORT_INV_BY_LOCATION_BRANCHES:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					branches: action.payload.branches,
				},
			})

		case SET_REPORT_INV_BY_LOCATION_SELECTED_BRANCH:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.branch,
					},
				},
			})

		case SET_REPORT_INV_BY_LOCATION_CODE:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					code: action.payload.code,
				},
			})

		case SET_REPORT_INV_BY_LOCATION_DESCRIPTION:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					description: action.payload.description,
				},
			})

		case SET_REPORT_INV_BY_LOCATION_VIEW_QTY:
			return Object.assign({}, state, {
				reportByLocation: {
					...state.reportByLocation,
					isQty: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default reportInvReducer
