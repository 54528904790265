import { Checkbox, Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION, SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION } from "~/redux/type"

export const CBPosition = {
	Supine: "S",
	LUD: "L",
	Lateral_Right: "LR",
	Lateral_Left: "LL",
	Prone: "P",
	Semilateral: "Se",
	Lithotomy: "Li",
	Sitting: "Si",
	Jackknife: "J",
	Trendelenburg: "T",
	Reversed_Trend: "R",
}
export const RRespiration = {
	Spontaneous: "S",
	Assisted: "A",
	Continuous: "C",
}

class OperatingRoomModalIntraAnePatientConditionDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onPositionDetail = this.onPositionDetail.bind(this)
		this.onRespiration = this.onRespiration.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION, op.position.split(","))
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION, op.respiration)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION, [])
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md={1}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.position)}:</label>
					</Col>
					<Col>
						<Checkbox.Group
							options={[
								{
									label: translate(ResourceAssistance.Message.supine),
									value: CBPosition.Supine,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.LUD),
									value: CBPosition.LUD,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.lateralRight),
									value: CBPosition.Lateral_Right,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.lateralLeft),
									value: CBPosition.Lateral_Left,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.prone),
									value: CBPosition.Prone,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.semilateral),
									value: CBPosition.Semilateral,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.lithotomy),
									value: CBPosition.Lithotomy,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.sitting),
									value: CBPosition.Sitting,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.jackknife),
									value: CBPosition.Jackknife,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.trendelenburg),
									value: CBPosition.Trendelenburg,
									style: { flexDirection: "row", alignItems: "center" },
								},
								{
									label: translate(ResourceAssistance.Message.reverseTrend),
									value: CBPosition.Reversed_Trend,
									style: { flexDirection: "row", alignItems: "center" },
								},
							]}
							value={this.props.operatingRoom.modalIntraAnePatientCondition.positionDetail}
							onChange={this.onPositionDetail}
						/>
					</Col>
				</Row>
				<Row style={{ marginTop: "10px" }}>
					<Col md={1}>
						<label style={{ fontWeight: "bold" }}>{translate(ResourceAssistance.Message.respiration)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAnePatientCondition.respiration} onChange={this.onRespiration}>
							<Radio style={{ flexDirection: "row" }} value={RRespiration.Spontaneous}>
								{translate(ResourceAssistance.Message.spontaneous)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRespiration.Assisted}>
								{translate(ResourceAssistance.Message.assisted)}
							</Radio>
							<Radio style={{ flexDirection: "row" }} value={RRespiration.Continuous}>
								{translate(ResourceAssistance.Message.continuous)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
			</Container>
		)
	}
	onPositionDetail(values) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_POSITION, values)
	}
	onRespiration(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_PATIENT_CONDITION_RESPIRATION, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAnePatientConditionDetails)
