import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setPoOrders, setPoSelectedPo, setPoSelectedSupplier } from "~/redux/action"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class POLeftFourthRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "POLeftFourthRow",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		// let marginBottom = parseFloat(Utils.getComputedStyle(document.getElementById("POLeftFourthRow")).marginBottom)
		// let computeHeight = document.getElementById("POLeftFourthRow").clientHeight - marginBottom
		// let table = Array.prototype.find.call(
		// 	document.getElementById("POLeftFourthRow").getElementsByTagName("div"),
		// 	(element) => {
		// 		console.log(element)
		// 		return element.id === "ScrollableTable"
		// 	}
		// )
		// table.style.maxHeight = computeHeight + ResourceAssistance.CONSTANT.PX
	}

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevProps.order.filtered) !== JSON.stringify(this.props.order.filtered)) {
			let index = this.props.selectedPo.po ? this.props.order.filtered.findIndex((order) => order.id === this.props.selectedPo.po.id) : -1
			if (index !== -1) {
				this.props.setPoSelectedPo(index, this.props.order.filtered[index])
			} else {
				this.props.setPoSelectedPo(-1, null)
			}
		}

		if (prevProps.isDisplayAll !== this.props.isDisplayAll) {
			this.props.setPoOrders(this.props.order.original)
		}
	}

	onSelectRow(row, index) {
		let selectedPo = this.props.order.filtered[index]
		this.props.setPoSelectedPo(index, selectedPo)
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.order}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.selectedPo.index === -1}
						highlightedRow={this.props.selectedPo.index}
					/>
					{this.props.order.isLoading && <Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplayAll: state.purchaseOrder.isDisplayAll,
	order: state.purchaseOrder.order,
	selectedType: state.purchaseOrder.selectedType,
	selectedPo: state.purchaseOrder.selectedPo,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction, setPoOrders, setPoSelectedPo, setPoSelectedSupplier }, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(POLeftFourthRow)
