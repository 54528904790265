import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import Fieldset from "~/report/component/Fieldset"

class ReportWithholdingTaxCertificateDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={[ResourceAssistance.Report.styles.document]}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page, { fontSize: this.state.data.isDisplayEn ? 12 : 13 }]}
					>
						<View style={{ border: "1 solid 0", paddingHorizontal: 3 }}>
							{/* Title */}
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 18, fontStyle: "bold" }]}>
								<View style={ResourceAssistance.Report.styles.colTextCenter}>
									<Text>{this.state.data.titleIntl}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col]} />
								<View style={[ResourceAssistance.Report.styles.colTextCenter]}>
									<Text>{this.state.data.subTitleIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<Text>
										{this.state.data.numberIntl}
										{this.state.data.number}
									</Text>
								</View>
							</View>
							{/* Tax withholder */}
							<Fieldset
								legend={this.state.data.taxWithholderIntl}
								content={
									<>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
												<Text>{this.state.data.nameIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.taxWithholderName}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
												<Text>
													{this.state.data.identificationIntl}: {this.state.data.taxWithholderIdentification}
												</Text>
												<Text>
													{this.state.data.taxIdIntl}: {this.state.data.taxWithholderTaxId}
												</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
												<Text>{this.state.data.addressIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.taxWithholderAddress}</Text>
											</View>
										</View>
									</>
								}
							/>

							{/* Tax Payer */}
							<Fieldset
								legend={this.state.data.taxPayerIntl}
								content={
									<>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
												<Text>{this.state.data.nameIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.taxPayerName}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
												<Text>
													{this.state.data.identificationIntl}: {this.state.data.taxPayerIdentification}
												</Text>
												<Text>
													{this.state.data.taxIdIntl}: {this.state.data.taxPayerTaxId}
												</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
												<Text>{this.state.data.addressIntl}:</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.taxPayerAddress}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND1a ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.pnd1aIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND1aEx ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.pnd1aExIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND2 ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.pnd2Intl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND2a ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.pnd2aIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND3 ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.pnd3Intl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND3a ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col]}>
												<Text>{this.state.data.pnd3aIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text style={[ResourceAssistance.Report.styles.textIcon]}>
													{this.state.data.isPND53 ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
												</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto]}>
												<Text>{this.state.data.pnd53Intl}</Text>
											</View>
										</View>
									</>
								}
							/>
							{/* Type of Income Paid */}
							<View style={[{ border: "1 solid 0", paddingBottom: 3, marginTop: 3 }]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.typeOfIncomePaid]}>
										<Text style={[ResourceAssistance.Report.styles.textBold]}>{this.state.data.typeOfIncomePaidIntl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.dateIntl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount, { textAlign: "center" }]}>
										<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountInt}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax, { textAlign: "center", lineHeight: 0.9 }]}>
										{this.state.data.isDisplayEn ? (
											<>
												<Text style={ResourceAssistance.Report.styles.textBold}>Tax</Text>
												<Text style={ResourceAssistance.Report.styles.textBold}>Withheld</Text>
											</>
										) : (
											<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.taxWithheldIntl}</Text>
										)}
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.horizontalLine, { marginBottom: 0 }]} />
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>1.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section401Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section401Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section401Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section401WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section402Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section402Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section402Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section402WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>3.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section403Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section403Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section403Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section403WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>4.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404aIntl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404aDate}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404aAmount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404aWithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404bIntl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404bDate}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404bAmount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404bWithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>1.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b1Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b1Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b1Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b1WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>1.1</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b11Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b11Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b11Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b11WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>1.2</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b12Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b12Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b12Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b12WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>1.3</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b13Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b13Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b13Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b13WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>1.4</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b14Intl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}>
										<Text>{this.state.data.section404b14Other}%</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b14Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b14Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b14WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b2Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b2Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b2Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b2WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.1</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b21Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b21Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b21Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b21WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.2</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b22Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b22Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b22Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b22WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.3</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b23Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b23Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b23Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b23WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.4</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section404b24Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b24Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b24Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b24WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}></View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>2.5</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.otherIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}>
										<Text>{this.state.data.section404b25Other}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section404b25Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section404b25Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section404b25WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row, { lineHeight: 0.8 }]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>5.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.section5Intl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section5Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section5Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section5WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.section]}>
										<Text>6.</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.typeOfIncomePaid]}>
										<Text>{this.state.data.otherIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}>
										<Text>{this.state.data.section6Other}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
										<Text>{this.state.data.section6Date}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
										<Text>{this.state.data.section6Amount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.withholdingTax]}>
										<Text>{this.state.data.section6WithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.horizontalLine, { marginBottom: 0 }]} />

								{/* Total */}
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.grandTotal]}>
										<Text>{this.state.data.totalAmountIncomeAndWithholdingTaxIntl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.amount]}>
										<Text>{this.state.data.totalAmount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.withholdingTax]}>
										<Text>{this.state.data.totalWithholdingTax}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto]}>
										<Text>{this.state.data.grandTotalLettersIntl}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colTextCenter, { border: "1 solid 0", marginHorizontal: 3 }]}>
										<Text>{this.state.data.grandTotalLetters}</Text>
									</View>
								</View>
							</View>

							{/* Fund */}
							<View style={[{ border: "1 solid 0", paddingBottom: 3, marginTop: 3 }]}>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
										<Text>{this.state.data.fundIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}>
										<Text>{this.state.data.fundAmount}</Text>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.row]}>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
										<Text>{this.state.data.socialSecurityFundIntl}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}>
										<Text>{this.state.data.socialSecurityFundAmount}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
										<Text>{this.state.data.providentFundInt}:</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}>
										<Text>{this.state.data.providentFundAmount}</Text>
									</View>
								</View>
							</View>

							{/* Signature */}
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<Fieldset
										legend={this.state.data.payerIntl}
										content={
											<>
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text style={[ResourceAssistance.Report.styles.textIcon]}>
															{this.state.data.isWithholdAtSource ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
														</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text>{this.state.data.withholdAtSourceIntl}</Text>
													</View>
												</View>
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text style={[ResourceAssistance.Report.styles.textIcon]}>
															{this.state.data.isPayEveryTime ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
														</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text>{this.state.data.payEveryTimeIntl}</Text>
													</View>
												</View>
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text style={[ResourceAssistance.Report.styles.textIcon]}>
															{this.state.data.isPayOneTime ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
														</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text>{this.state.data.payOneTimeIntl}</Text>
													</View>
												</View>
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text style={[ResourceAssistance.Report.styles.textIcon]}>
															{this.state.data.isTaxPayerOther ? ResourceAssistance.Symbol.Report.checkBox : ResourceAssistance.Symbol.Report.emptyCheckBox}
														</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colAuto]}>
														<Text>{this.state.data.otherIntl}</Text>
													</View>
												</View>
											</>
										}
									/>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, { border: "1 solid 0", marginTop: 7, paddingHorizontal: 3, justifyContent: "flex-start" }]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.col]}>
											<Text style={[{ fontStyle: "bold-italic" }]}>*{this.state.data.certifyAboveIntl}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
											<Text>{this.state.data.taxWithholderIntl}:</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "left", borderBottom: 1, borderStyle: "dotted" }]}></View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.labelHeader]}>
											<Text>{this.state.data.dateCertificateIssuedIntl}:</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, { marginLeft: 3, textAlign: "center", borderBottom: 1, borderStyle: "dotted" }]}>
											<Text>{this.state.data.issuedDateTime}</Text>
										</View>
									</View>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, { border: "1 solid 0", marginTop: 7, paddingHorizontal: 3, justifyContent: "center" }]}>
									<Text style={[{ textAlign: "center" }]}>{this.state.data.stampIntl}</Text>
								</View>
							</View>

							{/* Warning */}
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text style={[{ fontStyle: "bold-italic" }]}>* {this.state.data.warningIntl}</Text>
								</View>
							</View>
						</View>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		// paddingBottom: 180,
		paddingTop: 20,
		paddingBottom: 5,
	},
	labelHeader: {
		marginRight: 3,
	},
	labelBody: {
		fontStyle: "italic",
	},
	section: {
		minWidth: "2.2%",
		maxWidth: "2.2%",
	},
	typeOfIncomePaid: {},
	date: {
		borderLeft: "1 solid 0",
		minWidth: "9%",
		maxWidth: "9%",
	},
	amount: {
		borderLeft: "1 solid 0",
		textAlign: "right",
		minWidth: "9%",
		maxWidth: "9%",
	},
	withholdingTax: {
		borderLeft: "1 solid 0",
		textAlign: "right",
		minWidth: "8.1%",
		maxWidth: "8.1%",
	},
	grandTotal: {
		textAlign: "right",
		fontStyle: "bold",
		paddingRight: 3,
	},
})

export default ReportWithholdingTaxCertificateDoc
