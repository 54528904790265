class WithholdingTaxCertificateUtils {
	static convertHttpRequestKeys = (key) => {
		return {
			dateTime: "_" + key.replaceAll("-", "_") + "DateTime",
			amount: "_" + key.replaceAll("-", "_") + "Amount",
			withholdingTax: "_" + key.replaceAll("-", "_") + "WithholdingTax",
			other: "_" + key.replaceAll("-", "_") + "Other",
		}
	}
}

export { WithholdingTaxCertificateUtils }
