const PostAnesthesiaRecord = {
	"20%OfPreoperation": "\u00B120% of preoperation",
	"20%To40%OfPreoperation": "\u00B120% - 40% of preoperation",
	"BP&Pulse": "BP & Pulse",
	"greaterThan40%OfPreoperation": "\u003E40% of preoperation",
	aldreteScoreAndOrModifiedPADSS: "Aldrete score or modified PADSS <=8, notify the doctor.",
	ambulateWithAssistance: "Ambulate with assistance",
	complicationInPACU: "Complication(s) in PACU",
	continuteAfterRepeatedTreatment: "Continute after repeated treatment",
	controlledWithOralAnalgesicsAndAcceptableToPatient: "Controlled with oral analgesics and acceptable to patient",
	minimalBleeding: "Minimal bleeding",
	minimalTreatedWithOralMedication: "Minimal treated with oral medication",
	moderateBleeding: "Moderate bleeding",
	moderateTreatedWithIVMedication: "Moderate treated with IV medication",
	modifiedPADSSBeforeDischargeToHome: "Modified PADSS(before discharge to home)",
	painNotAcceptableToPatient: "Pain not acceptable to patient",
	sensationLevelIsLowerThanT10Level: "Sensation level is lower than T10 level, notify the doctor. (Only regional anesthesia case)",
	severeBleeding: "Severe bleeding",
	steadyGaitNoDizziness: "Steady gait no dizziness",
	unableToAmbulate: "Unable to ambulate",
}

export default PostAnesthesiaRecord
