import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_ARC_MODAL_SEARCH_RECEIPT_ID } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class ARCModalSearchDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onReceiptId = this.onReceiptId.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_ARC_MODAL_SEARCH_RECEIPT_ID, "")
	}

	onReceiptId(e) {
		this.props.setValue(SET_ARC_MODAL_SEARCH_RECEIPT_ID, Utils.trim(e.target.value))
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col md={2} />
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.id })}
							type={ResourceAssistance.FormControl.type.number}
							pattern="^\d+$"
							onChange={this.onReceiptId}
							value={this.props.ARC.modalSearch.searchReceiptId}
						/>
					</Col>
					<Col md={2} />
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	ARC: state.accounting.accountsReceive,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ARCModalSearchDetails))
