import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PaymentSlipLeftDate from "./PaymentSlipLeftDate"
import PaymentSlipLeftAction from "./PaymentSlipLeftAction"
import PaymentSlipLeftFilter from "./PaymentSlipLeftFilter"
import PaymentSlipLeftRequest from "./PaymentSlipLeftRequest"

class PaymentSlipLeft extends React.Component {
	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<PaymentSlipLeftDate />
				<PaymentSlipLeftFilter />
				<PaymentSlipLeftAction />
				<PaymentSlipLeftRequest />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentSlipLeft)
