import { ResourceAssistance } from "~/i18n"
import moment from "moment"
import {
	SET_FRONT_INVENTORY_DISPLAY_EXPIRED,
	SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK,
	SET_FRONT_INVENTORY_DISTRIBUTIONS,
	SET_FRONT_INVENTORY_EXPIRE_DATETIME,
	SET_FRONT_INVENTORY_INVENTORIES,
	SET_FRONT_INVENTORY_INVENTORIES_LOADING,
	SET_FRONT_INVENTORY_LOTS,
	SET_FRONT_INVENTORY_MODAL_SPLIT_LOTS,
	SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT,
	SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION,
	SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS,
	SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING,
	SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS,
	SET_FRONT_INVENTORY_MODAL_WASTE_LOTS,
	SET_FRONT_INVENTORY_MODAL_WASTE_REASON,
	SET_FRONT_INVENTORY_MODAL_WASTE_SELECTED_LOT,
	SET_FRONT_INVENTORY_ORGS,
	SET_FRONT_INVENTORY_ORGS_LOADING,
	SET_FRONT_INVENTORY_SEARCH_CODE,
	SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME,
	SET_FRONT_INVENTORY_SELECTED_DEPARTMENT,
	SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION,
	SET_FRONT_INVENTORY_SELECTED_INVENTORY,
	SET_FRONT_INVENTORY_SELECTED_TYPE,
	SET_FRONT_INVENTORY_TYPES,
	SET_FRONT_INVENTORY_TYPES_LOADING,
	SET_FRONT_INVENTORY_WASTES,
} from "~/redux/type"

const init = {
	lotExpiredDateTime: moment().endOf("day").add(6, "month").milliseconds(0).valueOf(),
	searchCode: "",
	searchGenericName: "",
	isDisplayExpired: false,
	isDisplayMinStock: false,
	isOrgsLoading: false,
	isTypesLoading: false,
	orgs: [],
	types: [],
	selectedDepartment: {
		index: -1,
		department: null,
	},
	selectedType: {
		index: -1,
		type: null,
	},
	selectedInventory: {
		index: -1,
		inventory: null,
	},
	selectedDistribution: {
		index: -1,
		distribution: null,
	},
	inventoryTable: {
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.genericName,
			ResourceAssistance.Message.brandName,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.currentLevel,
			ResourceAssistance.Message.minLevel,
			ResourceAssistance.Message.maxLevel,
			"",
		],
		original: [],
		filtered: [],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	lotTable: {
		header: [ResourceAssistance.Message.lotNum, ResourceAssistance.Message.current, ResourceAssistance.Message.unit, ResourceAssistance.Message.expired],
		original: [],
		filtered: [],
		body: [],
		colColor: [],
	},
	distributionTable: {
		header: [ResourceAssistance.Message.lotNum, ResourceAssistance.Message.current, ResourceAssistance.Message.unit, ResourceAssistance.Message.expired, ""],
		original: [],
		filtered: [],
		body: [],
		colColor: [],
	},
	wasteTable: {
		header: [ResourceAssistance.Message.lotNum, ResourceAssistance.Message.amount, ResourceAssistance.Message.unit, ResourceAssistance.Message.reason],
		original: [],
		filtered: [],
		body: [],
		colColor: [],
	},
	modalWaste: {
		reason: "",
		items: [],
		selectedLot: {
			index: -1,
			lot: null,
		},
		lotTable: {
			header: [
				ResourceAssistance.Message.lotNum,
				ResourceAssistance.Message.current,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.expired,
				ResourceAssistance.Message.waste,
				"",
			],
			original: [],
			filtered: [],
			body: [],
			rowColor: [],
		},
	},
	modalSplit: {
		unitConversions: [],
		isUnitConversionsLoading: false,
		selectedLot: {
			index: -1,
			lot: null,
		},
		selectedUnitConversion: {
			index: -1,
			unitConversion: null,
		},
		lotTable: {
			header: [
				ResourceAssistance.Message.lotNum,
				ResourceAssistance.Message.current,
				ResourceAssistance.Message.unit,
				ResourceAssistance.Message.expired,
				ResourceAssistance.Message.split,
				ResourceAssistance.Message.amount,
				ResourceAssistance.Message.unit,
				"",
			],
			original: [],
			filtered: [],
			body: [],
			rowColor: [],
		},
	},
}

const inventoryReducer = (state = init, action) => {
	switch (action.type) {
		case SET_FRONT_INVENTORY_ORGS:
			return Object.assign({}, state, {
				orgs: action.payload.objs,
			})
		case SET_FRONT_INVENTORY_ORGS_LOADING:
			return Object.assign({}, state, {
				isOrgsLoading: action.payload.isLoading,
			})
		case SET_FRONT_INVENTORY_TYPES:
			return Object.assign({}, state, {
				types: action.payload.objs,
			})
		case SET_FRONT_INVENTORY_TYPES_LOADING:
			return Object.assign({}, state, {
				isTypesLoading: action.payload.isLoading,
			})
		case SET_FRONT_INVENTORY_SELECTED_DEPARTMENT:
			return Object.assign({}, state, {
				selectedDepartment: {
					index: action.payload.index,
					department: action.payload.selected,
				},
			})
		case SET_FRONT_INVENTORY_SELECTED_TYPE:
			return Object.assign({}, state, {
				selectedType: {
					index: action.payload.index,
					type: action.payload.selected,
				},
			})
		case SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME:
			return Object.assign({}, state, {
				searchGenericName: action.payload.value,
			})
		case SET_FRONT_INVENTORY_SEARCH_CODE:
			return Object.assign({}, state, {
				searchCode: action.payload.value,
			})
		case SET_FRONT_INVENTORY_EXPIRE_DATETIME:
			return Object.assign({}, state, {
				lotExpiredDateTime: action.payload.value,
			})
		case SET_FRONT_INVENTORY_DISPLAY_EXPIRED:
			return Object.assign({}, state, {
				isDisplayExpired: action.payload.value,
			})
		case SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK:
			return Object.assign({}, state, {
				isDisplayMinStock: action.payload.value,
			})
		case SET_FRONT_INVENTORY_INVENTORIES:
			return Object.assign({}, state, {
				inventoryTable: {
					...state.inventoryTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_FRONT_INVENTORY_INVENTORIES_LOADING:
			return Object.assign({}, state, {
				inventoryTable: {
					...state.inventoryTable,
					isLoading: action.payload.isLoading,
				},
			})
		case SET_FRONT_INVENTORY_SELECTED_INVENTORY:
			return Object.assign({}, state, {
				selectedInventory: {
					index: action.payload.index,
					inventory: action.payload.selected,
				},
			})
		case SET_FRONT_INVENTORY_LOTS:
			return Object.assign({}, state, {
				lotTable: {
					...state.lotTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colColor: action.payload.colColor,
				},
			})
		case SET_FRONT_INVENTORY_DISTRIBUTIONS:
			return Object.assign({}, state, {
				distributionTable: {
					...state.distributionTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					colColor: action.payload.colColor,
				},
			})
		case SET_FRONT_INVENTORY_WASTES:
			return Object.assign({}, state, {
				wasteTable: {
					...state.wasteTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		case SET_FRONT_INVENTORY_MODAL_WASTE_LOTS:
			return Object.assign({}, state, {
				modalWaste: {
					...state.modalWaste,
					lotTable: {
						...state.modalWaste.lotTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_FRONT_INVENTORY_MODAL_WASTE_SELECTED_LOT:
			return Object.assign({}, state, {
				modalWaste: {
					...state.modalWaste,
					selectedLot: {
						index: action.payload.index,
						lot: action.payload.selected,
					},
				},
			})
		case SET_FRONT_INVENTORY_MODAL_WASTE_REASON:
			return Object.assign({}, state, {
				modalWaste: {
					...state.modalWaste,
					reason: action.payload.value,
				},
			})
		case SET_FRONT_INVENTORY_MODAL_SPLIT_LOTS:
			return Object.assign({}, state, {
				modalSplit: {
					...state.modalSplit,
					lotTable: {
						...state.modalSplit.lotTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
					},
				},
			})
		case SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_LOT:
			return Object.assign({}, state, {
				modalSplit: {
					...state.modalSplit,
					selectedLot: {
						index: action.payload.index,
						lot: action.payload.selected,
					},
				},
			})
		case SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS:
			return Object.assign({}, state, {
				modalSplit: {
					...state.modalSplit,
					unitConversions: action.payload.value,
				},
			})
		case SET_FRONT_INVENTORY_MODAL_SPLIT_UNIT_CONVERSIONS_LOADING:
			return Object.assign({}, state, {
				modalSplit: {
					...state.modalSplit,
					isUnitConversionsLoading: action.payload.isLoading,
				},
			})
		case SET_FRONT_INVENTORY_MODAL_SPLIT_SELECTED_UNIT_CONVERSION:
			return Object.assign({}, state, {
				modalSplit: {
					...state.modalSplit,
					selectedUnitConversion: {
						index: action.payload.index,
						unitConversion: action.payload.selected,
					},
				},
			})
		case SET_FRONT_INVENTORY_MODAL_WASTE_ITEMS:
			return Object.assign({}, state, {
				modalWaste: {
					...state.modalWaste,
					items: action.payload.value,
				},
			})
		case SET_FRONT_INVENTORY_SELECTED_DISTRIBUTION:
			return Object.assign({}, state, {
				selectedDistribution: {
					index: action.payload.index,
					distribution: action.payload.selected,
				},
			})
		default:
			return state
	}
}

export default inventoryReducer
