import { Card, Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setPVPurchases,
	setPaymentVouchers,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_PV_PURCHASE_DISPLAY, SET_PV_SELECTED_PURCHASE, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PaymentVoucherRightPurchase extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectRow = this.onSelectRow.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ACCOUNTING.paymentVoucher.deletePurchase)) {
				this.deletePurchases()
			}
		}
		if (!_.isEqual(prevProps.PV.selectedPV, this.props.PV.selectedPV)) {
			if (this.props.PV.selectedPV.PV) {
				this.props.setPVPurchases(this.props.PV.selectedPV.PV.purchases)
			} else {
				this.props.setPVPurchases([])
			}
		}
		if (!_.isEqual(prevProps.PV.purchaseTable.filtered, this.props.PV.purchaseTable.filtered)) {
			let index = this.props.PV.selectedPurchase.purchase
				? this.props.PV.purchaseTable.filtered.findIndex((each) => each.id === this.props.PV.selectedPurchase.purchase.id)
				: -1
			if (index !== -1) {
				this.props.setSelected(SET_PV_SELECTED_PURCHASE, index, this.props.PV.purchaseTable.filtered[index])
			} else {
				this.props.setSelected(SET_PV_SELECTED_PURCHASE, -1, null)
			}
		}
	}

	deletePurchases() {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.paymentVoucher.deletePurchases,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.PV.location.id,
				paymentVoucherId: this.props.PV.selectedPV.PV.id,
				userId: this.props.login.user.id,
				invoices: [
					{
						id: this.props.PV.selectedPV.PV.id,
						purchases: [
							{
								id: this.props.PV.selectedPurchase.purchase.id,
							},
						],
					},
				],
			},
		}
		let callback = (res) => {
			let invoices = this.props.PV.paymentVoucherTable.original.filter((PV) => PV.id !== this.props.PV.selectedPV.PV.id)
			if (res.data.invoices.length > 0) {
				invoices.push(res.data.invoices[0])
			}
			this.props.setPaymentVouchers(invoices)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	isDisplayBtn() {
		return this.props.PV.selectedPV.PV && !this.props.PV.selectedPV.PV.complete
	}

	onSelectRow(plan, index) {
		this.props.setSelected(SET_PV_SELECTED_PURCHASE, index, this.props.PV.purchaseTable.filtered[index])
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_PV_PURCHASE_DISPLAY, true)
	}

	onDelete(e, row, rIdx, cIdx) {
		let selectedPurchase = this.props.PV.purchaseTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ACCOUNTING.paymentVoucher.deletePurchase)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.smd)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{selectedPurchase.accountingCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
						{selectedPurchase.accountingChartName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
						{selectedPurchase.accountingTransDescr}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.withholdingTax)} span={1}>
						{selectedPurchase.withholdingTaxCode + "-" + selectedPurchase.withholdingTaxName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.code)} span={1}>
						{selectedPurchase.withholdingTaxAccountingCode}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
						{selectedPurchase.withholdingTaxAccountingChartName}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.taxRate)} span={1}>
						{selectedPurchase.tax}%
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.debit)} span={1}>
						{Utils.BigNumber(selectedPurchase.taxedTotalPrice).toFixed(2)}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		let total = this.props.PV.purchaseTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[10]))
		}, Utils.BigNumber(0))
		return (
			<Row className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
						btnMessages={[
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
							this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
						]}
						btnIcons={[
							this.isDisplayBtn() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined,
							this.isDisplayBtn() ? <MdDeleteForever size={ResourceAssistance.ReactIcon.size} /> : undefined,
						]}
						onCheckedArray={[this.onEdit, this.onDelete]}
						data={this.props.PV.purchaseTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.PV.selectedPurchase.index === -1}
						highlightedRow={this.props.PV.selectedPurchase.index}
						extra={
							!_.isEmpty(this.props.PV.purchaseTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)}>
												{Utils.formatNumWithComma(total.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	warning: state.modal.warning,
	PV: state.accounting.paymentVoucher,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPVPurchases,
			setPaymentVouchers,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentVoucherRightPurchase))
