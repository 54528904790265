import { Card, Checkbox, Descriptions, Divider, Select } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdDeleteForever, MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setCustomDefaultEvaluations, setLoadingAction, setSelected, setValue, setWarningId, setWarningMsgAction } from "~/redux/action"
import {
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_DISPLAY_ALL_IND,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION,
	SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE,
	SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DISPLAY,
	SET_MODAL_WARNING_SIZE,
	SET_PAGE_LOADING,
	SET_WARNING_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class ConfigEvaluation extends React.Component {
	constructor(props) {
		super(props)

		this.onType = this.onType.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		this.props.loadTypes(
			(types) => {
				this.props.setValue(SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES, types)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, true)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			},
			() => {
				this.props.setLoadingAction(SET_PAGE_LOADING, false)
			}
		)
	}
	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue && _.isEqual(this.props.warning.id, ResourceAssistance.ID.ADM.customDefault.evaluation.delete)) {
				this.props.deleteCustomDefault(this.props.evaluation.selectedEvaluation.evaluation.id, () => {
					let types = Array.from(this.props.evaluation.types).filter((each) => each.id !== this.props.evaluation.selectedType.type.id)
					let selectedType = Object.assign({}, this.props.evaluation.selectedType.type)
					selectedType.evaluations = selectedType.evaluations.filter((each) => each.id !== this.props.evaluation.selectedEvaluation.evaluation.id)
					types.push(selectedType)
					this.props.setValue(SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES, types)
				})
			}
		}
		if (!_.isEqual(prevProps.evaluation.types, this.props.evaluation.types)) {
			if (this.props.evaluation.selectedType.type) {
				let idx = this.props.evaluation.types.findIndex((each) => each.id === this.props.evaluation.selectedType.type.id)
				if (idx > -1) {
					this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE, idx, this.props.evaluation.types[idx])
				} else {
					this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE, 0, this.props.evaluation.types[0])
				}
			} else {
				this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE, 0, this.props.evaluation.types[0])
			}
		}
		if (
			!_.isEqual(prevProps.evaluation.selectedType, this.props.evaluation.selectedType) ||
			prevProps.evaluation.searchName !== this.props.evaluation.searchName ||
			prevProps.evaluation.displayInactive !== this.props.evaluation.displayInactive
		) {
			this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION, -1, null)
			if (this.props.evaluation.selectedType.type) {
				this.props.setCustomDefaultEvaluations(this.props.evaluation.selectedType.type.evaluations)
			}
		}
	}
	componentWillUnmount() {
		this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE, -1, null)
		this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION, -1, null)
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME, "")
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DISPLAY, false)
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_EVALUATION_CATEGORIES, [])
		this.props.setCustomDefaultEvaluations([])
	}

	onType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_TYPE, v, this.props.evaluation.types[v])
	}

	onSearchName(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setValue(SET_ADM_CUSTOM_DEFAULT_EVALUATION_DISPLAY_ALL_IND, event.target.checked)
	}

	onSelectRow(row, index) {
		this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION, index, this.props.evaluation.evaluationTable.filtered[index])
	}

	onAdd() {
		this.props.setSelected(SET_ADM_CUSTOM_DEFAULT_EVALUATION_SELECTED_EVALUATION, -1, null)
		this.props.onModalDisplayAction(SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_CUSTOM_DEFAULT_NEW_EVALUATION_DISPLAY, true)
	}

	onDelete(e, row, rIdx, cIdx) {
		let selectedDefault = this.props.evaluation.evaluationTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.ADM.customDefault.evaluation.delete)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.md)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.delete)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				styles={{
					header: {
						fontSize: "28px",
					},
				}}
			>
				<Descriptions style={{ flex: "unset", display: "unset" }} size={"small"} contentStyle={{ fontStyle: "italic", fontWeight: "normal" }} column={1}>
					<Descriptions.Item label={translate(ResourceAssistance.Message.name)} span={1}>
						{selectedDefault.name}
					</Descriptions.Item>
					<Descriptions.Item label={translate(ResourceAssistance.Message.description)} span={1}>
						{selectedDefault.description}
					</Descriptions.Item>
				</Descriptions>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Container fluid="small" className="full-flex">
				<Row>
					<Col md={3}>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
							placeholder={translate(ResourceAssistance.Message.category)}
							value={this.props.evaluation.selectedType.type ? this.props.evaluation.selectedType.index : undefined}
							onChange={this.onType}
						>
							{Utils.renderSelects(this.props.evaluation.types, false)}
						</Select>
					</Col>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Checkbox style={{ flexDirection: "row" }} checked={this.props.evaluation.displayInactive} onChange={this.onDisplayAll}>
							{translate(ResourceAssistance.Message.displayAll)}
						</Checkbox>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							hover
							striped
							enableHighlight
							displayBtn
							buttonColors={[ResourceAssistance.Button.variant.outlinePrimary, ResourceAssistance.Button.variant.outlineRed]}
							btnMessages={[
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.edit }),
								this.props.intl.formatMessage({ id: ResourceAssistance.Message.delete }),
							]}
							btnIcons={[<FaRegEdit size={ResourceAssistance.ReactIcon.size} />, <MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
							onCheckedArray={[this.onEdit, this.onDelete]}
							isClearHighlight={this.props.evaluation.selectedEvaluation.index === -1}
							highlightedRow={this.props.evaluation.selectedEvaluation.index}
							data={this.props.evaluation.evaluationTable}
							onClick={this.onSelectRow}
						/>
						{this.props.evaluation.evaluationTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	evaluation: state.admin.customDefaultConfig.evaluation,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setCustomDefaultEvaluations,
			setLoadingAction,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigEvaluation))
