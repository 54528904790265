import _ from "lodash"
import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	setAdmBankTypes,
	setAdmGLDocumentTypes,
	setAdmIPayTypes,
	setAdmInventoryTypes,
	setAdmRoleTypes,
	setAdmWithholdingTaxTypes,
	setAdminHealthCareTypes,
	setAdminInsuranceCompanyTypes,
	setAdminPaymentTypes,
	setAdminServiceTypes,
	setAdminTypeTitile,
	setAdminTypes,
	setLoadingAction,
	setOptions,
	setSelectedType,
	setTypeUrl,
	setValue,
} from "~/redux/action"
import {
	SET_ADM_CATEGORY_CHART_OF_ACCOUNTS,
	SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING,
	SET_ADM_CATEGORY_SERVICE_CHRGITEMS,
	SET_ADM_CATEGORY_SERVICE_IS_CHRGITEMS_LOADING,
	SET_GENERAL_TYPE_LOADING,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import "./type.css"
import AdmGLDocument from "./view/AdmGLDocument"
import AntigenType from "./view/AntigenType"
import DefaultCategory from "./view/DefaultCategory"
import FallRiskType from "./view/FallRiskType"
import ICDType from "./view/ICDType"
import IntakeType from "./view/IntakeType"
import InternetPay from "./view/InternetPay"
import IsolationPrecaution from "./view/IsolationPrecaution"
import OutputType from "./view/OutputType"
import PCRType from "./view/PCRType"
import RoleType from "./view/RoleType"
import AdmBank from "./view/bank/AdmBank"
import AdmHealthCarePlan from "./view/healthcarePlan/AdmHealthCarePlan"
import AdmInsuranceCompay from "./view/insuranceCompany/AdmInsuranceCompay"
import AdmCategoryInventory from "./view/inventory/AdmCategoryInventory"
import AdmPayment from "./view/payment/AdmPayment"
import ServiceType from "./view/service/ServiceType"
import WithholdingTax from "./view/withholdingTax/WithholdingTax"
import AdmCategoryORCase from "./view/operatingRoomCase/AdmCategoryORCase"
import AdmCategoryAnesthesia from "./view/anesthesia/AdmCategoryAnesthesia"

export const EType = {
	ANESTHESIA: "ANESTHESIA",
	ANTIGEN: "ANTIGEN",
	BANK: "BANK",
	DEFAULT_TYPE: "DEFAULT_TYPE",
	FALL_RISK: "FALL_RISK",
	GL_DOCUMENT: "GL_DOCUMENT",
	HEALTH_CARE_TYPE: "HEALTH_CARE_TYPE",
	ICD_TYPE: "ICD_TYPE",
	INSURANCE_COMPANY: "INSURANCE_COMPANY",
	INTAKE: "INTAKE",
	ISOLATION_PRECAUTION: "ISOLATION_PRECAUTION",
	ITEM_TYPE: "ITEM_TYPE",
	I_PAY: "I_PAY",
	OUTPUT: "OUTPUT",
	OR_CASE: "OR_CASE",
	PAYMENT_TYPE: "PAYMENT_TYPE",
	PCR: "PCR",
	ROLE_TYPE: "ROLE_TYPE",
	SERVICE_TYPE: "SERVICE_TYPE",
	WITHHOLDING_TAX: "WITHHOLDING_TAX",
}

class Type extends React.Component {
	constructor(props) {
		super(props)
		Object.freeze(EType)

		this.state = {
			componentName: "Type",
			tab: EType.ANESTHESIA,
		}

		this.loadAnesthesias = this.loadAnesthesias.bind(this)
		this.loadAntigens = this.loadAntigens.bind(this)
		this.loadBanks = this.loadBanks.bind(this)
		this.loadDefaultCategories = this.loadDefaultCategories.bind(this)
		this.loadFallRisks = this.loadFallRisks.bind(this)
		this.loadGLDocuments = this.loadGLDocuments.bind(this)
		this.loadHealthCareTypes = this.loadHealthCareTypes.bind(this)
		this.loadICDTypes = this.loadICDTypes.bind(this)
		this.loadInsuranceCompanies = this.loadInsuranceCompanies.bind(this)
		this.loadIntakes = this.loadIntakes.bind(this)
		this.loadInternetPayTypes = this.loadInternetPayTypes.bind(this)
		this.loadIsolationPrecautions = this.loadIsolationPrecautions.bind(this)
		this.loadItemTypes = this.loadItemTypes.bind(this)
		this.loadORCases = this.loadORCases.bind(this)
		this.loadOutputs = this.loadOutputs.bind(this)
		this.loadPCRs = this.loadPCRs.bind(this)
		this.loadPaymentTypes = this.loadPaymentTypes.bind(this)
		this.loadRoleTypes = this.loadRoleTypes.bind(this)
		this.loadServiceTypes = this.loadServiceTypes.bind(this)
		this.loadWithholdingTaxs = this.loadWithholdingTaxs.bind(this)
		this.onTabClick = this.onTabClick.bind(this)
	}

	componentDidMount() {
		this.props.setAdminTypeTitile(ResourceAssistance.Message.anesthesia)
		this.props.setTypeUrl(ResourceAssistance.Url.admin.type.anesthesia.saveType)
		this.loadAnesthesias()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.type.searchText !== this.props.type.searchText || prevProps.type.displayInactive !== this.props.type.displayInactive) {
			this.props.setAdminTypes(this.props.type.types.types)
		}
	}

	loadChartOfAccounts() {
		if (_.isEmpty(this.props.type.chartOfAccounts)) {
			let params = {
				method: "GET",
				url: ResourceAssistance.Url.admin.chartOfAccounts.getChartOfAccounts,
				withCredentials: true,
				headers: {
					"content-type": "application/json",
				},
			}
			let callback = (res) => {
				this.props.setValue(SET_ADM_CATEGORY_CHART_OF_ACCOUNTS, Utils.getChartOfAccountsByGroup(Utils.groupBy(res.data.accounts, "coreCodeName")))
			}
			let errorHandler = () => {
				this.props.setLoadingAction(SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING, false)
			}
			let reqInterceptor = () => {
				this.props.setLoadingAction(SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING, true)
			}
			let resInterceptor = () => {
				this.props.setLoadingAction(SET_ADM_CATEGORY_CHART_OF_ACCOUNTS_LOADING, false)
			}
			axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		}
	}

	loadAnesthesias() {
		this.load(ResourceAssistance.Url.admin.type.anesthesia.loadTypes, this.props.setAdminTypes)
	}

	loadItemTypes() {
		this.load(ResourceAssistance.Url.admin.type.item.loadTypes, this.props.setAdmInventoryTypes)
	}

	loadPaymentTypes() {
		this.load(ResourceAssistance.Url.admin.type.payment.loadTypes, this.props.setAdminPaymentTypes)
	}

	loadServiceTypes() {
		this.load(ResourceAssistance.Url.admin.type.service.loadTypes, this.props.setAdminServiceTypes)
		this.loadOption(
			ResourceAssistance.Url.admin.workflowProperty.eclaimUc.getChrgItem,
			SET_ADM_CATEGORY_SERVICE_CHRGITEMS,
			SET_ADM_CATEGORY_SERVICE_IS_CHRGITEMS_LOADING,
			"wfep",
			"id",
			"code"
		)
	}

	loadRoleTypes() {
		this.load(ResourceAssistance.Url.admin.type.role.loadTypes, this.props.setAdmRoleTypes)
	}

	loadDefaultCategories() {
		this.load(ResourceAssistance.Url.admin.type.default.loadTypes, this.props.setAdminTypes)
	}

	loadHealthCareTypes() {
		this.load(ResourceAssistance.Url.admin.type.healthCarePlan.loadTypes, this.props.setAdminHealthCareTypes)
	}

	loadICDTypes() {
		this.load(ResourceAssistance.Url.admin.type.icd.loadTypes, this.props.setAdminTypes)
	}

	loadInternetPayTypes() {
		this.load(ResourceAssistance.Url.admin.type.iPay.loadTypes, this.props.setAdmIPayTypes)
	}

	loadIsolationPrecautions() {
		this.load(ResourceAssistance.Url.admin.type.isolationPrecaution.loadTypes, this.props.setAdminTypes)
	}

	loadFallRisks() {
		this.load(ResourceAssistance.Url.admin.type.fallRisk.loadTypes, this.props.setAdminTypes)
	}

	loadIntakes() {
		this.load(ResourceAssistance.Url.admin.type.intake.loadTypes, this.props.setAdminTypes)
	}

	loadOutputs() {
		this.load(ResourceAssistance.Url.admin.type.output.loadTypes, this.props.setAdminTypes)
	}

	loadORCases() {
		this.load(ResourceAssistance.Url.admin.type.operatingRoomCase.loadTypes, this.props.setAdminTypes)
	}

	loadPCRs() {
		this.load(ResourceAssistance.Url.admin.type.pcr.loadTypes, this.props.setAdminTypes)
	}

	loadAntigens() {
		this.load(ResourceAssistance.Url.admin.type.antigen.loadTypes, this.props.setAdminTypes)
	}

	loadInsuranceCompanies() {
		this.load(ResourceAssistance.Url.admin.type.insuranceCompany.loadTypes, this.props.setAdminInsuranceCompanyTypes)
	}

	loadBanks() {
		this.load(ResourceAssistance.Url.admin.type.bank.loadTypes, this.props.setAdmBankTypes)
	}

	loadWithholdingTaxs() {
		this.load(ResourceAssistance.Url.admin.type.withholdingTax.loadTypes, this.props.setAdmWithholdingTaxTypes)
	}

	loadGLDocuments() {
		this.load(ResourceAssistance.Url.admin.type.glDocument.loadTypes, this.props.setAdmGLDocumentTypes)
	}

	load(url, func) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setSelectedType(-1, null)
			func(res.data.uts)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(SET_GENERAL_TYPE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_GENERAL_TYPE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_GENERAL_TYPE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadOption(url, setOptionType, loadingType, dataKey, aId, bId) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				excludeInActive: true,
			},
		}
		let callback = (res) => {
			this.props.setOptions(setOptionType, res.data[dataKey], aId, bId)
		}
		let errorHandler = () => {
			this.props.setLoadingAction(loadingType, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(loadingType, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(loadingType, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })

		switch (event) {
			case EType.ANESTHESIA:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.anesthesia.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.anesthesia)
				this.loadAnesthesias()
				break
			case EType.ANTIGEN:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.antigen.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.antigen)
				this.loadAntigens()
				break
			case EType.ITEM_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.item.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.itemType)
				this.loadItemTypes()
				this.loadChartOfAccounts()
				break
			case EType.PAYMENT_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.payment.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.payment)
				this.loadPaymentTypes()
				this.loadChartOfAccounts()
				break
			case EType.ROLE_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.role.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.role)
				this.loadRoleTypes()
				break
			case EType.SERVICE_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.service.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.service)
				this.loadServiceTypes()
				this.loadChartOfAccounts()
				break
			case EType.DEFAULT_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.default.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.default)
				this.loadDefaultCategories()
				break
			case EType.HEALTH_CARE_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.healthCarePlan.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.healthCarePlan)
				this.loadHealthCareTypes()
				this.loadChartOfAccounts()
				break
			case EType.ICD_TYPE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.icd.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.internationalClassificationDiseases)
				this.loadICDTypes()
				break
			case EType.ISOLATION_PRECAUTION:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.isolationPrecaution.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.isolationPrecaution)
				this.loadIsolationPrecautions()
				break
			case EType.FALL_RISK:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.fallRisk.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.fallRisk)
				this.loadFallRisks()
				break
			case EType.INTAKE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.intake.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.intake)
				this.loadIntakes()
				break
			case EType.OUTPUT:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.output.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.output)
				this.loadOutputs()
				break
			case EType.OR_CASE:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.operatingRoomCase.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.orCase)
				this.loadORCases()
				break
			case EType.PCR:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.pcr.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Hospitel.pcr)
				this.loadPCRs()
				break
			case EType.INSURANCE_COMPANY:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.insuranceCompany.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.insuranceCompany)
				this.loadInsuranceCompanies()
				this.loadChartOfAccounts()
				break
			case EType.BANK:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.bank.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.bank)
				this.loadBanks()
				this.loadChartOfAccounts()
				break
			case EType.I_PAY:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.iPay.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.iPay)
				this.loadInternetPayTypes()
				break
			case EType.WITHHOLDING_TAX:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.withholdingTax.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.withholdingTax)
				this.loadWithholdingTaxs()
				this.loadChartOfAccounts()
				break
			case EType.GL_DOCUMENT:
				this.props.setTypeUrl(ResourceAssistance.Url.admin.type.glDocument.saveType)
				this.props.setAdminTypeTitile(ResourceAssistance.Message.glDocument)
				this.loadGLDocuments()
				break
			default:
				this.props.setTypeUrl("")
				break
		}
	}

	render() {
		return (
			// <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<div className={` ${ResourceAssistance.CSS.fullFlex}`}>
							<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false} fill>
								<Tab eventKey={EType.ANESTHESIA} title={translate(ResourceAssistance.Message.anesthesia)} name={EType.ANESTHESIA}>
									{this.state.tab === EType.ANESTHESIA && <AdmCategoryAnesthesia {...this.props} onRefreshClick={this.loadAnesthesias} />}
								</Tab>
								<Tab eventKey={EType.ANTIGEN} title={translate(ResourceAssistance.Hospitel.antigen)} name={EType.ANTIGEN}>
									{this.state.tab === EType.ANTIGEN && <AntigenType {...this.props} onRefreshClick={this.loadAntigens} />}
								</Tab>
								<Tab eventKey={EType.BANK} title={translate(ResourceAssistance.Message.bank)} name={EType.BANK}>
									{this.state.tab === EType.BANK && <AdmBank {...this.props} onRefreshClick={this.loadBanks} />}
								</Tab>
								<Tab eventKey={EType.DEFAULT_TYPE} title={translate(ResourceAssistance.Message.default)} name={EType.DEFAULT_TYPE}>
									{this.state.tab === EType.DEFAULT_TYPE && <DefaultCategory {...this.props} onRefreshClick={this.loadDefaultCategories} />}
								</Tab>
								<Tab eventKey={EType.FALL_RISK} title={translate(ResourceAssistance.Message.fallRisk)} name={EType.FALL_RISK}>
									{this.state.tab === EType.FALL_RISK && <FallRiskType {...this.props} onRefreshClick={this.loadFallRisks} />}
								</Tab>
								<Tab eventKey={EType.GL_DOCUMENT} title={translate(ResourceAssistance.Message.glDocument)} name={EType.GL_DOCUMENT}>
									{this.state.tab === EType.GL_DOCUMENT && <AdmGLDocument {...this.props} onRefreshClick={this.loadGLDocuments} />}
								</Tab>
								<Tab eventKey={EType.HEALTH_CARE_TYPE} title={translate(ResourceAssistance.Hospitel.healthCarePlan)} name={EType.HEALTH_CARE_TYPE}>
									{this.state.tab === EType.HEALTH_CARE_TYPE && <AdmHealthCarePlan {...this.props} onRefreshClick={this.loadHealthCareTypes} />}
								</Tab>
								<Tab eventKey={EType.INSURANCE_COMPANY} title={translate(ResourceAssistance.Message.insuranceCompany)} name={EType.INSURANCE_COMPANY}>
									{this.state.tab === EType.INSURANCE_COMPANY && <AdmInsuranceCompay {...this.props} onRefreshClick={this.loadInsuranceCompanies} />}
								</Tab>
								<Tab eventKey={EType.ITEM_TYPE} title={translate(ResourceAssistance.Message.inventory)} name={EType.ITEM_TYPE}>
									{this.state.tab === EType.ITEM_TYPE && <AdmCategoryInventory {...this.props} onRefreshClick={this.loadItemTypes} />}
								</Tab>
								<Tab eventKey={EType.ICD_TYPE} title={translate(ResourceAssistance.Message.internationalClassificationDiseases)} name={EType.ICD_TYPE}>
									{this.state.tab === EType.ICD_TYPE && <ICDType {...this.props} onRefreshClick={this.loadICDTypes} />}
								</Tab>
								<Tab eventKey={EType.I_PAY} title={translate(ResourceAssistance.Message.iPay)} name={EType.I_PAY}>
									{this.state.tab === EType.I_PAY && <InternetPay {...this.props} onRefreshClick={this.loadInternetPayTypes} />}
								</Tab>
								<Tab eventKey={EType.ISOLATION_PRECAUTION} title={translate(ResourceAssistance.Message.isolationPrecaution)} name={EType.ISOLATION_PRECAUTION}>
									{this.state.tab === EType.ISOLATION_PRECAUTION && <IsolationPrecaution {...this.props} onRefreshClick={this.loadIsolationPrecautions} />}
								</Tab>
								<Tab eventKey={EType.INTAKE} title={translate(ResourceAssistance.Message.intake)} name={EType.INTAKE}>
									{this.state.tab === EType.INTAKE && <IntakeType {...this.props} onRefreshClick={this.loadIntakes} />}
								</Tab>
								<Tab eventKey={EType.OUTPUT} title={translate(ResourceAssistance.Message.output)} name={EType.OUTPUT}>
									{this.state.tab === EType.OUTPUT && <OutputType {...this.props} onRefreshClick={this.loadOutputs} />}
								</Tab>
								<Tab eventKey={EType.OR_CASE} title={translate(ResourceAssistance.Message.orCase)} name={EType.OR_CASE}>
									{this.state.tab === EType.OR_CASE && <AdmCategoryORCase {...this.props} onRefreshClick={this.loadORCases} />}
								</Tab>
								<Tab eventKey={EType.PAYMENT_TYPE} title={translate(ResourceAssistance.Message.payment)} name={EType.PAYMENT_TYPE}>
									{this.state.tab === EType.PAYMENT_TYPE && <AdmPayment {...this.props} onRefreshClick={this.loadPaymentTypes} />}
								</Tab>
								<Tab eventKey={EType.PCR} title={translate(ResourceAssistance.Hospitel.pcr)} name={EType.PCR}>
									{this.state.tab === EType.PCR && <PCRType {...this.props} onRefreshClick={this.loadPCRs} />}
								</Tab>
								<Tab eventKey={EType.ROLE_TYPE} title={translate(ResourceAssistance.Message.role)} name={EType.ROLE_TYPE}>
									{this.state.tab === EType.ROLE_TYPE && <RoleType {...this.props} onRefreshClick={this.loadRoleTypes} />}
								</Tab>
								<Tab eventKey={EType.SERVICE_TYPE} title={translate(ResourceAssistance.Hospitel.service)} name={EType.SERVICE_TYPE}>
									{this.state.tab === EType.SERVICE_TYPE && <ServiceType {...this.props} onRefreshClick={this.loadServiceTypes} />}
								</Tab>
								<Tab eventKey={EType.WITHHOLDING_TAX} title={translate(ResourceAssistance.Message.withholdingTax)} name={EType.WITHHOLDING_TAX}>
									{this.state.tab === EType.WITHHOLDING_TAX && <WithholdingTax {...this.props} onRefreshClick={this.loadWithholdingTaxs} />}
								</Tab>
							</Tabs>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setAdmBankTypes,
			setAdmGLDocumentTypes,
			setAdmIPayTypes,
			setAdmInventoryTypes,
			setAdmRoleTypes,
			setAdmWithholdingTaxTypes,
			setAdminHealthCareTypes,
			setAdminInsuranceCompanyTypes,
			setAdminPaymentTypes,
			setAdminServiceTypes,
			setAdminTypeTitile,
			setAdminTypes,
			setLoadingAction,
			setSelectedType,
			setTypeUrl,
			setOptions,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Type)
