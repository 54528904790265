import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators, compose } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOperatingRoomRequests, setValue } from "~/redux/action"
import { SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST, SET_PAGE_LOADING } from "~/redux/type"
import withScrollToTopOnShow from "~/view/component/modal/modal-scroll-to-top/withScrollToTopOnShow"
import OperatingRoomModalEditPreOperativeCheckListDetail from "./OperatingRoomModalEditPreOperativeCheckListDetail"

class OperatingRoomModalEditPreOperativeCheckList extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OR_NURSE_EDIT_PRE_OPERATIVE_CHECK_LIST, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.operatingRoom.selectedRequest.request) {
			return true
		}
		let preOPCheckList = _.isEmpty(this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists)
			? undefined
			: this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists[0]
		let target = Object.assign({}, preOPCheckList, {
			identifyPatient: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isIdentifyPatient},${this.props.operatingRoom.modalEditPreOperativeCheckList.identifyPatientRemark}`,
			markingSurgerySite: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isMarkingSiteForSurgery},${this.props.operatingRoom.modalEditPreOperativeCheckList.markingSiteForSurgeryRemark}`,
			consentSurgery: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isLetterConsentSurgery},${this.props.operatingRoom.modalEditPreOperativeCheckList.letterConsentSurgeryRemark}`,
			consentAnesthesia: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isLetterConsentAnesthesia},${this.props.operatingRoom.modalEditPreOperativeCheckList.letterConsentAnesthesiaRemark}`,
			labResult: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isLabResult},${this.props.operatingRoom.modalEditPreOperativeCheckList.labResultRemark}`,
			imagingResult: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isImagingResult},${this.props.operatingRoom.modalEditPreOperativeCheckList.imagingResultRemark}`,
			ekgResult: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isEKGResult},${this.props.operatingRoom.modalEditPreOperativeCheckList.EKGResultRemark}`,
			cleanPatientBody: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isCleanPatientBody},${this.props.operatingRoom.modalEditPreOperativeCheckList.cleanPatientBodyRemark}`,
			unisonEnema: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isUnisonEnema},${this.props.operatingRoom.modalEditPreOperativeCheckList.unisonEnemaRemark}`,
			npoTime: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isNPOTime},${this.props.operatingRoom.modalEditPreOperativeCheckList.NPOTimeRemark}`,
			removeDentures: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isRemoveDentures},${this.props.operatingRoom.modalEditPreOperativeCheckList.removeDenturesRemark}`,
			removeEyeglasses: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isEyeglassesRemoved},${this.props.operatingRoom.modalEditPreOperativeCheckList.eyeglassesRemovedRemark}`,
			removeJewelry: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isJewelryRemoved},${this.props.operatingRoom.modalEditPreOperativeCheckList.jewelryRemovedRemark}`,
			removeBodyPiercing: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isBodyPiercingRemoved},${this.props.operatingRoom.modalEditPreOperativeCheckList.bodyPiercingRemovedRemark}`,
			changePatientClothes: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isChangePaitentClothes},${this.props.operatingRoom.modalEditPreOperativeCheckList.changePatientClothesRemark}`,
			urinateBeforeOR: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isPatientUrinate},${this.props.operatingRoom.modalEditPreOperativeCheckList.patientUrinateRemark}`,
			bloodRequested: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isBloodRequested},${this.props.operatingRoom.modalEditPreOperativeCheckList.bloodRequestedRemark}`,
			preEducation: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isPreEducation},${this.props.operatingRoom.modalEditPreOperativeCheckList.preEducationRemark}`,
			otherMedicalDevice: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isOtherMedicalDevice},${this.props.operatingRoom.modalEditPreOperativeCheckList.otherMedicalDeviceRemark}`,
			toolSpecialEquipment: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isToolSpecialEquipment},${this.props.operatingRoom.modalEditPreOperativeCheckList.toolSpecialEquipmentRemark}`,
			implant: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isImplant},${this.props.operatingRoom.modalEditPreOperativeCheckList.implantRemark}`,
		})
		return _.isEqual(preOPCheckList, target)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.operatingRoom.savePreOperativeCheckLists,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				operatingRoomRequestId: this.props.operatingRoom.selectedRequest.request.id,
				userId: this.props.login.user.id,
				preOperativeCheckLists: [
					{
						id: _.isEmpty(this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists)
							? 0
							: this.props.operatingRoom.selectedRequest.request.operatingRoomPreOperativeCheckLists[0].id,
						identifyPatient: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isIdentifyPatient},${this.props.operatingRoom.modalEditPreOperativeCheckList.identifyPatientRemark}`,
						markingSurgerySite: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isMarkingSiteForSurgery},${this.props.operatingRoom.modalEditPreOperativeCheckList.markingSiteForSurgeryRemark}`,
						consentSurgery: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isLetterConsentSurgery},${this.props.operatingRoom.modalEditPreOperativeCheckList.letterConsentSurgeryRemark}`,
						consentAnesthesia: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isLetterConsentAnesthesia},${this.props.operatingRoom.modalEditPreOperativeCheckList.letterConsentAnesthesiaRemark}`,
						labResult: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isLabResult},${this.props.operatingRoom.modalEditPreOperativeCheckList.labResultRemark}`,
						imagingResult: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isImagingResult},${this.props.operatingRoom.modalEditPreOperativeCheckList.imagingResultRemark}`,
						ekgResult: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isEKGResult},${this.props.operatingRoom.modalEditPreOperativeCheckList.EKGResultRemark}`,
						cleanPatientBody: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isCleanPatientBody},${this.props.operatingRoom.modalEditPreOperativeCheckList.cleanPatientBodyRemark}`,
						unisonEnema: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isUnisonEnema},${this.props.operatingRoom.modalEditPreOperativeCheckList.unisonEnemaRemark}`,
						npoTime: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isNPOTime},${this.props.operatingRoom.modalEditPreOperativeCheckList.NPOTimeRemark}`,
						removeDentures: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isRemoveDentures},${this.props.operatingRoom.modalEditPreOperativeCheckList.removeDenturesRemark}`,
						removeEyeglasses: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isEyeglassesRemoved},${this.props.operatingRoom.modalEditPreOperativeCheckList.eyeglassesRemovedRemark}`,
						removeJewelry: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isJewelryRemoved},${this.props.operatingRoom.modalEditPreOperativeCheckList.jewelryRemovedRemark}`,
						removeBodyPiercing: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isBodyPiercingRemoved},${this.props.operatingRoom.modalEditPreOperativeCheckList.bodyPiercingRemovedRemark}`,
						changePatientClothes: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isChangePaitentClothes},${this.props.operatingRoom.modalEditPreOperativeCheckList.changePatientClothesRemark}`,
						urinateBeforeOR: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isPatientUrinate},${this.props.operatingRoom.modalEditPreOperativeCheckList.patientUrinateRemark}`,
						bloodRequested: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isBloodRequested},${this.props.operatingRoom.modalEditPreOperativeCheckList.bloodRequestedRemark}`,
						preEducation: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isPreEducation},${this.props.operatingRoom.modalEditPreOperativeCheckList.preEducationRemark}`,
						otherMedicalDevice: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isOtherMedicalDevice},${this.props.operatingRoom.modalEditPreOperativeCheckList.otherMedicalDeviceRemark}`,
						toolSpecialEquipment: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isToolSpecialEquipment},${this.props.operatingRoom.modalEditPreOperativeCheckList.toolSpecialEquipmentRemark}`,
						implant: `${this.props.operatingRoom.modalEditPreOperativeCheckList.isImplant},${this.props.operatingRoom.modalEditPreOperativeCheckList.implantRemark}`,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.requests.length > 0) {
				let requests = this.props.operatingRoom.operatingRequestTable.original.filter((each) => each.id !== res.data.requests[0].id)
				requests.push(res.data.requests[0])
				this.props.setOperatingRoomRequests(requests)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				centered
				scrollable
				animation={false}
				size={ResourceAssistance.Modal.size.xl}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.preOperativeCheckList)}</ModalTitle>
					</ModalHeader>
					<ModalBody ref={this.props.modalBodyRef}>
						<OperatingRoomModalEditPreOperativeCheckListDetail />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOperatingRoomNurseEditPreOperativeCheckListDisplay,
	login: state.login,
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setOperatingRoomRequests,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl, withScrollToTopOnShow)(OperatingRoomModalEditPreOperativeCheckList)
