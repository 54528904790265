import _ from "lodash"
import React from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction, setPoItems, setSelected } from "~/redux/action"
import { SET_PO_EDIT_ITEM_DISPLAY, SET_PO_SELECTED_ITEM } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PORightItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PORightItem",
		}
		this.onSelect = this.onSelect.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.selectedSupplier, this.props.selectedSupplier)) {
			if (this.props.selectedSupplier.index === -1) {
				this.props.setPoItems([])
			} else {
				this.props.setPoItems(this.props.selectedSupplier.supplier.items)
			}
		}
		if (!_.isEqual(prevProps.po.inventory.filtered, this.props.po.inventory.filtered)) {
			let index = this.props.po.selectedItem.item ? this.props.po.inventory.filtered.findIndex((each) => each.id === this.props.po.selectedItem.item.id) : -1
			if (index !== -1) {
				this.props.setSelected(SET_PO_SELECTED_ITEM, index, this.props.po.supplier.filtered[index])
			} else {
				this.props.setSelected(SET_PO_SELECTED_ITEM, -1, null)
			}
		}
	}

	isDisplayEdit() {
		return this.props.po.selectedPo.po && !this.props.po.selectedPo.po.inspectorApproved
	}

	onSelect(row, index) {
		this.props.setSelected(SET_PO_SELECTED_ITEM, index, this.props.po.inventory.filtered[index])
	}

	onEdit(e, row, rIdx, cIdx) {
		this.props.onModalDisplayAction(SET_PO_EDIT_ITEM_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableOverlay
						enableHighlight
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlinePrimary]}
						btnIcons={[this.isDisplayEdit() ? <FaRegEdit size={ResourceAssistance.ReactIcon.size} /> : undefined]}
						onCheckedArray={[this.onEdit]}
						data={this.props.inventory}
						isClearHighlight={this.props.po.selectedItem.index === -1}
						highlightedRow={this.props.po.selectedItem.index}
						onClick={this.onSelect}
					/>
					{this.props.inventory.isLoading && <Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />}
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	po: state.purchaseOrder,
	inventory: state.purchaseOrder.inventory,
	selectedSupplier: state.purchaseOrder.selectedSupplier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setPoItems,
			setSelected,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PORightItem)
