import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import { SET_PGL_GL_DOCUMENTS, SET_PGL_GL_DOCUMENTS_LOADING } from "~/redux/type"
import PostingGLLeftAction from "./PostingGLLeftAction"
import PostingGLLeftDate from "./PostingGLLeftDate"
import PostingGLLeftFilter from "./PostingGLLeftFilter"
import PostingGLLeftRequest from "./PostingGLLeftRequest"

class PostingGLLeft extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.PGL.glDocuments)) {
			this.loadGLDocuments()
		}
	}

	loadGLDocuments() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.postingGL.getGLDocuments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PGL_GL_DOCUMENTS, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PGL_GL_DOCUMENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PGL_GL_DOCUMENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PGL_GL_DOCUMENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"full-size full-flex border"}>
				<PostingGLLeftDate />
				<PostingGLLeftFilter />
				<PostingGLLeftAction />
				<PostingGLLeftRequest />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PGL: state.accounting.postingGL,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, {
		...stateProps,
		...dispatchProps,
	})
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PostingGLLeft)
