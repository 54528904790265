import { Descriptions } from "antd"
import BigNumber from "bignumber.js"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, ModalBody, ModalFooter, ModalTitle, Row, Spinner } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { MdAddShoppingCart } from "react-icons/md"
import { VscSaveAs } from "react-icons/vsc"
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	addNewItemAction,
	loadItemsAction,
	onModalDisplayAction,
	selectItemAction,
	selectTypeAction,
	setItemPropertyAction,
	setPrModalTypeAction,
	setPrNewPrLastPurchasedInfo,
	setPrSelectedBranch,
	setSearchText,
} from "~/redux/action"
import {
	PR_NEW,
	PR_UPDATE,
	SET_PROPERTY_AMOUNT,
	SET_PROPERTY_DOSE,
	SET_PROPERTY_FORM,
	SET_PROPERTY_LABEL,
	SET_PROPERTY_SUPPLIER,
	SET_PROPERTY_UNIT,
	SET_PROPERTY_UNIT_PRICE,
	SET_PR_NEW_PR_SEARCH_NAME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

const ORG = "ORG"
const BRANCH = "BRANCH"
const PR_ID_AMOUNT = "PR_ID_AMOUNT"

class NewPR extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "NewPR",
		}

		this.onCloseBtn = this.onCloseBtn.bind(this)
		this.onDoneBtn = this.onDoneBtn.bind(this)
		this.onSelectItemClick = this.onSelectItemClick.bind(this)
		this.onAddItemSubmit = this.onAddItemSubmit.bind(this)
		this.onSearchChange = this.onSearchChange.bind(this)
		this.onChangeType = this.onChangeType.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.newPr.selectedItem.index !== this.props.newPr.selectedItem.index && this.props.newPr.selectedItem.index !== -1) {
			this.props.setItemPropertyAction(SET_PROPERTY_LABEL, "")
			this.props.setItemPropertyAction(SET_PROPERTY_AMOUNT, "")
			this.updateForm()
		}

		if (prevProps.newPr.selectedForm.id !== this.props.newPr.selectedForm.id && this.props.newPr.selectedForm.id > 0) {
			this.updateDose()
		}

		if (prevProps.newPr.selectedDose.id !== this.props.newPr.selectedDose.id && this.props.newPr.selectedDose.id) {
			this.updateUnit()
		}

		if (prevProps.newPr.selectedUnit.id !== this.props.newPr.selectedUnit.id && this.props.newPr.selectedUnit.id > 0) {
			this.updateSupplier()
		}

		if (prevProps.newPr.selectedSupplier.id !== this.props.newPr.selectedSupplier.id && !_.isEmpty(this.props.newPr.selectedSupplier.id)) {
			this.updateUnitPrice()
		}

		if (prevProps.newPr.selectedAmount !== this.props.newPr.selectedAmount) {
			this.validateAmount()
		}

		//this works with set selected branch when open as edit window.
		//that this will set the select html according to the selected branch
		if (!prevProps.isShow && this.props.isShow && this.props.pr.location.selectedOrg.index !== -1 && this.props.pr.location.selectedBranch.index !== -1) {
			let org = document.getElementById(ResourceAssistance.CONSTANT.ORG)
			org.selectedIndex = Array.prototype.findIndex.call(org.options, (option) => {
				return option.value === String(this.props.pr.location.selectedOrg.index)
			})
			let branch = document.getElementById(ResourceAssistance.CONSTANT.BRANCH)
			branch.selectedIndex = Array.prototype.findIndex.call(branch.options, (option) => option.value === String(this.props.pr.location.selectedBranch.index))
		}

		if (this.props.isShow) {
			if (prevProps.pr.location.selectedBranch.index !== -1 && prevProps.pr.location.selectedBranch.index !== this.props.pr.location.selectedBranch.index) {
				if (!this.props.validateItem(Object.values(this.props.pr.newPr.selectedItems.newItems).concat(this.props.pr.prSelectedItemTableData.selectedItems))) {
					let org = document.getElementById(ResourceAssistance.CONSTANT.ORG)
					org.selectedIndex = Array.prototype.findIndex.call(org.options, (option) => {
						return option.value === String(prevProps.pr.location.selectedOrg.index)
					})
					let branch = document.getElementById(ResourceAssistance.CONSTANT.BRANCH)
					branch.selectedIndex = Array.prototype.findIndex.call(branch.options, (option) => option.value === String(prevProps.pr.location.selectedBranch.index))
					this.props.setPrSelectedBranch(prevProps.pr.location.selectedBranch.index, prevProps.pr.location.selectedBranch.branch)
				}
			}

			if (
				JSON.stringify(prevProps.pr.location.selectedBranch) !== JSON.stringify(this.props.pr.location.selectedBranch) ||
				JSON.stringify(prevProps.newPr.selectedDose) !== JSON.stringify(this.props.newPr.selectedDose)
			) {
				if (this.props.newPr.selectedItem.index !== -1 && this.props.newPr.selectedForm.id > 0 && this.props.newPr.selectedDose.id) {
					let itemName = this.props.newPr.filtered[this.props.newPr.selectedItem.index].displayName
					let itemForm = this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].uts.displayName
					let itemDose =
						this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[
							this.props.newPr.selectedDose.id
						].uts.displayName
					this.props.setPrNewPrLastPurchasedInfo(itemName.concat(" ", itemForm).concat(" ", itemDose))
				} else {
					this.props.setPrNewPrLastPurchasedInfo("")
				}
			}

			if (prevProps.pr.selectedType.index !== this.props.pr.selectedType.index || prevProps.pr.newPr.searchName !== this.props.pr.newPr.searchName) {
				this.props.loadItemsAction(this.props.newPr.original)
				this.props.selectItemAction(-1)
			}
		}

		if (prevProps.isShow !== this.props.isShow) {
			if (!this.props.isShow) {
				this.props.setPrNewPrLastPurchasedInfo("")
			} else {
				if (this.props.prTypes.length !== 0) {
					let selectedIndex = document.getElementById(ResourceAssistance.ID.PR.type).value
					this.props.selectTypeAction(selectedIndex, this.props.prTypes[selectedIndex])
				}
			}
			this.props.loadItemsAction(this.props.newPr.original)
		}
	}

	updateForm() {
		if (this.props.newPr.filtered[this.props.newPr.selectedItem.index].forms.length === 1) {
			this.props.setItemPropertyAction(
				SET_PROPERTY_FORM,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].forms,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].forms[0].id
			)
		} else {
			this.props.setItemPropertyAction(SET_PROPERTY_FORM, this.props.newPr.filtered[this.props.newPr.selectedItem.index].forms, 0)
		}
	}

	updateDose() {
		if (this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents.length === 1) {
			this.props.setItemPropertyAction(
				SET_PROPERTY_DOSE,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents[0].displayName
			)
		} else {
			this.props.setItemPropertyAction(
				SET_PROPERTY_DOSE,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents,
				""
			)
		}
	}

	updateUnit() {
		if (
			this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
				.currentLevelContents.length === 1
		) {
			this.props.setItemPropertyAction(
				SET_PROPERTY_UNIT,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.currentLevelContents,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.currentLevelContents[0].id
			)
		} else {
			this.props.setItemPropertyAction(
				SET_PROPERTY_UNIT,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.currentLevelContents,
				0
			)
		}
	}

	updateSupplier() {
		if (
			this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
				.next[this.props.newPr.selectedUnit.id].currentLevelContents.length === 1
		) {
			this.props.setItemPropertyAction(
				SET_PROPERTY_SUPPLIER,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.next[this.props.newPr.selectedUnit.id].currentLevelContents,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.next[this.props.newPr.selectedUnit.id].currentLevelContents[0].brandName
			)
		} else {
			this.props.setItemPropertyAction(
				SET_PROPERTY_SUPPLIER,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.next[this.props.newPr.selectedUnit.id].currentLevelContents,
				0
			)
		}
	}

	updateUnitPrice() {
		if (
			this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
				.next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].currentLevelContents.length === 1
		) {
			this.props.setItemPropertyAction(
				SET_PROPERTY_UNIT_PRICE,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].currentLevelContents,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].currentLevelContents[0].id
			)
		} else {
			this.props.setItemPropertyAction(
				SET_PROPERTY_UNIT_PRICE,
				this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
					.next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].currentLevelContents,
				""
			)
		}
	}

	reset() {
		this.props.reset()

		this.props.onModalDisplayAction(PR_NEW, false)
		this.props.setPrModalTypeAction("")

		this.props.selectItemAction(-1)
		this.props.setSearchText(SET_PR_NEW_PR_SEARCH_NAME, "")

		this.props.setItemPropertyAction(SET_PROPERTY_FORM, [], 0)
		this.props.setItemPropertyAction(SET_PROPERTY_DOSE, [], "")
		this.props.setItemPropertyAction(SET_PROPERTY_UNIT, [], 0)
		this.props.setItemPropertyAction(SET_PROPERTY_SUPPLIER, [], 0)
		this.props.setItemPropertyAction(SET_PROPERTY_UNIT_PRICE, [], "")
		this.props.setItemPropertyAction(SET_PROPERTY_LABEL, "")
		this.props.setItemPropertyAction(SET_PROPERTY_AMOUNT, "")
		this.props.addNewItemAction({})
	}

	getSupplier() {
		if (this.props.newPr.selectedItem.index > -1 && this.props.newPr.selectedUnitPrice.id) {
			return this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[
				this.props.newPr.selectedDose.id
			].next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].next[this.props.newPr.selectedUnitPrice.id].currentLevelContents[0]
		}
	}

	getDoseId(displayName) {
		let dose
		if (this.props.newPr.selectedItem.index > -1 && this.props.newPr.selectedForm.id > 0) {
			dose = this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents.find(
				(dose) => {
					return dose.displayName === displayName
				}
			)
		}

		return dose ? dose.id : ""
	}

	isAddItemDisabled() {
		return !(
			this.props.newPr.selectedItem.index > -1 &&
			this.props.newPr.selectedForm.id > 0 &&
			this.props.newPr.selectedDose.id &&
			this.props.newPr.selectedUnit.id > 0 &&
			this.props.newPr.selectedSupplier.id &&
			this.props.newPr.selectedUnitPrice &&
			this.props.newPr.selectedAmount
		)
	}

	isDoneDisabled() {
		let curLocationId = this.props.pr.selectedPR.pr ? this.props.pr.selectedPR.pr.location.id : 0
		let newLocationId = this.props.pr.location.selectedBranch.branch ? this.props.pr.location.selectedBranch.branch.id : 0

		return (
			_.isEqual(Object.values(this.props.newPr.selectedItems.newItems), this.props.pr.prItemsTableData.pendingItems) &&
			Utils.BigNumber(curLocationId).eq(newLocationId)
		)
	}

	validateAmount() {
		if (this.props.newPr.selectedAmount && this.props.newPr.selectedUnitPrice) {
			let minQtyPerOrder = BigNumber(this.getSupplier().minQtyPerOrder)
			let amountInput = document.getElementById(PR_ID_AMOUNT)
			if (!BigNumber(this.props.newPr.selectedAmount).dividedBy(minQtyPerOrder).isInteger()) {
				amountInput.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.wrongAmount }))
				return false
			} else {
				amountInput.setCustomValidity("")
			}
		} else {
			return false
		}

		return true
	}

	saveOrder(orderId) {
		let param = JSON.stringify({
			id: orderId,
			userId: this.props.login.user.id,
			locationId: this.props.pr.location.selectedBranch.branch.id,
			label: this.props.newPr.selectedLabel,
			items: Object.keys(this.props.newPr.selectedItems.newItems)
				.map((key) => this.props.newPr.selectedItems.newItems[key])
				.map((ms) => {
					let GL = Utils.getGeneralLedgerCode(this.props.pr.location.selectedBranch.branch.accountsPayable, [], ms.type.activities, false)
					return {
						id: ms.id,
						typeId: ms.type.id,
						categoryName: ms.type.description,
						itemCode: ms.code,
						itemName: ms.displayName,
						brand: ms.brand,
						unit: ms.unit,
						supplierCode: ms.supplierCode,
						supplierName: ms.supplierName,
						supplierPhone: ms.supplierPhone,
						supplierAddress: ms.supplierAddress,
						supplierTaxId: ms.supplierTaxId,
						amount: Utils.BigNumber(ms.amount).toNumber(),
						bonus: ms.bonus,
						minQtyPerOrder: ms.minQtyPerOrder,
						pricePerUnit: ms.pricePerUnit,
						discount: ms.discount,
						taxRate: ms.tax ? ms.tax.taxRate : ms.taxRate,
						accountingCode: GL.fullCode,
						accountingChartName: GL.displayName,
						accountingChartDescription: GL.description,
						accountingTransDescr: GL.transDescr,
					}
				}),
		})
		this.props.fetch(param)
	}

	onAddItemSubmit(event) {
		event.preventDefault()
		event.stopPropagation()

		let selected =
			this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
				.next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].next[this.props.newPr.selectedUnitPrice.id].currentLevelContents[0]

		if (!this.props.validateItem([selected])) {
			return
		}

		let newItems = Object.assign({}, this.props.newPr.selectedItems.newItems, {
			[selected.code]: Object.assign({}, this.props.newPr.selectedItems.newItems[selected.code], {
				...selected,
				id: this.props.newPr.selectedItems.newItems[selected.code] ? this.props.newPr.selectedItems.newItems[selected.code].id : -1,
				amount: this.props.newPr.selectedAmount,
				type: this.props.pr.selectedType.type,
			}),
		})

		this.props.addNewItemAction(newItems)
	}

	onCloseBtn() {
		this.reset()
	}

	onDoneBtn(event) {
		switch (this.props.modal.newPr.type) {
			case PR_NEW:
				this.saveOrder(-1)
				break
			case PR_UPDATE:
				this.saveOrder(this.props.pr.selectedPR.pr.id)
				break
			default:
				break
		}
		this.reset()
	}

	onSearchChange(event) {
		this.props.setSearchText(SET_PR_NEW_PR_SEARCH_NAME, event.target.value)
	}

	onSelectItemClick(item, index) {
		if (index !== this.props.pr.newPr.selectedItem.index) {
			this.props.selectItemAction(index)
		}
	}

	onPropertyChange(event, actionType) {
		event.preventDefault()
		let data = []
		let value = event.target.value

		switch (actionType) {
			case SET_PROPERTY_FORM:
				data = this.props.newPr.filtered[this.props.newPr.selectedItem.index].forms
				this.props.setItemPropertyAction(SET_PROPERTY_DOSE, [], "")
				this.props.setItemPropertyAction(SET_PROPERTY_UNIT, [], 0)
				this.props.setItemPropertyAction(SET_PROPERTY_SUPPLIER, [], 0)
				this.props.setItemPropertyAction(SET_PROPERTY_UNIT_PRICE, [], "")
				break

			case SET_PROPERTY_DOSE:
				data = this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents

				value = this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].currentLevelContents.find(
					(dose) => {
						return dose.id === event.target.value
					}
				).displayName
				this.props.setItemPropertyAction(SET_PROPERTY_UNIT, [], 0)
				this.props.setItemPropertyAction(SET_PROPERTY_SUPPLIER, [], 0)
				this.props.setItemPropertyAction(SET_PROPERTY_UNIT_PRICE, [], "")
				break

			case SET_PROPERTY_UNIT:
				data =
					this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
						.currentLevelContents
				this.props.setItemPropertyAction(SET_PROPERTY_SUPPLIER, [], 0)
				this.props.setItemPropertyAction(SET_PROPERTY_UNIT_PRICE, [], "")
				break

			case SET_PROPERTY_SUPPLIER:
				data =
					this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
						.next[this.props.newPr.selectedUnit.id].currentLevelContents
				this.props.setItemPropertyAction(SET_PROPERTY_UNIT_PRICE, [], "")
				break

			case SET_PROPERTY_UNIT_PRICE:
				data =
					this.props.newPr.filtered[this.props.newPr.selectedItem.index].trieTree.next[this.props.newPr.selectedForm.id].next[this.props.newPr.selectedDose.id]
						.next[this.props.newPr.selectedUnit.id].next[this.props.newPr.selectedSupplier.id].currentLevelContents
				break

			case SET_PROPERTY_AMOUNT:
				this.props.setItemPropertyAction(actionType, event.target.value ? event.target.value * 1 : "")
				return

			case SET_PROPERTY_LABEL:
				this.props.setItemPropertyAction(actionType, event.target.value)
				return

			default:
				break
		}

		this.props.setItemPropertyAction(SET_PROPERTY_LABEL, "")
		this.props.setItemPropertyAction(SET_PROPERTY_AMOUNT, "")
		this.props.setItemPropertyAction(actionType, data, value)
	}

	onChangeType(event) {
		this.props.selectTypeAction(event.target.value, this.props.prTypes[event.target.value])
	}

	renderProperty(list, valueStr = "id") {
		return list.map((obj, key) => {
			if (!(obj instanceof Object)) {
				return (
					<option key={key} value={obj}>
						{obj}
					</option>
				)
			} else {
				return (
					<option key={key} value={obj[valueStr]}>
						{obj.displayName}
					</option>
				)
			}
		})
	}

	renderLocs(locs) {
		let options = locs.map((loc, key) => {
			return (
				<option key={key} value={key}>
					{loc.displayName}
				</option>
			)
		})

		return Object.assign([], options, [
			<option key={-1} value={""}>
				{ResourceAssistance.Symbol.space}
			</option>,
			...options,
		])
	}

	renderTypes() {
		return this.props.prTypes.map((type, key) => {
			return (
				<option key={key} value={key}>
					{type.displayName}
				</option>
			)
		})
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isShow}
				onHide={this.onCloseBtn}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
			>
				<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
					<ModalTitle>{translate(ResourceAssistance.Message.createNewPR)}</ModalTitle>
				</ModalHeader>
				<ModalBody className="show-grid">
					<Form onSubmit={this.onAddItemSubmit} className={ResourceAssistance.CSS.fullFlex}>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<Row>
								<Col sm="auto">
									<label>{translate(ResourceAssistance.Message.org)}</label>
								</Col>
								<Col>
									<FormattedMessage id={ResourceAssistance.Message.loading}>
										{(placeholder) => (
											<FormControl
												required
												id={ORG}
												size={ResourceAssistance.FormControl.size.sm}
												as={ResourceAssistance.FormControl.as.select}
												disabled={this.props.pr.location.isLoading}
												onChange={this.props.onOrgChange}
											>
												{this.props.pr.location.isLoading ? <option>{placeholder}</option> : this.renderLocs(this.props.pr.location.orgs)}
											</FormControl>
										)}
									</FormattedMessage>
								</Col>
								<Col sm="auto">
									<label>{translate(ResourceAssistance.Message.location)}</label>
								</Col>
								<Col>
									<FormattedMessage id={ResourceAssistance.Message.loading}>
										{(placeholder) => (
											<FormControl
												required
												id={BRANCH}
												size={ResourceAssistance.FormControl.size.sm}
												as={ResourceAssistance.FormControl.as.select}
												disabled={this.props.pr.location.isLoading}
												onChange={this.props.onBranchChange}
											>
												{this.props.pr.location.isLoading ? <option>{placeholder}</option> : this.renderLocs(this.props.pr.location.branches)}
											</FormControl>
										)}
									</FormattedMessage>
								</Col>
							</Row>
							<Row>
								<Col md="auto">
									<FormattedMessage id={ResourceAssistance.Message.loading}>
										{(placeholder) => (
											<FormControl
												id={ResourceAssistance.ID.PR.type}
												size={ResourceAssistance.FormControl.size.sm}
												as={ResourceAssistance.FormControl.as.select}
												onChange={this.onChangeType}
											>
												{this.renderTypes()}
											</FormControl>
										)}
									</FormattedMessage>
								</Col>
								<Col>
									<SearchBox num={1} placeholders={[ResourceAssistance.Message.name]} callbacks={[this.onSearchChange]} />
								</Col>
							</Row>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col>
									<Row className={ResourceAssistance.CSS.fullSize}>
										<Col id={ResourceAssistance.ID.PR.modalNewPr.tableItem}>
											<ScrollableTable
												className={ResourceAssistance.CSS.fullSize}
												striped
												hover
												enableHighlight
												onClick={this.onSelectItemClick}
												data={this.props.newPr.items}
												isClearHighlight={this.props.newPr.selectedItem.index === -1}
											/>
											{this.props.newPr.items.isLoading && (
												<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
											)}
										</Col>
									</Row>
									<Row>
										<Col>
											<Descriptions style={{ flex: "unset", display: "unset" }} contentStyle={{ fontStyle: "italic" }} column={2}>
												<Descriptions.Item label={translate(ResourceAssistance.Message.lastPurchasedFrom)} span={1}>
													{this.props.newPr.lastPurchasedFrom}
												</Descriptions.Item>
												<Descriptions.Item label={translate(ResourceAssistance.Message.date)} span={1}>
													{this.props.newPr.lastPurchasedDate}
												</Descriptions.Item>
											</Descriptions>
										</Col>
										<Col md="auto">
											<Button
												size={ResourceAssistance.Button.size.md}
												type={ResourceAssistance.Button.type.submit}
												variant={ResourceAssistance.Button.variant.primary}
												disabled={this.isAddItemDisabled()}
											>
												<MdAddShoppingCart size={ResourceAssistance.ReactIcon.size} />
												{translate(ResourceAssistance.Message.add)}
											</Button>
										</Col>
									</Row>
								</Col>
								<Col md={3}>
									<Row>
										<Col sm="auto">
											<label>{translate(ResourceAssistance.Message.label)}</label>
										</Col>

										<Col>
											<FormControl
												type={ResourceAssistance.FormControl.type.text}
												onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_LABEL)}
												value={this.props.newPr.selectedLabel}
											></FormControl>
										</Col>
									</Row>

									<Row>
										<Col sm="auto">
											<label>{translate(ResourceAssistance.Message.form)}</label>
										</Col>
										<Col>
											<FormControl
												as={ResourceAssistance.FormControl.as.select}
												disabled={this.props.newPr.selectedItem.index === -1}
												onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_FORM)}
												value={this.props.newPr.selectedForm.id}
											>
												{this.props.newPr.forms.length > 1 && <option value={0}></option>}
												{this.renderProperty(this.props.newPr.forms)}
											</FormControl>
										</Col>
									</Row>

									<Row>
										<Col sm="auto">
											<label>{translate(ResourceAssistance.Message.dose)}</label>
										</Col>
										<Col>
											<FormControl
												as={ResourceAssistance.FormControl.as.select}
												disabled={this.props.newPr.selectedForm.id === 0}
												onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_DOSE)}
												value={this.getDoseId(this.props.newPr.selectedDose.id)}
											>
												{this.props.newPr.doses.length > 1 && <option value={0}></option>}
												{this.renderProperty(this.props.newPr.doses)}
											</FormControl>
										</Col>
									</Row>

									<Row>
										<Col sm="auto">
											<label>{translate(ResourceAssistance.Message.subunit)}</label>
										</Col>
										<Col>
											<FormControl
												as={ResourceAssistance.FormControl.as.select}
												disabled={!this.props.newPr.selectedDose.id}
												onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_UNIT)}
												value={this.props.newPr.selectedUnit.id}
											>
												{this.props.newPr.units.length > 1 && <option value={0}></option>}
												{this.renderProperty(this.props.newPr.units)}
											</FormControl>
										</Col>
									</Row>

									<Row>
										<Col sm="auto">
											<label>{translate(ResourceAssistance.Message.brandNameAndCode)}</label>
										</Col>
										<Col>
											<FormControl
												as={ResourceAssistance.FormControl.as.select}
												disabled={this.props.newPr.selectedUnit.id === 0}
												onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_SUPPLIER)}
												value={this.props.newPr.selectedSupplier.id}
											>
												{this.props.newPr.suppliers.length > 1 && <option value={0}></option>}
												{this.renderProperty(this.props.newPr.suppliers, "brandName")}
											</FormControl>
										</Col>
									</Row>

									<Row className={"g-0 label-info-row"}>
										<Col sm="auto">
											<label>{translate(ResourceAssistance.Message.amount)}</label>
										</Col>
										<Col>
											<InputGroup>
												<FormattedMessage id={ResourceAssistance.Message.setAmount}>
													{(placeholder) => (
														<FormControl
															id={PR_ID_AMOUNT}
															required
															type={ResourceAssistance.FormControl.type.number}
															disabled={this.props.newPr.selectedSupplier.id === 0}
															min="1"
															step="1"
															placeholder={placeholder}
															onChange={(event) => this.onPropertyChange(event, SET_PROPERTY_AMOUNT)}
															value={this.props.newPr.selectedAmount}
														/>
													)}
												</FormattedMessage>
												{this.props.newPr.selectedUnitPrice.id && <InputGroup.Text>{this.getSupplier().minQtyPerOrder}</InputGroup.Text>}
											</InputGroup>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col id={ResourceAssistance.ID.PR.modalNewPr.tableSelectedItem}>
									<ScrollableTable className={ResourceAssistance.CSS.fullSize} striped hover data={this.props.newPr.selectedItems} />
								</Col>
							</Row>
						</Container>
					</Form>
				</ModalBody>

				<ModalFooter>
					<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onCloseBtn}>
						<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.close)}
					</Button>
					<Button
						variant={ResourceAssistance.Button.variant.green}
						onClick={this.onDoneBtn}
						disabled={
							this.props.newPr.selectedItems.body.length === 0 ||
							this.props.pr.location.selectedOrg.index === -1 ||
							this.props.pr.location.selectedBranch.index === -1 ||
							this.isDoneDisabled()
						}
					>
						<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.save)}
					</Button>
				</ModalFooter>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isShow: state.modal.createPR,
	newPr: state.purchaseRequest.newPr,
	original: state.purchaseRequest.newPr.original,
	pr: state.purchaseRequest,
	modal: state.modal,
	prTypes: state.purchaseRequest.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			addNewItemAction,
			onModalDisplayAction,
			loadItemsAction,
			selectItemAction,
			setItemPropertyAction,
			setPrModalTypeAction,
			setPrSelectedBranch,
			setPrNewPrLastPurchasedInfo,
			selectTypeAction,
			setSearchText,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewPR))
