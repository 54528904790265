import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setHospitelModalNurseOrderPendingItems } from "~/redux/action"
import { SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY } from "~/redux/type"
import HospitelModalAdjustmentDetails from "./HospitelModalAdjustmentDetails"

class HospitelModalAdjustment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelModalAdjustment",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_ADJUSTMENT_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		return !this.props.ipd.modalAdjustment.selectedUnit.unit
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		if (window.adjustmentItemCaller) {
			window.adjustmentItemCaller.adjustmentItemCallback()
			window.adjustmentItemCaller = null
		} else {
			let selectedItem = Object.assign({}, this.props.ipd.modalNurseOrder.selectedItem.item, {
				categoryId: this.props.ipd.modalNurseOrder.selectedType.type.id,
				categoryName: this.props.ipd.modalNurseOrder.selectedType.type.description,
				startDateTime: this.props.ipd.modalAdjustment.startDateTime,
				endDateTime: this.props.ipd.modalAdjustment.isDisableEndDate ? null : this.props.ipd.modalAdjustment.endDateTime,
				qtyPerDay: this.props.ipd.modalAdjustment.qtyPerDay,
				unit: this.props.ipd.modalAdjustment.selectedUnit.unit.oneTime
					? this.props.ipd.modalNurseOrder.selectedItem.item.unit.displayName
					: this.props.ipd.modalAdjustment.selectedUnit.unit.displayName,
				itemSupplierUnit: this.props.ipd.modalNurseOrder.selectedItem.item.unit.displayName,
				pricePerUnit: this.props.ipd.modalAdjustment.selectedUnit.unit.oneTime
					? this.props.ipd.modalNurseOrder.selectedItem.item.sellPricePerUnit
					: this.props.ipd.modalAdjustment.selectedUnit.unit.sellingPricePerUnit,
				itemSupplierPricePerUnit: this.props.ipd.modalNurseOrder.selectedItem.item.sellPricePerUnit,
				notes: this.props.ipd.modalAdjustment.notes,
				isTakeHome: this.props.ipd.modalAdjustment.isTakeHome,
			})
			let items = this.props.ipd.modalNurseOrder.pendingItemTable.original
			items.push(selectedItem)
			this.props.setHospitelModalNurseOrderPendingItems(items)
		}
		this.reset()
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{this.props.ipd.modalNurseOrder.selectedItem.item ? this.props.ipd.modalNurseOrder.selectedItem.item.displayName : ""}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<HospitelModalAdjustmentDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.FormControl.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.hospitel.isAdjustmentDisplay,
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setHospitelModalNurseOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalAdjustment))
