import React from "react"

const withScrollToTopOnShow = (WrappedComponent) => {
	return class extends React.Component {
		constructor(props) {
			super(props)
			this.modalBodyRef = React.createRef()
		}

		componentDidUpdate(prevProps) {
			if (this.props.isDisplay && !prevProps.isDisplay && this.modalBodyRef.current) {
				this.modalBodyRef.current.scrollTop = 0
			}
		}

		render() {
			return <WrappedComponent {...this.props} modalBodyRef={this.modalBodyRef} />
		}
	}
}

export default withScrollToTopOnShow
