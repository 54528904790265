import React, { Fragment } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { onModalDisplayAction } from "~/redux/action"
import { SET_CONTRACT_BILLING_LOCATION_DISPLAY } from "~/redux/type"
import ContractBillingLeft from "./left/ContractBillingLeft"
import ContractBillingRight from "./right/ContractBillingRight"

class ContractBilling extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ContractBilling",
		}
	}

	componentDidMount() {
		this.props.onModalDisplayAction(SET_CONTRACT_BILLING_LOCATION_DISPLAY, true)
	}

	render() {
		return (
			<Fragment>
				{this.props.contract.location && (
					<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
						<Row className={"g-0 full-size"}>
							<Col style={{ maxWidth: "437px" }}>
								<ContractBillingLeft />
							</Col>
							<Col>
								<ContractBillingRight />
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	contract: state.accounting.contract,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ContractBilling))
