import { Divider } from "antd"
import React from "react"
import { Button, Col, Container, FormCheck, Row, Spinner } from "react-bootstrap"
import { FaRegEdit } from "react-icons/fa"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmUserDisplayAll,
	setAdmUserModalNURoles,
	setAdmUserRoles,
	setAdmUserSelectedUser,
	setAdmUserUsers,
	setObjArray,
	setSearchText,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_USER_SEARCH_FIRST_NAME,
	SET_ADMIN_USER_SEARCH_LAST_NAME,
	SET_ADMIN_USER_USERS_LOADING,
	SET_ADM_USER_CHANGE_PASSWORD_DISPLAY,
	SET_ADM_USER_NEW_USER_DISPLAY,
	SET_ADM_USER_ROLE_TYPES,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class AdmUser extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmUser",
		}

		this.onSearchFirstName = this.onSearchFirstName.bind(this)
		this.onSearchLastName = this.onSearchLastName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onChangePassword = this.onChangePassword.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		this.props.loadUsers(
			(users) => {
				this.props.setAdmUserUsers(users)
			},
			() => {
				this.props.setValue(SET_ADMIN_USER_USERS_LOADING, true)
			},
			() => {
				this.props.setValue(SET_ADMIN_USER_USERS_LOADING, false)
			},
			() => {
				this.props.setValue(SET_ADMIN_USER_USERS_LOADING, false)
			}
		)
		this.props.loadRoles(
			(roles) => {
				this.props.setAdmUserModalNURoles(roles)
			},
			() => {
				this.props.setValue(SET_ADMIN_USER_USERS_LOADING, true)
			},
			() => {
				this.props.setValue(SET_ADMIN_USER_USERS_LOADING, false)
			},
			() => {
				this.props.setValue(SET_ADMIN_USER_USERS_LOADING, false)
			}
		)
		this.loadRoleTypes()
	}

	componentWillUnmount() {
		this.props.setSearchText(SET_ADMIN_USER_SEARCH_FIRST_NAME, "")
		this.props.setSearchText(SET_ADMIN_USER_SEARCH_LAST_NAME, "")
		this.props.setAdmUserSelectedUser(-1, null)
		this.props.setAdmUserUsers([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.user.searchFirstName !== this.props.user.searchFirstName ||
			prevProps.user.searchLastName !== this.props.user.searchLastName ||
			prevProps.user.displayInactive !== this.props.user.displayInactive
		) {
			this.props.setAdmUserUsers(this.props.user.userTable.original)
		}

		if (prevProps.user.selectedUser.index !== this.props.user.selectedUser.index) {
			this.props.setAdmUserRoles(this.props.user.selectedUser.index === -1 ? [] : this.props.user.selectedUser.user.roles)
		}
	}

	loadRoleTypes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.admin.user.loadRoleTypes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: { excludeInActive: true },
		}
		let callback = (res) => {
			this.props.setObjArray(SET_ADM_USER_ROLE_TYPES, res.data.types)
		}
		axios(
			params,
			callback,
			() => {},
			() => {},
			() => {}
		)
	}

	onSearchFirstName(event) {
		this.props.setSearchText(SET_ADMIN_USER_SEARCH_FIRST_NAME, event.target.value)
	}

	onSearchLastName(event) {
		this.props.setSearchText(SET_ADMIN_USER_SEARCH_LAST_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmUserDisplayAll(event.target.checked)
	}

	onAdd() {
		this.props.setAdmUserSelectedUser(-1, null)
		this.props.onModalDisplayAction(SET_ADM_USER_NEW_USER_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_USER_NEW_USER_DISPLAY, true)
	}

	onChangePassword() {
		this.props.onModalDisplayAction(SET_ADM_USER_CHANGE_PASSWORD_DISPLAY, true)
	}

	onSelectRow(row, index) {
		this.props.setAdmUserSelectedUser(index, this.props.user.userTable.filtered[index])
	}

	render() {
		return (
			<Container fluid="small" className="full-flex">
				<Row>
					<Col>
						<SearchBox
							num={2}
							controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.firstName, ResourceAssistance.Message.lastName]}
							callbacks={[this.onSearchFirstName, this.onSearchLastName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.user.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.user.selectedUser.index === -1} onClick={this.onEdit}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} disabled={this.props.user.selectedUser.index === -1} onClick={this.onChangePassword}>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.changePassword)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							displayAttachment
							isClearHighlight={this.props.user.selectedUser.index === -1}
							data={this.props.user.userTable}
							onClick={this.onSelectRow}
						/>
						{this.props.user.userTable.isLoading && (
							<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
						)}
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize} style={{ maxHeight: "25%" }}>
					<Col>
						<ScrollableTable className={ResourceAssistance.CSS.fullSize} striped enableHighlight data={this.props.user.roleTable} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.admin.userConfig.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmUserDisplayAll,
			setAdmUserModalNURoles,
			setAdmUserRoles,
			setAdmUserSelectedUser,
			setAdmUserUsers,
			setObjArray,
			setSearchText,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmUser)
