import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setAdmBankTypes, setAdminPaymentTypes, setLoadingAction } from "~/redux/action"
import { SET_ADM_CATEGORY_PAYMENT_PAYABLE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AdmPaymentModalAccountsPayableDetails from "./AdmPaymentModalAccountsPayableDetails"

export const EAdmCategoryPaymentWorkflowRadio = {
	PS: {
		description: translate(ResourceAssistance.Message.paymentSlip),
		value: 1,
	},
	FO: {
		description: translate(ResourceAssistance.Message.financialOperations),
		value: 2,
	},
}
class AdmPaymentModalAccountsPayable extends React.Component {
	constructor(props) {
		super(props)

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_CATEGORY_PAYMENT_PAYABLE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (!this.props.coa.modalAccount.selectedAP.account) {
			return true
		}
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.type.savePayableActivities,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				payment: this.props.type.modal.title === ResourceAssistance.Message.payment,
				bank: this.props.type.modal.title === ResourceAssistance.Message.bank,
				userId: this.props.login.user.id,
				activities: [
					{
						id: this.props.coa.selectedAP.activity ? this.props.coa.selectedAP.activity.id : 0,
						categoryId: this.props.type.selectedType.type.id,
						bankBranchId: this.props.type.bank.selectedBranch.branch ? this.props.type.bank.selectedBranch.branch.id : 0,
						chartOfAccountsId: this.props.coa.modalAccount.selectedAP.account.id,
						description: Utils.trim(
							this.props.coa.modalAccount.description.concat(
								" ",
								this.props.coa.modalAccount.isDescriptionIncludesSupplier ? ResourceAssistance.PROGRAM_DEFINED.supplier : "",
								" ",
								this.props.coa.modalAccount.isDescriptionIncludesDate ? ResourceAssistance.PROGRAM_DEFINED.date : ""
							)
						),
						linkGL: this.props.coa.modalAccount.isLinkGL,
						paymentSlip: this.props.coa.modalAccount.workflow === EAdmCategoryPaymentWorkflowRadio.PS.value,
						financialOperations: this.props.coa.modalAccount.workflow === EAdmCategoryPaymentWorkflowRadio.FO.value,
					},
				],
			},
		}
		let callback = (res) => {
			if (this.props.type.modal.title === ResourceAssistance.Message.payment) {
				this.props.setAdminPaymentTypes(res.data.uts)
			} else if (this.props.type.modal.title === ResourceAssistance.Message.bank) {
				this.props.setAdmBankTypes(res.data.uts)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				size={ResourceAssistance.Modal.size.md}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsPayable)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<AdmPaymentModalAccountsPayableDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmCategoryPaymentAccountsPayableDisplay,
	login: state.login,
	coa: state.admin.type.COA,
	type: state.admin.type,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmBankTypes,
			setAdminPaymentTypes,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmPaymentModalAccountsPayable))
