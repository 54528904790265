import propTypes from "prop-types"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { ResourceAssistance } from "~/i18n"
import GInput from "../input/GInput"

class GlucoseLevel extends React.Component {
	render() {
		return (
			<Row className="g-0">
				<Col md={3} />
				<Col>
					<GInput
						required
						styles={{ input: { textAlign: "center" } }}
						placeholder={`${this.props.intl.formatMessage({ id: ResourceAssistance.Message.level })}`}
						pattern="^\d+$"
						suffix={ResourceAssistance.CONSTANT.MG_DL}
						onChange={this.props.onLevel}
						value={this.props.level}
					/>
				</Col>
				<Col md={3} />
			</Row>
		)
	}
}

GlucoseLevel.propTypes = {
	onLevel: propTypes.func.isRequired,
}

GlucoseLevel.defaultProps = {
	onLevel: () => {},
}

export default injectIntl(GlucoseLevel, { forwardRef: true })
