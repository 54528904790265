import { combineReducers } from "redux"
import notificationReducer from "./notificationReducer"
import timelineReducer from "./timelineReducer"
import selectableAccountsCodeReducer from "./selectableAccountsCodeReducer"
import documentReducer from "./documentReducer"

export default combineReducers({
	document: documentReducer,
	notification: notificationReducer,
	selectableAccountsCode: selectableAccountsCodeReducer,
	timeline: timelineReducer,
})
