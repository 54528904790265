import React from "react"
import { Button, Col, FormCheck, Row } from "react-bootstrap"
import { FaTimes } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setPoDisplayAllInd } from "~/redux/action"
import { SET_PO_REJECT_REASON_DISPLAY } from "~/redux/type/type/MODAL"
import { Utils } from "~/utils/Utils"

class POLeftThirdRow extends React.Component {
	constructor(props) {
		super(props)

		this.onReject = this.onReject.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
	}

	onDisplayAll(event) {
		this.props.setPoDisplayAllInd(event.target.checked)
	}

	onReject(event) {
		this.props.onModalDisplayAction(SET_PO_REJECT_REASON_DISPLAY, true)
	}

	isApproved() {
		return this.props.selectedPo.po && this.props.selectedPo.po.inspectorApproved && this.props.selectedPo.po.approvalApproved
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col>
					<FormCheck
						className={ResourceAssistance.CSS.flexSelfCenter}
						type={ResourceAssistance.FormControl.type.checkBox}
						label={translate(ResourceAssistance.Message.displayAll)}
						onChange={this.onDisplayAll}
					/>
				</Col>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Po.Reject, this.props.login.user.roles) && (
					<Col md="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							variant={ResourceAssistance.Button.variant.red}
							disabled={this.props.selectedPo.index === -1 || this.isApproved()}
							onClick={this.onReject}
						>
							<FaTimes size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.reject)}
						</Button>
					</Col>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	type: state.purchaseOrder.type,
	po: state.purchaseOrder,
	selectedPo: state.purchaseOrder.selectedPo,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ setLoadingAction, onModalDisplayAction, setPoDisplayAllInd }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(POLeftThirdRow))
