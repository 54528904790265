import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { IoHourglassOutline } from "react-icons/io5"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING, SET_REPORT_PO_FILTER_BY_ID } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ReportPOByIdFilterDetails from "./ReportPOByIdFilterDetails"

class ReportPOByIdFilter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ReportPOByIdFilter",
		}

		this.onClose = this.onClose.bind(this)
		this.onRun = this.onRun.bind(this)
	}

	runReport(orders) {
		let data = {
			titleIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.purchaseOrderById,
			}),
			idIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.id,
			}),
			poIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.poId,
			}),
			branchIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.branch,
			}),
			poDateTimeIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.date,
			}),
			issuedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.issuedBy,
			}),
			certifiedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.certifiedBy,
			}),
			approvedByIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.approvedBy,
			}),
			memoIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.memoId,
			}),
			supplierIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.supplier,
			}),
			subTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.subTotal,
			}),
			discountIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.discount,
			}),
			totalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.totalPriceDiscounted,
			}),
			vatIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.vat,
			}),
			grandTotalIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.grandTotal,
			}),
			paymentIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.payment,
			}),
			taxIdIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.taxId,
			}),
			uomIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.uom,
			}),
			taxIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.taxRate,
			}),
			descriptionIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.list,
			}),
			qtyIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.qty,
			}),
			bonusIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.bonus,
			}),
			unitPriceIntl: this.props.intl.formatMessage({
				id: ResourceAssistance.Message.unitPrice,
			}),
			arrowIcon: ResourceAssistance.Symbol.arrowIcon,

			//Filter
			id: this.props.filter.searchId,

			// Title
			name: orders.length > 0 ? orders[0].pr.location.parent.displayName : "",
			taxId: orders.length > 0 ? orders[0].pr.location.parent.taxNo : "",
			address: orders.length > 0 ? orders[0].pr.location.address.displayName : "",
			tel: orders.length > 0 ? orders[0].pr.location.address.tel : "",
			fax: orders.length > 0 ? orders[0].pr.location.address.fax : "",

			orders: orders.map((po) => {
				let grandTotal = Utils.BigNumber(0)
				return Object.assign(
					{},
					{
						poId: po.id,
						branch: po.pr.location.displayName,
						poDateTime: Utils.formatDateTime(po.pr.approverApprovedDateTime),
						issuedBy: po.pr.approval.lastName + " " + po.pr.approval.firstName,
						certifiedBy: po.inspectorApproved ? po.inspector.lastName + " " + po.inspector.firstName : "",
						approvedBy: po.approvalApproved ? po.approval.lastName + " " + po.approval.firstName : "",
						suppliers: po.suppliers.map((supplier) => {
							let vat = supplier.items[0].tax
							return Object.assign(
								{},
								{
									memoId: supplier.orderDetail.routingId,
									id: supplier.code,
									supplier: supplier.name,
									subTotal: Utils.formatNumWithComma(
										supplier.items
											.reduce((total, item) => {
												total = total.plus(
													Utils.BigNumber(item.pricePerUnit)
														.times(1 - item.discount / 100)
														.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
												)
												return total
											}, Utils.BigNumber(0))
											.toFixed(2)
									),
									discount: supplier.orderDetail.discount,
									total: Utils.formatNumWithComma(
										supplier.items
											.reduce((total, item) => {
												total = total.plus(
													Utils.BigNumber(item.pricePerUnit)
														.times(1 - item.discount / 100)
														.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
														.times(1 - supplier.orderDetail.discount / 100)
												)
												return total
											}, Utils.BigNumber(0))
											.toFixed(2)
									),
									vat: supplier.items.some((item) => item.tax !== vat) ? 0 : vat,
									grandTotal: Utils.formatNumWithComma(
										supplier.items
											.reduce((total, item) => {
												total = total
													.plus(
														Utils.BigNumber(item.pricePerUnit)
															.times(1 - item.discount / 100)
															.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
															.times(1 - supplier.orderDetail.discount / 100)
													)
													.times(1 + vat / 100)
												grandTotal = grandTotal.plus(total)
												return total
											}, Utils.BigNumber(0))
											.toFixed(2)
									),
									payment: supplier.orderDetail.payment ? supplier.orderDetail.payment.displayName : "",
									items: supplier.items.map((item) => {
										return Object.assign(
											{},
											{
												id: item.code,
												description: item.displayName,
												qty: Utils.formatNumWithComma(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder).toNumber()),
												minQtyPerOrder: Utils.formatNumWithComma(item.minQtyPerOrder),
												unit: item.unit,
												pricePerUnit: Utils.formatNumWithComma(Utils.BigNumber(item.pricePerUnit).toFixed(2)),
												discount: Utils.formatNumWithComma(item.discount),
												bonus: Utils.formatNumWithComma(item.bonus),
												tax: Utils.formatNumWithComma(item.tax),
												total: Utils.formatNumWithComma(
													Utils.BigNumber(item.pricePerUnit)
														.times(1 - item.discount / 100)
														.times(Utils.BigNumber(item.amount).dividedBy(item.minQtyPerOrder))
														.times(1 + item.tax / 100)
														.toFixed(2)
												),
											}
										)
									}),
								}
							)
						}),
						grandTotal: Utils.formatNumWithComma(grandTotal.toFixed(2)),
					}
				)
			}),
		}

		let myWindow = window.open(ResourceAssistance.Path.Report.Po.byId, "_blank")
		myWindow.data = data
	}

	onClose() {
		this.props.onModalDisplayAction(SET_REPORT_PO_FILTER_BY_ID, false)
	}

	onRun(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.report.po.getPO}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				id: this.props.filter.searchId,
			},
		}
		let callback = (res) => {
			this.runReport(res.data.orders)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, () => {}, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onRun}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.reportById)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<ReportPOByIdFilterDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<IoHourglassOutline size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.run)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.report.po.isReportByIdDisplay,
	filter: state.report.po.reportById,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({ onModalDisplayAction, setLoadingAction }, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ReportPOByIdFilter))
