import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setCashierModalFinancialPlanBillingStatements, setSelected } from "~/redux/action"
import { SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class CashierModalFinancialPlanService extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CashierModalFinancialPlanService",
		}

		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		if (this.props.cashier.selectedHealthCarePlan.healthCarePlan) {
			this.props.setCashierModalFinancialPlanBillingStatements(
				this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.cashier.selectedHealthCarePlan, this.props.cashier.selectedHealthCarePlan)) {
			this.props.setSelected(SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE, -1, null)
			if (this.props.cashier.selectedHealthCarePlan.healthCarePlan) {
				let billingStatements = this.props.cashier.selectedHealthCarePlan.healthCarePlan.billingStatements.concat(
					this.props.cashier.modalFinancialPlan.serviceTable.original.filter((billingStatement) => !billingStatement.id)
				)
				this.props.setCashierModalFinancialPlanBillingStatements(billingStatements)
			} else {
				this.props.setCashierModalFinancialPlanBillingStatements([])
			}
		}
	}

	componentWillUnmount() {
		this.props.setCashierModalFinancialPlanBillingStatements([])
		this.props.setSelected(SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE, -1, null)
	}

	onSelectRow(service, index) {
		this.props.setSelected(
			SET_CASHIER_MODAL_FINANCIAL_PLAN_SELECTED_SERVICE,
			index,
			this.props.cashier.modalFinancialPlan.serviceTable.filtered[index]
		)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.cashier.modalFinancialPlan.serviceTable}
						onClick={this.onSelectRow}
						isClearHighlight={this.props.cashier.modalFinancialPlan.selectedService.index === -1}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	cashier: state.hospitel.cashier,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setCashierModalFinancialPlanBillingStatements,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashierModalFinancialPlanService)
