import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { RiFileExcel2Line } from "react-icons/ri"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, resetAction, setLoadingAction, setValue } from "~/redux/action"
import { SET_REPORT_FINANCE_ARA_FILTER_DISPLAY } from "~/redux/type"
import ExcelDocument from "~/report/component/ExcelDocument"
import ReportFinanceARADetails from "./ReportFinanceARADetails"
import { ReportFinanceDataFactory } from "~/utils/factory/report/ReportFinanceDataFactory"

class ReportFinanceARA extends React.Component {
	constructor(props) {
		super(props)

		this.ref = React.createRef()
		this.onClose = this.onClose.bind(this)
		this.onExport = this.onExport.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_REPORT_FINANCE_ARA_FILTER_DISPLAY, false)
	}

	onClose() {
		this.reset()
	}

	onExport() {
		let excel = ReportFinanceDataFactory.generateAccountsReceivableExcelData(
			this.props.filter.accountsReceivableAgings,
			this.props.filter.selectedOrg.org,
			this.props.filter.filterDateTime
		)
		this.ref.current.updateExcel(excel)
		this.ref.current.click()
	}

	render() {
		return (
			<Modal
				centered
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				animation={false}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				show={this.props.isDisplay}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.accountsReceivableAging)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ReportFinanceARADetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} disabled={_.isEmpty(this.props.filter.accountsReceivableAgings)} onClick={this.onExport}>
							<RiFileExcel2Line size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.export)}
						</Button>
						<ExcelDocument ref={this.ref} style={{ visibility: "hidden", display: "none" }} />
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isReportFinanceAccountsReceivableAgingDisplay,
	filter: state.report.finance.accountsReceivableAging,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			resetAction,
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportFinanceARA)
