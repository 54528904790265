import { Divider, Select } from "antd"
import _ from "lodash"
import React, { Fragment } from "react"
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { IoMdDoneAll } from "react-icons/io"
import { MdRemoveDone } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OPD_MODAL_NURSE_INTERVENTION, SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class OPDModalNurseIntervention extends React.Component {
	constructor(props) {
		super(props)

		this.onIntervention = this.onIntervention.bind(this)
		this.onCategoryIntervention = this.onCategoryIntervention.bind(this)
		this.onSelectIntervention = this.onSelectIntervention.bind(this)
		this.onDeselectIntervention = this.onDeselectIntervention.bind(this)
		this.onSelectAllIntervention = this.onSelectAllIntervention.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedNurseNote.nurseNote) {
			this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, this.props.opd.selectedNurseNote.nurseNote.intervention)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, "")
		this.props.setSelected(SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY, -1, null)
	}

	getValues() {
		let values = []
		if (this.props.opd.modalNurse.selectedInterventionCategory.category) {
			this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.forEach((intervention, idx) => {
				if (_.includes(this.props.opd.modalNurse.intervention, intervention.description)) {
					values.push(idx)
				}
			})
		}
		return values
	}

	modifyIntervention(intervention, isAdd) {
		let value = this.props.opd.modalNurse.intervention
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : "\n", intervention)
		} else {
			intervention.split("\n").forEach((each) => {
				value = value.replace(each, "")
			})
		}
		value = Utils.replaceDuplicateEmptyLine(value)
		this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, Utils.trim(value))
	}

	onCategoryIntervention(value) {
		this.props.setSelected(
			SET_OPD_MODAL_NURSE_INTERVENTION_SELECTED_CATEGORY,
			value > -1 ? Number(value) : -1,
			value > -1 ? this.props.opd.modalNurse.categories[value] : null
		)
	}

	onSelectIntervention(value) {
		this.modifyIntervention(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions[value].description, true)
	}

	onSelectAllIntervention(event) {
		let interventions = ""
		this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.forEach((each) => {
			if (!_.includes(this.props.opd.modalNurse.intervention, each.description)) {
				interventions = interventions.concat(each.description, "\n")
			}
		})
		this.modifyIntervention(
			!_.isEmpty(interventions)
				? interventions
				: this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.reduce((total, cur) => {
						return (total = total.concat(cur.description, "\n"))
				  }, ""),
			!_.isEmpty(interventions)
		)
	}

	onDeselectIntervention(value) {
		this.modifyIntervention(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions[value].description, false)
	}

	onIntervention(event) {
		this.props.setValue(SET_OPD_MODAL_NURSE_INTERVENTION, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Hospitel.intervention)}</legend>
				<Container fluid="small" className="full-flex">
					<Row className="g-1">
						<Col md={3}>
							<Select
								showSearch
								size={ResourceAssistance.Select.size.small}
								style={{ flexGrow: 1, textAlign: "center" }}
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
								placeholder={translate(ResourceAssistance.Message.default)}
								value={
									this.props.opd.modalNurse.selectedInterventionCategory.category ? this.props.opd.modalNurse.selectedInterventionCategory.index : undefined
								}
								onChange={this.onCategoryIntervention}
							>
								{Utils.renderSelects(this.props.opd.modalNurse.categories, false)}
							</Select>
						</Col>
						<Col>
							<Select
								mode={ResourceAssistance.Select.mode.multiple}
								size={ResourceAssistance.Select.size.small}
								style={{ flexGrow: 1, textAlign: "center" }}
								disabled={!this.props.opd.modalNurse.selectedInterventionCategory.category}
								value={this.getValues()}
								maxTagCount="responsive"
								optionFilterProp="children"
								filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								filterSort={(optionA, optionB) => Utils.sort(optionA.children, optionB.children)}
								onSelect={this.onSelectIntervention}
								onDeselect={this.onDeselectIntervention}
								dropdownRender={(menu) => (
									<Fragment>
										{menu}
										{this.props.opd.modalNurse.selectedInterventionCategory.category &&
											!_.isEmpty(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions) && (
												<Fragment>
													<Divider style={{ margin: "4px 0" }} />
													<Row>
														<Col />
														<Col md="auto">
															<Button
																variant={ResourceAssistance.Button.variant.primary}
																disabled={
																	!this.props.opd.modalNurse.selectedInterventionCategory.category ||
																	_.isEmpty(this.props.opd.modalNurse.selectedInterventionCategory.category.interventions)
																}
																onMouseDown={(e) => e.preventDefault()}
																onClick={this.onSelectAllIntervention}
															>
																{this.props.opd.modalNurse.selectedInterventionCategory.category &&
																this.props.opd.modalNurse.selectedInterventionCategory.category.interventions.every((intervention) =>
																	this.props.opd.modalNurse.intervention.includes(intervention.description)
																) ? (
																	<Fragment>
																		<MdRemoveDone size={ResourceAssistance.ReactIcon.size} />
																		{translate(ResourceAssistance.Message.deselectAll)}
																	</Fragment>
																) : (
																	<Fragment>
																		<IoMdDoneAll size={ResourceAssistance.ReactIcon.size} />
																		{translate(ResourceAssistance.Message.selectAll)}
																	</Fragment>
																)}
															</Button>
														</Col>
														<Col />
													</Row>
												</Fragment>
											)}
									</Fragment>
								)}
							>
								{Utils.renderSelects(
									this.props.opd.modalNurse.selectedInterventionCategory.category
										? this.props.opd.modalNurse.selectedInterventionCategory.category.interventions
										: [],
									false,
									-1,
									"description"
								)}
							</Select>
						</Col>
					</Row>
					<Row className="full-size">
						<Col>
							<InputGroup>
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={this.props.opd.selectedNurseNote.nurseNote ? 6 : 4}
									value={this.props.opd.modalNurse.intervention}
									onChange={this.onIntervention}
								/>
							</InputGroup>
						</Col>
					</Row>
				</Container>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalNurseIntervention))
