import {
	SET_REPORT_HOSPITAL_LAB_COVID19_END_DATETIME,
	SET_REPORT_HOSPITAL_LAB_COVID19_START_DATETIME,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_PCR,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_ANTIGEN,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_POSITIVE,
	SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_NEGATIVE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"

const init = {
	covid19: {
		startDateTime: Utils.generateDate().getTime(),
		endDateTime: Utils.generateDate(0, 0, 0, 23, 59, 59).getTime(),
		includePCR: true,
		includeAntigen: false,
		includePositive: true,
		includeNegative: false,
	},
}

const reportLabReducer = (state = init, action) => {
	switch (action.type) {
		case SET_REPORT_HOSPITAL_LAB_COVID19_START_DATETIME:
			return Object.assign({}, state, {
				covid19: {
					...state.covid19,
					startDateTime: action.payload.date,
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_END_DATETIME:
			return Object.assign({}, state, {
				covid19: {
					...state.covid19,
					endDateTime: action.payload.date,
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_PCR:
			return Object.assign({}, state, {
				covid19: {
					...state.covid19,
					includePCR: action.payload.value,
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_ANTIGEN:
			return Object.assign({}, state, {
				covid19: {
					...state.covid19,
					includeAntigen: action.payload.value,
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_POSITIVE:
			return Object.assign({}, state, {
				covid19: {
					...state.covid19,
					includePositive: action.payload.value,
				},
			})

		case SET_REPORT_HOSPITAL_LAB_COVID19_INCLUDE_NEGATIVE:
			return Object.assign({}, state, {
				covid19: {
					...state.covid19,
					includeNegative: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default reportLabReducer
