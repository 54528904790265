import _ from "lodash"
import propTypes from "prop-types"
import React from "react"
import { Container } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import PupilSizeDate from "./PupilSizeDate"
import PupilSizeInput from "./PupilSizeInput"
import PupilSizeRemark from "./PupilSizeRemark"

class PupilSize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			datetime: "",
			left: "",
			right: "",
			leftReactionToLight: "",
			rightReactionToLight: "",
		}
		this.onDateChange = this.onDateChange.bind(this)
		this.onLeftChange = this.onLeftChange.bind(this)
		this.onRightChange = this.onRightChange.bind(this)
		this.onLeftReaction = this.onLeftReaction.bind(this)
		this.onRightReaction = this.onRightReaction.bind(this)
	}

	componentDidMount() {
		if (this.props.pupilSize) {
			this.setState({
				datetime: this.props.pupilSize.datetime,
				left: this.props.pupilSize.left,
				right: this.props.pupilSize.right,
				leftReactionToLight: this.props.pupilSize.leftReactionToLight,
				rightReactionToLight: this.props.pupilSize.rightReactionToLight,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState, this.state)) {
			this.props.onRenderParentCallback()
		}
	}

	onDateChange(dateObj) {
		this.setState({
			datetime: dateObj ? dateObj.valueOf() : "",
		})
	}

	onLeftChange(e) {
		this.setState({
			left: e.target.value,
		})
	}

	onLeftReaction(e) {
		this.setState({
			leftReactionToLight: e.target.value,
		})
	}

	onRightChange(e) {
		this.setState({
			right: e.target.value,
		})
	}

	onRightReaction(e) {
		this.setState({
			rightReactionToLight: e.target.value,
		})
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<PupilSizeDate {...this.props} {...this.state} onDateChange={this.onDateChange} />
				<PupilSizeInput
					{...this.props}
					{...this.state}
					onLeftChange={this.onLeftChange}
					onLeftReaction={this.onLeftReaction}
					onRightChange={this.onRightChange}
					onRightReaction={this.onRightReaction}
				/>
				<PupilSizeRemark />
			</Container>
		)
	}
}

PupilSize.propTypes = {
	pupilSize: propTypes.object,
}

PupilSize.defaultProps = {
	pupilSize: null,
}

export default PupilSize
