import _ from "lodash"
import React from "react"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { setIPDPupilSizes } from "~/redux/action"
import IPDRightPupilSizeChart from "./IPDRightPupilSizeChart"
import IPDRightPupilSizeTable from "./IPDRightPupilSizeTable"

class IPDRightPupilSize extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "IPDRightPupilSize",
		}
	}

	componentDidMount() {
		if (this.props.ipd.selectedHospitel.hospitel) {
			this.props.setIPDPupilSizes(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.pupilSizes)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(prevProps.ipd.selectedHospitel, this.props.ipd.selectedHospitel) ||
			!_.isEqual(prevProps.ipd.orderEndDateTime, this.props.ipd.orderEndDateTime)
		) {
			if (this.props.ipd.selectedHospitel.hospitel) {
				this.props.setIPDPupilSizes(this.props.ipd.selectedHospitel.hospitel.admission.clinicalRecord.pupilSizes)
			} else {
				this.props.setIPDPupilSizes([])
			}
		}
	}

	render() {
		return this.props.graphic ? <IPDRightPupilSizeChart /> : <IPDRightPupilSizeTable />
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setIPDPupilSizes,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDRightPupilSize))
