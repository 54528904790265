import _ from "lodash"
import React, { Fragment } from "react"
import { Container } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import ICDAction from "./ICDAction"
import ICDDiagnosis from "./ICDDiagnosis"
import ICDLevel from "./ICDLevel"
import ICDPendingDiagnosis from "./ICDPendingDiagnosis"
import ICDType from "./ICDType"
import propTypes from "prop-types"

class ICD extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLevelDisplay: false,
			searchCode: "",
			searchName: "",
			pendingICDs: [],
			selectedType: {
				index: -1,
				type: null,
			},
			selectedICD: {
				index: -1,
				icd: null,
			},
			selectedPendingICD: {
				index: -1,
				icd: null,
			},
			selectedLevel: {
				index: -1,
				level: null,
			},
		}
		this.onType = this.onType.bind(this)
		this.onSearchCode = this.onSearchCode.bind(this)
		this.onSearchName = this.onSearchName.bind(this)
		this.onSelectICD = this.onSelectICD.bind(this)
		this.onSelectPendingICD = this.onSelectPendingICD.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onLevelClose = this.onLevelClose.bind(this)
		this.onLevelSave = this.onLevelSave.bind(this)
		this.onLevelType = this.onLevelType.bind(this)
	}

	componentDidMount() {
		this.setState({
			selectedType: {
				index: _.isEmpty(this.props.types) ? -1 : 0,
				type: _.isEmpty(this.props.types) ? null : this.props.types[0],
			},
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevState.selectedType, this.state.selectedType)) {
			this.setState({
				selectedICD: {
					index: -1,
					icd: null,
				},
			})
		}
	}

	onType(event) {
		this.setState({
			selectedType: {
				index: event.target.value,
				type: this.props.types[event.target.value],
			},
		})
	}

	onSearchCode(event) {
		this.setState({
			searchCode: event.target.value,
		})
	}

	onSearchName(event) {
		this.setState({
			searchName: event.target.value,
		})
	}

	onSelectICD(icd, index) {
		this.setState({
			selectedICD: {
				index: index,
				icd: icd,
			},
		})
	}

	onSelectPendingICD(icd, index) {
		this.setState({
			selectedPendingICD: {
				index: index,
				icd: icd,
			},
		})
	}

	onDownBtn(event) {
		this.setState({
			isLevelDisplay: true,
		})
	}

	onUpBtn(event) {
		let pendingICDs = this.state.pendingICDs.filter((each) => each.id !== this.state.selectedPendingICD.icd.id)
		this.setState({
			pendingICDs: pendingICDs,
		})
	}

	onLevelType(event) {
		this.setState({
			selectedLevel: {
				index: event.target.value,
				level: this.props.levelTypes[event.target.value],
			},
		})
	}

	onLevelClose() {
		this.setState({
			isLevelDisplay: false,
		})
	}

	onLevelSave(event) {
		event.preventDefault()
		event.stopPropagation()
		let icd = Object.assign({}, this.state.selectedICD.icd, {
			type: this.state.selectedType.type.displayName,
			typeDescription: this.state.selectedType.type.description,
			level: this.state.selectedLevel.level.displayName,
			levelDescription: this.state.selectedLevel.level.description,
		})
		let pendingICDs = this.state.pendingICDs.filter((each) => each.id !== this.state.selectedICD.icd.id)
		pendingICDs.push(icd)
		this.setState({
			pendingICDs: pendingICDs,
		})
		this.onLevelClose()
	}

	render() {
		return (
			<Fragment>
				<Container fluid className={ResourceAssistance.CSS.fullFlex}>
					<ICDType
						{...this.state}
						{...this.props}
						onSearchCode={this.onSearchCode}
						onSearchName={this.onSearchName}
						onType={this.onType}
					/>
					<ICDDiagnosis {...this.state} {...this.props} onSelectICD={this.onSelectICD} />
					<ICDAction {...this.state} {...this.props} onDownBtn={this.onDownBtn} onUpBtn={this.onUpBtn} />
					<ICDPendingDiagnosis {...this.state} {...this.props} onSelectPendingICD={this.onSelectPendingICD} />
				</Container>
				<ICDLevel
					{...this.state}
					{...this.props}
					isDisplay={this.state.isLevelDisplay}
					onType={this.onLevelType}
					onSave={this.onLevelSave}
					onClose={this.onLevelClose}
				/>
			</Fragment>
		)
	}
}

ICD.propTypes = {
	types: propTypes.array,
	levelTypes: propTypes.array,
}

ICD.defaultProps = {
	types: [],
	levelTypes: [],
}

export default ICD
