import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setFOModalNewPendingPaymentPlans, setSelected } from "~/redux/action"
import { SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class FOModalNewPendingPaymentPlan extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectPaymentPlan = this.onSelectPaymentPlan.bind(this)
	}

	componentDidMount() {
		if (this.props.FO.selectedFinancialPlan.financialPlan) {
			this.props.setFOModalNewPendingPaymentPlans(this.props.FO.selectedFinancialPlan.financialPlan.paymentPlans)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!_.isEqual(
				prevProps.FO.modalNew.pendingPaymentPlanTable.original,
				this.props.FO.modalNew.pendingPaymentPlanTable.original
			)
		) {
			this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setFOModalNewPendingPaymentPlans([])
		this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN, -1, null)
	}

	onSelectPaymentPlan(service, index) {
		this.props.setSelected(
			SET_FO_MODAL_NEW_SELECTED_PENDING_PAYMENT_PLAN,
			index,
			this.props.FO.modalNew.pendingPaymentPlanTable.filtered[index]
		)
	}

	render() {
		let total = this.props.FO.modalNew.pendingPaymentPlanTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[7]))
		}, Utils.BigNumber(0))
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectPaymentPlan}
						data={this.props.FO.modalNew.pendingPaymentPlanTable}
						isClearHighlight={this.props.FO.modalNew.selectedPendingPaymentPlan.index === -1}
						highlightedRow={this.props.FO.modalNew.selectedPendingPaymentPlan.index}
						extra={
							!_.isEmpty(this.props.FO.modalNew.pendingPaymentPlanTable.filtered) && (
								<Row className={"g-0"}>
									<Col />
									<Col md={3}>
										<Descriptions
											size="small"
											contentStyle={{
												fontStyle: "italic",
												fontWeight: "normal",
												textAlign: "right",
												paddingRight: "1rem",
												display: "unset",
											}}
											column={1}
										>
											<Descriptions.Item label={translate(ResourceAssistance.Message.totalPriceDiscountedTaxed)}>
												{Utils.formatNumWithComma(total.toFixed(2))}
											</Descriptions.Item>
										</Descriptions>
									</Col>
									<Col md={1} />
								</Row>
							)
						}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setFOModalNewPendingPaymentPlans,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FOModalNewPendingPaymentPlan))
