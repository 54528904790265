import { ResourceAssistance } from "~/i18n"
import { SET_TIMELINE_PATIENT, SET_TIMELINE_PATIENTS, SET_TIMELINE_SELECTED_PATIENT } from "~/redux/type"

const init = {
	patient: null,
	modalSearch: {
		selectedPatient: {
			index: -1,
			patient: null,
		},
		patientTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Hospitel.patientId,
				ResourceAssistance.Hospitel.identification,
				ResourceAssistance.Message.firstName,
				ResourceAssistance.Message.lastName,
				ResourceAssistance.Hospitel.dateOfBirth,
			],
			body: [],
		},
	},
}

const timelineReducer = (state = init, action) => {
	switch (action.type) {
		case SET_TIMELINE_PATIENTS:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					patientTable: {
						...state.modalSearch.patientTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
					},
				},
			})
		case SET_TIMELINE_SELECTED_PATIENT:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					selectedPatient: {
						index: action.payload.index,
						patient: action.payload.selected,
					},
				},
			})
		case SET_TIMELINE_PATIENT:
			return Object.assign({}, state, {
				patient: action.payload.value,
			})
		default:
			return state
	}
}

export default timelineReducer
