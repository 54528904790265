import React from "react"
import { Col, Row } from "react-bootstrap"
import { FiCheckSquare } from "react-icons/fi"
import { connect } from "react-redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { selectPrItemAction, setLoadingAction } from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PRRightSecondRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PRRightSecondRow",
		}

		this.onChecked = this.onChecked.bind(this)
		this.onSelectItemClick = this.onSelectItemClick.bind(this)
	}

	componentDidUpdate() {
		if (Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Approval, this.props.login.user.roles)) {
			if (this.props.pr.approver.approverApproved && this.props.pr.prItemsTableData.pendingItems.length > 0) {
				this.props.disapprove(
					ResourceAssistance.PurchaseRequest.RightPannel.Value.approver,
					ResourceAssistance.PurchaseRequest.RightPannel.Value.isApproverApproved,
					ResourceAssistance.PurchaseRequest.RightPannel.Value.approverApprovedDate
				)
				this.updateApproval(ResourceAssistance.PurchaseRequest.RightPannel.Value.isApproverApproved)
			}
			return
		} else if (Utils.hasPrivilege(ResourceAssistance.Privilege.Management.Pr.Inspector, this.props.login.user.roles)) {
			if (this.props.pr.approver.inspectorApproved && this.props.pr.prItemsTableData.pendingItems.length > 0) {
				this.props.disapprove(
					ResourceAssistance.PurchaseRequest.RightPannel.Value.inspector,
					ResourceAssistance.PurchaseRequest.RightPannel.Value.isInspectorApproved,
					ResourceAssistance.PurchaseRequest.RightPannel.Value.inspectorApprovedDate
				)
				this.updateApproval(ResourceAssistance.PurchaseRequest.RightPannel.Value.isInspectorApproved)
			}
			return
		}
	}

	isDisplayApprove() {
		return this.props.pr.approver && this.props.pr.approver.requesterApproved
	}

	updateApproval(approver) {
		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.updatePR}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: Object.assign({}, this.props.selectedPR.pr, {
				[approver]: false,
			}),
		}
		let callback = (res) => {
			this.props.updateOriginalOrders(res)
		}

		axios(params, callback)
	}

	onChecked(event, row, rIndex, cIndex) {
		let params = {
			method: "POST",
			url: `${this.props.location.pathname}${ResourceAssistance.Url.pr.setOrderItemSelection}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: JSON.stringify({
				id: this.props.pr.selectedPR.pr ? this.props.pr.selectedPR.pr.id : -1,
				userId: this.props.login.user.id,
				items: [
					{
						id: this.props.pr.prItemsTableData.pendingItems[rIndex].id,
						current: this.props.pr.prItemsTableData.pendingItems[rIndex].current,
						min: this.props.pr.prItemsTableData.pendingItems[rIndex].min,
						max: this.props.pr.prItemsTableData.pendingItems[rIndex].max,
						selected: true,
					},
				],
			}),
		}

		let callback = (res) => {
			this.props.updateOriginalOrders(res)
			this.props.selectPrItemAction(-1, null)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectItemClick(row, index) {
		let item = this.props.pr.prItemsTableData.pendingItems[index]
		this.props.selectPrItemAction(index, item)
	}

	render() {
		return (
			<Row id={this.state.componentName}>
				<Col>
					<fieldset>
						<legend>{translate(ResourceAssistance.Message.requestedInventories)}</legend>
						<ScrollableTable
							striped
							hover
							responsive
							displayBtn
							buttonColor={ResourceAssistance.Button.variant.outlineGreen}
							btnIcon={this.isDisplayApprove() ? <FiCheckSquare size={ResourceAssistance.ReactIcon.size} /> : undefined}
							enableHighlight
							enableOverlay
							onChecked={this.onChecked}
							onClick={this.onSelectItemClick}
							data={this.props.pr.prItemsTableData}
							isClearHighlight={this.props.pr.selectedPrItem.index === -1}
							isCheckBoxDisabled={!this.props.pr.approver.requesterApproved}
						/>
					</fieldset>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	pr: state.purchaseRequest,
	selectedPR: state.purchaseRequest.selectedPR,
})

export default connect(mapStateToProps, { selectPrItemAction, setLoadingAction })(PRRightSecondRow)
