import React, { Fragment } from "react"
import { Button, Col, FormCheck, Row } from "react-bootstrap"
import { MdLibraryAdd } from "react-icons/md"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmRoleDisplayAll,
	setAdmRoleModalNRPermissions,
	setAdmRolePermissions,
	setAdmRoleRoles,
	setAdmRoleSelectedRole,
	setSearchText,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING,
	SET_ADMIN_ROLE_ROLES_LOADING,
	SET_ADMIN_ROLE_SEARCH_NAME,
	SET_ADM_ROLE_NEW_ROLE_DISPLAY,
} from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"
import { FaRegEdit } from "react-icons/fa"
import { Divider } from "antd"

class AdmRole extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "AdmRole",
		}

		this.onSearchName = this.onSearchName.bind(this)
		this.onDisplayAll = this.onDisplayAll.bind(this)
		this.onAdd = this.onAdd.bind(this)
		this.onEdit = this.onEdit.bind(this)
		this.onSelectRow = this.onSelectRow.bind(this)
	}

	componentDidMount() {
		this.props.loadRoles(
			(roles) => {
				this.props.setAdmRoleRoles(roles)
			},
			() => {
				this.props.setValue(SET_ADMIN_ROLE_ROLES_LOADING, true)
			},
			() => {
				this.props.setValue(SET_ADMIN_ROLE_ROLES_LOADING, false)
			},
			() => {
				this.props.setValue(SET_ADMIN_ROLE_ROLES_LOADING, false)
			},
			false
		)

		this.props.loadPermissions(
			(permissions) => {
				this.props.setAdmRoleModalNRPermissions(permissions)
			},
			() => {
				this.props.setValue(SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING, true)
			},
			() => {
				this.props.setValue(SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING, false)
			},
			() => {
				this.props.setValue(SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS_LOADING, false)
			}
		)
	}

	componentWillUnmount() {
		this.props.setSearchText(SET_ADMIN_ROLE_SEARCH_NAME, "")
		this.props.setAdmRoleSelectedRole(-1, null)
		this.props.setAdmRoleRoles([])
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.role.searchName !== this.props.role.searchName ||
			prevProps.role.displayInactive !== this.props.role.displayInactive
		) {
			this.props.setAdmRoleRoles(this.props.role.roleTable.original)
		}

		if (prevProps.role.selectedRole.index !== this.props.role.selectedRole.index) {
			this.props.setAdmRolePermissions(
				this.props.role.selectedRole.index === -1 ? [] : this.props.role.selectedRole.role.permissions
			)
		}
	}

	onSearchName(event) {
		this.props.setSearchText(SET_ADMIN_ROLE_SEARCH_NAME, event.target.value)
	}

	onDisplayAll(event) {
		this.props.setAdmRoleDisplayAll(event.target.checked)
	}

	onAdd() {
		this.props.setAdmRoleSelectedRole(-1, null)
		this.props.onModalDisplayAction(SET_ADM_ROLE_NEW_ROLE_DISPLAY, true)
	}

	onEdit() {
		this.props.onModalDisplayAction(SET_ADM_ROLE_NEW_ROLE_DISPLAY, true)
	}

	onSelectRow(row, index) {
		this.props.setAdmRoleSelectedRole(index, this.props.role.roleTable.filtered[index])
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onSearchName]}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<FormCheck
							type={ResourceAssistance.FormControl.type.checkBox}
							label={translate(ResourceAssistance.Message.displayAll)}
							checked={this.props.role.displayInactive}
							onChange={this.onDisplayAll}
						/>
					</Col>
					<Col sm="auto">
						<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
					</Col>
					<Col sm="auto">
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onAdd}>
							<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.add)}
						</Button>
					</Col>
					<Col sm="auto">
						<Button
							size={ResourceAssistance.Button.size.sm}
							disabled={this.props.role.selectedRole.index === -1}
							onClick={this.onEdit}
						>
							<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.edit)}
						</Button>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							isClearHighlight={this.props.role.selectedRole.index === -1}
							data={this.props.role.roleTable}
							onClick={this.onSelectRow}
						/>
					</Col>
				</Row>
				<Row className={ResourceAssistance.CSS.fullSize} style={{ maxHeight: "25%" }}>
					<Col>
						<ScrollableTable
							className={ResourceAssistance.CSS.fullSize}
							striped
							enableHighlight
							data={this.props.role.permissionTable}
						/>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	role: state.admin.userConfig.role,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSearchText,
			onModalDisplayAction,
			setAdmRoleRoles,
			setAdmRoleDisplayAll,
			setAdmRoleSelectedRole,
			setAdmRoleModalNRPermissions,
			setAdmRolePermissions,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(AdmRole)
