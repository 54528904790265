import { message } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { FcDownload, FcUpload } from "react-icons/fc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setOPDModalDoctorOrderItems, setOPDModalDoctorOrderPendingItems, setSelected } from "~/redux/action"
import { SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM } from "~/redux/type"
import { BigNumberRoundingMode, Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import OutpatientModalDoctorDuration from "./OutpatientModalDoctorDuration"

class OutpatientModalDoctorItems extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectItem = this.onSelectItem.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onKeyPress = this.onKeyPress.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.opd.modalDoctorOrder.searchDescription !== this.props.opd.modalDoctorOrder.searchDescription ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedType, this.props.opd.modalDoctorOrder.selectedType) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.pharmacyInventories, this.props.opd.modalDoctorOrder.pharmacyInventories)
		) {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
			this.props.setOPDModalDoctorOrderItems(this.props.opd.modalDoctorOrder.itemTable.original)
		}

		if (
			prevProps.opd.modalDoctorOrder.startDateTime !== this.props.opd.modalDoctorOrder.startDateTime ||
			prevProps.opd.modalDoctorOrder.endDateTime !== this.props.opd.modalDoctorOrder.endDateTime ||
			prevProps.opd.modalDoctorOrder.duration !== this.props.opd.modalDoctorOrder.duration ||
			prevProps.opd.modalDoctorOrder.durationQty !== this.props.opd.modalDoctorOrder.durationQty ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedDuration.duration, this.props.opd.modalDoctorOrder.selectedDuration.duration) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedUnit, this.props.opd.modalDoctorOrder.selectedUnit) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.selectedRate, this.props.opd.modalDoctorOrder.selectedRate) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.rateDuration, this.props.opd.modalDoctorOrder.rateDuration) ||
			!_.isEqual(prevProps.opd.modalDoctorOrder.exclusiveDays, this.props.opd.modalDoctorOrder.exclusiveDays)
		) {
			if (
				this.props.opd.modalDoctorOrder.durationQty &&
				(this.props.opd.modalDoctorOrder.duration || this.props.opd.modalDoctorOrder.isOneTime) &&
				this.props.opd.modalDoctorOrder.selectedUnit.unit &&
				this.props.opd.modalDoctorOrder.selectedRate.rate &&
				Utils.BigNumber(this.props.opd.modalDoctorOrder.rateDuration).gte(1) &&
				Utils.BigNumber(this.props.opd.modalDoctorOrder.selectedRate.rate.value)
					.times(this.props.opd.modalDoctorOrder.rateDuration)
					.gte(Utils.BigNumber(this.props.opd.modalDoctorOrder.selectedDuration.duration.value).times(this.props.opd.modalDoctorOrder.duration))
			) {
				message.destroy()
				message.warning(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.wrongFrequency }), 10)
			} else if (
				this.props.opd.modalDoctorOrder.durationQty &&
				(this.props.opd.modalDoctorOrder.duration || this.props.opd.modalDoctorOrder.isOneTime) &&
				this.props.opd.modalDoctorOrder.selectedUnit.unit &&
				(!this.props.opd.modalDoctorOrder.selectedRate.rate || !_.isEmpty(this.props.opd.modalDoctorOrder.rateDuration)) &&
				!this.validatePharmacyInventory()
			) {
				message.destroy()
				message.warning(this.props.intl.formatMessage({ id: ResourceAssistance.Warning.pharmacyCannotMeetDoctorOrder }), 10)
			} else {
				message.destroy()
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	checkPharmacyInventoryAvaliability(item) {
		let pharmacyInventory = Utils.findPharmacyInventory(item.code, undefined, true, this.props.opd.modalDoctorOrder.pharmacyInventories)
		return pharmacyInventory !== undefined
	}

	validatePharmacyInventory() {
		if (
			_.isEmpty(this.props.opd.modalDoctorOrder.durationQty) ||
			(_.isEmpty(this.props.opd.modalDoctorOrder.duration) && !this.props.opd.modalDoctorOrder.isOneTime) ||
			!this.props.opd.modalDoctorOrder.selectedUnit.unit ||
			(this.props.opd.modalDoctorOrder.selectedRate.rate && _.isEmpty(this.props.opd.modalDoctorOrder.rateDuration)) ||
			(this.props.opd.modalDoctorOrder.selectedRate.rate &&
				!_.isEmpty(this.props.opd.modalDoctorOrder.rateDuration) &&
				Utils.BigNumber(this.props.opd.modalDoctorOrder.selectedRate.rate.value)
					.times(this.props.opd.modalDoctorOrder.rateDuration)
					.gte(Utils.BigNumber(this.props.opd.modalDoctorOrder.selectedDuration.duration.value).times(this.props.opd.modalDoctorOrder.duration)))
		) {
			return false
		}

		let qty = Utils.calculateDoctorOrderQtyByRate(
			this.props.opd.modalDoctorOrder.durationQty,
			this.props.opd.modalDoctorOrder.rateDuration,
			this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
			this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
		)
		let doctorOrderQty = this.props.opd.modalDoctorOrder.isOneTime
			? qty.dp(0, BigNumberRoundingMode.ROUND_UP)
			: Utils.calculateDoctorOrderQty(
					{
						...this.props.opd.modalDoctorOrder.selectedItem.item,
						startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
						endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
						duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration)
							.times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value)
							.toNumber(),
						durationQty: qty.toNumber(),
						exclusiveDays: this.props.opd.modalDoctorOrder.exclusiveDays,
					},
					this.props.opd.modalDoctorOrder.startDateTime,
					this.props.opd.modalDoctorOrder.endDateTime
			  )

		let pharmacyInventory = Utils.findPharmacyInventory(
			this.props.opd.modalDoctorOrder.selectedItem.item.code,
			this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName,
			this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime,
			this.props.opd.modalDoctorOrder.pharmacyInventories
		)
		return pharmacyInventory && doctorOrderQty > 0 && pharmacyInventory.totalAmount >= doctorOrderQty
	}

	onKeyPress(e) {
		if (e.key === "Enter" || e.keyCode === 13) {
			e.preventDefault()
		}
	}

	onDownBtn(event) {
		event.preventDefault()
		event.stopPropagation()
		let qty = Utils.calculateDoctorOrderQtyByRate(
			this.props.opd.modalDoctorOrder.durationQty,
			this.props.opd.modalDoctorOrder.rateDuration,
			this.props.opd.modalDoctorOrder.selectedUnit.unit.amount,
			this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
		)
		let selectedItem = this.props.opd.modalDoctorOrder.selectedItem.item
		let sellPricePerUnit
		if (this.props.opd.modalDoctorOrder.isNonDispensingRx) {
			sellPricePerUnit = 0.0
		} else {
			sellPricePerUnit = this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
				? selectedItem.sellPricePerUnit
				: this.props.opd.modalDoctorOrder.selectedUnit.unit.sellingPricePerUnit
		}
		let newItem = Object.assign({}, selectedItem, {
			...selectedItem,
			startDateTime: this.props.opd.modalDoctorOrder.startDateTime,
			endDateTime: this.props.opd.modalDoctorOrder.endDateTime,
			notes: this.props.opd.modalDoctorOrder.notes,
			categoryId: this.props.opd.modalDoctorOrder.selectedType.type.id,
			categoryName: this.props.opd.modalDoctorOrder.selectedType.type.description,
			durationQty: this.props.opd.modalDoctorOrder.isOneTime ? qty.dp(0, BigNumberRoundingMode.ROUND_UP) : qty.toNumber(),
			duration: Utils.BigNumber(this.props.opd.modalDoctorOrder.duration).times(this.props.opd.modalDoctorOrder.selectedDuration.duration.value).toNumber(),
			unit: this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime
				? selectedItem.unit
				: { displayName: this.props.opd.modalDoctorOrder.selectedUnit.unit.displayName },
			sellPricePerUnit: sellPricePerUnit,
			isPRN: this.props.opd.modalDoctorOrder.isPRN,
			isSTAT: this.props.opd.modalDoctorOrder.isSTAT,
			isNonDispensingRx: this.props.opd.modalDoctorOrder.isNonDispensingRx,
			isTakeHome: !this.props.opd.modalDoctorOrder.isSTAT,
			isOneTimeOnly: this.props.opd.modalDoctorOrder.isOneTime,
			isNonDisposable: this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime !== undefined && !this.props.opd.modalDoctorOrder.selectedUnit.unit.oneTime,
			exclusiveDays: this.props.opd.modalDoctorOrder.exclusiveDays.toString(),
		})
		let pendingItems = Array.from(this.props.opd.modalDoctorOrder.pendingItemTable.filtered)
		pendingItems.push(newItem)
		this.props.setOPDModalDoctorOrderPendingItems(pendingItems)

		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, -1, null)
	}

	onUpBtn() {
		let pendingItems = this.props.opd.modalDoctorOrder.pendingItemTable.filtered.filter(
			(each) => each.displayName !== this.props.opd.modalDoctorOrder.selectedPendingItem.item.displayName
		)
		this.props.setOPDModalDoctorOrderPendingItems(pendingItems)
	}

	onSelectItem(item, index) {
		let selectedItem = this.props.opd.modalDoctorOrder.itemTable.filtered[index]
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_ORDER_SELECTED_ITEM, index, selectedItem)
	}

	render() {
		return (
			<Form onKeyPress={this.onKeyPress} onSubmit={this.onDownBtn}>
				<Row className={"full-size"} style={{ height: "525px" }}>
					<Col style={{ marginTop: 0, alignSelf: "flex-start" }}>
						<Container fluid="small" className={ResourceAssistance.CSS.fullFlex}>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col>
									<ScrollableTable
										striped
										hover
										enableHighlight
										onClick={this.onSelectItem}
										data={this.props.opd.modalDoctorOrder.itemTable}
										isClearHighlight={this.props.opd.modalDoctorOrder.selectedItem.index === -1}
										highlightedRow={this.props.opd.modalDoctorOrder.selectedItem.index}
									/>
								</Col>
								{this.props.opd.modalDoctorOrder.itemTable.isLoading && (
									<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.primary} />
								)}
							</Row>
							<Row>
								<Col />
								<Col md="auto">
									<Button
										type={ResourceAssistance.Button.type.submit}
										variant={ResourceAssistance.Button.variant.link}
										disabled={this.props.opd.modalDoctorOrder.selectedItem.index === -1 || !this.validatePharmacyInventory()}
									>
										<FcDownload size={"3rem"} />
									</Button>
								</Col>
								<Col />
								<Col md={"auto"}>
									<Button
										variant={ResourceAssistance.Button.variant.link}
										disabled={this.props.opd.modalDoctorOrder.selectedPendingItem.index === -1}
										onClick={this.onUpBtn}
									>
										<FcUpload size={"3rem"} />
									</Button>
								</Col>
								<Col />
							</Row>
						</Container>
					</Col>
					{this.props.opd.modalDoctorOrder.selectedItem.item && this.checkPharmacyInventoryAvaliability(this.props.opd.modalDoctorOrder.selectedItem.item) && (
						<Col md={3} style={{ minWidth: "500px" }}>
							<OutpatientModalDoctorDuration />
						</Col>
					)}
				</Row>
			</Form>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setOPDModalDoctorOrderItems,
			setOPDModalDoctorOrderPendingItems,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OutpatientModalDoctorItems))
