import propTypes from "prop-types"
import React from "react"
import { Col, FormControl, Row } from "react-bootstrap"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import SearchBox from "~/view/component/search_box/SearchBox"

class ICDType extends React.Component {
	render() {
		return (
			<Row>
				<Col md={3}>
					<FormControl
						size={ResourceAssistance.FormControl.size.sm}
						as={ResourceAssistance.FormControl.as.select}
						onChange={this.props.onType}
					>
						{Utils.renderOptions(this.props.types, false)}
					</FormControl>
				</Col>
				<Col>
					<SearchBox
						num={2}
						lableName=""
						controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
						callbacks={[this.props.onSearchCode, this.props.onSearchName]}
					/>
				</Col>
			</Row>
		)
	}
}

ICDType.propTypes = {
	onType: propTypes.func,
	onSearchCode: propTypes.func,
	onSearchName: propTypes.func,
}

ICDType.defaultProps = {}

export default ICDType
