import { ResourceAssistance } from "~/i18n"
import {
	SET_PO_ADDRESS,
	SET_PO_BILLING_CONTACT_TEL,
	SET_PO_DISPLAY_ALL_IND,
	SET_PO_EDIT_DISCOUNT,
	SET_PO_EDIT_DONE_IND,
	SET_PO_EDIT_TOTAL_PRICE_BEFORE_TAX,
	SET_PO_END_DATE,
	SET_PO_ITEMS,
	SET_PO_LOADING,
	SET_PO_MODAL_EDIT_BILLING_CONTACT_TEL,
	SET_PO_MODAL_EDIT_ITEM_SUPPLIER_PRICE_PER_ORDER,
	SET_PO_MODAL_EDIT_PAYMENTS,
	SET_PO_MODAL_EDIT_PAYMENT_TERMS,
	SET_PO_MODAL_EDIT_REQUIRED_DATE,
	SET_PO_MODAL_EDIT_ROUTING_ID,
	SET_PO_MODAL_EDIT_SELECTED_PAYMENT,
	SET_PO_MODAL_EDIT_SHIPPING_CONTACT_TEL,
	SET_PO_ORDERS,
	SET_PO_REJECTED_REASON,
	SET_PO_SEARCH_NUM,
	SET_PO_SELECTED_ITEM,
	SET_PO_SELECTED_PO,
	SET_PO_SELECTED_SUPPLIER,
	SET_PO_SHIPPING_CONTACT_TEL,
	SET_PO_START_DATE,
	SET_PO_SUPPLIERS,
} from "~/redux/type"

let DATE = new Date()
const init = {
	searchNum: "",
	address: "",
	shippingContact: "",
	billingContact: "",
	rejectedReason: "",
	isDisplayAll: false,
	searchDate: {
		startDate: new Date(DATE.getFullYear(), DATE.getMonth(), 1),
		endDate: new Date(DATE.getFullYear(), DATE.getMonth() + 1, 0, 23, 59, 59),
	},
	selectedPo: {
		po: null,
		index: -1,
	},
	selectedSupplier: {
		supplier: null,
		index: -1,
	},
	selectedItem: {
		index: -1,
		item: null,
	},
	order: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.time,
			ResourceAssistance.Message.hashSymbol,
			ResourceAssistance.Message.inspectorCheck,
			ResourceAssistance.Message.approverCheck,
		],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	edit: {
		routingId: "",
		totalPriceBeforeTax: 0,
		discount: 0,
		discountPrice: 0,
		isDone: false,
		callback: null,
		paymentTerms: "",
		requiredDate: null,
		shippingContact: "",
		billingContact: "",
		payments: [],
		selectedPayment: {
			index: -1,
			payment: null,
		},
	},
	supplier: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.memoId,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.supplierName,
			ResourceAssistance.Message.tel,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.totalPriceTaxed,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.totalPriceDiscounted,
			ResourceAssistance.Message.payment,
			ResourceAssistance.Message.terms,
			ResourceAssistance.Message.expected,
			ResourceAssistance.Message.attachment,
		],
		body: [],
		isLoading: false,
	},
	inventory: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.amount,
			ResourceAssistance.Message.unit,
			ResourceAssistance.Message.pricePerOrder,
			ResourceAssistance.Message.supplierPricePerOrder,
			ResourceAssistance.Message.discount,
			ResourceAssistance.Message.discountPricePerOrder,
			ResourceAssistance.Message.bonus,
			ResourceAssistance.Message.totalPrice,
			ResourceAssistance.Message.taxRate,
			ResourceAssistance.Message.totalPriceTaxed,
			"",
		],
		body: [],
		isLoading: false,
	},
	modalEditItem: {
		supplierPricePerOrder: "",
	},
}

const purchaseOrderReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PO_ORDERS:
			return Object.assign({}, state, {
				order: {
					...state.order,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})

		case SET_PO_LOADING:
			return Object.assign({}, state, {
				order: {
					...state.order,
					isLoading: action.payload.isLoading,
				},
			})

		case SET_PO_START_DATE:
			return Object.assign({}, state, {
				searchDate: {
					...state.searchDate,
					startDate: action.payload.date,
				},
			})

		case SET_PO_END_DATE:
			return Object.assign({}, state, {
				searchDate: {
					...state.searchDate,
					endDate: action.payload.date,
				},
			})

		case SET_PO_SEARCH_NUM:
			return Object.assign({}, state, {
				searchNum: action.payload.num,
			})

		case SET_PO_SELECTED_PO:
			return Object.assign({}, state, {
				selectedPo: {
					po: action.payload.po,
					index: action.payload.index,
				},
			})

		case SET_PO_ADDRESS:
			return Object.assign({}, state, {
				address: action.payload.address,
			})

		case SET_PO_SHIPPING_CONTACT_TEL:
			return Object.assign({}, state, {
				shippingContact: action.payload.tel,
			})

		case SET_PO_BILLING_CONTACT_TEL:
			return Object.assign({}, state, {
				billingContact: action.payload.tel,
			})

		case SET_PO_EDIT_TOTAL_PRICE_BEFORE_TAX:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					totalPriceBeforeTax: action.payload.total,
				},
			})

		case SET_PO_EDIT_DISCOUNT:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					discount: action.payload.discount,
					discountPrice: action.payload.discountPrice,
				},
			})

		case SET_PO_EDIT_DONE_IND:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					isDone: action.payload.isDone,
					callback: action.payload.callback,
				},
			})

		case SET_PO_REJECTED_REASON:
			return Object.assign({}, state, {
				rejectedReason: action.payload.reason,
			})

		case SET_PO_SUPPLIERS:
			return Object.assign({}, state, {
				supplier: {
					...state.supplier,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})

		case SET_PO_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				selectedSupplier: {
					...state.selectedSupplier,
					supplier: action.payload.supplier,
					index: action.payload.index,
				},
			})
		case SET_PO_ITEMS:
			return Object.assign({}, state, {
				inventory: {
					...state.inventory,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
				},
			})
		case SET_PO_SELECTED_ITEM:
			return Object.assign({}, state, {
				selectedItem: {
					index: action.payload.index,
					item: action.payload.selected,
				},
			})
		case SET_PO_DISPLAY_ALL_IND:
			return Object.assign({}, state, {
				isDisplayAll: action.payload.isDisplayAll,
			})
		case SET_PO_MODAL_EDIT_PAYMENTS:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					payments: action.payload.payments,
				},
			})
		case SET_PO_MODAL_EDIT_SELECTED_PAYMENT:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					selectedPayment: {
						index: action.payload.index,
						payment: action.payload.payment,
					},
				},
			})
		case SET_PO_MODAL_EDIT_PAYMENT_TERMS:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					paymentTerms: action.payload.terms,
				},
			})
		case SET_PO_MODAL_EDIT_REQUIRED_DATE:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					requiredDate: action.payload.date,
				},
			})
		case SET_PO_MODAL_EDIT_ROUTING_ID:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					routingId: action.payload.id,
				},
			})
		case SET_PO_MODAL_EDIT_SHIPPING_CONTACT_TEL:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					shippingContact: action.payload.tel,
				},
			})
		case SET_PO_MODAL_EDIT_BILLING_CONTACT_TEL:
			return Object.assign({}, state, {
				edit: {
					...state.edit,
					billingContact: action.payload.tel,
				},
			})
		case SET_PO_MODAL_EDIT_ITEM_SUPPLIER_PRICE_PER_ORDER:
			return Object.assign({}, state, {
				modalEditItem: {
					...state.modalEditItem,
					supplierPricePerOrder: action.payload.value,
				},
			})

		default:
			return state
	}
}

export default purchaseOrderReducer
