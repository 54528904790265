import { Input } from "antd"
import propTypes from "prop-types"
import React from "react"
import "./input.css"

class GInput extends React.Component {
	constructor(props) {
		super(props)

		this.containerRef = React.createRef()
		this.inputRef = React.createRef()
		this.textAreaRef = React.createRef()
		this.placeholderRef = React.createRef()

		this.state = {
			componentName: "GInput",
		}
		this.onFocus = this.onFocus.bind(this)
	}

	componentDidMount() {
		if (this.placeholderRef.current) {
			this.placeholderRef.current.addEventListener("click", () => {
				if (this.inputRef.current) {
					this.inputRef.current.focus()
				}
				if (this.textAreaRef.current) {
					this.textAreaRef.current.focus()
				}
			})
		}
		let input = this.inputRef.current ? this.inputRef.current.input : this.textAreaRef.current.resizableTextArea.textArea
		let placeholder = this.placeholderRef.current
		let GInput = this.containerRef.current
		let topEmptySpace = (GInput.clientHeight - input.offsetHeight) / 2
		if (input.parentElement.className.includes("wrapper")) {
			placeholder.style.left = parseFloat(input.offsetLeft) + parseFloat(placeholder.offsetLeft) + "px"
			if (input.required) {
				placeholder.className = placeholder.className + " placeholder-required"
			}
			input.addEventListener("focus", () => {
				placeholder.style.background = "linear-gradient(180deg, #eaf0f8 70%, transparent 30%)"
				placeholder.style.color = "#80bdff"
				placeholder.style.top = `calc(0% + ${topEmptySpace}px)`
				placeholder.style.marginTop = "2px"
			})
			input.addEventListener("focusout", () => {
				if (input.value === "") {
					placeholder.style.background = "inherit"
					placeholder.style.color = "#A9A9A9"
					placeholder.style.top = "50%"
					placeholder.style.marginTop = "0px"
				} else {
					placeholder.style.color = "inherit"
				}
			})
			if (this.props.autoFocus) {
				input.blur()
				input.focus()
			}
		}
	}

	onFocus(e) {
		e.target.select()
	}

	setCustomValidity(str) {
		if (this.props.autoSize) {
		} else {
			this.inputRef.current.input.setCustomValidity(str)
		}
	}

	render() {
		return (
			<div ref={this.containerRef} className={this.state.componentName}>
				{this.props.autoSize ? (
					<Input.TextArea
						id={this.props.id}
						ref={this.textAreaRef}
						required={this.props.required}
						variant={this.props.variant}
						style={this.props.style}
						name={this.props.name}
						step={this.props.step}
						min={this.props.min}
						max={this.props.max}
						placeholder={this.props.placeholder}
						autoSize={{ minRows: this.props.minRows, maxRows: this.props.maxRows }}
						disabled={this.props.disabled}
						value={this.props.value}
						extra-value={this.props.extraValue}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
						showCount={this.props.showCount}
						maxLength={this.props.maxLength}
						autoFocus={this.props.autoFocus}
					/>
				) : (
					<Input
						id={this.props.id}
						ref={this.inputRef}
						type={this.props.type}
						required={this.props.required}
						name={this.props.name}
						placeholder={this.props.placeholder}
						variant={this.props.variant}
						style={this.props.style}
						styles={this.props.styles}
						size={this.props.size}
						step={this.props.step}
						min={this.props.min}
						max={this.props.max}
						addonBefore={this.props.addonBefore}
						addonAfter={this.props.addonAfter}
						suffix={this.props.suffix}
						prefix={this.props.prefix}
						disabled={this.props.disabled}
						pattern={this.props.pattern}
						value={this.props.value}
						extra-value={this.props.extraValue}
						onChange={this.props.onChange}
						onFocus={this.onFocus}
						onBlur={this.props.onBlur}
						autoFocus={this.props.autoFocus}
					/>
				)}
				<Input
					id={this.props.id}
					name={this.props.name}
					placeholder={this.props.placeholder}
					style={{ display: "none", visibility: "hidden" }}
					type={"text"}
					step={this.props.step}
					min={this.props.min}
					max={this.props.max}
					disabled={this.props.disabled}
					// pattern={this.props.pattern}
					value={this.props.value}
					onChange={this.props.onChange}
				/>
				<span ref={this.placeholderRef} className="placeholder">
					{this.props.placeholder}
				</span>
			</div>
		)
	}
}

GInput.propTypes = {
	autoSize: propTypes.bool,
	disabled: propTypes.bool,
	id: propTypes.any,
	maxRows: propTypes.number,
	minRows: propTypes.number,
	onBlur: propTypes.func,
	onChange: propTypes.func,
	pattern: propTypes.string,
	placeholder: propTypes.any,
	required: propTypes.bool,
	showCount: propTypes.bool,
	style: propTypes.object,
	type: propTypes.string,
	value: propTypes.any,
}

GInput.defaultProps = {
	autoSize: false,
	disabled: false,
	maxRows: undefined,
	minRows: undefined,
	onChange: () => {},
	required: false,
	showCount: false,
	style: {},
	type: "text",
	value: undefined,
	autoFocus: true,
}

export default GInput
