import React from "react"
import { Spinner } from "react-bootstrap"
import { connect } from "react-redux"
import { ResourceAssistance } from "~/i18n"
import "./loading.css"

class Loading extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "Loading",
		}
	}

	render() {
		return (
			(this.props.common.isPageLoading || this.props.common.isContinueLoading) && (
				<div id={this.state.componentName}>
					<Spinner animation={ResourceAssistance.Spinner.border} variant={ResourceAssistance.Button.variant.info} />
				</div>
			)
		)
	}
}

const mapStateToProps = (state) => ({
	common: state.common,
})

export default connect(mapStateToProps, {})(Loading)
