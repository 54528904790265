import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setUploadDocuments } from "~/redux/action"
import { SET_DOCUMENT_DOCUMENTS_LOADING } from "~/redux/type"
import DocumentAction from "./DocumentAction"
import DocumentTable from "./DocumentTable"
import DocumentPatient from "./DocumentPatient"

class DocumentDetails extends React.Component {
	constructor(props) {
		super(props)

		this.loadUploadDocuments = this.loadUploadDocuments.bind(this)
	}
	componentDidMount() {
		this.loadUploadDocuments()
	}

	loadUploadDocuments() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.document.getUploadDocuments,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setUploadDocuments(res.data.uploadDocuments)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_DOCUMENT_DOCUMENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_DOCUMENT_DOCUMENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_DOCUMENT_DOCUMENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={"space-top-each-row-parent"}>
				<DocumentAction loadUploadDocuments={this.loadUploadDocuments} />
				<DocumentPatient />
				<DocumentTable />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setUploadDocuments,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails)
