import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setConfigServiceServices, setLoadingAction, setSelected } from "~/redux/action"
import { SET_ADMIN_SERVICE_SELECTED_SERVICE, SET_ADM_SERVICE_NEW_SERVICE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ConfigServiceModalNSDetails from "./ConfigServiceModalNSDetails"

class ConfigServiceModalNS extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "ConfigServiceModalNS",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_ADM_SERVICE_NEW_SERVICE_DISPLAY, false)
	}

	isDoneBtnDisabled() {
		if (this.props.service.selectedService.index !== -1) {
			const target = Object.assign({}, this.props.service.selectedService.service, {
				...this.props.service.selectedService.service,
				categoryId: this.props.service.modalNewService.selectedType.type ? this.props.service.modalNewService.selectedType.type.id : 0,
				userId: this.props.login.user.id,
				id: this.props.service.selectedService.service ? this.props.service.selectedService.service.id : 0,
				roleId: this.props.service.modalNewService.selectedRole.role ? this.props.service.modalNewService.selectedRole.role.id : 0,
				code: Utils.trim(this.props.service.modalNewService.code),
				fsCode: Utils.trim(this.props.service.modalNewService.fsCode),
				categoryNum: this.props.service.modalNewService.categoryNum,
				name: Utils.trim(this.props.service.modalNewService.name),
				description: Utils.trim(this.props.service.modalNewService.description),
				pricePerUnit: this.props.service.modalNewService.pricePerUnit,
				adpType: this.props.service.modalNewService.adpType,
				governmentCode: Utils.trim(this.props.service.modalNewService.governmentCode),
				tmltTmtCode: Utils.trim(this.props.service.modalNewService.tmltTmtCode),
				active: this.props.service.modalNewService.isActive,
			})
			return _.isEqual(this.props.service.selectedService.service, target)
		}

		return (
			this.props.service.modalNewService.selectedType.index === -1 ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.code)) ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.fsCode)) ||
			_.isEmpty(Utils.trim(String(this.props.service.modalNewService.categoryNum))) ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.name)) ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.description)) ||
			_.isEmpty(Utils.trim(String(this.props.service.modalNewService.pricePerUnit))) ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.adpType)) ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.governmentCode)) ||
			_.isEmpty(Utils.trim(this.props.service.modalNewService.tmltTmtCode))
		)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.admin.service.saveService,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				categoryId: this.props.service.modalNewService.selectedType.type.id,
				userId: this.props.login.user.id,
				id: this.props.service.selectedService.service ? this.props.service.selectedService.service.id : 0,
				roleId: this.props.service.modalNewService.selectedRole.role ? this.props.service.modalNewService.selectedRole.role.id : 0,
				code: Utils.trim(this.props.service.modalNewService.code),
				fsCode: Utils.trim(this.props.service.modalNewService.fsCode),
				categoryNum: this.props.service.modalNewService.categoryNum,
				name: Utils.trim(this.props.service.modalNewService.name),
				description: Utils.trim(this.props.service.modalNewService.description),
				pricePerUnit: this.props.service.modalNewService.pricePerUnit,
				fixAmount: this.props.service.modalNewService.isFixAmount,
				treatmentTime: this.props.service.modalNewService.duration,
				adpType: this.props.service.modalNewService.adpType,
				governmentCode: Utils.trim(this.props.service.modalNewService.governmentCode),
				tmltTmtCode: Utils.trim(this.props.service.modalNewService.tmltTmtCode),
				labTest: Utils.trim(this.props.service.modalNewService.labTest),
				active: this.props.service.modalNewService.isActive,
			},
		}
		let callback = (res) => {
			if (!_.isEmpty(res.data.services)) {
				Object.assign(this.props.service.selectedType.type, {
					services: this.props.service.selectedType.type.services.filter((each) => each.id !== res.data.services[0].id),
				})
				this.props.service.modalNewService.selectedType.type.services.push(res.data.services[0])
				this.props.setConfigServiceServices(this.props.service.selectedType.type.services)
				this.props.setSelected(SET_ADMIN_SERVICE_SELECTED_SERVICE, -1, null)
				this.reset()
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = () => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				size={ResourceAssistance.Modal.size.lg}
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.service)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<ConfigServiceModalNSDetails />
					</ModalBody>

					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isAdmServiceNewServiceDisplay,
	login: state.login,
	service: state.admin.serviceConfig.service,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setConfigServiceServices,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ConfigServiceModalNS))
