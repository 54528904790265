import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { FcDiploma1, FcRules } from "react-icons/fc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { SvgDoctorNote, SvgDoctorServiceLightBlue } from "~/icons"
import SvgSvgStethoscope from "~/icons/SvgStethoscope"
import { onModalDisplayAction, setSelected } from "~/redux/action"
import {
	SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY,
	SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM,
	SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY,
	SET_HOSPITEL_SELECTED_SOAP,
	SET_IPD_ICD_DISPLAY,
	SET_IPD_MEDICAL_CERTIFICATE_DISPLAY,
	SET_IPD_SELECTED_CERTIFICATE,
} from "~/redux/type"

class HospitelModalDoctorOrderAction extends React.Component {
	constructor(props) {
		super(props)

		this.onInstruction = this.onInstruction.bind(this)
		this.onDoctorNote = this.onDoctorNote.bind(this)
		this.onService = this.onService.bind(this)
		this.onMedicalCertificate = this.onMedicalCertificate.bind(this)
		this.onICD = this.onICD.bind(this)
	}

	onInstruction() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DEFAULT_ORDER_DISPLAY, true)
		this.props.setSelected(SET_HOSPITEL_MODAL_DOCTOR_ORDER_SELECTED_PENDING_ITEM, -1, null)
	}

	onService() {
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_SERVICE_DISPLAY, true)
	}

	onDoctorNote() {
		this.props.setSelected(SET_HOSPITEL_SELECTED_SOAP, -1, null)
		this.props.onModalDisplayAction(SET_HOSPITEL_MODAL_DOCTOR_NOTE_DISPLAY, true)
	}

	onMedicalCertificate() {
		this.props.onModalDisplayAction(SET_IPD_MEDICAL_CERTIFICATE_DISPLAY, true)
		this.props.setSelected(SET_IPD_SELECTED_CERTIFICATE, -1, null)
	}

	onICD() {
		this.props.onModalDisplayAction(SET_IPD_ICD_DISPLAY, true)
	}

	render() {
		return (
			<Row>
				<Col />
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onICD}>
						<FcRules size={ResourceAssistance.ReactIcon.size_md} />
						{translate(ResourceAssistance.Message.ICD)}
					</Button>
				</Col>
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onInstruction}>
						<SvgSvgStethoscope width={ResourceAssistance.ReactIcon.size_md} />
						{translate(ResourceAssistance.Message.instruction)}
					</Button>
				</Col>
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onMedicalCertificate}>
						<FcDiploma1 size={ResourceAssistance.ReactIcon.size_md} />
						{translate(ResourceAssistance.Hospitel.medicalCertificate)}
					</Button>
				</Col>
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onService}>
						<SvgDoctorServiceLightBlue width={ResourceAssistance.ReactIcon.size_md} />
						{translate(ResourceAssistance.Message.operationalService)}
					</Button>
				</Col>
				<Col md="auto">
					<Button variant={ResourceAssistance.Button.variant.link} onClick={this.onDoctorNote}>
						<SvgDoctorNote width={ResourceAssistance.ReactIcon.size_md} />
						{translate(ResourceAssistance.Hospitel.soap)}
					</Button>
				</Col>
				<Col />
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorOrderAction)
