export const SET_PHARMACY_ADMISSIONS = "SET_PHARMACY_ADMISSIONS"
export const SET_PHARMACY_DISPENSED_ORDERS = "SET_PHARMACY_DISPENSED_ORDERS"
export const SET_PHARMACY_DOCTOR_ORDERS = "SET_PHARMACY_DOCTOR_ORDERS"
export const SET_PHARMACY_FILTER = "SET_PHARMACY_FILTER"
export const SET_PHARMACY_LOCATION = "SET_PHARMACY_LOCATION"
export const SET_PHARMACY_LOCATION_DISPLAY = "SET_PHARMACY_LOCATION_DISPLAY"
export const SET_PHARMACY_MODAL_LOCATION_ORGS = "SET_PHARMACY_MODAL_LOCATION_ORGS"
export const SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING = "SET_PHARMACY_MODAL_LOCATION_ORGS_LOADING"
export const SET_PHARMACY_MODAL_LOCATION_SELECTED_BRANCH = "SET_PHARMACY_MODAL_LOCATION_SELECTED_BRANCH"
export const SET_PHARMACY_MODAL_LOCATION_SELECTED_DEPARTMENT = "SET_PHARMACY_MODAL_LOCATION_SELECTED_DEPARTMENT"
export const SET_PHARMACY_MODAL_LOCATION_SELECTED_ORG = "SET_PHARMACY_MODAL_LOCATION_SELECTED_ORG"
export const SET_PHARMACY_MODAL_RETURN_DOCTOR_ORDER_IND = "SET_PHARMACY_MODAL_RETURN_DOCTOR_ORDER_IND"
export const SET_PHARMACY_MODAL_RETURN_LOTS = "SET_PHARMACY_MODAL_RETURN_LOTS"
export const SET_PHARMACY_MODAL_RETURN_SELECTED_LOT = "SET_PHARMACY_MODAL_RETURN_SELECTED_LOT"
export const SET_PHARMACY_MODAL_VERIFY_DAILY_IND = "SET_PHARMACY_MODAL_VERIFY_DAILY_IND"
export const SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND = "SET_PHARMACY_MODAL_VERIFY_DOCTOR_ORDER_IND"
export const SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME = "SET_PHARMACY_MODAL_VERIFY_END_DATE_TIME"
export const SET_PHARMACY_MODAL_VERIFY_LOTS = "SET_PHARMACY_MODAL_VERIFY_LOTS"
export const SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY = "SET_PHARMACY_MODAL_VERIFY_PHARMACY_INVENTORY"
export const SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME = "SET_PHARMACY_MODAL_VERIFY_START_DATE_TIME"
export const SET_PHARMACY_NURSE_ORDERS = "SET_PHARMACY_NURSE_ORDERS"
export const SET_PHARMACY_PATIENTS = "SET_PHARMACY_PATIENTS"
export const SET_PHARMACY_PATIENTS_LOADING = "SET_PHARMACY_PATIENTS_LOADING"
export const SET_PHARMACY_RETURN_DISPLAY = "SET_PHARMACY_RETURN_DISPLAY"
export const SET_PHARMACY_RETURN_EDIT_DISPLAY = "SET_PHARMACY_RETURN_EDIT_DISPLAY"
export const SET_PHARMACY_RETURN_ORDERS = "SET_PHARMACY_RETURN_ORDERS"
export const SET_PHARMACY_SEARCH_DISPLAY = "SET_PHARMACY_SEARCH_DISPLAY"
export const SET_PHARMACY_SEARCH_END_DATETIME = "SET_PHARMACY_SEARCH_END_DATETIME"
export const SET_PHARMACY_SEARCH_IDENTIFICATION = "SET_PHARMACY_SEARCH_IDENTIFICATION"
export const SET_PHARMACY_SEARCH_PID = "SET_PHARMACY_SEARCH_PID"
export const SET_PHARMACY_SEARCH_START_DATETIME = "SET_PHARMACY_SEARCH_START_DATETIME"
export const SET_PHARMACY_SELECTED_ADMISSION = "SET_PHARMACY_SELECTED_ADMISSION"
export const SET_PHARMACY_SELECTED_DISPENSED_ORDER = "SET_PHARMACY_SELECTED_DISPENSED_ORDER"
export const SET_PHARMACY_SELECTED_DOCTOR_ORDER = "SET_PHARMACY_SELECTED_DOCTOR_ORDER"
export const SET_PHARMACY_SELECTED_PAITENT = "SET_PHARMACY_SELECTED_PAITENT"
export const SET_PHARMACY_SELECTED_SKIPPED_ORDER = "SET_PHARMACY_SELECTED_SKIPPED_ORDER"
export const SET_PHARMACY_SELECTED_VERIFIED_ORDER = "SET_PHARMACY_SELECTED_VERIFIED_ORDER"
export const SET_PHARMACY_SKIPPED_ORDERS = "SET_PHARMACY_SKIPPED_ORDERS"
export const SET_PHARMACY_VERIFIED_ORDERS = "SET_PHARMACY_VERIFIED_ORDERS"
export const SET_PHARMACY_VERIFY_DISPLAY = "SET_PHARMACY_VERIFY_DISPLAY"
