import React from "react"
import { Button, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLabPatients, setLoadingAction, setObjArray } from "~/redux/action"
import {
	SET_HOSPITEL_LAB_LAB_DISPLAY,
	SET_HOSPITEL_LAB_MODAL_LAB_CATEGORIES,
	SET_HOSPITEL_LAB_MODAL_LAB_ORGS,
	SET_HOSPITEL_LAB_PATIENTS_LOADING,
} from "~/redux/type"
import CLModalNewLabDetails from "./CLModalNewLabDetails"

class CLModalNewLab extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "CLModalNewLab",
		}

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	componentDidMount() {
		this.loadOrgs()
		this.loadServices()
	}

	reset() {
		this.props.onModalDisplayAction(SET_HOSPITEL_LAB_LAB_DISPLAY, false)
	}

	loadOrgs() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.lab.getOrgs}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_ORGS, res.data.locations)
		}
		axios(
			params,
			callback,
			() => {},
			() => {},
			() => {}
		)
	}

	loadServices() {
		let params = {
			method: "GET",
			url: `${ResourceAssistance.Url.lab.getServices}`,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setObjArray(SET_HOSPITEL_LAB_MODAL_LAB_CATEGORIES, res.data.uts)
		}
		axios(
			params,
			callback,
			() => {},
			() => {},
			() => {}
		)
	}

	isDoneBtnDisabled() {
		return false
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.lab.createLab,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				patientId: this.props.lab.selectedPatient.patient.id,
				locationId: this.props.lab.modalLab.selectedBranch.branch.id,
				categoryId: this.props.lab.modalLab.selectedCategory.category.id,
				serviceId: this.props.lab.modalLab.selectedService.service.id,
				userId: this.props.login.user.id,
				outpatient: this.props.lab.modalLab.selectedDepartment.department.outpatient,
				locationAccountsReceivable: this.props.lab.modalLab.selectedDepartment.department.accountsReceivable,
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.lab.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setLabPatients(patients)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_HOSPITEL_LAB_PATIENTS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_HOSPITEL_LAB_PATIENTS_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_HOSPITEL_LAB_PATIENTS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.newLab)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={ResourceAssistance.CSS.fullFlex}>
							<CLModalNewLabDetails />
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.hospitel.lab.isNewLabDisplay,
	login: state.login,
	lab: state.hospitel.lab,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setLabPatients,
			setObjArray,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(CLModalNewLab)
