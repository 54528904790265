import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import OPDModalMedicalRecordAdmission from "./OPDModalMedicalRecordAdmission"
import OPDModalMedicalRecordConsultation from "./OPDModalMedicalRecordConsultation"
import OPDModalMedicalRecordHealthHistory from "./OPDModalMedicalRecordHealthHistory"
import OPDModalMedicalRecordMenstruation from "./OPDModalMedicalRecordMenstruation"
import OPDModalMedicalRecordPE from "./OPDModalMedicalRecordPE"

class OPDModalMedicalRecordDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<OPDModalMedicalRecordHealthHistory />
				{(this.props.opd.modalMedicalRecord.isMenstruationDisplay ||
					(this.props.opd.selectedMedicalRecord.medicalRecord &&
						this.props.opd.selectedMedicalRecord.medicalRecord.menstruation)) && <OPDModalMedicalRecordMenstruation />}
				<Row>
					<Col>
						<OPDModalMedicalRecordPE />
					</Col>

					<Col>
						<OPDModalMedicalRecordConsultation />
					</Col>
				</Row>
				{this.props.opd.modalMedicalRecord.isAdmissionPlanDisplay && <OPDModalMedicalRecordAdmission />}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalRecordDetails))
