import { LOGIN, LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_ERROR } from "../type"

const init = {
	isLogin: false,
	user: {},
	username: "",
	password: "",
	errorMsg: "",
	displayError: false,
}

const LoginReducer = (state = init, action) => {
	switch (action.type) {
		case LOGIN:
			return Object.assign({}, state, {
				isLogin: action.payload.isLogin,
				username: action.payload.username,
				displayError: false,
				errorMsg: "",
				user: action.payload.user,
			})

		case LOGIN_USERNAME:
			return Object.assign({}, state, {
				username: action.payload.username,
			})

		case LOGIN_PASSWORD:
			return Object.assign({}, state, {
				password: action.payload.password,
			})

		case LOGIN_ERROR:
			let displayError = false
			let errorMsg = ""
			if (action.payload.errorMsg !== "") {
				displayError = true
				errorMsg = action.payload.errorMsg
			} else {
				displayError = false
				errorMsg = ""
			}

			return Object.assign({}, state, {
				displayError: displayError,
				errorMsg: errorMsg,
				user: {},
			})

		default:
			return state
	}
}

export default LoginReducer
