import { combineReducers } from "redux"
import reportCashierReducer from "./reportCashierReducer"
import reportHospitelReducer from "./reportHospitelReducer"
import reportInvReducer from "./reportInvReducer"
import reportLabReducer from "./reportLabReducer"
import reportPOReducer from "./reportPOReducer"
import reportTFReducer from "./reportTFReducer"
import reportFinanceReducer from "./reportFinanceReducer"

export default combineReducers({
	inv: reportInvReducer,
	tf: reportTFReducer,
	po: reportPOReducer,
	lab: reportLabReducer,
	hospitel: reportHospitelReducer,
	cashier: reportCashierReducer,
	finance: reportFinanceReducer,
})
