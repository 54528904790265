import { Select } from "antd"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import { SET_OPD_MODAL_OR_ANESTHESIOLOGIST_FEE, SET_OPD_MODAL_OR_SELECTED_ANESTHESIA, SET_OPD_MODAL_OR_SELECTED_ANESTHESIOLOGIST } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import GInput from "~/view/component/input/GInput"

class OutpatientModalORAnesthesia extends React.Component {
	constructor(props) {
		super(props)

		this.onAnesthesiologist = this.onAnesthesiologist.bind(this)
		this.onAnesthesiaType = this.onAnesthesiaType.bind(this)
		this.onAnesthesiologistFee = this.onAnesthesiologistFee.bind(this)
	}

	componentDidMount() {
		if (this.props.opd.selectedORRequest.request) {
			this.props.setValue(SET_OPD_MODAL_OR_ANESTHESIOLOGIST_FEE, Utils.BigNumber(this.props.opd.selectedORRequest.request.anesthesiologistFee).toFixed(2))
			let anesthesiologistIdx = this.props.opd.doctors.findIndex((doctor) => doctor.displayName === this.props.opd.selectedORRequest.request.anesthesiologist)
			if (anesthesiologistIdx > -1) {
				this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIOLOGIST, anesthesiologistIdx, this.props.opd.doctors[anesthesiologistIdx])
			}
			let anesthesiaIdx = this.props.opd.anesthesias.findIndex((anethesia) => anethesia.displayName === this.props.opd.selectedORRequest.request.anesthesiaType)
			if (anesthesiaIdx > -1) {
				this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIA, anesthesiaIdx, this.props.opd.anesthesias[anesthesiaIdx])
			}
		}
	}

	componentWillUnmount() {
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIOLOGIST, -1, null)
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIA, -1, null)
		this.props.setValue(SET_OPD_MODAL_OR_ANESTHESIOLOGIST_FEE, undefined)
	}

	isPreApprovedFieldDisabled() {
		return this.props.opd.selectedORRequest.request && this.props.opd.selectedORRequest.request.status === ResourceAssistance.Enum.approved
	}

	onAnesthesiologist(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIOLOGIST, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIOLOGIST, v, this.props.opd.doctors[v])
	}

	onAnesthesiologistFee(v) {
		this.props.setValue(SET_OPD_MODAL_OR_ANESTHESIOLOGIST_FEE, v.target.value)
	}

	onAnesthesiaType(v) {
		if (v === undefined) {
			this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIA, -1, null)
			return
		}
		this.props.setSelected(SET_OPD_MODAL_OR_SELECTED_ANESTHESIA, v, this.props.opd.anesthesias[v])
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.anesthesia)}</legend>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.anesthesiologist)}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.selectedAnesthesiologist.anesthesiologist ? this.props.opd.modalOR.selectedAnesthesiologist.index : undefined}
							onChange={this.onAnesthesiologist}
						>
							{Utils.renderSelects(this.props.opd.doctors, false)}
						</Select>
					</Col>
				</Row>
				<Row>
					<Col>
						<GInput
							required
							style={{ textAlign: "center" }}
							placeholder={translate(ResourceAssistance.Message.anesthesiologistFee)}
							pattern="^[0-9]*\.[0-9]{2}$"
							disabled={this.isPreApprovedFieldDisabled()}
							onChange={this.onAnesthesiologistFee}
							value={this.props.opd.modalOR.anesthesiologistFee}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.type)}
							loading={this.props.opd.isAnesthesiasLoading}
							disabled={this.isPreApprovedFieldDisabled()}
							value={this.props.opd.modalOR.selectedAnesthesiaType.type ? this.props.opd.modalOR.selectedAnesthesiaType.index : undefined}
							onChange={this.onAnesthesiaType}
						>
							{Utils.renderSelects(this.props.opd.anesthesias, false)}
						</Select>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientModalORAnesthesia)
