import "moment/locale/th" // without this line it didn't work
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME } from "~/redux/type"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class OPDModalAppointmentEditDate extends React.Component {
	constructor(props) {
		super(props)

		this.onDateChange = this.onDateChange.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME, "")
	}

	onDateChange(value, dateString) {
		this.props.setValue(SET_OPD_MODAL_APPOINTMENT_EDIT_DATE_TIME, value ? value.valueOf() : "")
	}

	render() {
		return (
			<Row>
				<Col>
					<AntDatePicker
						size={"large"}
						format={"DD-MM-YYYY  HH:mm"}
						showTime={{ format: "HH:mm" }}
						placeholder={[this.props.intl.formatMessage({ id: ResourceAssistance.Message.appointmentDate })]}
						onChange={this.onDateChange}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalAppointmentEditDate))
