import _ from "lodash"
import React from "react"
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import { FormattedMessage } from "react-intl"
import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import "./searchBox.css"

const SearchBox = ({
	num = 1,
	labelName,
	controlTypes = [],
	callbacks = [],
	values = [],
	placeholders = [],
	disabled = false,
}) => {
	return (
		<Container fluid id="searchBox">
			<Row>
				{labelName && (
					<Col md="auto">
						<label>{labelName}</label>
					</Col>
				)}
				<Col>
					<InputGroup>
						{renderSearchBox(num, controlTypes, callbacks, values, placeholders, disabled)}
						<InputGroup.Text>
							<GoSearch />
						</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
		</Container>
	)
}

const renderSearchBox = (num, controlTypes, callbacks, values, placeholders, disabled) => {
	let boxes = []
	for (let i = 0; i < num; i++) {
		boxes.push(
			placeholders.length === 0 ? (
				<FormControl
					style={_.isEmpty(values[i]) ? undefined : { color: ResourceAssistance.CSS.Color.dark_warning }}
					key={i}
					autoFocus
					size={ResourceAssistance.FormControl.size.sm}
					disabled={disabled}
					type={controlTypes[i] ? controlTypes[i] : ResourceAssistance.FormControl.type.text}
					onKeyPress={Utils.preventEnterKeyPress}
					onChange={callbacks[i]}
					value={values[i]}
				/>
			) : (
				<FormattedMessage key={i} id={placeholders[i]}>
					{(placeholder) => (
						<FormControl
							style={_.isEmpty(values[i]) ? undefined : { color: ResourceAssistance.CSS.Color.dark_warning }}
							key={i}
							autoFocus
							disabled={disabled}
							size={ResourceAssistance.FormControl.size.sm}
							type={controlTypes[i] ? controlTypes[i] : ResourceAssistance.FormControl.type.text}
							placeholder={placeholder}
							onKeyPress={Utils.preventEnterKeyPress}
							onChange={callbacks[i]}
							value={values[i]}
						/>
					)}
				</FormattedMessage>
			)
		)
	}
	return boxes
}

export default SearchBox
