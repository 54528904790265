import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setHospitelHospitels, setSelected, onModalDisplayAction, setValue } from "~/redux/action"
import {
	SET_HOSPITEL_HOSPITEL_NEW_DISPLAY,
	SET_HOSPITEL_MODAL_REGISTER_PATIENT,
	SET_HOSPITEL_SELECTED_HOSPITEL,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class HospitelLeftHospitelRow extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "HospitelLeftHospitelRow",
		}
		this.selectRow = this.selectRow.bind(this)
		this.onDoubleSelectRow = this.onDoubleSelectRow.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.hospitel.selectedPatient, this.props.hospitel.selectedPatient)) {
			if (this.props.hospitel.selectedPatient.patient) {
				this.props.setHospitelHospitels(this.props.hospitel.selectedPatient.patient.hospitels)
			} else {
				this.props.setHospitelHospitels([])
			}
		}

		if (!_.isEqual(prevProps.hospitel.hospitelTable.filtered, this.props.hospitel.hospitelTable.filtered)) {
			let index = this.props.hospitel.selectedHospitel.hospitel
				? this.props.hospitel.hospitelTable.filtered.findIndex(
						(hospitel) => hospitel.id === this.props.hospitel.selectedHospitel.hospitel.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(SET_HOSPITEL_SELECTED_HOSPITEL, index, this.props.hospitel.hospitelTable.filtered[index])
			} else if (this.props.hospitel.hospitelTable.filtered.length > 0) {
				this.props.setSelected(SET_HOSPITEL_SELECTED_HOSPITEL, 0, this.props.hospitel.hospitelTable.filtered[0])
			} else {
				this.props.setSelected(SET_HOSPITEL_SELECTED_HOSPITEL, -1, null)
			}
		}
	}

	selectRow(patient, index) {
		this.props.setSelected(SET_HOSPITEL_SELECTED_HOSPITEL, index, this.props.hospitel.hospitelTable.filtered[index])
	}

	onDoubleSelectRow(patient, index) {
		if (
			this.props.hospitel.selectedHospitel.hospitel.dischargeSummary ||
			!Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Hospitel.Nurse, this.props.login.user.roles)
		) {
			return
		}
		this.props.setValue(SET_HOSPITEL_MODAL_REGISTER_PATIENT, this.props.hospitel.selectedPatient.patient)
		this.props.onModalDisplayAction(SET_HOSPITEL_HOSPITEL_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"} style={{ maxHeight: "17%" }}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						data={this.props.hospitel.hospitelTable}
						onClick={this.selectRow}
						onDoubleClick={this.onDoubleSelectRow}
						highlightedRow={this.props.hospitel.selectedHospitel.index}
						isClearHighlight={this.props.hospitel.selectedHospitel.index === -1}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
			setHospitelHospitels,
			onModalDisplayAction,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(HospitelLeftHospitelRow)
