import _ from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance } from "~/i18n"
import { setLoadingAction, setValue } from "~/redux/action"
import {
	SET_PI_TAXES,
	SET_PI_TAXES_LOADING,
	SET_PI_WITHHOLDING_TAXES,
	SET_PI_WITHHOLDING_TAXES_LOADING,
} from "~/redux/type"
import PIModalPurchaseFields from "./PIModalPurchaseFields"

class PIModalPurchaseDetails extends React.Component {
	componentDidMount() {
		if (_.isEmpty(this.props.PI.withholdingTaxes)) {
			this.loadWithholdingTaxes()
		}
		if (_.isEmpty(this.props.PI.taxes)) {
			this.loadTaxes()
		}
	}

	loadWithholdingTaxes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.purchaseInvoice.getWithholdingTaxes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PI_WITHHOLDING_TAXES, res.data.uts)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PI_WITHHOLDING_TAXES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PI_WITHHOLDING_TAXES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PI_WITHHOLDING_TAXES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	loadTaxes() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.purchaseInvoice.getTaxes,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_PI_TAXES, res.data.taxes)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PI_TAXES_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PI_TAXES_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PI_TAXES_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<PIModalPurchaseFields />
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	PI: state.accounting.purchaseInvoice,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PIModalPurchaseDetails)
