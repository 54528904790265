import _ from "lodash"
import React from "react"
import { Button, Col, Container, FormControl, Row } from "react-bootstrap"
import { FaSignature } from "react-icons/fa"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setAdmUserModalNUActive,
	setAdmUserModalNUConfirmPassword,
	setAdmUserModalNUEmail,
	setAdmUserModalNUFirstName,
	setAdmUserModalNULastName,
	setAdmUserModalNULoginName,
	setAdmUserModalNUNickname,
	setAdmUserModalNUPassword,
	setSelected,
	setValue,
} from "~/redux/action"
import {
	SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG,
	SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG,
	SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM,
	SET_ADMIN_USER_MODAL_NEW_USER_PHONE,
	SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE,
	SET_ADM_USER_SIGNATURE_DISPLAY,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ToggleCheckBox from "~/view/component/toggle/ToggleCheckBox"

class AdmUserModalNUInfo extends React.Component {
	constructor(props) {
		super(props)

		this.onLastName = this.onLastName.bind(this)
		this.onFirstName = this.onFirstName.bind(this)
		this.onEmail = this.onEmail.bind(this)
		this.onNickname = this.onNickname.bind(this)
		this.onLoginName = this.onLoginName.bind(this)
		this.onPassword = this.onPassword.bind(this)
		this.onConfirmPassword = this.onConfirmPassword.bind(this)
		this.onActive = this.onActive.bind(this)
		this.onFirstNameEng = this.onFirstNameEng.bind(this)
		this.onLastNameEng = this.onLastNameEng.bind(this)
		this.onRole = this.onRole.bind(this)
		this.onLicenseNum = this.onLicenseNum.bind(this)
		this.onSignature = this.onSignature.bind(this)
		this.onPhone = this.onPhone.bind(this)
	}

	componentDidMount() {
		if (this.props.user.selectedUser.index !== -1) {
			this.props.setAdmUserModalNULastName(this.props.user.selectedUser.user.lastName)
			this.props.setAdmUserModalNUFirstName(this.props.user.selectedUser.user.firstName)
			this.props.setAdmUserModalNUEmail(this.props.user.selectedUser.user.email)
			this.props.setAdmUserModalNUNickname(this.props.user.selectedUser.user.nickname)
			this.props.setAdmUserModalNULoginName(this.props.user.selectedUser.user.username)
			this.props.setAdmUserModalNUActive(this.props.user.selectedUser.user.active)
			this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG, this.props.user.selectedUser.user.lastNameEng)
			this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG, this.props.user.selectedUser.user.firstNameEng)
			this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM, this.props.user.selectedUser.user.licenseNum)
			this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_PHONE, this.props.user.selectedUser.user.phone)

			let idx = this.props.user.roleTypes.findIndex((type) => _.isEqual(type.displayName, this.props.user.selectedUser.user.role.displayName))
			if (idx > -1) {
				let roleDOM = document.getElementById(ResourceAssistance.ID.ADM.user.modalNU.role)
				roleDOM.selectedIndex = idx + 1
				this.props.setSelected(SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE, idx, this.props.user.roleTypes[idx])
			}
		}
	}

	componentWillUnmount() {
		this.props.setAdmUserModalNULastName("")
		this.props.setAdmUserModalNUFirstName("")
		this.props.setAdmUserModalNUEmail("")
		this.props.setAdmUserModalNUNickname("")
		this.props.setAdmUserModalNULoginName("")
		this.props.setAdmUserModalNUPassword("")
		this.props.setAdmUserModalNUConfirmPassword("")
		this.props.setAdmUserModalNUActive(true)
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG, "")
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG, "")
		this.props.setSelected(SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE, -1, null)
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM, "")
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_PHONE, "")
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.user.modalNewUser.lastName !== this.props.user.modalNewUser.lastName ||
			prevProps.user.modalNewUser.firstName !== this.props.user.modalNewUser.firstName ||
			prevProps.user.modalNewUser.loginName !== this.props.user.modalNewUser.loginName ||
			prevProps.user.modalNewUser.password !== this.props.user.modalNewUser.password ||
			prevProps.user.modalNewUser.confirmPassword !== this.props.user.modalNewUser.confirmPassword
		) {
			this.validateInfo()
		}
	}

	validateInfo() {
		let lastNameHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalNU.lastName)
		let firstNameHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalNU.firstName)
		let loginNameHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalNU.loginName)
		let passwordHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalNU.password)
		let confirmPasswordHtml = document.getElementById(ResourceAssistance.ID.ADM.user.modalNU.confirmPassword)

		if (
			this.props.user.userTable.original
				.filter((each) => !this.props.user.selectedUser.user || this.props.user.selectedUser.user.id !== each.id)
				.some(
					(each) =>
						each.lastName.toLowerCase().trim() === this.props.user.modalNewUser.lastName.toLowerCase().trim() &&
						each.firstName.toLowerCase().trim() === this.props.user.modalNewUser.firstName.toLowerCase().trim()
				)
		) {
			lastNameHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning }))
			firstNameHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning }))
		} else {
			lastNameHtml.setCustomValidity("")
			firstNameHtml.setCustomValidity("")
		}

		if (
			this.props.user.userTable.original
				.filter((each) => !this.props.user.selectedUser.user || this.props.user.selectedUser.user.id !== each.id)
				.some((each) => each.username.toLowerCase().trim() === this.props.user.modalNewUser.loginName.toLowerCase().trim())
		) {
			loginNameHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.dupplicateNameWarning }))
		} else {
			loginNameHtml.setCustomValidity("")
		}

		if (this.props.user.modalNewUser.password.trim() !== this.props.user.modalNewUser.confirmPassword.trim()) {
			passwordHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.passwordDoesNotMatch }))
			confirmPasswordHtml.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.passwordDoesNotMatch }))
		} else {
			passwordHtml.setCustomValidity("")
			confirmPasswordHtml.setCustomValidity("")
		}
	}

	onLastName(event) {
		this.props.setAdmUserModalNULastName(event.target.value)
	}

	onFirstName(event) {
		this.props.setAdmUserModalNUFirstName(event.target.value)
	}

	onFirstNameEng(event) {
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_FIRST_NAME_ENG, event.target.value)
	}

	onLastNameEng(event) {
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_LAST_NAME_ENG, event.target.value)
	}

	onEmail(event) {
		this.props.setAdmUserModalNUEmail(event.target.value)
	}

	onPhone(event) {
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_PHONE, event.target.value)
	}

	onNickname(event) {
		this.props.setAdmUserModalNUNickname(event.target.value)
	}

	onLoginName(event) {
		this.props.setAdmUserModalNULoginName(event.target.value)
	}

	onPassword(event) {
		this.props.setAdmUserModalNUPassword(event.target.value)
	}

	onConfirmPassword(event) {
		this.props.setAdmUserModalNUConfirmPassword(event.target.value)
	}

	onActive(event) {
		this.props.setAdmUserModalNUActive(event.target.checked)
	}

	onRole(event) {
		this.props.setSelected(SET_ADMIN_USER_MODAL_NEW_USER_SELECTED_ROLE_TYPE, event.target.value, this.props.user.roleTypes[event.target.value])
	}

	onLicenseNum(event) {
		this.props.setValue(SET_ADMIN_USER_MODAL_NEW_USER_LICENSE_NUM, event.target.value)
	}

	onSignature(event) {
		this.props.onModalDisplayAction(SET_ADM_USER_SIGNATURE_DISPLAY, true)
	}

	render() {
		return (
			<Container fluid="small" className="full-flex">
				<Row>
					<Col md={"auto"}>
						<Button size={ResourceAssistance.Button.size.sm} onClick={this.onSignature}>
							<FaSignature size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Hospitel.signature)}
						</Button>
					</Col>
					<Col />
				</Row>

				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.role)}</label>
					</Col>
					<Col>
						<FormControl required id={ResourceAssistance.ID.ADM.user.modalNU.role} as={ResourceAssistance.FormControl.as.select} onChange={this.onRole}>
							{Utils.renderOptions(this.props.user.roleTypes)}
						</FormControl>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.licenseNum)}</label>
					</Col>
					<Col>
						<FormControl type={ResourceAssistance.FormControl.type.text} value={this.props.user.modalNewUser.licenseNum} onChange={this.onLicenseNum} />
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.lastName)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalNU.lastName}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.user.modalNewUser.lastName}
							onChange={this.onLastName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.firstName)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalNU.firstName}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.user.modalNewUser.firstName}
							onChange={this.onFirstName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.lastNameEng)}</label>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.user.modalNewUser.lastNameEng}
							onChange={this.onLastNameEng}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.firstNameEng)}</label>
					</Col>
					<Col>
						<FormControl
							required
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.user.modalNewUser.firstNameEng}
							onChange={this.onFirstNameEng}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.email)}</label>
					</Col>
					<Col>
						<FormControl required type={ResourceAssistance.FormControl.type.email} value={this.props.user.modalNewUser.email} onChange={this.onEmail} />
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.phone)}</label>
					</Col>
					<Col>
						<FormControl required type={ResourceAssistance.FormControl.type.number} value={this.props.user.modalNewUser.phone} onChange={this.onPhone} />
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.nickName)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalNU.nickname}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.user.modalNewUser.nickname}
							onChange={this.onNickname}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.loginName)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalNU.loginName}
							type={ResourceAssistance.FormControl.type.text}
							value={this.props.user.modalNewUser.loginName}
							onChange={this.onLoginName}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.password)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalNU.password}
							type={ResourceAssistance.FormControl.type.password}
							value={this.props.user.modalNewUser.password}
							disabled={this.props.user.selectedUser.index !== -1}
							onChange={this.onPassword}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.confirmPassword)}</label>
					</Col>
					<Col>
						<FormControl
							required
							id={ResourceAssistance.ID.ADM.user.modalNU.confirmPassword}
							type={ResourceAssistance.FormControl.type.password}
							value={this.props.user.modalNewUser.confirmPassword}
							disabled={this.props.user.selectedUser.index !== -1}
							onChange={this.onConfirmPassword}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={4}>
						<label>{translate(ResourceAssistance.Message.active)}</label>
					</Col>
					<Col>
						<ToggleCheckBox onClick={this.onActive} checked={this.props.user.modalNewUser.isActive} />
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.admin.userConfig.user,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setAdmUserModalNULastName,
			setAdmUserModalNUFirstName,
			setAdmUserModalNUEmail,
			setAdmUserModalNUNickname,
			setAdmUserModalNULoginName,
			setAdmUserModalNUPassword,
			setAdmUserModalNUConfirmPassword,
			setAdmUserModalNUActive,
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AdmUserModalNUInfo))
