import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_PS_AP_ACTIVITIES,
	SET_PS_CHART_OF_ACCOUNTS,
	SET_PS_CHART_OF_ACCOUNTS_LOADING,
	SET_PS_COMPLETE_PAYMENT_PLANS,
	SET_PS_END_DATE_TIME,
	SET_PS_FILTER,
	SET_PS_FILTER_PAYMENT_SLIP_ID,
	SET_PS_GL_GROUPS,
	SET_PS_INVOICES,
	SET_PS_LOCATION,
	SET_PS_MODAL_GL_DESCRIPTION,
	SET_PS_MODAL_GL_SELECTED_GL,
	SET_PS_MODAL_LOCATION_ORGS,
	SET_PS_MODAL_LOCATION_SELECTED_ORG,
	SET_PS_MODAL_NEW_FILTER_INVOICE_NUM,
	SET_PS_MODAL_NEW_FILTER_INVOICE_ID,
	SET_PS_MODAL_NEW_INVOICES,
	SET_PS_MODAL_NEW_INVOICES_LOADING,
	SET_PS_MODAL_NEW_PENDING_INVOICES,
	SET_PS_MODAL_NEW_SEARCH_SUPPLIER,
	SET_PS_MODAL_NEW_SELECTED_INVOICE,
	SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE,
	SET_PS_PAYMENT_SLIPS,
	SET_PS_BANKS,
	SET_PS_BANKS_LOADING,
	SET_PS_MODAL_PAYMENT_PLAN_BRANCHES,
	SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME,
	SET_PS_MODAL_PAYMENT_PLAN_INVOICES,
	SET_PS_MODAL_PAYMENT_PLAN_PENDING_INVOICES,
	SET_PS_MODAL_PAYMENT_PLAN_REMARK,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_PENDING_INVOICE,
	SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE,
	SET_PS_PAYMENT_TYPES,
	SET_PS_PAYMENT_TYPES_LOADING,
	SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID,
	SET_PS_PAYMENT_PLANS,
	SET_PS_PAYMENT_PLAN_TAB,
	SET_PS_REQUESTS,
	SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN,
	SET_PS_SELECTED_GROUP,
	SET_PS_SELECTED_INVOICE,
	SET_PS_SELECTED_PAYMENT_PLAN,
	SET_PS_SELECTED_PAYMENT_SLIP,
	SET_PS_SELECTED_SUPPLIER,
	SET_PS_START_DATE_TIME,
	SET_PS_SUPPLIERS,
	SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID,
	SET_PS_SUPPLIER_LOADING,
	SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER,
	SET_PS_MODAL_NEW_SEARCH_TYPE,
	SET_PS_WITHHOLDING_TAXES,
	SET_PS_GL_DATE_TIME,
	SET_PS_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("month").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("month").milliseconds(0).valueOf(),
	generalLedgerDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterPaymentSlipId: "",
	isPaymentTypeLoading: false,
	isSupplierLoading: false,
	isChartOfAccountsLoading: false,
	isPaymentPlanCompleteTab: false,
	isBankLoading: false,
	filters: [],
	paymentTypes: [],
	banks: [],
	suppliers: [],
	withholdingTaxes: [],
	chartOfAccounts: [],
	accountsPayableActivities: [],
	selectedPaymentSlip: {
		index: -1,
		ps: null,
	},
	selectedSupplier: {
		index: -1,
		supplier: null,
	},
	selectedInvoice: {
		index: -1,
		invoice: null,
	},
	selectedPaymentPlan: {
		index: -1,
		paymentPlan: null,
	},
	selectedCompletePaymentPlan: {
		index: -1,
		paymentPlan: null,
	},
	selectedGroup: {
		index: -1,
		group: null,
	},
	paymentSlipTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		isLoading: false,
	},
	supplierTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.bankAccNum,
			ResourceAssistance.Message.bank,
			ResourceAssistance.Message.phone,
			ResourceAssistance.Message.address,
			ResourceAssistance.Message.taxId,
			"",
		],
		body: [],
		rowColor: [],
	},
	paymentPlanTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.psId,
			ResourceAssistance.Message.dueDate,
			ResourceAssistance.Message.payment,
			ResourceAssistance.Message.bankAccNum,
			ResourceAssistance.Message.bankName,
			ResourceAssistance.Message.bankBranch,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
			ResourceAssistance.Message.remark,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
		isDisplayDropDownItems: [],
	},
	completePaymentPlanTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.psId,
			ResourceAssistance.Message.dueDate,
			ResourceAssistance.Message.payment,
			ResourceAssistance.Message.bankAccNum,
			ResourceAssistance.Message.bankName,
			ResourceAssistance.Message.bankBranch,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
			ResourceAssistance.Message.remark,
			ResourceAssistance.Message.financialTransaction,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	invoiceTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.date,
			ResourceAssistance.Message.id,
			ResourceAssistance.Message.invoiceId,
			ResourceAssistance.Message.payment,
			ResourceAssistance.Message.totalPriceDiscountedTaxed,
			"",
		],
		body: [],
		colStyle: [],
		rowColor: [],
	},
	generalLedgerGroupTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.payment,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.debit,
			ResourceAssistance.Message.credit,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalSearch: {
		searchPaymentSlipRequestId: "",
		searchPaymentSlipId: "",
		searchSelectedSupplier: {
			index: -1,
			supplier: null,
		},
	},
	modalNew: {
		searchType: 1,
		filterInvoiceNum: "",
		filterInvoiceId: "",
		searchSupplier: {
			index: -1,
			supplier: null,
		},
		selectedInvoice: {
			index: -1,
			invoice: null,
		},
		selectedPendingInvoice: {
			index: -1,
			invoice: null,
		},
		invoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.id,
				ResourceAssistance.Message.invoiceId,
				ResourceAssistance.Message.totalPriceDiscountedTaxed,
			],
			body: [],
			colStyle: [],
			isLoading: false,
		},
		pendingInvoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.id,
				ResourceAssistance.Message.invoiceId,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.supplier,
				ResourceAssistance.Message.bankAccNum,
				ResourceAssistance.Message.bankCode,
				ResourceAssistance.Message.totalPriceDiscountedTaxed,
			],
			body: [],
			colStyle: [],
		},
	},
	modalPaymentPlan: {
		dateTime: moment().endOf("day").milliseconds(0).valueOf(),
		remark: "",
		branches: [],
		selectedPaymentType: {
			index: -1,
			type: null,
		},
		selectedBank: {
			index: -1,
			bank: null,
		},
		selectedBranch: {
			index: -1,
			branch: null,
		},
		selectedInvoice: {
			index: -1,
			invoice: null,
		},
		selectedPendingInvoice: {
			index: -1,
			invoice: null,
		},
		invoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.id,
				ResourceAssistance.Message.invoiceId,
				ResourceAssistance.Message.payment,
				ResourceAssistance.Message.totalPriceDiscountedTaxed,
			],
			body: [],
			colStyle: [],
		},
		pendingInvoiceTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.date,
				ResourceAssistance.Message.id,
				ResourceAssistance.Message.invoiceId,
				ResourceAssistance.Message.payment,
				ResourceAssistance.Message.totalPriceDiscountedTaxed,
			],
			body: [],
			colStyle: [],
		},
	},
	modalGeneralLedger: {
		description: "",
		selectedGL: {
			index: -1,
			GL: null,
		},
	},
}

const paymentSlipReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PS_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_PS_AP_ACTIVITIES:
			return Object.assign({}, state, {
				accountsPayableActivities: action.payload.value,
			})
		case SET_PS_WITHHOLDING_TAXES:
			return Object.assign({}, state, {
				withholdingTaxes: action.payload.value,
			})
		case SET_PS_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_PS_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_PS_GL_DATE_TIME:
			return Object.assign({}, state, {
				generalLedgerDateTime: action.payload.value,
			})
		case SET_PS_FILTER_PAYMENT_SLIP_ID:
			return Object.assign({}, state, {
				filterPaymentSlipId: action.payload.value,
			})
		case SET_PS_FILTER:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_PS_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_PS_CHART_OF_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				isChartOfAccountsLoading: action.payload.isLoading,
			})
		case SET_PS_PAYMENT_TYPES:
			return Object.assign({}, state, {
				paymentTypes: action.payload.value,
			})
		case SET_PS_PAYMENT_TYPES_LOADING:
			return Object.assign({}, state, {
				isPaymentTypeLoading: action.payload.isLoading,
			})
		case SET_PS_PAYMENT_PLAN_TAB:
			return Object.assign({}, state, {
				isPaymentPlanCompleteTab: action.payload.value,
			})
		case SET_PS_REQUESTS:
			return Object.assign({}, state, {
				paymentSlipTable: {
					...state.paymentSlipTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PS_SELECTED_PAYMENT_SLIP:
			return Object.assign({}, state, {
				selectedPaymentSlip: {
					index: action.payload.index,
					ps: action.payload.selected,
				},
			})
		case SET_PS_PAYMENT_SLIPS:
			return Object.assign({}, state, {
				supplierTable: {
					...state.supplierTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PS_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				selectedSupplier: {
					index: action.payload.index,
					supplier: action.payload.selected,
				},
			})
		case SET_PS_INVOICES:
			return Object.assign({}, state, {
				invoiceTable: {
					...state.invoiceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_PS_SELECTED_INVOICE:
			return Object.assign({}, state, {
				selectedInvoice: {
					index: action.payload.index,
					invoice: action.payload.selected,
				},
			})
		case SET_PS_PAYMENT_PLANS:
			return Object.assign({}, state, {
				paymentPlanTable: {
					...state.paymentPlanTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
					isDisplayDropDownItems: action.payload.isDisplayDropDownItems,
				},
			})
		case SET_PS_SELECTED_PAYMENT_PLAN:
			return Object.assign({}, state, {
				selectedPaymentPlan: {
					index: action.payload.index,
					paymentPlan: action.payload.selected,
				},
			})
		case SET_PS_BANKS:
			return Object.assign({}, state, {
				banks: action.payload.value,
			})
		case SET_PS_BANKS_LOADING:
			return Object.assign({}, state, {
				isBankLoading: action.payload.isLoading,
			})
		case SET_PS_COMPLETE_PAYMENT_PLANS:
			return Object.assign({}, state, {
				completePaymentPlanTable: {
					...state.completePaymentPlanTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_PS_SELECTED_COMPLETE_PAYMENT_PLAN:
			return Object.assign({}, state, {
				selectedCompletePaymentPlan: {
					index: action.payload.index,
					paymentPlan: action.payload.selected,
				},
			})
		case SET_PS_GL_GROUPS:
			return Object.assign({}, state, {
				generalLedgerGroupTable: {
					...state.generalLedgerGroupTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_PS_SELECTED_GROUP:
			return Object.assign({}, state, {
				selectedGroup: {
					index: action.payload.index,
					group: action.payload.selected,
				},
			})
		case SET_PS_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_PS_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_PS_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_PS_SUPPLIERS:
			return Object.assign({}, state, {
				suppliers: action.payload.value,
			})
		case SET_PS_SUPPLIER_LOADING:
			return Object.assign({}, state, {
				isSupplierLoading: action.payload.isLoading,
			})
		case SET_PS_MODAL_NEW_SEARCH_SUPPLIER:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchSupplier: {
						index: action.payload.index,
						supplier: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_NEW_SEARCH_TYPE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					searchType: action.payload.value,
				},
			})
		case SET_PS_MODAL_NEW_FILTER_INVOICE_NUM:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterInvoiceNum: action.payload.value,
				},
			})
		case SET_PS_MODAL_NEW_FILTER_INVOICE_ID:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					filterInvoiceId: action.payload.value,
				},
			})
		case SET_PS_MODAL_NEW_INVOICES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					invoiceTable: {
						...state.modalNew.invoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_PS_MODAL_NEW_INVOICES_LOADING:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					invoiceTable: {
						...state.modalNew.invoiceTable,
						isLoading: action.payload.isLoading,
					},
				},
			})
		case SET_PS_MODAL_NEW_SELECTED_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_NEW_PENDING_INVOICES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					pendingInvoiceTable: {
						...state.modalNew.pendingInvoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_PS_MODAL_NEW_SELECTED_PENDING_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPendingInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_SEARCH_PAYMENT_SLIP_REQUEST_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchPaymentSlipRequestId: action.payload.value,
				},
			})
		case SET_PS_MODAL_SEARCH_PAYMENT_SLIP_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchPaymentSlipId: action.payload.value,
				},
			})
		case SET_PS_MODAL_SEARCH_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchSelectedSupplier: {
						index: action.payload.index,
						supplier: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_DATE_TIME:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					dateTime: action.payload.value,
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_REMARK:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					remark: action.payload.value,
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_SELECTED_TYPE:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					selectedPaymentType: {
						index: action.payload.index,
						type: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BANK:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					selectedBank: {
						index: action.payload.index,
						bank: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_BRANCHES:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					branches: action.payload.value,
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_SELECTED_BRANCH:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					selectedBranch: {
						index: action.payload.index,
						branch: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_INVOICES:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					invoiceTable: {
						...state.modalPaymentPlan.invoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_SELECTED_INVOICE:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					selectedInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_PENDING_INVOICES:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					pendingInvoiceTable: {
						...state.modalPaymentPlan.pendingInvoiceTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_PS_MODAL_PAYMENT_PLAN_SELECTED_PENDING_INVOICE:
			return Object.assign({}, state, {
				modalPaymentPlan: {
					...state.modalPaymentPlan,
					selectedPendingInvoice: {
						index: action.payload.index,
						invoice: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_GL_SELECTED_GL:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					selectedGL: {
						index: action.payload.index,
						GL: action.payload.selected,
					},
				},
			})
		case SET_PS_MODAL_GL_DESCRIPTION:
			return Object.assign({}, state, {
				modalGeneralLedger: {
					...state.modalGeneralLedger,
					description: action.payload.value,
				},
			})
		default:
			return state
	}
}
export default paymentSlipReducer
