import { Descriptions, Select, TreeSelect } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setSelected, setValue } from "~/redux/action"
import {
	SET_FO_MODAL_NEW_SELECTED_BANK,
	SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE,
	SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE,
	SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"

class FOModalNewFields extends React.Component {
	constructor(props) {
		super(props)

		this.onSupplierReceivedDate = this.onSupplierReceivedDate.bind(this)
		this.onPaymentType = this.onPaymentType.bind(this)
		this.onBank = this.onBank.bind(this)
		this.onServiceType = this.onServiceType.bind(this)
	}

	componentDidMount() {
		if (this.props.FO.selectedFinancialPlan.financialPlan) {
			let selectedFinancialPlan = this.props.FO.selectedFinancialPlan.financialPlan
			this.props.setValue(SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME, selectedFinancialPlan.supplierReceivedDateTime)
			if (!_.isEmpty(this.props.FO.modalNew.paymentTypes)) {
				this.initialPaymentType()
			}
			if (!_.isEmpty(this.props.FO.modalNew.banks)) {
				this.initialBank()
			}
			if (!_.isEmpty(this.props.FO.modalNew.serviceTypes)) {
				this.initialServiceType()
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.FO.modalNew.paymentTypes, this.props.FO.modalNew.paymentTypes)) {
			if (this.props.FO.selectedFinancialPlan.financialPlan && !_.isEmpty(this.props.FO.modalNew.paymentTypes)) {
				this.initialPaymentType()
			}
		}
		if (!_.isEqual(prevProps.FO.modalNew.banks, this.props.FO.modalNew.banks)) {
			if (this.props.FO.selectedFinancialPlan.financialPlan && !_.isEmpty(this.props.FO.modalNew.banks)) {
				this.initialBank()
			}
		}
		if (!_.isEqual(prevProps.FO.modalNew.serviceTypes, this.props.FO.modalNew.serviceTypes)) {
			if (this.props.FO.selectedFinancialPlan.financialPlan && !_.isEmpty(this.props.FO.modalNew.serviceTypes)) {
				this.initialServiceType()
			}
		}
		if (
			!_.isEqual(prevProps.FO.modalNew.selectedPaymentType, this.props.FO.modalNew.selectedPaymentType) &&
			_.isEmpty(this.props.FO.modalNew.pendingPaymentPlanTable.original)
		) {
			this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_BANK, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME, moment().endOf("day").milliseconds(0).valueOf())
		this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE, -1, null)
		this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_BANK, -1, null)
		this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE, -1, null)
	}

	initialPaymentType() {
		let paymentTypeIdx = this.props.FO.modalNew.paymentTypes.findIndex(
			(each) => each.displayName === this.props.FO.selectedFinancialPlan.financialPlan.paymentMethod
		)
		if (paymentTypeIdx > -1) {
			this.props.setSelected(
				SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE,
				paymentTypeIdx,
				this.props.FO.modalNew.paymentTypes[paymentTypeIdx]
			)
		}
	}

	initialServiceType() {
		let serviceTypeIdx = this.props.FO.modalNew.serviceTypes.findIndex(
			(each) => each.code === this.props.FO.selectedFinancialPlan.financialPlan.serviceTypeCode
		)
		if (serviceTypeIdx > -1) {
			this.props.setSelected(
				SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE,
				serviceTypeIdx,
				this.props.FO.modalNew.serviceTypes[serviceTypeIdx]
			)
		}
	}

	initialBank() {
		let bankIdx = this.props.FO.modalNew.banks.findIndex(
			(each) => each.code === this.props.FO.selectedFinancialPlan.financialPlan.bankCode
		)
		if (bankIdx > -1) {
			let branchIdx = this.props.FO.modalNew.banks[bankIdx].branches.findIndex(
				(each) => each.code === this.props.FO.selectedFinancialPlan.financialPlan.bankBranchCode
			)
			if (branchIdx > -1) {
				this.props.setSelected(
					SET_FO_MODAL_NEW_SELECTED_BANK,
					bankIdx + "-" + branchIdx,
					this.props.FO.modalNew.banks[bankIdx].branches[branchIdx]
				)
			}
		}
	}

	onSupplierReceivedDate(value) {
		this.props.setValue(
			SET_FO_MODAL_NEW_SUPPLIER_RECEIVED_DATE_TIME,
			moment(value).endOf("day").milliseconds(0).valueOf()
		)
	}

	onPaymentType(value) {
		if (value === undefined) {
			this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_PAYMENT_TYPE, value, this.props.FO.modalNew.paymentTypes[value])
	}

	onServiceType(value) {
		if (value === undefined) {
			this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE, -1, null)
			return
		}
		this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_SERVICE_TYPE, value, this.props.FO.modalNew.serviceTypes[value])
	}

	onBank(value, node) {
		if (value === undefined) {
			this.props.setSelected(SET_FO_MODAL_NEW_SELECTED_BANK, -1, null)
			return
		}
		let entry = value.split("-")
		this.props.setSelected(
			SET_FO_MODAL_NEW_SELECTED_BANK,
			value,
			this.props.FO.modalNew.banks[entry[0]].branches[entry[1]]
		)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.payment)}</legend>
				<Row>
					<Col>
						<AntDatePicker
							size={"middle"}
							format={"DD-MM-YYYY"}
							allowClear={false}
							values={[moment(this.props.FO.modalNew.supplierReceivedDateTime)]}
							onChange={this.onSupplierReceivedDate}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<Select
							allowClear
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) =>
								optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
							}
							placeholder={translate(ResourceAssistance.Message.paymentType)}
							loading={this.props.FO.modalNew.isPaymentTypeLoading}
							disabled={!_.isEmpty(this.props.FO.modalNew.pendingPaymentPlanTable.original)}
							value={
								this.props.FO.modalNew.selectedPaymentType.type
									? this.props.FO.modalNew.selectedPaymentType.index
									: undefined
							}
							onChange={this.onPaymentType}
						>
							{Utils.renderSelects(this.props.FO.modalNew.paymentTypes, false)}
						</Select>
					</Col>
				</Row>
				{this.props.FO.modalNew.selectedPaymentType.type &&
					this.props.FO.modalNew.selectedPaymentType.type.bankAccount && (
						<>
							<Row>
								<Col>
									<Select
										allowClear
										showSearch
										style={{ textAlign: "center", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) =>
											optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
										}
										placeholder={translate(ResourceAssistance.Message.serviceType)}
										loading={this.props.FO.modalNew.isServiceTypeLoading}
										status={this.props.FO.modalNew.selectedServiceType.type ? undefined : "error"}
										value={
											this.props.FO.modalNew.selectedServiceType.type
												? this.props.FO.modalNew.selectedServiceType.index
												: undefined
										}
										onChange={this.onServiceType}
									>
										{Utils.renderSelects(this.props.FO.modalNew.serviceTypes, false)}
									</Select>
								</Col>
							</Row>
							<Row>
								<Col>
									<TreeSelect
										style={{
											textAlign: "center",
										}}
										showSearch
										allowClear
										treeLine={{ showLeafIcon: false }}
										treeExpandAction="click"
										placeholder={translate(ResourceAssistance.Message.bank)}
										filterTreeNode={(input, treeNode) => {
											return treeNode.title.toLowerCase().includes(input)
										}}
										disabled={!_.isEmpty(this.props.FO.modalNew.pendingPaymentPlanTable.original)}
										status={this.props.FO.modalNew.selectedBank.bank ? undefined : "error"}
										value={
											this.props.FO.modalNew.selectedBank.bank ? this.props.FO.modalNew.selectedBank.index : undefined
										}
										onChange={this.onBank}
										treeData={Utils.getTreeNodesFromBank(this.props.FO.modalNew.banks, true, false)}
									/>
								</Col>
							</Row>
						</>
					)}
				{this.props.FO.modalNew.selectedPaymentType.type &&
					this.props.FO.modalNew.selectedPaymentType.type.bankAccount &&
					this.props.FO.modalNew.selectedBank.bank && (
						<Row>
							<Col>
								<Descriptions
									style={{ flex: "unset", display: "unset" }}
									contentStyle={{ fontStyle: "italic", fontWeight: "normal" }}
									column={1}
								>
									<Descriptions.Item label={translate(ResourceAssistance.Message.bank)}>
										{this.props.FO.modalNew.banks[this.props.FO.modalNew.selectedBank.index.split("-")[0]].code}
									</Descriptions.Item>
									<Descriptions.Item label={translate(ResourceAssistance.Message.branch)}>
										{this.props.FO.modalNew.selectedBank.bank.code}
									</Descriptions.Item>
									<Descriptions.Item label={translate(ResourceAssistance.Message.bankAccNum)}>
										{this.props.FO.modalNew.selectedBank.bank.account}
									</Descriptions.Item>
								</Descriptions>
							</Col>
						</Row>
					)}
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	FO: state.accounting.financialOperations,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FOModalNewFields))
