import english from "./en-US"
import chinese from "./zh-CN"
import thai from "./th-TH"

const Message = {
	...english,
	...chinese,
	...thai,
}

export default Message
