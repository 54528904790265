import React from "react"
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import ConfigAssets from "./assets/ConfigAssets"
import ConfigLiabilities from "./liabilities/ConfigLiabilities"
import ConfigEquity from "./equity/ConfigEquity"
import ConfigExpenses from "./expenses/ConfigExpenses"
import ConfigRevenues from "./revenues/ConfigRevenues"

class ConfigChartOfAccounts extends React.Component {
	EConfigCOA = {
		ASSETS: "ASSETS",
		LIABILITIES: "LIABILITIES",
		EQUITY: "EQUITY",
		EXPENSES: "EXPENSES",
		REVENUE: "REVENUE",
	}

	constructor(props) {
		super(props)

		this.state = {
			componentName: "ConfigChartOfAccounts",
			tab: this.EConfigCOA.ASSETS,
		}

		this.onTabClick = this.onTabClick.bind(this)
		this.loadData = this.loadData.bind(this)
	}

	loadData(url, func = () => {}, reqInterceptor = () => {}, resInterceptor = () => {}, error = () => {}) {
		let params = {
			method: "GET",
			url: url,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			func(res)
		}
		axios(params, callback, error, reqInterceptor, resInterceptor)
	}

	onTabClick(event) {
		this.setState({ tab: event })
	}

	render() {
		return (
			<Container fluid id={this.state.componentName} className={ResourceAssistance.CSS.fullFlex}>
				<Row className={ResourceAssistance.CSS.fullSize}>
					<Col>
						<Tabs activeKey={this.state.tab} onSelect={this.onTabClick} mountOnEnter={false} transition={false} fill>
							<Tab
								eventKey={this.EConfigCOA.ASSETS}
								title={translate(ResourceAssistance.Message.assets)}
								name={this.EConfigCOA.ASSETS}
							>
								{this.state.tab === this.EConfigCOA.ASSETS && <ConfigAssets loadAccounts={this.loadData} />}
							</Tab>
							<Tab
								eventKey={this.EConfigCOA.LIABILITIES}
								title={translate(ResourceAssistance.Message.liabilities)}
								name={this.EConfigCOA.LIABILITIES}
							>
								{this.state.tab === this.EConfigCOA.LIABILITIES && <ConfigLiabilities loadAccounts={this.loadData} />}
							</Tab>
							<Tab
								eventKey={this.EConfigCOA.EQUITY}
								title={translate(ResourceAssistance.Message.equity)}
								name={this.EConfigCOA.EQUITY}
							>
								{this.state.tab === this.EConfigCOA.EQUITY && <ConfigEquity loadAccounts={this.loadData} />}
							</Tab>
							<Tab
								eventKey={this.EConfigCOA.REVENUE}
								title={translate(ResourceAssistance.Message.revenues)}
								name={this.EConfigCOA.REVENUE}
							>
								{this.state.tab === this.EConfigCOA.REVENUE && <ConfigRevenues loadAccounts={this.loadData} />}
							</Tab>
							<Tab
								eventKey={this.EConfigCOA.EXPENSES}
								title={translate(ResourceAssistance.Message.expenses)}
								name={this.EConfigCOA.EXPENSES}
							>
								{this.state.tab === this.EConfigCOA.EXPENSES && <ConfigExpenses loadAccounts={this.loadData} />}
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigChartOfAccounts)
