import { Card } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { MdDeleteForever } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setLoadingAction,
	setPharmacyPatients,
	setPharmacySkippedOrders,
	setSelected,
	setValue,
	setWarningId,
	setWarningMsgAction,
} from "~/redux/action"
import { SET_MODAL_WARNING_SIZE, SET_PAGE_LOADING, SET_PHARMACY_SELECTED_SKIPPED_ORDER, SET_WARNING_DISPLAY } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class PharmacyRightSkippedOrder extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "PharmacyRightSkippedOrder",
		}

		this.onSelectSkipped = this.onSelectSkipped.bind(this)
		this.onDelete = this.onDelete.bind(this)
	}

	componentDidMount() {
		if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
			this.props.setPharmacySkippedOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyOrders)
		} else {
			this.props.setPharmacySkippedOrders([])
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.warning.isContinue, this.props.warning.isContinue)) {
			if (this.props.warning.isContinue) {
				if (_.isEqual(this.props.warning.id, ResourceAssistance.ID.HOSPITAL.pharmacy.deleteSkippedOrder)) {
					this.deletePharmacyOrder()
				}
			}
		}
		if (!_.isEqual(prevProps.pharmacy.selectedDoctorOrder, this.props.pharmacy.selectedDoctorOrder)) {
			if (this.props.pharmacy.selectedDoctorOrder.doctorOrder) {
				this.props.setPharmacySkippedOrders(this.props.pharmacy.selectedDoctorOrder.doctorOrder.pharmacyOrders)
			} else {
				this.props.setPharmacySkippedOrders([])
			}
		}
		if (!_.isEqual(prevProps.pharmacy.skippedOrderTable.filtered, this.props.pharmacy.skippedOrderTable.filtered)) {
			let index = this.props.pharmacy.selectedSkippedOrder.skippedOrder
				? this.props.pharmacy.skippedOrderTable.filtered.findIndex(
						(skippedOrder) => skippedOrder.id === this.props.pharmacy.selectedSkippedOrder.skippedOrder.id
				  )
				: -1
			if (index > -1) {
				this.props.setSelected(SET_PHARMACY_SELECTED_SKIPPED_ORDER, index, this.props.pharmacy.skippedOrderTable.filtered[index])
			} else {
				this.props.setSelected(SET_PHARMACY_SELECTED_SKIPPED_ORDER, -1, null)
			}
		}
	}

	deletePharmacyOrder() {
		let data = this.props.pharmacy.selectedPatient.patient.isSearchPatient
			? {
					pid: this.props.pharmacy.selectedPatient.patient.id,
			  }
			: {
					startDateTime: this.props.pharmacy.startDateTime,
					endDateTime: this.props.pharmacy.endDateTime,
			  }
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.pharmacy.deletePharmacyOrder,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: Object.assign(data, {
				branchId: this.props.pharmacy.modalLocation.selectedBranch.branch.id,
				pharmacyOrders: [
					{
						id: this.props.pharmacy.selectedSkippedOrder.skippedOrder.id,
					},
				],
			}),
		}
		let callback = (res) => {
			if (this.props.pharmacy.selectedPatient.patient.isSearchPatient) {
				res.data.patients.forEach((patient) =>
					Object.assign(patient, {
						isSearchPatient: true,
					})
				)
			}
			this.props.setPharmacyPatients(res.data.patients)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSelectSkipped(order, index) {
		this.props.setSelected(SET_PHARMACY_SELECTED_SKIPPED_ORDER, index, this.props.pharmacy.skippedOrderTable.filtered[index])
	}

	onDelete(event, row, rIdx, cIdx) {
		let selectedSkippedOrder = this.props.pharmacy.skippedOrderTable.filtered[rIdx]
		this.props.setWarningId(ResourceAssistance.ID.HOSPITAL.pharmacy.deleteSkippedOrder)
		this.props.setValue(SET_MODAL_WARNING_SIZE, ResourceAssistance.Modal.size.lg)
		this.props.setWarningMsgAction(
			<Card
				title={translate(ResourceAssistance.Message.deleteSkippedPharmacyOrder)}
				bordered={false}
				style={{
					backgroundColor: "inherit",
				}}
				headStyle={{
					fontSize: "28px",
				}}
			>
				<p>{this.props.pharmacy.selectedDoctorOrder.doctorOrder.code}</p>
				<p>{this.props.pharmacy.selectedDoctorOrder.doctorOrder.description}</p>
				<p>{this.props.pharmacy.selectedDoctorOrder.doctorOrder.notes}</p>
				<p>
					{Utils.formatDate(selectedSkippedOrder.startDateTime)} - {Utils.formatDate(selectedSkippedOrder.endDateTime)}
				</p>
				<p>
					{translate(ResourceAssistance.Message.amount)}: {Utils.formatNumWithComma(selectedSkippedOrder.amount)} {selectedSkippedOrder.unit}
				</p>
				<p>{selectedSkippedOrder.lastModifiedBy.displayName}</p>
			</Card>
		)
		this.props.onModalDisplayAction(SET_WARNING_DISPLAY, true)
	}

	render() {
		return (
			<Row id={this.state.componentName} className={"full-size"}>
				<Col>
					<ScrollableTable
						enableHighlight
						striped
						hover
						displayBtn
						buttonColors={[ResourceAssistance.Button.variant.outlineRed]}
						btnIcons={[<MdDeleteForever size={ResourceAssistance.ReactIcon.size} />]}
						onCheckedArray={[this.onDelete]}
						data={this.props.pharmacy.skippedOrderTable}
						onClick={this.onSelectSkipped}
						isClearHighlight={this.props.pharmacy.selectedSkippedOrder.index === -1}
						highlightedRow={this.props.pharmacy.selectedSkippedOrder.index}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	warning: state.modal.warning,
	pharmacy: state.hospital.pharmacy,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setPharmacyPatients,
			setPharmacySkippedOrders,
			setSelected,
			setValue,
			setWarningId,
			setWarningMsgAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PharmacyRightSkippedOrder))
