import _ from "lodash"
import React from "react"
import { Button, Form, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { AiOutlineWarning } from "react-icons/ai"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setOPDPatients, setObjArray } from "~/redux/action"
import { SET_OPD_DOCTOR_DISPLAY, SET_OPD_MEDICAL_CERTIFICATE_DISPLAY, SET_OPD_NURSE_DISPLAY, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"
import OPDModalMedicalCertificateDetails from "./OPDModalMedicalCertificateDetails"

class OPDModalMedicalCertificate extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "OPDModalMedicalCertificate",
		}

		this.onClose = this.onClose.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onDone = this.onDone.bind(this)
	}

	reset() {
		this.props.onModalDisplayAction(SET_OPD_MEDICAL_CERTIFICATE_DISPLAY, false)
	}

	isNewDisabled() {
		return !this.props.opd.modalCertificate.selectedCategory.category || !this.props.opd.modalCertificate.selectedService.service
	}

	isDoneBtnDisabled() {
		if (!this.props.opd.modalCertificate.selectedUser.user) {
			return true
		}
		if (!this.props.opd.selectedCertificate.certificate) {
			return false
		}
		let target = Object.assign({}, this.props.opd.selectedCertificate.certificate, {
			...this.props.opd.selectedCertificate.certificate,
			radiologyDetails: Utils.trim(this.props.opd.modalCertificate.radiologyDetails),
			examinationStartDateTime: this.props.opd.modalCertificate.examinationStartDateTime,
			bloodTestDetails: Utils.trim(this.props.opd.modalCertificate.bloodTestDetails),
			examinationEndDateTime: this.props.opd.modalCertificate.examinationEndDateTime,
			doctorOpinion: Utils.trim(this.props.opd.modalCertificate.doctorOpinion),
			diagnosis: Utils.trim(this.props.opd.modalCertificate.diagnosis),
			symptoms: Utils.trim(this.props.opd.modalCertificate.symptom),
			treatment: Utils.trim(this.props.opd.modalCertificate.treatment),
			bloodTestChecked: this.props.opd.modalCertificate.bloodTestChecked,
			radiologyChecked: this.props.opd.modalCertificate.radiologyChecked,
			investigation: Utils.trim(this.props.opd.modalCertificate.investigation),
			restEndDateTime: this.props.opd.modalCertificate.endDateTime,
			restStartDateTime: this.props.opd.modalCertificate.startDateTime,
		})

		return (
			_.isEqual(this.props.opd.selectedCertificate.certificate, target) &&
			this.props.opd.modalLocation.selectedOrg.org &&
			this.props.opd.selectedCertificate.certificate.location.parent.parent &&
			this.props.opd.modalLocation.selectedOrg.org.id === this.props.opd.selectedCertificate.certificate.location.parent.parent.id &&
			this.props.opd.modalLocation.selectedBranch.branch &&
			this.props.opd.modalLocation.selectedBranch.branch.id === this.props.opd.selectedCertificate.certificate.location.parent.id &&
			this.props.opd.modalLocation.selectedDepartment.department &&
			this.props.opd.modalLocation.selectedDepartment.department.id === this.props.opd.selectedCertificate.certificate.location.id &&
			this.props.opd.modalCertificate.selectedService.service &&
			this.props.opd.modalCertificate.selectedService.service.id === this.props.opd.selectedCertificate.certificate.service.id &&
			this.props.opd.modalCertificate.selectedUser.user &&
			this.props.opd.modalCertificate.selectedUser.user.displayName === this.props.opd.selectedCertificate.certificate.doctorName &&
			this.props.opd.modalCertificate.selectedUser.user &&
			this.props.opd.modalCertificate.selectedUser.user.licenseNum === this.props.opd.selectedCertificate.certificate.doctorLicense
		)
	}

	onClose() {
		this.reset()
	}

	onNew(event) {
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				medicalCertificates: [
					{
						locationId: this.props.opd.modalLocation.selectedDepartment.department.id,
						serviceId: this.props.opd.modalCertificate.selectedService.service.id,
						examinationStartDateTime: this.props.opd.modalCertificate.examinationStartDateTime,
						examinationEndDateTime: this.props.opd.modalCertificate.examinationEndDateTime,
						startDateTime: this.props.opd.modalCertificate.startDateTime,
						endDateTime: this.props.opd.modalCertificate.endDateTime,
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
				this.props.onModalDisplayAction(SET_OPD_DOCTOR_DISPLAY, false)
				this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, false)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.opd.saveCertificate,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				pid: this.props.opd.selectedPatient.patient.id,
				admissionId: this.props.opd.selectedMedicalRecord.medicalRecord.clinicalRecord.id,
				userId: this.props.login.user.id,
				medicalCertificates: [
					{
						id: this.props.opd.selectedCertificate.certificate ? this.props.opd.selectedCertificate.certificate.id : 0,
						locationId: this.props.opd.modalLocation.selectedDepartment.department.id,
						categoryId: this.props.opd.modalCertificate.selectedCategory.category.id,
						serviceId: this.props.opd.modalCertificate.selectedService.service.id,
						examinationStartDateTime: this.props.opd.modalCertificate.examinationStartDateTime,
						examinationEndDateTime: this.props.opd.modalCertificate.examinationEndDateTime,
						doctorName: Utils.trim(this.props.opd.modalCertificate.selectedUser.user.displayName),
						doctorNameEng: Utils.trim(this.props.opd.modalCertificate.selectedUser.user.displayNameEng),
						doctorLicense: Utils.trim(this.props.opd.modalCertificate.selectedUser.user.licenseNum),
						symptoms: Utils.trim(this.props.opd.modalCertificate.symptom),
						diagnosis: Utils.trim(this.props.opd.modalCertificate.diagnosis),
						bloodTestChecked: this.props.opd.modalCertificate.bloodTestChecked,
						bloodTestDetails: Utils.trim(this.props.opd.modalCertificate.bloodTestDetails),
						radiologyChecked: this.props.opd.modalCertificate.radiologyChecked,
						radiologyDetails: Utils.trim(this.props.opd.modalCertificate.radiologyDetails),
						investigation: Utils.trim(this.props.opd.modalCertificate.investigation),
						treatment: Utils.trim(this.props.opd.modalCertificate.treatment),
						doctorOpinion: Utils.trim(this.props.opd.modalCertificate.doctorOpinion),
						startDateTime: this.props.opd.modalCertificate.startDateTime,
						endDateTime: this.props.opd.modalCertificate.endDateTime,
						outpatient: true,
						locationAccountsReceivable: this.props.opd.location.accountsReceivable,
					},
				],
			},
		}

		let callback = (res) => {
			if (res.data.patients.length > 0) {
				let patients = this.props.opd.patientTable.original.filter((each) => each.id !== res.data.patients[0].id)
				patients.push(res.data.patients[0])
				this.props.setOPDPatients(patients)
				this.props.onModalDisplayAction(SET_OPD_DOCTOR_DISPLAY, false)
				this.props.onModalDisplayAction(SET_OPD_NURSE_DISPLAY, false)
			}
			this.reset()
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				size={ResourceAssistance.Modal.size.lg}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form className={`${ResourceAssistance.CSS.fullSize} ${ResourceAssistance.CSS.fullFlex}`} onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Hospitel.medicalCertificate)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<OPDModalMedicalCertificateDetails />
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						{!this.props.opd.selectedCertificate.certificate && (
							<Button variant={ResourceAssistance.Button.variant.warning} disabled={this.isNewDisabled()} onClick={this.onNew}>
								<AiOutlineWarning size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.new)}
							</Button>
						)}
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit} disabled={this.isDoneBtnDisabled()}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	isDisplay: state.modal.isOutpatientMedicalCertificateDisplay,
	login: state.login,
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setLoadingAction,
			setObjArray,
			setOPDPatients,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OPDModalMedicalCertificate))
