import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import Signature from "~/report/component/Signature"
import { Utils } from "~/utils/Utils"

class ReportIPDPatientExpenseEstDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				services: [],
				budgets: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page
						size={ResourceAssistance.Report.size.page.A4}
						style={[ResourceAssistance.Report.styles.page, styles.page]}
					>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID/{this.state.data.identificationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.identification}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age/{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB/{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Tel./{this.state.data.telIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.tel}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Address/{this.state.data.addressIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.address}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Service Header */}
						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceNameHeader]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.serviceIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.noteIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.amountIntl}</Text>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.serviceNameHeader]}>
									<Text style={[ResourceAssistance.Report.styles.textBold]}>Service</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.note]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Note</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.amount]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Amount</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{/* Services */}
						{Object.entries(this.state.data.services).map(([key, value]) => {
							return [
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontStyle: "bold" }]}>
									<View style={[ResourceAssistance.Report.styles.col]}>
										<Text>{key}</Text>
									</View>
								</View>,
							].concat(
								value.map((service) => {
									return (
										<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
											<View style={[ResourceAssistance.Report.styles.col, styles.blankSpace]}></View>

											<View style={[ResourceAssistance.Report.styles.col, styles.serviceName]}>
												<Text>{service.name}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.note]}>
												<Text>{service.description}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.amount, { textAlign: "right" }]}>
												<Text>{service.amount}</Text>
											</View>
										</View>
									)
								})
							)
						})}
						{/* Sub Total */}
						<View style={[{ flex: 1 }]} wrap={false}>
							<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Sub Total/{this.state.data.subTotalIntl}:
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.subTotal}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.dashBottom, { marginTop: 5 }]} />
							<View style={ResourceAssistance.Report.styles.row}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text style={[ResourceAssistance.Report.styles.textBoldIntalic]}>
										*{this.state.data.forAnyComplicationsIntl}
									</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Budget Header */}
						<View wrap={false} break>
							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.name]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.nameIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.contact]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>
										{this.state.data.responsibleContactIntl}
									</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.budgetNote]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.noteIntl}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.budget]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.budgetIntl}</Text>
								</View>
							</View>

							<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.name]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Name</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.contact]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Company/Responsible{"\n"}Person Contact</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.budgetNote]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Note</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.budget]}>
									<Text style={ResourceAssistance.Report.styles.textBold}>Budget</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.horizontalLine} />
						</View>

						{/* Budget */}
						{this.state.data.budgets.map((budget) => {
							return (
								<View style={[ResourceAssistance.Report.styles.row, styles.itemRow]}>
									<View style={[ResourceAssistance.Report.styles.col, styles.name, { paddingRight: 5 }]}>
										<Text>{budget.name}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.contact, { paddingRight: 5 }]}>
										<Text>{budget.contact}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.budgetNote, { paddingRight: 5 }]}>
										<Text>{budget.note}</Text>
									</View>
									<View style={[ResourceAssistance.Report.styles.col, styles.budget, { textAlign: "right" }]}>
										<Text>{budget.budget}</Text>
									</View>
								</View>
							)
						})}

						{/* Budget Sub Total */}
						<View style={[{ flex: 1 }]} wrap={false}>
							<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Sub Total/{this.state.data.subTotalIntl}:
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>
												{Utils.formatNumWithComma(Utils.BigNumber(this.state.data.budgetSubTotal).abs().toFixed(2))}
											</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						<View wrap={false}>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Total Estimation Cost/{this.state.data.serviceSubTotalIntl}:
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.subTotal}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Total Budget/{this.state.data.budgetSubTotalIntl}:
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.budgetSubTotal}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.col, { textAlign: "right" }]}>
									<View style={ResourceAssistance.Report.styles.row}>
										<View style={[ResourceAssistance.Report.styles.col, { minWidth: 20 }]}>
											<Text style={ResourceAssistance.Report.styles.textBold}>
												Grand Total/{this.state.data.grandTotalIntl}:
											</Text>
										</View>
										<View style={ResourceAssistance.Report.styles.col}>
											<Text>{this.state.data.grandTotal}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.horizontalSubline]} />
							<View style={ResourceAssistance.Report.styles.row}>
								<View style={[ResourceAssistance.Report.styles.col]}>
									<Text style={ResourceAssistance.Report.styles.textBoldIntalic}>
										*{this.state.data.inCaseRefundPatientIntl}
									</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Signature */}
						<Signature
							signatureDate={this.state.data.signatureDate}
							numOfSignature={2}
							signatureTitles={this.state.data.signatureTitles}
						/>
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	page: {
		paddingBottom: 100,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		fontSize: 16,
	},
	dashBottom: {
		borderBottomStyle: "dashed",
		borderBottomWidth: 1,
	},
	serviceNameHeader: {
		minWidth: "60%",
		maxWidth: "60%",
	},
	blankSpace: {
		minWidth: "2%",
		maxWidth: "2%",
	},
	serviceName: {
		minWidth: "58%",
		maxWidth: "58%",
	},
	note: {
		minWidth: "28%",
		maxWidth: "28%",
	},
	amount: {
		minWidth: "12%",
		maxWidth: "12%",
	},

	name: {
		minWidth: "28%",
		maxWidth: "28%",
	},
	budget: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	contact: {
		minWidth: "35%",
		maxWidth: "35%",
	},
	budgetNote: {
		minWidth: "25%",
		maxWidth: "25%",
	},
})

export default ReportIPDPatientExpenseEstDoc
