import React from "react"
import { Col, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

class RegisterModalClinicalRecordRef extends React.Component {
	constructor(props) {
		super(props)

		this.onHN = this.onHN.bind(this)
	}

	componentDidMount() {
		if (this.props.ipd.selectedAdmission.admission) {
			this.props.setValue(
				SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN,
				this.props.ipd.selectedAdmission.admission.clinicalRecord.hn
			)
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN, "")
	}

	onHN(event) {
		this.props.setValue(SET_HOSPITEL_REGISTER_MODAL_CLINICAL_RECORD_HN, event.target.value)
	}

	render() {
		return (
			<fieldset>
				<legend>{translate(ResourceAssistance.Message.references)}</legend>
				<Row>
					<Col>
						<GInput
							style={{ textAlign: "center" }}
							type={ResourceAssistance.FormControl.type.number}
							placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Hospitel.hn })}
							value={this.props.ipd.modalClinicalRecord.hn}
							onChange={this.onHN}
						/>
					</Col>
				</Row>
			</fieldset>
		)
	}
}

const mapStateToProps = (state) => ({
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RegisterModalClinicalRecordRef))
