import _ from "lodash"
import React from "react"
import { Fragment } from "react"
import { Col, FormCheck, FormControl, FormGroup, Row } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class HospitelModalDoctorNoteS extends React.Component {
	constructor(props) {
		super(props)

		this.onSubjective = this.onSubjective.bind(this)
		this.onSubjectiveAsymptomatic = this.onSubjectiveAsymptomatic.bind(this)
		this.onSubjectiveFever = this.onSubjectiveFever.bind(this)
		this.onSubjectiveCough = this.onSubjectiveCough.bind(this)
		this.onSubjectiveExhausted = this.onSubjectiveExhausted.bind(this)
	}

	componentDidMount() {
		if (this.props.hospitel.selectedSOAP.soap) {
			this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE, this.props.hospitel.selectedSOAP.soap.subjective)
		}
	}

	componentWillUnmount() {
		this.reset()
	}

	reset() {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE, "")
	}

	modifySubjective(subjective, isAdd) {
		let value = this.props.hospitel.modalDoctorNote.subjective
		if (isAdd) {
			value = value.concat(_.isEmpty(value) ? "" : ", ", subjective)
		} else {
			value = value.replace(subjective, "")
		}
		value = Utils.replaceDuplicateSpaces(value)
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE, Utils.trim(value))
	}

	onSubjective(event) {
		this.props.setValue(SET_HOSPITEL_MODAL_DOCTOR_NOTE_SUBJECTIVE, event.target.value)
	}

	onSubjectiveAsymptomatic(event) {
		this.modifySubjective(event.target.value, event.target.checked)
	}

	onSubjectiveFever(event) {
		this.modifySubjective(event.target.value, event.target.checked)
	}

	onSubjectiveCough(event) {
		this.modifySubjective(event.target.value, event.target.checked)
	}

	onSubjectiveExhausted(event) {
		this.modifySubjective(event.target.value, event.target.checked)
	}

	render() {
		return (
			<Fragment>
				<Row>
					<Col md={"auto"}>
						<Row>
							<Col>
								<FormGroup controlId={"asymptomatic"}>
									<FormCheck
										label={"ไม่มีอาการ"}
										checked={_.includes(this.props.hospitel.modalDoctorNote.subjective, "ไม่มีอาการ")}
										onChange={this.onSubjectiveAsymptomatic}
										value={"ไม่มีอาการ"}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup controlId={"fever"}>
									<FormCheck
										label={"ไข้"}
										checked={_.includes(this.props.hospitel.modalDoctorNote.subjective, "ไข้")}
										onChange={this.onSubjectiveFever}
										value={"ไข้"}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup controlId={"cough"}>
									<FormCheck
										label={"ไอ"}
										checked={_.includes(this.props.hospitel.modalDoctorNote.subjective, "ไอ")}
										onChange={this.onSubjectiveCough}
										value={"ไอ"}
									/>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<Col>
								<FormGroup controlId={"exhausted"}>
									<FormCheck
										label={"หอบเหนื่อย"}
										checked={_.includes(this.props.hospitel.modalDoctorNote.subjective, "หอบเหนื่อย")}
										onChange={this.onSubjectiveExhausted}
										value={"หอบเหนื่อย"}
									/>
								</FormGroup>
							</Col>
						</Row>
					</Col>
					<Col>
						<Row>
							<Col>
								<FormControl
									as={ResourceAssistance.FormControl.as.textArea}
									rows={5}
									value={this.props.hospitel.modalDoctorNote.subjective}
									onChange={this.onSubjective}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalDoctorNoteS))
