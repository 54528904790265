import _ from "lodash"
import React, { Fragment } from "react"
import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap"
import DropdownItem from "react-bootstrap/esm/DropdownItem"
import DropdownMenu from "react-bootstrap/esm/DropdownMenu"
import DropdownToggle from "react-bootstrap/esm/DropdownToggle"
import { BiBarcodeReader } from "react-icons/bi"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdLibraryAdd } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setLoadingAction, setSelected } from "~/redux/action"
import { SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY, SET_PAGE_LOADING } from "~/redux/type/"
import { PrintableDataFactory } from "~/utils/factory/print/PrintableDataFactory"
import { Utils } from "~/utils/Utils"
import { SET_REGISTER_SELECTED_ADMISSION } from "~/redux/type"
import { Divider } from "antd"

class IPDLeftClinicalRecordAction extends React.Component {
	constructor(props) {
		super(props)

		this.onRegisterOPD = this.onRegisterOPD.bind(this)
		this.onModifyPatient = this.onModifyPatient.bind(this)
		this.onPrintPatientLabel = this.onPrintPatientLabel.bind(this)
		this.onPrintFirstAssessment = this.onPrintFirstAssessment.bind(this)
		this.onPrintClaim = this.onPrintClaim.bind(this)
		this.onPrintNovel = this.onPrintNovel.bind(this)
		this.onPrintHealthCare = this.onPrintHealthCare.bind(this)
		this.onPrintCovidLab = this.onPrintCovidLab.bind(this)
		this.onPrintExpenseEstimation = this.onPrintExpenseEstimation.bind(this)
	}

	isRegisterOpdDisabled() {
		return this.props.ipd.selectedPatient.patient.admissions.some((admission) => !admission.dischargedDateTime && admission.clinicalRecord)
	}

	downloadFile(path, name, func) {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.registration.downloadFile,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			params: {
				path: path,
				name: name,
			},
		}

		let callback = (res) => {
			func(res)
		}

		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}

		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onModifyPatient(event) {
		this.props.onModalDisplayAction(SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY, true)
	}

	onRegisterOPD(event) {
		this.props.setSelected(SET_REGISTER_SELECTED_ADMISSION, -1, null)
		this.props.onModalDisplayAction(SET_HOSPITEL_REGISTER_CLINICAL_RECORD_DISPLAY, true)
	}

	onPrintPatientLabel(event) {
		let patient = this.props.ipd.selectedPatient.patient
		let hn = this.props.ipd.selectedAdmission.admission ? this.props.ipd.selectedAdmission.admission.clinicalRecord.hn : ""
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.label, "_blank")
		myWindow.data = PrintableDataFactory.generatePatientLabelData(patient, hn, "")
	}

	onPrintFirstAssessment() {
		let patient = {
			...this.props.ipd.selectedPatient.patient,
			clinicalRecord: this.props.ipd.selectedPatient.patient.admission.clinicalRecord,
		}
		let myWindow = window.open(ResourceAssistance.Path.Report.Hospital.firstAssessment, "_blank")
		myWindow.data = PrintableDataFactory.generateFirstAssessmentReportData(patient)
	}

	onPrintCovidLab() {
		this.downloadFile(
			this.props.ipd.selectedAdmission.admission.clinicalRecord.covidLabResultFilePath,
			this.props.ipd.selectedAdmission.admission.clinicalRecord.covidLabResultFileName,
			(res) => {
				let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
				myWindow.data = PrintableDataFactory.generatePdfFileData(res.data)
			}
		)
	}

	onPrintClaim() {
		this.downloadFile(
			this.props.ipd.selectedAdmission.admission.clinicalRecord.satCodeFilePath,
			this.props.ipd.selectedAdmission.admission.clinicalRecord.satCodeFileName,
			(res) => {
				let myWindow = window.open(ResourceAssistance.Path.Report.File.claim, "_blank")
				myWindow.data = PrintableDataFactory.generateClaimFileData(
					this.props.ipd.selectedPatient.patient,
					this.props.ipd.selectedAdmission.admission.clinicalRecord,
					res.data
				)
			}
		)
	}

	onPrintExpenseEstimation() {}

	onPrintNovel() {
		this.downloadFile(
			this.props.ipd.selectedAdmission.admission.clinicalRecord.novelFilePath,
			this.props.ipd.selectedAdmission.admission.clinicalRecord.novelFileName,
			(res) => {
				let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
				myWindow.data = PrintableDataFactory.generatePdfFileData(res.data)
			}
		)
	}

	onPrintHealthCare() {
		this.downloadFile(
			this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareFilePath,
			this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareFileName,
			(res) => {
				let myWindow = window.open(ResourceAssistance.Path.Report.File.pdf, "_blank")
				myWindow.data = PrintableDataFactory.generatePdfFileData(res.data)
			}
		)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<Dropdown as={ButtonGroup} variant={ResourceAssistance.Button.variant.info}>
						<DropdownToggle
							variant={ResourceAssistance.Button.variant.info}
							size={ResourceAssistance.FormControl.size.sm}
							disabled={this.props.ipd.selectedAdmission.index === -1}
						>
							<ImPrinter size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.print)}
						</DropdownToggle>
						<DropdownMenu variant={"info"}>
							<DropdownItem
								disabled={
									this.props.ipd.selectedAdmission.index === -1 || _.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.healthcareFileName)
								}
								onClick={this.onPrintHealthCare}
							>
								{translate(ResourceAssistance.Hospitel.healthCare)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={
									this.props.ipd.selectedPatient.index === -1 ||
									!this.props.ipd.selectedPatient.patient.admission ||
									!this.props.ipd.selectedPatient.patient.admission.clinicalRecord
								}
								onClick={this.onPrintFirstAssessment}
							>
								{translate(ResourceAssistance.Hospitel.firstAssessment)}
							</DropdownItem>
							<DropdownItem
								disabled={
									this.props.ipd.selectedAdmission.index === -1 ||
									_.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.expenseEstimationFileName)
								}
								onClick={this.onPrintExpenseEstimation}
							>
								{translate(ResourceAssistance.Message.expenseEstimation)}
							</DropdownItem>
							<Dropdown.Divider />
							<DropdownItem
								disabled={
									this.props.ipd.selectedAdmission.index === -1 || _.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.covidLabResultFileName)
								}
								onClick={this.onPrintCovidLab}
							>
								{translate(ResourceAssistance.Hospitel.covid19Lab)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.ipd.selectedAdmission.index === -1 || _.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.satCodeFileName)}
								onClick={this.onPrintClaim}
							>
								{translate(ResourceAssistance.Message.claimFile)}
							</DropdownItem>
							<DropdownItem
								disabled={this.props.ipd.selectedAdmission.index === -1 || _.isEmpty(this.props.ipd.selectedAdmission.admission.clinicalRecord.novelFileName)}
								onClick={this.onPrintNovel}
							>
								{translate(ResourceAssistance.Message.novelDiseaseInvestigationSheet)}
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</Col>
				<Col md="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.props.ipd.selectedAdmission.index === -1}
						onClick={this.onPrintPatientLabel}
					>
						<BiBarcodeReader size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Hospitel.printLabel)}
					</Button>
				</Col>
				{Utils.hasPrivilege(ResourceAssistance.Privilege.Hospital.Registration.CRUD, this.props.login.user.roles) && (
					<Fragment>
						<Col>
							<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
						</Col>
						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={this.props.ipd.selectedAdmission.index === -1 || this.props.ipd.selectedAdmission.admission.dischargedDateTime}
								onClick={this.onModifyPatient}
							>
								<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.edit)}
							</Button>
						</Col>
						<Col md="auto">
							<Button
								size={ResourceAssistance.Button.size.sm}
								variant={ResourceAssistance.Button.variant.primary}
								disabled={!this.props.ipd.selectedPatient.patient || this.isRegisterOpdDisabled()}
								onClick={this.onRegisterOPD}
							>
								<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
								{translate(ResourceAssistance.Message.registerOPD)}
							</Button>
						</Col>
					</Fragment>
				)}
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	ipd: state.hospitel.ipd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setLoadingAction,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(IPDLeftClinicalRecordAction))
