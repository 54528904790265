import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import _ from "lodash"
import React, { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportOPDNurseNoteDocument extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				title: "",
				nurseNotes: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						{/* Info */}
						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>PID: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.pid}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>ID/{this.state.data.identificationIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.identification}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Patient Name/{this.state.data.patientNameIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.patientName}</Text>
								</View>
							</View>
							<View style={[ResourceAssistance.Report.styles.row]}>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>Age/{this.state.data.ageIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.age}</Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
									<Text>DOB/{this.state.data.dobIntl}: </Text>
								</View>
								<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
									<Text>{this.state.data.dob}</Text>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Note Header */}
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.dateIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.timeIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.focus]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.focusIntl}</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.progressNote]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.progressNoteIntl}</Text>
							</View>
						</View>
						<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.date]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Date</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.focus]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Focus</Text>
							</View>
							<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.progressNote]}>
								<Text style={ResourceAssistance.Report.styles.textBold}>Progress Note</Text>
							</View>
						</View>
						<View style={ResourceAssistance.Report.styles.horizontalLine} />

						{this.state.data.nurseNotes.map((nurseNote) => {
							return (
								<Fragment>
									<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]} wrap={false}>
										<View style={[ResourceAssistance.Report.styles.col, styles.date]}>
											<Text>{Utils.formatDate(nurseNote.dateTime)}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.time]}>
											<Text>{Utils.formatTime(nurseNote.dateTime)}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.focus]}>
											<Text>{nurseNote.focus}</Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.col, styles.progressNote]}>
											{!_.isEmpty(nurseNote.assessment) && (
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View
														style={[
															ResourceAssistance.Report.styles.colAuto,
															{ justifyContent: "flex-start", marginRight: 5 },
														]}
													>
														<Text>{"A: "}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}>
														<Text>{nurseNote.assessment}</Text>
													</View>
												</View>
											)}
											{!_.isEmpty(nurseNote.intervention) && (
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View
														style={[
															ResourceAssistance.Report.styles.colAuto,
															{ justifyContent: "flex-start", marginRight: 5 },
														]}
													>
														<Text>{"I: "}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}>
														<Text>{nurseNote.intervention}</Text>
													</View>
												</View>
											)}
											{!_.isEmpty(nurseNote.evaluation) && (
												<View style={[ResourceAssistance.Report.styles.row]}>
													<View
														style={[
															ResourceAssistance.Report.styles.colAuto,
															{ justifyContent: "flex-start", marginRight: 5 },
														]}
													>
														<Text>{"E: "}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.col]}>
														<Text>{nurseNote.evaluation}</Text>
													</View>
												</View>
											)}

											<View style={[ResourceAssistance.Report.styles.row, { justifyContent: "flex-end" }]}>
												<View style={[ResourceAssistance.Report.styles.colAuto]}>
													<Text>{nurseNote.lastModifiedBy.displayName}</Text>
													<View style={styles.line}></View>
													<Text style={{ alignSelf: "center" }}>{Utils.formatDateTime(nurseNote.dateTime)}</Text>
												</View>
											</View>
										</View>
									</View>
									<View style={ResourceAssistance.Report.styles.horizontalLine} />
								</Fragment>
							)
						})}
						<View style={ResourceAssistance.Report.styles.separatorFlexGrow} />
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	itemRow: {
		marginBottom: 10,
	},
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	date: {
		minWidth: "10%",
		maxWidth: "10%",
	},
	time: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	focus: {
		minWidth: "30%",
		maxWidth: "30%",
	},
	progressNote: {
		minWidth: "48%",
		maxWidth: "48%",
	},
})

export default ReportOPDNurseNoteDocument
