import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import { setOPDModalDoctorServicePendingServices, setSelected } from "~/redux/action"
import { SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE } from "~/redux/type"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"

class OPDModalDoctorServicePendingService extends React.Component {
	constructor(props) {
		super(props)

		this.onSelectService = this.onSelectService.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.opd.modalDoctorService.pendingServiceTable.original, this.props.opd.modalDoctorService.pendingServiceTable.original)) {
			this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE, -1, null)
		}
	}

	componentWillUnmount() {
		this.props.setOPDModalDoctorServicePendingServices([])
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE, -1, null)
	}

	onSelectService(item, index) {
		this.props.setSelected(SET_OPD_MODAL_DOCTOR_SERVICE_SELECTED_PENDING_SERVICE, index, this.props.opd.modalDoctorService.pendingServiceTable.filtered[index])
	}

	render() {
		return (
			<Row className={ResourceAssistance.CSS.fullSize}>
				<Col>
					<ScrollableTable
						striped
						hover
						enableHighlight
						onClick={this.onSelectService}
						data={this.props.opd.modalDoctorService.pendingServiceTable}
						isClearHighlight={this.props.opd.modalDoctorService.selectedPendingService.index === -1}
					/>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setSelected,
			setOPDModalDoctorServicePendingServices,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OPDModalDoctorServicePendingService)
