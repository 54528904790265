import { SET_TIMELINE_PATIENTS } from "~/redux/type"
import { Utils } from "~/utils/Utils"

export const setTimelinePatients = (patients) => {
	return (dispatch, getState) => {
		let filtered = patients.sort((a, b) =>
			Utils.sort(Utils.BigNumber(a.id).toNumber(), Utils.BigNumber(b.id).toNumber())
		)

		let body = filtered.map((patient) => {
			return [
				patient.id,
				patient.identificationNumber,
				patient.firstName,
				patient.lastName,
				Utils.formatDate(patient.dobtime),
			]
		})

		dispatch({
			type: SET_TIMELINE_PATIENTS,
			payload: {
				original: patients,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
