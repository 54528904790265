export const SET_TF_DISPLAY_ALL_IND = "SET_TF_DISPLAY_ALL_IND"
export const SET_TF_MODAL_REQUEST_FROM_LOCATIONS = "SET_TF_MODAL_REQUEST_FROM_LOCATIONS"
export const SET_TF_MODAL_REQUEST_ITEMS = "SET_TF_MODAL_REQUEST_ITEMS"
export const SET_TF_MODAL_REQUEST_PENDING_ITEMS = "SET_TF_MODAL_REQUEST_PENDING_ITEMS"
export const SET_TF_MODAL_REQUEST_PENDING_SELECTED_ITEM = "SET_TF_MODAL_REQUEST_PENDING_SELECTED_ITEM"
export const SET_TF_MODAL_REQUEST_SEARCH = "SET_TF_MODAL_REQUEST_SEARCH"
export const SET_TF_MODAL_REQUEST_SELECTED_DUE_DATE = "SET_TF_MODAL_REQUEST_SELECTED_DUE_DATE"
export const SET_TF_MODAL_REQUEST_SELECTED_ITEM = "SET_TF_MODAL_REQUEST_SELECTED_ITEM"
export const SET_TF_MODAL_REQUEST_SELECTED_ITEM_AMOUNT = "SET_TF_MODAL_REQUEST_SELECTED_ITEM_AMOUNT"
export const SET_TF_MODAL_REQUEST_SELECTED_TO_BRANCH = "SET_TF_MODAL_REQUEST_SELECTED_TO_BRANCH"
export const SET_TF_MODAL_REQUEST_SELECTED_TO_LOCATION = "SET_TF_MODAL_REQUEST_SELECTED_TO_LOCATION"
export const SET_TF_MODAL_REQUEST_SELECTED_TO_ORG = "SET_TF_MODAL_REQUEST_SELECTED_TO_ORG"
export const SET_TF_MODAL_REQUEST_SELECTED_TYPE = "SET_TF_MODAL_REQUEST_SELECTED_TYPE"
export const SET_TF_MODAL_REQUEST_TO_BRANCHES = "SET_TF_MODAL_REQUEST_TO_BRANCHES"
export const SET_TF_MODAL_REQUEST_TO_LOCATIONS = "SET_TF_MODAL_REQUEST_TO_LOCATIONS"
export const SET_TF_MODAL_REQUEST_TO_ORGS = "SET_TF_MODAL_REQUEST_TO_ORGS"
export const SET_TF_REQUESTS = "SET_TF_REQUESTS"
export const SET_TF_REQUEST_LOADING = "SET_TF_REQUEST_LOADING"
export const SET_TF_SEARCH_END_DATE = "SET_TF_SEARCH_END_DATE"
export const SET_TF_SEARCH_LOCATION = "SET_TF_SEARCH_LOCATION"
export const SET_TF_SEARCH_NUMBER = "SET_TF_SEARCH_NUMBER"
export const SET_TF_SEARCH_START_DATE = "SET_TF_SEARCH_START_DATE"
export const SET_TF_TYPES = "SET_TF_TYPES"
export const SET_TF_SELECTED_REQUEST = "SET_TF_SELECTED_REQUEST"
export const SET_TF_REQUEST_ITEMS = "SET_TF_REQUEST_ITEMS"
export const SET_TF_SELECTED_REQUEST_ITEM = "SET_TF_SELECTED_REQUEST_ITEM"
export const SET_TF_MODAL_TRANSFER_ITEMS = "SET_TF_MODAL_TRANSFER_ITEMS"
export const SET_TF_MODAL_TRANSFER_SELECTED_ITEM = "SET_TF_MODAL_TRANSFER_SELECTED_ITEM"
export const SET_TF_MODAL_TRANSFER_ORGS = "SET_TF_MODAL_TRANSFER_ORGS"
export const SET_TF_MODAL_TRANSFER_BRANCHES = "SET_TF_MODAL_TRANSFER_BRANCHES"
export const SET_TF_MODAL_TRANSFER_SELECTED_ORG = "SET_TF_MODAL_TRANSFER_SELECTED_ORG"
export const SET_TF_MODAL_TRANSFER_SELECTED_BRANCH = "SET_TF_MODAL_TRANSFER_SELECTED_BRANCH"
export const SET_TF_MODAL_TRANSFER_LOTS = "SET_TF_MODAL_TRANSFER_LOTS"
export const SET_TF_MODAL_TRANSFER_SELECTED_LOT = "SET_TF_MODAL_TRANSFER_SELECTED_LOT"
export const SET_TF_MODAL_TRANSFER_CURRENT = "SET_TF_MODAL_TRANSFER_CURRENT"
export const SET_TF_MODAL_TRANSFER_EXPIRE = "SET_TF_MODAL_TRANSFER_EXPIRE"
export const SET_TF_MODAL_TRANSFER_TRANSFER = "SET_TF_MODAL_TRANSFER_TRANSFER"
export const SET_TF_MODAL_TRANSFER_PENDING_ITEMS = "SET_TF_MODAL_TRANSFER_PENDING_ITEMS"
export const SET_TF_TRANSFERS = "SET_TF_TRANSFERS"
export const SET_TF_SELECTED_TRANSFER = "SET_TF_SELECTED_TRANSFER"
export const SET_TF_TRANSFER_ITEMS = "SET_TF_TRANSFER_ITEMS"
export const SET_TF_SELECTED_TRANSFER_ITEM = "SET_TF_SELECTED_TRANSFER_ITEM"
export const SET_TF_MODAL_CANCEL_TRI_REASON = "SET_TF_MODAL_CANCEL_TRI_REASON"
export const SET_TF_MODAL_TRANSFER_SELECTED_PENDING_ITEM = "SET_TF_MODAL_TRANSFER_SELECTED_PENDING_ITEM"
