import { Descriptions } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { Utils } from "~/utils/Utils"

class OutpatientModalNurseOrderInfoCriteria extends React.Component {
	render() {
		return (
			<Row>
				<Col>
					<Descriptions
						style={{ flex: "unset", display: "unset" }}
						size={"small"}
						contentStyle={{ fontStyle: "italic", whiteSpace: "pre-wrap" }}
						column={1}
					>
						<Descriptions.Item span={1} contentStyle={{ justifyContent: "center" }}>
							{Utils.formatDateTime(this.props.opd.selectedNurseNote.nurseNote.dateTime)}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Message.focus)} span={1}>
							{this.props.opd.selectedNurseNote.nurseNote.focus}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Hospitel.assessment)} span={1}>
							{_.isEmpty(this.props.opd.selectedNurseNote.nurseNote.assessmentNote)
								? this.props.opd.selectedNurseNote.nurseNote.assessment
								: this.props.opd.selectedNurseNote.nurseNote.assessmentNote}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Hospitel.intervention)} span={1}>
							{this.props.opd.selectedNurseNote.nurseNote.intervention}
						</Descriptions.Item>
						<Descriptions.Item label={translate(ResourceAssistance.Hospitel.evaluation)} span={1}>
							{this.props.opd.selectedNurseNote.nurseNote.evaluation}
						</Descriptions.Item>
						<Descriptions.Item span={1} contentStyle={{ justifyContent: "flex-end" }}>
							{this.props.opd.selectedNurseNote.nurseNote.lastModifiedBy.displayName}
						</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	opd: state.hospital.opd,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OutpatientModalNurseOrderInfoCriteria)
