import React from "react"
import { Container } from "react-bootstrap"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance } from "~/i18n"
import HospitelModalRegisterIPD from "./HospitelModalRegisterIPD"
import HospitelModalRegisterPatient from "./HospitelModalRegisterPatient"

class HospitelModalRegisterDetails extends React.Component {
	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<HospitelModalRegisterPatient />
				{this.props.hospitel.modalRegister.patient && <HospitelModalRegisterIPD />}
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	hospitel: state.hospitel.hospitel,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators({}, dispatch),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(HospitelModalRegisterDetails))
