import { Radio } from "antd"
import _ from "lodash"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { setValue } from "~/redux/action"
import { SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER, SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB } from "~/redux/type"
import GInput from "~/view/component/input/GInput"

export const RGender = {
	Male: "M",
	Female: "F",
}

class OperatingRoomModalIntraAneBabyDeliveryDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onTimeOfBirth = this.onTimeOfBirth.bind(this)
		this.onGender = this.onGender.bind(this)
	}

	componentDidMount() {
		if (this.props.operatingRoom.selectedRequest.request) {
			let op = _.isEmpty(this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives)
				? undefined
				: this.props.operatingRoom.selectedRequest.request.anesthesiaIntraOperatives[0]
			if (op) {
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB, op.timeOfBirth)
				this.props.setValue(SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER, op.gender)
			}
		}
	}

	componentWillUnmount() {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB, "")
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER, "")
	}

	render() {
		return (
			<Container fluid className={ResourceAssistance.CSS.fullFlex}>
				<Row>
					<Col md="auto">
						<GInput
							size={"small"}
							styles={{ input: { textAlign: "center" } }}
							placeholder={translate(ResourceAssistance.Message.timeOfBirth)}
							value={this.props.operatingRoom.modalIntraAneBabyDelivery.timeOfBirth}
							onChange={this.onTimeOfBirth}
						/>
					</Col>
					<Col md="auto">
						<label>{translate(ResourceAssistance.Message.gender)}:</label>
					</Col>
					<Col md="auto">
						<Radio.Group value={this.props.operatingRoom.modalIntraAneBabyDelivery.gender} onChange={this.onGender}>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RGender.Male}>
								{translate(ResourceAssistance.Message.male)}
							</Radio>
							<Radio style={{ flexDirection: "row", alignItems: "center" }} value={RGender.Female}>
								{translate(ResourceAssistance.Message.female)}
							</Radio>
						</Radio.Group>
					</Col>
				</Row>
			</Container>
		)
	}

	onTimeOfBirth(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_TOB, e.target.value)
	}
	onGender(e) {
		this.props.setValue(SET_OR_MODAL_INTRA_ANE_BABY_DELIVERY_GENDER, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	operatingRoom: state.hospital.operatingRoom,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatingRoomModalIntraAneBabyDeliveryDetails)
