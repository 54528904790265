import moment from "moment"
import { ResourceAssistance } from "~/i18n"
import {
	SET_PI_CHART_OF_ACCOUNTS,
	SET_PI_CHART_OF_ACCOUNTS_LOADING,
	SET_PI_END_DATE_TIME,
	SET_PI_FILTERS,
	SET_PI_FILTER_PURCHASE_INVOICE_ID,
	SET_PI_INVOICES,
	SET_PI_LOCATION,
	SET_PI_MODAL_LOCATION_ORGS,
	SET_PI_MODAL_LOCATION_SELECTED_ORG,
	SET_PI_MODAL_NEW_DUE_DATE_TIME,
	SET_PI_MODAL_NEW_INVOICE,
	SET_PI_MODAL_NEW_PURCHASES,
	SET_PI_MODAL_NEW_REMARK,
	SET_PI_MODAL_NEW_SELECTED_PAYMENT,
	SET_PI_MODAL_NEW_SELECTED_PURCHASE,
	SET_PI_MODAL_NEW_SELECTED_SUPPLIER,
	SET_PI_MODAL_NEW_WHT_ISSUED_DATE_TIME,
	SET_PI_MODAL_PURCHASE_AMOUNT,
	SET_PI_MODAL_PURCHASE_DESCRIPTION,
	SET_PI_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER,
	SET_PI_MODAL_PURCHASE_SELECTED_GL,
	SET_PI_MODAL_PURCHASE_SELECTED_TAX,
	SET_PI_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE,
	SET_PI_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX,
	SET_PI_MODAL_SEARCH_PURCHASE_INVOICE_ID,
	SET_PI_PAYMENT_TYPES,
	SET_PI_PAYMENT_TYPES_LOADING,
	SET_PI_PURCHASES,
	SET_PI_SELECTED_PI,
	SET_PI_SELECTED_PURCHASE,
	SET_PI_START_DATE_TIME,
	SET_PI_SUPPLIERS,
	SET_PI_SUPPLIERS_LOADING,
	SET_PI_TAXES,
	SET_PI_TAXES_LOADING,
	SET_PI_WITHHOLDING_TAXES,
	SET_PI_WITHHOLDING_TAXES_LOADING,
	SET_PI_MODAL_PURCHASE_WHT_PERCENT,
	SET_PI_GL_DATE_TIME,
	SET_PI_MODAL_NEW_INVOICE_DATE_TIME,
	SET_PI_MODAL_LINK_LINKS,
	SET_PI_MODAL_LINK_SELECTED_LINK,
	SET_PI_MODAL_LOCATION_ORGS_LOADING,
} from "~/redux/type"

const init = {
	location: null,
	startDateTime: moment().startOf("day").milliseconds(0).valueOf(),
	endDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	generalLedgerDateTime: moment().endOf("day").milliseconds(0).valueOf(),
	filterPurchaseInvoiceId: "",
	isChartOfAccountsLoading: false,
	isSuppliersLoading: false,
	isPaymentTypesLoading: false,
	isWithholdingTaxesLoading: false,
	isTaxesLoading: false,
	filters: [],
	chartOfAccounts: [],
	suppliers: [],
	paymentTypes: [],
	withholdingTaxes: [],
	taxes: [],
	selectedPI: {
		index: -1,
		PI: null,
	},
	selectedPurchase: {
		index: -1,
		purchase: null,
	},
	purchaseInvoiceTable: {
		original: [],
		filtered: [],
		header: [ResourceAssistance.Message.date, ResourceAssistance.Message.id],
		body: [],
		rowColor: [],
		colStyle: [],
		isLoading: false,
	},
	purchaseTable: {
		original: [],
		filtered: [],
		header: [
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.description,
			ResourceAssistance.Message.withholdingTax,
			ResourceAssistance.Message.taxRateWithSymbol,
			ResourceAssistance.Message.subLevel,
			ResourceAssistance.Message.other,
			ResourceAssistance.Message.code,
			ResourceAssistance.Message.name,
			ResourceAssistance.Message.taxRateWithSymbol,
			ResourceAssistance.Message.debit,
			ResourceAssistance.Message.credit,
			"",
		],
		body: [],
		rowColor: [],
		colStyle: [],
	},
	modalLocation: {
		orgs: [],
		isOrgsLoading: false,
		selectedOrg: {
			index: -1,
			org: null,
		},
	},
	modalNew: {
		dueDateTime: "",
		whtIssuedDateTime: "",
		invoiceDateTime: "",
		invoice: "",
		remark: "",
		selectedSupplier: {
			index: -1,
			supplier: null,
		},
		selectedPayment: {
			index: -1,
			payment: null,
		},
		selectedPurchase: {
			index: -1,
			purchase: null,
		},
		purchaseTable: {
			original: [],
			filtered: [],
			header: [
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.description,
				ResourceAssistance.Message.withholdingTax,
				ResourceAssistance.Message.taxRateWithSymbol,
				ResourceAssistance.Message.subLevel,
				ResourceAssistance.Message.other,
				ResourceAssistance.Message.code,
				ResourceAssistance.Message.name,
				ResourceAssistance.Message.taxRateWithSymbol,
				ResourceAssistance.Message.totalPriceDiscountedTaxed,
				"",
			],
			body: [],
			rowColor: [],
			colStyle: [],
		},
	},
	modalPurchase: {
		description: "",
		percent: "",
		whtSubLevelOther: "",
		amount: "",
		selectedGL: {
			index: -1,
			GL: null,
		},
		selectedTax: {
			index: -1,
			tax: null,
		},
		selectedWithholdingTax: {
			index: -1,
			withholdingTax: null,
		},
		selectedWithholdingTaxSubLevel: {
			index: -1,
			subLevel: null,
		},
	},
	modalSearch: {
		searchPurchaseInvoiceId: "",
	},
	modalLink: {
		links: [],
		selectedLink: {
			index: -1,
			link: null,
		},
	},
}

const purchaseInvoiceReducer = (state = init, action) => {
	switch (action.type) {
		case SET_PI_LOCATION:
			return Object.assign({}, state, {
				location: action.payload.value,
			})
		case SET_PI_START_DATE_TIME:
			return Object.assign({}, state, {
				startDateTime: action.payload.value,
			})
		case SET_PI_END_DATE_TIME:
			return Object.assign({}, state, {
				endDateTime: action.payload.value,
			})
		case SET_PI_GL_DATE_TIME:
			return Object.assign({}, state, {
				generalLedgerDateTime: action.payload.value,
			})
		case SET_PI_FILTERS:
			return Object.assign({}, state, {
				filters: action.payload.value,
			})
		case SET_PI_FILTER_PURCHASE_INVOICE_ID:
			return Object.assign({}, state, {
				filterPurchaseInvoiceId: action.payload.value,
			})
		case SET_PI_SUPPLIERS:
			return Object.assign({}, state, {
				suppliers: action.payload.value,
			})
		case SET_PI_SUPPLIERS_LOADING:
			return Object.assign({}, state, {
				isSuppliersLoading: action.payload.isLoading,
			})
		case SET_PI_PAYMENT_TYPES:
			return Object.assign({}, state, {
				paymentTypes: action.payload.value,
			})
		case SET_PI_PAYMENT_TYPES_LOADING:
			return Object.assign({}, state, {
				isPaymentTypesLoading: action.payload.isLoading,
			})
		case SET_PI_WITHHOLDING_TAXES:
			return Object.assign({}, state, {
				withholdingTaxes: action.payload.value,
			})
		case SET_PI_WITHHOLDING_TAXES_LOADING:
			return Object.assign({}, state, {
				isWithholdingTaxesLoading: action.payload.isLoading,
			})
		case SET_PI_TAXES:
			return Object.assign({}, state, {
				taxes: action.payload.value,
			})
		case SET_PI_TAXES_LOADING:
			return Object.assign({}, state, {
				isTaxesLoading: action.payload.isLoading,
			})
		case SET_PI_CHART_OF_ACCOUNTS:
			return Object.assign({}, state, {
				chartOfAccounts: action.payload.value,
			})
		case SET_PI_CHART_OF_ACCOUNTS_LOADING:
			return Object.assign({}, state, {
				isChartOfAccountsLoading: action.payload.isLoading,
			})
		case SET_PI_INVOICES:
			return Object.assign({}, state, {
				purchaseInvoiceTable: {
					...state.purchaseInvoiceTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
				},
			})
		case SET_PI_SELECTED_PI:
			return Object.assign({}, state, {
				selectedPI: {
					index: action.payload.index,
					PI: action.payload.selected,
				},
			})
		case SET_PI_PURCHASES:
			return Object.assign({}, state, {
				purchaseTable: {
					...state.purchaseTable,
					original: action.payload.original,
					filtered: action.payload.filtered,
					body: action.payload.body,
					rowColor: action.payload.rowColor,
					colStyle: action.payload.colStyle,
				},
			})
		case SET_PI_SELECTED_PURCHASE:
			return Object.assign({}, state, {
				selectedPurchase: {
					index: action.payload.index,
					purchase: action.payload.selected,
				},
			})
		case SET_PI_MODAL_LOCATION_ORGS:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					orgs: action.payload.value,
				},
			})
		case SET_PI_MODAL_LOCATION_ORGS_LOADING:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					isOrgsLoading: action.payload.isLoading,
				},
			})
		case SET_PI_MODAL_LOCATION_SELECTED_ORG:
			return Object.assign({}, state, {
				modalLocation: {
					...state.modalLocation,
					selectedOrg: {
						index: action.payload.index,
						org: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_NEW_SELECTED_SUPPLIER:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedSupplier: {
						index: action.payload.index,
						supplier: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_NEW_SELECTED_PAYMENT:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPayment: {
						index: action.payload.index,
						payment: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_NEW_DUE_DATE_TIME:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					dueDateTime: action.payload.value,
				},
			})
		case SET_PI_MODAL_NEW_WHT_ISSUED_DATE_TIME:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					whtIssuedDateTime: action.payload.value,
				},
			})
		case SET_PI_MODAL_NEW_INVOICE_DATE_TIME:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					invoiceDateTime: action.payload.value,
				},
			})
		case SET_PI_MODAL_NEW_INVOICE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					invoice: action.payload.value,
				},
			})
		case SET_PI_MODAL_NEW_REMARK:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					remark: action.payload.value,
				},
			})
		case SET_PI_MODAL_NEW_PURCHASES:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					purchaseTable: {
						...state.modalNew.purchaseTable,
						original: action.payload.original,
						filtered: action.payload.filtered,
						body: action.payload.body,
						rowColor: action.payload.rowColor,
						colStyle: action.payload.colStyle,
					},
				},
			})
		case SET_PI_MODAL_NEW_SELECTED_PURCHASE:
			return Object.assign({}, state, {
				modalNew: {
					...state.modalNew,
					selectedPurchase: {
						index: action.payload.index,
						purchase: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_PURCHASE_SELECTED_GL:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					selectedGL: {
						index: action.payload.index,
						GL: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_PURCHASE_SELECTED_WITHHOLDING_TAX:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					selectedWithholdingTax: {
						index: action.payload.index,
						withholdingTax: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_PURCHASE_SELECTED_WHT_SUB_TYPE:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					selectedWithholdingTaxSubLevel: {
						index: action.payload.index,
						subLevel: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_PURCHASE_SELECTED_TAX:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					selectedTax: {
						index: action.payload.index,
						tax: action.payload.selected,
					},
				},
			})
		case SET_PI_MODAL_PURCHASE_DESCRIPTION:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					description: action.payload.value,
				},
			})
		case SET_PI_MODAL_PURCHASE_AMOUNT:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					amount: action.payload.value,
				},
			})
		case SET_PI_MODAL_PURCHASE_WHT_SUB_LEVEL_OTHER:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					whtSubLevelOther: action.payload.value,
				},
			})
		case SET_PI_MODAL_PURCHASE_WHT_PERCENT:
			return Object.assign({}, state, {
				modalPurchase: {
					...state.modalPurchase,
					percent: action.payload.value,
				},
			})
		case SET_PI_MODAL_SEARCH_PURCHASE_INVOICE_ID:
			return Object.assign({}, state, {
				modalSearch: {
					...state.modalSearch,
					searchPurchaseInvoiceId: action.payload.value,
				},
			})
		case SET_PI_MODAL_LINK_LINKS:
			return Object.assign({}, state, {
				modalLink: {
					...state.modalLink,
					links: action.payload.value,
				},
			})
		case SET_PI_MODAL_LINK_SELECTED_LINK:
			return Object.assign({}, state, {
				modalLink: {
					...state.modalLink,
					selectedLink: {
						index: action.payload.index,
						link: action.payload.selected,
					},
				},
			})
		default:
			return state
	}
}
export default purchaseInvoiceReducer
