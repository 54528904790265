import { Button, Checkbox, Divider, Select, TreeSelect } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { FiRefreshCw } from "react-icons/fi"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setValue } from "~/redux/action"
import {
	SET_FRONT_INVENTORY_DISPLAY_EXPIRED,
	SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK,
	SET_FRONT_INVENTORY_EXPIRE_DATETIME,
	SET_FRONT_INVENTORY_SEARCH_CODE,
	SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME,
	SET_FRONT_INVENTORY_SELECTED_DEPARTMENT,
	SET_FRONT_INVENTORY_SELECTED_TYPE,
} from "~/redux/type"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import SearchBox from "~/view/component/search_box/SearchBox"

class FrontInventoryFilter extends React.Component {
	constructor(props) {
		super(props)

		this.onLocation = this.onLocation.bind(this)
		this.onType = this.onType.bind(this)
		this.onCode = this.onCode.bind(this)
		this.onGenericName = this.onGenericName.bind(this)
		this.onExpiredDate = this.onExpiredDate.bind(this)
		this.onDisplayExpired = this.onDisplayExpired.bind(this)
		this.onDisplayMinStock = this.onDisplayMinStock.bind(this)
		this.onRefresh = this.onRefresh.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (!_.isEqual(prevProps.frontInventory.types, this.props.frontInventory.types)) {
			if (!_.isEmpty(this.props.frontInventory.types)) {
				this.props.setSelected(
					SET_FRONT_INVENTORY_SELECTED_TYPE,
					0,
					this.props.frontInventory.types.sort((a, b) => Utils.sort(a.displayName, b.displayName))[0]
				)
			}
		}
	}

	onLocation(value, node) {
		this.props.setSelected(
			SET_FRONT_INVENTORY_SELECTED_DEPARTMENT,
			node.extra.department.index,
			node.extra.department.department
		)
	}

	onType(value, option) {
		this.props.setSelected(SET_FRONT_INVENTORY_SELECTED_TYPE, Number(value), this.props.frontInventory.types[value])
	}

	onCode(event) {
		this.props.setValue(SET_FRONT_INVENTORY_SEARCH_CODE, event.target.value)
	}

	onGenericName(event) {
		this.props.setValue(SET_FRONT_INVENTORY_SEARCH_GENERIC_NAME, event.target.value)
	}

	onExpiredDate(value) {
		this.props.setValue(SET_FRONT_INVENTORY_EXPIRE_DATETIME, moment(value).endOf("day").milliseconds(0).valueOf())
	}

	onDisplayExpired(event) {
		this.props.setValue(SET_FRONT_INVENTORY_DISPLAY_EXPIRED, event.target.checked)
	}

	onDisplayMinStock(event) {
		this.props.setValue(SET_FRONT_INVENTORY_DISPLAY_MIN_STOCK, event.target.checked)
	}

	onRefresh(event) {
		this.props.getPharmacyInventories(this.props.frontInventory.selectedDepartment.department.id)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col md="auto">
					<Button
						type="primary"
						size="middle"
						shape="circle"
						icon={<FiRefreshCw size={ResourceAssistance.ReactIcon.size} />}
						disabled={!this.props.frontInventory.selectedDepartment.department}
						onClick={this.onRefresh}
					/>
				</Col>
				<Col md={2}>
					<TreeSelect
						loading={this.props.frontInventory.isOrgsLoading}
						treeLine={{ showLeafIcon: false }}
						style={{
							textAlign: "center",
						}}
						treeExpandAction="click"
						placeholder={translate(ResourceAssistance.Message.location)}
						treeData={Utils.getTreeNodesFromOrg(this.props.frontInventory.orgs, false)}
						onSelect={this.onLocation}
					/>
				</Col>
				<Col md={2}>
					<Select
						size={ResourceAssistance.Select.size.middle}
						style={{ textAlign: "center" }}
						optionFilterProp="children"
						filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						filterSort={(optionA, optionB) =>
							optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
						}
						loading={this.props.frontInventory.isTypesLoading}
						value={
							this.props.frontInventory.selectedDepartment.index === -1
								? undefined
								: this.props.frontInventory.selectedType.index
						}
						disabled={this.props.frontInventory.selectedDepartment.index === -1}
						onChange={this.onType}
					>
						{Utils.renderSelects(
							this.props.frontInventory.selectedDepartment.index === -1 ? [] : this.props.frontInventory.types,
							false
						)}
					</Select>
				</Col>
				<Col>
					<SearchBox
						num={2}
						disabled={this.props.frontInventory.selectedDepartment.index === -1}
						controlTypes={[ResourceAssistance.FormControl.type.text, ResourceAssistance.FormControl.type.text]}
						placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.genericName]}
						callbacks={[this.onCode, this.onGenericName]}
						values={[this.props.frontInventory.searchCode, this.props.frontInventory.searchGenericName]}
					/>
				</Col>
				<Col md="auto">
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<AntDatePicker
						allowClear={false}
						size={"middle"}
						format={"DD-MM-YYYY"}
						disabled={this.props.frontInventory.selectedDepartment.index === -1}
						defaultValues={[moment(this.props.frontInventory.lotExpiredDateTime)]}
						onChange={this.onExpiredDate}
					/>
				</Col>
				<Col md="auto">
					<Checkbox
						style={{ flexDirection: "row" }}
						disabled={this.props.frontInventory.selectedDepartment.index === -1}
						onChange={this.onDisplayExpired}
					>
						{translate(ResourceAssistance.Message.displayExpire)}
					</Checkbox>
				</Col>
				<Col md="auto">
					<Checkbox
						style={{ flexDirection: "row" }}
						disabled={this.props.frontInventory.selectedDepartment.index === -1}
						onChange={this.onDisplayMinStock}
					>
						{translate(ResourceAssistance.Message.displayMinStock)}
					</Checkbox>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	frontInventory: state.hospital.frontInventory,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FrontInventoryFilter))
