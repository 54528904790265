import { Select } from "antd"
import _ from "lodash"
import moment from "moment"
import React from "react"
import { Button, Col, Container, Form, Modal, ModalBody, ModalFooter, ModalTitle, Row } from "react-bootstrap"
import ModalHeader from "react-bootstrap/ModalHeader"
import { FcDownload, FcUpload } from "react-icons/fc"
import { GrRotateLeft } from "react-icons/gr"
import { VscSaveAs } from "react-icons/vsc"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import {
	onModalDisplayAction,
	setDateAction,
	setLoadingAction,
	setRcInvoiceNum,
	setRcModalReceiveWaitingReceives,
	setRcOrders,
	setRcPendingReceives,
	setRcSelectedPending,
	setRcSelectedSupplier,
	setRcSelectedWaiting,
	setRcTaxedTotalPrice,
	setSearchText,
	setValue,
} from "~/redux/action"
import { SET_PAGE_LOADING } from "~/redux/type"
import { SET_RC_RECEIVE_DETAIL_DISPLAY, SET_RC_RECEIVE_DISPLAY } from "~/redux/type/type/MODAL"
import { SET_RC_MODAL_RECEIVE_DISCOUNT_RECEIPT, SET_RC_RECEIVED_DATE, SET_RC_SEARCH_CODE, SET_RC_SEARCH_NAME } from "~/redux/type/type/RC"
import { Utils } from "~/utils/Utils"
import AntDatePicker from "~/view/component/date_picker/AntDatePicker"
import GInput from "~/view/component/input/GInput"
import ScrollableTable from "~/view/component/scroll_table/ScrollableTable"
import SearchBox from "~/view/component/search_box/SearchBox"

class RCModalReceive extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			componentName: "RCModalReceive",
		}

		this.discountedTaxedRef = React.createRef()

		this.onClose = this.onClose.bind(this)
		this.onDone = this.onDone.bind(this)
		this.onDownBtn = this.onDownBtn.bind(this)
		this.onUpBtn = this.onUpBtn.bind(this)
		this.onWaitingSelectRow = this.onWaitingSelectRow.bind(this)
		this.onPendingSelectRow = this.onPendingSelectRow.bind(this)
		this.onSupplierChange = this.onSupplierChange.bind(this)
		this.onCodeChange = this.onCodeChange.bind(this)
		this.onNameChange = this.onNameChange.bind(this)
		this.onReceiveDate = this.onReceiveDate.bind(this)
		this.onInvoiceChange = this.onInvoiceChange.bind(this)
		this.onDiscountReceipt = this.onDiscountReceipt.bind(this)
		this.onTaxedTotalPriceChange = this.onTaxedTotalPriceChange.bind(this)
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.modalReceive.searchCode !== this.props.modalReceive.searchCode ||
			prevProps.modalReceive.searchName !== this.props.modalReceive.searchName ||
			JSON.stringify(prevProps.selectedSupplier) !== JSON.stringify(this.props.selectedSupplier)
		) {
			if (this.props.selectedSupplier.supplier) {
				this.props.setRcModalReceiveWaitingReceives(this.props.selectedSupplier.supplier.items)
			}
		}

		if (JSON.stringify(prevProps.modalReceiveWaitingReceive.filtered) !== JSON.stringify(this.props.modalReceiveWaitingReceive.filtered)) {
			let index = this.props.selectedWaiting.inventory
				? this.props.modalReceiveWaitingReceive.filtered.findIndex((inventory) => inventory.id === this.props.selectedWaiting.inventory.id)
				: -1
			if (index !== -1) {
				this.props.setRcSelectedWaiting(index, this.props.modalReceiveWaitingReceive.filtered[index])
			} else {
				this.props.setRcSelectedWaiting(-1, null)
			}
		}

		if (
			!_.isEqual(prevProps.modalReceive.taxedTotalPrice, this.props.modalReceive.taxedTotalPrice) ||
			!_.isEqual(prevProps.modalReceive.discountReceipt, this.props.modalReceive.discountReceipt) ||
			JSON.stringify(prevProps.pendingReceive.filtered) !== JSON.stringify(this.props.pendingReceive.filtered)
		) {
			this.validateTotalPrice()
		}

		if (prevProps.isDisplay !== this.props.isDisplay) {
			if (this.props.isDisplay) {
				this.props.setRcSelectedSupplier(0, this.props.selectedRc.rc.suppliers[0])
			}
		}
	}

	reset() {
		//modalReceive
		this.props.setSearchText(SET_RC_SEARCH_CODE, "")
		this.props.setSearchText(SET_RC_SEARCH_NAME, "")
		this.props.setDateAction(SET_RC_RECEIVED_DATE, Utils.generateDate())
		this.props.setRcInvoiceNum("")
		this.props.setRcTaxedTotalPrice("")
		this.props.setValue(SET_RC_MODAL_RECEIVE_DISCOUNT_RECEIPT, "")

		this.props.setRcModalReceiveWaitingReceives([])
		this.props.setRcPendingReceives([])
		this.props.setRcSelectedSupplier(-1, null)
		this.props.setRcSelectedWaiting(-1, null)
		this.props.setRcSelectedPending(-1, null)

		this.props.onModalDisplayAction(SET_RC_RECEIVE_DISPLAY, false)
	}

	validateTotalPrice() {
		let htmlInput = this.discountedTaxedRef.current
		if (!htmlInput) {
			return
		}

		if (this.props.modalReceive.taxedTotalPrice && this.props.selectedSupplier.supplier && this.props.pendingReceive.filtered.length > 0) {
			let pendingTotalPrice = this.props.pendingReceive.filtered.reduce((object, item) => {
				return object.plus(item.taxedTotalPrice)
			}, Utils.BigNumber(0))

			let difference = pendingTotalPrice.minus(this.props.modalReceive.taxedTotalPrice).minus(this.props.modalReceive.discountReceipt).abs()
			if (difference.isLessThanOrEqualTo(1)) {
				htmlInput.setCustomValidity("")
			} else {
				htmlInput.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxedTotalPriceDoesNotMatch }))
			}
		} else {
			htmlInput.setCustomValidity(this.props.intl.formatMessage({ id: ResourceAssistance.Message.taxedTotalPriceDoesNotMatch }))
		}
	}

	onSupplierChange(value) {
		if (value === undefined) {
			this.props.setRcSelectedSupplier(-1, null)
			return
		}
		this.props.setRcSelectedSupplier(value, this.props.selectedRc.rc.suppliers[value])
	}

	onCodeChange(event) {
		this.props.setSearchText(SET_RC_SEARCH_CODE, event.target.value)
	}

	onNameChange(event) {
		this.props.setSearchText(SET_RC_SEARCH_NAME, event.target.value)
	}

	onReceiveDate(value) {
		this.props.setDateAction(SET_RC_RECEIVED_DATE, moment(value).startOf("day").milliseconds(0).valueOf())
	}

	onInvoiceChange(event) {
		this.props.setRcInvoiceNum(event.target.value)
	}

	onDiscountReceipt(e) {
		this.props.setValue(SET_RC_MODAL_RECEIVE_DISCOUNT_RECEIPT, e.target.value)
	}

	onTaxedTotalPriceChange(event) {
		this.props.setRcTaxedTotalPrice(event.target.value)
	}

	onClose() {
		this.reset()
	}

	onDone(event) {
		event.preventDefault()
		event.stopPropagation()

		let params = {
			method: "POST",
			url: ResourceAssistance.Url.rc.saveReceiveItems,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				userId: this.props.login.user.id,
				id: this.props.selectedRc.rc.id,
				supplierCode: this.props.selectedSupplier.supplier.code,
				supplierName: this.props.selectedSupplier.supplier.displayName,
				supplierPhone: this.props.selectedSupplier.supplier.phone,
				supplierAddress: this.props.selectedSupplier.supplier.address,
				supplierTaxId: this.props.selectedSupplier.supplier.taxId,
				receivedDate: this.props.modalReceive.receivedDate,
				payment: this.props.selectedSupplier.supplier.payment,
				terms: this.props.selectedSupplier.supplier.paymentTerms,
				invoice: this.props.modalReceive.invoiceNum.trim(),
				discount: this.props.selectedSupplier.supplier.discount,
				discountReceipt: this.props.modalReceive.discountReceipt,
				totalPrice: Utils.BigNumber(this.props.modalReceive.taxedTotalPrice).toNumber(),
				receiveItems: this.props.pendingReceive.filtered.map((receive) => {
					return {
						prItemId: receive.prItemId,
						lot: receive.lot,
						expireDate: receive.expireDate,
						amount: Utils.BigNumber(receive.amount).times(receive.minQtyPerOrder).toNumber(),
						bonus: receive.bonus,
						totalPrice: receive.totalPrice,
						discountedTotalPrice: receive.discountedTotalPrice,
						taxedTotalPrice: receive.taxedTotalPrice,
					}
				}),
			},
		}

		let callback = (res) => {
			let original = this.props.order.original
			res.data.receives.forEach((receive) => {
				let index = original.findIndex((original) => original.id === receive.id)
				original[index] = receive
			})
			this.props.setRcOrders(original)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
		this.reset()
	}

	onWaitingSelectRow(row, index) {
		this.props.setRcSelectedWaiting(index, this.props.modalReceiveWaitingReceive.filtered[index])
	}

	onPendingSelectRow(row, index) {
		this.props.setRcSelectedPending(index, this.props.pendingReceive.filtered[index])
	}

	onDownBtn(event) {
		if (this.props.selectedWaiting.index === -1) {
			return
		}
		this.props.onModalDisplayAction(SET_RC_RECEIVE_DETAIL_DISPLAY, true)
	}

	onUpBtn(event) {
		if (this.props.selectedPending.index === -1) {
			return
		}
		let pendingReceives = Array.from(this.props.pendingReceive.filtered)
		pendingReceives.splice(this.props.selectedPending.index, 1)
		this.props.setRcPendingReceives(pendingReceives)
	}

	render() {
		return (
			<Modal
				id={this.state.componentName}
				centered
				animation={false}
				dialogClassName={ResourceAssistance.CSS.Modal.fullSize}
				show={this.props.isDisplay}
				backdrop={ResourceAssistance.Modal.backdrop.static}
				keyboard={ResourceAssistance.Modal.keyboard.false}
				onHide={this.onClose}
			>
				<Form onSubmit={this.onDone}>
					<ModalHeader closeButton className={ResourceAssistance.CSS.backgourndLightSkyBlue}>
						<ModalTitle>{translate(ResourceAssistance.Message.newReceive)}</ModalTitle>
					</ModalHeader>

					<ModalBody>
						<Container fluid className={"full-flex"}>
							<Row className={"g-1"}>
								<Col md={4}>
									<Select
										showSearch
										style={{ textAlign: "center", margin: 0 }}
										optionFilterProp="children"
										filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
										disabled={this.props.pendingReceive.filtered.length > 0}
										value={this.props.selectedSupplier.supplier ? this.props.selectedSupplier.index : undefined}
										onChange={this.onSupplierChange}
									>
										{Utils.renderSelects(this.props.selectedRc.rc ? this.props.selectedRc.rc.suppliers : [], false)}
									</Select>
								</Col>
								<Col>
									<SearchBox
										size={ResourceAssistance.Button.size.sm}
										num={2}
										controlType={ResourceAssistance.FormControl.type.text}
										callbacks={[this.onCodeChange, this.onNameChange]}
										placeholders={[ResourceAssistance.Message.code, ResourceAssistance.Message.name]}
									/>
								</Col>
							</Row>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col id={ResourceAssistance.ID.RC.modalReceive.tableReceive}>
									<ScrollableTable
										enableHighlight
										striped
										bordered
										hover
										isClearHighlight={this.props.selectedWaiting.index === -1}
										data={this.props.modalReceiveWaitingReceive}
										onClick={this.onWaitingSelectRow}
									/>
								</Col>
							</Row>
							<Row>
								<Col className={this.props.selectedWaiting.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
									<FcDownload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onDownBtn} />
								</Col>
								<Col className={this.props.selectedPending.index === -1 ? ResourceAssistance.CSS.disabled : ""}>
									<FcUpload size={"3rem"} style={{ alignSelf: ResourceAssistance.CSS.center }} onClick={this.onUpBtn} />
								</Col>
							</Row>
							<Row className={ResourceAssistance.CSS.fullSize}>
								<Col id={ResourceAssistance.ID.RC.modalReceive.tableReceived}>
									<ScrollableTable
										enableHighlight
										striped
										bordered
										hover
										isClearHighlight={false}
										data={this.props.pendingReceive}
										onClick={this.onPendingSelectRow}
									/>
								</Col>
							</Row>
							<Row className={"g-2"}>
								<Col md="auto" style={{ paddingTop: "10px", marginTop: 0 }}>
									<AntDatePicker
										size={"large"}
										format={"DD-MM-YYYY"}
										allowClear={false}
										values={[moment(this.props.modalReceive.receivedDate)]}
										onChange={this.onReceiveDate}
									/>
								</Col>
								<Col>
									<GInput
										required
										style={{ textAlign: "center" }}
										placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.invoiceNum })}
										value={this.props.modalReceive.invoiceNum}
										onChange={this.onInvoiceChange}
									/>
								</Col>
								<Col md={1}>
									<GInput
										disabled
										variant={"borderless"}
										style={{ textAlign: "center" }}
										placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.payment })}
										value={this.props.selectedSupplier.supplier ? this.props.selectedSupplier.supplier.payment : ""}
									/>
								</Col>
								<Col md={1}>
									<GInput
										disabled
										variant={"borderless"}
										style={{ textAlign: "center" }}
										placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.supplierDiscount })}
										value={this.props.selectedSupplier.supplier ? `${this.props.selectedSupplier.supplier.discount} %` : "%"}
									/>
								</Col>
								<Col md={2}>
									<GInput
										required
										style={{ textAlign: "center" }}
										placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.discountReceipt })}
										pattern="^[0-9]*\.[0-9]{2}$"
										value={this.props.modalReceive.discountReceipt}
										onChange={this.onDiscountReceipt}
									/>
								</Col>
								<Col md={2}>
									<GInput
										required
										ref={this.discountedTaxedRef}
										style={{ textAlign: "center" }}
										placeholder={this.props.intl.formatMessage({ id: ResourceAssistance.Message.totalPriceDiscountedTaxed })}
										pattern="^[0-9]*\.[0-9]{2}$"
										value={this.props.modalReceive.taxedTotalPrice}
										onChange={this.onTaxedTotalPriceChange}
									/>
								</Col>
							</Row>
						</Container>
					</ModalBody>
					<ModalFooter>
						<Button variant={ResourceAssistance.Button.variant.secondary} onClick={this.onClose}>
							<GrRotateLeft size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.close)}
						</Button>
						<Button variant={ResourceAssistance.Button.variant.green} type={ResourceAssistance.Button.type.submit}>
							<VscSaveAs size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.save)}
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	isDisplay: state.modal.isRCReceiveDisplay,
	order: state.receive.order,
	modalReceive: state.receive.modalReceive,
	modalReceiveWaitingReceive: state.receive.modalReceiveWaitingReceive,
	pendingReceive: state.receive.pendingReceive,
	selectedWaiting: state.receive.selectedWaiting,
	selectedPending: state.receive.selectedPending,
	selectedRc: state.receive.selectedRc,
	selectedSupplier: state.receive.selectedSupplier,
	payment: state.receive.payment,
	selectedPayment: state.receive.selectedPayment,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDateAction,
			setLoadingAction,
			setRcInvoiceNum,
			setRcModalReceiveWaitingReceives,
			setRcOrders,
			setRcPendingReceives,
			setRcSelectedPending,
			setRcSelectedSupplier,
			setRcSelectedWaiting,
			setRcTaxedTotalPrice,
			setSearchText,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RCModalReceive))
