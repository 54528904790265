import { Select } from "antd"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { setLoadingAction, setSelected, setValue } from "~/redux/action"
import { SET_DEBT_MODAL_LOCATION_ORGS, SET_DEBT_MODAL_LOCATION_ORGS_LOADING, SET_DEBT_MODAL_LOCATION_SELECTED_ORG } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class DebtModalLocationDetails extends React.Component {
	constructor(props) {
		super(props)

		this.onOrg = this.onOrg.bind(this)
	}

	componentDidMount() {
		this.loadLocations()
	}

	componentWillUnmount() {
		this.props.setValue(SET_DEBT_MODAL_LOCATION_ORGS, [])
	}

	loadLocations() {
		let params = {
			method: "GET",
			url: ResourceAssistance.Url.debt.getOrgs,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
		}
		let callback = (res) => {
			this.props.setValue(SET_DEBT_MODAL_LOCATION_ORGS, res.data.locations)
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_LOCATION_ORGS_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_LOCATION_ORGS_LOADING, true)
		}

		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_DEBT_MODAL_LOCATION_ORGS_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onOrg(v) {
		if (v === undefined) {
			this.props.setSelected(SET_DEBT_MODAL_LOCATION_SELECTED_ORG, -1, null)
			return
		}
		this.props.setSelected(SET_DEBT_MODAL_LOCATION_SELECTED_ORG, v, this.props.debt.modalLocation.orgs[v])
	}

	render() {
		return (
			<Container fluid>
				<Row>
					<Col>
						<Select
							showSearch
							style={{ textAlign: "center", margin: 0 }}
							optionFilterProp="children"
							filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
							placeholder={translate(ResourceAssistance.Message.org)}
							loading={this.props.debt.modalLocation.isOrgsLoading}
							value={this.props.debt.modalLocation.selectedOrg.org ? this.props.debt.modalLocation.selectedOrg.index : undefined}
							onChange={this.onOrg}
						>
							{Utils.renderSelects(this.props.debt.modalLocation.orgs, false)}
						</Select>
					</Col>
				</Row>
			</Container>
		)
	}
}

const mapStateToProps = (state) => ({
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			setLoadingAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(DebtModalLocationDetails)
