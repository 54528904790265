import { Document, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer"
import React from "react"
import { Fragment } from "react"
import { ResourceAssistance } from "~/i18n"
import ReportTitle from "~/report/component/ReportTitle"
import { Utils } from "~/utils/Utils"

class ReportGlasgowComaScaleDoc extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {
				glasgowComaScales: [],
			},
		}
	}

	componentDidMount() {
		this.setState({
			data: Object.assign({}, this.state.data, {
				...this.state.data,
				...window.data,
			}),
		})
		delete window.data
	}

	render() {
		return (
			<PDFViewer style={ResourceAssistance.Report.styles.iframe}>
				<Document style={ResourceAssistance.Report.styles.document}>
					<Page size={ResourceAssistance.Report.size.page.A4} style={[ResourceAssistance.Report.styles.page]}>
						{/* Title */}
						<ReportTitle
							data={this.state.data}
							displayOrg={false}
							displayAddress={false}
							displayFax={false}
							displayTaxNum={false}
							displayBranch={false}
							displayTel={false}
						/>

						<View fixed>
							<View style={[ResourceAssistance.Report.styles.row, { justifyContent: "center" }]}>
								<View style={[ResourceAssistance.Report.styles.colAuto]}>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>PID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.pid}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>ID: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.id}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Name: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.patientName}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Age: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.age}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>DOB: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.dob}</Text>
										</View>
									</View>
									<View style={[ResourceAssistance.Report.styles.row]}>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
											<Text>Ref HN: </Text>
										</View>
										<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoBody]}>
											<Text>{this.state.data.hn}</Text>
										</View>
									</View>
								</View>
							</View>
							<View style={ResourceAssistance.Report.styles.separator} />
						</View>

						{/* Date Based Vital Sign */}
						{this.state.data.glasgowComaScales.map((glasgowComaScale) => {
							return (
								<Fragment>
									<View wrap={false}>
										<View style={[ResourceAssistance.Report.styles.row]}>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>Date: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody, { marginRight: 10 }]}>
												<Text>{glasgowComaScale.date}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colAuto, styles.infoHeader]}>
												<Text>Day: </Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.col, styles.infoBody]}>
												<Text>{glasgowComaScale.day} </Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.separator} />

										{/* Item Header */}
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.timeIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.eyeOpening]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.eyeOpeningIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.verbal]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.verbalIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.motor]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.motorIntl}</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.score]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>{this.state.data.scoreIntl}</Text>
											</View>
										</View>
										<View style={[ResourceAssistance.Report.styles.row, { fontSize: 16 }]}>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Time</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.eyeOpening]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Eye Opening Response</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.verbal]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Verbal Response</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.motor]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Motor Response</Text>
											</View>
											<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.score]}>
												<Text style={ResourceAssistance.Report.styles.textBold}>Score</Text>
											</View>
										</View>
										<View style={ResourceAssistance.Report.styles.horizontalLine} />
										{glasgowComaScale.glasgowComaScales.map((gcs) => {
											return (
												<View style={[ResourceAssistance.Report.styles.row, styles.itemRow, { fontSize: 16 }]}>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.time]}>
														<Text>{Utils.formatTime(gcs.dateTime)}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.eyeOpening]}>
														<Text>{gcs.eyeOpening}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.verbal]}>
														<Text>{gcs.verbal}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.motor]}>
														<Text>{gcs.motor}</Text>
													</View>
													<View style={[ResourceAssistance.Report.styles.colTextCenter, styles.score]}>
														<Text>{gcs.score}</Text>
													</View>
												</View>
											)
										})}
									</View>
									<View style={[ResourceAssistance.Report.styles.separatorFlexGrow]} />
								</Fragment>
							)
						})}
					</Page>
				</Document>
			</PDFViewer>
		)
	}
}

const styles = StyleSheet.create({
	line: {
		borderBottomColor: "black",
		borderBottomWidth: 1,
	},
	infoHeader: {
		fontStyle: "bold",
	},
	infoBody: {
		fontStyle: "italic",
	},
	time: {
		minWidth: "12%",
		maxWidth: "12%",
	},
	eyeOpening: {
		minWidth: "26%",
		maxWidth: "26%",
	},
	verbal: {
		minWidth: "24%",
		maxWidth: "24%",
	},
	motor: {
		minWidth: "26%",
		maxWidth: "26%",
	},
	score: {
		minWidth: "12%",
		maxWidth: "12%",
	},
})

export default ReportGlasgowComaScaleDoc
