import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { GrFormUpload } from "react-icons/gr"
import { RiRefreshLine } from "react-icons/ri"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setSelected, setValue } from "~/redux/action"
import { SET_DOCUMENT_FILTER_NAME, SET_DOCUMENT_SELECTED_DOCUMENT, SET_DOCUMENT_UPLOAD_DISPLAY } from "~/redux/type"
import SearchBox from "~/view/component/search_box/SearchBox"

class DocumentAction extends React.Component {
	constructor(props) {
		super(props)

		this.onUpload = this.onUpload.bind(this)
		this.onRefresh = this.onRefresh.bind(this)
		this.onFilterName = this.onFilterName.bind(this)
	}

	componentWillUnmount() {
		this.props.setValue(SET_DOCUMENT_FILTER_NAME, "")
	}

	render() {
		return (
			<>
				<Row className="g-2">
					<Col>
						<SearchBox
							num={1}
							controlTypes={[ResourceAssistance.FormControl.type.text]}
							placeholders={[ResourceAssistance.Message.name]}
							callbacks={[this.onFilterName]}
						/>
					</Col>
					<Col md={"auto"}>
						<Button variant={ResourceAssistance.Button.variant.primary} onClick={this.onUpload}>
							<GrFormUpload size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.upload)}
						</Button>
					</Col>
					<Col md="auto">
						<Button variant={ResourceAssistance.Button.variant.primary} onClick={this.onRefresh}>
							<RiRefreshLine size={ResourceAssistance.ReactIcon.size} />
							{translate(ResourceAssistance.Message.refresh)}
						</Button>
					</Col>
				</Row>
			</>
		)
	}
	onUpload() {
		this.props.onModalDisplayAction(SET_DOCUMENT_UPLOAD_DISPLAY, true)
		this.props.setSelected(SET_DOCUMENT_SELECTED_DOCUMENT, -1, null)
	}

	onRefresh() {
		this.props.loadUploadDocuments()
	}

	onFilterName(e) {
		this.props.setValue(SET_DOCUMENT_FILTER_NAME, e.target.value)
	}
}

const mapStateToProps = (state) => ({
	document: state.menu.document,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setSelected,
			setValue,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DocumentAction))
