import { Divider } from "antd"
import _ from "lodash"
import React from "react"
import { Button, Col, Row } from "react-bootstrap"
import { AiOutlineFileSearch } from "react-icons/ai"
import { FaRegEdit } from "react-icons/fa"
import { ImPrinter } from "react-icons/im"
import { MdLibraryAdd, MdPlaylistAddCheck } from "react-icons/md"
import { injectIntl } from "react-intl"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { axios } from "~/axios"
import { ResourceAssistance, translate } from "~/i18n"
import { onModalDisplayAction, setDebtPlans, setLoadingAction, setSelected } from "~/redux/action"
import { SET_DEBT_NEW_DISPLAY, SET_DEBT_SEARCH_DISPLAY, SET_DEBT_SELECTED_INVOICE, SET_DEBT_SELECTED_PLAN, SET_PAGE_LOADING } from "~/redux/type"
import { Utils } from "~/utils/Utils"

class DebtLeftAction extends React.Component {
	constructor(props) {
		super(props)

		this.onComplete = this.onComplete.bind(this)
		this.onSearchDebt = this.onSearchDebt.bind(this)
		this.onNew = this.onNew.bind(this)
		this.onEdit = this.onEdit.bind(this)
	}

	isCompleteDisabled() {
		let totalBalance = this.props.debt.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[5]))
		}, Utils.BigNumber(0))
		let totalDiscount = this.props.debt.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[6])).plus(Utils.formatNumberFromStr(cur[7]))
		}, Utils.BigNumber(0))
		let totalPaid = this.props.debt.invoiceTable.body.reduce((total, cur) => {
			return total.plus(Utils.formatNumberFromStr(cur[9]))
		}, Utils.BigNumber(0))
		return (
			!this.props.debt.selectedPlan.plan ||
			this.props.debt.selectedPlan.plan.complete ||
			_.isEmpty(this.props.debt.selectedPlan.plan.payment) ||
			!Utils.BigNumber(this.props.debt.selectedPlan.plan.amount)
				.plus(this.props.debt.selectedPlan.plan.transactionFee)
				.plus(this.props.debt.selectedPlan.plan.withholdingTax)
				.plus(this.props.debt.selectedPlan.plan.balanceAdjustment)
				.eq(totalBalance.minus(totalDiscount).minus(totalPaid))
		)
	}

	onComplete() {
		let invoices = this.props.debt.selectedPlan.plan.invoices
		let avgWHT = Utils.BigNumber(this.props.debt.selectedPlan.plan.withholdingTax).dividedBy(invoices.length)
		let avgTransFee = Utils.BigNumber(this.props.debt.selectedPlan.plan.transactionFee).dividedBy(invoices.length)
		let avgBalanceAdjustment = Utils.BigNumber(this.props.debt.selectedPlan.plan.balanceAdjustment).dividedBy(invoices.length)
		let totalWHT = Utils.BigNumber(0)
		let totalTransFee = Utils.BigNumber(0)
		let totalBalanceAdjustment = Utils.BigNumber(0)
		let params = {
			method: "POST",
			url: ResourceAssistance.Url.debt.completeDebtPlans,
			withCredentials: true,
			headers: {
				"content-type": "application/json",
			},
			data: {
				orgId: this.props.debt.location.id,
				userId: this.props.login.user.id,
				debtPlans: [
					{
						id: this.props.debt.selectedPlan.plan.id,
						invoices: invoices
							.sort((a, b) => Utils.sort(b.total, a.total))
							.map((invoice, idx) => {
								if (idx === invoices.length - 1) {
									return {
										id: invoice.id,
										amount: Utils.BigNumber(invoice.total)
											.minus(invoice.billing.total)
											.minus(totalWHT.minus(this.props.debt.selectedPlan.plan.withholdingTax).abs())
											.minus(totalTransFee.minus(this.props.debt.selectedPlan.plan.transactionFee).abs())
											.minus(totalBalanceAdjustment.minus(this.props.debt.selectedPlan.plan.balanceAdjustment).abs())
											.toFixed(2),
										withholdingTaxAmount: totalWHT.minus(this.props.debt.selectedPlan.plan.withholdingTax).abs().toFixed(2),
										transFee: totalTransFee.minus(this.props.debt.selectedPlan.plan.transactionFee).abs().toFixed(2),
										balanceAdjustment: totalBalanceAdjustment.minus(this.props.debt.selectedPlan.plan.balanceAdjustment).abs().toFixed(2),
									}
								} else {
									if (avgWHT.plus(avgTransFee).plus(avgBalanceAdjustment).isGreaterThan(Utils.BigNumber(invoice.total).minus(invoice.billing.total))) {
										return {
											id: invoice.id,
											amount: Utils.BigNumber(invoice.total).minus(invoice.billing.total),
										}
									} else {
										totalWHT = totalWHT.plus(avgWHT.toFixed(2))
										totalTransFee = totalTransFee.plus(avgTransFee.toFixed(2))
										totalBalanceAdjustment = totalBalanceAdjustment.plus(avgBalanceAdjustment.toFixed(2))
										return {
											id: invoice.id,
											amount: Utils.BigNumber(invoice.total)
												.minus(invoice.billing.total)
												.minus(avgWHT.toFixed(2))
												.minus(avgTransFee.toFixed(2))
												.minus(avgBalanceAdjustment.toFixed(2))
												.toFixed(2),
											withholdingTaxAmount: avgWHT.toFixed(2),
											transFee: avgTransFee.toFixed(2),
											balanceAdjustment: avgBalanceAdjustment.toFixed(2),
										}
									}
								}
							}),
					},
				],
			},
		}
		let callback = (res) => {
			if (res.data.debtPlans.length > 0) {
				let plans = this.props.debt.planTable.original.filter((each) => each.id !== res.data.debtPlans[0].id)
				plans.push(res.data.debtPlans[0])
				this.props.setDebtPlans(plans)
			}
		}
		let errorHandler = (error) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		let reqInterceptor = (config) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, true)
		}
		let resInterceptor = (response) => {
			this.props.setLoadingAction(SET_PAGE_LOADING, false)
		}
		axios(params, callback, errorHandler, reqInterceptor, resInterceptor)
	}

	onSearchDebt(event) {
		this.props.onModalDisplayAction(SET_DEBT_SEARCH_DISPLAY, true)
	}

	onNew(event) {
		this.props.setSelected(SET_DEBT_SELECTED_PLAN, -1, null)
		this.props.onModalDisplayAction(SET_DEBT_NEW_DISPLAY, true)
	}

	onEdit() {
		this.props.setSelected(SET_DEBT_SELECTED_INVOICE, -1, null)
		this.props.onModalDisplayAction(SET_DEBT_NEW_DISPLAY, true)
	}

	render() {
		return (
			<Row className={"g-0 label-info-row"}>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.green}
						size={ResourceAssistance.Button.size.sm}
						disabled={this.isCompleteDisabled()}
						onClick={this.onComplete}
					>
						<MdPlaylistAddCheck size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.complete)}
					</Button>
				</Col>
				<Col sm="auto">
					<Button
						variant={ResourceAssistance.Button.variant.info}
						size={ResourceAssistance.Button.size.sm}
						disabled={!this.props.debt.selectedPlan.plan || !this.props.debt.selectedPlan.plan.complete}
						onClick={this.onPrint}
					>
						<ImPrinter size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.print)}
					</Button>
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: "100%", alignSelf: "center" }} />
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.info} onClick={this.onSearchDebt}>
						<AiOutlineFileSearch size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.search)}
					</Button>
				</Col>
				<Col md="auto">
					<Button
						size={ResourceAssistance.Button.size.sm}
						variant={ResourceAssistance.Button.variant.primary}
						disabled={!this.props.debt.selectedPlan.plan || this.props.debt.selectedPlan.plan.complete}
						onClick={this.onEdit}
					>
						<FaRegEdit size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.edit)}
					</Button>
				</Col>
				<Col md="auto">
					<Button size={ResourceAssistance.Button.size.sm} variant={ResourceAssistance.Button.variant.primary} onClick={this.onNew}>
						<MdLibraryAdd size={ResourceAssistance.ReactIcon.size} />
						{translate(ResourceAssistance.Message.new)}
					</Button>
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	login: state.login,
	debt: state.accounting.debt,
})

const mapDispatchToProps = (dispatch) => ({
	...bindActionCreators(
		{
			onModalDisplayAction,
			setDebtPlans,
			setLoadingAction,
			setSelected,
		},
		dispatch
	),
	dispatch,
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DebtLeftAction))
