import { ResourceAssistance } from "~/i18n"
import { Utils } from "~/utils/Utils"
import {
	SET_ADMIN_ROLE_ROLES,
	SET_ADMIN_ROLE_DISPLAY_ALL_IND,
	SET_ADMIN_ROLE_SELECTED_ROLE,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_NAME,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_ACTIVE_IND,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_DESCRIPTION,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PERMISSION,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PENDING_PERMISSION,
	SET_ADMIN_ROLE_MODAL_NEW_ROLE_PENDING_PERMISSIONS,
	SET_ADMIN_ROLE_PERMISSIONS,
} from "../../type/type/ADMIN"

export const setAdmRoleRoles = (roles) => {
	return (dispatch, getState) => {
		let filtered = roles
			.filter((each) => {
				if (getState().admin.userConfig.role.displayInactive) {
					return each.name.toLowerCase().includes(getState().admin.userConfig.role.searchName.toLowerCase())
				} else {
					return (
						each.active && each.name.toLowerCase().includes(getState().admin.userConfig.role.searchName.toLowerCase())
					)
				}
			})
			.sort((a, b) => Utils.sort(a.name, b.name))

		let colors = []
		if (getState().admin.userConfig.role.displayInactive) {
			colors = filtered.map((each) => {
				return [!each.active, ResourceAssistance.CSS.Color.red]
			})
		}

		let body = filtered.map((each) => {
			return [each.name, each.description, each.createdBy.displayName, Utils.formatDate(each.creationDateTime)]
		})

		dispatch({
			type: SET_ADMIN_ROLE_ROLES,
			payload: {
				original: roles,
				filtered: filtered,
				body: body,
				rowColor: colors,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmRoleDisplayAll = (isDisplay) => {
	return {
		type: SET_ADMIN_ROLE_DISPLAY_ALL_IND,
		payload: {
			isDisplay: isDisplay,
		},
	}
}

export const setAdmRoleSelectedRole = (index, role) => {
	return {
		type: SET_ADMIN_ROLE_SELECTED_ROLE,
		payload: {
			index: index,
			role: role,
		},
	}
}

export const setAdmRoleModalNRPermissions = (permissions) => {
	return (dispatch, getState) => {
		let filtered = permissions.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => {
			return [each.displayName, each.description]
		})

		dispatch({
			type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_PERMISSIONS,
			payload: {
				original: permissions,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmRoleModalNRName = (name) => {
	return {
		type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_NAME,
		payload: {
			name: name,
		},
	}
}

export const setAdmRoleModalNRDescription = (description) => {
	return {
		type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_DESCRIPTION,
		payload: {
			description: description,
		},
	}
}

export const setAdmRoleModalNRActive = (isActive) => {
	return {
		type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_ACTIVE_IND,
		payload: {
			isActive: isActive,
		},
	}
}

export const setAdmRoleModalNRSelectedPermission = (index, permission) => {
	return {
		type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PERMISSION,
		payload: {
			index: index,
			permission: permission,
		},
	}
}

export const setAdmRoleModalNRPendingPermissions = (permissions) => {
	return (dispatch, getState) => {
		let filtered = permissions.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => [each.displayName, each.description])

		dispatch({
			type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_PENDING_PERMISSIONS,
			payload: {
				original: permissions,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}

export const setAdmRoleModalNRSelectedPendingPermission = (index, permission) => {
	return {
		type: SET_ADMIN_ROLE_MODAL_NEW_ROLE_SELECTED_PENDING_PERMISSION,
		payload: {
			index: index,
			permission: permission,
		},
	}
}

export const setAdmRolePermissions = (permissions) => {
	return (dispatch, getState) => {
		let filtered = permissions.sort((a, b) => Utils.sort(a.displayName, b.displayName))

		let body = filtered.map((each) => [each.displayName, each.description])

		dispatch({
			type: SET_ADMIN_ROLE_PERMISSIONS,
			payload: {
				original: permissions,
				filtered: filtered,
				body: body,
			},
		})
		return Promise.resolve()
	}
}
